import React, { useState, useEffect } from 'react';
import Timeline, {TimelineHeaders, SidebarHeader, DateHeader, TimelineMarkers, TodayMarker, CustomMarker} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import '../../../../Utilities/ProjectTimeline/projectTimeline.css'
import moment from 'moment'
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Button, Input, Label, Dropdown, DropdownItem, DropdownMenu,DropdownToggle } from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'
import { DateRangePicker } from 'react-dates';
import Select from 'react-select';
import { TrelloOptionsModal } from './TrelloOptionsModal';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux'
import TextEditor from '../../../../Utilities/TextEditor/texteditor';
import { ModifiedContainer } from '../DataContainers';
import { getTrelloBoardLists, getTrelloDataForTimeline, updateChecklistTrelloCard } from '../../../../Components/Reports/actions/ActionCreator';
import { executeRequest } from '../../../../Utilities/requestHandler/dataHandler';
import { getTrelloColor } from '../../../../Utilities/ProjectTimeline/Trello/TrelloCustomVariables';

const valueList = [
    {value:5, label:5},
    {value:10, label:10},
    {value:15, label:15}
]

export const TrelloTimelineBlock = ({item, itemId, businessId, editable, index, layoutPreview, toggleItemVisibility, onDelete, promptSave, saveItemWithoutQueue}) => {
    const [search, setSearch] = useState("")
    const [dateFilter, setDateFilter] = useState({ startDate: null, endDate:null})
    const [focusedInput, setFocusedInput] = useState()
    const [defaultStart, setDefaultStart] = useState(moment().add(-7, 'day'))
    const [defaultEnd, setDefaultEnd] = useState(moment().add(21, 'day'))
    const [calculatedHeight, setCalculatedHeight] = useState("250px")
    const [showAmount, setShowAmount] = useState({value:10, label:10})
    const [trelloModalOpen, setTrelloModalOpen] = useState(false)
    const [selectedTrelloBoard, setSelectedTrelloBoard] = useState(false)
    const [selectedTrelloLists, setSelectedTrelloLists] = useState(false)
    const [trelloItems, setTrelloItems] = useState([])
    const [trelloGroups, setTrelloGroups] = useState([])
    const [buildingLoader, setBuildingLoader] = useState(false)
    const [onlyDeadlineCards, setOnlyDeadlineCards] = useState(false)
    const [filteredTrelloItems, setFilteredTrelloItems] = useState([])
    const [filteredTrelloGroups, setFilteredTrelloGroups] = useState([])
    const [sameDateItems, setSameDateItems] = useState([])
    const [sameDateModalOpen, setSameDateModalOpen] = useState(false)
    const [forceUpdate, setForceUpdate] = useState(false)
    const [openMenu, toggleMenu] = useState(false)
    const [title, setTitle] = useState(item.topicContent)
    const [trelloOptions, setTrelloOptions] = useState(item.trelloOptions? item.trelloOptions : {})
    const [trelloCards, setTrelloCards] = useState([])

    const dispatch = useDispatch()

    useEffect(()=>{
        if(item.trelloOptions){
            let options = {
                ...item.trelloOptions
            }
            setTrelloOptions(options)
        }
    },[item])

    const handleDataChanges = (type, data) => {
        
        if(type === "title") {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                type: item.type
            }
            promptSave(itemPayload);
        }
       
    }

    const savePlanItemTrelloKeys = (apiKeys) => {
       
        let itemPayload = {
            id: item.id,
            topicContent: title,
            type: item.type,
            trelloOptions:{
                ...trelloOptions,
                trelloConnection:{
                    ...apiKeys
                }
            }
        }
        setTrelloOptions({...trelloOptions, trelloConnection:apiKeys})
        saveItemWithoutQueue(itemPayload)     
    }

    const saveTrelloOptions = () => {
        let payload = {
            selectedTrelloLists: selectedTrelloLists,
            selectedTrelloBoard: selectedTrelloBoard,
            onlyDeadlineCards: onlyDeadlineCards
        }
       
        let itemPayload = {
            id: item.id,
            topicContent: title,
            type: item.type,
            trelloOptions:{
                ...trelloOptions,
                ...payload
            }
        }
        setTrelloOptions({...trelloOptions, ...payload})
        saveItemWithoutQueue(itemPayload)     
    }

    useEffect(()=>{
        calculateHeight()
    },[showAmount, selectedTrelloLists, buildingLoader, search, dateFilter, filteredTrelloGroups])
    
    const calculateHeight = () => {
        let height = 110
        
        if(showAmount.value <= filteredTrelloGroups.length){
            height = (showAmount.value * 50 + 70)
        } else {
            if(filteredTrelloGroups.length > 0){
                height = (filteredTrelloGroups.length * 50 + 70)
            }
        }
        
        setCalculatedHeight(height+'px')
        
    }

    const getTrelloCardData = async(payload) => {
        const response = await executeRequest('getTrelloCardData',payload)
        if(response.cardList){
          setSameDateItems(response.cardList)
        }
    }

    const handleSameDateModal = (incomingItems) => {
        setSameDateItems([])
        let trelloConnection = trelloOptions.trelloConnection
        let payload = {
            cardList: incomingItems,
            apiKey: trelloConnection.apiKey,
            apiToken: trelloConnection.apiToken
        }    
        getTrelloCardData(payload)
        setSameDateModalOpen(true)
    }

    const changeFilterDates = (dates) => {
        const {startDate, endDate} = dates
        let dateFilter = {startDate: startDate, endDate: endDate}
        setDateFilter({...dateFilter})
        if(startDate && endDate){
          setDefaultStart(moment(startDate).add(-2, "day"))
          setDefaultEnd(moment(endDate).add(2,"day"))
        }
    } 

    const itemsOnSameDateFilter = ( obj, item) => {
        if((onlyDeadlineCards? obj.due===true : true) && obj.group === item.group && timeChecker(obj,item)) return true 
    }

    const timeChecker = (obj,item) => {
        if(moment(obj.start_time).isAfter(moment(item.start_time).set("hours",0)) && moment(obj.end_time).set("hours",22).isBefore(moment(item.end_time))){
          return true
        }
    }

    const groupRenderer = ({ group }) => {
        return (
          <div className="custom-group" style={{ cursor:'pointer'}} title={group.title}>
           <span style={{marginTop:'-10px', fontSize:'0.9rem', fontWeight:'bold'}} className="title">{group.title}</span>
           <span style={{marginTop:'-30px', fontSize:'0.7rem'}}>{group.target}</span>
           
          </div>
        )
    }

    const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        // could make the coloring to represent the current status of the task => green completed, yellow ongoing, red deadline missed 
        let backgroundColor = "#f3be21";
        if(item.due){
          if(item.completed){
            backgroundColor = "#4bce97";
          } else {
            if(moment.utc(new Date(item.dueDate),"MMM D, YYYY hh:mm:ss ", "en").local().isBefore(moment.utc(new Date(),"MMM D, YYYY hh:mm:ss ", "en").local())){
              backgroundColor = "#f87168"
            }
          }
        }
        if(item.progress){
          if(item.progress === 100){
            backgroundColor = "#4bce97";
          }
        }
        let onSameDateLength = filteredTrelloItems.filter((card) => itemsOnSameDateFilter(card,item)).length
        const borderColor = "#bbb";
        return (
          <div
            {...getItemProps({
              style: {
                background:backgroundColor,
                color: item.color,
                borderColor: borderColor,
                borderStyle: "solid",
                borderWidth: 1,
                borderRadius: 4,
                opacity:1,
                fontWeight: "bold",
                zIndex:50
              }
            })}
          >
            {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
            <div
              style={{
                height: itemContext.dimensions.height,
                overflow: "hidden",
                paddingLeft: 3,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
              onClick={()=> (handleSameDateModal(filteredTrelloItems.filter((card) => itemsOnSameDateFilter(card,item))))}
            >
              {itemContext.title}
              {onSameDateLength > 1 && 
                <div style={{position:'absolute', top:'0em', right:'0em', background:'red', borderRadius:'50px', zIndex:'8', padding:'0.15em 0.15em', height:'1.25em', border:'1px solid #bbb' }}>
                  <p style={{marginTop: '-1.1em', fontSize: '0.8rem', fontWeight:'bold', color:'#fff'}}>{onSameDateLength}</p>
                </div>
              }
            </div>
           
            {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
          </div>
        )
    }

    const groupFilter = (group) => { 
        if(group.title.toLowerCase().includes(search.toLowerCase()) || (group.target && group.target.toLowerCase().includes(search.toLowerCase()))) return true 
        return false
      }

    useEffect(()=>{
        let filtered = trelloItems.filter((item) => filterTrelloDates(item) && trelloItemFilter(item) && filterDeadline(item))
        setFilteredTrelloItems(filtered)
        setFilteredTrelloGroups(trelloGroups.filter((group) => filtered.filter((item) => item.group === group.id).length > 0 || filterTrelloDates(group) && groupFilter(group) && filterDeadline(group))) 
    },[search, dateFilter, onlyDeadlineCards, buildingLoader, trelloGroups])
    
    const trelloItemFilter = (item) => {

        if(memberFilter(item)) return true
        
        if(item.title.toLowerCase().includes(search.toLowerCase())) return true
    
        return false
    }

    const memberFilter = (item) => {
        // Pitää fixata logiikka, jolla filtteröi oikein halutun nimen
        if(item.memberList && item.memberList.length > 0){
            if(item.memberList.filter((member) => member.fullName.toLowerCase().includes(search.toLowerCase())).length > 0){
                return true
            }
        } else return false
    }

    const filterDeadline = (item) => {
        if(onlyDeadlineCards){
            if(item.due === true) return true
            return false
        } else return true
    }
    
    const filterTrelloDates = (item) => {
        if(dateFilter.startDate && dateFilter.endDate){
            if(moment(item.start_time).isAfter(moment(dateFilter.startDate)) && moment(item.end_time).isBefore(moment(dateFilter.endDate))) return true
            return false
        } else return true
    }

    const handleDateReset = () => {
        setDateFilter({startDate: '', endDate:''})
        setDefaultStart(moment().add(-7, 'day'))
        setDefaultEnd(moment().add(21, 'day'))
    }
    
    const handleShowAmountChanged = (value) => {
        setShowAmount(value)
    }

    const toggleTrelloModal = () => {
        setTrelloModalOpen(!trelloModalOpen)
    }

    const handleSelectedTrelloLists = (list) => {
        if(list.length > 0){
            setSelectedTrelloLists(list)
        }
    }
    
    const buildTrelloTimeline = (list) => {
        let builder = buildTrelloGroups(list, trelloCards)
        if(builder.trelloGroups){
          setTrelloGroups(builder.trelloGroups)
        } 
        if(builder.trelloItems){
          setTrelloItems(builder.trelloItems)
        }
        setBuildingLoader(false)
    }

    useEffect(()=>{
        if(trelloOptions && trelloOptions.selectedTrelloBoard && trelloOptions.selectedTrelloLists){

            setSelectedTrelloLists(trelloOptions.selectedTrelloLists)

            if(!selectedTrelloBoard || trelloOptions.selectedTrelloBoard.value !== selectedTrelloBoard.value){
                getSelectedBoardLists(trelloOptions.selectedTrelloBoard)
           
                setSelectedTrelloBoard(trelloOptions.selectedTrelloBoard)
            }
            setOnlyDeadlineCards(trelloOptions.onlyDeadlineCards? trelloOptions.onlyDeadlineCards: false)
           
        }
    },[trelloOptions, forceUpdate])


    useEffect(()=>{
        if(selectedTrelloLists.length > 0 && trelloCards.length > 0){
            buildTrelloTimeline(selectedTrelloLists)
        }
    },[trelloCards, selectedTrelloLists])

    const getSelectedBoardLists = async(val) => {
        setBuildingLoader(true)
        const boardData = {
        apiKey: trelloOptions.trelloConnection.apiKey,
        apiToken: trelloOptions.trelloConnection.apiToken,
        boardId: val.value
        }
        let selected = []
        if(item.trelloOptions && item.trelloOptions.selectedTrelloLists){
            selected = item.trelloOptions.selectedTrelloLists
        }
        const response = await dispatch( getTrelloDataForTimeline( boardData ) )
        if(response && response.trelloLists){
            let array = response.trelloLists.map((list)=>{
                if(selected.length > 0){
                    let selectedObj = selected.filter((item) => item.id === list.id)[0]
                    list = {
                        ...list,
                        selected: selectedObj.selected
                    }
                }
                else  {
                    list = {
                        ...list,
                        selected: true
                    }
                }
                return list
            })
        
            handleSelectedTrelloLists(array)
        } 
        if(response.cards){
            setTrelloCards(response.cards)
        }
    }

    const updateTrelloList = () => {
        if(trelloOptions.trelloConnection && trelloOptions.selectedTrelloBoard){
          setSelectedTrelloBoard(selectedTrelloBoard)
          setForceUpdate(!forceUpdate)
          setBuildingLoader(true)
        } else {
            if(!trelloOptions.trelloConnection){
                NotificationManager.error("Trellon API-avainta ja -tokenia ei ole asetettu", "Virhe", 3000)
            }
            if(!trelloOptions.selectedBoard){
                NotificationManager.error("Trellon taulua ei ole valittu", "Virhe", 3000)
            }
        }
    }

    return (
        <div style={{ marginTop:'2em', marginBottom:'2em' }}>
            <MultipleItemsModal
                isOpen={sameDateModalOpen}
                sameDateItems={sameDateItems}
                toggle={setSameDateModalOpen}
            />
            <TrelloOptionsModal 
                isOpen={trelloModalOpen} 
                toggle={toggleTrelloModal}
                handleSelectedTrelloBoard={setSelectedTrelloBoard}
                handleSelectedTrelloLists={handleSelectedTrelloLists}
                selectedTrelloBoard={selectedTrelloBoard}       
                onlyDeadlineCards={onlyDeadlineCards}
                setOnlyDeadlineCards={setOnlyDeadlineCards}
                forceUpdate={forceUpdate}
                trelloSelected={true}
                planItem={item}
                saveTrelloOptions={saveTrelloOptions}
                savePlanItemTrelloKeys={savePlanItemTrelloKeys}
            />
            <Row className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                <Col xl="12">
                    {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red' }}>Yksityinen</span>}
                    {(!editable && !layoutPreview && item.modified !== undefined) && 
                        <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                    }
                </Col>
                <Col xl="12" style={{marginBottom: 8}} className="title-col">
                    <div style={{ display:'flex' }}>
                        {!layoutPreview && <><div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}</div> <i className="fab fa-trello plan-input subTitle" style={{alignSelf: 'center', marginRight:'1em'}}></i></>}
                        {editable ? 
                        <input className="plan-input subTitle" name="title" type="text" style={{ width: "100%" }} placeholder="Otsikko" value={(item && title) ? title : ''} onChange={(e) => handleDataChanges("title", e.target.value)}></input>
                        :
                            <TextEditor
                                editorId={`${item.id}-subtitle-${editable}`} 
                                content={item.topicContent}
                                additionalClass="plan-input subTitle plan-subtitle"
                                readOnly={!editable}
                                placeholder="-"
                                plaintextEditor={true}
                            />
                        }
                    </div>
                </Col>
                <Col xs="11">
                    <div style={{ marginBottom:'0.25em', display:'flex', width:'100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft:'2.5em', fontSize:'0.9rem' }}>
                            <Input type="search" className="search-bar-open" placeholder={"Hae"} value={search} onChange={(event) => setSearch(event.target.value)} />
                        </div>
                        <div style={{ marginLeft:'1em', marginTop:'0.1em', display:'flex' }}>
                            <DateRangePicker
                                startDate={dateFilter.startDate} // momentPropTypes.momentObj or null,
                                startDateId="unique_start_" // PropTypes.string.isRequired,
                                startDatePlaceholderText="Alku pvm"
                                endDate={dateFilter.endDate} // momentPropTypes.momentObj or null,
                                endDateId="unique_enddate_" // PropTypes.string.isRequired
                                endDatePlaceholderText="Loppu pvm"
                                onDatesChange={({ startDate, endDate }) => changeFilterDates({ startDate: startDate, endDate: endDate })} // PropTypes.func.isRequired,
                                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                isOutsideRange={() => false}
                                block
                                small
                                hideKeyboardShortcutsPanel={true}
                                // showClearDates  
                            /> 
                            <div className="small-white-button" style={{ height:'2.28em', fontWeight:'normal', fontSize:'0.9rem', marginLeft:'-0.5em', marginTop:'0.5px', borderRadius:'0 5px 5px 0', cursor:'pointer'}} onClick={()=> handleDateReset()}>Tyhjennä</div>
                        </div>
                        {editable &&
                            <div style={{ marginLeft:'1em'}}>
                                <div className="small-white-button timeline-div-button" title="Trello-asetukset" style={{padding:'0.25em 0.4em'}} onClick={()=> setTrelloModalOpen(true)}><i className="fab fa-trello"></i></div>
                            </div>
                        }
                        <div style={{ marginLeft:'1em'}}>
                            {buildingLoader ?
                                <div className="small-white-button timeline-div-button" title="Päivitä" style={{padding:'0.25em 0.4em'}}>
                                    <LoadingSpinner/>
                                </div>
                                :
                                <div className="small-white-button timeline-div-button" title="Päivitä" style={{padding:'0.25em 0.4em'}} onClick={()=> updateTrelloList()}>
                                    <i className="fas fa-sync-alt"></i>
                                </div>
                            }
                        </div>
                        <div style={{ display:'flex', marginLeft:'auto', marginRight:'-1em'}}>
                            <Label style={{ marginTop:'0.5em'}}>Näytä näkymässä (max): </Label>
                            <div style={{ zIndex:'10', display:'flex', width:'6em'}}>
                                <Select  
                                options={valueList} 
                                onChange={(val)=> handleShowAmountChanged(val)}
                                value={showAmount}
                                isDisabled={false}
                                noOptionsMessage={() => { return "Yrityksiä ei löytynyt" }}
                                className="dashboard-survey-selector timeline-amount-selector"
                                classNamePrefix="select"
                                />
                            </div>
                        </div>
                        {/* <div className="small-white-button timeline-div-button" title="Asetukset" style={{marginLeft:'0.75em'}}><i className="fas fa-cog"></i></div> */}
                    </div>
                    {!buildingLoader &&
                        <div className="project-timeline-container" style={{ marginLeft:'0.5em', width:'100%'}} >
                            <Scrollbars  
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                style={{ width: "100%", height:calculatedHeight}}      
                            >
                                <Timeline
                                    groups={filteredTrelloGroups}
                                    items={filteredTrelloItems}
                                    defaultTimeStart={ defaultStart}
                                    defaultTimeEnd={ defaultEnd}
                                    showCursorLine={true}
                                    itemHeightRatio={0.75}
                                    lineHeight={50}
                                    canMove={false}
                                    canResize={false}
                                    minZoom={(7*24*60*60*1000)}
                                    itemRenderer={itemRenderer}
                                    groupRenderer={groupRenderer}
                                    onItemSelect={()=>{return}}
                                >
                                    <TimelineHeaders className="project-timeline-sticky">
                                        <SidebarHeader>
                                        {({ getRootProps }) => {
                                            return <div {...getRootProps()} style={{ fontWeight:'bold', padding:'0.5em', width:'9.4em' }}><span>{selectedTrelloBoard.label}</span></div>;
                                        }}
                                        </SidebarHeader>
                                        <DateHeader unit="primaryHeader" />
                                        <DateHeader 
                                        intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
                                        return <div {...getIntervalProps()} className={'lower-date-time-div '+ (moment().isAfter(intervalContext.interval.startTime) && moment().isBefore(intervalContext.interval.endTime) && "date-is-today")}>
                                            {intervalContext.intervalText}
                                        
                                        </div>
                                        }}
                                        />
                                    </TimelineHeaders>
                                    <TimelineMarkers>
                                        <CustomMarker date={moment().add(0, 'day')}>
                                                {({ styles, date }) => {
                                        const customStyles = {
                                            ...styles,
                                            backgroundColor: '#e90101',
                                            width: '3px',
                                            zIndex:3
                                        }
                                        return <div style={customStyles} />
                                        }}
                                        </CustomMarker>
                                    </TimelineMarkers>
                                </Timeline>
                            </Scrollbars>
                        </div>
                    }
                </Col>
                <Col xs="1">
                    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                        {editable && 
                            <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                                <DropdownToggle tag="div">
                                    <i className="fas fa-bars plusIcon" title="Valikko"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                                    <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const buildTrelloGroups = (lists, cards) => {
    let finalGroups = []
    let finalItems = []
    lists.map((list)=>{
      if(list.selected){
        let group = {id: list.id, title: list.name}
        finalGroups.push(group)
      }
    })

    cards.map((card)=>{
        let item = {
          id:card.id,
          group: card.idList,
          title: card.name,
          start_time:  card.start?  moment(card.start).set("hours",1) :(card.due? moment(card.due).set("hours",1) : moment(card.dateLastActivity).set("hours",1)),
          end_time: card.due? moment(card.due).set("hours",23) : moment(card.dateLastActivity).set("hours",23),
          due: card.due? true: false,
          dueDate: card.due? card.due : false,
          completed: card.dueComplete,
          url:card.shortUrl,
          idMembers: card.idMembers ? card.idMembers : [],
          idChecklists: card.idChecklists? card.idChecklists : [],
          memberList: card.memberList? card.memberList : [],
          checklists: card.checklists? card.checklists : [],
          checkItemStates: card.checkItemStates? card.checkItemStates : [],
          labels: card.labels? card.labels : []
        }
        finalItems.push(item)
    })     

    return({trelloGroups: finalGroups, trelloItems: finalItems})
}

const LoadingSpinner = () => {
    return (
        <div style={{ width: '2rem', height: '2rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
 }

const MultipleItemsModal = ({sameDateItems, isOpen, toggle}) => {
  
    const onItemClick = (item) =>{
     
    } 
    
    const goToTrello = (item) => {
      if(item.url){
        let url = item.url.toString()
        window.open( url, "_blank")
      }
    }
  
    const getCheckItemProgress = (item) => {
      let totalItems = 0
      let done = 0
      if(item.checkItemStates){
        done = item.checkItemStates.length
      }
      item.checklists.map((list)=>{
        totalItems = (totalItems + list.checkItems.length)
      })
  
      return (done + '/' + totalItems)
  
    }
    return (
        <> 
            <Modal isOpen={isOpen} toggle={()=> toggle(!isOpen)}>
                <ModalHeader toggle={()=> toggle(!isOpen)}>Samanaikaiset tehtävät</ModalHeader>
                <ModalBody>
                    <Col>
                        {sameDateItems && sameDateItems.length > 0 &&
                            <Row>
                                <Col>
                                    <Scrollbars  
                                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                        style={{ width: "100%", height:'500px'}}      
                                    >
                                        {sameDateItems.map((item, key) => {
                                            let backgroundColor = "#f3be21";
                                            let color = "#404040"
                                            if(item.due){
                                                if(item.completed){
                                                    backgroundColor = "#4bce97";
                                                } else {
                                                    if(moment.utc(new Date(item.dueDate),"MMM D, YYYY hh:mm:ss ", "en").local().isBefore(moment.utc(new Date(),"MMM D, YYYY hh:mm:ss ", "en").local())){
                                                    backgroundColor = "#f87168"
                                                    color = '#fff'
                                                    }
                                                }
                                            } 
                                        
                                            return(
                                                <div key={key} className="timeline-modal-card" onClick={() => onItemClick(item)}>
                                                    <div style={{display:'flex'}}>
                                                        {item.labels && item.labels.map((label,index)=>{
                                                             let colors = getTrelloColor(label.color)
                                                             return(
                                                               <div key={index} className="timeline-trello-label" style={{ color:colors.color, backgroundColor: colors.backgroundColor}}>{label.name}</div>
                                                             )
                                                        })}
                                                        <button className="small-white-button" style={{ marginLeft:'auto', height:'1.75em', padding:'0 0.25em'}} title="Siirry korttiin" onClick={()=> goToTrello(item)}><i className="fab fa-trello"></i></button>
                                                    </div>
                                                    <div style={{display:'flex'}}>
                                                        <div style={{ fontWeight:'bold'}}>{item.title}</div>
                                                    </div>
                                                    <div style={{display:'flex', marginTop:'0.5em'}}>
                                                        {item.memberList && item.memberList.length > 0 && item.memberList.map((member, key)=>{
                                                            return(
                                                            <div className="member-tag" key={key}>{member.fullName}</div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div style={{display:'flex'}}>
                                                        <div>{moment(item.start_time).format("DD.MM.YYYY")}</div>
                                                        {item.checklists && item.checklists.length > 0 &&
                                                            <div style={{ marginLeft:'0.5em'}}>
                                                                <div className="timeline-card-duedate" style={{padding:'0.1em 0.2em', marginTop:'0.25em'}}>
                                                                    <i className="far fa-check-square" style={{ marginRight:'0.25em', color:'#6ba53f'}}></i> 
                                                                    <div>{getCheckItemProgress(item)}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {item.due && 
                                                            <div className="timeline-card-duedate" style={{backgroundColor:backgroundColor, color:color}}>
                                                            <i className="far fa-clock" style={{marginRight:'0.25em', marginTop:'0.15em'}}></i> 
                                                            <p>{moment.utc(item.dueDate).local().format("DD.MM.YYYY HH:mm")}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Scrollbars>
                                </Col>
                            </Row>
                        }
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={()=> toggle(!isOpen)}>Sulje</a>
                </ModalFooter>
            </Modal>  
        </>
    )
}
  
import React, { Component, Fragment, useEffect, useState} from 'react';
import { NotificationManager } from 'react-notifications';
import { Row, Col, Input, Label, Button } from 'reactstrap';
import './emailSender.css';

export const PersonalEmailSender = ({topic, text}) => {
    const [givenEmail, setGivenEmail] = useState("")
    const [emailOk, setEmailOk] = useState(false)
    const [emailTopic, setEmailTopic] = useState(topic? topic : "")
    const [emailText, setEmailText] = useState(text? text : "")
    const [emailList, setEmailList] = useState([])
    const [recipients, setRecipients] = useState("")
    const [type, setType] = useState("copy")

    const handleEmail = (email) => {
        // TODO - REGEX check for email to ease the checkup for user 
        setGivenEmail(email)

        const emailRegex = /[^@]+@[^\.]+\..+/

        if (emailRegex.test(email)) {
           setEmailOk(true)
        } else{
            setEmailOk(false)
        }
    }

    const addEmail = () => {
        
        if(emailOk){
            let emails = [...emailList, givenEmail]
            setEmailList(emails)
            setGivenEmail("")
            handleRecipientUpdate(emails)
        }
    }

    const removeFromEmailList = (index) => {
        let array = emailList
        array.splice(index,1)
        setEmailList([...array])
        handleRecipientUpdate(array)
    }

    const handleRecipientUpdate = (list) => {
        let finalRecipients = ""
        if(list.length > 0){
            list.map(email=>{
                finalRecipients += (email + ";")
            })
        }
        setRecipients(finalRecipients)
    }

    const alternativeSender = () => {
        var mailToLink = "mailto:"+recipients+"?subject="+emailTopic+"&body=" + encodeURIComponent(emailText);
        window.location.href = mailToLink;
    }

    const copyToClipboard = () => {
        let textarea = document.getElementById("textarea");
        textarea.select();
        document.execCommand("copy");
        NotificationManager.success("Viesti kopioitu leikepöydälle", "",5000)
    }

    return(
        <Col className="email-sender-container">
            <p>Voit kopioida tekstin <b>Kopioi viesti</b> -painiketta painamalla. Voit myös siirtyä työpöydän sähköpostisovellukseen valitsemalla <b>Siirry sähköpostiin</b> -painikkeen.</p>
            {/* // If sender required the commented code will work through that */}
            {/* <Row>
                <Col>
                    <Label className="">Lisää sähköposti</Label>
                    <Input className="email-input" placeholder={"esimerkki@email.com"} value={givenEmail} onChange={(e) => handleEmail(e.target.value)}/>
                    {givenEmail.length > 0 && 
                        <div className="email-check-box">
                            {emailOk?
                                <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                :
                                <i className="fas fa-times" style={{ color: 'red' }}></i> 
                            }
                        </div>
                    }
                    <button onClick={() => addEmail()} className="small-white-button wide-button add-email-button" style={{ marginTop: '0.5em' }}>Lisää sähköposti</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label className="content-label" style={{ marginBottom: '0.25em'}}>Vastaanottajat:</Label>
                    {emailList.length > 0 && emailList.map((email, key) => {
                        return(
                            <div className="email-list-row" key={key}><p>{"- " + email}</p><div style={{cursor:'pointer', marginLeft: '0.5em'}} onClick={()=> removeFromEmailList(key)}>
                            <i className="fas fa-times"></i>
                            </div></div>
                        )
                    })}
                </Col>
            </Row> */}
           
            {/* <Row>
                <Col>
                    <Label className="content-label">Sähköpostin otsikko</Label>
                    <Input className="email-topic"  value={emailTopic} onChange={(e) => setEmailTopic(e.target.value)}/>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <Label className="content-label">Viesti</Label>
                    <Input id="textarea" className="email-text" type="textarea" value={emailText} onChange={(e) => setEmailText(e.target.value)}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div onClick={() => copyToClipboard()} className="small-white-button wide-button send-email-button" style={{cursor: 'pointer', backgroundColor: "#6ba53f", fontWeight:'bold' }}>Kopioi viesti</div>
                    <div onClick={() => alternativeSender()} className="small-white-button wide-button send-email-button" style={{cursor: 'pointer', backgroundColor: "#6ba53f", fontWeight:'bold' }}>Siirry sähköpostiin</div>
                </Col>
            </Row>
        </Col>
    )
}
import * as React from "react";
import { motion, useCycle } from "framer-motion";
import './filter.css';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const subVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.02 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

const SubMenu = (props) => {
    
    return (
        <motion.ul variants={subVariants} className={props.className}>
            {props.subFilters.map(i => (
                <SubMenuItem i={{value: i}} key={i} filterItem={{value: props.mainItem, target: i}} handleSelection={ props.handleSelection } isInFilters={props.mainFilters.filter(item => item.target === i).length > 0}/>
            ))}
        </motion.ul>
    )
}

export const MenuItem = ({ i, handleSelection, mainFilters}) => {
  const [sideMenu, setSideMenuOpen] = useCycle(false, true);

  return (
    <motion.li
      variants={variants}
      className="dropdown-submenu"
      onMouseEnter={() => setSideMenuOpen()} 
      onMouseLeave={() => setSideMenuOpen()}
    >
    <div>
       <span className="mainItem">
          {i.value}
          <i className="fa fa-caret-right" style={{paddingLeft: '0.5em'}}></i>
        </span>
        {sideMenu && <SubMenu subFilters={i.filters} handleSelection={ handleSelection } mainItem={i.value} mainFilters={ mainFilters } className="dropdown-menu show subMenu"/>}
    </div>
    </motion.li>
  );
};

const SubMenuItem = ( {i, filterItem, handleSelection, isInFilters} ) => {
  return (
  <motion.li
      variants={variants}
      className={isInFilters ? "subMenuItem-disabled" : "subMenuItem"}
      onClick={() => handleSelection(filterItem)}
    >
    <div>
       <span className="subItem">
          {i.value}
        </span>
    </div>
    </motion.li>
  )
}
import React, { useState, useEffect } from 'react';
import Timeline, {TimelineHeaders, SidebarHeader, DateHeader, TimelineMarkers, TodayMarker, CustomMarker} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import './projectTimeline.css'
import moment from 'moment'
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Button, Input, Label } from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'
import { executeRequest } from '../requestHandler/dataHandler';
import { DateRangePicker } from 'react-dates';
import Select from 'react-select';
import { TrelloConnectionModal, TrelloModal } from './Trello/TrelloModal';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux'
import { TrelloCustomVariables, getTrelloColor } from './Trello/TrelloCustomVariables';

const valueList = [
  {value:5, label:5},
  {value:10, label:10},
  {value:15, label:15}
]

export const ProjectTimelineBuilder = ({projects, history}) => {
  const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])
  const [search, setSearch] = useState("")
  const [showMarkedOnly, setShowMarkedOnly] = useState(false)
  const [dateFilter, setDateFilter] = useState({ startDate: null, endDate:null})
  const [focusedInput, setFocusedInput] = useState()
  const [filteredGroups, setFilteredGroups] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [defaultStart, setDefaultStart] = useState(moment().add(-7, 'day'))
  const [defaultEnd, setDefaultEnd] = useState(moment().add(21, 'day'))
  const [calculatedHeight, setCalculatedHeight] = useState("250px")
  const [showAmount, setShowAmount] = useState({value:10, label:10})
  const [trelloModalOpen, setTrelloModalOpen] = useState(false)
  const [trelloSelected, setTrelloSelected] = useState(false)
  const [selectedTrelloBoard, setSelectedTrelloBoard] = useState({})
  const [selectedTrelloLists, setSelectedTrelloLists] = useState([])
  const [trelloItems, setTrelloItems] = useState([])
  const [trelloGroups, setTrelloGroups] = useState([])
  const [trelloCards, setTrelloCards] = useState([])
  const [buildingLoader, setBuildingLoader] = useState(false)
  const [onlyDeadlineCards, setOnlyDeadlineCards] = useState(false)
  const [filteredTrelloItems, setFilteredTrelloItems] = useState([])
  const [filteredTrelloGroups, setFilteredTrelloGroups] = useState([])
  const [sameDateItems, setSameDateItems] = useState([])
  const [sameDateModalOpen, setSameDateModalOpen] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [storedTrelloLists, setStoredTrelloLists] = useState([])

  const trelloConnection = useSelector((state) => state.authentication.trelloConnection)
  const currentEnterprise = useSelector((state) => state.authentication.enterpriseId)
  const modulesInUse = useSelector((state) => state.authentication.modulesInUse)

  useEffect(()=>{
    if(projects){
      buildTimeline(projects)
    }
  },[projects])

  const buildTimeline = (projectList) => {
    let finalGroups = []
    let finalItems = []
    projectList.map((project) => {
      let group = {id: project._id, title: project.projectName, target:project.targetBusiness.label}
      finalGroups = [...finalGroups, group]
      project.ganttPlan.phases.map((phase) => {
        phase.tasks.map((task) => {
          let item = {
            id: task.id + project._id,
            group: project._id,
            title: task.name,
            start_time:  moment(task.start).set("hours",1),
            end_time: moment(task.end).set("hours",23),
            marked: task.marked? task.marked : false,
            progress: task.progress
          }
          finalItems = [...finalItems, item]
        })
      })
    })
    setGroups(finalGroups)
    setItems(finalItems)
  }

  useEffect(()=>{
    calculateHeight()
  },[showAmount, filteredGroups, trelloSelected, selectedTrelloLists, buildingLoader, search, dateFilter, filteredTrelloGroups])

  const calculateHeight = () => {
    let height = 110
    if(modulesInUse && modulesInUse.trelloEnabled && trelloSelected){
      if(showAmount.value <= filteredTrelloGroups.length){
        height = (showAmount.value * 50 + 70)
      } else {
        if(filteredTrelloGroups.length > 0){
          height = (filteredTrelloGroups.length * 50 + 70)
        }
      }
    } else{
      if(showAmount.value <= filteredGroups.length){
        height = (showAmount.value * 50 + 70)
      } else {
        height = (filteredGroups.length * 50 + 70)
      }
    }
    
    setCalculatedHeight(height+'px')
    
  }

  
  const getTrelloCardData = async(payload) => {
    const response = await executeRequest('getTrelloCardData',payload)
    if(response.cardList){
      setSameDateItems(response.cardList)
    }
  }

  const handleSameDateModal = (incomingItems) => {
    if(trelloSelected){
      setSameDateItems([])
      let payload = {
        cardList: incomingItems,
        apiKey: trelloConnection.apiKey,
        apiToken: trelloConnection.apiToken
      }

      getTrelloCardData(payload)
      
    } else {
      setSameDateItems(incomingItems)
    }
    setSameDateModalOpen(true)
  }

  const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    // could make the coloring to represent the current status of the task => green completed, yellow ongoing, red deadline missed 
    let backgroundColor = "#f3be21";
    if(item.due){
      if(item.completed){
        backgroundColor = "#4bce97";
      } else {
        if(moment.utc(new Date(item.dueDate),"MMM D, YYYY hh:mm:ss ", "en").local().isBefore(moment.utc(new Date(),"MMM D, YYYY hh:mm:ss ", "en").local())){
          backgroundColor = "#f87168"
        }
      }
    }
    if(item.progress){
      if(item.progress === 100){
        backgroundColor = "#4bce97";
      }
    }
    let onSameDateLength = trelloSelected? filteredTrelloItems.filter((card) => itemsOnSameDateFilter("trello",card,item)).length : items.filter((obj) => itemsOnSameDateFilter("project", obj,item)).length
    const borderColor = "#bbb";
    return (
      <div
        {...getItemProps({
          style: {
            background:backgroundColor,
            color: item.color,
            borderColor: borderColor,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 4,
            opacity:1,
            fontWeight: "bold",
            zIndex:50
          }
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
          onClick={()=> (handleSameDateModal(trelloSelected? filteredTrelloItems.filter((card) => itemsOnSameDateFilter("trello",card,item)) : items.filter((obj) => itemsOnSameDateFilter("project", obj,item))))}
        >
          {itemContext.title}
          {onSameDateLength > 1 && 
            <div style={{position:'absolute', top:'0em', right:'0em', background:'red', borderRadius:'50px', zIndex:'8', padding:'0.15em 0.15em', height:'1.25em', border:'1px solid #bbb' }}>
              <p style={{marginTop: '-1.1em', fontSize: '0.8rem', fontWeight:'bold', color:'#fff'}}>{onSameDateLength}</p>
            </div>
          }
        </div>
       
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    )
  }

  const itemsOnSameDateFilter = (type, obj, item) => {
    if(type === 'trello' && (onlyDeadlineCards? obj.due===true : true) && obj.group === item.group && timeChecker(obj,item)) return true

    if(type === 'project' && (showMarkedOnly? obj.marked === true : true) && obj.group === item.group && timeChecker(obj,item)) return true
  }

  const timeChecker = (obj,item) => {
    if(moment(obj.start_time).isAfter(moment(item.start_time).set("hours",0)) && moment(obj.end_time).set("hours",22).isBefore(moment(item.end_time))){
      return true
    }
  }

  const goToProject = (projectId) => {
    history.push({
      pathname:'/projektit/hallitse',
      state: {
        projectId:projectId
      }
    })
  }

  const goToTrello = () => {
    let url = selectedTrelloBoard.url.toString()
    window.open( url, "_blank")
  }

  const onItemClick = (item) =>{
    let url = item.url.toString()
    window.open( url, "_blank")
  } 

  const groupRenderer = ({ group }) => {
    return (
      <div className="custom-group" style={{ cursor:'pointer'}} title={group.title} onClick={()=> (trelloSelected? goToTrello() : goToProject(group.id))}>
       <span style={{marginTop:'-10px', fontSize:'0.9rem', fontWeight:'bold'}} className="title">{group.title}</span>
       <span style={{marginTop:'-30px', fontSize:'0.7rem'}}>{group.target}</span>
       
      </div>
    )
  }

  const toggleMarked = () => {
    setShowMarkedOnly(!showMarkedOnly)
  }

  const changeFilterDates = (dates) => {
    const {startDate, endDate} = dates
    let dateFilter = {startDate: startDate, endDate: endDate}
    setDateFilter({...dateFilter})
    if(startDate && endDate){
      setDefaultStart(moment(startDate).add(-2, "day"))
      setDefaultEnd(moment(endDate).add(2,"day"))
    }
  } 

  const groupFilter = (group) => { 
    if(group.title.toLowerCase().includes(search.toLowerCase()) || (group.target && group.target.toLowerCase().includes(search.toLowerCase()))) return true 
    return false
  }

  const itemFilter = (item) => {
    if(item.title.toLowerCase().includes(search.toLowerCase())) return true

    return false
  }
  const filterItemDates = (item) => {
    if(dateFilter.startDate && dateFilter.endDate){
      if(moment(item.start_time).isAfter(moment(dateFilter.startDate)) && moment(item.end_time).isBefore(moment(dateFilter.endDate))) return true
      return false
    } else return true
  }

  const filterDates = (group) => {
    if(dateFilter.startDate && dateFilter.endDate){
      if(items.filter((item) => item.group === group.id && (moment(item.start_time).isAfter(moment(dateFilter.startDate)) && moment(item.end_time).isBefore(moment(dateFilter.endDate)))).length > 0) return true
      return false
    } else return true
  }
  const filterMarked = (group) => {
    if(showMarkedOnly){
      if(items.filter((item) => item.group === group.id && item.marked === true).length > 0) return true
    } else return true
  }

  useEffect(()=>{
    if(!trelloSelected){
      let filtered = items.filter((item) => itemFilter(item) && filterItemDates(item))
      setFilteredItems(filtered)
      setFilteredGroups(groups.filter((group) => filtered.filter((item) => item.group === group.id).length > 0 || (filterDates(group) && groupFilter(group) && filterMarked(group))))
    }
  },[search, dateFilter, showMarkedOnly, groups])

  // useEffect(()=>{
  //   setFilteredTrelloGroups(trelloGroups.filter((group) => filterTrelloDates(group) && groupFilter(group) && filterDeadline(group)))
  // },[search, dateFilter, trelloGroups, onlyDeadlineCards,buildingLoader])

 useEffect(()=>{
    if(trelloSelected){
      let filtered = trelloItems.filter((item) => filterTrelloDates(item) && trelloItemFilter(item) && filterDeadline(item))
      setFilteredTrelloItems(filtered)
      setFilteredTrelloGroups(trelloGroups.filter((group) => filtered.filter((item) => item.group === group.id).length > 0 || filterTrelloDates(group) && groupFilter(group) && filterDeadline(group)))
    }
  },[search, dateFilter, onlyDeadlineCards,buildingLoader, trelloGroups])

  const trelloItemFilter = (item) => {

    if(memberFilter(item)) return true
    
    if(item.title.toLowerCase().includes(search.toLowerCase())) return true

    return false
  }
  const memberFilter = (item) => {
    // Pitää fixata logiikka, jolla filtteröi oikein halutun nimen
    if(item.memberList && item.memberList.length > 0){
      if(item.memberList.filter((member) => member.fullName.toLowerCase().includes(search.toLowerCase())).length > 0){
          return true
        }
      
    } else return false
  }

  const filterDeadline = (item) => {
    if(onlyDeadlineCards){
      if(item.due === true) return true
      return false
    } else return true
  }
  
  const filterTrelloDates = (item) => {
    if(dateFilter.startDate && dateFilter.endDate){
      if(moment(item.start_time).isAfter(moment(dateFilter.startDate)) && moment(item.end_time).isBefore(moment(dateFilter.endDate))) return true
      return false
    } else return true
  }

  const handleDateReset = () => {
    setDateFilter({startDate: '', endDate:''})
    setDefaultStart(moment().add(-7, 'day'))
    setDefaultEnd(moment().add(21, 'day'))
  }

  const handleShowAmountChanged = (value) => {
    setShowAmount(value)
    window.localStorage.setItem('projectTimelineShowAmount', JSON.stringify(value)) 
  }

  useEffect(()=>{
    getAmountFromStorage()
    getStoredTrello()
  },[])

  const getAmountFromStorage = () => {
    let storedValue = JSON.parse(window.localStorage.getItem('projectTimelineShowAmount'))
    if(storedValue){
      setShowAmount(storedValue)
    }
  }

  const toggleTrelloModal = () => {
    setTrelloModalOpen(!trelloModalOpen)
  }

  const toggleTrelloSelected = () => {
    setTrelloSelected(!trelloSelected)
  }

  const handleSelectedTrelloLists = (list) => {
    setSelectedTrelloLists(list)
    
  }

  useEffect(()=>{
    if(trelloCards.length > 0 && selectedTrelloLists.length > 0){
      buildTrelloTimeline(selectedTrelloLists)
    } 
  },[trelloCards, selectedTrelloLists])

  const buildTrelloTimeline = (list) => {
    let builder = buildTrelloGroups(list, trelloCards, storedTrelloLists)
    if(builder.trelloGroups){
      setTrelloGroups(builder.trelloGroups)
    } 
    if(builder.trelloItems){
      setTrelloItems(builder.trelloItems)
    }
    setBuildingLoader(false)
  }

  const saveTrelloToStorage = () => {
    let storedTrello = JSON.parse(window.localStorage.getItem('trelloTimeline'))
    let trelloObject = {}

    if(storedTrello){
     trelloObject = {...storedTrello}
    }
   
    trelloObject = {
      ...trelloObject,
      [currentEnterprise]:{
        trelloSelected:trelloSelected,
        selectedTrelloBoard: selectedTrelloBoard,
        onlyDeadlineCards: onlyDeadlineCards,
        selectedTrelloLists: selectedTrelloLists
      }
    }

    window.localStorage.setItem('trelloTimeline', JSON.stringify(trelloObject)) 
    NotificationManager.success("Trelloasetukset tallennettu onnistuneesti","",3000)
    toggleTrelloModal()
  }

  const getStoredTrello = () => {
    let storedTrello = JSON.parse(window.localStorage.getItem('trelloTimeline'))

    if(trelloConnection && trelloConnection.apiKey && trelloConnection.apiToken){
      if(storedTrello){
        if(storedTrello[currentEnterprise]){
          let storedData = storedTrello[currentEnterprise]
          if(storedData.trelloSelected){
            setTrelloSelected(storedData.trelloSelected)
          }
          if(storedData.trelloSelected){
            setSelectedTrelloBoard(storedData.selectedTrelloBoard)
            setBuildingLoader(true)
          }
          if(storedData.onlyDeadlineCards){
            setOnlyDeadlineCards(storedData.onlyDeadlineCards)
          }
          if(storedData.selectedTrelloLists){
            setStoredTrelloLists(storedData.selectedTrelloLists)
          }
        }
        else{
          if(storedTrello.trelloSelected){
            setTrelloSelected((modulesInUse && modulesInUse.trelloEnabled)? storedTrello.trelloSelected: false)
          }
          if(storedTrello.trelloSelected){
            setSelectedTrelloBoard(storedTrello.selectedTrelloBoard)
            setBuildingLoader(true)
          }
          if(storedTrello.onlyDeadlineCards){
            setOnlyDeadlineCards(storedTrello.onlyDeadlineCards)
          }
        }
      }
    } 
    //updateTrelloList()
  }

  const updateTrelloList = () => {
    if(trelloConnection){
      setSelectedTrelloBoard(selectedTrelloBoard)
      setForceUpdate(!forceUpdate)
      setBuildingLoader(true)
    } else {
      NotificationManager.error("Trellon API-avainta ja -tokenia ei ole asetettu", "Virhe", 3000)
    }
  }

  return(
    <Col xs="12" style={{ marginTop:'2em' }}>
      <MultipleItemsModal
        isOpen={sameDateModalOpen}
        sameDateItems={sameDateItems}
        toggle={setSameDateModalOpen}
        trelloSelected={trelloSelected}
      />
      <TrelloModal 
        isOpen={trelloModalOpen} 
        toggle={toggleTrelloModal}
        handleTrelloSelected={toggleTrelloSelected}
        trelloSelected={trelloSelected}
        handleSelectedTrelloBoard={setSelectedTrelloBoard}
        handleSelectedTrelloLists={handleSelectedTrelloLists}
        selectedTrelloBoard={selectedTrelloBoard}
        saveTrelloToStorage={saveTrelloToStorage}
        onlyDeadlineCards={onlyDeadlineCards}
        setOnlyDeadlineCards={setOnlyDeadlineCards}
        forceUpdate={forceUpdate}
        setTrelloCards={setTrelloCards}
        storedTrelloLists={storedTrelloLists}
      />
      <div style={{ marginBottom:'0.25em', display:'flex', width:'100%' }}>
        <Label style={{fontWeight:'bold', fontSize:'1.25rem'}}>{(modulesInUse && modulesInUse.trelloEnabled && trelloSelected)?"Trelloaikajana" :"Projektitehtävät aikajanalla"}</Label>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '3em', fontSize:'0.9rem' }}>
            <Input type="search" className="search-bar-open" placeholder={"Hae"} value={search} onChange={(event) => setSearch(event.target.value)} />
        </div>
        <div style={{ marginLeft:'1em', marginTop:'0.1em', display:'flex' }}>
          <DateRangePicker
            startDate={dateFilter.startDate} // momentPropTypes.momentObj or null,
            startDateId="unique_start_" // PropTypes.string.isRequired,
            startDatePlaceholderText="Alku pvm"
            endDate={dateFilter.endDate} // momentPropTypes.momentObj or null,
            endDateId="unique_enddate_" // PropTypes.string.isRequired
            endDatePlaceholderText="Loppu pvm"
            onDatesChange={({ startDate, endDate }) => changeFilterDates({ startDate: startDate, endDate: endDate })} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            isOutsideRange={() => false}
            block
            small
            hideKeyboardShortcutsPanel={true}
            // showClearDates  
          /> 
          <div className="small-white-button" style={{ height:'2.28em', fontWeight:'normal', fontSize:'0.9rem', marginLeft:'-0.5em', marginTop:'0.5px', borderRadius:'0 5px 5px 0', cursor:'pointer'}} onClick={()=> handleDateReset()}>Tyhjennä</div>
        </div>
        {!trelloSelected && 
          <div className={"small-white-button timeline-div-button " + (showMarkedOnly && "active-button")} title={showMarkedOnly? "Näytä kaikki" : "Näytä milestone"} style={{ marginLeft:'1em', padding:'0em 0.5em'}} onClick={()=> toggleMarked()}>
            <i className="fas fa-thumbtack"></i>
          </div>
        }
        {modulesInUse && modulesInUse.trelloEnabled &&
          <div style={{ marginLeft:'1em'}}>
            <div className="small-white-button timeline-div-button" title="Trello-asetukset" style={{padding:'0.25em 0.4em'}} onClick={()=> setTrelloModalOpen(true)}><i className="fab fa-trello"></i></div>
          </div>
        }
        {trelloSelected &&
          <div style={{ marginLeft:'1em'}}>
            <div className="small-white-button timeline-div-button" title="Päivitä" style={{padding:'0.25em 0.4em'}} onClick={()=> updateTrelloList()}><i className="fas fa-sync-alt"></i></div>
          </div>
        }
        <div style={{ display:'flex', marginLeft:'auto', marginRight:'-1em'}}>
          <Label style={{ marginTop:'0.5em'}}>Näytä näkymässä (max): </Label>
          <div style={{ zIndex:'10', display:'flex', width:'6em'}}>
            <Select  
              options={valueList} 
              onChange={(val)=> handleShowAmountChanged(val)}
              value={showAmount}
              isDisabled={false}
              noOptionsMessage={() => { return "Yrityksiä ei löytynyt" }}
              className="dashboard-survey-selector timeline-amount-selector"
              classNamePrefix="select"
            />
          </div>
        </div>
        {/* <div className="small-white-button timeline-div-button" title="Asetukset" style={{marginLeft:'0.75em'}}><i className="fas fa-cog"></i></div> */}
      </div>
      <div className="project-timeline-container" >
        <Scrollbars  
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
          style={{ width: "100%", height:calculatedHeight}}      
        >
          {(modulesInUse && modulesInUse.trelloEnabled && trelloSelected)? 
            <>
              {buildingLoader?
                <MegaLoaderSpinner/>
                :
                <Timeline
                  groups={filteredTrelloGroups}
                  items={filteredTrelloItems}
                  defaultTimeStart={ defaultStart}
                  defaultTimeEnd={ defaultEnd}
                  showCursorLine={true}
                  itemHeightRatio={0.75}
                  lineHeight={50}
                  canMove={false}
                  canResize={false}
                  minZoom={(7*24*60*60*1000)}
                  itemRenderer={itemRenderer}
                  groupRenderer={groupRenderer}
                  onItemSelect={()=>{return}}
                >
                  <TimelineHeaders className="project-timeline-sticky">
                    <SidebarHeader>
                      {({ getRootProps }) => {
                        return <div {...getRootProps()} style={{ fontWeight:'bold', padding:'0.5em', width:'9.4em' }}><span>{selectedTrelloBoard.label}</span></div>;
                      }}
                    </SidebarHeader>
                    <DateHeader unit="primaryHeader" />
                    <DateHeader 
                    intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
                      return <div {...getIntervalProps()} className={'lower-date-time-div '+ (moment().isAfter(intervalContext.interval.startTime) && moment().isBefore(intervalContext.interval.endTime) && "date-is-today")}>
                        {intervalContext.intervalText}
                      
                      </div>
                    }}
                    />
                  </TimelineHeaders>
                  <TimelineMarkers>
                    <CustomMarker date={moment().add(0, 'day')}>
                            {({ styles, date }) => {
                      const customStyles = {
                        ...styles,
                        backgroundColor: '#e90101',
                        width: '3px',
                        zIndex:3
                      }
                      return <div style={customStyles} />
                    }}
                    </CustomMarker>
                  </TimelineMarkers>
                </Timeline>
              }
            </>
            :
            <>
              {groups.length > 0 && items.length > 0 &&
                <Timeline
                  groups={filteredGroups}
                  items={filteredItems}
                  defaultTimeStart={ defaultStart}
                  defaultTimeEnd={ defaultEnd}
                  showCursorLine={true}
                  itemHeightRatio={0.75}
                  lineHeight={50}
                  canMove={false}
                  canResize={false}
                  minZoom={(7*24*60*60*1000)}
                  itemRenderer={itemRenderer}
                  groupRenderer={groupRenderer}
                  onItemSelect={()=>{return}}
                >
                  <TimelineHeaders className="project-timeline-sticky">
                    <SidebarHeader>
                      {({ getRootProps }) => {
                        return <div {...getRootProps()} style={{ fontWeight:'bold', padding:'0.5em', width:'9.4em' }}><span>Projekti / tehtävät</span></div>;
                      }}
                    </SidebarHeader>
                    <DateHeader unit="primaryHeader" />
                    <DateHeader 
                    intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
                      return <div {...getIntervalProps()} className={'lower-date-time-div '+ (moment().isAfter(intervalContext.interval.startTime) && moment().isBefore(intervalContext.interval.endTime) && "date-is-today")}>
                        {intervalContext.intervalText}
                      
                      </div>
                    }}
                    />
                  </TimelineHeaders>
                  <TimelineMarkers>
                    
                    <CustomMarker date={moment().add(0, 'day')}>
                            {({ styles, date }) => {
                      const customStyles = {
                        ...styles,
                        backgroundColor: '#e90101',
                        width: '3px',
                        zIndex:3
                      }
                      return <div style={customStyles} />
                    }}
                    </CustomMarker>
                  </TimelineMarkers>
                </Timeline>
              }
            </>
          }
        </Scrollbars>
      </div>
    </Col>
  )
}

// handle cards separately
// vrt haku trellokappale (plan)
const buildTrelloGroups = (lists, cards, storedTrelloLists) => {
  let finalGroups = []
  let finalItems = []

  lists.map((list)=>{
    if(list.selected){
      let group = {id: list.id, title: list.name}
      finalGroups.push(group)
    }
  })

  cards.map((card)=>{
      let item = {
        id:card.id,
        group: card.idList,
        title: card.name,
        start_time:  card.start?  moment(card.start).set("hours",1) :(card.due? moment(card.due).set("hours",1) : moment(card.dateLastActivity).set("hours",1)),
        end_time: card.due? moment(card.due).set("hours",23) : moment(card.dateLastActivity).set("hours",23),
        due: card.due? true: false,
        dueDate: card.due? card.due : false,
        completed: card.dueComplete,
        url:card.shortUrl,
        memberList: card.memberList? card.memberList : [],
        idMembers: card.idMembers ? card.idMembers : [],
        idChecklists: card.idChecklists? card.idChecklists : [],
        checklists: card.checklists? card.checklists : [],
        checkItemStates: card.checkItemStates? card.checkItemStates : [],
        labels: card.labels? card.labels : []
      }
      finalItems.push(item)
  })     

  return({trelloGroups: finalGroups, trelloItems: finalItems})
}

const MegaLoaderSpinner = () => {
  return (
      <Row>
          <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '5rem', height: '5rem', marginTop: '0rem' }} className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
              </div>
          </Col>
      </Row>
  )
}

const MultipleItemsModal = ({sameDateItems, isOpen, toggle, trelloSelected}) => {
  const onItemClick = (item) =>{
   
  } 
  const goToTrello = (item) => {
    if(item.url){
      let url = item.url.toString()
      window.open( url, "_blank")
    }
  }

  const getCheckItemProgress = (item) => {
    let totalItems = 0
    let done = 0
    if(item.checkItemStates){
      done = item.checkItemStates.length
    }
    item.checklists.map((list)=>{
      totalItems = (totalItems + list.checkItems.length)
    })

    return (done + '/' + totalItems)

  }

  const handleToggle = () => {
    toggle(!isOpen)
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={()=> handleToggle()}>
        <ModalHeader toggle={()=> handleToggle()}>Samanaikaiset tehtävät</ModalHeader>
        <ModalBody>
          <Col>
            {sameDateItems && sameDateItems.length > 0 &&
              <Row>
                <Col>
                  <Scrollbars  
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height:'500px'}}      
                  >
                    {sameDateItems.map((item, key) => {
                      let backgroundColor = "#f3be21";
                      let color = "#404040"
                      if(item.due){
                        if(item.completed){
                          backgroundColor = "#4bce97";
                        } else {
                          if(moment.utc(new Date(item.dueDate),"MMM D, YYYY hh:mm:ss ", "en").local().isBefore(moment.utc(new Date(),"MMM D, YYYY hh:mm:ss ", "en").local())){
                            backgroundColor = "#f87168"
                            color = '#fff'
                          }
                        }
                      } 
                      if(item.progress){
                        if(parseInt(item.progress) === 100){
                          backgroundColor = "#4bce97";
                        } else {
                          backgroundColor = "#f3be21";
                        }
                      }
                      return(
                        <div key={key} className="timeline-modal-card" onClick={() => onItemClick(item)}>
                          <div style={{display:'flex'}}>
                            {item.labels && item.labels.map((label,index)=>{
                              let colors = getTrelloColor(label.color)
                              return(
                                <div key={index} className="timeline-trello-label" style={{ color:colors.color, backgroundColor: colors.backgroundColor}}>{label.name}</div>
                              )
                            })}
                            {trelloSelected && <button className="small-white-button" style={{ marginLeft:'auto', height:'1.75em', padding:'0 0.25em'}} title="Siirry korttiin" onClick={()=> goToTrello(item)}><i className="fab fa-trello"></i></button>}
                          </div>
                          <div style={{display:'flex'}}>
                            <div style={{ fontWeight:'bold'}}>{item.title}</div>
                          </div>
                          <div style={{display:'flex', marginTop:'0.5em'}}>
                            {item.memberList && item.memberList.length > 0 && item.memberList.map((member, key)=>{
                              return(
                                <div className="member-tag" key={key}>{member.fullName}</div>
                              )
                            })}
                          </div>
                          <div style={{display:'flex'}}>
                            <div>{moment(item.start_time).format("DD.MM.YYYY")}</div>
                            {item.checklists && item.checklists.length > 0 &&
                              <div style={{ marginLeft:'0.5em'}}>
                                    <div className="timeline-card-duedate" style={{padding:'0.1em 0.2em', marginTop:'0.25em'}}>
                                      <i className="far fa-check-square" style={{ marginRight:'0.25em', color:'#6ba53f'}}></i> 
                                      <div>{getCheckItemProgress(item)}</div>
                                    </div>
                              </div>
                            }
                            {item.due && 
                              <div className="timeline-card-duedate" style={{backgroundColor:backgroundColor, color:color}}>
                                <i className="far fa-clock" style={{marginRight:'0.25em', marginTop:'0.15em'}}></i> 
                                <p>{moment.utc(item.dueDate).local().format("DD.MM.YYYY HH:mm")}</p>
                              </div>
                            }
                            {item.progress !== undefined &&
                              <div className="timeline-card-duedate" style={{backgroundColor:backgroundColor, color:color}}>
                                <i className="far fa-clock" style={{marginRight:'0.25em', marginTop:'0.15em'}}></i>
                              </div>
                            } 
                          
                          </div>
                        </div>
                      )
                    })}
                  </Scrollbars>
                </Col>
              </Row>
            }
          </Col>
        </ModalBody>
        <ModalFooter>
          <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={()=> handleToggle()}>Sulje</a>
        </ModalFooter>
      </Modal>      
    </>
  )
}

import React, { Component } from 'react';
import {Row, Col, ListGroup} from 'reactstrap';
import MainItem from '../../../../Reusables/MainItem';
import SubButton from '../../../Buttons/SubButton';
import AddButton from '../../../Buttons/AddButton';
import {connect} from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import * as ActionCreator from '../../../../../../store/index';

class GroupList extends Component {
    constructor(props) {
        super(props);
        super(props);
        this.state = {
            category: null,
            activeCategory: null,
            activeQuestion: null,
            activeQuestionIndex: null,
            activeMenu: -1,
            groupModal: false,
            questionModal: false,
            optionModal: false,
            payload: {}
        }

        this.setGroup = this.setGroup.bind(this);
        this.menuToggler = this.menuToggler.bind(this);
        this.openGroupModal = this.openGroupModal.bind(this);
        this.proceedToDelete = this.proceedToDelete.bind(this);
    }

    componentDidMount() {
        this.setState({category: this.props.currentCategory})
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.currentCategory !== this.props.currentCategory) {
            this.setState({category: this.props.currentCategory})
        }
    }

    setGroup = (x) => {
        this.setState({activeCategory: x, activeQuestion: null});
    }

    menuToggler(index) {
        if(index === this.state.activeMenu) {
            this.setState({activeMenu: -1});
        } else {
            this.setState({activeMenu: index});
        }
    }

    proceedToDelete() {
        if(Object.keys(this.state.payload).length > 0) {
            this.setState({groupModal: false});
            this.props.deleteGroup(this.state.payload);
        }
    }

    onDragEnd = result => {
        const { destination, source } = result

        if(!destination) { return }
        if(destination.droppableId === source.droppableId &&
        destination.index === source.index
        ) {
            return
        }
        
        /*if(this.state.activeCategory !== null && parseInt(destination.droppableId) !== this.state.activeCategory && source.index === this.state.activeQuestionIndex) {
            this.setState({
                activeCategory: parseInt(destination.droppableId)
            })
        }*/

        let surveyObj = this.state.category
        const movedObj = surveyObj.questionGroups[source.droppableId].questions[source.index]

        surveyObj.questionGroups[source.droppableId].questions.splice(source.index, 1)
        surveyObj.questionGroups[destination.droppableId].questions.splice(destination.index, 0, movedObj)

        this.setState({
            category: surveyObj
        })
        this.props.onQuestionReorder(surveyObj)
    }

    moveGroup = (groupIndex, change) => {
        if( !(groupIndex === 0 && change < 0) && !(groupIndex === this.state.category.questionGroups.length - 1 && change > 0) ) {
            let surveyObj = this.state.category
            const movedObj = surveyObj.questionGroups[groupIndex]

            surveyObj.questionGroups.splice(groupIndex, 1)
            surveyObj.questionGroups.splice(groupIndex + change, 0, movedObj)

            this.setState({
                category: surveyObj
            }, () => this.props.onQuestionReorder(surveyObj))
        }
    }

    copyGroup = (args) => {
        const { groupIndex } = args
        
        const survey = JSON.parse(JSON.stringify(this.state.category))
        const questionGroups = JSON.parse(JSON.stringify(survey.questionGroups))
        let group = questionGroups[groupIndex]

        // delete id's from the questionGroup, all it's questions and questionOptions so no accidents happen
        delete group.id
        group.title += " -kopio"

        group.questions.map((question) => {
            delete question.id

            return question.questionOptions.map((questionOption) => delete questionOption.id)
        })
        
        const params = {
            surveyId: survey._id,
            group: group
        }

        this.props.onQuestionGroupCopy(params)
    }

    render() {
        return (
            <Col>
                {this.state.category !== null && this.state.category._id !== 'undefined' && 
                <Row>
                    <Col>
                        <div block="true" className= "general-button-report"  onClick={() => this.props.getDocument(this.state.category)}><i className="material-icons">print</i></div>
                    </Col>
                </Row>
                }
                <Row className="itemContainer_Title" style={{cursor: 'pointer'}} onClick={() => this.setGroup(null)}>
                    <Col><h3 onClick={typeof this.state.category !== 'undefined' ? () => this.props.categoryEdit() : null}>{typeof this.state.category.title !== 'undefined' ? this.state.category.title : "Aloita tallentamalla kategoria"}</h3></Col>
                </Row>
                <Row className="Pedro" style={{marginBottom: 40}}>
                    <Col>
                        <ListGroup>
                        
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            {(typeof this.state.category !== 'undefined' && typeof this.state.category.questionGroups !== 'undefined') &&
                                this.state.category.questionGroups.map((categoryGroup, y) =>
                                <div key={y}>
                                    <MainItem
                                        text={categoryGroup.title}
                                        groupIndex={y}
                                        group={categoryGroup}
                                        categoryId={this.state.category._id}
                                        activeGroup={this.props.currentQuestionGroup}
                                        setActiveGroup={this.setGroup}
                                        handleClick={this.props.setGroupData}
                                        setData={this.props.onQuestionGroupSelected}
                                        activeIndex={this.state.activeMenu}
                                        openMenu={this.menuToggler}
                                        deleteGroup={this.openGroupModal}
                                        moveGroup={this.moveGroup}
                                        copyGroup={this.copyGroup}
                                    />
                                    {/* <Col className='group-column' sm={{ size: 'auto', offset: 1 }} style={{}}> */}
                                    
                                    {/* Droppables are all of different type to prevent moving questions from one group to another */}
                                    <Droppable droppableId={y.toString()} type={`question-${y}`}>
                                        {(provided, snapshot) => (
                                            <div
                                            ref={provided.innerRef}
                                            className={snapshot.isDraggingOver ? 'group-column col-sm-auto offset-sm-1 dragging-over' : 'group-column col-sm-auto offset-sm-1'}
                                            {...provided.droppableProps}
                                            >
                                                {typeof categoryGroup.questions !== 'undefined' &&
                                                    categoryGroup.questions.map((subgroup, x) => {         
                                                        return <SubButton 
                                                        text={subgroup.text}
                                                        id={subgroup.id}
                                                        target={subgroup}
                                                        key={x} 
                                                        startEdit={this.props.setQuestionData} 
                                                        activeCategory={this.state.activeCategory}
                                                        activeQuestion={this.props.currentQuestion}
                                                        categoryGroup={categoryGroup} 
                                                        groupIndex={y}
                                                        questionIndex={x}
                                                        /> 
                                                    })
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                    
                                    <AddButton setQuestionData={this.props.setQuestionData} group={categoryGroup} index={y} text="Uusi Kysymys" />
                                    {/* </Col> */}
                                </div>
                            )}
                            </DragDropContext>
                            {typeof this.state.category.title !== 'undefined' ? <MainItem add="Hublahubla" style={{paddingTop: 50}} handleClick={this.props.setGroupData} setData={this.props.onQuestionGroupSelected}/> : null}
                            
                         </ListGroup>
                    </Col>
                </Row>
                </Col>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onQuestionGroupEdit: (group) => dispatch(ActionCreator.questionGroupView(group)),
        onQuestionGroupSelected: (group) => dispatch(ActionCreator.setGroup(group)),
        onQuestionEdit: (question) => dispatch(ActionCreator.startEditingQuestion(question)),
        onQuestionAdd: () => dispatch(ActionCreator.newQuestion()),
        onQuestionGroupCopy: (params) => dispatch(ActionCreator.copyQuestionGroup(params))
    }
}

const mapStateToProps = state => {
    return {
        currentCategory: state.kartoitus.currentCategory,
        currentQuestion: state.kartoitus.currentQuestion,
        currentQuestionGroup: state.kartoitus.currentQuestionGroup
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
import React, { Component } from 'react'
import { Row, Col, Input } from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'
import { NotificationManager } from 'react-notifications'
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar'
import moment from 'moment';

export default class Users extends Component {
  constructor() {
    super()

    this.state = {
      filter: '',
      emailEdited: false,
      editIndex: null,
      userToEdit: null,
      newUser: null,
      userList: []
    }

    this.itemRef = React.createRef()
  }

  componentDidMount() {
    let list = [
      ...this.props.users
    ]

    this.setState({ userList: list })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      let list = [
        ...this.props.users
      ]

      this.setState({ userList: list })
      this.setState({ editIndex: null, userToEdit: null, newUser: null })
    }
  }

  toggleEdit = (user, y) => {
    if (this.state.editIndex === y) {
      this.setState({ editIndex: null, userToEdit: null, newUser: null, emailEdited: false })
    } else {
      this.setState({ editIndex: y })
      this.setState({ userToEdit: user })
    }
  }

  handleField = (field, value) => {
    let object = {
      ...this.state.userToEdit
    }

    object[field] = value

    const emailEdited = ( field === 'email' || this.state.emailEdited ) ? true : false

    this.setState({ userToEdit: object, emailEdited: emailEdited })
  }

  createUser = () => {
    const newuser = { email: '', phone: '' }

    this.itemRef.current.scrollTop()
    this.setState({ newUser: newuser, userToEdit: newuser, editIndex: 0 })
  }

  handleSave = ( sendNewCode = false ) => {
    const emailCheck = /[^@]+@[^\.]+\..+/
    let editeduser = this.state.userToEdit
    editeduser.sendNewLoginToken = sendNewCode

    if (emailCheck.test(editeduser.email)) {
      this.props.save(editeduser)
    } else {
      NotificationManager.error('Tarkista sähköpostiosoite!', 'Virhe', 2000)
    }
  }

  render() {
    const { filter, emailEdited, newUser, editIndex, userList, userToEdit } = this.state

    return (
      <Col xl={{ size: 12 }} className="enterprise-box">
        <Row>
          <Col>
            <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
              <p>Käyttäjät</p>
              <BoxSearchBar placeholder='Etsi käyttäjää' value={ filter } onChange={( val ) => this.setState({ filter: val }) } />
              <div style={{ marginLeft: 'auto', marginRight: '1em' }}>
                <button className="small-white-button lighter-text wide-button" onClick={this.createUser}>Lisää uusi</button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <Scrollbars
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
              style={{ width: "100%", height: '500px' }}
              ref={this.itemRef}
              className="custom-scrollbars">
              {userList && userList.map((user, y) => {
                if ((user.email.toLowerCase().includes(filter.toLowerCase())) || (user.phone.toLowerCase().includes(filter.toLowerCase()))) {
                  let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row'

                  return <Row key={y} className={oddEvenClass + ' list-row '}>
                    <Col xs="12">
                      <Row>
                        <Col xs="8">
                          {editIndex === y ?
                            <div style={{ lineHeight: '0.2em' }}>
                              <p style={{ paddingBottom: '1.3em', paddingTop: '0.6em' }}>{newUser ? 'Luo uusi käyttäjä' : 'Muokkaa käyttäjää'}</p>
                              <label className="lighter-text" >Sähköposti</label>
                              <Input className="input-bar" type="text" name="email" value={userToEdit.email} style={{ width: "100%" }} onChange={(e) => this.handleField("email", e.target.value)}></Input>
                            </div>
                            :
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <span>{user.email}</span>
                                <span>{user.lastLogin && moment.utc( user.lastLogin, 'MMM D, YYYY h:m:s A', 'en' ).local().format( 'DD.MM.YYYY HH:mm' )}</span>
                            </div>
                          }

                        </Col>
                        <Col xs="4" style={{ textAlign: 'right' }}>
                          <div style={{ marginLeft: 'auto' }}>
                            <button className="small-white-button lighter-text wide-button" onClick={() => this.toggleEdit(user, y)}>{editIndex === y ? 'Peruuta' : 'Muokkaa'}</button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="8">
                          {editIndex === y ?
                            <div style={{ lineHeight: '0.2em', marginTop: '1em' }}>
                              <label className="lighter-text" >Puhelinnumero</label>
                              <Input className="input-bar" name="phone" type="text" value={userToEdit.phone} style={{ width: "100%" }} onChange={(e) => this.handleField("phone", e.target.value)}></Input>
                            </div>
                            :
                            <p className="lighter-text">{user.phone}</p>
                          }
                        </Col>
                      </Row>
                      {editIndex === y &&
                        <Row>
                          <Col xs="12" style={{ marginTop: '1em', marginBottom: '0.2em' }}>
                            <button className="small-white-button lighter-text wide-button" onClick={() => this.handleSave(false)} >Tallenna</button>
                            {(emailEdited && !newUser) && <button className="small-white-button lighter-text wide-button" onClick={() => this.handleSave(true)} style={{ marginLeft: '0.5rem' }} >Tallenna ja lähetä kirjautumiskoodi</button>}
                          </Col>
                        </Row>
                      }
                    </Col>
                  </Row>
                }
                return null
              })}
              {userList.length === 0 &&
                <Col style={{ marginTop: '1em' }}>
                  <span>Käyttäjiä ei löytynyt</span>
                </Col>
              }
            </Scrollbars>
          </Col>
        </Row>
      </Col>
    )
  }
}

import React, { useState, Fragment } from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col} from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';

export const AttachmentAnswersModal = ({isOpen, toggleModal, answerSessionIds, attachmentAnswers}) => {
    const [selected, setSelected] = useState()
    const [selectedFile, setSelectedFile] = useState({})
    const [fileOptionsModal, setFileOptionsModal] = useState(false)
    const [fileEnd, setFileEnd] = useState()

    const closeModal = () => {
        setSelected(answerSessionIds)
        toggleModal()
    }

    const onOptionButtonClick = (answer) => {
        if(answer && answer.name){
            setSelectedFile(answer)
            let array = answer.name.split('.')
            let end = array[(array.length -1 )]
            setFileEnd(end) 
            setFileOptionsModal(true)
        }
    }

    const downloadFile = async(file, preview=false) => {
        let payload = {
            fileId: file.fileId,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const response = await executeRequest('plan/downloadFile', payload)
        if(response.data){
            try{
                var blob = new Blob([new Uint8Array(response.data.data)], { type: response.mimeType });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.name + "." + response.fileEnd;
        
                document.body.appendChild(link);
                if(preview){
                    window.open(link)
                }else{
                    link.click();
                }
        
                document.body.removeChild(link);

            } catch {}
        }       
    }

    return(
        <>
            <Modal isOpen={fileOptionsModal} toggle={()=> setFileOptionsModal(false)}>
                <ModalHeader toggle={()=> setFileOptionsModal(false)}> <p className="modal-name-overflow-handler" style={{ color: '#9a0707' }}>{selectedFile.name}</p></ModalHeader>
                <ModalBody>
                    {fileEnd && (fileEnd.toLowerCase() === "jpg" || fileEnd.toLowerCase() === "jpeg" || fileEnd.toLowerCase() === "png" || fileEnd.toLowerCase() === "svg" || fileEnd.toLowerCase() === "bmp" || fileEnd.toLowerCase() === "pdf") &&
                        <button onClick={() => downloadFile(selectedFile, true)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>Esikatsele</button>
                    }   
                    <button onClick={() => downloadFile(selectedFile)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>Lataa</button>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setFileOptionsModal(false)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                </ModalFooter>
            </Modal>
            <Modal isOpen={isOpen} toggle={() => closeModal()} centered={true} className="freetext-answers-modal">
                <ModalHeader toggle={() => closeModal() }>
                    {  } 
                    <p style={{}}> Voit ladata tiedoston painamalla "Liitteen valinnat" -painiketta ja valitsemalla "Lataa"</p>
                </ModalHeader>
                <ModalBody>
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '500px', paddingBottom: '1rem' }}
                        className="custom-scrollbars" >

                        {attachmentAnswers && Object.keys(attachmentAnswers).map((key, index) => {
                            let answerStack = attachmentAnswers[key]

                            return (
                                <Fragment key={index}>
                                    <Row>
                                        <Col>
                                            <h5><b>{answerStack.question}</b></h5>
                                        </Col>
                                    </Row>

                                    {answerStack.answers && answerStack.answers.map((answer, index) => {
                                        let className = index % 2 === 0 ? 'even-row' : 'odd-row'
                                        if(answer.files && answer.files.length > 0){
                                            return(
                                                <Col xs="12" key={index}>
                                                    {answer.files.map((file, key)=>{
                                                        return (    
                                                            <Row key={key} className={className} style={{padding: '1rem'}}>
                                                                <Col xs={{size: 2}}>
                                                                    {moment.utc(answer.time).format('D.M.YYYY  HH.mm')}
                                                                </Col>
                                                                <Col xs={{size: 8}}>
                                                                    {file.name}
                                                                </Col>
                                                                <button onClick={() => onOptionButtonClick( file )} className="small-white-button" style={{padding: '0.25rem 1rem'}}>Liitteen valinnat</button>
                                                            </Row>
                                                        )
                                                    })}
                                                </Col>
                                            )
                                        }
                                    })}


                                </Fragment>
                            )
                        })}
                    </Scrollbars>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => closeModal()}>Sulje</a>
                </ModalFooter>
            </Modal>
        </>
    )
}
import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Popup from 'reactjs-popup';

am4core.options.onlyShowOnViewport = true;

export default class CompanyNpsScore extends Component {

   constructor(props) {
      super(props)
      this.state = {
         rotate: 90,
         oneDayTrend: null,
         oneWeekTrend: null,
         oneMonthTrend: null
      }
   }

   componentDidMount() {
      let chart = am4core.create("nps-pie", am4charts.PieChart);
      chart.data = [
         {
            "name": "Arvostelijat",
            value: this.props.data.negative || 0
         },
         {
            "name": "Passiiviset",
            value: this.props.data.passive || 0
         },
         {
            "name": "Suosittelijat",
            value: this.props.data.positive || 0
         },
      ]

      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "name";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.showOnInit = false;
      //chart.legend = new am4charts.Legend();
      //chart.legend.position = "right";
      chart.innerRadius = am4core.percent(60);


      var label = pieSeries.createChild(am4core.Label);
      label.text = `${this.props.data.score}`;
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.fontSize = "1rem";

      var colorSet = new am4core.ColorSet();
      colorSet.list = ["#ed5652", "#fc0", "#b5e413"].map(function (color) {
         return new am4core.color(color);
      });
      pieSeries.colors = colorSet;

      this.chart = chart;

      if (this.props.data && this.props.data.trend) {
         let score = this.props.data.score;
         let trend = this.props.data.trend;
         let day = trend.oneDayScore;
         let week = trend.oneWeekScore;
         let month = trend.oneMonthScore;

         let dayTrend;
         let weekTrend;
         let monthTrend;

         if (day.answers) {
            if (score - day.score < 0) {
               dayTrend = this.calculateDecrease(score, day.score)
            } else {
               dayTrend = this.calculateIncrease(score, day.score)
            }
            this.setState({ oneDayTrend: dayTrend })
         }

         if (week.answers) {
            if (score - week.score < 0) {
               weekTrend = this.calculateDecrease(score, week.score)
            } else {
               weekTrend = this.calculateIncrease(score, week.score)
            }
            this.setState({ oneWeekTrend: weekTrend })
         }

         if (month.answers) {
            if (score - month.score < 0) {
               monthTrend = this.calculateDecrease(score, month.score)
            } else {
               monthTrend = this.calculateIncrease(score, month.score)
            }
            this.setState({ oneMonthTrend: monthTrend })
         }
      }
   }

   componentWillUnmount() {
      if (this.chart) {
         this.chart.dispose();
      }
   }

   calculateDecrease = (today, old) => {
      if(old !== 0){
         let number = Math.round((old - today) / old * 100)
         if(number > 0){
            number = number - (2 * number)
         }
         return number
      }else{
         return today;
      }
      
   }

   calculateIncrease = (today, old) => {
      if(old !== 0){
         let number = Math.round((today - old) / old * 100)
         if(number < 0 ){
            number = number - (2 * number)
         }
         return number
      }else{
         return today;
      }
      
   }

   arrowColor = () => {
      if (this.state.oneDayTrend > 0) {
         if (this.state.rotate !== 0) {
            this.setState({ rotate: 0 })
         }
         return '#bde533'
      } else if (this.state.oneDayTrend < 0) {
         if (this.state.rotate !== 180) {
            this.setState({ rotate: 180 })
         }
         return '#ed5652'
      } else {
         if (this.state.rotate !== 90) {
            this.setState({ rotate: 90 })
         }
         return '#fc0'
      }
   }

   render() {
      let color = this.arrowColor();
      return (
         <div style={{ position: 'relative' }}>
            <div className="px-0 m-0" style={{ height: '4em' }} id="nps-pie" ></div>
            {this.state.oneDayTrend !== null &&
               <Popup
                  trigger={(<i className={`fas fa-caret-up nps-arrow fa-rotate-${this.state.rotate}`} style={{ color: color }}></i>)}
                  on="hover"
               >
                  <div className="">
                     <div>
                        <span style={{ float: 'left' }}>Muutos päivässä:</span>
                        <b style={{ float: 'right' }}>{this.state.oneDayTrend}%</b>
                     </div>
                     {this.state.oneWeekTrend &&
                        <div >
                           <span style={{ float: 'left' }}>Muutos viikossa:</span>
                           <b style={{ float: 'right' }}>{this.state.oneWeekTrend}%</b>
                        </div>
                     }
                     {this.state.oneMonthTrend &&
                        <div>
                           <span style={{ float: 'left' }}>Muutos kuukaudessa:</span>
                           <b style={{ float: 'right' }}> {this.state.oneMonthTrend}% </b>
                        </div>
                     }
                  </div>
               </Popup>
            }
         </div>
      )
   }
}

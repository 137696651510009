import React from 'react'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline"
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets"
import am4lang_fi_FI from "@amcharts/amcharts4/lang/fi_FI"
import moment from 'moment'

import riskIcon from '../../../assets/riskIcon.svg'
import taskNormalIcon from '../../../assets/taskIcon.svg'
import taskDoneIcon from '../../../assets/taskDoneIcon.svg'
import taskDeclinedIcon from '../../../assets/taskDeclinedIcon.svg'
import surveyIcon from '../../../assets/surveyIcon.svg'
import planIcon from '../../../assets/planIcon.svg'
import projectIcon from '../../../assets/projectIcon.svg'

const colors = {
  qibbieGreen: '#B5E413',
  yellow: '#FFCC00',
  gray: '#C8C8C8',
  red: '#ED5652',
  lightBlue: '#8ED1FC',
  projectRed: '#efcbc4'

}

am4core.options.autoSetClassName = true

class Timeline extends React.Component {
    
  componentDidMount() {

    let chart = am4core.create( "timeline-chart", am4plugins_timeline.CurveChart )
    
    chart.curveContainer.padding( 0, 20, 20, 20 )
    chart.zoomOutButton.background.fill = am4core.color( colors.qibbieGreen )
    chart.zoomOutButton.background.states.getKey( "hover" ).properties.fill = am4core.color( colors.yellow )
    // this aligns the whole timeline to the bottom of the chart
    chart.curveContainer.valign = 'bottom'
    chart.maskBullets = false
    chart.language.locale = am4lang_fi_FI

    chart.dateFormatter.inputDateFormat = "dd.MM.yyyy HH:mm"
    chart.dateFormatter.dateFormat = "dd.MM.yyyy HH:mm"

    chart.data = this.loadChartData()

    let categoryAxis = chart.yAxes.push( new am4charts.CategoryAxis() )
    // categoryAxis.dataFields.category = "category"
    categoryAxis.renderer.grid.template.disabled = true
    categoryAxis.renderer.labels.template.paddingRight = 25
    categoryAxis.renderer.labels.template.hide()
    categoryAxis.renderer.minGridDistance = 1
    categoryAxis.renderer.innerRadius = 10
    categoryAxis.renderer.radius = 30
    // categoryAxis.renderer.autoCenter = false

    let dateAxis = chart.xAxes.push( new am4charts.DateAxis() )

    // with points u can shape the timeline. internet might give u examples if needed
    // dateAxis.renderer.points = this.getPoints()

    dateAxis.renderer.autoScale = true
    dateAxis.renderer.autoCenter = false
    dateAxis.renderer.minGridDistance = 90
    dateAxis.renderer.tooltipLocation = 0
    dateAxis.renderer.line.strokeDasharray = '1,4'
    dateAxis.renderer.labels.template.location = 0.001

    dateAxis.tooltip.background.cornerRadius = 5
    dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor( "alternativeBackground" )
    dateAxis.cursorTooltipEnabled = false
    
    dateAxis.dateFormats.setKey( "day", "[font-size: 12px]MMM dd" )
    dateAxis.dateFormats.setKey( "week", "[font-size: 12px]MMM dd" )
    dateAxis.dateFormats.setKey( "month", "[font-size: 12px]MMM yyyy" )
    
    dateAxis.periodChangeDateFormats.setKey( "day", "[bold, font-size: 12px]MMM yyyy[/]" )
    dateAxis.periodChangeDateFormats.setKey( "week", "[bold, font-size: 12px]MMM yyyy[/]" )
    dateAxis.periodChangeDateFormats.setKey( "month", "[bold, font-size: 12px]MMM yyyy[/]" )

    dateAxis.baseInterval = { count: 1, timeUnit: "day" }
    dateAxis.gridIntervals.setAll( [
      { timeUnit: "day", count: 1 },
      { timeUnit: "day", count: 5 },
      { timeUnit: "week", count: 1 },
      { timeUnit: "month", count: 1 }
    ] )

    let labelTemplate = dateAxis.renderer.labels.template
    labelTemplate.verticalCenter = "middle"
    // labelTemplate.fillOpacity = 0.6
    labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor( "background" )
    labelTemplate.background.fillOpacity = 1
    labelTemplate.fill = new am4core.InterfaceColorSet().getFor( "text" )
    labelTemplate.padding( 7, 7, 7, 7 )

    let series = chart.series.push( new am4plugins_timeline.CurveColumnSeries() )
    series.columns.template.height = am4core.percent( 30 )
    series.dataFields.dateX = "created"
    series.dataFields.categoryY = "category"
    series.dataFields.created = "created"
    series.dataFields.id = "id"
    series.dataFields.offset = "offset"
    series.dataFields.text = "text"
    series.dataFields.onClick = "onClick"
    series.dataFields.color = "color"
    series.baseAxis = categoryAxis
    series.columns.template.propertyFields.fill = "color" // get color from data
    series.columns.template.propertyFields.stroke = "color"
    series.columns.template.strokeOpacity = 0
    // series.columns.template.fillOpacity = 0.6
    series.tooltip.label.wrap = true
    series.tooltip.label.maxWidth = 400
    series.showOnInit = false

    let imageBullet1 = series.bullets.push( new am4plugins_bullets.PinBullet() )
    imageBullet1.background.radius = 12
    imageBullet1.locationX = 1
    imageBullet1.propertyFields.stroke = "color"
    imageBullet1.background.propertyFields.fill = "color"
    imageBullet1.image = new am4core.Image()
    imageBullet1.image.propertyFields.href = "icon"
    imageBullet1.image.scale = 0.6
    imageBullet1.circle.radius = am4core.percent( 100 )
    // imageBullet1.background.fillOpacity = 0.8
    imageBullet1.background.strokeOpacity = 0
    imageBullet1.dy = -2
    imageBullet1.background.pointerBaseWidth = 8
    imageBullet1.background.pointerLength = 10
    imageBullet1.tooltipText = "{text}"

    series.tooltip.pointerOrientation = "up"

    imageBullet1.background.adapter.add( "pointerAngle", ( value, target ) => {
      if ( target.dataItem ) {
        let position = dateAxis.valueToPosition( target.dataItem.dateX.getTime() )
        return dateAxis.renderer.positionToAngle( position )
      }
      return value
    } )

    imageBullet1.events.on( 'hit', ev => {
      const onClick = ev.target.dataItem.onClick
      if( typeof onClick === 'function' ) { onClick() }
    })

    imageBullet1.adapter.add( 'dy', ( value, target ) => {
      let offset = target.dataItem.offset
      return offset
    } )

    let hs = imageBullet1.states.create( "hover" )
    hs.properties.scale = 1.3
    hs.properties.opacity = 1
    hs.properties.strokeWidth = 5

    let textBullet = series.bullets.push( new am4charts.LabelBullet() )
    textBullet.label.propertyFields.text = "icon"
    textBullet.disabled = true
    textBullet.propertyFields.disabled = "textDisabled"
    textBullet.label.strokeOpacity = 0
    textBullet.locationX = 1
    textBullet.dy = - 100
    textBullet.label.textAlign = "middle"

    chart.scrollbarX = new am4core.Scrollbar()
    chart.scrollbarX.align = "center"
    chart.scrollbarX.width = am4core.percent( 100 )
    chart.scrollbarX.parent = chart.bottomAxesContainer
    chart.scrollbarX.height = 10
    chart.scrollbarX.orientation = "horizontal"
    chart.scrollbarX.x = 0
    chart.scrollbarX.y = 0
    chart.scrollbarX.isMeasured = true
    chart.scrollbarX.opacity = 0.5
    chart.scrollbarX.padding( 0, 20, 0, 20 )
    chart.scrollbarX.showSystemTooltip = false
    chart.scrollbarX.thumb.showSystemTooltip = false
    chart.scrollbarX.startGrip.showSystemTooltip = false
    chart.scrollbarX.endGrip.showSystemTooltip = false

    let cursor = new am4plugins_timeline.CurveCursor()
    chart.cursor = cursor
    cursor.xAxis = dateAxis
    cursor.yAxis = categoryAxis

    this.chart = chart
  }

  componentDidUpdate( prevProps ) {
    if ( prevProps.tasks !== this.props.tasks || prevProps.risks !== this.props.risks || prevProps.surveys !== this.props.surveys || prevProps.mounted !== this.props.mounted || prevProps.filters !== this.props.filters ) {
      this.chart.data = this.loadChartData()
    }
  }

  componentWillUnmount() {
    if( this.chart ) {
      this.chart.dispose()
    }
  }

  loadChartData = () => {
    const { filters } = this.props
    const chartData = []

    if( this.props.mounted ) {
      if ( filters.indexOf( 'Tarkistuspisteet' ) !== -1 ) {
        this.props.tasks.map( task => {
          const deadline = moment( new Date( task.deadline ) ).format( 'DD.MM.YYYY' )
          // these dates were added at one point to test the performance improvement compared to string dates. also the times were all over the place when done with a timestamp
          // const deadlineDate = new Date( task.deadline ).getTime()

          let taskIconz
          let color
          let text

          switch ( task.status ) {
            case 'done':
              taskIconz = taskDoneIcon
              color = colors.qibbieGreen
              text = `Tarkistuspiste merkitty suoritetuksi \nDeadline: ${ deadline } \n${ task.taskTitle }`
              break
            case 'declined':
              taskIconz = taskDeclinedIcon
              color = colors.red
              text = `Tarkistuspiste merkitty peruutetuksi \nDeadline: ${ deadline } \n${ task.taskTitle }`
              break
            default:
              taskIconz = taskNormalIcon
              color = colors.yellow
              text = `Tarkistuspiste kesken \nDeadline: ${ deadline } \n${ task.taskTitle }`
              break
          }

          const dataTask = {
            created: deadline,
            createdString: deadline,
            text: text,
            category: 'task',
            icon: taskIconz,
            id: task._id,
            color: color,
            onClick: () => this.props.onTaskClick( task )
          }

          return chartData.push( dataTask )
        } )
      }

      if ( filters.indexOf( 'Riskit' ) !== -1 ) {
        this.props.risks.map( risk => {
          const created = moment( new Date( risk.created ) ).format( 'DD.MM.YYYY' )
          // const createdDate = new Date( risk.created ).getTime()

          const dataRisk = {
            created: created,
            createdString: created,
            text: `Riskiarvio lisätty ${ created }`,
            category: 'risk',
            icon: riskIcon,
            id: risk._id,
            color: risk.color,
            onClick: () => this.props.onRiskClick( risk )
          }

          return chartData.push( dataRisk )
        } )
      }


      if ( filters.indexOf( 'Lomakkeet' ) !== -1 ) {
        this.props.plans && this.props.plans.map( plan => {
          const created = moment( new Date( plan.created ) ).format( 'DD.MM.YYYY' )
          // const createdDate = new Date( risk.created ).getTime()

          const dataPlan = {
            created: created,
            createdString: created,
            text: `${plan.name} \n ${ created }`,
            category: 'plan',
            icon: planIcon,
            id: plan._id,
            color: colors.lightBlue,
            onClick: () => this.props.onPlanClick( plan.id )
          }

          return chartData.push( dataPlan )
        } )
      }
      

      if ( filters.indexOf( 'Kartoitukset' ) !== -1 ) {
        this.props.surveys && this.props.surveys.map( survey => {
          const dataSurvey = []
          const color = survey.jobCalculatedStats ? survey.jobCalculatedStats.color : colors.qibbieGreen
          const surveyScore = survey.jobCalculatedStats ? survey.jobCalculatedStats.avg : 0

          if ( survey.dates ) {
            survey.dates.map( dateRange => {
              const startDateString = moment( new Date( dateRange.startDate ) ).format( 'DD.MM.YYYY' )
              // const startDate = new Date( dateRange.startDate ).getTime()

              dataSurvey.push( {
                created: startDateString,
                createdString: startDateString,
                text: `${ survey.name } \nTulos: ${ surveyScore }`,
                category: 'survey',
                icon: surveyIcon,
                id: survey._id,
                color: color,
                onClick: () => this.props.onSurveyClick( survey )
              } )
            } )
          } else {
            const startDateString = moment( new Date( survey.startDate ) ).format( 'DD.MM.YYYY' )
            // const startDate = new Date( survey.startDate ).getTime()

            dataSurvey.push( {
              created: startDateString,
              createdString: startDateString,
              text: `${ survey.name } \nTulos: ${ surveyScore }`,
              category: 'survey',
              icon: surveyIcon,
              id: survey._id,
              color: color,
              onClick: () => this.props.onSurveyClick( survey )
            } )
          }

          return chartData.push( ...dataSurvey )
        } )
      }

      if ( filters.indexOf( 'Projektit' ) !== -1 ) {
        this.props.projects && this.props.projects.map( project => {
          const created = moment.utc(project.created ,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')

          const dataProject = {
            created: created,
            createdString: created,
            text: `${project.projectName} \n ${ created }`,
            category: 'project',
            icon: projectIcon,
            id: project._id,
            color: colors.projectRed,
            onClick: () => this.props.onProjectClick( project._id )
          }

          return chartData.push( dataProject )
        } )
      }

      chartData.sort( ( a, b ) => {
        return new Date( this.parseDate( b.createdString ) ) - new Date( this.parseDate( a.createdString ) )
      } )

      let prevItem = { createdString: 0 }

      chartData.map( item => {
        
        let curDate = item.createdString,
            prevDate = prevItem.createdString

        if( curDate === prevDate ) {
          item.offset = prevItem.offset - 18
        } else {
          item.offset = -2
        }

        prevItem = item
      })

      return chartData.reverse()
    }
    return chartData
  }

parseDate = input => {
  var parts = input.match( /(\d+)/g )
  // note parts[1]-1
  return new Date( parts[ 2 ], parts[ 1 ] - 1, parts[ 0 ] )
}

  render() {
    return (
      <div style={ { height: '90%' } } id="timeline-chart">

      </div>
    )
  }
}

export default Timeline
import React, { useState, Suspense } from 'react';
import qlikIcon from '../../../../assets/qlikIcon.svg'

const QlikIframe = ({ data, hostName, style }) => {
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [startLogIn, setStartLogIn] = useState(false)
    const [doingLogIn, setDoingLogIn] = useState(false)
    const [tenant, setTenant] = useState('')

    React.useEffect(() => {
        checkTenant(hostName).then(a => {
            checkIsLoggedIn(a).then(loggedIn => {
                if (!loggedIn) {
                    setLoggedIn(false)
                    // popup with login
                    // if (window.confirm('If you click "ok" you would be redirected to the login page')) {
                    //     window.open("https://saviorofazeroth.eu.qlikcloud.com/login?qlik-web-integration-id=" + data.webIntegrationId + "&returnto='https://none'");
                    // };
                } else {
                    setLoggedIn(true)
                }
            })
        })
    }, []);

    function checkIsLoggedIn(tenant) {
        return fetch("https://" + tenant + ".eu.qlikcloud.com/api/v1/users/me", {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'qlik-web-integration-id': data.webIntegrationId,
            },
        }).then(response => {
            return response.status === 200;
        });
    }

    function checkTenant(string) {
        return new Promise((resolve) => {
            let url = new URL(string)
            let denant = url.hostname.substring(0, url.hostname.indexOf("."));
            setTenant(denant)
            resolve(denant)
        })
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    React.useEffect(() => {
        if (startLogIn) {
            setStartLogIn(false)
            let popper = window.open("https://" + tenant + ".eu.qlikcloud.com/login?qlik-web-integration-id=" + data.webIntegrationId, "mywindow", "menubar=1,resizable=1,width=600,height=500")
            if (!doingLogIn) {
                setDoingLogIn(true)
                async function startLogin() {

                    let counter = 0;
                    while (await checkIsLoggedIn(tenant) === false && counter <= 10) {
                        counter++
                        await delay(6000)
                    }
                    popper.close()
                    setLoggedIn(true)
                    setDoingLogIn(false)
                }
                startLogin()
            }
        }
    }, [startLogIn])

    if (isLoggedIn) {
        return <iframe src={hostName} frameBorder="0" style={style ? style : { height: '100%', width: '100%' }} ></iframe>
    } else {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <div style={{textAlign: 'center'}}>
                <img src={qlikIcon} style={{ maxWidth: '6em', marginBottom: '1em' }} />
                <button className="small-white-button wide-button" onClick={() => setStartLogIn(true)}>Kirjaudu sisään</button>
            </div>
        </div>
    }
}

export default QlikIframe
import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ActionCreator from './ActionCreator';

class MainContent extends Component {

    isActive = (path) => {
        return this.props.activetab === path
    }

    handleClick = (path) => {
        this.props.history.push(path)
    }


    render() {
        let sentSurveys = this.props.sentSurveys.filter(ss => ss.code)
        const activatedText = sentSurveys.length === 1 ? 'aktivoitu kartoitus' : 'aktivoitua kartoitusta'

        return (
            <Container>
                <Row>
                    <Col>
                        <Row className="surveyMainContentRow">
                            <Col className={this.isActive('/kartoitukset/aktivoi') ? 'survey-bar-active' : 'survey-bar'} onClick={() => this.handleClick('/kartoitukset/aktivoi')}>
                                    <p>Aktivoi kartoituksia</p>
                                    {/* <span className="contentSubTitle lighter-text">{`${sentSurveys.length} ${activatedText}`}</span> */}
                            </Col>
                            <Col className={this.isActive('/kartoitukset') ? 'survey-bar-active' : 'survey-bar'} onClick={() => this.handleClick('/kartoitukset')}>
                                    <p>Hallinnoi kartoituksia</p>
                                    <div className="contentSubTitle lighter-text">{`${sentSurveys.length} ${activatedText}`}</div>
                                    <div className="contentSubTitle lighter-text" >{this.props.dasboardSummary.length} kartoituspohjaa</div>  
                            </Col>
                            <Col className={this.isActive('/kartoitukset/luonti') ? 'survey-bar' : 'survey-bar'} onClick={() => this.handleClick('/kartoitukset/luonti')}>
                                    <p className="contentTitle" onClick={() => this.props.onNewCategory()}>Luo uusi kartoitus</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        sentSurveys: state.senderV2.sentSurveys,
        dasboardSummary: state.senderV2.availableSurveys
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNewCategory: (params) => dispatch(ActionCreator.startCategory(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContent));
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalFooter, ModalBody, Label, Input, FormGroup, FormFeedback } from 'reactstrap';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { DateRangePicker, SingleDatePicker} from 'react-dates';
import moment from 'moment';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import { Scrollbars } from 'react-custom-scrollbars';
import { TagHandler } from './TagHandler';
import { NotificationManager } from 'react-notifications';
import { motion } from 'framer-motion';
import { EmptyProjectList } from './ProjectLayout';
import { ListHeaderSorter } from './ListHeaderSorter';

const INITIAL_STATE_ACTIVITY = {
    date: null,
    name: "",
    description:"",
    emailList:[],
    tags:[]
}

export const Activities = ({projectId, update}) => {
    const [search, setSearch] = useState("")
    const [activitiesModalOpen, setActivitiesModalOpen] = useState(false)
    const [focusedDateInput, setFocusedDateInput] = useState(false)
    const [showDates, setShowDates] = useState(false)
    const [activities, setActivities] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState()
    const [modifyOpen, setModifyOpen] = useState(false)
    const [followEmailInputHasChanged, setFollowEmailInputHasChanged] = useState(false)
    const [followEmailIsValid, setFollowEmailIsValid] = useState(false)
    const [followEmail, setFollowEmail] = useState("")
    const [activity, setActivity] = useState(INITIAL_STATE_ACTIVITY);
    const [selectedIndex, setSelectedIndex] = useState()
    const [deleteText, setDeleteText] = useState("")
    const [orderBy, setOrderBy] = useState("name")
    const [sortOrder, setSortOrder] = useState(1)
    const [sortedActivities, setSortedActivities] = useState([])

    const saveActivity = async() => {
        if(activity.name.length > 0){
            let params = {
                projectId: projectId,
                activity: activity
            }
            const response = await executeRequest('project/newActivity', params)
            if(response){
                setActivitiesModalOpen(!activitiesModalOpen)
                getActivities()
                setActivity(INITIAL_STATE_ACTIVITY)
            }
        } else {
            NotificationManager.error('Anna tapahtumalle nimi!', 'Virhe', 3000)
        }
    }
    const saveModifiedActivity = async() => {
        let params = {
            projectId: projectId,
            activity: activity
        }
        const response = await executeRequest('project/saveActivity', params)
        if(response){
            setModifyOpen(!modifyOpen)
            getActivities()
            setActivity(INITIAL_STATE_ACTIVITY)
        }

    }


    const handleDelete = async(selected) => {
        if(deleteText === "poista"){
            let params = {
                projectId: projectId,
                activityId: selected._id
            }
            
            const response = await executeRequest('project/deleteActivity', params)
            if(response.deleted){
                getActivities()
                NotificationManager.success('Poistettu onnistuneesti!', '', 3000)
            }  else {
                NotificationManager.error('Virhe poistaessa tapahtumaa!', 'Virhe', 3000)
            }
            setModalOpen(!modalOpen)
            setDeleteText("")
        }
    }

    useEffect(()=>{
        getActivities()
    },[])

    const getActivities = async() => {
        let params = {
            projectId: projectId
        }
        const response = await executeRequest('project/getActivities', params)
        setActivities(response)
    }

    const toggleModal = (data, x) => {
        setSelectedData(data) 
        setModalOpen(!modalOpen)
    }

    const toggleDropdown = (y) => {
        if (menuOpen === y) {
            setMenuOpen(null)
        } else {
            setMenuOpen(y)
        }
    }

    const handleActivityChange = (target) => {
        const {name, value} = target
        setActivity(prevData =>({
            ...prevData,
            [name]:value
        }))
    }

    const toggleModify = (data) =>{
        setActivity({
            ...data,
            date: moment(data.date)
        })
        setModifyOpen(true)
    }

    const handleAddActivityEmail = () => { 
        setActivity(prevActivity => ({
            ...prevActivity,
            emailList:[...activity.emailList, followEmail]
        }))

        setFollowEmail("")
        setFollowEmailIsValid(false)

    }
    const handleFollowEmailChange = (value) => {
        const email = value
        setFollowEmail(email)
        setFollowEmailInputHasChanged(true)
        const emailRegex = /[^@]+@[^\.]+\..+/
        let followEmailIsValid = false

        if (emailRegex.test(email)) {
            followEmailIsValid = true
        } else if (email === '') {
            setFollowEmailInputHasChanged(false)
        }

        setFollowEmailIsValid(followEmailIsValid)
    }

    const handleActivityDateChange = (date) => {
        setActivity(prevActivity => ({
            ...prevActivity,
            date:date
        }))
    }

    const handleActivityEmailDelete = (index) => {
        let array = activity.emailList
        array.splice(index,1)
        setActivity(prevActivity => ({
            ...prevActivity,
            emailList:array
        }))
    }
    let emailInputProps = followEmailInputHasChanged ? { valid: followEmailIsValid, invalid: !followEmailIsValid } : {}

    const handleTagChange = (props) => {
        const {id, value} = props.target
        handleActivityChange({name:id,value:value})
    }

    const handleListNameFilter = (activity) => {
        const { name, tags} = activity
        
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        
        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    useEffect(()=>{
        if(activities){
            setSortedActivities(listSorter())
        }
    },[orderBy, sortOrder, activities])

    const listSorter = () => {
        let sortedArray = [...activities]
        let orderVal1 = sortOrder
        let orderVal2 = sortOrder === 1? -1 : 1
        if(orderBy !== "modified" && orderBy !== "created"){
            sortedArray.sort((a,b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase()) ? orderVal1 : orderVal2)
            return [...sortedArray]
        }
        return [...sortedArray]
    }

    const handleSortOrder = (attribute) => {
        if(orderBy !== attribute){
            setOrderBy(attribute)
            if(attribute === "name"){
                setSortOrder(1)
            } else {
                setSortOrder(-1)
            }
        } else {
            setSortOrder(sortOrder === -1? 1 : -1)
        }
    }

    return(
        <>
            <ModifyModal
                activity={activity}
                setActivity={setActivity}
                handleActivityChange={handleActivityChange}
                modifyOpen={modifyOpen}
                setModifyOpen={setModifyOpen}
                saveModifiedActivity={saveModifiedActivity}              
                handleActivityDateChange={handleActivityDateChange}
                selectedIndex={selectedIndex}
                getActivities={getActivities}
                handleTagChange={handleTagChange}
            />
            <Modal size="lg" isOpen={activitiesModalOpen} toggle={(() => setActivitiesModalOpen(!activitiesModalOpen))}>
                <ModalHeader toggle={(() => setActivitiesModalOpen(!activitiesModalOpen))}>Uusi tapahtuma</ModalHeader>
                <ModalBody>
                    <div style={{ display: "flex", flexDirection: "column", marginRight: "1em" }}>
                        <div style={{ marginBottom: '1em' }}>
                            <Label>Tapahtuman nimi</Label>
                            <Input type="text" name="name" value={activity.name} onChange={(e) => handleActivityChange(e.target)}></Input>
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <Label>Tapahtuman päivämäärä</Label>
                            <SingleDatePicker
                                date={activity.date}
                                id="dateId"
                                onDateChange={(date) => handleActivityDateChange(date)}
                                placeholder="Päivämäärä"
                                appendToBody={false}
                                focused={focusedDateInput}
                                onFocusChange={({focused})  => setFocusedDateInput(focused)}
                                hideKeyboardShortcutsPanel={true}
                                block
                                readOnly
                            />
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <Label>Tagit</Label>
                            <TagHandler 
                                tags={activity.tags}
                                handleChange={handleTagChange}
                            />
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <Label>Kuvaus</Label>
                            <TextEditor   
                                placeholder=" -" 
                                // value={description}                        
                                content={activity.description} 
                                onTextChange={(val) => handleActivityChange({name:"description", value:val})} 
                                readOnly={false}
                                additionalClass="round-corners"
                                allowFileUpload={false}
                            />
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <Label style={{ fontSize: '0.8rem'}}>Lisää tähän sähköpostiosoite, joihin haluat lähettää muistutuksen</Label>
                            <FormGroup>
                                <Input {...emailInputProps} value={followEmail} onBlur={() => setFollowEmailInputHasChanged(false)} onChange={(e) => handleFollowEmailChange(e.target.value)} type="text" placeholder="Sähköpostiosoite" />
                                <FormFeedback>Syötä sähköpostiosoite oikeassa muodossa</FormFeedback>
                            </FormGroup>
                            {followEmailIsValid &&
                                <button className="small-white-button lighter-text wide-button" onClick={() => handleAddActivityEmail()}>Lisää sähköposti</button>
                            }
                        </div>
                        <div style={{ marginTop: '1em' }}>
                            {activity.emailList && activity.emailList.length > 0 && activity.emailList.map((mail,y) =>{
                                let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row';
                                y++;
                                return(
                                    <div key={y} style={{ display: 'flex', alignContent: 'center' }} className={oddEvenClass + ' company-list selectable-row'}>
                                        <p>{mail}</p><i style={{ marginRight:'0.5em', marginLeft: 'auto', marginTop: '0.25em' }} onClick={() => (handleActivityEmailDelete(y), setSelectedIndex(y))} className="far fa-trash-alt"></i>
                                    </div>
                                )
                            })}  
                        </div>   
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() =>setActivitiesModalOpen(!activitiesModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => saveActivity()} className="small-white-button wide-button"> Tallenna</button>{' '}
                </ModalFooter>
            </Modal>
            <Modal isOpen={modalOpen} toggle={() => (setModalOpen(!modalOpen), setDeleteText(""))}>
                    <ModalHeader>{selectedData && selectedData.name}</ModalHeader>
                    <ModalBody>
                        <p>Oletko varma, että haluat poistaa tapahtuman? <b>Toimintoa ei voi peruuttaa!</b></p>
                        <div style={{ marginTop: '1em' }}>
                            <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                            <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                            <div className="email-check-box">
                                {deleteText === "poista"?
                                    <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                    :
                                    <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <a onClick={() => (setModalOpen(!modalOpen), setDeleteText(""))} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                        <button onClick={() => handleDelete(selectedData)} className="small-white-button wide-button delete-plan" style={{backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                    </ModalFooter>
            </Modal>
            <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ ease: 'easeIn', duration: 0.3 }}
                exit={{ opacity: 0, x: -50 }}
                key={"activities"}
            >
                <Row className="tab-header bigger-text" style={{ display: 'flex', alignItems: 'center'}}>   
                    <Col>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1em' }}>
                            <Input type="search" className="search-bar-open" placeholder={"Hae"} value={search} onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </Col>
                    <Col>
                        <button className="small-white-button wide-button project-survey-button" style={{ float: 'right' }} onClick={() => (setActivitiesModalOpen(true),setActivity(INITIAL_STATE_ACTIVITY))} >
                            Lisää projektille tapahtuma
                        </button>
                    </Col>
                </Row>
                <Row style={{ fontSize:'0.9rem', marginBottom:'0.25em' }}>
                    <Col xs="5" style={{ cursor:'pointer', marginLeft:'1em'}}>
                        <ListHeaderSorter label={"Nimi"} name={"name"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder}/>
                    </Col>
                    <Col xs="3" style={{ marginLeft:'-1.25em'}}>Tapahtuman pvm</Col>
                    <Col xs="3" style={{ marginLeft:'-1.1em'}}>Tagit</Col>
                    <Col></Col>
                </Row>
                {activities && activities.length > 0 ?
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '500px' }}
                        className="custom-scrollbars"
                    >
                    {sortedActivities.length > 0 && sortedActivities.filter(a => handleListNameFilter(a)).map((activity, y) => {
                        let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row';
                        var today = moment.utc(new Date()).local()
                        var activityDate = moment.utc(activity.date).local()
                        var expired = false
                        if(today > activityDate){
                            expired = true
                        }
                        y++;
                        return(
                            <div key={y} style={{ padding: '1rem', alignItems: 'center' }} className={oddEvenClass + " list-row"} onClick={() => toggleModify(activity)}>
                                <Row className='task-list-content' style ={{ alignItems: 'center' }}>
                                    <Col xs="5">
                                        <p className="content-text name-overflow-handler-long" title={activity.name}>{activity.name}</p> 
                                        {activity.emailList && activity.emailList.length > 0 &&
                                            <p style={{ fontSize: '0.9rem', marginTop: '0.25em' }}>{"Sähköpostimuistutus " + (expired? "lähetetty": "aktivoitu")}</p>
                                        }   
                                    </Col>
                                    <Col xs="3">
                                        <p style={{ fontSize: '0.8rem', marginTop: '0.25em' }}><span style={ expired? { color:'red' } : {}}>{moment.utc(activity.date, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>
                                    </Col>
                                    <Col xs="3">
                                        <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                                            {activity.tags && activity.tags.slice(0, 5).map((tag, y) => {                        
                                                    return(<div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="tag-display tag-text project-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                })  
                                            }                                                   
                                            {activity.tags && activity.tags.length > 5 &&  
                                                    <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                                        <i className="material-icons" title={tagsToMore(activity.tags.slice(5,activity.tags.length))}>more_horiz</i>
                                                    </div>
                                            }  
                                        </div>         
                                    </Col>
                                    <Col xs="1">
                                        <Dropdown isOpen={menuOpen === y} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent:'center' }} onClick={(event) => event.stopPropagation()} toggle={() => toggleDropdown(y)}>
                                            <DropdownToggle tag="div" style={{  float: 'right', fontSize: '1rem' }}>
                                                <i className="fas fa-bars" title="Valikko"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => toggleModify(activity)}>Muokkaa</DropdownItem>
                                                <DropdownItem onClick={() => toggleModal(activity, true)}>Poista</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>  
                        )
                    })}
                    </Scrollbars>
                    :
                    <EmptyProjectList listText={"Ei tapahtumia"} />
                }
                
            </motion.div>
        </>     
    )
}

const tagsToMore = (props) => {
    let tagss = ""
    
    if(props !== undefined){
        props.filter(tags => tagss = tagss + "  " + tags.label.toString())

        return(tagss)
    }
}


const ModifyModal = ({activity, setActivity, selectedIndex, getActivities, handleActivityChange, handleActivityDateChange, modifyOpen, setModifyOpen, saveModifiedActivity, handleTagChange}) => {
    const [focusedDateInput, setFocusedDateInput] = useState(false)
    const [followEmailIsValid, setFollowEmailIsValid] = useState(false)
    const [followEmailInputHasChanged, setFollowEmailInputHasChanged] = useState(false)
    const [followEmail, setFollowEmail] = useState("")
    const date = moment.utc(activity.date, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
    const handleFollowEmailChange = (value) => {
        const email = value
        setFollowEmail(email)
        setFollowEmailInputHasChanged(true)
        const emailRegex = /[^@]+@[^\.]+\..+/
        let followEmailIsValid = false

        if (emailRegex.test(email)) {
            followEmailIsValid = true
        } else if (email === '') {
            setFollowEmailInputHasChanged(false)
            setFollowEmailIsValid(false)
        }

        setFollowEmailIsValid(followEmailIsValid)
    }

    const handleAddActivityEmail = () => {
        setActivity(prevActivity => ({
            ...prevActivity,
            emailList: activity.emailList?[...activity.emailList, followEmail] : [followEmail]
        }))

        setFollowEmail("")
        setFollowEmailIsValid(false)
    }

    const handleActivityEmailDelete = (index) => {
        let array = activity.emailList

        array.splice(index-1,1)
        setActivity(prevActivity => ({
            ...prevActivity,
            emailList:array
        }))

        setFollowEmail("")
        setFollowEmailIsValid(false)
    }

    

    let emailInputProps = followEmailInputHasChanged ? { valid: followEmailIsValid, invalid: !followEmailIsValid } : {}

    return(
        <>
            <Modal size="lg" isOpen={modifyOpen} toggle={(() => setModifyOpen(!modifyOpen))}>
                <ModalHeader toggle={(() => setModifyOpen(!modifyOpen))}>Muokkaa tapahtumaa</ModalHeader>
                <ModalBody>
                    <div style={{ display: "flex", flexDirection: "column", marginRight: "1em" }}>
                        <div style={{ marginBottom: '1em' }}>
                            <Label>Tapahtuman nimi</Label>
                            <Input type="text" name="name" value={activity.name} onChange={(e) => handleActivityChange(e.target)}></Input>
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <Label>Tapahtuman päivämäärä</Label>
                            <SingleDatePicker
                                date={activity.date}
                                id="dateId"
                                onDateChange={(date) => handleActivityDateChange(date)}
                                placeholder="Päivämäärä"
                                appendToBody={false}
                                focused={focusedDateInput}
                                onFocusChange={({focused})  => setFocusedDateInput(focused)}
                                hideKeyboardShortcutsPanel={true}
                                block
                                readOnly
                            />
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <Label>Tagit</Label>
                            <TagHandler 
                                tags={activity.tags}
                                handleChange={handleTagChange}
                            />
                        </div>
                        <div style={{ marginBottom: '1em' }}>
                            <Label>Kuvaus</Label>
                            <TextEditor   
                                placeholder=" -" 
                                // value={description}                        
                                content={activity.description} 
                                onTextChange={(val) => handleActivityChange({name:"description", value:val})} 
                                readOnly={false}
                                additionalClass="round-corners"
                                allowFileUpload={false}
                            /> 
                        </div>
                         <div>
                            <Label style={{ fontSize: '0.8rem', paddingTop:'1em' }}>Lisää tähän sähköpostiosoitteet, joihin haluat lähettää muistutuksen</Label>
                            <FormGroup>
                                <Input {...emailInputProps} value={followEmail} onBlur={() => setFollowEmailInputHasChanged(false)} onChange={(e) => handleFollowEmailChange(e.target.value)} type="text" placeholder="Sähköpostiosoite" />
                                <FormFeedback>Syötä sähköpostiosoite oikeassa muodossa</FormFeedback>
                            </FormGroup>
                            {followEmailIsValid &&
                                <button className="small-white-button lighter-text wide-button" onClick={() => handleAddActivityEmail()}>Lisää sähköposti</button>
                            }
                        </div>
                        <div style={{ marginTop: '0.5em' }}>
                            {activity.emailList && activity.emailList.length > 0 && activity.emailList.map((mail,y) =>{
                                let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row';
                                y++;
                                return(
                                    <div key={y} style={{ display: 'flex', alignContent: 'center' }} className={oddEvenClass + ' company-list selectable-row'}>
                                        <p>{mail}</p><i style={{ marginRight:'0.5em', marginLeft: 'auto', marginTop: '0.25em' }} onClick={() => handleActivityEmailDelete(y)} className="far fa-trash-alt"></i>
                                    </div>
                                )
                            })}  
                        </div>    
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => (setModifyOpen(!modifyOpen), getActivities())} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => saveModifiedActivity()} className="small-white-button wide-button" >Tallenna</button>{' '}
                </ModalFooter>
            </Modal>
        </>
    )
}
import React, { useState } from 'react'
import {
  Row,
  Col
} from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'

import ViewCardModal from './ViewCardModal'

const emptyCard = {
  name: '',
  desc: '',
  labels: [],
  memberList: []
}

const TrelloList = props => {
  const { list, cards, onDeleteClick, businessId } = props

  const [ cardModalIsOpen, setCardModalIsOpen ] = useState( false )
  const [ selectedCard, setSelectedCard ] = useState( emptyCard )

  const toggleCardmodal = () => {
    setCardModalIsOpen( !cardModalIsOpen )
  }

  const openCardModal = card => {
    setSelectedCard( card )
    toggleCardmodal()
  }

  const checkListNumbers = (checklists) => {
    let numberOfItems = 0;
    let completed = 0;

    if(checklists !== undefined) {
      const lists = checklists.map(checklist => {
        const {checkItems} = checklist

        checkItems.map(checkItem => {
          if(checkItem.state !== 'incomplete') {
            completed++;
          }
          return checkItem
        })

        numberOfItems += checkItems.length
        return checklist;
      })

      return {
        numberOfItems: numberOfItems,
        completed: completed
      }
    }

    return;
  }

  return (
    <Col md={ { size: 12 } } xl={ { size: 6 } } >
      
      <ViewCardModal isOpen={ cardModalIsOpen } toggle={ toggleCardmodal } card={ selectedCard } businessId={ businessId } />

      <div className="dashboard-medium-box">
        <div className="box-header bigger-text">
          <p>
            { list.name }
            <i onClick={ () => onDeleteClick( list ) } className="fas fa-trash-alt" style={ { color: '#ed5652', float: 'right', marginRight: '1em', marginTop: '.3em', cursor: 'pointer' } }></i>
          </p>
        </div>
        <Scrollbars
          renderTrackHorizontal={ props => <div { ...props } className="track-horizontal" style={ { display: "none" } } /> }
          renderThumbHorizontal={ props => <div { ...props } className="thumb-horizontal" style={ { display: "none" } } /> }
          style={ { width: "100%", height: '500px' } }
        >
          { cards.filter( card => !card.closed ).map( ( card, index ) => {

            let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'
            let cardNumbers = checkListNumbers(card.checklists);
            return (
              <div key={ index } className={ "selectable-row " + oddEvenClass } style={ { padding: '1em' } } onClick={ () => openCardModal( card ) }>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        { card.labels && card.labels.map( ( label, index ) => {
                          return (
                            <span key={ index } title={ label.name } className={ `trello-label trello-label-${ label.color }` }>
                              <span className='trello-label-text'>{ label.name }</span>
                            </span>
                          )
                        } ) }
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                          { card.name }
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="member-row">                   
                          <div>
                            {card.checklists && 
                              <>
                                <i className="far fa-check-square trello"></i>
                                <span className="checklist-numbers">{`${cardNumbers.completed}/${cardNumbers.numberOfItems}`}</span>
                              </>
                            }
                          </div>
                          <div>
                          { card.memberList.map( ( member, index ) => {
                            return <span key={ index } title={ member.fullName } className='trello-member'>{ member.initials }</span>
                          } ) }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )
          } ) }

        </Scrollbars>
      </div>
    </Col>
  )
}

export default TrelloList
import * as ActionTypes from '../actions/Kartoitus/ActionTypes';
import { SET_ACTIVE_BUSINESS_LIST } from '../../Components/Reports/Dashboard/Actions/actionTypes';

const initialState = {
    categories: [],
    currentCategory: {},
    currentQuestionGroup: {},
    currentQuestion: {},
    categoryEdit: false,
    groupEdit: false,
    categoryWindow: false,
    questionGroupWindow: false,
    dashboardSummary: null,
    questionWindow: false,
    fromSurveys: false,
    tagSearch: [],
    questionId: "",
    groupId: "",
    loading: false,
    sentSurveyList: [],
    activeSentSurveys: [],
    categoryOptions: [],
    surveyLinkTree: {},
    selectedSentSurvey: null,
    fromSurveys: false,
    activeBusinessList: null
};


const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ACTIVE_BUSINESS_LIST:
            return {
                ...state,
                activeBusinessList: action.payload
            };
        case ActionTypes.STORE_CATEGORIES: 
            return {
                ...state,
                categories: [...action.payload.categories],
                categoryOptions: [...action.payload.categoryOptions]
            };
        case ActionTypes.STORE_SENT_SURVEYLIST:
            return {
                ...state,
                sentSurveyList: action.payload
            }
        case ActionTypes.STORE_SURVEY_LINKTREE:
            return {
                ...state,
                surveyLinkTree: action.payload
            }
        case ActionTypes.STORE_CATEGORY:
            return {
                ...state,
                currentCategory: action.payload,
                categoryEdit: false
            };
        case ActionTypes.STORE_GROUP: 
            return {
                ...state,
                currentQuestionGroup: action.payload
            }
        case ActionTypes.STORE_SUMMARY:
            return {
                ...state,
                dashboardSummary: action.payload
            }
        case ActionTypes.DELETE_GROUP:
            return {
                ...state,
                categories: state.categories.map((category, y) => {
                    if(category._id === action.payload._id) {
                        return action.payload;
                    } else {
                        return category;
                    }
                }),
                currentCategory: action.payload
            }
        case ActionTypes.START_CATEGORY: {
            return {
                ...state,
                categoryWindow: action.start,
                categoryEdit: action.start
            };
        }
        case ActionTypes.EDIT_CATEGORY: {
            return {
                ...state,
                currentCategory: {...action.payload.categoryToEdit},
                categoryWindow: action.payload.start,
                categoryEdit: action.payload.start,
                fromSurveys: false
            }
        }
        case ActionTypes.EDIT_CATEGORY_FROM_SENT_SURVEYS: {
            return {
                ...state,
                currentCategory: {
                    ...action.payload.categoryToEdit
                },
                selectedSentSurvey: {
                    ...action.payload.selectedSurvey
                },
                categoryWindow: action.payload.start,
                categoryEdit: action.payload.start,
                fromSurveys: true
            }
        }
        case ActionTypes.SET_CATEGORY: {
            return {
                ...state,
                currentCategory: action.payload,
                categoryEdit: false
            }
        }
        case ActionTypes.NEW_SURVEY: {
            return {
                ...state,
                currentCategory: {},
                currentQuestion: {},
                currentQuestionGroup: {},
                categoryEdit: true,
                fromSurveys: false
            }
        }
        case ActionTypes.EDIT_QUESTION: {
            return {
                ...state,
                ...action.data
            }
        }
        case ActionTypes.CATEGORY_VIEW: 
            return {
                ...state,
                ...action.payload
            }
        case ActionTypes.QUESTIONGROUP_VIEW:
            return {
                ...state,
                ...action.payload
            }
        case ActionTypes.SET_QUESTION:
            return {
                ...state,
                ...action.payload
            }
        case ActionTypes.SET_QUESTIONGROUP:
            return {
                ...state,
                ...action.payload
            }
        case ActionTypes.STORE_ACTIVE_SENT_SURVEYLIST:
            return {
                ...state,
                activeSentSurveys: action.payload
            }
        case ActionTypes.REORDER_SURVEY_QUESTIONS:
            return {
                ...state,
                currentCategory: action.payload
            }
        case ActionTypes.SET_TAGS:
            return{
                ...state,
                tagSearch: action.payload
            }
        case ActionTypes.LOADING_STATUS:
            return {
                ...state,
                loading: action.payload
            }
        case ActionTypes.RESET_BUILDER:
            return {
                ...state,
                categoryEdit: false,
                currentCategory: {},
                currentQuestion: {},
                currentQuestionGroup: {},
                fromSurveys: false
            }
        case ActionTypes.RESET_BUILDER_REDUX:
            return {
                ...initialState
            }
        case ActionTypes.ADD_POINTS:
            return {
                ...state,
                currentQuestion : {
                    ...state.currentQuestion,
                    questionOptions: [
                        ...state.currentQuestion.questionOptions.map((option, x) => {
                            if(x === action.payload.targetIndex && option.points + 1 <= 100) {
                                option.points += 1;
                            }
                            return option;
                        })
                    ]
                }
            }
        case ActionTypes.DECREMENT_POINTS:
            return {
                ...state,
                currentQuestion : {
                    ...state.currentQuestion,
                    questionOptions: [
                        ...state.currentQuestion.questionOptions.map((option, x) => {
                            if(x === action.payload.targetIndex && option.points - 1 >= 0) {
                                option.points -= 1;
                            }
                            return option;
                        })
                    ]
                }
            }
        case ActionTypes.ADD_GROUP_POINTS:
            return {
                ...state,
                currentQuestionGroup : {
                    ...state.currentQuestionGroup,
                    feedbacks: [
                        ...state.currentQuestionGroup.feedbacks.map((feedback, x) => {
                            if(x === action.payload.targetIndex) {
                                feedback.range[1] = action.payload.range;
                            }
                            return feedback;
                        })
                    ]
                }
            }
        case ActionTypes.DECREMENT_GROUP_POINTS:
            return {
                ...state,
                currentQuestionGroup : {
                    ...state.currentQuestionGroup,
                    feedbacks: [
                        ...state.currentQuestionGroup.feedbacks.map((feedback, x) => {
                            if(x === action.payload.targetIndex) {
                                feedback.range[1] = action.payload.range;
                            }
                            return feedback;
                        })
                    ]
                }
            }
        case ActionTypes.SET_POINTS:
            return {
                ...state,
                currentQuestion : {
                    ...state.currentQuestion,
                    questionOptions: [
                        ...state.currentQuestion.questionOptions.map((option, x) => {
                            if(x === action.payload.targetIndex) {
                                option.points = action.payload.points;
                            }
                            return option;
                        })
                    ]
                }
            }
        case ActionTypes.CHANGE_TAB_FOR_QUESTIONOPTION:
            return {
                ...state,
                currentQuestion: {
                    ...state.currentQuestion,
                    questionOptions: [
                        ...state.currentQuestion.questionOptions.map((option, x) => {
                            if(x === action.payload.targetIndex) {
                                option.activeTab = action.payload.tab;
                            }
                            return option;
                        })
                    ]
                }
            }
        default:
            return state;
    }
}

export default reducer;
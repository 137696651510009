import React, { Component, useState } from 'react'
import './addImageStyles.css'
import { Dropzone } from '../../Dropzone/Dropzone';
import { confs } from '../../../modules/config';
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col} from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { Scrollbars } from 'react-custom-scrollbars';
import { getLeadingCommentRanges } from 'typescript';
import { executeRequest } from '../../requestHandler/dataHandler';

const styles = {
  addImage: 'addImage',
  addImagePopover: 'addImagePopover',
  addImageClosedPopover: 'addImageClosedPopover',
  addImageButton: 'addImageButton',
  addImagePressedButton: 'addImagePressedButton',
  addImageBottomGradient: 'addImageBottomGradient',
  addImageInput: 'addImageInput',
  addImageConfirmButton: 'addImageConfirmButton'
}

export default class ImageAdd extends Component {
  // Start the popover closed
  state = {
    url: '',
    open: false,
    selectImageModal: false,
    selectedImage: false,
    imageList: [],
    search: ""
  }

  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    document.addEventListener('click', this.closePopover)
    this.getImages()
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closePopover)
  }

  getImages = async() => {
    const response = await executeRequest('file/getEditorImages', {})
    this.setState({ imageList: response })
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick = () => {
    this.preventNextClose = true
  }

  openPopover = () => {
    if (!this.state.open) {
      this.preventNextClose = true
      this.setState({
        open: true
      })
    }
  }

  closePopover = () => {
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false,
      })
    }

    this.preventNextClose = false
  }

  addExistingImage = async() => {
    const { editorState, onChange } = this.props
    let payload = {
      fileId: this.state.selectedImage.id,
      documentId: this.props.documentId ,
      connectionType: this.props.connectionType 
    }
    const response = await executeRequest('get/imageUrlForEditor', payload)
    if(response.url){
      onChange(this.props.modifier(editorState, response.url))
      this.toggleImageModal()
    }
  }


  addImage = () => {
    const { editorState, onChange } = this.props
    onChange(this.props.modifier(editorState, this.state.url))
  }

  changeUrl = (evt) => {
    this.setState({ url: evt.target.value })
  }

  setFile = (file) => {
    this.handleDropzoneFiles(file)

  }

  handleUmlauts = (name) => {
      
    if(name){
        let newName = name
        newName = newName.replace(/ä/g, 'a');
        newName = newName.replace(/ö/g, 'o');
        newName = newName.replace(/å/g, 'o');
        newName = newName.replace(/Ä/g, 'A');
        newName = newName.replace(/Ö/g, 'O');
        newName = newName.replace(/Å/g, 'O');

        
        return newName
    }
}

  async handleDropzoneFiles( file ) { 
    const { editorState, onChange } = this.props

    if(!!file) {
      const formData = new FormData()
      let blob = file.slice(0, file.size, file.type); 

      let newFile = new File([blob], this.handleUmlauts(file.name), {type:file.type})

      formData.append( "file", newFile, newFile.name )
      formData.append( "jwtToken", window.sessionStorage.getItem("token") )
      formData.append( "type", "draft-js-image" )
      formData.append( "fileName", newFile.name)
      formData.append( "documentId", this.props.documentId )
      formData.append( "connectionType", this.props.connectionType )
      const resultJson = await fetch(confs.url + 'saveTextEditorImage', {
          method: 'POST',
          body: formData
      })
      const result = await resultJson.json();
      if (result.fileUrl) {
        onChange(this.props.modifier(editorState, result.fileUrl))
        this.closePopover()
      } 
    }
  }

  toggleImageModal = () => {
    this.setState({selectImageModal: !this.state.selectImageModal})
    if(this.state.selectedImage){
      this.setState({selectedImage: false})
      this.closePopover()
    }
  }

  handleNameFilter = (file) => {
    
    if(file.name && file.name.toUpperCase().includes(this.state.search.toUpperCase())) return true

    if(file.fileName && file.fileName.toUpperCase().includes(this.state.search.toUpperCase())) return true

    if(!file.name && !file.fileName && this.state.search === "") return true

    return false
  }

  render() {
    const popoverClassName = this.state.open ?
      styles.addImagePopover :
      styles.addImageClosedPopover
    const buttonClassName = this.state.open ?
      styles.addImagePressedButton :
      styles.addImageButton

    return (
      <>
        <Modal size="lg" isOpen={this.state.selectImageModal} toggle={() => this.toggleImageModal()}>
          <ModalHeader toggle={() => this.toggleImageModal()}>Valitse lisättävä kuva</ModalHeader>
          <ModalBody>
            {this.state.selectedImage ?
              <div style={{ marginTop: '1em', marginBottom: '2em' }} className="even-row">
                <Row>
                    <Col>
                      <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '200px', maxWidth: '400px', backgroundRepeat: 'no-repeat' }}>
                          <img style={{ maxHeight: '200px'}}  src={`${confs.host}/api/get/imagePreview/${this.state.selectedImage.id}`}></img>
                      </div>
                    </Col>
                </Row>
              </div>
              :
              <div> 
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5em', marginBottom: '0.5em', borderBottom: '1px solid #6ba53f' }}>
                  <BoxSearchBar  placeholder='Hae tiedostoa' value={ this.state.search } onChange={(e) => this.setState({ search: e })}/>
                </div>
                <Scrollbars
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                  style={{ width: "100%", height: '500px' }}
                  className="custom-scrollbars"
                >    
                  {this.state.imageList && this.state.imageList.length > 0 && this.state.imageList.filter(file => this.handleNameFilter(file)).map((image, key) => {
                    let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                    return(
                      <Row key={key} className={oddEvenClass + ' selectable-row'} onClick={()=> this.setState({ selectedImage: image})}>
                        <Col>
                          <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '70px', maxWidth: '300px', backgroundRepeat: 'no-repeat' }}>
                            <img style={{ maxHeight: '70px'}}  src={`${confs.host}/api/get/imagePreview/${image.id}`}></img>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            {image.fileName?
                              <p>{image.fileName}</p>
                              :
                              (image.name? 
                                <p>{image.name + "." + image.fileEnd}</p>
                                :
                                <p>Ei nimeä</p>
                              )
                            }
                          </div>
                        </Col>
                      </Row>
                    )
                  })}
                </Scrollbars>
              </div>
            }
          </ModalBody>
          <ModalFooter>
            {this.state.selectedImage ?
              <a onClick={() => this.setState({selectedImage: false})} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Takaisin</a>
              :
              <a onClick={() => this.toggleImageModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
            }
            {this.state.selectedImage &&
              <button onClick={() => this.addExistingImage()} className="small-white-button wide-button" >Lisää kuva</button>
            }
          </ModalFooter>
        </Modal>
        <div className={styles.addImage}>
          <button
            className={buttonClassName}
            onMouseUp={this.openPopover}
            type="button"
          >
            <i className="material-icons" style={{fontSize: '20px'}}>add_photo_alternate</i>
          </button>
          <div
            className={popoverClassName}
            onClick={this.onPopoverClick}
          >
            <label>Lisää kuva</label>
            <button onClick={() => this.toggleImageModal()} className="small-white-button wide-button active" style={{ width: '100%', marginBottom: '1em' }}>Valitse olemassa oleva</button>
            <label>Lisää url</label>
            <input
              type="text"
              placeholder="Kuvan URL"
              className={styles.addImageInput}
              onChange={this.changeUrl}
              value={this.state.url}
            />
            <button
              className={styles.addImageConfirmButton + ' small-white-button wide-button'}
              type="button"
              onClick={this.addImage}
              style={{ width: '100%', marginBottom: '1em' }}
            >
              {' Lisää '}
            </button>
            {this.props.allowFileUpload &&
              <>
                <label>tai valitse tietokoneelta</label>
                <FileWithDropzone 
                  setFile={this.setFile}
                /> 
              </>
            }
          </div>
        </div>
      </>
    )
  }
}

const FileWithDropzone = ({setFile}) => {
  return(
    <>
      <Dropzone
        setFile={setFile}
        acceptTypes='image/*'
        showFiles={false}
      />
    </>
  )
}
import React from 'react';
import moment from 'moment';
import { InputGroup, InputGroupAddon, Input} from 'reactstrap';
export const ModifiedContainer = ({modified, visible = true, modifiedBy}) => {
    return (
        <div className={visible ? "modified-container" : "modified-container private"}>
            {!visible && <span style={{fontWeight: 700, alignSelf: 'flex-end', color: 'red' }}>Yksityinen</span>}
            <div className="modified-data">
                <small style={{opacity: 0.6}}>
                    <b>{moment.utc( modified, 'MMM D, YYYY h:m:s A', 'en' ).local().format( 'DD.MM.YYYY HH:mm' )}</b>
                </small>
                <small style={{opacity: 0.6}}><b>{modifiedBy}</b></small>
            </div>
        </div>
    )
}

export const PublishedByContainer = ({publishedDate, publishedBy, copyPlanLink, linkRef, frontpageUrl, planId}) => {
    return (
        // <div className="modified-data published " style={{ display: 'flex', flexDirection: 'row', float:'right'}}>
        //     {/* <small style={{opacity: 0.6}}>
        //         <b>{moment.utc( publishedDate, 'MMM D, YYYY h:m:s A', 'en' ).local().format( 'DD.MM.YYYY HH:mm' )}</b>
        //     </small>
        //     <small style={{opacity: 0.6}}><b>{publishedBy}</b></small> */}
        //     {/* <input className="token"
        //         ref={linkRef}
        //         name="link"
        //         style={{ width: "100%", border: 'none', backgroundColor: 'transparent', cursor: 'pointer', zIndex:10}}
        //         value={`${frontpageUrl}/plan/${planId}`} readOnly
        //         onClick={() => document.queryCommandSupported('copy') && copyPlanLink()}
        //         type="text"
        //     /> */}
            
        //     {/* <div>
        //          <a href="#" title="Siirry julkaistuun lomakkeeseen" onClick={() => window.open(`${frontpageUrl}/plan/${planId}`, "_blank")}>{`${frontpageUrl}/plan/${planId}`}</a>
        //     </div> */}
        // </div>

    <InputGroup className="copy-link" style={{ marginTop: '1em' }}>
        <Input type="link" value={`${frontpageUrl}/plan/${planId}`} title={`${frontpageUrl}/plan/${planId}`} className="plan-link-container" onClick={() => window.open(`${frontpageUrl}/plan/${planId}`, "_blank")} readOnly/>
        <InputGroupAddon addonType="append">
            <button className="lighter-text wide-button copy-plan-link-button" title="Kopioi linkki" onClick={() => document.queryCommandSupported('copy') && copyPlanLink(`${frontpageUrl}/plan/${planId}`)}>
                <i className="far fa-copy" style={{ zIndex:10}}></i>  
            </button>
        </InputGroupAddon>
    </InputGroup>
    )
}
import React, { useState, useEffect, useRef } from 'react';
import { HorizontalContainer, HorizontalList, HorizontalItem } from '../HorizontalElements';
import { ContactCard, ProjectCards, ClientTaskCards, ClientPlanCards, SurveyResultCards, HubSpotContactCard, HubSpotDealCard, ActiveClientCard, ExcelFieldCard, ActivatedSurveyCard } from '../HorizontalCards';
import TaskCardModal from '../../../Components/Reports/Dashboard/TaskCards/TaskCardsMainBoard/TaskCardModal.js';
import { executeRequest } from '../../requestHandler/dataHandler';
import { useDispatch, useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications';
import { storeTasks, storeTask, updateTaskStatus } from '../../../store/index'
import { ExcelDataModal } from '../../Modals/ExcelDataModal';
import {addTask} from '../../HelperFunctions/fetchPhaser'
import { VerticalList, VerticalContainer, VerticalItem } from '../../ListView/ListElements';
import { PlanListItem, ProjectListItem, TaskListItem, AnswerListItem, HubContactListItem, HubDealListItem, ActivatedSurveyItem, ContactListItem } from '../../ListView/ListItems';
import * as ActionCreator from '../../../store/index';
import * as PlanActionCreator from '../../../Components/planBuilder/Actions/ActionCreator'
import { SentSurveyOptions } from '../../../Components/Dashboard/subComponents/SentSurveyOptions';
import { storeContacts } from '../../../store/actions/Contacts/ActionCreator';
import moment from 'moment';
import { ModalHeader } from 'reactstrap';
import { DashboardGantt } from '../../../Components/Dashboard/subComponents/DashboardGantt';
import Timeline from 'react-timelines';
import 'react-timelines/lib/css/style.css'
import {ProjectTimelineBuilder} from '../../ProjectTimeline/ProjectTimelineBuilder';

export const ActiveCompanies = ({ selectBusiness, search = ""}) => {
    const [activeCompanies, setActiveCompanies] = useState([])

    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    const isMounted = useRef(true);

    useEffect(() => {
        const fetchTasks = async params => {
            const response = await addTask('get/activeBusinessList', {}, dispatch)
            setTimeout(() => {
            if (!!response) {
              if (isMounted.current) {
                setActiveCompanies(response.activeList);
              }
            }

            if (isMounted.current) setIsLoading(false) 
          }, 200)
        }

        fetchTasks();

        return () => {
            isMounted.current = false;
        }
    }, [])

    return (
        <HorizontalContainer label={"Aktiiviset asiakkaat (" + activeCompanies.length +")"} >
            <HorizontalList>
                {activeCompanies.length === 0 ?
                    <EmptyList isLoading={isLoading} />
                    :
                    activeCompanies.filter(company => company.name.toUpperCase().includes(search.toUpperCase())).map(business =>
                        <HorizontalItem key={business.id}>
                            <ActiveClientCard business={business} select={selectBusiness} />
                        </HorizontalItem>
                    )}
            </HorizontalList>
        </HorizontalContainer>
    )
}
export const Tasks = ({ userInfo, location, selectedBusinessId, selectReport, activeType, toggleType, search = "" }) => {
    const [tasks, setTasks] = useState([]);
    const [statusFilters, setFilters] = useState([{
        value: 'Avoin',
        filter: 'Avoin'
    }]);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTask, setSelectedTask] = useState({});
    const dispatch = useDispatch();

    const isMounted = useRef(true);


    useEffect(() => {
        let businessId = selectedBusinessId ? selectedBusinessId : location.selectedBusinessId

        if (userInfo.userType === 'business' && userInfo.businessId) businessId = userInfo.businessId

        const fetchTasks = async params => {
            const response = await addTask('get/tasksForDashboard', { businessId: businessId }, dispatch)
            setTimeout(() => {
            if (!!response) {
              if (isMounted.current) {
                setTasks(response);
                dispatch(storeTasks(response));
              }
            }

            if (isMounted.current) setIsLoading(false) 
          }, 200)
        }

        fetchTasks();

        return () => {
            isMounted.current = false;
        }
    }, [])

    const changeTaskStatus = async payload => {
        const response = await executeRequest('modify/taskCardV2', payload, dispatch)

        if (!!response) {
            if (isMounted.current && (response.status === "updated" || response.status === "declined")) {
                setTasks(tasks => tasks.map(task => {
                    if (task._id === response.modifiedTask._id)
                        return {
                            ...task,
                            status: response.modifiedTask.status
                        }
                    return task
                }))

                setOpenModal(false);
                NotificationManager.success("Muutokset tallennettu", "", 2000);
            }
        }

    }

    const statusFilterHandler = (task, filters) => {
        if (filters.length === 0) {
            return true;
        } else if (filters.filter(x => {
            if (x.filter === task.status)
                return true
            else if (x.filter === "Avoin" && task.status === 'inactive')
                return true
            else
                return false
        }).length > 0) {
            return true;
        }

        return false;
    }

    const selectTask = task => {
        setSelectedTask(task);
        setOpenModal(true);
    }

    const isSurveyIncluded = task => {

        const { taskTree } = task;

        let isIncluded = false;
        if (taskTree !== undefined && Object.keys(taskTree).length > 0) {
            const { surveyTitles } = taskTree;

            surveyTitles.forEach((surveyTitle, x) => {
                const { title } = surveyTitle
                if (title.toUpperCase().includes(search.toUpperCase())) {
                    isIncluded = true;
                }
            })
        } else if (task.planName) {
            isIncluded = task.planName.toUpperCase().includes(search.toUpperCase());
        }

        return isIncluded;
    }

    return (
        <>
            {activeType==='cards'?
                <HorizontalContainer label={"TARKISTUSPISTEET (" + tasks.filter(t => statusFilterHandler(t, statusFilters)).filter(task => (task.taskTitle && task.taskTitle.toLowerCase().includes(search.toLowerCase())) || isSurveyIncluded(task)).length +")"} filter filters={statusFilters} setStatusFilters={setFilters} activeType={activeType} toggleType={toggleType} listName={"tasks"}>
                    {openModal &&
                        <TaskCardModal
                            isOpen={openModal}
                            toggler={() => setOpenModal(!openModal)}
                            task={selectedTask}
                            updateTaskStatus={changeTaskStatus}
                            selectReport={selectReport} />
                    }
                    <HorizontalList>
                        {tasks.length > 0 ?
                            tasks.filter(t => statusFilterHandler(t, statusFilters)).map((task, index) => {
                                if ((task.taskTitle &&
                                    task.taskTitle.toLowerCase().includes(search.toLowerCase())) ||
                                    isSurveyIncluded(task)) {
                                    return <HorizontalItem key={task._id}>
                                        <ClientTaskCards task={task} openTask={selectTask} />
                                    </HorizontalItem>
                                }
                            }) : <EmptyList isLoading={isLoading} />}
                    </HorizontalList>
                </HorizontalContainer>
                :
                <VerticalContainer label={"TARKISTUSPISTEET (" + tasks.filter(t => statusFilterHandler(t, statusFilters)).filter(task => (task.taskTitle && task.taskTitle.toLowerCase().includes(search.toLowerCase())) || isSurveyIncluded(task)).lengt +")"} filter filters={statusFilters} setStatusFilters={setFilters} activeType={activeType} toggleType={toggleType} listName={"tasks"}>
                    {(tasks && tasks.filter(t => statusFilterHandler(t, statusFilters)).length > 0) ? 
                        <VerticalList>
                            {tasks.filter(t => statusFilterHandler(t, statusFilters)).map((task, key) => {
                                if ((task.taskTitle && task.taskTitle.toLowerCase().includes(search.toLowerCase())) || isSurveyIncluded(task)) {
                                    let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                                    return( 
                                        <VerticalItem key={task._id}>
                                            <TaskListItem key={key} task={task} openTask={selectTask} oddEvenClass={oddEvenClass}/>
                                        </VerticalItem>
                                    )
                                }
                            })}
                        </VerticalList>
                        :
                        <EmptyList isLoading={isLoading} />
                    }
                </VerticalContainer>
        }
      </>
    )
}

export const ProjectTimeline = ({history, newProject, businessId, activeType, toggleType , showAddNewOnList = null, search = "", modulesInUse, dashboardMain}) => {
    const [projects, setProjects] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [filteredProjects, setFilteredProjects] = useState([])

    useEffect(() => {       
        const fetchProjects = async() => {
            let params = {
                businessId: businessId || null,
                getGanttPlans: true
            }
            const response = await executeRequest('get/projectsForDashboard', params)
            
            if (!!response) {
                setIsLoading(false)
                if (isMounted.current && response.projectList){                    
                    setProjects(response.projectList);
                }
            } else {
                NotificationManager.error("Lomakkeiden haku epäonnistui", "", 3000);
            }      
            if (isMounted.current) setIsLoading(false) 
            
        }

        fetchProjects();

        return () => {
            isMounted.current = false;
        }
    }, [])

    const handleListNameFilter = project => {
        const {projectName, tags, targetBusiness, modified} = project

        if(projectName && projectName.toUpperCase().includes(search.toUpperCase())) return true
        if(targetBusiness && targetBusiness.label !== undefined && targetBusiness.label.toUpperCase().includes(search.toUpperCase())) return true
        // else if(selectedBusiness !== undefined) {
        //     const {value, label} = selectedBusiness

        //     if(label.toUpperCase().includes(search.toUpperCase())) {
        //         return true
        //     } 
        // } 

        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        if(modified !== undefined){
            let edited = moment.utc(modified,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(edited === search) return true
            if(search.includes("-")){
                let dates = search.split("-")
                const [d0, m0, y0] = edited.split(".")
                const [d1, m1, y1] = dates[0].split(".")
                const [d2, m2, y2] = dates[1].split(".")
                let firstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y0+"."+m0+"."+d0))
                let secondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y0+"."+m0+"."+d0))
                // console.log(edited,moment(new Date(y1+"."+m1+"."+d1)), moment(new Date(y2+"."+m2+"."+d2)), firstOk, secondOk);
                if( firstOk && secondOk )return true
                else return false
            }
        }

        return false
    }


    return(
        <>
          <ProjectTimelineBuilder projects={projects} history={history}/>
        </>
    )

}

export const Projects = ({history, newProject, businessId, activeType, toggleType , showAddNewOnList = null, search = "", modulesInUse, dashboardMain}) => {
    const [projects, setProjects] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [filteredProjects, setFilteredProjects] = useState([])

    const [projectFilters,setProjectFilters] = useState([])
    const customProjectFilters = [
        { value: 'Avoin', filter: "#c3c3c3" },
        { value: 'Käynnissä', filter: "#ffcc00" },
        { value: 'Valmis', filter: "#b5e413" },
        { value: 'Suljettu', filter: "#ed5652" }
    ]

    useEffect(() => {
        let storedFilters = window.localStorage.getItem('projectFilters') 
        if(storedFilters && storedFilters.length >0 ){
            setProjectFilters(JSON.parse(storedFilters))
        }
       
        const fetchProjects = async() => {
            let params = {
                businessId: businessId || null
            }
            const response = await addTask('get/projectsForDashboard', params, dispatch)
            setTimeout(() => {
                if (!!response) {
                    setIsLoading(false)
                    if (isMounted.current && response.projectList)
                        setProjects(response.projectList);
                } else {
                    NotificationManager.error("Lomakkeiden haku epäonnistui", "", 3000);
                }      
                if (isMounted.current) setIsLoading(false) 
            }, 200)
        }

        fetchProjects();

        return () => {
            isMounted.current = false;
        }
    }, [])

    const handleListNameFilter = project => {
        const {projectName, tags, targetBusiness, modified} = project

        if(projectName && projectName.toUpperCase().includes(search.toUpperCase())) return true
        if(targetBusiness && targetBusiness.label !== undefined && targetBusiness.label.toUpperCase().includes(search.toUpperCase())) return true
        // else if(selectedBusiness !== undefined) {
        //     const {value, label} = selectedBusiness

        //     if(label.toUpperCase().includes(search.toUpperCase())) {
        //         return true
        //     } 
        // } 

        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        if(modified !== undefined){
            let edited = moment.utc(modified,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(edited === search) return true
            if(search.includes("-")){
                let dates = search.split("-")
                const [d0, m0, y0] = edited.split(".")
                const [d1, m1, y1] = dates[0].split(".")
                const [d2, m2, y2] = dates[1].split(".")
                let firstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y0+"."+m0+"."+d0))
                let secondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y0+"."+m0+"."+d0))
                // console.log(edited,moment(new Date(y1+"."+m1+"."+d1)), moment(new Date(y2+"."+m2+"."+d2)), firstOk, secondOk);
                if( firstOk && secondOk )return true
                else return false
            }
        }

        return false
    }
    
    const goToProject = (projectId) => {
        history.push({
            pathname:'/projektit/hallitse',
            state: {
                projectId:projectId
            }
        })
    }

    const goToPlan = (projectName, projectId, planId) => {
        dispatch(PlanActionCreator.startEditingPlan(planId, null))
        
        history.push({
            pathname:'/lomakkeet/luonti',
            state:{
                crumbs:{
                    to: 'projektit/hallitse', label: projectName
                }, 
                projectId:projectId
            }
        })
    }

    const filterProjects = (project) => {
        let isMatch = false

        if(projectFilters.length > 0){           
            projectFilters.map( filter => {
                if(filter.filter && project.status && project.status.value.toUpperCase().includes(filter.filter.toUpperCase())){isMatch = true}
            })
        
        } else isMatch = true
        
        return isMatch
    }

    useEffect(()=>{
        let storedFilters =  window.localStorage.getItem('projectFilters') 

        if(projects.length > 0 && projectFilters.length > 0){
            let filtered = projects.filter(project => filterProjects(project)).filter(p => handleListNameFilter(p))
            setFilteredProjects(filtered) 
        } else {
            setFilteredProjects(projects.filter(p => handleListNameFilter(p)))
        }

        if(storedFilters !== projectFilters){
            window.localStorage.setItem('projectFilters', JSON.stringify(projectFilters)) 
        }

    },[projects, projectFilters, search])

    return(
        <>
            {modulesInUse && dashboardMain && modulesInUse.ganttEnabled && activeType === 'gantt' ? 
                <DashboardGantt activeType={activeType} toggleType={toggleType} goToProject={goToProject} goToPlan={goToPlan}/>
                :            
                (activeType === 'cards' ?
                    <HorizontalContainer label={"PROJEKTIT (" + filteredProjects.length +")"} addNew={newProject} project={true} activeType={activeType} toggleType={toggleType} listName={"projects"} showAddNewOnList={showAddNewOnList} filter filters={projectFilters} setStatusFilters={setProjectFilters} customFilters={customProjectFilters} ganttOption={(dashboardMain && modulesInUse.ganttEnabled)? true : false}>
                            <HorizontalList>
                                {filteredProjects && filteredProjects.length > 0 ? 
                                    filteredProjects.map((project,key) =>
                                        <HorizontalItem key={key}>
                                            <ProjectCards project={project} goToProject={goToProject}/>
                                        </HorizontalItem>
                                    ) 
                                    : (
                                        <EmptyList isLoading={isLoading} />
                                    )}
                            </HorizontalList>
                    </HorizontalContainer>
                    : 
                    <VerticalContainer label={"PROJEKTIT (" + filteredProjects.length +")"} addNew={newProject} project={true} activeType={activeType} toggleType={toggleType} listName={"projects"} showAddNewOnList={showAddNewOnList} filter filters={projectFilters} setStatusFilters={setProjectFilters} customFilters={customProjectFilters} ganttOption={(dashboardMain && modulesInUse.ganttEnabled)? true : false}>
                        {(filteredProjects && filteredProjects.length > 0) ?
                            <VerticalList>
                                {filteredProjects.map((project,key) =>{
                                let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                                    return(
                                        <VerticalItem key={key}>
                                            <ProjectListItem key={key} project={project} goToProject={goToProject} oddEvenClass={oddEvenClass}/>
                                        </VerticalItem>
                                )})
                            }
                            </VerticalList>
                            :
                            <EmptyList isLoading={isLoading} />
                        }
                    </VerticalContainer>
                    
                    )
                }
        </>
    )
}
export const ActivatedSurveysList = (props) => {
    const {activeType, toggleType, history, search} = props
    const sentSurveys = useSelector(state => state.senderV2.sentSurveysForDashboard)
    // const [sentSurveys, setSentSurveys] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [surveyOptionsModal, setSurveyOptionsModalOpen] = useState(false)
    const selectedSurvey = useSelector(state => state.senderV2.selectedSentSurvey)
    const [statusFilters, setFilters] = useState([{
        value: 'Avoin',
        filter: 'Avoin'
    }])
    const isMounted = useRef(true);
    const dispatch = useDispatch();

    useEffect(() => {
       
        const fetchActivatedSurveys = async() => {
            
            const response = await addTask('get/sentSurveysForDashboard', {}, dispatch)
            setTimeout(() => {
                if (!!response) {
                    setIsLoading(false)
                    if (isMounted.current)
                        // setSentSurveys(response);
                        dispatch(ActionCreator.updateSentSurveysForDashboard(response))
                } else {
                    NotificationManager.error("Kartoitusten haku epäonnistui", "", 3000);
                }      
                if (isMounted.current) setIsLoading(false)
            }, 200)
        }

        fetchActivatedSurveys();

        return () => {
            isMounted.current = false;
        }
    }, [])

    
    const handleListNameFilter = survey => {
        const {name, tags, businessName, dates} = survey

        if(name && name.toUpperCase().includes(search.toUpperCase())) return true

        if(businessName && businessName.toUpperCase().includes(search.toUpperCase())) return true

        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        if(dates !== undefined){
            let range = dates[dates.length-1]
            let start = moment.utc(range.startDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            let end = moment.utc(range.endDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(search.includes("-")){
                let dates = search.split("-")
                const [d01, m01, y01] = start.split(".")
                const [d02, m02, y02] = end.split(".")
                const [d1, m1, y1] = dates[0].split(".")
                const [d2, m2, y2] = dates[1].split(".")
                let firstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y01+"."+m01+"."+d01))
                let secondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y02+"."+m02+"."+d02))
                // console.log(edited,moment(new Date(y1+"."+m1+"."+d1)), moment(new Date(y2+"."+m2+"."+d2)), firstOk, secondOk);
                if( firstOk && secondOk )return true
                else return false
            }
        }

        return false
    }

    const selectReport = (obj) => {
        dispatch(ActionCreator.updateSelectedSentSurvey(obj))
        //setSelectedSurvey(obj)
        setSurveyOptionsModalOpen(true)

    }
    
    // useEffect(()=>{
    //     setSentSurveys(useSelector)
    // },[])
const state = useSelector(state=> state)
    return(
        <>
            <SentSurveyOptions
                isOpen={surveyOptionsModal}
                closeModal={setSurveyOptionsModalOpen}
                survey={selectedSurvey}
                history={history}
            />
            {sentSurveys && 
                <>
                    {activeType === 'cards'?
                        <HorizontalContainer label={"AKTIIVISET KARTOITUKSET (" + sentSurveys.filter(survey => handleListNameFilter(survey)).length +")"} activeType={activeType} toggleType={toggleType} listName={"surveys"}>
                            <HorizontalList>
                                {sentSurveys.filter(survey => handleListNameFilter(survey)).length > 0 ? 
                                    sentSurveys.filter(survey => handleListNameFilter(survey)).map((survey, key) =>
                                        <HorizontalItem key={key}>
                                            <ActivatedSurveyCard survey={survey} goToReport={selectReport}/>
                                        </HorizontalItem>
                                    ) 
                                    : (
                                        <EmptyList isLoading={isLoading} />
                                    )}
                            </HorizontalList> 
                        </HorizontalContainer>
                        : 
                        <VerticalContainer label={"AKTIIVISET KARTOITUKSET (" + sentSurveys.filter(survey => handleListNameFilter(survey)).length +")"} activeType={activeType} toggleType={toggleType} listName={"surveys"}>
                            {(sentSurveys.length > 0 && sentSurveys.filter(survey => handleListNameFilter(survey)).length > 0) ?
                                <VerticalList>
                                    {sentSurveys.length > 0 && sentSurveys.filter(survey => handleListNameFilter(survey)).map((survey, key) => {
                                    let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                                    return(
                                        <VerticalItem key={key}>
                                            <ActivatedSurveyItem key={key} survey={survey} oddEvenClass={oddEvenClass} goToReport={selectReport}/>
                                        </VerticalItem>
                                    )})}
                                </VerticalList>
                                :
                                <EmptyList isLoading={isLoading} />
                            }
                        </VerticalContainer>
                    }
                </>
            }
        </> 
    )
}
export const Contacts = ({businessId=null, newContact, showAddNewOnList = null, activeType, toggleType, search = ""}) => {

    const [isLoading, setIsLoading] = useState(true);
    
    const contacts = useSelector(state => state.contacts.contacts)

    const dispatch = useDispatch()

    useEffect(() => {
        getContactsToDashboard()
    },[businessId])

    const getContactsToDashboard = async() => {
        let payload = {}
        if(businessId !== null){
            payload = {
                targetBusiness: businessId
            }
        }
        const response = await executeRequest('business/getContactInfo', payload)
        if(response.contacts){
            dispatch(storeContacts(response.contacts))
        }
        setIsLoading(false)
    }

    const handleListNameFilter = contact => {
        const {business, tags, name, jobTitle} = contact
        let businessName = (business && business.businessName) ? business.businessName : ""

        if(businessName && businessName.toUpperCase().includes(search.toUpperCase())) return true
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        if(jobTitle && jobTitle.toUpperCase().includes(search.toUpperCase())) return true
        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    return(
        <>
            {contacts && contacts.length > 0 &&
                (activeType === "cards" ?
                    <HorizontalContainer label={"KONTAKTIT (" + contacts.filter(p => handleListNameFilter(p)).length +")"} showAddNewOnList={showAddNewOnList} addNew={newContact} listName={"contacts"}  activeType={activeType} toggleType={toggleType}>
                        <HorizontalList>
                            {contacts.filter(ctct => handleListNameFilter(ctct)).map((contact,key) =>
                                <HorizontalItem key={key}>
                                    <ContactCard contact={contact} />
                                </HorizontalItem>
                            )}
                        </HorizontalList>
                    </HorizontalContainer>
                    :
                    <VerticalContainer label={"KONTAKTIT (" + contacts.filter(p => handleListNameFilter(p)).length +")"} showAddNewOnList={showAddNewOnList} addNew={newContact} listName={"contacts"} activeType={activeType} toggleType={toggleType}>
                        {contacts && contacts.filter(p => handleListNameFilter(p)).length > 0 ?
                            <VerticalList>
                                {contacts.filter(p => handleListNameFilter(p)).map((contact, key) => {
                                    let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                                    return(
                                        <VerticalItem key={key}>
                                            <ContactListItem contact={contact} oddEvenClass={oddEvenClass}/>
                                        </VerticalItem>
                                
                                )})}
                            </VerticalList>
                            :
                            <EmptyList isLoading={isLoading} />
                        }
                    </VerticalContainer>
                )
            }
        </>
    )
}

export const Plans = ({ goToPlan, businessPage, userInfo, location, selectedBusinessId, newPlan, planList = false, activeType, toggleType, showAddNewOnList = null, search = ""}) => {
    const [plans, setPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredPlans, setFilteredPlans] = useState([])
    const dispatch = useDispatch();

    const isMounted = useRef(true);
    
    useEffect(() => {
        let businessId = selectedBusinessId
        let payload = {}
        if (location && location.selectedBusinessId) businessId = location.selectedBusinessId
        if (userInfo && userInfo.userType === 'business' && userInfo.businessId) businessId = userInfo.businessId
        if(businessId){
            payload = {
                businessId: businessId
            }
        }

        const fetchPlans = async params => {
            const response = await addTask('plan/getFirstPlans', payload, dispatch)
            setTimeout(() => {
                if (!!response) {
                    if (isMounted.current && response.reports)
                        setPlans(response.reports);
                } else {
                    NotificationManager.error("Lomakkeiden haku epäonnistui", "", 3000);
                }      
                if (isMounted.current) setIsLoading(false) 
            }, 200)
        }

        fetchPlans();
        
        return () => {
            isMounted.current = false;
        }
    }, [])

    const handleListNameFilter = plan => {
        const {planName, selectedBusiness, tags, lastEdited, modified} = plan

        if(planName && planName.toUpperCase().includes(search.toUpperCase())) return true
        if(selectedBusiness !== undefined) {
            const {value, label} = selectedBusiness

            if(label.toUpperCase().includes(search.toUpperCase())) {
                return true
            } 
        }

        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        if(lastEdited !== undefined){
            let edited = moment.utc(lastEdited,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(edited === search) return true
            if(search.includes("-")){
                let dates = search.split("-")
                const [d0, m0, y0] = edited.split(".")
                const [d1, m1, y1] = dates[0].split(".")
                const [d2, m2, y2] = dates[1].split(".")
                let firstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y0+"."+m0+"."+d0))
                let secondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y0+"."+m0+"."+d0))
                // console.log(edited,moment(new Date(y1+"."+m1+"."+d1)), moment(new Date(y2+"."+m2+"."+d2)), firstOk, secondOk);
                if( firstOk && secondOk )return true
                else return false
            }
        } else {
            let edited = moment.utc(modified,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(edited === search) return true
            if(search.includes("-")){
                let dates = search.split("-")
                const [d0, m0, y0] = edited.split(".")
                const [d1, m1, y1] = dates[0].split(".")
                const [d2, m2, y2] = dates[1].split(".")
                let firstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y0+"."+m0+"."+d0))
                let secondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y0+"."+m0+"."+d0))
                // console.log(edited,moment(new Date(y1+"."+m1+"."+d1)), moment(new Date(y2+"."+m2+"."+d2)), firstOk, secondOk);
                if( firstOk && secondOk )return true
                else return false
            }
            // return false
        }

        return false
    }

    useEffect(()=>{
        let filtered = plans.filter(p => handleListNameFilter(p))
        setFilteredPlans(filtered)
    },[search, plans])

    return (
        <>
            
            {activeType === 'cards' ?
                <HorizontalContainer label={"LOMAKKEET (" + filteredPlans.length +")"} addNew={newPlan} selectedBusiness={selectedBusinessId} activeType={activeType} toggleType={toggleType} listName={"forms"} showAddNewOnList={showAddNewOnList}>
                    <HorizontalList>
                        {plans.filter(p => handleListNameFilter(p)).length > 0? plans.filter(p => handleListNameFilter(p)).map(plan =>
                            <HorizontalItem key={plan._id}>
                                <ClientPlanCards plan={plan} goToPlan={goToPlan} businessPage={businessPage} />
                            </HorizontalItem>
                        ) : (
                                <EmptyList isLoading={isLoading} />
                            )}
                    </HorizontalList> 
                </HorizontalContainer>
                : 
                <VerticalContainer label={"LOMAKKEET (" + filteredPlans.length +")"} addNew={newPlan} selectedBusiness={selectedBusinessId} activeType={activeType} toggleType={toggleType} listName={"forms"} showAddNewOnList={showAddNewOnList}>
                    {plans.filter(p => handleListNameFilter(p)).length > 0? 
                        <VerticalList listLength={plans.filter(p => handleListNameFilter(p)).length}>
                            {plans.filter(p => handleListNameFilter(p)).map((plan, key) => {
                                let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                                return(
                                    <VerticalItem key={plan._id}>
                                        <PlanListItem key={key} plan={plan} goToPlan={goToPlan} oddEvenClass={oddEvenClass}/>
                                    </VerticalItem>
                                )
                            })}
                        </VerticalList>
                        :
                        <EmptyList isLoading={isLoading} />
                    }
                </VerticalContainer>
            }
           
        </>
    )
}

export const HubSpotContacts = ({ hubSpot, openSettings, activeType, toggleType, business }) => {
    const { hubSpotId, hubSpotCompanyId } = hubSpot;
    const [contacts, setContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();

    const isMounted = useRef(true);

    const ebContacts = useSelector(state => state.contacts.contacts)

    useEffect(() => {
        return () => {
            isMounted.current = false;
           
        }
    }, [])

    useEffect(() => {
        if (!isLoading) setIsLoading(true);

        const fetchHubspotContacts = async params => {
            const response = await addTask('hubspot/get/contacts', params, dispatch)
            setTimeout(() => {
                if (!!response) {
                    if (isMounted.current && response.message === undefined) {
                        setContacts(response.identities || []);
                    } else {
                        setErrorMessage(response.message);
                        NotificationManager.error(response.message, "HubSpot Kontaktit", 4000);
                    }
                } else {
                    NotificationManager.error("HubSpot kontaktien haku epäonnistui", "", 3000);
                } 
                if (isMounted.current) setIsLoading(false);
            }, 200)
        }

        fetchHubspotContacts({ hubSpotId: hubSpotId });
    }, [hubSpotCompanyId]);

    const fullNameFilter = contact => {
        const { firstName, lastName, value } = contact

        let fullName = firstName + " " + lastName;

        if (fullName.toUpperCase().includes(search.toUpperCase()) || value.toUpperCase().includes(search.toUpperCase())) {
            return true;
        }

        return false;
    }

    const getEbContacts = async() => {
        let payload = {}
        if(business.id !== null){
            payload = {
                targetBusiness: business.id
            }
        }
        const response = await executeRequest('business/getContactInfo', payload)
        if(response.contacts){
            dispatch(storeContacts(response.contacts))
        }
        setIsLoading(false)
    }

    const createAsContact = async(contact) => {   
        let payload = {
            contact: {
                email: contact.email,
                business: business,
                phone: contact.phone || "" ,
                name: contact.firstName + " " + contact.lastName ,
                role: "",
                tags:[]
            }
        } 
        if(contact){
            const response = await executeRequest('business/saveContactInfo', payload)
            if(response.success){
                NotificationManager.success("Luotu onnistuneesti!", "", 3000);
                getEbContacts()
                
            }
        } else {
            NotificationManager.error("Kontakti on jo olemassa", "Virhe", 3000);
        }
    }

    return (
        <>
            { activeType === 'cards' ?
                <HorizontalContainer label={"HubSpot Kontaktit (" + contacts.length +")"} setFilter={setSearch} searchFilter={search} openSettings={openSettings} activeType={activeType} toggleType={toggleType} listName={"hubContacts"}>
                    <HorizontalList>
                        {(isLoading || contacts.length === 0) ?
                            <EmptyList isLoading={isLoading} error={errorMessage} />
                            :
                            contacts.filter(contact => fullNameFilter(contact)).map((result, key) =>{
                                let exists = false
                                if(ebContacts.filter(con => con.email === result.email).length > 0){
                                    exists = true
                                } else {
                                    exists = false
                                }
                                return(
                                    <HorizontalItem key={key}>
                                        <HubSpotContactCard contact={result} createAsContact={createAsContact}  exists={exists}/>
                                    </HorizontalItem>
                                )
                            })
                        }
                    </HorizontalList>
                </HorizontalContainer>
                :
                <VerticalContainer label={"HubSpot Kontaktit (" + contacts.length +")"} setFilter={setSearch} searchFilter={search} openSettings={openSettings} activeType={activeType} toggleType={toggleType} listName={"hubContacts"}>
                    <VerticalList>
                        {contacts.length > 0 ? contacts.filter(contact => fullNameFilter(contact)).map((result, key) => {
                            let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                            return(
                                <VerticalItem key={result.vid}>
                                    <HubContactListItem key={key} contact={result} oddEvenClass={oddEvenClass} createAsContact={createAsContact}/>
                                </VerticalItem>
                            )
                        })
                        : 
                        (
                            <EmptyList isLoading={isLoading} />
                        )}
                    </VerticalList>
                </VerticalContainer>
            }
        </>
    )
}

export const HubSpotDeals = ({ hubSpot, openSettings, updateBusinessValues, activeType, toggleType }) => {
    const { hubSpotId, hubSpotCompanyId, keyStatus } = hubSpot;
    const [deals, setDeals] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [selectedPipeline, setSelectedPipeLine] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [statusFilters, setFilters] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [stageLabels, setStageLabels] = useState([]);
    const dispatch = useDispatch();

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        if (!isLoading) setIsLoading(true);

        const fetchHubspotPipeLines = async params => {
            const response = await addTask('hubspot/get/pipelines', params, dispatch)
            setTimeout(() => {
                if (!!response) {
                    if (isMounted.current) {
                        if (response.message === undefined) {
                            setPipelines(response.pipelines);
    
                            response.pipelines && response.pipelines.map(pipeline => {
                                if (pipeline.id === "default") {
                                    const { stages } = pipeline;
                                    let customFilters = stages.filter(stage => stage.label !== undefined).map(s => {
                                        return {
                                            value: s.label,
                                            filter: s.label
                                        }
                                    });
                                    setStageLabels(customFilters);
                                    setSelectedPipeLine(pipeline);
                                }
                            })
                        } else {
                            setErrorMessage(response.message);
                            NotificationManager.error(response.message, "HubSpot Kanavat", 4000);
                        }
                    }
                } else {
                    NotificationManager.error("HubSpot kanavien haku epäonnistui", "", 3000);
                }
                if (isMounted.current) setIsLoading(false);
            }, 200)
        }

        const fetchHubSpotDeals = async params => {           
            const response = await addTask('hubspot/get/deals', params, dispatch)
            setTimeout(async() => {
                if (!!response) {
                    if (isMounted.current) {
                        if (response.message === undefined) {
                            setDeals(response.deals || []);

                            if (response.deals !== undefined && response.deals.length === 0) {
                                let values = {
                                    closedValues: 0,
                                    inProgressValues: 0
                                }
                                updateBusinessValues(values);
                                //Send to redux
                            } else {
                                    let values = {
                                        closedValues: await calculateClosedValue(response.deals),
                                        inProgressValues: await calculateInprogressValues(response.deals)
                                    }
                                    updateBusinessValues(values);
                                //Send to redux
                            }
                        } else {
                            setErrorMessage(response.message);
                            NotificationManager.error(response.message, "HubSpot Diilit", 4000);
                        }
                    }
                } else {
                    NotificationManager.error("HubSpot diilien haku epäonnistui", "", 3000);
                }
                if (isMounted.current) setIsLoading(false);
            }, 200)
        }

        fetchHubspotPipeLines({ hubSpotId: hubSpotId });
        fetchHubSpotDeals({ hubSpotId: hubSpotId });

    }, [hubSpotCompanyId]);

    const getStageLabel = (deal) => {
        const { pipeline, dealstage } = deal;
        let stageLabel = "Tuntematon";

        if (pipeline !== undefined && pipeline === selectedPipeline.id) {
            if (selectedPipeline.stages) {
                const { stages } = selectedPipeline;

                stages.map(stage => {
                    if (dealstage === stage.id) {
                        stageLabel = stage.label;
                    }
                })
            }
        }

        return stageLabel;
    }

    const changePipe = (event) => {
        const { name, value } = event.target;

        pipelines.map(pipe => {
            if (pipe.id === value) {
                const { stages } = pipe;
                let customFilters = stages.filter(stage => stage.label !== undefined).map(s => {
                    return {
                        value: s.label,
                        filter: s.label
                    }
                });
                setFilters([]);
                setStageLabels(customFilters);

                setSelectedPipeLine(pipe);
            }
        })
    }

    const calculateClosedValue = async (deals) => {
        let value = 0;

        const closedDeals = deals && deals.filter(d => d.isClosed);

        closedDeals && closedDeals.map(cd => {
            const { closed_amount } = cd
            value += parseInt(closed_amount)
        })

        return value;
    }

    const calculateInprogressValues = async deals => {
        let value = 0;

        const inProgressDeals = deals && deals.filter(d => !d.isClosed);
        inProgressDeals && inProgressDeals.map(cd => {
            const { amount } = cd
            value += parseInt(amount)
        })
        return value;
    }
    const dealNameFilter = deal => {
        const { dealname, updatedAt } = deal;

        if (dealname.toUpperCase().includes(search.toUpperCase())) return true
        try{
            let modified = moment.utc(updatedAt,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(search.includes("-")){
                let dates = search.split("-")
                
                const [d1, m1, y1] = dates[0].split(".")
                const [d2, m2, y2] = dates[1].split(".")
                let dateOne = moment.utc(new Date(y1+"."+m1+"."+d1),"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
                let dateTwo = moment.utc(new Date(y2+"."+m2+"."+d2),"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
                // console.log("y", (moment.utc(new Date(y1+"."+m1+"."+d1),"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY') > moment.utc(updatedAt,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')));
                if(dateOne <= modified && dateTwo >= modified )return true
                else return false
            }
            if(modified.includes(search)) return true
        }catch(ex) {
            return false
        }
        
        return false
    }

    const stageFilter = deal => {
        return statusFilters.length > 0 ? statusFilters.filter(status => status.filter === getStageLabel(deal)).length > 0 : true;
    }

    return (
        <>
            { activeType === 'cards'?
                <HorizontalContainer
                    label={"HubSpot Diilit (" + deals.length +")"}
                    setFilter={setSearch}
                    searchFilter={search}
                    pipeline={selectedPipeline}
                    availablePipelines={pipelines}
                    filter={stageLabels.length > 0}
                    customFilters={stageLabels}
                    filters={statusFilters}
                    setStatusFilters={setFilters}
                    changePipeline={changePipe}
                    openSettings={openSettings}
                    activeType={activeType} 
                    toggleType={toggleType} 
                    listName={"hubDeals"}>
                    <HorizontalList>
                        {(isLoading || deals.length === 0) ?
                            <EmptyList isLoading={isLoading} error={errorMessage} />
                            :
                            deals.filter(deal => (deal.pipeline === selectedPipeline.id && dealNameFilter(deal) && stageFilter(deal))).map(result =>
                                <HorizontalItem key={result.dealId}>
                                    <HubSpotDealCard deal={result} getStage={getStageLabel} />
                                </HorizontalItem>
                            )
                        }
                    </HorizontalList>
                </HorizontalContainer>
                :
                <VerticalContainer 
                label={"HubSpot Diilit (" + deals.length +")"}
                setFilter={setSearch}
                searchFilter={search}
                pipeline={selectedPipeline}
                availablePipelines={pipelines}
                filter={stageLabels.length > 0}
                customFilters={stageLabels}
                filters={statusFilters}
                setStatusFilters={setFilters}
                changePipeline={changePipe}
                openSettings={openSettings}
                activeType={activeType} 
                toggleType={toggleType} 
                listName={"hubDeals"}>
                    <VerticalList>
                        {deals.length > 0 ? deals.filter(deal => (deal.pipeline === selectedPipeline.id && dealNameFilter(deal) && stageFilter(deal))).map((result, key) => {
                            let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                            return(
                                <VerticalItem key={result.dealId}>
                                    <HubDealListItem key={key} deal={result} getStage={getStageLabel} oddEvenClass={oddEvenClass}/>
                                </VerticalItem>
                            )
                        })
                        : 
                        (
                            <EmptyList isLoading={isLoading} />
                        )}
                    </VerticalList>
                </VerticalContainer>
            }
        </>
    )
}

export const EmptyHubSpotContainer = ({ openSettings }) => {
    return (
        <HorizontalContainer
            label={"HubSpot"}
            openSettings={openSettings}>
            <HorizontalList>
                <EmptyList isLoading={false} error={"Määritelmiä puuttuu"} />
            </HorizontalList>
        </HorizontalContainer>
    )
}



export const Answers = ({ goToReport, userInfo, location, selectedBusinessId, activeType, toggleType }) => {
    const [answers, setAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();

    const isMounted = useRef(true);

    useEffect(() => {
        let businessId = selectedBusinessId ? selectedBusinessId : location.selectedBusinessId

        if (userInfo.userType === 'business' && userInfo.businessId) businessId = userInfo.businessId

        const fetchAnswers = async params => {            
            const response = await addTask('get/latestAnswers', { businessId: businessId }, dispatch)
            setTimeout(() => {
                if (!!response) {
                    if (isMounted.current)
                        setAnswers(response);
                } else {
                    NotificationManager.error("Viimeisimpien vastauksien haku epäonnistui", "", 3000);
                }
                if (isMounted.current) setIsLoading(false) 
            }, 200)
        }

        fetchAnswers();

        return () => {
            isMounted.current = false;
        }
    }, [])

    return (
        <>
            {activeType === 'cards' ?
                <HorizontalContainer label={"VIIMEISIMMÄT VASTAUKSET (" + answers.length +")"} setFilter={setSearch} searchFilter={search} activeType={activeType} toggleType={toggleType} listName={"answers"}>
                    <HorizontalList>
                        {answers.length === 0 ?
                            <EmptyList isLoading={isLoading} />
                            :
                            answers.filter(x => (x.code && x.code.toUpperCase().includes(search.toUpperCase())) || (x.surveyName && x.surveyName.toUpperCase().includes(search.toUpperCase()))).map(result =>
                                <HorizontalItem key={result.answerSessionId}>
                                    <SurveyResultCards result={result} goToReport={goToReport} />
                                </HorizontalItem>
                            )
                        }
                    </HorizontalList>
                </HorizontalContainer>
                :
                <VerticalContainer label={"VIIMEISIMMÄT VASTAUKSET (" + answers.length +")"} setFilter={setSearch} searchFilter={search} activeType={activeType} toggleType={toggleType} listName={"answers"}>
                    <VerticalList>
                        {answers.length > 0 ? answers.filter(x => (x.code && x.code.toUpperCase().includes(search.toUpperCase())) || (x.surveyName && x.surveyName.toUpperCase().includes(search.toUpperCase()))).map((result, key) => {
                            let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                            return(
                                <VerticalItem key={result.answerSessionId}>
                                    <AnswerListItem key={key} result={result} goToReport={goToReport} oddEvenClass={oddEvenClass}/>
                                </VerticalItem>
                            )
                        })
                        : 
                        (
                            <EmptyList isLoading={isLoading} />
                        )}
                    </VerticalList>
                </VerticalContainer>
            }
        </>
    )
}


export const ExcelList = ({ selectedBusinessId, location }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [excels, setExcels] = useState([])
    const [search, setSearch] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedExcel, setSelectedExcel] = useState({})

    const dispatch = useDispatch();
    const isMounted = useRef(true);

    useEffect(() => {
        let businessId = selectedBusinessId ? selectedBusinessId : location.selectedBusinessId

        const fetchAnswers = async params => {
            const response = await addTask('excel/getBusinessExcels', { businessId: businessId }, dispatch)
            setTimeout(() => {
                if (!!response) {
                    if (isMounted.current)
                        setExcels(response);
                } else {
                    NotificationManager.error("Tiedostojen haku epäonnistui", "", 3000);
                }                
                if (isMounted.current) setIsLoading(false) 
            }, 200)
        }

        fetchAnswers();

        return () => {
            isMounted.current = false;
        }
    }, [])

    const selectExcel = (excel) => {
        setSelectedExcel(excel)
        setIsModalOpen(true)
    }

    return (
        <>
            <ExcelDataModal data={selectedExcel} isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} />
            {isLoading &&
                <HorizontalContainer label={""} setFilter={setSearch} searchFilter={search}>
                    <HorizontalList>
                        <EmptyList isLoading={isLoading} />
                    </HorizontalList>
                </HorizontalContainer>
            }
            {excels && 
                <HorizontalContainer label={"LADATUT EXCEL-TIEDOSTOT"} setFilter={setSearch} searchFilter={search}>
                    <HorizontalList>
                        {excels.filter(x => x.name && x.name.toUpperCase().includes(search.toUpperCase())).map((excel, j) =>
                            <HorizontalItem key={j} >
                                <ExcelFieldCard excel={excel} created={excel.created} i={j} click={() => selectExcel(excel)}/>
                            </HorizontalItem>
                        )}
                    </HorizontalList>
                </HorizontalContainer>
            }
        </>
    )
}


const EmptyList = ({ isLoading, error = "" }) => {
    return (
        <div className="list-placeholder-container">
            {isLoading ?
                <ListLoadingSpinner />
                :
                <h3>{error === "" ? "Ei tuloksia" : error}</h3>
            }
        </div>
    )
}

const ListLoadingSpinner = () => {
    return (
        <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}
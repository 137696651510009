import React from 'react';
import MultiSelect from "@kenshooui/react-multi-select";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {confs} from '../../../modules/config';
import {NotificationManager} from 'react-notifications';

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi kartoitusta...",
        noItemsMessage: "Ei valintoja",
        noneSelectedMessage: "Valitse kartoitus",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "Voit valita vain yhden raportin"
    }
};

const MegaLoaderSpinner = () => {
    return (
            <Row className="loadingSpinnerRow">
                <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center'}}>
                    <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </Col>
            </Row>
    )
}

const ReportSelectionModal = ({open, toggle, targetBusiness, item, promptSave, type = 'selector', createReportItem, clearItems, setSelectedSurvey}) => {
    const [isLoading, setLoading] = React.useState(false);
    const [availableSurveys, setAvailableSurveys] = React.useState([]);
    const [selectedSurveys, setSelectedSurveys] = React.useState((item.targetSurvey && Object.keys(item.targetSurvey).length > 0) ? [item.targetSurvey] : [])

    React.useEffect(() => {
        if(open) {
            handleSurveyFetch();
        }
    }, [open])

    const handleSurveyFetch = () => {
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            selectedBusiness: targetBusiness ? targetBusiness.value : '',
            active: false
        }
        return new Promise( async ( resolve ) => {
            setLoading(true);
            const resultJson = await fetch( confs.url + 'plan/availableSurveys', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )
    
            const response = await resultJson.json()
    
            if(response) {
                setAvailableSurveys(response);
            }

            setLoading(false);

            resolve()
        })
    }

    const handleSurveySelection = selectedItems => {
        setSelectedSurveys(selectedItems);
    }

    const finalizeReportSelection = () => {
        
        let itemPayload = {
            ...item,
            targetSurvey: {
                ...selectedSurveys[0]
            }
        }
        //onItemChange(itemPayload);
        if(type === 'creation') {
            let payload = {
                ...item.selectedItem,
                targetSurvey: {
                    ...selectedSurveys[0]
                }
            }
            createReportItem(payload, item.destinationIndex, item.destClone, item.planId);
            
            clearItems();
        } else {
            setSelectedSurvey({...selectedSurveys[0]})
            promptSave(itemPayload);
        }

        toggle();
    }

    
    return (
        <Modal isOpen={open} toggle={toggle} centered size="lg">
                <ModalHeader toggle={toggle} className="taskModal">
                    <div>
                        Valitse näytettävä raportti
                    </div> 
                </ModalHeader>
                <ModalBody>
                    {isLoading ? <MegaLoaderSpinner /> : 
                    <Row className="taskCreatorRow">
                        <Col xl="12">
                            <MultiSelect
                                messages={customMessages.messages}
                                items={availableSurveys}
                                selectedItems={selectedSurveys}
                                onChange={handleSurveySelection}
                                showSelectAll={false}
                                showSelectedItems={true}
                                wrapperClassName="multi-selector"
                                maxSelectedItems={1}
                                withGrouping
                            />
                        </Col>
                </Row>}
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                {selectedSurveys.length > 0 && <button className="small-white-button wide-button" onClick={() => finalizeReportSelection()}>Tallenna</button>}
            </ModalFooter>
        </Modal>
    )

}

export default ReportSelectionModal;
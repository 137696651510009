import React, { useState, useEffect } from 'react';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import { useSelector } from 'react-redux';
import HelperBox from '../../../../Utilities/HelperBox/HelperBox';
import RiskMatrice from '../../../../Utilities/Matrice/RiskMatrice';
import { ModifiedContainer } from '../DataContainers';
import RiskListModal from '../../../../Utilities/Modals/riskModals/RiskListModal'
import { riskLevelValues, riskOddsValues } from '../../../../Utilities/Riskvalues'


export const RiskBlock = (props) => {
    const {item, editable, index, startRiskAssesment, toggleItemVisibility, onDelete, startAddingTask, plan, layoutPreview = false} = props
    const risks = useSelector(state => state.sender.risksForDashboard)
    const riskMatrice = useSelector(state=>state.sender.riskMatrice)
    const riskMatriceResults = useSelector(state=>state.sender.riskMatriceResults)
    const newRiskMatrice = useSelector(state =>  state.sender.newRiskMatrice)
    const modulesInUse = useSelector(state => state.authentication.modulesInUse)
    const [openMenu, toggleMenu] = useState(false);
    const [modifiedMatrice, setModifiedMatrice] = useState([])
    const [riskListOpen, setRiskListOpen] = useState(false)
    const [filterRiskLevel, setFilterRiskLevel] = useState([])
    const [filterRiskOdds, setFilterRiskOdds] = useState([])
    const [squareColor, setSquareColor] = useState("")

    useEffect(()=> {
        if (newRiskMatrice !== undefined && modifiedMatrice !== newRiskMatrice) {
            // reorder the matrice to match the order amchart wants it to be
            const reOrderedRiskMatrice = []

            for (let j = 0; j < newRiskMatrice.length; j++) {
                for (let i = 0; i < newRiskMatrice.length; i++) {
                    reOrderedRiskMatrice.push(newRiskMatrice[i][j])
                }
            }

            setModifiedMatrice( reOrderedRiskMatrice )
        }
    },[newRiskMatrice])
    
    const openRiskListModal = (riskOdds, riskLevel, color) => { 
        setFilterRiskLevel(riskLevelValues[riskLevel])
        setFilterRiskOdds(riskOddsValues[riskOdds])
        setSquareColor(color) 
        setRiskListOpen(true)
    }

    const closeRiskListModal = () => {
        setRiskListOpen(false)
        setFilterRiskLevel([])
        setFilterRiskOdds([])
        setSquareColor('')
    }

    return(
        <>
            <RiskListModal 
                isOpen={riskListOpen} 
                riskModal={false} 
                modifyRisk={()=> {return}} 
                riskOdds={filterRiskOdds} 
                riskLevel={filterRiskLevel} 
                riskList={risks} 
                closed={closeRiskListModal} 
                squareColor={squareColor} 
            />
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                <Col xl="12">
                        {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red' }}>Yksityinen</span>}
                        {(!editable && !layoutPreview && item.modified !== undefined) && 
                            <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                        }
                </Col>
                {!layoutPreview && 
                    <Col xl="12" style={{marginBottom: 8}} className="title-col">
                        <div className="plan-input subTitle" style={{marginBottom: 8, width: '100%', cursor: 'default', display:'flex'}}>{index + 1}<p style={{ marginLeft: '0.5em' }} className="plan-input subTitle">Riskit</p></div>
                    </Col>
                }
                <Col xs={{ size: 11 }}>
                    {(risks && risks.length > 0)?
                        <Row> 
                            <Col xs='8'>
                                <Row style={{ margin: 'auto' }}>
                                    <Col className="col-xs-12" xl="12" style={{ padding: '0 2.5em 0 .5em' }}>
                                        <RiskMatrice 
                                            risks={risks} 
                                            riskMatriceResults={riskMatriceResults} 
                                            newRiskMatrice={modifiedMatrice} 
                                            openRiskListModal={openRiskListModal}
                                            riskMatrice={riskMatrice}
                                            showRiskMatrice={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>  
                        </Row>
                        : (
                            <div style={{paddingTop: '1.5em'}}>
                                <HelperBox text="Ei riskiarvioita" onlyText={true} />
                            </div>
                        )}
                    
                </Col>
                {editable &&
                    <Col xl="1" style={{paddingLeft: 15}} className="plan-dropdown-col">
                        <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                        <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                            <DropdownToggle tag="div">
                                <i className="fas fa-bars plusIcon" title="Valikko"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                {/* { modulesInUse.taskManagement && <DropdownItem onClick={() => startAddingTask(item)} className="add-task">Lisää tarkistuspiste</DropdownItem> } */}
                                {/* { modulesInUse.riskManagement && <DropdownItem onClick={() => startRiskAssesment(item)} className="add-risk">Lisää riski</DropdownItem> } */}
                                <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                                <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        </div>
                    </Col>
                }
            </Row>
        </>
    )
}
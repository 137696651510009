import React, { Component, Fragment } from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col} from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import TextEditor from '../TextEditor/texteditor'

export default class FreetextAnswersModal extends Component {
    constructor() {
        super()
        this.state = {
            selected: []
        }
    }

    componentDidUpdate( prevProps ) {
        if( this.props.answerSessionIds !== prevProps.answerSessionIds ) {
            const selected = [ ...this.props.answerSessionIds ]
            this.setState({ selected: selected })
        }
    }

    onFilterClick = ( answer ) => {
        let list = [
            ...this.state.selected
        ]

        const isSelected = list.some( val => val === answer.answerSessionId )

        if( isSelected ) {
            const filteredList = list.filter( x => x !== answer.answerSessionId )
            this.setState({ selected: filteredList })
        } else {
            list.push( answer.answerSessionId )
            this.setState({ selected: list })
        }
    }

    startFilter = () => {
        const selecteds = this.state.selected.length > 0 ? [ ...this.state.selected ] : null
        
        this.props.filterReportByAnswerSession( selecteds )
        this.props.toggleModal()
    }

    closeModal = () => {
        this.setState({ selected: this.props.answerSessionIds })
        this.props.toggleModal()
    }

    render() {
        let data = {...this.props.freetextAnswers, ...this.props.dateQuestionAnswers};
        
        return (
            <Modal isOpen={this.props.isOpen} toggle={ this.closeModal } centered={true} className="freetext-answers-modal">
                <ModalHeader toggle={ this.closeModal }>
                    { this.props.isDateModal ? 'Päivämäärävastaukset' : 'Vapaatekstivastaukset' }
                </ModalHeader>
                <p style={{padding: '1rem'}}>Valitsemalla haluamasi vastaukset ja painamalla Tarkastele-nappia, voit suodattaa raportin näyttämään vain kyseisten { this.props.isDateModal ? 'päivämäärä' : 'vapaateksti' }vastausten antaneiden henkilöiden vastaukset.</p>
                <ModalBody>

                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '500px', paddingBottom: '1rem' }}
                        className="custom-scrollbars" >

                        {Object.keys(this.props.freetextAnswers).map((key, index) => {
                            let answerStack = this.props.freetextAnswers[key]

                            return (
                                <Fragment key={index}>
                                    <Row>
                                        <Col>
                                            <h5><b>{answerStack.question}</b></h5>
                                        </Col>
                                    </Row>

                                    {answerStack.answers && answerStack.answers.map((answer, index) => {
                                        let className = index % 2 === 0 ? 'even-row' : 'odd-row'

                                        if(answer.isDate) {
                                            return (    
                                                <Row key={index} className={className} style={{padding: '1rem'}}>
                                                    <Col xs={{size: 2}}>
                                                        {moment.utc(answer.time, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')}
                                                    </Col>
                                                    <Col xs={{size: 8}}>
                                                        {moment(new Date(answer.text)).format('L')}
                                                    </Col>
                                                    
                                                    {typeof answer.answerSessionId !== 'undefined' ? (
                                                        <Col xs={{size: 2}}>
                                                            { this.state.selected.some( id => id === answer.answerSessionId ) ?
                                                                 <button onClick={() => this.onFilterClick( answer )} className="small-white-button" style={{padding: '0.25rem 1rem', backgroundColor: '#b5e413', color: '#fff'}}>Valittu</button> :
                                                                 <button onClick={() => this.onFilterClick( answer )} className="small-white-button" style={{padding: '0.25rem 1rem'}}>Valitse</button>
                                                            }
                                                        </Col>
                                                    ) : (
                                                        <Col></Col>
                                                    )}
                                                </Row>
                                            )
                                        } else {
                                            return (    
                                                <Row key={index} className={className} style={{padding: '1rem'}}>
                                                    <Col xs={{size: 2}}>
                                                        {moment.utc(answer.time, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')}
                                                    </Col>
                                                    <Col xs={{size: 8}}>
                                                    <TextEditor 
                                                        editorId={`${index+answer._id}-summary`} 
                                                        content={answer.text} 
                                                        additionalClass="block-with-text plan-input"
                                                        readOnly={true}
                                                    />
                                                        {/* {answer.text} */}
                                                    </Col>
                                                    
                                                    {typeof answer.answerSessionId !== 'undefined' ? (
                                                        <Col xs={{size: 2}}>
                                                            { this.state.selected.some( id => id === answer.answerSessionId ) ?
                                                                 <button onClick={() => this.onFilterClick( answer )} className="small-white-button" style={{padding: '0.25rem 1rem', backgroundColor: '#b5e413', color: '#fff'}}>Valittu</button> :
                                                                 <button onClick={() => this.onFilterClick( answer )} className="small-white-button" style={{padding: '0.25rem 1rem'}}>Valitse</button>
                                                            }
                                                            
                                                        </Col>
                                                    ) : (
                                                        <Col></Col>
                                                    )}
                                                </Row>
                                            )
                                        }
                                    })}


                                </Fragment>
                            )
                        })}
                    </Scrollbars>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => this.closeModal()}>Sulje</a>
                    <Button onClick={() => this.startFilter()}>Tarkastele</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
import React, {useState, useEffect, useRef} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import { useInView } from 'react-intersection-observer'
import FilterContainer from '../../Components/Kartoitus/Sender/modules/taskFilter/FilterContainer';
import FilterButton from '../../Components/Kartoitus/Sender/modules/taskFilter/FilterButton';
import './horizontal.css';
import {
    Row,
    Col,
    Input,
    Dropdown
} from 'reactstrap'
import Select from 'react-select';

export const HorizontalList = ({children}) => {
    const [leftArrow, setLeftArrow] = useState(false)
    const [rightArrow, setRightArrow] = useState(false)
    const listRef = useRef();
    const [listSize, setListSize] = useState(0)

    const variants = {
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {delay: i * 0.3, ease: 'easeIn', duration: 0.2}
        }),
        hidden: {
            opacity: 0
        }
    }

    useEffect(()=>{
        if(children.length !== listSize){
            setListSize(children.length)
        }
    },[children])

    useEffect(() => {
        if(listRef.current) {
            listRef.current.scrollLeft = 0;
            const scrollWidth = listRef.current.scrollWidth - listRef.current.clientWidth;

            if(scrollWidth > 0) {
                if(!rightArrow) setRightArrow(true);
            }
        }
    }, [])

    useEffect(() => {
        const scrollWidth = listRef.current.scrollWidth - listRef.current.clientWidth;

        if(scrollWidth > 0) {
            if(!rightArrow) setRightArrow(true);
        }
    }, [children])

    /*TRUE = RIGHT, FALSE = LEFT*/ 
    const scrollList = (direction = false) => {
        const scrollWidth = listRef.current.scrollWidth - listRef.current.clientWidth;
        //calculates how much list needs to be moved
        let move = (listRef.current.scrollWidth / listRef.current.childElementCount)*2
        if(direction) {
            if(listRef.current.scrollLeft + move < scrollWidth) {
                if(!rightArrow) setRightArrow(true);
                if(!leftArrow) setLeftArrow(true);
                listRef.current.scrollLeft += move;
            } else {
                if(rightArrow) setRightArrow(false);
                if(!leftArrow) setLeftArrow(true);
                listRef.current.scrollLeft = listRef.current.scrollWidth
            }
        } else {
            if(listRef.current.scrollLeft - move > 0) {
                if(!rightArrow) setRightArrow(true);
                if(!leftArrow) setLeftArrow(true);
                listRef.current.scrollLeft -= move;
            } else {
                if(!rightArrow) setRightArrow(true);
                if(leftArrow) setLeftArrow(false);
                listRef.current.scrollLeft = 0
            } 
        }
    }

    return (
        <div className="horizontal-container">
            {leftArrow &&
                <span className="caret-left" style={{marginLeft: '0'}} onClick={(e) => scrollList()}>       
                    <i style={ {color: 'rgba(97,97,97,1)', fontSize: '2em', } } className="fas fa-caret-left"></i>
                </span>
            }
                <motion.ul 
                    className="card-elements" 
                    ref={listRef} 
                    variants={variants}
                    style={{ width: `calc( 15em * ${listSize})`, paddingTop: '0.25em'}}
                >
                   {children}
                </motion.ul>
            
            {rightArrow && 
                <span className="caret-right" style={{marginRight: '0.4em'}} onClick={(e) => scrollList(true)}>       
                    <i style={ {color: 'rgba(97,97,97,1)', fontSize: '2em' } } className="fas fa-caret-right"></i>
                </span>
            }
        </div>
    )
}

export const HorizontalItem = ({children}) => {
    const [ref, inView, entry] = useInView({
        threshold: 0,
    })
    let direction = 50
    if(entry && entry.boundingClientRect){
        if(entry.boundingClientRect.x < 0){
            direction = -25
        }
        if(entry.boundingClientRect.x > 0){
            direction = 25
        }
    }
    const variants = {
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {delay: i * 0.2, ease: 'easeIn', duration: 0.25}
        }),
        hidden: i => ({
            opacity: 0, 
            x: direction,
            transition: {delay: i * 0.2, ease: 'easeOut', duration: 0.25}
        })
    }

    return (
        <motion.li
            initial={{opacity: 0, x: 150}}
            animate={inView ? "visible" : "hidden"}
            variants={variants}
            ref={ref}
        >
            <div className={inView ? "card--content" : "card--content hide"}>
                {inView && children}
            </div>
        </motion.li>
    )
}

export const HorizontalContainer = ({label, children, filter = false, scoreFilter=false, downloadCSV, selectedScore, setSelectedScore, scoreOptions, scoreFilterOpen, setScoreFilterOpen, handleScoreFilterChange, filters, setFilter, searchFilter, setStatusFilters, pipeline, availablePipelines, changePipeline, openSettings, customFilters, addNew, selectedBusiness, project, toggleType, activeType = 'cards', listName = null, showAddNewOnList = null, ganttOption = false}) => {

    const handleTypeChange = (type) => {
        if(listName !== null){
            toggleType(listName,type)
        }
    }

    let typeSubString = ""

    if(listName !== null){
        typeSubString = listName.slice(0, -1)
    }
    
    return (
        <Col md="12" id={listName? listName+"-tourId" : ""} className="dashboard-list-container">
            <div>
                <div className={"title-bar "}>
                    {listName !== null &&
                        <div className="label-bar bigger-text" style={{display:'flex'}}>
                            <div className={(!listName.toString().toLowerCase().includes("hub") ?'dashboard-dot ':"") + (typeSubString.length > 0? typeSubString + "-color" : "")} style={{ marginRight: '0.5em'}}></div> <p>{label}</p>
                        </div>
                    }
                    {/* <div id="search-tourId">
                        <form id="search-bar">
                            <input id={label} type="search" className="search-bar-input show-search-always" placeholder="Haku" name="taskFilter" value={searchFilter} onChange={(event) => setFilter(event.target.value)} />
                        </form>
                    </div>*/}
                    {scoreFilter &&
                        <Row>
                            <Col xs="2">
                                <button className={"small-white-button lighter-text filter-nibblet-surveylist" } title="Pistehaku. Voit hakea pisteettömiä tuloksia laittamalla molempiin kenttiin - merkin" style={{ padding: '0.3em 0.65em', marginLeft: '0.5em' }} onClick={ () => setScoreFilterOpen(!scoreFilterOpen)}><i className="fas fa-filter"></i></button>
                            </Col>
                            {scoreFilterOpen &&
                                <Col xs="10">
                                    <Row style={{marginLeft:'-10px', maxWidth: '20em', minWidth:'10em'}}>
                                        <select onChange={(e) => setSelectedScore(e.target.value)} value={selectedScore} style={{ marginLeft: '0.25em', marginRight:'0.25em', borderRadius: '5px', border:'none'}}>
                                            {scoreOptions.map((option, index) => {
                                                return <option key={index} >
                                                    {option}
                                                </option>
                                            })}
                                        </select>
                                        <Input style={{ width: '4em', height:'1.6em'}} value={scoreFilter.first} onChange={(e)=> handleScoreFilterChange({id: "first", value: e.target.value})}/>
                                        <p style={{marginLeft: '0.25em', marginRight: '0.25em'}}>-</p>
                                        <Input style={{ width: '4em', height:'1.6em'}} value={scoreFilter.second} onChange={(e)=> handleScoreFilterChange({id: "second", value: e.target.value})}/>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    }
                    {scoreFilter && 
                        <div >
                            <button className={"small-white-button lighter-text " } title="Lataa csv" style={{ padding: '0.2em 0.5em', marginLeft: '1em' }} onClick={ () => downloadCSV()}><i className="fas fa-download"></i></button>
                        </div>
                    }
                    {filter &&
                        <>
                            {filters.map((filterItem) =>
                                <FilterButton filterItem={filterItem} removeItem={setStatusFilters} key={filterItem.value} filters={filters} />
                            )}
                            <div  id="filter-button-tourId" style={{position: 'relative' }} className="filter-nav">
                                <FilterContainer handleStatusFilters={setStatusFilters} statusFilters={filters} customFilters={customFilters} />
                            </div>
                        </>
                    } 
                    {pipeline &&
                        <div style={{marginLeft: '0.5rem'}}>
                            <select name="pipeline" value={pipeline.id} onChange={(e) => changePipeline(e)}>
                                {availablePipelines && availablePipelines.map(pipe =>
                                    <option value={pipe.id} key={pipe.id}>{pipe.label}</option> 
                                )}
                            </select>
                        </div>
                    }
                    {openSettings && <div className="horizontal-container-settings"><i className="fas fa-cog settings-icon" onClick={openSettings}></i></div>}  
                    {listName !== null && 
                        <div id="list-view-tourId" className="view-type-container">
                            {activeType !== "cards" && <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("cards")}><i className="fas fa-columns"></i></button>}
                            {activeType !== "list" && <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("list")}><i className="fas fa-list-ul"></i></button>}
                            {listName === "projects" && ganttOption && activeType !== "gantt" && <button className={"small-white-button lighter-text" } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("gantt")}><i className="fas fa-chart-bar"></i></button>}
                        </div>
                    }         
                    {addNew && showAddNewOnList && (project ? 
                        <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                            <i className="fas fa-plus dash-plusIcon project-footer"  style={{ backgroundColor: '#fff' }} onClick={() => {addNew()}}></i>
                            <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew()}}>Lisää</p>
                        </div>  
                        :
                        (selectedBusiness ?
                            <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                                <i className="fas fa-plus dash-plusIcon"  style={{ backgroundColor: '#fff' }} onClick={() => {addNew(selectedBusiness)}}></i>
                                <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew(selectedBusiness)}}>Lisää</p>
                            </div>
                            :
                            <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                                <i className="fas fa-plus dash-plusIcon"  style={{ backgroundColor: '#fff' }} onClick={() => {addNew()}}></i>
                                <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew()}}>Lisää</p>
                            </div>  
                        )                           
                    )}
                   
                </div>
                {children}
            </div>
        </Col>
    )
}

export const HorizontalContainerWithSearch = ({label, children, filter = false, filters, setFilter, searchFilter, setStatusFilters, pipeline, availablePipelines, changePipeline, openSettings, customFilters, addNew, selectedBusiness, project, toggleType, activeType = 'cards', listName = null, showAddNewOnList = null}) => {

    const handleTypeChange = (type) => {
        if(listName !== null){
            toggleType(listName,type)
        }
    }

    let typeSubString = ""

    if(listName !== null){
        typeSubString = listName.slice(0, -1)
    }
    
    return (
        <Col md="12" id={listName? listName+"-tourId" : ""} className="dashboard-list-container">
            <motion.div
                initial={{ opacity: 0, x: 40 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ ease: 'easeIn', duration: 0.3 }}
                exit={{ opacity: 0, x: -50 }}
                className="horizontal-main-container"
                key={label}
            >
                <div className={"title-bar "}>
                    {listName !== null &&
                        <>
                            <div className="label-bar bigger-text" style={{display:'flex'}}>
                                <div className={(!listName.toString().toLowerCase().includes("hub") ?'dashboard-dot ':"") + (typeSubString.length > 0 ? typeSubString + "-color" : "")} style={{ marginRight: '0.5em'}}></div> <p>{label}</p>
                            </div>
                            <div id="search-tourId">
                                <form id="search-bar">
                                    <input id={label} type="search" className="search-bar-input show-search-always" placeholder="Haku" name="taskFilter" value={searchFilter} onChange={(event) => setFilter(event.target.value)} />
                                </form>
                            </div>
                        </>
                    }
                    {filter &&
                        <>
                            {filters.map((filterItem) =>
                                <FilterButton filterItem={filterItem} removeItem={setStatusFilters} key={filterItem.value} filters={filters} />
                            )}
                            <div  id="filter-button-tourId" style={{position: 'relative' }} className="filter-nav">
                                <FilterContainer handleStatusFilters={setStatusFilters} statusFilters={filters} customFilters={customFilters} />
                            </div>
                        </>
                    } 
                    {pipeline &&
                        <div style={{marginLeft: '0.5rem'}}>
                            <select name="pipeline" value={pipeline.id} onChange={(e) => changePipeline(e)}>
                                {availablePipelines && availablePipelines.map(pipe =>
                                    <option value={pipe.id} key={pipe.id}>{pipe.label}</option> 
                                )}
                            </select>
                        </div>
                    }
                    {openSettings && <div className="horizontal-container-settings"><i className="fas fa-cog settings-icon" onClick={openSettings}></i></div>}  
                    {listName !== null && listName !== "Kontaktit" &&
                        <div id="list-view-tourId" className="view-type-container">
                            {activeType === "list" && <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("cards")}><i className="fas fa-columns"></i></button>}
                            {activeType === "cards" && <button className={"small-white-button lighter-text " + (activeType === "list"? "dashboard-active-selection": "")} style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("list")}><i className="fas fa-list-ul"></i></button>}
                        </div>
                    }         
                    {addNew && showAddNewOnList && (project ? 
                        <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                            <i className="fas fa-plus dash-plusIcon project-footer"  style={{ backgroundColor: '#fff' }} onClick={() => {addNew()}}></i>
                            <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew()}}>Lisää</p>
                        </div>  
                        :
                        (selectedBusiness ?
                            <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                                <i className="fas fa-plus dash-plusIcon"  style={{ backgroundColor: '#fff' }} onClick={() => {addNew(selectedBusiness)}}></i>
                                <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew(selectedBusiness)}}>Lisää</p>
                            </div>
                            :
                            <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                                <i className="fas fa-plus dash-plusIcon"  style={{ backgroundColor: '#fff' }} onClick={() => {addNew()}}></i>
                                <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew()}}>Lisää</p>
                            </div>  
                        )                           
                    )}
                   
                </div>
                {children}
            </motion.div>
        </Col>
    )
}
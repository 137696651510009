import React, {useState} from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import {confs} from '../../../modules/config';
import {NotificationManager} from 'react-notifications';
import Select from 'react-select';
import TextEditor from '../../../Utilities/TextEditor/texteditor'
import { v4 as uuidv4 } from 'uuid';
const rowSelections = [...Array(10).keys()].map(key => {
    return {
        value: key + 1,
        label: key + 1
    }
})

const colSelections = [...Array(4).keys()].map(key => {
    return {
        value: key + 1,
        label: key + 1
    }
})

const MegaLoaderSpinner = () => {
    return (
        <Row className="loadingSpinnerRow">
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center'}}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const TableCreationModal = ({open, toggle, targetBusiness, item, type = 'selector', createTableItem, clearItems}) => {
    const [isLoading, setLoading] = React.useState(false);
    const [closeAll, setCloseAll] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [numberOfRows, setNumberOfRows] = React.useState({value: 1, label: 1});
    const [numberOfColumns, setNumberOfColumns] = React.useState({value: 1, label: 1})
    const [editRow, setEditRow] = React.useState({});

    const [tableConfiguration, setTableConfiguration] = React.useState({
        selectedNumberOfCols: 1,
        rows: [
            {
                id: uuidv4(),
                cols: [
                    {
                        id: uuidv4(),
                        text: '-'
                    }
                ]
            }
        ]
    })

    const tableConfigurationHandler = (type, incomingValues) => {
        let configuration = {
            ...tableConfiguration
        }

        switch(type) {
            case 'rows':
                let colValue = numberOfColumns.value;

                configuration = {
                    ...configuration,
                    rows: [...Array(incomingValues.value).keys()].map(key => {
                        
                        return {
                            id: uuidv4(),
                            cols: 
                                [...Array(colValue).keys()].map(col => {
                                    return {
                                        text: '-',
                                        id: uuidv4()
                                    }
                                })
                            
                        }
                    })
                }
                setNumberOfRows(incomingValues)
                break;
            case 'columns':
                let rowValue = numberOfRows.value

                configuration = {
                    ...configuration,
                    selectedNumberOfCols: incomingValues.value,
                    rows: [...Array(rowValue).keys()].map(key => {
                        return {
                            id: uuidv4(),
                            cols:
                                [...Array(incomingValues.value).keys()].map(col => {
                                    return {
                                        text: '-',
                                        id: uuidv4()
                                    }
                                })
                            
                        }
                    })
                }
                setNumberOfColumns(incomingValues)
                break;
            default:
                break;
        }

        setTableConfiguration(configuration);
    }

    const toggleNested = (index = null) => {
        if(index !== null) setEditRow({rowData: tableConfiguration.rows[index], editIndex: index});
        else setEditRow({});

        setNestedModal(!nestedModal);
        setCloseAll(false);
    }

    const toggleAll = () => {
        setNestedModal(!nestedModal);
        setCloseAll(true);
    }

    const finalizeCreateTableItem = () => {
        //onItemChange(itemPayload);
        if(type === 'creation') {
            let payload = {
                ...item.selectedItem,
                ...tableConfiguration,
                businessId: (targetBusiness && targetBusiness.value)?targetBusiness.value : null
            }

            createTableItem(payload, item.destinationIndex, item.destClone, item.planId);
            
            clearItems();
        } 
    
        toggleAll(true);
    }

    const handleRowEdit = (row, index) => {
        
        let configuration = {
            ...tableConfiguration,
        }

        if(row.cols.length > configuration.selectedNumberOfCols) {
            configuration = {
                ...configuration,
                selectedNumberOfCols: row.cols.length
            }
        }

        configuration.rows.splice(index, 1, row)
        setTableConfiguration(configuration);
        toggleNested();
    }

    return (
        <Modal isOpen={open} toggle={toggle} centered size="lg">
            <ModalHeader toggle={toggle} className="taskModal">
                Määrittele taulukko
            </ModalHeader>
            <ModalBody>
                {Object.keys(editRow).length > 0 && <RowEditModal nestedModal={nestedModal} toggleNested={toggleNested} closeAll={closeAll} toggle={toggle} editRow={editRow} saveRowSelection={handleRowEdit}/>}
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: '2em'}}>
                    <div style={{flex: '1', marginRight: '2em'}}>
                        <label htmlFor="rowSelector">Rivit</label>
                        <Select 
                            id="rowSelector" 
                            options={rowSelections}
                            value={numberOfRows}
                            onChange={value => tableConfigurationHandler('rows', value)}
                        />
                    </div>
                    <div style={{flex: '1'}}>
                        <label htmlFor="rowColumns">Sarakkeet</label>
                        <Select 
                            id="rowColumns" 
                            options={colSelections}
                            value={numberOfColumns}
                            onChange={value => tableConfigurationHandler('columns', value)}
                        />
                    </div>
                </div>
                <Table bordered responsive hover>
                    <thead>
                        
                    </thead>
                    <tbody>
                        {tableConfiguration.rows.map((row, index) => 
                            <tr onClick={() => toggleNested(index)} key={index}>
                                {row.cols.map((col, index) => {
                                    return <TableElement count={row.cols.length} col={col} index={index} maximumCount={tableConfiguration.selectedNumberOfCols} key={col.id}/> 
                                })}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                <button className="small-white-button  wide-button" onClick={finalizeCreateTableItem}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

const TableElement = ({count, col, index, maximumCount}) => {

    if(count === 1) {
        return (
            <td key={col.id} colSpan={maximumCount}>{col.text}</td> 
        )
    } else {
        return (
            <td key={col.id} colSpan={(maximumCount % count === 1 && index + 1 === count) ? maximumCount / count + 1 : maximumCount / count}>{col.text}</td> 
        )
    }
}

{/*const TableModifierModal = ({open, toggle, item}) => {
    const {rows} = item
    const [closeAll, setCloseAll] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [numberOfRows, setNumberOfRows] = React.useState(rows.length);
    const [numberOfColumns, setNumberOfColumns] = React.useState(rows.length);
    const [editRow, setEditRow] = React.useState({});

    return (
        <Modal isOpen={open} toggle={toggle} centered size="xl">
            <ModalHeader toggle={toggle} className="taskModal">
                Taulukon määrittelyt
            </ModalHeader>
            <ModalBody>
                {editRow && <RowEditModal nestedModal={nestedModal} toggleNested={toggleNested} closeAll={closeAll} toggle={toggle} editRow={editRow} />}
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: '2em'}}>
                    <div style={{flex: '1', marginRight: '2em'}}>
                        <label htmlFor="rowSelector">Rivit</label>
                        <Select 
                            id="rowSelector" 
                            options={rowSelections}
                            value={numberOfRows}
                            onChange={value => tableConfigurationHandler('rows', value)}
                        />
                    </div>
                    <div style={{flex: '1'}}>
                        <label htmlFor="rowColumns">Kolumnit</label>
                        <Select 
                            id="rowColumns" 
                            options={colSelections}
                            value={numberOfColumns}
                            onChange={value => tableConfigurationHandler('columns', value)}
                        />
                    </div>
                </div>
                <Table bordered responsive hover>
                    <thead>
                        
                    </thead>
                    <tbody>
                        {tableConfiguration.rows.map((row, index) => 
                            <tr onClick={() => toggleNested(index)} key={index}>
                                {row.cols.map((col, index) =>
                                    <th key={index * Math.random(10)}>{col.text}</th> 
                                )}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <button className="small-white-button lighter-text wide-button" onClick={toggle}>Takaisin</button>
                <button className="small-white-button lighter-text wide-button" onClick={finalizeCreateTableItem}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}*/}

const RowEditModal = ({nestedModal, toggleNested, closeAll, toggle, editRow, saveRowSelection}) => {
    const { rowData, editIndex } = editRow

    const [columnCount, setColumnCount] = useState(rowData !== undefined && rowData.cols.length);

    return (
        <Modal isOpen={nestedModal} toggle={toggleNested} onClosed={closeAll ? toggle : undefined} centered>
            <ModalHeader>Rivin asetukset</ModalHeader>
            <ModalBody>
                <div style={{paddingBottom: '2em'}}>
                    <label htmlFor="columnCount">Sarakkeiden määrä</label>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <i className="fas fa-minus" onClick={() => {if(columnCount > 1) setColumnCount(columnCount - 1)}} style={{
                            alignSelf: 'center',
                            paddingRight: '1.5em',
                            fontSize: '1.8em',
                            cursor: 'pointer'
                        }}></i>
                        <h5>{columnCount}</h5>
                        <i className="fas fa-plus" onClick={() => {if(columnCount < 4) setColumnCount(columnCount + 1)}} style={{
                            alignSelf: 'center',
                            paddingLeft: '1.5em',
                            fontSize: '1.8em',
                            cursor: 'pointer'
                        }}></i>
                    </div>
                </div>
                <div>
                    <Table bordered responsive>
                        <thead>
                            
                        </thead>
                        <tbody>
                            <tr>
                                {[...Array(columnCount).keys()].map((col, index) =>
                                    <td key={index * Math.random(10)}>-</td> 
                                )}
                            </tr>
                        </tbody>
                    </Table>
                </div>
                </ModalBody>
            <ModalFooter>
              <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggleNested}>Peruuta</a>{' '}
              <Button color="secondary" onClick={() => saveRowSelection(
                  {
                    id: uuidv4(), 
                    cols:[...Array(columnCount).keys()].map(col => {
                      return {
                        text: '-',
                        id: uuidv4()
                      }
                  })},
                  editIndex
              )}>Tallenna</Button>
            </ModalFooter>
        </Modal>
    )
}

export default TableCreationModal;
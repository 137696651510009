export const START_NEW_PLAN = "START_NEW_PLAN";
export const SET_LIST_OF_PLANS = "SET_LIST_OF_PLANS";
export const CHANGE_EDIT_MODE = "CHANGE_EDIT_MODE";
export const START_EDITING_PLAN = "START_EDITING_PLAN";
export const UPDATE_PLAN_DATA = "UPDATE_PLAN_DATA";
export const SET_PLAN_ITEMS = "SET_PLAN_ITEMS";
export const REORDER_PLAN_ITEMS = "REORDER_PLAN_ITEMS";
export const ADD_ITEM_ID = "ADD_ITEM_ID";
export const SET_PLAN_ITEM = "SET_PLAN_ITEM";
export const SET_PLAN_TITLE = "SET_PLAN_TITLE";
export const ADD_TASK_TO_ITEM = "ADD_TASK_TO_ITEM";
export const ADD_RISK_TO_ITEM = "ADD_RISK_TO_ITEM";
export const SET_ITEM_TASK = "SET_ITEM_TASK";
export const SET_ITEM_RISK = "SET_ITEM_RISK";
export const START_FETCH_REQUEST = "START_FETCH_REQUEST";
export const FETCH_REQUEST_FINISHED = "FETCH_REQUEST_FINISHED";
export const SET_RISKS_BY_ID = "SET_RISKS_BY_ID";
export const SET_TASKS_BY_ID = "SET_TASKS_BY_ID";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const ADD_TEMPLATE_ITEM = "ADD_TEMPLATE_ITEM";
export const SET_TEMPLATE_ITEMS = "SET_TEMPLATE_ITEMS";
export const HANDLE_CLIENT_INFORMATION = "HANDLE_CLIENT_INFORMATION";
export const PLAN_STATUS = "PLAN_STATUS";
export const PLAN_PRIVACY = "PLAN_PRIVACY";
export const ADD_COPIED_PLAN = "ADD_COPIED_PLAN";
export const DELETE_PLAN ="DELETE_PLAN";
export const REMOVE_DELETED_ITEM = "REMOVE_DELETED_ITEM";
export const TOGGLE_VISIBILITY = "TOGGLE_VISIBILITY";
export const REMOVE_DELETED_TASK = "REMOVE_DELETED_TASK";
export const REMOVE_DELETED_RISK = "REMOVE_DELETED_RISK";
export const HANDLE_PASSWORD_STATUS = "HANDLE_PASSWORD_STATUS";
export const SEARCH_PLAN = "SEARCH_PLAN";
export const SET_LIST_OF_AUTOMATIONS = "SET_LIST_OF_AUTOMATIONS";
export const ADD_TO_AUTOMATIONRESULTS = "ADD_TO_AUTOMATIONRESULTS";
export const SET_LIST_OF_AUTOMATIONRESULTS = "SET_LIST_OF_AUTOMATIONRESULTS";
export const SET_LIST_OF_TEMPLATES = "SET_LIST_OF_TEMPLATES";
export const SET_ONLY_LIST_OF_PLANS = "SET_ONLY_LIST_OF_PLANS";
export const SET_PLAN_SETTINGS = "SET_PLAN_SETTINGS";
export const RESET_PLAN_SELECTION = "RESET_PLAN_SELECTION";
export const SET_PLANLIST_SETTINGS = "SET_PLANLIST_SETTINGS";
export const MODIFY_PLAN_SENTSURVEY = "MODIFY_PLAN_SENTSURVEY"; 
export const SET_REFRESH_IN_PROGRESS = "SET_REFRESH_IN_PROGRESS"; 
export const ADD_COPIED_TEMPLATE = "ADD_COPIED_TEMPLATE";
export const SET_SHOW_TABLE_OF_CONTENTS = "SET_SHOW_TABLE_OF_CONTENTS";
export const SET_PLAN_LAYOUT = "SET_PLAN_LAYOUT";
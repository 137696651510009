import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as ActionTypes from '../../../../store/index';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import TextEditor from '../../../../Utilities/TextEditor/texteditor'
import ComparisonBar from '../../Comparison/ComparisonBar';

import './home.css';
class MainReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openReport: true,
        }
    }

    componentDidMount() {
        this.props.addBreadcrumbs({ to: '/raportit/vertailu/raportti', label: 'vertailu' });
    }


    leaveComparison = () => {
        
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="container">
            <Row className="report">
                <Col style={{ height: '100%', overflow: 'auto', padding: '0 2rem' }}>
                    {this.props.comparisonReport.length === 0 &&
                        <div >
                            <Row className="survey-buttonfilters-row ">
                                <Col md={{ size: 4 }} xs={{ size: 12 }}>
                                    <button className="report-button-filter" onClick={() => this.leaveComparison()}>Poistu vertailusta</button>
                                </Col>
                            </Row>
                            <Row className="survey-main-row">

                                <Col md={{ size: 12 }}>
                                    Valituilla kartoituksilla ei löydy vastauksia.
                                </Col>
                            </Row>
                        </div>
                    }
                    {this.props.comparisonReport.map((survey, ssIndex) => {
                        const surveyColor = survey.surveyColor;
                        const surveyDifference = typeof survey.surveyTotalStatistics.difference !== 'undefined' ? survey.surveyTotalStatistics.difference : 0;
                        return typeof survey.surveyTotalStatistics !== 'undefined' &&
                            <div key={ssIndex}>
                                <Row className="survey-buttonfilters-row">
                                    <Col md={{ size: 4 }} xs={{ size: 12 }}>
                                        <button className="report-button-filter" onClick={() => this.leaveComparison()}>Poistu vertailusta</button>
                                    </Col>
                                </Row>
                                <Row className="survey-main-row">
                                    <div className="ball-container">
                                        <div className="large-score-ball" style={{ backgroundColor: surveyColor, boxShadow: "0 0 0 2pt " + surveyColor }}>
                                            {surveyDifference > 0 ? "+" + surveyDifference : surveyDifference}
                                        </div>
                                    </div>
                                    <Col md={{ size: 11 }} className="survey-title-container super-border-left" style={{ borderColor: surveyColor }}>
                                        <Row className="survey-title-row">
                                            <Col md={{ size: 12 }}>
                                                <h2>{survey.title}</h2>
                                                <hr />
                                                <TextEditor content={survey.text} onTextChange={() => { return }} readOnly={true} />
                                            </Col>
                                        </Row>
                                        {survey.questionGroups.map((group, gIndex) => {
                                            const groupColor = group.groupColor;
                                            const groupDifference = group.groupTotalStatistics !== undefined && typeof group.groupTotalStatistics.difference !== 'undefined' ? group.groupTotalStatistics.difference : 0;
                                            return typeof group.groupTotalStatistics !== 'undefined' &&

                                                <Row className="questiongroup-main-row" key={gIndex}>
                                                    <div className="ball-container">
                                                        <div className="medium-score-barball" style={{ backgroundColor: groupColor, boxShadow: "0 0 0 2pt " + groupColor }}>{groupDifference > 0 ? "+" + groupDifference : groupDifference}</div>
                                                    </div>
                                                    <Col md={{ size: 11 }} className="questiongroup-title-container-comparison super-border-left" style={{ borderColor: groupColor }}>
                                                        <Row className="questiongroup-title-row" style={{ marginBottom: '3.5em' }}>
                                                            <Col md={{ size: 12 }}>
                                                                <h2>{group.title}</h2>
                                                                <ComparisonBar
                                                                    firstNames={this.props.firstNames}
                                                                    secondNames={this.props.secondNames}
                                                                    feedbacks={group.feedbacks}
                                                                    avg={group.groupTotalStatistics.avg}
                                                                    scores={group.groupTotalStatistics.scores}
                                                                    ballColor={groupColor} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {group.questions.map((question, qIndex) => {
                                                                if (question.answerStatistics) {
                                                                    const questionColor = question.questionColor;
                                                                    const questionDifference = typeof question.answerStatistics.difference !== 'undefined' ? question.answerStatistics.difference : 0;
                                                                    return (
                                                                        <Col md={{ size: 12 }} className="question-title-container-comparison" key={qIndex}>
                                                                            <Row>
                                                                                <Col className="col-xl-auto" md="3">
                                                                                    <div className="ball-container-comparison">
                                                                                        <div className="small-score-barball" style={{ backgroundColor: questionColor, boxShadow: "0 0 0 2pt " + questionColor }}>{questionDifference > 0 ? "+" + questionDifference : questionDifference}</div>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="col-xl" md="9">
                                                                                    <h4>{question.text}</h4>
                                                                                    <ComparisonBar
                                                                                        firstNames={this.props.firstNames}
                                                                                        secondNames={this.props.secondNames}
                                                                                        feedbacks={group.feedbacks}
                                                                                        avg={question.answerStatistics.avg}
                                                                                        scores={question.answerStatistics.scores}
                                                                                        ballColor={questionColor} />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    )
                                                                }
                                                                return null
                                                            })}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                        })}
                                    </Col>
                                </Row>
                            </div>
                    })}
                </Col>
            </Row>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSurveyTree: (company) => dispatch(ActionTypes.getSurveyTree(company)),
        onEmailSend: (params) => dispatch(ActionTypes.sendEmail(params)),
        getSurveyStatistics: (company, date = null) => dispatch(ActionTypes.getSurveyStatistics(company, date)),
        onSaveNotes: (params) => dispatch(ActionTypes.saveNotes(params)),
        onNoteCheck: (params) => dispatch(ActionTypes.fetchNotes(params)),
        // onReportClicked: (params) => dispatch(ActionTypes.getReport(params)),
        onNoteEdit: (params) => dispatch(ActionTypes.editNote(params)),
        getComparisonReport: (params) => dispatch(ActionTypes.getComparisonReport(params)),
        onComparisonEnd: (params) => dispatch(ActionTypes.endComparison(params)),
        clearComparisonReport: (params) => dispatch(ActionTypes.clearComparisonReport(params)),
        printReport: (params) => dispatch(ActionTypes.printPdfReport(params)),
        onTaskUpdated: (params) => dispatch(ActionTypes.updateTaskCard(params)),
        addBreadcrumbs: (params) => dispatch(ActionTypes.addBreadcrumbs(params))
    }
}

const mapStateToProps = state => {
    return {
        recievedCode: state.reportAndTab.recievedCode,
        activeReports: state.surveys.activeReports,
        targetNotes: state.surveys.targetNotes,
        targetId: state.reportAndTab.targetId,
        surveyId: state.reportAndTab.surveyId,
        comparisonTree: state.reportAndTab.comparisonTree,
        openComparison: state.reportAndTab.openComparison,
        comparisonReport: state.comparison.comparisonReport,
        firstNames: state.comparison.firstColumnNames,
        secondNames: state.comparison.secondColumnNames,
        selectedBusiness: state.reports.selectedBusiness,
        taskData: state.reportAndTab.taskData,
        chosenSurveyTargets: state.reports.sentSurveys
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainReport));
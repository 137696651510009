export const STORE_CATEGORIES = "STORE_CATEGORIES";
export const STORE_GROUP = "STORE_GROUP";
export const STORE_CATEGORY = "STORE_CATEGORY";
export const START_CATEGORY = "START_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const EDIT_CATEGORY_FROM_SENT_SURVEYS = "EDIT_CATEGORY_FROM_SENT_SURVEYS";
export const CATEGORY_VIEW = "CATEGORY_VIEW";
export const QUESTIONGROUP_VIEW = "QUESTIONGROUP_VIEW";
export const EDIT_QUESTION = "EDIT_QUESTION";
export const SAVE_QUESTION = "SAVE_QUESTION";
export const DELETE_GROUP = "DELETE_GROUP";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_QUESTION = "SET_QUESTION";
export const SET_QUESTIONGROUP = "SET_QUESTIONGROUP";
export const GET_SURVEY_DOCUMENT = "GET_SURVEY_DOCUMENT";
export const REORDER_SURVEY_QUESTIONS = "REORDER_SURVEY_QUESTIONS";
export const TAG_SEARCH = "TAG_SEARCH";
export const TAG_SAVE = "TAG_SAVE";
export const SET_TAGS = "SET_TAGS"
export const LOADING_STATUS = "LOADING_STATUS";
export const RESET_BUILDER = "RESET_BUILDER";
export const RESET_BUILDER_REDUX = "RESET_BUILDER_REDUX";
export const ADD_POINTS = "ADD_POINTS";
export const DECREMENT_POINTS = "DECREMENT_POINTS";
export const SET_POINTS = "SET_POINTS";
export const ADD_GROUP_POINTS = "ADD_GROUP_POINTS";
export const DECREMENT_GROUP_POINTS = "DECREMENT_GROUP_POINTS";
export const STORE_SURVEYVARIABLES = "STORE_SURVEYVARIABLES";
export const CHANGE_TAB_FOR_QUESTIONOPTION = "CHANGE_TAB_FOR_QUESTIONOPTION";
export const NEW_SURVEY = "NEW_SURVEY";
export const STORE_SUMMARY = "STORE_SUMMARY";
export const MODIFY_CATEGORY_DATA = "MODIFY_CATEGORY_DATA";
export const STORE_SENT_SURVEYLIST = "STORE_SENT_SURVEYLIST";
export const STORE_ACTIVE_SENT_SURVEYLIST = "STORE_ACTIVE_SENT_SURVEYLIST";
export const STORE_SURVEY_LINKTREE = "STORE_SURVEY_LINKTREE";

import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { Menu } from "./Menu";
import MenuToggle from './MenuToggle';
import {useDimensions} from './use-dimensions';

import './filter.css';
const filterMainBar = {
  open: (height = '6em', width = 200) => ({
    transition: {
      type: "spring",
      stiffness: 120,
      restDelta: 2
    }
  }),
  closed: {
    height: 0, 
    transition: {
      delay: 0.8,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

export const MenuContainer = ({surveyFilters, handleMainFilters, mainFilters}) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const {height} = useDimensions(containerRef);

  function handleClickOutside(event) {
    if(containerRef.current && !containerRef.current.contains(event.target)) {
      if(isOpen)
        toggleOpen();
    }
  }

  function handleFilterSelection(filter) {
    handleMainFilters(filter);
    toggleOpen();
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  })

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      <motion.div className="background" variants={filterMainBar} />
        <Menu surveyFilters={ surveyFilters } handleSelection={ handleFilterSelection } mainFilters={ mainFilters } className={isOpen ? "dropdown-menu show" : "dropdown-menu"}/>
      <MenuToggle toggle={() => toggleOpen()}/>
    </motion.nav>
  );
};

export default MenuContainer;

import React, { Component, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar'
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler'
import { NotificationManager } from 'react-notifications';
import { confs } from '../../../modules/config';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ContactModal } from '../../../Utilities/Modals/ContactModal';

export default class ClientsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            business: {
                businessName: "",
                address: "",
                businessID: "",
                postCode: "",
                businessTags: []
            },
            user: {
                email: "",
                phone: ""
            },
            startNew: false,
            addUser: false,
            filter: "",
            selected: null,
            cancelModalIsOpen: false,
            cancellingCompany: null,
            editBusinessModalIsOpen: false,
            targetBusiness: {},
            contactModalOpen: false,
            removeModalOpen: false,
            selectedCompany: null,
            deleteText: ""
        }
        this.startAddingUser = this.startAddingUser.bind(this);
        this.companyFormHandler = this.companyFormHandler.bind(this);
        this.userFormHandler = this.userFormHandler.bind(this);
        this.prepareBusinessStackAndSave = this.prepareBusinessStackAndSave.bind(this);
    }

    componentWillUnmount() {
        this.props.resetCollaborations();
        
    }
    startAddingUser() {
        this.setState({ addUser: !this.state.addUser });
    }

    companyFormHandler(type, value) {
        let companyStack = JSON.parse(JSON.stringify(this.state.business))

        if (type === 'postCode') {
            if (value.length <= 5 && !isNaN(parseInt(value))) {
                companyStack[type] = value;
            }
        } else {
            companyStack[type] = value;
        }

        this.setState({ business: companyStack });
    }

    prepareBusinessStackAndSave(e) {
        e.preventDefault();
        let params = {
            company: {
                business: {
                    ...this.state.business
                },
                user: {
                    ...this.state.user,
                    phone: "+358" + this.state.user.phone
                }
            }
        }
        this.props.modify(params);

        let initialState = {
            business: {
                businessName: "",
                address: "",
                businessID: "",
                postCode: "",
                businessTags:[]
            },
            user: {
                email: "",
                phone: ""
            },
            startNew: false,
            addUser: false
        }
        this.setState(initialState);
    }

    userFormHandler(type, value) {
        let userStack = JSON.parse(JSON.stringify(this.state.user))

        userStack[type] = value;

        this.setState({ user: userStack });
        //<input type="search" className="search-bar-input" placeholder="Etsi yritystä" value={this.state.filter} onChange={(event) => this.setState({ filter: event.target.value })} />
    }

    sendPermissionRequest = company => {
        this.props.resendPermissionRequest( company )
    }

    toggleDropdown = y => {
        if ( this.state.selected === y ) {
            this.setState({ selected: null })
        } else {
            this.setState({ selected: y })
        }
    }

    toggleCancelModal = ( company = null ) => {
        let stateObj = {
            cancelModalIsOpen: !this.state.cancelModalIsOpen
        }

        if( company ) { stateObj.cancellingCompany = company }
        this.setState( stateObj )
    }

    confirmCancel = () => {
        this.props.cancelPendingCollaboration( this.state.cancellingCompany )
        this.toggleCancelModal()
    }

    toggleCompanyModal = (event, company = {}) => {
        event.stopPropagation();
        this.setState({
            editBusinessModalIsOpen: !this.state.editBusinessModalIsOpen,
            targetBusiness: company
        })
    }

    toggleContactModal = () => {
        this.setState({contactModalOpen: !this.state.contactModalOpen})
    }

    toggleRemoveModal = (e, company) => {
        this.setState({removeModalOpen:!this.state.removeModalOpen, selectedCompany: company})
    }

    removeCompanyFromList = async() => {
        if(this.state.deleteText === 'poista'){
            let payload = {
                companyId: this.state.selectedCompany._id
            }
            const response = await executeRequest('business/removeCollaboration',payload)
            if(response && response.collaborations){
                NotificationManager.success('Asiakas poistettu listalta onnistuneesti!', '',3000)
                this.props.updateList()
            } else{
                NotificationManager.error('Tapahtui virhe!', 'Virhe!',3000)
            }
            this.setState({removeModalOpen:false, selectedCompany: null})
            this.setState({deleteText: ""})
        }
    }
    
    toggleDeleteModal = () => {
        this.setState({removeModalOpen:!this.state.removeModalOpen})
        this.setState({deleteText: ""})
        let deleteInput = document.getElementById("deleteInput")
        
    }

    render() {
        let i = 0;
        const { companyList, modulesInUse } = this.props
        return (
            <div className="inner-client-container" style={{ paddingTop:0}}>
                {this.state.editBusinessModalIsOpen && 
                    <EditBusinessModal 
                        isOpen={ this.state.editBusinessModalIsOpen }
                        toggleModal={ this.toggleCompanyModal }
                        business={ this.state.targetBusiness }
                        tags={ this.state.targetBusiness.businessTags} 
                        setTagOptions={this.setTagOptions}
                    />
                }
                {this.state.contactModalOpen &&
                    <ContactModal
                        isOpen={this.state.contactModalOpen}
                        toggleModal={this.toggleContactModal}
                        business={this.state.targetBusiness}
                        allowRemove={true}
                    />
                }
                {this.state.removeModalOpen && 
                    <Modal isOpen={this.state.removeModalOpen} toggle={() => this.toggleDeleteModal()}>
                        <ModalHeader><b>Huom! Olet poistamassa asiakasta listalta</b></ModalHeader>
                        <ModalBody>
                            <p>Oletko varma, että haluat poistaa <b>{this.state.selectedCompany.businessName}</b> listalta? Tätä toimintoa ei voi peruuttaa</p>
                            <div style={{ marginTop: '1em'}}>
                                <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                                <Input id="deleteInput" type="text" value={this.state.deleteText} onChange={(e) => this.setState({deleteText: e.target.value})}/>
                                <div className="email-check-box">
                                    {this.state.deleteText === "poista"?
                                        <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                        :
                                        <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                    }
                                </div>
                            </div>
                        </ModalBody>
                    <ModalFooter>
                        <a onClick={() => this.toggleDeleteModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                        <button onClick={() => this.removeCompanyFromList()} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>
                    </ModalFooter>
                    </Modal>
                }
                <ConfirmCancelModal isOpen={ this.state.cancelModalIsOpen } toggleModal={ this.toggleCancelModal } confirmCancel={ this.confirmCancel } />
                <div className="client-box-header bigger-text" style={{ display: 'flex', alignItems: 'center', borderBottom: 'none', borderRadius: '5px 5px 0 0', height:'100%' }}>
                    <p>Asiakkaat</p>
                    <div style={{ marginLeft: '3em', width: '100%', marginRight:'1.75em', alignItems: 'center' }}>
                        <input type="search" className="search-bar-open form-control" placeholder={'Hae asiakkaista'} value={this.state.filter} onChange={(event) => this.setState({ filter: event.target.value })} />
                    </div>
                </div>
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{width: 'auto', height: '500px', borderRadius: '10px', marginLeft: '1em', marginRight:'1em' }}
                    className="custom-scrollbars" 
                >
                    <>
                        <AnonClientRow inspect={ this.props.selectBusiness } />
                        {companyList && companyList.map((company, y) => {
                        if (company.businessName && (company.businessName.toLowerCase().includes(this.state.filter.toLowerCase()) || company.businessID.toLowerCase().includes(this.state.filter.toLowerCase())) || (company.businessTags && company.businessTags.filter(tag => tag.label.toUpperCase().includes(this.state.filter.toUpperCase())).length > 0)) {
                            let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row';
                            i++;
                            
                            if( company.isPending ) {
                                return (
                                    <div key={y} style={{ paddingLeft: '1rem' }} className={oddEvenClass + ' list-row'}>
                                        <Row className='task-list-content'>
                                            <Col xs="5">
                                                {company.businessName} <small>(Odottaa vastausta)</small>
                                            </Col>
                                            <Col xs="3" style={{ textAlign: 'center' }}>
                                                {company.businessID}
                                            </Col>
                                            <Col xs="4" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '' }}>
                                                <Dropdown isOpen={ this.state.selected === y } style={{ height: '100%', width: '100%', cursor: 'pointer' }} onClick={ (event)=> event.stopPropagation() } toggle={ () => this.toggleDropdown( y ) } >
                                                    <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                        <i className="fas fa-bars" title="Valikko"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={ () => this.sendPermissionRequest( company ) } >Lähetä yhteistyöpyyntö uudelleen</DropdownItem>
                                                        <DropdownItem onClick={ () => this.toggleCancelModal( company ) } >Peruuta yhteistyöpyyntö</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={{ size: 12 }}>
                                                <p className="lighter-text">{company.address}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                )

                            } else {
                                return (
                                    <div key={y} onClick={() => this.props.selectBusiness( company )} style={{ paddingLeft: '1rem' }} className={oddEvenClass + ' list-row'}>
                                        <Row className='task-list-content'>
                                            <Col xs="3">
                                                <p className="company-businessname" title={company.businessName}>{company.businessName}</p>
                                                <p className="lighter-text">{company.address}</p>
                                            </Col>
                                            <Col xs="2" style={{ textAlign: 'center' }}>
                                                {company.businessID}
                                            </Col>
                                            <Col xs="2" style={{}}>
                                                <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                                                    {company.businessTags && company.businessTags.slice(0, 7).map((tag, i) => {                        
                                                            return(<div key={i} style={{paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text client-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                        })  
                                                    }                                                   
                                                    {company.businessTags && company.businessTags.length > 7 &&  
                                                            <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                                                <i className="material-icons" title={tagsToMore(company.businessTags.slice(7,company.businessTags.length))}>more_horiz</i>
                                                            </div>
                                                    }  
                                                </div>                                            
                                            </Col>
                                            
                                            {/* <Col xs="3" style={{ textAlign: 'right', paddingRight: '2em' }}>
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <ToggleYTJButton selectedBusiness={company} enableYTJ={company.enableYTJ} />
                                                    <button className="small-white-button lighter-text wide-button" 
                                                        onClick={(e) => this.toggleCompanyModal(e, company)}>Muokkaa
                                                    </button>
                                                </div>
                                            </Col> */}
                                            <Col xs="2">
                                                <ToggleYTJButton selectedBusiness={company} enableYTJ={company.enableYTJ} />
                                            </Col>
                                            <Col xs="2">
                                            <button className="small-white-button lighter-text wide-button" onClick={(e) => (e.stopPropagation(), this.toggleCompanyModal(e, company))}>Muokkaa</button>
                                            </Col>
                                            <Col xs="1" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '' }}>    
                                                <Dropdown isOpen={ this.state.selected === y } style={{ height: '100%', width:'100%', cursor: 'pointer' }} onClick={ (event)=> event.stopPropagation() } toggle={ () => this.toggleDropdown( y ) } >
                                                    <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                        <i className="fas fa-bars" title="Valikko"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={(e) => this.toggleCompanyModal(e, company)}>Muokkaa</DropdownItem>
                                                        {modulesInUse.contactManagement && <DropdownItem onClick={() => (this.setState({targetBusiness: company}, ()=> this.toggleContactModal()))}>Hallitse kontakteja</DropdownItem>}
                                                        <DropdownItem onClick={(e) => this.toggleRemoveModal(e, company)}>Poista asiakas</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col xs={{ size: 12 }}>
                                                <p className="lighter-text">{company.address}</p>
                                            </Col>
                                        </Row> */}
                                    </div>
                                )
                            }
                        }
                        return null
                    })}
                    {/* {i === 0 &&
                        <Col style={{ marginTop: '1em' }}>
                            <span id='no-clients-text'>Yrityksiä ei löytynyt</span>
                        </Col>
                    } */}
                    </>
                </Scrollbars>
            </div>
        )
    }
}

const tagsToMore = (props) => {
    let tagss = ""
    
    if(props !== undefined){
        props.filter(tags => tagss = tagss + "  " + tags.label.toString())

        return(tagss)
    }
}

const ToggleYTJButton = ({selectedBusiness, enableYTJ = false}) => {

    const [enabled, setEnabled] = useState(enableYTJ);
    const dispatch = useDispatch();

    const toggleYTJ = async (e) => {
        e.stopPropagation();
        
        let toggledStatus = enabled ? false : true

        let payload = {
            ...selectedBusiness,
            enableYTJ: toggledStatus,
            responsible: null
        }

        const response = await executeRequest('modify/business', {targetBusiness: payload}, dispatch)

        if(!!response) {
            if(toggledStatus)
                NotificationManager.success("Lisätty YTJ Integraatio yritykselle", "", 3000);
            else
                NotificationManager.success("YTJ Integraatio poistettu käytöstä", "", 3000);
            
            setEnabled(toggledStatus)
        }
    }

    return (
        <button 
            className={enabled ? "small-white-button lighter-text wide-button active" : "small-white-button lighter-text wide-button inactive"} onClick={(e) => toggleYTJ(e)}>
            YTJ
        </button>
    )
}

const ConfirmCancelModal = props => {
    const { isOpen, toggleModal, confirmCancel } = props

    return (
        <Modal isOpen={ isOpen } toggle={ toggleModal }>
            <ModalHeader toggle={ toggleModal }>Peruuta yhteistyöpyyntö</ModalHeader>
            <ModalBody>
                <p>Haluatko varmasti peruuttaa yhteistyöpyynnön? Kohdeyritys ei tämän jälkeen pysty enää hyväksymään aiemmin lähettämäänne pyyntöä.</p>
            </ModalBody>
            <ModalFooter> 
                <a onClick={ toggleModal } className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Takaisin</a>
                <Button onClick={ confirmCancel } color="danger">Vahvista peruutus</Button>
               
            </ModalFooter>
        </Modal>
    )
}


const INITIAL_STATE = {
    label: "",
    value: "",
    type: "textEditor"
}


const EditBusinessModal = (props) => {
    const { isOpen, toggleModal, business } = props
    const [targetBusiness, setBusinessValues] = useState({...business});
    const [enterpriseUsers, setEnterpriseUsers] = useState([]);
    const [customField, setCustomField] = useState(INITIAL_STATE)
    const [appendModal, setAppendModal] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [selectedField, setSelectedField] = useState(INITIAL_STATE)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [customFields, setCustomFields] = useState([])
    const [alterCustomFields, setAlterCustomFields] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();  

    useEffect(() => {
        //Get enterprise users get/enterpriseEmailsIds
        const getEmails = async () => {
            const response = await executeRequest('get/enterpriseEmailsIds', {}, dispatch)

            if(!!response) {
                setEnterpriseUsers(response);
            }
        }
        
        getEmails();
    }, [])

    useEffect(()=> {
        if(targetBusiness){
            getCustomFields()
            setCustomFields(targetBusiness.customFields)
        }
    },[])

    const getCustomFields = async() => {
        let payload = {
            targetBusinessId: targetBusiness._id
        }
        const response = await executeRequest('get/enterpriseCustomFields', payload)
        if(response && response.customFields){
            setCustomFields(response.customFields)
        }
    }

    const onSave = async (e) => {
        //modify/business
        const { responsible } = targetBusiness
        let payload = {
            ...targetBusiness
        }

        if(responsible) {
            const targetUser = enterpriseUsers.filter(x => x.email === responsible);

            payload = {
                ...payload,
                responsible: {...targetUser[0]}
            }

        }

        if(customFields){
            payload={
               ...payload,
               customFields: customFields,
               alterCustomFields: alterCustomFields
            }
        }

        const response = await executeRequest('modify/business', {targetBusiness: payload}, dispatch)
        if(!!response) {
            //refresh item in redux
            dispatch(updateBusinessValue(response));
            NotificationManager.success("Tiedot tallennettu", "", 3000);
            toggleModal(e);
        }   

        setAlterCustomFields(false)
    }

    const updateBusinessValue = response => {
        return {
            type: 'UPDATE_BUSINESS_INFO',
            payload: response
        }
    }

    const handleValues = event => {
        const {name, value} = event.target

        setBusinessValues({
            ...targetBusiness,
            [name]: value
        });
    }

    const setTagOptions = (tags) => {
        setBusinessValues(prevTargetBusiness => ({
            ...prevTargetBusiness,
            businessTags: tags
        }));
    }

    const handleCustomChange = (e) => {
        const {name, value} = e
        setCustomField(prevData => ({
            ...prevData,
            [name]:value
        }))
        setAlterCustomFields(true)
    }

    const createNew = async() => {
        if(customField.label.length > 0){ 
           let payload = {
            customField: customField,
            targetBusinessId: targetBusiness._id
            }
            const response = await executeRequest('create/newCustomField',payload)
            if(response.success === true){
                getCustomFields()
                NotificationManager.success("Tieto luotu onnistuneesti!", "", 3000);
                setCustomField(INITIAL_STATE)
                setAppendModal(!appendModal)
                setAlterCustomFields(true)
            }
        } else {
            NotificationManager.error('Anna tietueelle nimi!', 'virhe', 3000)
        }
        
    }

    const handleListItemChange = (e,index, obj) => {
        const {name, value} = e.target
       
        setCustomFields(prevData => ([
            ...prevData.map((x,y) => { 
                if(y === index){
                    return {
                        ...obj,
                        [name]:value
                    }
                }
                return x
            })
        ]))

    }

    const removeField = (index, field) => {
        setSelectedField(field)
        setSelectedIndex(index)
        setIsDelete(true)
    }

    const handleDelete = async() => {
        let payload = {
            selectedField: selectedField
        }
        const response = await executeRequest('delete/deleteCustomField',payload)
        if(response.success === true){
            getCustomFields()
            NotificationManager.success("Tieto poistettu onnistuneesti!", "", 3000);
            setCustomField(INITIAL_STATE)
            setIsDelete(!isDelete)
        } else {
            NotificationManager.error("Tietoa ei voitu poistaa!", "", 3000);
        }
    }

    return (
        <>  
            {selectedField &&
                <Modal isOpen={isDelete} toggle={() => setIsDelete(!isDelete)}>
                    <ModalHeader toggle={() => setIsDelete(!isDelete)}>{selectedField.label || ""}</ModalHeader>
                    <ModalBody>
                        <p>Oletko varma, että haluat poistaa tietueen? 
                            <b> Huom! Poistaminen tallentuu vasta modaalin Tallenna-nappia painamalla</b>
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <a onClick={() => setIsDelete(!isDelete)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                        <button onClick={() => handleDelete()} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                    </ModalFooter>
                </Modal>
            }
            <Modal isOpen={appendModal} toggle={() => setAppendModal(!appendModal)} >
                <ModalHeader toggle={()=> setAppendModal(!appendModal)}>Lisää yritykselle tietue</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Tietueen nimi</Label>
                        <Input name="label" value={customField.label} onChange={(e) => handleCustomChange(e.target)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Tietueen arvo</Label>
                        <Input name="value" type="text" value={customField.value} onChange={(e) => handleCustomChange(e.target)}/>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => (setAppendModal(!appendModal), setCustomField(INITIAL_STATE))}>Peruuta</a>
                    <button className="small-white-button wide-button" style={{ }} onClick={() => createNew()}>Tallenna</button>   
                </ModalFooter>
            </Modal>
            <Modal isOpen={ isOpen } toggle={ toggleModal } size="lg">
                <ModalHeader toggle={ toggleModal }>Yrityksen tiedot</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="businessName">Nimi</Label>
                            <Input type="text" name="businessName" id="businessName" placeholder="Nimi" value={targetBusiness.businessName} onChange={(e) => handleValues(e)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="businessID">Y-tunnus</Label>
                            <Input type="text" name="businessID" id="businessID" placeholder="Y-tunnus" value={targetBusiness.businessID} onChange={(e) => handleValues(e)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="address">Osoite</Label>
                            <Input type="text" name="address" id="address" placeholder="Osoite" value={targetBusiness.address} onChange={(e) => handleValues(e)}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="responsible">Vastuuhenkilö</Label>
                            <Input type="select" name="responsible" id="responsible" value={targetBusiness.responsible} onChange={(e) => handleValues(e)}>
                                <option value="">Valitse vastuuhenkilö</option>
                                {enterpriseUsers && enterpriseUsers.map(user => 
                                    <option key={user.id}>{user.email}</option>
                                )}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="businesTags">Tagit</Label>
                            <TagList businessTags={targetBusiness.businessTags}  setTagOptions={setTagOptions}></TagList>
                        </FormGroup>
                        {customFields && customFields.length > 0 && customFields.map((field, index)=> {
                            if(field && field.value){
                                return(
                                    <FormGroup key={index}>
                                        <Label>{field.label}</Label> 
                                        <i style={{ float: 'right', cursor: 'pointer', marginTop: '0.5em', fontSize: '0.8em' }} className="far fa-trash-alt" onClick={() => removeField(index,field)}></i>
                                        <Input type="text" name="value" value={field.value} onChange={(e) => handleListItemChange(e, index, field)}/>
                                    </FormGroup>
                                )
                            }
                        })}
                        <div id="divToAppend"></div>
                    </Form>
                    <i className="fas fa-plus dash-plusIcon" onClick={() => setAppendModal(!appendModal)}></i>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={ toggleModal }>Peruuta</a>
                    <button className="small-white-button wide-button" style={{ }} onClick={ onSave }>Tallenna</button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const AnonClientRow = props => {
    return (
        <div onClick={ () => props.inspect( 'anon' ) } style={ { paddingLeft: '1rem' } } className='odd-row list-row company-list'>
            <Row className='task-list-content'>
                <Col xs="3">
                    Ei kohdeyritystä
                </Col>
                <Col xs="2" style={ { textAlign: 'center' } }>
                   -
                </Col>
                <Col xs="4">
                </Col>
                <Col xs="3" style={ { textAlign: 'left', paddingRight: '' } }>
                    <button className="small-white-button lighter-text wide-button">Tarkastele</button>
                </Col>
            </Row>
            <Row>
                <Col xs={ { size: 12 } }>
                    <p className="lighter-text">-</p>
                </Col>
            </Row>
        </div>
    )
}

const TagList = props => {
    
    async function promiseOptions(inputValue) {
        let params = {
            data: inputValue,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const data = fetch(confs.url + 'tagSearch', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => response.json())

        let tags = await data;

        return tags
    };

    function NoOptionsMessage(props) {
        if (props.options.length) return null;
        return <div {...props.innerProps} style={{padding: 5}}>Voit hakea tai lisätä tageja kirjoittamalla</div>;
      }

    const components = {
        NoOptionsMessage
    }

    function formatCreateLabelCreator(value) {
        return 'Lisää tagi: "' + value + '"'
    }

    return (
        // <Input placeholder="Uusi tagi" onChange={props.searchTags} ></Input>    
            <AsyncCreatableSelect 
                loadOptions={promiseOptions} 
                components={components} 
                isMulti 
                value={props.businessTags} 
                onChange={selectedTags => props.setTagOptions(selectedTags)}
                placeholder=""
                className='react-select-container'
                classNamePrefix="react-select"
                formatCreateLabel={formatCreateLabelCreator} 
                />
    )
}
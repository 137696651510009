import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Button
} from 'reactstrap'
import { useDispatch } from 'react-redux'
import { NotificationManager } from 'react-notifications'

//actions
import { saveTrelloList, getTrelloBoardLists, getTrelloUserBoards } from '../../actions/ActionCreator'

const TrelloCreationModal = props => {
  const { isOpen, toggle, businessId } = props

  const [ apiKey, setApiKey ] = useState( '' )
  const [ apiToken, setApiToken ] = useState( '' )
  const [ lists, setLists ] = useState( [] )
  const [ boards, setBoards ] = useState( [] )
  const [ selectedList, setSelectedList ] = useState( null )
  const [ selectedBoard, setSelectedBoard ] = useState( null )
  const [ boardModalIsOpen, setBoardModalIsOpen ] = useState( false )

  const [ loadingLists, setLoadingLists ] = useState( false )

  // get board lists when selected board updates
  useEffect( () => {
    if ( selectedBoard !== null ) {
      getBoardLists()
    }
  }, [ selectedBoard ] )

  const dispatch = useDispatch()

  const saveList = () => {
    if ( selectedList !== null && selectedList.trim() !== '' ) {
      
      const listData = {
        apiKey,
        apiToken,
        listId: selectedList,
        businessId
      }

      dispatch( saveTrelloList( listData ) )
      
      resetModal()
      toggle()

    } else {
      NotificationManager.error( 'Valitse taulu ja lista ennen tallennusta.', '', 4000 )
    }
  }

  const getBoards = async () => {
    const data = {
      apiKey,
      apiToken
    }

    const fetchedBoards = await dispatch( getTrelloUserBoards( data ) )

    if ( fetchedBoards.length > 0 ) {
      setBoards( fetchedBoards )

      setBoardModalIsOpen( true )
    }
  }

  const getBoardLists = async () => {
    setLoadingLists( true )
    const boardData = {
      apiKey,
      apiToken,
      boardId: selectedBoard
    }

    const fetchedLists = await dispatch( getTrelloBoardLists( boardData ) )
    setLists( fetchedLists )
    
    setTimeout( () => {
      setLoadingLists( false )
    }, 300 )
  }

  const resetModal = () => {
    setBoardModalIsOpen( false )
    setSelectedBoard( null )
    setSelectedList( null )
    setBoards( [] )
    setLists( [] )
  }

  const selectBoard = boardId => {
    // reset selected list when selected board changes
    setSelectedList( null )
    setSelectedBoard( boardId )
  }

  return (
    <Modal isOpen={ isOpen } toggle={ toggle } size='xl'>
      <ModalBody>

        <Row>
          <Col>
            <div style={{ marginBottom: '1em' }}>
              <h5 style={ { fontWeight: 'bold' } }>Löydät Trellon API-avaimesi osoitteesta: <a target="_blank" rel="noopener noreferrer" href="https://trello.com/app-key">https://trello.com/app-key</a></h5>
            </div>            
            
            <Label>API-avain</Label>
            <Input value={ apiKey } name="key" type="text" onChange={ ( e ) => setApiKey( e.target.value ) }></Input>
          </Col>
        </Row>

        <Row style={ { marginTop: '1em' } }>
          <Col>
            <Label>API-token</Label>
            <Input value={ apiToken } name="id" type="text" onChange={ ( e ) => setApiToken( e.target.value ) }></Input>
          </Col>
        </Row>

        <Modal isOpen={ boardModalIsOpen } toggle={ () => setBoardModalIsOpen( !boardModalIsOpen ) } size='xl'>

          <ModalBody>

            <Row>
              <Col>
                <Label>Valitse taulu, josta lista haetaan</Label>
                <Input type='select' onChange={ ( e ) => selectBoard( e.target.value ) }>
                  <option></option>
                  { boards.map( ( board, index ) => {
                    return <option key={ index } value={ board.id }>{ board.name }</option>
                  } ) }
                </Input>
              </Col>
            </Row>

            <Row style={ { marginTop: '1em' } }>
              <Col>
                <Label>Valitse lisättävä lista</Label>
                { loadingLists ? (
                  <div style={ { width: '100%', display: 'flex', justifyContent: 'center' } }>
                    <LoaderSpinner />
                  </div>
                ) : (
                    <Input type='select' onChange={ ( e ) => setSelectedList( e.target.value ) }>
                      <option value="">{ lists.length > 0 ? 'Valitse' : '' }</option>
                      { lists.map( ( list, index ) => {
                        return <option key={ index } value={ list.id }>{ list.name }</option>
                      } ) }
                    </Input>
                  ) }

              </Col>
            </Row>

          </ModalBody>

          <ModalFooter>
            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={ () => setBoardModalIsOpen( !isOpen ) }>Peruuta</a>
            <Button onClick={ () => saveList() }>Tallenna</Button>
          </ModalFooter>

        </Modal>


      </ModalBody>
      <ModalFooter>
        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={ toggle }>Sulje</a>
        <Button onClick={ getBoards }>Jatka</Button>
      </ModalFooter>
    </Modal>
  )
}

const LoaderSpinner = () => {
  return (
    <div style={ { paddingLeft: '.5em', paddingRight: '.5em' } }>
      <div style={ { width: '2rem', height: '2rem' } } className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default TrelloCreationModal
class confsCreator {
	constructor() {
		this.serverBase = ''
		this.frontpageBase = ''

		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			this.serverBase = "http://localhost:9001"
			this.frontpageBase = "http://localhost:3008"
		} else {
			this.serverBase = "https://portal.everbetter.fi"
			this.frontpageBase = "https://app.everbetter.fi"
		}

		this.url = `${this.serverBase}/api/`
		this.host = this.serverBase
		this.frontpageUrl = this.frontpageBase

		const userType = window.sessionStorage.getItem( "userType" )
		if( userType ) {
			this.setUrls( userType )
		}
	}
	
	setUrls = ( userType ) => {
		switch( userType ) {
			case 'enterprise':
				this.url = `${this.serverBase}/api/`
				this.host = this.serverBase
				this.frontpageUrl = this.frontpageBase
				return
			case 'business':
			default:
				this.url = `${this.serverBase}/api/business/`
				this.host = this.serverBase
				this.frontpageUrl = this.frontpageBase
				return
		}
	}
}

export const confs = new confsCreator()
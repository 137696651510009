import React, { useState, useEffect } from 'react';
import { NavLink, NavItem, Nav } from 'reactstrap';

export const TabHandler = (props) => {
    const {activeTab, setActiveTab, } = props
    const {files, plans, selectedSurveys, activities, compareViews} = props.project
    let activitiesLength = (activities && activities.length)? activities.length : 0
    let comparesLength = (compareViews && compareViews.length)? compareViews.length : 0
    return(
        <div>
            <Nav pills className="project-nav-tabs">
                <NavItem style={{ width: '100%', textAlign: 'center', margin:0, fontWeight: 'bold' }}>
                    <NavLink className={activeTab === 'plans' ? 'active' : ''} title={"Lomakkeet " + (plans.length > 0 ? '*' : '')} onClick={() => setActiveTab('plans')}>
                        <p>Lomakkeet </p>
                        {/* <span style={{fontSize:'0.7rem'}}>{plans.length}</span> */}
                    </NavLink>
                </NavItem>
                <NavItem style={{ width: '100%', textAlign: 'center', margin:0, fontWeight: 'bold' }} >
                    <NavLink className={activeTab === 'surveys' ? 'active' : ''} title={"Kartoitukset " + (selectedSurveys.length > 0 ? '*' : '')} onClick={() => setActiveTab('surveys')}>
                        <p>Kartoitukset </p>
                    </NavLink>
                </NavItem>
                <NavItem style={{ width: '100%', textAlign: 'center', margin:0, fontWeight: 'bold' }} >
                    <NavLink className={activeTab === 'attachments' ? 'active' : ''} title={"Liitteet " + (files.length > 0 ? '*' : '')} onClick={() => setActiveTab('attachments')}>
                        <p>Liitteet </p>
                    </NavLink>
                </NavItem>
                <NavItem style={{ width: '100%', textAlign: 'center', margin:0, fontWeight: 'bold' }} >
                    <NavLink className={activeTab === 'activities' ? 'active' : ''} title={"Tapahtumat " + (activitiesLength > 0 ? '*' : '')} onClick={() => setActiveTab('activities')}>
                    <p>Tapahtumat </p>
                    </NavLink>
                </NavItem>  
                <NavItem style={{ width: '100%', textAlign: 'center', margin:0, fontWeight: 'bold' }} >
                    <NavLink className={activeTab === 'templates' ? 'active' : ''} title={"Pohjat"} onClick={() => setActiveTab('templates')}>
                        <p>Pohjat</p>
                    </NavLink>
                </NavItem>  
                <NavItem style={{ width: '100%', textAlign: 'center', margin:0, fontWeight: 'bold' }} >
                    <NavLink className={activeTab === 'views' ? 'active' : ''} title={"Vertailut " + (comparesLength > 0 ? '*' : '')} onClick={() => setActiveTab('views')}>  
                        <p>Vertailut </p>
                    </NavLink>
                </NavItem>      
                {/* <NavItem style={{ width: '100%', textAlign: 'center', margin:0, fontWeight: 'bold' }} >
                    <NavLink className={activeTab === 'trello' ? 'active' : ''} title={"Trello"} onClick={() => setActiveTab('trello')}>  
                        <i className="fab fa-trello"></i>
                    </NavLink>
                </NavItem>                      */}
            </Nav>
        </div>
    )
} 
import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Button,
  ModalHeader
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import Select from 'react-select';
//actions
import { getTrelloUserBoards, saveTrelloKeys } from '../../../../Utilities/ProjectTimeline/Trello/Actions/TrelloActions'
import { getTrelloBoardLists, updateChecklistTrelloCard } from '../../../../Components/Reports/actions/ActionCreator';

export const TrelloOptionsModal = ({ isOpen, toggle, handleSelectedTrelloBoard, handleSelectedTrelloLists, trelloSelected, selectedTrelloBoard, onlyDeadlineCards, setOnlyDeadlineCards, forceUpdate, savePlanItemTrelloKeys, planItem, saveTrelloOptions, handleKeysRemoved }) => {
    const [ apiKey, setApiKey ] = useState( '' )
    const [ apiToken, setApiToken ] = useState( '' )
    const [ lists, setLists ] = useState( [] )
    const [keyHandler, setKeyHandler] = useState(true)
    const [userBoards, setUserBoards] = useState([])
    const [selectedBoard, setSelectedBoard] = useState({})
    const [boardLists, setBoardLists] = useState([]) 
    const [loader, setLoader] = useState(false)

    const [trelloConnection, setTrelloConnection] = useState(planItem.trelloOptions && planItem.trelloOptions.trelloConnection? planItem.trelloOptions.trelloConnection:false)

    const userTrelloConnection = useSelector((state) => state.authentication.trelloConnection)

    const [userHasTrelloKeys, setUserHasTrelloKeys] = useState(false)

    const dispatch = useDispatch()

    useEffect(()=>{
        if(planItem){
            if(planItem.trelloOptions && planItem.trelloOptions.trelloConnection){
                setTrelloConnection( planItem.trelloOptions.trelloConnection)
                setKeyHandler(false)
                if(planItem.trelloOptions.selectedTrelloLists){
                    setBoardLists(planItem.trelloOptions.selectedTrelloLists)
                }
                if(planItem.trelloOptions.selectedTrelloBoard){
                    setSelectedBoard(planItem.trelloOptions.selectedTrelloBoard)
                }
               
            } else {
                if(userTrelloConnection){
                    setApiKey(userTrelloConnection.apiKey)
                    setApiToken(userTrelloConnection.apiToken)
                    setUserHasTrelloKeys(true)
                }
            }
        }
    },[planItem, userTrelloConnection])

    const saveTrelloKeysToPlan = () => {
        let payload = {
            apiKey: apiKey,
            apiToken: apiToken
        }
        savePlanItemTrelloKeys(payload)
        setTrelloConnection(payload)
        if(!userHasTrelloKeys){
            dispatch(saveTrelloKeys(payload))
        }
        setKeyHandler(false)
    }

    useEffect(()=>{
        if(trelloConnection && trelloConnection.apiKey && trelloConnection.apiToken){
            setKeyHandler(false)
            setApiKey(trelloConnection.apiKey)
            setApiToken(trelloConnection.apiToken)
            if(boardLists.length === 0){
                getUserBoards()
            }
        }
    },[trelloConnection])

    const getUserBoards = async() => {
        let payload = {
            apiKey: trelloConnection.apiKey,
            apiToken: trelloConnection.apiToken
        }

        const boards = await dispatch( getTrelloUserBoards( payload ) )
        if(boards != null){
            if ( boards.length > 0 ) {
            let array = []
                boards.map((board)=>{
                    array.push({label:board.name, value:board.id, url:board.shortUrl})
                })
                setUserBoards(array)
            }
        }
    }

    const handleBoardSelectionChanged = (val) =>{
        //setLoader(true)
        setSelectedBoard(val)
        getSelectedBoardLists(val)
        handleSelectedTrelloBoard(val)
    }

    useEffect(()=>{
        if(selectedTrelloBoard && selectedTrelloBoard.value){
            if(trelloConnection){
                getSelectedBoardLists(selectedTrelloBoard)
                setSelectedBoard(selectedTrelloBoard)
            }
        }
    },[ forceUpdate])

    const getSelectedBoardLists = async(val) => {
        setLoader(true)
        const boardData = {
        apiKey: trelloConnection.apiKey,
        apiToken: trelloConnection.apiToken,
        boardId: val.value
        }
        let selected = []
        if(planItem.trelloOptions && planItem.trelloOptions.selectedTrelloLists){
            selected = planItem.trelloOptions.selectedTrelloLists
        }
        const response = await dispatch( getTrelloBoardLists( boardData ) )

        if(response && response.trelloLists){
            let array = response.trelloLists.map((list)=>{
                if(selected.length > 0){
                    let selectedObj = selected.filter((item) => item.id === list.id)[0]
                    list = {
                        ...list,
                        selected: selectedObj.selected
                    }
                }
                else  {
                    list = {
                        ...list,
                        selected: true
                    }
                }
                return list
            })
            setBoardLists(array)
            handleSelectedTrelloLists(array)
            setLoader(false)
        }
    }

    const handleListItemSelection = (props) => {
        const {id, value} = props
        
        let array =  boardLists.map((list) => {
        if(list.id === id){
            list = {
            ...list,
            selected: value
            }
        }
        return list
        })
        setBoardLists(array)
        handleSelectedTrelloLists(array)
    }

    const handleShowOnlyDeadlineCards = () => {
        setOnlyDeadlineCards(!onlyDeadlineCards)
    }

    const removeTrelloKeys = () => {
        handleKeysRemoved()
        setTrelloConnection(false)
    }

    return (
        <Modal isOpen={ isOpen } size='lg' >
            <ModalHeader toggle={ toggle }>Trellon asetukset</ModalHeader>
            <ModalBody>
                <Col style={{ marginBottom:'1em'}}>
                <Row>
                    <Col>
                    <div className="list-selection-item">
                        <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleShowOnlyDeadlineCards()}>
                            <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>{'Näytä vain kortit, joissa deadline'}</p>
                        </div>
                        <label className="switch switch-in-modal" >
                            <input id="switch-input" type="checkbox" onChange={() => handleShowOnlyDeadlineCards()} checked={onlyDeadlineCards}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {trelloConnection &&
                        <div style={{marginTop:'1em'}}>
                            <button className="small-white-button" onClick={()=> setKeyHandler(!keyHandler)}>Näytä API-avaimet</button>
                        </div>
                    }
                    </Col>
                </Row>
                </Col>
                {keyHandler? 
                <Col>
                    <Row>
                    <Col>
                        <Label>Muodosta yhteys Trelloon:</Label>
                        <div style={{ marginBottom: '1em', marginTop:'1em' }}>
                        <h6 style={ { fontWeight: 'bold' } }>Löydät Trellon API-avaimesi osoitteesta: <a target="_blank" rel="noopener noreferrer" href="https://trello.com/app-key">https://trello.com/app-key</a></h6>
                        </div> 
                        {trelloConnection.apiKey && trelloConnection.apiToken ?
                            <div>
                                <p>{'Avaimet asetettu (mikäli ongelmia taulujen tai listojen haussa => poista nykyiset avaimet ja lisää avaimet uudelleen)'}</p>
                                <button className="small-white-button" style={{marginTop:'1em'}} onClick={() => removeTrelloKeys()}>Poista Trello-avaimet</button>
                            </div>
                            :
                            <div>      
                                <div>
                                    <Label>API-avain</Label>
                                    <Input value={ apiKey } name="key" type="text" onChange={ ( e ) => setApiKey( e.target.value ) }></Input>
                                </div>
                                <div style={{marginTop:'1em'}}>
                                    <Label>API-token</Label>
                                    <Input value={ apiToken } name="id" type="text" onChange={ ( e ) => setApiToken( e.target.value ) }></Input>
                                </div>
                            </div>   
                        }
                    </Col>
                    </Row>
                </Col>
                :
                <Col style={{ minHeight:'300px'}}>
                    <Row >
                    <Col>
                        <Label>Valitse tarkasteltava taulu (Board)</Label>
                        <Select  
                        options={userBoards} 
                        onChange={(val)=> (handleBoardSelectionChanged(val))}
                        value={selectedBoard}
                        isDisabled={false}
                        noOptionsMessage={() => { return "Tauluja ei löytynyt" }}
                        className=""
                        classNamePrefix="select"
                        />
                    </Col>
                    </Row>
                    <Row style={{marginTop:'1em'}}>
                    {loader? 
                        <Col>
                        <MegaLoaderSpinner/>
                        </Col>
                        :
                        <>
                        {boardLists.length > 0 && 
                            <Col>
                            <Label>Valitse näytettävät listat</Label>
                            {boardLists.map((list, key)=>{
                                return(
                                    <div className="list-selection-item" key={key} >
                                    <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListItemSelection({id:list.id, value:!list.selected})}>
                                        <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>{list.name}</p>
                                    </div>
                                    <label className="switch switch-in-modal" >
                                        <input id="switch-input" type="checkbox" onChange={() => handleListItemSelection({id:list.id, value:!list.selected})} checked={list.selected}/>
                                        <span className="slider round"></span>
                                    </label>
                                    </div>
                                )
                            })}
                            </Col>
                        }
                        </>
                    }
                    </Row>
                </Col>
                }
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={ toggle }>Sulje</a>
                {keyHandler?
                <button className={"small-white-button wide-button " + (trelloConnection && trelloConnection.apiKey && trelloConnection.apiToken && 'active-button')} onClick={ saveTrelloKeysToPlan }>Tallenna</button>
                :
                <button className={"small-white-button wide-button " + (trelloConnection && trelloConnection.apiKey && trelloConnection.apiToken && 'active-button')} onClick={() => (saveTrelloOptions(),toggle()) }>Tallenna</button>
                }
            </ModalFooter>
        </Modal>
    )
}

const MegaLoaderSpinner = () => {
  return (
      <Row>
          <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '5rem', height: '5rem', marginTop: '0rem' }} className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
              </div>
          </Col>
      </Row>
  )
}
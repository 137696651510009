import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Container,
    Row,
    Col
} from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'

import * as ActionCreator from '../../../store'
import LatestAnswerRow from './LatestAnswerRow'
import ComparisonContainer from '../../comparisonTool/ComparisonContainer';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar'

class BusinessSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            answerFilter: ''
        }
    }

    componentDidMount() {
        this.props.clearSelected()
        this.props.getBusinesses({})
        this.props.getLatestAnswers({})
        this.props.setBreadcrumbs({to: 'tiedot', label: this.props.businessName});
        this.props.addBreadcrumbs({to: 'raportit', label: 'raportit'})
    }

    selectBusiness = (params) => {
        this.props.addBreadcrumbs({to: 'raportit/yritys', label: params.name, businessId: params.businessId})
        this.props.onBusinessSelect(params)
        this.props.getReportTree({ businessId: params.businessId })
        this.props.history.push('/raportit/yritys')
    }

    selectLatestAnswerReport = async ( obj ) => {
        const { getReport, getReportTree, addReportFilter, filterTypes, activeFilters } = this.props
        
        const params = {
            reports: [
                {
                    reportId: obj.sentSurveyId
                }
            ],
            answerSessionIds: [
                obj.answerSessionId
            ]
        }

        let filterType = JSON.parse(JSON.stringify( filterTypes.answerSession ) )
        filterType.answerSessionIds = [ obj.answerSessionId ]

        addReportFilter( filterType, activeFilters )

        getReport(params)

        if(obj.businessId !== 'anon') {
            await getReportTree({businessId: obj.businessId})
            this.props.history.push('/raportit/yritys/raportti')
        } else {
            this.props.storeBusiness( { name: 'Ei kohdeyritystä' } )
            this.props.history.push('/raportit/kohdistamaton/raportti')
        }
    }

    filterAnswers = filter => {
        this.setState({ answerFilter: filter })
    }

    render() {
        const { businesses, latestAnswers } = this.props

        return (
            <Container fluid>
                <Row>
                    {latestAnswers ?
                        <Col md={{ size: 12 }} xl={{ size: 6 }} >
                            <div className="dashboard-medium-box">
                                <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                                    <p>Viimeisimmät vastaukset</p>
                                    <BoxSearchBar placeholder='Etsi vastauksia' value={ this.state.answerFilter } onChange={ this.filterAnswers } />
                                </div>
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "100%", height: '500px' }}
                                    className="custom-scrollbars"
                                >

                                    {(latestAnswers && latestAnswers.length > 0) ? (
                                        latestAnswers.map((answer, index) => {
                                            if ( answer.businessName.toLowerCase().includes(this.state.answerFilter.toLowerCase()) || answer.code && answer.code.toLowerCase().includes(this.state.answerFilter.toLowerCase()) || answer.surveyName.toLowerCase().includes(this.state.answerFilter.toLowerCase()) ) {
                                                return <LatestAnswerRow key={index} index={index} answerData={answer} onInspectClick={this.selectLatestAnswerReport} />
                                            }
                                        })
                                    ) : (
                                        <div>
                                            <p className="empty-dashboard-row light-text">Kartoituksillanne ei ole vielä vastauksia</p>
                                        </div>
                                    )}
                                </Scrollbars>
                            </div>
                        </Col>
                        :
                        <EmptyBox title="Viimeisimmät vastaukset" md="12" xl="6"/>
                    }
                    {businesses ?
                        <Col md={{ size: 12 }} xl={{ size: 6 }} >
                            <div className="dashboard-medium-box">
                                <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                                    <p>Asiakkaat</p>
                                    <BoxSearchBar placeholder='Etsi yritystä' value={ this.state.filter } onChange={ (val) => this.setState({ filter: val }) } />
                                </div>
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "100%", height: '500px' }}
                                    className="custom-scrollbars" >
                                    
                                    {businesses.length > 0 ? (
                                        businesses.map((business, index) => {
                                            // only show top 4 surveys, for no particular reason
                                            // const startDate = moment(new Date(sentSurvey.startDate)).format('DD.MM.YYYY')
                                            // const endDate = moment(new Date(sentSurvey.endDate)).format('DD.MM.YYYY')
                                            if ((business.businessName.toLowerCase().includes(this.state.filter.toLowerCase())) || (business.businessID.toLowerCase().includes(this.state.filter.toLowerCase()))) {
                                                let rowClass = index % 2 === 0 ? 'even-row' : 'odd-row'

                                                return (
                                                    <div className={'selectable-row ' + rowClass} key={index} style={{paddingLeft: '1rem'}} onClick={() => this.selectBusiness({ businessId: business._id, name: business.businessName })}>
                                                        <Row>
                                                            <Col xs={{ size: 12 }}>
                                                                <Row>
                                                                    <Col xs={{ size: 8 }}>
                                                                        <p className="big-text mb-0">{business.businessName}</p>
                                                                    </Col>
                                                                    <Col xs={{ size: 3 }}>
                                                                        <p className="big-text mb-0">{business.businessID}</p>
                                                                    </Col>
                                                                    {/* <Col xs={{ size: 2 }} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <p className="big-text mb-0">{business.surveyCount}</p>
                                                                    </Col> */}
                                                                    <Col xs={{ size: 1 }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                                                        <i style={ { float: 'right', color: '#ccc', marginRight: '.5em' } } className="fas fa-caret-right"></i>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs={{ size: 12 }}>
                                                                        <p className="light-text">Kartoituksia {business.surveyCount} kpl</p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            }
                                            return null
                                        })
                                    ) : (
                                        <div>
                                            <p className="empty-dashboard-row light-text">Ei yrityksiä</p>
                                        </div>
                                    )}
                                </Scrollbars>
                            </div>
                        </Col>
                        :
                        <EmptyBox title="Asiakkaat" md="12" xl="6" />
                    }

                </Row>
                <Row>
                    <Col xs={{ size: 12 }}>
                        <ComparisonContainer />
                    </Col>
                </Row>
            </Container>
        )
    }
}

const EmptyBox = (props) => {
    return (
        <Col md={{ size: props.md }} xl={{ size: props.xl }} >
            <div className="company-box">
                <div className="box-header bigger-text">
                    <p>{props.title}</p>
                </div>
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height: '500px', marginTop: '0.5rem' }} >
                    <Row>
                        <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </Col>
                    </Row>
                </Scrollbars>

            </div>
        </Col>
    )
}



const mapStateToProps = state => {
    return {
        businesses: state.reports.businesses,
        latestAnswers: state.reports.latestAnswers,
        selectedBusiness: state.reports.selectedBusiness,
        answerSessionReport: state.reports.sentSurveys,
        filterTypes: state.reports.filterTypes,
        activeFilters: state.reports.activeFilters,
        businessName: state.authentication.businessName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBusinesses: (params) => dispatch(ActionCreator.getBusinesses(params)),
        getLatestAnswers: (params) => dispatch(ActionCreator.getLatestAnswers(params)),
        onBusinessSelect: (params) => dispatch(ActionCreator.selectBusinessForReport(params)),
        getReport: (params) => dispatch(ActionCreator.getReportForDashboard(params)),
        clearSelected: () => dispatch(ActionCreator.clearSelected()),
        getReportTree: (params) => dispatch(ActionCreator.getBusinessSurveyTree(params)),
        addReportFilter: (params) => dispatch(ActionCreator.addFilter(params)),
        setBreadcrumbs: (params) => dispatch(ActionCreator.setBreadcrumbs(params)),
        addBreadcrumbs: (params) => dispatch(ActionCreator.addBreadcrumbs(params)),
        storeBusiness: (params) => dispatch(ActionCreator.storeBusiness(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSelect)
import { useState, useRef, useEffect } from "react"
import TextEditor from '../../TextEditor/texteditor';
import { convertFromRaw } from 'draft-js';
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import './openAI.css'
import { Scrollbars } from 'react-custom-scrollbars';
import { NotificationManager } from "react-notifications";
const { Configuration, OpenAIApi } = require("openai");

const INITIAL_MESSAGES = [
  {role: "system", content: "Olet asiantuntija."}, {role:'assistant', content:'Hei! Miten voin auttaa?'}
]

export const OpenAI = ({apiKey}) => {
  const configuration = new Configuration({
    apiKey: apiKey,
  });

  const openai = new OpenAIApi(configuration);
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [AIChatOpen, setAIChatOpen] = useState(false)
  const [history, setHistory] = useState(INITIAL_MESSAGES)
  const [position, setPosition] = useState('left')
  const [calculatedHeight, setCalculatedHeight] = useState('420px')
  const [calculatedMargin, setCalculatedMargin] = useState('100px')
  const scrollBar = useRef();

  const handleSubmit = async (e) => {
    setLoading(true);
    let chatHistory = [...history]
    let messages = [...history]
    let newMessage = prompt
    messages.push({role:'user', content:prompt})
    setHistory([...messages])
   
    setPrompt('')
    try {
      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{role: "user", content: newMessage}, ...chatHistory],
        temperature: 0
      })
      
      let response = completion.data.choices[0].message
      messages.push(response)
      setHistory([...messages])
    } catch (e) {
      let message = e.response.data.error.message
      messages.push({role:'assistant', content:message})
      setHistory([...messages])
    }
    setLoading(false);
    
  };

  useEffect(() => {
    if (scrollBar && scrollBar.current){ 
      scrollBar.current.scrollToBottom() 
    }
  }, [scrollBar, history, AIChatOpen, calculatedHeight]);

  useEffect(()=>{
    if(document.querySelector(".ai-footer") && document.querySelectorAll(".grow-wrap")){
      const growers = document.querySelectorAll(".grow-wrap");
      
      growers.forEach((grower) => {
        const textarea = grower.querySelector("textarea");
        textarea.addEventListener("input", () => {
          grower.dataset.replicatedValue = textarea.value;
        });
      });
      const footer = document.querySelector(".ai-footer")
      let footerHeight = footer.offsetHeight
      let footerOriginal = 34;
      let toBeReducedBy = footerHeight - footerOriginal
      let chatAreaOriginal = 420
      if(toBeReducedBy <= 166 && document.querySelector(".ai-frame-body")){
        const chatArea = document.querySelector(".ai-frame-body")
        setCalculatedHeight(chatAreaOriginal-toBeReducedBy+'px')
        chatArea.setAttribute('height', (chatAreaOriginal-toBeReducedBy+'px'))
        let originalMarginBottom = 100
        setCalculatedMargin(originalMarginBottom + toBeReducedBy +'px')
      }
    }
  },[prompt])
  return (
    <>
      {AIChatOpen &&
        <div className={"ai-frame" + (position === 'left' ? ' ai-frame-left ': ' ai-frame-right ')}>
          <div className="ai-header">
            <div className={"active-button ai-toggler-top" + (position === 'left' ? ' ai-toggler-left ': ' ai-toggler-right ')} title="OpenAI chat" onClick={()=> setAIChatOpen(!AIChatOpen)}>
              <i className="fas fa-comment-dots"></i>
            </div>
            <div className="close-ai-frame" onClick={()=> setAIChatOpen(!AIChatOpen)}>x</div>
            <p>OpenAI chat</p>
          </div>
          {position === 'right' && <div className="move-ai-left" title="Siirrä vasemmalle" onClick={()=> setPosition('left')}><i className="fas fa-chevron-left"></i></div>}
          {position === 'left' &&   <div className="move-ai-right" title="Siirrä oikealle" onClick={()=> setPosition('right')}><i className="fas fa-chevron-right"></i></div>}
          <div className='ai-frame-body' style={{height:calculatedHeight, marginBottom:calculatedMargin}}>
            <Scrollbars
              renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
              renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
              style={{ width: "100%", height:calculatedHeight,marginBottom:calculatedMargin, cursor: 'initial', paddingBottom:'0.5em'}}
              ref={scrollBar}
            >
              {history && history.map((message,key) => {
                  if(message.role !== "system"){
                    return(
                      <div key={"ai-message-"+key} className={'ai-message ' + (message.role === 'assistant'? 'ai-response': 'user-message')}>
                        {message.role === 'assistant'&&<div className= 'ai-response-nibblet'></div>}
                        {message.role === 'assistant' && <div className="ai-robot-image"><i className="fas fa-robot"></i></div>}
                        <p style={{paddingRight:'0.5em'}}>{message.content}</p>
                        {message.role === 'user'&&<div className= 'user-nibblet'></div>}
                      </div>
                    )
                  }
              })} 
              {loading &&
                <div className={'ai-message ai-response'}>
                  <div className= 'ai-response-nibblet'></div>
                  <div className="ai-robot-image"><i className="fas fa-robot"></i></div>
                  <div  style={{width: '2.5em'}}><div className="ai-loader"></div></div>
                </div>
              }
            </Scrollbars>  
           
          </div> 
          <div className="ai-footer grow-wrap" style={{}}> 
            <textarea className='ai-textarea' style={{borderRadius: '10px 0 0 10px'}} rows={1} cols={40} onChange={(e)=> setPrompt(e.target.value)} value={prompt} ></textarea>
            <div className={"ai-send-button " +( prompt.length > 0 ? 'active-button' : 'plain-button')} onClick={()=> handleSubmit()} title="Lähetä" style={{ marginRight:'0.75em', display:'flex', alignItems:'center', justifyContent:'center'}}>
              <i className="fas fa-paper-plane"></i>  
            </div>
          </div>
           
        </div>
      }
      {!AIChatOpen &&
        <>
          <div className={'active-button ai-toggler-bottom ' + (position === 'left' ? ' ai-toggler-bottom-left ': ' ai-toggler-bottom-right ')} title="OpenAI chat" onClick={()=> setAIChatOpen(!AIChatOpen)}>
            <i className="fas fa-comment-dots"></i>
          </div>
        </>
      }
    </>
  )
}
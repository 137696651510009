import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import '../builder/kartoitus.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux';
import Select from 'react-select';
import {copyGroup, getAvailableGroups, copyQuestion, fetchSentSurveysWithId, getTargetSentSurvey, getAllActiveSurveysById, updateSelectedSentSurveysToPresentOriginal, getSurveyLinks} from '../../../../../store/index';
import TextEditor from '../../../../../Utilities/TextEditor/texteditor';
import { convertFromRaw } from 'draft-js';
import InfoModal from './InfoModal';
import ActiveSurveysModal from './updateSurveyModals/ActiveSurveysModal';
import LinkTreeModalHandler from './LinkTreeModal/LinkTreeModalHandler';
import {SurveySelectorContainer} from './SurveySelectorContainer'

class SidePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "",
            groupModal: false,
            selectedData: {},
            newGroup: "",
            newQuestion: "",
            questionModal: false,
            chosenGroup: {},
            selectedSentSurvey: {},
            activeDropdown: '',
            infoModal: false,
            showListModal: false,
            linkTreeOpen: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setState({ chosenGroup: this.props.defaultValue });
        }

        if(this.props.currentCategory._id !== prevProps.currentCategory._id) {
            let payload = {
                surveyId: this.props.currentCategory._id,
                original: this.props.currentCategory.original
            };
            this.props.getSurveyList(payload);
            this.props.getSurveyLinks(payload);
        }
        
        if (prevProps.sentSurveyList !== this.props.sentSurveyList) {
            if(this.props.fromSurveys) {
                if(this.props.selectedSentSurvey) {
                    this.handleSentSurveySelection(this.props.selectedSentSurvey);
                }
            } else {
                let filteredList = this.props.sentSurveyList.filter(x => x.label === "Alkuperäinen")

                if (filteredList.length > 0) {
                    this.setState({ selectedSentSurvey: filteredList[0] });
                }
            }
        }
    }

    componentDidMount() {
        if(Object.keys(this.props.currentCategory).length > 0) {
            let payload = {
                surveyId: this.props.currentCategory._id,
                original: this.props.currentCategory.original
            };
            this.props.getSurveyList(payload);
            this.props.getSurveyLinks(payload);
        }
    }
    onDragEnd = (result) => {
        const { destination, source } = result

        if (!destination) { return }
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        let droppableIdNumber = source.droppableId.replace('droppable-', '')

        let grouplist = this.props.currentSurveyQuestionGroups
        const movedObj = grouplist[droppableIdNumber].questions[source.index]

        grouplist[droppableIdNumber].questions.splice(source.index, 1)
        grouplist[droppableIdNumber].questions.splice(destination.index, 0, movedObj)

        this.props.updateQuestionOrder(grouplist)
    }

    handleArrowClick = (ind, change) => {
        let grouplist = this.props.currentSurveyQuestionGroups
        const movedObj = grouplist[ind]

        grouplist.splice(ind, 1)
        grouplist.splice(ind + change, 0, movedObj)

        this.props.updateQuestionOrder(grouplist)
    }

    toggleGroupModal = () => {
        this.setState(prevState => ({
            groupModal: !prevState.groupModal
        }));
    }

    toggleQuestionModal = () => {
        this.setState(prevState => ({
            questionModal: !prevState.questionModal
        }));
    }

    openGroupModal = (payload) => {
        this.setState({ selectedData: payload, groupModal: true });
    }

    openQuestionModal = (question, group) => {
        let payload = {
            group: group
        };
        this.props.fetchAvailableGroups(payload);
        this.setState({ selectedData: question, questionModal: true });
    }

    handleQuestionCopyName = (rawEditorState) => {
        let plaintText = convertFromRaw(rawEditorState).getPlainText()
        plaintText = plaintText.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        this.setState({ newQuestion: plaintText });
    }

    handleGroupCopyName = (rawEditorState) => {
        let plaintText = convertFromRaw(rawEditorState).getPlainText()
        plaintText = plaintText.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        this.setState({ newGroup: plaintText });
    }

    finishGroupCopy = () => {
        let textPayload = {}
        if (this.state.newGroup === "") {
            textPayload = {
                newGroupName: this.state.selectedData.title + " - kopio"
            }
        } else {
            textPayload = {
                newGroupName: this.state.newGroup
            }
        }

        let payload = {
            ...textPayload,
            group: this.state.selectedData
        }
        this.props.onGroupCopy(payload);
        this.setState(prevState => ({
            groupModal: !prevState.groupModal
        }));

    }

    finishQuestionCopy = () => {
        let textPayload = {

        };

        if (this.state.newQuestion === "") {
            textPayload = {
                text: this.state.selectedData.text + " - kopio"
            }
        } else {
            textPayload = {
                text: this.state.newQuestion
            }
        }

        let payload = {
            group: this.state.chosenGroup,
            question: {
                ...this.state.selectedData,
                ...textPayload
            }
        };
        this.props.onQuestionCopy(payload);
        this.setState(prevState => ({
            questionModal: !prevState.questionModal
        }));
    }

    handleGroupSelection = selection => {
        this.setState({ chosenGroup: selection });
    }

    handleSentSurveySelection = selectedItem => {
        //Find index for draftjs editors to update automatically
        let selectedSurveyIndex = null;
        this.props.sentSurveyList.map((survey, index) => {
            if (survey === selectedItem) {
                selectedSurveyIndex = index;
            }
        })
        this.setState({ selectedSentSurvey: selectedItem });
        let payload = {}
        if (selectedItem.label !== "Alkuperäinen") {
            let filterOriginal = this.props.sentSurveyList.filter(x => x.label === "Alkuperäinen");
            payload = {
                sentSurveyId: selectedItem.value,
                originalId: filterOriginal[0].value,
                activeQuestionGroupId: this.props.currentQuestionGroup.id,
                activeQuestionId: this.props.currentQuestion.id
            };
            this.props.surveySelected(selectedItem.value, selectedSurveyIndex);
            this.props.getSentSurvey(payload);
            this.props.getSurveyLinks(payload);
        } else {
            payload = {
                surveyId: selectedItem.value,
                activeQuestionGroupId: this.props.currentQuestionGroup.id,
                activeQuestionId: this.props.currentQuestion.id
            }
            this.props.surveySelected("", 0);
            this.props.getSentSurvey(payload);
            this.props.getSurveyLinks(payload);
        }

    }

    toggleDropdown = (id) => {
        if (this.state.activeDropdown === id) {
            this.setState({ activeDropdown: false });
        } else {
            this.setState({ activeDropdown: id })
        }
    }

    toggleInfo = () => {
        this.setState(prevState => ({
            infoModal: !prevState.infoModal
        }));
    }

    toggleListModal = (payload = null) => {
        this.setState(prevState => ({
            showListModal: !prevState.showListModal
        }), () => {
            if (payload != null && this.state.showListModal) {
                this.props.fetchActiveSurveys(payload);
            }
        })
    }

    toggleLinkTree = () => {
        this.setState(prevState => ({
            linkTreeOpen: !prevState.linkTreeOpen
        }));
    }

    startUpdatingSelectedSurveys = (selectedSurveys) => {
        let surveyIds = [];

        selectedSurveys.filter(survey => survey.selected).map(x => surveyIds.push({surveyId: x.id}))
       
        let params = {
            categoryId: this.props.currentCategory._id,
            selectedActiveSurveys: surveyIds
        }

        this.setState(prevState => ({
            showListModal: !prevState.showListModal
        }), () => {
            this.props.updateSelected(params);
        })
    }

    render() {
        let size = this.props.currentSurveyQuestionGroups ? this.props.currentSurveyQuestionGroups.length : 0;
        let height = (Object.keys(this.props.linkTree).length > 0 && this.props.linkTree.data.numberOfLinks > 0) ? '11em' : '10em'
        return (
            <div className="kartoitusSidePanelFull">
                <InfoModal info={this.state.infoModal} toggleInfo={this.toggleInfo} />
                {Object.keys(this.props.linkTree).length > 0 && <LinkTreeModalHandler modalOpen={this.state.linkTreeOpen} incomingCategory={this.props.linkTree.data} toggle={this.toggleLinkTree} />}
                <ActiveSurveysModal isOpen={this.state.showListModal} activeSurveys={this.props.activeSentSurveys} toggle={this.toggleListModal} startUpdatingSelectedSurveys={this.startUpdatingSelectedSurveys} />
                <CopyGroupModal groupModal={this.state.groupModal} handleCopyName={this.handleGroupCopyName} toggleGroupModal={this.toggleGroupModal} selectedData={this.state.selectedData} newGroup={this.state.newGroup} copyGroup={this.finishGroupCopy} />
                <CopyQuestionModal questionModal={this.state.questionModal}
                    handleQuestionName={this.handleQuestionCopyName}
                    toggleQuestionModal={this.toggleQuestionModal}
                    selectedData={this.state.selectedData}
                    newQuestion={this.state.newQuestion}
                    copyQuestion={this.finishQuestionCopy}
                    groupOptions={this.props.availableGroups}
                    chosenGroup={this.state.chosenGroup}
                    handleGroupSelection={this.handleGroupSelection} />
                {this.props.sentSurveyList.length > 1 &&
                    <div style={{ paddingTop: '2rem', paddingLeft: 25, paddingRight: '1rem' }}>
                        <div className="infoContainer">
                            <span>Muokkaa aktivoitua kartoitusta</span>
                            <i className="fas fa-info-circle infoIcon" onClick={this.toggleInfo}></i>
                        </div>
                        <Select
                            value={this.state.selectedSentSurvey}
                            onChange={this.handleSentSurveySelection}
                            options={this.props.sentSurveyList}
                            classNamePrefix="select"
                        />
                        {/*this.props.currentCategory.original &&
                            <div style={{ textAlign: 'center', paddingTop: 10 }}>
                                <button className="small-white-button lighter-text" style={{ textAlign: 'center', paddingTop: 10 }} onClick={() => this.toggleListModal({ categoryId: this.props.currentCategory._id })}>Päivitä aktiiviset kartoitukset</button>
                            </div>
                        */}
                    </div>
                }
                <div style={{ height: this.props.sentSurveyList.length > 1 ? height : '17rem', paddingTop: this.props.sentSurveyList.length > 1 ? '1.5rem' : '7rem' }}>
                    <Row>
                        <Col>
                            {(Object.keys(this.props.linkTree).length > 0 && this.props.linkTree.data.numberOfLinks > 0) && 
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <button className="small-white-button lighter-text" style={{textAlign: 'center', paddingTop: 10}} onClick={() => this.toggleLinkTree()}>Kartoitus sisältää linkityksiä</button>
                                </div>
                            }
                            <p className={this.props.view === 'category' ? "selectedSurveySubTitle active" : "selectedSurveySubTitle"} onClick={() => this.props.changeToCategory()}>Perustiedot</p>
                        </Col>
                    </Row>
                    {this.props.type !== 'feedback' &&
                        <Row>
                            {(this.props.currentCategory._id && this.props.currentCategory.original) &&
                                <Col style={{ textAlign: 'center', paddingTop: 10 }}>
                                    <AddItem text={"Uusi kysymysryhmä"} add={this.props.addGroup} target={-1} />
                                </Col>
                            }
                        </Row>
                    }
                </div>
                {this.props.type !== 'feedback' &&
                    <>
                        <div style={{ height: this.props.sentSurveyList.length > 1 ? 'calc(93% - 22rem)' : 'calc(93% - 18rem)', overflowY: this.state.infoModal ? 'hidden' : 'auto' }} className="kartoitusSidePanel">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                {this.props.currentSurveyQuestionGroups !== undefined ? (
                                    <>
                                        {this.props.currentSurveyQuestionGroups.map((group, x) =>
                                            <div key={group.id} className="sidePanelGroup">
                                                <Row className={this.props.currentQuestionGroup.id === group.id ? "active subGroupTitleSelector" : "subGroupTitleSelector"} onClick={() => this.props.setGroupData(x)}>

                                                    <Col className="sidePanelTitle" xs={{ size: 10 }}>
                                                        {group.title}
                                                    </Col>

                                                    <Col xs={{ size: 2 }}>
                                                        {this.props.currentCategory.original &&
                                                            <Dropdown isOpen={this.state.activeDropdown === group.id + x} onClick={(event) => event.stopPropagation()} toggle={() => this.toggleDropdown(group.id + x)}>
                                                                <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0em', fontSize: '1.25rem', cursor: 'pointer' }}>
                                                                    <i className="fas fa-bars" title="Valikko"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    {x !== 0 &&
                                                                        <DropdownItem>
                                                                            <p onClick={() => this.handleArrowClick(x, -1)} >Siirrä ryhmä ylöspäin</p>
                                                                        </DropdownItem>
                                                                    }
                                                                    {x !== size - 1 &&
                                                                        <DropdownItem>
                                                                            <p onClick={() => this.handleArrowClick(x, +1)}>Siirrä ryhmä alaspäin</p>
                                                                        </DropdownItem>
                                                                    }
                                                                    {this.props.currentCategory.original &&
                                                                        <DropdownItem>
                                                                            <p onClick={() => this.openGroupModal(group)}>Kopioi ryhmä</p>
                                                                        </DropdownItem>
                                                                    }
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        }
                                                    </Col>
                                                </Row>


                                                <Droppable droppableId={x.toString()} key={group.id} index={x} type={`question-${x}`}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                        >

                                                            {typeof group.questions !== 'undefined' && group.questions.map((question, y) =>
                                                                <Draggable index={y} draggableId={question.id} type="question" key={question.id}>
                                                                    {(provided, snapshot) => {
                                                                        return (
                                                                            <div
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={provided.innerRef}
                                                                            // onClick={() => setActiveQuestion(props.categoryGroup, props.target)}
                                                                            >
                                                                                <Row className={this.props.currentQuestion.id === question.id ? "active subTitleSelector" : "subTitleSelector"} onClick={() => this.props.setQuestionData(group, question)} key={question.id}>

                                                                                    <Col className="sidePanelSubTitle" xs={{ size: 10 }}>
                                                                                        {question.isComment ? "Välikommentti" : question.text}
                                                                                    </Col>

                                                                                    <Col xs={{ size: 2 }}>
                                                                                        {this.props.currentCategory.original &&
                                                                                            <Dropdown isOpen={this.state.activeDropdown === question.id + y} onClick={(event) => event.stopPropagation()} toggle={() => this.toggleDropdown(question.id + y)}>
                                                                                                <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0em', fontSize: '1rem', cursor: 'pointer' }}>
                                                                                                    <i className="fas fa-bars" title="Valikko"></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem>
                                                                                                        <p onClick={() => this.openQuestionModal(question, group)}>Kopioi kysymys</p>
                                                                                                    </DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </Dropdown>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        )
                                                                    }}
                                                                </Draggable>


                                                            )}

                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>

                                                {this.props.currentCategory.original &&
                                                    <Col style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <AddQuestion text="Uusi kysymys" add={this.props.addQuestion} group={group} question={{}} />
                                                    </Col>
                                                }

                                            </div>
                                        )}
                                        
                                    </>
                                    ) : (
                                        <Row className="sidePanelGroup">
                                            <Col>
                                                <div style={{display: 'flex', flexDirection: 'column', padding: '0 25px'}}>
                                                    <p style={{paddingLeft: 0}}>{this.props.title ? "Seuraavaksi lisää ensimmäinen kysymysryhmä" : "Aloita antamalla kartoituksen nimi tai kopioimalla olemassa oleva kartoitus pohjaksi"}</p>
                                                    <SurveySelectorContainer />
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                            </DragDropContext>
                        </div>
                    { size > 0 && <Row>
                        <Col>
                            <p className={ this.props.view === 'feedbacks' ? "selectedSurveySubTitle active" : "selectedSurveySubTitle" } onClick={ () => this.props.changeToFeedbacksView() }>Loppupalaute</p>
                        </Col>
                    </Row>}
                    </>
                }
            </div>

        )
    }
}

const AddItem = props => {
    return (
        <button className="small-white-button lighter-text" id="add-group" onClick={() => props.add(props.target)}>{props.text}</button>
    )
}

const AddQuestion = props => {
    return (
        <button className="small-white-button lighter-text" onClick={() => props.add(props.group, props.question)}>{props.text}</button>
    )
}

const CopyGroupModal = props => {
    return (
        <Modal isOpen={props.groupModal} toggle={props.toggleGroupModal} className={props.className} centered>
            <ModalHeader>{props.selectedData && <p>Kopioidaanko ryhmä? <b>{props.selectedData.title}</b></p>}</ModalHeader>
            <ModalBody>
                <h6>Anna ryhmälle nimi:</h6>
                <TextEditor
                    editorId={`${props.selectedData.id}-copyGroupTitle`}
                    content={props.selectedData.title + " - kopio"}
                    onTextChange={props.handleCopyName}
                    plaintextEditor={true}
                />
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={props.toggleGroupModal}>Peruuta</a>
                <button className="small-white-button wide-button swb-hover" color="primary" onClick={props.copyGroup} >Kopioi</button>{' '}
            </ModalFooter>
        </Modal>
    )
}

const CopyQuestionModal = props => {
    return (
        <Modal isOpen={props.questionModal} toggle={props.toggleQuestionModal} className={props.className} centered>
            <ModalHeader>{props.selectedData && <p>Kopioidaanko kysymys?  <b>{props.selectedData.text}</b></p>}</ModalHeader>
            <ModalBody>
                <h6>Anna kopiolle nimi:</h6>
                <TextEditor
                    editorId={`${props.selectedData.id}-copyQuestionTitle`}
                    content={props.selectedData.text + " - kopio"}
                    onTextChange={props.handleQuestionName}
                    plaintextEditor={true}
                />
                {/*<input type="text" value={props.newQuestion} placeholder={props.selectedData.text + " - kopio"} onChange={props.handleQuestionName} style={{ width: '100%' }}></input>*/}
                {props.groupOptions.length > 1 &&
                    <div style={{ paddingTop: 10 }}>
                        <h6>Valitse kysymysryhmä, johon kopioidaan</h6>
                        <Select value={props.chosenGroup}
                            onChange={props.handleGroupSelection}
                            options={props.groupOptions}
                            placeholder="Etsi ryhmä"
                            isDisabled={false}
                            noOptionsMessage={() => { return "Ryhmiä ei ole valittavissa" }}
                            classNamePrefix="select"
                        />
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={props.toggleQuestionModal} >Peruuta</a>
                <button className="small-white-button wide-button swb-hover" color="primary" onClick={props.copyQuestion} >Kopioi</button>{' '}
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        availableGroups: state.group.availableGroups,
        defaultValue: state.group.defaultValue,
        sentSurveyList: state.kartoitus.sentSurveyList,
        activeSentSurveys: state.kartoitus.activeSentSurveys,
        linkTree: state.kartoitus.surveyLinkTree,
        selectedSentSurvey: state.kartoitus.selectedSentSurvey,
        fromSurveys: state.kartoitus.fromSurveys
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGroupCopy: (params) => dispatch(copyGroup(params)),
        onQuestionCopy: (params) => dispatch(copyQuestion(params)),
        fetchAvailableGroups: (params) => dispatch(getAvailableGroups(params)),
        getSurveyList: (params) => dispatch(fetchSentSurveysWithId(params)),
        getSentSurvey: (params) => dispatch(getTargetSentSurvey(params)),
        fetchActiveSurveys: (params) => dispatch(getAllActiveSurveysById(params)),
        updateSelected: (params) => dispatch(updateSelectedSentSurveysToPresentOriginal(params)),
        getSurveyLinks: (params) => dispatch(getSurveyLinks(params))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);

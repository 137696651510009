import * as ActionTypes from '../../../Components/planBuilder/Actions/ActionTypes';

const initialState = {
    startBuilding: false,
    plan: {},
    risksById: {},
    tasksById: {},
    planList: [],
    automationList: null,
    automationResults: [],
    templateList: [],
    planId: null,
    taskModifyStatus: false,
    taskModifyMessage: "",
    templates: [],
    created: false,
    editPlan: false,
    searchResults: null
};

const removeItemIdFromRisks = (risks, deletedId) => {
    delete risks[deletedId]
    return risks;
}

const removeItemIdFromTasks = (tasks, deletedId) => {
    delete tasks[deletedId]
    return tasks;
}

const removePublishedDate = plan => {
    delete plan.publishedDate
    return plan;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.START_NEW_PLAN:
            return {
                ...state,
                startBuilding: false,
                plan: {},
                risksById: {},
                tasksById: {},
                planId: null
            }
        case ActionTypes.SET_LIST_OF_PLANS:
            return {
                ...state,
                planList: [...action.payload],
                hasLoaded: true
            }
        case ActionTypes.SET_TEMPLATES:
            return {
                ...state,
                templateList: [...action.payload],
                hasLoaded: true
            }
        case ActionTypes.SET_SHOW_TABLE_OF_CONTENTS:
            return{
                ...state,
                plan:{
                    ...plan,
                    showTableOfContents: action.payload
                }
            }
        case ActionTypes.SET_REFRESH_IN_PROGRESS:
            return {
                ...state,
                refreshInProgress: action.payload
            }
        case ActionTypes.RESET_PLAN_SELECTION:
            return {
                ...state,
                startBuilding: false,
                editPlan: false,
                plan: {},
                risksById: {},
                tasksById: {},
                planId: null
            }
        case ActionTypes.SET_ONLY_LIST_OF_PLANS:
            return {
                ...state,
                planList: [...action.payload]
            }
        case ActionTypes.SET_LIST_OF_AUTOMATIONRESULTS:
            return {
                ...state,
                automationResults: [...action.payload],
            }
        case ActionTypes.ADD_TO_AUTOMATIONRESULTS:
            return {
                ...state,
                automationResults: [...this.state.automationResults,...action.payload],
            }
        case ActionTypes.SET_LIST_OF_AUTOMATIONS:
            return {
                ...state,
                automationList: [...action.payload],
            }
        case ActionTypes.SET_LIST_OF_TEMPLATES:
            return {
                ...state,
                templateList: [...action.payload],
            }
        case ActionTypes.SET_PLAN_SETTINGS:
            let plan = state.plan
            plan.settings = action.payload
            return {
                ...state,
                ...plan
            }
        case ActionTypes.SET_PLAN_LAYOUT:{
            let plan = state.plan
            plan.layoutOptions = action.payload
            return {
                ...state,
                plan:{
                    ...plan,
                    layoutOptions: {
                        ... action.payload
                    }
                }
            }
        }
        case ActionTypes.SET_PLANLIST_SETTINGS: {
            let list = state.automationList
            let id = action.payload.id
            delete action.payload.id
            let edited = list.map((item,index) => {
                if(item._id === id){
                    
                    item = {
                        ...item,
                        settings:action.payload
                    }
                }
                return item
            })            
            return {
                ...state,
                automationList: edited
            }
        }

        case ActionTypes.MODIFY_PLAN_SENTSURVEY: {
            let list = state.planList
            let item = list.filter(item => item._id === action.payload._id)
            item[0].automationSurvey = action.payload.automationSurvey
            item[0].automationSurveyName = action.payload.automationSurveyName
            item[0].answers = action.payload.answers
            
            return {
                ...state,
                planList: list
            }

        }
        case ActionTypes.ADD_COPIED_PLAN:
            return {
                ...state,
                planList: [
                    action.payload,
                    ...state.planList
                ]
            }
        case ActionTypes.ADD_COPIED_TEMPLATE:
            return {
                ...state,
                planList: [
                    ...state.planList,
                    action.payload
                ]
            }
        case ActionTypes.DELETE_PLAN:
            return {
                ...state,
                planList: state.planList.filter(plan => plan._id !== action.payload),
                automationResults: state.automationResults.filter(plan => plan._id !== action.payload),
                automationList: state.automationList.filter(plan => plan._id !== action.payload),
                templateList: state.templateList.filter(plan => plan._id !== action.payload),
            }
        case ActionTypes.ADD_ITEM_ID:
            return {
                ...state,
                risksById: {
                    ...state.risksById,
                    ...action.payload.risksById
                },
                tasksById: {
                    ...state.tasksById,
                    ...action.payload.tasksById
                }
            }
        case ActionTypes.UPDATE_PLAN_DATA:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    ...action.payload
                }
            }
        case ActionTypes.SET_PLAN_TITLE:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    planTitle: action.payload
                }
            }
        case ActionTypes.START_EDITING_PLAN:
            return {
                ...state,
                plan: action.payload.plan,
                risksById: action.payload.risksById,
                tasksById: action.payload.tasksById,
                startBuilding: true,
                editPlan: action.payload.edit?action.payload.edit: action.payload.created
            }
        case ActionTypes.SET_PLAN_ITEMS:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    planItems: [...action.payload]
                },
                created: true
            }
        case ActionTypes.PLAN_PRIVACY:
            return{
                ...state,
                plan:{
                    ...state.plan,
                    isPrivateDocument: action.payload.isPrivateDocument,
                    visibleTo: action.payload.visibleTo
                }
            }
        case ActionTypes.PLAN_STATUS:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    status: action.payload.status,
                    publishedDate: action.payload.publishedDate,
                    publishedBy: action.payload.publishedBy,
                    passwordOn: action.payload.passwordOn
                }
            }
        case ActionTypes.REMOVE_DELETED_TASK:
            return {
                ...state,
                tasksById: {
                    ...state.tasksById,
                    [action.payload.planItemId]: [
                        ...state.tasksById[action.payload.planItemId].filter(task => task._id !== action.payload.taskId)
                    ]
                }
            }
        case ActionTypes.REMOVE_DELETED_RISK:
            return {
                ...state,
                risksById: {
                    ...state.risksById,
                    [action.payload.targetId]: [
                        ...state.risksById[action.payload.targetId].filter(risk => risk._id !== action.payload.riskId)
                    ]
                }
            }
        case ActionTypes.CHANGE_EDIT_MODE:
            return {
                ...state,
                editPlan: !state.editPlan
            }
        case ActionTypes.REORDER_PLAN_ITEMS:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    planItems: [
                        ...action.payload
                    ]
                }
            }
        case ActionTypes.HANDLE_PASSWORD_STATUS:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    passwordOn: action.payload
                }
            }
        case ActionTypes.START_FETCH_REQUEST:
            return {
                ...state,
                taskModifyStatus: true,
                taskModifyMessage: ""
            }
        case ActionTypes.FETCH_REQUEST_FINISHED:
            return {
                ...state,
                taskModifyStatus: false,
                taskModifyMessage: action.payload
            }
        case ActionTypes.SET_PLAN_ITEM:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    planItems: state.plan.planItems.map(planItem =>
                        planItem.id === action.payload.id ? {
                            ...planItem,
                            ...action.payload
                        } : planItem
                    )
                }
            }
        case ActionTypes.ADD_TASK_TO_ITEM:
            return {
                ...state,
                tasksById: {
                    ...state.tasksById,
                    [action.payload.planItemId]: [
                        ...state.tasksById[action.payload.planItemId],
                        action.payload
                    ]
                }
            }
        case ActionTypes.ADD_RISK_TO_ITEM:
            return {
                ...state,
                risksById: {
                    ...state.risksById,
                    [action.payload.targetId]: [
                        ...state.risksById[action.payload.targetId],
                        action.payload
                    ]
                }
            }
        case ActionTypes.REMOVE_DELETED_ITEM:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    planItems: state.plan.planItems.filter(item => item.id !== action.payload)
                },
                risksById: {
                    ...removeItemIdFromRisks(state.risksById, action.payload)
                },
                tasksById: {
                    ...removeItemIdFromTasks(state.tasksById, action.payload)
                }
            }
        case ActionTypes.SET_ITEM_RISK:
            return {
                ...state,
                risksById: {
                    ...state.risksById,
                    [action.payload.targetId]: state.risksById[action.payload.targetId].map(riskItem =>
                        riskItem._id === action.payload._id ?
                            {
                                ...action.payload
                            } : riskItem

                    )
                }
            }
        case ActionTypes.SET_ITEM_TASK:
            return {
                ...state,
                tasksById: {
                    ...state.tasksById,
                    [action.payload.planItemId]: state.tasksById[action.payload.planItemId].map(taskItem =>
                        taskItem._id === action.payload._id ?
                            {
                                ...action.payload
                            } : taskItem

                    )
                }
            }
        case ActionTypes.ADD_TEMPLATE_ITEM:
            return {
                ...state,
                templates: [
                    ...state.templates,
                    action.payload
                ]
            }
        case ActionTypes.TOGGLE_VISIBILITY:
            return {
                ...state,
                plan: {
                    ...state.plan,
                    planItems: state.plan.planItems.map(planItem =>
                        planItem.id === action.payload.id ? {
                            ...planItem,
                            ...action.payload
                        } : planItem
                    )
                }
            }
        case ActionTypes.SEARCH_PLAN:
            return {
                ...state,
                searchResults: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
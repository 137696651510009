import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Label, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';  
import { executeRequest } from '../requestHandler/dataHandler';
import { startEditingPlan } from '../../Components/planBuilder/Actions/ActionCreator';
import { Scrollbars } from 'react-custom-scrollbars';
import './favourites.css';
import { FavouriteStar } from './FavouriteStar';
import BoxSearchBar from '../BoxSearchBar/BoxSearchBar';
import { SentSurveyOptions } from '../../Components/Dashboard/subComponents/SentSurveyOptions';
import { createEnumDeclaration } from 'typescript';

export const Favourites = (props) => {
    const { history } = props
    const [favouritesModal, setFavouritesModal] = useState(false)
    const favourites = useSelector(state => state.authentication.favourites)
    const [groupedFavourites, setGroupedFavourites] = useState([])
    const [search, setSearch] = useState("")
    const [surveyOptionsModal, setSurveyOptionsModalOpen] = useState(false)
    const [selectedSurvey, setSelectedSurvey] = useState(null)
    const dispatch = useDispatch();

    const goToSelected = (selected) => {
        if(selected.itemType === "project"){
            history.push({
                pathname:'/projektit/hallitse',
                state: {
                    projectId:selected.id
                }
            })
        }
        if(selected.itemType === "plan"){
            dispatch(startEditingPlan(selected.id))
            history.push('/lomakkeet/luonti')
        }
        if(selected.itemType === "sentSurvey"){
           getSelectedSurvey(selected.id)
        }
    }

    const listFilter = (item) => {
        const {title, targetBusiness} = item

        if(title && title.toLowerCase().includes(search.toLowerCase()))return true

        if(targetBusiness && targetBusiness.toLowerCase().includes(search.toLowerCase()))return true

        return false
    }

    const groupArrayOfObjects = (list, key) => {
        return list.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    }

    useEffect(() => {
        if(favourites.length > 0){
            let array = groupArrayOfObjects(favourites, "targetBusiness");
            setGroupedFavourites({...array})
        }
    },[favourites])

    const toggleOptionsModal = (value) => {  
        setSurveyOptionsModalOpen(value)
        setSelectedSurvey(null)
    }

    const getSelectedSurvey = async(sentSurveyId) => {
        let payload = {
            sentSurveyId: sentSurveyId
        }

        const response = await executeRequest('favourites/getSentSurvey',payload)
        if(response._id){
            setSelectedSurvey(response)
            setSurveyOptionsModalOpen(true)
        }
    }

    return(
        
            <>
               {selectedSurvey &&
                    <SentSurveyOptions 
                        isOpen={surveyOptionsModal}
                        closeModal={toggleOptionsModal}
                        survey={selectedSurvey}
                        history={history}
                    />
                }
                <div className="dashboard-button" style={{ width: '2.8em' }}>
                    <i id="favourites-tourId" style={{ fontSize: '1.5rem' }} className="fas fa-star favourite-star favourite-selected" title="Suosikit" onClick={() => setFavouritesModal(true)}></i> 
                </div>
                <Modal size="lg" isOpen={favouritesModal} toggle={() => setFavouritesModal(false)}>
                    <ModalHeader toggle={() => setFavouritesModal(false)}>Suosikit</ModalHeader>
                        <ModalBody>
                            <>
                                <div className="favourites-search-bar" ><Input type="search" style={{ marginLeft:'0'}}className="search-bar-open form-control" placeholder='Hae suosikeista' value={ search } onChange={(e) => setSearch(e.target.value)}/></div>
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "100%", height: '500px' }}
                                    className="custom-scrollbars"
                                >    
                                    {groupedFavourites  && Object.keys(groupedFavourites).length > 0 && Object.keys(groupedFavourites).map((group, i)=>{
                                        return(
                                            <div key={i} style={i !== 0? { marginTop: '1em' } : {}}>
                                                {groupedFavourites[group].filter(favourite => listFilter(favourite)).length > 0 && <div style={{ fontSize: '1.2rem', fontWeight: 'bold', borderBottom: '1px solid #6ba53f', marginBottom: '0.5em' }}>{Object.keys(groupedFavourites)[i]}</div>}
                                            
                                                    {groupedFavourites[group].filter(favourite => listFilter(favourite)).map((starred, key)=>{
                                                        let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row'
                                                        return( 
                                                            <div key={key} style={{ marginLeft: '1em',  paddingTop: 0, paddingBottom: 0, }} className={oddEvenClass + " list-row "} >
                                                                <Row style={{ padding: '0.25em 0em 0.5em 0.25em'}} onClick={() => goToSelected(starred)}>
                                                                    <Col xs="1" >
                                                                        <div className={ "dot " + (starred.itemType === "plan" && " form-color ") + (starred.itemType === "project" && " project-color ") + (starred.itemType === "sentSurvey" && " survey-color")} style={{ fontSize: '0.8rem', display: 'flex', alignItems: 'center', marginTop: '0.7em' }}></div>
                                                                    </Col>
                                                                    <Col xs="4" style={{ marginTop: '0.5em' }}>
                                                                        <p className="name-overflow-handler-long"><b>{starred.title}</b></p>
                                                                    </Col>
                                                                    <Col xs="4" style={{ marginTop: '0.5em' }}>
                                                                        <p>{starred.targetBusiness? starred.targetBusiness : " "}</p>
                                                                    </Col>
                                                                    <Col xs="2" style={starred.itemType === "sentSurvey" ? { marginTop : '0.25em' } : { marginTop: '0.5em' }}>
                                                                        {starred.itemType === "plan" &&  <p>Lomake</p>}
                                                                        {starred.itemType === "project" &&  <p>Projekti</p>}
                                                                        {starred.itemType === "sentSurvey" &&  <p style={{ fontSize:'0.8rem'}}>Aktivoitu kartoitus</p>}
                                                                    </Col>
                                                                    <Col xs="1" style={{ marginTop: '0.5em' }}>
                                                                        <div >
                                                                            <FavouriteStar itemId={starred.id} itemType={starred.itemType} title={starred.title} targetBusiness={starred.targetBusiness}/>
                                                                        </div>
                                                                    </Col>
                                                                </Row> 
                                                            </div>
                                                        )
                                                    })}
                                            
                                            </div>
                                        )
                                    })}
                                </Scrollbars>
                            </>
                        </ModalBody>
                        <ModalFooter>
                            <a onClick={() => setFavouritesModal(false)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Sulje</a>
                        </ModalFooter>
                </Modal>
            </>
     
    )
}
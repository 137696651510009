import React from 'react';

const SelectableIcon = props => {
    return (
        <div className={props.selected ? "iconArea selected" : "iconArea"} onClick={() => props.clickHandler(props.index)}>
            {typeof props.text !== 'undefined' ? (
                <p className="reportIconsText">{props.text}</p>
            ) : (
                <i className="material-icons reportIcons">{props.icon}</i>
            )}
        </div>
    )
}

export default SelectableIcon;
import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import './modals.css';

const textFunction = length => {
    if(length >= 2) {
        return (
            <p>Oletko aivan varma kysymysvaihtoehdon poistosta? <b>Kysymysvaihtoehdon poisto on pysyvä.</b></p>
        )
    } else {
        return (
            <p>Olet poistamassa viimeistä vaihtoehtoa, kysymyksellä täytyy olla ainakin yksi kysymysvaihtoehto.</p>
        )
    }
}
const QuestionOptionDeletionModal = props => {
    return (
        <div>
            <Modal isOpen={props.modal} toggle={props.toggler} centered={true}>
                <ModalHeader toggle={props.toggler}>Kysymysvaihtoehdon poisto</ModalHeader>
                <ModalBody>
                    {textFunction(props.length)}
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={props.toggler}>Peruuta</a>{' '}
                    {props.length >= 2 && <button className="small-white-button wide-button swb-hover" style={{ backgroundColor: '#ed5652', color: '#fff'}} onClick={props.deleteOption}>Poista</button>}
                </ModalFooter>
            </Modal>
      </div>
    )
}

export default QuestionOptionDeletionModal;
import React, { useState } from 'react'
import { Nav, NavbarBrand, TabContent, TabPane } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import MainContent from './kartoitus/MainContent';
import ReportContent from './raportit/ReportContent'
import PlannerPanels from './planner/PlannerPanels';
import { motion } from 'framer-motion'
import './sidebar.css'
import { Scrollbars } from 'react-custom-scrollbars'
import { EnterpriseSelection } from './enterpriseSelection/EnterpriseSelection';
import { useSelector } from 'react-redux';

const SideNav = (props) => {
    const navItemVariants = {
        active: {
            backgroundColor: 'rgba(97,97,97,1)',
            // transition: { type: 'spring', duration: .1 }
        },
        notActive: {
            backgroundColor: '#fff',
            transition: { duration: .2 }
        }
    }

    const tabContentVariants = {
        active: {
            display: 'block',
            opacity: 1,
            transition: { duration: .3 }
        },
        notActive: {
            transitionEnd: { display: 'none' },
            opacity: 0,
            transition: { duration: .1 }
        }
    }

    const isMobileNav = props => {
        return typeof props.mobileNav !== 'undefined' && props.mobileNav
    }

    const [enterpriseSelection, setEnterpriseSelection] = useState()
    const { modulesInUse, enterpriseList } = props

    const allowTools = useSelector(state=> state.authentication.allowTools)

    const trial = useSelector(state => state.authentication.trial?state.authentication.trial:false)

    return (
        <div className="sidebar" style={isMobileNav(props) ? { paddingTop: '2em' } : {}}>
            {enterpriseList && 
                <EnterpriseSelection
                    modalOpen={enterpriseSelection}
                    toggle={setEnterpriseSelection}
                    enterpriseList={enterpriseList}
                />
            }
            <Nav vertical className="vertical-nav" style={{ overflow: 'hidden' }}>

                <NavbarBrand style={enterpriseList? { cursor: 'pointer' } : { pointerEvents: 'none' }} onClick={()=> setEnterpriseSelection(true)}>{Object.keys(props.logoStyle).length > 0 ? (
                    <div className="logo-container-nav-bar" style={props.logoStyle}>
                    </div>
                ) :
                    (
                        <img src="/images/qibbie_logo.png" className="nav-logo" alt="Qibbie" />
                    )
                }
                {trial && <div className='logo-trial-watermark'>{"Trial"}</div>}
                </NavbarBrand>

                <motion.div id="yhteenveto" onClick={() => props.onNavClick('yhteenveto')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='active' animate={props.location.pathname.indexOf('yhteenveto') !== -1 ? 'active' : 'notActive'} variants={navItemVariants} >
                    <NavLink to="/yhteenveto" className="nav-link" activeClassName='active-nav'>
                        <i title="Yhteenveto" className="fas fa-chart-line nav-icon"></i>
                        <small>Yhteenveto</small>
                    </NavLink>
                </motion.div>

                {modulesInUse.planManagement &&
                    <motion.div id="lomakkeet" onClick={() => props.onNavClick('lomakkeet')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={props.location.pathname.indexOf('lomakkeet') !== -1 ? 'active' : 'notActive'} variants={navItemVariants} >
                        {((props.activeTab.includes('/lomakkeet')) && !props.panelOpen) &&
                            <div className="togglePanelOpenDiv">
                                <i className="fas fa-chevron-right openIcon" onClick={props.togglePanel}></i>
                            </div>
                        }
                        <NavLink to="/lomakkeet" className="nav-link" activeClassName='active-nav'>
                            <i title="Lomakkeet" className="fas fa-clipboard-list nav-icon"></i>
                            <small>Lomakkeet</small>
                        </NavLink>
                    </motion.div>
                }

                {modulesInUse.surveyManagement && <>
                    <motion.div id="kartoitukset" onClick={() => props.onNavClick('kartoitukset')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={props.location.pathname.indexOf('kartoitukset') !== -1 ? 'active' : 'notActive'} variants={navItemVariants} >
                        {(props.location.pathname.indexOf('kartoitukset') !== -1 && !props.panelOpen) &&
                            <div className="togglePanelOpenDiv">
                                <i className="fas fa-chevron-right openIcon" onClick={props.togglePanel}></i>
                            </div>
                        }
                        <NavLink to="/kartoitukset" className="nav-link" activeClassName='active-nav'>
                            <i title="Kartoitukset" className="fas fa-file-alt nav-icon"></i>
                            <small>Kartoitukset</small>
                        </NavLink>
                    </motion.div>

                    {/* <motion.div id="raportit" onClick={() => props.onNavClick('raportit')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={props.location.pathname.indexOf('raportit') !== -1 ? 'active' : 'notActive'} variants={navItemVariants} >
                        {((props.activeTab === '/raportit/yritys' || props.activeTab === '/raportit/yritys/raportti') && !props.panelOpen) &&
                            <div className="togglePanelOpenDiv">
                                <i className="fas fa-chevron-right openIcon" onClick={props.togglePanel}></i>
                            </div>
                        }
                        <NavLink to="/raportit" className="nav-link" activeClassName='active-nav'>
                            <i title="Raportit" className="fas fa-chart-pie nav-icon"></i>
                            <small>Raportit</small>
                        </NavLink>
                    </motion.div> */}
                </>
                }

                {modulesInUse.embedManagement &&
                    <motion.div id="upotus" onClick={() => props.onNavClick('upotus')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={props.location.pathname.indexOf('upotus') !== -1 ? 'active' : 'notActive'} variants={navItemVariants} >
                        <NavLink to="/upotus" className="nav-link" activeClassName='active-nav'>
                            <i title="Upotus" className="far fa-window-maximize nav-icon"></i>
                            <small>Upotus</small>
                        </NavLink>
                    </motion.div>
                }

                <motion.div id="yhteistyo" onClick={() => props.onNavClick('yhteistyo')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={props.location.pathname.indexOf('yhteistyo') !== -1 ? 'active' : 'notActive'} variants={navItemVariants} >
                    <NavLink to="/yhteistyo" className="nav-link" activeClassName='active-nav'>
                        <i title="Asiakkaat" className="far fa-address-card nav-icon"></i>
                        <small>Asiakkaat</small>
                    </NavLink>
                </motion.div>
                {modulesInUse.projectManagement &&
                    <motion.div id="projektit" onClick={() => props.onNavClick('projektit')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={props.location.pathname.indexOf('projektit') !== -1 ? 'active' : 'notActive'} variants={navItemVariants}>
                        <NavLink to="/projektit" className="nav-link" activeClassName='active-nav'>
                            <i title="Projektit" className="fas fa-briefcase nav-icon"></i>
                            <small>Projektit</small>
                        </NavLink>
                    </motion.div>
                }
                {modulesInUse.planManagement &&
                    <motion.div id="tuotteet" onClick={() => props.onNavClick('tuotteet')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={props.location.pathname.indexOf('tuotteet') !== -1 ? 'active' : 'notActive'} variants={navItemVariants}>
                        <NavLink to="/tuotteet" className="nav-link" activeClassName='active-nav'>
                            <i title="Tuotteet" className="fas fa-tags nav-icon"></i>
                            <small>Tuotteet</small>
                        </NavLink>
                    </motion.div>
                }
                {modulesInUse.toolsBeta && allowTools &&
                    <motion.div id="tyokalut" onClick={() => props.onNavClick('tyokalut')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={props.location.pathname.indexOf('tyokalut') !== -1 ? 'active' : 'notActive'} variants={navItemVariants}>
                        <NavLink to="/tyokalut" className="nav-link" activeClassName='active-nav'>
                            <i title="Työkalut" className="fas fa-tools nav-icon"></i>
                            <small>Työkalut</small>
                        </NavLink>
                    </motion.div>
                }
                <motion.div id="tiedot" onClick={() => props.onNavClick('tiedot')} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={props.location.pathname.indexOf('tiedot') !== -1 ? 'active' : 'notActive'} variants={navItemVariants} >
                    <NavLink to="/tiedot" className="nav-link" activeClassName='active-nav'>
                        <i title="Asetukset" className="fas fa-users-cog nav-icon"></i>
                        <small>Asetukset</small>
                    </NavLink>
                </motion.div>

                <motion.div onClick={() => props.onLogOut()} whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} initial='notActive' animate={'notActive'} variants={navItemVariants} >
                    <NavLink to="/" className="nav-link">
                        <i title="Kirjaudu ulos" className="fas fa-sign-out-alt nav-icon"></i>
                        <small>Ulos</small>
                    </NavLink>
                </motion.div>
                <p className="lighter-text" style={{ marginTop: 'auto' }}>Powered by</p>
                <p className="lighter-text" style={{ fontStyle: 'italic' }}>EverBetter Oy</p>
            </Nav>

            <TabContent activeTab={props.activeTab} >
                {modulesInUse.surveyManagement &&
                    <motion.div initial='active' animate={(props.activeTab.includes('/kartoitukset') && props.panelOpen) ? 'active' : 'notActive'} variants={tabContentVariants}>
                        <TabPane tabId="/kartoitukset" className="survey-side-bar">
                            <div className="sidePanelCloseDiv">
                                <i className="fas fa-chevron-left closeIcon" onClick={props.togglePanel}></i>
                            </div>
                            <MainContent type="anon" activetab={props.activeTab} sidePanelOpen={props.panelOpen} />
                        </TabPane>
                    </motion.div>
                }

                {modulesInUse.surveyManagement &&
                    <motion.div initial='notActive' animate={(props.activeTab === '/raportit/yritys' && props.panelOpen) ? 'active' : 'notActive'} variants={tabContentVariants} >
                        <TabPane tabId="/raportit/yritys" className="tabContent">
                            <div className="sidePanelCloseDiv">
                                <i className="fas fa-chevron-left closeIcon" onClick={props.togglePanel}></i>
                            </div>
                            <ReportContent type="anon" sidePanelOpen={props.panelOpen} navigateTo={props.navigateTo} />
                        </TabPane>
                    </motion.div>
                }

                {modulesInUse.planManagement &&
                    <motion.div initial='notActive' animate={(props.activeTab.includes('/lomakkeet') && props.panelOpen) ? 'active' : 'notActive'} variants={tabContentVariants} >
                        <TabPane tabId="/lomakkeet" className="tabContent">
                            <div className="sidePanelCloseDiv">
                                <i id="sidePanelCloseIcon" className="fas fa-chevron-left closeIcon" onClick={props.togglePanel}></i>
                            </div>
                            <PlannerPanels type="anon" sidePanelOpen={props.panelOpen} navigateTo={props.navigateTo} onNewPlan={props.onNewPlan} />
                        </TabPane>
                    </motion.div>
                }

                {modulesInUse.surveyManagement &&
                    <motion.div initial='notActive' animate={(props.activeTab === '/raportit/yritys/raportti' && props.panelOpen) ? 'active' : 'notActive'} variants={tabContentVariants} >
                        <TabPane tabId="/raportit/yritys/raportti" className="tabContent">
                            <div className="sidePanelCloseDiv">
                                <i className="fas fa-chevron-left closeIcon" onClick={props.togglePanel}></i>
                            </div>
                            <ReportContent type="anon" sidePanelOpen={props.panelOpen} />
                        </TabPane>
                    </motion.div>
                }

            </TabContent>

        </div>
    )
}

export default SideNav
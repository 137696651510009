import { NotificationManager } from 'react-notifications';
import {confs} from '../../modules/config';
import {authFailed} from '../../store/actions/Login/ActionCreator';

const baseURL = confs.url

export const executeRequest = async (url, params, dispatch, stringify = true) => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token"),
        ...params
    }

    try {
        const responseJson = await fetch(baseURL + url, {
            method: 'POST',
            body: stringify ? JSON.stringify( payload ) : params
        })
        
        if(checkResponseStatusCode(responseJson, dispatch)) {
            const response = await responseJson.json()
            return response;
        } else {
            const response = await responseJson.json()
            if(response.reason || response.trialEnded){
                return response;
            } else{
                return false;
            }
        }
    } catch(error) {
        return Promise.reject(error);
    }
}

const checkResponseStatusCode = (response, dispatch) => {
    
    switch(response.status) {
        case 401:
        case 403:
            if(dispatch){
                dispatch(authFailed())
            }
            return false; 
        case 404:
        case 500:
            return false;
        default:
            return true;
    }
}
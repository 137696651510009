import React, { useState, useEffect } from 'react';
import { Input, Button, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Form } from 'reactstrap';
import Select from 'react-select';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import { SurveyList } from './SurveyList';
import { DateRangePicker } from 'react-dates';
import { TagHandler } from './TagHandler';
import moment from 'moment'
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { useDispatch } from 'react-redux';
import { ActivateSurveyModal, SurveyModal } from './ActivateSurveyModal';
import { PlanList } from './PlanList';
import { NewPlanModal } from  './NewPlanModal';
import { TabHandler } from './TabHandler';
import { Attachments } from './Attachments';
import { Activities } from './Activities';
import { ContactCard } from '../../../Utilities/horizontal-scroller/HorizontalCards';
import { TemplateList } from './TemplateList';
import { ViewList } from './ViewList';
import { NotificationManager } from 'react-notifications';
import { ProjectDataModal } from '../ProjectDataModal';
import { Scrollbars } from 'react-custom-scrollbars';
import { getProjectsForBusiness } from '../../../store';
import * as ActionCreator from '../../../store/index';
import { TrelloView } from './TrelloView';

const INITIAL_STATE_PROJECT = {
    projectName: "",
    description: null,
    tags: null,
    selectedType: "",
    selectedProviders: [],
    startDate: null,
    targetDate: null,
    targetBusiness: {label:"", value:""},
    selectedSurveys: [],
    plans: [],
    files: [],
    activities:[]
}


export const Project = (props) => { 
    const {modify, setModify} = props
    const {targetBusiness, updateProject, history}  = props
    const [focusedDateInput, setFocusedDateInput] = useState()
    const [showDates, setShowDates] = useState(false)
    const [projectName, setProjectName] = useState("")
    const [clientList, setClientList] = useState([])
    const [toggleModal, setToggleModal] = useState(false)
    const [project, setProject] = useState(INITIAL_STATE_PROJECT)
    const [selectedSurveys, setSelectedSurveys] = useState([])
    const [projectSurveys, setProjectSurveys] = useState(null)
    const [thisId, setThisId] = useState(null)
    const [projectPlans, setProjectPlans] = useState(null)
    const [providers, setProviders] = useState()
    const [planModalOpen, setPlanModalOpen] = useState(false)
    const [planType, setPlanType] = useState()
    const [activeTab, setActiveTab] = useState(props.activeTab? props.activeTab :'plans')
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [selectedData, setSelectedData] = useState(project)
    const {startDate, targetDate} = project
    const [contactModal, setContactModal] = useState(false)
    const [projectViews, setProjectViews] = useState([])
    const [dateChecked, setDateChecked] = useState(false)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modifyProjectModal, setModifyProjectModal] = useState(false)
    const [calculatedProgress, setCalculatedProgress] = useState(null)
    const [phaseProgressVisible, setPhaseProgressVisible] = useState(false)
   
    const dispatch = useDispatch();

    const types = [{"value":"testType1", "label":"testType1"}, {"value":"testType2", "label":"testType2"}]

    const onDatePickerFocusChange = (focusedInput) => {
        setFocusedDateInput(focusedInput)
        if (!focusedInput) {
            setShowDates(false)
        }
    }

    useEffect(()=>{
        const getClients = async() => {
            const response = await executeRequest('sender/get/getCompanies',{}, dispatch)
            if(response && response.businessList){
                setClientList(response.businessList)
            }
        }
        getClients()

        const getProviders = async() =>{
            const response = await executeRequest('project/getProviders',{})
            if(response){
                setProviders(response)
            }
        }
        getProviders()
    },[])

    useEffect(() => {
        if(targetBusiness && targetBusiness.label){
            handleChange({target: {id:"targetBusiness",value:targetBusiness}})
        }  
    },[targetBusiness])


    useEffect(() => {
        if(props.project){
            setProject(prevProject => ({
                ...prevProject,
                ...props.project,
                startDate: props.project.startDate ? moment(props.project.startDate).isValid() && moment(new Date(props.project.startDate)) : null,
                targetDate: props.project.targetDate ? moment(props.project.targetDate).isValid() && moment(new Date(props.project.targetDate)) : null
            }))
            setThisId(props.project._id)
        }
    },[props.project])

    // useEffect(() => {
    //     if(project.projectName  && project.projectName.length > 0){
    //         let noName = document.getElementById('projectName')
    //         noName.setAttribute('style', 'border-color: default;')
    //     }
    // },[project.projectName])

    const setDates = (date) => {
        setProject(prevData => ({
            ...prevData,
            startDate: date.startDate,
            targetDate: date.endDate
        }))
        if (date.endDate !== null && date.startDate !== null) {       
            setShowDates(false)
        }
    }
    const handleChange = event => {
        const { id, value } = event.target
        setProject(prevData => ({
            ...prevData,
            [id]: value
        }))
        if(id === "description"){
            setModify(true)
        }
    }

    useEffect(()=>{
        setProject(prevData => ({
            ...prevData,
            selectedSurveys: [...selectedSurveys]
        }))
    },[selectedSurveys])

    const handleSave = () => {
        if(project){         
            if(!!props.project){
                props.saveProject(project)
            } else {
                props.create(project)
            }
            setModify(false)  
        } 
    }

    const saveSelectedSurveys = () => {      
        setProject(prevData => ({
            ...prevData,
            selectedSurveys: project.selectedSurveys
        }))     
        handleSave()           
        
    }

    useEffect(()=>{  
        if(props.project){
            getProjectSurveys(props.project._id)
            getProjectPlans(props.project._id)
            getCompareViews(props.project._id)
        } 
    },[props.project])

    useEffect(()=>{
        calculateGanttProgress()
        if(!dateChecked){
            checkProjectDates()
        }
       
    },[projectPlans])

    const calculateGanttProgress = () => {
        if(project && project.ganttPlan && projectPlans && projectPlans.plans){
            projectPlans.plans.map((plan)=>{
                if(plan._id === project.ganttPlan.planId){
                    plan.planItems.map((item) => {
                        if(item.id === project.ganttPlan.value){
                            let progress = 0
                            if(item && item.phases){
                                item.phases.map((phase) =>{
                                    progress+=phase.progress
                                })
                                setCalculatedProgress({progress:progress>0?(progress/item.phases.length): 0, phases:item.phases })
                            }
                        }
                    })
                }
            })
        }
    }

    const checkProjectDates = () => {
        if(project && project.ganttPlan && projectPlans && projectPlans.plans){
            projectPlans.plans.map((plan)=>{
                if(plan._id === project.ganttPlan.planId){
                    plan.planItems.map((item) => {
                        if(item.id === project.ganttPlan.value){
                            if(item && item.phases){
                                let firstDate = ""
                                let lastDate = ""
                                item.phases.map((phase, index) =>{
                                    let start = moment(phase.start).toDate()
                                    let end = moment(phase.end).toDate()
                                    if(index === 0){
                                        firstDate = start
                                        lastDate = end
                                    }
                                    if(start < firstDate){
                                        firstDate = start
                                    }
                                    if(end > lastDate){
                                        lastDate = end
                                    }
                                })
                                let save = false

                                let start = firstDate.getDate() + "." + (firstDate.getMonth()+1) + "." + firstDate.getFullYear()
                                let end = lastDate.getDate() + "." + (lastDate.getMonth()+1) + "." + lastDate.getFullYear()
                                let saveStart = moment(firstDate)
                                let saveEnd = moment(lastDate)

                                if(project.startDate && project.targetDate){

                                    let projStart = moment(project.startDate).toDate()
                                    let projEnd = moment(project.targetDate).toDate()
                                    let projS = projStart.getDate() + "." + (projStart.getMonth()+1) + "." + projStart.getFullYear()
                                    let projE = projEnd.getDate() + "." + (projEnd.getMonth()+1) + "." + projEnd.getFullYear()
                                    if((projS !== start || projE !== end)){
                                        saveStart = moment(firstDate)
                                        saveEnd = moment(lastDate)
                                        save = true
                                    }      
                                } else {
                                    save = true
                                }

                                let update = {
                                    ...project,
                                    startDate: saveStart,
                                    targetDate: saveEnd
                                }
                                if(save){
                                    setProject({...update})
                                    props.saveProject(update)
                                }
                                setDateChecked(true)
                            }
                        }
                    })
                }
            })
        }
    }

    const getProjectSurveys = async(id) => {
        if(props.project || (project && project.selectedSurveys.length > 0)){
            let payload = ({
                projectId:id
            })
            const result = await executeRequest('project/getSurveysForProject', payload)
            setProjectSurveys(result)
        }
    }   

    const getProjectPlans = async(id) => {
        if(props.project && props.project.plans || project && project.plans && project.plans.length > 0){
            let payload = ({
                projectId:id
            })
            const result = await executeRequest('project/getPlansForProject', payload)
            setProjectPlans(result)
        }
    }

    const handleNewPlan = (type) => {
        setPlanModalOpen(true)
        setPlanType(type)
    }

    const customFilter = (option, searchText) => {
        if (
            option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.value.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.tags.filter(tag => tag.toLowerCase().includes(searchText.toLowerCase())).length > 0
        ) {
          return true;
        } else {
          return false;
        }
    }
    const customOption = (option) => {
        return(
            <div style={{ display:'flex', flexDirection:"row" }}>
                <div className="option-label">{`${option.label}`}</div>
                <div className="option-tag-container">
                    {option && option.tags && option.tags.slice(0,4).map(tag => {
                        return(
                            <div key={tag} className="option-tag client-lighter-color">{tag}</div>
                        )                  
                    })}
                    {option && option.tags && option.tags.length > 4 &&  
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="material-icons" style={{ fontSize: '0.9rem' }} title={tagsToMore(option.tags,4)}>more</i>
                        </div>
                    }
                    
                </div>
            </div>
        )
    }
    const tagsToMore = (tags, slicer) => {
        var tagss = ""
        if(tags !== undefined ){
            tags.slice(slicer, tags.length).filter(t => tagss = tagss + "  " + t)
            return(tagss)
        }
    }

    const getCompareViews = async(projectId) => {
        let payload = {
            projectId:projectId
        }
        const response = await executeRequest('project/getCompareViews', payload)
        if(response.length > 0){
            setProjectViews(response)
        }
    }

    const setDescriptionFromModify = (data) => {
        handleChange({target:{ id: "description", value: data}})
    }

    const goToBusiness = () => {
        if(project.targetBusiness){
            dispatch(ActionCreator.addBreadcrumbs({ to: 'raportit/yritys', label: project.targetBusiness.label, businessId: project.targetBusiness.value }))
            dispatch(ActionCreator.selectBusinessForReport({ businessId: project.targetBusiness.value, name: project.targetBusiness.label }))
            dispatch(ActionCreator.getBusinessSurveyTree({ businessId: project.targetBusiness.value }))
            history.push({
                pathname:"/raportit/yritys",
                state:{
                    crumbs:{
                        to: 'projektit/hallitse', label: project.projectName
                    }, 
                    projectId:thisId
                } 
            })
        }
    }

    const toggleProgressBar = () => {
        setPhaseProgressVisible(!phaseProgressVisible)
    }

    return (
        <div className="inner-project-container"> 
            <ProjectDataModal 
                isOpen={modifyProjectModal}
                closeModal={setModifyProjectModal}
                projectToModify={project}
                updateProject={updateProject}
                setUpdatedProject={setProject}
                planList={(projectPlans && projectPlans.plans) ? projectPlans.plans : []}
            />
            <ActivateSurveyModal 
                isOpen={isModalOpen} 
                toggleModal={setIsModalOpen}
                clientList={clientList}
                setSelectedSurveys={setSelectedSurveys}
                saveSelectedSurveys={saveSelectedSurveys}
                selectedSurveys={project.selectedSurveys}
                targetBusiness={project.targetBusiness}
                projectId={thisId}
                getProjectSurveys={getProjectSurveys}
            />
            <SurveyModal 
                modal={modalOpen} 
                toggle={setModalOpen} 
                selectedBusiness={project.targetBusiness}
                selectedSurveys={project.selectedSurveys || []}
                setSelectedSurveys={setSelectedSurveys}
                saveSelectedSurveys={saveSelectedSurveys}
                projectId={thisId}
            />
            <NewPlanModal
                planType={planType}
                clientList={clientList}
                modalOpen={planModalOpen}
                toggle={setPlanModalOpen}
                history={history}
                projectId={thisId}
                projectName={project.projectName}
                updatePlans={getProjectPlans}
            /> 
            {/* <Modal isOpen={contactModal} toggle={() => setContactModal(!contactModal)}>
                <ContactCard
                
                />.js
                <ModalFooter>
                    <button onClick={() => setContactModal(!contactModal)} className="small-white-button swb-hover" style={{ width: '100%' }}>Peruuta</button> 
                </ModalFooter>
            </Modal> */}
            <Col>
                <Row className="project-header">
                    {/* <Col xs="1"  style={{ alignItems: 'center', paddingLeft: '1.5em' }}>
                        <div className={'dashboard-dot project-color'} style={{ marginRight: '0.5em', marginTop: '0.25em', height:'1.5em', width: '1.5em'}}></div> 
                    </Col>  */}
                    <Row  style={{ paddingTop: '1.5em', borderRadius: '5px 5px 0 0', marginLeft:'1.5em', marginRight:'1em', width:'100%' }}>
                        <Col xs="7">
                            <Row>
                                <h4 className="project-title-overflow-handler" style={{ fontWeight:600 }}>{project.projectName}</h4>
                            </Row>
                        </Col>
                        <Col xs="2">
                            {project.tags &&
                                <Row className="project-data-row">
                                    <Col>
                                        <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                                            {project.tags && project.tags.slice(0, 7).map((tag, y) => {                        
                                                    return(<div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="project-tag-in-header-bar tag-display tag-text project-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                })  
                                            }                                                   
                                            {project.tags && project.tags.length > 7 &&  
                                                <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                                    <i className="material-icons" title={tagsToMore(project.tags.slice(7,project.tags.length))}>more_horiz</i>
                                                </div>
                                            }  
                                        </div>        
                                    </Col>
                                </Row>
                            } 
                        </Col>
                        <Col xs="2"> 
                            <Row className="project-data-row">
                                <Col>
                                    <div className="project-status-div-from-top" style={{ backgroundColor: project.status? project.status.value: "#c3c3c3"}}>
                                        <p style={{ fontWeight: 'bold' }}>{project.status? project.status.label : "Ei statusta"}</p>
                                    </div> 
                                </Col>
                            </Row> 
                        </Col>  
                        
                        <Col xs="1" style={{ paddingRight: '1.5em'}}>
                            <div className='project-settings-button' title="Muokkaa projektin tietoja" style={{ float: 'right', backgroundColor: '#fefefe', padding: '0.25em 0.5em', borderRadius: '10px', boxShadow: '2px 2px 3px #ccc', cursor: 'pointer', border: '1px solid #ebebeb'}} onClick={()=> setModifyProjectModal(true)}>
                                <i className="fas fa-cog" style={{ }}></i>
                            </div>
                        </Col>
                    </Row>
                    <Row  style={{ marginLeft:'1.5em', marginRight:'1.75em', width:'100%' }}>
                        <Col xs="5">
                            <Row>
                                <a className="go-back-link" onClick={() => goToBusiness()} style={{cursor:'pointer' }}>{project.targetBusiness.label || ""}</a>
                            </Row>
                        </Col>
                        <Col xs ="7" style={{ }}>
                            <Row style={{ margin: 0}}>
                                <Col> 
                                    <div className="project-statistic-div" title="Lomakkeet"><i className="fas fa-clipboard-list"  style={{ opacity: '0.7', marginLeft: '0.5em' }}></i><p style={{ marginLeft: '0.5em' }}>{project.plans ? project.plans.length: 0}</p></div>
                                </Col>
                                <Col> 
                                    <div className="project-statistic-div" title="Kartoitukset"><i  className="fas fa-file-alt" style={{ opacity: '0.7', marginLeft: '0.5em' }}></i><p style={{ marginLeft: '0.5em' }}>{project.selectedSurveys? project.selectedSurveys.length : 0}</p></div>
                                </Col>
                                <Col>
                                   <div className="project-statistic-div" title="Liitteet"><i className="fas fa-paperclip"   style={{ opacity: '0.7', marginLeft: '0.5em' }}></i><p style={{ marginLeft: '0.5em' }}>{project.files ? project.files.length : 0}</p></div>
                                </Col>
                                <Col>
                                    <div className="project-statistic-div" title="Tapahtumat"><i style={{ opacity: '0.7', marginLeft: '0.5em' }}   className="far fa-calendar-alt"></i><p style={{ marginLeft: '0.5em' }}>{project.activities ? project.activities.length : 0}</p></div>
                                </Col>
                                <Col>
                                    <div className="project-statistic-div" title="Pohjat"><i className="fas fa-clipboard-list"  style={{ opacity: '0.7', marginLeft: '0.5em' }}></i><p style={{ marginLeft: '0.5em' }}>{project.templates ? project.templates.length : 0}</p></div>
                                </Col>
                                <Col>
                                    <div className="project-statistic-div" title="Vertailut"><i className="fas fa-chart-bar"  style={{ opacity: '0.7', marginLeft: '0.5em' }}></i><p style={{ marginLeft: '0.5em' }}>{project.compareViews ? project.compareViews.length : 0}</p></div>
                                </Col>
                                {/* <Col>
                                    <div className="project-statistic-div" title="Trello"><i className="fab fa-trello"style={{ opacity: '0.7', marginLeft: '0.5em' }}></i><p style={{ marginLeft: '0.5em' }}>{0}</p></div>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Row>
                <div className="project-divider"></div>
                {project.ganttPlan && 
                    <Row onClick={()=> toggleProgressBar()} style={{cursor:'pointer' }}> 
                        {calculatedProgress && 
                            <>
                                <div className="progress-bar-base" style={{ width:'100%', borderRadius:'10px 10px 10px 10px', marginTop:'0.25em'}}>
                                    <div style={{ width: calculatedProgress.progress? calculatedProgress.progress + "%": 0, height:'1.2em', display:'flex', fontSize:'0.8rem', fontWeight:'bold', borderRadius: '10px 10px 10px 10px', backgroundColor:'#acd811'}}></div>
                                </div>
                                <div style={{ width: '100%', display:'flex', position:'relative', fontSize:'0.8rem', fontWeight:'bold', justifyContent:'center', marginTop:'-1.35em'}}>{calculatedProgress && calculatedProgress.progress? <p style={{fontSize:'0.8rem', fontWeight:'bold', }}>{calculatedProgress.progress? calculatedProgress.progress.toFixed(0) + '%' : "0"}/100%</p> : <p style={{fontSize:'0.8rem', fontWeight:'bold' }}>{"0%/100%"}</p>}</div>
                            </>
                        }
                        {phaseProgressVisible && calculatedProgress.phases.length <= 20 &&
                            <div className="progress-bar-base" style={{borderRadius:'10px 10px 10px 10px', marginTop: '0.25em'}}>
                                {/* <div style={{ backgroundColor:'#acd811', width: calculatedProgress? calculatedProgress+'%':0, height:'1.5em', textAlign:'center', fontSize:'0.8rem', fontWeight:'bold', borderRadius: calculatedProgress === 100 ? '10px': '10px 0px 0px 10px'}}><p>{calculatedProgress? calculatedProgress + '%' : ""}</p></div> */}
                                {calculatedProgress && calculatedProgress.phases && calculatedProgress.phases.map((phase, index)=>{
                                    return(
                                        <div style={{width:'100%', borderRadius: index === 0 ? '10px 0px 0px 10px' : (index === (calculatedProgress.phases.length-1) ? '0px 10px 10px 0px' : '0')}}>
                                            <div style={{ height:'1.25em', display:'flex', fontSize:'0.8rem', fontWeight:'bold', borderRadius: index === 0 ? '10px 0px 0px 10px' : (index === (calculatedProgress.phases.length-1) ? '0px 10px 10px 0px' : '0'), borderLeft: index !== 0? '1px solid #404040' :''}}>
                                                <div style={{backgroundColor:'#acd811', width: phase.progress + '%', borderRadius: index === 0 ? '10px 0px 0px 10px' : (index === (calculatedProgress.phases.length-1) ? '0px 10px 10px 0px' : '0')}}></div>
                                            </div>
                                            <div style={{ width: '100%', display:'flex', position:'relative', fontSize:'0.8rem', fontWeight:'bold', justifyContent:'center', marginTop:'-1.3em'}} title={phase.name}><p className="one-line-text" style={calculatedProgress.phases.length > 7? { maxWidth:'50%' } : {maxWidth:'50%'}}>{calculatedProgress.phases.length < 7 ? phase.name : ("v" + (index+1))}</p> <p style={{ marginLeft:'0.5em'}}>{phase.progress? phase.progress.toFixed(0) + '%' : ""}</p></div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                       
                    </Row>
                }
                <Row className="project-box">
                    <Col style={{ marginTop: '0.3em', paddingLeft: '1.5em' }} lg="5">
                        <Row className="project-data-row">
                            <Col>
                                <p className="project-data-label">Projektin aloitus ja tavoite pvm:</p>
                                <p>{(startDate?moment(startDate).format("DD.MM.YYYY"): "Ei määritetty") + " -> " + (targetDate? moment(targetDate).format("DD.MM.YYYY"): "Ei määritetty")}</p>
                            </Col>
                        </Row>
                        <Row className="project-data-row">
                            <Col>
                                <p className="project-data-label">Projektiin liitetyt yritykset:</p>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {project.selectedProviders && project.selectedProviders.map((provider,key)=>{
                                        return(
                                            <div key={key} className="provider-item"> 
                                                <p>{provider.label}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                        <Row className="project-data-row">
                            <Col>
                                <p className="project-data-label">Projektin kuvaus:</p>
                                {project && project.description && 
                                    <Scrollbars
                                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                        style={{ width: "100%"}}
                                        className="custom-scrollbars"
                                        autoHeight={true}
                                        autoHeightMax={'420px'}
                                    >
                                        <TextEditor
                                            placeholder=" - "                        
                                            content={project.description} 
                                            onTextChange={(val) => {return}} 
                                            additionalClass="round-corners project-description"
                                            allowFileUpload={false}
                                            connectionType={"project"}
                                            documentId={project._id}
                                            readOnly={true}
                                        />
                                    </Scrollbars>
                                }
                            </Col>
                        </Row>

                    </Col>                
                    <Col style={{ marginTop: '0em', paddingRight: '1.5em', minHeight: '600px', maxHeight: '650px' }} lg="7">
                        {props.project && 
                            <div className="" >
                                <TabHandler
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    project={props.project}
                                  
                                /> 
                                <div className="">      
                                    {activeTab === 'surveys' &&
                                        <div className="">
                                            {projectSurveys &&
                                                <SurveyList
                                                    updateChange={getProjectSurveys}
                                                    businessList={clientList}
                                                    projectId={thisId}
                                                    history={history}
                                                    sentSurveys={projectSurveys.surveys}
                                                    surveyFilters={projectSurveys.filters}
                                                    projectName={project.projectName}
                                                    setIsModalOpen={setIsModalOpen}
                                                />
                                            }
                                        </div>
                                    }
                                    {activeTab === 'plans' &&
                                        <div className="">
                                            {projectPlans &&
                                                <PlanList 
                                                    projectPlans={projectPlans}
                                                    history={history}
                                                    projectId={thisId}
                                                    project={project}
                                                    projectName={project.projectName}
                                                    updatePlans={getProjectPlans}
                                                    setPlanModalOpen={handleNewPlan}
                                                />
                                            }
                                        </div>
                                    }
                                    {activeTab === 'attachments' &&
                                        <div className="tab-styles">
                                            <Attachments
                                                projectId={thisId}
                                                attachments={project.files}
                                            />
                                        </div>
                                    } 
                                    {activeTab === 'activities' &&
                                        <div className="tab-styles">
                                            <Activities
                                                projectId={thisId}
                                                activities={project.files}
                                            />
                                        </div>
                                    } 
                                    {activeTab === 'templates' &&
                                        <div className="tab-styles">
                                            <TemplateList
                                                history={history}
                                                projectId={thisId}
                                                projectName={project.projectName}
                                                setPlanModalOpen={handleNewPlan}
                                            />
                                        </div>
                                    }
                                    {activeTab === 'views' &&
                                        <div className="tab-styles">
                                            <ViewList
                                                projectViews={projectViews}
                                                history={history}
                                                projectId={thisId}
                                                projectName={project.projectName}
                                                setView={props.setView}
                                                setViewOptions={props.setViewOptions}
                                                activeTab={activeTab}
                                            />
                                        </div>
                                    }
                                    {/* {activeTab === 'trello' &&
                                        <div className="tab-styles">
                                            <TrelloView/> 
                                        </div>
                                    } */}
                                </div>
                            </div>
                        } 
                    </Col>               
                </Row>  
            </Col>      
        </div>     
    )
}

export const EmptyProjectList = ({listText}) => {
    return(
        <div style={{ display: 'flex', width: '100%', height: '25%', justifyContent:'center', alignItems: 'center', paddingTop: '1em' }}>
            <h4>{listText}</h4>
        </div>
    )
}
import React, { Component } from 'react';
import './App.css';
import Navigator from './Router/Navigator';
import 'react-notifications/lib/notifications.css';
import 'react-dates/initialize'

import {NotificationContainer} from 'react-notifications';
import "react-toggle/style.css";
import "@kenshooui/react-multi-select/dist/style.css";
export default class App extends Component {

  render() {
    return (
        <div className="App">
          <Navigator />
          <NotificationContainer />
        </div>
    );
  }
}

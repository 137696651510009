import React, {useState, Fragment} from 'react';
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import {Row, Col} from 'reactstrap';
import TextEditor from '../../../../Utilities/TextEditor/texteditor';


const FreetextContainer = ({answers, additionalClass, filterByAnswerSession}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Row className={additionalClass} style={{paddingTop: '1rem'}}>
                <Col md={{size: 12}}>

                    <h5 style={{marginBottom: 0}}>
                        <button className="small-white-button lighter-text wide-button" style={{margin: '.25rem 0 .5rem .5rem', float: 'right'}}
                            onClick={() => setOpen(!isOpen)}>{isOpen ? "Piilota vapaatekstit" : "Näytä vapaatekstit"}
                        </button>
                        Vapaatekstit
                    </h5>

                    <hr style={{clear: 'both'}}/>
                    {isOpen &&
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '300px' }}
                        className="custom-scrollbars" >

                        {answers && answers.map((answer, index) => {
                        let className = index % 2 === 0 ? 'even-row' : 'odd-row'
                        {/*let buttonText = visibleIndex === index ? 'Piilota loppupalautteen arvot' : 'Näytä loppupalautteen arvot'*/}

                        return (
                            <Fragment key={index}>
                                <Row className={className} style={{ padding: '1rem' }}>
                                    <Col xs={{size: 4}}>
                                        <TextEditor 
                                            content={answer.text}
                                            onTextChange={() => {return}} 
                                            readOnly={true}
                                        />
                                        <p className="lighter-text">{ moment.utc( answer.time, "MMM D, YYYY, HH:mm:ss A", "en" ).local().format( 'D.M.YYYY  HH.mm' ) }</p>
                                    </Col>
                                    <Col xs={{size: 4}} style={{textAlign: 'center'}}>
                                        <p> { answer.surveyName } </p>
                                        <p className="lighter-text"> { answer.code } </p>
                                    </Col>
                                    {typeof answer.answerSessionId !== 'undefined' ? (
                                    <Col xs={{size: 4}} style={{textAlign: 'center'}}>
                                        <button className="small-white-button" style={{padding: '0.25rem 1rem'}} onClick={() => filterByAnswerSession( [answer.answerSessionId] )} >Tarkastele</button>
                                    </Col>
                                    ) : (
                                    null
                                    )}
                                </Row>
                            </Fragment>
                        )
                        })}

                </Scrollbars>
                }
          </Col>
        </Row>
    )
}

export default FreetextContainer;
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { Row, Col } from 'reactstrap'
import moment from 'moment'

import * as ActionCreator from '../../store/'
import MainReport from './subComponents/reportTypes/MainReport'
import BarReport from './subComponents/reportTypes/BarReport'
import BarReportQuestions from './subComponents/reportTypes/BarReportQuestions'
import ReportFilterBar from './subComponents/ReportFilterBar'
import FreetextAnswersModal from '../../Utilities/Modals/FreetextAnswersModal'
import BusinessIdAnswersModal from '../../Utilities/Modals/BusinessIdAnswersModal'
import FinalFeedbackAnswersModal from '../../Utilities/Modals/FinalFeedbackAnswersModal'
import IconUrlModal from '../../Utilities/Modals/IconUrlModal'
import ReportLinkTree from './subComponents/Graphics/ReportLinkTree';
import { AttachmentAnswersModal } from './subComponents/AttachmentAnswersModal';


class Reports extends Component {
    constructor() {
        super()

        this.state = {
            reportType: 'main', // one of 'main', 'bar', 'barQuestions'
            freetextAnswersModalIsOpen: false,
            freetextAnswersForModal: {},
            attachmentAnswersModalIsOpen: false,
            attachmentAnswersForModal: {},
            businessIdModalIsOpen: false,
            businessIdsForModal: [],
            isDateModal: false,
            reportBases: [],
            baseChangedTo: null,
            iconUrlModalIsOpen: false,
            iconUrlAnswersForModal: []
        }
        
    }

    setReportType = (type) => {
        this.setState({ reportType: type })
    }

    componentDidMount() {
        if(this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.crumbs){
            let crumbs = this.props.history.location.state.crumbs
            let projectId = this.props.history.location.state.projectId

            if (this.props.selectedBusinessName && this.props.selectedBusinessId && this.props.selectedBusinessId !== 'anon') {
                let businessId = this.props.selectedBusinessId;
                this.props.addMoreBreadcrumbs([{...crumbs, projectId: projectId}, { to: 'raportit/yritys', label: this.props.selectedBusinessName, selectedBusinessId: businessId }, { to: 'raportit/yritys/raportti', label: 'raportti' }])
            } else {
                let businessId = this.props.selectedBusinessId
                this.props.addMoreBreadcrumbs([{...crumbs, projectId: projectId}, { to: 'raportit/kohdistamaton', label: "Ei kohdeyritystä", selectedBusinessId: businessId }, { to: 'raportit/kohdistamaton/raportti', label: 'raportti' }])
            }

        } else {
            this.props.addMoreBreadcrumbs([{ to: 'tiedot', label: `${this.props.businessName}` }]);
            if (this.props.selectedBusinessName && this.props.selectedBusinessId && this.props.selectedBusinessId !== 'anon') {
                let businessId = this.props.selectedBusinessId;
                this.props.addMoreBreadcrumbs([{ to: 'raportit/yritys', label: this.props.selectedBusinessName, selectedBusinessId: businessId }, { to: 'raportit/yritys/raportti', label: 'raportti' }])
            } else {
                let businessId = this.props.selectedBusinessId
                this.props.addMoreBreadcrumbs([{ to: 'raportit/kohdistamaton', label: "Ei kohdeyritystä", selectedBusinessId: businessId }, { to: 'raportit/kohdistamaton/raportti', label: 'raportti' }])
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBusinessName && this.props.selectedBusinessName !== prevProps.selectedBusinessName && this.props.selectedBusinessId !== 'anon') {
            let businessId = this.props.selectedBusinessId;
            this.props.addMoreBreadcrumbs([{ to: 'raportit/yritys', label: this.props.selectedBusinessName, selectedBusinessId: businessId }, { to: 'raportit/yritys/raportti', label: 'raportti' }])
        }
    }

    componentWillUnmount() {
        this.props.clearReportData();
    }

    /**
     * filters the report with given answerSessionId
     * if answerSessionId is null, clears the filter
     * 
     * arg answerSessionIds must be an array of strings or null
     */
    filterReportByAnswerSession = (answerSessionIds = null) => {
        const { addFilter, activeFilters, removeFilter, filterTypes } = this.props
        let getReports = true

        if (answerSessionIds) {
            // let's remove variable filters when user filters by an answersession. these two won't work together very well
            const surveyVariableFilters = activeFilters.filter(filter => filter.type === 'variable')

            if (surveyVariableFilters.length > 0) {
                NotificationManager.error("Vastauksen mukaan suodatusta ei voi käyttää yhdessä taustamuuttujien kanssa", "Ilmoitus", 5000)
                getReports = false
            } else {
                let filterType = JSON.parse(JSON.stringify(filterTypes.answerSession))
                filterType.answerSessionIds = answerSessionIds

                addFilter(filterType, activeFilters)
            }
        } else {
            const filterItem = activeFilters.find(filter => filter.type === 'answerSession')

            removeFilter(activeFilters.indexOf(filterItem))
        }
        if (getReports) {
            this.getReportWithChosenFilters()
        }
    }

    onFilterClick = (filter) => {
        switch (filter.type) {
            case 'answerSession':
                return this.filterReportByAnswerSession()
            case 'variable':
                return this.removeVariable(filter)
            default:
                return
        }
    }

    selectVariable = (variable) => {
        const { filterTypes, addFilter, removeFilter, activeFilters, selectedVariables, setVariables } = this.props

        const answerSessionFilter = activeFilters.find(filter => filter.type === 'answerSession') || null

        if (answerSessionFilter) {
            const filterIndex = activeFilters.indexOf(answerSessionFilter)
            activeFilters.splice(filterIndex, 1)
            removeFilter(filterIndex)
        }

        let filterType = JSON.parse(JSON.stringify(filterTypes.variable))
        filterType.label = variable.label
        filterType.question = variable.question

        addFilter(filterType, activeFilters)

        let newSelectedVariables = JSON.parse(JSON.stringify(selectedVariables))

        if (!newSelectedVariables[variable.question]) {
            newSelectedVariables[variable.question] = [variable.label]
        } else {
            let yeOlde = newSelectedVariables[variable.question]
            yeOlde.push(variable.label)
            newSelectedVariables[variable.question] = yeOlde
        }

        setVariables(newSelectedVariables)
        this.getReportWithChosenFilters()
    }

    removeVariable = (variable) => {
        const { activeFilters, removeFilter, selectedVariables, setVariables } = this.props

        let newSelectedVariables = JSON.parse(JSON.stringify(selectedVariables))
        let yeOlde = newSelectedVariables[variable.question]
        const index = yeOlde.indexOf(variable.label)

        if (index !== -1) {
            yeOlde.splice(index, 1)
            if (yeOlde.length > 0) {
                newSelectedVariables[variable.question] = yeOlde
            } else {
                delete newSelectedVariables[variable.question]
            }
            setVariables(newSelectedVariables)
        }

        const filterItem = activeFilters.find(filter => filter.label === variable.label)

        removeFilter(activeFilters.indexOf(filterItem))

        this.getReportWithChosenFilters()
    }

    addDateFilter = (startDate, endDate) => {
        const { filterTypes, activeFilters, addFilter, removeFilter } = this.props

        if (startDate !== null && endDate !== null) {
            const formattedStart = moment(new Date(startDate)).format('DD.MM.YYYY')
            const formattedEnd = moment(new Date(endDate)).format('DD.MM.YYYY')

            let filterType = JSON.parse(JSON.stringify(filterTypes.dateRange))
            filterType.label = `${formattedStart} - ${formattedEnd}`
            filterType.startDate = startDate
            filterType.endDate = endDate

            // remove old date filter if one is found
            const filterItem = activeFilters.find(filter => filter.type === 'dateRange')

            if (filterItem) {
                const filterIndex = activeFilters.indexOf(filterItem)
                removeFilter(filterIndex)
                activeFilters.splice(filterIndex, 1)
            }

            addFilter(filterType, activeFilters)

        } else {
            const filterItem = activeFilters.find(filter => filter.type === 'dateRange')

            removeFilter(activeFilters.indexOf(filterItem))
        }

        this.getReportWithChosenFilters()
    }

    getReportWithChosenFilters = () => {
        setTimeout(() => {
            const { sentSurveyIds, activeFilters, selectedVariables, getReport } = this.props
            const { reportBases, baseChangedTo } = this.state;
            let params = {
                reports: [],
                reportBases: reportBases,
                baseChangeTo: baseChangedTo
            }

            // ADD REPORT ID:S TO PARAMS
            const newSentSurveyIds = []

            sentSurveyIds.map(surveyId => {
                if (newSentSurveyIds.indexOf(surveyId) === -1) {
                    return newSentSurveyIds.push(surveyId)
                }
                return false
            })

            newSentSurveyIds.map(id => params.reports.push({ reportId: id }))

            // ADD DATERANGE TO PARAMS
            const dateRangeFilter = activeFilters.find(filter => filter.type === 'dateRange') || null

            if (dateRangeFilter) {
                const dateRange = {
                    startDate: dateRangeFilter.startDate,
                    endDate: dateRangeFilter.endDate
                }

                params.dateRange = dateRange
            }

            // ADD ANSWERSESSION ID:S TO PARAMS
            const answerSessionFilter = activeFilters.find(filter => filter.type === 'answerSession') || null

            if (answerSessionFilter) {
                const answerSessionIds = answerSessionFilter.answerSessionIds

                params.answerSessionIds = answerSessionIds
            }

            // ADD SURVEYVARIABLES TO PARAMS
            if (Object.keys(selectedVariables).length > 0) {
                params.surveyVariables = selectedVariables
            }

            getReport(params)

            this.setState({ baseChangedTo: null })
        }, 300)
    }

    toggleFreetextAnswersModal = (freetextAnswers = {}, isDateModal = false) => {
        this.setState(prevState => ({
            freetextAnswersModalIsOpen: !prevState.freetextAnswersModalIsOpen,
            freetextAnswersForModal: freetextAnswers,
            isDateModal: isDateModal
        }))
    }

    toggleAttachmentAnswersModal = (attachmentAnswers = {}, isDateModal = false) => {
        this.setState(prevState => ({
            attachmentAnswersModalIsOpen: !prevState.attachmentAnswersModalIsOpen,
            attachmentAnswersForModal: attachmentAnswers,
            isDateModal: isDateModal
        }))
    }

    toggleFinalFeedbackAnswersModal = (answers = []) => {
        this.setState(prevState => ({
            finalFeedbackAnswersModalIsOpen: !prevState.finalFeedbackAnswersModalIsOpen,
            finalFeedbackAnswersForModal: answers
        }))
    }

    toggleBusinessIdModal = (answers = []) => {
        this.setState(prevState => ({
            businessIdModalIsOpen: !prevState.businessIdModalIsOpen,
            businessIdsForModal: answers
        }))
    }

    toggleIconUrlListModal = (answers = []) => {
        this.setState(prevState => ({
            iconUrlModalIsOpen: !prevState.iconUrlModalIsOpen,
            iconUrlAnswersForModal: answers
        }))
    }

    printPdfReportWithFilters = (type, emailParams) => {
        const { sentSurveyIds, activeFilters, selectedVariables, printPdfReport, sendEmailReport } = this.props

        let params = {
            reports: []
        }

        // ADD REPORT ID:S TO PARAMS
        const newSentSurveyIds = []

        sentSurveyIds.map(surveyId => {
            if (newSentSurveyIds.indexOf(surveyId) === -1) {
                return newSentSurveyIds.push(surveyId)
            }
            return false
        })

        newSentSurveyIds.map(id => params.reports.push({ reportId: id }))

        // ADD DATERANGE TO PARAMS
        const dateRangeFilter = activeFilters.find(filter => filter.type === 'dateRange') || null

        if (dateRangeFilter) {
            const dateRange = {
                startDate: dateRangeFilter.startDate,
                endDate: dateRangeFilter.endDate
            }

            params.dateRange = dateRange
        }

        // ADD ANSWERSESSION ID:S TO PARAMS
        const answerSessionFilter = activeFilters.find(filter => filter.type === 'answerSession') || null

        if (answerSessionFilter) {
            const answerSessionIds = answerSessionFilter.answerSessionIds

            params.answerSessionIds = answerSessionIds
        }

        // ADD SURVEYVARIABLES TO PARAMS
        if (Object.keys(selectedVariables).length > 0) {
            params.surveyVariables = selectedVariables
        }

        if(type === 'email'){
            params = {...params, email: emailParams}
            sendEmailReport(params)

        }else{
            printPdfReport(params)
        }
        

    }

    pxToMm = (px) => {
        return Math.floor(px / document.getElementById('myMm').offsetHeight);
    };

    mmToPx = (mm) => {
        return document.getElementById('myMm').offsetHeight * mm;
    };

    range = (start, end) => {
        return Array(end - start).join(0).split(0).map(function (val, id) { return id + start });
    };

    printWordReportWithFilters = () => {
        const { sentSurveyIds, activeFilters, selectedVariables, printWordReport } = this.props

        let params = {
            reports: []
        }

        // ADD REPORT ID:S TO PARAMS
        const newSentSurveyIds = []

        sentSurveyIds.map(surveyId => {
            if (newSentSurveyIds.indexOf(surveyId) === -1) {
                return newSentSurveyIds.push(surveyId)
            }
            return false
        })

        newSentSurveyIds.map(id => params.reports.push({ reportId: id }))

        // ADD DATERANGE TO PARAMS
        const dateRangeFilter = activeFilters.find(filter => filter.type === 'dateRange') || null

        if (dateRangeFilter) {
            const dateRange = {
                startDate: dateRangeFilter.startDate,
                endDate: dateRangeFilter.endDate
            }

            params.dateRange = dateRange
        }

        // ADD ANSWERSESSION ID:S TO PARAMS
        const answerSessionFilter = activeFilters.find(filter => filter.type === 'answerSession') || null

        if (answerSessionFilter) {
            const answerSessionIds = answerSessionFilter.answerSessionIds

            params.answerSessionIds = answerSessionIds
        }

        // ADD SURVEYVARIABLES TO PARAMS
        if (Object.keys(selectedVariables).length > 0) {
            params.surveyVariables = selectedVariables
        }

        printWordReport(params)
    }

    handleReportBases = (selectedBase, index, changed) => {
        let bases = [
            ...this.state.reportBases
        ]

        let filtered = bases.filter(x => x.index === index);

        if (filtered.length === 0) {
            bases.push({ index: index, item: selectedBase });
            this.setState({ reportBases: bases, baseChangedTo: selectedBase });
        } else {
            let filtered = bases.filter(x => x.index !== index);
            filtered.push({ index: index, item: selectedBase });
            this.setState({ reportBases: filtered, baseChangedTo: selectedBase });
        }

        if (changed) {
            this.getReportWithChosenFilters();
        }
    }

    canPrintBoth = (sentSurveys) => {
        let filtered = sentSurveys.filter(x => x.freetextAnswers !== undefined);

        if (filtered.length > 0) {
            return true;
        }

        return false;
    }

    render() {
        const { reportType, isDateModal } = this.state
        const { sentSurveys, surveyVariables, activeFilters } = this.props
        const answerSessionFilter = activeFilters.find(filter => filter.type === 'answerSession') || null
        let answerSessionIds = []

        let oneAnswer = false;

        if (answerSessionFilter) {
            answerSessionIds = answerSessionFilter.answerSessionIds
            if (answerSessionIds.length === 1) {
                oneAnswer = true;
            }
        }

        const reportProps = {
            sentSurveys: sentSurveys,
            toggleFreetextAnswersModal: this.toggleFreetextAnswersModal,
            toggleBusinessIdModal: this.toggleBusinessIdModal,
            toggleFinalFeedbackAnswersModal: this.toggleFinalFeedbackAnswersModal,
            filterByAnswerSession: this.filterReportByAnswerSession,
            handleReportBases: this.handleReportBases,
            toggleIconUrlListModal: this.toggleIconUrlListModal,
            toggleAttachmentAnswersModal: this.toggleAttachmentAnswersModal,
            oneAnswer: oneAnswer
        }

        return (
            <Container fluid>
                <FreetextAnswersModal
                    isOpen={this.state.freetextAnswersModalIsOpen}
                    toggleModal={this.toggleFreetextAnswersModal}
                    freetextAnswers={this.state.freetextAnswersForModal}
                    filterReportByAnswerSession={this.filterReportByAnswerSession}
                    isDateModal={isDateModal}
                    answerSessionIds={answerSessionIds}
                />

                <AttachmentAnswersModal
                   isOpen={this.state.attachmentAnswersModalIsOpen}
                   toggleModal={this.toggleAttachmentAnswersModal}
                   attachmentAnswers={this.state.attachmentAnswersForModal}
                   filterReportByAnswerSession={this.filterReportByAnswerSession}
                   isDateModal={isDateModal}
                   answerSessionIds={answerSessionIds} 
                />

                <FinalFeedbackAnswersModal
                    isOpen={this.state.finalFeedbackAnswersModalIsOpen}
                    toggleModal={this.toggleFinalFeedbackAnswersModal}
                    answers={this.state.finalFeedbackAnswersForModal}
                    filterReportByAnswerSession={this.filterReportByAnswerSession}
                />

                <BusinessIdAnswersModal
                    isOpen={this.state.businessIdModalIsOpen}
                    toggleModal={this.toggleBusinessIdModal}
                    answers={this.state.businessIdsForModal}
                    filterReportByAnswerSession={this.filterReportByAnswerSession} 
                />

                <IconUrlModal
                    isOpen={this.state.iconUrlModalIsOpen}
                    toggleModal={this.toggleIconUrlListModal}
                    urls={this.state.iconUrlAnswersForModal}
                />

                <ReportFilterBar
                    reportType={reportType}
                    setReportType={this.setReportType}
                    activeFilters={activeFilters}
                    onClick={this.onFilterClick}
                    surveyVariables={surveyVariables}
                    selectVariable={this.selectVariable}
                    addDateFilter={this.addDateFilter}
                    multiPrint={this.canPrintBoth(sentSurveys)}
                    printPdfReport={this.printPdfReportWithFilters}
                    printWordReport={this.printWordReportWithFilters}
                    sendEmailReport={this.sendEmailReport}
                />
                {sentSurveys.length === 0 ? (
                    this.props.isLoading ? <FetchPlaceHolder /> : <NoReportsPlaceholder />
                ) : (
                        <div id="pdf-container" >
                            {reportType === 'main' && <MainReport ref={el => (this.componentRef = el)} {...reportProps} />}
                            {reportType === 'bar' && <BarReport {...reportProps} />}
                            {reportType === 'barQuestions' && <BarReportQuestions {...reportProps} />}
                            {reportType === 'graphics' && <ReportLinkTree {...reportProps} sentSurveyIds={this.props.sentSurveyIds} />}
                        </div>
                    )}
            </Container>
        )
    }
}

const NoReportsPlaceholder = () => {
    return (
        <Row className="report">
            <Col style={{ padding: '0 2rem' }}>
                <Row className="survey-buttonfilters-row"></Row>
                <Row className="survey-main-row" style={{ paddingBottom: '5rem' }}>
                    <Col className="text-center">
                        <h4>Vaihda hakuehtoja nähdäksesi raportin</h4>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}

const FetchPlaceHolder = () => {
    return (
        <Row className="report">
            <Col style={{ padding: '0 2rem' }}>
                <Row className="survey-buttonfilters-row"></Row>
                <Row className="survey-main-row" style={{ paddingBottom: '5rem' }}>
                    <Col className="text-center">
                        <h4>Haetaan tuloksia</h4>
                    </Col>
                    <MegaLoaderSpinner />
                </Row>
            </Col>
        </Row>
    )
}

const MegaLoaderSpinner = () => {
    return (
        <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </Col>
    )
}


const mapStateToProps = state => {
    return {
        sentSurveys: state.reports.sentSurveys,
        sentSurveyIds: state.reports.sentSurveyIds,
        activeFilters: state.reports.activeFilters,
        filterTypes: state.reports.filterTypes,
        surveyVariables: state.reports.surveyVariables,
        selectedVariables: state.reports.selectedVariables,
        selectedBusinessName: state.reports.selectedBusiness.businessName,
        selectedBusinessId: state.reports.selectedBusinessId,
        businessName: state.authentication.businessName,
        isLoading: state.reports.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //getReportByAnswerSession: (params) => dispatch(ActionCreator.getReportByAnswerSession(params)),
        getReport: (params) => dispatch(ActionCreator.getReportForDashboard(params)),
        addFilter: (newFilter, activeFilters) => dispatch(ActionCreator.addFilter(newFilter, activeFilters)),
        removeFilter: (params) => dispatch(ActionCreator.removeFilter(params)),
        setVariables: (params) => dispatch(ActionCreator.setVariables(params)),
        addMoreBreadcrumbs: (params) => dispatch(ActionCreator.addMoreBreadcrumbs(params)),
        printPdfReport: (params) => dispatch(ActionCreator.printPdfReport(params)),
        printWordReport: (params) => dispatch(ActionCreator.printWordReport(params)),
        sendEmailReport: (params) => dispatch(ActionCreator.sendEmailReport(params)),
        clearReportData: () => dispatch(ActionCreator.clearSelected())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
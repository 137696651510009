import React, {useState} from "react";
import { NotificationManager } from "react-notifications";
import { Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { executeRequest } from "../../../Utilities/requestHandler/dataHandler";

export const PlanBeingEditedModal = ({plan, isOpen, goToEdit, toggle}) => {
    const {planName, beingEditedBy} = plan
    const [emailText, setEmailText] = useState("")
    const [emailSent, setEmailSent] = useState(false)

    const sendNotificationToEditers = async() => {
        let payload = {
            message:emailText,
            beingEditedBy: beingEditedBy,
            planName: planName
        }
        const response = await executeRequest('plan/send/notificationToEditors ',payload)
        if(response && response.success){
           
            let emails = ""
            response.success.map((email) =>{
                emails = emails + email + "" 
            })
            NotificationManager.success('Ilmoitus lähetetty '+emails,'',3000)
            setEmailSent(true)
        }
    }
    return(
        <Modal size="lg" isOpen={isOpen} toggle={()=> toggle(true)}>
            <ModalHeader toggle={()=> toggle(true)}>Lomaketta ollaan muokkaamassa</ModalHeader>
            <ModalBody> 
                <p>Lomaketta <b>{planName}</b> ollaan muokkaamassa seuraavien käyttäjien toimesta: </p> 
                <br/>
                {beingEditedBy && beingEditedBy.map((user,key)=>{
                    return(
                        <p key={key}>{user.email}</p>
                    )
                })}
                <br/>
                <p>Mikäli jatkat muokkaamaan lomaketta, tämänhetkisten muokkaajien tekemät muutokset voivat kumoutua</p>
                <br/>
                <div>
                    <Input placeholder="Lähetettävä teksti" value={emailText} disasbled={emailSent} onChange={(e)=>setEmailText(e.target.value)}/>
                    <button onClick={() => sendNotificationToEditers()} disabled={emailSent} className="small-white-button wide-button" >{emailSent ? 'Sähköpostiviesti lähetetty' : 'Lähetä muokkaajille sähköpostiviesti'}</button>
                    
                </div>
            </ModalBody>
            <ModalFooter>
                <a onClick={() => toggle(true)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                <button onClick={() => goToEdit()} className="small-white-button wide-button" >Jatka muokkaamaan</button>
            </ModalFooter>
        </Modal>
    )

}
import React, { useState, useEffect } from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import {Table, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label} from 'reactstrap';
import {ModifiedContainer} from './DataContainers';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';

const types = [
    {value:1, label:'Rivi'},
    {value:2, label:'Sarake'}
]

const beforeAfterList = [
    {value:0, label:'Ennen'},
    {value:1, label:'Jälkeen'}
]

export const TableContainer = ({ item, promptSave, saveWithoutPrompt, editable, toggleItemVisibility, onDelete, onTemplateAdd, index, planId, layoutPreview = false}) => {
    const [title, setTitle] = useState(item.topicContent);
    const [openMenu, toggleMenu] = useState(false);
    const [openTableModifier, setOpenTableModifier] = useState(false);
    const [tableRows, setTableRows] = useState(item.rows? item.rows : null)
    const [removeRowModal, setRemoveRowModal] = useState(false)
    const [openColSettings, setOpenColSettings] = useState(false)
    const [customWidth, setCustomWidth] = useState(item.customWidth? item.customWidth : [])
    const [lastCol, setLastCol] = useState("")
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [removeColModal, setRemoveColModal] = useState(false)
    const [tableEditModal, setTableEditModal] = useState(false)
    const [selectedType, setSelectedType] = useState(null)
    const [addRemove, setAddremove] = useState(null)
    const [indexList, setIndexList] = useState([{ label:1, value:0}])
    const [beforeAfter, setBeforeAfter] = useState(null)
    const [textContent, setTextContent] = useState(item.textContent || "")
    const [showAdditionalTextField, setShowAdditionalTextField] = useState(item.showAdditionalTextField||false)
    const [addRemoveList, setAddRemoveList] = useState([
        {value:1, label:'Lisää'},
        {value:2, label:'Poista'}
    ])

    const [borderless, setBorderless] = useState(item.borderless? item.borderless : false)

    function handleDataChanges(type, data) {
        
        if(type === "title") {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                type: item.type,
                borderless:borderless,
                rows: tableRows,
                textContent: textContent
            }
            promptSave(itemPayload);
        }
         
        if(type === "content") {
            setTextContent(data);
            let itemPayload = {
                id: item.id,
                topicContent: title,
                type: item.type,
                textContent: data,
                borderless:borderless,
                rows: tableRows
            }
            promptSave(itemPayload);
        }
        //onItemChange(itemPayload);
    }

    useEffect(()=>{
        if(item && item.rows){
            setTableRows(item.rows)
            if(item.rows.length === 0){
                setAddRemoveList([{value:1, label:'Lisää'}])
            } else { 
                setAddRemoveList([{value:1, label:'Lisää'},{value:2, label:'Poista'}])
            }
        }
    },[item])

    useEffect(()=>{
        if(selectedType){
            let array = []
            if(tableRows.length > 0){
                if(selectedType.value === 1){
                    array = tableRows.map((row, index)=>{return({ label:index+1, value:index})})
                } else if(selectedType.value === 2){
                    array = tableRows[0].cols.map((col, index)=>{return({ label:index+1, value:index})})
                }  
            }else{
                array = [{ label:1, value:0}]
            }
            setSelectedIndex(null)
            setIndexList([...array])
        }
    },[selectedType, tableRows])

    useEffect(()=>{
        if(customWidth.length === 0){
            let array = customWidth
            for(var i = 0; i < (item.selectedNumberOfCols-1); i++){
                array[i] = (100/item.selectedNumberOfCols).toFixed(0)
            }

            setCustomWidth([...array])
        }
    },[])

    useEffect(()=>{
        let lastColWidth = 100
        customWidth.map((width,i)=>{
            lastColWidth-=width
        })
        setLastCol(lastColWidth)
    },[customWidth])

    const addTableTemplate = item => {
        const {rows} = item

        let newTable = [
            ...rows.map(row => {
                const {cols} = row
                return {
                    id: uuidv4(),
                    cols: cols.map(col => {
                        return {
                            ...col,
                            id: uuidv4()
                        }
                    })
                }
            })
        ]
        let templateItem = {
            ...item,
            rows: newTable
        }

        onTemplateAdd({planItem: templateItem});
    }

    const addTableRow = (index) => {

        if(item && tableRows && saveWithoutPrompt){
            let colValue = item.selectedNumberOfCols
            let row = {
                id: uuidv4(),
                cols: 
                    [...Array(colValue).keys()].map(col => {
                        return {
                            text: '-',
                            id: uuidv4()
                        }
                    })
            }
            let rows = [...tableRows]
            let addIndex = (index + (beforeAfter? beforeAfter.value : 0))
            rows.splice(addIndex,0,row)
            setTableRows([...rows])
            let itemPayload = {
                ...item,
                textContent:textContent,
                topicContent: title,
                rows: [...rows]
            }
            saveWithoutPrompt(itemPayload)
            setSelectedIndex(null)
        }
    }

    const addTableColumn = (index) => {
        if(tableRows && saveWithoutPrompt){
            let col = {
                text: '-',
                id: uuidv4()
            }

            let array = tableRows.map((row) => {
                let cols = [...row.cols]
                cols.splice(selectedIndex.value, 0, col);
                row = {
                    ...row,
                    cols:[...cols]
                }
                return row
            })
            setTableRows([...array])
            let itemPayload = {
                ...item,
                textContent:textContent,
                topicContent: title,
                rows: [...array],
                selectedNumberOfCols: (item.selectedNumberOfCols + 1)

            }
            saveWithoutPrompt(itemPayload)
            setSelectedIndex(null)
        }
    }

    const removeTableRow = () => {
        let rows = [...tableRows]
        if(selectedIndex){
            rows.splice(selectedIndex.value, 1)
            setTableRows([...rows])
            let itemPayload = {
                ...item,
                textContent:textContent,
                topicContent: title,
                rows: rows
            }
            saveWithoutPrompt(itemPayload)
            setSelectedIndex(null)
            setRemoveRowModal(!removeRowModal)
        }
    }

    const removeTableColumn = () => {
        let rows = [...tableRows]
        let array = rows.map((row) => {
            let cols = [...row.cols]
            cols.splice(selectedIndex.value, 1);
            row = {
                ...row,
                cols:[...cols]
            }
            return row
        })

        setTableRows([...array])
        let itemPayload = {
            ...item,
            textContent:textContent,
            topicContent: title,
            selectedNumberOfCols: (item.selectedNumberOfCols -1),
            rows: [...array]
        }
        saveWithoutPrompt(itemPayload)
        setSelectedIndex(null)
        setRemoveColModal(!removeColModal)
    }

    const saveColSettings = () => {
        let itemPayload = {
            ...item,
            textContent:textContent,
            topicContent: title,
            rows:tableRows,
            customWidth: [...customWidth]
        }
        saveWithoutPrompt(itemPayload)

        setOpenColSettings(!openColSettings)
    }

    const getColSpan = (count, colIndex, maximumCount) => {
        if(count === 1)
            return maximumCount;
        else
            return (maximumCount % count === 1 && colIndex + 1 === count) ? maximumCount / count + 1 : maximumCount / count
    }

    const handleWidthChange = (props) => {
        const {index, value} = props
        let widths = [...customWidth]
        let altered = widths.map((width, i)=>{
            if(i===index){
                return value
            }
            else{
                return width
            }
        })
        setCustomWidth([...altered])  
    }

    const handleAdditionalTextField = () => {
        let payload = {
            ...item,
            showAdditionalTextField: !showAdditionalTextField
        }
        setShowAdditionalTextField(!showAdditionalTextField)
        saveWithoutPrompt(payload)
    }

    const toggleTableBorders = () => {
        let payload = {
            ...item,
            textContent:textContent,
            topicContent: title,
            rows: tableRows,
            borderless: !borderless
        }
        setBorderless(!borderless)
        promptSave(payload)
        saveWithoutPrompt(payload)
        
    }

    return (
        <>
            <Modal isOpen={removeRowModal} toggle={() => setRemoveRowModal(!removeRowModal)}>
                <ModalHeader>Poista taulukon rivi</ModalHeader>
                <ModalBody>
                    <p>Oletko varma, että haluat poistaa rivin <b>{selectedIndex && selectedIndex.label? selectedIndex.label : ""}</b>? <b>Poisto on pysyvä, eikä sitä voida peruuttaa!</b></p>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setRemoveRowModal(!removeRowModal)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => removeTableRow()} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                </ModalFooter>
            </Modal>
            <Modal isOpen={removeColModal} toggle={() => setRemoveColModal(!removeColModal)}>
                <ModalHeader>Poista taulukon sarake</ModalHeader>
                <ModalBody>
                    <p>Oletko varma, että haluat poistaa sarakkeen <b>{selectedIndex && selectedIndex.label? selectedIndex.label : ""}</b>? <b>Poisto on pysyvä, eikä sitä voida peruuttaa!</b></p>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setRemoveColModal(!removeColModal)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => removeTableColumn(selectedIndex)} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                </ModalFooter>
            </Modal>
            <Modal isOpen={openColSettings} size="lg" toggle={() => setOpenColSettings(!openColSettings)}>
                <ModalHeader>Muokkaa sarakkeiden kokoa</ModalHeader>
                <ModalBody>
                    <Table bordered hover style={{tableLayout: 'fixed'}}>
                        <thead>
                        </thead>
                        <tbody>
                            <tr key={0} height={"2.5em"}>
                                { item && item.rows && item.rows.map((row, index) => {
                                    if(index === 0){
                                        return(
                                            row.cols.map((col, i) => {
                                                return(
                                                    <td key={i} style={{verticalAlign: 'top'}} width={customWidth.length > 0? (customWidth[i]+"%"): ""} colSpan={getColSpan(row.cols.length,i,item.selectedNumberOfCols)}>
                                                        <TextEditor
                                                        editorId={i}
                                                        content={"-"}
                                                        additionalClass="plan-input"
                                                        readOnly={true}
                                                        />
                                                    </td>
                                                )
                                            })
                                        )
                                    }
                                })}
                            </tr>
                        </tbody>
                    </Table>
                    <Label>Anna sarakkeiden koko prosentteina (esim 25)</Label>
                    <div style={{ display:'flex', flexDirection:'row', marginTop: '0.25em'}}>
                        {item && item.rows && item.rows.map((row, index) => {
                            if(index === 0){
                                return(
                                    row.cols.map((col, i) => {
                                        if(i !== row.cols.length-1){
                                            return(
                                                <div key={i} style={{width:((100/row.cols.length) + "%")}}>
                                                    <Input type="number" value={customWidth[i]?customWidth[i]:""} onChange={(e)=> handleWidthChange({index:i,value:e.target.value})} />
                                                </div>
                                            )
                                        } else {
                                            return(
                                                <div key={i} style={{width:((100/row.cols.length) + "%")}}>
                                                    <Input readOnly value={lastCol? (lastCol) :""}/>
                                                </div>
                                            )
                                        }
                                    })
                                )
                            }
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setOpenColSettings(!openColSettings)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => saveColSettings()} className="small-white-button wide-button delete-plan">Tallenna</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={tableEditModal} toggle={()=>setTableEditModal(!tableEditModal)}>
                <ModalHeader toggle={()=>setTableEditModal(!tableEditModal)}>Muokkaa taulukon rakennetta</ModalHeader>
                <ModalBody>
                    <Label style={{ }}>Lisää vai poista</Label>
                    <Select
                        value={addRemove}
                        onChange={(selected) => setAddremove(selected)}
                        options={addRemoveList}
                        placeholder="Valitse lisäys / poisto"
                        isDisabled={false}
                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                        className="companyTargets"
                        classNamePrefix="select"
                    />
                    <Label style={{ marginTop:'1em' }}>Rivi vai sarake</Label>
                    <Select
                        value={selectedType}
                        onChange={(selected) => setSelectedType(selected)}
                        options={types}
                        placeholder="Valitse rivi / sarake"
                        isDisabled={false}
                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                        className="companyTargets"
                        classNamePrefix="select"
                    />
                    <Label style={{ marginTop:'1em' }}>Valitse indeksi</Label>
                    <Select
                        value={selectedIndex}
                        onChange={(selected) => setSelectedIndex(selected)}
                        options={indexList}
                        placeholder="Valitse indeksi"
                        isDisabled={false}
                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                        className="companyTargets"
                        classNamePrefix="select"
                    />
                    {addRemove && addRemove.value === 1 && tableRows.length > 0 &&
                        <>
                            <Label style={{ marginTop:'1em' }}>Ennen vai jälkeen</Label>
                            <Select
                                value={beforeAfter}
                                onChange={(selected) => setBeforeAfter(selected)}
                                options={beforeAfterList}
                                placeholder="Valitse ennen / jälkeen"
                                isDisabled={false}
                                noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                className="companyTargets"
                                classNamePrefix="select"
                            />
                        </>
                    }
                    {addRemove && ((addRemove.value === 1 && (beforeAfter || tableRows.length === 0)) || addRemove.value === 2) && selectedIndex && selectedType && 
                        <div style={{ marginTop:'1.5em' }}>
                            {addRemove.value === 1 ? 
                                ((selectedType.value === 1 && (beforeAfter || tableRows.length === 0)) ?
                                    <button onClick={() => addTableRow(selectedIndex.value)} style={{ width:'100%' }} className="small-white-button wide-button">Lisää rivi</button>
                                    :
                                    <button onClick={() => addTableColumn(selectedIndex.value)}  style={{ width:'100%' }} className="small-white-button wide-button">Lisää sarake</button>
                                )
                                :
                                (selectedType.value === 1?
                                    <button onClick={() => setRemoveRowModal(true)}  style={{ width:'100%' }} className="small-white-button wide-button">Poista rivi</button>
                                    :
                                    <button onClick={() => setRemoveColModal(true)}  style={{ width:'100%' }} className="small-white-button wide-button">Poista sarake</button>    
                                )
                            }
                        </ div>
                    }
                </ModalBody>
                <ModalFooter>
                    <a onClick={()=>setTableEditModal(!tableEditModal)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Poistu</a>
                </ModalFooter>
            </Modal>
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                <Col xl="12">
                    {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red'}}>Yksityinen</span>}
                    {(!editable && !layoutPreview && item.modified !== undefined) && 
                        <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                    }
                </Col>
                <Col xl="12" style={{marginBottom: 8}} className="title-col">
                    <div style={{ display: 'flex' }}>
                        {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', display:'flex', alignSelf: 'center'}}>{index + 1}<i className="fas fa-table plan-input subTitle" style={{ marginLeft: '0.5em', alignSelf: 'center'}}></i></div>}            
                        <TextEditor 
                            editorId={`${item.id}-subtitle-${editable}`} 
                            content={item.topicContent}
                            onTextChange={editorState => handleDataChanges("title", editorState)}
                            additionalClass="plan-input subTitle plan-subtitle"
                            readOnly={!editable}
                            placeholder="-"
                            plaintextEditor={true}
                        />
                    </div>
                </Col>
                {showAdditionalTextField &&
                    <Col xl={"11"} style={{ marginTop: '1.5em' }}>
                        <TextEditor 
                            editorId={`${item.id}-${editable}`} 
                            content={textContent} 
                            onTextChange={editorState => handleDataChanges('content', editorState)}
                            additionalClass="plan-input"
                            placeholder="Kirjoita tähän"
                            readOnly={!editable}
                            documentId={planId}
                            connectionType={"plans"}
                        />
                    </Col>
                }
                <Col xl={"11"} style={{ marginTop: '1.5em' }}>
                    <TableItem table={item} tableRows={tableRows} setTableRows={setTableRows} promptSave={promptSave} editable={editable} title={title} saveWithoutPrompt={saveWithoutPrompt} planId={planId} textContent={textContent} borderless={borderless}/>
                    {editable && 
                        <div>
                            <button className='add-table-row-button small-white-button' title="Muokkaa taulukon rakennetta" onClick={()=> setTableEditModal(true)}>
                                <i className="fas fa-plus"></i>/
                                <i className="fas fa-minus"></i>
                            </button>
                            <button className="small-white-button add-table-row-button" style={{marginLeft:'0.5em'}} title="Lisää tyhjän rivin taulukon loppuun" onClick={()=> addTableRow(tableRows.length)}>Lisää tyhjä rivi loppuun</button>
                        </div>
                    }
                </Col>
                <Col xl="1" style={{paddingLeft: 15, marginBottom: 8}} className="plan-dropdown-col">
                    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                    {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                        <DropdownToggle tag="div">
                            <i className="fas fa-bars plusIcon" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                            <DropdownItem onClick={() => handleAdditionalTextField()} className="delete-plan-item">{showAdditionalTextField?'Piilota tekstialue' :'Näytä tekstialue'}</DropdownItem>
                            <DropdownItem onClick={() => toggleTableBorders()} className="add-template">{borderless? "Näytä taulukon reunat julkaistussa" : "Piilota taulukon reunat julkaistussa" }</DropdownItem>
                            <DropdownItem onClick={() => setOpenColSettings(true)} className="add-template">Muokkaa sarakkeiden kokoa</DropdownItem>
                            <DropdownItem onClick={() => addTableTemplate(item)} className="add-template">Lisää pohjaksi</DropdownItem>
                            <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                    </div>
                </Col>
                
            </Row>
        </>
    )
}

const TableItem = ({table, tableRows, setTableRows, promptSave, editable, title, saveWithoutPrompt, planId, borderless, textContent }) => {
    const [tableFields, setTableFields] = useState(table);
    const rows = tableRows;

    const handleTableData = (rowData) => {
        //const {rows} = table
        if(editable){
            rows.splice(rowData.rowIndex, 1, {cols: rowData.cols})
            let payload = {
                ...table,
                textContent:textContent,
                topicContent: title,
                rows: rows
            }
            setTableRows(rows)
        
            promptSave(payload);
        }
    }

    return (
        <Table bordered hover style={{tableLayout: 'fixed'}}>
            <thead>
            </thead>
            <tbody>
                {rows && rows.map((row, rowIndex) => {
                    return(
                        <TableRowContainer row={row} rowIndex={rowIndex} handleTableData={ handleTableData } saveWithoutPrompt={saveWithoutPrompt} item={table} editable={editable} maximumCount={table.selectedNumberOfCols} key={rowIndex} planId={planId}/>
                )})}
            </tbody>
        </Table>
    )
}

const TableRowContainer = ({row, rowIndex, handleTableData, editable, maximumCount, item, planId }) => {
    const { cols } = row
    const {customWidth} = item
    const handleRowChange = colData => {
        const { cols } = row
        let newCols = cols.map(col => {
            if(col.id === colData.id) {
                return colData
            }

            return col
        })

        handleTableData({
            cols: newCols,
            rowIndex: rowIndex
        })
    }

    return (
        <tr key={rowIndex}>   
            {cols.map((col, index) =>
                <ColumnEditor 
                    col={col} 
                    key={col.id} 
                    editorId={col.id} 
                    rowIndex={rowIndex} 
                    handleRowChange={handleRowChange} 
                    editable={editable} 
                    count={cols.length}
                    maximumCount={maximumCount}
                    colIndex={index}
                    customWidth={(customWidth && customWidth[index])? (customWidth[index] + "%"):"" }
                    planId={planId}
                />
            )}
        </tr>
    )
}

const ColumnEditor = ({col, editorId, handleRowChange, editable, maximumCount, count, colIndex, customWidth, planId}) => {
    const getColSpan = () => {
        if(count === 1)
            return maximumCount;
        else
            return (maximumCount % count === 1 && colIndex + 1 === count) ? maximumCount / count + 1 : maximumCount / count
    }

   

    return (
        <td style={{verticalAlign: 'top', backgroundColor: col.color? col.color : "#ffff" }} width={customWidth} colSpan={getColSpan()}>
            <TextEditor
                editorId={editorId}
                content={col.text}
                onTextChange={editorState => handleRowChange({...col, text: editorState})}
                additionalClass="plan-input"
                readOnly={!editable}
                backgroundColor={col.color? col.color: "#ffff"}
                changeBackgroundColor={color => handleRowChange({...col, color: color})}
                connectionType={"plans"}
                documentId={planId}
            />
        </td>
    )
}


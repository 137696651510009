import React, {useState, useRef, useEffect} from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import TaskItem from './TaskItem';
import RiskItem from './RiskItem';
import {ModifiedContainer} from './DataContainers';
import { useSelector } from 'react-redux';
import { OpenAIBlock } from '../../../Utilities/OpenAI/OpenAIBlock';
import { NotificationManager } from 'react-notifications';

const TopicAndDraft = (
    {
        item, 
        editable, 
        promptSave, 
        startAddingTask, 
        modifyTask, 
        startRiskAssesment, 
        riskList, 
        taskList, 
        onTemplateAdd, 
        index, 
        onDelete, 
        toggleItemVisibility, 
        modulesInUse, 
        variables,
        planId,
        toggleDrag,
        updatePrompts,
        enterprisePrompts,
        layoutPreview = false
    }) => {

    const [title, setTitle] = useState(item.topicContent);
    const [content, setContent] = useState(item.textContent);
    const [summaryItem, setSummaryItem] = useState(item.summaryItem)
    const [openMenu, toggleMenu] = useState(false);
    const [openTasks, toggleTasks] = useState(false);
    const [openRisks, toggleRisks] = useState(false);
    const [showAIContent, setShowAIContent] = useState(false)
    const [forceUpdate, setForceUpdate] = useState(false)
    const openAIKey = useSelector(state => state.authentication.openAIKey? state.authentication.openAIKey:false)
    const OpenAIref = useRef()
    const editorRef = useRef()

    function handleDataChanges(type, data) {
        
        if(type === 'draft') {
            setContent(data);
            let itemPayload = {
                id: item.id,
                textContent: data,
                topicContent: title,
                type: item.type
            }
            promptSave(itemPayload);

        } else if(type === 'title') {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                textContent: content,
                type: item.type
            }
            promptSave(itemPayload);
        } else {
            setSummaryItem(data);
            let itemPayload = {
                id: item.id,
                topicContent: title,
                textContent: content,
                summaryItem: data,
                type: item.type
            }
            promptSave(itemPayload);
        }
    }

    const AITextToPlan = (text) => {
        handleForceUpdate()
        setContent(text)
        handleDataChanges('draft', text)
        NotificationManager.success("AI-vastaus siirretty kappaleeseen","",3000)
    }

    const handleForceUpdate = () => {
        setForceUpdate(true)
    }

    const executeScroll = (visible) => { 
        if(visible){
            OpenAIref.current.scrollIntoView({behavior:'smooth'}) 
        } 
    }

    const handleAIContentToggle = () => {
        editorRef.current.scrollIntoView({behavior:'smooth'})
        setShowAIContent(!showAIContent)
    }

    return (
        <div ref={editorRef}>          
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                <Col xl="12">
                    {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red' }}>Yksityinen</span>}
                    {(!editable && !layoutPreview && item.modified !== undefined) && 
                        <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                    }
                </Col>
                <Col xl="12" style={{marginBottom: 8}} className="title-col">
                    <div style={{ display: 'flex' }}>
                       {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}</div>} 
                        <TextEditor 
                            editorId={`${item.id}-subtitle-${editable}`} 
                            content={title} 
                            onTextChange={editorState => handleDataChanges("title", editorState)}
                            additionalClass="plan-input subTitle plan-subtitle"
                            placeholder="-"
                            readOnly={!editable}
                            plaintextEditor={true}
                            suggestions={variables}
                            needToForceUpdate={forceUpdate}
                            setForceUpdate={setForceUpdate}
                        /> 
                        {item.answered && <div className="plan-input subTitle plan-subtitle">{item.answered}</div>}     
                    </div>
                </Col>
                <Col xl="11" style={{paddingTop: '0.8em'}} className="plan-text-col">
                    <TextEditor 
                        editorId={`${item.id}-${editable}`} 
                        content={content} 
                        onTextChange={editorState => handleDataChanges('draft', editorState)}
                        additionalClass="plan-input"
                        readOnly={!editable}
                        suggestions={variables}
                        connectionType={"plans"}
                        documentId={planId}
                        needToForceUpdate={forceUpdate}
                        setForceUpdate={setForceUpdate}
                    />
                </Col>
                <Col xl="1" style={{paddingLeft: 15}} className="plan-dropdown-col">
                    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                    {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                        <DropdownToggle tag="div">
                            <i className="fas fa-bars plusIcon" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            {/* {(modulesInUse.betaTesting && openAIKey) && <DropdownItem onClick={() => setAIModalOpen(true)}>Avaa OpenAI-modaali</DropdownItem>} */}
                            {openAIKey && <DropdownItem onClick={() =>( setShowAIContent(!showAIContent), toggleDrag(showAIContent), executeScroll(!showAIContent))}>{showAIContent? 'Piilota OpenAI-lohko' :'Näytä OpenAI-lohko'}</DropdownItem>}
                            { modulesInUse.taskManagement && <DropdownItem onClick={() => startAddingTask(item)} className="add-task">Lisää tarkistuspiste</DropdownItem> }
                            { modulesInUse.riskManagement && <DropdownItem onClick={() => startRiskAssesment(item)} className="add-risk">Lisää riski</DropdownItem> }
                            <DropdownItem onClick={() => onTemplateAdd({planItem: item})} className="add-template">Lisää pohjaksi</DropdownItem>
                            <DropdownItem onClick={() => onTemplateAdd({planItem: item, type: 'new-custom-block'})} className="add-customblock">Lisää päivitysmalliksi</DropdownItem>
                            <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                            <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                    </div>
                </Col>
                {((taskList !== undefined && taskList.length > 0 ) || (riskList !== undefined && riskList.length > 0)) && 
                    <Col xl="12">

                        <div style={{height: '100%', width: 90, display: 'flex', justifyContent: 'space-between', paddingTop: '1em', paddingBottom: '0.5em'}}>
                            
                            { ( modulesInUse.taskManagement && taskList !== undefined && taskList.length > 0 ) && 
                            <i className="fas fa-tasks plan-task-icon" onClick={() => toggleTasks(!openTasks)} style={openTasks ? {border: '1px solid #B5E413', backgroundColor: '#B5E413', color: '#FFF', padding: 10, borderRadius: '50%', cursor: 'pointer'} : {border: '1px solid #404040', padding: 10, borderRadius: '50%', cursor: 'pointer'}}></i>}

                            { ( modulesInUse.riskManagement && riskList !== undefined && riskList.length > 0 ) && 
                            <i className="fas fa-exclamation plan-task-icon" onClick={() => toggleRisks(!openRisks)} style={openRisks ? {border: '1px solid #B5E413', padding: 10, paddingLeft: 15, paddingRight: 15, backgroundColor: '#B5E413', color: '#FFF', borderRadius: '50%', cursor: 'pointer'} 
                            : {border: '1px solid #404040', padding: 10, paddingLeft: 15, paddingRight: 15, borderRadius: '50%', cursor: 'pointer'}}></i>}

                        </div>

                    </Col>
                }
                { modulesInUse.taskManagement && (
                    openTasks &&
                    taskList.map( taskItem =>
                        <TaskItem text={ taskItem.taskTitle } key={ taskItem._id } deadline={ taskItem.deadline } modifyTask={ modifyTask } taskItem={ taskItem } topicContent={ item.topicContent } />
                    )
                ) }
                { modulesInUse.riskManagement && (
                    openRisks &&
                    riskList.map( riskItem =>
                        <RiskItem riskItem={ riskItem } item={ item } modifyRisk={ startRiskAssesment } key={ riskItem._id } />
                    )
                ) }
                <Col xs="12">
                    {openAIKey && 
                        <div ref={OpenAIref}>
                            <OpenAIBlock apiKey={openAIKey} text={content} textToPlan={AITextToPlan} showAIContent={showAIContent} toggleAIContent={handleAIContentToggle} updatePrompts={updatePrompts} enterprisePrompts={enterprisePrompts}/>
                        </div>
                    }   
                </Col>
            </Row>
        </div>    
    )
}

export default TopicAndDraft;
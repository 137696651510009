import React, {useState} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap'

export const DeleteModal = ({isOpen, toggleModal, handleDelete, title, targetText}) => {
    const [deleteText, setDeleteText] = useState("")

    const toggleDeleteModal = () => {
        setDeleteText("")
        toggleModal()
    }

    const deleteCheck = () => {
        if(deleteText === "poista"){
            setDeleteText("")
            handleDelete()
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={() => toggleDeleteModal()} centered>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <p>Oletko varma, että haluat poistaa {targetText}? <b>Toimintoa ei voi peruuttaa!</b></p>
                <div style={{ marginTop: '1em' }}>
                    <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                    <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                    <div className="email-check-box">
                        {deleteText === "poista"?
                            <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                            :
                            <i className="fas fa-times" style={{ color: 'red' }}></i> 
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <a onClick={() => toggleDeleteModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                <button onClick={() => deleteCheck()} className="small-white-button wide-button swb-hover delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
            </ModalFooter>
        </Modal>
    ) 
}

export const CopyModal = ({isOpen, toggleModal, target, title}) => {
    return (
        <Modal isOpen={isOpen} toggle={toggleModal} size="lg" centered>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                {/*<NewPlanTarget copy={true} onCopy={this.handleCopy} selectedData={this.state.selectedData} cleanUp={this.cleanUp} />*/}
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={this.toggleModal}>Peruuta</a>
            </ModalFooter>
        </Modal>
    )
}
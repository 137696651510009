import React, { useState, useEffect, useRef } from 'react';
import { Input, Modal, ModalHeader, ModalFooter, ModalBody, Col, Row } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import BackgroundEditor from '../../Kartoitus/SenderV2/subComponents/BackgroundEditor';
import MultiSelect from "@kenshooui/react-multi-select";
import '../project.css';
import { debounce } from 'debounce';

const INITIAL_STATE_SURVEY = {
    name: "",
    targets: [],
    startDate: null,
    endDate: null,
    surveys: [],
    startSending: true,
    dates: [],
    background: null,
    backgroundSet: false,
    hideMenu: false,
    //resetBackground: true
}
const customMessages = {
    messages: {
        searchPlaceholder: "Etsi kartoitusta...",
        noItemsMessage: "Valitse kartoitus",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
};

export const ActivateSurveyModal = (props) => {    
    const {clientList, getProjectSurveys, targetBusiness} = props
    const [focusedDateInput, setFocusedDateInput] = useState()
    const [survey, setSurvey] = useState(INITIAL_STATE_SURVEY)
    const [showDates, setShowDates] = useState(false)
    const [availableSurveys, setAvailableSurveys] = useState()
    const [selectedDate, setselectedDate] = useState({startDate:null,endDate:null})
    const [background, setBackGround] = useState()
    const [hideMenu, setHideMenu] = useState(false)
    const [resetBackground, setResetBackground] = useState(false)
    const [editBackground, setEditBackGround] = useState(false)
    const [backgroundList, setBackgroundList] = useState([])
    const [saved, setSaved] = useState(false)

    useEffect(()=>{
        if(targetBusiness){
            handleSurveyChange({target:{id:"targets", value:targetBusiness}})
        }
    },[targetBusiness])

    const handleSurveyChange = (event) => {
        const { id, value } = event.target
        if(id === "targets"){
            setSurvey(prevData => ({
                ...prevData,
                [id]: [value]
            }))
        } else {
            setSurvey(prevData => ({
                ...prevData,
                [id]: value
            }))
            if(id=="name"){
                afterSurveySelection()
            }
        }
    }

    const setDates = (date) => {
        setselectedDate(date)
       
        if (date.endDate !== null && date.startDate !== null) {
            setSurvey(prevData => ({
                ...prevData,
                startDate: null,
                endDate: null,
                dates:[...prevData.dates, date]
                
            }))
            setselectedDate({startDate:null, endDate:null})
        }       
    }

    const removeDateFromList = (index) => {
        let obj = [...survey.dates]
        obj.splice(index, 1)
        setSurvey(prevData =>({ 
            ...prevData,
            dates: obj 
        }))
    }

    const  isDayBlocked = (day) => {
        let compare = moment(new Date(day._d))

        for (let date of survey.dates) {
            let start = moment(new Date(date.startDate._d))
            let end = moment(new Date(date.endDate._d))
            if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                 return true;
            }
        }
        return false;

    }

    useEffect(() => {           
        const getSurveys = async () => {
            const result = await executeRequest('sender/get/availableSurveys', {})
            if(!!result) {
                setAvailableSurveys(result)
            }
        }
        getSurveys()
    }, [])

    const activateSurvey = () => {
        let obj = survey
        if (background) {
            obj = { ...obj, background: background }
        }

        if (hideMenu) {
            obj = { ...obj, hideMenu: hideMenu };
        }

        saveSurvey(obj)

        setSurvey(INITIAL_STATE_SURVEY)
        setResetBackground(true)
        props.toggleModal(false)

    }
 
    const saveSurvey = async(obj)=>{
        
        let params = {
            ...obj,
            projectId:props.projectId
        }

       // delete params.survey.startDate
       // delete params.survey.endDate

        const result = await executeRequest('project/activateSurvey', params)
        getProjectSurveys(props.projectId)
        clearSurveyData()
    }

    const handleSurveySelection = selectedItems => {
        setSurvey(prevData => ({
            ...prevData,
            surveys: selectedItems,
        }))
        afterSurveySelection()
    }

    const handleBGChange = (bgSettings) =>{
        setSurvey((prevData)=>({
            ...prevData,
            backgroundSet:bgSettings.backgroundSet,
            background: bgSettings.background 
        }))
        afterSurveySelection()
    }

    useEffect(()=>{
        const getBackgrounds = async() => {
            const result = await executeRequest('sender/get/backgroundList', {})       
            if(result){
                setBackgroundList(result)
            }
        }
        getBackgrounds()
    },[])

    const saveBackground = (obj) => {
        setSurvey((prevData)=>({
            ...prevData,
            background: obj, 
            backgroundSet: true
        }))
        setEditBackGround(false)
    }

    const saveModifiedBackground = async(obj) => {
        let payload = {
            sentSurvey: survey.backgroundEdit,
            background: obj
        }
        const result = await executeRequest('sender/modify/background', payload)
        setSurvey((prevData)=>({
            ...prevData,
            backgroundEdit: null, 
            background: null
        }))
        setEditBackGround(false)
    }

    const uploadBackground = async(payload) => {
        const response = await executeRequest('sender/uploadBackground',payload)
    }
    const senderRef = useRef();

    const afterSurveySelection = debounce(() => {
        scrollToMyRef();
    }, 800);

    const scrollToMyRef = () => {   
        if(senderRef && senderRef.current){
            senderRef.current.parentElement.scroll({ top: senderRef.current.offsetTop, left: 0, behavior: 'smooth' })
        } else {
            var elem = document.getElementById("ref")
            elem.scrollIntoView();
        }
    }

    const clearSurveyData = () => {
        setSurvey(INITIAL_STATE_SURVEY)
    }
   
    return(
        <>
            {!resetBackground && <BackgroundEditor
                isOpen={editBackground}
                toggle={setEditBackGround}
                background={survey.background}
                list={backgroundList}
                save={survey.backgroundEdit ? saveModifiedBackground : saveBackground}
                uploadBackground={uploadBackground}
            />}
            <Modal isOpen={props.isOpen} size="lg">
                <ModalHeader toggle={() => (props.toggleModal(false), clearSurveyData())}>
                    <p>Aktivoi kartoitus</p>
                </ModalHeader>
                <ModalBody >
                    <Col xl="12" className={clientList.length > 0 ? "senderContainer magic" : "senderContainer"}>
                        <Row>
                            <Col xl="12">
                                <p className="senderSteps">1. Valitse kohdeyritys</p>
                                <Row className="companySelector magic">
                                    <Select 
                                        options={clientList} 
                                        onChange={(val)=> handleSurveyChange({target:{ id: "targets", value: [...val]}})}
                                        value={survey.targets[0]}
                                        isDisabled={false}
                                        noOptionsMessage={() => { return "Yrityksiä ei löytynyt" }}
                                        className="survey-selector"
                                        classNamePrefix="select"
                                    />
                                </Row>
                            </Col>
                            <Col xl="12" className={Object.keys(survey.targets).length !== 0 ? "selector-visible" : "selector"} >
                                <div className={Object.keys(survey.targets).length !== 0 ? "companySelector magic" : "companySelector"}>
                                    <p className="senderSteps">2. Valitse aktiivinen aikaväli</p>
                                    <Row>
                                        <Col>
                                            {survey.dates.length > 0 && survey.dates.map((dateobj, ind) => {
                                                let start = moment(new Date(dateobj.startDate)).format('DD.MM.YYYY')
                                                let end = moment(new Date(dateobj.endDate)).format('DD.MM.YYYY')
                                                let oddEvenClass = ind % 2 === 0 ? 'even-row' : 'odd-row';

                                                return <Row key={ind} className={"modify-dates-list " + oddEvenClass} >
                                                    <Col md="11">{start} - {end}</Col>
                                                    {survey.dates.length > 0 &&
                                                        <Col md="1" onClick={() => removeDateFromList(ind)}><i className="far fa-trash-alt"></i></Col>
                                                    }
                                                </Row>
                                            })

                                            }
                                        </Col>
                                        <Col xs="12" style={{ marginTop: "0.5em" }}>
                                            <DateRangePicker
                                                startDate={selectedDate.startDate} // momentPropTypes.momentObj or null,
                                                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                                endDate={selectedDate.endDate} // momentPropTypes.momentObj or null,
                                                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                                onDatesChange={({ startDate, endDate }) => setDates({ startDate, endDate })} // PropTypes.func.isRequired,
                                                focusedInput={focusedDateInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                onFocusChange={focusedInput => setFocusedDateInput(focusedInput)} // PropTypes.func.isRequired,
                                                appendToBody={false}
                                                block
                                                hideKeyboardShortcutsPanel={true}
                                                isDayBlocked={isDayBlocked}
                                                readOnly
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xl="12" className={survey.dates.length > 0 ? "selector-visible" : "selector"}>
                                <div className={survey.dates.length > 0 !== null ? "companySelector magic" : "companySelector"}>
                                    <p className="senderSteps">3. Anna kartoitukselle nimi</p>
                                    <Row>
                                        <Col>
                                        <Input id="name" className="survey-name" value={survey.name} onChange={(e)=> handleSurveyChange(e)}></Input>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col xl="12" className={survey.name !== "" ? "selector-visible" : "selector"}>
                                <div className={survey.name !== "" ? "companySelector magic" : "companySelector"}>
                                    <p className="senderSteps">4. Aktivoitavat kartoitukset</p>
                                    <Row>
                                        {/*
                                <SurveySelector {...this.state} surveyOptions={this.props.availableSurveys} 
                                filterOptions={this.filterSurveyOptions} handleSurveySelection={this.handleSurveySelection}/>*/}
                                        <Col xl="12">
                                            {survey.name !== "" &&
                                                 <MultiSelect
                                                    messages={customMessages.messages}
                                                    items={availableSurveys}
                                                    selectedItems={survey.surveys}
                                                    onChange={handleSurveySelection}
                                                    wrapperClassName="multi-selector"
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col xl="12" className={survey.surveys.length > 0 ? "selector-visible" : "selector"}>
                                <div className={survey.surveys.length > 0 ? "companySelector magic" : "companySelector"}>
                                    <p className="senderSteps">5. Valitse taustakuva</p>
                                    <Row>
                                        <Col xl="12" style={{ marginBottom: '1em' }}>
                                            <button className={`small-white-button wide-button lighter-text ${!survey.background && survey.backgroundSet && 'active-button'}`} onClick={() =>  handleBGChange({ backgroundSet: true, background: null })} style={{ marginRight: '1em' }}>Oletus taustakuva</button>
                                            <button className={`small-white-button wide-button lighter-text ${survey.background && survey.backgroundSet && 'active-button'}`} onClick={() => (setEditBackGround(!editBackground), setResetBackground(false))} style={{ marginRight: '1em' }}>Mukautettu</button>
                                            <button className={`small-white-button wide-button lighter-text ${hideMenu && 'active-button'}`} onClick={() => setHideMenu(!hideMenu)}>Piilota menu</button>
                                        </Col>
                                    </Row>
                                </div>

                            </Col>

                            <Col xl="12" className={survey.backgroundSet ? "selector-visible" : "selector"}>
                                <div className={survey.backgroundSet > 0 ? "companySelector magic" : "companySelector"}>
                                    <p className="senderSteps">6. Aktivoi kartoitus</p>
                                    <Row>
                                        <Col xl="12" className="surveyButton">
                                            <button className="small-white-button lighter-text" style={{ marginBottom: '1rem', width: '100%' }} onClick={() => activateSurvey()}>Aktivoi kartoitus</button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <div id="ref" ref={senderRef}></div>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => (props.toggleModal(false), clearSurveyData())}>Peruuta</a>
                </ModalFooter>
            </Modal>
        </>
    ) 
}

export const SurveyModal = (props) => {
    const [surveyList, setSurveyList] = useState()
    const {selectedSurveys, setSelectedSurveys, saveSelectedSurveys, projectId} = props
    
    useEffect(()=>{
        if(props.modal){
            const getSurveysForModal = async() => {
                let params = ({
                    selectedBusiness: props.selectedBusiness,
                    projectId:projectId
                })
                const result = await executeRequest('project/surveysForModal', params)
                if(result){
                    setSurveyList(result)
                }
            }
            getSurveysForModal()
        }
    },[props.modal])    

    const handleSurveySelection = selectedItems => {
        setSelectedSurveys(selectedItems)   
    }

    const handleToggle = () => {  
        saveSelectedSurveys()
        props.toggle(false)
    } 

    return (
        <Modal isOpen={props.modal} toggle={() => props.toggle(false)} centered size="lg">
                <ModalHeader className="taskModal" toggle={() => props.toggle(false)}>
                    <div>
                        Valitse lomakkeeseen liitettävät kartoitukset
                    </div> 
                </ModalHeader>
                <ModalBody>
                    <Row className="taskCreatorRow">
                        <Col xl="12">
                            <MultiSelect
                                messages={customMessages.messages}
                                items={surveyList}
                                selectedItems={selectedSurveys}
                                onChange={handleSurveySelection}
                                showSelectAll={false}
                                showSelectedItems={false}
                                wrapperClassName="multi-selector"
                                withGrouping
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="taskCreatorFooter">
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => props.toggle(false)}>Peruuta</a>
                    <button className="small-white-button wide-button" style={{ }} onClick={() => handleToggle()}>Tallenna</button>
                </ModalFooter>
            </Modal>
    )
}

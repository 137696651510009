import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Label, Input, FormGroup, Form } from 'reactstrap';
import moment from 'moment'
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications'
import Select from 'react-select'
import {SingleDatePicker} from 'react-dates'
import * as ActionCreator from '../Actions/ActionCreator';
import { useDispatch } from 'react-redux';
import * as BaseActions from '../../../store/index'
import { confs } from '../../../modules/config';

const INITIAL_PROPS = {
    amount: 0,
    closedate: null,
    dealname: "",
    dealstage: null,
    hubspot_owner_id: "",
    pipeline: null
}

const INITIAL_CONTACT = {
    name: "",
    email: "",
    phone: "",
    role: "",
    tags:[]
}


export const CreateHubSpotDealModal = ({modalOpen, setModal, hubSpotInfo, plan}) => {
    const [selectedPipeline, setSelectedPipeline] = useState(null)
    const [pipelines, setPipelines] = useState([])
    const [dealStages,setDealStages] = useState([])
    const [properties, setProperties] = useState(INITIAL_PROPS)
    const [userList, setUserList] = useState([])
    const [selectedOwner, setSelectedOwner] = useState(null)
    const [chosenStage, setChosenStage] = useState(null)
    const [dateFocused, setDateFocused] = useState(false)
    const [closeDate, setCloseDate] = useState(null)
    const [hubSpotCompanyId, setHubSpotCompanyId] = useState(null)
    const [existingCompanyId, setExistingCompanyId] = useState(false)
    const [hubSpotContactList, setHubSpotContactList] = useState([])
    const [selectedContact, setSelectedContact] = useState(null)
    const [pipelinesLoading, setPipelinesLoading] = useState(false)

    const dispatch = useDispatch()

    const toggleModal = () => {
        setModal(!modalOpen)
    }

    useEffect(()=>{
        if(plan && plan.selectedBusiness && plan.selectedBusiness.value !== "anon"){
            handleChange({id:"dealname", value:plan.planName})
            getHubSpotCompanyId()
            
        }
    },[plan])

    useEffect(()=>{
        if(hubSpotCompanyId){
            getPipelines()
            getHubSpotUsers()
            getHubSpotContacts()
        }
    },[hubSpotCompanyId])

    const getHubSpotCompanyId = async() => {
        let payload = {
            businessId: plan.selectedBusiness.value
        }
        const response = await executeRequest("hubspot/get/hubSpotCompanyId", payload)
        if(response.hsCompanyId){
            setHubSpotCompanyId(response.hsCompanyId)
            setExistingCompanyId(true)
        }
    }

    const getHubSpotContacts = async() => {
        let payload = {
            businessId: plan.selectedBusiness.value,
            hsCompanyId: hubSpotCompanyId
        }
        const response = await executeRequest("hubspot/get/hubSpotContacts", payload)
        if(response.contactList){
            setHubSpotContactList(response.contactList)
        }else {
            NotificationManager.error("Virhe hakiessa HubSpot kontakteja","Virhe",3000)
        }
    }

    const getPipelines = async() => {
        setPipelinesLoading(true)
        let payload = {
            businessId: plan.selectedBusiness.value
        }
        const response = await executeRequest("plan/getHubSpotDeals", payload)
        
        if(response.pipelines){
            let array = response.pipelines.map((pipe)=>{
                pipe = {
                    ...pipe,
                    value: pipe.id
                }
                return(
                    pipe
                )
            })
            setPipelines(array)
            setPipelinesLoading(false)
        } else {
            if(!response.noKey){
                NotificationManager.error("Virhe hakiessa HubSpot dataa","Virhe",4000)
            }
        }
    }

    const handlePipelineChange = (pipeline) => {
        setSelectedPipeline(pipeline)
        let dealstages = pipeline.stages.map((stage)=>{
            stage = {
                ...stage,
                value:stage.id
            }
            return(stage)
        })
        setDealStages(dealstages)
        handleChange({id:"pipeline", value: pipeline.id})
    }

    const handleOwnerChange = (owner) => {
        setSelectedOwner(owner)
        //SET owner id to properties
        handleChange({id:"hubspot_owner_id",value: owner.value})
    }

    const handleChange = (props) => {
        const {id, value} = props
        setProperties({
            ...properties,
            [id]:value
        })
    }

    const handleStageChange = (stage) => {
        setChosenStage(stage)
        handleChange({id:'dealstage', value:stage.id})
    }

    const handleCloseDate = (date) => {
        setCloseDate(date)
        let ISODate = date.toISOString()
        handleChange({id:"closedate",value: ISODate})
    }

    const getHubSpotUsers = async() => {
        let payload = {}
        const response = await executeRequest("hubspot/get/users", payload)
        if(response.users){
            setUserList([...response.users])
        } else {
            NotificationManager.error("Virhe hakiessa HubSpot käyttäjiä","Virhe",3000)
        }
    }

    const getStageLabel = (selectedDeal) => {
        const { pipeline, dealstage } = selectedDeal;
        let stageLabel = "Tuntematon";

        if (pipeline !== undefined && pipeline === selectedPipeline.id) {
            if (selectedPipeline.stages) {
                const { stages } = selectedPipeline;

                stages.map(stage => {
                    if (dealstage === stage.id) {
                        stageLabel = stage.label;
                    }
                })
            }
        }

        return stageLabel
    }

    const handleSave = async() => {
        if(hubSpotCompanyId.length > 0 && properties.dealname.length > 0 && properties.hubspot_owner_id.length > 0){

            let payload = {
                properties: properties,
                hsCompanyId: hubSpotCompanyId,
                contactId: selectedContact.value,
                companyIdExists:existingCompanyId,
                businessId: plan.selectedBusiness.value
            }
            let item = {
                id:'HubSpot',
                topicContent:'HubSpot Deal',
                selectedDeal:{},
                stageLabel:"",
                visible:false,
                summaryItem:"1."
            } 

            const response = await executeRequest('hubspot/create/newDeal',payload)
            if(response && response.createdDeal){
                NotificationManager.success("Uusi HubSpot-Deal luotu onnistuneesti", "", 3000);
                let url = ("https://app.hubspot.com/contacts/"+response.hsPortalId+"/deal/" + response.createdDeal.dealId +"/")
                let stageLabel = getStageLabel(response.createdDeal)
                item = {
                    ...item,
                    selectedDeal: response.createdDeal,
                    hubSpotLink:url,
                    stageLabel: stageLabel,
                    selectedContact: selectedContact? selectedContact: null
                }
                if(selectedContact){
                    let contactItem = {
                        email: selectedContact.email,
                        phone: selectedContact.phone? selectedContact.phone: "",
                        name: (selectedContact.firstName + " " + selectedContact.lastName),
                        business: {
                            businessName: plan.selectedBusiness.label,
                            id: plan.selectedBusiness.value
                        },
                        role: "",
                        tags:[]
                    }
                    let contactPayload = {
                        contact: contactItem,
                        addFromHubSpot: true
                    }
                    const contactResponse = await executeRequest('business/saveContactInfo', contactPayload)
                    if(contactResponse && contactResponse.success){
                        NotificationManager.success("Uusi kontakti lisätty", "", 3000);
                    }
                }
                let destClone = [
                    ...plan.planItems   
                ]
                dispatch(ActionCreator.createNewPlanItem(item, 0, destClone, plan._id))
            } else {
                NotificationManager.error("Virhe luodessa uutta HubSpot-Dealia", "Virhe", 3000);
            }
            // createNewDeal(payload)
            finishDealCreation()
        }
    }

    const finishDealCreation = () => {
        setProperties(INITIAL_PROPS)
        setSelectedOwner(null)
        setSelectedPipeline(null)
        setChosenStage(null)
        setCloseDate(null)
        toggleModal()
    }

    return(
        <Modal size="lg" isOpen={modalOpen} >
            <ModalHeader toggle={()=> toggleModal()}>Luo uusi HubSpot deal</ModalHeader>
            <ModalBody> 
                <Form >
                    {!hubSpotCompanyId && <p>Asiakkaalle ei ole liitetty HubSpot companyId:tä</p>}
                    {pipelinesLoading?
                        <MegaLoaderSpinner/>
                        :
                        <FormGroup>
                            <Label>Valitse pipeline</Label>
                            <Select
                                value={selectedPipeline}
                                onChange={(selected) => handlePipelineChange(selected)}
                                options={pipelines}
                                placeholder="Valitse pipeline"
                                isDisabled={false}
                                noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                className="pipelineSelection"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                    }
                    {selectedPipeline &&
                        <>
                            <FormGroup>
                                <Label>Nimi</Label>
                                <Input value={properties.dealname} onChange={(e) => handleChange({id:"dealname", value: e.target.value})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Summa (amount)</Label>
                                <Input value={properties.amount} onChange={(e) => handleChange({id:"amount", value: e.target.value})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Closedate</Label>
                                <SingleDatePicker
                                    block
                                    date={closeDate}
                                    onDateChange={date => handleCloseDate(date)}
                                    focused={dateFocused} // PropTypes.bool
                                    onFocusChange={({ focused }) => setDateFocused(focused) } // PropTypes.func.isRequired
                                    id="closeDate"
                                    openDirection={"down"} 
                                    numberOfMonths={2}
                                    hideKeyboardShortcutsPanel
                                    enableOutsideDays
                                    isOutsideRange={()=>false}
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>HubSpot CompanyId</Label>
                                <Input value={hubSpotCompanyId} readOnly={existingCompanyId} onChange={(e) => setHubSpotCompanyId(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Valitse deal owner</Label>
                                <Select
                                    value={selectedOwner}
                                    onChange={(selected) => handleOwnerChange(selected)}
                                    options={userList}
                                    placeholder="Valitse deal owner"
                                    isDisabled={false}
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    className="pipelineSelection"
                                    classNamePrefix="select"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Valitse deal stage</Label>
                                <Select
                                    value={chosenStage}
                                    onChange={(selected) => handleStageChange(selected)}
                                    options={dealStages}
                                    placeholder="Valitse deal stage"
                                    isDisabled={false}
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    className="stageSelection"
                                    classNamePrefix="select"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Valitse deal contact (ei pakollinen)</Label>
                                <Select
                                    value={selectedContact}
                                    onChange={(selected) => setSelectedContact(selected)}
                                    options={hubSpotContactList}
                                    placeholder="Valitse deal contact"
                                    isDisabled={false}
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    className="contactSelection"
                                    classNamePrefix="select"
                                />
                            </FormGroup>
                        </>
                    }
                </Form>
            </ModalBody>
            <ModalFooter>
                <a onClick={() => toggleModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                <button onClick={() => handleSave()} className="small-white-button wide-button">Tallenna uusi deal</button>
            </ModalFooter>
        </Modal>
    )
}

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '1rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}
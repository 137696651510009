const multipliersForYTunnus = [
    7, 
    9, 
    10, 
    5, 
    8, 
    4, 
    2
];

const checkValues = values => {
    for(let i = 0; i < values.length; i++) {
        if(isNaN(parseInt(values[i]))) {
            return false;
        }
    }
    return true;
}

export const checkYTunnus = (yTunnus) => {
    let sum = 0;
    
    if(yTunnus !== undefined && yTunnus !== null && yTunnus.includes("-")) {
        let split = yTunnus.split("-");
        if(split[0].length === 7) {
            let values = split[0];
            let checknumber = split[1];
            //First check incoming values that they only contain numbers

            if(checkValues(values)) {
                //Get the sum of all values with multipliers
                for(let i = 0; i < values.length; i++) {
                    sum += (parseInt(values[i]) * multipliersForYTunnus[i]);
                }

                //Handle rest of the operations, if the checknumber match, we are golden
                let jakojaannos = sum % 11;

                if(jakojaannos >= 2 && jakojaannos <= 10) {
                    if((11 - jakojaannos) === parseInt(checknumber[0])) {
                        return true;
                    }
                } else if(jakojaannos === parseInt(checknumber[0])) {
                    return true;
                }
            }
        }
    }
    return false;
}

export const stripUnicodeFromString = ( plaintext ) => {
    const whitelist = [
        '€'
    ]

    return plaintext.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, (match) => { return whitelist.indexOf(match) === -1 ? '' : match })
}
import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import {SingleDatePicker} from 'react-dates';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import { convertFromRaw } from 'draft-js';
import {NotificationManager} from 'react-notifications';
import {stripUnicodeFromString} from '../../../Utilities/HelperFunctions/helper';
import InnerSecondModal from '../../Reports/Dashboard/taskCreator/modals/innerModals/InnerSecondModal';
import InnerFinalModal from '../../Reports/Dashboard/taskCreator/modals/innerModals/innerFinalModal';
import * as ActionCreator from '../Actions/ActionCreator';
import {confs} from '../../../modules/config';

import '../../Reports/Dashboard/taskCreator/modals/modal.css';
import {connect} from 'react-redux';
import moment from 'moment';

import {executeRequest} from '../../../Utilities/requestHandler/dataHandler'

const customEmailMessages = {
    messages: {
        searchPlaceholder: "Etsi sähköpostiosoitetta...",
        noItemsMessage: "Valitse sähköpostiosoite",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
};

class PlanTaskCreatorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            taskType: "Plan-Tehtävä",
            innerSecond: false,
            innerFinal: false,
            emailList: [],
            selectedEmails: [],
            closeAll: false,
            selectedRiskLevel: null,
            emailToAdd: "",
            status: {},
            taskTitle: "",
            taskExplanation: "",
            deadline: null,
            notificationDate: null,
            focusedDeadline: false,
            focusedNotification: false,
            existingTask: false,
            deletionModal: false,
            hideModal: false,
            deleteText: ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.modal !== this.props.modal) {
            this.setState({modal: this.props.modal});

            if(this.props.modal) {
                let payload = {
                    companyId: this.props.activeCompany.value, 
                    jwtToken: sessionStorage.getItem("token")
                }

                fetch(confs.url + 'plan/getEmails', {
                    method: 'POST',
                    body: JSON.stringify(payload)
                }).then(resp => resp.json())
                .then(response => {
                    this.setState({emailList: response});
                })
            }
        }

        if(prevProps.chosenTarget.id !== this.props.chosenTarget.id) {
            this.setState({
                selectedEmails: [],
                emailToAdd: "",
                status: {},
                taskTitle: "",
                taskExplanation: "",
                deadline: null,
                notificationDate: null,
                selectedRiskLevel: null,
                existingTask: false,
                hideModal: false
            })
        }
        
        if(!prevProps.existingTask && this.props.existingTask) {
            
            let obj = {
                selectedEmails: this.props.chosenTarget.notificationList,
                status: this.props.chosenTarget.status,
                taskTitle: this.props.chosenTarget.taskTitle,
                taskExplanation: this.props.chosenTarget.styledTextTask,
                deadline: moment(this.props.chosenTarget.deadline),
                notificationDate: moment(this.props.chosenTarget.notificationDate),
                selectedRiskLevel: this.props.chosenTarget.riskValues,
                existingTask: true 
            }

            this.setState(obj);
        }

        if(prevProps.fetchStatus !== this.props.fetchStatus && this.props.fetchMessage === "finished") {
            this.toggleAll();
            this.setState({
                selectedEmails: [],
                emailToAdd: "",
                status: {},
                taskTitle: "",
                taskExplanation: "",
                deadline: null,
                notificationDate: null,
                selectedRiskLevel: null,
                existingTask: false,
                hideModal: false
            })
        }
    }

    handleEmailChange = email => {
        let check = this.validateEmail(email.target.value)
        if(check.status) {
            this.setState({emailReady: true, emailToAdd: email.target.value, status: check});
        } else {
            this.setState({emailReady: false, emailToAdd: email.target.value, status: check});
        } 
    }

    handleEmailAdd = event => {
        if(event.key === 'Enter') {
            if(this.state.emailReady) {
                let emailObject = {
                    id: this.state.emailToAdd,
                    label: this.state.emailToAdd
                };

                let emails = [
                    ...this.state.emailList,
                    emailObject
                ];

                this.setState({emailList: emails, emailToAdd: "", startAddingEmail: false});
            }
        }
    }

    validateEmail = (email) => {
        let emailObject = {
            id: email,
            label: email
        };

        var regex = /[^@]+@[^\.]+\..+/; /*/^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;*/
        /* 	    
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/*/
        if(regex.test(email)) {
            if(this.state.emailList.filter(email => email.label === emailObject.label).length === 0) {
                return {
                    status: true,
                    feedback: "Lisää sähköposti painamalla 'Enter'-näppäintä"
                }
            } else {
                return {
                    status: false,
                    feedback: "Sähköposti on jo listalla"
                };
            }
        } else {
            return {
                status: false,
                feedback: "Syötä sähköpostiosoite oikeassa muodossa"
            }
        }
    }

    changeType = type => {
        this.setState({taskType: type.type});
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleSecond = () => {
        this.setState({
          innerSecond: !this.state.innerSecond,
          modal: !this.state.modal,
          closeAll: false
        });
    }

    toggleFinal = () => {
        this.setState({
            innerSecond: !this.state.innerSecond,
            innerFinal: !this.state.innerFinal,
            closeAll: false
          });
    }

    toggleAll = () => {
        this.setState({
            modal: false,
            innerSecond: false,
            innerFinal: false,
            closeAll: true
        });
    }

    handleEmailSelections = selectedItems => {
        this.setState({selectedEmails: selectedItems});
    }

    handleRiskLevel = risk => {
        this.setState({selectedRiskLevel: risk});
    }

    startAddingEmail = () => {
        this.setState({startAddingEmail: true})
    }

    handleTaskTitle = (rawEditorState) => {
        let plaintextEmail = convertFromRaw(rawEditorState).getPlainText()
        plaintextEmail = stripUnicodeFromString( plaintextEmail )
        // plaintextEmail = plaintextEmail.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        this.setState({taskTitle: rawEditorState, taskTitlePlainContent: plaintextEmail});
    }

    handleTaskExplanation = (rawEditorState) => {
        let plaintextEmail = convertFromRaw(rawEditorState).getPlainText()
        plaintextEmail = stripUnicodeFromString( plaintextEmail )
        // plaintextEmail = plaintextEmail.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        this.setState({taskExplanation: rawEditorState, taskExplanationPlainContent: plaintextEmail});
    }

    proceedToSaveTask = (visibility = null) => {
        let deadLineMoment = null;
        let notificationDateMoment = null;
        let ready = true;

        if(this.state.deadline === null) {
            NotificationManager.error("Tarkistuspisteestä puuttuu deadline", "Virhe", 2000);
            ready = false;
        } else {
            deadLineMoment = new Date(moment(this.state.deadline))
        }

        if(this.state.notificationDate === null) {
            NotificationManager.error("Tarkistuspisteestä puuttuu muistutuspäivämäärä", "Virhe", 2000);
            ready = false;
        } else {
            notificationDateMoment =  new Date(moment(this.state.notificationDate));
        }

        if(this.state.selectedEmails.length === 0) {
            NotificationManager.error("Tarkistuspisteestä puuttuu vastuuhenkilö", "Virhe", 2000);
            ready = false;
        }

        if(deadLineMoment !== null && notificationDateMoment !== null && ready) {
            if(!this.state.existingTask) {
                let payload = {
                    type: this.state.taskType,
                    deadline:  deadLineMoment,
                    notificationDate: notificationDateMoment,
                    title: this.state.taskTitlePlainContent,
                    riskValues:  this.state.selectedRiskLevel,
                    responsible: this.state.selectedEmails,
                    styledMemo: this.state.taskExplanation,
                    plaintextMemo: this.state.taskExplanationPlainContent,
                    target: {...this.props.chosenTarget},
                    companyTarget: this.props.plan.selectedBusiness.value,
                    planId: this.props.plan._id
                }

                
                this.props.onTaskAdd(payload);
            } else {
               
                let payload = {
                    type: this.state.taskType,
                    deadline:  deadLineMoment,
                    notificationDate: notificationDateMoment,
                    title: this.state.taskTitlePlainContent || this.props.chosenTarget.taskTitle,
                    riskValues:  this.state.selectedRiskLevel,
                    responsible: this.state.selectedEmails,
                    styledMemo: this.state.taskExplanation || this.props.chosenTarget.styledTextTask,
                    plaintextMemo: this.state.taskExplanationPlainContent || this.props.chosenTarget.plainTextTask,
                    planTaskId: this.props.chosenTarget._id,
                    planId: this.props.plan._id,
                    hide: visibility
                }

                this.props.onTaskModify(payload);
            }
        }
    }

    checkFirstValues = () => {
        let ready = true;
        if(this.state.taskTitlePlainContent === "") {
            ready = false;
        }

        if(this.state.taskExplanationPlainContent === "") {
            ready = false;
        }

        if(this.state.deadline === null) {
            ready = false;
        }

        if(this.state.notificationDate === null) {
            ready = false;
        }

        return ready;
    }

    checkSecondValues = () => {
        let ready = true;

        if(this.state.selectedEmails.length === 0) {
            ready = false;
        }

        return ready;
    }

    toggleDeletionModal = () => {
        this.setState({deleteModal: !this.state.deleteModal})
        this.setState({deleteText: ""})
    }

    toggleHideModal = () => {
        this.setState({hideModal: !this.state.hideModal});
    }

    checkDelete = () => {
        if(this.state.deleteText === "poista"){
            this.props.onTaskDelete({planItemId: this.props.chosenTarget.planItemId, taskId: this.props.chosenTarget._id}) 
            this.toggle()
            this.setState({deleteText: ""})
        }
    }
    render() {
        const firstModalReady = this.checkFirstValues();
        const secondModalReady = this.checkSecondValues();
        const {visible} = this.props.chosenTarget;

        if(this.state.modal) {
            return (
                <>
                    {this.state.deleteModal && 
                        <Modal isOpen={this.state.deleteModal} toggle={this.toggleDeletionModal} className={this.props.className} centered>
                            <ModalHeader>
                                Tarkistuspisteen poisto
                            </ModalHeader>
                            <ModalBody>
                                <p>Oletko varma, että haluat poistaa valitun tarkistuspisteen?</p>
                                <h6 style={{paddingTop: '1.5em'}}><b>Toimintoa ei voi peruuttaa!</b></h6>
                                <div style={{ marginTop: '1em'}}>
                                    <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                                    <Input id="deleteInput" type="text" value={this.state.deleteText} onChange={(e) => this.setState({deleteText: e.target.value})}/>
                                    <div className="email-check-box">
                                        {this.state.deleteText === "poista"?
                                            <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                            :
                                            <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                        }
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <a onClick={this.toggleDeletionModal} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                                <button onClick={() => this.checkDelete()} className="small-white-button wide-button swb-hover" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                            </ModalFooter>
                        </Modal>
                    }
                    {this.state.hideModal &&
                        <Modal isOpen={this.state.hideModal} toggle={this.toggleHideModal} className={this.props.className} centered>
                            <ModalHeader>
                            Tarkistuspisteen piilotus
                            </ModalHeader>
                            <ModalBody>
                                <p>Oletko varma, että haluat piilottaa valitun tarkistuspisteen?</p>
                                <h6 style={{paddingTop: '1.5em'}}><b>Tarkistuspiste on valinnan jälkeen vielä voimassa, mutta sitä ei näytetä lomakkeen katselussa.</b></h6>
                            </ModalBody>
                            <ModalFooter>
                                <a onClick={this.toggleHideModal} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                                <button onClick={() => this.proceedToSaveTask(this.props.chosenTarget.visible !== undefined ? !this.props.chosenTarget.visible : false)} className="small-white-button wide-button swb-hover" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Piilota</button>{' '}
                            </ModalFooter>
                        </Modal>
                    }
                    <Modal isOpen={this.state.modal} toggle={this.toggle} centered size="lg">
                    <ModalHeader toggle={this.toggle} className="taskModal">
                        <div>
                            {this.state.existingTask ? "Muokkaa tarkistuspistettä" : "Luo uusi tarkistuspiste"}
                            <span className="modalPageNumber">1 / 3</span>
                        </div> 
                    </ModalHeader>
                    <ModalBody>
                        <div style={{paddingTop: '0.5em', paddingBottom: '0.5em', display: 'flex'}}>
                            {this.state.existingTask && 
                            <div>
                                <button className="small-white-button lighter-text wide-button delete" onClick={
                                    ((visible !== undefined && visible) || visible === undefined ) ? () => this.toggleHideModal() 
                                    : () => this.proceedToSaveTask(!visible)}>{(visible !== undefined && !visible) ? "Näytä tarkistuspiste" : "Piilota tarkistuspiste"}
                                </button>
                            </div>}
                            {this.state.existingTask && <div><button className="small-white-button lighter-text wide-button delete" onClick={() => this.toggleDeletionModal()} style={{marginLeft: '1.8em'}}>Poista tarkistuspiste</button></div> }
                        </div>
                        <TextEditor editorId={"targetTitle"}
                            content={this.props.chosenTarget.topicContent || ""}
                            plaintextEditor={true}
                            readOnly={true}
                            additionalClass="plan-input subTitle"
                        />
                        <Row className="taskCreatorRow">
                            <Col xl="12">
                                <p>Otsikko</p>
                                <TextEditor editorId={"taskCreatorTitle"}
                                    content={this.state.taskTitle || ""}
                                    onTextChange={this.handleTaskTitle}
                                    plaintextEditor={true}
                                    additionalClass="taskTitle"
                                />
                            </Col>
                            <Col xl="12">
                                <p>Selite</p>
                                    <TextEditor editorId={"taskCreatorExplanation"}
                                    content={this.state.taskExplanation || ""}
                                    onTextChange={this.handleTaskExplanation}
                                    plaintextEditor={true}
                                    additionalClass="taskExplanation"
                                    styleEditor={true} 
                                />
                            </Col>
                            <Col xl="6">
                                <p>Muistutuspäivä</p>
                                <SingleDatePicker
                                    block
                                    date={this.state.notificationDate}
                                    onDateChange={date => this.setState({notificationDate:  date })}
                                    focused={this.state.focusedNotification} // PropTypes.bool
                                    onFocusChange={({ focused }) => this.setState({ focusedNotification: focused })} // PropTypes.func.isRequired
                                    id="notificationDate"
                                    openDirection={"up"} 
                                    numberOfMonths={1}
                                    hideKeyboardShortcutsPanel
                                    readOnly
                                />
                            </Col>  
                            <Col xl="6">
                                <p>Deadline</p>
                                    <SingleDatePicker
                                        block
                                        date={this.state.deadline}
                                        onDateChange={date => this.setState({deadline:  date })}
                                        focused={this.state.focusedDeadline} // PropTypes.bool
                                        onFocusChange={({ focused }) => this.setState({ focusedDeadline: focused })} // PropTypes.func.isRequired
                                        id="deadline"
                                        openDirection="up"
                                        numberOfMonths={1}
                                        hideKeyboardShortcutsPanel
                                        readOnly
                                    />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="taskCreatorFooter">
                        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => this.toggle()}>Peruuta</a>
                        {firstModalReady && <button className="small-white-button lighter-text wide-button" onClick={() => this.toggleSecond()}>Jatka</button>}
                    </ModalFooter>
                </Modal>
                </>
            )
        }

        if(this.state.innerSecond) {
            return (
                <InnerSecondModal 
                        {...this.state}
                        messages={customEmailMessages.messages}
                        toggleSecond={this.toggleSecond}
                        toggleFinal={this.toggleFinal}
                        handleEmailSelections={this.handleEmailSelections}
                        handleRiskLevel={this.handleRiskLevel}
                        startAdding={this.startAddingEmail}
                        handleEmailChange={this.handleEmailChange}
                        handleEmailAdd={this.handleEmailAdd}
                        toggleAll={this.toggleAll}
                        modalReady={secondModalReady}
                    />
            )
        }

        if(this.state.innerFinal) {
            return (
                <InnerFinalModal 
                {
                    ...this.state
                }
                toggleFinal={this.toggleFinal}
                toggleAll={this.toggleAll}
                saveTask={this.proceedToSaveTask}
                fetchStatus={this.props.fetchStatus}
                editPlan={this.props.editPlan}
                />
            )
        }

        return (
            null
        )
    }
}

const mapStateToProps = state => {
    return {
        targetCompany: state.collaborations.targetCompany,
        fetchStatus: state.reports.taskModifyStatus,
        fetchMessage: state.reports.taskModifyMessage,
        plan: state.plan.plan,
        editPlan: state.plan.editPlan
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTaskAdd: (params) => dispatch(ActionCreator.addPlanItemTask(params)),
        onTaskModify: (params) => dispatch(ActionCreator.modifyPlanItemTask(params)),
        onTaskDelete: (params) => dispatch(ActionCreator.removeTask(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanTaskCreatorModal);


import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import './modals.css';
const QuestionDeletionModal = props => {
    return (
        <div>
            <Modal isOpen={props.modal} toggle={props.toggle} centered={true}>
                <ModalHeader toggle={props.toggle}>Kysymyksen poisto</ModalHeader>
                <ModalBody>
                    Oletko aivan varma kysymyksen poistosta? <b>Kysymyksen poisto on pysyvä.</b>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={props.toggle}>Peruuta</a>{' '}
                    <button className="small-white-button wide-button swb-hover" style={{ backgroundColor: '#ed5652', color: '#fff'}} onClick={props.deleteQuestion}>Poista</button>
                </ModalFooter>
            </Modal>
      </div>
    )
}

export default QuestionDeletionModal;
import * as ActionCreator from '../../../store/index'
import { confs } from '../../../modules/config'
import * as ActionTypes from './ActionTypes'

import { NotificationManager } from 'react-notifications'
import { CHANGE_LOGO } from '../../../store/actions/Login/ActionTypes';

const storeBusiness = payload => {
  return {
    type: ActionTypes.GET_BUSINESS,
    payload: payload
  }
}

const storeBusinessUsers = payload => {
  return {
    type: ActionTypes.GET_BUSINESS_USERS,
    payload: payload
  }
}

export const clearBusiness = () => {
  return {
    type: 'CLEAR_BUSINESS_INFO'
  }
}

export const setHubSpotClosedValue = (amount) => {
  return {
    type: ActionTypes.HUBSPOT_CLOSED_VALUE,
    payload: amount
  }
}

export const getBusiness = params => {
  let jwt = window.sessionStorage.getItem("token")
  let payload = {
    ...params,
    jwtToken: jwt
  }

  return dispatch => {
    fetch(confs.url + 'getBusiness', {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then((response) => response.json())
      .then((responseJson) => {
        if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
          dispatch(ActionCreator.authFailed())
        } else {
          dispatch(storeBusiness(responseJson.business))
          dispatch(storeBusinessUsers(responseJson.users))
        }
      })
  }
}

export const editBusiness = params => {
  let jwt = window.sessionStorage.getItem("token")
  let payload = {
    ...params,
    jwtToken: jwt
  }

  return dispatch => {
    fetch(confs.url + 'editBusiness', {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then((response) => response.json())
      .then((responseJson) => {
        if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
          dispatch(ActionCreator.authFailed())
        } else {
          dispatch(storeBusiness(responseJson))
          NotificationManager.success('Tietojen tallentaminen onnistui', 'Ilmoitus', 4000)
        }
      })
  }
}

export const editBusinessUser = params => {
  let jwt = window.sessionStorage.getItem("token")
  let payload = {
    user: params,
    jwtToken: jwt
  }

  return dispatch => {
    fetch(confs.url + 'editUser', {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then((response) => response.json())
      .then((responseJson) => {
        if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
          dispatch(ActionCreator.authFailed())
        } else {
          if (typeof responseJson.success === 'undefined') {
            NotificationManager.success('Tietojen tallentaminen onnistui', 'Ilmoitus', 4000)
            dispatch(storeBusinessUsers(responseJson))
          } else {
            if(typeof responseJson.error !== undefined && responseJson.error === 'EXISTS') {
              NotificationManager.error('Tietojen tallentaminen epäonnistui. Antamasi sähköpostiosoite on jo käytössä.', 'Ilmoitus', 4000)
            } else {
              NotificationManager.error('Tietojen tallentaminen epäonnistui', 'Ilmoitus', 4000)
            }
          }
        }
      })
  }
}

const updateLogo = ( logo ) => {
  return {
    payload: logo,
    type: CHANGE_LOGO
  }
}

export const uploadBusinessLogo = data => {
  return dispatch => {
    fetch(confs.url + 'uploadLogo', {
      method: 'POST',
      body: data
    }).then((response) => response.json())
      .then((responseJson) => {
        if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
          dispatch(ActionCreator.authFailed())
        } else {
          if (responseJson.success) {
            dispatch(storeBusiness(responseJson.business))
            dispatch(updateLogo(responseJson.business.logo))
            NotificationManager.success("Logon tallentaminen onnistui", "Ilmoitus", 2000)
          } else {
            switch (responseJson.error) {
              case "SIZE":
                NotificationManager.error('Logon tallennus epäonnistui. Tiedoston koko täytyy olla vähemmän kuin 16MB', 'Ilmoitus', 2000);
                return;
              case "TYPE":
                NotificationManager.error('Logon tallennus epäonnistui. Tiedoston täytyy olla kuvatiedosto', 'Ilmoitus', 2000);
                return;
              default:
                NotificationManager.error('Logon tallennus epäonnistui. Jokin meni pieleen :(', 'Ilmoitus', 2000);
                return;
            }

          }
        }
      })
  }
}

export const deleteBusinessLogo = () => dispatch => {
  let jwt = window.sessionStorage.getItem("token")
  let payload = {
    jwtToken: jwt
  }

  return fetch(confs.url + 'deleteLogo', {
    method: 'POST',
    body: JSON.stringify(payload)
  }).then((response) => response.json())
    .then((responseJson) => {
      if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
        dispatch(ActionCreator.authFailed())
      } else {
        if (typeof responseJson.success === 'undefined') {
          NotificationManager.success('Tietojen tallentaminen onnistui', 'Ilmoitus', 4000)
          dispatch(storeBusiness(responseJson))
          dispatch(updateLogo(null))
        } else {
          NotificationManager.error('Tietojen tallentaminen epäonnistui', 'Ilmoitus', 4000)
        }
      }
    })
}
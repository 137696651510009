import React, {useState, useEffect} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Row, Col} from 'reactstrap';
import {executeRequest} from '../../../Utilities/requestHandler/dataHandler';
import { Dropzone } from '../../../Utilities/Dropzone/Dropzone';
import { confs } from '../../../modules/config';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { Scrollbars } from 'react-custom-scrollbars';

const baseURL = confs.url

export const AttachmentCreationModal = ({open, toggle, targetBusiness, item, createAttachmentItem, clearItems, addFiles = null, planId = null, creation=false}) => {
    const [file, setFile] = useState(null)
    const [fileOk, setFileOk] = useState(false)
    const [nameOk, setNameOk] = useState(false)
    const [fileName, setFileName] = useState("")
    const [showSave, setShowSave] = useState(false)
    const [option, setOption] = useState("existing")
    
    const newAttachmentItem = (uploadedFile) => {
        let payload = {
            ...item.selectedItem,
            files: item.files? [...item.files, uploadedFile] : [ uploadedFile ],
            businessId: targetBusiness? targetBusiness.value : null
        }

        createAttachmentItem(payload, item.destinationIndex, item.destClone, item.planId);
        
        clearItems();
    }

    useEffect(()=>{
        if(fileName){
            checkName()
        }
        if(file){
            checkDataType(file)
        }
        if(fileOk && nameOk){
            setShowSave(true)
        } else {
            setShowSave(false)
        
        }
    },[file, fileName, fileOk, nameOk])

    const checkDataType = (file) => {
        let regex = new RegExp(/([a-zA-Z0-9åäöÅÄÖ\s_.,\-\(\)])+(.doc|.docx|.pdf|.xls|.xlsx|.xlsm|.xlsb|.xltx|.txt|.jpg|.jpeg|.png|.bmp|.svg|.ppt|.pptx|.pptm)$/)

        if(file && file.name){  
            if(regex.exec(file.name.toLowerCase()) !== null){
                setFileOk(true)
            } else { 
                setFileOk(false)
                NotificationManager.error("Tiedoston nimessä ei saa olla tiettyjä erityismerkkejä. Hyväksyttyjä ovat ( ) _ . ","Virhe", 10000)
            }
        } else {
            setFileOk(false)
        }    
    }

    useEffect(()=>{
        if(file){
            handleFileChange(file)
        }
    },[file, setFile])

    const handleFileChange = (e) => {
        let name = e.name.split(".")
        name.pop()
        let finalName = name.join(".")
        if(!fileName || (fileName && fileName.length === 0)){
            setFileName(finalName)
            checkName()
        }
    }

    const checkName = () => {
        let regex = new RegExp(/^[a-zA-Z0-9åäöÅÄÖ\s_.,\-\(\)]+$/)
        if(fileName && fileName.length > 0 ){
            if(regex.exec(fileName) != null){
                let nameColour = document.getElementById("fileName")
                nameColour.setAttribute("style", "color:default;")     
                setNameOk(true)
            }  else {
                let nameColour = document.getElementById("fileName")
                nameColour.setAttribute("style", "color:red;")
                setNameOk(false)
                NotificationManager.error("Tiedoston nimessä ei saa olla tiettyjä erityismerkkejä. Hyväksyttyjä ovat ( ) _ . , ","Virhe", 10000)
            }
        } else {
            setNameOk(false)
        }
    }

    useEffect(()=>{
        if(file){
            handleFileChange(file)
        }
    },[file, setFile])

    const handleUmlauts = (name) => {
        
        if(name){
            let newName = name
            newName = newName.replace(/ä/g, 'a');
            newName = newName.replace(/ö/g, 'o');
            newName = newName.replace(/å/g, 'o');
            newName = newName.replace(/Ä/g, 'A');
            newName = newName.replace(/Ö/g, 'O');
            newName = newName.replace(/Å/g, 'O');

            
            return newName
        }
    }


    const handleSave = async() => {
        let data = new FormData()
        let blob = file.slice(0, file.size, file.type); 

        let newFile = new File([blob],handleUmlauts(file.name), {type:file.type})
        //let business = targetBusiness? JSON.stringify({...targetBusiness}) : null
        //data.set("business", business)
        data.set("jwtToken", window.sessionStorage.getItem("token"))
        data.set("name",fileName)
        data.set("file", newFile, newFile.name)
        data.set("type", "attachment")
        let tempName = newFile.name.split(".")
        data.set("fileEnd", tempName[tempName.length-1])
        data.set("connectionType", "plans")
        data.set("documentId", planId)
        upload(data)
    }

    const upload = async(props) => {
        try {
            const responseJson = await fetch(baseURL + 'plan/uploadFile', {
                method: 'POST',
                body: props
            })
            if(responseJson) {
                const response = await responseJson.json()
                clearFields()
                let resp = {} 
                if(response.fileConnection){
                    resp = response
                    delete resp.fileConnection
                } else {
                    resp = response
                }
                
                if(addFiles !== null){
                    addFiles(resp)
                } else {
                    newAttachmentItem(resp)
                }
                return resp;
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }
    const createWithoutFile = () => {
        let payload = {
            ...item.selectedItem,
            files: [],
            businessId: targetBusiness? targetBusiness.value : null
        }

        createAttachmentItem(payload, item.destinationIndex, item.destClone, item.planId);
        toggle()
    }

    const saveExisting = async() => {
        let payload = {
            planId: planId,
            fileId: selectedFile.id,
            connectionType: "plans"
        }
        const response = await executeRequest('plan/addExistingFile', payload)
        if(addFiles !== null){
            addFiles(response)
            clearFields()
        } else {
            newAttachmentItem(response)
        }
        setSelectedFile(null)
    }

    const clearFields = () => {
        setFile(null)
        setFileName("")    
    }

    useEffect(()=>{
        getExistingFiles()
    },[])

    const [selectedFile, setSelectedFile] = useState(false)
    const [fileList, setFileList] = useState([])
    const[imagesSelected, setImagesSelected] = useState(false)
    const [search, setSearch] = useState("")
    const [originalFileList, setOriginalFileList] = useState([])

    const modulesInUse = useSelector(state => state.authentication.modulesInUse)

    const getExistingFiles = async() => {
        const response = await executeRequest('file/getExistingFiles',{})
        if(response){
            setFileList(response)
            setOriginalFileList(response)
        }
    }

    const handleListNameFilter = file => {
        const {name, type} = file
        
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true

        return false
    }

    const filterImages = (file) => {
        const {type, mimeType} = file
        if(type && type.toUpperCase().includes("DRAFTJS-IMAGE") || type && type.toUpperCase().includes("DRAFT-JS-IMAGE")) return true
        if(mimeType && mimeType.toUpperCase().includes("IMAGE")) return true

        return false
    }

    useEffect(()=>{
        let files = originalFileList
        if(imagesSelected){
            let filtered = files.filter(file => filterImages(file))
            setFileList(filtered)
        } else {
            setFileList(files)
        }

    },[imagesSelected])

    return(
        <Modal size="lg" isOpen={open} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()}>
                Lisää lomakkeeseen tiedosto
            </ModalHeader>
            <ModalBody>
                <Row style={{ marginBottom: "1em", marginRight:'' }}>
                    <Col>
                        <button className={"small-white-button lighter-text wide-button " + (option === "existing" ? " active":"")} style={{ width: '100%' }} onClick={() => (setOption("existing"), clearFields())}>Olemassa oleva</button>
                    </Col>
                    <Col>
                        <button className={"small-white-button lighter-text wide-button" + (option === "new" ? " active":"") } style={{ width: '100%' }} onClick={() => (setOption("new"), setSelectedFile(false))}>Uusi liite</button>
                    </Col>
                </Row>
                {option === "new" &&
                    <>
                        <Dropzone
                            setFile={setFile}
                        />
                        <Label>Tiedoston nimi</Label>
                        <Input id="fileName" type="text" value={fileName} onChange={(e) => setFileName(e.target.value)}></Input> 
                    </> 
                }
                {option === "existing" && 
                    <>
                        {selectedFile && <button className={"small-white-button wide-button" } style={{ fontSize: '0.8rem', marginTop: '' }} onClick={() => setSelectedFile(false)}>Takaisin valintaan</button>}
                        {selectedFile?
                            <div style={{ marginTop: '1em', marginBottom: '2em' }} className="even-row">
                                <Row>
                                    <Col xs="8">
                                        <p>{selectedFile.name? (selectedFile.name + "." + selectedFile.fileEnd) : "Ei nimeä"}</p>
                                    </Col>
                                    <Col xs="4">
                                        {(selectedFile.type === "draftjs-image" || selectedFile.type === "draft-js-image" || (selectedFile.mimeType && selectedFile.mimeType.toUpperCase().includes('IMAGE'))) &&
                                            <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '70px', maxWidth: '300px', backgroundRepeat: 'no-repeat' }}>
                                                <img style={{ maxHeight: '70px'}}  src={`${confs.host}/api/get/imagePreview/${selectedFile.id}`}></img>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div> 
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1em', marginBottom: '0.5em', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginLeft: '2em', marginRight: '1em' }}>
                                        <input type="search" className="search-bar-open form-control" placeholder={"Hae lomaketta"} value={search} onChange={(event) => setSearch(event.target.value)} />
                                    </div>
                                    <button className={"small-white-button lighter-text wide-button" + (imagesSelected ? " active":"") } style={{ width: '12em', marginRight: '1em' }} onClick={() => setImagesSelected(!imagesSelected)}>{imagesSelected? "Näytä kaikki" : "Näytä vain kuvat"}</button>
                                </div>
                                    <Scrollbars
                                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                        style={{ width: "100%", height: '500px' }}
                                        className="custom-scrollbars"
                                    >    
                                        {fileList && fileList.length > 0 ? 
                                            fileList.filter(file => handleListNameFilter(file)).map((file, key) => {
                                                let isImage = false
                                                if(file.type === "draftjs-image" || file.type === "draft-js-image" || (file.mimeType && file.mimeType.toUpperCase().includes('IMAGE'))){
                                                    isImage = true
                                                }
                                                let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                                            
                                                return(
                                                    <Row key={key} className={oddEvenClass + ' list-row'} style={{ marginLeft:0}} onClick={()=> setSelectedFile(file)}>
                                                        <Col xs="8">
                                                            <p>{file.name? (file.name + "." + file.fileEnd) : "Ei nimeä"}</p>
                                                        </Col>
                                                        <Col xs="4">
                                                            <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '70px', maxWidth: '300px', backgroundRepeat: 'no-repeat' }}>
                                                                {isImage && <img style={{ maxHeight: '70px'}}  src={`${confs.host}/api/get/imagePreview/${file.id}`}></img>}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                            :
                                            <Row>
                                                <Col xs="12" style={{}}>
                                                   {"Ei tiedostoja. Ole hyvä ja valitse uusi tiedosto yllä olevasta painikkeesta."}
                                                </Col>
                                                
                                            </Row>
                                        }
                                    </Scrollbars>
                            </div>
                        }

                    </>
                }      
            </ModalBody>
            <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => (toggle(), setFileName(""), setSelectedFile(null))}>Peruuta</a>
                    {selectedFile ?
                        <button className="small-white-button lighter-text wide-button" onClick={() => saveExisting()}>Tallenna</button>
                        :
                        (showSave ?
                            <button className="small-white-button lighter-text wide-button" onClick={() => handleSave()}>Tallenna</button>
                            :
                            (creation && 
                                <button className="small-white-button lighter-text wide-button" onClick={() => createWithoutFile()}>Luo alue ilman tiedostoa</button>
                            )
                        )
                    }
            </ModalFooter>
        </Modal>
    )
}
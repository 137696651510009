

//VITTU MIIKA

const OPEN_REPORT_AND_TAB = "OPEN_REPORT_AND_TAB";
const SWITCH_TAB = "SWITCH_TAB";
const GET_SURVEY_TREE = "GET_SURVEY_TREE";
const SET_COMPARISON_TREE = "SET_COMPARISON_TREE";
const END_COMPARISON = "END_COMPARISON";
const REORDER_SURVEYTREE = "REORDER_SURVEYTREE";
const SET_COMPANY_TASKS = "SET_COMPANY_TASKS";
const CLEAR_COMPANY_DATA = "CLEAR_COMPANY_DATA";


const initialState = {
    activeTab: '1',
    reportId: "",
    startDate: null,
    endDate: null,
    targetId: "",
    anonSurveys: [],
    surveyTree: [],
    recievedCode: "",
    comparisonTree: [],
    openComparison: false,
    selectedCompany: {},
    selectedBusiness: null,
    taskData: [],
    highestPriorities: [],
    lowestPriorities: []
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case OPEN_REPORT_AND_TAB:
            return {
                ...state,
                ...action.payload
            }
        case SWITCH_TAB: {
            return {
                ...state,
                activeTab: action.payload
            }
        }
        case GET_SURVEY_TREE: {
            return {
                ...state,
                surveyTree: action.payload.surveyTree,
                selectedBusiness: action.payload.business
            }
        }
        case SET_COMPANY_TASKS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case SET_COMPARISON_TREE: {
            return {
              ...state,
              comparisonTree: [
                ...action.payload
              ],
              openComparison: true
            }
        }
        case END_COMPARISON: {
            return {
                ...state,
                openComparison: false
            }
        }
        case REORDER_SURVEYTREE: {
            return {
                ...state,
                surveyTree: action.payload,
                selectedBusiness: {
                    ...state.selectedBusiness,
                    surveyTree: action.payload
                }
            }
        }
        case CLEAR_COMPANY_DATA: {
            return {
                ...state,
                surveyTree: [],
                selectedBusiness: null,
                taskData: [],
                highestPriorities: [],
                lowestPriorities: []
            }
        }
        default:
            return state
        
    }
}

export default reducer;
import React, {useState, useEffect, useRef} from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Input } from "reactstrap"
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { Scrollbars } from 'react-custom-scrollbars'
import moment from 'moment';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';

const PrivatePlanModal = ({isOpen, toggle, plan, savePlanPrivacySettings}) => {
    const [userList, setUserList] = useState([])
    const [search, setSearch] = useState('')
    const [isPrivatePlan,setIsPrivatePlan] = useState(false)
    const [planVisibleTo, setPlanVisibleTo] = useState([])

    useEffect(()=>{
        getUserList()
    },[])

    const getUserList = async() => {
        const enterpriseData = await executeRequest('get/enterprise', {})
        setUserList([...enterpriseData.users])
    }

    useEffect(()=>{
        if(plan.isPrivateDocument){
            setIsPrivatePlan(plan.isPrivateDocument)
        }
        if(plan.visibleTo){
            setPlanVisibleTo(plan.visibleTo)
        }
    },[plan])

    const savePrivacySettings = () => {
        if(plan && plan._id){
            let payload = {
                planId: plan._id,
                isPrivateDocument: isPrivatePlan,
                visibleTo: planVisibleTo
            }
            savePlanPrivacySettings(payload)
        }
    }

    const handlePlanPrivacy = () => {
        setIsPrivatePlan(!isPrivatePlan)
    }

    const handleVisibleToUser = (user) => {
        let updatedVisibility = planVisibleTo

        if(planVisibleTo.find((userId) => userId === user.id) !== undefined){
            updatedVisibility = planVisibleTo.filter((userId) => userId !== user.id)
        }else{
            updatedVisibility = [...updatedVisibility,user.id]
        }
        setPlanVisibleTo(updatedVisibility)
    }
    
    return(
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={()=>toggle(false)}>Yksityisyysasetukset</ModalHeader>
            <ModalBody>
                {plan &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom:'2em' }}>
                        <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Yksityinen lomake</p>
                        <label className="switch">
                            <input id="switch-input" type="checkbox" onChange={() => handlePlanPrivacy()} checked={isPrivatePlan}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                }
                {isPrivatePlan &&
                    <div>
                        <div style={{ fontWeight:'bold', marginBottom:'0.5em'}}>
                            Salli käyttäjän nähdä lomake 
                            <p style={{fontSize:'0.8rem', fontWeight:'normal'}}>Valinta tapahtuu painamalla riviä</p>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2em', width: 'auto', marginRight:'1.75em', marginBottom:'0.5em' }}>
                            <Input type="search" className="search-bar-open" placeholder={"Hae käyttäjää..."} value={search} onChange={(event) => setSearch(event.target.value)} />
                        </div>
                        <Scrollbars
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            style={{ width: "100%", height: '500px' }}
                            className="custom-scrollbars"
                        >
                            <Col>
                                {planVisibleTo && userList && userList.map((user, y) => {
                                    if ((user.email.toLowerCase().includes(search.toLowerCase())) || (user.phone.toLowerCase().includes(search.toLowerCase()))) {
                                        let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row'
                                        return (
                                            <Row key={y} className={oddEvenClass + ' list-row '} onClick={()=> handleVisibleToUser(user)}>
                                                <Col xs="12">
                                                    <Row>
                                                        <Col xs="10">
                                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                <span>{user.email}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs="2">
                                                        <label className="switch">
                                                            <input id={"switch-input"} type="checkbox" checked={planVisibleTo.filter((userId) => userId === user.id).length > 0}/>
                                                            <span className="slider round"></span>
                                                        </label>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    return null
                                })}
                                {userList.length === 0 &&
                                    <Col style={{ marginTop: '1em' }}>
                                    <span>Käyttäjiä ei löytynyt</span>
                                    </Col>
                                }
                            </Col>
                        </Scrollbars>
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <a onClick={()=>toggle(false)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                <button onClick={()=> savePrivacySettings()} className="small-white-button wide-button swb-hover delete-plan" >Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

export default PrivatePlanModal
import { setIndex } from '@amcharts/amcharts4/.internal/core/utils/Array';
import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Label, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { Annotation } from './Annotation';
import './annotation.css';
import { executeRequest } from '../requestHandler/dataHandler';

export const AnnotationTool = ({annotationList = [], planId}) => {
    const [annotations, setAnnotations] = useState(annotationList)
    const [deleteModalOpen, setDeleteModalOpen] = useState()
    const [selectedAnnotation, setSelectedAnnotation] = useState({})


    const handleDelete = (annotation) => {
        setSelectedAnnotation(annotation)
        setDeleteModalOpen(true)
    }

    const confirmDelete = async() => {

        let payload = {
            annotationId: selectedAnnotation.annotationId,
            planId:planId
        }

        const response = await executeRequest('homepage/removeAnnotation', payload)

        if(response.annotations){
            setAnnotations(response.annotations)
            setDeleteModalOpen(false)
        }
        

    }

    return (
        <>
            <Modal isOpen={deleteModalOpen}>
                <ModalHeader>
                    {"Olet poistamassa kommenttia!"}
                </ModalHeader>
                <ModalBody>
                    {"Olet poistamassa kommenttia! Tätä toimintoa ei voi perua!"}
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => setDeleteModalOpen(false)}>Peruuta</a>
                    <button className="small-white-button wide-button" style={{ marginLeft: '0.25rem', backgroundColor: '#ed5652', color: '#fff' }} onClick={() => confirmDelete()}>Poista</button>  
                </ModalFooter>
            </Modal>
    
            <div className="comment-section" style={{ marginTop: '1em', overflowY: 'auto'}}>
                {annotations.length > 0 && annotations.map((annotation, key) => {
                    return(
                        <Fragment key={key}>
                            <Annotation 
                                item={annotation}
                                handleDelete={handleDelete}
                            />
                        </Fragment>
                    )
                })}
            </div>   
        </>
    )
}
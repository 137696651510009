import React, {useState, useEffect, useRef} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import { Scrollbars } from 'react-custom-scrollbars';
import { Row, Col, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalFooter, ModalBody, Label, Input, FormGroup, FormFeedback } from 'reactstrap';
import FilterContainer from '../../Components/Kartoitus/Sender/modules/taskFilter/FilterContainer';
import FilterButton from '../../Components/Kartoitus/Sender/modules/taskFilter/FilterButton';
import { useInView, InView } from 'react-intersection-observer'
import { logoDownloaded } from '../../store';
import Select from 'react-select';
import './list-styles.css'

export const VerticalList = ({children}) => {

    return (
        <div className="list-container">
            
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", borderRadius: '10px'}}
                    className="custom-scrollbars"
                    autoHeight={true}
                    autoHeightMin={'80px'}
                    autoHeightMax={'300px'}
                >
                    
                    {children}
                
                </Scrollbars> 
            
        </div>
    )
}

export const VerticalItem = ({children}) => {
    const [ref, inView, entry] = useInView({threshold:0})
    const variants = {
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {delay: 0.1, ease: 'easeIn', duration: 0.1}
        }),
        hidden: i => ({
            opacity: 1, 
            x: 0,
            transition: {delay: 0.1, ease: 'easeOut', duration: 0.1}
        })
    }
    return ( 
        <motion.div
            initial={{ opacity: 0, x: 100 }}
            transition={{ ease: 'easeIn', duration: 0.1 }}
            exit={{ opacity: 0, x: -100 }}
            animate={inView ? "visible" : "hidden"}
            variants={variants}
            key={"forms"}
            ref={ref}
        >   
            <div className={"list--content"}>
                {inView && children}
            </div>
        </motion.div>
    )
}


export const VerticalContainer = ({label, children, filter = false, filters, downloadCSV, selectedScore, setSelectedScore, scoreOptions, scoreFilter=false, scoreFilterOpen, setScoreFilterOpen, handleScoreFilterChange, setFilter, searchFilter, setStatusFilters, pipeline, availablePipelines, changePipeline, openSettings, customFilters, addNew, selectedBusiness, project, toggleType, activeType = 'cards', listName = null, showAddNewOnList = null, ganttOption = false}) => {
    const handleTypeChange = (type) => {
        if(listName !== null){
            toggleType(listName,type)
        }
    }

    let typeSubString = ""

    if(listName !== null){
        typeSubString = listName.slice(0, -1)
    }   

    return (
        <Col style={{minWidth: '50%' }} id={listName? listName+"-tourId" : ""} className="dashboard-list-container">
            <div>
                <div className={"title-bar vertical-label-bar" }>
                    
                    <div className="bigger-text" style={{display:'flex'}}>
                        <div className={(!listName.toString().toLowerCase().includes("hub") ?'dashboard-dot ':"") + (typeSubString.length > 0 ? typeSubString + "-color" : "")} style={{ marginRight: '0.5em'}}></div> <p>{label}</p>
                    </div>
                    {scoreFilter &&
                        <Row>
                            <Col xs="2">
                                <button className={"small-white-button lighter-text filter-nibblet-surveylist" } title="Pistehaku. Voit hakea pisteettömiä tuloksia laittamalla molempiin kenttiin - merkin" style={{ padding: '0.2em 0.5em', marginLeft: '0.5em', height:'2.1em' }} onClick={ () => setScoreFilterOpen(!scoreFilterOpen)}><i className="fas fa-filter"></i></button>
                            </Col>
                            {scoreFilterOpen &&
                                <Col xs="10">
                                    <Row style={{marginLeft:'-10px', maxWidth: '20em', minWidth:'10em'}}>
                                        <select onChange={(e) => setSelectedScore(e.target.value)} value={selectedScore} style={{ marginLeft: '0.25em', marginRight:'0.25em', borderRadius: '5px', border:'none'}}>
                                            {scoreOptions.map((option, index) => {
                                                return <option key={index} >
                                                    {option}
                                                </option>
                                            })}
                                        </select>
                                        <Input style={{ width: '4em', height:'1.6em'}} value={scoreFilter.first} onChange={(e)=> handleScoreFilterChange({id: "first", value: e.target.value})}/>
                                        <p style={{marginLeft: '0.25em', marginRight: '0.25em'}}>-</p>
                                        <Input style={{ width: '4em', height:'1.6em'}} value={scoreFilter.second} onChange={(e)=> handleScoreFilterChange({id: "second", value: e.target.value})}/>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    }
                    {scoreFilter && 
                        <div >
                            <button className={"small-white-button lighter-text " } title="Lataa csv" style={{ padding:'0.3em 0.65em', marginLeft: '1em' }} onClick={ () => downloadCSV()}><i className="fas fa-download"></i></button>
                        </div>
                    }
                    {filter &&
                        <>
                            {filters.map((filterItem) =>
                                <FilterButton filterItem={filterItem} removeItem={setStatusFilters} key={filterItem.value} filters={filters} />
                            )}
                            <div style={{position: 'relative' }} id="filter-button-tourId" className="filter-nav">
                                <FilterContainer handleStatusFilters={setStatusFilters} statusFilters={filters} customFilters={customFilters} />
                            </div>
                        </>
                    } 
                    {pipeline &&
                        <div style={{marginLeft: '0.5rem'}}>
                            <select name="pipeline" value={pipeline.id} onChange={(e) => changePipeline(e)}>
                                {availablePipelines && availablePipelines.map(pipe =>
                                    <option value={pipe.id} key={pipe.id}>{pipe.label}</option> 
                                )}
                            </select>
                        </div>
                    }
                    {openSettings && <div className="horizontal-container-settings"><i className="fas fa-cog settings-icon" onClick={openSettings}></i></div>}  
                    {listName !== null &&
                        <div id="list-view-tourId" className="view-type-container">
                            {activeType !== "cards" && <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("cards")}><i className="fas fa-columns"></i></button>}
                            {activeType !== "list" && <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("list")}><i className="fas fa-list-ul"></i></button>}
                            {listName === "projects" && ganttOption && activeType !== "gantt" && <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("gantt")}><i className="fas fa-chart-bar"></i></button>}
                        </div>
                    }         
                    {addNew && showAddNewOnList && (project ? 
                        <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                            <i className="fas fa-plus dash-plusIcon project-footer" style={{ backgroundColor: '#fff' }} onClick={() => {addNew()}}></i>
                            <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew()}}>Lisää</p>
                        </div>  
                        :
                        (selectedBusiness ?
                            <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                                <i className="fas fa-plus dash-plusIcon" style={{ backgroundColor: '#fff' }} onClick={() => {addNew(selectedBusiness)}}></i>
                                <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew(selectedBusiness)}}>Lisää</p>
                            </div>
                            :
                            <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                                <i className="fas fa-plus dash-plusIcon" style={{ backgroundColor: '#fff' }} onClick={() => {addNew()}}></i>
                                <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew()}}>Lisää</p>
                            </div>  
                        )                           
                    )}
                   
                </div>
                {children}
            </div>
        </Col>
    )
}

export const VerticalContainerWithSearch = ({label, children, filter = false, filters, setFilter, searchFilter, setStatusFilters, pipeline, availablePipelines, changePipeline, openSettings, customFilters, addNew, selectedBusiness, project, toggleType, activeType = 'cards', listName = null, showAddNewOnList = null}) => {

    const handleTypeChange = (type) => {
        if(listName !== null){
            toggleType(listName,type)
        }
    }

    let typeSubString = ""

    if(listName !== null){
        typeSubString = listName.slice(0, -1)
    }

    return (
        <Col style={{minWidth: '50%' }} id={listName? listName+"-tourId" : ""} className="dashboard-list-container">
            <motion.div
                initial={{ opacity: 0, x: 40 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ ease: 'easeIn', duration: 0.3 }}
                exit={{ opacity: 0, x: -50 }}
                className="vertical-main-container"
                key={label}
            >
                <div className={"title-bar vertical-label-bar" }>
                    
                    <div className="bigger-text" style={{display:'flex'}}>
                        <div className={(!listName.toString().toLowerCase().includes("hub") ?'dashboard-dot ':"") + (typeSubString.length > 0 ? typeSubString + "-color" : "")} style={{ marginRight: '0.5em'}}></div> <p>{label}</p>
                    </div>
                    <div id="search-tourId">
                        <form id="search-bar">
                            <input id={label} type="search" className="search-bar-input" placeholder="Haku" name="taskFilter" value={searchFilter} onChange={(event) => setFilter(event.target.value)} />
                        </form>
                    </div>
                    {filter &&
                        <>
                            {filters.map((filterItem) =>
                                <FilterButton filterItem={filterItem} removeItem={setStatusFilters} key={filterItem.value} filters={filters} />
                            )}
                            <div style={{position: 'relative' }} id="filter-button-tourId" className="filter-nav">
                                <FilterContainer handleStatusFilters={setStatusFilters} statusFilters={filters} customFilters={customFilters} />
                            </div>
                        </>
                    } 
                    {pipeline &&
                        <div style={{marginLeft: '0.5rem'}}>
                            <select name="pipeline" value={pipeline.id} onChange={(e) => changePipeline(e)}>
                                {availablePipelines && availablePipelines.map(pipe =>
                                    <option value={pipe.id} key={pipe.id}>{pipe.label}</option> 
                                )}
                            </select>
                        </div>
                    }
                    {openSettings && <div className="horizontal-container-settings"><i className="fas fa-cog settings-icon" onClick={openSettings}></i></div>}  
                    {listName !== null &&
                        <div  id="list-view-tourId" className="view-type-container">
                            {activeType === "list" && <button className={"small-white-button  " + (activeType === "cards"? "dashboard-active-selection": "")} style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("cards")}><i className="fas fa-columns"></i></button>}
                            {activeType === "cards" && <button className={"small-white-button " + (activeType === "list"? "dashboard-active-selection": "")} style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("list")}><i className="fas fa-list-ul"></i></button>}
                        </div>
                    }         
                    {addNew && showAddNewOnList && (project ? 
                        <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                            <i className="fas fa-plus dash-plusIcon project-footer" style={{ backgroundColor: '#fff' }} onClick={() => {addNew()}}></i>
                            <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew()}}>Lisää</p>
                        </div>  
                        :
                        (selectedBusiness ?
                            <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                                <i className="fas fa-plus dash-plusIcon" style={{ backgroundColor: '#fff' }} onClick={() => {addNew(selectedBusiness)}}></i>
                                <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew(selectedBusiness)}}>Lisää</p>
                            </div>
                            :
                            <div className="addNew" style={{ marginLeft: '0.5em', marginRight: '0.5em', display: 'flex' }}>
                                <i className="fas fa-plus dash-plusIcon" style={{ backgroundColor: '#fff' }} onClick={() => {addNew()}}></i>
                                <p className="bold" style={{ marginLeft: '0.5em', fontSize: '0.8rem', marginTop: '0.25em', cursor: 'pointer' }} onClick={() => {addNew()}}>Lisää</p>
                            </div>  
                        )                           
                    )}
                   
                </div>
                {children}
            </motion.div>
        </Col>
    )
}
import React, { Component, useState, useRef, useEffect } from 'react';
import {
   Row,
   Col,
   Modal,
   ModalHeader,
   ModalBody
} from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import HelperBox from '../../../Utilities/HelperBox/HelperBox';
import { HorizontalContainer, HorizontalList, HorizontalItem } from '../../../Utilities/horizontal-scroller/HorizontalElements';
import { ClientTaskCards, ClientPlanCards, SurveyResultCards, HubSpotContactCard, HubSpotDealCard, ActiveClientCard, ExcelFieldCard } from '../../../Utilities/horizontal-scroller/HorizontalCards';
import { addTask } from '../../../Utilities/HelperFunctions/fetchPhaser'
import { useDispatch } from 'react-redux'
import { setActiveBusinessList } from '../../../Components/Reports/Dashboard/Actions/actionCreator';
import { VerticalContainer, VerticalItem, VerticalList } from '../../../Utilities/ListView/ListElements';
import { ActiveClientListItem } from '../../../Utilities/ListView/ListItems';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';

const INITIAL_FILTER = {
    first: null,
    second: null
}

const scoreOptions = [
    "Pisteet",
    "NPS"
]

export const ActiveBusinessList = (props) => {
   const { actBusinessList,  activeType, toggleType, search } = props;
   const [isLoading, setIsLoading] = useState(true);
   const [businessList, setBusinessList] = useState([])
   const [scoreFilterOpen, setScoreFilterOpen] = useState(false)
   const [scoreFilter, setScoreFilter] = useState(INITIAL_FILTER)
   const [selectedScore, setSelectedScore] = useState("NPS")
   
   const handleListNameFilter = business => {
      const {name, businessId, tags, lastActive} = business

      if(name && name.toUpperCase().includes(search.toUpperCase())) return true
      else if(businessId && businessId.toUpperCase().includes(search.toUpperCase())) return true

      if(tags !== undefined){     
          if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
      }
      if(lastActive !== undefined){
        let edited = moment.utc(lastActive,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
        if(edited === search) return true
        if(search.includes("-")){
            let dates = search.split("-")
            const [d0, m0, y0] = edited.split(".")
            const [d1, m1, y1] = dates[0].split(".")
            const [d2, m2, y2] = dates[1].split(".")
            let firstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y0+"."+m0+"."+d0))
            let secondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y0+"."+m0+"."+d0))
            // console.log(edited,moment(new Date(y1+"."+m1+"."+d1)), moment(new Date(y2+"."+m2+"."+d2)), firstOk, secondOk);
            if( firstOk && secondOk )return true
            else return false
        }
    }

      return false
  }

   const isMounted = useRef(true);

   const dispatch = useDispatch();
 
   useEffect(() => {
 
       const fetchActiveBusinessList = async params => {
           const response = await addTask('get/activeBusinessList', { }, dispatch)
           setTimeout(() => {
             if (!!response.activeList) {
               if (isMounted.current) {
                  setBusinessList(response.activeList)
                  dispatch(setActiveBusinessList(response.activeList))
               }
             }
             if (isMounted.current) setIsLoading(false) 
           }, 200)     
       }
 
       fetchActiveBusinessList();
 
       return () => {
           isMounted.current = false;
       }
   }, [])

   const handleScoreFilterChange = (data) => {
        const {id, value} = data
        setScoreFilter(
            {
                ...scoreFilter,
                [id]:value
            }
        )
   }

   const handleScoreFilter = (business) => {
        const {companyTotalScore, npsScore} = business
        if(scoreFilter.first && scoreFilter.first !== null && scoreFilter.second && scoreFilter.second != null){
            let first = scoreFilter.first
            let second = scoreFilter.second
            if(selectedScore === "NPS"){
                let score = npsScore.score
                let total = (npsScore.negative + npsScore.passive + npsScore.positive)
                if(score>=first && score<=second && (total > 0)) return true
                if(score>=second && score<=first && total > 0) return true
                if(score === 0 && total === 0 && first === "-"  && second === "-" ) return true
            } else {
                let total = companyTotalScore.followedCount
                let score = companyTotalScore.totalScore
                if(score>=first && score<=second && total > 0) return true
                if(score>=second && score<=first && total > 0) return true
                if(score === 0 && total === 0 && (first === "-" && second === '-')) return true
            }
        } 
        else return true
   }
 
   const [tempObject, setTempObject] = useState(null)
   const [isLoaderVisible, setIsLoaderVisible] = useState(false)
   const downloadCSV = async() => {
    setIsLoaderVisible(true)
    let data = businessList.filter(b => (handleListNameFilter(b) || b.businessId.toUpperCase().includes(search.toUpperCase())) && handleScoreFilter(b))

    let csvData = ""

    let headers = ("Y-TUNNUS;YRITYS;AVG;NPS;VIIM.PVM;VIIM.VAST" + "\n")

    csvData += headers

    for(var business of data){
        let lastActive = moment.utc(business.lastActive,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
        let avg = (business.companyTotalScore.followedCount && business.companyTotalScore.followedCount > 0) ? business.companyTotalScore.totalScore : ""
        let nps = (business.npsScore && (business.npsScore.negative + business.npsScore.passive + business.npsScore.positive) > 0)? business.npsScore.score : ""
        let latestAnswer = "" 
        if(business && business.id){
            let payload = {
                businessId: business.id
            }
            const response = await executeRequest('get/latestAnswers',payload)
            let resp = response[0]
            latestAnswer = resp && resp.answered ? moment.utc(resp.answered, "MMM D, YYYY hh:mm:ss A", "en").local().format("DD.MM.YYYY") :""
        }
        csvData += (business.businessId +";" + business.name +";" + avg +";" + nps +";" + lastActive + ";"+latestAnswer +"\n")
    }
    var now = moment.utc( new Date()).valueOf()
    setIsLoaderVisible(false)
    try{
        var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], { type: 'text/csv;charset=windows-1252;' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "yritykset_" + now +".csv";
        
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(link.href)
    } catch {}
   
   }

   const getBusinessLatestAnswer = async(businessId) => {
        let payload = {
            businessId: businessId
        }
        const response = await executeRequest('get/latestAnswers',payload)
        // console.log(response[0]);
        setTempObject(response[0])

   }
   const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '0rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

   return (
        <>  
            <Modal isOpen={isLoaderVisible}>
                <ModalHeader>Haetaan asiakkaiden tietoja</ModalHeader>
                <ModalBody>
                    <MegaLoaderSpinner/>
                </ModalBody>
            </Modal>
            { activeType === 'cards' ?
                <HorizontalContainer label={"AKTIIVISET ASIAKKAAT (" + businessList.filter(b => (handleListNameFilter(b) || b.businessId.toUpperCase().includes(search.toUpperCase())) && handleScoreFilter(b)).length +")"} activeType={activeType} toggleType={toggleType} listName={"clients"} downloadCSV={downloadCSV} scoreFilter={scoreFilter} scoreFilterOpen={scoreFilterOpen} setScoreFilterOpen={setScoreFilterOpen} handleScoreFilterChange={handleScoreFilterChange} selectedScore={selectedScore} setSelectedScore={setSelectedScore} scoreOptions={scoreOptions}>
                    <HorizontalList>
                    {businessList && businessList.filter(b => handleListNameFilter(b) || b.businessId.toUpperCase().includes(search.toUpperCase())).length > 0 ? 
                            businessList.filter(b => (handleListNameFilter(b) || b.businessId.toUpperCase().includes(search.toUpperCase())) && handleScoreFilter(b) ).map(business =>
                                <HorizontalItem key={business.id}>
                                        <ActiveClientCard business={business} select={props} />
                                </HorizontalItem>
                            ) : (
                                    <EmptyList isLoading={isLoading} />
                            )
                        }
                    </HorizontalList>
                </HorizontalContainer>
                :
                <VerticalContainer label={"AKTIIVISET ASIAKKAAT (" + businessList.filter(b => (handleListNameFilter(b) || b.businessId.toUpperCase().includes(search.toUpperCase())) && handleScoreFilter(b)).length +")"} activeType={activeType} toggleType={toggleType} listName={"clients"} downloadCSV={downloadCSV} scoreFilter={scoreFilter} scoreFilterOpen={scoreFilterOpen} setScoreFilterOpen={setScoreFilterOpen} handleScoreFilterChange={handleScoreFilterChange} selectedScore={selectedScore} setSelectedScore={setSelectedScore} scoreOptions={scoreOptions}>
                    {(businessList && businessList.filter(b => handleListNameFilter(b) || b.businessId.toUpperCase().includes(search.toUpperCase())).length > 0) ?
                        <VerticalList>
                            {businessList.filter(b => (handleListNameFilter(b) || b.businessId.toUpperCase().includes(search.toUpperCase())) && handleScoreFilter(b) ).map((business, key) => { 
                            let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                                return( 
                                    <VerticalItem key={business.id}>
                                        <ActiveClientListItem key={key} business={business} select={props} oddEvenClass={oddEvenClass}/>
                                    </VerticalItem>
                                )
                            })}
                        </VerticalList>
                        :
                        <EmptyList isLoading={isLoading} />
                    }
                </VerticalContainer>
            }
       
        </>
   )

}


const EmptyList = ({ isLoading, error = "" }) => {
   return (
       <div className="list-placeholder-container">
           {isLoading ?
               <ListLoadingSpinner />
               :
               <h3>{error === "" ? "Ei tuloksia" : error}</h3>
           }
       </div>
   )
}

const ListLoadingSpinner = () => {
   return (
       <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
           <span className="sr-only">Loading...</span>
       </div>
   )
}

export default ActiveBusinessList
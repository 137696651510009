import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as ActionCreator from '../../store/index';

class HelperBox extends React.Component {
    constructor(props) {
        super(props);
    }

    changeRoute = (to, label) => {
        this.props.setBreadcrumbs({ to: to, label: `${this.props.businessName} / ` + label });
        this.props.history.push(to)
    }
    render() {
        if (typeof this.props.onlyText !== 'undefined' && this.props.onlyText) {
            return (
                <div className="helper-box">
                    <div className="helper-box-div">
                        <div className="helper-box-content">
                            <h3>{this.props.text}</h3>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="helper-box">
                    <div className="helper-box-div">
                        <div className="helper-box-content">
                            <h3>{this.props.titleText || 'Näin pääset alkuun'}</h3>
                            <p>{this.props.text}</p>
                            <button className="small-white-button" style={{ marginTop: '1.5em', minWidth: 250 }} onClick={() => this.changeRoute(this.props.to, this.props.label)}>{this.props.buttonText}</button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        businessName: state.authentication.businessName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: (params) => dispatch(ActionCreator.setBreadcrumbs(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HelperBox));
import React, {useState, useEffect} from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, Table } from 'reactstrap';
import Select from 'react-select';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';

export const BusinessExcelModal = ({isOpen, toggle, targetBusiness, createExcelItem, item}) => {
    const [selectedData, setSelectedData] = useState()
    const [businessExcels, setBusinessExcels] = useState([])
    const [rowData, setRowData] = useState([])
    
    const getExcels = async() =>{
        let payload = {
            targetBusiness: targetBusiness.value
        }

        const response = await executeRequest('plan/excelsForPlan', payload)
        if(response && response.excelList){
            setBusinessExcels(response.excelList)
        }
    }
    useEffect(()=>{
        if(targetBusiness && targetBusiness.value !== "anon"){
            getExcels()
        }
    },[])

    const newExcelItem = () => {
        let payload = {
            ...item.selectedItem,
            topicContent: selectedData.label,
            excelData: selectedData
        }
        createExcelItem(payload, item.destinationIndex, item.destClone, item.planId);
        toggle();
    }

    useEffect(() => {
        if (selectedData && selectedData.data && selectedData.headers) {
            let rows = [];
            for (let i = 1; i <= selectedData.rowCount; i++) {
                let row = []
                for(let j = 0; j < selectedData.headers.length; j++){
                    row.push(selectedData.data[j].values[i-1])
                }
                rows.push(row)
            }
            setRowData(rows)
        }
    }, [selectedData])

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} className="taskModal">
                <div>
                    Lisää lomakkeeseen yrityksen excel
                </div> 
            </ModalHeader>
            <ModalBody>
                <Label>Valitse ladattu excel</Label>
                <Select
                    value={selectedData}
                    onChange={(selected) => setSelectedData(selected)}
                    options={businessExcels}
                    placeholder="Valitse excel"
                    isDisabled={false}
                    noOptionsMessage={() => { return "Yritykselle ei löytynyt exceleitä" }}
                    className="companyTargets"
                    classNamePrefix="select"
                />
                <div style={{ maxHeight: '20em', overflow: 'scroll' }}>
                    <Label style={{ marginTop: '1.5em'}}>Esikatselu</Label>
                    {selectedData && selectedData.data && rowData &&
                        <Table striped hover reflow >
                            <thead striped style={{position:"sticky", top:0, backgroundColor:"white"}}>
                                <tr>
                                    {selectedData.headers.map((head, i) => {
                                        return <th key={i}>{head || ''}</th>
                                    })}
                                    
                                </tr>
                            </thead>
                            <tbody >
                                    {rowData.map((row, ind) => {
                                            return <tr key={ind}>
                                                {row.map((r, rowInd) => {
                                                    return <td key={rowInd} >
                                                        {r}
                                                    </td>
                                                })}
                                            </tr>
                                    })}
                            </tbody>
                        </Table>
                    } 
                </div>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                <button className="small-white-button wide-button"  onClick={() => newExcelItem()}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

import React, { useState } from 'react';
import {Form, FormGroup, Label, Input} from 'reactstrap'
import './checklist.css'
export const TrelloChecklistContainer = ({checklist, handleUpdate}) => {
    const {name, checkItems} = checklist
    const [checklistItems, setCheckListitems] = useState(checkItems);

    const handleProgress = (checkItem, checked) => {
        handleUpdate(checkItem.id, checked ? 'complete' : 'incomplete');
        setCheckListitems(checklistItems => checklistItems.map(checkI => {
            if(checkI.id === checkItem.id) {
                return {
                    ...checkItem,
                    state: checked ? 'complete' : 'incomplete'
                }
            }
            return checkI;
        }))
    }
    return (
        <div style={{marginBottom: 24}}>
            <div style={{display: 'flex'}}>
                <i className="far fa-check-square trello" style={{paddingRight: '1.5em'}}></i>
                <h6>{name}</h6>
            </div>
            {checklistItems && <ProgressBar completed={checklistItems.filter(x => x.state !== 'incomplete').length} itemCount={checklistItems.length}/>}
            {checklistItems && <Checklist checkItems={checklistItems} handleProgress={handleProgress}/>}
        </div>
    )
}

const ProgressBar = ({completed, itemCount}) => {
    return (
        <div className="checklist-progress">
            <span className="percent">{completed / itemCount * 100}%</span>
            <div className="progress-bar-trello">
                <div className={completed === itemCount ? "progress-bar-progress progress-bar-current-complete": "progress-bar-progress"} style={{width: completed / itemCount * 100 + '%'}}></div>
            </div>
        </div>
    )
}

const Checklist = ({checkItems, handleProgress}) => {
    return (
        <div className="checklist-items-list">
            {checkItems.map(checkItem => 
                <CheckItem checkItem={checkItem} key={checkItem.id} changeStatus={handleProgress}/>
            )}
        </div>
    )
}

const CheckItem = ({checkItem, changeStatus}) => {
    
    const checkHandler = (event) => {
        event.stopPropagation();
        changeStatus(checkItem, checkItem.state !== 'complete');
    }

    return (
        <div onClick={checkHandler} className="checkItem-main-row">
            <div>
                <Input type="checkbox" id="checkbox2" style={{marginLeft: 0}} checked={checkItem.state === "complete"} onChange={() => { return }}/>{'   '}
            </div>
            <div style={{paddingLeft: '1.25rem'}} className={checkItem.state === "complete" ? "checklist-item-state-complete" : ""}>
                <div className="checklist-item-row"><span className="checklist-item-details-text">{checkItem.name}</span></div>
            </div>
        </div>
    )
}
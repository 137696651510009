import React, {useState, Fragment} from 'react';
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import {Row, Col} from 'reactstrap';
import { executeRequest } from '../../../../Utilities/requestHandler/dataHandler';



export const AttachmentContainer = ({answers, additionalClass}) => {
    const [isOpen, setOpen] = useState(false);

    const handleDownload = async(file) => {
        let payload = {
            fileId: file.id,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const response = await executeRequest('project/downloadFile', payload)
        if(response.data){
            try{
                var blob = new Blob([new Uint8Array(response.data.data)], { type: response.mimeType });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.name + "." + response.fileEnd;
        
                document.body.appendChild(link);
        
                link.click();
        
                document.body.removeChild(link);

            } catch {}
        }       
    }

    return (
        <Row className={additionalClass} style={{paddingTop: '1rem'}}>
                <Col md={{size: 12}}>

                    <h5 style={{marginBottom: 0}}>
                        <button className="small-white-button lighter-text wide-button" style={{margin: '.25rem 0 .5rem .5rem', float: 'right'}}
                            onClick={() => setOpen(!isOpen)}>{isOpen ? "Piilota liitteet" : "Näytä liitteet"}
                        </button>
                        Tiedostot
                    </h5>

                    <hr style={{clear: 'both'}}/>
                    {isOpen &&
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '300px' }}
                        className="custom-scrollbars" >

                        {answers && answers.map((file, index) => {
                        let className = index % 2 === 0 ? 'even-row' : 'odd-row'
                        {/*let buttonText = visibleIndex === index ? 'Piilota loppupalautteen arvot' : 'Näytä loppupalautteen arvot'*/}

                        return (
                            <Fragment key={index}>
                                <Row className={className} style={{ padding: '1rem' }}>
                                    <Col xs={{size: 10}}>
                                        <p>{file.name}</p>
                                        <p className="lighter-text">{ moment.utc( file.time, "MMM D, YYYY, HH:mm:ss A", "en" ).local().format( 'D.M.YYYY  HH.mm' ) }</p>
                                    </Col>
                                    <Col xs={{size: 1}} style={{textAlign: 'center'}}>
                                        <button className="small-white-button" style={{padding: '0.25rem 1rem'}} onClick={() => handleDownload(file)}>Lataa</button>
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                        })}

                </Scrollbars>
                }
          </Col>
        </Row>
    )
}

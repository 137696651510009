import React, { Component } from 'react';
import { Col, Row, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ChromePicker } from 'react-color';
import { confs } from '../../../../modules/config';
import Slider from 'rc-slider';


const bgcolor = {
   r: 0,
   g: 0,
   b: 0,
   a: 0
}
export default class BackgroundEditor extends Component {
   constructor(props) {
      super(props);
      this.state = {
         selected: false,
         blur: 2,
         color: bgcolor,
         toggleUpload: false
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (this.props.background && this.props.background !== prevProps.background) {
         if(this.props.background.id){
            this.setState({ 
               ...this.props.background, 
               selected: this.props.background.id
            })
         } else {
            this.setState({ selected:false })
         }
      } 
   }


   handleChangeComplete = (color) => {
      this.setState({ color: color.rgb });
   };

   save = () => {
      let obj = {
         id: this.state.selected,
         blur: this.state.blur
      }

      if (this.state.color !== bgcolor) {
         obj = { ...obj, color: this.state.color }
      }

      this.props.save(obj);
   }

   uploadBackground = (e) => {
      const formData = new FormData()
      formData.append("background", e.target.files[0], e.target.files[0].name)
      formData.append("jwtToken", window.sessionStorage.getItem("token"))
      this.props.uploadBackground(formData)
  };


   render() {
      const selected = this.state.selected;
      const list = this.props.list;
      let rgbColorString;
      if (this.state.color) {
         let color = this.state.color;
         rgbColorString = `inset 0 0 0 100vmax rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
      }

      return (
         <Modal size="lg" isOpen={this.props.isOpen} toggle={() => (this.props.toggle())}>
            <ModalHeader>
               <div style={{ display: 'flex' }}>
                  <h5>{selected ? 'Muokkaa taustakuvaa' : 'Valitse taustakuva'}</h5>
                  {selected &&
                     <button className="small-white-button lighter-text" style={{ marginLeft: 'auto', marginRight: '1em' }} onClick={() => this.setState({ selected: null })}>Vaihda kuvaa</button>
                  }
               </div>
            </ModalHeader>
            <ModalBody>
               {selected ?
                  <Row>
                     <Col md="8" style={{ overflow: 'hidden' }}>
                        <div style={{ margin: '0em 1.5em' }}>
                           <div className="background-edit" style={{ backgroundImage: `url(${confs.url}getBackground/${selected})`, boxShadow: this.state.color !== null ? rgbColorString : 'none', filter: `blur(${this.state.blur}px)` }}></div>
                        </div>
                        {/* <img src={`${confs.url}getBackground/${selected}`} style={{ boxShadow: this.state.bgColor !== null ? rgbColorString : 'none', filter: `blur(${this.state.blur}px)`, position: 'relative', width: '100%' }} /> */}
                     </Col>
                     <Col md="4">
                        <ChromePicker
                           onChangeComplete={this.handleChangeComplete}
                           color={this.state.color || '#fff'}
                        />
                        <Label style={{ marginTop: '1em' }}>Taustan sumeus</Label>
                        <Slider
                           dots
                           step={2}
                           defaultValue={this.state.blur}
                           min={0} max={8}
                           onAfterChange={(val) => this.setState({ blur: val })}
                           railStyle={{ backgroundColor: '#dedede' }}
                           trackStyle={{ backgroundColor: '#dedede' }}
                           dotStyle={{ borderColor: '#7d7d7d' }}
                           activeDotStyle={{ borderColor: '#b5e413' }}
                           handleStyle={{ borderColor: '#b5e413' }}
                        />
                     </Col>
                  </Row>
                  :
                  <Row>
                     {list && list.length > 0 && list.map((bg, i) => {
                        return (
                           <Col xs="3" key={i} style={{ width: '100%', marginBottom: '1em' }}>
                              <img className="background-image" src={`${confs.url}getBackground/${bg.url}`} style={{ width: '100%', height: 'auto', cursor: 'pointer' }} onClick={() => this.setState({ selected: bg.url })} />
                           </Col>
                        )
                     })}
                     <Col xs="3" style={{ width: '100%', marginBottom: '1em' }}>
                        <div className="background-image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer' }} onClick={() => this.setState({toggleUpload: true})}>
                           <i className="fas fa-plus" style={{ fontSize: '4em' }}></i>
                           <input onChange={this.uploadBackground} type="file" name="logo" style={{ opacity: 0, height: '100%', width: '100%', position: 'absolute', top: 0, cursor: 'pointer', zIndex: 99 }} />
                        </div>
                     </Col>
                  </Row>
               }


            </ModalBody>
            <ModalFooter>
               <a onClick={() => this.props.toggle()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a> {' '}
               {selected ? 
                  <button onClick={this.save} className="small-white-button wide-button" style={{ }}>Tallenna</button> 
                  :
                  <button onClick={this.save} className="small-white-button wide-button" style={{ }}>Käytä oletustaustakuvaa</button> 
               }
            </ModalFooter>
         </Modal>
      )
   }
}
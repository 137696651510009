import * as ActionTypes from './ActionTypes';
import { confs } from '../../../modules/config';
import * as ActionCreator from '../../index';
import { NotificationManager } from 'react-notifications';

export const updateEnterpriseDownloads = (payload) => {
    return {
        type: ActionTypes.UPDATE_ENTERPRISE_DOWNLOADS,
        payload: payload
    }
}

export const editEnterpriseDownloads = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }
    
    return dispatch => {
        fetch(confs.url + 'editEnterpriseDownloads', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
        .then(response => {
            if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(response && response.success){
                    NotificationManager.success("Tiedosto lisätty ladattaviin", "", 4000);
                    dispatch(updateEnterpriseDownloads(response.updated))
                    
                } else {
                    if(response.exists){
                        NotificationManager.error("Tiedosto on jo ladattavissa", "Virhe", 4000);
                    } else {
                        NotificationManager.error("Ongelma päivittäessä ladattavia tiedostoja", "Virhe", 3000);
                    }
                }
            }               
        }).catch(error => {
            // dispatch(fetchRequestFinished("failed"));
            NotificationManager.error("Ongelma päivittäessä ladattavia tiedostoja", "Virhe", 3000);
        })
    }
}

export const getEnterpriseDownloads = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }
    return dispatch => {
        fetch(confs.url + 'getEnterpriseDownloads', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
        .then(response => {
            if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(response){
                   dispatch(updateEnterpriseDownloads(response))
                } else {
                    NotificationManager.error("Ongelma päivittäessä ladattavia tiedostoja", "Virhe", 3000);
                }
            }               
        }).catch(error => {
            // dispatch(fetchRequestFinished("failed"));
            NotificationManager.error("Ongelma päivittäessä ladattavia tiedostoja", "Virhe", 3000);
        })
    }
}

import React from 'react';
import { Row, Col } from 'reactstrap';
import ComparisonBar from '../../Comparison/ComparisonBar';
import TextEditor from '../../../../Utilities/TextEditor/texteditor';
import FeedbackReport from './FeedbackReport';

const BarReportQuestions = props => {
    return (
        <Row className="report">
            <Col style={{height: '100%', overflow: 'auto', padding: '0 2rem'}}>
                {props.sentSurveys.map((survey, ssIndex) => {                
                    if (survey.type === 'feedback') {
                        return <FeedbackReport key={ssIndex} survey={survey} toggleModal={props.toggleIconUrlListModal} />
                    }
                    const surveyColor = survey.surveyColor;
                    
                    return typeof survey.surveyTotalStatistics !== 'undefined' && 
                        <div key={ssIndex}>
                            <Row className="survey-buttonfilters-row">
                                {survey.freetextAnswers && <Col md={{size: 3}} xs={{size: 12}}>
                                    <button className="report-button-filter" onClick={ () => props.toggleFreetextAnswersModal(survey.freetextAnswers) }>Näytä vapaatekstivastaukset</button>
                                </Col>}
                                {survey.dateQuestionAnswers && <Col md={{size: 3}} xs={{size: 12}}>
                                    <button className="report-button-filter" onClick={ () => props.toggleFreetextAnswersModal(survey.dateQuestionAnswers, true) }>Näytä päivämäärävastaukset</button>
                                </Col>}
                                {survey.finalFeedbackAnswers && <Col md={{size: 3}} xs={{size: 12}}>
                                    <button className="report-button-filter" onClick={ () => props.toggleFinalFeedbackAnswersModal(survey.finalFeedbackAnswers) }>Näytä loppupalauteen pyytäneet</button>
                                </Col>}
                                {survey.businessIdAnswers && <Col md={{size: 3}} xs={{size: 12}}>
                                    <button className="report-button-filter" onClick={ () => props.toggleBusinessIdModal(survey.businessIdAnswers) }>Yritysvastaukset</button>
                                </Col>}
                            </Row>
                            <Row className="survey-main-row">
                                <div className="ball-container">
                                <div className="large-score-ball" style={{backgroundColor: surveyColor, boxShadow: "0 0 0 2pt " + surveyColor}}>
                                    {survey.surveyTotalStatistics.avg}
                                </div>
                                </div>
                                <Col md={{size: 11}} className="survey-title-container super-border-left" style={{borderColor: surveyColor}}>
                                    <Row className="survey-title-row">
                                        <Col md={{size: 12}}>
                                            <h2>{survey.title}</h2>
                                            <hr />
                                            <TextEditor content={survey.text} onTextChange={() => {return}} readOnly={true} />
                                        </Col>
                                    </Row>
                                    {survey.questionGroups.map((group, gIndex) => {
                                        const groupColor = group.groupColor;

                                        return typeof group.groupTotalStatistics !== 'undefined' && 
                                        
                                        <Row className="questiongroup-main-row" key={gIndex}>
                                            <div className="ball-container">
                                                <div className="medium-score-barball" style={{backgroundColor: groupColor, boxShadow: "0 0 0 2pt " + groupColor}}>{group.groupTotalStatistics.avg}</div>
                                            </div>
                                            <Col md={{size: 11}} className="questiongroup-title-container-comparison super-border-left" style={{borderColor: groupColor}}>
                                                <Row className="questiongroup-title-row" style={{marginBottom: '3.5em'}}>
                                                    <Col md={{size: 12}}>
                                                        <h2>{group.title}</h2>
                                                        <ComparisonBar 
                                                        feedbacks={group.feedbacks} 
                                                        avg={group.groupTotalStatistics.avg} 
                                                        scores={group.groupTotalStatistics.scores} 
                                                        ballColor={groupColor}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {group.questions.map((question, qIndex) => {
                                                        if(question.answerStatistics) {
                                                            const questionColor = question.questionColor;
                                                            return (
                                                                <Col md={{size: 12}} className="question-title-container-comparison" key={qIndex}>
                                                                    <Row>
                                                                        <Col className="col-xl-auto" md="3">
                                                                        <div className="ball-container-comparison">
                                                                            <div className="small-score-barball" style={{backgroundColor: questionColor, boxShadow: "0 0 0 2pt " + questionColor}}>{question.answerStatistics.avg}</div>
                                                                        </div>
                                                                        </Col>
                                                                        <Col className="col-xl" md="9">        
                                                                        <h4>{question.text}</h4>
                                                                            <ComparisonBar 
                                                                            feedbacks={group.feedbacks} 
                                                                            avg={question.answerStatistics.avg} 
                                                                            scores={question.answerStatistics.scores} 
                                                                            ballColor={questionColor}/>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            )
                                                        }
                                                        return null
                                                    })}
                                                </Row>
                                            </Col> 
                                        </Row>
                                    })}
                                </Col>
                            </Row>
                        </div>
                })}
            </Col>
        </Row>
    )
}

export default BarReportQuestions;
import * as ActionTypes from '../../Components/Enterprise/Actions/ActionTypes';

const initialState = {
    enterprise: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.SET_ENTERPRISE:
            return {
                ...state,
                enterprise:{ 
                    ...state.enterprise,
                    ...action.payload
                }
            }
        case ActionTypes.SAVE_LOGO:
            return {
                ...state,
                enterprise: {
                    ...state.enterprise,
                    logo: action.payload
                }
            }
        case ActionTypes.SAVE_BACKGROUND:
            let styles = state.enterprise && state.enterprise.styles? state.enterprise.styles : {}
            return {
                ...state,
                enterprise: {
                    ...state.enterprise,
                    styles:{
                        ...styles,
                        backgroundImage: action.payload
                    }
                }
            }
        case ActionTypes.API_KEYS_IN_USE:
            if (action.payload.qlik) {
                let list = state.enterprise.qlikList
                if(action.payload.id){
                    list = list.filter((item) => item.id !== action.payload.id)
                }else{
                    if(list){
                        list = [...list, action.payload.qlik]
                    }else{
                        list = [action.payload.qlik]
                    }
                }
                
                return {
                    ...state,
                    enterprise: {
                        ...state.enterprise,
                        qlikList: list
                    }
                }
            } else {
                return {
                    ...state,
                    enterprise: {
                        ...state.enterprise,
                        ...action.payload
                    }
                }
            }
        case ActionTypes.ADD_EXCEL:
            return {
                ...state,
                enterprise:{
                    ...state.enterprise,
                    excels: [
                        ...state.enterprise.excels || [],
                        action.payload
                    ]
                }
            }
        case ActionTypes.NOT_ADDED_HUBSPOTS:
            return{
                ...state,
                enterprise:{
                    ...state.enterprise,
                    notAddedHubSpots: action.payload
                }
            }
        case ActionTypes.REMOVE_EXCEL:
            let excels = state.enterprise.excels.filter((exl) => exl.id !== action.payload)
            return {
                ...state,
                enterprise: {
                    ...state.enterprise,
                    excels: excels
                }
            }
        default:
            return state
    }
}

export default reducer;
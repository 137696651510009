import React, { Component, useState } from 'react'
import './addColor.css'
import {Col, Input, InputGroup, InputGroupAddon} from 'reactstrap'
import { TwitterPicker } from 'react-color';

const styles = {
  addColor: 'addColor',
  addColorPopover: 'addColorPopover',
  addColorClosedPopover: 'addColorClosedPopover',
  addColorButton: 'addColorButton',
  addColorPressedButton: 'addColorPressedButton',
  addColorBottomGradient: 'addColorBottomGradient',
  addColorInput: 'addColorInput',
  addColorConfirmButton: 'addColorConfirmButton'
}

const listOfDefaultColors = [
  '#B5E41370',
  '#ED565270', 
  '#FFCC0070', 
  '#7BDCB570', 
  '#00D08470', 
  '#8ED1FC70', 
  '#0693E370', 
  '#ABB8C370', 
  '#EB144C70', 
  '#F78DA770', 
  '#9900EF50'
];

export default class AddColorButton extends Component {
  // Start the popover closed
  state = {
    url: '',
    open: false,
    chosenColor: "#ffff"
  }

  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    this.setState({ chosenColor: this.props.backgroundColor? this.props.backgroundColor: "#ffffff"})
    document.addEventListener('click', this.closePopover)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closePopover)
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick = () => {
    this.preventNextClose = true
  }

  openPopover = () => {
    if (!this.state.open) {
      this.preventNextClose = true
      this.setState({
        open: true
      })
    }
  }

  closePopover = () => {
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false,
      })
    }

    this.preventNextClose = false
  }

  handleChange = (params, event) => {
    this.props.changeBackgroundColor(params)
    this.setState({chosenColor: params})
    this.closePopover()
  }

  changeColor = (color) => {
    let colorCode = "#"+color
    this.setState({chosenColor: colorCode})
  }

  render() {
    const popoverClassName = this.state.open ?
      styles.addColorPopover :
      styles.addColorClosedPopover
    const buttonClassName = this.state.open ?
      styles.addColorPressedButton :
      styles.addColorButton
    const colorValue = this.state.chosenColor.substring(1)
    return (
      <>
        <div className={styles.addColor}>
          <button
            className={buttonClassName}
            onMouseUp={this.openPopover}
            type="button"
          >
            <i className="material-icons" style={{fontSize: '20px'}}>format_color_fill</i>
          </button>
          {this.state.open &&
            <div className={popoverClassName} onClick={this.onPopoverClick} style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {listOfDefaultColors && listOfDefaultColors.map((color, index)=>{
                  return(
                    <div key={index} className="color-square" onClick={() => this.handleChange(color)} style={{backgroundColor: color}}></div>
                  )
                })}
                <div class="input-group mb-3" style={{ borderRadius:' 5px'}}>
                  <div class="input-group-append">
                    <span class="mySpanClass input-group-text" style={{ borderRadius:'5px 0 0 5px', backgroundColor:this.state.chosenColor}}>#</span>
                  </div>
                  <input  type="text" class="form-control" placeholder="FFFF" value={colorValue} onChange={(e) => this.changeColor(e.target.value)}/>
                  <div class="input-group-append">
                    <button className="small-white-button" onClick={() => this.handleChange(this.state.chosenColor)} style={{ borderRadius:'0 5px 5px 0'}}>Valitse</button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </>
    )
  }
}

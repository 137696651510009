import React, { Component } from 'react'
import {
  Row,
  Col
} from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'
import MultiSelect from "@kenshooui/react-multi-select";
import Select from 'react-select';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ActionCreator from '../../store/index';
import './comparison.css';
import {fetchStatus} from './actions/ActionCreator';
import {setComparisonNames, setComparisonReport, saveFirst, saveSecond} from '../comparisonTool/actions/ActionCreator'

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi kartoitusta...",
        noItemsMessage: "Valitse kartoitus",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "Kartoitus on jo valittuna toisesta listasta"
    }
};


class ComparisonContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstCompany: null,
            secondCompany: null,
            firstSelections: [],
            secondSelections: [],
            isCollapsed: props.fromBusiness !== undefined ? props.isCollapsed : true, // comparison tool made to look different with fromBusiness -prop when logged in as business user
        }
    }

    componentDidMount() {
        const { userInfo } = this.props
        const empty = [];

        if(this.props.comparisonReport.length > 0){
            this.props.resetComparisonNames(empty, empty);
            this.props.resetComparisonReport(empty);
            this.props.resetFirstColumn(empty);
            this.props.resetSecondColumn(empty);
        }
        
        if( userInfo.userType === 'enterprise' ) {
            this.props.getCompanies();
        } else {
            const company = {
                label: userInfo.businessName,
                value: userInfo.businessId
            }

            this.handleFirstCompany( company )
            this.handleSecondCompany( company )
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.fetchStatus){
                if( this.props.comparisonReport[0] !== undefined && this.props.comparisonReport[0]._id !== undefined){
                    this.props.fetchEnd();
                    this.props.history.push('/raportit/vertailu/raportti');
                }
            }
           
    }
    

    handleFirstCompany = company => {
        this.setState({firstCompany: company, firstSelections: []});

        if(Object.keys(company).length > 0) {
            let payload = {
                ...company,
                column: 1
            };

            this.props.onCompanySelection(payload);
        }
    }

    handleSecondCompany = company => {
        this.setState({secondCompany: company, secondSelections: []});

        if(Object.keys(company).length > 0) {
            let payload = {
                ...company,
                column: 2
            };

            this.props.onCompanySelection(payload);
        }
    }

    handleFirstSelections = selectedItems => {
        this.setState({firstSelections: selectedItems});
    }

    handleSecondSelections = selectedItems => {
        this.setState({secondSelections: selectedItems});
    }

    startComparison = () => {
        let payload = {
            firstColumn: [
                ...this.state.firstSelections
            ],
            secondColumn: [
                ...this.state.secondSelections
            ]
        };

        if(payload.firstColumn.length > 0 && payload.secondColumn.length > 0) {
            this.props.onCompare(payload);
            
        }
    }

    render() {
        const { fromBusiness } = this.props

        return (
            <div className={this.state.isCollapsed ? "dashboard-medium-box-collapsed" : "dashboard-medium-box"}>
                { fromBusiness ? (
                    <div className="bigger-text box-header">
                        <p>Vertaile raportteja</p>
                    </div>
                ) : (
                    <div onClick={() => this.setState({isCollapsed: !this.state.isCollapsed})} className="toggleHeader">
                        <div className={this.state.isCollapsed ? "box-header-collapsed bigger-text" : "box-header bigger-text"} style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="comparison-header">
                                <p>Vertaile raportteja <i style={{marginLeft: '.5rem'}} className={"fas " + (this.state.isCollapsed ? "fa-chevron-down" : "fa-chevron-up")}></i></p>
                                
                            </div> 
                        </div>
                    </div>
                )}
                
                {!this.state.isCollapsed &&
                    <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height: '500px' }}
                    className="custom-scrollbars"
                    >
                    <Col xl="12">
                        <Row>
                            <Col xl="4">
                                { fromBusiness !== undefined ? (
                                    <p className="big-text" style={{ marginTop: '.5rem' }}>1.) Valitse vertailtava kartoitus:</p>
                                ) : (
                                    <div>
                                        <p className="senderSteps">Valitse vertailun kohde</p>
                                        <Row>
                                            <CompanySelector options={this.props.linkedCompanies} selectedOption={this.state.firstCompany} handleCompanySelection={this.handleFirstCompany}/>
                                        </Row>
                                    </div>
                                ) }
                                
                                {this.props.firstSurveys.length > 0 &&
                                <div style={{paddingTop: '1rem'}}>
                                    <MultiSelect
                                        messages={customMessages.messages}
                                        items={this.props.firstSurveys}
                                        selectedItems={this.state.firstSelections}
                                        onChange={this.handleFirstSelections} 
                                        showSelectAll={false}
                                        showSelectedItems={false}
                                        wrapperClassName="multi-selector"
                                        isLocked={item => this.state.secondSelections.filter(x => x.id === item.id).length === 1}
                                        height={ fromBusiness !== undefined ? 300 : 400 }
                                    />
                                </div>
                                }   
                            </Col>
                            
                            { this.state.firstSelections.length > 0 &&  
                            <Col xl="4">
                                { fromBusiness !== undefined ? (
                                    <p className="big-text" style={{ marginTop: '.5rem' }}>2.) Valitse kartoitus johon verrataan:</p>
                                ) : (
                                    <div>
                                        <p className="senderSteps">Valitse vertailtava yritys</p>
                                        <Row>
                                            <CompanySelector options={this.props.linkedCompanies.filter(company => company.label !== 'Ei kohdeyritystä')} selectedOption={this.state.secondCompany} handleCompanySelection={this.handleSecondCompany}/>
                                        </Row>
                                    </div>
                                ) }
                                
                                {this.props.secondSurveys.length > 0 ? (
                                <div style={{paddingTop: '1rem'}}>
                                    <MultiSelect
                                        messages={customMessages.messages}
                                        items={this.props.secondSurveys}
                                        selectedItems={this.state.secondSelections}
                                        onChange={this.handleSecondSelections} 
                                        showSelectAll={false}
                                        showSelectedItems={false}
                                        wrapperClassName="multi-selector"
                                        isLocked={item => this.state.firstSelections.filter(x => x.id === item.id).length === 1}
                                        height={ 350 }
                                    />
                                </div>
                                ) : (
                                    <h3 style={{paddingTop: '2rem'}}>{this.state.secondCompany !== null ? "Yrityksellä ei ole kartoituksia" : ""}</h3>
                                )}
                            </Col>
                        }
                            <Col xl="4">
                            {(this.state.firstSelections.length > 0 && this.state.secondSelections.length > 0) &&
                                <button className="small-white-button lighter-text swb-hover" onClick={this.startComparison} style={{width: '100%', paddingTop: '2em', paddingBottom: '2em', marginTop: '3em'}}>Aloita vertailu</button>
                            }
                            </Col>
                        </Row>
                    </Col>
                    </Scrollbars>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        linkedCompanies: state.senderV2.linkedCompanies,
        firstSurveys: state.comparison.firstSurveys,
        secondSurveys: state.comparison.secondSurveys,
        comparisonReport: state.comparison.comparisonReport,
        fetchStatus: state.comparison.fetching,
        userInfo: state.authentication
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCompanies: (payload) => dispatch(ActionCreator.fetchLinkedCompanies(payload)),
        onCompanySelection: (payload) => dispatch(ActionCreator.getCompanySurveysForComparison(payload)),
        onCompare: (payload) => dispatch(ActionCreator.getComparisonReport(payload)),
        fetchEnd: (payload) => dispatch(fetchStatus(payload)),
        resetComparisonReport: (params) => dispatch(setComparisonReport(params)),
        resetComparisonNames: (params1, params2) => dispatch(setComparisonNames(params1, params2)),
        resetFirstColumn: (params) => dispatch(saveFirst(params)),
        resetSecondColumn: (params) => dispatch(saveSecond(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComparisonContainer));

const CompanySelector = props => {
    const {selectedOption} = props;
    const customStyles = {
        control: (base, state) => ({
          ...base,
          height: '58px',
          minHeight: '58px',
        }),
    }
    return (
        <Col xl="12" className="comparison-select">
            <Select value={selectedOption}
                onChange={props.handleCompanySelection}
                options={props.options}
                placeholder="Etsi yritys"
                isDisabled={false}
                noOptionsMessage={() => {return "Tuloksia ei löytynyt"}}
                styles={ customStyles }
                classNamePrefix="select"
            />
        </Col>
    )
}
import React, { Component } from 'react';
import GroupFeedbackItem from './subComponents/GroupFeedbackItem';
import { Row, Col, Button } from 'reactstrap';
import * as ActionCreator from '../../../../../../../store/index';
import { connect } from 'react-redux';
import ColorPicker from './subComponents/ColorPicker';
import Toggle from 'react-toggle';
import DebounceTextEditor from '../../../../../../../Utilities/TextEditor/DebounceTextEditor';
import { convertFromRaw, convertToRaw, EditorState, ContentState } from 'draft-js'
import { NotificationManager } from 'react-notifications';
import '../../kartoitus.css';
import { debounce } from "debounce";
import { stripUnicodeFromString } from '../../../../../../../Utilities/HelperFunctions/helper'
import GroupDeletionModal from './modals/GroupDeletionModal'
import QuestionGroupRangeDeletionModal from './modals/QuestionGroupRangeDeletionModal';

const AddItem = props => {
    return (
        <Col>
            <Button className="addOption" onClick={() => props.add()}>Uusi raja-arvo</Button>
        </Col>
    )
}

const emptyFeedback = () => {
    let contentState = ''
    contentState = ContentState.createFromText("")

    let editorState = EditorState.createWithContent(contentState);
    return convertToRaw(editorState.getCurrentContent())
}

class QuestionGroupEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groupName: this.props.group.title || "",
            groupText: this.props.group.text || "",
            title: "",
            titleChanged: false,
            styledGroupDescription: this.props.group.text || "",
            plaintextGroupDescription: this.props.group.plaintextGroupDescription || "",
            textChanged: false,
            feedbacks: this.props.group.feedbacks || [
                {
                    color: '#B5E413',
                    range: [0, 15],
                    text: ""
                }
            ],
            groupColor: this.props.group.groupColor || '#B5E413',
            colorInUse: this.props.group.colorInUse || false,
            currentGroup: this.props.group,
            targetPayload: {},
            originalHigh: 999,
            groupModal: false,
            payload: {},
            _reMount: false,
            rangeModal: false,
            rangePayload: {}
        }
        this.start = 600;
        this.repeatTimer = undefined;
        this.pointTarget = undefined;
        this.placeholderForPoints = undefined;
        this.sendingPoints = false;

        this.handleFeedBackChange = this.handleFeedBackChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addNewfeedBack = this.addNewfeedBack.bind(this);
        this.handleNumberValue = this.handleNumberValue.bind(this);
        this.colorHandler = this.colorHandler.bind(this);
        this.changeGroupColor = this.changeGroupColor.bind(this);
        this.handleColorSelector = this.handleColorSelector.bind(this);
    }

    handleFeedBackChange(index, value, plaintextFeedback = '') {
        let feedbacks = [
            ...this.state.feedbacks
        ];

        let targetPayload = {
            text: feedbacks[index].text,
            plaintextFeedback: feedbacks[index].plaintextFeedback,
            index: index
        }

        feedbacks[index].text = value;
        feedbacks[index].plaintextFeedback = plaintextFeedback;


        this.setState({ feedbacks: feedbacks, targetPayload: targetPayload });
        this.onBlurFeedback();
    }

    componentDidMount() {
        this.setState({
            title: this.props.group.title,
            styledGroupDescription: this.props.group.text,
            feedbacks: this.props.group.feedbacks || [
                {
                    color: '#B5E413',
                    range: [0, 15],
                    text: emptyFeedback()
                }
            ],
            groupColor: this.props.group.groupColor || '#B5E413',
            colorInUse: this.props.group.colorInUse || false,
            plaintextGroupDescription: this.props.group.plaintextGroupDescription,
            currentGroup: this.props.group
        }, () => {
            this.setState({
                editorId: this.props.group.id || 'new'
            })
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if (typeof prevProps.group !== 'undefined' && prevProps.group !== this.props.group) {

            this.setState({
                title: this.props.group.title,
                styledGroupDescription: this.props.group.text,
                feedbacks: this.props.group.feedbacks || [
                    {
                        color: '#B5E413',
                        range: [0, 15],
                        text: emptyFeedback()
                    }
                ],
                groupColor: this.props.group.groupColor || '#B5E413',
                colorInUse: this.props.group.colorInUse || false,
                plaintextGroupDescription: this.props.group.plaintextGroupDescription,
                currentGroup: this.props.group
            }, () => {
                this.setState({
                    editorId: this.props.category.original ? this.props.group.id : `${this.props.group.id}-${this.props.surveyId}-${this.props.selectedSurveyIndex}`
                })
            });
        }

        if(prevProps.surveyId !== this.props.surveyId) {
            this.setState({_reMount: !prevState._reMount});

            setTimeout( () => {
                this.setState(prevState => ({
                    _reMount: !prevState._reMount
                }))
            }, 300);
        }
    }

    handleChange(data) {
        this.setState(data);
    }

    colorHandler(colour, index) {
        let data = [
            ...this.state.feedbacks
        ];

        data[index].color = colour;
        this.setState({ feedbacks: data });

        let payload = {
            categoryId: this.props.categoryId,
            groupId: this.props.group.id,
            surveyId: this.props.surveyId,
            incomingData: {
                feedbacks: this.state.feedbacks
            }
        };

        this.props.category.original ? this.props.onGroupModified(payload) : this.props.onSentSurveyGroupModified(payload)
    }

    createNewFeedBackAndModifyPreviousValue(previousFeedbacks) {
        if(previousFeedbacks.length === 0) {
            let newFeedBackItem = {
                color: '#B5E413',
                range: [0, 100],
                text: emptyFeedback(),
                plaintextFeedback: ""
            };
            previousFeedbacks.push(newFeedBackItem);
        } else if(previousFeedbacks[previousFeedbacks.length - 1].range[0] === 0) {
            let newFeedBackItem = {
                color: '#B5E413',
                range: [(previousFeedbacks[previousFeedbacks.length - 1].range[1] / 2) + 1, 100],
                text: emptyFeedback(),
                plaintextFeedback: ""
            };
            
            let modifyPrevious = {
                ...previousFeedbacks[previousFeedbacks.length - 1],
                range: [0, newFeedBackItem.range[0] - 1]
            };

            previousFeedbacks[previousFeedbacks.length - 1] = {
                ...modifyPrevious
            };

            previousFeedbacks.push(newFeedBackItem);
        } else {
            //Ei laske niinkun pitäs, mutta tuntuu loksahvatan, ehkä parempi näin, aikasemmin yritti asettaa isompaa siivua kerralla
            let newFeedBackItem = {
                color: '#B5E413',
                range: [((100 - previousFeedbacks[previousFeedbacks.length - 1].range[1]) / (previousFeedbacks.length + 1)) + (previousFeedbacks[previousFeedbacks.length - 1].range[0] + 2), 100],
                text: emptyFeedback(),
                plaintextFeedback: ""
            };
            
            let modifyPrevious = {
                ...previousFeedbacks[previousFeedbacks.length - 1],
                range: [previousFeedbacks[previousFeedbacks.length - 1].range[0], newFeedBackItem.range[0] - 1]
            };

            previousFeedbacks[previousFeedbacks.length - 1] = {
                ...modifyPrevious
            };

            previousFeedbacks.push(newFeedBackItem);
        }
        return previousFeedbacks;
    }

    addNewfeedBack() {
        let feedbacks = [
            ...this.state.feedbacks
        ]

        let length = feedbacks.length;

        if (length === 0 || feedbacks[length - 1].range[0] <= 98) {
            /*let newFeedBackItem = {
                color: '#B5E413',
                range: [feedbacks[feedbacks.length - 1].range[1] + 1, feedbacks[feedbacks.length - 1].range[1] + 2],
                text: emptyFeedback(),
                plaintextFeedback: ""
            };

            feedbacks.push(newFeedBackItem);*/

            let payload = {
                categoryId: this.props.categoryId,
                groupId: this.props.group.id,
                incomingData: {
                    feedbacks: this.createNewFeedBackAndModifyPreviousValue(feedbacks)
                }
            };

            this.props.onGroupModified(payload);

        } else {
            NotificationManager.error("Uusia palautteita ei voida lisätä, pisteet eivät voi ylittää rajaa 100.", "Virhe", 3500);
        }
    }

    handleNumberValue(index, value) {
        if (!isNaN(value)) {
            let feedbacks = [
                ...this.state.feedbacks
            ];
            let valueToInt = parseInt(value);
            //If new value is higher than previous lower range and its not higher than 100
            if (valueToInt <= 100) {
                if (typeof feedbacks[index + 1] !== 'undefined') {

                    if (valueToInt > feedbacks[index].range[0]) {
                        if (valueToInt + 1 < feedbacks[index + 1].range[1]) {
                            feedbacks[index].range[1] = valueToInt;
                            feedbacks[index + 1].range[0] = valueToInt + 1;
                        }
                    }
                } else {
                    if (valueToInt > feedbacks[index].range[0]) {
                        feedbacks[index].range[1] = valueToInt;
                    }
                }
            }

            this.setState({ feedbacks: feedbacks });
        }
    }

    onButtonRelease = (event) => {
        clearTimeout(this.repeatTimer)

        this.start = 600;
        this.checkPoints();
    }

    checkPoints = debounce(() => {
        if (this.pointTarget !== undefined) {
            const { index } = this.pointTarget

            if (this.props.group !== undefined && this.props.group.feedbacks !== undefined) {

                let feedbacks = [
                    ...this.props.group.feedbacks
                ];

                if (feedbacks[index] !== undefined) {
                    if (!this.sendingPoints && (feedbacks[index].range[1] > this.placeholderForPoints.higherRange || feedbacks[index].range[1] < this.placeholderForPoints.higherRange)) {
                        let payload = {
                            categoryId: this.props.categoryId,
                            groupId: this.props.group.id,
                            incomingData: {
                                feedbacks: this.state.feedbacks
                            }
                        };
                        this.sendingPoints = true;
                        this.props.onGroupModified(payload)
                    }
                } else {
                    let payload = {
                        categoryId: this.props.categoryId,
                        groupId: this.props.group.id,
                        incomingData: {
                            feedbacks: this.state.feedbacks
                        }
                    };
                    this.props.onGroupModified(payload)
                }
            } else {
                let payload = {
                    categoryId: this.props.categoryId,
                    groupId: this.props.group.id,
                    incomingData: {
                        feedbacks: this.state.feedbacks
                    }
                };
                this.props.onGroupModified(payload)
            }
        }
    }, 1000)

    repeatPositiveButtonEvent = (values) => {
        if (values !== undefined) {
            this.pointTarget = values;
            this.placeholderForPoints = {
                ...JSON.parse(JSON.stringify(values))
            }
            this.sendingPoints = false;
        }

        if (this.pointTarget !== undefined) {
            this.pointTarget.higherRange += 1;
            this.handleNumberValue(this.pointTarget.index, this.pointTarget.higherRange);
        }

        this.repeatTimer = setTimeout(this.repeatPositiveButtonEvent, this.start)
        this.start = this.start / 2
    }

    repeatNegativeButtonEvent = (values) => {
        if (values !== undefined) {
            this.pointTarget = values;
            this.placeholderForPoints = {
                ...JSON.parse(JSON.stringify(values))
            }
            this.sendingPoints = false;
        }

        if (this.pointTarget !== undefined) {
            this.pointTarget.higherRange -= 1;
            this.handleNumberValue(this.pointTarget.index, this.pointTarget.higherRange);
        }

        this.repeatTimer = setTimeout(this.repeatNegativeButtonEvent, this.start)
        this.start = this.start / 2
    }

    feedbackNumberInput = (index, value) => {
        let feedbacks = [
            ...this.state.feedbacks
        ];

        let feedback = {
            ...feedbacks[index],
            scoreBefore: feedbacks[index].range[1]
        };
        //Input field is cleared
        if (value.length === 0) {
            feedback.range[1] = value;
            //Check that value is a number
        } else if (!isNaN(value)) {
            //100 is max value
            if (parseInt(value) <= 100) {
                feedback.range[1] = parseInt(value);
                //Check if there's more than 1 feedback, else just set the value and do nothing else
                if (typeof feedbacks[index + 1] !== 'undefined') {
                    //If value is higher than the lowerRange of the feedback && if the next element in feedbacks lowerRange holds higher value than the incoming value, delete isValid from object
                    if (parseInt(value) > feedback.range[0] && feedbacks[index + 1].range[0] > parseInt(value)) {
                        delete feedback["isValid"];
                    } else {
                        //Make sure value is still higher than range[0]
                        if (parseInt(value) > feedbacks[index].range[0]) {
                            //If value + 1 is less than next elements range[1], increase the range[0] in next element to value + 1
                            if (parseInt(value) + 1 < feedbacks[index + 1].range[1]) {
                                feedbacks[index].range[1] = parseInt(value);
                                feedbacks[index + 1].range[0] = parseInt(value) + 1;
                                delete feedback["isValid"];
                            } else {
                                feedback.isValid = false;
                            }
                        } else {
                            feedback.isValid = false;
                        }


                    }
                }

                feedbacks[index] = feedback;
            }
        }

        this.setState({ feedbacks: feedbacks });
        this.checkPoints();
    }

    changeGroupColor(color) {
        let payload = {
            categoryId: this.props.categoryId,
            groupId: this.props.group.id,
            surveyId: this.props.surveyId,
            incomingData: {
                groupColor: color
            }
        };

        this.props.category.original ? this.props.onGroupModified(payload) : this.props.onSentSurveyGroupModified(payload) 
    }

    handleColorSelector() {
        let payload = {
            categoryId: this.props.categoryId,
            groupId: this.props.group.id,
            surveyId: this.props.surveyId,
            incomingData: {
                colorInUse: !this.state.colorInUse,
                groupColor: this.state.groupColor
            }
        };
        this.props.category.original ? this.props.onGroupModified(payload) : this.props.onSentSurveyGroupModified(payload)
    }

    onGroupDelete = () => {
        this.props.deleteGroup(this.state.payload);
        this.setState({groupModal: false, payload: {}});
    }

    openGroupModal = (payload) => {
        if(!this.state.groupModal) {
            this.setState({groupModal: true, payload: payload});
        } else {
            this.setState({groupModal: false, payload: {}});
        }
    }

    openRangeModal = (payload) => {
        if(!this.state.rangeModal) {
            this.setState({rangeModal: true, rangePayload: payload});
        } else {
            this.setState({rangeModal: false, rangePayload: {}});
        }
    }

    proceedToDeleteFeedbackRange = () => {
       
        if(Object.keys(this.state.rangePayload).length > 0) {
            let payload = {
                categoryId: this.props.categoryId,
                groupId: this.props.group.id,
                feedbackRange: this.state.rangePayload
            }

            this.props.onFeedbackDelete(payload);
            this.setState({rangeModal: false});
        }
    }

    render() {
        return (
            
            <div className="question-Group">
                <GroupDeletionModal modal={this.state.groupModal} deleteGroup={this.onGroupDelete} toggle={this.openGroupModal}/>
                <QuestionGroupRangeDeletionModal modal={this.state.rangeModal} deleteRange={this.proceedToDeleteFeedbackRange} toggle={this.openRangeModal} />
                <Row className="">
                    <Col className="categoryContainer_Title">
                        {(this.props.category.original && this.props.group.id)  && <Button onClick={() => this.openGroupModal({categoryId: this.props.categoryId, groupId: this.props.group.id})} className="deleteButton">Poista ryhmä</Button>}
                        <h4>Muokkaa ryhmää</h4>
                    </Col>
                </Row>
                {!this.state._reMount && 
                    <Row className="">
                        <Col xl="12">
                            <h5>Kysymysryhmän nimi</h5>
                            <DebounceTextEditor
                                editorId={`${this.state.editorId}-groupTitle`}
                                content={this.props.group.title || ""}
                                handleSave={this.props.category.original ? this.props.onGroupModified : this.props.onSentSurveyGroupModified}
                                type="groupTitle"
                                autoFocus={this.props.group.autoFocus}
                                categoryId={this.props.categoryId}
                                groupId={this.props.group.id}
                                surveyId={this.props.surveyId}
                                plaintextEditor={true}
                                additionalClass="categoryTextArea"
                                spellCheck={ this.props.spellCheckOn }
                                connectionType={"surveys"}
                                documentId={this.props.surveyId}
                            />
                        </Col>
                    </Row>
                }
                {(this.props.group.title !== "" && !this.state._reMount)  &&
                    <Row className="">
                        <Col xl="12">
                            <h5>Selite</h5>
                            {/* <textarea rows="5" className="categoryTextArea" value={this.props.group.text} placeholder="Anna Kysymysryhmän selite" onChange={(e) => this.props.groupHandler({text: e.target.value})} /> */}
                            <DebounceTextEditor
                                editorId={this.state.editorId}
                                content={this.state.styledGroupDescription || ""}
                                handleSave={this.props.category.original ? this.props.onGroupModified : this.props.onSentSurveyGroupModified}
                                categoryId={this.props.categoryId}
                                groupId={this.props.group.id}
                                surveyId={this.props.surveyId}
                                type="groupDescription"
                                readOnly={false}
                                additionalClass="categoryTextArea"
                                saveDraftjsImage={this.props.saveDraftjsImage}
                                spellCheck={ this.props.spellCheckOn }
                                connectionType={"surveys"}
                                documentId={this.props.surveyId}
                            />
                            <small style={{ float: "right", color: "#000", marginBottom: "1em" }}>Voit lisätä tekstiin tyylejä ja linkkejä maalamalla tekstin osan, johon haluat näitä lisätä.</small>
                        </Col>

                        <Col>
                            <h5>Kysymysryhmän väri</h5>
                            <ColorPicker handleColor={this.changeGroupColor} color={this.state.groupColor} />
                        </Col>
                        <Col> 
                            <h5>Käytetäänkö väriä</h5>
                            <Row>
                                <Col>
                                    <Toggle
                                        checked={this.state.colorInUse}
                                        onChange={this.handleColorSelector} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {(this.props.group.title !== "" && !this.state._reMount) &&
                    <GroupFeedbacks
                        categoryId={this.props.categoryId}
                        groupId={this.props.group.id}
                        original={this.props.category.original}
                        questionGroup={this.props.group}
                        changeFeedBack={this.handleFeedBackChange}
                        onBlurFeedback={this.onBlurFeedback}
                        handleNumberValue={this.handleNumberValue}
                        textHandler={this.feedbackNumberInput}
                        handleColorChange={this.colorHandler}
                        addNewfeedBack={this.addNewfeedBack}
                        feedbacks={this.props.group.feedbacks}
                        repeatPositiveButtonEvent={this.repeatPositiveButtonEvent}
                        repeatNegativeButtonEvent={this.repeatNegativeButtonEvent}
                        onButtonRelease={this.onButtonRelease}
                        surveyId={this.props.surveyId}
                        handleSave={this.props.category.original ? this.props.onGroupModified : this.props.onSentSurveyGroupModified}
                        saveDraftjsImage={this.props.saveDraftjsImage}
                        spellCheckOn={ this.props.spellCheckOn }
                        openRangeModal={ this.openRangeModal }
                    />
                }
            </div>
        )
    }
}

const GroupFeedbacks = props => {
    return (
        <Row>
            <Col>
                <h5>Palaute ja raja-arvot</h5>
            </Col>
            <Col xl="12">
                {typeof props.feedbacks !== 'undefined' && props.feedbacks.map((feedback, x) => (
                    <div key={x} >
                        <hr></hr>
                        <GroupFeedbackItem
                            repeatPositiveButtonEvent={props.repeatPositiveButtonEvent}
                            repeatNegativeButtonEvent={props.repeatNegativeButtonEvent}
                            onButtonRelease={props.onButtonRelease}
                            questionGroup={props.questionGroup}
                            rangeLower={feedback.range[0]} rangeHigher={feedback.range[1]}
                            feedbackData={feedback}
                            feedbacks={props.feedbacks}
                            feedback={feedback.text}
                            handleSave={props.handleSave}
                            index={x}
                            original={props.original}
                            changeFeedBack={props.changeFeedBack}
                            handleNumberValue={props.handleNumberValue}
                            textHandler={props.feedbackNumberInput}
                            color={feedback.color}
                            handleColorChange={props.handleColorChange}
                            categoryId={props.categoryId}
                            groupId={props.groupId}
                            surveyId={props.surveyId}
                            saveDraftjsImage={props.saveDraftjsImage}
                            spellCheckOn={ props.spellCheckOn }
                            openRangeModal={ props.openRangeModal }
                        />
                    </div>
                )
                )}
                {props.original && <Row style={{paddingBottom: '1em'}}>
                    <AddItem add={props.addNewfeedBack} />
                </Row>}
            </Col>
        </Row>
    )
}

const mapStateToProps = state => {
    return {
        currentCategory: state.kartoitus.currentCategory,
        editingQuestionGroup: state.group.editedQuestionGroup
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGroupSave: (params) => dispatch(ActionCreator.saveGroup(params)),
        onGroupModified: (params) => dispatch(ActionCreator.modifyGroup(params)),
        onFeedbackDelete: (params) => dispatch(ActionCreator.deleteFeedbackRange(params)),
        onFieldEdit: (payload) => dispatch(ActionCreator.updateField(payload)),
        onSentSurveyGroupModified: (params) => dispatch(ActionCreator.modifySentSurveyGroup(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionGroupEdit);
import React, { useEffect, useState } from 'react';
import './product.css';
import { Container } from 'reactstrap';
import { ProductList } from './subComponents/ProductList';
import { executeRequest } from '../../Utilities/requestHandler/dataHandler';
import { NewProductModal } from './subComponents/NewProductModal';
import { ModifyProductModal } from './subComponents/ModifyProductModal';
import { addMoreBreadcrumbs, setBreadcrumbs } from '../../store';
import { useDispatch, useSelector } from 'react-redux';

const INITIAL_STATE = {
    productName: "",
    price: null,
    tags:[],
    description: null
}

export const ProductContainer = () => {
    const businessName = useSelector(state => state.authentication.businessName)
    const [createModal, setCreateModal] = useState(false)
    const [product, setProduct] = useState(INITIAL_STATE)
    const [productList, setProductList] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(INITIAL_STATE)
    const [modifyModal, setModifyModal] = useState(false)

    const dispatch = useDispatch();
    useEffect(()=>{
        
        dispatch( setBreadcrumbs( { to: 'tiedot', label: businessName } ) )
        dispatch(addMoreBreadcrumbs({ to: 'tuotteet', label: 'Tuotteet' }))
        getProducts()
    },[])

    const getProducts = async() => {
        const response = await executeRequest('product/getProductsForEnterprise', {})
        if(!!response){ 
            setProductList(response)
        }
    }

    return(
        <>
            <NewProductModal 
                isOpen={createModal} 
                toggleModal={setCreateModal}
                setProduct={setProduct}
                product={product}
                updateList={getProducts}
            />
            <ModifyProductModal
                isOpen={modifyModal} 
                toggleModal={setModifyModal}
                setSelectedProduct={setSelectedProduct}
                product={selectedProduct}
                updateList={getProducts}
            />
            <ProductList
                setCreateModal={setCreateModal}
                products={productList}
                setSelectedProduct={setSelectedProduct}
                setModifyModal={setModifyModal}
                updateList={getProducts}
            />              
        </>
    )

}

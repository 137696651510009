import * as ActionTypes from '../../../Components/Clients/actions/ActionTypes';

const initialState = {
    fetchingData: false,
    targetCompany: {},
    strippedList: [],
    collaborations: [],
    requestStatus: ""
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.COLLABORATION_COMPANY:
            return {
                ...state,
                targetCompany: action.payload
            }
        case ActionTypes.FETCHING_DATA:
            return {
                ...state,
                fetchingData: !state.fetchingData
            }
        case ActionTypes.SET_COLLABORATIONS:
            return {
                ...state,
                collaborations: [
                    ...action.payload
                ]
            }
        case ActionTypes.RESET_COLLABORATIONS:
            return {
                ...state,
                collaborations: []
            }
        case ActionTypes.UPDATE_BUSINESS_INFO:
            return {
                ...state,
                collaborations: state.collaborations.map(collab => {
                    if(collab._id === action.payload._id) return action.payload

                    return collab
                })
            }
        case ActionTypes.STRIPPED_LIST:
            return {
                ...state,
                strippedList: [
                    ...action.payload
                ]
            }
        case ActionTypes.REQUEST_STATUS:
            return {
                ...state,
                requestStatus: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
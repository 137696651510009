import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalFooter, ModalBody, Col, Row, Button, FormGroup, FormFeedback, Input } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import * as ActionCreator from '../../planBuilder/Actions/ActionCreator';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { motion } from 'framer-motion';
import { CreateFromTemplateModal } from '../../planBuilder/Modals/CreatePlanFromTemplate';
import moment from 'moment';
import { EmptyProjectList } from './ProjectLayout';
import { ListHeaderSorter } from './ListHeaderSorter';

export const TemplateList = ({ history, projectId, projectName, setPlanModalOpen}) => {
    const [planTemplates, setPlanTemplates] = useState()
    const [search, setSearch] = useState("")
    const [selectedData, setSelectedData] = useState({})
    const [menuOpen, setMenuOpen] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [isDelete, setIsDelete] = useState()
    const [emailList, setEmailList] = useState([])
    const [followEmailInputHasChanged, setFollowEmailInputHasChanged] = useState(false)
    const [followEmailIsValid, setFollowEmailIsValid] = useState(false)
    const [followModal, setFollowModal] = useState(false)
    const [followEmail, setFollowEmail] = useState("")
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [orderBy, setOrderBy] = useState("planName")
    const [sortedPlans, setSortedPlans] = useState()
    const [optionsOpen,setOptionsOpen] = useState(false)
    const [sortOrder, setSortOrder] = useState(1)

    const openAnswers = (id) => {
        if (id === this.state.answersId) {
            this.setState({ answersId: '' })

        } else {
            this.setState({ answersId: id })
        }
    }

    const dispatch = useDispatch()

    const startEditing = (planId) => {
        dispatch(ActionCreator.copiedFromTemplate(planId))
        history.push({
            pathname:'/lomakkeet/luonti',
            state:{
                crumbs:{
                    to: 'projektit/hallitse', label: projectName
                }, 
                projectId:projectId
            }
        })
    }
    const openTemplate = (planId) => {
        dispatch(ActionCreator.startEditingPlan(planId))
        history.push({
            pathname:'/lomakkeet/luonti',
            state:{
                crumbs:{
                    to: 'projektit/hallitse', label: projectName
                }, 
                projectId:projectId
            }
        })
    }

    useEffect(()=>{
        getAllPlanTemplates()
    },[])

    const getAllPlanTemplates = async() => {
        const response = await executeRequest('plan/getTemplatesForProject', {})
        if(!!response){
            setPlanTemplates(response)
        }
    }

    const handleListNameFilter = plan => {
        const {name, planName, selectedBusiness, tags} = plan
        
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        else if(planName && planName.toUpperCase().includes(search.toUpperCase())) return true
        else if(selectedBusiness !== undefined) {
            const {value, label} = selectedBusiness

            if(label.toUpperCase().includes(search.toUpperCase())) {
                return true
            } 
        } 

        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    const toggleModal = (data, x) => {
        setIsDelete(x)
        setSelectedData(data) 
        setModalOpen(!modalOpen)
    }

    const toggleDropdown = (y) => {
        if (menuOpen === y) {
            setMenuOpen(null)
        } else {
            setMenuOpen(y)
        }
    }
    const [startCopy, setStartCopy] = useState(false)

    const toggleTemplateModal = (bool,plan) =>{
        setStartCopy(bool)
        setSelectedPlan(plan)
    }

    useEffect(()=>{
        if(planTemplates){
            setSortedPlans(listSorter())
        }
    },[orderBy, sortOrder,planTemplates])

    const listSorter = () => {
        let sortedArray = [...planTemplates]
        let orderVal1 = sortOrder
        let orderVal2 = sortOrder === 1? -1 : 1

        if(orderBy === "planName"){
            sortedArray.sort((a,b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase()) ? orderVal1 : orderVal2)
            return [...sortedArray]
        } else{
            if(orderBy !== "modified" && orderBy !== "created"){
                sortedArray.sort((a,b) => (a[orderBy] > b[orderBy]) ? orderVal1 : orderVal2)
                return [...sortedArray]
            }
            else {
                sortedArray.sort((a,b) => (new Date(a[orderBy]) > new Date(b[orderBy])) ? orderVal1 : orderVal2)
                return [...sortedArray]
            }
        }
    }

    const handleSortOrder = (attribute) => {
        if(orderBy !== attribute){
            setOrderBy(attribute)
            if(attribute === "planName"){
                setSortOrder(1)
            } else {
                setSortOrder(-1)
            }
        } else {
            setSortOrder(sortOrder === -1? 1 : -1)
        }
    }

    let i = 0;

    return(  
        <>
            {selectedPlan &&
                <CreateFromTemplateModal
                    copyModalOpen={startCopy}
                    setCopyModalOpen={setStartCopy}
                    planId={selectedPlan._id || null}
                    projectId={projectId}
                    startEditing={startEditing}

                />
            }
            <div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ ease: 'easeIn', duration: 0.3 }}
                    exit={{ opacity: 0, x: -50 }}
                    key={"plans"}
                >
                    <div> 
                        <Row className="tab-header bigger-text" style={{ display: 'flex', alignItems: 'center'}}> 
                            <Col xs="5">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1em' }}>
                                    <Input type="search" className="search-bar-open" placeholder={"Hae"} value={search} onChange={(event) => setSearch(event.target.value)} />
                                </div>
                            </Col>
                            <Col xs="3">
                            </Col>
                            <Col xs="4">
                                <button className="small-white-button wide-button project-survey-button" style={{ float: 'right' }} onClick={() => setPlanModalOpen("template")} >
                                    Luo uusi pohja
                                </button>
                            </Col>
                        </Row>
                        <Row style={{ fontSize:'0.9rem', marginBottom:'0.25em' }}>
                            <Col xs="5" style={{ cursor:'pointer', marginLeft:'1.25em'}}>
                                <ListHeaderSorter label={"Nimi"} name={"planName"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder}/>
                            </Col>
                            <Col xs="2" style={{ marginLeft:'-1.5em'}}>
                                Tagit
                            </Col>
                            <Col></Col>
                        </Row>
                        <div>
                            {planTemplates && planTemplates.length > 0 ?
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "100%", height: '500px', borderRadius: '10px'}}
                                    className="custom-scrollbars"
                                >
                                    {sortedPlans && sortedPlans.length > 0 && sortedPlans.filter(p => handleListNameFilter(p) || p.creator.toUpperCase().includes(search.toUpperCase())).map((plan, y)=>{
                                        //{sentSurveys.length > 0 && sentSurveys.filter(survey => statusFilter(survey)).map((sentSurvey, index) => {                        
                                        let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                        i++;
                                        
                                        const {planName} = plan;
                                        return (
                                            <div style={{padding:'1em '}} key={y}  className={oddEvenClass + " list-row "} onClick={() => plan.automation ? openAnswers(plan._id) : openTemplate(plan._id)}>
                                                <Row>
                                                    <Col xs="5">
                                                        <p className="content-text name-overflow-handler-long" title={planName}>{planName}</p>
                                                        {plan.selectedBusiness &&
                                                            <p style={{ fontWeight:300, fontSize: '0.8rem' }}>{plan.selectedBusiness.label}</p>
                                                        }
                                                    </Col>
                                                    <Col xs="2" style={{ display: 'flex', flexDirection: 'row' }}>
                                                        {plan.tags&& plan.tags.slice(0, 3).map((tag, i) => {                        
                                                            return(<div key={i} style={{ marginLeft: '0.25em', paddingTop:"0.25em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                        })}                                                       
                                                        {plan.tags && plan.tags.length > 3 &&  
                                                            <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                                                <i className="material-icons" title={tagsToMore(plan.tags,3)}>more_horiz</i>
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col xs="4" style={{ textAlign: 'center'}}>
                                                        <button className="small-white-button wide-button " style={{ fontSize: '0.8rem'}} onClick={(e) => (e.stopPropagation(), toggleTemplateModal(true,plan))} >
                                                            Luo lomake
                                                        </button>
                                                    </Col>
                                                    <Col xs="1">
                                                        <Dropdown isOpen={menuOpen === y} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent:'center' }} onClick={(event) => event.stopPropagation()} toggle={() => toggleDropdown(y)}>
                                                            <DropdownToggle tag="div" style={{  float: 'right', fontSize: '1rem' }}>
                                                                <i className="fas fa-bars" title="Valikko"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={() => startEditing(plan._id)}>Muokkaa</DropdownItem>
                                                                <DropdownItem onClick={() => toggleTemplateModal(true,plan)}>Luo pohjasta kopio</DropdownItem>
                                                                {/* <DropdownItem onClick={() => toggleModal(plan, true)}>Poista</DropdownItem> */}
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </Col>
                                                </Row> 
                                            </div>                     
                                        )                       
                                    })}
                                </Scrollbars>
                                :
                                <EmptyProjectList listText={"Ei lomakepohjia"} />
                            }
                        </div>                   
                    </div>
                </motion.div>
            </div>
        </>
    )
}
const tagsToMore = (tags, slicer) => {
    var tagss = ""
    if(tags !== undefined ){
        tags.slice(slicer, tags.length).filter(t => tagss = tagss + "  " + t.label.toString())
        return(tagss)
    }
}
import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

const RiskListModal = props => {
    const {isOpen, riskList, riskLevel, riskOdds, riskModal} = props;
    if(isOpen && !riskModal) {
        if(riskList.filter(x => x.riskLevel.value === riskLevel.value && x.riskOdds.value === riskOdds.value).length === 0)
            props.closed()
        return ( 
                <Modal isOpen={isOpen} toggle={props.closed} centered size="lg">
                    <ModalHeader toggle={props.closed} className="taskModal">
                        <div style={{display: 'flex'}}>
                            <p>Riskiarviot - {riskOdds.label} - {riskLevel.label}</p>
                            <span className="modalRiskColorSquare" style={{backgroundColor: props.squareColor}}></span>
                        </div>
                    </ModalHeader>
                    <ModalBody style={{padding: 0}}>
                        <Scrollbars
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            style={{ width: "100%", height: '500px' }}
                            className="custom-scrollbars"
                        >
                            {(riskList !== null && riskLevel !== null && riskOdds !== null ) && riskList.filter(x => x.riskLevel.value === riskLevel.value && x.riskOdds.value === riskOdds.value).map((risk, index) => {
                                let creationDate = risk.created ? moment(new Date(risk.created)).format('DD.MM.YYYY') : ''
                                let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'

                                return <div className={oddEvenClass + ' selectable-row modal-risk-list-row'} style={{ paddingLeft: '1rem' }} key={index} onClick={() => props.modifyRisk(risk)}>
                                            <Row>
                                                <Col className="riskIconCol">
                                                    <div style={{display: 'flex', height: '100%'}}>
                                                    <i className="fas fa-exclamation-triangle" style={{paddingRight: '0.5em', color: risk.color, fontSize: '1.5em', alignSelf: 'center'}}></i>
                                                    </div>
                                                </Col>
                                                <Col xs="10">
                                                    <Row className='modal-risk-list-content'>
                                                    <Col xs={{ size: 6 }}>
                                                        {risk.riskName && <p style={{ fontWeight: 'bold' }}>{risk.riskName}</p>}
                                                        <p>{riskOdds.label} - {riskLevel.label}</p>
                                                    </Col>
                                                    <Col xs={{ size: 3 }}>
                                                        <p>{risk.riskGroup.label}</p>
                                                    </Col>
                                                    <Col xs={{ size: 3 }}>
                                                        <p>{risk.riskClassification.label}</p>
                                                    </Col>
                                                    </Row>
                                                    <Row>
                                                    <Col xs={{ size: 6 }}>
                                                        <div className="tasksText">
                                                            <span className="overflower-short lighter-text">{risk.explanationPlainContent}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ size: 3 }}>
                                                        <p className="lighter-text">{risk.targetCompanyName}</p>
                                                    </Col>
                                                    <Col xs={{ size: 3 }}>
                                                        <p className="lighter-text">{creationDate}</p>
                                                    </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                            })}
                            </Scrollbars>
                    </ModalBody>
                    <ModalFooter className="taskCreatorFooter">
                        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => props.closed()}>Takaisin</a>
                    </ModalFooter>
            </Modal>
        )}
    else
        return null
    
}

export default RiskListModal;
import React, {useState} from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input, Button, Label } from 'reactstrap';

const PasswordModal = ({isOpen, toggle, isPasswordOn, saveChanges}) => {
    const [passwordOn, setPasswordOn] = useState(isPasswordOn);
    const [password, setPassword] = useState("");
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} className="taskModal">
                <div>
                    Salasanan asetukset
                </div> 
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xl="12" style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                    <button className="small-white-button lighter-text wide-button" onClick={() => setPasswordOn(!passwordOn)} style={passwordOn ? {backgroundColor: 'red', color: '#FFF', fontWeight: 700} : {}}>
                            {passwordOn ? "Ota salasana pois käytöstä" : "Ota salasana käyttöön"}</button>
                    </Col>
                    <Col xl="6">
                        {passwordOn &&
                        <>
                            <Label>
                                Uusi salasana
                            </Label>
                            <Input type="password" className="new-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                {((passwordOn && password.length > 0) || !passwordOn) && <button className="small-white-button wide-button" onClick={() => saveChanges({passwordOn: passwordOn, newPassword: password})}>Tallenna</button>}
            </ModalFooter>
        </Modal>
    )
}

export default PasswordModal;
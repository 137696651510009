import React from 'react';
import MultiSelect from "@kenshooui/react-multi-select";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {confs} from '../../../modules/config';
import ExcelItem from './previewItems/ExcelItem';
import DemoBlock from './previewItems/DemoBlock';
import TrelloItem from './previewItems/TrelloItem';

import {NotificationManager} from 'react-notifications';

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi lohkoa...",
        noItemsMessage: "Ei lohkoja",
        noneSelectedMessage: "Valitse lohko",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinta",
        disabledItemsTooltip: "Voit valita vain yhden upotuksen"
    }
};

const MegaLoaderSpinner = () => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

const BusinessEmbedSelectorModal = ({open, toggle, targetBusiness, item, promptSave, type = 'selector', createEmbedItem, clearItems}) => {
    const [isLoading, setLoading] = React.useState(false);
    const [availableEmbeds, setAvailableEmbeds] = React.useState([]);
    const [selectedEmbeds, setSelectedEmbeds] = React.useState((item.targetSurvey && Object.keys(item.targetSurvey).length > 0) ? [item.targetSurvey] : [])

    React.useEffect(() => {
        if(open) {
            handleEmbedsFetch();
        }
    }, [open])

    const handleEmbedsFetch = async () => {
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            businessId: targetBusiness ? targetBusiness.value : ''
        }

        setLoading(true);

        try {
            const response = await fetch( confs.url + 'plan/availableEmbeds', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )

            if(response.success === undefined) {
                const responseJson = await response.json();

                setAvailableEmbeds(responseJson);
                
            }
        } catch(err) {
            NotificationManager.error("Valitettavasti lohkojen haussa tapahtui virhe", "", 3000);
        }

        setLoading(false);
    }

    const filterToGetLastSelection = async (items) => {
        if(items.length <= 1)
            return items;

        return items.filter(item => item.id !== selectedEmbeds[0].id);
        
    }

    const handleEmbedSelection = async selectedItems => {

        const filteredSelection = await filterToGetLastSelection(selectedItems);

        setSelectedEmbeds(filteredSelection);
    }

    const finalizeEmbedSelection = () => {
        //onItemChange(itemPayload);
        if(type === 'creation') {
            let payload = {
                ...item.selectedItem,
                selectedEmbed: {
                    ...selectedEmbeds[0]
                },
                businessId: targetBusiness.value
            }

            createEmbedItem(payload, item.destinationIndex, item.destClone, item.planId);
            
            clearItems();
        } 

        toggle();
    }

    const handlePreviewComponents = () => {
        let item = {
            ...selectedEmbeds[0]
        }

        
        switch(item.group) {
            case 'Excel':
                return <ExcelItem itemId={item.id} businessId={targetBusiness.value} />
            case 'list':
                return <DemoBlock itemId={item.id} businessId={targetBusiness.value} type="list"/>
            case 'iframe':
                return <DemoBlock itemId={item.id} businessId={targetBusiness.value} type="iframe"/>
            case 'Trello':
                return <TrelloItem itemId={item.id} businessId={targetBusiness.value} />
            default:
                return <div><h1>Valitse lohko</h1></div>
        }
    }

    
    return (
        <Modal isOpen={open} toggle={toggle} centered size="xl">
                <ModalHeader toggle={toggle} className="taskModal">
                    <div>
                        Valitse näytettävä lohko
                    </div> 
                </ModalHeader>
                <ModalBody>
                    {isLoading ? <div className="taskCreatorRow" style={{height: 600}}><MegaLoaderSpinner /></div> : 
                    <Row className="taskCreatorRow">
                        <Col xl="6" style={{height: 600}}>
                            <MultiSelect
                                messages={customMessages.messages}
                                items={availableEmbeds}
                                selectedItems={selectedEmbeds}
                                onChange={handleEmbedSelection}
                                showSelectAll={false}
                                showSelectedItems={false}
                                wrapperClassName="multi-selector"
                                height={600}
                                responsiveHeight={'100%'}
                                withGrouping
                            />
                        </Col>
                        <Col xl="6" style={{height: 600}}>
                            {handlePreviewComponents()}
                        </Col>
                </Row>}
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                {selectedEmbeds.length > 0 && <button className="small-white-button wide-button" onClick={() => finalizeEmbedSelection()}>Tallenna</button>}
            </ModalFooter>
        </Modal>
    )

}

export default BusinessEmbedSelectorModal;
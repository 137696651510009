import React, {useState, useEffect} from 'react';
import { Row, Col, DropdownToggle, Dropdown, DropdownItem, DropdownMenu, Table } from 'reactstrap';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import { ModifiedContainer } from './DataContainers';

export const BusinessExcelBlock = ({item,editable,onDelete,toggleItemVisibility, index, promptSave, layoutPreview = false}) => {
    const [rowData, setRowData] = useState([])
    const [openMenu, toggleMenu] = useState(false)
    const [title, setTitle] = useState(item.topicContent)

    useEffect(() => {
        if (item && item.excelData && item.excelData.data && item.excelData.headers) {
            let rows = [];
            for (let i = 1; i <= item.excelData.rowCount; i++) {
                let row = []
                for(let j = 0; j < item.excelData.headers.length; j++){
                    row.push(item.excelData.data[j].values[i-1])
                }
                rows.push(row)
            }
            setRowData(rows)
        }
    }, [item])

    const handleDataChanges = (data) => {
        setTitle(data)
        let itemPayload = {
            ...item,
            topicContent: data
        }
        promptSave(itemPayload)
    }

    return (
        <>
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                <Col xl="12">
                    {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red'}}>Yksityinen</span>}
                    {(!editable && !layoutPreview && item.modified !== undefined) && 
                        <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                    }
                </Col>
                <Col xl="12" className="plan-text-col">  
                    <div style={{ display: 'flex' }}>
                        {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}</div>}                      
                        <TextEditor
                                editorId={`${item.id}-${editable}`} 
                                content={title}
                                onTextChange={(data) => handleDataChanges(data)} 
                                additionalClass="plan-input subTitle"
                                placeholder="Otsikko"
                                readOnly={!editable}
                                plaintextEditor={true}
                            />
                    </div>
                </Col>
                <Col xl="11" className="plan-text-col">            
                    <div style={{ maxHeight: '20em', overflow: 'scroll' }}>
                        {item && item.excelData && item.excelData.data &&
                            <Table striped hover reflow >
                                <thead striped style={{position:"sticky", top:0, backgroundColor:"white"}}>
                                    <tr>
                                        {item.excelData.headers.map((head, i) => {
                                            return <th key={i}>{head || ''}</th>
                                        })}   
                                    </tr>
                                </thead>
                                <tbody >
                                    {rowData.map((row, ind) => {
                                        return <tr key={ind}>
                                            {row.map((r, rowInd) => {
                                                return <td key={rowInd} >
                                                    {r}
                                                </td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        }
                    </div>
                </Col>
                <Col xl="1" style={{paddingLeft: 15}} className="plan-dropdown-col">
                    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                    {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                        <DropdownToggle tag="div">
                            <i className="fas fa-bars plusIcon" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                            <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.blockName})} className="delete-plan-item">Poista</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                    </div>
                </Col>
            </Row>
        </>
    )
}

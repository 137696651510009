import React from 'react';
import BarReport from './BarReport';
import BarReportQuestions from './BarReportQuestions';

import './home.css';
import {confs} from '../../../modules/config';
import {Row, Col} from 'reactstrap';

const ReportItem = ({id, planId, saveDateRange, item}) => {
    const [sentSurveys, setSentSurveys] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);
    const [reportType, setReportType] = React.useState('bar');

    React.useEffect(() => {
        let dates = null
        if(item.dateRange){
          dates = item.dateRange
        }
        getReportData(id, planId, dates)
    }, [])

    const getReportData = (id, planId, dates = null) => {
        let payload = {
            target: id,
            planId: planId
        }

        if(dates){
            payload = {
                ...payload,
                dateRange: dates
            }
        }

        return new Promise( async ( resolve ) => {
            const resultJson = await fetch( confs.url + 'homepage/planReport', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )
    
            const response = await resultJson.json()
    
            if(response.surveys) {
                setSentSurveys(response.surveys);
            }

            setTimeout(() => {
                setLoading(false)
            }, 500);

            resolve()
        })
    }

    const handleDateSelection = (props) => {
        let payload = {
            ...item,
            dateRange:props
        }
        saveDateRange(payload)
        getReportData(id, planId, props)
    }
    
    const reportProps = {
        sentSurveys: sentSurveys,
        reportType: reportType,
        setReportType: setReportType,
        handleDateSelection: handleDateSelection,
        item: item
    }

    return (
        <div>
            {isLoading ? <FetchPlaceHolder /> 
            :
            <>
                {sentSurveys.length === 0 ? <NoReportsPlaceholder /> :
                    <>
                    { reportType === 'bar' && <BarReport {...reportProps} /> }
                    { reportType === 'barQuestions' && <BarReportQuestions {...reportProps} /> }             
                    </>
                }
            </>
            }
        </div>
    )
}

const FetchPlaceHolder = () => {
    return (
        <Row className="report">
            <Col style={{ padding: '0 2rem' }}>
                <Row className="survey-buttonfilters-row"></Row>
                <Row className="survey-main-row" style={{ paddingBottom: '5rem' }}>
                    <Col className="text-center">
                        <h4>Haetaan tuloksia</h4>
                    </Col>
                    <MegaLoaderSpinner />
                </Row>
            </Col>
        </Row>
    )
}

const NoReportsPlaceholder = () => {
    return (
        <Row className="report">
            <Col style={{ padding: '0 2rem' }}>
                <Row className="survey-buttonfilters-row"></Row>
                <Row className="survey-main-row" style={{ paddingBottom: '5rem' }}>
                    <Col className="text-center">
                        <h4>Vastauksia ei vielä ole</h4>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}

const MegaLoaderSpinner = () => {
    return (
        <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
    )
}

export default ReportItem;
import React from 'react';
import {motion} from 'framer-motion';

const FilterButton = ({filterItem, handleMainFilters, key}) => {
    return (
        <motion.div
            initial={{opacity: 0, x: 100}}
            animate={{opacity: 1, x: 0}}
            transition={{ease: 'easeOut', duration: 0.2}}
            exit={{opacity: 0, x: -50}}
            key={filterItem.target}
        >
            <div key={filterItem.target} title={ filterItem.target } className="filter-nibblet mainFilter" onClick={ () => handleMainFilters( filterItem ) }>{ filterItem.target }</div>
        </motion.div>
    )
}

export default FilterButton; 
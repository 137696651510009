import * as ActionTypes from '../../Components/Embed/actions/ActionTypes'

const initialState = {
    embedList: null,
    edit: null,
    sentSurveys: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_EMBED:
            return {
                ...state,
                embedList: action.payload
            }
        case ActionTypes.SET_EDIT:
            return {
                ...state,
                edit: action.payload
            }
        case ActionTypes.SET_SENTSURVEYS:
            return {
                ...state,
                sentSurveys: action.payload
            }
        case ActionTypes.SET_INITIALSTATE:
            return {
                initialState
            }
        default:
            return state
    }
}

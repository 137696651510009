import React, { useEffect, useState, useMemo }from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col,  Label } from "reactstrap";
import './planLayoutEdit.css'
import GridLayout,{Responsive, WidthProvider} from "react-grid-layout"
import { convertFromRaw } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { checkPreviewItemType } from "../../PlanbuiderContainer";

// in case we need responseive grid
//const ResponseiveGridLayout = WidthProvider(Responsive);


export const PlanLayoutEditModal = ({modalOpen, toggle, plan, savePlanLayout, planPreviewProps}) => {
    const [layoutType, setLayoutType] = useState('normal')
    const [layout, setLayout] = useState([])
    const [gridWidth, setGridWidth] = useState(1070)
    const [layoutMap,setLayoutMap] = useState([])
    const [previewModalOpen, setPreviewModalOpen] = useState(false)

    const savePlan = () => {
        let payload = {
            planId: plan._id,
            layoutOptions:{
                layout: layout,
                layoutType:layoutType,
                layoutMap:layoutMap
            }
        }

        savePlanLayout(payload)
    }

    useEffect(()=>{
        if(plan && plan.planItems){
            if(plan.layoutOptions){ 
                if(plan.layoutOptions.layoutType){
                    setLayoutType(plan.layoutOptions.layoutType)
                } 
                if(plan.layoutOptions.layoutMap){
                    setLayoutMap(plan.layoutOptions.layoutMap)
                }
              // 
              if(plan.layoutOptions.layout){
                handleUpdateOfList(plan.layoutOptions.layout)
              }
            } else {
                createLayout()
            }
        }
    },[plan])

    // Also given option to reset the layout with the create function
    const createLayout = () => {
        let planItems = plan.planItems.filter((item) => item.visible)
        let layoutList = []

        planItems.map((item, index)=>{
            layoutList = [...layoutList, generateItem(item, index)]
        })
       
        setLayout(layoutList)
    }

    const generateItem = (planItem, index, array = false) => {
        let id = planItem.id
        let y = 0
        if(array){
            let sorted = array.sort((a,b) => (b.y + b.h) - (a.y + a.h))
            let last = sorted[0]
            y = last.y + last.h
        } else {
           y =  index * 3
        }
        let item ={ 
            x: 0,
            y: y,
            w: 6,
            h: 3,
            i: id.toString(),
        }
        return(item)
    }

    // create intersection of layout and planItems to see what items are missing and create new items for those missing entries
    // see if something removed and remove from layout
    const handleUpdateOfList = (array) => {
        let planItems = plan.planItems.filter((item) => item.visible)
        let layoutList = [...array]
        // generate new item if any items are missing
        planItems.map((item, index)=>{
            if(layoutList.filter((i) => i.i === item.id).length < 1){
                layoutList = [...layoutList, generateItem(item, index, array)]
            }
        })

        // Remove missing if planItems havent been updated to layout
        let referenceLayout = [...layoutList]
        referenceLayout.map((item, index)=>{
            if(planItems.filter((planItem) => planItem.id === item.i).length === 0){
                layoutList.splice(index,1)
            }
        })
        
        setLayout(layoutList)
    }

    const getPlainText = rawEditorState => {
        if(typeof rawEditorState !== 'string') {
            let plainTopic = convertFromRaw(rawEditorState).getPlainText();
            plainTopic = plainTopic.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
            return plainTopic;
        } else {
            return rawEditorState;
        }
    }

    const handleLayoutChanged = (newLayout) => {
        setLayout(newLayout)
    }

    useEffect(()=>{
        handleResize()
        window.addEventListener("resize", (event) => {
            handleResize()
        })
    },[])

    //TODO - Dynamically change grid width if screen size changes or if smaller screen (i.e. 1000 px vs 1920px etc)
    const handleResize = () =>{
        if(document.getElementsByTagName("body")[0]){
            let windowWidth  = document.getElementsByTagName("body")[0].clientWidth
            
            if(windowWidth >= 1200){
                setGridWidth(1070)
            }
            if(windowWidth < 1200 && windowWidth >= 992){
                setGridWidth(750)
            } 
            if(windowWidth < 992){
                setGridWidth(450)
            }
        }
    }

   useEffect(()=>{
    if(layout){
        let newLayoutMap = []
       
        //Dynamically loop through and create rows for each actual row and add cols for those rows defined by the width ( = col width)
        let layoutLoop = layout

        while(layoutLoop.length > 0){
            let runThrough = getLayoutRow(layoutLoop)
            layoutLoop = runThrough.remaining
            newLayoutMap = [...newLayoutMap, runThrough.currentRow]

        }

        setLayoutMap([...newLayoutMap])
    }
},[layout])

const getLayoutRow = (array) => {

    // remaining objects to iterate throuhg on next round
    let remaining = array

    // initially no item
    let firstItem = false

    // get first item in row. This means getting the largest item by height in that row
    array.map((item) => {
        if(!firstItem){
            firstItem = item
        } 
        
        if(item.y < firstItem.y){
            firstItem = item
        } else if(item.y === firstItem.y){
        }else {
            if(item.y < firstItem.h){
                if(item.h>firstItem.h){
                    firstItem = item
                }
            } 
        }
    })

    //get all items in current "row"
    let row = array.map((item) => {
        if(item.y < (firstItem.h + firstItem.y)){
            return item
        }
    })

    // Arrange row by x from smallest to largest num.
    row.sort((a,b) => a.x - b.x || a.y - b.y)
    row = row.filter((item)=> item !== undefined)
   
    // Generate main columns for layout 
    let colStructure = []

    // GridItem x:n ja y:n välinen suhde mitattuna => col width 1-12 => saadaan sisemmät rivit suoraan haltuun

    row.map((item) => {
       
        if(row.filter((i) => i.x === item.x || ( i.x < item.x <(i.x+i.w))).length > 1){
            if(colStructure.filter((i) => i.x === item.x).length === 0){
                let sameX = row.filter((i) => i.x === item.x)
                let width = sameX.sort((a,b) => b.w-a.w)[0].w
                let rows = row.filter((i) => i.x === item.x)
                //loop items for row and measure widths => put widths into correct relations
                colStructure = [
                    ...colStructure,
                    {
                        w: width,   
                        x: item.x,
                        rows: [
                            ...rows
                        ]
                    }
                ]
            }
        } else {
            colStructure = [
                ...colStructure,
                {
                    w: item.w,   
                    x: item.x,
                    rows: [
                        {...item}
                    ]
                }
            ]
        }
            
    })

    //remove row-items from remaininng
    remaining = array.filter((item) =>{
        return row.indexOf(item) < 0
    })
    
    return {remaining: remaining, currentRow: colStructure}
}


    return(
        <>
            <Modal size={"xl"} isOpen={previewModalOpen}>
                <ModalHeader toggle={()=> setPreviewModalOpen(false)}>Mukautetun ulkoasun esikatselu</ModalHeader>
                <ModalBody>
                    <Row className="plan-main-row">
                        <Col xs="12">    
                            {layoutMap && layoutMap.map((item,index)=>{
                                return(
                                    <Row  key={index}>
                                        {item.map((col, key)=>{
                                            return(
                                                <Col xs={col.w*2} key={key}>  
                                                    {col.rows.map((row,i)=>{
                                                        let planItems = plan.planItems
                                                        if(planItems.filter((planItm) => planItm.id === row.i).length > 0){
                                                            let planItem = planItems.filter((planItm) => planItm.id === row.i)[0]
                                                            let layoutPreview = true
                                                            return(
                                                                <Col xs={12} key={i}>
                                                                    {checkPreviewItemType(planItem, key, planPreviewProps, layoutPreview)}
                                                                </Col>
                                                            )
                                                        }
                                                    })}
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                )
                            })}         
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => setPreviewModalOpen(false)}>Sulje esikatselu</a>  
                </ModalFooter>
            </Modal>
            <Modal size="xl" isOpen={modalOpen}>
                <ModalHeader toggle={() => toggle()}>Lomakkeen ulkoasun muokkaus</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <div style={{ display:'flex', marginBottom:'1em'}}>
                                <Label>Tyyli:</Label>
                                <div  style={{ display:'flex', marginLeft:'0.5em'}}>
                                    <button className={"small-white-button " + (layoutType === 'normal' && 'template-active') } onClick={() => setLayoutType('normal')} style={{ fontSize:'0.8rem', marginRight:'0.5em', cursor:'pointer'}}>
                                        Normaali
                                    </button>
                                    <button className={"small-white-button " + (layoutType === 'custom' && 'template-active')} onClick={() => setLayoutType('custom')} style={{ fontSize:'0.8rem', cursor:'pointer'}}>
                                        Muokattu
                                    </button>
                                </div>
                                {layoutType === 'custom' &&
                                    <div style={{display:'flex', marginLeft:'3em'}}>
                                        <Label>Työkalut:</Label>
                                        <div  style={{ display:'flex', marginLeft:'0.5em'}}>
                                            <button className={"small-white-button "} style={{ fontSize:'0.8rem', marginRight:'0.5em', cursor:'pointer'}} onClick={()=> createLayout()}>
                                                Nollaa ulkoasu
                                            </button>
                                            <button className={"small-white-button "} style={{ fontSize:'0.8rem', marginRight:'0.5em', cursor:'pointer'}} onClick={()=> setPreviewModalOpen(true)}>
                                                Avaa esikatselu
                                            </button>
                                            <div style={{ fontSize:'0.8rem', marginLeft:'1em'}}>
                                                Huom! Ulkoasu täytyy tallentaa uudelleen lomakkeeseen tehtyjen muutosten jälkeen. 
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="ui-edit-container">
                                {layoutType === 'normal' && 
                                    <div style={{ display:'flex', height:'5em', width:'100%', justifyContent:'center', alignItems:'center'}}> 
                                        <p>Käytetään normaalia lomakkeen näkymää</p>
                                    </div>
                                }
                                {layoutType === 'custom' &&
                                    <div>
                                        <GridLayout
                                            className="layout"
                                            layout={layout}
                                            cols={6}
                                            onLayoutChange={current => handleLayoutChanged(current)}
                                            //row height may need to be calculated with a function to ensure that element is the correct height (i.e. topic-draft grows larger and it needs to fit in the layout)
                                            rowHeight={30}
                                            width={gridWidth}
                                        >
                                            {plan && plan.planItems && layout && layout.map((gridItem, key)=>{
                                                let planItem = plan.planItems.filter(item => item.id === gridItem.i)[0]
                                                //{getPlainText(planItem[0].topicContent)}
                                                if(planItem){
                                                    return(
                                                        <div className="grid-object" key={gridItem.i}>
                                                            <p style={{fontWeight:'bold'}}>{(key+1) + ". " + (planItem && planItem.topicContent?getPlainText(planItem.topicContent):gridItem.i)}</p>
                                                        </div>
                                                    ) 
                                                } else {
                                                    <div className="grid-object" key={gridItem.i}>{"undefined"}</div>
                                                }
                                            })}
                                        </GridLayout>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Peruuta</a>  
                    <button className="small-white-button lighter-text wide-button" onClick={() => savePlan()}>Tallenna</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
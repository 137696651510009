import React, { Component } from 'react';

import { Row, Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
import * as ActionCreator from '../../store/index';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars'

import Users from './subComponents/Users';
import EnterpriseEdit from './subComponents/EnterpriseEdit';

class EnterpriseContainer extends Component {
    componentDidMount() {
        this.props.setBreadcrumbs({to: 'tiedot', label: `${this.props.businessName} / tiedot`})
        this.props.onPageOpen();
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col xl={{ size: 5 }} xs={{ size: 12 }}>
                        {this.props.enterprise ?
                            <Users userList={this.props.enterprise.users} id={this.props.enterprise.enterpriseId} edit={this.props.onUserEdit} />
                            :
                            <EmptyBox title="Käyttäjät" />
                        }
                    </Col>
                    <Col xl={{ size: 7 }} xs={{ size: 12 }}>
                        {this.props.enterprise ?
                            <EnterpriseEdit enterprise={this.props.enterprise} edit={this.props.onEnterpriseEdit} />
                            :
                            <EmptyBox title="Yrityksen tiedot" />
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

const EmptyBox = (props) => {
    return (
        <Row>
            <Col md={{ size: 12 }} xl={{ size: 12 }} >
                <div className="company-box">
                    <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                        <p>{props.title}</p>
                    </div>
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '500px', marginTop: '0.5rem' }} >
                        <MegaLoaderSpinner />
                    </Scrollbars>

                </div>
            </Col>
        </Row>
    )
}

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const mapStateToProps = state => {
    return {
        enterprise: state.enterprise.enterprise,
        businessName: state.authentication.businessName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPageOpen: (params) => dispatch(ActionCreator.fetchEnterprise(params)),
        onEnterpriseEdit: (params) => dispatch(ActionCreator.editEnterprise(params)),
        onUserEdit: (params) => dispatch(ActionCreator.saveUserData(params)),
        setBreadcrumbs: (params) => dispatch(ActionCreator.setBreadcrumbs(params)),
        addMoreBreadcrumbs: (params) => dispatch(ActionCreator.addMoreBreadcrumbs(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnterpriseContainer));



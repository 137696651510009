import React, {useState} from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input, Button, Label } from 'reactstrap';

const PlanActivatorModal = ({isOpen, toggle, changePlanStatus}) => {
    const [passwordOn, setPasswordOn] = useState(false);
    const [password, setPassword] = useState("");
    const [planActivated, setPlanStatus] = useState(true);
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} className="taskModal">
                <div>
                    Lomakkeen julkaisu
                </div> 
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xl="12" style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                        <button className="small-white-button lighter-text wide-button" onClick={() => setPasswordOn(!passwordOn)} style={passwordOn ? {backgroundColor: '#B5E413', color: '#FFF', fontWeight: 700} : {}}>Ota salasana käyttöön</button>
                    </Col>
                    <Col xl="6">
                        {passwordOn &&
                        <>
                            <Label>
                                Salasana
                            </Label>
                            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="password-field"/>
                        </>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                {planActivated  && <button className="small-white-button wide-button publish" onClick={() => changePlanStatus(password, planActivated, passwordOn)}>Tallenna</button>}
            </ModalFooter>
        </Modal>
    )
}

export default PlanActivatorModal;
import * as ActionTypes from '../../Components/Reports/Dashboard/Actions/actionTypes';

const initialState = {
  sentSurveys: null,
  anonSurveys: [],
  activeReports: [],
  targetNotes: {},
  employeeList: [],
  noPermission: false
}

export default function(state = initialState, action) {
  switch(action.type) {
    case ActionTypes.REORDER_SURVEYS:
      return {
        ...state,
        ...action.payload
      }
    case ActionTypes.GET_STATISTICS:
      return {
        ...state,
        sentSurveys: [
          ...action.payload
        ]
      }
    case ActionTypes.CLEAR_COMPARISON_REPORT:
      return {
        ...state,
        comparisonReport: [],
        sentSurveys: []
      }
    case ActionTypes.CLEAR_STATISTICS:
      return {
        ...state,
        sentSurveys: []
      }
    case ActionTypes.FETCH_ANON:
      return {
        ...state,
        anonSurveys: [
          ...action.payload
        ]
    }
    case ActionTypes.FETCH_NOTES:
      return {
        ...state,
        targetNotes: {
          ...action.payload
        }
      }
    case ActionTypes.ADD_REMOVE_REPORT: {
        return {
            ...state,
            activeReports: action.reports
        }
    }

    case ActionTypes.SET_EMPLOYEES_FOR_MEMO: {
      return {
        ...state,
        employeeList: action.payload
      };
    }
    case ActionTypes.EDIT_NOTE: {
      return {
        ...state,
        targetNotes: {
          notes: state.targetNotes.notes.map((note, x) => {
            if(x === action.payload.noteIndex) {
              note["notes"] = action.payload.notes; 
            }
            return note;
          })
        }

      }
    }
    case ActionTypes.DELETE_NOTE: {
      return {
        ...state,
        targetNotes: {
          notes: state.targetNotes.notes.filter((note, x) => x !== action.payload.noteIndex)
        }

      }
    }
    case ActionTypes.NO_PERMISSION: {
      return {
        ...state,
        noPermission: action.payload
      };
    }
    default:
      return state;
  }
}
import React, { useState, useEffect } from 'react';
import { executeRequest } from '../../../../../Utilities/requestHandler/dataHandler'
import Select from 'react-select';
import {Row, Col, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'
import {useDispatch} from 'react-redux';
import {fetchSurveyStack} from '../../../../../store/index';

export const SurveySelectorContainer = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(previousState => 
            !previousState
        );
    }

    return (
        <>
            <SurveySelectorModal modalOpen={modalOpen} toggleModal={toggleModal} />
            <button className="small-white-button lighter-text" onClick={toggleModal}>Valitse kartoitus</button>
        </>
    )
}

const SurveySelectorModal = ({modalOpen, toggleModal}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [availableSurveys, setAvailableSurveys] = useState([]);
    const [surveyName, setSurveyName] = useState("");
    const [selectedSurvey, setSelectedSurvey] = useState({});
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(modalOpen) {
            

            const getSurveys = async () => {
                const result = await executeRequest('get/selectSurveys', {})

                if(!!result) {
                    setAvailableSurveys(result);
                    await setLoading(false);
                }

                return 'boom'
            }
            //GET SURVEYS
            setLoading(true);
            getSurveys();
        }

    }, [modalOpen])

    const setLoading = async (loadingStatus) => {
        setIsLoading(loadingStatus);
        
        setTimeout(() => {
            return true
        }, 100)
    }

    const handleChange = selectedOption => {
        setSurveyName(selectedOption.label + " - KOPIO");
        setSelectedSurvey(selectedOption);
    }

    const handleSelection = async () => {

        if(Object.keys(selectedSurvey).length > 0) {
            let params = {
                surveyId: selectedSurvey.value,
                name: surveyName
            }
        
            const response = await executeRequest('modify/copySurvey', params);

            if(!!response) {
                if(response.id !== undefined) {
                    let payload = {
                        surveyId: response.id
                    }
                    dispatch(fetchSurveyStack(payload));
                    toggleModal();
                }
            }
        }
    }

    return (
        <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" centered>
            <ModalHeader toggle={toggleModal}>
                Kartoituksen kopiointi
            </ModalHeader>
            <ModalBody>
                {isLoading ? <LoadingSpinner /> :
                    <div>
                        <div style={{padding: '0.5em 0'}}>
                            <h6>Valitse kopioitava kartoitus</h6>
                            <Select
                                value={selectedSurvey}
                                onChange={handleChange}
                                options={availableSurveys}
                                classNamePrefix="select"
                            />
                        </div>
                        <div style={{padding: '0.5em 0'}}>
                            <label htmlFor="survey-name">Anna kartoitukselle nimi</label>
                            <input id="survey-name" value={surveyName} onChange={(e) => setSurveyName(e.target.value)} style={{width: '100%'}}></input>
                        </div>
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggleModal}>Peruuta</a>
                <button className="small-white-button wide-button" onClick={handleSelection}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

const LoadingSpinner = () => {
    return (
        <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}
import React from 'react';
import {ListGroupItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col} from 'reactstrap';
const MainItem = props => {
    if(typeof props.add === 'undefined') {
        return (
            <ListGroupItem 
            className={props.activeGroup.id === props.group.id ? "justify-content-between listItem listItem-active" : "justify-content-between listItem"} 
            >
                <Row>
                    <Col sm="12">
                        <Row>
                            <Col sm="9" style={{padding: '.75rem 1.25rem'}} className="kartoitus-group-title" onClick={() => {props.handleClick(props.groupIndex); if(typeof props.setActiveGroup !== "undefined")props.setActiveGroup(props.groupIndex);}}>{props.text}</Col>
                            <Col sm="2" style={{paddingLeft: 0}}>
                                <div style={{textAlign: 'right', paddingTop: '1rem'}}>
                                    <i className="material-icons hover-opa" onClick={() => props.moveGroup(props.groupIndex, -1)}>arrow_upward</i>
                                    <i className="material-icons hover-opa" onClick={() => props.moveGroup(props.groupIndex, 1)}>arrow_downward</i>
                                </div>
                            </Col>
                            <Col sm="1" style={{paddingLeft: 0}}>
                                <div onClick={() => props.openMenu(props.groupIndex)} style={{textAlign: 'right', paddingTop: '.75rem', paddingBottom: '.75rem'}}>
                                    <Dropdown isOpen={props.activeIndex === props.groupIndex} toggle={props.openMenu} style={{display: 'inline-block'}}>
                                        <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                            <i className="fas fa-bars" title="Valikko"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => props.copyGroup({categoryId: props.categoryId, groupIndex: props.groupIndex})}>Kopioi ryhmä</DropdownItem>
                                            <DropdownItem onClick={() => props.deleteGroup({groupId: props.group.id, categoryId: props.categoryId})}>Poista ryhmä</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ListGroupItem>
        )
    } else {
        return (
            <ListGroupItem className="justify-content-between listItem" onClick={() => props.handleClick(-1)}>
                <Row>
                    <Col style={{padding: '.75rem 1.25rem'}}>Lisää uusi ryhmä</Col>
                </Row>
            </ListGroupItem>
        )
    }
}

export default MainItem;
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Table,
  Label
} from 'reactstrap'
import moment from 'moment';
//actions
import { searchPlan, startEditingPlan } from './Actions/ActionCreator'
import { setBreadcrumbs, addMoreBreadcrumbs } from '../../store'

//custom components
import TextEditor from '../../Utilities/TextEditor/texteditor'

const PlanSearch = props => {
  const { history } = props

  const dispatch = useDispatch()

  const searchResults = useSelector( state => state.plan.searchResults )
  const enterpriseName = useSelector( state => state.authentication.businessName )

  // set breadcrumbs
  useEffect( () => {
    dispatch( setBreadcrumbs( { to: 'tiedot', label: enterpriseName } ) )
    dispatch( addMoreBreadcrumbs( [ { to: 'lomakkeet', label: 'Lomakkeet' }, { to: 'lomakkeet/hae', label: 'Hae' } ] ) )
  }, [] )

  const [ searchPhrase, setSearchPhrase ] = useState( '' )
  const [ searching, setSearching ] = useState( false )

  const makeSearch = () => {
    setSearching( true )

    setTimeout( async () => {
      const params = {
        searchPhrase
      }

      await dispatch( searchPlan( params ) )
      setSearching( false )
    }, 500 )
  }

  const onPlanClick = planId => {
    dispatch( startEditingPlan( planId ) )

    history.push( '/lomakkeet/luonti' )
  }

  const onSearchInputKeyPress = e => {
    if( e.key === 'Enter' ) {
      makeSearch()
    }
  }

  return (
    <Container fluid>
      <div className="whitebox" style={ { marginTop: '1em', overflow: 'hidden auto', padding: '2em' } }>

        <Row>
          <Col style={ { display: 'flex', justifyContent: 'center' } }>
            <h3>Hae lomaketta kappaleen nimellä</h3>
          </Col>
        </Row>

        <Row>
          <Col xs={ { size: 4, offset: 4 } } style={ { display: 'flex', justifyContent: 'center', marginTop: '1em' } }>
            <Input onKeyPress={ onSearchInputKeyPress } type="text" placeholder="Hakusana...." value={ searchPhrase } onChange={ ( e ) => setSearchPhrase( e.target.value ) }></Input>
            <Button style={ { minWidth: '4em', marginLeft: '.5em' } } onClick={ makeSearch }>{ searching ? <LoaderSpinner /> : 'Hae' }</Button>
          </Col>
        </Row>

        <Row style={ { marginTop: '2em' } }>
          <Col xs={ { size: 12 } }>
            { searchResults && (

              Object.keys( searchResults ).length > 0 ? (

                Object.keys( searchResults ).map( ( key, index ) => {
                  let result = searchResults[ key ]

                  return <SearchResultBlock key={ index } planList={ result.planList } business={ result.business } onPlanClick={ onPlanClick } />
                } )

              ) : (

                  <Row>
                    <Col style={ { display: 'flex', justifyContent: 'center', marginTop: '2em' } }>
                      <h5>Ei tuloksia</h5>
                    </Col>
                  </Row>

                ) ) }

          </Col>
        </Row>

      </div>
    </Container>
  )
}

const SearchResultBlock = props => {
  const { planList, business, onPlanClick } = props

  return (
    <div style={ { marginTop: '2em' } }>
      <h2><b>{ business.name }</b></h2>

      <hr style={ { marginTop: '2em' } } />

      { planList && planList.map( ( plan, i ) => {
        const { planItems } = plan

        return <Row key={ i } onClick={ () => onPlanClick( plan._id ) }>
          <Col className="plan-search-result">
            <h3><b>{ plan.planName }</b></h3>

            { planItems && planItems.map( ( item, j ) => {
                let totalAmount = 0
                if(item.products && item.products.length > 0){
                    let total = 0
                    item.products.map(product => 
                        total += (product.price * product.amount)
                    )
                    totalAmount = total.toFixed(2)
                }
                let rowData = []
                if (item && item.excelData && item.excelData.data && item.excelData.headers) {
                  let rows = [];
                  for (let i = 1; i <= item.excelData.rowCount; i++) {
                      let row = []
                      for(let j = 0; j < item.excelData.headers.length; j++){
                          row.push(item.excelData.data[j].values[i-1])
                      }
                      rows.push(row)
                  }
                  rowData = rows
                }
              return <div key={ j } className="plan-search-result-content" >
                <Row style={{ marginBottom: '1em'}}>
                  <Col>
                    <TextEditor
                      content={ item.topicContent }
                      additionalClass="subTitle texteditor-no-minHeight"
                      readOnly={ true }
                      editorId={ `${ item.id }-topic` }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {item && item.type === "topic-draft" &&
                      <TextEditor
                        content={ item.textContent }
                        readOnly={ true }
                        editorId={ `${ item.id }-content` }
                      />
                    }
                    {item && item.type === "tables" &&
                      <TableItem table={item}/>
                    }
                    {item && item.type === "signature" &&
                      <Row>
                        {item.signatures && item.signatures.map((signer,key) => {
                          return(
                              <Col key={key}>
                                  <Row>
                                      <Col>
                                          <p>
                                              Päiväys: {signer.signedDate ? moment.utc(signer.signedDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY'): "-"}
                                          </p>
                                          <p style={{ marginTop:'0.25em' }}>{signer.business || ""}</p>
                                      </Col>
                                  </Row>
                                  <Row  className="sign-row">
                                      <Col>
                                          {signer.verified? 
                                              <div className="signature-container underLine">
                                                  <p className="signed-name">{signer.name}</p>
                                              </div>
                                              :
                                              <div className="signature-container">
                                                  <p>Ei allekirjoitettu</p>
                                              </div> 
                                          }
                                      </Col>
                                  </Row>
                                  <Row style={{marginTop: '0.5em' }}>
                                      <Col>
                                          <p>{signer.name}</p>
                                      </Col>
                                  </Row>
                                  <Row style={{marginTop: '0.5em' }}>
                                      <Col>
                                          {signer.verified? 
                                              <div style={{justifySelf:'flex-end' }}>
                                                  <p>Digitaalinen allekirjoitus:</p>
                                                  {signer.signerCode}
                                              </div>
                                              :
                                              <div></div>
                                          }
                                      </Col>
                                  </Row>
                              </Col>
                          )
                        })}
                      </Row>
                    }
                    {item && item.type === "product" && 
                      <Col>
                        {item.products &&     
                          <div className="plan-product-row" style={{display:'flex', flexDirection:'row'}}>
                              <Col className="product-summary-container">
                                  <Row className="product-headers-row">
                                      <Col xs="4">
                                          <Label>Nimi</Label>
                                      </Col>
                                      <Col xs="2">
                                          <Label>Yksikkö</Label>
                                      </Col>
                                      <Col xs="2">
                                          <Label>Hinta (€)</Label>
                                      </Col>
                                      <Col xs="2">
                                          <Label>Määrä (á)</Label>
                                      </Col>
                                      <Col xs="2">
                                          <Label>Yhteensä</Label>
                                      </Col>
                                  </Row>
                                  {item.products.map((product, key) => {
                                      return(
                                          <Row key={key} className="product-content-block">
                                              <Col xs="4">
                                                  <p>{product.label}</p>
                                              </Col>
                                              <Col xs="2">
                                                  <p>{product.unit}</p>
                                              </Col>
                                              <Col xs="2">
                                                  <p>{product.price}</p>
                                              </Col>
                                              <Col xs="2">
                                                  <p>{product.amount}</p>
                                              </Col>
                                              <Col xs="2">
                                                  <p>{(product.price * product.amount).toFixed(2)}</p>
                                              </Col>
                                          </Row> 
                                      )})
                                  }
                                  <div className="product-summary-row">
                                      {item.showSummary &&
                                          <Row>
                                              <Col xs="10">
                                                  <p>Yhteensä: </p>
                                              </Col>
                                              <Col xs="2">
                                                  <p><b>{totalAmount}€</b></p>
                                              </Col>
                                          </Row>
                                      }
                                  </div>
                              </Col>
                          </div>
                        }  
                      </Col>
                    }
                    {item && item.type === "businessExcel" && 
                      <div style={{ overflow: 'scroll'}}>
                        <Table striped hover reflow >
                            <thead striped style={{position:"sticky", top:0, backgroundColor:"white"}}>
                                <tr>
                                    {item.excelData && item.excelData.headers.map((head, i) => {
                                        return <th key={i}>{head || ''}</th>
                                    })}   
                                </tr>
                            </thead>
                            <tbody >
                                {rowData && rowData.map((row, ind) => {
                                    return <tr key={ind}>
                                        {row.map((r, rowInd) => {
                                            return <td key={rowInd} >
                                                {r}
                                            </td>
                                        })}
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                      </div>
                    }
                    {item && item.type === "attachment" &&
                      <>        
                        {item.files && item.files.map((file,key) => {
                            return(
                                <Row key={key} className="plan-attachment-row" >
                                    <Col className="plan-attachment-row " >
                                        <p className="planAttachment-name-overflow-handler" title={file.fileName +"."+file.fileEnd}>- {file.fileName +"."+file.fileEnd}</p>
                                    </Col>
                                </Row>
                            )
                        })}
                      </>
                    }
                  </Col>
                </Row>
              </div>
            } ) }

          </Col>
        </Row>
      } ) }

    </div>
  )
}

const LoaderSpinner = () => {
  return (
    <div style={ { paddingLeft: '.5em', paddingRight: '.5em' } }>
      <div style={ { width: '1rem', height: '1rem' } } className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

const TableItem = ({table, title}) => {
  const [tableFields, setTableFields] = useState(table);
  const [tableRows, setTableRows] = useState(table.rows)
  
  useEffect(()=>{
    if(table && table.rows){
      setTableRows(table.rows)
    }
  },[table])
  
  return (
      <Table bordered hover style={{tableLayout: 'fixed'}}>
          <thead>
          </thead>
          <tbody>
              {tableRows && tableRows.map((row, rowIndex) => 
                  <TableRowContainer row={row} rowIndex={rowIndex} item={table} maximumCount={table.selectedNumberOfCols} key={rowIndex}/>
              )}
          </tbody>
      </Table>
  )
}

const TableRowContainer = ({row, rowIndex, handleTableData, maximumCount, item}) => {
  const { cols } = row

  const handleRowChange = colData => {
      const { cols } = row
      
      let newCols = cols.map(col => {
          if(col.id === colData.id) {
              return colData
          }

          return col
      })

      handleTableData({
          cols: newCols,
          rowIndex: rowIndex
      })
  }

  return (
      <tr key={rowIndex}>         
          {cols.map((col, index) =>
              <ColumnEditor 
                  col={col} 
                  key={col.id} 
                  editorId={col.id} 
                  rowIndex={rowIndex}  
                  count={cols.length}
                  maximumCount={maximumCount}
                  colIndex={index}
              />
          )}
      </tr>
  )
}

const ColumnEditor = ({col, editorId, maximumCount, count, colIndex}) => {

  const getColSpan = () => {
      if(count === 1)
          return maximumCount;
      else
          return (maximumCount % count === 1 && colIndex + 1 === count) ? maximumCount / count + 1 : maximumCount / count
  }

  return (
      <td style={{verticalAlign: 'top'}} colSpan={getColSpan()}>
          <TextEditor
              editorId={editorId}
              content={col.text}
              onTextChange={() => {return}}
              additionalClass="plan-input"
              readOnly={true}
          />
      </td>
  )
}

export default PlanSearch
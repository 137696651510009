
import * as ActionTypes from './ActionTypes';
import {confs} from '../../../modules/config';
import {NotificationManager} from 'react-notifications';

import {authFailed} from '../../../store/index';
import * as ActionCreator from '../../../store/index';

export const saveFirst = params => {
    return {
        type: ActionTypes.FIRST_COMPANY_SURVEY_LIST,
        payload: params
    }
};

export const saveSecond = params => {
    return {
        type: ActionTypes.SECOND_COMPANY_SURVEY_LIST,
        payload: params
    }
};

export const getCompanySurveysForComparison = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'comparison/get/getSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(authFailed());
            } else {
                if(params.column === 1) {
                    dispatch(saveFirst(responseJson.surveyList));
                } else {
                    dispatch(saveSecond(responseJson.surveyList));
                }
            }   
        }).catch(error => {
            NotificationManager.error("Lähetettävien kartoituksien haussa tapahtui virhe", "Virhe", 3000);
        })
    }
}

export const getFirstSelectorOptions = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'comparison/get/firstSelector', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(authFailed());
            } else {
                NotificationManager.success("Joha pelemahti LOL", "", 1500);
            }   
        }).catch(error => {
            NotificationManager.error("Lähetettävien kartoituksien haussa tapahtui virhe", "Virhe", 3000);
        })
    }
}

export const getComparisonReport = payload => dispatch => {
    let jwt = window.sessionStorage.getItem("token");
  
    let firstColumn = [
      ...payload.firstColumn
    ];
  
    let secondColumn = [
      ...payload.secondColumn
    ];
  
    let firstColm = [];
    firstColumn.map((id) => {
      return firstColm.push({reportId: id.id})
    });
    let secondColm = [];
  
     secondColumn.map((id) => {
      return secondColm.push({reportId: id.id})
    });
  
    let params = {
      firstColumn: firstColm,
      secondColumn: secondColm,
      jwtToken: jwt
    }
    dispatch(fetchStatus())
    fetch(confs.url + 'comparison/get/comparisonReport', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(data => {
      if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
        dispatch(ActionCreator.authFailed())
      } else {
        if(data.length > 0) {
          NotificationManager.success("Vertailuraportti luotu", "", 2000);
          dispatch(setComparisonNames(payload.firstColumn, payload.secondColumn));
          dispatch(setComparisonReport(data));
        } else {
          NotificationManager.error("Valinnoilla ei löytynyt tuloksia", "Virhe", 2000);
          dispatch(fetchStatus())
        }
      }
    }).catch(error => {
      dispatch(fetchStatus());
      NotificationManager.error("Vertailuraporttia ei voitu luoda", "Virhe", 2000);
    })
  }

  export const fetchStatus = payload => {
    return {
      type: ActionTypes.FETCH_STATUS
    }
  }

 export const setComparisonReport = payload => {
    return {
      type: ActionTypes.SET_COMPARISON_REPORT,
      payload: payload
    }
  };

  export const setComparisonNames = (firstColumn, secondColumn) => {
    let firstNames = [];
    let secondNames = [];
  
    if(firstColumn.length > 0){
      firstNames = firstColumn.map((x, y) => {
        return x.label
      })
    }
    if(secondColumn.length > 0){
      secondNames = secondColumn.map((x, y) => {
        return x.label;
      });
    }
  
    let data = {
      firstNames: firstNames,
      secondNames: secondNames
    }
  
    return {
      type: ActionTypes.SET_COMPARISON_NAMES,
      payload: data
    }
  }

  const setComparisonTree = payload => {
    return {
        type: ActionTypes.SET_COMPARISON_TREE,
        payload: payload
    }
  }

  export const getComparisonTree = payload => dispatch => {
    let jwt = window.sessionStorage.getItem("token");
    let params = {
        ...payload,
        jwtToken: jwt
    }
    fetch(confs.url + 'get/comparisonTree', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then((data) => { 
        if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
            dispatch(ActionCreator.authFailed());
        } else {
            dispatch(setComparisonTree(data))
        }
    })	
}

export const endComparison = payload => {
    return {
        type: ActionTypes.END_COMPARISON
    }
}

export const getSurveyTree = (company) => dispatch => {
  let jwt = window.sessionStorage.getItem("token");
  let params = {
      ...company,
      jwtToken: jwt
  }
  fetch(confs.url + 'get/reportTree', {
    method: 'POST',
    body: JSON.stringify(params)
  })
  .then(res => res.json())
  .then((data) => {
      if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
          dispatch(ActionCreator.authFailed());
      } else {
          dispatch({
              type: ActionTypes.GET_SURVEY_TREE,
              payload: data
          })
      }
  })
}
import React, { useState, useEffect } from 'react';
import { addMoreBreadcrumbs, setBreadcrumbs } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import './project.css';
import { Container, Button } from 'reactstrap';
import { Project } from './subComponents/ProjectLayout';
import { BaseView } from './subComponents/View';
import { executeRequest } from '../../Utilities/requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications';

export const HandleProject = ({history}) => {
    const businessName = useSelector(state => state.authentication.businessName)
    const [providers, setProviders] = useState()
    const [chosenProject, setChosenProject] = useState(null)
    const [targetBusiness, setTargetBusiness] = useState(null)  
    const [modify, setModify] = useState(false)
    const [view, setView] = useState("project")
    const [viewOptions, setViewOptions] = useState({})
    const [activeTab, setActiveTab] = useState()

    const dispatch = useDispatch();
    
    useEffect(()=>{
        if(chosenProject && chosenProject.projectName){
            dispatch(addMoreBreadcrumbs([{ to: 'projektit', label: 'Projektit' }, { to: 'projektit/hallitse', label: chosenProject.projectName }]))
        }
    },[chosenProject])

    useEffect(()=>{
        const setProject = () => {
            if(history && history.location) {
                if(history.location.state && history.location.state.projectId){
                    getWantedProject(history.location.state.projectId)
                }
                else if(history.location.projectId){
                    
                    getWantedProject(history.location.projectId)

                }
                else if(history.location.state && history.location.state.targetBusiness){
                    setTargetBusiness(history.location.state.targetBusiness)
                    dispatch(addMoreBreadcrumbs([{ to: 'projektit', label: 'Projektit' }, { to: 'projektit/hallitse', label: 'Uusi Projekti' }]))
                    setModify(true)
                }

            }

        }
        setProject()
    },[])

    const getWantedProject = async(props) => {
        let payload = {
            projectId:props
        }
        const response = await executeRequest('project/getSelected',payload)
        setChosenProject(response)
    }

    const createProject = async(props) => {
        let payload = ({
            project:{...props}
        })
        const response = await executeRequest('project/create', payload, dispatch)
            
        if(response.success){
            setChosenProject(response.project)
            NotificationManager.success('Projekti luotu onnistuneesti', 'Ilmoitus', 4000)
        } else{
            if(response.trialEnded){
                NotificationManager.error("Kokeilujakso on päättynyt","",4000)
            } else {
                if(response.reason){
                    if(response.reason === "limit"){
                        NotificationManager.error("Projektejen enimmäismäärä on täyttynyt","",4000)
                    }
                    if(response.reason === "module"){
                        NotificationManager.error("Ominaisuus (lisenssi) ei ole käytössä","",4000)
                    } 
                } 
                else{
                    NotificationManager.error('Projektin luonnissa tapahtui virhe', 'Virhe', 4000)
                }
            }
        }
    }

    const saveProject = async(props) => {
        let payload = ({
            project: {...props},
            _id: chosenProject._id
        })
        const response = await executeRequest('project/save', payload, dispatch)

        if(response.success){
          setChosenProject(response.project)
          NotificationManager.success('Tietojen tallentaminen onnistui', 'Ilmoitus', 4000)
        } else {
            NotificationManager.error('Tietojen tallentamisessa tapahtui virhe', 'Virhe', 4000)
        }
    }
    
    return (
        <Container fluid style={{ overflowX: 'hidden' }}>
           
                {view === "project" &&
                    <div className="project-colored-box" style={{ marginTop:'1em', marginBottom: '1.5em' }}>
                        <Project 
                            create={createProject}
                            providers={providers}
                            history={history}
                            project={chosenProject}
                            targetBusiness={targetBusiness}
                            saveProject={saveProject}
                            updateProject={getWantedProject}
                            modify={modify}
                            setModify={setModify}
                            setView={setView}
                            setViewOptions={setViewOptions}
                            activeTab={activeTab}
                        /> 
                    </div>
                }  
                {view === "view" &&
                    <div className="dashboard-medium-box" style={{ marginTop:'1em', marginBottom: '1.5em', minHeight: '80vh' }}>
                        <BaseView
                            setView={setView}
                            viewOptions={viewOptions}
                            projectId={chosenProject._id}
                            setActiveTab={setActiveTab}
                        />
                    </div>
                }      
          
        </Container>
    )
}


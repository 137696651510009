import React, { useState, useEffect } from 'react';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader, Button, Label, DropdownItem, Dropdown, DropdownToggle, DropdownMenu, FormGroup, Col, Row} from 'reactstrap';
import { motion } from 'framer-motion';
import { confs } from '../../../modules/config';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications';
import { TagHandler } from './TagHandler';
import { Scrollbars } from 'react-custom-scrollbars';
import { Dropzone } from '../../../Utilities/Dropzone/Dropzone';
import Select from 'react-select';
import moment from 'moment';
import { EmptyProjectList } from './ProjectLayout';
import { ListHeaderSorter } from './ListHeaderSorter';

const baseURL = confs.url
const INITIAL_FILE_STATE = {
    name: "",
    accurateType:"",
    tags: []
}
export const Attachments = (props) => {
    const { projectId } = props
    const [search, setSearch] = useState("")
    const [newFile, setNewFile] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [fileName, setFileName] = useState("")
    const data = new FormData()
    const [projectFiles, setProjectFiles] = useState()
    const [tags, setTags] = useState([])
    const [modifyOpen, setModifyOpen] = useState(false)
    const [selectedFile, setSelectedFile] = useState(INITIAL_FILE_STATE)
    const [fileChanged, setFileChanged] = useState()
    const [clientList, setClientList] = useState([])
    const [selectedBusiness, setSelectedBusiness] = useState({})
    const [toCompare, setToCompare] = useState(null)
    const [compareOption, setCompareOption] = useState(false)
    const [showSave, setShowSave] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [type, setType] = useState("newFile")
    const [fileList, setFileList] = useState([])
    const [modalSearch, setModalSearch] = useState("")
    const [chosenFile, setChosenFile] = useState(false)
    const [orderBy, setOrderBy] = useState("name")
    const [sortedAttachments, setSortedAttachments] = useState()
    const [optionsOpen,setOptionsOpen] = useState(false)
    const [sortOrder, setSortOrder] = useState(1)
    const [deleteText, setDeleteText] = useState("")


    const handleUmlauts = (name) => {
      
        if(name){
            let newName = name
            newName = newName.replace(/ä/g, 'a');
            newName = newName.replace(/ö/g, 'o');
            newName = newName.replace(/å/g, 'o');
            newName = newName.replace(/Ä/g, 'A');
            newName = newName.replace(/Ö/g, 'O');
            newName = newName.replace(/Å/g, 'O');

            
            return newName
        }
    }

    const handleSave = async() => {
        let taglist = JSON.stringify({tags:[...tags]})
        let business = selectedBusiness? JSON.stringify({...selectedBusiness}) : null

        let blob = newFile.slice(0, newFile.size, newFile.type); 

        let file = new File([blob],handleUmlauts(newFile.name), {type:newFile.type})

        data.set("jwtToken", window.sessionStorage.getItem("token"))
        data.set("name",fileName)
        data.set("file", file, file.name)
        data.set("type", "file")
        data.set("tags",taglist)
        data.set("business", business)
        data.set("documentId", projectId)
        data.set("connectionType", "projects")
        let tempName = file.name.split(".")
        data.set("fileEnd", tempName[tempName.length-1])
        data.set("toCompare", toCompare)
        upload(data)
    }

    useEffect(()=>{
        if(newFile){
            handleFileChange(newFile)
        }
    },[newFile, setNewFile])

    const upload = async(props) => {
        try {
            const responseJson = await fetch(baseURL + 'project/uploadFile', {
                method: 'POST',
                body: props
            })
            if(responseJson) {
                const response = await responseJson.json()
                getFiles()
                setModalOpen(false)
                clearFields()
                if(response.success){
                    NotificationManager.success('Tiedosto lisätty projektin liitteisiin!', 'Ilmoitus', 4000)
                }
                if(response.error){
                    NotificationManager.error('Exceliä ei voitu lisätä vertailtaviin! Ole hyvä ja tarkasta, että se on vaaditun mukainen ja ota tarvittaessa yhteyttä tuki@qibbie.com', 'Virhe', 10000)
                }
                return response;
            } else {
                NotificationManager.error('Virhe lisättäessä tiedostoa', 'Virhe', 4000)
                return false;
            }
        } catch(error) {
            NotificationManager.error(error, 'Virhe', 4000)
            return Promise.reject(error);
        }
    }

    const clearFields = () => {
        setNewFile(null)
        setFileName("")
        setTags([])
        setSelectedBusiness(clientList[0])
        setCompareOption(false)
        setToCompare(null)
        setChosenFile(false)
    }

    const getFiles = async() => {
        let payload = {
            projectId: projectId
        }
        const response = await executeRequest('project/getFilesToList',payload)
        setProjectFiles(response.files)
    }

    const getFileList = async() => {
        const response = await executeRequest('file/getAttachments', {})
        setFileList(response)
    }

    useEffect(()=>{
        getFiles()
        getFileList()
    },[])


    const toggleDropdown = (y) => {
        if (menuOpen === y) {
            setMenuOpen(null)
        } else {
            setMenuOpen(y)
        }
    }

    const toggleDelete = (file) => {
        setDeleteModalOpen(true)
        setSelectedFile(file)
    }

    const handleDelete = async() => {
        if(deleteText === "poista"){
            let payload = {
                projectId: projectId,
                fileId: selectedFile.id,
                connectionType: "projects"
            }

            const response = await executeRequest('project/deleteFile', payload)
            if(response.success === true){
                NotificationManager.success('Liite poistettu onnistuneesti!', 'Ilmoitus', 4000)
            } else{
                NotificationManager.error('Liitteen poistossa tapahtui virhe', 'Virhe', 4000)
            }
            setDeleteModalOpen(false)
            getFiles()
            setDeleteText("")
        }
    }

    const handleDownload = async(file, view=false) => {
        let payload = {
            fileId: file.id,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const response = await executeRequest('project/downloadFile', payload)
        if(response.data){
            try{
                var blob = new Blob([new Uint8Array(response.data.data)], { type: response.mimeType });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.name + "." + response.fileEnd;
                
                document.body.appendChild(link);
                
                if(view){
                    window.open(link)
                } else {
                    link.click();
                }
                document.body.removeChild(link);

            } catch {}
        }       
    }

    const handleFileChange = (e) => {
        setFileChanged(e)
        let name = e.name.split(".")
        name.pop()
        let finalName = name.join(".")
        if(!fileName || (fileName && fileName.length === 0)){
            setFileName(finalName)
        }
    }

    const handleTagChange = (e) => {
        setTags(e.target.value)
    }

    const handleListNameFilter = file => {
        const {name, accurateType, tags, business} = file
        
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        else if(accurateType && accurateType.toUpperCase().includes(search.toUpperCase())) return true
       
        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }
        if(business !== undefined && business.label){     
            if(business.label.toUpperCase().includes(search.toUpperCase())) return true
        }
        return false
    }

    const [fileOk, setFileOk] = useState(false)
    const [nameOk, setNameOk] = useState(false)
    useEffect(()=>{
        if(fileName){
            checkName()
        }
        if(fileChanged){
            checkDataType(fileChanged)
        }
        if(fileOk && nameOk){
            setShowSave(true)
        } else {
            setShowSave(false)
        
        }
    },[fileName, fileChanged, fileOk, nameOk])

    const checkDataType = (file) => {
        let regex = new RegExp(/([a-zA-Z0-9åäöÅÄÖ\s_.,\-\(\)])+(.doc|.docx|.pdf|.xls|.xlsx|.xlsm|.xlsb|.xltx|.txt|.jpg|.jpeg|.png|.bmp|.svg|.ppt|.pptx|.pptm)$/)
        if(file && file.name){  
            if(regex.exec(file.name.toLowerCase()) !== null){
                setFileOk(true)
            } else { 
                setFileOk(false)
                NotificationManager.error("Tiedoston nimessä ei saa olla ääkkösiä tai muita erityismerkkejä Hyväksyttyjä ovat ( ) _ . , ","Virhe", 10000)
            }
        } else {
            setFileOk(false)
        }    
        checkExcel(file.name)
    }

    const checkExcel = (fileName) => {
        let checkExcel = new RegExp(/([a-zA-Z0-9åäöÅÄÖ\s_.,\-\(\)])+(.xls|.xlsx)$/)
        if(checkExcel.exec(fileName) !== null){
            setCompareOption(true)
        } else {
            setCompareOption(false)
        } 
    }

    const checkName = () => {
        let regex = new RegExp(/^[a-zA-Z0-9åäöÅÄÖ\s_.,\-\(\)]+$/)
        if(fileName && fileName.length > 0 ){
            if(regex.exec(fileName) != null){
                let nameColour = document.getElementById("fileName")
                nameColour.setAttribute("style", "color:default;")     
                setNameOk(true)
            }  else {
                let nameColour = document.getElementById("fileName")
                nameColour.setAttribute("style", "color:red;")
                setNameOk(false)
                NotificationManager.error("Tiedoston nimessä ei saa olla ääkkösiä tai muita erityismerkkejä Hyväksyttyjä ovat ( ) _ . , ","Virhe", 10000)
            }
        } else {
            setNameOk(false)
        }
    }

    const toggleModify = (file) => {
        setSelectedFile(file)
        setModifyOpen(true)

    }
    const handleModifyChange = (e) => {
        const { id, value } = e.target
        setSelectedFile(prevData => ({
            ...prevData,
            [id]:value
        }))
    }

    const handleModifiedSave = async() => {
       
        let params = {
            projectId: projectId,
            selectedFile:selectedFile,
            toCompare: toCompare
        }
        const response = await executeRequest('project/modifyFile',params)
        if(response){
            if(response.success){
                NotificationManager.success('Muutokset tallennettu!', 'Ilmoitus', 4000)
            }
            if(response.error){
                NotificationManager.error('Exceliä ei voitu lisätä vertailtaviin! Ole hyvä ja tarkasta, että se on vaaditun mukainen ja ota tarvittaessa yhteyttä tuki@qibbie.com', 'Virhe', 10000)
            }
            
            setToCompare(null)
            setModifyOpen(!modifyOpen)
            setSelectedFile(INITIAL_FILE_STATE)
            getFiles()
        }
    }
    useEffect(()=>{
        const getClients = async() => {
            const response = await executeRequest('sender/get/getCompanies', {})
            if(response && response.businessList){
                setClientList(response.businessList)
                setSelectedBusiness(response.businessList[0])
            }
        }
        getClients()
    },[])

    const toggleCompare = () => {
        if(!toCompare){
            setToCompare(true)
        } else {
            setToCompare(null)
        }
    }

    const toggleCancelNewFile = () => {
       
        setSelectedBusiness(clientList[0])
        clearFields()
        setModalOpen(false)
    }

    useEffect(()=>{
       
        if(selectedFile){ 
            let fileName = selectedFile.name + "." + selectedFile.accurateType
            checkExcel(fileName)
        }
    },[selectedFile])

    const [fileOptionsModal, setFileOptionsModal] = useState(false)
    const toggleFileOptionsModal = () => {
        setFileOptionsModal(false)
        setSelectedFile(INITIAL_FILE_STATE)
    }

    const handleFileListFilter = (file) => {

        if(file.name && file.name.toUpperCase().includes(modalSearch.toUpperCase())) return true

        return false
    }

    const handleAddToProject = async() => {
        let payload = {
            projectId: projectId,
            fileId: chosenFile.id,
            connectionType: "projects"
        }
        const response = await executeRequest("project/addExistingFile",payload)
        if(response.savedToProject){
            getFiles()
        } else {
            NotificationManager.error("Virhe lisättäessä liitettä", "Virhe", 2000)
        }
        setChosenFile(false)
        setModalOpen(false)
    }

    useEffect(()=>{
        if(projectFiles){
            setSortedAttachments(listSorter())
        }
    },[orderBy, sortOrder, projectFiles])

    const listSorter = () => {
        let sortedArray = [...projectFiles]
        let orderVal1 = sortOrder
        let orderVal2 = sortOrder === 1? -1 : 1
        if(orderBy !== "modified" && orderBy !== "created"){
            sortedArray.sort((a,b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase()) ? orderVal1 : orderVal2)
            return [...sortedArray]
        }
        return [...sortedArray]
    }

    const handleSortOrder = (attribute) => {
        if(orderBy !== attribute){
            setOrderBy(attribute)
            if(attribute === ""){
                setSortOrder(1)
            } else {
                setSortOrder(-1)
            }
        } else {
            setSortOrder(sortOrder === -1? 1 : -1)
        }
    }

    return(
        <>
            <Modal isOpen={fileOptionsModal} toggle={()=> toggleFileOptionsModal()}>
                <ModalHeader toggle={()=> toggleFileOptionsModal()}> <p className="modal-name-overflow-handler" style={{ color: '#9a0707' }}>{selectedFile.name +"."+selectedFile.accurateType}</p></ModalHeader>
                <ModalBody>
                    {selectedFile && (selectedFile.accurateType === "jpg" || selectedFile.accurateType === "jpeg" || selectedFile.accurateType === "png" || selectedFile.accurateType === "svg" || selectedFile.accurateType === "bmp" || selectedFile.accurateType.toLowerCase() === "pdf") &&
                        <button onClick={() => handleDownload(selectedFile, true)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>Esikatsele</button>
                    }   
                    <button onClick={() => handleDownload(selectedFile)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>Lataa</button>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => toggleFileOptionsModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                </ModalFooter>
            </Modal>
            <Modal id="creation-modal" isOpen={modalOpen} toggle={() => toggleCancelNewFile()} size="lg">
                <ModalHeader toggle={() => toggleCancelNewFile()}>Lisää liite</ModalHeader>
                <ModalBody>
                    <Row style={{paddingBottom: '0.5em', marginBottom: '0.5em'}}>
                        <Col>
                            <button onClick={() => (setType("existing"), clearFields())} className={"small-white-button " + (type === "existing" ? "active-type" : "")} style={{ width: '100%' }}>Lisää olemassa oleva</button>
                        </Col>
                        <Col>
                            <button onClick={() => (setType("newFile"), setChosenFile(false))} className={"small-white-button " + (type === "newFile" ? "active-type" : "")} style={{ width: '100%' }}>Lisää uusi</button>
                        </Col>
                    </Row>
                    {type === "existing" && 
                        <>
                            {chosenFile ?
                                <>
                                    <Row>
                                        <Col>
                                            <button className="small-white-button lighter-text wide-button" style={{ }} onClick={() => setChosenFile(false)}>Takaisin</button>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '2em', marginBottom: '2em'}} >
                                        <Col xs="12" style={{ alignItems: 'center', marginBottom: '1em'}}>
                                            <p style={{ fontWeight: 'bold'}}>{chosenFile.name? chosenFile.name + "." + chosenFile.fileEnd : "Ei nimeä"}</p>
                                        </Col>
                                        { chosenFile.mimeType && chosenFile.mimeType.toUpperCase().includes('IMAGE')&&
                                            <Col xs="12">
                                                <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '100%', maxWidth: '100%', backgroundRepeat: 'no-repeat' }}>
                                                    <img style={{ maxHeight: '500px', maxWidth: '100%'}}  src={`${confs.host}/api/get/imagePreview/${chosenFile.id}`}></img>
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                </>
                                :
                                <>
                                    <Row>
                                        <Col style={{marginRight:'3em'}}>
                                            <div style={{ marginLeft: '2em', width: '100%', marginRight:'0.8em', marginBottom: '0.5em' }}>
                                                <input type="search" className="search-bar-open form-control" placeholder={"Hae liitettä"} value={search} onChange={(event) => setSearch(event.target.value)} />
                                            </div>
                                        </Col>
                                    </Row>
                                    {fileList && fileList.length > 0 ?
                                        <Scrollbars
                                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                            style={{ width: "100%", height: '500px' }}
                                            className="custom-scrollbars"
                                        >    
                                            {fileList.filter(file => handleFileListFilter(file)).map((file, key) => {
                                                let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                                                let isImage = false;
                                                if(file.mimeType && file.mimeType.toUpperCase().includes('IMAGE')){
                                                    isImage = true
                                                }
                                                return(
                                                    <Row key={key} style={{ height: '85px', marginLeft: 0 }} className={oddEvenClass + ' list-row'} onClick={()=> setChosenFile(file)}>
                                                        <Col xs="8">
                                                            <p>{file.name? file.name + "." + file.fileEnd : "Ei nimeä"}</p>
                                                        </Col>
                                                        {isImage &&
                                                            <Col xs="4">
                                                                <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '70px', maxWidth: '300px', backgroundRepeat: 'no-repeat' }}>
                                                                    <img style={{ maxHeight: '70px'}}  src={`${confs.host}/api/get/imagePreview/${file.id}`}></img>
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                )
                                            })}
                                        </Scrollbars>
                                        :
                                        <p> Ei tiedostoja </p>
                                    }
                                </>
                            }
                        </>
                    }
                    {type === "newFile" &&
                        <>
                            <FormGroup> {}
                                <Dropzone
                                    setFile={setNewFile}
                                /> 
                                <Label>Anna tiedostolle nimi</Label>
                                <Input id="fileName" type="text" value={fileName} onChange={(e) => setFileName(e.target.value)}></Input>                         
                            </FormGroup>
                            <FormGroup>
                                <Label>Minkä yrityksen tiedosto on kyseessä</Label>
                                <Select
                                    value={selectedBusiness}
                                    onChange={(selected) => setSelectedBusiness(selected)}
                                    options={clientList}
                                    placeholder="Valitse kohdeyritys"
                                    isDisabled={false}
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    className="companyTargets"
                                    classNamePrefix="select"
                                />
                            </FormGroup>
                            {compareOption && newFile && fileOk &&
                                <div style={{marginBottom: '1em'}}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" onChange={() => toggleCompare()} />{' '}
                                            Lisää vertailtaviin (HUOM! vain tietynlainen excel hyväksytään tähän)
                                        </Label>
                                    </FormGroup>
                                </div>
                            }
                            <FormGroup>
                                <Label>Lisää tiedostolle tageja</Label>
                                <TagHandler
                                    handleChange={handleTagChange}
                                    tags={tags}
                                />
                            </FormGroup>
                        </>
                    }
                </ModalBody>
                <ModalFooter className="taskCreatorFooter">
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggleCancelNewFile()}>Peruuta</a>
                    {showSave && 
                        <button className="small-white-button wide-button" onClick={() => handleSave()}>Tallenna</button>
                    }
                    {chosenFile && 
                        <button className="small-white-button wide-button" onClick={() => handleAddToProject()}>Lisää tiedosto</button>
                    }
                </ModalFooter>
            </Modal>
            <Modal isOpen={deleteModalOpen} toggle={() => (setDeleteModalOpen(!deleteModalOpen), setDeleteText(""))}>
                <ModalHeader>{selectedFile && selectedFile.name}</ModalHeader>
                <ModalBody>
                    <p>Oletko varma, että haluat poistaa tiedoston? <b>Toimintoa ei voi peruuttaa!</b></p>
                    <div style={{ marginTop: '1em' }}>
                        <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                        <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                        <div className="email-check-box">
                            {deleteText === "poista"?
                                <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                :
                                <i className="fas fa-times" style={{ color: 'red' }}></i> 
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => (setDeleteModalOpen(!deleteModalOpen), setDeleteText(""))} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => handleDelete()} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                </ModalFooter>
            </Modal>
            <Modal id="modify-modal" isOpen={modifyOpen} toggle={() => setModifyOpen(!modifyOpen)} size="lg">
                <ModalHeader toggle={() => setModifyOpen(!modifyOpen)} >{selectedFile.name}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                         <Label>Tiedoston nimi</Label>
                        <Input id="name" type="text" value={selectedFile.name} onChange={(e) => handleModifyChange(e)}></Input>      
                    </FormGroup>
                    <FormGroup>
                        <Label>Minkä yrityksen tiedosto on kyseessä</Label>
                        <Select
                            value={selectedFile.business}
                            onChange={(selected) => handleModifyChange({target:{id:"business", value:selected}})}
                            options={clientList}
                            placeholder="Valitse kohdeyritys"
                            isDisabled={false}
                            noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                            className="companyTargets"
                            classNamePrefix="select"
                        />
                    </FormGroup>
                    {selectedFile.type !== 'compare' && compareOption && selectedFile &&
                        <div style={{marginBottom: '1em'}}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" onChange={() => toggleCompare()} />{' '}
                                    Lisää vertailtaviin (HUOM! vain tietynlainen excel hyväksytään tähän)
                                </Label>
                            </FormGroup>
                        </div>
                    }
                    <FormGroup>
                        <Label>Tagit</Label>
                        <TagHandler
                            handleChange={handleModifyChange}
                            tags={selectedFile.tags}
                        />
                    </FormGroup>             
                </ModalBody>
                <ModalFooter className="taskCreatorFooter">
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => (setModifyOpen(!modifyOpen), setFileName(""))}>Peruuta</a>
                    <button className="small-white-button wide-button" onClick={() => handleModifiedSave()}>Tallenna</button>
                </ModalFooter>
            </Modal>
            <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ ease: 'easeIn', duration: 0.3 }}
                exit={{ opacity: 0, x: -50 }}
                key={"attachments"}
            >
                <div>
                    <Row className="tab-header bigger-text" style={{ display: 'flex', alignItems: 'center'}}> 
                        <Col xs="5">
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1em' }}>
                                <Input type="search" className="search-bar-open" placeholder={"Hae"} value={search} onChange={(event) => setSearch(event.target.value)} />
                            </div>
                        </Col>
                        <Col xs="3">
                        </Col>
                        <Col xs="4">
                            <button className="small-white-button wide-button" style={{ float: 'right' }} onClick={() => setModalOpen(true)}>Lisää projektille liite</button>
                        </Col>
                    </Row>
                    <Row style={{ fontSize:'0.9rem', marginBottom:'0.25em' }}>
                        <Col xs="5" style={{ cursor:'pointer', marginLeft:'1.25em'}}>
                            <ListHeaderSorter label={"Nimi"} name={"name"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder}/>
                        </Col>
                        <Col xs="3" style={{ marginLeft:'-1.25em'}}>Tagit</Col>
                        <Col xs="3" style={{ marginLeft:'-1em'}}>Tiedostotyyppi</Col>
                        <Col></Col>
                    </Row>
                    <div>
                        {projectFiles && projectFiles.length > 0 ?
                            <Scrollbars
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                style={{ width: "100%", height: '500px' }}
                                className="custom-scrollbars"
                            >
                            {sortedAttachments && sortedAttachments.length > 0 && sortedAttachments.filter(f => handleListNameFilter(f)).map((file, i)=>{
                                let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row';
                                i++;
                                return(
                                    <div key={i} style={{ padding: '0.75em 1em'}} onClick={() => (setSelectedFile(file), setFileOptionsModal(true))} className={oddEvenClass + " list-row"}>
                                        <Row>
                                            <Col xs="5">
                                                <p className="content-text name-overflow-handler-long">{file.name}</p>
                                                {file.business && 
                                                    <p className="name-overflow-handler-long" style={{fontSize: '0.8rem'}}>{file.business.label}</p>  
                                                }
                                            </Col>
                                            <Col xs="3">
                                                <div className="tag-row">
                                                    {file.tags && file.tags.slice(0, 7).map((tag,y) => {
                                                        return(
                                                            <div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="tag-display tag-text project-lighter-color" title={tag.label}>{tag.label}</p></div>
                                                        )
                                                    })}
                                                    {file.tags && file.tags.length > 7 &&  
                                                            <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                                                <i className="material-icons" title={tagsToMore(file.tags.slice(7,file.tags.length))}>more_horiz</i>
                                                            </div>
                                                    }
                                                </div>  
                                            </Col>
                                            <Col xs="3" style={{fontSize: '0.7em' }}>
                                                <p>Tiedostotyyppi: <span style={{ fontWeight:'bold' }}>{file.accurateType}</span></p>
                                                {file.type === 'compare' && <p><b>Vertailtavissa</b></p>}
                                            </Col>
                                            <Col xs="1" >
                                                <Dropdown isOpen={menuOpen === i} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent:'center' }} onClick={(event) => event.stopPropagation()} toggle={() => toggleDropdown(i)}>
                                                    <DropdownToggle tag="div" style={{  float: 'right', fontSize: '1rem' }}>
                                                        <i className="fas fa-bars" title="Valikko"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => toggleModify(file)}>Muokkaa</DropdownItem>
                                                        {file && (file.accurateType.toLowerCase() === "jpg" || file.accurateType.toLowerCase() === "jpeg" || file.accurateType.toLowerCase() === "png" || file.accurateType.toLowerCase() === "svg" || file.accurateType.toLowerCase() === "bmp" || file.accurateType.toLowerCase() === "pdf") &&
                                                            <DropdownItem onClick={() => handleDownload(file, true)}>Esikatsele</DropdownItem>
                                                        }
                                                        <DropdownItem onClick={() => handleDownload(file)}>Lataa tiedosto</DropdownItem>
                                                        <DropdownItem onClick={() => toggleDelete(file)}>Poista</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </div>  
                                )
                            })} 
                            </Scrollbars>
                            :
                            <EmptyProjectList listText={"Ei liitteitä"} />
                        }             
                    </div>
                </div>
            </motion.div>
        </>
    )
} 

const tagsToMore = (props) => {
    let tagss = ""
    
    if(props !== undefined){
        props.filter(tags => tagss = tagss + "  " + tags.label.toString())

        return(tagss)
    }
}
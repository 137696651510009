import { NotificationManager } from 'react-notifications'
import * as ActionTypes_login from '../../../../store/actions/Login/ActionTypes';
import { confs } from '../../../../modules/config'

export const getTrelloUserBoards = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'get/trelloUserBoards', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        if( result.length === 0 ) {
            NotificationManager.error( 'Antamillasi tunnuksilla ei löytynyt käyttäjää tai aktiivisia tauluja.', '', 4000 )
        }
        resolve( result )
    } )
}

export const getTrelloBoardLists = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'get/trelloBoardLists', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        resolve( result )
    } )
}

const updateUserTrelloConnection = (payload) => {
    return {
        type: ActionTypes_login.SET_TRELLO_KEYS,
        payload: payload
    }
}

export const saveTrelloKeys = (props) => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    let payload = {
        ...props,
        jwtToken:jwt
    }

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'save/trelloKeysForUser', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        if( result.length === 0 ) {
            NotificationManager.error( 'Virhe tallentaessa avaimia. Yritä hetken kuluttua uudelleen', '', 4000 )
        } else {
            dispatch(updateUserTrelloConnection(result.trelloConnection))
        }
        resolve( result )
    } )
}
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap'
import { connect } from 'react-redux';
import * as ActionCreator from '../../store/index';

import EmbedList from './subComponents/EmbedList';
import ModifyEmbed from './subComponents/ModifyEmbed';

import { Scrollbars } from 'react-custom-scrollbars';

import './embed.css';

class Embed extends Component {
    constructor() {
        super()

        this.state = {
            editId: null,
            filter: ''
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.edit && this.props.edit.id !== this.state.editId) {
            this.setState({ editId: this.props.edit.id })
        }
    }


    componentDidMount() {
        this.props.setBreadcrumbs({ to: 'tiedot', label: `${this.props.businessName}` })
        this.props.addMoreBreadcrumbs([{ to: 'upotus', label: "upotukset" }]);
        this.props.getEmbedList();
        this.props.getSentSurveys();
    }

    componentWillUnmount() {
        this.props.setEditEmbed()
    }

    handleSelection = (embed = null, id) => {
        
        if (id === -1) {
            this.props.setEditEmbed({})
        } else {
            this.props.setEditEmbed(embed)
        }
    }

    toggleDropdown = (y) => {
        if (this.state.selected === y) {
            this.setState({ selected: false });
        } else {
            this.setState({ selected: y })
        }
    }

    setfilter = (filter) => {
        this.setState({filter: filter});
    }


    render() {
        return (
            <Container fluid className="embed-container">
                <Row>
                    <Col xl="4" xs="12">
                        {this.props.embedList ?
                            <EmbedList
                                list={this.props.embedList}
                                handleSelection={this.handleSelection}
                                editId={this.state.editId}
                                toggleDropdown={this.toggleDropdown}
                                selected={this.state.selected}
                                delete={this.props.deleteEmbed}
                                setfilter={this.setfilter}
                                filter={this.state.filter}
                            />
                            :
                            <EmptyBox title="Upotukset" load />
                        }
                    </Col>
                    <Col xl="8" xs="12">
                        <ModifyEmbed
                            save={this.props.saveEmbed}
                            embed={this.props.edit}
                            id={this.state.modifyId}
                            sentSurveys={this.props.sentSurveys}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}

const EmptyBox = (props) => {
    return (
        <div className="company-box" >
            <div className="box-header">
                <p><b>{props.title}</b></p>
            </div>
            <Scrollbars
                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                style={{ width: "100%", height: '700px', marginTop: '0.5rem' }} >
                {props.load &&
                    <MegaLoaderSpinner />
                }
            </Scrollbars>

        </div>
    )
}

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}
const mapStateToProps = state => {
    return {
        businessName: state.authentication.businessName,
        embedList: state.embed.embedList,
        edit: state.embed.edit,
        sentSurveys: state.embed.sentSurveys
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: (params) => dispatch(ActionCreator.setBreadcrumbs(params)),
        addMoreBreadcrumbs: (params) => dispatch(ActionCreator.addMoreBreadcrumbs(params)),
        getEmbedList: () => dispatch(ActionCreator.getEmbedList()),
        setEditEmbed: (payload) => dispatch(ActionCreator.setEditEmbed(payload)),
        saveEmbed: (payload) => dispatch(ActionCreator.saveEmbed(payload)),
        getSentSurveys: () => dispatch(ActionCreator.getSentSurveysForEmbed()),
        deleteEmbed: (payload) => dispatch(ActionCreator.deleteEmbed(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Embed);

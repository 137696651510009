import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col} from 'reactstrap';
import moment from 'moment';
import TextEditor from '../../../../../Utilities/TextEditor/texteditor';
import 'rc-slider/assets/index.css';
import '../../taskCreator/modals/modal.css';

class TaskCardModal extends Component {
    constructor() {
        super()

        this.state = {
            isNotificationFocused: false,
            isDeadlineFocused: false,
            showTree: false
        }
    }

    onStatusChange = status => {
        let params = {
            taskId: this.props.task._id,
            status: status,
            businessId: this.props.task.targetCompany
        }

        this.props.updateTaskStatus( params )
    }

    getSurveyTitles = surveyTitles => {
        let titlesAsString = "";

        surveyTitles.forEach((title, x) => {
            if(x !== surveyTitles.length - 1) {
                titlesAsString += title.title + "   " + `( ${title.code} )` + ", ";
            } else {
                titlesAsString += title.title + "   " + `( ${title.code} )`;
            }
        })
        return titlesAsString;
    }

    render() {
        const { task, isOpen, toggler, selectReport } = this.props
        const { taskTree } = this.props.task
        return (
            <Modal isOpen={isOpen} toggle={toggler} size="lg" centered>
                <ModalHeader toggle={toggler} className="taskModal">
                    <div>
                        Tarkistuspisteen yhteenveto
                        {(taskTree !== undefined && Object.keys(taskTree).length > 0) &&
                        <button className="small-white-button lighter-text wide-button" onClick={() => this.setState(prevState => ({
                            showTree: !prevState.showTree
                        }))} style={{marginLeft: '3rem'}}>
                            {this.state.showTree ? "Piilota" : "Näytä tarkistuspisteen sijainti"}
                        </button>}
                    </div> 
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div style={{width: '100%'}}>
                            {taskTree !== undefined &&
                                <div>
                                    {this.state.showTree && 
                                        <div>
                                            <Col xl="12" style={{paddingBottom: '0.3rem'}}>
                                                <p>{taskTree.surveyTitles.length === 1 ? "Kartoitus: " : "Kartoitukset: "}</p>
                                                <p><b>{this.getSurveyTitles(taskTree.surveyTitles)}</b></p>
                                            </Col>
                                            <Col xl="12" style={{paddingBottom: '1rem'}}>
                                                <p>Rakenne: </p>
                                                <div className="treeStack">
                                                    {taskTree.elements.survey !== undefined &&
                                                        <div className="taskTreeRow">
                                                            <i className="fas fa-layer-group taskTreeIcon"></i><p>{taskTree.elements.survey}</p>
                                                        </div>
                                                    }
                                                    {taskTree.elements.group !== undefined &&
                                                        <div className="taskTreeRow" style={{paddingLeft: '2em'}}>
                                                            <i className="fas fa-layer-group taskTreeIcon"></i><p>{taskTree.elements.group}</p>
                                                        </div>
                                                    }
                                                    {taskTree.elements.question !== undefined &&
                                                    <div className="taskTreeRow" style={{paddingLeft: '4em'}}>
                                                        <i className="fas fa-layer-group taskTreeIcon"></i><p>{taskTree.elements.question}</p>
                                                    </div>
                                                    }
                                                </div>
                                                { taskTree.surveyTitles.some( sTitle => sTitle.id !== undefined ) &&
                                                    <div style={{ marginTop: '1rem' }}>
                                                        <button className="small-white-button lighter-text" onClick={ () => selectReport( task ) }>Avaa raportti</button>
                                                    </div>
                                                }
                                            </Col>
                                        </div>
                                    }
                                </div>
                            }
                            <Col xl="12">
                                <h5 style={{ marginBottom: 0 }}>Otsikko:</h5>
                                <TextEditor editorId={"taskCreatorTitle"}
                                    content={task.taskTitle || ""}
                                    readOnly={true}
                                    additionalClass="taskcard-text"
                                />
                            </Col>
                            <Col xl="12">
                                <h5 style={{ marginBottom: 0 }}>Selite:</h5>
                                <TextEditor 
                                    editorId={"taskCreatorExplanation"}
                                    content={task.styledTextTask || ""}
                                    readOnly={true}
                                    additionalClass="taskcard-text"
                                    allowFileUpload={false}
                                />
                            </Col>
                            <hr style={{clear: 'both'}}/>
                        </div>
                        
                        {task.deadline !== null &&
                        <Col xl="6">
                            <h5 style={{marginBottom: 0}}>Deadline</h5>
                            <p>{moment(new Date(task.deadline)).format('DD.MM.YYYY')}</p>
                        </Col>}
                        {task.notificationDate !== null &&
                        <Col xl="6">
                            <h5 style={{marginBottom: 0}}>Muistutuspäivä</h5>
                            <p>{moment(new Date(task.notificationDate)).format('DD.MM.YYYY')}</p>
                        </Col>}
                        <Col xl="12">
                            <h5 style={{marginBottom: 0, marginTop: '1rem'}}>Vastuuhenkilöt</h5>
                                {task.notificationList.map((target, x) => {
                                    return <p key={`${target.label}-${x}`}>{target.label}</p>
                                })}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggler}>Peruuta</a>
                    {(task.status === "Avoin" || task.status === 'Ei huomioitu' || task.status === 'inactive') && <button className="small-white-button wide-button" onClick={ () => this.onStatusChange("done") }>Merkitse valmiiksi</button>}
                    {(task.status === "Avoin" || task.status === 'Ei huomioitu' || task.status === 'inactive') && <button className="small-white-button wide-button" onClick={ () => this.onStatusChange("declined") }>Merkitse peruutetuksi</button>}  
                </ModalFooter>
            </Modal>
        )
    }
}

export default TaskCardModal;
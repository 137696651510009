import React, {useState, useEffect} from 'react';
import { executeRequest } from "../../Utilities/requestHandler/dataHandler"

class templateVariables {
  constructor (){
        this.ownInfo = [
            {"name":"@oma_yritysnimi", "title": "oman yrityksen nimi", "anchor":{"id":"own", "type":"businessName"}},
            {"name":"@oma_email", "title": "oman yrityksen sähköposti", "anchor":{"id":"own", "type":"email"}},
            {"name":"@oma_osoite", "title": "oma yrityksen osoite", "anchor":{"id":"own", "type":"address"}},  
            {"name":"@oma_postinumero", "title": "oman yrityksen postinumero", "anchor":{"id":"own", "type":"postcode"}},
            {"name":"@oma_ytunnus", "title": "oman yrityksen y-tunnus", "anchor":{"id":"own", "type":"businessID"}}
        ]
       this.targetBusiness = [
            {"name":"@kohde_yritysnimi", "title": "kohdeyrityksen nimi", "anchor":{"id":"targetBusiness", "type":"businessName"}},
            {"name":"@kohde_email", "title": "kohdeyrityksen sähköposti", "anchor":{"id":"targetBusiness", "type":"email"}},
            {"name":"@kohde_osoite", "title": "kohdeyrityksen osoite", "anchor":{"id":"targetBusiness", "type":"address"}},
            {"name":"@kohde_ytunnus", "title": "kohdeyrityksen y-tunnus", "anchor":{"id":"targetBusiness", "type":"businessID"}}
        ]
        //client is the same client as in project to whom it's created for
        this.project_client = [
            {"name":"@projektin_kohde_yritysnimi", "title": "projektin kohteen nimi", "anchor":{"id":"client", "type":"businessName"}},
            {"name":"@projektin_kohde_email", "title": "projektin kohteen sähköposti", "anchor":{"id":"client", "type":"email"}},
            {"name":"@projektin_kohde_osoite", "title": "projektin kohteen osoite", "anchor":{"id":"client", "type":"address"}},
            {"name":"@projektin_kohde_ytunnus", "title": "projektin kohteen y-tunnus", "anchor":{"id":"client", "type":"businessID"}}
        ]
        //this.custom = getEnterpriseFields()
        

    
    }
   
}
export const tempVariables = new templateVariables()

// const getEnterPriseFields = async() => {
//     const response = executeRequest('')
// }
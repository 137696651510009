import React from 'react';
import {motion} from 'framer-motion';

const FilterButton = ({filterItem, removeItem, filters}) => {
    return (
        <motion.div
            initial={{opacity: 0, x: 100}}
            animate={{opacity: 1, x: 0}}
            transition={{ease: 'easeOut', duration: 0.2}}
            exit={{opacity: 0, x: -50}}
        >
            <div title={ filterItem.value } className="filter-nibblet mainFilter" onClick={ () => removeItem( filters.filter(x => x.value !== filterItem.value ) ) }>{ filterItem.value }</div>
        </motion.div>
    )
}

export default FilterButton; 
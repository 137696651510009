import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { HorizontalContainer, HorizontalList, HorizontalItem } from '../horizontal-scroller/HorizontalElements';
import { ContactCard } from '../horizontal-scroller/HorizontalCards';
import { executeRequest } from '../requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { confs } from '../../modules/config';
import { storeContacts } from '../../store/actions/Contacts/ActionCreator';
import { useDispatch } from 'react-redux';

const INITIAL_STATE = {
    name: "",
    email: "",
    phone: "",
    role: "",
    tags:[]
}

export const ContactModal = ({isOpen, toggleModal, business = null, allowRemove=false }) => {

    const [search, setSearch] = useState("")
    const [contacts, setContacts] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [contact, setContact] = useState(INITIAL_STATE)
    const [delModalOpen, setDelModalOpen] = useState(false)
    const [selectedData,setSelectedData] = useState({})
    const [deleteText, setDeleteText] = useState("")

    const dispatch = useDispatch()
    
    useEffect(()=>{
        if(business){

            getContacts()

            setContact(prevContent => ({
                ...prevContent,
                business: {businessName: business.businessName, id: business._id}
            }))
        }
    },[isOpen])
    
    const getContacts = async() => {
        let payload = {
            targetBusiness: business._id
        }
        const response = await executeRequest('business/getContactInfo', payload)
        if(response.contacts){
            setContacts(response.contacts)
            dispatch(storeContacts(response.contacts))
        } 
        setIsLoading(false)
    }   

    const   handleSave = async() => {
        if(contact.name.length > 0 && (contact.email.length > 0 || contact.phone.length > 0)){
            if(!contact.business){
                setContact(prevContent => ({
                    ...prevContent,
                    business: {businessName: business.businessName, id: business._id}
                }))
            }
            let payload = {
                contact: contact
            }

            const response = await executeRequest('business/saveContactInfo', payload)
            if(response.success){
                NotificationManager.success("Tallennettu onnistuneesti!", "", 3000);
                getContacts()
                setContact(INITIAL_STATE)
            }
        } else {
            NotificationManager.error("Anna ensin kontaktille tiedot!", "Virhe!", 3000);
        }
    }
    const handleChange = (target) => {
        const {name, value} = target
        setContact(prevData => ({
            ...prevData,
            [name]:value
        }))
    }

    const handleListNameFilter = contact => {
        const {business, tags, name, jobTitle} = contact
        let businessName = business.businessName

        if(businessName && businessName.toUpperCase().includes(search.toUpperCase())) return true
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        if(jobTitle && jobTitle.toUpperCase().includes(search.toUpperCase())) return true
        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    const handleRemove = (contact) => {
        setSelectedData(contact)
        setDelModalOpen(true)
    }

    const removeContact = async() => {
        if(deleteText === 'poista'){
            let payload = {
                id:selectedData._id
            }

            const response = await executeRequest('business/removeContact', payload)
            if(!response.success){
                NotificationManager.error("Virhe poistaessa kontaktia", "", 3000);
            }
            setDelModalOpen(false)
            setSelectedData({})
            setContact(INITIAL_STATE)
            getContacts()
        }
    }

    return(
        <>
            <Modal isOpen={delModalOpen} toggle={() => (setDelModalOpen(!delModalOpen), setDeleteText(""))}>
                <ModalHeader toggle={() => (setDelModalOpen(!delModalOpen), setDeleteText(""))}>{selectedData ? selectedData.name : ""}</ModalHeader>
                <ModalBody>
                    <p>Oletko varma, että haluat poistaa kontaktin? <b>Toimintoa ei voi peruuttaa!</b></p>
                    <div style={{ marginTop: '1em' }}>
                        <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                        <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                        <div className="email-check-box">
                            {deleteText === "poista"?
                                <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                :
                                <i className="fas fa-times" style={{ color: 'red' }}></i> 
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => (setDelModalOpen(!delModalOpen), setDeleteText(""))} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => removeContact(selectedData._id)} className="small-white-button wide-button swb-hover delete-plan" style={{backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                </ModalFooter>
            </Modal>
            <Modal isOpen={isOpen} toggle={() => toggleModal()} size="lg">
                <ModalHeader toggle={()=> toggleModal()}>Kontaktien hallinta</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Nimi </Label>
                        <Input name="name" value={contact.name} onChange={(e) => handleChange(e.target)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Rooli / ammattinimike</Label>
                        <Input name="role" value={contact.role} onChange={(e) => handleChange(e.target)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Sähköpostiosoite</Label>
                        <Input name="email" type="email" value={contact.email} onChange={(e) => handleChange(e.target)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Puhelinnumero </Label>
                        <Input name="phone" value={contact.phone} onChange={(e) => handleChange(e.target)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Tagit</Label>
                        <TagHandler
                            tags={contact.tags}
                            handleChange={handleChange}
                        />
                    </FormGroup>
                    {/* <FormGroup>
                        <Label>Valitse kuva</Label>
                        
                    </FormGroup> */}
                    <div className="contact-card-overview">
                        <HorizontalContainer label={"Kontaktit"} setFilter={setSearch}>
                            <HorizontalList>
                                {contacts && contacts.length > 0 ? contacts.filter(ctct => handleListNameFilter(ctct)).map((contact,key) =>
                                    <HorizontalItem key={key}>
                                        <ContactCard contact={contact} onSelect={setContact} removeSelected={handleRemove} allowRemove={allowRemove}/>
                                    </HorizontalItem>
                                ) : (
                                        <EmptyList isLoading={isLoading} />
                                    )}
                                </HorizontalList>
                            </HorizontalContainer>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => ( setContact(INITIAL_STATE), toggleModal() )} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Sulje</a>
                    <button onClick={() => handleSave()} className="small-white-button wide-button swb-hover" >Tallenna kontakti</button>              
                </ModalFooter>
            </Modal>
        </>
    )
}

const EmptyList = ({ isLoading, error = "" }) => {
    return (
        <div className="list-placeholder-container">
            {isLoading ?
                <ListLoadingSpinner />
                :
                <h3>{error === "" ? "Ei tuloksia" : error}</h3>
            }
        </div>
    )
}

const ListLoadingSpinner = () => {
    return (
        <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}

const TagHandler = props => {
    async function promiseOptions(inputValue) {
        let params = {
            data: inputValue,
            jwtToken: window.sessionStorage.getItem("token")
        }
        const data = fetch(confs.url + 'tagSearch', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => response.json())

        let tags = await data;

        return tags
    };

    function NoOptionsMessage(props) {
        if (props.options.length) return null;
        return <div {...props.innerProps} style={{padding: 5}}>Voit hakea tai lisätä tageja kirjoittamalla</div>;
    }

    const components = {
        NoOptionsMessage
    }

    function formatCreateLabelCreator(value) {
        return 'Lisää tagi: "' + value + '"'
    }

    return (  
        <AsyncCreatableSelect 
            loadOptions={promiseOptions} 
            components={components} 
            isMulti 
            value={props.tags} 
            onChange={selectedTags => props.handleChange({ name: "tags", value: selectedTags})}
            placeholder=""
            className='react-select-container'
            classNamePrefix="react-select"
            formatCreateLabel={formatCreateLabelCreator} 
        />
    )
}
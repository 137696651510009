export const STORE_COMPANIES = "FETCH_COMPANIES";
export const STORE_SURVEYS = "STORE_SURVEYS";
export const END_SURVEY = "END_SURVEY";
export const FETCH_ANON = "FETCH_ANON";
export const STORE_AVAILABLE_SURVEYS = "STORE_AVAILABLE_SURVEYS";
export const SET_MODIFIED_SENT_SURVEY = "SET_MODIFIED_SENT_SURVEY";
export const DELETE_SENT_SURVEY_FROM_LIST = "DELETE_SENT_SURVEY_FROM_LIST";
export const ADD_SENT_SURVEY = "ADD_SENT_SURVEY";
export const GROUPED_SURVEYS = "GROUPED_SURVEYS";
export const AUTO_SELECT_SURVEY = "AUTO_SELECT_SURVEY";
export const CHANGE_SURVEY_TARGET = "CHANGE_SURVEY_TARGET";
export const SET_BACKGROUND_LIST = "SET_BACKGROUND_LIST";
export const ADD_TO_BACKGROUND_LIST = "ADD_TO_BACKGROUND_LIST";
export const UPDATE_SENT_SURVEYS_FOR_DASHBOARD = "UPDATE_SENT_SURVEYS_FOR_DASHBOARD";
export const UPDATE_SELECTED_SENT_SURVEY = "UPDATE_SELECTED_SENT_SURVEY";
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer'
import {motion, useAnimation} from 'framer-motion';
import {Row, Col, Label, DropdownMenu, DropdownItem, Dropdown, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {confs} from '../../../modules/config';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';

const SurveyListItem = ({sentSurvey, selectReport, changeDate, toggleChangeSurveyTarget, toggleKartoitusEdit, toggleNameChange, toggleMenu, endSurvey, index, menuIndex, modifyBackground, oddEven, projects = [] }) => {
    const [ref, inView, entry] = useInView({
        threshold: 0,
    })

    const [addToProjectModal, setAddToProjectModal] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null)

    let startDate
    let endDate
    const today = moment.utc().local()
    let titles = ""



    if (!sentSurvey.dates){
        startDate = moment(new Date(sentSurvey.startDate)).format('DD.MM.YYYY')
        endDate = moment(new Date(sentSurvey.endDate)).format('DD.MM.YYYY')
    }else{
        startDate = moment(new Date(sentSurvey.dates[0].startDate)).format('DD.MM.YYYY')
        endDate = moment(new Date(sentSurvey.dates[0].endDate)).format('DD.MM.YYYY')
    }

    sentSurvey.dates && sentSurvey.dates.map((date) => {
        const dateS = moment.utc(date.startDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
        const dateE = moment.utc(date.endDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
        titles = titles + moment(new Date(date.startDate)).format('DD.MM.YYYY') + " - " +moment(new Date(date.endDate)).format('DD.MM.YYYY') + "\n"

        if(today.isBetween(dateS, dateE)){
            startDate = moment(new Date(date.startDate)).format('DD.MM.YYYY')
            endDate = moment(new Date(date.endDate)).format('DD.MM.YYYY')
        }
    })

    let color = (sentSurvey && sentSurvey.answerCount && sentSurvey.answerCount > 0) ? (sentSurvey.surveyAvgColor || '#c8c8c8') : '#c8c8c8'
    let avg = sentSurvey.surveyAvgScore || 0

    const variants = {
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {delay: i * 0.3, ease: 'easeIn', duration: 0.2}
        }),
        hidden: {
            opacity: 0
        }
    }

    const handleAddToProject = async() => {
        if( selectedProject !== null ){
            let payload = {
                projectId: selectedProject.value,
                sentSurveyId: sentSurvey._id
            }
            const response = await executeRequest('project/addSurveyToProject', payload)
            if(response.success){
                NotificationManager.success('Kartoitus lisätty projektiin onnistuneesti!', '', 3000)
                setAddToProjectModal(!addToProjectModal)
            }
            else if(response.exists){
                NotificationManager.error('Kartoitus on jo lisätty projektiin!', 'Virhe', 3000)
            }
            else {
                NotificationManager.error('Virhe lisätessä kartoitusta!', 'Virhe', 3000)
            }
        } else {
            NotificationManager.error('Ei valittua projektia!', 'Virhe', 3000)
        }
    }

    return (
        
        <motion.div
            initial={{opacity: 0, x: 150}}
            animate={inView ? "visible": "hidden"}
            variants={variants}
            exit={{opacity: 0, x: -100}}
            ref={ref}
            style={{minHeight: 80}}
            key={index}
            className={oddEven + " list-row"}
        >
            <Modal size="lg" isOpen={addToProjectModal} toggle={() => setAddToProjectModal(!addToProjectModal)}>
                <ModalHeader toggle={() => setAddToProjectModal(!addToProjectModal)}> Lisää kartoitus projektiin</ModalHeader>
                <ModalBody>
                    <Label>Valitse projekti, johon kartoitus liitetään</Label>
                    <Select
                        value={selectedProject}
                        onChange={(selected) => setSelectedProject(selected)}
                        options={projects}
                        placeholder="Valitse projekti.."
                        isDisabled={false}
                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                        className="companyTargets"
                        classNamePrefix="select"
                    />
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => setAddToProjectModal(!addToProjectModal)}>Peruuta</a>
                    <button className="small-white-button wide-button" onClick={() => handleAddToProject()}>Tallenna</button>
                </ModalFooter>
            </Modal>
        {inView &&
            <Row className="" onClick={() => selectReport(sentSurvey)}>
                <Col xs={{ size: 1 }} >
                    <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                        <div className="smaller-score-ball" style={avg.length > 3 ? { borderColor: color, fontSize: '0.9rem' } : { borderColor: color }}>{avg}</div>
                    </div>
                </Col>
                <Col xs={{ size: 5 }}>
                    <Row>
                        <Col xs={{ size: 12 }}>
                            <p className="big-text mb-0">{sentSurvey.name}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ size: 12 }}>
                            <p className="lighter-text">{`${startDate} - ${endDate}`} {sentSurvey.dates && sentSurvey.dates.length > 1 ? <i className="far fa-calendar" style={{paddingLeft: '5px'}} title={titles}></i> : ""}</p>
                            <button style={{ marginTop: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem' }} className="small-white-button lighter-text">Tarkastele</button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ size: 2 }} style={{ textAlign: 'center' }} className="content-text">
                    <p>{sentSurvey.businessName}</p>
                </Col>
                <Col xs={{ size: 2 }} style={{ textAlign: 'center' }} className="content-text">
                    <Row>
                        <Col xs={{size: 12}}>
                            <p>{sentSurvey.code}</p>
                        </Col>
                        <Col xs={{size: 12}}>
                            <p><b>{sentSurvey.isActive ? 'Aktiivinen' : 'Päättynyt'}</b></p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ size: 1 }} style={{ textAlign: 'center' }}>
                    <Row>
                        <Col xs={{ size: 12 }}>
                            <p>{sentSurvey.answerCount}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ size: 12 }}>
                            <p className="lighter-text">Vastausta</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ size: 1 }} style={{ textAlign: 'center' }}>
                    <Dropdown isOpen={menuIndex === index} onClick={(event) => event.stopPropagation()} toggle={() => toggleMenu(index)}>
                        <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                            <i className="fas fa-bars" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            { sentSurvey.isActive && <DropdownItem onClick={ () => window.open( `${ confs.frontpageUrl }/${ sentSurvey.code }`, "_blank", "noopener" ) }>Avaa kartoitus</DropdownItem>}
                            {sentSurvey.isActive && <DropdownItem onClick={() => toggleChangeSurveyTarget(sentSurvey)}>Vaihda kohdeyritys</DropdownItem>}
                            <DropdownItem onClick={() => toggleKartoitusEdit( sentSurvey )}>Muokkaa kartoitusta</DropdownItem>
                            <DropdownItem onClick={() => toggleNameChange( sentSurvey )}>Muokkaa nimeä</DropdownItem>
                            <DropdownItem onClick={() => changeDate(sentSurvey)}>Muokkaa aikaväliä</DropdownItem>
                            <DropdownItem onClick={() => modifyBackground(sentSurvey)}>Muokkaa taustakuvaa</DropdownItem>
                            {projects && projects.length > 0 && <DropdownItem onClick={() => setAddToProjectModal(true)}> Lisää projektiin </DropdownItem>}
                            {sentSurvey.isActive && <DropdownItem onClick={(e) => endSurvey(sentSurvey, "cancel")}>Lopeta kartoitus</DropdownItem> }
                            <DropdownItem onClick={() => endSurvey(sentSurvey, "delete")}>Poista kartoitus</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
        }
        </motion.div>
    )
}

export default SurveyListItem;
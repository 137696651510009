import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalFooter, ModalBody, Col, Row, Button, FormGroup, FormFeedback, Input } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import * as ActionCreator from '../../planBuilder/Actions/ActionCreator';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { motion } from 'framer-motion';
import moment from 'moment';
import { FollowOptions } from '../../planBuilder/Modals/FollowOptions';
import { EmptyProjectList } from './ProjectLayout';
import { ListHeaderSorter } from './ListHeaderSorter';

export const PlanList = ({projectPlans, history, projectId, projectName, updatePlans, setPlanModalOpen, project}) => {
    const {plans} = projectPlans
    const [search, setSearch] = useState("")
    const [selectedData, setSelectedData] = useState({})
    const [menuOpen, setMenuOpen] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [isDelete, setIsDelete] = useState()
    const [followModal, setFollowModal] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [orderBy, setOrderBy] = useState("modified")
    const [sortedPlans, setSortedPlans] = useState([])
    const [optionsOpen,setOptionsOpen] = useState(false)
    const [sortOrder, setSortOrder] = useState(-1)

    const openAnswers = (id) => {
        if (id === this.state.answersId) {
            this.setState({ answersId: '' })

        } else {
            this.setState({ answersId: id })
        }
    }

    const dispatch = useDispatch()

    const startEditing = (planId, edit = null) => {
        
        dispatch(ActionCreator.startEditingPlan(planId, edit))
        
        history.push({
            pathname:'/lomakkeet/luonti',
            state:{
                crumbs:{
                    to: 'projektit/hallitse', label: projectName
                }, 
                projectId:projectId
            }
        })
    }

    const handleListNameFilter = plan => {
        const {name, planName, selectedBusiness, tags} = plan
        
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        else if(planName && planName.toUpperCase().includes(search.toUpperCase())) return true
        else if(selectedBusiness !== undefined) {
            const {value, label} = selectedBusiness

            if(label.toUpperCase().includes(search.toUpperCase())) {
                return true
            } 
        } 

        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    const handleDelete = async() => {
        let params = {
            projectId: projectId,
            planId: selectedData._id
        }

        const response = await executeRequest('project/removePlanFromProject', params)
        setModalOpen(false)
        setSelectedData({})
        updatePlans(projectId)
    }

    const toggleModal = (data, x) => {
        setIsDelete(x)
        setSelectedData(data) 
        setModalOpen(!modalOpen)
    }

    const toggleDropdown = (y) => {
        if (menuOpen === y) {
            setMenuOpen(null)
        } else {
            setMenuOpen(y)
        }
    }

    const lastActiveDay = (lastDate) => {
        if(lastDate){
            let today = moment.utc(new Date(),"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            let lastActive = moment.utc(lastDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(today === lastActive || today > lastActive){
                return(true)

            } else return(false)
        }
    }

    useEffect(()=>{
        if(plans){
            setSortedPlans(listSorter())
        }
    },[orderBy, sortOrder, plans])

    const listSorter = () => {
        let sortedArray = [...plans]
        let orderVal1 = sortOrder
        let orderVal2 = sortOrder === 1? -1 : 1

        if(orderBy === "planName"){
            sortedArray.sort((a,b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase()) ? orderVal1 : orderVal2)
            return [...sortedArray]
        } else{
            if(orderBy !== "modified" && orderBy !== "created"){
                sortedArray.sort((a,b) => (a[orderBy] > b[orderBy]) ? orderVal1 : orderVal2)
                return [...sortedArray]
            }
            if(orderBy === "modified" || orderBy === "created"){
                sortedArray.sort((a,b) => (new Date(a[orderBy]) > new Date(b[orderBy])) ? orderVal1 : orderVal2)
                return [...sortedArray]
            }
        }
        return [...sortedArray]
    }

    const handleSortOrder = (attribute) => {
        if(orderBy !== attribute){
            setOrderBy(attribute)
            if(attribute === "planName"){
                setSortOrder(1)
            } else {
                setSortOrder(-1)
            }
        } else {
            setSortOrder(sortOrder === -1? 1 : -1)
        }
    }

    let i = 0;

    return(  
        <>  
            {selectedPlan && followModal && 
                <FollowOptions
                    planId={selectedPlan._id}
                    followModalOpen={followModal}
                    setFollowModalOpen={setFollowModal}
                />
            }
            {isDelete &&
                <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                    <ModalHeader>{selectedData && selectedData.planName}</ModalHeader>
                    <ModalBody>
                        <p>Oletko varma, että haluat poistaa lomakkeen? <b>Toimintoa ei voi peruuttaa!</b></p>
                    </ModalBody>
                    <ModalFooter>
                        <a onClick={() => setModalOpen(!modalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                        <button onClick={() => handleDelete()} className="small-white-button wide-button delete-plan" style={{backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                    </ModalFooter>
                </Modal>
            }
            <div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ ease: 'easeIn', duration: 0.3 }}
                    exit={{ opacity: 0, x: -50 }}
                    key={"plans"}
                >
                    <div>
                        <Row className="tab-header bigger-text" style={{ display: 'flex', alignItems: 'center'}}>          
                            <Col xs="5">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1em' }}>
                                    <Input type="search" className="search-bar-open" placeholder={"Hae"} value={search} onChange={(event) => setSearch(event.target.value)} />
                                </div>
                            </Col>
                            <Col xs="3">
                            </Col>
                            <Col xs="4">
                                <button className="small-white-button wide-button project-survey-button" style={{ float: 'right' }} onClick={() => setPlanModalOpen("plan")} >
                                    Lisää projektille lomake
                                </button>
                            </Col>
                        </Row>
                        <Row style={{ fontSize:'0.9rem', marginBottom:'0.25em' }}>
                            <Col xs="5" style={{ cursor:'pointer', marginLeft:'1.25em'}}>
                                <ListHeaderSorter label={"Nimi"} name={"planName"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder}/>
                            </Col>
                            <Col xs="2" style={{ cursor:'pointer', marginLeft:'-1.5em'}}>
                                <ListHeaderSorter label={"Muokattu"} name={"modified"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder} />
                            </Col>
                            <Col xs="2" style={{ marginLeft:'-0.25em'}}>Tagit</Col>
                            <Col xs="2" style={{ marginLeft:'0em'}}>Status</Col>
                            <Col></Col>
                        </Row>
                        <div>
                            {plans && plans.length > 0 ?
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "100%" }}
                                    className="custom-scrollbars"
                                    autoHeight={true}
                                    autoHeightMin={'200px'}
                                    autoHeightMax={'500px'}
                                >
                                    {sortedPlans && sortedPlans.length > 0 && sortedPlans.filter(p => handleListNameFilter(p) || p.creator.toUpperCase().includes(search.toUpperCase())).map((plan, y)=>{
                                        //{sentSurveys.length > 0 && sentSurveys.filter(survey => statusFilter(survey)).map((sentSurvey, index) => {                        
                                        let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                        let signedInfo = "Tähän lomakkeeseen on jo tehty allekirjoitus, joten muokkaamaan ei pääse suoraan. Voit siirtyä lomakkeen esikatseluun painamalla lomaketta."
                                        i++;
                                        const {planName} = plan;
                                        let isFollowed = false
                                        if(plan.followOptions){
                                            plan.followOptions.map(option =>{
                                                if(option.planOpenFollowed && option.planOpenFollowed === true){
                                                    isFollowed = true
                                                }
                                                if(option.commentingFollowed && option.commentingFollowed === true){
                                                    isFollowed = true
                                                }
                                            }) 
                                        }      
                                        let isGanttPlan = false
                                        if(project && project.ganttPlan && project.ganttPlan.planId === plan._id){
                                            isGanttPlan = true
                                        }
                                        return (
                                            <div className={oddEvenClass + ' list-row'} style={{ padding: '0.75em 1em', alignItems: 'center' }} key={y} onClick={() => plan.automation ? openAnswers(plan._id) : startEditing(plan._id)}>
                                                {isGanttPlan && <div style={{ color:'red', fontSize:'0.7rem', fontWeight:'bold', marginTop:'-0.8em', marginBottom:'-0.2em'}}>Projektisuunnitelma</div>}
                                                <Row className='plan-list-content' style={{ alignItems: 'center' }}>
                                                    <Col xs="5">
                                                        <p title={planName} className="content-text name-overflow-handler-long">{planName}</p>
                                                        {plan.selectedBusiness &&
                                                            <p style={{ fontWeight:300, fontSize: '0.8rem' }}>{plan.selectedBusiness.label}</p>
                                                        }
                                                    </Col >
                                                    <Col xs="2" style={{ }}>
                                                        <p style={{ fontSize: '0.7rem'}}>Muokattu: <span>{moment.utc(plan.lastEdited? plan.lastEdited : plan.modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p> 
                                                        {plan.lastDate && 
                                                            <p style={{ fontSize: '0.7rem'}}>Akt: <span style={lastActiveDay(plan.lastDate)?{ color:'red' } : {}}>{moment.utc(plan.lastDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>
                                                        }
                                                    </Col> 
                                                    <Col xs="2" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                        {plan.tags&& plan.tags.slice(0, 3).map((tag, i) => {                        
                                                            return(<div key={i} style={{ marginLeft: '0.15em', paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                        })}                                                       
                                                        {plan.tags && plan.tags.length > 3 &&  
                                                            <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                                                <i className="material-icons" title={tagsToMore(plan.tags,3)}>more_horiz</i>
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col xs="2" style={{ marginLeft:''}}>
                                                        <Row>
                                                            {(plan.status && plan.status === 'published') &&
                                                                <span className="dot" title="Julkaistu" style={{backgroundColor: 'rgb(181, 228, 19)', fontSize: '0.8rem', marginLeft:'2em' }}></span>
                                                            }  
                                                            {(plan.isSigned === true)&& 
                                                                <div style={{ marginLeft: '0.5em'}}>
                                                                {(plan.allSigned && plan.allSigned === true)? 
                                                                    <i className="fas fa-signature" title="Allekirjoitettu" aria-hidden="true" style={{ color: '#b5e413', cursor: 'pointer', fontSize: '1.5rem' }}></i>
                                                                    : 
                                                                    <i className="fas fa-signature" title="Osin allekirjoitettu" aria-hidden="true" style={{ color: '#ffcc00', cursor: 'pointer', fontSize: '1.5rem' }}></i>
                                                                }
                                                                </div>
                                                            }  
                                                        </Row>
                                                        <Row>
                                                            {plan.annotations && plan.annotations.length > 0 &&
                                                                <i className="far fa-comment-dots" style={{ fontSize: '1.2rem', color: '#b5e413', marginTop: '0.15em', marginLeft:'1.4em'}} title="Sisältää kommentteja"></i>
                                                            }
                                                            {isFollowed && <div title="Lomaketta seurataan" style={{ marginLeft: '0.5em',  opacity: '0.8' }}><i className="far fa-eye"></i></div>}
                                                        </Row>
                                                    </Col>
                                                    <Col xs="1">
                                                        <Dropdown isOpen={menuOpen === y} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent:'center' }} onClick={(event) => event.stopPropagation()} toggle={() => toggleDropdown(y)}>
                                                            <DropdownToggle tag="div" style={{  float: 'right', fontSize: '1rem' }}>
                                                                <i className="fas fa-bars" title="Valikko"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {plan.isSigned?<DropdownItem onClick={() => startEditing(plan._id)}> Siirry lomakkeeseen </DropdownItem>:<DropdownItem onClick={() => startEditing(plan._id, true)}> Muokkaa lomaketta </DropdownItem>}
                                                                <DropdownItem onClick={() => (setFollowModal(true),setSelectedPlan(plan))}>Hallitse lomakkeen seurantaa</DropdownItem>
                                                                <DropdownItem onClick={() => toggleModal(plan, true)}>Poista projektista</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </Col>
                                                </Row>
                                            </div>                      
                                        )                       
                                    })}
                                </Scrollbars> 
                                :
                                <EmptyProjectList listText={"Ei lomakkeita"}/>
                            }
                        </div>                   
                    </div>
                </motion.div>
            </div>
        </>
    )
}
const tagsToMore = (tags, slicer) => {
    var tagss = ""
    if(tags !== undefined ){
        tags.slice(slicer, tags.length).filter(t => tagss = tagss + "  " + t.label.toString())
        return(tagss)
    }
}
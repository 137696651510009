import React, { useState, useEffect } from 'react';
import "gantt-task-react/dist/index.css";
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap'
import '../../../Dashboard/subComponents/dashboardGantt.css'
import moment from 'moment'
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { TagHandler } from '../../../Project/subComponents/TagHandler';
import { Scrollbars } from 'react-custom-scrollbars'

const INITIAL_PHASE = {
    name:"",
    start: null,
    end: null,
    description: "",
    tasks:[],
    id:'',
    progress: 0,
    styles: { progressColor: '#acd811', progressSelectedColor: '#fac465', backgroundColor:'#fac465', backgroundSelectedColor: '#fac465' },
    checked: false,
    type: 'project',
    tags:[],
}

const INITIAL_TASK = {
    name:"",
    start: null,
    end: null,
    description: "",
    id:'',
    progress: 0,
    styles: { progressColor: '#acd811', progressSelectedColor: '#aeb8c2', backgroundSelectedColor: '#aeb8c2' },
    checked: false,
    type: 'task',
    tags:[],
    isDateChangeable:true
}

const listOfDefaultColors = [
    '#fac465',
    '#FAF371', 
    '#7BDCB5', 
    '#00D084', 
    '#7DDBFA', 
    '#4BA0FA', 
    '#D38DFA',
    '#F78DA7', 
    '#FA766C',  
    '#FA9946',
    '#ABB8C3',
  ];

export const GanttTemplateEdit = ({ganttPhases, setGanttPhases}) => {
    const [phases, setPhases] = useState(ganttPhases? ganttPhases:[])
    const [newPhase, setNewPhase] = useState(INITIAL_PHASE)
    const [selectedPhase, setSelectedPhase] = useState(null)
    const [focusedDateInput, setFocusedDateInput] = useState()
    const [addNewPhase, setAddNewPhase] = useState(false)
    const [newTask, setNewTask] = useState(INITIAL_TASK)
    const [showPhaseData, setShowPhaseData] = useState(true)
    const [addNewTask, setAddNewTask] = useState(false)
    const [selectedTask, setSelectedTask] = useState(false)
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const [selectedForRemove, setSelectedForRemove] = useState(null)
    const [removeIndex, setRemoveIndex] = useState(null)
    const [unsavedData, setUnsavedData] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [selectedPhaseDates, setSelectedPhaseDates] = useState(null)
    const [chosenColor, setChosenColor] = useState("")
    const [search, setSearch] = useState("")
    const [showMore,setShowMore] = useState(false)

    const handleNewPhase = ({id, value}) => {
        setNewPhase({
            ...newPhase,
            [id]: value
        })
    }

    useEffect(()=>{
        setPhases(ganttPhases)
    },[ganttPhases])

    const setNewPhaseDates = (props) => {
        const {id,value} = props
        let update = {
            ...newPhase,
            [id]:value
        }
            setNewPhase({...update})
        if(update.start && update.end){
            handleEdit(update)
        }
    }

    const setPhaseDates = (props) => {
        const {id,value} = props
        let update = {
            ...selectedPhase,
           [id]:value
        }
        setSelectedPhase({...update})
        if(update.start && update.end){
            handleEdit(update)
        }
    }

    const setNewTaskDates = (props) => {
        const {id, value} = props
        let update = {
            ...newTask,
            [id]:value
        }
        setNewTask(update)
        if(update.start && update.end){
            updateTasks(update)
        }
    }

    const handleNewTask = ({id, value}) => {
        setNewTask({
            ...newTask,
            [id]: value
        })
    }

    const handleSelectedTask = ({id, value}) => {
        let update = {
            ...selectedTask,
            [id]: value
        }
        setSelectedTask({...update})
        updateTasks(update)
    }

    const setTaskDates = (props) => {
        const {id, value} = props
        let update = {
            ...selectedTask,
            [id]:value
        }
        setSelectedTask(update)
        if(update.start && update.end){
            checkAndModifyPhaseDates(update)
            // updateTasks(update)
        }
    }

    const updateTasks = (updateTask) => {
        let phaseList = phases.map((phase) => {
            if(phase.id === selectedPhase.id){
                let taskList = selectedPhase.tasks.map((task) =>{
                    if(task.id === updateTask.id){
                        task = {...updateTask}
                        return updateTask
                    }
                    return task
                })
                return {
                    ...selectedPhase,
                    tasks: taskList
                }
            }
            return phase
        })
        setPhases([...phaseList])
        setGanttPhases([...phaseList])
        setUnsavedData(true)
    }
    
    const handleTags = ({target}) => {
        const {id, value} = target
        setNewPhase({
            ...newPhase,
            [id]:value
        })
    }

    const handleSelectedPhaseTags = ({target}) => {
        const {id, value} = target
        setSelectedPhase({
            ...selectedPhase,
            [id]:value
        })
        handleEdit({...selectedPhase, [id]:value})
    }

    const handleSelectedTaskTags = ({target}) => {
        const {id, value} = target
        setSelectedTask({
            ...selectedTask,
            [id]:value
        })
        updateTasks({...selectedTask, [id]:value})
    }

    const handleTaskTags = ({target}) => {
        const {id, value} = target
        setNewTask({
            ...newTask,
            [id]:value
        })
    }

    const editPhase = ({id, value}) => {
        let update = {
            ...selectedPhase,
            [id]: value
        }
        setSelectedPhase({...update})
        let array = phases.map((phase) => {
            if(phase.id === update.id){
                phase = update
            }
            return phase
        })
        setPhases([...array])
        
    }

    const handleEdit = (update) => {
        let array = phases.map((phase) => {
            if(phase.id === update.id){
                return {...update}
            }
            return phase
        })
        setPhases([...array])
        setGanttPhases([...array])
    }

    const swapSelectedTask = (task) => {
        if(task !== selectedTask){
            setSelectedTask(task)
        } else {
            setSelectedTask(null)
        }
    }

    const checkAndModifyPhaseDates = (changed) => {
        let list = [...phases]
        let currentPhase = {}
        let array = list.map((phase)=>{
            let earliest = ""
            let latest = ""
            if(phase.id === changed.project){
                let tasks = [...phase.tasks]
                let taskList = tasks.map((task, index)=>{
                    if(index === 0){
                        let start = ""
                        let end = ""
                        if(task.id === changed.id){
                            start = moment(changed.start).toDate()
                            end = moment(changed.end).toDate()
                        } else {
                            start = moment(task.start).toDate()
                            end = moment(task.end).toDate()
                        }  
                        earliest = start
                        latest = end
                    }

                    if(task.id === changed.id){
                        let start = moment(changed.start).toDate()
                        let end = moment(changed.end).toDate()
                        if(start < earliest){
                            earliest = start
                        }
                        if(end > latest){
                            latest = end
                        }
                        return {...changed}
                    } else {
                        let start = moment(task.start).toDate()
                        let end = moment(task.end).toDate()
                        if(start < earliest){
                            earliest = start
                        }
                        if(end > latest){
                            latest = end
                        }
                        return task
                    }
                })

                let start = moment(changed.start).toDate()
                let end = moment(changed.end).toDate()
                if(start < earliest){
                    earliest = start
                }
                if(end > latest){
                    latest = end
                }
                phase = {
                    ...phase,
                    start:earliest,
                    end:latest,
                    tasks: [...taskList]
                }
                setSelectedPhase({...phase})
                return {
                    ...phase,
                    start:earliest,
                    end:latest,
                    tasks: [...taskList]
                }
            }
            
            return phase
        })
        
        setPhases([...array])
        setGanttPhases([...array])
    }

    const handleTaskAdd = () => {
        if(selectedPhase){
            let taskId = Date.now()
            let toBeAdded = {
                ...newTask,
                project: selectedPhase.id,
                id: taskId
            }
           
            let taskList = [...selectedPhase.tasks, toBeAdded]
            
            let update = {
                ...selectedPhase,
                tasks:[...taskList]
            }

            let earliest = moment(selectedPhase.start).toDate()
            let latest = moment(selectedPhase.end).toDate()
            let start = moment(newTask.start).toDate()
            let end = moment(newTask.end).toDate()
            if(start < earliest){
                earliest = start
            }
            if(end > latest){
                latest = end
            }

            update = {
                ...update,
                start:earliest,
                end:latest
            }
            let array = phases.map((phase) => {
                if(phase.id === update.id){
                    return {...update}
                }
                return phase
            })
            setSelectedPhase({...update})
            let calculated = calculateProgress(toBeAdded,[...array])
            setAddNewTask(false)
            setNewTask(INITIAL_TASK)
        }
    }  

    const calculateProgress = (task, list) => {
        let updatedPhase = {}
        let array = [...list.map((phase)=>{
            if(phase.id === task.project){
                let progress = 0
                let count = 0
                let tasks = phase.tasks
                tasks.map((obj)=>{
                    progress += obj.progress
                    count++
                })
           
                phase = {
                    ...phase,
                    progress: (progress/count)
                } 
                updatedPhase = phase
            }
            return phase
        })]
        setPhases([...array])
        setGanttPhases([...array])
        return array
    }

    const handleNewPhaseAdd = () => {
        let phaseId = Date.now()
        if(phaseId){
            let toBeAdded = {
                ...newPhase,
                id: phaseId
            }
            setPhases([...phases, toBeAdded])
            setAddNewPhase(false)
            setNewPhase(INITIAL_PHASE)
        }
    }


    // const requestObjectId = async() => {
    //     const response = await executeRequest('plan/requestGanttTaskId',{})
    //     if(response && response.newId){
    //         return response.newId
    //     } else {
    //         return Date.now()
    //     }
    // }

    const handleRemoveModal = (props) => {
        const {object, index} = props
        setSelectedForRemove(object)
        setRemoveIndex(index)
        setRemoveModalOpen(true)
    }

    const cancelRemove = () => {
        setSelectedForRemove(null)
        setRemoveModalOpen(false)
    }

    const removeSelected = () => {
        if(selectedForRemove && selectedForRemove.type){
           if(selectedForRemove.type === "project"){
                let phaseList = [...phases]
                phaseList.splice(removeIndex,1)
                setPhases([...phaseList])
            } else {
                let tasks = [...selectedPhase.tasks]
                tasks.splice(removeIndex, 1)
                let update = {
                    ...selectedPhase,
                    tasks: tasks
                }
                setSelectedPhase({...update})
                handleEdit(update)
            }
        }
        setRemoveIndex(null)
        setRemoveModalOpen(false)
        setSelectedForRemove({})
    }

    const goBackToPhases = () => {
        setSelectedPhase(null)
        setSelectedTask(null)
        setShowPhaseData(false)
        setNewTask(INITIAL_TASK)
        setAddNewTask(false)
    }

    const handleSelectedPhaseDates = () => {
        let start = moment(selectedPhase.start).toDate()
        let end = moment(selectedPhase.end).toDate()
        let startDate = start.getDate() + "." + (start.getMonth()+1) + "." + start.getFullYear()
        let endDate = end.getDate() + "." + (end.getMonth()+1) + "." + end.getFullYear()

        let dateString = startDate + " - " + endDate
        setSelectedPhaseDates(dateString)
    }

    useEffect(()=>{
        if(selectedPhase && selectedPhase.start && selectedPhase.end){
            handleSelectedPhaseDates()
        }
    },[selectedPhase])

    const handleColorChange = (color) => {
        if(selectedPhase){
            let styles = { 
                progressColor: '#acd811', 
                progressSelectedColor: color, 
                backgroundColor: color, 
                backgroundSelectedColor: color
            }
            let update = {
                ...selectedPhase,
                styles: styles
            }
            setChosenColor(color)
            setSelectedPhase({...update})
            handleEdit(update)
        }
    }

    const handleNameFilter = (phase) => {
        const {name} = phase

        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
    }

    const saveAndReturn = () => {
        let phase = {...selectedPhase}
        let updatedTasks = phase.tasks.map((task)=>{
            if(task.id === selectedTask.id){
                return selectedTask
            }
            return task
        })
        phase = {
            ...phase,
            tasks: [...updatedTasks]
        }
        let array = phases.map((phase) => {
            if(phase.id === selectedPhase.id){
                return {...phase}
            }
            return phase
        })
        setSelectedPhase({...phase})
        setPhases([...array])
        setGanttPhases([...array])
        setSelectedTask(null)
    }
    const handleDateBlocked = (props) => {
        const {type,day} = props
        let compare = moment(new Date(day._d))
        let object = getObjectByType(type)
        if(object && object.start){
            let start = moment(object.start)
            if (compare.isBefore(start, "day")) {
                return true;
            } 
            return false;
        } else{
            return false;
        }
    }

    const getObjectByType = (type) => {
        switch (type) {
            case 'newPhase':
                return newPhase
            case 'selectedPhase':
                return selectedPhase
            case 'newTask':
                return newTask
            case 'selectedTask':
                return selectedTask
            default:
                break;
        }
    }

    const checkToday = (day) => {
        let today = moment(new Date())
        if(today.isSame(day._d,"day")){
            return true
        }else {
            return false
        }
    }

    return (
        <>
            {selectedForRemove &&
                <Modal isOpen={removeModalOpen}>
                    <ModalHeader>{"Poista " + (selectedForRemove.type === "task"? "tehtävä" : "vaihe")}</ModalHeader>
                    <ModalBody>
                        <p>Oletko varma, että haluat poistaa {selectedForRemove.type === "task"? "tehtävän: " : "vaiheen: "} <b>{selectedForRemove.name}</b>? <b>Toimintoa ei voi peruuttaa!</b></p>
                    </ModalBody>
                    <ModalFooter>
                        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => cancelRemove()}>Peruuta</a>   
                        <button className="small-white-button wide-button" onClick={() => removeSelected({type: selectedForRemove.type})} style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>  
                    </ModalFooter>    
                </Modal>
            }
            <div style={{ paddingLeft:'1.5em'}}>
                { !addNewPhase && !selectedPhase &&
                    <Col>
                        <div style={{display:'flex', marginBottom:'0.5em'}}>
                            <div style={{ border: '1px solid #404040', borderRadius:'50px', padding:'0.1em 0.75em', display:'flex', justifyContent:'center', alignItems:'center', height:'2em', marginRight:'1em', cursor:'pointer'}} onClick={()=> setShowInfo(!showInfo)}><i className="fas fa-info"></i></div>
                            {showInfo && <p>{"Valitsemalla vaiheen siirryt vaiheen muokkaukseen, jossa voit muokata vaiheen tietoja, sekä lisätä ja muokata tehtäviä"}</p>}
                            <button className="small-white-button wide-button" style={{ backgroundColor:'#acd811', marginLeft:'auto', marginRight:'1em', marginTop:'0', height:'2.25em'}} onClick={() => setAddNewPhase(true)}>Lisää vaihe</button>
                        </div>
                        <div style={{marginLeft:""}}>
                            <form id="search-bar" className="gantt-search" style={{width:'12em'}}>
                                <input type="search" className="search-bar-input gantt-search" placeholder="Haku" value={search} onChange={(event)=> setSearch(event.target.value)} />
                            </form>
                        </div>
                        <Scrollbars
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            style={{ width: "100%", height: '300px' }}
                        >   
                            <div style={{ padding:'0.5em 0.5 0.5em 0.5em' ,fontWeight:'bold', marginRight:'0.5em', borderRadius:'10px', marginBottom:'0.5em' }}>
                                <Row style={{}}>
                                    <Col xs="6">Nimi</Col>
                                    <Col xs="4">Alku- ja tavoite pvm.</Col>
                                    <Col xs="2"></Col>
                                </Row>
                            </div>
                            {phases && phases.length > 0 && phases.filter(phase => handleNameFilter(phase)).map((phase,index)=>{
                                if(phase !== undefined){
                                    let oddEven = index % 2 === 0 ? 'even-row' : 'odd-row'
                                    const weekDay = ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la']
                                    let start = moment(phase.start).toDate()
                                    let end = moment(phase.end).toDate()
                                    let startDate = weekDay[start.getDay()] + " " + start.getDate() + "." + (start.getMonth()+1) + "." + start.getFullYear()
                                    let endDate = weekDay[end.getDay()] + " " + end.getDate() + "." + (end.getMonth()+1) + "." + end.getFullYear()
                                    return(
                                        <div key={index} className={"list-row " + oddEven} style={{ marginRight:'0.25em', borderRadius:'10px'}} onClick={()=> (setSelectedPhase(phase), setChosenColor(phase.styles.backgroundColor))} >
                                            <Row >
                                                <Col xs="6" style={{cursor:'pointer'}} >
                                                    <p>{phase.name}</p>
                                                </Col>
                                                <Col xs="4" >
                                                    <p>{startDate + " - " + endDate}</p>
                                                </Col>
                                                <Col xs="2">
                                                    <i style={{ float: 'right', cursor: 'pointer', marginTop: '0.5em', fontSize: '0.8em', marginRight:'0.5em', color:'red' }} className="far fa-trash-alt" onClick={(e) => (e.stopPropagation(), handleRemoveModal({object: phase, index:index}))}></i>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }
                            })}
                        </Scrollbars>
                    </Col>
                }
                {addNewPhase &&
                    <Col>
                        <Row> 
                            <button className="small-white-button wide-button" onClick={() => (setAddNewPhase(false),setNewPhase(INITIAL_PHASE))}><i className="fas fa-arrow-left"></i></button>
                        </Row>
                        <Row style={{ marginTop:'1em' }}>
                            <Label>Vaiheen nimi:</Label>
                            <Input value={newPhase.name} onChange={(e) => handleNewPhase({id:'name', value:e.target.value})} />
                        </Row>
                        <Row style={{ marginTop:'1em' }}>
                            <div style={{ display: "flex", flexDirection: "column"}}>
                                <Label>Alku- ja tavoite pvm</Label>
                                <div style={{display:'flex'}}>
                                    <div style={{display:'flex', width:'60%'}}>
                                        <SingleDatePicker
                                            date={newPhase.start ? moment(newPhase.start) : null}
                                            id="start"
                                            onDateChange={(date) => setNewPhaseDates({id: "start", value: date})}
                                            placeholder="Alku pvm"
                                            appendToBody={false}
                                            focused={focusedDateInput && focusedDateInput.focusedNewPhaseStart}
                                            onFocusChange={({focused})  =>setFocusedDateInput({focusedNewPhaseStart:focused})}
                                            hideKeyboardShortcutsPanel={true}
                                            showClearDate 
                                            isOutsideRange={()=>false}
                                            isDayHighlighted={checkToday}
                                            block
                                            readOnly
                                        />
                                        <SingleDatePicker
                                            date={newPhase.end ? moment(newPhase.end) : null}
                                            id="end"
                                            onDateChange={(date) => setNewPhaseDates({id: "end", value: date})}
                                            placeholder="Loppu pvm"
                                            appendToBody={false}
                                            focused={focusedDateInput && focusedDateInput.focusedNewPhaseEnd}
                                            onFocusChange={({focused})  =>setFocusedDateInput({focusedNewPhaseEnd:focused})}
                                            hideKeyboardShortcutsPanel={true}
                                            showClearDate 
                                            isDayBlocked={(day) => handleDateBlocked({type:"newPhase", day:day})}
                                            isOutsideRange={()=>false}
                                            isDayHighlighted={checkToday}
                                            block
                                            readOnly
                                            disabled={(newPhase &&! newPhase.start)?true:false}
                                        />
                                    </div>
                                </div>
                                {newPhase && newPhase.start && newPhase.end && moment(newPhase.start).isAfter(newPhase.end,'day') && 
                                    <div style={{marginLeft:'0'}}>
                                        <p style={{ color: 'red', fontSize:'0.8rem' }}> HUOM! Alku pvm on loppu pvm jälkeen</p>
                                    </div>
                                }
                            </div>  
                        </Row>
                        <Row style={{ marginTop:'1em' }}>
                            <Label>Kuvaus</Label>
                            <Input value={newPhase.description} onChange={(e) => handleNewPhase({id:'description', value:e.target.value})} />
                        </Row> 
                        <Row style={{ marginTop:'1em',  }}>
                            <div style={{ display:'flex', flexDirection:'column', width:'100%'}}>
                                <Label>Lisää tageja</Label>
                                <TagHandler
                                    tags={newPhase.tags}
                                    handleChange={handleTags}
                                />
                            </div>
                        </Row> 
                        <Row style={{ marginTop:'1em',  }}>
                            <button className="small-white-button wide-button" style={{width:'100%', backgroundColor:'#acd811'}} disabled={(newPhase.name && newPhase.name.length > 0 && newPhase.start !== null && newPhase.end !== null )? false: true} onClick={() => handleNewPhaseAdd()}>Lisää uusi vaihe</button>  
                        </Row>
                    </Col>
                }
                {selectedPhase &&
                    <>
                        <Col>
                            <Row> 
                                <button className="small-white-button wide-button" onClick={() => goBackToPhases()}><i className="fas fa-arrow-left"></i></button>
                            </Row>
                            <Row style={{ marginTop:'1em', cursor:'pointer' }}>
                                <div>
                                    <Label>Vaiheen nimi:</Label>
                                    <Input value={selectedPhase.name} onChange={(e) => editPhase({id:'name', value:e.target.value})} />
                                </div>
                            </Row>
                            <Row style={{ marginTop:'1em'}}>
                                <div style={{ display: "flex", flexDirection: "column", width:'100%'}}>
                                    <Label>Alku- ja tavoite pvm</Label>
                                    <div style={{display:'flex', width:'100%'}}>
                                        <div style={{display:'flex', width:'60%'}}>
                                            <SingleDatePicker
                                                date={selectedPhase.start ? moment(selectedPhase.start) : null}
                                                id="phaseStart"
                                                onDateChange={(date) => setPhaseDates({id: "start", value: date})}
                                                placeholder="Alku pvm"
                                                appendToBody={false}
                                                focused={focusedDateInput && focusedDateInput.focusedSelectedPhaseStart}
                                                onFocusChange={({focused})  => setFocusedDateInput({focusedSelectedPhaseStart:focused})}
                                                hideKeyboardShortcutsPanel={true}
                                                showClearDate 
                                                block
                                                isDayHighlighted={checkToday}
                                                isOutsideRange={()=>false}
                                                readOnly
                                            />
                                            <SingleDatePicker
                                                date={selectedPhase.end ? moment(selectedPhase.end) : null}
                                                id="phaseEnd"
                                                onDateChange={(date) => setPhaseDates({id: "end", value: date})}
                                                placeholder="Loppu pvm"
                                                appendToBody={false}
                                                focused={focusedDateInput && focusedDateInput.focusedSelectedPhaseEnd}
                                                onFocusChange={({focused})  => setFocusedDateInput({focusedSelectedPhaseEnd:focused})}
                                                hideKeyboardShortcutsPanel={true}
                                                isOutsideRange={()=>false}
                                                showClearDate 
                                                block
                                                isDayHighlighted={checkToday}
                                                isDayBlocked={(day) => handleDateBlocked({type:"selectedPhase", day:day})}
                                                readOnly
                                                disabled={(selectedPhase && !selectedPhase.start)?true:false}
                                            />
                                            
                                        </div>
                                        <div style={{marginLeft:'auto', marginRight:'2em'}}>
                                            <button className="small-white-button wide-button" onClick={()=>setShowMore(!showMore)}>{showMore?'Näytä vähemmän': 'Näytä enemmän'}</button>
                                        </div> 
                                    </div>    
                                    {selectedPhase && selectedPhase.start && selectedPhase.end && moment(selectedPhase.start).isAfter(selectedPhase.end,'day') && 
                                        <div style={{marginLeft:'0'}}>
                                            <p style={{ color: 'red', fontSize:'0.8rem' }}> HUOM! Alku pvm on loppu pvm jälkeen</p>
                                        </div>
                                    }                                    
                                </div>
                            </Row>
                            {showMore&&
                                <>
                                    <Row style={{ marginTop:'1em', marginRight:'1em'}}>
                                        <div style={{  marginLeft: '0em', marginBottom:'-0.5em'}}>
                                            <div style={{  marginLeft: '0.25em', display:'flex', marginBottom:'0.5em'}}> <i className="material-icons" style={{fontSize: '1.5rem'}}>format_color_fill</i> <Label style={{marginLeft:'0.5em'}}>Vaiheen väri</Label></div>
                                            <div style={{display:'flex'}}>
                                                {listOfDefaultColors && listOfDefaultColors.map((color, index)=>{
                                                    return(
                                                        <div key={index} className="color-square" onClick={() => handleColorChange(color)} style={{backgroundColor: color, border: (selectedPhase.styles && selectedPhase.styles.backgroundColor && selectedPhase.styles.backgroundColor === color)? '2px solid #404040' :'none'}}></div>
                                                    )
                                                })}
                                            </div>
                                            <div class="input-group mb-3" style={{ borderRadius:' 5px'}}>
                                                <div class="input-group-append">
                                                    <span class="mySpanClass input-group-text" style={{ borderRadius:'5px 0 0 5px', backgroundColor: chosenColor}}>#</span>
                                                </div>
                                                <input  type="text" class="form-control" placeholder="FFFF" value={chosenColor.substring(1)} onChange={(e) => setChosenColor(e.target.value)}/>
                                                <div class="input-group-append">
                                                    <button className="small-white-button" onClick={() => handleColorChange((chosenColor))} style={{ borderRadius:'0 5px 5px 0'}}>Valitse</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row style={{ marginTop:'1em', marginRight:'1em'}}>
                                        <Label>Kuvaus</Label>
                                        <Input value={selectedPhase.description} onChange={(e) => editPhase({id:'description', value:e.target.value})} />
                                    </Row> 
                                    <Row style={{ marginTop:'1em', marginRight:'1em' }}>
                                        <div style={{ display:'flex', flexDirection:'column', width:'100%'}}>
                                            <Label>Tagit</Label>
                                            <TagHandler
                                                tags={selectedPhase.tags}
                                                handleChange={handleSelectedPhaseTags}
                                            />
                                        </div>
                                    </Row> 
                                </>
                            }
                            <div style={{borderTop:'1px solid #c8c8c8', marginTop:'1em', marginLeft:'-1em', marginRight:'1em'}}></div>
                            <Row>
                                <Col style={{ marginTop:'1.5em', marginLeft: '-1em', marginRight:'', }}> 
                                    {!addNewTask&& 
                                        <>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                                                <Label><b>{selectedTask?'Tehtävän muokkaus' :'Tehtävät'}</b></Label> 
                                                {selectedTask?
                                                    <div style={{marginBottom:'0',marginLeft:'auto', marginRight:'0em'}} >
                                                        <button className='small-white-button wide-button' onClick={()=> setSelectedTask(null)}> Peruuta tehtävän muokkaus </button>
                                                    </div>
                                                    :
                                                    <div style={{marginBottom:'0',marginLeft:'auto', marginRight:'0em'}}>
                                                        <button className="small-white-button wide-button" style={{ backgroundColor:'#acd811'}} onClick={() => (setAddNewTask(true), setSelectedTask(null))}>Lisää tehtävä</button>
                                                    </div>
                                                }
                                            </div>
                                            {(selectedTask)?
                                                <div style={{ marginLeft:'1em', marginRight:'1em',marginBottom:'0.25em', borderRadius:'10px'}} >
                                                    <Row style={{ marginTop:'0.5em' }}>
                                                        <Label>Tehtävän nimi:</Label>
                                                        <Input value={selectedTask.name} onChange={(e) => handleSelectedTask({id:'name', value:e.target.value})} onClick={(e) => e.stopPropagation()} />
                                                    </Row>
                                                    <Row style={{ marginTop:'1em' }}>
                                                        <div style={{ display: "flex", flexDirection: "column", width:'100%'}}>
                                                            <Label>Alku- ja tavoite pvm</Label>
                                                            <div style={{display:'flex'}}>
                                                                <div style={{display:'flex', width:'60%'}}>
                                                                    <SingleDatePicker
                                                                        date={selectedTask.start ? moment(selectedTask.start) : null}
                                                                        id="taskStart"
                                                                        onDateChange={(date) => setTaskDates({id: "start", value: date})}
                                                                        placeholder="Alku pvm"
                                                                        appendToBody={false}
                                                                        focused={focusedDateInput && focusedDateInput.focusedSelectedTaskStart}
                                                                        onFocusChange={({focused})  => setFocusedDateInput({focusedSelectedTaskStart:focused})}
                                                                        hideKeyboardShortcutsPanel={true}
                                                                        isOutsideRange={()=>false}
                                                                        showClearDate 
                                                                        block
                                                                        isDayHighlighted={checkToday}
                                                                        readOnly
                                                                    />
                                                                    <SingleDatePicker
                                                                        date={selectedTask.end ? moment(selectedTask.end) : null}
                                                                        id="taskEnd"
                                                                        onDateChange={(date) => setTaskDates({id: "end", value: date})}
                                                                        placeholder="Loppu pvm"
                                                                        appendToBody={false}
                                                                        focused={focusedDateInput && focusedDateInput.focusedSelectedTaskEnd}
                                                                        onFocusChange={({focused})  => setFocusedDateInput({focusedSelectedTaskEnd:focused})}
                                                                        hideKeyboardShortcutsPanel={true}
                                                                        isOutsideRange={()=>false}
                                                                        showClearDate 
                                                                        block
                                                                        isDayHighlighted={checkToday}
                                                                        isDayBlocked={(day) => handleDateBlocked({type:"selectedTask", day:day})}
                                                                        readOnly
                                                                        disabled={(selectedTask && !selectedTask.start)?true:false}
                                                                    />
                                                                </div>
                                                                <div style={{marginLeft:'3em', marginTop:'-1.5em'}}>
                                                                    <Label style={{ marginBottom:'0.5em'}}>Vaiheen kesto</Label>
                                                                    <p>{selectedPhaseDates}</p>
                                                                </div>
                                                            </div>
                                                            {selectedTask && selectedTask.start && selectedTask.end && moment(selectedTask.start).isAfter(selectedTask.end,'day') && 
                                                                <div style={{marginLeft:'0'}}>
                                                                    <p style={{ color: 'red', fontSize:'0.8rem' }}> HUOM! Alku pvm on loppu pvm jälkeen</p>
                                                                </div>
                                                            }
                                                        </div>  
                                                        
                                                    </Row>
                                                    <Row style={{ marginTop:'1em' }}>
                                                        <Label>Kuvaus</Label>
                                                        <Input value={selectedTask.description} onChange={(e) => handleSelectedTask({id:'description', value:e.target.value})} />
                                                    </Row> 
                                                    <Row style={{ marginTop:'1em',  }}>
                                                        <div style={{ display:'flex', flexDirection:'column', width:'100%'}}>
                                                            <Label>Lisää tageja</Label>
                                                            <TagHandler
                                                                tags={selectedTask.tags}
                                                                handleChange={handleSelectedTaskTags}
                                                            />
                                                        </div>
                                                    </Row> 
                                                    <Row style={{ marginTop:'1em',  }}>
                                                        <button className='small-white-button wide-button' style={{ backgroundColor:'#acd811', width:"100%"}} onClick={()=> saveAndReturn()}>Palaa tehtävälistaan</button>
                                                    </Row>
                                                </div>
                                                :
                                                <div>
                                                    <div style={{marginLeft:""}}>
                                                        <form id="search-bar" className="gantt-search" style={{width:'12em'}}>
                                                            <input type="search" className="search-bar-input gantt-search" placeholder="Haku" value={search} onChange={(event)=> setSearch(event.target.value)} />
                                                        </form>
                                                    </div>
                                                    <Scrollbars
                                                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                                        style={{ width: "100%", height: '100%', maxHeight:(selectedPhase.tasks && selectedPhase.tasks.length > 3)?'500px':'250px', minHeight:selectedTask?'500px':'250px', paddingBottom:'2em' }}
                                                    >                    
                                                        {selectedPhase.tasks && selectedPhase.tasks.filter(task => handleNameFilter(task)).map((task,index)=>{
                                                            if(task !== undefined){
                                                                let oddEven = index % 2 === 0 ? 'even-row' : 'odd-row'
                                                                const weekDay = ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la']
                                                                let start = moment(task.start).toDate()
                                                                let end = moment(task.end).toDate()
                                                                let startDate = weekDay[start.getDay()] + " " + start.getDate() + "." + (start.getMonth()+1) + "." + start.getFullYear()
                                                                let endDate = weekDay[end.getDay()] + " " + end.getDate() + "." + (end.getMonth()+1) + "." + end.getFullYear()
                                                                return(
                                                                    <div key={index} className={oddEven + " list-row"}>
                                                                        <div key={index} style={{ marginRight:'0.25em',marginBottom:'0.25em', borderRadius:'10px'}} onClick={()=> swapSelectedTask(task)}>
                                                                            <Row >
                                                                                <Col style={{cursor:'pointer', height:'100%'}} >
                                                                                    <p style={{ marginLeft:'0.5em', height:'100%'}}>{task.name}</p>
                                                                                </Col>
                                                                                <Col style={{cursor:'pointer'}} >
                                                                                    <p>{startDate + " - " + endDate}</p>
                                                                                </Col>
                                                                                <Col xs="2">
                                                                                    <div style={{ float: 'right', cursor: 'pointer', marginTop: '0.25em', marginRight:'1.25em', fontSize: '0.8em', color:'red' }} ><i className="far fa-trash-alt" onClick={(e) =>(e.stopPropagation(), handleRemoveModal({object: task, index:index}))}></i></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </Scrollbars> 
                                                </div>
                                            }
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        {addNewTask &&  
                            <div style={{ backgroundColor:'#ebebeb', borderRadius:'10px', padding:'1em', marginRight:'2em' }}>            
                                <Col>
                                    <Row >
                                        <p><b>Uusi tehtävä</b></p>
                                        <button className="small-white-button wide-button" style={{ marginLeft:'auto', marginRight:'0'}} onClick={() => (setAddNewTask(false), setNewTask(INITIAL_TASK))}>Peruuta tehtävän lisäys</button>
                                    </Row>
                                    <Row>
                                        <p style={{ color:'red', fontSize:'0.9rem' }}>Tehtävälle on annettava vähintään nimi ja aikaväli.</p>
                                    </Row>
                                    <Row style={{ marginTop:'0.75em' }}>
                                        <Label>Tehtävän nimi:</Label>
                                        <Input value={newTask.name} onChange={(e) => handleNewTask({id:'name', value:e.target.value})} />
                                    </Row>
                                    <Row style={{ marginTop:'1em' }}>
                                        <div style={{ display: "flex", flexDirection: "column"}}>
                                            <Label>Alku- ja tavoite pvm</Label>
                                            <div style={{display:'flex'}}>
                                                <div style={{display:'flex', width:'60%'}}>
                                                    <SingleDatePicker
                                                        date={newTask.start ? moment(newTask.start) : null}
                                                        id="taskStart"
                                                        onDateChange={(date) => setNewTaskDates({id: "start", value: date})}
                                                        placeholder="Alku pvm"
                                                        appendToBody={false}
                                                        focused={focusedDateInput && focusedDateInput.focusedNewTaskStart}
                                                        onFocusChange={({focused})  => setFocusedDateInput({focusedNewTaskStart:focused})}
                                                        hideKeyboardShortcutsPanel={true}
                                                        isOutsideRange={()=>false}
                                                        showClearDate 
                                                        block
                                                        isDayHighlighted={checkToday}
                                                        readOnly
                                                    />
                                                    <SingleDatePicker
                                                        date={newTask.end ? moment(newTask.end) : null}
                                                        id="taskEnd"
                                                        onDateChange={(date) => setNewTaskDates({id: "end", value: date})}
                                                        placeholder="Loppu pvm"
                                                        appendToBody={false}
                                                        focused={focusedDateInput && focusedDateInput.focusedNewTaskEnd}
                                                        onFocusChange={({focused})  => setFocusedDateInput({focusedNewTaskEnd:focused})}
                                                        hideKeyboardShortcutsPanel={true}
                                                        isOutsideRange={()=>false}
                                                        showClearDate 
                                                        block
                                                        isDayHighlighted={checkToday}
                                                        isDayBlocked={(day) => handleDateBlocked({type:"newTask", day:day})}
                                                        readOnly
                                                        disabled={(newTask && !newTask.start)?true:false}
                                                    />
                                                </div>
                                                <div style={{marginLeft:'3em', marginTop:'-1.5em'}}>
                                                    <Label style={{ marginBottom:'0.5em'}}>Vaiheen kesto</Label>
                                                    <p>{selectedPhaseDates}</p>
                                                </div>
                                            </div>
                                            {newTask && newTask.start && newTask.end && moment(newTask.start).isAfter(newTask.end,'day') && 
                                                <div style={{marginLeft:'0'}}>
                                                    <p style={{ color: 'red', fontSize:'0.8rem' }}> HUOM! Alku pvm on loppu pvm jälkeen</p>
                                                </div>
                                            }
                                        </div>  
                                    </Row>
                                    <Row style={{ marginTop:'1em' }}>
                                        <Label>Kuvaus</Label>
                                        <Input value={newTask.description} onChange={(e) => handleNewTask({id:'description', value:e.target.value})} />
                                    </Row> 
                                    <Row style={{ marginTop:'1em',  }}>
                                        <div style={{ display:'flex', flexDirection:'column', width:'100%'}}>
                                            <Label>Lisää tageja</Label>
                                            <TagHandler
                                                tags={newTask.tags}
                                                handleChange={handleTaskTags}
                                            />
                                        </div>
                                    </Row> 
                                    <Row style={{ marginTop:'1em'  }}>
                                        <button className="small-white-button wide-button" style={{ marginLeft:'auto', marginRight:'0em', backgroundColor:'#acd811'}} disabled={(newTask.name && newTask.name.length > 0 && newTask.start !== null && newTask.end !== null )? false: true} onClick={() => handleTaskAdd()}>Lisää uusi tehtävä</button>  
                                    </Row>
                                </Col>
                            </div>   
                        }
                    </>
                }
            </div>
        </>
    )
}
import React, {useState, useEffect} from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { SteppedLineTo} from 'react-lineto';
import LineTo from 'react-lineto';
import LinkTreeItem from '../LinkTreeItem';

const QuestionModal = ({currentViewData, toggle, isOpen, setcurrentViewData, close}) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" centered>
                <ModalHeader toggle={close} className="taskModal">
                    <div>
                        Kartoituksen linkit
                    </div> 
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <Row style={{marginBottom: 40}}>
                            <Col xs="12" xl="12" style={{marginBottom: 30}}>
                                <Row>
                                    <Col xs="8" xl="8"><b>Kysymys</b></Col>
                                </Row>
                                <Row>
                                    <Col xs="8" xl="8">
                                        <div style={{border: '1px solid #CACACA', padding: 8}} className="title">
                                            <Row>
                                                <Col xs="10" xl="11">
                                                    <p style={{paddingLeft: 10}}>{currentViewData.title}</p>
                                                </Col>
                                                <Col xs="2" xl="1">
                                                    {(currentViewData.numberOfLinks !== undefined && currentViewData.numberOfLinks > 0) && 
                                                    <span style={{float: 'right'}}>
                                                        <span className="dot infoBlock">{currentViewData.numberOfLinks}</span>
                                                    </span>}
                                                </Col> 
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" xl="12">
                                <Row>
                                    <Col xs="1" xl="1"></Col>
                                    <Col xs="6" xl="6"><b>Vastausvaihtoehdot</b></Col>
                                </Row>
                            </Col>
                            {currentViewData.questionOptions && currentViewData.questionOptions.map((option, index) =>
                            <Col xs="12" xl="12" key={option.id} style={{marginBottom: 30}}>
                                <Row>
                                    <Col xs="1" xl="1" className={`lineHolder-${index}`}></Col>
                                    {option.title !== "" ?
                                    <Col xs="6" xl="6">
                                        <LinkTreeItem className={index} linkItem={option.title} style={{border: '1px solid #CACACA', padding: 8, textAlign: 'center', height: '100%', alignItems: 'center', display: 'flex', wordWrap: 'anywhere'}} setcurrentViewData={() => true}/>
                                    </Col>
                                    : <Col xs="6" xl="6">
                                        <LinkTreeItem className={index} linkItem={"Kenttä on tyhjä"} style={{border: '1px solid #CACACA', padding: 8, textAlign: 'center', height: '100%', alignItems: 'center', display: 'flex', wordWrap: 'anywhere'}} setcurrentViewData={() => true}/>
                                        </Col>
                                }
                                </Row>
                                {option.link !== undefined &&
                                <Row style={{marginTop: 30}} className="justify-content-center">
                                    <Col xs="6" xl="6">
                                        <LinkTreeItem className={option.id} linkItem={option.link} style={{border: '1px solid #CACACA', padding: 8, textAlign: 'center', height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-between'}} setcurrentViewData={() => true}/>
                                    </Col>
                                </Row>}
                            </Col>
                            )}
                            
                            <React.Fragment>
                                <LineTo from={"title"} to={`lineHolder-${currentViewData.questionOptions.length - 1}`} fromAnchor={"4.5% 100%"} toAnchor={"50% 55%"} borderColor="#CACACA" borderStyle="solid" borderWidth={3} zIndex={9999} delay={1000} within="modal-body"/>
                            </React.Fragment>
                            {currentViewData.questionOptions && currentViewData.questionOptions.map((item, index) =>
                                <div key={index + 1}> 
                                    <SteppedLineTo from={`lineHolder-${index}`} to={index + ""} fromAnchor={"51% 50%"} toAnchor={"left"} borderColor="#CACACA" borderStyle="solid" borderWidth={3} zIndex={9999} within="modal-body"/>
                                    <SteppedLineTo from={index + ""} to={item.id} fromAnchor={"bottom"} toAnchor={"top"} borderColor="#CACACA" borderStyle="dashed" borderWidth={3} zIndex={9999} within="modal-body"/>
                                </div>
                            )}
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Peruuta</a>
                </ModalFooter>
            </Modal>
    )
}

export default QuestionModal;
import React, {useState, useEffect} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import {confs} from '../../../../modules/config';
import {NotificationManager} from 'react-notifications';
import GoalCreator from './GoalCreator';

import moment from 'moment';
moment.locale();

const MegaLoaderSpinner = () => {
    return (
            <div className="loadingSpinnerRowChart">
                <div style={{ display: 'flex', justifyContent: 'center', height: '100%'}}>
                    <div style={{ width: '5rem', height: '5rem', marginTop: '8rem' }} className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
    )
}

const SummaryCardInformationModal = ({startCard, className, toggle, targetSurveyCard, getSummaryCards, companyPayload}) => {
  const [isOpen, setModalOpen] = useState(startCard);
  const [surveyCard, setSurveyCard] = useState(targetSurveyCard);
  const [surveyDataReady, setSurveyDataReady] = useState(false);
  const [goalValue, setGoalValue] = useState([0]);

    useEffect(() => {
        if(isOpen) {
            setSurveyCard(targetSurveyCard);
            let payload = {
                jwtToken: window.sessionStorage.getItem("token"),
                ...targetSurveyCard
            }
            
            fetch(confs.url + 'fetch/surveyCardInfo', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then(response => response.json())
            .then(res => {
                setSurveyCard(res);

                if(res.chartGoalValue !== undefined) {
                    setGoalValue(res.chartGoalValue);
                }

                setSurveyDataReady(true);
            }).catch(error => {
                NotificationManager.error("Valitettavasti tietojen haku epäonnistui", "", 2000);
            })
        } else {
            setSurveyCard({});
            setSurveyDataReady(false);
        }
    }, [isOpen])

    useEffect(() => {
        setModalOpen(startCard);
    }, [startCard])

    useEffect(() => {
        if(surveyCard !== undefined && surveyCard.chartGoalValue !== undefined)
            setGoalValue(surveyCard.chartGoalValue);
    }, [surveyCard])

    function saveNewGoalValue(values) {
        if(isOpen) {
            let payload = {
                jwtToken: window.sessionStorage.getItem("token"),
                ...targetSurveyCard,
                chartGoalValue: values
            }
            
            fetch(confs.url + 'modify/surveyCard', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then(response => response.json())
            .then(res => {
                if(res.valueChanged) {
                    NotificationManager.success("Uusi tavoitearvo asetettu", "", 2000);
                    getSummaryCards(companyPayload)
                }
                
            }).catch(error => {
                NotificationManager.error("Valitettavasti tietojen haku epäonnistui", "", 2000);
            })
        }
    }

    function endSummaryCard() {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            ...targetSurveyCard,
            deleted: true
        }
        
        fetch(confs.url + 'modify/surveyCard', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => response.json())
        .then(res => {
            if(res.deleted) {
                NotificationManager.success("Seuranta lopetettu", "", 2000);
                getSummaryCards(companyPayload)
                toggle();
            }
            
        }).catch(error => {
            NotificationManager.error("Valitettavasti seurannan lopetus epäonnistui", "", 2000);
        })
    }

    return (
      <div>
        <Modal isOpen={isOpen} toggle={() => toggle()} className={className} size="xl">
          <ModalHeader toggle={() => toggle()}>Seurantakortin tiedot</ModalHeader>
          <ModalBody>
              {surveyDataReady ? 
                <Row>
                    <Col xl="12" style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h3>{targetSurveyCard.summaryName !== "" ? targetSurveyCard.summaryName : "Nimetön"}</h3>
                        <button className="small-white-button" style={{backgroundColor: 'red', color: '#FFF', fontWeight: 700}} onClick={() => endSummaryCard()}>Lopeta seuranta</button>
                    </Col>
                    <Col xl="12">
                        <label><h6>Tavoitearvo</h6></label>
                        <GoalCreator setGoalValue={setGoalValue} goalValue={goalValue} finalChange={saveNewGoalValue}/>
                    </Col>
                    {surveyCard.latestAnswer !== undefined &&
                    <Col xl="12">
                        <label><h6><b>Viimeisin vastaus</b></h6></label>
                        <p><b>{surveyCard.latestAnswer.surveyName} ( {surveyCard.latestAnswer.surveyCode} ) - {moment(surveyCard.latestAnswer.date).format("DD.MM.YYYY HH:mm")}</b></p>
                    </Col>
                    }
                    <Col xl="12" style={{paddingTop: '1em'}}>
                        <Row>
                            <Col xl="4">
                                <label><b>Kartoitus</b></label>
                            </Col>
                            <Col xl="2" style={{textAlign: 'center'}}>
                                <label><b>Tarkistuspisteet</b></label>
                            </Col>
                            <Col xl="2" style={{textAlign: 'center'}}>
                                <label><b>Riskit</b></label>
                            </Col>
                            <Col xl="2" style={{textAlign: 'center'}}>
                                <label><b>Vastauksien lkm</b></label>
                            </Col>
                            <Col xl="2">
                                <label><b>Seuraava ajo</b></label>
                            </Col>
                        </Row>
                        {surveyCard.surveys.map((item, index) =>
                            <Row key={item.id} style={{paddingTop: '0.25em', paddingBottom: '0.25em'}}>
                                <Col xl="4">
                                    <p>{item.surveyName}</p>
                                </Col>
                                <Col xl="2" style={{textAlign: 'center'}}>
                                    {item.tasks}
                                </Col>
                                <Col xl="2" style={{textAlign: 'center'}}>
                                    {item.risks}
                                </Col>
                                <Col xl="2" style={{textAlign: 'center'}}>
                                    <p>{item.answers}</p>
                                </Col>
                                <Col xl="2">
                                    <p>{ item.nextRun !== 'none' ? `${moment(item.nextRun.start).format("DD.MM.YYYY")} - ${moment(item.nextRun.end).format("DD.MM.YYYY")}` : "Päättynyt"}</p>
                                </Col>
                            </Row> 
                        )}
                    </Col>
                </Row>
              : 
                <MegaLoaderSpinner />
              }
            
          </ModalBody>
          <ModalFooter style={{display: 'flex', justifyContent: 'space-between'}}>
            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Sulje</a>
          </ModalFooter>
        </Modal>
      </div>
    );
}

export default SummaryCardInformationModal;
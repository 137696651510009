import React, { useRef } from 'react'
import { motion, useCycle } from 'framer-motion'
import { FormGroup, Input } from 'reactstrap'

import FilterButton from './subComponents/FilterButton'

const filterListVariants = {
  open: {
    y: -45,
    opacity: 1,
    display: 'block',
    transition: { staggerChildren: 0.07, delayChildren: 0.02 },
  },
  closed: {
    x: '-101%',
    y: -25,
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
    transitionEnd: {
      display: "none",
    },
  }
}

const filterItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

const AnimatedFilterToggler = ({ filters, selectedFilters, selectFilter, buttonStyle }) => {
  const [ isOpen, toggleOpen ] = useCycle( false, true )
  const containerRef = useRef( null )

  return (
    <motion.nav
      initial={ false }
      animate={ isOpen ? 'open' : 'closed' }
      ref={ containerRef }
      style={ buttonStyle } 
    >
      {/* <motion.div className='background' variants={ filterMainBar } /> */}

      <motion.ul variants={ filterListVariants } className={ isOpen ? 'dropdown-menu show' : 'dropdown-menu' } style={ { zIndex: 99, paddingTop: 0, paddingBottom: 0 } }>
        { filters.map( ( filter, index ) => {
          const filterIsSelected = selectedFilters.indexOf( filter ) !== -1

          return <motion.li
            variants={ filterItemVariants }
            className='animated-filter-item'
            onClick={ () => selectFilter( filter ) }
            key={ index }
          >
            <FormGroup check>
              <h5>
                <Input type="checkbox" checked={ filterIsSelected } onChange={ () => { return }} />{ ' ' }
                { filter }
              </h5>
            </FormGroup>
          </motion.li>
        } ) }
      </motion.ul>

      <FilterButton toggle={ () => toggleOpen() } />
    </motion.nav>
  )
}

export default AnimatedFilterToggler
import React, {useEffect, useState} from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Label, Row, Input, FormGroup, FormFeedback, Form } from "reactstrap"
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import * as ActionCreator from '../../planBuilder/Actions/ActionCreator';

export const CreatePlanFromTemplate = ({history, planId, projectId = null, editPlan=null, clients = null, projects = null}) => {
    const [copyModalOpen, setCopyModalOpen] = useState(false)
    const dispatch = useDispatch()

    const startEditing = (planId) => {
        dispatch(ActionCreator.copiedFromTemplate(planId))
    }

    return(
        <>
            <CreateFromTemplateModal
                clients={clients}
                projectList={projects}
                planId={planId}
                projectId={projectId}
                copyModalOpen={copyModalOpen}
                setCopyModalOpen={setCopyModalOpen}
                startEditing={startEditing}
                
            />
            <div>
                <button className="small-white-button lighter-text wide-button" onClick={(event) => (event.stopPropagation(), setCopyModalOpen(true))}>Luo lomake</button>
            </div>
        </>
    )
}

export const CreateFromTemplateModal = ({copyModalOpen, setCopyModalOpen, planId, projectId, startEditing, clients = false, projectList = false}) => {
    
    const [clientList, setClientList] = useState([])
    const [selectedBusiness, setSelectedBusiness] = useState(null)
    const [copyName, setCopyName] = useState("")
    const [projects, setProjects] = useState()
    const [selectedProject, setSelectedProject] = useState()

    useEffect(() => {   
      
        if(clients && projectList ){
            setProjects(projectList)
            setClientList(clients)
            setSelectedBusiness(clients[0])
        } else {
            if(!clients){getClientList()}
            if(!projectList){getUserProjects()}
        }   
    },[])
    const getClientList = async() => {
        const response = await executeRequest('sender/get/getCompanies',{})
        if(response && response.businessList){
            setClientList(response.businessList)
            setSelectedBusiness(response.businessList[0])
        }
    }
    const handleCopySave = async() => {
        if(copyName.length>0){
            let payload = {
                planId: planId,
                planName: copyName,
                projectId: selectedProject? selectedProject.value : projectId,
                selectedBusiness: selectedBusiness? selectedBusiness : null
            }
            const response = await executeRequest('plan/create/copyFromTemplate', payload)
            if(response.created){
                NotificationManager.success('Lomakkeen luominen onnistui', 'Ilmoitus', 4000)
                startEditing(response.created._id)
            } else {
                if(response.trialEnded){
                    NotificationManager.error("Kokeilujakso on päättynyt","",4000)
                } else {
                    if(response.reason){
                        if(response.reason === "limit"){
                            NotificationManager.error("Lomakkeiden enimmäismäärä on täyttynyt","",4000)
                        }
                        if(response.reason === "module"){
                            NotificationManager.error("Ominaisuus (lisenssi) ei ole käytössä","",4000)
                        }
                    } else{
                        NotificationManager.error('Lomakkeen luominen epäonnistui', 'Ilmoitus', 4000)
                    }
                }
            }
            setCopyModalOpen(false)
        } else {
            NotificationManager.error('Anna lomakkeelle nimi!','Virhe', 3000)
        }
    }    

    const getUserProjects = async() => {
        const response = await executeRequest('project/getProjectsForSelect',{})
        if(!!response){
            setProjects(response)
        }
    }

    const toggle = () => {
        setCopyModalOpen(!copyModalOpen)
        setCopyName("")
        setSelectedBusiness(clientList[0])
    }
    return(
        <Modal isOpen={copyModalOpen} toggle={() => toggle()}>
                <ModalHeader>Luodaan pohjasta lomake</ModalHeader>
                <ModalBody>
                    <div style={{marginBottom: '1em'}}>
                        <Label>Lomakkeen nimi</Label>
                        <Input type="text" value={copyName} onChange={(e) => setCopyName(e.target.value)}></Input>
                    </div>
                    <div style={{marginBottom: '1em'}}>
                    <Label>Kenelle lomake luodaan</Label>
                    <Select
                        value={selectedBusiness}
                        onChange={(selected) => setSelectedBusiness(selected)}
                        options={clientList}
                        placeholder="Valitse kohdeyritys"
                        isDisabled={false}
                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                        className="companyTargets"
                        classNamePrefix="select"
                    />
                    </div>
                    <div style={{marginBottom: '1em'}}>
                        {projectId === null && projects && 
                            <>
                                <Label>Liitä lomake projektiin (mikäli et tahdo liittää, jätä tyhjäksi)</Label>
                                <Select
                                    value={selectedProject}
                                    onChange={(selected) => setSelectedProject(selected)}
                                    options={projects}
                                    placeholder="Valitse projekti"
                                    isDisabled={false}
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    className="companyTargets"
                                    classNamePrefix="select"
                                />
                            </>
                        }
                    </div>
                </ModalBody>
                <ModalFooter> 
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Peruuta</a>
                    <button className={"small-white-button wide-button " + ((selectedBusiness !== null && copyName.length > 0) ? "continue-highlighted" : "")}  onClick={() => handleCopySave()}>Luo lomake</button> 
                </ModalFooter>
            </Modal>
    )
}
import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { Scrollbars } from 'react-custom-scrollbars';
import { motion } from 'framer-motion';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import { ProjectDataModal } from '../ProjectDataModal';

export const ProjectList = (props) => {
    const {projects, handleProject, setIsModalOpen, update, clientList} = props
    const [search, setSearch] = useState("")
    const [selectedData, setSelectedData] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [modifyProjectModal, setModifyProjectModal] = useState(false)
    const [targetBusiness, setTargetBusiness] = useState(null)
    const [deleteText, setDeleteText] = useState("")

    const handleDelete = async() => {
        if(deleteText === "poista"){
            let params = {
                projectId: selectedData._id,
            }
            const response = await executeRequest('project/deleteProject',params)
            setModalOpen(false)
            setSelectedData({})
            update()
            setDeleteText("")
        }
    }

    const toggleModal = (data, x) => {
        setIsDelete(x)
        setSelectedData(data) 
        setModalOpen(!modalOpen)
    }

    const toggleDropdown = (y) => {
        if (menuOpen === y) {
            setMenuOpen(null)
        } else {
            setMenuOpen(y)
        }
    }
    const handleListNameFilter = project => {
        const { projectName, tags, targetBusiness} = project
        
        if(projectName && projectName.toUpperCase().includes(search.toUpperCase())) return true
        if(targetBusiness && targetBusiness.label && targetBusiness.label.toUpperCase().includes(search.toUpperCase())) return true
        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    const openModifyModal = (project) => {
        setSelectedData(project)
        setModifyProjectModal(true)
    }

    // const handleProjetTargetChanged = async() => {
    //     let payload = {
    //         targetBusiness: targetBusiness,
    //         projectId: selectedData._id
    //     }

    //     const response = await executeRequest('project/changeProjectTarget', payload)
    //     if(response.success){
    //         NotificationManager.success("Siirretty onnistuneesti","",3000)
    //     } else {
    //         NotificationManager.error("Tapahtui virhe!","Virhe",3000)
    //     }
    //     setTargetBusiness(null)
    //     setSelectedData({})
    //     setTargetBusinessModal(false)
    //     update()
    // }

    return(
        <>
            {isDelete &&
                <Modal isOpen={modalOpen} toggle={() => (setModalOpen(!modalOpen), setDeleteText(""))}>
                    <ModalHeader>{selectedData && selectedData.projectName}</ModalHeader>
                    <ModalBody>
                        <p>Oletko varma, että haluat poistaa projektin? <b>Toimintoa ei voi peruuttaa!</b></p>
                        <div style={{ marginTop: '1em' }}>
                            <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                            <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                            <div className="email-check-box">
                                {deleteText === "poista"?
                                    <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                    :
                                    <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                }
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <a onClick={() => (setModalOpen(!modalOpen), setDeleteText(""))} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                        <button onClick={() => handleDelete()} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                    </ModalFooter>
                </Modal>
            }
            <ProjectDataModal 
                projectToModify={selectedData}
                isOpen={modifyProjectModal}
                closeModal={setModifyProjectModal}
                updateProject={update}
            />
            <div className="project-colored-box project-container main-container">
                <div className="inner-project-container">
                    <Col style={{ paddingBottom:'1em' }}>
                        <Row className="tab-header bigger-text " style={{ width: 'auto', display: 'flex', alignItems: 'center', paddingBottom: "1em"}}>    
                            <Col>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0.25em'}}>
                                    <p>Projektit</p>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '3em', width: '100%' }}>
                                        <Input type="search" className="search-bar-open" placeholder={"Hae projekteista"} value={search} onChange={(event) => setSearch(event.target.value)} />
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <button className={"new-project-button wide-button small-white-button "} style={{ float: 'right', marginRight: '0.1em' }} onClick={() => setIsModalOpen(true)} >Uusi Projekti</button>
                            </Col>
                        
                        </Row>
                        <Row>
                            {projects && 
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "100%", height: '500px', borderRadius: '10px', marginLeft: '1em', marginRight: '1em'}}
                                    className="custom-scrollbars"
                                >
                                {projects.length > 0 && projects.filter(p => handleListNameFilter(p)).map((project, y)=>{
                                    let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row';
                                    y++;
                                    return(
                                        <div key={y} onClick={() => handleProject(project)} style={{ padding: '1em' }} className={oddEvenClass + ' list-row'}>
                                            <Row className='task-list-content'>
                                                <Col xs="5">
                                                    <p className="list-project-name name-overflow-handler-long" title={project.projectName}>{project.projectName}</p>
                                                    <p className="list-project-target" title={project.targetBusiness.label}>{project.targetBusiness.label}</p>
                                                </Col>
                                                <Col xs="3" style={{}}>
                                                    <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                                                        {project.tags && project.tags.slice(0, 7).map((tag, y) => {                        
                                                                return(<div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="tag-display tag-text project-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                            })  
                                                        }                                                   
                                                        {project.tags && project.tags.length > 7 &&  
                                                                <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                                                    <i className="material-icons" title={tagsToMore(project.tags.slice(7,project.tags.length))}>more_horiz</i>
                                                                </div>
                                                        }  
                                                    </div>                                            
                                                </Col>
                                                <Col xs="2">
                                                    {project.plans && project.plans.length > 0 && <i className="fas fa-clipboard-list" title="Lomakkeet" style={{ opacity: '0.7', marginLeft: '0.5em' }}></i>}
                                                    {project.selectedSurveys && project.selectedSurveys.length > 0 && <i title="Kartoitukset" className="fas fa-file-alt" style={{ opacity: '0.7', marginLeft: '0.5em' }}></i>}
                                                    {project.files && project.files.length > 0 && <i className="fas fa-paperclip" title="Liitteet"  style={{ opacity: '0.7', marginLeft: '0.5em' }}></i>}
                                                    {project.activities && project.activities.length > 0 && <i style={{ opacity: '0.7', marginLeft: '0.5em' }} title="Tapahtumat"  className="far fa-calendar-alt"></i>}
                                                </Col>
                                                <Col xs="1">
                                                    <div style={{ marginRight: '0', marginLeft: 'auto', marginTop: '0.5em', display: 'flex', flexDirection: 'column' }}>
                                                        {project.status? 
                                                            <span className="dot" title={project.status.label} style={{backgroundColor: project.status.value, fontSize: '0.8rem', float:'right' }}></span>
                                                            :
                                                            <></>
                                                        }
                                                    </div> 
                                                </Col>
                                                <Col xs="1">
                                                    <Dropdown isOpen={menuOpen === y} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent:'center' }} onClick={(event) => event.stopPropagation()} toggle={() => toggleDropdown(y)}>
                                                        <DropdownToggle tag="div" style={{  float: 'right', marginRight:'-1.5em', fontSize: '1.25rem' }}>
                                                            <i className="fas fa-bars" title="Valikko"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => openModifyModal(project)}>Muokkaa projektin tietoja</DropdownItem>
                                                            <DropdownItem onClick={() => toggleModal(project, true)}>Poista</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </div>  
                                    )
                                })}  
                                </Scrollbars>
                            }              
                        </Row>
                    </Col>
                </div>
            </div>
        </>
    )
}
const tagsToMore = (props) => {
    let tagss = ""
    
    if(props !== undefined){
        props.filter(tags => tagss = tagss + "  " + tags.label.toString())

        return(tagss)
    }
}

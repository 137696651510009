import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import moment from 'moment'
import 'react-dates/lib/css/_datepicker.css';

const ModifyDateModal = props => {
    const closeBtn = <button className="close" onClick={props.toggle}>&times;</button>;
    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className} centered>
                <ModalHeader toggle={props.toggle} close={closeBtn}>Aktiivisen aikavälin muutos</ModalHeader>
                <ModalBody style={{ paddingBottom: 0, paddingTop: 0 }}>
                    {props.dates ?
                        <div>
                            {props.modifyIndex !== null &&
                                <div style={{ margin: "1em 0" }}>
                                    <Row>
                                        <Col xl="6">
                                            <div className="lighter-text">Alku</div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="lighter-text">Loppu</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="12">
                                            <DateRangePicker
                                                readOnly
                                                startDate={props.startDate} // momentPropTypes.momentObj or null,
                                                startDateId="unique_start_" // PropTypes.string.isRequired,
                                                endDate={props.endDate} // momentPropTypes.momentObj or null,
                                                endDateId="unique_enddate_" // PropTypes.string.isRequired,
                                                onDatesChange={({ startDate, endDate }) => props.changeModifierDate({ startDate, endDate })} // PropTypes.func.isRequired,
                                                focusedInput={props.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                onFocusChange={focusedInputModal => props.modifierFocus({ focusedInputModal })}
                                                block
                                                hideKeyboardShortcutsPanel={true}
                                                isDayHighlighted={props.isDayBlocked}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                                <Col>
                                    <button className="small-white-button" onClick={() => props.setIndex(null, -1)}><i className="far fa-calendar-plus lighter-text"></i><span style={{paddingLeft: '0.5em'}}>Lisää aika</span></button>
                                </Col>
                            </Row>
                            {props.dates.map((date, index) => {
                                let start = moment(new Date(date.startDate)).format('DD.MM.YYYY')
                                let end = moment(new Date(date.endDate)).format('DD.MM.YYYY')
                                let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row';
                                let highlight = props.modifyIndex === index ? " highlight-row" : "";

                                return <Row key={index} className={"modify-dates-list " + oddEvenClass + highlight} >
                                    <Col md="10">{start} - {end}</Col>
                                    <Col md="1" onClick={() => props.setIndex(date, index)}><i style={{ cursor: 'pointer' }} className="far fa-edit"></i></Col>
                                    {props.dates.length > 1 &&
                                        <Col md="1" onClick={() => props.removeDate(index)}><i style={{ cursor: 'pointer' }} className="far fa-trash-alt"></i></Col>
                                    }
                                </Row>
                            })}
                        </div>
                        :
                        <div>
                            <Row>
                                <Col xl="6">
                                    <h5>Alku</h5>
                                </Col>
                                <Col xl="6">
                                    <h5>Loppu</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="12">
                                    <DateRangePicker
                                        readOnly
                                        startDate={props.startDate} // momentPropTypes.momentObj or null,
                                        startDateId="unique_startdate_" // PropTypes.string.isRequired,
                                        endDate={props.endDate} // momentPropTypes.momentObj or null,
                                        endDateId="unique_enddate" // PropTypes.string.isRequired,
                                        onDatesChange={({ startDate, endDate }) => props.changeModifierDate({ startDate, endDate })} // PropTypes.func.isRequired,
                                        focusedInput={props.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={focusedInputModal => props.modifierFocus({ focusedInputModal })}
                                        block
                                        hideKeyboardShortcutsPanel={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.save}>Tallenna</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModifyDateModal;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';  
import { executeRequest } from '../requestHandler/dataHandler';
import * as ActionCreator from '../../store/index';
import './favourites.css';

export const FavouriteStar = (props) => {

    const { itemId, itemType, title, targetBusiness, sentSurvey } = props
    const favourites = useSelector(state => state.authentication.favourites);

    const dispatch = useDispatch();

    const updateFavourites = async(params) => {
        let payload = {
            ...params,
            itemId: itemId,
            itemType: itemType,
            title: title,
            targetBusiness: targetBusiness
        }
        if(itemType === "sentSurvey" && sentSurvey){
            payload = {
                ...payload,
                sentSurvey: {
                    code: sentSurvey.code, 
                    name: sentSurvey.name,
                    _id: sentSurvey._id
                }
            }
        }

        const response = await executeRequest('updateFavourites', payload)
        dispatch(ActionCreator.updateFavourites(response))
    }

    return(
        <div>
            {favourites && favourites.length > 0 && favourites.filter(favourite => favourite.id === itemId).length > 0 ? 
                <i className="fas fa-star favourite-star favourite-selected" title="Poista suosikki" onClick={(e) => ( e.stopPropagation(), updateFavourites({function: "remove"}))}></i> 
                :
                <i className="far fa-star favourite-star favourite-not-selected" title="Lisää suosikki" onClick={(e) => ( e.stopPropagation(), updateFavourites({function: "add"}))}></i>     
            }
        </div>
    )
}

import React, { useState, useRef, forwardRef } from 'react'
import {
  Row,
  Col,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap'

import TextEditor from '../../../../Utilities/TextEditor/texteditor';

import {confs} from '../../../../modules/config';
import {NotificationManager} from 'react-notifications';


import moment from 'moment';

import { Rnd } from "react-rnd";
import {ResizedScrollBar} from './hocs/ResizedScrollBar';
import {ModifiedContainer} from '../DataContainers'

const MegaLoaderSpinner = () => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '5rem', height: '5rem', alignSelf: 'center'}} className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}


const ListBlock = ({item, itemId, businessId, preview = false, editable, promptSave, toggleDrag, toggleItemVisibility, onDelete, saveItemWithoutQueue, layoutPreview = false}) => {
    const isCancelled = useRef(false);
    const [ title , setTitle] = useState(item.topicContent);
    const [ data, setData] = useState({});
    const [ isLoading, setIsLoading ] = useState( false )
    const [enableResize, setEnableResize] = useState(false);
    const [openMenu, toggleMenu] = useState(false);
    const [size, setSize] = useState(item.size || {height: 500, width: '100%'});

    React.useEffect(() => {
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            businessId: businessId,
            id: itemId,
            type: 'list'
        }
        setIsLoading(true);
        fetchData(payload);

        return () => isCancelled.current = true
    
    }, []);

    function handleDataChanges(type, data) {
        
        if(type === "title") {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                type: item.type
            }
            promptSave(itemPayload);
        }
        //onItemChange(itemPayload);
    }

    const fetchData = async payload => {
        

        try {
            const response = await fetch( confs.url + 'plan/embedItemData', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )

            if(response.success === undefined) {
                const responseJson = await response.json();
                if(!isCancelled.current)
                    setData(responseJson);
            }
        } catch(err) {
            NotificationManager.error("Valitettavasti lohkon tietojen haussa tapahtui virhe", "", 3000);
        }

        if(!isCancelled.current)
            setIsLoading(false);
    }

    const onSave = () => {
        let itemPayload = {
            id: item.id,
            topicContent: title,
            size: size,
            type: item.type
        }

        saveItemWithoutQueue(itemPayload);
        setEnableResize(false);
        toggleDrag(true);
    }

    function toggleResize() {
        if(enableResize) {
            toggleDrag(true);
        } else {
            toggleDrag(false);
        }
        setEnableResize(!enableResize);
    }

    function handleResize(height, width) {
        setSize({height: height, width: width});
    }

    function handleDivSize(height, direction) {
        if(size.height !== height) {
            setSize({...size, height: height});
        }
    }

    const style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "#f0f0f0"
    };

    return (
        <Row style={{paddingBottom: '1.75em', marginBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
            <Col xl="12">
                {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red' }}>Yksityinen</span>}
                {(!editable && !layoutPreview && item.modified !== undefined) && 
                    <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                }
            </Col>
            <Col xl="1" className="topic-col" style={{display: 'flex'}}>
                <i className="fas fa-list plan-input subTitle" style={{alignSelf: 'center'}}></i>
            </Col>
            <Col xl="10" style={{marginBottom: 8}} className="title-col">
            {editable ? 
            <input className="plan-input subTitle" name="title" type="text" style={{ width: "100%" }} placeholder="Otsikko" value={(item && title) ? title : ''} onChange={(e) => handleDataChanges("title", e.target.value)}></input>
            :
                <TextEditor 
                    editorId={`${item.id}-subtitle-${editable}`} 
                    content={item.topicContent}
                    additionalClass="plan-input subTitle plan-subtitle"
                    readOnly={!editable}
                    placeholder="-"
                    plaintextEditor={true}
                />
            }
            </Col>
            <Col xl="1" style={{paddingLeft: 15, marginBottom: 8}} className="plan-dropdown-col">
                <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                    <DropdownToggle tag="div">
                        <i className="fas fa-bars plusIcon" title="Valikko"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                        <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                    </DropdownMenu>
                </Dropdown>}
                </div>
            </Col>
            {editable && 
                <>
                    <Col className="col-sm-12 col-md-5 offset-md-1 plan-dropdown-col">
                        <button className={enableResize ? "small-white-button lighter-text wide-button active" : "small-white-button lighter-text wide-button"} onClick={() => toggleResize()} style={{marginBottom: '0.8em'}}>Muuta kokoa</button>
                    </Col>
                    <Col className="col-sm-12 col-md-5 offset-md-1 plan-dropdown-col">
                        <button className="small-white-button lighter-text wide-button" onClick={() => onSave()} id={`${item.id}-saveButton`}>Tallenna</button>
                    </Col>
                </>
            }
            <Col className="col-sm-12 col-md-10 offset-md-1" style={{marginTop: '1.5em'}}>
            {enableResize ?
                <div style={{...size, maxWidth: '100%'}}>
                    <Rnd
                    size={size}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        handleResize(ref.style.height, ref.style.width)
                    }}
                    onResize={(e, direction, ref, delta, position) => {
                        handleDivSize(ref.style.height, direction)
                    }}
                    disableDragging
                    style={style}
                    maxWidth={'100%'}
                    enableResizing={ {top:false, right:true, bottom:true, left:true, topRight:false, bottomRight:true, bottomLeft:true, topLeft:false } }
                    >
                        <div className="dashboard-medium-box" style={{marginTop: 0, height: size.height, width: '100%', border: '1px solid black', paddingBottom: 2}}>
                            <ResizedScrollBar size={size} title={data.title}>
                            { 
                                [...Array(10).keys()].map(number => {

                                    let oddEvenClass = number % 2 === 0 ? 'even-row' : 'odd-row'

                                    return (
                                        Object.keys(data).length > 0 && 
                                        <div key={number} className={oddEvenClass} style={{ padding: '1em' }}>
                                            <Row>
                                                {Object.keys(data.data).map((key, index) => {
                                                    if (data.data[key] !== '') {
                                                        return <Col key={index}>{data.data[key]}</Col>
                                                    }
                                                })}

                                            </Row>
                                        </div>
                                    )
                                })
                            }
                            </ResizedScrollBar>
                        </div>
                    </Rnd>
                </div> 
            : <div className="dashboard-medium-box" style={{marginTop: 0, ...size}}>
                {isLoading ? 
                    <div style={{height: '100%', width: '100%'}}>
                        <MegaLoaderSpinner />
                    </div>
                : <ResizedScrollBar size={size} title={data.name}>
                        { 
                            [...Array(10).keys()].map(number => {

                                let oddEvenClass = number % 2 === 0 ? 'even-row' : 'odd-row'

                                return (
                                    Object.keys(data).length > 0 && 
                                    <div key={number} className={oddEvenClass} style={{ padding: '1em' }}>
                                        <Row>
                                            {Object.keys(data.data).map((key, index) => {
                                                if (data.data[key] !== '') {
                                                    return <Col key={index}>{data.data[key]}</Col>
                                                }
                                            })}

                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </ResizedScrollBar>
                }
                </div>
            }
            </Col>
        </Row>
    )
}

export default ListBlock


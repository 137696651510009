import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Label, Input } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import HelperBox from '../../../Utilities/HelperBox/HelperBox';

export const ListSelectionModal = ({isOpen, modulesInUse, listSelection, toggle, updateSelection, handleListSelection}) => {

    useEffect(()=>{

    },[])

    const setListOrder = () => {

    }

    const onDragEnd = (result) => {
        const { destination, source } = result

        if (!destination) { return }
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        let droppableIdNumber = source.droppableId.replace('droppable-', '')

        let list = listSelection
        const movedObj = list[source.index]

        list.splice(source.index, 1)
        list.splice(destination.index, 0, movedObj)

    }

    return(
        <Modal isOpen={isOpen} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()}>Listojen asetukset</ModalHeader>
            <ModalBody>
                <div style={{ fontSize:'0.9rem', marginBottom:'1em' }}>Voit vaihtaa listojen järjestystä raahaamalla ja pudottamalla valinnan haluttuun kohtaan tai listan näkyvyyttä oikeassa reunassa olevasta painikkeesta. Muutokset tallentuvat vasta Tallenna-painikkeella.</div>
                <DragDropContext onDragEnd={result => onDragEnd(result)}>
                    <Droppable droppableId='list'>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={{height: '100%', width: '100%', border: '1px dashed #C8C8C8' }}
                                {...provided.droppableProps}
                            >
                                {listSelection && listSelection.map((item, index) => {
                                    switch(item.name){
                                        case 'plans':
                                            return(
                                                <Fragment key={index}>
                                                    {modulesInUse.planManagement &&
                                                        <ListItem item={item} index={index} handleListSelection={handleListSelection}/>
                                                    }
                                                </Fragment>
                                            )
                                        case 'project-timeline':
                                            return (
                                                <Fragment key={index}>
                                                    {modulesInUse.projectManagement && modulesInUse.ganttEnabled && modulesInUse.betaTesting &&
                                                        <ListItem item={item} index={index} handleListSelection={handleListSelection}/>
                                                    }
                                                </Fragment>
                                            )
                                        case 'projects':
                                            return (
                                                <Fragment key={index}>
                                                    {modulesInUse.projectManagement &&
                                                            <ListItem item={item} index={index} handleListSelection={handleListSelection}/>
                                                    }   
                                                </Fragment>
                                            )
                                        case 'clients':
                                            return (
                                                <Fragment key={index}>
                                                    {modulesInUse.clientManagement &&
                                                        <ListItem item={item} index={index} handleListSelection={handleListSelection}/>
                                                    }
                                                </Fragment>
                                            )
                                        case 'activeSurveys':
                                            return (
                                                <Fragment key={index}>
                                                    {modulesInUse.surveyManagement &&
                                                        <ListItem item={item} index={index} handleListSelection={handleListSelection}/>
                                                    }
                                                </Fragment>
                                            )
                                        case 'answers':
                                            return (
                                                <Fragment key={index}>
                                                    {modulesInUse.surveyManagement &&
                                                        <ListItem item={item} index={index} handleListSelection={handleListSelection}/>
                                                    }
                                                </Fragment>
                                            )
                                        case 'tasks':
                                            return (
                                                <Fragment key={index}>
                                                    {modulesInUse.taskManagement &&
                                                        <ListItem item={item} index={index} handleListSelection={handleListSelection}/>
                                                    }
                                                </Fragment>
                                            )
                                        case 'risks':
                                            return (
                                                <Fragment key={index}>
                                                    {modulesInUse.riskManagement &&
                                                        <ListItem item={item} index={index} handleListSelection={handleListSelection}/>
                                                    }
                                                </Fragment>
                                            )
                                    }
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext> 
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Peruuta</a>
                <button className="small-white-button wide-button active-button" style={{ fontWeight:'bold' }} onClick={() => (updateSelection(), toggle())}>Tallenna</button>
            </ModalFooter>
        </Modal>        
    )
}

const ListItem = ({item, index, handleListSelection}) => {
    return(
        <Draggable draggableId={'item-'+index} index={index}>
            {(provided, snapshot) => {
                return (
                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <div className="list-selection-item" >
                            <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:item.name, value:!item.visible})}>
                                <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>{item.name === "project-timeline"? 'Projektitehtävät aikajanalla' : item.label}</p>
                            </div>
                            <label className="switch switch-in-modal" >
                                <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:item.name, value:!item.visible})} checked={item.visible}/>
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                )
            }}
        </Draggable>
    )
}
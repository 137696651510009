import { NotificationManager } from 'react-notifications';
import * as ActionTypes from './actionTypes';
import * as ActionCreator from '../../../../store/index';
import { confs } from '../../../../modules/config';
import {storeReport} from '../../../../store/index';

const reorderToState = surveyTree => {
  return {
    type: ActionTypes.REORDER_SURVEYS,
    payload: surveyTree
  } 
}

export const reorderSurveys = (email, surveyTree) => dispatch => {
  dispatch(reorderToState(surveyTree))
  let postData = {
    email: email,
    ...surveyTree
  }
  fetch(confs.url + 'business/reorderSurveys', {
    method: 'POST',
    body: JSON.stringify(postData)
  })
  .then(res => res.json())
  .then(data => {
    if(!data.success) {
      NotificationManager.error('Tallennus epäonnistui', 'Virhe', 2000);
    }
  }).catch(error => {
    ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
  }) 
}


export const addRemoveReports = reports => {
  return {
      type: ActionTypes.ADD_REMOVE_REPORT,
      reports: reports
  }
}

const getStatistics = statsObj => {
  return {
    type: ActionTypes.GET_STATISTICS,
    payload: statsObj
  }
}

const storeNotes = payload => {
  return {
    type: ActionTypes.FETCH_NOTES,
    payload: payload
  }
}

const deleteNote = payload => {
  return {
    type: ActionTypes.DELETE_NOTE,
    payload: payload
  }
}
const saveEditedNote = payload => {
  return {
    type: ActionTypes.EDIT_NOTE,
    payload: payload
  }
}
export const editNote = payload => {
  let jwt = window.sessionStorage.getItem("token");
  let params = {
    ...payload,
    jwtToken: jwt
  };

  return dispatch => {
    fetch(confs.url + 'modify/reportNotes', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(data => {
      if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
        dispatch(ActionCreator.authFailed())
      } else {
        if(data.success && payload.action === 'edit') {
          dispatch(saveEditedNote(payload))
          NotificationManager.success('Muistiinpanon muokkaus onnistui', 'Tiedot tallennettu', 2000);
        } else if(data.success && payload.action === 'delete') {
          dispatch(deleteNote(payload))
          NotificationManager.success('Muistiinpano poistettu onnistuneesti', 'Tiedot tallennettu', 2000);
        } else {
          NotificationManager.success('Muistiinpanon muokkaus epäonnistui', 'Virhe', 2000);
        }
      }
    }).catch(error => {
      ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
    })
  }
}
export const getSurveyStatistics = (company, dateRange) => dispatch => {
  let jwt = window.sessionStorage.getItem("token");
  const postData = {
    id: company.id,
    dateRange: dateRange,
    code: company.code,
    token: jwt
  }

  fetch(confs.url + 'business/getSurveyStatistics', {
    method: 'POST',
    body: JSON.stringify(postData)
  })
  .then(res => res.json())
  .then(data => {
    if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
      dispatch(ActionCreator.authFailed());
    } else {
      if(data.length > 0) {
        NotificationManager.success("Uusi raportti luotu", "", 2000);
        dispatch(getStatistics(data))
      } else {
        NotificationManager.error('Aikavälillä ei löytynyt vastauksia', 'Virhe', 2000);
        dispatch(getStatistics(data))  
      }
    }
  }).catch(error => {
    ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
  })
}

const setComparisonReport = payload => {
  return {
    type: ActionTypes.SET_COMPARISON_REPORT,
    payload: payload
  }
};

export const getComparisonReport = payload => dispatch => {
  let jwt = window.sessionStorage.getItem("token");

  let firstColumn = [
    ...payload.firstColumn
  ];

  let secondColumn = [
    ...payload.secondColumn
  ];

  let firstColm = [];
  firstColumn.map((id) => {
    return firstColm.push({reportId: id.id})
  });
  let secondColm = [];

   secondColumn.map((id) => {
    return secondColm.push({reportId: id.id})
  });

  let params = {
    firstColumn: firstColm,
    secondColumn: secondColm,
    jwtToken: jwt
  }

      fetch(confs.url + 'get/comparisonReport', {
        method: 'POST',
        body: JSON.stringify(params)
      })
      .then(res => res.json())
      .then(data => {
        if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
          dispatch(ActionCreator.authFailed())
        } else {
          if(data.length > 0) {
            NotificationManager.success("Vertailuraportti luotu", "", 2000);
            dispatch(setComparisonNames(payload.firstColumn, payload.secondColumn));
            dispatch(setComparisonReport(data));
          }
        }
      }).catch(error => {
        ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
      })
}
export const getReport = (payload) => dispatch => {
  let jwt = window.sessionStorage.getItem("token");
    let newReportPayload = {
      reports: [],
      jwtToken: jwt
    };

    if(payload.timeRange !== null){
      newReportPayload["dateRange"] = (payload.timeRange);
    }

    let selectedReports = [
      ...payload.reports
    ];

    payload.reports.map((id, index) => {
      return newReportPayload.reports.push({reportId: id.id});
    });

    
    if(selectedReports.length > 0) {
      fetch(confs.url + 'get/reportFromTreeV2', {
        method: 'POST',
        body: JSON.stringify(newReportPayload)
      })
      .then(res => res.json())
      .then(data => {
        if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
          dispatch(ActionCreator.authFailed())
        } else {
          if(data.length > 0) {
            if(typeof data[0].noPermission === 'undefined') {
              dispatch(getStatistics(data))
              if(newReportPayload.reports.length === 1 && data.length > 0) {
                dispatch(addRemoveReports(selectedReports));
                if(typeof payload.reload === 'undefined') {
                  NotificationManager.success("Raportissa mukana " + newReportPayload.reports.length + " kartoitus", "Tiedot päivitetty",  2000);
                }
              } else {
                if(data.length > 0) {
                  dispatch(addRemoveReports(selectedReports));
                  if(typeof payload.reload === 'undefined') {
                    NotificationManager.success("Raportissa mukana " + newReportPayload.reports.length + " kartoitusta", "Tiedot päivitetty",  2000);
                  }
                } else {
                  dispatch(getStatistics(data))
                  NotificationManager.error("Kartoituksessa ei ole vastauksia.", "Virhe", 2000);
                }
              }
            } else {
              let removeLastSelection = selectedReports.slice(0, selectedReports.length - 1);
              dispatch(addRemoveReports(removeLastSelection));
              NotificationManager.error("Raporttia ei voida näyttää", "Virhe", 3000);
            }
          }else{
            NotificationManager.error("Kartoituksessa ei ole vastauksia.", "Virhe", 2000);
          }
        }
      }).catch(error => {
        ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
      })
    } else {
      dispatch(addRemoveReports(selectedReports));
      dispatch(getStatistics(selectedReports))
    }
}

export const saveNotes = (payload) => {
  let jwt = window.sessionStorage.getItem("token");

  let params = {
    ...payload,
    jwtToken: jwt
  }
  return dispatch => {
    fetch(confs.url + 'send/reportNotes', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(data => {
      if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
        dispatch(ActionCreator.authFailed());
      } else {
        if(data.length > 0) {
          dispatch(getStatistics(data))
          NotificationManager.success('Jatko tallennettu onnistuneesti', 'Tiedot tallennettu', 2000);
        } else {
          dispatch(getStatistics(data))
        }
      }
    }).catch(error => {
      ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
    })
  }
}

export const fetchNotes = payload => {
  let jwt = window.sessionStorage.getItem("token");

  let params = {
    ...payload,
    jwtToken: jwt
  }
  return dispatch => {
    fetch(confs.url + 'get/targetNotes', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(data => {
      if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
        dispatch(ActionCreator.authFailed());
      } else {
        if(data.notes.length > 0) {
          dispatch(storeNotes(data))
        } else {
          dispatch(storeNotes(data))
          NotificationManager.error('Jatkojen haussa tapahtui virhe', 'Virhe', 2000);
        }
      }
    }).catch(error => {
      ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
    })
  }
}

const setRiskEvaluations = data => {
  return {
    type: ActionTypes.SET_RISK_EVALUATIONS,
    payload: data
  }
}

export const fetchRisks = payload => {
  let jwt = window.sessionStorage.getItem("token");
  
  let params = {
    ...payload,
    jwtToken: jwt
  }

  return dispatch => {
    fetch(confs.url + 'get/riskEvaluations', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(data => {
      if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
        dispatch(ActionCreator.authFailed());
      } else {
        if ( typeof data.success !== 'undefined' && !data.success ) {
          let emptyData = { riskList: [] }
          dispatch( setRiskEvaluations( emptyData ) )
        } else {
          dispatch( setRiskEvaluations( data ) )
        }
      }
    })
  }
}

const setCompanyScore = data => {
  return {
    type: ActionTypes.SET_COMPANY_SCORE,
    payload: data
  }
}
export const getCompanyScore = payload => {
  let jwt = window.sessionStorage.getItem("token");
  
  let params = {
    ...payload,
    jwtToken: jwt
  }

  return dispatch => {
    dispatch(setCompanyScore(null))
    fetch(confs.url + 'get/companyScore', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(data => {
      if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
        dispatch(ActionCreator.authFailed());
      } else {
        dispatch(setCompanyScore(data))
      }
    })
  }
}

export const addTask = payload => {
  let jwt = window.sessionStorage.getItem("token");
  
  let params = {
    ...payload,
    jwtToken: jwt
  }
  const storedSurveyIds = [ ...params.activeReports ]
  const sentSurveyIds = []
  params.activeReports.map(report => sentSurveyIds.push({ sentSurveyId : report }))

  params.activeReports = [
    ...sentSurveyIds
  ]
  return dispatch => {
    dispatch(ActionCreator.fetchRequestStart());
    fetch(confs.url + 'add/targetTask', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(data => {
      if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
        dispatch(ActionCreator.authFailed());
      } else {
        if(typeof data[0].status !== 'undefined' && data[0].status === false) {
          dispatch(ActionCreator.fetchRequestFinished("failed"));
          NotificationManager.error("Tarkistuspisteen lisäyksessä oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
        } else {
          NotificationManager.success("Uusi tarkistuspiste lisätty onnistuneesti", "", 3000);
          dispatch(ActionCreator.fetchRequestFinished("finished"));
          dispatch(storeReport(data, storedSurveyIds))
        }
      }
    }).catch(error => {
      NotificationManager.error("Järjestelmässä tapahtui virhe", "", 3000);
      dispatch(ActionCreator.fetchRequestFinished("finished"));
    })
  }
}

export const clearComparisonReport = payload => {
  return {
    type: ActionTypes.CLEAR_COMPARISON_REPORT
  }
}

export const clearSurveys = () => {
  return {
    type: "CLEAR_COMPANY_DATA"
  }
}


export const setComparisonNames = (firstColumn, secondColumn) => {
  let firstNames = [];
  let secondNames = [];

  firstNames = firstColumn.map((x, y) => {
    return x.title
  })

  secondNames = secondColumn.map((x, y) => {
    return x.title;
  });

  let data = {
    firstNames: firstNames,
    secondNames: secondNames
  }

  return {
    type: ActionTypes.SET_COMPARISON_NAMES,
    payload: data
  }
}

export const printPdfReport = payload => {
  let jwt = window.sessionStorage.getItem("token");

  let params = {
    ...payload,
    jwtToken: jwt
  }
 
  if(payload.dateRange !== null){
    params["dateRange"] = (payload.dateRange);
  }

  return dispatch => {
    fetch(confs.url + 'get/printReportV2', {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(function(response) {
      return response.blob();
    }).then(function(res) {
      if(typeof res.isAuthenticated !== 'undefined' && !res.isAuthenticated) {
        dispatch(ActionCreator.authFailed());
      } else {
        var data = new Blob([res], {type: 'application/pdf'});
        var csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement('a');
        document.body.appendChild(tempLink);
        tempLink.href = csvURL;
        let filename = 'EverBetter-'+ Date.now() + '.pdf';
        tempLink.setAttribute('download', filename.replace(' ','-').trim());
        tempLink.click();
        URL.revokeObjectURL(tempLink.href)
      }
    }).catch(error => {
      ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
    })
  }
}

export const printWordReport = payload => {
  let jwt = window.sessionStorage.getItem("token");

  let params = {
    ...payload,
    jwtToken: jwt
  }

  return dispatch => {
    fetch(confs.url + 'get/word', {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(function(response) {
      return response.blob();
    }).then(function(res) {
      if(typeof res.isAuthenticated !== 'undefined' && !res.isAuthenticated) {
        dispatch(ActionCreator.authFailed());
      } else {
        var data = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
        var csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement('a');
        document.body.appendChild(tempLink);
        tempLink.href = csvURL;
        let filename = 'EverBetter-'+ Date.now() + '.docx';
        tempLink.setAttribute('download', filename.replace(' ','-').trim());
        tempLink.click();
        URL.revokeObjectURL(tempLink.href)
      }
    });
  }
}

export const sendEmailReport = payload => {
  let jwt = window.sessionStorage.getItem("token");

  let params = {
    ...payload,
    jwtToken: jwt
  }

  return dispatch => {
    fetch(confs.url + 'send/emailReport', {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(res => res.json())
    .then(res => {
      if(typeof res.isAuthenticated !== 'undefined' && !res.isAuthenticated) {
        dispatch(ActionCreator.authFailed());
      } else {
        if(res.success){
          NotificationManager.success("Sähköposti lähetetty", "", 3000);
        }else{
          NotificationManager.error("Sähköpostin lähetys epäonnistui", "", 2000);
        }
      }
    });
  }
}


export const getMemoEmployees = payload => {
    let jwt = window.sessionStorage.getItem("token");

    let params = {
      jwtToken: jwt,
      companyId: payload.companyId
    };

    return dispatch => {
      fetch(confs.url + 'get/employeesForMemo', {
        method: 'POST',
        body: JSON.stringify(params)
      })
      .then(res => res.json())
      .then(data => {
        if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
          dispatch(ActionCreator.authFailed());
        } else {
          dispatch(setMemoEmployees(data))
        }
      }).catch(error => {
        ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
      })
    }
}

const setMemoEmployees = data => {
  return {
    type: ActionTypes.SET_EMPLOYEES_FOR_MEMO,
    payload: data
  }
}

const replaceModifiedTask = modifiedTask => {
  return {
    type: ActionTypes.REPLACE_MODIFIED_TASK,
    payload: modifiedTask
  }
}

const removeFinishedTask = modifiedTask => {
  return {
    type: ActionTypes.REMOVE_FINISHED_TASK,
    payload: modifiedTask
  }
}

export const updateTaskCard = payload => dispatch => {
  let jwt = window.sessionStorage.getItem("token");
  
  let cardPayload = {
    jwtToken: jwt,
    ...payload
  };

  if(payload.editedFromSurvey) {
    cardPayload["reports"] = [];

    payload.reports.map((id) => {
      return cardPayload.reports.push({reportId: id.id})
    })
  }

  fetch(confs.url + 'modify/taskCardV2', {
    method: 'POST',
    body: JSON.stringify(cardPayload)
  })
  .then(res => res.json())
  .then(data => {
    if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
      dispatch(ActionCreator.authFailed())
    } else {
        if(data.status === "updated") {
          if(typeof data.modifiedTask !== 'undefined') {
            if(data.modifiedTask.status === "done") {
              dispatch(removeFinishedTask(data.modifiedTask));
            } else {
              dispatch(replaceModifiedTask(data.modifiedTask))
            }
            NotificationManager.success("Tarkistuspisteen tila päivitetty.", "Tiedot päivitetty",  4000);
          }
        } else {
            if(data.status === "declined") {
              dispatch(removeFinishedTask(data.modifiedTask));
              NotificationManager.error("Tarkistuspiste peruttiin onnistuneesti", "",  4000);
            } else {
              NotificationManager.error("Tarkistuspisteen tilan päivitys epäonnistui, yritä hetken päästä uudelleen", "Virhe",  4000);
            }
        }
    }
  }).catch(error => {
    NotificationManager.error("Tarkistuspisteen tilan päivitys epäonnistui, yritä hetken päästä uudelleen", "Virhe",  4000);
  })
}

const getReSentMessage = sentList => {
  let message = "";
  let resentList = sentList.filter((x => typeof x.reSentTo !== 'undefined'));
  
  if(resentList.length > 0) {
    resentList.forEach((person, x) => {
      message += person.reSentTo + "\n";
    })
  }

  return message;
}

const getNewMessage = sentList => {
  let message = "";
  let sentToList = sentList.filter((x => typeof x.sentTo !== 'undefined'));
  if(sentToList.length > 0) {
    sentToList.forEach((person, x) => {
      message += person.sentTo + "\n";
    })
  }

  return message;
}

export const sendEmail = payload => dispatch => {
  let jwt = window.sessionStorage.getItem("token");
  
  let cardPayload = {
    jwtToken: jwt,
    ...payload
  };

  if(payload.editFromSurvey) {
    cardPayload["reports"] = [];
    payload.reports.map((id) => {
      return cardPayload.reports.push({reportId: id.id})
    })
  }

  fetch(confs.url + 'send/taskEmail', {
    method: 'POST',
    body: JSON.stringify(cardPayload)
  })
  .then(res => res.json())
  .then(data => {
    if(typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
      dispatch(ActionCreator.authFailed())
    } else {
        if(data.sentList.length > 0) {
          let message = getReSentMessage(data.sentList);
          let newMessage = getNewMessage(data.sentList);
          
          if(message.length > 0) {
            NotificationManager.success(message, "Uudelleen lähetetty sähköpostiosoitteisiin",  4000);
          }

          if(newMessage.length > 0) {
            NotificationManager.success(newMessage, "Tarkistuspisteen tiedot lähetetty sähköpostiosoitteisiin",  4000);
          }

          if(typeof data.surveys !== 'undefined') {
            dispatch(getStatistics(data.surveys))
          } else {
            dispatch(ActionCreator.setTaskData(data.tasks))
          }
        } else {
            if(data.status === "reSent") {
              if(typeof data.surveys !== 'undefined') {
                dispatch(getStatistics(data.surveys))
              } else {
                dispatch(ActionCreator.setTaskData(data.tasks))
              }
              NotificationManager.success("Tiedot on lähetetty uudelleen.", "",  4000);
            } else {
              NotificationManager.error("Tietoja ei voitu lähettää, yritä hetken päästä uudelleen", "Virhe",  4000);
            }
        }
    }
  }).catch(error => {
    ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
  })
}

export const setTaskData = payload => {
  let params = {
      taskData: payload.riskScoreList,
      lowestPriorities: payload.lowestPriorities,
      highestPriorities: payload.highestPriorities
      
  }
  return {
      type: ActionTypes.SET_COMPANY_TASKS,
      payload: params
  };
}

export const saveTags = payload => dispatch => {

  let jwt = window.sessionStorage.getItem( "token" )
  let params = {
    jwtToken: jwt,
    ...payload
  }
  
  return new Promise(async (resolve) => {
      const resultJson = await fetch (confs.url + 'modify/businessTags', {
          method: 'POST',
          body: JSON.stringify(params)
      })

      const result = await resultJson.json()

      if(result.success){
        if(typeof result.isAuthenticated !== 'undefined' && !result.isAuthenticated) {
          dispatch(ActionCreator.authFailed())
        } else {
          NotificationManager.success("Tagi(t) muutettu onnistuneesti.", "",  4000);
        }  
      } else {
        NotificationManager.error("Tietoja ei voitu lähettää, yritä hetken päästä uudelleen", "Virhe",  4000);
      }
      if(typeof result.isAuthenticated !== 'undefined' && !result.isAuthenticated) {
        dispatch(ActionCreator.authFailed())
      } 
  })
}

export const getActiveBusinessList = payload => dispatch => {
  let jwt = window.sessionStorage.getItem( "token" )
  let params = {
    jwtToken: jwt
  }

  return new Promise(async (resolve) => {
      const resultJson = await fetch (confs.url + 'get/activeBusinessList', {
          method: 'POST',
          body: JSON.stringify(params)
      })
      const result = await resultJson.json()
      
      if(result.success){
        if(typeof result.isAuthenticated !== 'undefined' && !result.isAuthenticated) {
          dispatch(ActionCreator.authFailed())
        } else {
          dispatch(setActiveBusinessList(result.activeList))
        }  
      }
      if(typeof result.isAuthenticated !== 'undefined' && !result.isAuthenticated) {
        dispatch(ActionCreator.authFailed())
      } 
  })
}


export const setActiveBusinessList = payload => {
  return {
    type: ActionTypes.SET_ACTIVE_BUSINESS_LIST,
    payload: payload
  }
}
import React, {useState, useRef} from 'react';
import TextEditor from '../../../../Utilities/TextEditor/texteditor';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table} from 'reactstrap';
import {convertFromRaw} from 'draft-js'
import TaskItem from '../../builderItems/TaskItem';
import RiskItem from '../../builderItems/RiskItem';
import { isTemplateMiddle } from 'typescript';

export const CopyBlockElement = (
    {
        item, 
        editable, 
        promptSave, 
        startAddingTask, 
        modifyTask, 
        startRiskAssesment, 
        riskList, 
        taskList, 
        onTemplateAdd, 
        index, 
        onDelete, 
        toggleItemVisibility, 
        modulesInUse, 
        variables,
        oddEvenClass="",
        setSelectedBlock,
        setEditModalOpen
    }) => {

    const [title, setTitle] = useState(item.topicContent);
    const [content, setContent] = useState(item.textContent);
    const [summaryItem, setSummaryItem] = useState(item.summaryItem)
    const [openMenu, toggleMenu] = useState(false);
    const [openTasks, toggleTasks] = useState(false);
    const [openRisks, toggleRisks] = useState(false);

    function handleDataChanges(type, data) {
        
        if(type === 'draft') {
            setContent(data);
            let itemPayload = {
                id: item.id,
                textContent: data,
                topicContent: title,
                type: item.type
            }
            //onItemChange(itemPayload);
            promptSave(itemPayload);

        } else if(type === 'title') {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                textContent: content,
                type: item.type
            }
            //onItemChange(itemPayload);
            promptSave(itemPayload);
        } else {
            setSummaryItem(data);
            let itemPayload = {
                id: item.id,
                topicContent: title,
                textContent: content,
                summaryItem: data,
                type: item.type
            }
            //onItemChange(itemPayload);
            promptSave(itemPayload);
        }
    }

    const {topicContent, textContent} = getPlainTexts(item.topicContent, item.textContent)
    return (
        
        <div className={"copied-container "+ oddEvenClass}>
            <div style={{marginBottom: 8}} className="title-col copied-container-block">
                <div style={{display:'flex'}}>
                    {item && (item.type === 'tables' || item.type === 'gantt') &&
                        <span style={{ marginTop:'0.5em', marginRight:'0.5em'}}>
                            {item && item.type === 'tables' && <i className="fas fa-table"></i>}
                            {item && item.type === 'gantt' && <i className="fas fa-chart-bar"></i>}
                        </span>
                    }
                    <TextEditor 
                        editorId={`${item._id}-subtitle-${editable}`} 
                        content={item.topicContent} 
                        onTextChange={editorState => handleDataChanges("title", editorState)}
                        additionalClass="plan-input subTitle"
                        readOnly={!editable}
                        plaintextEditor={true}
                        suggestions={variables}
                    /> 
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    {item && item.textContent && 
                        <div style={{paddingTop: '0.8em'}} className="plan-text-col">
                            <TextEditor 
                                editorId={`${item._id}-${editable}`} 
                                content={item.textContent} 
                                onTextChange={editorState => handleDataChanges('draft', editorState)}
                                additionalClass="plan-input"
                                readOnly={true}
                                suggestions={variables}
                                connectionType={"customBlocks"}
                                documentId={item._id} 
                            />
                        </div>
                    }
                    {item && item.type === 'tables' &&
                        <TableItem  table={item}/>
                    }
                    {item && item.type === 'gantt' && 
                        <div style={{maxHeight:'350px', display:'flex', flexDirection:'column', flexFlow:'column-wrap'}}>
                            {item.phases.map((phase, index)=>{
                                return (
                                    <p key={index}>{phase.name}</p>
                                )
                            })}
                        </div>
                    }
                <div style={{paddingLeft: 15, paddingRight: '1em'}} className="plan-dropdown-col">
                    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                        <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                            <DropdownToggle tag="div">
                                <i className="fas fa-bars plusIcon" title="Valikko"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                {item.type !== 'attachment' && <DropdownItem onClick={() => (setSelectedBlock(item), setEditModalOpen(true))} className="add-template">Muokkaa</DropdownItem>}
                                { /*modulesInUse.taskManagement && <DropdownItem onClick={() => startAddingTask(item)} className="add-task">Lisää tarkistuspiste</DropdownItem> */}
                                {/* { modulesInUse.riskManagement && <DropdownItem onClick={() => startRiskAssesment(item)} className="add-risk">Lisää riski</DropdownItem> } */}
                                {/* <DropdownItem onClick={() => onTemplateAdd({planItem: item})} className="add-template">Lisää kopiomalliksi</DropdownItem> */}
                                {/* <DropdownItem onClick={() => onTemplateAdd({planItem: item, type: 'new-custom-block'})} className="add-customblock">Lisää päivitysmalliksi</DropdownItem> */}
                                {/* <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem> */}
                                <DropdownItem onClick={() => onDelete({modal: true, target: {...item, topicContent: topicContent}})} className="delete-plan-item">Poista</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            {/* {((taskList !== undefined && taskList.length > 0 ) || (riskList !== undefined && riskList.length > 0)) && 
                <div style={{height: '100%', width: 90, display: 'flex', justifyContent: 'space-between', paddingTop: '1em', paddingBottom: '0.5em'}}>
                    
                    <i className="fas fa-tasks plan-task-icon" onClick={() => toggleTasks(!openTasks)} style={openTasks ? {border: '1px solid #B5E413', backgroundColor: '#B5E413', color: '#FFF', padding: 10, borderRadius: '50%', cursor: 'pointer'} : {border: '1px solid #404040', padding: 10, borderRadius: '50%', cursor: 'pointer'}}></i>

                    <i className="fas fa-exclamation plan-task-icon" onClick={() => toggleRisks(!openRisks)} style={openRisks ? {border: '1px solid #B5E413', padding: 10, paddingLeft: 15, paddingRight: 15, backgroundColor: '#B5E413', color: '#FFF', borderRadius: '50%', cursor: 'pointer'} 
                    : {border: '1px solid #404040', padding: 10, paddingLeft: 15, paddingRight: 15, borderRadius: '50%', cursor: 'pointer'}}></i>

                </div>
            } */}

            {/* { 
                openTasks &&
                taskList.map( taskItem =>
                    <TaskItem text={ taskItem.taskTitle } key={ taskItem._id } deadline={ taskItem.deadline } modifyTask={ modifyTask } taskItem={ taskItem } topicContent={ item.topicContent } />
                )
             }

            { 
                openRisks &&
                riskList.map( riskItem =>
                    <RiskItem riskItem={ riskItem } item={ item } modifyRisk={ startRiskAssesment } key={ riskItem._id } />
                )
            } */}
        </div>
    )
}

export const getPlainTexts = (rawEditorStateTopic, rawEditorStateText) => {
    let plainTopic = "";
    let plainText = "";
    if(typeof rawEditorStateTopic !== 'string' && rawEditorStateTopic !== undefined) {
        plainTopic = convertFromRaw(rawEditorStateTopic).getPlainText();
        plainTopic = plainTopic.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    } else {
        plainTopic = rawEditorStateTopic;
    }

    if(typeof rawEditorStateText !== 'string' && rawEditorStateText !== undefined) {
        plainText = convertFromRaw(rawEditorStateText).getPlainText();
        plainText = plainText.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    } else {
        plainText = rawEditorStateText;
    }
    
    return {topicContent: plainTopic, textContent: plainText};
}


export const CopiedIFrameElement = ({iFrame, onDelete}) => {
    const {hostName} = iFrame
    const {topicContent, textContent} = getPlainTexts(iFrame.topicContent, "");
    const [loadIFrame, setLoadIFrame] = useState(false);
    return (
        <div style={{padding: '1em'}} className="single-iframe-container">             
            <h5 className="plan-list-name" title={topicContent}>{topicContent}</h5>
            <div>
                {loadIFrame ? 
                <div style={{height: 350}} className="iFrame-item-block-div">
                    <iframe src={hostName} frameBorder="0" style={{ height: '100%', width: '100%' }}></iframe>
                </div>
                : <div className="iFrame-load-div">
                    <button className="small-white-button lighter-text wide-button iFrame-load-button" onClick={() => setLoadIFrame(true)}>Esikatsele</button>
                  </div>}
            </div>
            <div className="delete-button-div">
                <button className="small-white-button lighter-text wide-button" onClick={() => onDelete({modal: true, target: {...iFrame, topicContent: topicContent}})}>Poista</button>
            </div>
        </div>
    )
}

export const TableItem = ({table}) => {
    
    const {rows} = table;

    return (
        <Table bordered hover style={{tableLayout: 'fixed'}}>
            <thead>
            </thead>
            <tbody>
                {rows && rows.map((row, rowIndex) => 
                    <TableRowContainer key={rowIndex} row={row} rowIndex={rowIndex} maximumCount={table.selectedNumberOfCols} item={table} blockId={table._id}/>
                )}
            </tbody>
        </Table>
    )
}

const TableRowContainer = ({row, rowIndex, maximumCount, item, blockId}) => {
    const { cols } = row
    const {customWidth} = item
    return (
        <tr key={rowIndex}>
            {cols.map((col, index) =>
                <ColumnEditor 
                    col={col} 
                    key={col.id} 
                    editorId={col.id} 
                    rowIndex={rowIndex} 
                    count={cols.length}
                    maximumCount={maximumCount}
                    colIndex={index}
                    customWidth={(customWidth && customWidth[index])? (customWidth[index] + "%"):"" }
                    blockId={blockId}
                />
            )}
        </tr>
    )
}

const ColumnEditor = ({col, editorId, maximumCount, count, colIndex, customWidth, blockId}) => {

    const getColSpan = () => {
        if(count === 1)
            return maximumCount;
        else
            return (maximumCount % count === 1 && colIndex + 1 === count) ? maximumCount / count + 1 : maximumCount / count
    }

    return (
        <td style={{verticalAlign: 'top', backgroundColor: col.color? col.color : "#ffff"}} width={customWidth} colSpan={getColSpan()}>
            <TextEditor
                editorId={editorId}
                content={col.text}
                additionalClass="plan-input"
                onTextChange={() => { return }}
                readOnly={true}
                connectionType={"customBlocks"}
                documentId={blockId} 
            />
        </td>
    )
}
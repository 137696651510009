import * as ActionTypes from '../actions/Breadcrumbs/ActionTypes';

const initialState = {
    crumbs: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.SET_BREADCRUMBS:
            return {
                crumbs: [
                    action.payload
                ]
            }
        case ActionTypes.ADD_BREADCRUMBS:
            let isnew = true;
            let crumbs = state.crumbs;
            let i = 0;

            state.crumbs.forEach((item, ind) => {
                if (item.to === action.payload.to) {
                    isnew = false;
                    i = ind;
                }
            })

            if (isnew) {
                return {
                    ...state,
                    crumbs: [
                        ...state.crumbs,
                        action.payload
                    ]
                }
            }else{
                crumbs.splice(i, crumbs.length);
                return {
                    ...state,
                    crumbs: [
                        ...crumbs,
                        action.payload
                    ]
                }
            }
        default:
            return state
    }
}

export default reducer;
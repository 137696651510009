export const GET_BUSINESSES = 'GET_BUSINESSES'
export const GET_LATEST_ANSWERS = 'GET_LATEST_ANSWERS'
export const SELECT_BUSINESS = 'SELECT_BUSINESS'
export const GET_REPORT_DASHBOARD = 'GET_REPORT_DASHBOARD'
export const GET_REPORT = 'GET_REPORT'
export const CLEAR_SELECTED = 'CLEAR_SELECTED'
export const GET_SURVEY_TREE = 'GET_SURVEY_TREE'
export const GET_BUSINESS = 'GET_BUSINESS'
export const LOADING = 'LOADING'
export const ADD_FILTER = 'ADD_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const GET_SURVEY_VARIABLES = 'GET_SURVEY_VARIABLES'
export const SET_VARIABLES = 'SET_VARIABLES'
export const SET_SENTSURVEY_IDS = 'SET_SENTSURVEY_IDS'
export const START_FETCH_REQUEST = "START_FETCH_REQUEST";
export const FETCH_REQUEST_FINISHED = "FETCH_REQUEST_FINISHED";
export const REPLACE_MODIFIED_TASK = "REPLACE_MODIFIED_TASK";
export const REMOVE_FINISHED_TASK = "REMOVE_FINISHED_TASK";
export const SET_RISK_MATRICE = "SET_RISK_MATRICE";
export const SET_RISK_MATRICE_RESULTS = "SET_RISK_MATRICE_RESULTS";
export const SET_SUMMARY_CARDS = "SET_SUMMARY_CARDS";
export const SET_BUSINESS_SENTSURVEYS = "SET_BUSINESS_SENTSURVEYS";
export const SET_BUSINESS_PROJECTS = "SET_BUSINESS_PROJECTS";
export const SET_BUSINESS_PLANS = "SET_BUSINESS_PLANS";
export const SET_TRELLO_BOARD_LISTS = "SET_TRELLO_BOARD_LISTS";
export const SET_BUSINESS_EXCELS = "SET_BUSINESS_EXCELS";
export const UPDATE_BUSINESS_GOAL="UPDATE_BUSINESS_GOAL";
export const UPDATE_BUSINESS_GOALS = "UPDATE_BUSINESS_GOALS";
export const CLEAR_REPORT_DASHBOARD = "CLEAR_REPORT_DASHBOARD";
export const RESET_COLLABORATIONS = "RESET_COLLABORATIONS";
export const UPDATE_TRELLO_CARD_CHECKLIST = "UPDATE_TRELLO_CARD_CHECKLIST";
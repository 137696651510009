import React, {useState, useEffect} from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Input} from 'reactstrap';
import ReportSelectionModal from '../Modals/ReportSelectionModal';
import {ModifiedContainer} from './DataContainers';
const ReportItem = ({item, editable, promptSave, onDelete, toggleItemVisibility, targetBusiness, index, layoutPreview = false}) => {
    const [title, setTitle] = useState(item.topicContent);
    const [openMenu, toggleMenu] = useState(false);
    const [openReportSelection, setReportOpen] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState(item.targetSurvey);
    
    function handleDataChanges(type, data) {
        
        if(type === "title") {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                type: item.type
            }
            promptSave(itemPayload);
        }
        //onItemChange(itemPayload);
    }

    return (
        <>
        <ReportSelectionModal open={openReportSelection} toggle={() => setReportOpen(!openReportSelection)} targetBusiness={targetBusiness} promptSave={promptSave} item={item} setSelectedSurvey={setSelectedSurvey}/>
        <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
            <Col xl="12">
                {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red'}}>Yksityinen</span>}
                {(!editable && !layoutPreview && item.modified !== undefined) && 
                    <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                }
            </Col>
            <Col xl="11" style={{marginBottom: 8}} className="title-col">
                <div style={{ display: 'flex' }}>
                    {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', display: 'flex'}}>{index + 1}<i className="fas fa-chart-pie plan-input subTitle" style={{marginLeft: '0.5em', alignSelf: 'center'}}></i></div>}            
                    {editable ? 
                        <input className="plan-input subTitle" name="title" type="text" style={{ width: "100%" }} placeholder="Raportin otsikko" value={(item && title) ? title : ''} onChange={(e) => handleDataChanges("title", e.target.value)}></input>
                        :
                        <TextEditor 
                            editorId={`${item.id}-subtitle-${editable}`} 
                            content={item.topicContent}
                            additionalClass="plan-input subTitle plan-subtitle"
                            readOnly={!editable}
                            placeholder="-"
                            plaintextEditor={true}
                        />
                    }  
                </div>
            </Col>
            <Col xl="1" style={{paddingLeft: 15, marginBottom: 8}} className="plan-dropdown-col">
                <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                    <DropdownToggle tag="div">
                        <i className="fas fa-bars plusIcon" title="Valikko"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                        <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                    </DropdownMenu>
                </Dropdown>}
                </div>
            </Col>
            {editable &&
            <Col xl={{size: 10, offset: 1}}>
                <button className="small-white-button lighter-text wide-button" onClick={(e) => setReportOpen(true)}>{selectedSurvey === undefined ? "Valitse raportti" : <span style={{fontWeight: 700}}>{`${selectedSurvey.label} (${selectedSurvey.group})`}</span>}</button>
            </Col>
            }
        </Row>
        </>
    )
}

export default ReportItem;
import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextEditor from '../../../../../../Utilities/TextEditor/texteditor';
import moment from 'moment';

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center', position: 'absolute' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const InnerFinalModal = props => {
    const {editPlan} = props;
    
    return (
        
        <Modal isOpen={props.innerFinal} toggle={props.toggleFinal} onClosed={props.closeAll ? props.toggle : undefined} size="lg" centered>
            <ModalHeader toggle={props.toggleAll} className="taskModal">
                <div>
                    Tarkistuspisteen yhteenveto
                    <span className="modalPageNumber">3 / 3</span>
                </div> 
            </ModalHeader>
            <ModalBody>
                {props.fetchStatus && <MegaLoaderSpinner />}
                <Row>
                    <div style={{width: '100%'}}>
                        <Col xl="12">
                            <h5 style={{ marginBottom: 0 }}>Otsikko:</h5>
                            <TextEditor editorId={"taskCreatorTitle"}
                                content={props.taskTitle || ""}
                                readOnly={true}
                                additionalClass="taskcard-text"
                            />
                        </Col>
                        <Col xl="12">
                            <h5 style={{ marginBottom: 0 }}>Selite:</h5>
                            <TextEditor 
                                editorId={"taskCreatorExplanation"}
                                content={props.taskExplanation || ""}
                                readOnly={true}
                                additionalClass="taskcard-text"
                                allowFileUpload={false}
                            />
                        </Col>
                        <hr style={{clear: 'both'}}/>
                    </div>
                    
                    {props.deadline !== null &&
                    <Col xl="6">
                        <h5 style={{marginBottom: 0}}>Deadline</h5>
                        <p>{moment(new Date(props.deadline)).format('DD.MM.YYYY')}</p>
                    </Col>}
                    {props.notificationDate !== null &&
                    <Col xl="6">
                        <h5 style={{marginBottom: 0}}>Muistutuspäivä</h5>
                        <p>{moment(new Date(props.notificationDate)).format('DD.MM.YYYY')}</p>
                    </Col>}
                    <Col xl="12">
                        <h5 style={{marginBottom: 0, marginTop: '1rem'}}>Vastuuhenkilöt</h5>
                            {props.selectedEmails.map((target, x) => {
                                return <p key={`${target.label}-${x}`}>{target.label}</p>
                            })}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => props.toggleFinal()}>Takaisin</a>
                {editPlan !== undefined && !editPlan ? <button className="small-white-button wide-button" onClick={props.toggleFinal}>Sulje</button> :
                    <button className="small-white-button wide-button" onClick={ () => props.saveTask() }>{editPlan !== undefined && editPlan ? "Tallenna tarkistuspiste" : "Lähetä tarkistuspiste"}</button>
                }
            </ModalFooter>
        </Modal>
    )
}

export default InnerFinalModal;
import React from 'react'
import { Nav, NavbarBrand, TabContent, TabPane } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import ReportContent from './raportit/ReportContent'
import { motion } from 'framer-motion'
import './sidebar.css'
import { Scrollbars } from 'react-custom-scrollbars'

const SideNav = ( props ) => {
    const navItemVariants = {
        active: {
            backgroundColor: 'rgba(125,125,125,1)',
            transition: { type: 'spring', duration: .2 }
        },
        notActive: {
            backgroundColor: '#fff',
            transition: { duration: .2 }
        }
    }

    const tabContentVariants = {
        active: {
            display: 'block',
            opacity: 1,
            transition: { duration: .2 }
        },
        notActive: {
            transitionEnd: { display: 'none' },
            opacity: 0,
            transition: { duration: .2 }
        }
    }
    return (
        <div className="sidebar">
            <Nav vertical className="vertical-nav">
                
                <NavbarBrand style={ { pointerEvents: 'none' } }>{ Object.keys( props.logoStyle ).length > 0 ? (
                        <div className="logo-container-nav-bar" style={ props.logoStyle }>
                        </div>
                        ) :
                        (
                            <img src="/images/qibbie_logo.png" className="nav-logo" alt="Qibbie" />
                        ) 
                    }
                </NavbarBrand>

                <Scrollbars
                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                style={{ width: "100%", height: '100%' }}
                className="custom-scrollbars" >

                    <motion.div whileHover={{ boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' }} onClick={ () => props.onNavClick( 'raportit/yritys' ) } initial='notActive' animate={ props.location.pathname.indexOf( 'raportit/yritys' ) !== -1 ? 'active' : 'notActive' } variants={ navItemVariants }>
                        { ( ( props.activeTab === '/raportit/yritys' || props.activeTab === '/raportit/yritys/raportti' ) && !props.panelOpen ) &&
                            <div className="togglePanelOpenDiv">
                                <i className="fas fa-chevron-right openIcon" onClick={ props.togglePanel }></i>
                            </div>
                        }
                        <NavLink to="/raportit/yritys" className="nav-link" activeClassName='active-nav'>
                            <i title="Raportit" className="fas fa-chart-pie nav-icon"></i>
                            <small>Raportit</small>
                        </NavLink>
                    </motion.div>

                    <motion.div whileHover={ { boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' } } onClick={ () => props.onNavClick( 'tiedot' ) } initial='notActive' animate={ props.location.pathname.indexOf( 'tiedot' ) !== -1 ? 'active' : 'notActive' } variants={ navItemVariants }>
                        <NavLink to="/tiedot" className="nav-link" activeClassName='active-nav'>
                            <i title="Omat tiedot" className="fas fa-users-cog nav-icon"></i>
                            <small>Omat tiedot</small>
                        </NavLink>
                    </motion.div>

                    <motion.div whileHover={ { boxShadow: 'inset 0px -5px 0px rgba(125,125,125,1)' } } onClick={ () => props.onLogOut() } initial='notActive' animate='notActive' variants={ navItemVariants }>
                        <NavLink to="/" className="nav-link">
                            <i title="Kirjaudu ulos" className="fas fa-sign-out-alt nav-icon"></i>
                            <small>Ulos</small>
                        </NavLink>
                    </motion.div>
                    
                    <p className="lighter-text" style={ { marginTop: 'auto' } }>Powered by</p>

                    <NavbarBrand href="https://qibbie.com" target="_blank"><img src="/images/qibbie_logo_neg.png" className="nav-logo" alt="Qibbie" /></NavbarBrand>
                    
                </Scrollbars>

            </Nav>
            <TabContent activeTab={ props.activeTab } >

                <motion.div initial='active' animate={ ( props.activeTab === '/raportit/yritys' && props.panelOpen ) ? 'active' : 'notActive' } variants={ tabContentVariants }>
                    <TabPane tabId="/raportit/yritys" className="tabContent">
                        <div className="sidePanelCloseDiv">
                            <i className="fas fa-chevron-left closeIcon" onClick={ props.togglePanel }></i>
                        </div>
                        <ReportContent type="anon" navigateTo={ props.navigateTo } />
                    </TabPane>
                </motion.div>

                <motion.div initial='active' animate={ ( props.activeTab === '/raportit/yritys/raportti' && props.panelOpen ) ? 'active' : 'notActive' } variants={ tabContentVariants }>
                    <TabPane tabId="/raportit/yritys/raportti" className="tabContent">
                        <div className="sidePanelCloseDiv">
                            <i className="fas fa-chevron-left closeIcon" onClick={ props.togglePanel }></i>
                        </div>
                        <ReportContent type="anon" />
                    </TabPane>
                </motion.div>

            </TabContent>

        </div>
    )
}

export default SideNav
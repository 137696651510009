import React, { useState } from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import { Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Input } from 'reactstrap';
import moment from 'moment';
import { Rnd } from "react-rnd";
import QlikIframe from '../Modals/previewItems/QlikIframe';
import {ModifiedContainer} from './DataContainers';
const IFrame = ({ item, editable, promptSave, onDelete, toggleItemVisibility, saveIFrameHost, onTemplateAdd, toggleDrag, scrollToSaveButtonAfterResize, index, layoutPreview = false }) => {

    const [title, setTitle] = useState(item.topicContent);
    const [hostName, setHostName] = useState(item.hostName);
    const [openMenu, toggleMenu] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [preview, setPreview] = useState(false);
    const [size, setSize] = useState(item.size);
    const [enableResize, setEnableResize] = useState(false);

    function handleDataChanges(type, data) {

        if (type === "title") {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                type: item.type
            }
            promptSave(itemPayload);
        } else if (type === 'hostName') {
            setHostName(data);
        }
        //onItemChange(itemPayload);
    }

    function handlePreview() {
        setPreview(!preview);
    }

    function handleUrlSave() {
        let itemPayload = {
            id: item.id,
            topicContent: title,
            hostName: hostName,
            size: size,
            type: item.type
        }
        saveIFrameHost(itemPayload);
        setPreview(false);
        setEnableResize(false);
        toggleDrag(true);
    }

    function toggleResize() {
        if (enableResize) {
            toggleDrag(true);
        } else {
            toggleDrag(false);
        }
        setEnableResize(!enableResize);
    }

    function handleResize(height, width) {
        setSize({ height: height, width: width });
        setShowSaveButton(true);
        scrollToSaveButtonAfterResize(item.id);
    }

    function handleDivSize(height, direction) {
        if (size.height !== height) {
            setSize({ ...size, height: height });
        }
        setShowSaveButton(true);
    }
    const style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "#f0f0f0"
    };
    return (

        <Row style={{ paddingBottom: '1.75em' }} className="plan-object" id={item.id}>
            <Col xl="12">
                {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700}}>Yksityinen</span>}
                {(!editable && item.modified !== undefined) && 
                    <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                }
            </Col>
            <Col xl="12" style={{ marginBottom: 8 }} className="title-col">
                <div style={{ display: 'flex' }}>
                    {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1 + " "}<i className="far fa-file-code plan-input subTitle" style={{ alignSelf: 'center' }}></i></div>}            
                    {editable ?
                        <input className="plan-input subTitle" name="title" type="text" style={{ width: "100%" }} placeholder="iFrame otsikko" value={(item && title) ? title : ''} onChange={(e) => handleDataChanges("title", e.target.value)}></input>
                        :
                        <TextEditor
                            editorId={`${item.id}-subtitle-${editable}`}
                            content={item.topicContent}
                            additionalClass="plan-input subTitle plan-subtitle"
                            readOnly={!editable}
                            placeholder="-"
                            plaintextEditor={true}
                        />
                    }
                </div>
            </Col>
            <Col xl="6" style={{ marginBottom: 8 }} className="plan-text-col">
                <input className="plan-input subTitle" name="domain" type="text" style={{ width: "100%" }} placeholder="iFrame urli" value={(item && hostName) ? hostName : ''} onChange={(e) => handleDataChanges("hostName", e.target.value)}></input>
            </Col>
            <Col xl="5" className="plan-text-col">
                <button className="small-white-button lighter-text wide-button active-button" onClick={() => handleUrlSave()} id={`${item.id}-saveButton`} style={{color: '#fff', fontWeight:'bold'}} >Tallenna</button>
            </Col>
            <Col xl="1" style={{ paddingLeft: 15, marginBottom: 8 }} className="plan-dropdown-col">
                <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                    {editable && <Dropdown isOpen={openMenu} style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={(event) => event.stopPropagation()} toggle={() => toggleMenu(!openMenu)} >
                        <DropdownToggle tag="div">
                            <i className="fas fa-bars plusIcon" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => onTemplateAdd({ planItem: { ...item, size: { ...size } } })} className="add-template">Lisää pohjaksi</DropdownItem>
                            <DropdownItem onClick={() => toggleItemVisibility({ planItemId: item.id })} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                            <DropdownItem onClick={() => onDelete({ planItemId: item.id, topicContent: item.topicContent })} className="delete-plan-item">Poista</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                </div>
            </Col>
            <Col className="col-sm-12 col-md-10 offset-md-1" style={{ marginTop: '1.5em' }}>
                <div>
                    <button className={enableResize ? "small-white-button lighter-text wide-button active" : "small-white-button lighter-text wide-button"} onClick={() => toggleResize()} style={{ marginBottom: '0.8em' }}>Muuta kokoa</button>
                </div>
                {enableResize ?
                    <div style={{ ...size, maxWidth: '100%' }}>
                        <Rnd
                            size={size}
                            onResizeStop={(e, direction, ref, delta, position) => {
                                handleResize(ref.style.height, ref.style.width)
                            }}
                            onResize={(e, direction, ref, delta, position) => {
                                handleDivSize(ref.style.height, direction)
                            }}
                            disableDragging
                            style={style}
                            maxWidth={'100%'}
                            enableResizing={{ top: false, right: true, bottom: true, left: true, topRight: false, bottomRight: true, bottomLeft: true, topLeft: false }}
                        >
                            <iframe src={hostName} frameBorder="1" style={{ height: size.height, width: '100%' }}></iframe>
                        </Rnd>
                    </div>
                    : item.qlik ? <QlikIframe data={item} hostName={hostName} frameBorder="0" style={size} /> : <iframe src={hostName} frameBorder="0" style={size}></iframe>}
            </Col>
        </Row>
    )
}

export default IFrame;
import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ActionCreator from '../../../Components/planBuilder/Actions/ActionCreator';

class PlannerPanels extends Component {

    isActive = (path) => {
        return this.props.activetab === path
    }

    handleClick = (path) => {
        this.props.history.push(path)
    }


    render() {
        
        const activatedText = this.props.sentSurveys.length === 1 ? 'luotu lomake' : 'luotua lomaketta'

        return (
            <Container>
                <Row>
                    <StartPanel 
                        isActive={ this.isActive } 
                        handleClick={ this.handleClick } 
                        planList={ this.props.planList } 
                        activatedText={ activatedText } 
                        dasboardSummary={ this.props.dasboardSummary } 
                        onNewPlan={this.props.onNewPlan}
                    />
                </Row>
            </Container>
        )
    }
}

const StartPanel = props => {

    return (
        <Col>
            <Row className="surveyMainContentRow">
                <Col className={props.isActive('/kartoitukset') ? 'survey-bar-active' : 'survey-bar'} onClick={() => props.handleClick('/lomakkeet')}>
                        <p>Lomakkeet</p>
                        <span className="contentSubTitle lighter-text">{`${props.planList.length} ${props.activatedText}`}</span>
                </Col>
                <Col className={props.isActive('/lomakkeet/uusi') ? 'survey-bar' : 'survey-bar'} onClick={() => {props.handleClick('/lomakkeet/uusi'); props.onNewPlan();}}>
                        <p className="contentTitle">Uusi lomake</p>
                </Col>
                <Col className={props.isActive('/lomakkeet/uusi/lohko') ? 'survey-bar' : 'survey-bar'} onClick={() => {props.handleClick('/lomakkeet/uusi/lohko');}}>
                        <p className="contentTitle">Luo päivitysmalli</p>
                </Col>
                <Col className={ props.isActive( '/lomakkeet/hae' ) ? 'survey-bar' : 'survey-bar' } onClick={ () => props.handleClick( '/lomakkeet/hae' ) }>
                    <p className="contentTitle">Hae lomakkeita</p>
                </Col>
            </Row>
        </Col>
    )
}

const mapStateToProps = state => {
    return {
        sentSurveys: state.senderV2.sentSurveys,
        dasboardSummary: state.senderV2.availableSurveys,
        planList: state.plan.planList
    }
}

export default withRouter(connect(mapStateToProps, null)(PlannerPanels));
import * as ActionTypes from './ActionTypes';

import {confs} from '../../../../../../../../modules/config';
import {NotificationManager} from 'react-notifications';
import {authFailed} from '../../../../../../../../store/index';
import {loadingStatus} from '../../../../../../../../store/index'
const storeCategory = payload => {
    return {
        type: ActionTypes.STORE_CATEGORY,
        payload: payload
    }
}

const setQuestion = payload => {
    return {
        type: ActionTypes.SET_QUESTION,
        payload: payload
    }
}

export const copyQuestion = (params) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        if(Object.keys(params).length > 0) {
        dispatch(loadingStatus(true))
        fetch(confs.url + 'modify/copyQuestion', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    if (responseJson.status === true) {
                        if(responseJson.survey !== undefined) {
                            NotificationManager.success("Kysymys kopioitu onnistuneesti", "", 2000);
                            dispatch(storeCategory(responseJson.survey));
                            dispatch(loadingStatus(false))
                        }
                    } else {
                        NotificationManager.error("Kysymyksen kopioinnissa tapahtui virhe", "", 2000);
                        dispatch(loadingStatus(false))
                    }
                }
            }).catch(error => {
                NotificationManager.error("Palvelussa tapahtui virhe", "", 2000);
                dispatch(loadingStatus(false))
            })
        }
    }
}
export const modifyQuestion = (params) => {
        let jwt = window.sessionStorage.getItem("token");
        let payload = {
            ...params,
            jwtToken: jwt
        }
        return dispatch => {
            if(Object.keys(params).length > 0) {
            dispatch(loadingStatus(true))
            fetch(confs.url + 'modify/questionModifyV2', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                        dispatch(authFailed());
                    } else {
                        if (Object.keys(responseJson).length > 0) {
                            if(responseJson.newQuestion !== undefined) {
                                NotificationManager.success("Kysymys lisätty onnistuneesti", "", 2000);
                                dispatch(storeCategory(responseJson.categoryData));
                                dispatch(loadingStatus(false))
                                dispatch(setQuestion({currentQuestion: responseJson.newQuestion}));
                            } else if(responseJson.targetQuestion !== undefined) {
                                dispatch(loadingStatus(false))
                                NotificationManager.success("Kysymystä muokattu onnistuneesti", "", 2000);
                                dispatch(storeCategory(responseJson.categoryData));
                                dispatch(setQuestion({currentQuestion: responseJson.targetQuestion}));
                            } else if(responseJson.targetQuestion === undefined && responseJson.newQuestion === undefined && responseJson.survey !== undefined) {
                                dispatch(loadingStatus(false))
                                dispatch(storeCategory(responseJson.survey));
                                dispatch(setQuestion({currentQuestion: {}}));
                            }
                        } else {
                            dispatch(loadingStatus(false))
                            NotificationManager.error("Kysymyksen tallennus epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000);
                        }
                    }
                }).catch(error => {
                    dispatch(loadingStatus(false))
                    NotificationManager.error("Kysymyksen tallennus epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000);
                })
        }
    }
}

export const modifySentSurveyQuestion = (params) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        if(Object.keys(params).length > 0) {
        dispatch(loadingStatus(true))
        fetch(confs.url + 'modify/sentSurveyQuestion', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    if (Object.keys(responseJson).length > 0) {
                        if(responseJson.targetQuestion !== undefined) {
                            dispatch(loadingStatus(false))
                            NotificationManager.success("Kysymystä muokattu onnistuneesti", "", 2000);
                            dispatch(storeCategory(responseJson.categoryData));
                            dispatch(setQuestion({currentQuestion: responseJson.targetQuestion}));
                        } else if(responseJson.targetQuestion === undefined && responseJson.newQuestion === undefined && responseJson.survey !== undefined) {
                            dispatch(loadingStatus(false))
                            dispatch(storeCategory(responseJson.survey));
                            dispatch(setQuestion({currentQuestion: {}}));
                        }
                    } else {
                        dispatch(loadingStatus(false))
                        NotificationManager.error("Kysymyksen tallennus epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000);
                    }
                }
            }).catch(error => {
                dispatch(loadingStatus(false))
                NotificationManager.error("Kysymyksen tallennus epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000);
            })
    }
}
}

export const deleteQuestionV2 = (params) => {
    
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        dispatch(loadingStatus(true))
        fetch(confs.url + 'delete/question', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(authFailed());
            } else {
                if (Object.keys(responseJson).length > 0) {
                    if(responseJson.nextTarget !== undefined) {
                        NotificationManager.success("Kysymys poistettu onnistuneesti", "", 2000);
                        dispatch(storeCategory(responseJson.survey));
                        dispatch(loadingStatus(false))
                        dispatch(setQuestion({currentQuestion: responseJson.nextTarget}));
                    }              
                } else {
                    dispatch(loadingStatus(false));
                    NotificationManager.error("Kysymyksen poisto epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000);
                }
            }
        }).catch(error => {
            dispatch(loadingStatus(false))
            NotificationManager.error("Kysymyksen poisto epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000);
        })
    }
}


import React from 'react';
import {Row, Col} from 'reactstrap';
import { Draggable } from 'react-beautiful-dnd'

const SubButton = props => {
    const setActiveQuestion = (group, target) => {
        props.startEdit(group, target)
    }

    return (
        <Draggable index={props.questionIndex} draggableId={props.id} type="question">
        {(provided, snapshot) => {
        return (
            <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                className={props.activeQuestion.id === props.id ? "justify-content-between dialogListOption question-active" : "justify-content-between dialogListOption"}
                onClick={ () => setActiveQuestion(props.categoryGroup, props.target) }
            >
                <Row>
                    <Col xs="2">
                        <i className="material-icons add_icon float-left">add</i>
                    </Col>
                    <Col xs="10" style={{textAlign: "left"}}>
                        {props.text}
                    </Col>
                </Row>
            </div>
        )}}
        </Draggable>
    )
}

export default SubButton;
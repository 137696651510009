import * as React from "react";
import { motion, useCycle } from "framer-motion";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const subVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.02 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};

export const MenuItem = ({ i, handleSelection, filters}) => {
    const [sideMenu, setSideMenuOpen] = useCycle(false, true);
  
    return (
      <motion.li
        variants={variants}
        className={filters.filter(x => x.value === i.value).length === 0 ? "dropdown-submenu" : "dropdown-submenu disabled"}
        onMouseEnter={() => setSideMenuOpen()} 
        onMouseLeave={() => setSideMenuOpen()}
        onClick={() => handleSelection([...filters, i])}
      >
      <div>
         <span className="mainItem">
            {i.value}
          </span>
      </div>
      </motion.li>
    );
  };
import React, { useEffect } from 'react'
import {
  Row,
  Col
} from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import RiskMatrice from '../../../Utilities/Matrice/RiskMatrice';
import HelperBox from '../../../Utilities/HelperBox/HelperBox';

const getSurveyTitles = task => {
  let taskTitles = "";
  if (task.taskTree !== undefined) {
    task.taskTree.surveyTitles.forEach((taskTitle, x) => {
      if (x === task.taskTree.surveyTitles.length - 1) {
        taskTitles += taskTitle.title;
      } else {
        taskTitles += taskTitle.title + ", ";
      }
    })
  }
  return taskTitles;
}

const RiskList = (props) => {
  const { risks, showRiskMatrice, filterBy } = props
  return (
    <Col md={{ size: 12 }} xl={{ size: 6 }}>
      <div className="dashboard-medium-box">
        <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
          <p>Riskiarviot</p>
          <div style={{ marginLeft: '0.5em' }}>
            <form id="search-bar">
              <input type="search" className="search-bar-input" placeholder="Etsi riskiarviota" name="riskFilter" onChange={(event) => props.setFilter(event)} />
            </form>
          </div>
          {props.risks.length > 0 &&
            <button className="small-white-button lighter-text wide-button riskView" onClick={() => props.changeView()} style={{ marginLeft: '3rem' }}>
              {showRiskMatrice ? "Tekstinäkymä" : "Graafinen näkymä"}
            </button>
          }
        </div>
        <Scrollbars
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
          style={{ width: "100%", height: '500px', zIndex: 90 }}
          className="custom-scrollbars"
        >
          {(risks && risks.length > 0) ? (
            showRiskMatrice ?
              <Row style={{ margin: 'auto' }}>
                {/* <Col xs="12" xl="12" style={{padding: '2.5em'}}> */}
                {/* <p>Taulukosta selviää riskiarvoiden määrät, todennäköisyys / vaikuttavuus asteikolla. Numerot viittaavat kappalemääriin, tarkempaa tarkastelua varten valitse asteikosta numero, jolloin pääset tarkastamaan listausta tästä asteikon osasta.</p> */}
                {/* </Col> */}
                <Col className="col-xs-12" xl="12" style={{ padding: '0 2.5em 0 .5em' }}>

                  <RiskMatrice {...props} />

                </Col>
              </Row>
              :
              risks.map((risk, index) => {
                if ((risk.riskClassification.label && risk.riskClassification.label.toLowerCase().includes(props.filter.toLowerCase())) || (risk.riskGroup.label && risk.riskGroup.label.toLowerCase().includes(props.filter.toLowerCase()))) {
                  let creationDate = risk.created ? moment(new Date(risk.created)).format('DD.MM.YYYY') : ''
                  let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'

                  return <div className={oddEvenClass + ' selectable-row risk-list-row'} style={{ paddingLeft: '1rem' }} key={index} onClick={() => props.modifyRisk(risk)}>
                    <Row>
                      <Col xs="1" className="riskIconCol">
                        <div style={{ display: 'flex', height: '100%' }}>
                          <i className="fas fa-exclamation-triangle" style={{ paddingRight: '0.5em', color: risk.color, fontSize: '1.5em', alignSelf: 'center' }}></i>
                        </div>
                      </Col>
                      <Col xs="10">
                        <Row className='risk-list-content'>
                          <Col xs={{ size: 5 }}>
                            {risk.title &&
                              <div className="tasksText">
                                <p>{risk.title}</p>
                              </div>
                            }
                            <div className="tasksText">
                              <span className="overflower-short">{risk.explanationPlainContent}</span>
                            </div>
                          </Col>
                          <Col xs={{ size: 4 }}>
                            <p style={{ fontSize: '.9em' }}>{risk.riskGroup.label}</p>
                          </Col>
                          <Col xs={{ size: 3 }}>
                            <p style={{ fontSize: '.9em' }}>{risk.riskClassification.label}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={{ size: 6 }}>
                            <p className="lighter-text">{risk.riskOdds.label} - {risk.riskLevel.label}</p>
                          </Col>
                          <Col xs={{ size: 3 }}>
                            <p className="lighter-text">{risk.targetCompanyName}</p>
                          </Col>
                          <Col xs={{ size: 3 }}>
                            <p className="lighter-text">
                              {creationDate}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <i style={{ float: 'right', color: '#ccc', marginRight: '.5em' }} className="fas fa-caret-right"></i>
                      </Col>
                    </Row>

                  </div>
                }
                return null
              })
          ) : (
              <div style={{paddingTop: '1.5em'}}>
                <HelperBox text="Ei riskiarvioita" onlyText={true} />
              </div>
            )}
        </Scrollbars>
      </div>
    </Col>
  )
}

const MegaTinyLoaderSpinner = () => {
  return (
    <Row>
      <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '2rem', height: '2rem', marginTop: '0.5rem' }} className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Col>
    </Row>
  )
}

export default RiskList
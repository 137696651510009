import React from 'react';
import {Row, Col} from 'reactstrap'
import moment from 'moment';

const RiskItem = props => {
    return (
        <Col xl="12" className="task-risk-row" onClick={() => props.modifyRisk({...props.item._id, risk: props.riskItem})}>
            <div style={{display: 'flex'}}>
                <div>
                    <i className="fas fa-exclamation plan-task-icon" style={{border: '1px solid #404040', padding: '10px 15px 10px 15px', borderRadius: '50%'}}></i>
                </div>
                <div>
                    <p style={{alignSelf: 'center', paddingLeft: '2em'}}>{props.riskItem.riskName ? <b>{props.riskItem.riskName}</b> : props.riskItem.explanationPlainContent}<br /><b>{moment( new Date( props.riskItem.created ) ).format( 'DD.MM.YYYY' )}</b> - {props.riskItem.riskClassification.label}</p>
                </div>
            </div>
        </Col>
    )
}

export default RiskItem;
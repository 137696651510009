import React from 'react';
import { Range, getTrackBackground } from 'react-range';

const MIN = 0;
const MAX = 100;
const STEP = 1;

const GoalCreator = props => {
    const {setGoalValue, goalValue, finalChange} = props;
    return (
        <div
            style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            paddingLeft: '0.5em',
            paddingRight: '0.4em'
            }}
        >
            <Range
            values={goalValue}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={values => setGoalValue( values )}
            onFinalChange={values => finalChange(values)}
            renderTrack={({ props, children }) => (
                <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%'
                }}
                >
                <div
                    ref={props.ref}
                    style={{
                    height: '5px',
                    width: '100%',
                    borderRadius: '4px',
                    background: getTrackBackground({
                        values: goalValue,
                        colors: ['#548BF4', '#ccc'],
                        min: MIN,
                        max: MAX
                    }),
                    alignSelf: 'center'
                    }}
                >
                    {children}
                </div>
                </div>
            )}
            renderThumb={({ props, isDragged }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '15px',
                        width: '15px',
                        borderRadius: '50%',
                        backgroundColor: '#FFF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0px 2px 6px #AAA'
                    }}
                    >
                    <div
                        style={{
                        position: 'absolute',
                        top: '-38px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        padding: '0.5em'
                        }}
                    >
                        {goalValue > 0 && goalValue}
                    </div>
                </div>
            )}
            />
        </div>
    )
}

export default GoalCreator;
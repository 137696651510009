import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import Tour from 'reactour'
import { connect } from 'react-redux'

class GuideTour extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isTourOpen: false,
            steps: null
        }
    }

    

    componentDidMount() {
        if(this.props.modulesInUse){
            this.buildSteps()
        }
    }

    componentDidUpdate(prevProps) {
        if((prevProps.modulesInUse !== this.props.modulesInUse) && !this.state.steps){
            this.buildSteps()
        }
    }
    
    

    buildSteps = () => {
        let { modulesInUse } = this.props
        let steps = [
            {
                id: "Yhteenveto",
                selector: '#yhteenveto',
                content: 'Vexillologist PBR&B marfa yuccie, portland ethical af tousled quinoa ramps forage swag cred. Vinyl pickled kinfolk enamel pin austin cred banjo synth offal pork belly humblebrag. Fixie deep v selfies mustache health goth unicorn shoreditch pinterest. Hashtag try-hard 90s pug chambray skateboard hot chicken, kombucha literally brunch lyft gastropub direct trade shabby chic.',
            }
        ]
        if (modulesInUse.planManagement) {
            steps.push({
                id: "Lomakkeet",
                selector: '#lomakkeet',
                content: 'Hella jianbing keytar cray yr kombucha banh mi tofu authentic tacos twee before they sold out gluten-free meh sriracha. Ramps austin bicycle rights lumbersexual gochujang hot chicken chia quinoa, meggings flannel before they sold out selfies banjo tumeric. Swag sustainable master cleanse, squid celiac bitters cloud bread slow-carb man braid put a bird on it.'
            })
        }
        if (modulesInUse.surveyManagement) {
            steps.push({
                id: "Kartoitukset",
                selector: '#kartoitukset',
                content: 'Craft beer beard glossier kogi hell of chia poke intelligentsia jean shorts. Cold-pressed raclette brooklyn, cloud bread lumbersexual locavore brunch distillery kogi. Mumblecore pinterest cardigan palo santo distillery vexillologist typewriter knausgaard.'
                })
            }

        if (modulesInUse.embedManagement) {
            steps.push({
                id: "Upotus",
                selector: '#upotus',
                content: 'Selfies distillery viral flannel. Skateboard lomo cloud bread, stumptown man bun copper mug seitan organic hella affogato. Keffiyeh helvetica vape, godard single-origin coffee kale chips chambray cardigan photo booth activated charcoal air plant readymade beard humblebrag.'
            })
        }

        steps.push({
            id: "Asiakkaat",
            selector: '#yhteistyo',
            content: 'Four loko deep v bespoke, cronut pinterest brooklyn williamsburg master cleanse lomo fingerstache heirloom. XOXO farm-to-table air plant four dollar toast woke pork belly. Retro gluten-free tbh mlkshk. Normcore kinfolk ramps chicharrones bitters. Franzen beard four dollar toast raclette. Poke paleo hashtag prism literally.'
        })
        if(modulesInUse.projectManagement){
            steps.push({
                id: "Projektit",
                selector: '#projektit',
                content: 'Four loko deep v bespoke, cronut pinterest brooklyn williamsburg master cleanse lomo fingerstache heirloom. XOXO farm-to-table air plant four dollar toast woke pork belly. Retro gluten-free tbh mlkshk. Normcore kinfolk ramps chicharrones bitters. Franzen beard four dollar toast raclette. Poke paleo hashtag prism literally.'
            })
        }
        if (modulesInUse.planManagement) {
            steps.push({
                id: "Tuotteet",
                selector: '#tuotteet',
                content: 'Four loko deep v bespoke, cronut pinterest brooklyn williamsburg master cleanse lomo fingerstache heirloom. XOXO farm-to-table air plant four dollar toast woke pork belly. Retro gluten-free tbh mlkshk. Normcore kinfolk ramps chicharrones bitters. Franzen beard four dollar toast raclette. Poke paleo hashtag prism literally.'
            })
        }
        if(modulesInUse.toolsBeta && this.props.allowTools){
            steps.push({
                id: "Työkalut",
                selector: '#tyokalut',
                content: 'ctivated charcoal snackwave craft beer locavore before they sold out four dollar toast. Blue bottle salvia street art la croix locavore tilde keffiyeh hexagon. Hexagon cronut tacos organic. Narwhal humblebrag normcore synth glossier cronut tousled kickstarter mumblecore live-edge thundercats cardigan cold-pressed 8-bit tumblr. '
            })
        }
        steps.push({
            id: "Asetukset",
            selector: '#tiedot',
            content: 'ctivated charcoal snackwave craft beer locavore before they sold out four dollar toast. Blue bottle salvia street art la croix locavore tilde keffiyeh hexagon. Hexagon cronut tacos organic. Narwhal humblebrag normcore synth glossier cronut tousled kickstarter mumblecore live-edge thundercats cardigan cold-pressed 8-bit tumblr. '
        })

       

        this.setState({ steps: steps })
    }


    render() {
        
        return (

            <>
                <Button className="tour-button" onClick={() => this.setState({ isTourOpen: true })}>Getting started</Button>
                <Tour
                    steps={this.state.steps}
                    isOpen={this.state.isTourOpen}
                    rounded={4}
                    onRequestClose={() => (this.setState({ isTourOpen: false }))}
                    badgeContent={(curr, total) => (this.state.steps && !Number.isNaN(parseInt(curr)) && this.state.steps[(parseInt(curr)-1)].id)? `${this.state.steps[(parseInt(curr)-1)].id}` : (parseInt(curr) + "/" + total)}   
                    startAt={0}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        allowTools: state.authentication.allowTools
    }
}

export default withRouter(connect(mapStateToProps)(GuideTour));
import * as ActionTypes from '../../Components/Reports/actions/ActionTypes'
import {SET_CONTROLGROUPS, SET_RISKCLASSIFICATIONS} from '../../Utilities/Modals/riskModals/actions/ActionTypes';
const initialState = {
  businesses: null,
  latestAnswers: null,
  selectedBusinessId: null,
  selectedBusiness: [],
  reportDashboardData: null,
  sentSurveys: [],
  surveyTree: [],
  sentSurveyIds: [],
  isLoading: false,
  activeFilters: [], // array of objects { label: 'filter label', type: 'one of: answerSession, ' }
  filterTypes: {
    answerSession: { label: 'Vastaajan mukaan', type: 'answerSession', answerSessionIds: [] },
    variable: { label: '', type: 'variable', question: '' },
    dateRange: { label: '', type: 'dateRange', startDate: null, endDate: null }
  },
  surveyVariables: [],
  selectedVariables: {},
  taskModifyStatus: false,
  taskModifyMessage: "",
  riskClassifications: [],
  riskControlGroups: [],
  riskMatrice: null,
  riskMatriceResults: null,
  surveySummaryCards: [],
  businessSentSurveys: null,
  businessPlans: null,
  businessGoals: null
}

const reducer = (state = initialState, action) => {
  const activeFilters = JSON.parse(JSON.stringify(state.activeFilters))

  switch(action.type) {
    case ActionTypes.GET_BUSINESSES:
      return {
        ...state,
        businesses: action.payload
      }
    case ActionTypes.SET_RISK_MATRICE:
      return {
        ...state,
        riskMatrice: action.payload
      }
    case ActionTypes.SET_SUMMARY_CARDS:
      return {
        ...state,
        surveySummaryCards: [...action.payload]
      }
    case ActionTypes.GET_LATEST_ANSWERS:
      return {
        ...state,
        latestAnswers: action.payload
      }
      case SET_CONTROLGROUPS: {
        return {
            ...state,
            riskControlGroups: action.payload
        }
    }
    case SET_RISKCLASSIFICATIONS: {
        return {
            ...state,
            riskClassifications: action.payload
        }
    }
    case ActionTypes.SELECT_BUSINESS:
      return {
        ...state,
        selectedBusinessId: action.payload
      }
    case ActionTypes.GET_REPORT_DASHBOARD:
      let values;
      if(action.payload.business && !state.businessGoals && !action.payload.business.hubSpot){
        let business = action.payload.business
        values = {
          closedValues: business.closedValues || 0,
          inProgressValues: business.inProgressValues || 0
        }
      }
      
      return {
        ...state,
        businessGoals: values ? values : state.businessGoals,
        reportDashboardData: action.payload,
        sentSurveyIds: [],
        surveyVariables: initialState.surveyVariables,
        selectedVariables: {},
        activeFilters: []
      }
    case ActionTypes.UPDATE_TRELLO_CARD_CHECKLIST:
      const {cardId, checkItem} = action.payload
      return {
        ...state,
        reportDashboardData: {
          ...state.reportDashboardData,
          trelloLists: state.reportDashboardData.trelloLists.map(trelloList => {
            const {cardData} = trelloList
            
            const newCardData = cardData.map(card => {
              if(card.id === cardId) {
                const {checklists} = card
                
                const checkLists = checklists.map(checklist => {
                  if(checklist.id === checkItem.idChecklist) {
                    const {checkItems} = checklist
                    
                    const newCheckItems = checkItems.map(item => {
                      if(item.id === checkItem.id) {
                        return checkItem
                      }
                      return item;
                    })

                    return {
                      ...checklist,
                      checkItems: newCheckItems
                    }
                  }
                  return checklist 
                })

                return {
                  ...card,
                  checklists: checkLists
                }
              }

              return card
            })

            return {
              ...trelloList,
              cardData: [...newCardData]
            }
          })
        }
      }
    case ActionTypes.SET_BUSINESS_SENTSURVEYS:
      return {
        ...state,
        businessSentSurveys: action.payload
      }
    case ActionTypes.SET_BUSINESS_PROJECTS:
      return {
        ...state,
        projects: action.payload
      }
    case ActionTypes.GET_REPORT:
      return {
        ...state,
        sentSurveys: action.payload,
        sentSurveyIds: action.sentSurveyIds
      }
    case ActionTypes.CLEAR_SELECTED:
      return {
        ...state,
        sentSurveyIds: [],
        surveyVariables: initialState.surveyVariables,
        selectedVariables: {},
        activeFilters: [],
        surveySummaryCards: []
      }
    case ActionTypes.GET_SURVEY_TREE:
      return {
        ...state,
        surveyTree: action.payload
      }
    case ActionTypes.GET_BUSINESS:
      return {
        ...state,
        selectedBusiness: action.payload
      }
    case ActionTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case ActionTypes.ADD_FILTER:
      return {
        ...state,
        activeFilters: [
          ...action.payload
        ]
      }    
    case ActionTypes.REMOVE_FILTER:
      // remove one from given index
      activeFilters.splice( action.payload, 1 )

      return {
        ...state,
        activeFilters: activeFilters
      }
    case ActionTypes.GET_SURVEY_VARIABLES:
      return {
        ...state,
        surveyVariables: action.payload
      }
    case ActionTypes.SET_VARIABLES:
      return {
        ...state,
        selectedVariables: action.payload
      }
    case ActionTypes.SET_SENTSURVEY_IDS:
      return {
        ...state,
        sentSurveyIds: action.payload
      }
    case ActionTypes.START_FETCH_REQUEST:
      return {
        ...state,
        taskModifyStatus: true,
        taskModifyMessage: ""
      }
    case ActionTypes.FETCH_REQUEST_FINISHED:
      return {
        ...state,
        taskModifyStatus: false,
        taskModifyMessage: action.payload
      }
    case ActionTypes.REPLACE_MODIFIED_TASK:
      return {
        ...state,
        sentSurveys: [...replaceTaskInSurveys(state.sentSurveys, action.payload)]
      }
    case ActionTypes.REMOVE_FINISHED_TASK:
      return {
        ...state,
        sentSurveys: [...removeFinishedTasksInSurveys(state.sentSurveys, action.payload)]
      }
    case ActionTypes.SET_BUSINESS_PLANS:
      return {
        ...state,
        businessPlans: action.payload
      }
    case ActionTypes.UPDATE_BUSINESS_GOAL:
      let reportDashboardData = state.reportDashboardData
      let business = reportDashboardData.business
      business['goal'] = action.payload.sum
      return {
        ...state,
        business: business
      }
    case ActionTypes.UPDATE_BUSINESS_GOALS:
      return {
        ...state,
        businessGoals: action.payload,
      }
    case ActionTypes.CLEAR_REPORT_DASHBOARD: {
      return {
        ...state,
        businessGoals: null,
        reportDashboardData: {}
      }
    }
    default:
      return state
  }
  
}

const removeFinishedTasksInSurveys = (surveys, targetTask) => {
    let newSurveyList = surveys.map((survey, x) => {
      if(survey.tasks !== undefined) {
          Object.assign(survey, removeFromList(survey.tasks, targetTask)); 
      }
  
      if(survey.questionGroups !== undefined) {
        survey.questionGroups.forEach((group, y) => {
          if(group.tasks !== undefined) {
            Object.assign(group, removeFromList(group.tasks, targetTask));
          }
  
          if(group.questions !== undefined) {
            group.questions.forEach((question, j) => {
              if(question.tasks !== undefined) {
                Object.assign(question, removeFromList(question.tasks, targetTask)); 
              }
            })
          }
        })
      }
      return survey;
    })
  
    return newSurveyList;
}

const removeFromList = (taskList, targetTask) => {
    return {tasks: taskList.filter(task => task._id !== targetTask._id)};
}

const replaceTaskInSurveys = (surveys, targetTask) => {
  let newSurveyList = surveys.map((survey, x) => {
    if(survey.tasks !== undefined) {
        Object.assign(survey, compareTasks(survey.tasks, targetTask)); 
    }

    if(survey.questionGroups !== undefined) {
      survey.questionGroups.forEach((group, y) => {
        if(group.tasks !== undefined) {
          Object.assign(group, compareTasks(group.tasks, targetTask));
        }

        if(group.questions !== undefined) {
          group.questions.forEach((question, j) => {
            if(question.tasks !== undefined) {
              Object.assign(question, compareTasks(question.tasks, targetTask)); 
            }
          })
        }
      })
    }
    return survey;
  })

  return newSurveyList;
}

const compareTasks = (taskList, targetTask) => {
  let tasksList = taskList.map((task, y) => {
    if(task._id === targetTask._id) {
      return targetTask;
    }
    return task;
  })
  return {tasks: tasksList}
}

export default reducer
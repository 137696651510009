import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { connect } from 'react-redux'
import * as ActionCreator from '../../store'
import Select from 'react-select'
import moment from 'moment'
import { NotificationManager } from 'react-notifications';

const dataTypes = [
   { value: 'string', label: 'Teksti' },
   // { value: 'date', label: 'Päivämäärä' },
   { value: 'int', label: 'Kokonaisluku' },
   { value: 'double', label: 'Murtoluku' },
]

const stages = {
   default: {
      name: 'default',
      title: 'Valitse tiedosto',
      buttonName: 'Jatka',
      showBackButton: false
   },
   headers: {
      name: 'headers',
      title: 'Valitse ja muokkaa tallennettavat kentät',
      buttonName: 'Jatka',
      showBackButton: false
   },
   report: {
      name: 'report',
      title: 'Luo diagrammi',
      buttonName: 'Luo diagrammi',
      showBackButton: true
   }
}

const intTypes = [
   { value: 'add', label: 'Lisää arvot yhteen' },
   { value: 'count', label: 'Laske kpl yhteen' }
]

let initialState = {
   isLoading: false,
   excelFiles: null,
   url: '',
   excelname: '',
   headers: [],
   stage: stages.default,
   headerEditIndex: null,
   editText: '',
   createNewFile: false,
   selectedFile: false,
   xAxis: null,
   yAxis: null,
   xAxisTitle: '',
   yAxisTitle: '',
   yAxisName: '',
   yAxisList: [],
   addNewAxis: true,
   chartName: '',
   usingFile: false,
   file: null,
   chartCreationStage: 1,
   fileMenuIndex: null
}

class CreateChartModal extends Component {
   constructor(props) {
      super(props)

      this.state = {
         isLoading: false,
         excelFiles: null,
         url: '',
         excelname: '',
         headers: [],
         stage: stages.default,
         headerEditIndex: null,
         editText: '',
         createNewFile: false,
         selectedFile: false,
         xAxis: null,
         yAxis: null,
         xAxisTitle: '',
         yAxisTitle: '',
         yAxisName: '',
         yAxisList: [],
         addNewAxis: true,
         chartName: '',
         usingFile: false,
         file: null,
         chartCreationStage: 1,
         fileMenuIndex: null
      }
   }

   componentDidUpdate(prevProps) {
      if (this.props.isOpen && (this.props.isOpen !== prevProps.isOpen)) {
         this.getFiles();
      }
      if (this.props.isOpen === false && prevProps.isOpen === true) {
         this.setState(initialState)
      }
   }


   modifyValue = (event) => {
      this.setState({ [event.target.name]: event.target.value });
   }

   getFiles = async () => {
      this.setState({ isLoading: true })
      const excels = await this.props.getFiles({ businessId: this.props.businessId });

      if (excels) {
         this.setState({ excelFiles: excels })
      }
      this.setState({ isLoading: false })
   }

   fetch = async () => {
      let { stage, usingFile } = this.state;
      if (stage.name === 'default') {
         this.setState({ isLoading: true })
         let payload = {
            url: this.state.url
         }
         const headers = await this.props.getHeaders(payload);

         if (headers) {
            this.setState({ headers: headers, stage: stages.headers, isLoading: false })
         }
      } else if (stage.name === 'headers') {
         this.setState({ isLoading: true })
         let result;
         if (usingFile) {
            let formData = new FormData()

            let headers = {
               headers: this.state.headers
            }

            formData.append("file", this.state.file)
            formData.append("jwtToken", window.sessionStorage.getItem("token"))
            formData.append("name", this.state.excelname)
            formData.append("businessId", this.props.businessId)
            formData.append("headers", JSON.stringify(headers))

            result = await this.props.getRowsFromFile(formData);

         } else {
            let payload = {
               name: this.state.excelname,
               url: this.state.url,
               businessId: this.props.businessId,
               headers: this.state.headers
            }

            result = await this.props.getRows(payload);
         }

         if (result && result.count && typeof result !== 'undefined') {
            NotificationManager.success(`Lisättiin onnistuneesti ${result.count} riviä`, '', 3000)

            this.setState({ stage: stages.report, isLoading: false, headers: result.headers, excelId: result.excelId })
         } else {
            NotificationManager.error('Jokin meni pileen', 'Virhe', 2000)
         }
      } else if (stage.name === 'report') {
         this.setState({ isLoading: true })
         let payload = {
            businessId: this.props.businessId,
            chartName: this.state.chartName,
            xAxis: this.state.xAxis,
            yAxis: this.state.yAxisList,
            excelId: this.state.excelId,
            xAxisTitle: this.state.xAxisTitle,
            yAxisTitle: this.state.yAxisTitle,
         }

         const result = await this.props.createReport(payload);

         if (typeof result !== 'undefined' && result.success) {
            NotificationManager.success('Diagrammi lisätty onnistuneesti', '', 3000)
            this.props.getExcelReports({ businessId: this.props.businessId })
            this.props.toggle()
         }
      }
   }

   modifyHeaderType = (event, i) => {
      let data = this.state.headers
      let item = data[i]
      item.type = event.value
      this.setState({ headers: data })
   }

   toggleModify = (name, i, value) => {
      if (this.state[name] === i) {
         this.setState({ [name]: null, editText: '' })
      } else {
         this.setState({ [name]: i, editText: value })
      }
   }

   handleHeaderSave = (type) => {
      let data = this.state.headers
      let item = data[this.state.headerEditIndex]
      if (type === 'save') {
         item.name = this.state.editText
         this.setState({ headers: data, headerEditIndex: null, editText: '' })
      }
      if (type === 'delete') {
         item.deleted = true;
         this.setState({ headers: data, headerEditIndex: null, editText: '' })
      }
   }

   axisSelector = (event, axis, operation) => {
      if (operation) {
         let obj = this.state[axis]
         obj["operation"] = event.value
         obj["operationLabel"] = event.label
         this.setState({ [axis]: obj })
      } else {
         this.setState({ [axis]: event })
      }
   }

   yAxisHandler = () => {
      let obj = JSON.parse(JSON.stringify(this.state.yAxis))
      obj["barName"] = this.state.yAxisName

      let list = [...this.state.yAxisList, obj]

      this.setState({ yAxisList: list, addNewAxis: false, barName: '', yAxis: null, yAxisName: '' })
   }

   handleFile = async (e) => {
      this.setState({ isLoading: true })
      let formData = new FormData()

      formData.append("file", e.target.files[0], e.target.files[0].name)
      formData.append("jwtToken", window.sessionStorage.getItem("token"))
      formData.append("name", this.state.excelname)
      this.setState({ usingFile: true, file: e.target.files[0] })

      const headers = await this.props.uploadFile(formData);

      if (headers) {
         this.setState({ headers: headers, stage: stages.headers, isLoading: false })
      }
   }

   onFileClick = (file) => {
      this.setState({ headers: file.headers, businessId: file.businessId, excelId: file.id, excelname: file.name, stage: stages.report, selectedFile: true })
   }

   checkIsButtonDisabled = () => {
      let stage = this.state.stage.name

      if (stage === 'headers') {
         if (this.state.headers.length > 0) {
            return false
         }
      }

      if (stage === 'report') {
         if (this.state.chartName !== '' && this.state.yAxisList.length > 0 && this.state.xAxis) {
            return false
         } else {
            return true
         }
      }
      return true
   }

   checkIsContinueButtonDisabled = () => {
      let nbr = this.state.chartCreationStage

      if (nbr === 1) {
         if (this.state.chartName === '') {
            return true
         }
      } else if (nbr === 2) {
         if (!this.state.xAxis) {
            return true
         }
      }

      return false
   }

   handleBackButton = () => {
      let stage = this.state.stage
      let cStage = this.state.chartCreationStage

      if (cStage > 1) {
         this.setState({ chartCreationStage: this.state.chartCreationStage - 1 })
      } else {
         if (stage.name === 'report' && this.state.selectedFile) {
            this.setState({ stage: stages.default })
         }
      }
   }

   removeFromYList = (i) => {
      let yList = this.state.yAxisList
      yList.splice(i, 1);
      this.setState({ yAxisList: yList })
   }

   toggleMenu = (index) => {
      if (this.state.fileMenuIndex === index) {
         
         this.setState({fileMenuIndex: null});
      } else {
         this.setState({fileMenuIndex: index});
      }
   }

   deleteFile = async (id) => {
      let result = await this.props.deleteFile({ fileId: id })

      if (result) {
         let newState = this.state.excelFiles.filter(ex => ex.id !== result)
         this.setState({ excelFiles: newState, fileMenuIndex: null });

      }
   }

   render() {
      const { toggle, isOpen } = this.props
      const { isLoading, headers, stage, xAxis, yAxis, yAxisList, addNewAxis, excelname } = this.state
      return (
         <div>
            <Modal isOpen={isOpen} toggle={toggle} size="lg">
               <ModalHeader>
                  {stage.title}

                  {stage.name === 'default' &&
                     < button className="small-white-button lighter-text" style={{ float: 'right', marginRight: '.5em' }} onClick={() => this.setState({ createNewFile: !this.state.createNewFile })}>{this.state.createNewFile ? 'Peruuta' : 'Lisää uusi'}</button>
                  }
               </ModalHeader>
               <ModalBody style={{ paddingBottom: 0, paddingTop: 0 }}>
                  {isLoading ?
                     <MegaLoaderSpinner />
                     :
                     <div>
                        {stage.name === 'default' && <FileSelection
                           modifyValue={this.modifyValue}
                           files={this.state.excelFiles}
                           createNew={this.state.createNewFile}
                           handleFile={this.handleFile}
                           name={excelname}
                           onFileClick={this.onFileClick}
                           openFileMenu={this.toggleMenu}
                           fileMenuIndex={this.state.fileMenuIndex}
                           deleteFile={this.deleteFile}
                        />}
                        {stage.name === 'headers' && <HeadersSelection
                           headers={headers}
                           modifyType={this.modifyHeaderType}
                           toggleHeaderModify={this.toggleModify}
                           editIndex={this.state.headerEditIndex}
                           modifyText={this.modifyValue}
                           editText={this.state.editText}
                           handleSave={this.handleHeaderSave}
                        />}
                        {stage.name === 'report' && <ChartBuilder
                           modifyText={this.modifyValue}
                           headers={headers}
                           axisSelector={this.axisSelector}
                           yAxisHandler={this.yAxisHandler}
                           xAxis={xAxis}
                           yAxis={yAxis}
                           yAxisList={yAxisList}
                           addNewHandler={() => this.setState({ addNewAxis: !this.state.addNewAxis })}
                           addNew={addNewAxis}
                           stage={this.state.chartCreationStage}
                           removeFromYList={this.removeFromYList}
                        />}
                     </div>
                  }
               </ModalBody>
               <ModalFooter>
                  <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Sulje</a>

                  {stage.showBackButton &&
                     <button className="small-white-button wide-button" onClick={this.handleBackButton}>Takaisin</button>
                  }
                  {stage.name === 'report' && this.state.chartCreationStage !== 3 ?
                     <button className="small-white-button wide-button" disabled={this.checkIsContinueButtonDisabled()} onClick={() => this.setState({ chartCreationStage: this.state.chartCreationStage + 1 })}>Jatka</button>
                     :
                     <button className="small-white-button wide-button" disabled={this.checkIsButtonDisabled()} onClick={this.fetch}>{stage.buttonName}</button>
                  }
               </ModalFooter>
            </Modal>
         </div >
      );
   }
}


const MegaLoaderSpinner = () => {
   return (
      <Row>
         <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
               <span className="sr-only">Loading...</span>
            </div>
         </Col>
      </Row>
   )
}

const FileSelection = props => {
   return (
      <>
         {props.createNew ?
            <Row>
               <Col xs="12" style={{ marginTop: '1em', marginBottom: '1em' }}>
                  <Label>Nimi</Label>
                  <Input name="excelname" onChange={(e) => props.modifyValue(e)}></Input>
               </Col>
               {props.name &&
                  <>
                     <Col xs="12" style={{ marginBottom: '1em' }}>
                        <Label>Valitse tiedosto tai syötä url-osoite</Label>
                        <Input name="url" onChange={(e) => props.modifyValue(e)} ></Input>
                        <Input onChange={props.handleFile} style={{ marginTop: '.5em' }} type="file" name="excel" />
                     </Col>
                     <Col>
                     </Col>
                  </>
               }
            </Row>
            :
            <>
               {props.files && props.files.length === 0 &&
                  <div style={{ paddingTop: '1em', paddingBottom: '1em' }}>
                     Aloita lisäämällä uusi tiedosto
                  </div>
               }
               {props.files && props.files.map((item, i) => {
                  let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                  return (< Row key={i} className={`selectable-row ${oddEvenClass}`} style={{ cursor: 'pointer' }} onClick={() => props.onFileClick(item)} >
                     <Col xs="6" >
                        {item.name}.xlsx
                        <p className="lighter-text">{item.headers && item.headers.length} kenttää</p>
                     </Col>
                     <Col xs="3">
                        <p className="lighter-text">Luotu</p>
                        <p>{moment(new Date(item.created)).format('DD.MM.YYYY')}</p>
                     </Col>
                     <Col xs="3" style={{ display: 'flex', alignItems: 'center' }}>
                        <button style={{ paddingLeft: '1rem', paddingRight: '1rem', float: 'right' }} className="small-white-button lighter-text">Valitse</button>
                        <Dropdown style={{ marginLeft: 'auto' }} isOpen={props.fileMenuIndex === i} onClick={(event) => event.stopPropagation()} toggle={() => props.openFileMenu(i)}>
                           <DropdownToggle tag="div" style={{  float: 'right', fontSize: '1rem' }}>
                              <i className="fas fa-bars" title="Valikko"></i>
                           </DropdownToggle>
                           <DropdownMenu>
                              <DropdownItem>
                                 <p onClick={() => props.deleteFile(item.id)}>Poista</p>
                              </DropdownItem>
                           </DropdownMenu>
                        </Dropdown>
                     </Col>
                  </Row>
                  )
               })}
            </>
         }
      </>
   )
}

const HeadersSelection = props => {
   let { headers, modifyType, toggleHeaderModify, editIndex, modifyText, editText, handleSave } = props;
   return (
      <>
         <Row style={{ marginBottom: '1em', marginTop: '1em' }}>
            <Col xs="6" ><b>Nimi</b></Col>
            <Col xs="4"><b>Tyyppi</b></Col>
         </Row>
         {headers && headers.map((header, i) => {
            if (!header.deleted) {
               let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
               return (<Row key={i} className={oddEvenClass}>
                  <Col xs="6">
                     {editIndex === i ?
                        <Input value={editText} name="editText" onChange={(event) => modifyText(event)}></Input>
                        :
                        <p>{header.name}</p>
                     }
                  </Col>
                  <Col xs="4">
                     <Select options={dataTypes}
                        value={dataTypes.find(opt => opt.value === header.type)}
                        onChange={(event) => modifyType(event, i)}
                     />
                  </Col>
                  <Col xs="2" style={{ display: 'flex', justifyContent: 'right' }}>
                     {editIndex === i ?
                        <>
                           <i className="far fa-trash-alt" onClick={() => handleSave('delete')} style={{ padding: '.5em', color: '#ED5652' }}></i>
                           <i className="fas fa-check" onClick={() => handleSave('save')} style={{ padding: '.5em', color: '#B5E413' }}></i>
                           <i className="fas fa-times" onClick={() => toggleHeaderModify('headerEditIndex', i, header.name)} style={{ padding: '.5em' }} ></i>
                        </>
                        :
                        <i className="far fa-edit" onClick={() => toggleHeaderModify('headerEditIndex', i, header.name)} style={{ padding: '.5em' }}></i>
                     }
                  </Col>
               </Row>
               )
            } else {
               return null;
            }
         })
         }
      </>
   )
}

const ChartBuilder = props => {
   let { modifyText, headers, axisSelector, yAxisHandler, xAxis, yAxis, addNewHandler, addNew, yAxisList, stage, removeFromYList } = props;

   return (
      <Row>
         <Col xs="12" style={{ marginTop: '1em', marginBottom: '1em' }}>
            {stage === 1 &&
               <>
                  <Label>Otsikko</Label>
                  <Input name="chartName" onChange={(event) => modifyText(event)} />

                  <Label style={{ marginTop: '.5em' }}>X-akselin nimi</Label>
                  <Input name="xAxisTitle" onChange={(event) => modifyText(event)} style={{ marginBottom: '.5em' }} />

                  <Label>Y-akselin nimi</Label>
                  <Input name="yAxisTitle" onChange={(event) => modifyText(event)} />
               </>
            }
            {stage === 2 &&
               <>
                  <h5>X-akselin arvo</h5>
                  <Label>Käytä saraketta:</Label>
                  <Select options={headers} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.name} onChange={(event) => axisSelector(event, "xAxis")} />
               </>
            }
            {stage === 3 &&
               <>
                  <h5>Y-akselin arvot</h5>
                  {yAxisList.length > 0 && yAxisList.map((item, i) => {
                     let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                     return (<Row key={i} className={`selectable-row ${oddEvenClass}`}>
                        <Col xs="10" style={{ display: 'flex', alignItems: 'center' }}>
                           {item.barName && item.barName !== '' ?
                              item.barName
                              :
                              item.name}
                        </Col>
                        <Col xs="2">
                           <i className="far fa-trash-alt" onClick={() => removeFromYList(i)} style={{ padding: '.5em', color: '#ED5652', float: 'right' }}></i>
                        </Col>
                     </Row>
                     )
                  })}

                  {yAxisList && yAxisList.length > 0 &&
                     <button className="small-white-button lighter-text" onClick={addNewHandler} style={{ marginBottom: '1em', marginTop: '1em' }}>{addNew ? 'Peruuta' : 'Lisää uusi'}</button>
                  }

                  {(addNew || yAxisList.length === 0) &&
                     <div style={{ marginTop: '1em' }}>
                        <Label>Valitse pylvään data:</Label>
                        <Select options={headers} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.name} onChange={(event) => axisSelector(event, "yAxis")} />
                        {yAxis &&
                           <div style={{ marginTop: '1em' }}>
                              {(yAxis.type === 'int' || yAxis.type === 'double') &&
                                 <>
                                    <Label>Pylvään nimi</Label>
                                    <Input name="yAxisName" onChange={(event) => modifyText(event)} style={{ marginBottom: '1em' }} ></Input>
                                    <Label>Operaatio</Label>
                                    <Select options={intTypes} onChange={(event) => axisSelector(event, "yAxis", "operation")}></Select>
                                 </>
                              }
                           </div>
                        }
                        <button className="small-white-button lighter-text" onClick={() => yAxisHandler()} disabled={yAxis ? false : true} style={{ marginTop: '1em', marginBottom: '1em' }}>Lisää</button>
                     </div>
                  }
               </>
            }

         </Col>
      </Row>
   )
}

const mapStateToProps = state => {
   return {

   }
}

const mapDispatchToProps = dispatch => {
   return {
      getHeaders: (payload) => dispatch(ActionCreator.getExcelHeaders(payload)),
      getRows: (payload) => dispatch(ActionCreator.getExcelRows(payload)),
      getFiles: (payload) => dispatch(ActionCreator.getFiles(payload)),
      createReport: (payload) => dispatch(ActionCreator.createReport(payload)),
      uploadFile: (payload) => dispatch(ActionCreator.uploadFile(payload)),
      getRowsFromFile: (payload) => dispatch(ActionCreator.getRowsFromFile(payload)),
      deleteFile: (payload) => dispatch(ActionCreator.deleteFile(payload))

   }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateChartModal);
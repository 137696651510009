import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Col, Row } from 'reactstrap';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import NewPlanTarget from '../../planBuilder/NewPlanTarget';
import { Scrollbars } from 'react-custom-scrollbars';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications';

const INITIAL_PLAN = {
    planName: "",
    tags: null,
    selectedBusiness: null
}

export const NewPlanModal = (props) => {
    const {toggle, modalOpen, history, projectId, projectName, planType, updatePlans} = props  
    const [active, setActive] = useState("newPlan")
    const [loaded, setLoaded] = useState(false)
    const [search, setSearch] = useState("")
    const [plans, setPlans] = useState([]) 

    const handleListNameFilter = plan => {
        const {name, planName, selectedBusiness, tags} = plan
        
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        else if(planName && planName.toUpperCase().includes(search.toUpperCase())) return true
        else if(selectedBusiness !== undefined) {
            const {value, label} = selectedBusiness

            if(label.toUpperCase().includes(search.toUpperCase())) {
                return true
            } 
        } 

        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    const getPlans = async() => {
        
        const response = await executeRequest('get/plansForBusiness',{})

        if(response.planList){
            setPlans(response.planList)
        }
    }

    useEffect(()=>{
        getPlans()
    },[])

    const handleAddToProject = async(plan) => {
        if(plan && plan._id && projectId){
            let payload = {
                planId: plan._id,
                projectId: projectId
            }
            const response = await executeRequest('project/addPlanToProject', payload)
            if(response.success){
                NotificationManager.success('Lomake lisätty projektiin onnistuneesti!', '', 3000)
                toggle(false) 
                updatePlans(projectId)
            }
            else if(response.exists){
                NotificationManager.error('Lomake on jo lisätty projektiin!', 'Virhe', 3000)
            }
            else {
                NotificationManager.error('Virhe lisätessä lomaketta!', 'Virhe', 3000)
            }
        } else {
            NotificationManager.error('Ei valittua projektia!', 'Virhe', 3000)
        }
    }

    let i = 0;

    return(
        <>
            <Modal size="lg"  style={{ minHeight: '30em' }} isOpen={modalOpen} toggle={() => toggle(false)} >
                <ModalHeader toggle={() => toggle(false)}>{planType === "template"? "Luo uusi lomakepohja" :"Luo uusi lomake tai liitä olemassa oleva lomake projektiin"}</ModalHeader>
                <ModalBody>
                    <Col style={{ padding: 0 }}>
                        {planType !== "template" &&
                            <Row style={{ paddingTop: '1em', paddingBottom: '1em', marginRight: '0em' }}>
                                <Col>
                                    <button className={"small-white-button wide-button " + (active === 'newPlan' ? "active-plan-selection" : "")} style={{ width: '100%' }} onClick={() => setActive("newPlan")}>Uusi lomake</button>
                                </Col>
                                <Col>
                                    <button className={"small-white-button wide-button " + (active === 'existingPlan' ? "active-plan-selection" : "")} style={{ width: '100%' }} onClick={() => setActive("existingPlan")}>Olemassa oleva</button>
                                </Col>
                            </Row>
                        }
                        {active === 'newPlan'  &&                  
                            <Row>
                                <NewPlanTarget
                                    history={history}
                                    projectId={projectId}
                                    projectName={projectName}
                                    planType={planType}
                                /> 
                            </Row> 
                        }
                        {(active === 'existingPlan' && planType !== "template") &&
                            <div>  
                                <Col className="tab-header " style={{ display: 'flex', alignItems: 'center'}}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginLeft: '1em' }}>
                                        <input type="search" className="search-bar-open form-control" placeholder={"Hae lomaketta"} value={search} onChange={(event) => setSearch(event.target.value)} />
                                    </div>
                                </Col>
                                <div>
                                    {plans &&
                                        <Scrollbars
                                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                            style={{ width: "100%", height: '500px' }}
                                            className="custom-scrollbars"
                                        >
                                        {plans.length > 0 && plans.filter(p => (handleListNameFilter(p) && p.selectedBusiness)).map((plan, y)=>{
                                            //{sentSurveys.length > 0 && sentSurveys.filter(survey => statusFilter(survey)).map((sentSurvey, index) => {                        
                                            let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                            let signedInfo = "Tähän lomakkeeseen on jo tehty allekirjoitus, joten muokkaamaan ei pääse suoraan. Voit siirtyä lomakkeen esikatseluun painamalla lomaketta."
                                            i++;
                                            const {planName} = plan;
                                            return (
                                                <div className={oddEvenClass + ' list-row'} style={{ padding: '1em', alignItems: 'center' }} key={y}>
                                                    <Row className='plan-list-content' style={{ alignItems: 'center' }}>
                                                        <Col xs="5">
                                                            <p title={planName} className="content-text name-overflow-handler-long">{planName}</p>
                                                            {plan.selectedBusiness &&
                                                                <p style={{ fontWeight:300, fontSize: '0.8rem' }}>{plan.selectedBusiness.label}</p>
                                                            }
                                                        </Col >
                                                        <Col xs="3" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                            {plan.tags&& plan.tags.slice(0, 3).map((tag, i) => {                        
                                                                return(<div key={i} style={{ marginLeft: '0.15em', paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                            })}                                                       
                                                            {plan.tags && plan.tags.length > 3 &&  
                                                                <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                                                    <i className="material-icons" title={tagsToMore(plan.tags,3)}>more_horiz</i>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col xs="4" style={{ alignItems: 'center'}}>
                                                            <Row>
                                                                <Col>
                                                                    {(plan.isSigned === true)? 
                                                                        ((plan.allSigned && plan.allSigned === true)? 
                                                                            <i className="fas fa-signature" aria-hidden="true" title="Allekirjoitettu" style={{ color: '#b5e413', fontSize: '1.5rem' }}></i>
                                                                            : 
                                                                            <i className="fas fa-signature" aria-hidden="true" title="Osin allekirjoitettu" style={{ color: '#ffcc00', fontSize: '1.5rem' }}></i>
                                                                        )
                                                                        :
                                                                        <></>
                                                                    }  
                                                                    {(plan.status && plan.status === 'published') &&
                                                                        <span className="dot" title="Julkaistu" style={{backgroundColor: 'rgb(181, 228, 19)', fontSize: '0.8rem'}}></span>
                                                                    }   
                                                                </Col>
                                                                <Col>
                                                                    <button className="small-white-button lighter-text wide-button" onClick={() => handleAddToProject(plan)}>Lisää</button>
                                                                </Col>
                                                            </Row>
                                                        </Col> 
                                                       
                                                    </Row>
                                                </div>                      
                                            )                       
                                        })}
                                    </Scrollbars>
                                    
                                    }
                                </div>                   
                            </div>
                        }
                    </Col> 
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle(false)}>Peruuta</a>
                </ModalFooter>
            </Modal>
        </>
    )
}

const tagsToMore = (tags, slicer) => {
    var tagss = ""
    if(tags !== undefined ){
        tags.slice(slicer, tags.length).filter(t => tagss = tagss + "  " + t.label.toString())
        return(tagss)
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import * as ActionCreator from '../../../../../../../store/index';
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import SelectableIcon from './SelectableIcon';
import DebounceTextEditor from '../../../../../../../Utilities/TextEditor/DebounceTextEditor';
import { confs } from '../../../../../../../modules/config';
import { debounce } from 'debounce';
import Select from 'react-select'
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import ColorPicker from '../questiongroup/subComponents/ColorPicker'
import CustomNumberDisplayer from '../questiongroup/subComponents/ColorPicker'
import Popup from "reactjs-popup";
import IconFeedbacks from './IconFeedbacks'

import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';

import { iconList } from '../../../../../../../Utilities/IconList'

const emptyFeedback = () => {
    let contentState = ''
    contentState = ContentState.createFromText("")

    let editorState = EditorState.createWithContent(contentState);
    return convertToRaw(editorState.getCurrentContent())
}

const typeList = [
    {
        value: 'Kartoitus',
        label: 'Kartoitus'
    },
    {
        value: 'dialog',
        label: 'Dialogi'
    },
    {
        value: 'feedback',
        label: 'Feedback'
    },
    {
        value: 'Lakana',
        label: 'Lakana'
    }
]

const mentionSuggestions = [
    { name: '@prosentti', title: 'Samoin vastanneiden prosenttimäärä kaikista vastanneista' },
]

class CategoryEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryName: this.props.category.title || "",
            styledCategoryExplanation: this.props.category.text || emptyFeedback(),
            plaintextCategoryExplanation: this.props.category.plaintextCategoryExplanation || "",
            styledSurveyEndedText: this.props.category.surveyEndedText || emptyFeedback(),
            plaintextSurveyEndedText: this.props.category.plaintextSurveyEndedText || "",
            iconNames: [
                {
                    icon: "view_comfy",
                    type: "boxes",
                    selected: true
                },
                {
                    icon: "blur_linear",
                    type: "bars",
                    selected: false
                },
                {
                    icon: "whatshot",
                    type: "risk",
                    selected: false
                },
                {
                    text: "360°",
                    type: "360",
                    selected: false
                }
            ],
            selectedIcon: null,
            selectedIconIndex: null,
            iconModal: false,
            style: {},
            type: this.props.category.type || "Kartoitus",
            following: this.props.category.following || false,
            iconList: [],
            pointsInState: null,
            editIndex: null,
            activeTab: "1",
            feedbacks: this.props.iconFeedbacks || [
                {
                    color: '#B5E413',
                    range: [0, 15],
                    text: ""
                }
            ]
        }

        this.iconCheck = this.iconCheck.bind(this);
        this.toggleIconSelected = this.toggleIconSelected.bind(this);
        this.saveCategory = this.saveCategory.bind(this);
        this.start = 600;
        this.repeatTimer = undefined;
    }

    componentDidMount() {
        if (this.props.category.dialogImage !== undefined) {
            this.setState({
                style: { backgroundImage: `url(${confs.host + '/api/getDialogImage/' + this.props.category.dialogImage + '?d=' + Date.now()})`, backgroundSize: 'cover' }
            })
        }
        
        this.setState({
            feedbacks: this.props.category.iconFeedbacks || [
                {
                    color: '#B5E413',
                    range: [0, 15],
                    text: emptyFeedback()
                }
            ]
        })
    }
    componentDidUpdate(prevProps) {
        if (typeof this.props.category !== 'undefined' && prevProps.category !== this.props.category) {
            let nextState = {
                categoryName: this.props.category.title || "",
                styledCategoryExplanation: this.props.category.text || emptyFeedback(),
                plaintextCategoryExplanation: this.props.category.plaintextCategoryExplanation || "",
                styledSurveyEndedText: this.props.category.surveyEndedText || emptyFeedback(),
                plaintextSurveyEndedText: this.props.category.plaintextSurveyEndedText || "",
                editorId: prevProps.category.original ? this.props.category._id : `${this.props.surveyId}-${this.props.selectedSurveyIndex}`,
                following: this.props.category.following
            }

            if (this.props.category.dialogImage !== prevProps.category.dialogImage) {
                nextState = {
                    ...nextState,
                    style: { backgroundImage: `url(${confs.host + '/api/getDialogImage/' + this.props.category.dialogImage + '?d=' + Date.now()})`, backgroundSize: 'cover' }
                };

            }
            this.setState(nextState);
        }
        if (Object.keys(this.props.category).length === 0 && this.state.editorId !== 'newCategory') {
            this.setState({ editorId: 'newCategory' })
        }
    }

    //Function to return proper Col including SelectableIcon depending on the type of icon
    iconCheck(icon, index) {
        if (typeof icon.text !== 'undefined') {
            return (
                <Col key={index} xl="2" md="3">
                    <SelectableIcon text={icon.text} selected={icon.selected} clickHandler={this.toggleIconSelected} index={index} />
                </Col>
            );
        } else {
            return (
                <Col key={index} xl="2" md="3">
                    <SelectableIcon icon={icon.icon} selected={icon.selected} clickHandler={this.toggleIconSelected} index={index} />
                </Col>
            );
        }
    }

    toggleIconSelected(index) {
        let newIconStatus = [...this.state.iconNames];
        let reportType = {};
        newIconStatus.forEach((icon, y) => {
            if (y === index) {
                icon.selected = true;
                Object.assign(reportType, icon);
            } else {
                icon.selected = false;
            }
        })

        this.setState({ iconNames: [...newIconStatus], reportType: reportType });
    }

    saveCategory() {

        // save the raw block array -type content to categoryExplanation and plaintext one to categoryPlainExplanation
        let params = {
            categoryId: this.props.category._id || null,
            categoryTitle: this.state.categoryName,
            categoryExplanation: this.state.styledCategoryExplanation,
            reportType: this.state.reportType || { type: "bars" },
            type: this.state.type,
            plaintextCategoryExplanation: this.state.plaintextCategoryExplanation,
            surveyEndedText: this.state.styledSurveyEndedText,
            plaintextSurveyEndedText: this.state.plaintextSurveyEndedText
        }

        this.props.onSave(params);
    }

    changeType = values => {
        if (values.type !== this.state.type) {
            let payload;
            if (values.type === 'feedback' && this.props.category && !this.props.category.iconFeedbacks) {
                payload = {
                    categoryId: this.props.category._id,
                    surveyId: this.props.category.sentSurveyId || null,
                    incomingData: {
                        type: values.type,
                        iconFeedbacks: [
                            {
                                color: '#B5E413',
                                range: [0, 100],
                                text: emptyFeedback()
                            }
                        ]
                    }
                }
            } else {
                payload = {
                    categoryId: this.props.category._id,
                    surveyId: this.props.category.sentSurveyId || null,
                    incomingData: {
                        type: values.type
                    }
                }
            }
           
            this.setState(values);
            this.props.category.original ? this.props.onCategoryModified(payload) : this.props.onSentSurveyCategoryModified(payload)
        }
    }

    activateSurveyFollowing = values => {
        if (values.following !== this.state.following) {
            let payload = {
                categoryId: this.props.category._id,
                incomingData: {
                    following: values.following
                }
            }
            this.setState(values);
            this.props.onCategoryModified(payload);
        }
    }

    uploadImg = (e) => {
        const formData = new FormData()
        formData.append("img", e.target.files[0], e.target.files[0].name)
        if (this.props.surveyId !== undefined && this.props.surveyId !== "") {
            formData.append("surveyId", this.props.surveyId);
            formData.append("documentId",this.props.surveyId)
        } else {
            formData.append("surveyId", this.props.category._id)
            formData.append("documentId", "anon")
        }
        formData.append("fileName", e.target.files[0].name)
        formData.append("jwtToken", window.sessionStorage.getItem("token"))
        formData.append("connectionType", "surveys")
       
        

        let payload = {
            form: formData,
            id: this.props.surveyId !== undefined && this.props.surveyId !== "" ? this.props.surveyId : this.props.category._id,
            surveyId: this.props.surveyId,
            categoryId: this.props.category._id
        }
        this.props.uploadImg(payload)
    }

    deleteImg = () => {
        let payload = {
            categoryId: this.props.category._id,
            incomingData: {
                dialogImage: null
            }
        }
        this.props.onCategoryModified(payload);
    }

    handleTitle = value => {
        this.setState({ categoryName: value });
        this.onBlurForTitle();
    }

    /* Instant feedback */

    addIcon = (icon) => {
        if (icon !== undefined && icon !== '') {
            let obj = []

            if (this.props.category.iconList && this.props.category.iconList.length > 0) {
                obj = [...this.props.category.iconList, { name: icon, points: 0 }]
            } else {
                obj = [{ name: icon, points: 0 }]
            }

            let payload = {
                categoryId: this.props.category._id,
                incomingData: {
                    iconList: obj
                }
            }
            this.props.onCategoryModified(payload);
        }
    }

    removeIcon = (index, icon) => {
        this.setState({ iconModal: true, selectedIcon: icon, selectedIconIndex: index });
    }

    toggleIconModal = () => {
        this.setState({ iconModal: false, selectedIcon: null, selectedIconIndex: null });
    }

    finalizeRemoveIcon = (i) => {
        let obj = this.props.category.iconList
        obj.splice(i, 1);

        let payload = {
            categoryId: this.props.category._id,
            incomingData: {
                iconList: obj
            }
        }
        this.props.onCategoryModified(payload);
        this.toggleIconModal();
    }

    hover = (isHovering, i) => {
        this.setState({ hoverIndex: isHovering ? i : null })
    }

    handleColor = (color, i) => {
        let obj = this.props.category.iconList

        obj[i] = { ...obj[i], color: color }

        let payload = {
            categoryId: this.props.category._id,
            incomingData: {
                iconList: obj
            }
        }
        this.props.onCategoryModified(payload);
    }

    setEditIndex = (i) => {
        this.setState({ editIndex: this.state.editIndex !== null ? null : i });
    }

    handlePoints = (number, i) => {
        let obj = this.props.category.iconList

        obj[i] = { ...obj[i], points: number }

        let payload = {
            categoryId: this.props.category._id,
            incomingData: {
                iconList: obj
            }
        }
        this.onPointsSave(payload);
    }

    onPointsSave = debounce((payload) => {
        this.props.onCategoryModified(payload)
    }, 1000);

    repeatPositiveButtonEvent = (points) => {
        if (points) {
            this.setState({ pointsInState: points + 1 })
        } else {
            if (this.state.pointsInState < 100) {
                this.setState({ pointsInState: this.state.pointsInState + 1 })
            }
        }


        this.repeatTimer = setTimeout(this.repeatPositiveButtonEvent, this.start)
        this.start = this.start / 2
    }

    repeatNegativeButtonEvent = (points) => {
        if (points) {
            this.setState({ pointsInState: points - 1 })
        } else {
            if (this.state.pointsInState > 0) {
                this.setState({ pointsInState: this.state.pointsInState - 1 })
            }
        }


        this.repeatTimer = setTimeout(this.repeatNegativeButtonEvent, this.start)
        this.start = this.start / 2
    }

    onButtonRelease = (index) => {
        clearTimeout(this.repeatTimer)
        this.pointTarget = undefined;

        this.handlePoints(this.state.pointsInState, index)

        this.setState({ pointsInState: null })
        this.start = 600;
    }

    onDragEnd = (result) => {
        const { destination, source } = result

       
        if (!destination) { return }

        let list = this.props.category.iconList
        const mobeobj = list[source.index]

        list.splice(source.index, 1)
        list.splice(destination.index, 0, mobeobj)

        if(destination.index !== source.index){
            let payload = {
                categoryId: this.props.category._id,
                incomingData: {
                    iconList: list
                }
            }
            this.props.onCategoryModified(payload);
        }
    }


    getTypeObject = type => {
        switch (type) {
            case 'Kartoitus':
                return {
                    value: 'Kartoitus',
                    label: 'Kartoitus'
                }
            case 'dialog':
                return {
                    value: 'dialog',
                    label: 'Dialogi'
                }
            case 'feedback':
                return {
                    value: 'feedback',
                    label: 'Feedback'
                }
            case 'Lakana':
                return {
                    value: 'Lakana',
                    label: 'Lakana'
                }
            default:
                return {
                    value: 'Kartoitus',
                    label: 'Kartoitus'
                }
        }
    }

    render() {

        const typeStyles = {
            option: (provided, state) => ({
                ...provided,
                fontSize: '1rem'
            }),
            container: (provided, state) => ({
                ...provided,
                width: 400
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 400
            })
        }
        const { type } = this.props.category;

        return (

            <div className="categoryEditWindow">
                <IconDeletionModal modal={this.state.iconModal} toggle={this.toggleIconModal} icon={this.state.selectedIcon} confirm={this.finalizeRemoveIcon} selectedIconIndex={this.state.selectedIconIndex}/>
                

                <Row>
                    <Col xs={{ size: 8 }}>
                        <h3>Perustiedot</h3>
                    </Col>
                    <Col xs={{ size: 4 }} style={{ textAlign: 'center' }}>
                        <p>Tekstikenttien oikoluku (beta):</p>
                        <button className="small-white-button" style={{ marginTop: '.5rem' }} onClick={this.props.toggleSpellCheck}>{this.props.spellCheckOn ? "Päällä" : "Pois"}</button>
                    </Col>
                </Row>
                <Row>
                    <Col className="categoryContainer_Title">
                        <h5>Kartoituksen nimi</h5>
                        <DebounceTextEditor
                            editorId={`${this.state.editorId}-categoryTitle`}
                            content={this.props.category.title || ""}
                            handleSave={(this.props.category.original || Object.keys(this.props.category).length === 0) ? this.props.onCategoryModified : this.props.onSentSurveyCategoryModified}
                            categoryId={this.props.category._id}
                            surveyId={this.props.surveyId}
                            autoFocus={this.props.category.autoFocus}
                            plaintextEditor={true}
                            type="categoryTitle"
                            additionalClass="categoryTextArea"
                            saveDraftjsImage={this.props.saveDraftjsImage}
                            spellCheck={this.props.spellCheckOn}
                            connectionType={"surveys"}
                            documentId={this.props.surveyId}
                        />
                    </Col>
                </Row>
                {this.props.category.title && this.props.category.title !== "" &&
                    <div>
                        <Row>
                            <Col>
                                <h5>Kartoituksen tyyppi</h5>
                            </Col>
                            {type !== 'feedback' &&
                                <Col>
                                    <label><h5>Vaikuttaako yrityksen kokonaistulokseen</h5></label>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    value={this.getTypeObject(type)}
                                    onChange={item => this.changeType({ type: item.value })}
                                    options={typeList.filter(x => x.label !== this.getTypeObject(type).label)}
                                    placeholder="Valitse kartoituksen tyyppi"
                                    noOptionsMessage={() => { return "Valintoja ei löytynyt" }}
                                    styles={typeStyles}
                                    className="type-selector"
                                    classNamePrefix="select"
                                />
                            </Col>
                            {type !== 'feedback' &&
                                <Col>
                                    <div>
                                        <button
                                            disabled={!this.props.category.original}
                                            style={{ paddingTop: '0.35em' }}
                                            onClick={(e) => this.activateSurveyFollowing({ following: !this.state.following })}
                                            className={this.state.following ? "followSelection wide-button selected" : "followSelection wide-button"}>
                                            {this.state.following ? "Kyllä" : "Ei"}
                                        </button>
                                    </div>
                                </Col>
                            }

                        </Row>

                        {type === 'dialog' &&
                            <Row style={{ marginTop: "1em" }}>
                                <Col>
                                    <label><h5>Dialogin kuva</h5></label>
                                    <div className="dialog-head" >
                                        <div style={{ display: 'inline-block', textAlign: 'center' }}>
                                            <div className="picture-circle question-picture" style={this.state.style}>
                                                {/* <img src={`${confs.url}getDialogImage/${this.props.category._id}`} width="300px" height="300px" /> */}
                                                <Input onChange={this.uploadImg} type="file" name="logo" style={{ opacity: 0, height: '100%', cursor: 'pointer' }} />
                                            </div>
                                            {this.props.category.dialogImage ?
                                                <p style={{ cursor: 'pointer' }} onClick={() => this.deleteImg()}>poista</p>
                                                :
                                                <div style={{ display: 'line-block', position: 'relative' }}>
                                                    <p style={{ cursor: 'pointer' }} type="file" onChange={this.uploadImg}>Lataa kuva</p>
                                                    <Input onChange={this.uploadImg} type="file" name="logo" style={{ opacity: 0, height: '100%', position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }

                        {type === 'feedback' &&
                            <InstantFeedbackIcons
                                icons={this.props.category.iconList}
                                addIcon={this.addIcon}
                                removeIcon={this.removeIcon}
                                hover={this.hover}
                                hoverIndex={this.state.hoverIndex}
                                editIcon={this.setEditIndex}
                                editIndex={this.state.editIndex}
                                handleColor={this.handleColor}
                                handlePoints={this.handlePoints}
                                repeatPositiveButtonEvent={this.repeatPositiveButtonEvent}
                                repeatNegativeButtonEvent={this.repeatNegativeButtonEvent}
                                pointsInState={this.state.pointsInState}
                                onButtonRelease={this.onButtonRelease}
                                onDragEnd={this.onDragEnd}
                            />
                        }

                        {type === 'feedback' &&
                            < Nav tabs>
                                <NavItem className="question-edit-navitem">
                                    <NavLink
                                        className={this.state.activeTab === '1' ? ' active' : ''}
                                        onClick={() => this.setState({ activeTab: "1" })}
                                        style={{ paddingBottom: '0.4rem', paddingTop: '0.4rem' }}
                                    >
                                        Perustiedot
                                </NavLink>
                                </NavItem>
                                <NavItem className="question-edit-navitem">
                                    <NavLink
                                        className={this.state.activeTab === '2' ? ' active' : ''}
                                        onClick={() => this.setState({ activeTab: "2" })}
                                        style={{ paddingBottom: '0.4rem', paddingTop: '0.4rem' }}
                                    >
                                        Palaute ja raja-arvot
                                </NavLink>
                                </NavItem>
                            </Nav>
                        }
                        <TabContent activeTab={this.state.activeTab} style={{ paddingTop: 0 }}>
                            <TabPane tabId="1">
                                <Row style={{ paddingTop: '2em' }}>
                                    <Col className="explanationEditor">
                                        <h5>{this.state.type === 'feedback' ? 'Kysymys' : 'Kuvaus'}</h5>
                                        {/* <textarea rows="5" className="categoryTextArea" value={this.state.categoryExplanation} placeholder="Anna Kartoituksen selite" onChange={(e) => this.setState({categoryExplanation: e.target.value})} /> */}
                                        <DebounceTextEditor
                                            editorId={`${this.state.editorId}`}
                                            content={this.props.category.text || ""}
                                            onTextChange={this.onCategoryTextChange}
                                            handleSave={this.props.category.original ? this.props.onCategoryModified : this.props.onSentSurveyCategoryModified}
                                            categoryId={this.props.category._id}
                                            surveyId={this.props.surveyId}
                                            type="categoryExplanation"
                                            readOnly={false}
                                            saveDraftjsImage={this.props.saveDraftjsImage}
                                            withHeadersEditor={this.state.type === 'feedback' ? true : null}
                                            spellCheck={this.props.spellCheckOn}
                                            connectionType={"surveys"}
                                            documentId={this.props.surveyId}
                                        />
                                        <small style={{ float: "right", color: "#000" }}>Voit lisätä tekstiin tyylejä ja linkkejä maalamalla tekstin osan, johon haluat näitä lisätä.</small>
                                    </Col>
                                </Row>

                            </TabPane>
                            <TabPane tabId="2">
                                <Row style={{ paddingTop: '2em' }}>
                                    <Col xl="12">
                                        <IconFeedbacks
                                            category={this.props.category}
                                            groupId={null}
                                            feedbacks={this.props.category.iconFeedbacks}
                                            handleSave={this.props.onCategoryModified}
                                            categoryId={this.props.category._id}
                                            surveyId={this.props.surveyId}
                                            saveDraftjsImage={this.props.saveDraftjsImage}
                                            onCategoryModified={this.props.onCategoryModified}
                                            original={true}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>

                    { type === 'feedback' &&
                        <Row style={ { marginTop: '2em' } }>
                            <Col className="tomHanksEditor">
                                <h5>Kiitosteksti</h5>
                                <p>Kiitosteksti näytetään kartoituksen yhteydessä viimeisen kysymyksen jälkeen.</p>
                                {/* <textarea rows="5" className="categoryTextArea" value={this.state.categoryExplanation} placeholder="Anna Kartoituksen selite" onChange={(e) => this.setState({categoryExplanation: e.target.value})} /> */ }
                                <DebounceTextEditor
                                    editorId={ `${ this.state.editorId }-surveyEndedText` }
                                    content={ this.props.category.surveyEndedText || "" }
                                    // onTextChange={ this.onSurveyEndedTextChange }
                                    handleSave={ this.props.category.original ? this.props.onCategoryModified : this.props.onSentSurveyCategoryModified }
                                    categoryId={ this.props.category._id }
                                    surveyId={ this.props.surveyId }
                                    type="categoryTomHanks"
                                    readOnly={ false }
                                    saveDraftjsImage={ this.props.saveDraftjsImage }
                                    spellCheck={ this.props.spellCheckOn }
                                    suggestions={ mentionSuggestions }
                                    connectionType={"surveys"}
                                    documentId={this.props.surveyId }
                                // placeholder="Syötä haluamasi kartoituksen kiitosteksti tähän. Kiitosteksti näytetään kartoituksen täyttäneelle henkilölle kartoituksen jälkeen."
                                />
                            </Col>
                        </Row>
                    }

                    </div>
                }
            </div>
        )
    }
}



const InstantFeedbackIcons = (props) => {
    const icons = props.icons;
    return (
        <Row>
            <Col xs="12" style={{ marginTop: '1em', marginBottom: '0.5em' }}>
                <DragDropContext onDragEnd={props.onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <Row>
                                    {icons && icons.length > 0 && icons.map((icon, i) => {
                                        let fontColor = icon.color ? icon.color : '#000'
                                        return (
                                            <Col xs="2" key={i}>
                                                <Draggable key={icon.id} index={i} draggableId={i.toString()} >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps} >

                                                            <div className="icon-box" onMouseEnter={() => props.hover(true, i)} onMouseLeave={() => props.hover(false, i)}>
                                                                <i style={{ fontSize: '4em', color: fontColor }} className={(props.hoverIndex === i || props.editIndex === i) ? ` ${icon.name} blur` : icon.name} ></i>

                                                                {(props.hoverIndex === i || props.editIndex === i) &&
                                                                    <div style={{ position: 'absolute', top: 0, height: '100%', width: '100%' }}>
                                                                        <div style={{ position: 'absolute', display: 'inline-block', height: '100%', width: '50%', right: 0, fontSize: '3em' }}><i className="far fa-trash-alt" onClick={() => props.removeIcon(i, icon)}></i></div>
                                                                        <div style={{ position: 'absolute', display: 'inline-block', height: '100%', width: '50%', left: 0, fontSize: '3em' }} >
                                                                            <Popup
                                                                                trigger={(open) => (!open ? <i className="fa fa-edit" onClick={() => props.editIcon(i)}></i> : <i className=" fas fa-times" onClick={() => props.editIcon(i)}></i>)}
                                                                                position="bottom left"
                                                                                closeOnDocumentClick
                                                                            >
                                                                                <div style={{ margin: '0.2em' }}>
                                                                                    <Row>
                                                                                        <Col style={{ textAlign: 'left' }}>
                                                                                            <h5 style={{ fontWeight: 600 }}>Väri</h5>
                                                                                            <ColorPicker index={i} handleColor={props.handleColor} color={icon.color ? icon.color : '#000'} />
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <hr />
                                                                                    <Row>
                                                                                        <Col style={{ textAlign: 'left' }}>
                                                                                            <h5 style={{ fontWeight: 600 }}>Pisteet</h5>
                                                                                            <CustomPoints
                                                                                                handlePoints={props.handlePoints}
                                                                                                index={i}
                                                                                                value={icon.points}
                                                                                                pointsInState={props.pointsInState !== null ? props.pointsInState : icon.points}
                                                                                                points={icon.points ? icon.points : 0}
                                                                                                repeatPositiveButtonEvent={props.repeatPositiveButtonEvent}
                                                                                                onButtonRelease={props.onButtonRelease}
                                                                                                repeatNegativeButtonEvent={props.repeatNegativeButtonEvent} />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Popup>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                                {provided.placeholder}
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Col >
            <Col xs="12">
                <Label className="lighter-text">Lisää uusi valinta</Label>
                <div>
                    <FontIconPicker
                        isMulti={false}
                        closeOnSelect={true}
                        icons={iconList}
                        onChange={(val) => props.addIcon(val)}
                        noSelectedPlaceholder="Valitse ikoni "
                    />
                </div>
            </Col>
        </Row >
    )
}

const IconDeletionModal = props => {
    return (
        props.icon !== null &&
        <div>
            <Modal isOpen={props.modal} toggle={props.toggle} centered={true}>
                <ModalHeader toggle={props.toggle}>Feedback iconin poisto</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="icon-box" style={{ width: '50%' }}>
                            <i style={{ fontSize: '4em', color: props.icon.color }} className={props.icon.name}>
                            </i>
                        </div>
                    </div>
                    Oletko aivan varma kyseisen iconin poistosta? <b>Iconin poisto on pysyvä.</b>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={props.toggle}>Peruuta</a>{' '}
                    <button className="small-white-button wide-button swb-hover" style={{ backgroundColor: '#ed5652', color: '#fff' }} onClick={() => props.confirm(props.selectedIconIndex)}>Poista</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

const CustomPoints = props => {
    return (
        <Row className="customPointsPicker">
            <Col style={{ paddingRight: 0 }}>
                <i className="material-icons" onMouseDown={() => props.repeatNegativeButtonEvent(parseInt(props.points))} onMouseUp={() => props.onButtonRelease(props.index)}>remove</i>
            </Col>
            <Col style={{ paddingRight: 0, paddingLeft: 0 }}>
                <p style={{ height: '100%', textAlign: 'center' }} className="customPointsPickerInput" autoFocus
                    //onBlur={(e) => props.handlePoints(e.target.value, props.index)}
                    readOnly
                // onBlur={() => props.changeToInput(props.index, props.points)} 
                >{props.pointsInState ? props.pointsInState : 0}</p>

            </Col>
            <Col>
                <i className="material-icons" onMouseDown={() => props.repeatPositiveButtonEvent(parseInt(props.points))} onMouseUp={() => props.onButtonRelease(props.index)} >add</i>
            </Col>
        </Row>
    )
};

const mapStateToProps = state => {
    return {
        modulesInUse: state.authentication.modulesInUse
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSave: (params) => dispatch(ActionCreator.saveCategory(params)),
        onCategoryModified: (params) => dispatch(ActionCreator.modifyCategory(params)),
        onSentSurveyCategoryModified: (params) => dispatch(ActionCreator.modifySentSurveyCategory(params)),
        uploadImg: (params) => dispatch(ActionCreator.uploadImg(params)),
        getEnterpriseEmails: () => dispatch(ActionCreator.getEnterpriseEmails())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEdit);
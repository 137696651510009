import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane, Input } from 'reactstrap'
import { confs } from '../../../modules/config'
import qlikImg1 from '../../../assets/qlik-info-1.png'
import qlikImg2 from '../../../assets/qlik-info-2.png'

export const APIHandler = ({ hubSpot, qlikList, openAI, saveApiKey }) => {
    const [showNewInput, setShowNewInput] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteStuff, setDeleteStuff] = useState({})


    const removeKey = () => {
        saveApiKey({
            apiKey: deleteStuff
        });
        setShowConfirmation(false)
    }


    const handleApiKeySave = (apiKey, type, business) => {
        if (apiKey !== "") {
            saveApiKey({
                apiKey: {
                    target: type,
                    apiKey: apiKey,
                    business: business || '',
                    type: "add"
                }
            });
            setShowNewInput(!showNewInput);
        }
    }

    const deleteApiKey = (key, target) => {
        setDeleteStuff({ target: target, apiKey: key, type: "remove" })
        setShowConfirmation(true)
    }

    return (
        <>
            {showConfirmation && <ConfirmationModal isOpen={showConfirmation} target={deleteStuff.target} confirm={removeKey} toggle={() => setShowConfirmation(false)} />}
            <div>
                
                <label htmlFor="keys">API-Avaimet
                <i className="fas fa-info-circle apiKey-info"
                        title="Toistaiseksi tähän voi lisätä vain HubSpot API-avaimen tai Qlik ID:n"
                        style={{ paddingLeft: 5, paddingRight: 5 }}></i>:
                </label>
                <span style={{ marginLeft: '8em' }}>
                    <button id="apiKeys" name="apiKeys" className="small-white-button lighter-text wide-button deleteApi" style={{ marginRight: '.5em', pointerEvents: 'none' }}>
                        <i className="fas fa-key"></i><span style={{ paddingLeft: '0.5em' }}>YTJ-API</span>
                    </button>
                    {(hubSpot && hubSpot !== "deleted") &&
                        <button id="apiKeys" name="apiKeys" className="small-white-button lighter-text wide-button deleteApi" style={{ marginRight: '.5em' }} onClick={() => deleteApiKey(hubSpot, "HubSpot")}>
                            <i className="fab fa-hubspot"></i><span style={{ paddingLeft: '0.5em' }}>HubSpot-API</span>
                        </button>
                    }
                    {(qlikList && qlikList.map((qlik, i) => {
                        return <button id="apiKeys" name="apiKeys" key={i} className="small-white-button lighter-text wide-button deleteApi" style={{ marginRight: '.5em' }} onClick={() => deleteApiKey(qlik.id, "Qlik")}>
                            <i className="fas fa-key"></i><span style={{ paddingLeft: '0.5em' }}>Qlik: {qlik.business.label}</span>
                        </button>
                    })
                    )} 
                    {(openAI && openAI !== "deleted") &&
                        <button id="apiKeys" name="apiKeys" className="small-white-button lighter-text wide-button deleteApi" style={{ marginRight: '.5em' }} onClick={() => deleteApiKey(openAI, "OpenAI")}>
                            <i className="fas fa-key"></i><span style={{ paddingLeft: '0.5em' }}>OpenAI-API</span>
                        </button>
                    }
                    <button
                        style={{position:"absolute",marginLeft:"auto", marginRight: '1.5em', right:0}}
                        id="apiKeys"
                        name="apiKeys"
                        className="small-white-button lighter-text wide-button"
                        onClick={() => setShowNewInput(!showNewInput)}
                        >
                        <span>{showNewInput ? "Peruuta" : "Lisää uusi"}</span>
                    </button>   
                </span>
            </div>
            {showNewInput &&
                        <APIKeyInput label={"API Key:"} saveKey={handleApiKeySave} />
                    }
        </>
    )
}

export const APIKeyInput = ({ label, saveKey }) => {
    const [apiKey, setApiKey] = useState("");
    const [selectedBusiness, setSelectedBusiness] = useState({})
    const [clientList, setClientList] = useState([]);
    const [currentTab, setCurrentTab] = useState('hubspot')
    const [qlikModal, setQlikModal] = useState(false)
    const [openAIModal, setOpenAIModal] = useState(false)

    useEffect(() => {
        //Fetch client list
        let payload = {
            jwtToken: window.sessionStorage.getItem("token")
        }

        fetch(confs.url + 'sender/get/getCompanies', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(response.businessList){
                    let list = response.businessList.filter((item) => item.value !== 'anon')
                    setClientList(list)
                }
            }).catch(error => {

            })
    }, [])


    return (
        <FormGroup>
            <QlikInfoModal toggle={() => setQlikModal(!qlikModal)} isOpen={qlikModal} />
            <OpenAIModal toggle={ ()=> setOpenAIModal(!openAIModal)} isOpen={openAIModal} />
            <Row>
                <Nav tabs style={{ width: '100%', textAlign: 'center' }}>
                    <Col md="2" style={{ padding: 0 }}>
                        <NavItem style={{ width: '100%' }}>
                            <NavLink
                                className={currentTab === 'hubspot' ? 'active' : ''}
                                onClick={() => { setCurrentTab('hubspot') }}
                            >
                                HubSpot
                                                                                            </NavLink>
                        </NavItem>
                    </Col>
                    <Col md="2" style={{ padding: 0, textAlign: 'center' }}>
                        <NavItem style={{ width: '100%' }} >
                            <NavLink
                                className={currentTab === 'qlik' ? 'active' : ''}
                                onClick={() => { setCurrentTab('qlik') }}
                            >
                                Qlik
                                                                                    </NavLink>
                        </NavItem>
                    </Col>
                    <Col md="2" style={{ padding: 0, textAlign: 'center' }}>
                        <NavItem style={{ width: '100%' }} >
                            <NavLink
                                className={currentTab === 'openAI' ? 'active' : ''}
                                onClick={() => { setCurrentTab('openAI') }}
                            >
                                OpenAI
                                                                                    </NavLink>
                        </NavItem>
                    </Col>
                </Nav>
            </Row>
            <TabContent activeTab={currentTab}>
                <TabPane tabId="hubspot">
                    <Row>
                        <Col xs="12">
                            <Label htmlFor={label}><b>{label}</b></Label><br />
                            <input type="text" id={label} name={label} value={apiKey} onChange={(e) => setApiKey(e.target.value)} className="input-bar" style={{ width: '100%', marginBottom: '0.8em' }}></input><br />
                            <button
                                className="small-white-button lighter-text wide-button"
                                type="onSubmit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveKey(apiKey, 'HubSpot', selectedBusiness)
                                }}>Tallenna</button>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="qlik">
                    <Row>
                        <Col xs="12">
                            <div onClick={() => setQlikModal(true)} style={{ marginBottom: '1em', cursor: 'pointer' }}><p><i className="fas fa-info" style={{ marginRight: '.5em' }}></i>Ohjeet</p></div>
                            <div style={{ maxWidth: '20em' }} >
                                <Label className="smaller-text">Yritys</Label>
                                <Select
                                    value={selectedBusiness}
                                    onChange={setSelectedBusiness}
                                    options={clientList}
                                    //filterOption={(opt) => opt.value !== 'anon'}
                                    placeholder="Etsi yritys"
                                    isDisabled={false}
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    style={{ maxWidth: '20em' }}
                                    classNamePrefix="select"
                                />
                            </div>
                            <Label htmlFor={label} style={{ marginTop: '1em' }}><b>Qlik ID:</b></Label><br />
                            <input type="text" id={label} name={label} value={apiKey} onChange={(e) => setApiKey(e.target.value)} className="input-bar" style={{ width: '100%', marginBottom: '0.8em' }}></input><br />
                            <button
                                className="small-white-button lighter-text wide-button"
                                type="onSubmit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveKey(apiKey, 'Qlik', selectedBusiness)
                                }}>Tallenna</button>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="openAI">
                    <Row>
                        <Col xs="12">
                        <div onClick={() => setOpenAIModal(true)} style={{ marginBottom: '1em', cursor: 'pointer' }}><p><i className="fas fa-info" style={{ marginRight: '.5em' }}></i>Ohjeet</p></div>
                            <Label htmlFor={label}><b>{label}</b></Label><br />
                            <input type="text" id={label} name={label} value={apiKey} onChange={(e) => setApiKey(e.target.value)} className="input-bar" style={{ width: '100%', marginBottom: '0.8em' }}></input><br />
                            <button
                                className="small-white-button lighter-text wide-button"
                                type="onSubmit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveKey(apiKey, 'OpenAI', selectedBusiness)
                                }}>Tallenna</button>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </FormGroup>
    )
}

const QlikInfoModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered size="xl">
            <ModalHeader>Ohjeet Qlik Iframen käyttöön</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="7">
                        <h4 style={{ marginBottom: '.5em' }}>Luo uusi Content Security Policy portaaliin ja etusivulle</h4>
                        <p>{`1. Luo uusi käytäntö osoitteessa https:// XXXXXXXX .eu.qlikcloud.com/console/csp/ `}</p>
                        <p>{'2. Origin: https://portal.everbetter.fi '}</p>
                        <p>{'3. Directive: frame-ancestors'}</p>
                        <p>4. Tallenna</p>
                        <p>5. Etusivun lomakkeita varten toista kohdat 2, 3 ja 4 lisäämällä: https://app.everbetter.fi</p>
                    </Col>
                    <Col md="5">
                        <img src={qlikImg1} style={{ border: '1px solid #40404040', maxWidth: '100%' }} ></img>
                    </Col>
                </Row>
                <Row style={{ marginTop: '4em', marginBottom: '.5em' }}>
                    <Col md="7">
                        <h4 >Luo uusi ID</h4>
                        <p>{'1. Luo uusi ID osoitteessa https:// XXXXXXXX .eu.qlikcloud.com/console/web-integrations/'}</p>
                        <p>{'2. Lisää domain https://portal.everbetter.fi'}</p>
                        <p>{'3. Mikäli iframea käytetään etusivun lomakkeissa, lisää domain https://app.everbetter.fi'}</p>
                        <p>3. Tallenna</p>
                        <p>5. Kopioi ja tallenna ID EverBetteriin valitsemasi yrityksen alle</p>
                    </Col>
                    <Col md="5">
                        <img src={qlikImg2} style={{ border: '1px solid #40404040', maxWidth: '100%' }} ></img>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button onClick={props.toggle} className="small-white-button swb-hover" style={{ width: '100%' }}>Sulje</button>
            </ModalFooter>
        </Modal>
    )
}

const OpenAIModal = (props) => {

    return(
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered size="xl">
            <ModalHeader>Ohjeet OpenAI:n käyttöönottoon</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <p>{`1. (Jos ei vielä tehty) Luo tunnukset ja kirjaudu osoitteessa: `} <a href='https://openai.com/' target="_blank">https://openai.com/</a></p>
                        <p>{'2. Navigoi sivulle API'}</p>
                        <p>{'3. Sivun oikeassa yläreunassa löytyy omat asetukset (personal) => valitaan "View API keys"'}</p>
                        <p>4. Luodaan uusi API-avain painikkeella "Create new secret key"</p>
                        <p>5. Nimetään luotava avain (esim. MyKey)</p>
                        <p>{'6. Kopioi luotu avain ja liitä se EverBetteriin "Api-Avaimet" => "OpenAI"'}</p>
                        <p>7. Päivitä selaimen sivu (esim. painikkeella f5)</p>
                        <p>8. Voit nyt käyttää OpenAI-tekoälyä EverBetter-lomakkeiden tekstikappaleissa {' => kappaleen asetukset => "Näytä OpenAI-lohko"'}</p>
                        <p>9. Ohjeita tekoälyn käyttöön löytyy OpenAI-lohkosta info-painiketta painamalla</p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button onClick={props.toggle} className="small-white-button swb-hover" style={{ width: '100%' }}>Sulje</button>
            </ModalFooter>
        </Modal>
    )
}

const ConfirmationModal = (props) => {
    const [deleteText, setDeleteText] = useState("")
    const toggleModal = () => {
        setDeleteText("")
        props.toggle()
    }

    const confirmDelete = () => {
        if(deleteText === "poista"){
            setDeleteText("")
            props.confirm()
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={() => toggleModal()} centered>
            <ModalHeader>{props.target === "HubSpot"?"HubSpot API":"Qlik Api"}</ModalHeader>
            <ModalBody>
                <p>Oletko varma, että haluat poistaa {props.target === "HubSpot"?"HubSpot API":"Qlik Api"}-avaimen? <b>Toimintoa ei voi peruuttaa!</b></p>
                <div style={{ marginTop: '1em' }}>
                    <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                    <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                    <div className="email-check-box">
                        {deleteText === "poista"?
                            <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                            :
                            <i className="fas fa-times" style={{ color: 'red' }}></i> 
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <a onClick={() => toggleModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                <button onClick={() => confirmDelete()} className="small-white-button wide-button swb-hover" style={{backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
            </ModalFooter>
        </Modal>
    )
}
import * as ActionTypes from '../../../Components/comparisonTool/actions/ActionTypes';

const initialState = {
    firstSurveys: [],
    secondSurveys: [],
    comparisonReport: [],
    firstColumnNames: [],
    secondColumnNames: [],
    fetching: false
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.FIRST_COMPANY_SURVEY_LIST:
            return {
                ...state,
                firstSurveys: [...action.payload]
            }
        case ActionTypes.SECOND_COMPANY_SURVEY_LIST:
            return {
                ...state,
                secondSurveys: [...action.payload]
            }
        case ActionTypes.SET_COMPARISON_REPORT:
                return {
                    ...state,
                    comparisonReport: [
                        ...action.payload
                    ]
                }
        case ActionTypes.SET_COMPARISON_NAMES: 
            return {
                ...state,
                firstColumnNames: action.payload.firstNames,
                secondColumnNames: action.payload.secondNames
            }
        case ActionTypes.FETCH_STATUS:
            return {
                ...state,
                fetching: !state.fetching
            }
        default:
            return state
    }
}

export default reducer;
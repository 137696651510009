import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import {Row, Col} from 'reactstrap';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

//am4core.useTheme(am4themes_animated);
am4core.options.onlyShowOnViewport = true;

let dateAxis;
let valueAxis;

const shadeColor = (color, percent) => {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

export default class MegaUltraChart extends Component {
    componentDidMount() {
        let chart = am4core.create(`${this.props.chartId}-chartdiv`, am4charts.XYChart);
        chart.data = [...this.props.chartData.data];
        chart.properties.paddingLeft = 0;
        dateAxis = chart.xAxes.push(new am4charts.ValueAxis());
        dateAxis.max = this.props.chartData.data.length - 1;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.disabled = true;
        this.props.chartData.data.map((item, index) => {
            this.createXGrid(item.label);
        })
        
        // Create value axis
        valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //Disable normal templates for labels in yAxe
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        let gradient = new am4core.LinearGradient();
        // gradient.addColor(am4core.color(shadeColor(this.props.chartData.chartColor, 10)));
        gradient.addColor(am4core.color(this.props.chartData.chartColor));
        
        
        chart.background.fill = gradient;
        chart.background.opacity = 1;
        this.createGrid(0);
        if(this.props.chartGoal !== undefined && this.props.chartGoal[0] > 0) {
            this.createGrid(this.props.chartGoal);
        }
        
        this.createGrid(100);
        valueAxis.min = 0;
        valueAxis.max = 100;
        
        // Create series
        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.valueY = "value";
        lineSeries.dataFields.valueX = "label";
        lineSeries.name = this.props.chartName;
        lineSeries.strokeWidth = 3;
        lineSeries.propertyFields.stroke = "lineColor";
        lineSeries.propertyFields.fill = "lineColor";
        lineSeries.showOnInit = false
        chart.maskBullets = false;
        // Add simple bullet
        let bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.tooltipText = "Tulos: [bold]{value}[/]\nVastauksia: [bold]{answers}[/]\nKartoitus: [bold]{category}[/]";
        lineSeries.tooltip.getFillFromObject = false;
        lineSeries.tooltip.background.fill = am4core.color("#FFF");
        lineSeries.tooltip.autoTextColor = false;
        lineSeries.tooltip.label.fill = am4core.color("#404040")
        //lineSeries.tooltip.text
        bullet.disabled = true;
        bullet.propertyFields.disabled = "disabled";
        
        let image = bullet.createChild(am4core.Circle);
        image.width = 10;
        image.height = 10;
        image.fill = am4core.color("#FFF");

        image.horizontalCenter = "middle";
        image.verticalCenter = "middle";

        // Add a guide
        if(this.props.chartGoal !== undefined && this.props.chartGoal[0] > 0) {
            let range = valueAxis.axisRanges.create();
            range.value = this.props.chartGoal;
            range.grid.stroke = am4core.color("#404040");
            range.grid.strokeWidth = 1;
            range.grid.strokeOpacity = 1;
            range.label.inside = true;
            range.label.text = "Tavoite";
            range.label.fill = range.grid.stroke;
            range.label.verticalCenter = "bottom";
        }
        this.chart = chart;

    }

    createGrid = (value) => {
        let range = valueAxis.axisRanges.create();
        range.value = value;
        range.label.text = "{value}";
    }

    createXGrid = (value) => {
        let range = dateAxis.axisRanges.create();
        range.value = value;
        range.label.text = "{value}";
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <Col className="px-0" id={`${this.props.chartId}-chartdiv`} style={{ height: "200px" }}></Col>
        )
    }
}

import React, { Component } from 'react';

import { Row, Col, Input, FormFeedback } from 'reactstrap';
import * as BasicHelper from '../../../Utilities/HelperFunctions/helper';
import '../companylink.css';
import { connect } from 'react-redux';
import * as ActionCreator from '../actions/ActionCreator';
import MultiSelect from "@kenshooui/react-multi-select";
import {requestStatus} from '../actions/ActionCreator'
import { convertFromRaw, ContentState, EditorState, convertToRaw } from 'draft-js';
import { NotificationManager } from 'react-notifications';

const MegaLoaderSpinner = () => {
    return (
        <Row className="loadingSpinnerRow">
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const emptyBlock = () => {
    let contentState = ''
    contentState = ContentState.createFromText("")

    let editorState = EditorState.createWithContent(contentState);
    return convertToRaw(editorState.getCurrentContent())
}

class CompanyRegistrationV2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yTunnus: "",
            yTunnusCorrect: false,
            postNumber: "",
            companyAddress: "",
            targetEmail: "",
            companyName: "",
            emailList: [],
            selectedEmails: [],
            emailContent: emptyBlock(),
            emailPlainContent: "",
            addCounter: 0,
            emailReady: false,
            emailToAdd: "",
            status: {}
        }
        this.senderRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.targetCompany !== this.props.targetCompany) {
            let object = {
                postNumber: this.props.targetCompany.postcode,
                companyAddress: this.props.targetCompany.address,
                companyName: this.props.targetCompany.businessName,
                emailList: this.props.targetCompany.emailList
            }
            this.setState(object);
            /*setTimeout(() => {
                this.scrollToMyRef();
            }, 400);*/
        }

        if(this.props.requestStatus === "finished" && this.state.startingRequest) {
            let newState = {
                yTunnus: "",
                yTunnusCorrect: false,
                postNumber: "",
                companyAddress: "",
                targetEmail: "",
                companyName: "",
                emailList: [],
                selectedEmails: [],
                emailContent: emptyBlock(),
                emailPlainContent: "",
                startingRequest: false,
            }

            this.setState(newState);
        }

        if(this.props.requestStatus === "EXISTING_EMAILS") {
            this.props.onRequestFinished({});
            this.setState({emailList: this.props.strippedList, selectedEmails: this.props.strippedList});
        }

        if(this.props.resendToCompany !== null && this.props.resendToCompany.businessID !== this.state.yTunnus) {
            const { businessID, address, postCode, businessName } = this.props.resendToCompany
            
            this.setState({ postNumber: postCode, companyAddress: address, companyName: businessName }, () => {
                if(BasicHelper.checkYTunnus( businessID )) {
                    this.props.onYTunnusCorrect({ yTunnus: businessID })
                    this.setState({ yTunnusCorrect: true, yTunnus: businessID })
                }
            })
        }
    }

    scrollToMyRef = () => {
        this.senderRef.current.parentElement.scroll({ top: this.senderRef.current.offsetTop, left: 0, behavior: 'smooth' })
    }

    // validateEmail = (email) => {
    //     let emailObject = {
    //         id: email,
    //         label: email
    //     };

    //     var regex = /[^@]+@[^\.]+\..+/;

    //     if (regex.test(email)) {
    //         if (this.state.emailList.filter(email => email.label === emailObject.label).length === 0) {
    //             return true;
    //         }
    //     } else {
    //         return false;
    //     }
    // }

    handleSenderEmailField = (rawEditorState) => {
        let plaintextEmail = convertFromRaw(rawEditorState).getPlainText()
        plaintextEmail = plaintextEmail.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        this.setState({ emailContent: rawEditorState, emailPlainContent: plaintextEmail });
    }

    addEmailToList = (email) => {
        let emailObject = {
            id: email,
            label: email
        };

        if (this.state.emailList.filter(email => email.label === emailObject.label).length === 0) {
            let emailList = [
                ...this.state.emailList,
                emailObject
            ]

            let selectedItems = [
                ...this.state.selectedEmails,
                emailObject
            ]

            this.setState({ emailList: emailList, selectedEmails: selectedItems, emailContent: emptyBlock(), emailPlainContent: "", emailEditorId: `${emailList.length}-EmailEditor` });
        }
    }

    handleEmailChange = email => {
        let check = this.validateEmail(email.target.value)
        if (check.status) {
            this.setState({ emailReady: true, emailToAdd: email.target.value, status: check });
        } else {
            this.setState({ emailReady: false, emailToAdd: email.target.value, status: check });
        }
    }

    handleEmailAdd = event => {
        if (event.key === 'Enter') {
            if (this.state.emailReady) {
                let emailObject = {
                    id: this.state.emailToAdd,
                    label: this.state.emailToAdd
                };

                let emails = [
                    ...this.state.emailList,
                    emailObject
                ];

                let selectedEmails = [
                    ...this.state.selectedEmails,
                    emailObject
                ];

                this.setState({ emailList: emails, selectedEmails: selectedEmails, emailToAdd: "", emailReady: false, status: {} });
            }
        }
    }

    validateEmail = (email) => {
        let emailObject = {
            id: email,
            label: email
        };

        var regex = /[^@]+@[^\.]+\..+/; /*/^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;*/
        /* 	    
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/*/
        if (regex.test(email)) {
            if (this.state.emailList.filter(email => email.label === emailObject.label).length === 0) {
                return {
                    status: true,
                    feedback: "Lisää sähköposti painamalla 'Enter'-näppäintä"
                }
            } else {
                return {
                    status: false,
                    feedback: "Sähköposti on jo listalla"
                };
            }
        } else {
            return {
                status: false,
                feedback: "Syötä sähköpostiosoite oikeassa muodossa"
            }
        }
    }

    handleYTunnus = values => {
        if (values.length <= 9) {
            let object = {
                yTunnus: values
            }

            if (values.length === 9) {
                if(BasicHelper.checkYTunnus(values)) {
                    this.props.onYTunnusCorrect(object);
                    object.yTunnusCorrect = true;
                } else {
                    object.yTunnusCorrect = false;
                }
            }
            this.setState(object);
        }
    }

    handlePostNumber = postNumber => {
        if (postNumber.length <= 5 && !isNaN(postNumber)) {
            this.setState({ postNumber: postNumber });
        } else if (postNumber === "") {
            this.setState({ postNumber: postNumber });
        }
    }

    handleBusinessName = businessName => {
        this.setState({ companyName: businessName });
    }

    handleAddress = address => {
        this.setState({ companyAddress: address })
    }

    handleEmail = email => {
        this.setState({ targetEmail: email });
    }

    handleEmailSelections = selectedItems => {
        this.setState({ selectedEmails: selectedItems });
    }

    requestCollaboration = ( withoutUsers = false ) => {
        if(this.state.yTunnus.length > 0 && this.state.companyName.length > 1 && this.state.companyName !== "Nimetön yritys"){
            let payload = {
                yTunnus: this.state.yTunnus,
                businessName: this.state.companyName,
                address: this.state.companyAddress
            }
            
            if( !withoutUsers ) {
                payload.emailList = this.state.selectedEmails
                payload.createWithoutUsers = false
            } else {
                payload.emailList = []
                payload.createWithoutUsers = true
            }

            this.setState({startingRequest: true});
            this.props.resetResend( null )
            this.props.onRequest(payload);
        } else {
            NotificationManager.error('Tarkasta yrityksen nimi!', 'Virhe', 5000)
        }
    }

    addEmailToList = (email) => {
        let emailObject = {
            id: email,
            label: email
        };

        if(this.state.emailList.filter(email => email.label === emailObject.label).length === 0) {
            let emailList = [
                ...this.state.emailList,
                emailObject
            ]

            this.setState({emailList: emailList, emailContent: emptyBlock(), emailPlainContent: "", emailEditorId: `${emailList.length}-EmailEditor`});
        }
    }

    handleEmailChange = email => {
        let check = this.validateEmail(email.target.value)
        if(check.status) {
            this.setState({emailReady: true, emailToAdd: email.target.value, status: check});
        } else {
            this.setState({emailReady: false, emailToAdd: email.target.value, status: check});
        } 
    }

    handleEmailAdd = event => {
        if(event.key === 'Enter') {
            if(this.state.emailReady) {
                let emailObject = {
                    id: this.state.emailToAdd,
                    label: this.state.emailToAdd
                };

                let emails = [
                    ...this.state.emailList,
                    emailObject
                ];

                let selectedEmails = [
                    ...this.state.selectedEmails,
                    emailObject
                ];

                this.setState({emailList: emails, selectedEmails: selectedEmails, emailToAdd: "", emailReady: false, status: {}});
            }
        }
    }

    validateEmail = (email) => {
        let emailObject = {
            id: email,
            label: email
        };

        var regex = /[^@]+@[^\.]+\..+/; /*/^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;*/
        /* 	    
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/*/
        if(regex.test(email)) {
            if(this.state.selectedEmails.length < 5 && this.state.emailList.filter(email => email.label === emailObject.label).length === 0) {
                return {
                    status: true,
                    feedback: "Lisää sähköposti painamalla 'Enter'-näppäintä"
                }
            } else if(this.state.selectedEmails.length >= 5) {
                return {
                    status: false,
                    feedback: "Voit lisätä ainoastaan viisi sähköpostia toistaiseksi postituslistalle"
                }
            } else {
                return {
                    status: false,
                    feedback: "Sähköposti on jo listalla"
                };
            }
        } else {
            return {
                status: false,
                feedback: "Syötä sähköpostiosoite oikeassa muodossa"
            }
        }
    }


    render() {
        return (
            <div className={this.props.inModal? "add-client-in-modal" : " add-client-container"} style={{ }}>
                <div className="client-box-header bigger-text " style={{ display: 'flex', alignItems: 'center', borderRadius:' 5px 5px 0 0', borderBottom: 'none' }}>
                    <p>Lisää asiakas</p>
                </div>
                <div xs="12" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                    <Row style={{ height: '500px', overflow: 'auto' }}>
                        <Col xl="12" style={{}}>
                            {(this.props.fetching) && <MegaLoaderSpinner />}
                            {this.state.yTunnus.length < 9 ? (
                                <Input id="collab-business-id" className="YTunnusInput" type="text" value={this.state.yTunnus} placeholder="Anna Y-tunnus" onChange={(e) => this.handleYTunnus(e.target.value)}></Input>
                            ) : (
                                    this.state.yTunnusCorrect ? (
                                        <Input id="collab-business-id" className="YTunnusInput-correct" type="text" value={this.state.yTunnus} placeholder="Anna Y-tunnus" onChange={(e) => this.handleYTunnus(e.target.value)}></Input>
                                    ) : (
                                        <Input id="collab-business-id" className="YTunnusInput-false" type="text" value={this.state.yTunnus} placeholder="Anna Y-tunnus" onChange={(e) => this.handleYTunnus(e.target.value)}></Input>
                                    )
                                )}

                            {this.state.yTunnusCorrect &&
                                <Row>
                                    <Col xl="12" style={{ paddingTop: "1.5rem" }}>
                                        <Input id="collab-business-name" className="companyRegistrations" type="text" value={this.state.companyName} placeholder="Anna yrityksen nimi" onChange={(e) => this.handleBusinessName(e.target.value)}></Input>
                                    </Col>
                                    <Col xl="12" style={{ paddingTop: "1.5rem" }}>
                                        <Input id="collab-business-address" className="companyRegistrations" type="text" value={this.state.companyAddress} placeholder="Anna yrityksen osoite" disabled={this.props.targetCompany.address !== ""} onChange={(e) => this.handleAddress(e.target.value)}></Input>
                                    </Col>
                                    {/*<Col xl="12" style={{paddingTop: "1.5rem"}}>
                                    <Input className="companyRegistrations" type="number" value={this.state.postNumber} placeholder="Anna yrityksen postinumero" onChange={(e) => this.handlePostNumber(e.target.value)} disabled={this.props.targetCompany.postcode !== ""}></Input>
                                </Col>*/}


                                    <Col xl="12" style={ { paddingTop: "1.5rem" } }>
                                        <button id="noemail-collab-button" className="small-white-button" style={ { width: '100%' } } onClick={ () => this.requestCollaboration( true ) }>Lisää yritys</button>
                                    </Col>

                                    
                                    {/*<Col xl="12">
                                        <p className="senderSteps">Lisää sähköposti</p>
                                        <div style={{paddingTop: "0.8rem"}}>
                                            <span>
                                                <Input valid={this.state.emailReady} invalid={!this.state.emailReady} value={this.state.emailToAdd} onChange={this.handleEmailChange} type="text" placeholder="Sähköpostiosoite" onKeyPress={this.handleEmailAdd}/>
                                                <FormFeedback valid={this.state.emailReady}>{this.state.status.feedback}</FormFeedback>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xl="12" style={{paddingTop: "1.5rem"}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <p className="senderSteps">Kenelle yhteistyöpyyntö lähetetään</p>
                                            <span className="info">{this.state.selectedEmails.length}</span>
                                        </div>
                                        <MultiSelect
                                            items={this.state.emailList}
                                            selectedItems={this.state.selectedEmails}
                                            onChange={this.handleEmailSelections}
                                            showSearch={this.state.emailList.length > 0 ? true : false}
                                            showSelectAll={false}
                                            showSelectedItems={false}
                                            wrapperClassName="multi-selector-compppany-register"
                                        />
                                    </Col>
                                    {this.state.selectedEmails.length > 0 &&
                                        <Col xl="12" style={{ paddingTop: "1.5rem", paddingBottom: '1.5rem' }}>
                                            <button className="small-white-button" style={{ width: '100%' }} onClick={() => this.requestCollaboration()}>Lähetä</button> 
                                        </Col>
                                    }
                                    */}
                                </Row>
                            }
                            {/*<div ref={this.senderRef}></div>*/}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        targetCompany: state.collaborations.targetCompany,
        fetching: state.collaborations.fetchingData,
        requestStatus: state.collaborations.requestStatus,
        strippedList: state.collaborations.strippedList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onYTunnusCorrect: (params) => dispatch(ActionCreator.fetchCompanyInformation(params)),
        onRequest: (params) => dispatch(ActionCreator.requestPermission(params)),
        onRequestFinished: (params) => dispatch(requestStatus(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRegistrationV2);
import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalFooter, ModalBody, Input, Col, Row, Button, Label} from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import * as ActionCreator from '../../planBuilder/Actions/ActionCreator';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { motion } from 'framer-motion';
import Select from 'react-select';
import MultiSelect from "@kenshooui/react-multi-select";
import moment from 'moment'
import { TagHandler } from './TagHandler';
import { NotificationManager } from 'react-notifications';
import { EmptyProjectList } from './ProjectLayout';
import { ListHeaderSorter } from './ListHeaderSorter';

export const ViewList = ({ projectViews, history, projectId, projectName, setViewOptions, setView}) => {
    const [views, setViews] = useState([])
    const [search, setSearch] = useState("")
    const [selectedData, setSelectedData] = useState({})
    const [menuOpen, setMenuOpen] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [isDelete, setIsDelete] = useState()
    const [newViewModalOpen, setNewViewModalOpen] = useState(false)
    const [modifyModalOpen, setModifyModalOpen] = useState(false)
    const [selectedView, setSelectedView] = useState({})
    const [deleteText, setDeleteText] = useState("")
    const [sortedViews, setSortedViews] = useState([])
    const [sortOrder, setSortOrder] = useState(-1)
    const [orderBy, setOrderBy] = useState("modified")

    const handleListNameFilter = view => {
        const {name, planName, selectedBusiness, tags} = view

        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        else if(planName && planName.toUpperCase().includes(search.toUpperCase())) return true
        else if(selectedBusiness !== undefined) {
            const {value, label} = selectedBusiness
            if(label.toUpperCase().includes(search.toUpperCase())) {
                return true
            } 
        } 
        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }
        return false
    }

    const toggleModal = (data) => {
        setSelectedData(data) 
        setModalOpen(true)
    }

    const toggleDropdown = (y) => {
        if (menuOpen === y) {
            setMenuOpen(null)
        } else {
            setMenuOpen(y)
        }
    }

    useEffect(()=>{
        if(projectViews.length > 0){
            setViews(projectViews)
        }
    },[projectViews])

    const getCompareViews = async() => {
        let payload = {
            projectId:projectId
        }
        const response = await executeRequest('project/getCompareViews', payload)
        if(response.length > 0){
            setViews(response)
        }
    }
    
    const openSelected = async(viewId) => {
        let payload = {
            viewId: viewId
        }
        const response = await executeRequest('project/getSelectedView', payload)
        if(response.chosenExcels.length > 0){
            setViewOptions({
                chosenExcels: response.chosenExcels,
                selectedGroups: response.selectedGroups,
                viewName: response.viewName,
                viewTags: response.viewTags,
                selectedViewId:response._id,
                extras: response.extras,
                calcStyle: response.calcStyle
            })
            setView("view")
        }
    }

    const deleteView = async(viewId) => {
        if(deleteText === "poista"){
            let payload = {
                viewId: viewId
            }
            const response = executeRequest('project/deleteView', payload)

            if(response){
                NotificationManager.success('Vertailun poistaminen onnistui', 'Ilmoitus', 4000)
                setModalOpen(!modalOpen)
                getCompareViews()
            } else {
                NotificationManager.error('Vertailun poistossa tapahtui virhe', 'Virhe', 4000)
                setModalOpen(!modalOpen)
            }
        }
    }

    const toggleModifyModal = (view) => {
        setSelectedView({...view})
        setModifyModalOpen(!modifyModalOpen)
    }

    useEffect(()=>{
        if(views){
            setSortedViews(listSorter())
        }
    },[orderBy, sortOrder, views])

    const listSorter = () => {
        let sortedArray = [...views]
        let orderVal1 = sortOrder
        let orderVal2 = sortOrder === 1? -1 : 1

        if(orderBy === "viewName"){
            sortedArray.sort((a,b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase()) ? orderVal1 : orderVal2)
            return [...sortedArray]
        } else{
            if(orderBy !== "modified" && orderBy !== "created"){
                sortedArray.sort((a,b) => (a[orderBy] > b[orderBy]) ? orderVal1 : orderVal2)
                return [...sortedArray]
            }
            if(orderBy === "modified" || orderBy === "created"){
                sortedArray.sort((a,b) => (new Date(a[orderBy]) > new Date(b[orderBy])) ? orderVal1 : orderVal2)
                return [...sortedArray]
            }
        }
        return [...sortedArray]
    }

    const handleSortOrder = (attribute) => {
        if(orderBy !== attribute){
            setOrderBy(attribute)
            if(attribute === "viewName"){
                setSortOrder(1)
            } else {
                setSortOrder(-1)
            }
        } else {
            setSortOrder(sortOrder === -1? 1 : -1)
        }
    }

    let i = 0;

    return(  
        <>
            <Modal isOpen={modalOpen} toggle={() => (setModalOpen(!modalOpen), setDeleteText(""))}>
                <ModalHeader toggle={() =>  (setModalOpen(!modalOpen), setDeleteText(""))}>{selectedData && selectedData.viewName}</ModalHeader>
                <ModalBody>
                    <p>Oletko varma, että haluat poistaa vertailun? <b>Toimintoa ei voi peruuttaa!</b></p>
                    <div style={{ marginTop: '1em' }}>
                        <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                        <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                        <div className="email-check-box">
                            {deleteText === "poista"?
                                <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                :
                                <i className="fas fa-times" style={{ color: 'red' }}></i> 
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => (setModalOpen(!modalOpen), setDeleteText(""))} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => deleteView(selectedData._id)} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                </ModalFooter>
            </Modal>
            <NewViewModal
                setModalOpen={setNewViewModalOpen}
                modalOpen={newViewModalOpen}
                projectId={projectId}
                setView={setView}
                setViewOptions={setViewOptions}
            />
            <ModifyViewModal 
                setModifyModalOpen={setModifyModalOpen}
                modifyModalOpen={modifyModalOpen}
                viewOptions={selectedView}
                projectId={projectId}
                update={getCompareViews}
            />
            <div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ ease: 'easeIn', duration: 0.3 }}
                    exit={{ opacity: 0, x: -50 }}
                    key={"plans"}
                >
                    <div>
                        <Row className="tab-header bigger-text" style={{ display: 'flex', alignItems: 'center'}}>          
                            <Col>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1em' }}>
                                    <Input type="search" className="search-bar-open" placeholder={"Hae"} value={search} onChange={(event) => setSearch(event.target.value)} />
                                </div>
                            </Col>
                            <Col>
                                <button className="small-white-button wide-button project-survey-button" style={{ float: 'right' }} onClick={() => setNewViewModalOpen(!newViewModalOpen)} >
                                    Luo uusi vertailu
                                </button>
                            </Col>
                        </Row>
                        <Row style={{ fontSize:'0.9rem', marginBottom:'0.25em' }}>
                            <Col xs="4" style={{ cursor:'pointer', marginLeft:'1em'}}>
                                <ListHeaderSorter label={"Nimi"} name={"viewName"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder}/>
                            </Col>
                            <Col xs="4" style={{ cursor:'pointer', marginLeft:'-1em'}}>
                                <ListHeaderSorter label={"Muokattu"} name={"modified"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder} />
                            </Col>
                            <Col xs="3" style={{ marginLeft:'-1em'}}>Tagit</Col>
                            <Col></Col>
                        </Row>
                        <div>
                            {views && views.length > 0 ?
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "100%", height: '500px' }}
                                    className="custom-scrollbars"
                                >
                                    {sortedViews.length > 0 && sortedViews.filter(v => handleListNameFilter(v) || true).map((view, y)=>{
                                        //{sentSurveys.length > 0 && sentSurveys.filter(survey => statusFilter(survey)).map((sentSurvey, index) => {                        
                                        let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                        i++;
                                        return (
                                            <div className={oddEvenClass +" list-row"} style={{ padding: '1em', alignItems: 'center' }} key={y} onClick={() => openSelected(view._id)}>
                                                <Row className='plan-list-content'>
                                                    <Col xs="4">
                                                        <p title={view.viewName} className="content-text name-overflow-handler">{view.viewName}</p>
                                                    </Col >
                                                    <Col xs="4">
                                                        <p className="content-text" style={{ fontSize: '0.8rem' }}><span style={{color: 'red'}}>Muokattu:</span> {moment.utc(view.modified).format('D.M.YYYY  HH.mm')}</p>
                                                        <p className="content-text" style={{ fontSize: '0.8rem' }}>{view.modifiedBy}</p>
                                                    </Col >
                                                    <Col xs="3">
                                                        <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                                                            {view.viewTags && view.viewTags.slice(0, 4).map((tag, y) => {                        
                                                                    return(<div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="tag-display tag-text project-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                                })  
                                                            }                                                   
                                                            {view.viewTags && view.viewTags.length > 4 &&  
                                                                <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                                                    <i className="material-icons" title={tagsToMore(view.viewTags.slice(4,view.viewTags.length))}>more_horiz</i>
                                                                </div>
                                                            }  
                                                        </div>                                            
                                                    </Col>
                                                    <Col xs="1">
                                                        <Dropdown isOpen={menuOpen === y} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent:'center' }} onClick={(event) => event.stopPropagation()} toggle={() => toggleDropdown(y)}>
                                                            <DropdownToggle tag="div" style={{  float: 'right', fontSize: '1rem' }}>
                                                                <i className="fas fa-bars" title="Valikko"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={() => toggleModifyModal(view)}>Muokkaa</DropdownItem>
                                                                <DropdownItem onClick={() => toggleModal(view)}>Poista</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </Col>
                                                </Row>
                                            </div>                      
                                        )                       
                                    })}
                                </Scrollbars>
                                :
                                <EmptyProjectList listText={"Ei vertailuja"} />                       
                            }
                        </div>                   
                    </div>
                </motion.div>
            </div>
        </>
    )
}
const tagsToMore = (tags, slicer) => {
    var tagss = ""
    if(tags !== undefined ){
        tags.slice(slicer, tags.length).filter(t => tagss = tagss + "  " + t.label.toString())
        return(tagss)
    }
}

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi aihetta...",
        noItemsMessage: "Valitse aihe",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
}

const NewViewModal = ({modalOpen, setModalOpen, selectedExcels = [], projectId, setView, setViewOptions}) => {
    const [excels, setExcels] = useState([])
    const [selectedExcel, setSelectedExcel] = useState(null)
    const [chosenExcels, setChosenExcels] = useState(selectedExcels)
    const [selectableGroups, setSelectableGroups] = useState(null)
    const [selectedGroups, setSelectedGroups] = useState([])
    const [viewName, setViewName] = useState("")
    const [viewTags, setViewTags] = useState([])
    const [calcStyle, setCalcStyle] = useState("sum")

    useEffect(()=>{
        if(chosenExcels.length > 0){
          getGroups()  
        }
        else {
            setChosenExcels([])
            setSelectableGroups(null)
        }
    },[chosenExcels[0]])
    
    const handleChange = (val) => {
        setSelectedExcel(val)
        setChosenExcels(prevData =>([
            ...prevData,
            val
        ]))
        setSelectedExcel(null)
    }

    useEffect(() => {
        getExcelsForView()
    },[])

    const getExcelsForView = async() => {
        let payload = {
            projectId: projectId
        }
        const response = await executeRequest('project/newView/getExcels', payload)
        
        setExcels([...response])
    }

    const handleGroupSelection = (selectedItems) => {
        setSelectedGroups([...selectedItems])
    }

    const getGroups = () => {
        setSelectableGroups(chosenExcels[0].groups)
    }


    const removeSelection = (index) => {
        let array = chosenExcels
        let addToOriginal = array.splice(index,1)
    
        setChosenExcels([...array])
    }

    const selectedExcelFilter = (excel) => {
        const { value, groups } = excel

        if(groups.length === 0) return false
        if(chosenExcels.filter(exl => exl.value.toUpperCase().includes(value.toUpperCase())).length > 0) return false
        else if(groups && chosenExcels[0]){
            if(JSON.stringify(groups) == JSON.stringify(chosenExcels[0].groups)){
                return true
            }
            else return false
        }
        return true
    }
    
    const createNewView = () => {
        if(viewName.length > 0 && chosenExcels.length > 0){
            saveView()
        } 
        else {
            if(viewName.length === 0){
                NotificationManager.error('Tarkasta nimi!', 'Virhe', 4000)
            }
            if(chosenExcels.length === 0){
                NotificationManager.error('Valitse vähintään yksi exceltiedosto!', 'Virhe', 4000)
            }
        }
    }

    const saveView = async() => {
        let payload = {
            projectId: projectId,
            chosenExcels: chosenExcels,
            selectedGroups: selectedGroups,
            viewName: viewName,
            viewTags: viewTags,
            calcStyle: calcStyle
        }

        const response = await executeRequest('project/saveView', payload)

        if(response.success === true){
            NotificationManager.success('Tallennus onnistui!', 'Ilmoitus', 4000)
            setViewOptions({
                chosenExcels: chosenExcels,
                selectedGroups: selectedGroups,
                viewName: viewName,
                viewTags: viewTags,
                calcStyle: calcStyle,
                selectedViewId: response._id
            })
            setView("view")
            
        } else {
            NotificationManager.error('Tallentamisessa tapahtui virhe!', 'Virhe', 4000)
        }

    }

    const handleTags= (e) => {
        setViewTags(e.target.value)
    }

    const customOption = (option) => {
        return(
            <div className="option-label">
                <p>{option.business.label + " - " + option.label}</p>
            </div>
        )
    }

    return(
        <Modal size="lg" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
            <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Vertailun asetukset</ModalHeader>
            <ModalBody>
                <Label>Valitut excelit  <p style={{ fontSize: '0.7rem', marginBottom: '0.5em' }}>(Voit poistaa valinnan painamalla)</p></Label>
                
                <div style={{ marginBottom: '1em', display: 'flex', flexDirection:'row', flexWrap: 'wrap' }}>
                    {chosenExcels && chosenExcels.map((excel, index) => {
                        return(
                            <div key={index} className="selected-excel-block">
                                {/* <span><i style={{ float: 'right', cursor: 'pointer', marginTop: '0.5em', fontSize: '0.8em' }} className="far fa-trash-alt" onClick={() => removeSelection(index)}></i></span> */}
                                <button onClick={() => removeSelection(index)} className="small-white-button swb-hover">
                                    <div>
                                        {excel.business && <p className="name-overflow-handler" style={{ fontSize: '0.9rem'}}>{excel.business.label}</p>}
                                        <p style={{ fontSize: '0.7rem'}}>{excel.label}</p>
                                    </div>
                                </button>
                            </div>
                        )
                    })}
                </div>
                <div style={{ marginBottom: '1em' }}>
                    <Label>Valittavat excelit</Label>
                    <Select
                        value={selectedExcel}
                        onChange={(val)=> handleChange(val)}
                        options={excels.filter(excel => selectedExcelFilter(excel))}
                        getOptionLabel={option => customOption(option)}
                        placeholder="Valitse excel.."
                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                        className="projectTypes"
                        classNamePrefix="select"
                    />
                </div>
                <div style={{ marginBottom: '1em' }}>
                    <Label>Nimi</Label>
                    <Input id="viewName" placeholder="Anna vertailulle nimi.." type="text" value={viewName} onChange={(e) => setViewName(e.target.value)}></Input>
                </div>
                <div style={{ marginBottom: '1em' }}>
                    <Label>Tagit</Label>
                    <TagHandler
                        tags={viewTags}
                        handleChange={handleTags}
                    />
                </div>
                <div style={{ marginBottom: '1em' }}>
                    <Label>Laskettava arvo</Label>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button onClick={() => setCalcStyle("sum")}  className={"small-white-button " + (calcStyle === "sum"? "selected-calc-active": "")} style={{ width: '100%' }}>Summa</button> 
                        <button onClick={() => setCalcStyle("avg")}  className={"small-white-button " + (calcStyle === "avg"? "selected-calc-active": "")} style={{ width: '100%' }}>Keskiarvo</button> 
                    </div>
                </div>
                {selectableGroups &&
                    <div style={{ marginBottom: '1em' }}> 
                        <Label>Valitse aiheet, jotka liitetään vertailuun</Label>                                                               
                        <MultiSelect
                            messages={customMessages.messages}
                            items={selectableGroups}
                            selectedItems={selectedGroups}
                            onChange={handleGroupSelection}
                            wrapperClassName="multi-selector round-corners"
                        />
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <a onClick={() => setModalOpen(!modalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>        
                <button onClick={() => createNewView()} className="small-white-button wide-button" >Luo näkymä</button>
            </ModalFooter>
        </Modal>
    )
}

const ModifyViewModal = ({viewOptions = {}, update, modifyModalOpen, setModifyModalOpen, projectId, selectedViewId, projectExcels = []}) => {
    const [chosenExcels, setChosenExcels] = useState([])
    const [excels, setExcels] = useState([])
    const [selectableGroups, setSelectableGroups] = useState(null)
    const [selectedGroups, setSelectedGroups] = useState([])
    const [viewName, setViewName] = useState("")
    const [viewTags, setViewTags] = useState([])
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [selectedData, setSelectedData] = useState(null)
    const [calcStyle, setCalcStyle] = useState("sum")

    const handleChange = (val) => {
        setChosenExcels(prevData =>([
            ...prevData,
            val
        ])) 
    }
    useEffect(() => {
        getExcelsForView()
    },[])
    useEffect(() => {
        setChosenExcels(viewOptions.chosenExcels)
        setViewName(viewOptions.viewName)
        setViewTags(viewOptions.viewTags)
        setSelectedGroups(viewOptions.selectedGroups)
        setCalcStyle(viewOptions.calcStyle)

    },[viewOptions])
    const selectedExcelFilter = (excel) => {
        const {groups, value} = excel

        if(groups.length === 0) return false
        if(chosenExcels.filter(exl => exl.value.toUpperCase().includes(value.toUpperCase())).length > 0) return false
        else if(groups && chosenExcels[0]){
            if(JSON.stringify(groups) == JSON.stringify(chosenExcels[0].groups)){
                return true
            }
            else return false
        }

        return true
    }

    useEffect(()=>{
        if(chosenExcels && chosenExcels.length > 0){
          getGroups()  
        }
        else {
            setChosenExcels([])
            setSelectableGroups(null)
        }
    },[chosenExcels[0]])

    const getGroups = () => {
        setSelectableGroups(chosenExcels[0].groups)
    }
  
    const handleGroupSelection = (selectedItems) => {
        setSelectedGroups([...selectedItems])
    }

   const handleRemove = (index, excel) => {
        setSelectedIndex(index)
        setSelectedData(excel)
        setRemoveModalOpen(true)
    }

    const removeSelection = (index) => {
        if((chosenExcels.length -1) > 0){
            let array = chosenExcels
            array.splice(index,1)
            setChosenExcels([...array])  
        }
        setSelectedIndex(null)
        setSelectedData(null)
        setRemoveModalOpen(!removeModalOpen)
    }

    const handleTags= (e) => {
        setViewTags(e.target.value)
    }

    const getExcelsForView = async() => {
        let payload = {
            projectId: projectId
        }
        const response = await executeRequest('project/newView/getExcels', payload)  
        setExcels([...response])
    }
    const handleSave = () => {
        if(viewName.length > 0 && chosenExcels.length > 0){
            saveView()
        } 
        else {
            if(viewName.length === 0){
                NotificationManager.error('Tarkasta nimi!', 'Virhe', 4000)
            }
            if(chosenExcels.length === 0){
                NotificationManager.error('Valitse vähintään yksi exceltiedosto!', 'Virhe', 4000)
            }
        }
    }

    const saveView = async() => {
        let payload = {
            projectId: projectId,
            chosenExcels: chosenExcels,
            selectedGroups: selectedGroups,
            selectedViewId: viewOptions._id ,
            viewName: viewName,
            viewTags: viewTags,
            calcStyle: calcStyle
        }

        const response = await executeRequest('project/saveView', payload)

        if(response.success === true){
            NotificationManager.success('Tallennus onnistui!', 'Ilmoitus', 4000)
            setModifyModalOpen(!modifyModalOpen)
            update()
        } else {
            NotificationManager.error('Tallentamisessa tapahtui virhe!', 'Virhe', 4000)
        }
    }

    const customOption = (option) => {
        return(
            <div className="option-label">
                <p>{option.business.label + " - " + option.label}</p>
            </div>
        )
    }

    return(
        <>
            {viewOptions && viewOptions.chosenExcels &&
                <> 
                    <Modal isOpen={removeModalOpen} toggle={() => setRemoveModalOpen(!removeModalOpen)}>
                        <ModalHeader toggle={() => setRemoveModalOpen(!removeModalOpen)}>
                            {chosenExcels && chosenExcels.length > 1 ?
                                <p>{selectedData && selectedData.label}</p>
                                :
                                <p>Huom!</p>
                            }
                        </ModalHeader>
                        <ModalBody>
                            {chosenExcels && chosenExcels.length > 1 ?
                                <p>Oletko varma, että haluat poistaa excelin vertailusta?</p>
                                :
                                <p>Et voi poistaa viimeistä exceltiedostoa vertailusta</p>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <a onClick={() => setRemoveModalOpen(!removeModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                            {chosenExcels && chosenExcels.length > 1 &&
                                <button onClick={() => removeSelection(selectedIndex)} className="small-white-button wide-button delete-plan" style={{backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>
                            }
                        </ModalFooter>
                    </Modal>
                    <Modal size="lg" isOpen={modifyModalOpen} toggle={() => setModifyModalOpen(!modifyModalOpen)}>
                        <ModalHeader toggle={() => setModifyModalOpen(!modifyModalOpen)}>Vertailun asetukset</ModalHeader>
                        <ModalBody>
                            <Label>Valitut excelit  <p style={{ fontSize: '0.7rem', marginBottom: '0.5em' }}>(Voit poistaa valinnan painamalla)</p></Label>
                            
                            <div style={{ marginBottom: '1em', display: 'flex', flexDirection:'row', flexWrap: 'wrap' }}>
                                {chosenExcels && chosenExcels.map((excel, index) => {
                                    return(
                                        <div key={index} className="selected-excel-block">
                                            {/* <span><i style={{ float: 'right', cursor: 'pointer', marginTop: '0.5em', fontSize: '0.8em' }} className="far fa-trash-alt" onClick={() => removeSelection(index)}></i></span> */}
                                            <button onClick={() => handleRemove(index, excel)} className="small-white-button swb-hover">
                                            <div>
                                                {excel.business && <p className="name-overflow-handler" style={{ fontSize: '0.9rem'}}>{excel.business.label}</p>}
                                                <p style={{ fontSize: '0.7rem'}}>{excel.label}</p>
                                            </div>
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <Label>Valittavat excelit</Label>
                                <Select
                                    value={null}
                                    onChange={(val)=> handleChange(val)}
                                    options={excels.filter(excel => selectedExcelFilter(excel))}
                                    getOptionLabel={option => customOption(option)}
                                    placeholder="Valitse excel.."
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    className="projectTypes"
                                    classNamePrefix="select"
                                />
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <Label>Nimi</Label>
                                <Input id="viewName" placeholder="Anna vertailulle nimi tallentaaksesi " type="text" value={viewName} onChange={(e) => setViewName(e.target.value)}></Input>
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <Label>Tagit</Label>
                                <TagHandler
                                    tags={viewTags}
                                    handleChange={handleTags}
                                />
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <Label>Laskettava arvo</Label>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button onClick={() => setCalcStyle("sum")}  className={"small-white-button " + (calcStyle === "sum"? "selected-calc-active": "")} style={{ width: '100%' }}>Summa</button> 
                                    <button onClick={() => setCalcStyle("avg")}  className={"small-white-button " + (calcStyle === "avg"? "selected-calc-active": "")} style={{ width: '100%' }}>Keskiarvo</button> 
                                </div>
                            </div>
                            {selectableGroups &&
                                <div style={{ marginBottom: '1em' }}> 
                                    <Label>Valitse näytettävät aihealueet</Label>                                                               
                                    <MultiSelect
                                        messages={customMessages.messages}
                                        items={selectableGroups}
                                        selectedItems={selectedGroups}
                                        onChange={handleGroupSelection}
                                        wrapperClassName="multi-selector round-corners"
                                    />
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <a onClick={() => setModifyModalOpen(!modifyModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                            <button onClick={() => handleSave()} className="small-white-button wide-button" >Tallenna</button>
                        </ModalFooter>
                    </Modal>
                </>
            }
        </>
    )
}
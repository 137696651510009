import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import './home.css';
import TextEditor from '../../../../Utilities/TextEditor/texteditor'
import TaskCreatorModal from '../../Dashboard/taskCreator/modals/TaskCreatorModal';
import TaskContainer from '../../Dashboard/taskContainer/TaskContainer';
import ModifierModal from '../../Dashboard/taskCreator/modals/taskModifier/TaskModifier';
import RiskAssesmentModal from '../../../../Utilities/Modals/riskModals/MainRiskModal';
import RiskContainer from '../../Dashboard/RiskContainer/RiskContainer';
import FreetextContainer from '../../Dashboard/FreetextContainer/FreetextContainer';
import ReportBase from './reportBase/ReportBase';
import FeedbackReport from './FeedbackReport';
import { AttachmentContainer } from '../../Dashboard/AttachmentContainer/AttachmentContainer';


class MainReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openReport: true,
            addTask: false,
            selectedTarget: {},
            modifier: false,
            targetTask: {},
            reportBases: []
        }
    }

    startAddingTask = data => {
        let obj = {
            addTask: true,
            selectedTarget: { ...data }
        };

        this.setState(obj);
    }

    riskAssesmentClosed = () => {
        this.setState({ riskModal: false });
    }

    startRiskAssesment = data => {
        let obj = {
            riskModal: true,
            selectedTarget: {
                ...data,
                risk: data.risk !== undefined ? data.risk : 'new'
            }
        }
        this.setState(obj);
    }

    calculateColor = (avg, group) => {
        let color = "#ed5652";
        group.feedbacks.forEach((feedback, y) => {
            if (feedback.range[0] <= avg && feedback.range[1] >= avg) {
                color = feedback.color;
            }
        })
        return color;
    }

    getMostAnswersFeedback = (questions) => {
        let mostAnswersFeedback = questions.questionOptions[0].feedback;
        let mostAnswersIndex = 0;

        questions.questionOptions.forEach((option, index) => {
            const answerCount = option.countForStatistics;
            if (answerCount > questions.questionOptions[mostAnswersIndex].countForStatistics) {
                mostAnswersFeedback = option.feedback;
                mostAnswersIndex = index;
            }
        })
        return mostAnswersFeedback;
    }

    modifyTask = (task) => {
        this.setState({ targetTask: task, modifier: true });
    }

    toggleModifier = () => {
        this.setState(prevState => ({
            modifier: !prevState.modifier
        }));
    }

    render() {
        const { modulesInUse } = this.props

        return (
            <div>
                <RiskAssesmentModal riskModal={this.state.riskModal} chosenTarget={this.state.selectedTarget} closed={this.riskAssesmentClosed} activeReports={this.props.chosenSurveyTargets} activeIds={this.props.chosenSurveyTargetIds} activeCompany={this.props.selectedBusiness} />
                <TaskCreatorModal modal={this.state.addTask} chosenTarget={this.state.selectedTarget} activeReports={this.props.chosenSurveyTargets} activeIds={this.props.chosenSurveyTargetIds} activeCompany={this.props.selectedBusiness} endAdd={() => this.setState({ addTask: false })} />
                <ModifierModal modify={this.state.modifier} task={this.state.targetTask} modifierToggle={this.toggleModifier} />

                <Row style={{ height: "100%" }} className="report">
                    {this.state.openReport &&
                        this.props.sentSurveys && this.props.sentSurveys.length > 0 ? (

                            <Col style={{ overflow: 'auto', padding: '0 2rem' }}>

                                {this.props.sentSurveys.map((survey, ssIndex) => {
                                    if (survey.type === 'feedback') {
                                        return <FeedbackReport key={ssIndex} survey={survey} toggleModal={this.props.toggleIconUrlListModal} />
                                    }
                                    const surveyColor = survey.surveyColor || '#c8c8c8';
                                    // const surveyAvg = survey.surveyTotalStatistics ? survey.surveyTotalStatistics.avg : "NPS"

                                    let surveyAvg

                                    if ( survey.surveyTotalStatistics && Object.keys( survey.surveyTotalStatistics ).length > 0 ) {
                                        if ( typeof survey.surveyTotalStatistics.avg !== 'undefined' ) {
                                            surveyAvg = survey.surveyTotalStatistics.avg
                                        } else {
                                            surveyAvg = '-'
                                        }
                                    } else {
                                        surveyAvg = 'NPS'
                                    }

                                    return (typeof survey.surveyTotalStatistics !== 'undefined' || typeof survey.hasNps !== 'undefined') ? (
                                        <div key={ssIndex}>
                                            <Row className="survey-buttonfilters-row">
                                                {survey.freetextAnswers && <Col xl={{ size: 3 }} xs={{ size: 12 }}>
                                                    <button className="report-button-filter" onClick={() => this.props.toggleFreetextAnswersModal(survey.freetextAnswers)}>Näytä vapaatekstivastaukset</button>
                                                </Col>}
                                                {survey.dateQuestionAnswers && <Col xl={{ size: 3 }} xs={{ size: 12 }}>
                                                    <button className="report-button-filter" onClick={() => this.props.toggleFreetextAnswersModal(survey.dateQuestionAnswers, true)}>Näytä päivämäärävastaukset</button>
                                                </Col>}
                                                {( survey.finalFeedbackAnswers && survey.finalFeedbackAnswers.length > 0 ) && <Col xl={{ size: 3}} xs={{ size: 12 }}>
                                                    <button className="report-button-filter" onClick={() => this.props.toggleFinalFeedbackAnswersModal(survey.finalFeedbackAnswers)}>Näytä loppupalautteen pyytäneet</button>
                                                </Col>}
                                                { (survey.businessIdAnswers && survey.businessIdAnswers.length > 0) && <Col md={{size: 3}} xs={{size: 12}}>
                                                    <button className="report-button-filter" onClick={ () => this.props.toggleBusinessIdModal(survey.businessIdAnswers) }>Yritysvastaukset</button>
                                                </Col>}
                                                {(survey.reportBases !== undefined && survey.reportBases.filter(x => x.value !== survey.sentSurveyId).length > 0) &&
                                                    <Col xl={{ size: 12 }} xs={{ size: 12 }}>
                                                        <Row>
                                                            <Col xl={{ size: 4 }} xs={{ size: 12 }}>
                                                                <label>Valitse raporttipohja</label>
                                                                <ReportBase reportBases={survey.reportBases} index={ssIndex} handleReportBases={this.props.handleReportBases} sentSurveyId={survey.sentSurveyId} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                }
                                                {survey.attachmentAnswers && <Col md={{size: 3}} xs={{size: 12}}>
                                                    <button className="report-button-filter" onClick={ () =>  this.props.toggleAttachmentAnswersModal(survey.attachmentAnswers)}>Näytä liitteet</button>
                                                </Col>}

                                            </Row>
                                            <Row className="survey-main-row">
                                                <div className="ball-container">
                                                    <div className="large-score-ball" style={{ backgroundColor: surveyColor, boxShadow: "0 0 0 2pt " + surveyColor }}>
                                                        {surveyAvg}
                                                    </div>
                                                </div>
                                                <Col md={{ size: 11 }} className="survey-title-container super-border-left" style={{ borderColor: surveyColor }}>
                                                    <Row className="survey-title-row">
                                                        <Col md={{ size: 12 }}>
                                                            <Row>
                                                                <Col xs={{ size: 12 }} xl={{ size: 9 }}>
                                                                    <h2 style={{ marginBottom: 0 }}>
                                                                        {survey.title}
                                                                    </h2>
                                                                </Col>
                                                                <Col xs={{ size: 12 }} xl={{ size: 3 }} className="report-button-container">
                                                                    
                                                                    { modulesInUse.taskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button" onClick={() => this.startAddingTask({ targetText: survey.title, mainId: survey._id })}>Lisää tarkistuspiste</button> }
                                                                    
                                                                    { modulesInUse.riskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button" onClick={() => this.startRiskAssesment({ targetText: survey.title, mainId: survey._id, risk: survey.risk })}>Lisää riskiarvio</button> }

                                                                </Col>
                                                            </Row>
                                                            <hr style={{ clear: 'both' }} />
                                                            <TextEditor editorId={survey.sentSurveyId} content={survey.text} onTextChange={() => { return }} readOnly={true} />

                                                            {survey.tasks !== undefined &&
                                                                <TaskContainer tasks={survey.tasks} headerSize={2} modifyTask={this.modifyTask} additionalClass="survey-title-row" />
                                                            }
                                                            {survey.risks !== undefined &&
                                                                <RiskContainer risks={survey.risks} headerSize={2} modifyRisk={this.startRiskAssesment} riskTargetData={{ targetText: survey.title, mainId: survey._id }} />
                                                            }
                                                        </Col>
                                                    </Row>
                                                    {survey.questionGroups.map((group, gIndex) => {

                                                        const groupColor = group.groupColor || "#c8c8c8";
                                                        // let groupAvg = group.groupTotalStatistics ? group.groupTotalStatistics.avg : "NPS";
                                                        let groupAvg

                                                        if( group.groupTotalStatistics ) {
                                                            if( typeof group.groupTotalStatistics.avg !== 'undefined' ) {
                                                                groupAvg = group.groupTotalStatistics.avg
                                                            } else {
                                                                groupAvg = '-'
                                                            }
                                                        } else {
                                                            groupAvg = 'NPS'
                                                        }

                                                        return (typeof group.groupTotalStatistics !== 'undefined' || typeof group.hasNps !== 'undefined') &&
                                                            <Row className="questiongroup-main-row" key={gIndex}>
                                                                <div className="ball-container">
                                                                    <div className="medium-score-ball" style={{ backgroundColor: groupColor, boxShadow: "0 0 0 2pt " + groupColor }}>{ groupAvg }</div>
                                                                </div>
                                                                <Col md={{ size: 11 }} className="questiongroup-title-container super-border-left" style={{ borderColor: groupColor }}>
                                                                    <Row className="questiongroup-title-row">
                                                                        <Col md={{ size: 12 }}>
                                                                            <Row>
                                                                                <Col xs={{ size: 12 }} xl={{ size: 9 }}>
                                                                                    <h2 style={{ marginBottom: 0 }}>
                                                                                        {group.title}
                                                                                    </h2>
                                                                                </Col>
                                                                                <Col xs={{ size: 12 }} xl={{ size: 3 }} className="report-button-container">
                                                                                    { modulesInUse.taskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button" onClick={() => this.startAddingTask({ targetText: group.title, groupId: group.id })}>Lisää tarkistuspiste</button> }
                                                                                    { modulesInUse.riskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button" onClick={() => this.startRiskAssesment({ targetText: group.title, groupId: group.id, risk: group.risk })}>Lisää riskiarvio</button> }
                                                                                </Col>

                                                                            </Row>
                                                                            <hr style={{ clear: 'both' }} />
                                                                            <TextEditor editorId={`${survey.sentSurveyId}-${group.id}`} content={group.text} onTextChange={() => { return }} readOnly={true} />


                                                                            {group.groupTotalStatistics && group.feedbacks && group.feedbacks.length > 0 && group.feedbacks.map((feedback, index) => {
                                                                                if (group.groupTotalStatistics.avg >= feedback.range[0] && group.groupTotalStatistics.avg <= feedback.range[1]) {
                                                                                    return <TextEditor key={index} editorId={group.id + index} content={feedback.text} onTextChange={() => { return }} readOnly={true} />
                                                                                }
                                                                                return null
                                                                            })}

                                                                            {group.tasks !== undefined &&
                                                                                <TaskContainer tasks={group.tasks} headerSize={2} modifyTask={this.modifyTask} additionalClass="survey-title-row" />
                                                                            }
                                                                            {group.risks !== undefined &&
                                                                                <RiskContainer risks={group.risks} headerSize={2} modifyRisk={this.startRiskAssesment} riskTargetData={{ targetText: group.title, groupId: group.id }} />
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                    {group.questions && group.questions.map((question, qIndex) => {
                                                                        if (!question.isAttachmentQuestion && question.answerStatistics && (typeof question.showInReport === 'undefined' || question.showInReport === true)) {
                                                                            const questionColor = question.questionColor || this.calculateColor(question.answerStatistics.avg, group);

                                                                            let questionAvg

                                                                            if ( typeof question.answerStatistics.avg !== 'undefined' ) {
                                                                                questionAvg = question.answerStatistics.avg
                                                                            } else {
                                                                                questionAvg = '-'
                                                                            }
                                                                            
                                                                            return <Row className="question-main-row" key={qIndex}>
                                                                                <div className="ball-container">
                                                                                    <div className="small-score-ball" style={{ backgroundColor: questionColor, boxShadow: "0 0 0 2pt " + questionColor }}>{ questionAvg }</div>
                                                                                </div>
                                                                                <Col md={{ size: 11 }} className={"question-title-container" + (group.questions[qIndex + 1] ? " super-border-left" : "")} style={{ borderColor: questionColor }}>
                                                                                    <Row className="question-title-row">
                                                                                        <Col md={{ size: 12 }}>
                                                                                            <Row>
                                                                                                <Col xs={{ size: 12 }} xl={{ size: 8 }}>
                                                                                                    <h4 style={{ marginBottom: 0 }}>
                                                                                                        {question.text}
                                                                                                    </h4>
                                                                                                </Col>
                                                                                                <Col xs={{ size: 12 }} xl={{ size: 4 }} className="report-button-container">
                                                                                                    { modulesInUse.taskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button report-button" onClick={() => this.startAddingTask({ targetText: question.text, questionId: question.id })}>Lisää tarkistuspiste</button> }
                                                                                                    { modulesInUse.riskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button report-button" onClick={() => this.startRiskAssesment({ targetText: question.text, questionId: question.id, risk: question.risk })}>Lisää riskiarvio</button> }
                                                                                                </Col>
                                                                                            </Row>

                                                                                            <hr style={{ clear: 'both' }} />

                                                                                            <TextEditor editorId={`${survey.sentSurveyId}-${question.id}`} content={this.getMostAnswersFeedback(question)} onTextChange={() => { return }} readOnly={true} />

                                                                                            {question.analytics && question.analytics.length > 0 && question.analytics.map((anal, index) => {
                                                                                                if (typeof anal.linkText !== 'undefined' && typeof anal.linkURL !== 'undefined') {
                                                                                                    if (question.answerStatistics.avg >= anal.range[0] && question.answerStatistics.avg <= anal.range[1]) {
                                                                                                        return <div key={index}><a target="_blank" rel="noopener noreferrer" href={anal.linkURL} style={{ marginTop: "2em" }}>{anal.linkText}</a></div>
                                                                                                    }
                                                                                                }
                                                                                                return null
                                                                                            })}

                                                                                            {question.questionOptions.map((option, index) => {
                                                                                                const totalAnswers = question.answerStatistics.count;
                                                                                                const answerCount = option.countForStatistics;
                                                                                                const percentage = answerCount !== 0 ? Math.round(answerCount / totalAnswers * 100) : 0;

                                                                                                if (this.props.oneAnswer) {
                                                                                                    if (answerCount !== 0) {
                                                                                                        return <div key={index}>Vastaus: {option.text}</div>
                                                                                                    } else {
                                                                                                        return null
                                                                                                    }
                                                                                                } else {
                                                                                                    return <div key={index} title={option.text} onClick={() => { answerCount > 0 && typeof option.answerSessionIds !== 'undefined' && this.props.filterByAnswerSession(option.answerSessionIds) }} className="small-analysis-ball cursor-pointer" style={{ backgroundColor: option.color, boxShadow: "0 0 0 2pt " + option.color }}>{percentage}%</div>
                                                                                                }

                                                                                            })}

                                                                                            {question.analytics && question.analytics.length > 0 && question.analytics.map((anal, index) => {
                                                                                                if (question.answerStatistics.avg >= anal.range[0] && question.answerStatistics.avg <= anal.range[1]) {
                                                                                                    return <TextEditor editorId={`${survey.sentSurveyId}-${question.id + index}`} key={index} content={anal.text} onTextChange={() => { return }} readOnly={true} additionalClass="answer-analytic-text" />
                                                                                                }
                                                                                                return null
                                                                                            })}

                                                                                            {question.tasks !== undefined &&
                                                                                                <TaskContainer tasks={question.tasks} headerSize={2} modifyTask={this.modifyTask} additionalClass="survey-title-row" />
                                                                                            }
                                                                                            {question.risks !== undefined &&
                                                                                                <RiskContainer risks={question.risks} headerSize={2} modifyRisk={this.startRiskAssesment} riskTargetData={{ targetText: question.text, questionId: question.id }} />
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        } else if (question.isFreetextQuestion) {
                                                                            return <Row className="question-main-row" key={qIndex}>
                                                                                <div className="ball-container">
                                                                                    <div className="small-score-ball" style={{ backgroundColor: '#C8C8C8', boxShadow: "0 0 0 2pt " + '#C8C8C8' }}><i className="far fa-comment-dots"></i></div>
                                                                                </div>
                                                                                <Col md={{ size: 11 }} className={"question-title-container" + (group.questions[qIndex + 1] ? " super-border-left" : "")} style={{ borderColor: '#C8C8C8', minHeight: '10em' }}>
                                                                                    <Row className="question-title-row">
                                                                                        <Col md={{ size: 12 }}>
                                                                                            <Row>
                                                                                                <Col xs={{ size: 12 }} xl={{ size: 8 }}>
                                                                                                    <h4 style={{ marginBottom: 0 }}>
                                                                                                        {question.text}
                                                                                                    </h4>
                                                                                                </Col>
                                                                                                <Col xs={{ size: 12 }} xl={{ size: 4 }} className="report-button-container">
                                                                                                    { modulesInUse.taskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button" onClick={() => this.startAddingTask({ targetText: question.text, questionId: question.id })}>Lisää tarkistuspiste</button> }
                                                                                                    { modulesInUse.riskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button" onClick={() => this.startRiskAssesment({ targetText: question.text, questionId: question.id, risk: question.risk })}>Lisää riskiarvio</button> }
                                                                                                </Col>
                                                                                            </Row>
                                                                                            {question.freetextAnswers !== undefined && <FreetextContainer answers={question.freetextAnswers} {...this.props} />}
                                                                                            {/* <hr style={{ clear: 'both' }} /> */}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        } else if (question.isNPS) {
                                                                            return <Row className="question-main-row" key={qIndex}>
                                                                                <div className="ball-container">
                                                                                    <div className="small-score-ball" style={{ backgroundColor: '#C8C8C8', boxShadow: "0 0 0 2pt " + '#C8C8C8' }}>{question.npsScore}</div>
                                                                                </div>
                                                                                <Col md={{ size: 11 }} className={"question-title-container" + (group.questions[qIndex + 1] ? " super-border-left" : "")} style={{ borderColor: '#C8C8C8', minHeight: '10em' }}>
                                                                                    <Row className="question-title-row">
                                                                                        <Col md={{ size: 12 }}>
                                                                                            <Row>
                                                                                                <Col xs={{ size: 12 }}>
                                                                                                    <h4 style={{ marginBottom: 0 }}>
                                                                                                        {question.text} (NPS)
                                                                                                        <hr style={{ clear: 'both' }} />
                                                                                                    </h4>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        } else if (question.isAttachmentQuestion){
                                                                            return <Row className="question-main-row" key={qIndex}>
                                                                                <div className="ball-container">
                                                                                    <div className="small-score-ball" style={{ backgroundColor: '#C8C8C8', boxShadow: "0 0 0 2pt " + '#C8C8C8' }}><i className="fas fa-paperclip"></i></div>
                                                                                </div>
                                                                                <Col md={{ size: 11 }} className={"question-title-container" + (group.questions[qIndex + 1] ? " super-border-left" : "")} style={{ borderColor: '#C8C8C8', minHeight: '10em' }}>
                                                                                    <Row className="question-title-row">
                                                                                        <Col md={{ size: 12 }}>
                                                                                            <Row>
                                                                                                <Col xs={{ size: 12 }} xl={{ size: 8 }}>
                                                                                                    <h4 style={{ marginBottom: 0 }}>
                                                                                                        {question.text}
                                                                                                    </h4>
                                                                                                </Col>
                                                                                                <Col xs={{ size: 12 }} xl={{ size: 4 }} className="report-button-container">
                                                                                                    { modulesInUse.taskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button" onClick={() => this.startAddingTask({ targetText: question.text, questionId: question.id })}>Lisää tarkistuspiste</button> }
                                                                                                    { modulesInUse.riskManagement && <button style={{ margin: '.25rem 0 .5rem .5rem', float: 'right' }} className="small-white-button lighter-text wide-button report-button" onClick={() => this.startRiskAssesment({ targetText: question.text, questionId: question.id, risk: question.risk })}>Lisää riskiarvio</button> }
                                                                                                </Col>
                                                                                            </Row>
                                                                                            {question.attachmentAnswers !== undefined && <AttachmentContainer answers={question.attachmentAnswers} {...this.props} />}
                                                                                            {/* <hr style={{ clear: 'both' }} /> */}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                        return null
                                                                    })}
                                                                </Col>
                                                            </Row>
                                                    })}
                                                </Col>

                                            </Row>
                                        </div>
                                    ) : (
                                            // if there are no statistics for the survey. for example when there are only freetext/date answers
                                            <div key={ssIndex}>
                                                <Row className="survey-buttonfilters-row">
                                                    {survey.freetextAnswers && <Col md={{ size: 3 }} xs={{ size: 12 }}>
                                                        <button className="report-button-filter" onClick={() => this.props.toggleFreetextAnswersModal(survey.freetextAnswers)}>Näytä vapaatekstivastaukset</button>
                                                    </Col>}
                                                    {survey.dateQuestionAnswers && <Col md={{ size: 3 }} xs={{ size: 12 }}>
                                                        <button className="report-button-filter" onClick={() => this.props.toggleFreetextAnswersModal(survey.dateQuestionAnswers, true)}>Näytä päivämäärävastaukset</button>
                                                    </Col>}
                                                    {survey.finalFeedbackAnswers && <Col md={{ size: 3 }} xs={{ size: 12 }}>
                                                        <button className="report-button-filter" onClick={() => this.props.toggleFreetextAnswersModal(survey.finalFeedbackAnswers)}>Näytä loppupalauteen pyytäneet</button>
                                                    </Col>}
                                                    {survey.businessIdAnswers && <Col md={{size: 3}} xs={{size: 12}}>
                                                        <button className="report-button-filter" onClick={ () => this.props.toggleBusinessIdModal(survey.businessIdAnswers)}>Yritysvastaukset</button>
                                                    </Col>}
                                                </Row>
                                                <Row className="survey-main-row" key={ssIndex}>
                                                    <Col md={{ size: 12 }} className="survey-title-container">
                                                        <Row className="survey-title-row">
                                                            <Col md={{ size: 12 }}>
                                                                <div style={{ width: '89%', float: 'left' }}><h2>{survey.title}</h2></div>
                                                                <div style={{ width: '10%', float: 'right' }}>
                                                                    {/* <i className="material-icons float-right addNote" onClick={() => this.startAddingTask({targetText: survey.title, mainId: survey._id})} style={{fontSize: '2rem'}}>add_circle_outline</i>     */}
                                                                    {/* {typeof survey.freetextAnswers !== 'undefined' && <i className="material-icons float-right cursor-pointer" onClick={() => this.toggleFreetextAnswersModal(survey.freetextAnswers)} style={{fontSize: '2rem'}}>chat_bubble_outline</i>} */}
                                                                </div>
                                                                <hr style={{ clear: 'both' }} />
                                                                <TextEditor content={survey.text} onTextChange={() => { return }} readOnly={true} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                })}
                            </Col>

                        ) : (
                            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </Col>
                        )}

                </Row>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedBusiness: state.reports.selectedBusiness,
        chosenSurveyTargets: state.reports.sentSurveys,
        chosenSurveyTargetIds: state.reports.sentSurveyIds,
        modulesInUse: state.authentication.modulesInUse
    }
}
export default connect(mapStateToProps, null)(MainReport);
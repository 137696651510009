import React, { Component } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { convertFromRaw, ContentState, EditorState, convertToRaw } from 'draft-js';
import { debounce } from 'debounce';
import { NotificationManager } from 'react-notifications';
import GroupFeedbackItem from '../questiongroup/subComponents/GroupFeedbackItem';
import { stripUnicodeFromString } from '../../../../../../../Utilities/HelperFunctions/helper'


const emptyFeedback = () => {
    let contentState = ''
    contentState = ContentState.createFromText("")

    let editorState = EditorState.createWithContent(contentState);
    return convertToRaw(editorState.getCurrentContent())
}
const emptyOptions = {
    text: "",
    feedback: emptyFeedback(),
    points: 15,
    tags: [],
    color: '#FFCC00',
    dialogFeedback: emptyFeedback(),
    dialogPlaintextFeedback: "",
    plaintextFeedback: ""
}


export default class IconFeedbacks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedbacks: this.props.feedbacks || [
                {
                    color: '#B5E413',
                    range: [0, 100],
                    text: ""
                }
            ],
        }

        this.start = 600;
        this.repeatTimer = undefined;
        this.pointTarget = undefined;
        this.placeholderForPoints = undefined;
        this.sendingPoints = false;

    }

    componentDidMount() {
        this.setState({
            feedbacks: this.props.feedbacks || [
                {
                    color: '#B5E413',
                    range: [0, 100],
                    text: emptyFeedback()
                }
            ],
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if (typeof prevProps.feedbacks !== 'undefined' && prevProps.feedbacks !== this.props.feedbacks) {
            this.setState({
                feedbacks: this.props.feedbacks || [
                    {
                        color: '#B5E413',
                        range: [0, 15],
                        text: emptyFeedback()
                    }
                ]
            })
        }
    }

    handleChange = (data) => {
        this.setState(data);
    }

    colorHandler = (colour, index) => {
        let data = [
            ...this.state.feedbacks
        ];

        data[index].color = colour;
        this.setState({ feedbacks: data });

        let payload = {
            categoryId: this.props.categoryId,
            incomingData: {
                iconFeedbacks: this.state.feedbacks
            }
        };

        this.props.category.original ? this.props.onCategoryModified(payload) : this.props.onSentSurveyGroupModified(payload)
    }

    handleFeedBackChange = (index, value, plaintextFeedback = '') => {
        let feedbacks = [
            ...this.state.feedbacks
        ];

        let targetPayload = {
            text: feedbacks[index].text,
            plaintextFeedback: feedbacks[index].plaintextFeedback,
            index: index
        }

        feedbacks[index].text = value;
        feedbacks[index].plaintextFeedback = plaintextFeedback;


        this.setState({ feedbacks: feedbacks, targetPayload: targetPayload });
        this.onBlurFeedback();
    }

    createNewFeedBackAndModifyPreviousValue = (previousFeedbacks) => {
        if (previousFeedbacks[previousFeedbacks.length - 1].range[0] === 0) {
            let newFeedBackItem = {
                color: '#B5E413',
                range: [(previousFeedbacks[previousFeedbacks.length - 1].range[1] / 2) + 1, 100],
                text: emptyFeedback(),
                plaintextFeedback: ""
            };

            let modifyPrevious = {
                ...previousFeedbacks[previousFeedbacks.length - 1],
                range: [0, newFeedBackItem.range[0] - 1]
            };

            previousFeedbacks[previousFeedbacks.length - 1] = {
                ...modifyPrevious
            };

            previousFeedbacks.push(newFeedBackItem);
        } else {
            //Ei laske niinkun pitäs, mutta tuntuu loksahvatan, ehkä parempi näin, aikasemmin yritti asettaa isompaa siivua kerralla
            let newFeedBackItem = {
                color: '#B5E413',
                range: [parseInt(((100 - previousFeedbacks[previousFeedbacks.length - 1].range[1]) / (previousFeedbacks.length + 1)) + (previousFeedbacks[previousFeedbacks.length - 1].range[0] + 2)), 100],
                text: emptyFeedback(),
                plaintextFeedback: ""
            };

            let modifyPrevious = {
                ...previousFeedbacks[previousFeedbacks.length - 1],
                range: [previousFeedbacks[previousFeedbacks.length - 1].range[0], newFeedBackItem.range[0] - 1]
            };

            previousFeedbacks[previousFeedbacks.length - 1] = {
                ...modifyPrevious
            };

            previousFeedbacks.push(newFeedBackItem);
        }
        return previousFeedbacks;
    }

    addNewfeedBack =() => {
        let feedbacks = [
            ...this.state.feedbacks
        ]

        let length = feedbacks.length;

        if (feedbacks[length - 1].range[0] <= 98) {
            /*let newFeedBackItem = {
                color: '#B5E413',
                range: [feedbacks[feedbacks.length - 1].range[1] + 1, feedbacks[feedbacks.length - 1].range[1] + 2],
                text: emptyFeedback(),
                plaintextFeedback: ""
            };

            feedbacks.push(newFeedBackItem);*/

            let payload = {
                categoryId: this.props.categoryId,
                incomingData: {
                    iconFeedbacks: this.createNewFeedBackAndModifyPreviousValue(feedbacks)
                }
            };

            this.props.onCategoryModified(payload);

        } else {
            NotificationManager.error("Uusia palautteita ei voida lisätä, pisteet eivät voi ylittää rajaa 100.", "Virhe", 3500);
        }
    }

    handleNumberValue = (index, value) => {
        if (!isNaN(value)) {
            let feedbacks = [
                ...this.state.feedbacks
            ];
            let valueToInt = parseInt(value);
            //If new value is higher than previous lower range and its not higher than 100
            if (valueToInt <= 100) {
                if (typeof feedbacks[index + 1] !== 'undefined') {

                    if (valueToInt > feedbacks[index].range[0]) {
                        if (valueToInt + 1 < feedbacks[index + 1].range[1]) {
                            feedbacks[index].range[1] = valueToInt;
                            feedbacks[index + 1].range[0] = valueToInt + 1;
                        }
                    }
                } else {
                    if (valueToInt > feedbacks[index].range[0]) {
                        feedbacks[index].range[1] = valueToInt;
                    }
                }
            }

            this.setState({ feedbacks: feedbacks });
        }
    }

    onButtonRelease = (event) => {
        clearTimeout(this.repeatTimer)

        this.start = 600;
        this.checkPoints();
    }

    checkPoints = debounce(() => {
        if (this.pointTarget !== undefined) {
            const { index } = this.pointTarget

            if (this.props.group !== undefined && this.props.group.feedbacks !== undefined) {

                let feedbacks = [
                    ...this.state.feedbacks
                ];

                if (feedbacks[index] !== undefined) {
                    if (!this.sendingPoints && (feedbacks[index].range[1] > this.placeholderForPoints.higherRange || feedbacks[index].range[1] < this.placeholderForPoints.higherRange)) {
                        let payload = {
                            categoryId: this.props.categoryId,
                            incomingData: {
                                iconFeedbacks: this.state.feedbacks
                            }
                        };
                        this.sendingPoints = true;
                        this.props.onCategoryModified(payload)
                    }
                } else {
                    let payload = {
                        categoryId: this.props.categoryId,
                        incomingData: {
                            iconFeedbacks: this.state.feedbacks
                        }
                    };
                    this.props.onCategoryModified(payload)
                }
            } else {
                let payload = {
                    categoryId: this.props.categoryId,
                    incomingData: {
                        iconFeedbacks: this.state.feedbacks
                    }
                };
                this.props.onCategoryModified(payload)
            }
        }
    }, 1000)

    repeatPositiveButtonEvent = (values) => {
        if (values !== undefined) {
            this.pointTarget = values;
            this.placeholderForPoints = {
                ...JSON.parse(JSON.stringify(values))
            }
            this.sendingPoints = false;
        }

        if (this.pointTarget !== undefined) {
            this.pointTarget.higherRange += 1;
            this.handleNumberValue(this.pointTarget.index, this.pointTarget.higherRange);
        }

        this.repeatTimer = setTimeout(this.repeatPositiveButtonEvent, this.start)
        this.start = this.start / 2
    }

    repeatNegativeButtonEvent = (values) => {
        if (values !== undefined) {
            this.pointTarget = values;
            this.placeholderForPoints = {
                ...JSON.parse(JSON.stringify(values))
            }
            this.sendingPoints = false;
        }

        if (this.pointTarget !== undefined) {
            this.pointTarget.higherRange -= 1;
            this.handleNumberValue(this.pointTarget.index, this.pointTarget.higherRange);
        }

        this.repeatTimer = setTimeout(this.repeatNegativeButtonEvent, this.start)
        this.start = this.start / 2
    }

    feedbackNumberInput = (index, value) => {
        let feedbacks = [
            ...this.state.feedbacks
        ];

        let feedback = {
            ...feedbacks[index],
            scoreBefore: feedbacks[index].range[1]
        };
        //Input field is cleared
        if (value.length === 0) {
            feedback.range[1] = value;
            //Check that value is a number
        } else if (!isNaN(value)) {
            //100 is max value
            if (parseInt(value) <= 100) {
                feedback.range[1] = parseInt(value);
                //Check if there's more than 1 feedback, else just set the value and do nothing else
                if (typeof feedbacks[index + 1] !== 'undefined') {
                    //If value is higher than the lowerRange of the feedback && if the next element in feedbacks lowerRange holds higher value than the incoming value, delete isValid from object
                    if (parseInt(value) > feedback.range[0] && feedbacks[index + 1].range[0] > parseInt(value)) {
                        delete feedback["isValid"];
                    } else {
                        //Make sure value is still higher than range[0]
                        if (parseInt(value) > feedbacks[index].range[0]) {
                            //If value + 1 is less than next elements range[1], increase the range[0] in next element to value + 1
                            if (parseInt(value) + 1 < feedbacks[index + 1].range[1]) {
                                feedbacks[index].range[1] = parseInt(value);
                                feedbacks[index + 1].range[0] = parseInt(value) + 1;
                                delete feedback["isValid"];
                            } else {
                                feedback.isValid = false;
                            }
                        } else {
                            feedback.isValid = false;
                        }


                    }
                }

                feedbacks[index] = feedback;
            }
        }

        this.setState({ feedbacks: feedbacks });
        this.checkPoints();
    }
    //Handle point changes here

    onTextChange = (rawEditorState) => {
        let plaintext = convertFromRaw(rawEditorState).getPlainText()
        // plaintext = plaintext.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
        plaintext = stripUnicodeFromString(plaintext)

        this.setState({
            styledGroupDescription: rawEditorState,
            plaintextGroupDescription: plaintext
        }, () => this.props.dataChanged())
        this.onBlurSelite();
    }

    render() {
        return (
            <Row>
                <Col xl="12">
                    {typeof this.state.feedbacks !== 'undefined' && this.state.feedbacks.map((feedback, x) => (
                        <div key={x} >
                            {x !== 0 &&
                                <hr></hr>
                            }
                            <GroupFeedbackItem
                                repeatPositiveButtonEvent={this.repeatPositiveButtonEvent}
                                repeatNegativeButtonEvent={this.repeatNegativeButtonEvent}
                                onButtonRelease={this.onButtonRelease}
                                rangeLower={feedback.range[0]} rangeHigher={feedback.range[1]}
                                feedbackData={feedback}
                                feedbacks={this.props.feedbacks}
                                feedback={feedback.text}
                                handleSave={this.props.onCategoryModified}
                                index={x}
                                original={this.props.original}
                                changeFeedBack={this.handleFeedBackChange}
                                handleNumberValue={this.handleNumberValue}
                                textHandler={this.feedbackNumberInput}
                                color={feedback.color}
                                handleColorChange={this.colorHandler}
                                categoryId={this.props.categoryId}
                                groupId={this.props.categoryId}
                                surveyId={this.props.surveyId}
                                saveDraftjsImage={this.props.saveDraftjsImage}
                                iconFeedback={true}
                            />
                        </div>
                    )
                    )}
                    <Row style={{ paddingBottom: '1em' }}>
                        <Col>
                            <Button className="addOption" onClick={() => this.addNewfeedBack()}>Uusi raja-arvo</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
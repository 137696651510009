import React from 'react';
import MultiSelect from "@kenshooui/react-multi-select";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {confs} from '../../../modules/config';
import {NotificationManager} from 'react-notifications';

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi kartoitusta...",
        noItemsMessage: "Aktiivisia kartoituksia ei löytynyt",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
};

const MegaLoaderSpinner = () => {
    return (
            <Row className="loadingSpinnerRow">
                <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center'}}>
                    <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </Col>
            </Row>
    )
}

class SurveyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSurveys: [],
            surveyList: [],
            loading: true
        }
    }

    componentDidMount() {
        
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            selectedBusiness: this.props.selectedBusiness.value,
            selectedSurveys: this.props.selectedSurveys
        }

        fetch(confs.url + 'plan/availableSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
        .then(response => {
            this.setState({surveyList: response, selectedSurveys: this.props.selectedSurveys, loading: false});
        }).catch(error => {
            NotificationManager.error("Kartoituksien haku epäonnistui, yritä hetken päästä uudelleen", "", 3000);
        })
    }

    handleSurveySelection = selectedItems => {
        this.setState({selectedSurveys: selectedItems});
    }

    render() {
        const {selectedSurveys} = this.state;
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} centered size="lg">
                    <ModalHeader toggle={this.props.toggle} className="taskModal">
                        <div>
                            Valitse lomakkeeseen liittyvät kartoitukset
                        </div> 
                    </ModalHeader>
                    <ModalBody>
                        {this.state.loading ? <MegaLoaderSpinner /> : 
                        <Row className="taskCreatorRow">
                            <Col xl="12">
                                <MultiSelect
                                    messages={customMessages.messages}
                                    items={this.state.surveyList}
                                    selectedItems={selectedSurveys}
                                    onChange={this.handleSurveySelection}
                                    showSelectAll={false}
                                    showSelectedItems={false}
                                    wrapperClassName="multi-selector"
                                    withGrouping
                                />
                            </Col>
                        </Row>}
                    </ModalBody>
                    <ModalFooter className="taskCreatorFooter">
                        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={this.props.toggle}>Peruuta</a>
                        <button className="small-white-button wide-button" onClick={() => this.props.saveSelectedSurveys(this.state.selectedSurveys)}>Tallenna</button>
                    </ModalFooter>
                </Modal>
        )
    }
}

export default SurveyModal;
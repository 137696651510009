import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import * as ActionCreator from '../../store/index';
import ClientsList from './subComponents/ClientsList';
import { connect } from 'react-redux';
import CompanyLink from './subComponents/CompanyRegistrationV2';
import './companylink.css';

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCompanies: [],
            selectedOption: null,
            newUser: {
                emailStart: "",
                emailEnd: "",
                phone: ""
            },
            selectedCompany: null,
            confirm: false,
            phoneReady: false,
            emailReady: false,
            resendToCompany: null,
            loaded: false
        }

        this.handleCompanyChanges = this.handleCompanyChanges.bind(this);
        this.removeCompanyFromList = this.removeCompanyFromList.bind(this);
        this.requestPermission = this.requestPermission.bind(this);
        this.endCollaboration = this.endCollaboration.bind(this);
        this.handleNewUserAddValues = this.handleNewUserAddValues.bind(this);
        this.setCompanyData = this.setCompanyData.bind(this);
        this.saveNewUser = this.saveNewUser.bind(this);
        this.toggleUserModal = this.toggleUserModal.bind(this);
    }

    componentDidMount() {
        this.props.setBreadcrumbs({to: 'tiedot', label: `${this.props.businessName}`})
        this.props.addMoreBreadcrumbs([{to: 'yhteistyo', label: "asiakkaat"}]);
        this.props.onPageOpen();

        setTimeout( () => {
            this.setState({ loaded: true })
        }, 100)
    }

    handleCompanyChanges(company) {
        let selectedSoFar = [
            ...this.state.selectedCompanies,
            company
        ];


        this.setState({ selectedCompanies: selectedSoFar });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.collaborations !== this.props.collaborations) {
            this.setState({
                newUser: {
                    emailStart: "",
                    emailEnd: "",
                    phone: ""
                }
            });
        }
    }

    removeCompanyFromList(company) {
        let selectedSoFar = [
            ...this.state.selectedCompanies.filter(x => x.value !== company.value)
        ];

        this.setState({ selectedCompanies: selectedSoFar });
    }

    requestPermission() {
        let params = {
            selectedPartners: [
                ...this.state.selectedCompanies
            ]
        };
        this.props.onPermissionRequest(params);
    }

    endCollaboration(agreement, cardData) {

        //Need to make an alert box and ask if user really wants to end collaboration before sending the request
        let params = {
            agreement: agreement,
            collaboration: cardData
        };

        this.props.onCollaborationEnd(params);

    }

    handleNewUserAddValues(type, value) {
        const emailCheck = /[^@]+@[^\.]+\..+/;
        let emailReady = this.state.emailReady;
        let phoneReady = this.state.phoneReady;
        let user = {
            ...this.state.newUser
        };

        user[type] = value;

        if (type === "emailStart" || type === "emailEnd") {
            let testEmail = "";
            if (type === "emailStart") {
                testEmail = user[type] + "@" + this.state.newUser.emailEnd;
            }

            if (type === "emailEnd") {
                testEmail = this.state.newUser.emailStart + "@" + user[type];
            }
            if (emailCheck.test(testEmail)) {
                emailReady = true;
            } else {
                emailReady = false;
            }

        } else if (type === "phone") {
            let phoneNumber = "+358" + user[type];

            if (phoneNumber.length > 12) {
                phoneReady = true;
            } else {
                phoneReady = false;
            }
        }
        this.setState({ newUser: user, emailReady: emailReady, phoneReady: phoneReady });
    }

    setCompanyData(company, index) {
        let newCompanyData = {
            ...company,
            companyIndex: index
        };

        this.setState({ selectedCompany: newCompanyData });
    }

    saveNewUser() {
        let username = this.state.newUser.emailStart + "@" + this.state.newUser.emailEnd;
        let phone = "+358" + this.state.newUser.phone;
        let payload = {
            newUser: {
                businessId: this.state.selectedCompany._id,
                email: username,
                phone: phone
            }
        };

        this.props.onUserAdd(payload);
        this.setState({ confirm: false });
    }

    toggleUserModal() {
        this.setState({ confirm: !this.state.confirm });
    }

    selectBusiness = ( business ) => {
        if( business !== 'anon' ) {
            this.props.addBreadcrumbs( { to: 'raportit/yritys', label: business.businessName, businessId: business._id } )
            this.props.onBusinessSelect( { businessId: business._id, name: business.businessName } )
            this.props.getReportTree( { businessId: business._id } )
            this.props.history.push( "/raportit/yritys" )
        } else {
            this.props.storeBusiness( { name: 'Ei kohdeyritystä' } )
            this.props.addBreadcrumbs( { to: 'raportit/kohdistamaton', label: 'Ei kohdeyritystä', businessId: 'anon' } )
            this.props.onBusinessSelect( { businessId: 'anon', name: 'Ei kohdeyritystä' } )
            this.props.history.push( "/raportit/kohdistamaton" )
        }
    }

    resendPermissionRequest = company => {
        this.setState({ resendToCompany: company })
    }

    cancelPendingCollaboration = params => {
        const data = {
            businessId: params._id
        }

        this.props.cancelCollaborationRequest( data )
    }

    render() {
        const { modulesInUse } = this.props
        let companyList = this.props.businesses

        return (
            <Container fluid  className="dashboard-main">
                <div className="client-colored-box">
                    <div className='inner-client-container'>
                        <Row >
                            <Col xl={{ size: 8 }} xs={{ size: 12 }}>
                                {( companyList && this.state.loaded ) ?
                                    <ClientsList modulesInUse={modulesInUse} updateList={this.props.onPageOpen} companyList={companyList} endCollaboration={this.endCollaboration} modify={this.props.onCompanyModify} setCompany={this.setCompanyData} selectBusiness={ this.selectBusiness } resendPermissionRequest={ this.resendPermissionRequest } cancelPendingCollaboration={ this.cancelPendingCollaboration } resetCollaborations={this.props.resetCollaborations}/>
                                    :
                                    <EmptyBox title="Asiakkaat" />
                                }
                            </Col>
                            {/* <UserAddModal email={this.state.newUser.emailStart + "@" + this.state.newUser.emailEnd} phone={"+358" + this.state.newUser.phone} userComplete={this.state.confirm} toggle={this.toggleUserModal} save={this.saveNewUser}/> */}
                            { modulesInUse.clientManagement && (
                                <Col className="client-divider" xl={ { size: 4 } } xs={ { size: 12 } }>
                                    <CompanyLink resendToCompany={ this.state.resendToCompany } resetResend={ this.resendPermissionRequest } />
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>
            </Container>
        )
    }
}

const EmptyBox = (props) => {
    return (
        <Row>
            <Col md={{ size: 12 }} xl={{ size: 12 }} >
                <div className="company-box">
                    <div className="client-box-header bigger-tex" style={{ border: 'none', fontWeight: 'bold', marginTop: '1.5em'}}>
                        <p>{props.title}</p>
                    </div>
                    <div style={ { height: '500px' } }>
                        <MegaLoaderSpinner />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const mapStateToProps = state => {
    return {
        businesses: state.collaborations.collaborations,
        businessPath: state.authentication.businessURL,
        businessName: state.authentication.businessName,
        modulesInUse: state.authentication.modulesInUse
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPageOpen: () => dispatch(ActionCreator.fetchCollaborations()),
        onPermissionRequest: (params) => dispatch(ActionCreator.requestPermission(params)),
        onCollaborationEnd: (params) => dispatch(ActionCreator.endCollaboration(params)),
        onCompanyModify: (params) => dispatch(ActionCreator.modifyBusiness(params)),
        onUserAdd: (params) => dispatch(ActionCreator.addBusinessUser(params)),
        setBreadcrumbs: (params) => dispatch(ActionCreator.setBreadcrumbs(params)),
        onBusinessSelect: (params) => dispatch(ActionCreator.selectBusinessForReport(params)),
        addBreadcrumbs: (params) => dispatch(ActionCreator.addBreadcrumbs(params)),
        getReportTree: (params) => dispatch(ActionCreator.getBusinessSurveyTree(params)),
        addMoreBreadcrumbs: (params) => dispatch(ActionCreator.addMoreBreadcrumbs(params)),
        cancelCollaborationRequest: ( params ) => dispatch(ActionCreator.cancelCollaborationRequest( params )),
        storeBusiness: ( params ) => dispatch( ActionCreator.storeBusiness( params ) ),
        resetCollaborations: (params) => dispatch(ActionCreator.resetCollaborations(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
import React, { useState, useEffect } from 'react';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { motion } from 'framer-motion';
import MenuContainer from '../../../Components/Kartoitus/SenderV2/Filter/MenuContainer';
import FilterButton from '../../../Components/Kartoitus/SenderV2/Filter/FilterButton'
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import * as ActionCreator from '../../../store/index';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import moment from 'moment'
import { DateRangePicker } from 'react-dates';
import { NotificationManager } from 'react-notifications';
import { ProjectSurveyListItem } from './ProjectSurveyListItem';    
import BackgroundEditor from '../../Kartoitus/SenderV2/subComponents/BackgroundEditor';
import { confs } from '../../../modules/config';
import { EmptyProjectList } from './ProjectLayout';
import { ListHeaderSorter } from './ListHeaderSorter';

const baseURL = confs.url;
const INITIAL_STATE_SURVEY = {
    name: "",
    targets: [],
    startDate: null,
    endDate: null,
    surveys: [],
    startSending: true,
    dates: [],
    background: null,
    backgroundSet: false,
    hideMenu: false,
    //resetBackground: true
}

export const SurveyList = (props) => {
    const { sentSurveys, surveyFilters, history, projectId, businessList, updateChange, projectName, setIsModalOpen} = props

    const [mainFilters, setMainFilters] = useState([])
    const [filter, setFilter] = useState("")
    const [sortOrder, setSortOrder] = useState(1)
    const [sortedSurveys, setSortedSurveys] = useState([])
    const [orderBy, setOrderBy] = useState("name")

    const dispatch = useDispatch()
    
    //This function sets the filters from filter list
    const handleMainFilters = (filter) => {
        if (mainFilters.includes(filter)) {
            let removeFilter = mainFilters.filter(x => x !== filter);
            //this.props.getAllSurveys({ companyTargets: removeFilter.filter(x => x.businessName !== undefined) });
            setMainFilters(removeFilter)
        } else {
            let filterObject = {
                target: filter.target
            }

            if (filter.value === "Tilanne") {
                //These will be used to directly filter results in the current list
                if (filter.target === "Aktiivinen") {
                    filterObject = {
                        ...filterObject,
                        isActive: true
                    }
                } else if (filter.target === "Päättynyt") {
                    filterObject = {
                        ...filterObject,
                        isActive: false
                    }
                } else if (filter.target === "Vastauksia") {
                    filterObject = {
                        ...filterObject,
                        hasAnswers: true
                    }
                } else if (filter.target === "Ei vastauksia") {
                    filterObject = {
                        ...filterObject,
                        hasAnswers: false
                    }
                }
            } 
            // else if (filter.value === "Kohteet") {
            //     //Fetch new list with the companies
            //     filterObject = {
            //         ...filterObject,
            //         businessName: filter.target
            //     }
            // }

            let filters = [
                ...mainFilters,
                filterObject
            ]

            //this.props.getAllSurveys({ companyTargets: filters.filter(x => x.businessName !== undefined) });

            setMainFilters(filters)
        }
    }

    const endSurvey= async({data}) => {
        let payload = {
            surveyData:{...data},
            type: data.type,
            projectId:projectId
        }  
        const response = await executeRequest('project/survey/cancel', payload)  
        updateChange(projectId)
        setConfirmationModal(false)
    }
    const statusFilter = (sentSurvey) => {
        if(mainFilters){
            let mainFiltersActive = mainFilters.filter(x => x.isActive !== undefined);
            let mainFiltersAnswers = mainFilters.filter(x => x.hasAnswers !== undefined);

            let activeMatch = false;
            let answerMatch = false;

            if (mainFiltersActive.length > 0) {
                mainFiltersActive.map(filter => {
                    if (filter.isActive === sentSurvey.isActive) {
                        activeMatch = true;
                    }
                })
            } else {
                activeMatch = true;
            }

            if (mainFiltersAnswers.length > 0) {
                mainFiltersAnswers.map(filter => {
                    if (filter.hasAnswers && sentSurvey.answerCount > 0) {
                        answerMatch = true;
                    } else if (!filter.hasAnswers && sentSurvey.answerCount === 0) {
                        answerMatch = true;
                    }
                })
            } else {
                answerMatch = true;
            }

            return activeMatch === answerMatch;
        }
    }

    const [menuIndex, setMenuIndex] = useState()

    const toggleMenu = (cardIndex) => {
        if (menuIndex === cardIndex) {
            setMenuIndex(-1)
        } else {
            setMenuIndex(cardIndex)
        }
    }
    

    const selectReport = (obj) => {

        const params = {
            reports: [
                {
                    reportId: obj._id
                }
            ]
        }

        dispatch(ActionCreator.getReportForDashboard(params))

        if (obj.businessName !== "Ei kohdeyritystä") {
            dispatch(ActionCreator.getBusinessSurveyTree({ businessId: obj.businessID }))
            history.push({
                pathname:'/raportit/yritys/raportti',
                state:{
                    crumbs:{ to: 'projektit/hallitse', label: projectName },
                    projectId: projectId,
                    projectName: projectName
                }

            })
        } else {
            dispatch(ActionCreator.storeBusiness({ name: 'Ei kohdeyritystä' }))
            history.push({
                pathname:'/raportit/kohdistamaton/raportti',
                state:{
                    crumbs:{ to: 'projektit/hallitse', label: projectName },
                    projectId: projectId,
                    projectName: projectName
                }       
            })
            
        }
    }

    const saveTarget = async(survey, {target, value}) => {

        let params = {
            sentSurveyId:survey._id,
            change: target,
            data: value
        }
        const response = await executeRequest('project/updateSentSurvey', params)
        if(response.success){
            updateChange(projectId)
        }
        setModalIsOpen(false)
        setNameModalActive(false)
        setDatesModal(false)
        setSDate({startDate:null, endDate:null})
        updateChange(projectId)
    }


    const [confirmationModal, setConfirmationModal] = useState(false)
    const handleSurveyEnding = (survey, type) => {
        setData({...survey, type:type, projectId:projectId})
        setConfirmationModal(true)
    }

    const [surveyToUpdate, setSurveyToUpdate] = useState()
    const [data, setData] = useState()

    //Change target
    const [target, setTarget] = useState()
    const [modalIsOpen, setModalIsOpen] = useState()
    const toggleChangeSurveyTarget = (props) => {
        setModalIsOpen(!modalIsOpen)
        setSurveyToUpdate(props)
    }
   
    //Change Name
    const [nameModalActive, setNameModalActive] = useState(false)
    const [newName, setNewName] = useState("")
    const toggleNameChange = (props) => {
        setNameModalActive(!nameModalActive)
        setSurveyToUpdate(props)
    }

    //Modify dates
    const [datesModal, setDatesModal] = useState(false)
    const toggleDateModal = (props) => {
        setDatesModal(!datesModal)
        setSurveyToUpdate(props)
    }

    const  isDayBlocked = (day) => {
        let compare = moment(new Date(day))
        if(surveyToUpdate && surveyToUpdate.dates){
            for (let date of surveyToUpdate.dates) {
                let start = moment(new Date(date.startDate))
                let end = moment(new Date(date.endDate))
                if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                    return true;
                }
            } 
            return false;
        }
       

    }

    
    const isDayHighlighted = (day) => {
        let compare = moment(new Date(day))
        if(surveyToUpdate && surveyToUpdate.dates){
            for (let date of this.state.datesList) {
                let start = moment(new Date(date.startDate._d))
                let end = moment(new Date(date.endDate._d))

                if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                    return true;
                }
            }
            return false;
        }
    }

    const [sDate, setSDate] = useState({})
    const [dateToChangeInd, setDateToChangeInd] = useState(null)
    const [modifyDates, setModifyDates] = useState({})
    const [modifierFocus, setModifierFocus] = useState()
    const onDatePickerFocusChange = (focusedInput) => {
        setModifierFocus(focusedInput)
    }
    const changeModifierDate = (dates) => {
        let formatedDates = {
            startDate: new Date(moment.utc(dates.startDate, "MMM D, YYYY, HH:mm:ss A", "en").local()),
            endDate: new Date(dates.endDate),
        }
        setSDate({ startDate: dates.startDate, endDate: dates.endDate });

        if (dates.endDate !== null && dates.startDate !== null) {
            let obj = []
            if (dateToChangeInd !== null && modifyDates.length > 0) {
                if (dateToChangeInd === -1) {
                    obj = [...modifyDates, formatedDates]
                } else {
                    obj = [...modifyDates];
                    obj[dateToChangeInd] = formatedDates;
                }
            } else {
                obj = [dates];
            }

            setModifyDates(obj);
            if (dateToChangeInd === -1) {
                setDateToChangeInd(obj.length - 1)
            }
            //this.setState({ dateToChangeInd: null });
        }
    }

    const changeModifiedDateIndex = (dateobj, ind) => {
        if (ind !== -1) {
            setSDate({ startDate: moment(new Date(dateobj.startDate)), endDate: moment(new Date(dateobj.endDate)) })
        } else {
            setSDate({ startDate: null, endDate: null })
        }
        setDateToChangeInd(ind)
    }

    useEffect(() => {
        if(!datesModal){
            setDateToChangeInd(null)
        }
    },[datesModal])

    const removeDateFromList = (ind) => {
        if (datesModal) {
            let obj = [...modifyDates]
            obj.splice(ind, 1)
            setModifyDates( obj) 
        }
    }
    useEffect(()=>{
        if(surveyToUpdate && surveyToUpdate.dates){
            setModifyDates(surveyToUpdate.dates)
        }
    },[surveyToUpdate])

    const [survey, setSurvey] = useState(INITIAL_STATE_SURVEY)
    const [editBackground, setEditBackGround] = useState(false)
    const [backgroundList, setBackgroundList] = useState([])

    const toggleBackground =() => {
        setEditBackGround(false)
    }
    
    
    const getBackgrounds = async() => {
        const result = await executeRequest('sender/get/backgroundList', {})       
        if(result){
            setBackgroundList(result)
        }
    }

    useEffect(()=>{
        getBackgrounds()
    },[])

    const modifyBackground = (props) => {
        setEditBackGround(true)
        setSurvey(props)
    }

    const uploadBackground = async(payload) => {
        try {
            const responseJson = await fetch(baseURL + 'sender/uploadBackground', {
                method: 'POST',
                body: payload
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                getBackgrounds()
                return response;
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }
    const saveModifiedBackground = async(obj) => {
        let payload = {
            sentSurvey: survey.backgroundEdit,
            background: obj
        }
        const result = await executeRequest('sender/modify/background', payload)
        setSurvey((prevData)=>({
            ...prevData,
            backgroundEdit: null, 
            background: null
        }))
        setEditBackGround(false)
    }
    const saveBackground = async(obj) => {
        setSurvey((prevData)=>({
            ...prevData,
            background: obj, 
            backgroundSet: true
        }))
        let payload = {
            sentSurvey:survey,
            background:obj
        }
        const result = await executeRequest('sender/modify/background', payload)
        setEditBackGround(false)
    }

    const startEditing = (sentSurvey) => {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            surveyId: sentSurvey.mainSurveys[0].value
        }

        fetch(confs.url + 'fetch/mainSurveyForEdit', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => response.json())
            .then(resp => {

                if (Object.keys(resp).length > 0) {
                    onSurveyEdit(
                        {
                            categoryToEdit: resp,
                            selectedSurvey: {value:sentSurvey._id, label: sentSurvey.name},
                            start: true
                        }
                    );
                    history.push('/kartoitukset/luonti')

                }
            })

    }
    const onSurveyEdit = (payload) => {
        dispatch(ActionCreator.startSurveyEditFromSentSurveys(payload))
    } 

    useEffect(()=>{
        if(sentSurveys){
            setSortedSurveys(listSorter())
        }
    },[orderBy, sortOrder, sentSurveys])

    const listSorter = () => {
        let sortedArray = [...sentSurveys]
        let orderVal1 = sortOrder
        let orderVal2 = sortOrder === 1? -1 : 1

        if(orderBy === "name"){
            sortedArray.sort((a,b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase()) ? orderVal1 : orderVal2)
            return [...sortedArray]
        } else{
            if(orderBy !== "startDate"){
                sortedArray.sort((a,b) => (a[orderBy] > b[orderBy]) ? orderVal1 : orderVal2)
                return [...sortedArray]
            }
            else{
                sortedArray.sort((a,b) => (new Date(a.dates.startDate) > new Date(b.dates.startDate)) ? orderVal1 : orderVal2)
                return [...sortedArray]
            }
        }
    }

    const handleSortOrder = (attribute) => {
        if(orderBy !== attribute){
            setOrderBy(attribute)
            if(attribute === "name"){
                setSortOrder(1)
            } else {
                setSortOrder(-1)
            }
        } else {
            setSortOrder(sortOrder === -1? 1 : -1)
        }
    }

    return(
        <>
            <ConfirmationModal
                isOpen={confirmationModal}
                toggle={setConfirmationModal}
                confirm={endSurvey}
                data={data}
            />
            <ChangeSurveyTargetModal
                isOpen={modalIsOpen}
                toggle={toggleChangeSurveyTarget}
                confirm={saveTarget}
                businesses={businessList}
                sentSurvey={surveyToUpdate}
                onTargetChange={setTarget}
                selected={target}
            />
            <ChangeNameModal
                isOpen={nameModalActive}
                toggle={toggleNameChange}
                name={newName}
                onNameChange={setNewName}
                confirm={saveTarget}
                sentSurvey={surveyToUpdate}
            />
            <ModifyDateModal
                changeModifierDate={changeModifierDate}
                modifierFocus={onDatePickerFocusChange}
                focusedInput={modifierFocus}
                save={saveTarget}
                isOpen={datesModal}
                toggle={setDatesModal}
                dates={modifyDates}
                sDate={sDate}
                setIndex={changeModifiedDateIndex}
                modifyIndex={dateToChangeInd}
                removeDate={removeDateFromList}
                isDayBlocked={isDayBlocked}
                sentSurvey={surveyToUpdate}
            />    
            <BackgroundEditor
                isOpen={editBackground}
                toggle={toggleBackground}
                background={survey.background}
                list={backgroundList}
                save={survey.backgroundEdit ? saveModifiedBackground : saveBackground}
                uploadBackground={uploadBackground}
            />
            <div>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ ease: 'easeIn', duration: 0.3 }}
                    exit={{ opacity: 0, x: -50 }}
                    key={"sentSurveys"}
                >
                    <div>
                        <Row className="tab-header bigger-text" style={{ display: 'flex', alignItems: 'center'}}> 
                            <Col xs="8">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1em' }}>
                                    <Input type="search" className="search-bar-open" placeholder={"Hae"} value={filter} onChange={(event) => setFilter(event.target.value)} />
                                </div>
                                    <div style={{ display: 'flex' }}>
                                        {mainFilters && mainFilters.map((filterItem, key) =>
                                            <FilterButton key={filterItem.target + key} filterItem={filterItem} handleMainFilters={handleMainFilters} />
                                        )}
                                    </div>
                                    {surveyFilters &&
                                        <div style={{position: 'relative' }} className="filter-nav">
                                            <MenuContainer surveyFilters={surveyFilters} handleMainFilters={handleMainFilters} mainFilters={mainFilters} />
                                        </div>
                                    }
                                </div>
                            </Col>
                            <Col xs="4">
                                <button className="small-white-button wide-button project-survey-button" style={{ alignSelf: 'center', float: 'right' }} onClick={() => setIsModalOpen(true)} >
                                    Aktivoi- ja lisää kartoitus
                                </button>
                            </Col>
                        </Row>
                        <Row style={{ fontSize:'0.9rem', marginBottom:'0.25em' }}>
                            <Col xs="3" style={{ cursor:'pointer', marginLeft:'1.25em'}}>
                                <ListHeaderSorter label={"Nimi"} name={"name"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder}/>
                            </Col>
                            <Col xs="2" style={{ cursor:'pointer', marginLeft:'-1em'}}>
                                <ListHeaderSorter label={"Aktiivinen"} name={"startDate"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder} />
                            </Col>
                            <Col xs="2" style={{ cursor:'pointer', marginLeft:'-0.75em'}}>
                                <ListHeaderSorter label={"Vastauksia"} name={"answerCount"} sortOrder={sortOrder} orderBy={orderBy} handleSortOrder={handleSortOrder} />
                            </Col>
                            <Col xs="2" style={{marginLeft:'-0.5em'}}>Koodi/status</Col>
                            <Col></Col>
                        </Row>
                        {sentSurveys && sentSurveys.length > 0 ?
                            <Scrollbars
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                style={{ width: "100%", height: '500px' }}
                                className="custom-scrollbars"
                            >

                                {sortedSurveys.length > 0 && sortedSurveys.filter(survey => statusFilter(survey)).map((sentSurvey, index) => {
                                     let oddEven = index % 2 === 0 ? 'even-row' : 'odd-row'
                                     index++;
                                    if (sentSurvey.code) {
                                        if (sentSurvey.name.toLowerCase().includes(filter.toLowerCase()) || (sentSurvey.code && sentSurvey.code.toLowerCase().includes(filter.toLowerCase())) || sentSurvey.businessName.toLowerCase().includes(filter.toLowerCase())) {
                                            return (
                                                <div key={index}>
                                                    <ProjectSurveyListItem
                                                        sentSurvey={sentSurvey}
                                                        selectReport={selectReport}
                                                        toggleMenu={toggleMenu}
                                                        toggleChangeSurveyTarget={toggleChangeSurveyTarget}
                                                        toggleKartoitusEdit={startEditing}
                                                        toggleNameChange={toggleNameChange}
                                                        changeDate={toggleDateModal}
                                                        endSurvey={handleSurveyEnding}
                                                        index={index}
                                                        key={sentSurvey._id}
                                                        menuIndex={menuIndex}
                                                        modifyBackground={modifyBackground}
                                                        oddEven={oddEven}
                                                        projectId={projectId}
                                                        updateSurveys={updateChange}
                                                    />
                                                </div>
                                            )
                                        }
                                    }

                                })}

                            </Scrollbars>
                            :
                            <EmptyProjectList listText={"Ei kartoituksia"} />
                        }
                    </div>
                </motion.div>
            </div>
        </>
    )  
}

const MegaLoaderSpinner = () => {
    return (
        <Row className="loadingSpinnerRow">
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}
const ChangeSurveyTargetModal = (props) => {
    const { businesses, sentSurvey, isOpen, toggle, confirm, onTargetChange, selected } = props
    const customStyles = {
        control: (base, state) => ({
            ...base,
            marginBottom: '.5rem'
        }),
    }
    const businessList = (businesses && sentSurvey) ? businesses.filter(business => business.label !== sentSurvey.businessName) : {}

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Vaihda kartoituksen kohdeyritys</ModalHeader>
            <ModalBody>
                <div className="big-text" style={{ marginBottom: '1rem' }} >Tämänhetkinen kohdeyritys: <b>{sentSurvey && sentSurvey.businessName}</b> </div>

                <div className="big-text" style={{ marginBottom: '.5rem' }} >Valitse uusi kohdeyritys:</div>

                <Select
                    value={selected}
                    onChange={onTargetChange}
                    options={businessList}
                    placeholder="Valitse yritys"
                    isDisabled={false}
                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                    styles={customStyles}
                    classNamePrefix="select"
                />
            </ModalBody>
            <ModalFooter>
                <a onClick={toggle} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                <button onClick={() => confirm(sentSurvey, {target:"targetBusiness",value:selected})} className="small-white-button wide-button" >Vaihda</button>
            </ModalFooter>
        </Modal>
    )
}
const ConfirmationModal = (props) => {
    const{data } = props
    const [deleteText, setDeleteText] = useState("")
    const actionType = (data && data.type)? data.type : ""
    
    const toggleModal = () => {
        props.toggle(false)
        setDeleteText("")
    }

    const confirmDelete = () => {
        if(!actionType === "delete" || (actionType === 'delete' && deleteText === "poista")){
            props.confirm({data})
            setDeleteText("")
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={() => toggleModal()}>
            <ModalHeader>{(data && data.name)? data.name : ""} </ModalHeader>
            <ModalBody>
                {actionType === 'delete' ?
                    <>
                        <p>Oletko varma, että haluat poistaa aktivoidun kartoituksen? <b>Toimintoa ei voi peruuttaa!</b></p>
                        <div style={{ marginTop: '1em' }}>
                            <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                            <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                            <div className="email-check-box">
                                {props.deleteText === "poista"?
                                    <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                    :
                                    <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                }
                            </div>
                        </div>
                    </>
                    :
                    <p>Oletko varma, että haluat lopettaa aktivoidun kartoituksen?</p>
                }
            </ModalBody>
            <ModalFooter>
                <a onClick={() => toggleModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                <button onClick={() => confirmDelete()} className="small-white-button wide-button" style={{ backgroundColor: '#ed5652', color: '#fff' }}>{ actionType === 'delete' ? 'Poista' : 'Lopeta'}</button>{' '}
            </ModalFooter>
        </Modal>
    )
}
const ChangeNameModal = (props) => {
    const [name, setName] = useState("")
    useEffect(()=>{
        if(props.sentSurvey && props.sentSurvey.name){
            setName(props.sentSurvey.name)
        }
    },[props])
    return (
        <Modal isOpen={props.isOpen} toggle={() => props.toggle()}>
            <ModalHeader toggle={() => props.toggle()}>Muuta nimeä</ModalHeader>
            <ModalBody>
                <Label>Nimi</Label>
                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </ModalBody>
            <ModalFooter>
                <a onClick={() => props.toggle()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a> {' '}
                <button onClick={() => props.confirm(props.sentSurvey, {target:"name",value:name})} className="small-white-button wide-button" style={{ }}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}
const ModifyDateModal = props => {
    const {save, setIndex, focusedInput} = props
    const closeBtn = <button className="close" onClick={() => props.toggle(false)}>&times;</button>;
    const promptSave = () => {
        if(props.sentSurvey && props.sentSurvey.dates !== props.dates){
            for(var date of props.dates){
                date.startDate = moment.utc(date.startDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
                date.endDate = moment.utc(date.endDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
            }
            save(props.sentSurvey, {target:"dates",value:props.dates})
            
            NotificationManager.success('Päivämäärät muutettu onnistuneesti', 'Ilmoitus', 4000)
        } else {
            NotificationManager.error('Päivämäärille ei ole tehty muutoksia', 'Virhe', 4000)
        }
    }
    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className} >
                <ModalHeader toggle={props.toggle} close={closeBtn}>Aktiivisen aikavälin muutos</ModalHeader>
                <ModalBody style={{ paddingBottom: 0, paddingTop: 0 }}>
                    {props.dates ?
                        <div style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                            {props.modifyIndex !== null &&
                                <div style={{ margin: "1em 0" }}>
                                    <Row style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                                        <Col xl="6">
                                            <div className="lighter-text">Alku</div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="lighter-text">Loppu</div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                                        <Col xl="12">
                                            <DateRangePicker
                                                readOnly
                                                startDate={props.sDate.startDate} // momentPropTypes.momentObj or null,
                                                startDateId="unique_start_" // PropTypes.string.isRequired,
                                                endDate={props.sDate.endDate} // momentPropTypes.momentObj or null,
                                                endDateId="unique_enddate_" // PropTypes.string.isRequired,
                                                onDatesChange={({ startDate, endDate }) => props.changeModifierDate({ startDate, endDate })} // PropTypes.func.isRequired,
                                                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                onFocusChange={focusedInput => props.modifierFocus(focusedInput)}
                                                block
                                                hideKeyboardShortcutsPanel={true}
                                                isDayHighlighted={props.isDayBlocked}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row style={{ marginBottom: '0.5em', marginTop: '1em' }}>
                                <Col>
                                    <button className="small-white-button" onClick={() => setIndex(null, -1)}><i className="far fa-calendar-plus lighter-text"></i><span style={{paddingLeft: '0.5em'}}>Lisää aika</span></button>
                                </Col>
                            </Row >
                            {props.dates.length > 0 && props.dates.map((date, index) => {
                                let start = moment(new Date(date.startDate)).local().format('DD.MM.YYYY')
                                let end = moment(new Date(date.endDate)).local().format('DD.MM.YYYY')
                                let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row';
                                let highlight = props.modifyIndex === index ? " highlight-row" : "";

                                return <Row key={index} style={{ marginBottom: '0.5em', marginTop: '1em' }} className={"modify-dates-list " + oddEvenClass + highlight} >
                                    <Col md="10">{start} - {end}</Col>
                                    <Col md="1" onClick={() => props.setIndex(date, index)}><i style={{ cursor: 'pointer' }} title="Edit dates" className="far fa-edit"></i></Col>
                                    {props.dates.length > 1 &&
                                        <Col md="1" onClick={() => props.removeDate(index)}><i style={{ cursor: 'pointer' }} className="far fa-trash-alt"></i></Col>
                                    }
                                </Row>
                            })}
                        </div>
                        :
                        <div style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                            <Row style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                                <Col xl="6">
                                    <h5>Alku</h5>
                                </Col>
                                <Col xl="6">
                                    <h5>Loppu</h5>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                                <Col xl="12">
                                    <DateRangePicker
                                        readOnly
                                        startDate={props.sDate.startDate} // momentPropTypes.momentObj or null,
                                        startDateId="unique_startdate_" // PropTypes.string.isRequired,
                                        endDate={props.sDate.endDate} // momentPropTypes.momentObj or null,
                                        endDateId="unique_enddate" // PropTypes.string.isRequired,
                                        onDatesChange={({ startDate, endDate }) => props.changeModifierDate({ startDate, endDate })} // PropTypes.func.isRequired,
                                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={focusedInput => props.modifierFocus(focusedInput)}
                                        block
                                        hideKeyboardShortcutsPanel={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => props.toggle()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a> {' '}
                    <button onClick={() => promptSave()} className="small-white-button wide-button" style={{ }}>Tallenna</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
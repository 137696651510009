export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const SENT_SURVEYS = "SENT_SURVEYS";
export const REQUEST_PERMISSION = "REQUEST_PERMISSION";
export const SET_COLLABORATIONS = "SET_COLLABORATIONS";
export const END_SURVEY = "END_SURVEY";
export const FETCH_ANON = "FETCH_ANON";
export const FETCH_TASKS = "FETCH TASKS";
export const FETCH_TASKS_DASHBOARD = 'FETCH_TASKS_DASHBOARD'
export const REMOVE_TASK_FROM_LIST = 'REMOVE_TASK_FROM_LIST'
export const CLEAR_TASK_LIST = "CLEAR_TASK_LIST"
export const SET_TASK_IN_LIST = "SET_TASK_IN_LIST";
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import * as ActionCreator from '../../../../../store/index';
import DataWindow from './subComponents/DataWindow';
import './kartoitus.css';
/*
*    This Component is the container responsible for displaying the tools needed for new Kartoitus (Question groups) and can be recycled for dialog building purposes
*                                                                       Redux 
*   Props                                                             -----------
*   categories - Array of existing categories fetched from server and mapped to props of this component, pushed down to KartoitusTool to make a list of categories as props
*   startBuilding - boolean, true if modifying existing category or starting to add new category, false otherwise. Event called from KartoitusTool
*   
*   Dispatches
*   onMountFetchCategories is fetchCategories, calls ActionCreator at Kartoitus/ActionCreator and dispatches an event to fetch all categories from the server.
*/

class KartoitusBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: {},
            numberOfCategories: -1,
            groupIndex: -1,
            questionIndex: -1,
            group: {},
            groupDataChanged: false,
            question: {},
            startBuilding: false,
            view: 'category'
        }
        this.setCategoryData = this.setCategoryData.bind(this);
        this.setQuestionData = this.setQuestionData.bind(this);
        this.setGroupData = this.setGroupData.bind(this);
        this.changeToCategoryView = this.changeToCategoryView.bind(this);
        this.prepareGroupDeletionData = this.prepareGroupDeletionData.bind(this);
    }

    resetState = () => {
        let object = {
            category: {},
            numberOfCategories: -1,
            groupIndex: -1,
            questionIndex: -1,
            group: {},
            groupDataChanged: false,
            question: {},
            startBuilding: false,
            view: 'category'
        }
        this.setState(object);
    }

    componentDidMount() {
        //this.props.resetBuilder();
        if (this.props.startBuilding) {
            this.setState({ view: 'category' });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCategory !== undefined && prevProps.currentCategory.questionGroups !== undefined) {
            if (prevProps.currentCategory.questionGroups.length > 0 && (this.props.currentCategory.questionsGroups !== undefined && this.props.currentCategory.questionGroups.length === 0)) {
                this.setState({ view: 'category' });
            }
        }

        if (this.props.startBuilding && this.state.view !== 'category') {
            this.setState({ view: 'category' });
        }
    }

    componentWillUnmount() {
        this.props.onUnmountResetRedux();
    }

    changeViewToGroup = () => {
        this.setState({ view: 'group' });
    }

    setCategoryData(index, data) {
        //-1 index means new category
        if (index !== -1) {
            let object = {
                ...data
            };
            this.props.setCategory(object);
            this.setState({ category: object, categoryIndex: index, startBuilding: true, view: 'category' });
        } else {
            let newCategory = {

            };
            this.props.setCategory(newCategory);
            this.setState({ category: newCategory, categoryIndex: index, startBuilding: true, view: 'category' });
        }
    }

    setGroupData(index) {
        //-1 index means new group
        if (index !== -1) {
            let object = {
                ...this.props.currentCategory.questionGroups[index]
            }
            this.props.setQuestionGroup({ currentQuestionGroup: object, currentQuestion: {} });
            this.setState({ group: object, question: {}, groupIndex: index, view: 'group' });
        } else {
            let newGroup = {
                title: "",
                text: "",
                feedbacks: [
                    {
                        color: '#B5E413',
                        range: [0, 25],
                        text: ""
                    }
                ]
            };
            this.props.setQuestionGroup({ currentQuestionGroup: newGroup, currentQuestion: {} });
            this.setState({ group: newGroup, question: {}, groupIndex: index, view: 'group' });
        }
    }

    setQuestionData(group, question) {
        /*let groupData = {
            ...this.props.currentCategory.questionGroups[groupIndex]
        };
        
        if(questionIndex != -1) {
            let object = {
                ...this.props.currentCategory.questionGroups[groupIndex].questions[questionIndex]
            }
            this.setState({question: object, group: groupData, groupIndex: groupIndex, questionIndex: questionIndex, view: 'question'});
            this.props.setQuestion(object);
        } else {
            let newQuestion = {

            };
            

            this.setState({question: newQuestion, group: groupData, groupIndex: groupIndex, view: 'question'});
            this.props.setQuestion(newQuestion);
        }*/

        let payload = {
            currentQuestionGroup: group,
            currentQuestion: question
        };

        this.props.setQuestion(payload);
        this.setState({ view: 'question' });
    }

    //Prepares question data and sends it to the actionCreator, removes question!
    prepareQueestionDeletionData = data => {
        let params = {
            ...data
        };

        this.props.onQuestionDelete(params);
    }

    //Prepares group data and sends it to actionCreator, removes questionGroup!
    prepareGroupDeletionData(data) {
        let params = {
            ...data
        };
        this.props.onGroupDelete(params);
    }

    changeToCategoryView() {
        let payload = {
            currentQuestionGroup: {},
            currentQuestion: {}
        };

        this.props.setQuestion(payload);
        this.setState({ view: 'category' });
    }

    changeToFeedbacksView = () => {
        let payload = {
            currentQuestionGroup: {},
            currentQuestion: {}
        };

        this.props.setQuestion(payload);
        this.setState({ view: 'feedbacks' });
    }

    reorderQuestions = (reorderedCategoryTree) => {
        this.props.reorderSurveyQuestions(reorderedCategoryTree)
    }

    getIndexOfGroup = group => {
        if (this.props.currentQuestionGroup !== undefined && Object.keys(this.props.currentQuestionGroup).length > 0) {
            if (this.props.currentCategory.questionGroups !== undefined) {
                return this.props.currentCategory.questionGroups.indexOf(group);
            } else {
                return -1;
            }
        } else {
            return -1;
        }
    }

    render() {
        const activeGroupIndex = this.props.currentCategory.questionGroups !== undefined ? this.getIndexOfGroup(this.props.currentQuestionGroup) : -1
        return (
            <Container fluid>
                <div className="whitebox" style={{ marginTop: '1rem', padding: '0 1rem' }}>
                    <DataWindow 
                        setGroupData={this.setGroupData}
                        setQuestionData={this.setQuestionData}
                        addQuestion={this.setQuestionData}
                        categoryEdit={this.changeToCategoryView}
                        deleteGroup={this.prepareGroupDeletionData}
                        activeGroupIndex={activeGroupIndex}
                        view={this.state.view}
                        category={this.props.currentCategory}
                        group={this.props.currentQuestionGroup}
                        originalGroup={this.props.currentQuestionGroup}
                        question={this.props.currentQuestion}
                        originalQuestion={this.props.currentQuestion}
                        addGroup={this.setGroupData}
                        questionDelete={this.prepareQueestionDeletionData}
                        changeViewToGroup={this.changeViewToGroup}
                        categories={this.props.categoryOptions} 
                        changeToFeedbacksView={ this.changeToFeedbacksView }
                    />
                </div>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories: state.kartoitus.categories,
        categoryOptions: state.kartoitus.categoryOptions,
        currentCategory: state.kartoitus.currentCategory,
        startBuilding: state.kartoitus.categoryEdit,
        createdCategory: state.kartoitus.createdCategory,
        currentQuestion: state.kartoitus.currentQuestion,
        currentQuestionGroup: state.kartoitus.currentQuestionGroup
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGroupDelete: (params) => dispatch(ActionCreator.deleteQuestionGroup(params)),
        onQuestionDelete: (params) => dispatch(ActionCreator.deleteQuestion(params)),
        setCategory: (params) => dispatch(ActionCreator.setCategory(params)),
        setQuestion: (params) => dispatch(ActionCreator.setQuestion(params)),
        setQuestionGroup: (params) => dispatch(ActionCreator.setQuestionGroup(params)),
        onSurveyCheck: (params) => dispatch(ActionCreator.getSurveyDocument(params)),
        reorderSurveyQuestions: (params) => dispatch(ActionCreator.reorderSurveyQuestions(params)),
        onUnmountResetRedux: (params) => dispatch(ActionCreator.resetBuilderRedux(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KartoitusBuilder);
import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Input
} from 'reactstrap'
import CompanyNpsScore from './CompanyNpsScore'

const BusinessSummary = (props) => {
    const {tags, selectedBusiness} = props
    const [companyScoreColor, setCompanyScoreColor] = useState('#919090')
    useEffect(()=>{
        if(props.companyScore && props.companyScore.totalScore){
            checkCompanyScore()
        }
    },[props.companyScore])

    const checkCompanyScore = () => {
        if(props.companyScore.totalScore >= 75){
            setCompanyScoreColor('#b5e413')
        }
        if(props.companyScore.totalScore < 75 && props.companyScore.totalScore >= 45 ){
            setCompanyScoreColor('#ffcc00')
        }
        if(props.companyScore.totalScore < 45){
            setCompanyScoreColor('#ed5652')
        }
        
    }

    return (
        <Col xs={{ size: 12 }} style={{}}>
            <div className="dashboard-big-box client-color" id="client-dashboard-tourId" style={{ display: 'flex', flexDirection: 'column' }}>
                <Row className="" style={{margin: '-0.5em -0.5em 0 -0.5em', padding: '0.5em 0 0em 0', borderRadius: '30px 30px 0 0', backgroundColor: '#fff' }}>
                    <Col className="business-summary-column" style={{display: 'flex', flexWrap: 'wrap', textAlign: 'left' }}>
                        {props.business ?<h5 className='bold' title={props.business.businessName} style={{ cursor:'pointer'}}>{props.business.businessName}</h5> : <h5></h5>}
                    </Col>
                    {props.modulesInUse.surveyManagement &&
                        <Col className="business-summary-column business-summary-prefix">
                            <div className="business-summmary-title">
                                <p>Palaute</p>
                            </div>
                        </Col>
                       
                    } 
                    {props.modulesInUse.surveyManagement &&
                        <Col className="business-summary-column business-summary-prefix">
                            <div className="business-summmary-title">
                                <p>NPS</p>
                            </div>
                        </Col>
                    }
                    {(selectedBusiness && selectedBusiness.hubSpot) &&
                        <Col className="business-summary-column business-summary-prefix">
                            <div className="business-summmary-title">
                                <p>Odotetut EUR <i className="far fa-question-circle" title="HubSpot tavoiteltujen diilien arvo."></i></p>  
                            </div>
                        </Col>
                    }
                    {(selectedBusiness && selectedBusiness.hubSpot) &&
                        <Col className="business-summary-column business-summary-prefix">
                            <div className="business-summmary-title">
                                <p>Voitetut EUR <i className="far fa-question-circle" title="HubSpot voitettujen diilien arvo."></i></p>
                            </div>
                        </Col>
                    }
                    <Col>
                    </Col>
                </Row>
                <Row style={{ backgroundColor: '#fff', margin: '-0.5em', padding: '0.5em 0 0.25em 0', borderRadius: '0 0 10px 10px' }}>
                    {props.business &&
                        (<Col className="business-summary-column" style={{display: 'flex', flexWrap: 'wrap', textAlign: 'left' }}>
                            <div className="" id="ytj-info-tour" style={{ height: '100%' }}>
                                <p className="business-id" style={{marginTop: '0.5em'}}>{props.business.businessID}</p>
                                {props.YTJInfo &&
                                    <>
                                        {props.YTJInfo && props.YTJInfo.mainLine && props.YTJInfo.mainLine.name && 
                                            <p className="business-id" style={{marginTop: '0.5em'}}>{props.YTJInfo.mainLine.name}</p>
                                        }
                                        {props.YTJInfo && props.YTJInfo.mainAddress && props.YTJInfo.mainAddress.street && props.YTJInfo.mainAddress.street.length > 5 ?
                                            <div style={{ display: 'flex' }}>
                                                {props.YTJInfo.mainAddress.street && <p className="business-id" style={{marginTop: '0.5em', marginRight: '0.5em'}}>{props.YTJInfo.mainAddress.street},</p>}
                                                {props.YTJInfo.mainAddress.city && <p className="business-id" style={{marginTop: '0.5em'}}>{props.YTJInfo.mainAddress.city}</p>}                                            
                                            </div>
                                            :
                                            (props.YTJInfo && props.YTJInfo.postAddress? 
                                                <div style={{ display: 'flex' }}>
                                                    {props.YTJInfo.postAddress.street && <p className="business-id" style={{marginTop: '0.5em', marginRight: '0.5em'}}>{props.YTJInfo.postAddress.street},</p>}
                                                    {props.YTJInfo.postAddress.city && <p className="business-id" style={{marginTop: '0.5em'}}>{props.YTJInfo.postAddress.postCode? props.YTJInfo.postAddress.postCode : " "} {props.YTJInfo.postAddress.city}</p>}
                                                </div>
                                                :
                                                <></>
                                            )
                                        }
                                        {props.YTJInfo && props.YTJInfo.contactDetails && props.YTJInfo.contactDetails.map((detail, key)=> {
                                            if(detail.type.toLowerCase() === 'kotisivun www-osoite'){ 
                                                let linkAddress = detail.value
                                                if(!linkAddress.startsWith('http://') || !linkAddress.startsWith('https://')) {
                                                    linkAddress = '//'+linkAddress
                                                }
                                                return(
                                                    <a key={key} href={linkAddress} className="business-id" target="_blank" style={{marginTop: '0.5em'}}>{detail.value}</a>
                                                )
                                            }
                                        })}
                                    </>
                                } 
                            </div>     
                        </Col>)
                       
                    }                     
                    {props.modulesInUse.surveyManagement && (
                    <>
                        <Col className="business-summary-column business-summary-prefix">
                            <div>
                                {(props.mounted && props.companyScore) ?
                                    <>
                                        <div className="border-ball" style={{ borderColor: companyScoreColor }} >
                                            <p className="summary-ball-font">{props.companyScore.totalScore}</p>
                                        </div>
                                    </>
                                    :
                                    <MegaTinyLoaderSpinner />
                                }
                            </div>
                        </Col>
                        <Col className="business-summary-column business-summary-prefix">
                                            
                            {(props.mounted && props.business && props.business.nps) ?
                                <div className="" >
                                    <CompanyNpsScore data={props.business.nps}/>
                                </div>
                                :
                                <MegaTinyLoaderSpinner />
                            }
                        </Col>
                        {(selectedBusiness && selectedBusiness.hubSpot) &&
                            <Col className="business-summary-column business-summary-prefix">
                                <div>
                                    {((props.mounted && props.businessGoals) && props.businessGoals.inProgressValues !== undefined) ?
                                        <h3 className="bold business-summary-value">{props.businessGoals.inProgressValues} €</h3>
                                        :
                                        <MegaTinyLoaderSpinner />
                                    }
                                </div>
                            </Col>
                        }
                        {(selectedBusiness && selectedBusiness.hubSpot) &&
                            <Col className="business-summary-column business-summary-prefix">
                                <div>
                                    {((props.mounted && props.businessGoals) && props.businessGoals.closedValues !== undefined ) ?
                                        <h3 className="bold cursor-p business-summary-value">{props.businessGoals.closedValues} €</h3>
                                        :
                                        <MegaTinyLoaderSpinner />
                                    }
                                </div>
                            </Col>
                        }
                        <Col>
                            {props.business &&
                                <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '10em', flexWrap: 'wrap'}}>
                                    {tags&& tags.slice(0, 6).map((tag, i) => {                        
                                        return(<div key={i} className="summary-tags"><p key={i} className="tag-display tag-text client-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                    })}
                                    {tags && tags.length > 6 &&  
                                        <div className="summary-tags">
                                            <i className="material-icons" style={{ marginBottom: '-1em' }} title={tagsToMore(tags)}>more_horiz</i>
                                        </div>
                                    }
                                </div>  
                            }   
                        </Col>
                    </>)}
                </Row>
            </div>
        </Col>
    )
}

const tagsToMore = (tags) => {
    var tagss = ""
    if(tags !== undefined  ){
        tags.slice(6, tags.length).filter(t => {tagss = tagss + "  " + t.label.toString()})
        return(tagss)
    }
}

const MegaTinyLoaderSpinner = () => {
    return (
        <Row style={{ marginBottom: '2em' }}>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '2rem', height: '2rem', marginTop: '0.5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

export default BusinessSummary
import React, { Component} from 'react';
import {
    Row,
    Col
  } from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

export default class TaskContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    getTitleBasedOnProps = () => {
        if(this.props.headerSize === 2) {
            return (
                "Tarkistuspisteet"
            )
        }
    }

    render() {
        return (
            <Row className={this.props.additionalClass} style={{paddingTop: '1rem'}}>
                <Col md={{size: 12}}>

                    <h5 style={{marginBottom: 0}}>
                        <button className="small-white-button lighter-text wide-button" style={{margin: '.25rem 0 .5rem .5rem', float: 'right'}}
                            onClick={() => this.setState({isOpen: !this.state.isOpen})}>{this.state.isOpen ? "Piilota tarkistuspisteet" : "Näytä tarkistuspisteet"}
                        </button>
                        {this.getTitleBasedOnProps()}
                    </h5>

                    <hr style={{clear: 'both'}}/>
                    {this.state.isOpen &&
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%" }}
                        className="custom-scrollbars"
                        autoHeight
                        autoHeightMin={100}
                        autoHeightMax={300}
                        >
                        {(this.props.tasks && this.props.tasks.length > 0) && 
                            this.props.tasks.map((task, index) => {
                                let deadline = task.deadline ? moment(new Date(task.deadline)).format('DD.MM.YYYY') : ''
                                let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'

                                return <div className={oddEvenClass + ' selectable-row'} style={{ paddingLeft: '2rem' }} key={index} onClick={() => this.props.modifyTask(task)}>
                                <Row className='task-list-content'>
                                    <Col xs={{ size: 6 }}>
                                        <p>{task.taskTitle}</p>
                                    </Col>
                                    <Col xs={{ size: 3 }}>
                                        <p>{task.targetCompanyName}</p>
                                    </Col>
                                    <Col xs={{ size: 3 }}>
                                        <p>{deadline}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ size: 12 }}>
                                        <p className="lighter-text">{task.plainTextTask}</p>
                                    </Col>
                                </Row>
                                </div>
                            })
                            }
                    </Scrollbars>
                    }
                </Col>
            </Row>
        )
    }
}
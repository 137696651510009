import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button
} from 'reactstrap'

import { archiveTrelloCard, updateChecklistTrelloCard } from '../../actions/ActionCreator'
import {TrelloChecklistContainer} from './TrelloChecklistContainer'
const ViewCardModal = props => {
  const { toggle, isOpen, card, businessId, archiveCard, updateChecklistCard } = props
  const description = card.desc === '' ? 'Ei kuvausta' : card.desc

  const dispatch = useDispatch()

  const archive = card => {
    const payload = {
      cardId: card.id,
      listId: card.idList,
      businessId: businessId
    }
    archiveCard !== undefined ? archiveCard(payload) : dispatch( archiveTrelloCard( payload ) )
    
    props.toggle()
  }

  const checklistItemHandler = (idCheckItem, state) => {
    const payload = {
      cardId: card.id,
      idCheckItem: idCheckItem,
      state: state,
      listId: card.idList,
      businessId: businessId
    }

    updateChecklistCard !== undefined ? updateChecklistCard(payload) : dispatch(updateChecklistTrelloCard(payload));
  }

  return (
    <Modal isOpen={ isOpen } toggle={ toggle } size="lg" centered>
      <ModalBody>
        <Row>
          <Col xs={ { size: 8 } }>
            
            <Row>
              <Col>
                <h2>{ card.name }</h2>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <h5>Jäsenet</h5>
                { card.memberList.map( ( member, index ) => {
                  return <span key={ index } title={ member.fullName } className='trello-member' style={{ float: 'left' }}>{ member.initials }</span>
                } ) }
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <h5>Nimilaput</h5>
                { card.labels && card.labels.map( ( label, index ) => {
                  return (
                    <span key={ index } title={ label.name } className={ `trello-label trello-label-${ label.color }` }>
                      <span className='trello-label-text'>{ label.name }</span>
                    </span>
                  )
                } ) }
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <h5>Kuvaus</h5>
                <p>{ description }</p>
              </Col>
            </Row>
            {card.checklists && 
            <Row className="mt-5">
              <Col>
                {card.checklists.map(checklist => {
                  return <TrelloChecklistContainer checklist={checklist} key={checklist.id} handleUpdate={checklistItemHandler}/>
                })}
              </Col>
            </Row>
            }
          </Col>
    
          <Col xs={ { size: 4 } } style={ { backgroundColor: 'rgba(200, 200, 200, 0.1)', marginTop: '-1rem', marginBottom: '-1rem', paddingTop: '2rem', paddingBottom: '2rem' }}>
            <Row>
              <Col>
                <Button onClick={ () => archive( card ) } style={{ width: '100%' }}>Arkistoi <i className="fas fa-archive"></i></Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={ props.toggle }>Sulje</a>
      </ModalFooter>
    </Modal>
  )
}

export default ViewCardModal
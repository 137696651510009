import * as ActionTypes from '../actions/Login/ActionTypes';

const initialState = {
    isAuthenticated: false,
    authenticationFailed: false,
    agreement: false,
    news: false,
    showNews: true,
    userinfo: {
        company: ""
    },
    downloadNew: false,
    userType: null,
    favourites: [],
    helpLinks: null
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.CHECK_LOGIN: 
            return {
                ...state,
                ...action.payload
            };
        case ActionTypes.AUTH_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                authenticationFailed: true
            }
        case ActionTypes.AGREEMENT: {
            return {
                ...state,
                agreement: action.payload
            }
        }
        case ActionTypes.SET_FIRST_LOGIN_DONE:
            return{
                ...state,
                firstLoginDone: action.payload
             }
        case ActionTypes.NEWS_OK: {
            return {
                ...state,
                news: true,
                showNews: false
            }
        }
        case ActionTypes.CHANGE_LOGO:
            return {
                ...state,
                logo: action.payload,
                downloadNew: true
            }
        case ActionTypes.CHANGE_DOWNLOAD_STATUS:
            return {
                ...state,
                downloadNew: false
            }
        case ActionTypes.UPDATE_FAVOURITES: 
            return {
                ...state,
                favourites: [...action.payload]
            };
        case ActionTypes.SET_HELPLINKS:
            return{
                ...state,
                helpLinks: action.payload
            }
        case ActionTypes.SET_TRELLO_KEYS:
            return{
                ...state,
                trelloConnection: action.payload
            }
        default: {
            return state;
        }
        
    }
}


export default reducer;
import {confs} from '../../../../../../../../modules/config';
import {NotificationManager} from 'react-notifications';
import * as ActionCreator from '../../../../../../../../store/index';

export const STORE_CATEGORY = "STORE_CATEGORY";

export const storeCategory = data => {
    return {
        type: STORE_CATEGORY,
        payload: data
    }
};

export const fetchSurveyStack = data => dispatch => {
    let payload = {
        ...data,
        jwtToken:  window.sessionStorage.getItem("token")
    }

    fetch(confs.url + 'fetch/mainSurveyForEdit', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then(response => response.json())
    .then((responseJson) => {
        if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
            dispatch(ActionCreator.authFailed())
        } else {
            dispatch(storeCategory(responseJson))
        }  
    })
}

const EDIT_CATEGORY_FROM_SENT_SURVEYS = "EDIT_CATEGORY_FROM_SENT_SURVEYS";

export const startSurveyEditFromSentSurveys = data => {
    return {
        type: EDIT_CATEGORY_FROM_SENT_SURVEYS,
        payload: data
    }
};

const modifySurveyData = (survey, changes) => {
    let changedSurvey = {
        ...survey
    };

    for(let i = 0; i < changes.length; i++) {
        Object.keys(changes[i]).forEach((key, index) => {
            changedSurvey = {
                ...changedSurvey,
                [key]: changes[i][key]
            }
        });
    };
    
    return changedSurvey
}

export const modifyCategory = params => dispatch => 
{
    let payload = {
        ...params,
        jwtToken:  window.sessionStorage.getItem("token")
    }
    dispatch(ActionCreator.loadingStatus(true))
    fetch(confs.url + 'modify/category', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then(response => response.json())
    .then(responseJson => {
        if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
            dispatch(ActionCreator.authFailed())
        } else {
            if(responseJson.status === 'ok') {
                NotificationManager.success("Muutokset tallennettu", "", 3000);
                if(responseJson.changedFields !== undefined) {
                dispatch(storeCategory(modifySurveyData(responseJson.category, responseJson.changedFields)));
                } else {
                dispatch(storeCategory(responseJson.category));
                }
                dispatch(ActionCreator.loadingStatus(false))
            } else {
                NotificationManager.error("Tallennus epäonnistui", "", 3000);
                dispatch(ActionCreator.loadingStatus(false))
            }
        } 
    }).catch(error => {
        NotificationManager.error("Tallennus epäonnistui", "", 3000);
        dispatch(ActionCreator.loadingStatus(false))
    })
}

export const modifySentSurveyCategory = params => dispatch => {
    let payload = {
        ...params,
        jwtToken:  window.sessionStorage.getItem("token")
    }
    dispatch(ActionCreator.loadingStatus(true))
    fetch(confs.url + 'modify/sentSurveyCategory', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then(response => response.json())
    .then(responseJson => {
        if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
            dispatch(ActionCreator.authFailed())
          } else {
                if(responseJson.status === 'ok') {
                    NotificationManager.success("Muutokset tallennettu", "", 3000);
                    if(responseJson.changedFields !== undefined) {
                        dispatch(storeCategory(modifySurveyData(responseJson.category, responseJson.changedFields)));
                    } else {
                        dispatch(storeCategory(responseJson.category));
                    }
                    dispatch(ActionCreator.loadingStatus(false))
                } else {
                    NotificationManager.error("Tallennus epäonnistui", "", 3000);
                    dispatch(ActionCreator.loadingStatus(false))
                }
          }      
    }).catch(error => {
        NotificationManager.error("Tallennus epäonnistui", "", 3000);
        dispatch(ActionCreator.loadingStatus(false))
    })
}

export const uploadImg = (data) => dispatch => {
    fetch(confs.url + 'send/uploadImage', {
      method: 'POST',
      body: data.form
    })
    .then(res => res.json())
    .then(response => {
        if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
            //dispatch(ActionCreator.authFailed())
        } else {
                if(response.success) {
                NotificationManager.success("Kuva ladattu onnistuneesti","", 2500);
                
                if(data.surveyId === "") { 
                    let payload = {
                        categoryId: data.id,
                        incomingData: {
                            dialogImage: response.logo.urlid
                        }
                    }
                    dispatch(modifyCategory(payload))
                } else {
                    let payload = {
                        categoryId: data.categoryId,
                        surveyId: data.surveyId,
                        incomingData: {
                            dialogImage: response.logo.urlid
                        }
                    }
                    dispatch(modifySentSurveyCategory(payload))
                }
                return response.logo.urlid;
            } else {
                switch(response.error) {
                case "IMG_SIZE":
                    NotificationManager.error('Tiedoston koko täytyy olla vähemmän kuin 14MB', 'Logon tallennus epäonnistui', 2000);
                    return;
                case "NOT_IMG":
                    NotificationManager.error('Tiedoston täytyy olla kuvatiedosto', 'Logon tallennus epäonnistui', 2000);
                    return;
                default:
                    NotificationManager.error('Jokin meni pieleen :(', 'Tallennus epäonnistui', 2000);
                    return;
                }
            }
        }
    })
}
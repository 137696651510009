import * as ActionTypes from '../../../Utilities/DiagramBuilder/Actions/ActionTypes'

const initialState = {
    businessExcels: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_BUSINESS_EXCELS:
            return {
                ...state,
                businessExcels: action.payload
            }
            
        case ActionTypes.DELETE_CHART:
            let newState = state.businessExcels.filter(e => e.id !== action.payload)
            return {
                ...state,
                businessExcels: newState
            }
        default:
            return state;
    }
}

export default reducer;
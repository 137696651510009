import React, { Component } from 'react'
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
  RichUtils
 } from 'draft-js'
import Editor, { composeDecorators } from 'draft-js-plugins-editor'
import VideoAdd from './modules/addVideoButton'
import ImageAdd from './modules/addImageButton'

/** Draft-js plugins */
import createInlineToolbarPlugin from './modules/draft-js-inline-toolbar-plugin'
import './modules/draft-js-inline-toolbar-plugin/lib/plugin.css'

import createLinkPlugin from 'draft-js-anchor-plugin'
import 'draft-js-anchor-plugin/lib/plugin.css'

import createEmojiPlugin from 'draft-js-emoji-plugin'
import 'draft-js-emoji-plugin/lib/plugin.css'

import createVideoPlugin from 'draft-js-video-plugin'

import createImagePlugin from 'draft-js-image-plugin'

import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin'
import 'draft-js-mention-plugin/lib/plugin.css'

import createAlignmentPlugin from 'draft-js-alignment-plugin'
import 'draft-js-alignment-plugin/lib/plugin.css'

import createFocusPlugin from 'draft-js-focus-plugin'

import createResizeablePlugin from 'draft-js-resizeable-plugin'
import 'draft-js-image-plugin/lib/plugin.css'

import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton'

import createHashtagPlugin from 'draft-js-hashtag-plugin';

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton
} from 'draft-js-buttons'

// import toolbarStyles from './styles/toolbarStyles.css';
// import buttonStyles from './styles/buttonStyles.css'
import './texteditor.css'
import { confs } from '../../modules/config';
import { indexOf } from '@amcharts/amcharts4/.internal/core/utils/Array'
import AddColorButton from './modules/AddBackgroundColorButton'
import { executeRequest } from '../requestHandler/dataHandler'

// draft-js-buttons only has headlinethree (h3) button so let's make a four 
const HeadlineFourButton = createBlockStyleButton({
  blockType: 'header-four',
  children: 'H4',
})

class TextEditor extends React.Component {
  constructor(props) {
    super(props)

    const textContent = props.content || ''
    let contentState = ''

    if(typeof textContent === 'string') {
      contentState = ContentState.createFromText(textContent)
    } else {
      contentState = convertFromRaw(textContent)
    }

    this.state = {
      editorState: EditorState.createWithContent(contentState),
      editorReadOnly: props.readOnly || false,
      additionalClass: props.additionalClass || "",
      suggestions: props.suggestions || null,
      arrayOfImages: [],
      showButtons: false
    }

    this.editor = React.createRef()

    // kokkeiltu saada kustomi tyylit tälle paska inline toolbaarille, kun menee jokaisen muun elementin alle saatana
    // this.inlineToolbarPlugin = createInlineToolbarPlugin({
    //   theme: { buttonStyles, toolbarStyles }
    // });
     this.inlineToolbarPlugin = createInlineToolbarPlugin()
    const { InlineToolbar } = this.inlineToolbarPlugin

    this.linkPlugin = createLinkPlugin({
      placeholder: 'Linkin osoite',
      linkTarget: '_blank'
    })

    this.hashtagPlugin = createHashtagPlugin()

    this.emojiPlugin = createEmojiPlugin()
    const { EmojiSuggestions, EmojiSelect } = this.emojiPlugin;

    this.focusPlugin = createFocusPlugin()
    this.resizePlugin = createResizeablePlugin()
    this.alignmentPlugin = createAlignmentPlugin()

    const { AlignmentTool } = this.alignmentPlugin

    const decorator = composeDecorators(
      this.alignmentPlugin.decorator,
      this.focusPlugin.decorator,
      this.resizePlugin.decorator
    )

    const imageClasses = !props.readOnly ? 'draft-js-image' : ''

    this.videoPlugin = createVideoPlugin()
    this.imagePlugin = createImagePlugin({ 
      decorator, 
      theme: {
        image: imageClasses
      }
    })

    this.mentionPlugin = createMentionPlugin({
      theme: {
        mention: 'mention',
        mentionSuggestions: 'mention-suggestions',
        mentionSuggestionsEntry: 'mention-suggestions-entry',
        mentionSuggestionsEntryFocused: 'mention-suggestions-entry-focused',
        mentionSuggestionsEntryText: 'mention-suggestions-entry-text'
      }
      // if you have the courage to try and position the suggestions to open up when there's not enough room below, here's a link to point you in (probably) the right direction: https://github.com/draft-js-plugins/draft-js-plugins/issues/665#issuecomment-289681856
      // holy moly what a "työmaa"
    })

    this.plugins = [
      this.inlineToolbarPlugin, this.linkPlugin,
      this.emojiPlugin, this.videoPlugin, this.imagePlugin,
      this.mentionPlugin, this.alignmentPlugin, this.focusPlugin,
      this.resizePlugin, this.hashtagPlugin
    ]

    this.components = {
      InlineToolbar,
      EmojiSuggestions,
      EmojiSelect,
      AlignmentTool
    }

    this.handleDroppedFiles = this.handleDroppedFiles.bind(this)
  }

  componentDidUpdate(prevProps) {   
    if(prevProps.editorId !== this.props.editorId) {
      if( this.editor && !this.props.autoFocus) {
        this.editor.current.blur()
      }
     
      const textContent = this.props.content || ''
      let contentState = ''

      if (typeof textContent === 'string') {
        contentState = ContentState.createFromText(textContent)
      } else {
        contentState = convertFromRaw(textContent)
      }

      let editorState = "";
      // if(this.props.editorId !== undefined && (this.props.editorId.includes("-categoryTitle") || this.props.editorId.includes("-groupTitle") || this.props.editorId.includes("-questionTitle")) && typeof textContent === 'string' && textContent !== "") {
      //   editorState = EditorState.moveFocusToEnd(EditorState.createWithContent(contentState));
      // } else {
      //   editorState = EditorState.createWithContent(contentState)
      // }
      editorState = EditorState.createWithContent(contentState)
      this.setState({
        editorState: editorState,
        editorReadOnly: this.props.readOnly || false,
        additionalClass: this.props.additionalClass || ""
      })

      if(this.props.autoFocus) {
        this.setState({
          editorState:  EditorState.moveFocusToEnd(this.state.editorState)
        });
      }
    } else if (prevProps.content !== this.props.content && prevProps.readOnly === true) {
      
      const textContent = this.props.content || ''
      let contentState = ''

      if (typeof textContent === 'string') {
        contentState = ContentState.createFromText(textContent)
      } else {
        contentState = convertFromRaw(textContent)
      }

      let editorState = "";
      // if(this.props.editorId !== undefined && (this.props.editorId.includes("-categoryTitle") || this.props.editorId.includes("-groupTitle") || this.props.editorId.includes("-questionTitle")) && typeof textContent === 'string' && textContent !== "") {
      //   editorState = EditorState.moveFocusToEnd(EditorState.createWithContent(contentState));
      // } else {
      //   editorState = EditorState.createWithContent(contentState)
      // }
      editorState = EditorState.createWithContent(contentState)
      this.setState({
        editorState: editorState,
        editorReadOnly: this.props.readOnly || false,
        additionalClass: this.props.additionalClass || ""
      })

      if(this.props.autoFocus) {
        this.setState({
          editorState:  EditorState.moveFocusToEnd(this.state.editorState)
        });
      }
    }
    else if (prevProps.content !== this.props.content && this.props.needToForceUpdate === true) {
     //This will update incoming change when the whole content needs to be updated 
     // it is important to set the needToForceUpdate false to maintain integrity in the editor afterwards
      const textContent = this.props.content || ''
      let contentState = ''

      if (typeof textContent === 'string') {
        contentState = ContentState.createFromText(textContent)
      } else {
        contentState = convertFromRaw(textContent)
      }

      let editorState = "";
      // if(this.props.editorId !== undefined && (this.props.editorId.includes("-categoryTitle") || this.props.editorId.includes("-groupTitle") || this.props.editorId.includes("-questionTitle")) && typeof textContent === 'string' && textContent !== "") {
      //   editorState = EditorState.moveFocusToEnd(EditorState.createWithContent(contentState));
      // } else {
      //   editorState = EditorState.createWithContent(contentState)
      // }
      editorState = EditorState.createWithContent(contentState)
      this.setState({
        editorState: editorState,
        editorReadOnly: this.props.readOnly || false,
        additionalClass: this.props.additionalClass || ""
      })

      if(this.props.autoFocus) {
        this.setState({
          editorState:  EditorState.moveFocusToEnd(this.state.editorState)
        });
      }

      this.props.setForceUpdate(false)
    }
  }

  removeFileConnection = async(payload) => {
    const response = await executeRequest('removeFileConnection', {...payload});
    console.log(response);
  }

  // for some reason image drag n drop doesnt trigger previousState !== newState so I added a forceSave prop to override that check
  onChange = ( editorState, editorProps = null, forceSave = false ) => {
    let previousState = this.state.editorState.getCurrentContent()
    let newState = editorState.getCurrentContent()
    

    //Check if image is removed and remove connection from FileConnector
    if(previousState !== newState){
      let oldRaw = convertToRaw(previousState)
      let newRaw = convertToRaw(newState)
      let keyFound = false;
      // console.log("New:",newRaw.blocks);
      // console.log("Old:",oldRaw.blocks);
      let oldAtomicBlocks = []
      Object.keys(oldRaw.blocks).map(oldKey => {
        if(oldRaw.blocks[oldKey].type === "atomic"){
          oldAtomicBlocks = [...oldAtomicBlocks,oldRaw.blocks[oldKey]]
        } 
      });
      let newAtomicBlocks = []
      Object.keys(newRaw.blocks).map(newKey => {
        if(newRaw.blocks[newKey].type === "atomic"){
          newAtomicBlocks = [...newAtomicBlocks,newRaw.blocks[newKey]]
        } 
      });
      if(oldAtomicBlocks.length > 0){
        // console.log(oldAtomicBlocks, newAtomicBlocks);

        // check and filter removed content if atomic (image)
        let removed = oldAtomicBlocks.filter(block => {
          if(newAtomicBlocks.length >0){
            let blockExists = true
            for(let obj of newAtomicBlocks){
              if(obj.key === block.key){
                blockExists = false
              }
            }
            return blockExists
          }else{
            return true
          }
        })
        // console.log("removed:",removed);
        // console.log("oldEntities:",oldRaw.entityMap);

        // handle FileConnector removal for each image
        try{
          if(removed.length > 0){
            let oldEntityMap = oldRaw.entityMap
            for(let obj of removed){
              let entityKey = obj.entityRanges[0].key
              let imageUrl = oldEntityMap[entityKey].data.src
              let stringArray = imageUrl.split("/")
              let fileId = stringArray[stringArray.length-1]
              // console.log("removed Id:",fileId);
              this.removeFileConnection({documentId: this.props.documentId, connectionType: this.props.connectionType, fileId: fileId})

            }
          }
        } catch (e) {

        }
      }
      
    }

    // Handles image resize to be saved
    // Still need to remove the edit option -bar when saved
    let figures = document.getElementsByTagName("figure") 

    if(figures){
      let oldImageState = [...this.state.arrayOfImages]
      let newImageState = []
      let index = 0
      for(var figure of figures){

        let image = figure.firstChild
        if(image){
          let newImageWidth = image.style.width
          newImageState = [...newImageState, newImageWidth]

          if(oldImageState.length > 0){
            let oldImageWidth = oldImageState[index] 

            if(newImageWidth !== oldImageWidth){
              forceSave = true
            }
          }
        } 
        index++
      }
     
      this.setState({arrayOfImages:newImageState})
    }
    //

    if( (previousState !== newState) || forceSave ) {
      if( typeof this.props.index !== 'undefined' ) {
 
        this.props.onTextChange( convertToRaw( editorState.getCurrentContent() ), this.props.index )
      } else {
        this.props.onTextChange( convertToRaw( editorState.getCurrentContent() ) )
      }
    }
    this.setState({ editorState })
  }

  onURLChange = ( e ) => {
    this.setState({ urlValue: e.target.value })
  }

  handleKeyCommand = ( command, editorState ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  
  onMediaAdd = ( editorState ) => {
    this.setState({ editorState })
    this.onChange( editorState, null, true )
  }

  onSuggestionSearchChange = ({ value }) => {
    const mentions = this.props.suggestions

    this.setState({
      suggestions: this.customSuggestionsFilter(value, mentions)
    })
  }

  customSuggestionsFilter = (searchValue, suggestions) => {
    const get = ( obj, attr ) => ( obj.get ? obj.get(attr) : obj[attr] )
    const value = searchValue.toLowerCase()

    const filteredSuggestions = suggestions.filter(( suggestion ) => (
      !value || get(suggestion, 'title').toLowerCase().indexOf(value) > -1
    ))

    return filteredSuggestions
  }

  handleUpload = () => { return }

  async handleDroppedFiles( selection, files ) {
    if( this.props.saveDraftjsImage ) {
      const formData = new FormData()
      formData.append( "file", files[0], files[0].name )
      formData.append( "surveyId", this.props.surveyId )
      formData.append( "jwtToken", window.sessionStorage.getItem("token") )
      formData.append( "type", "draftjs-image" )
      formData.append( "connectionType", this.props.connectionType )
      formData.append( "documentId", this.props.documentId )
      formData.append( "fileName", files[0].name)
      const response = await this.props.saveDraftjsImage( formData )
      const editorWithImage = this.imagePlugin.addImage( this.state.editorState, response.fileUrl )
      this.onMediaAdd( editorWithImage )
    }
  }

  checkEditorState = (state) => {
    let contentState = state.getCurrentContent()
    let errors = false

    contentState.getBlockMap().forEach( (block) => {
      if( block !== undefined && ( block.getType() === 'atomic' && block.getEntityAt(0) === null ) ) {
        errors = true
      }
    })

    if( errors ) {
      const blockMap = contentState.getBlockMap().filter( block => block !== undefined && ( block.getType() !== 'atomic' || ( block.getType() === 'atomic' && block.getEntityAt(0) !== null ) ) )
      contentState = contentState.set( 'blockMap', blockMap )
      const newEditorState = EditorState.set( state, { currentContent: contentState } )
      return newEditorState
    }
    return state
  }

  render() {
    const { editorReadOnly, suggestions } = this.state
    const { InlineToolbar, EmojiSuggestions, AlignmentTool } = this.components
    // connectionType and documentId are for fileConnector
    const { additionalClass, placeholder, textAlignment, plaintextEditor, styleEditor, withHeadersEditor, changeBackgroundColor, backgroundColor, connectionType, documentId } = this.props
    const { MentionSuggestions } = this.mentionPlugin

    const spellCheck = this.props.spellCheck || false
    const allowFileUpload = this.props.allowFileUpload || true

    return (
      <div style={ this.props.width ? { position: "relative", width: this.props.width } : { position: "relative" }}>
        <div className={ "texteditor-container " + ( editorReadOnly ? "readonly " : "" ) + ( additionalClass ? `${additionalClass} ` : "" )}> 
          <Editor 
            editorState={ this.checkEditorState( this.state.editorState ) } 
            onChange={this.onChange} 
            handleKeyCommand={ this.handleKeyCommand }
            readOnly={ editorReadOnly }
            ref={ this.editor }
            plugins={ this.plugins }
            placeholder={ placeholder }
            textAlignment={ textAlignment }
            handleDroppedFiles={ this.handleDroppedFiles }
            spellCheck={ spellCheck }
          />

          {(!plaintextEditor || styleEditor) &&
            <span>
              <EmojiSuggestions />
              <InlineToolbar>
                {
                  // may be use React.Fragment instead of div to improve perfomance after React 16
                  (externalProps) => (
                    <React.Fragment>
                      <HeadlineFourButton {...externalProps} />
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <this.linkPlugin.LinkButton {...externalProps} />
                      {withHeadersEditor &&
                        <HeadlinesButton {...externalProps} />
                      }
                    </React.Fragment>
                  )
                }
              </InlineToolbar>
            </span>
          }

        </div>
        <div>
          {!editorReadOnly && !plaintextEditor &&
            <button className="addon-toggle-button" onClick={()=> this.setState({showButtons: !this.state.showButtons})}>
              {this.state.showButtons?
                <i className="material-icons" title="Sulje työkalut">more_horiz</i>
                :
                <i className="material-icons" title="Avaa työkalut">more_vert</i>
              }
            </button>
          }
          {(!editorReadOnly && !plaintextEditor ) && this.state.showButtons ? (
              <div style={{height: 0}}>
                <ImageAdd
                  editorState={this.state.editorState}
                  onChange={this.onMediaAdd}
                  modifier={this.imagePlugin.addImage}
                  allowFileUpload={allowFileUpload}
                  handleDropzoneFiles={this.handleDropzoneFiles}
                  documentId={documentId}
                  connectionType={connectionType}
                />
                <VideoAdd
                  editorState={this.state.editorState}
                  onChange={this.onMediaAdd}
                  modifier={this.videoPlugin.addVideo}
                />
                {changeBackgroundColor &&
                  <AddColorButton 
                    editorState={this.state.editorState}
                    changeBackgroundColor={changeBackgroundColor}
                    backgroundColor={backgroundColor}
                    onChange={this.onMediaAdd}
                    modifier={this.imagePlugin.addImage}
                  />  
                }
                <AlignmentTool />
              </div>
            ) : (
              null
            )
          }
        </div>
        {suggestions && <MentionSuggestions
          onSearchChange={this.onSuggestionSearchChange}
          suggestions={suggestions}
          entryComponent={MentionEntries}
        />
        }
      </div>
    )
  }

}

const MentionEntries = (props) => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props

  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainerRight}>

        <p>{mention.name}</p>
        <small>{mention.title}</small>

      </div>
    </div>
  )
}

class HeadlinesPicker extends Component {
  componentDidMount() {
    setTimeout(() => { window.addEventListener('click', this.onWindowClick); });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    this.props.onOverrideContent(undefined);

  render() {
    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
    return (
      <div>
        {buttons.map((Button, i) => // eslint-disable-next-line
          <Button key={i} {...this.props} />
        )}
      </div>
    );
  }
}

class HeadlinesButton extends Component {
  // When using a click event inside overridden content, mouse down
  // events needs to be prevented so the focus stays in the editor
  // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
  onMouseDown = (event) => event.preventDefault()

  onClick = () =>
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    this.props.onOverrideContent(HeadlinesPicker);

  render() {
    return (
      <div onMouseDown={this.onMouseDown} className="headlineButtonWrapper">
        <button onClick={this.onClick} className="headlineButton">
          Otsikko
        </button>
      </div>
    );
  }
}



export default TextEditor
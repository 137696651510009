import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import Select from 'react-select';
import { confs } from '../../../modules/config';

export const ModifySentSurveyModal = ({ isOpen, toggle, plan, save }) => {
    const [sentSurveys, setSentSurveys] = useState(null)
    const [selectedSurvey, setSelectedSurvey] = useState({})

    useEffect(() => {
        if (plan && plan.automationSurvey && plan.automationSurvey !== '') {
            let payload = {
                jwtToken: window.sessionStorage.getItem("token"),
                sentSurveyId: plan.automationSurvey
            }
            fetch(confs.url + 'plan/getSentSurveyListForPlan', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then(resp => resp.json())
                .then(response => {
                    if (response.list) {
                        const list = response.list.filter(e => e.label !== 'Alkuperäinen')
                        setSentSurveys(list)
                    } else {
                        setSentSurveys([])
                    }
                }).catch(error => {

                })
        }

    }, [plan])

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered size="md">
            <ModalHeader toggle={toggle} className="taskModal">
                <div>
                    Vaihda generoidun lomakkeen kartoitusta
                </div>
            </ModalHeader>
            <ModalBody>
                <Row>
                    {sentSurveys &&
                        <Col xl="12">
                            <Label>Lähetetty kartoitus</Label>
                            <Select
                                value={selectedSurvey}
                                onChange={setSelectedSurvey}
                                options={sentSurveys}
                                className="companyTargets"
                                classNamePrefix="select"
                            />
                        </Col>
                    }
                </Row>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                <button className="small-white-button wide-button" onClick={() => save({planId: plan._id, newSentSurvery: selectedSurvey })} >Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

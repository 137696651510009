import * as ActionTypes from './ActionTypes';
import { confs } from '../../../modules/config';
import { NotificationManager } from 'react-notifications';
import { responseHandler } from '../../../modules/responseHandler';
import * as ActionCreator from '../../../store/index';

export const startNewPlan = (data) => {
    return {
        type: ActionTypes.START_NEW_PLAN
    }
}

export const changeEditMode = () => {
    return {
        type: ActionTypes.CHANGE_EDIT_MODE
    }
}

//Creates a new plan 
export const createNewPlan = (payload) => {
    return dispatch => {
        fetch(confs.url + 'plan/createNew', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if(responseJson.error){
                        if(responseJson.trialEnded){
                            NotificationManager.error("Kokeilujakso on päättynyt","",4000)
                        } else if(responseJson.reason){
                            if(responseJson.reason === "limit"){
                                NotificationManager.error("Lomakkeiden enimmäismäärä on täyttynyt","",4000)
                            }
                            if(responseJson.reason === "module"){
                                NotificationManager.error("Ominaisuus (lisenssi) ei ole käytössä","",4000)
                            }
                        } else {
                            NotificationManager.error("Lomaketta ei voitu luoda: " + responseJson.error, "", 5000);
                        }
                    } else {
                        dispatch(setPlan(responseJson));
                    }
                }               
            }).catch(error => {

            })
    }
}

const changePlanStatus = statusItems => {
    return {
        type: ActionTypes.PLAN_STATUS,
        payload: statusItems
    }
}

export const publishPlan = payload => {
    return dispatch => {
        fetch(confs.url + 'plan/save/publish', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   if (response.status === 'published') {
                        dispatch(changePlanStatus({ ...response, passwordOn: payload.passwordStatus }))
                        NotificationManager.success("Lomake julkaistu", "", 2000);
                    } else if (response.status === 'inactive') {
                        dispatch(changePlanStatus(response))
                        NotificationManager.error("Lomakkeen julkaisu peruttu", "", 2000);
                    }
                } 
            }).catch(error => {
                NotificationManager.error("Lomakkeen julkaisussa tapahtui virhe", "", 2000);
            })
    }
}

const setPrivacySettings = props => {
    return {
        type: ActionTypes.PLAN_PRIVACY,
        payload: props
    }
}

// Admin panel update for this module
export const savePlanPrivacySettings = payload =>{
    payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...payload
    }
    return dispatch => {
        fetch(confs.url + 'plan/savePlanPrivacySettings', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if(response.saved){
                        dispatch(setPrivacySettings(response.saved))
                        NotificationManager.success("Yksityisyysasetukset tallennettu", "", 4000);
                    } else {
                        NotificationManager.error("Tallennuksessa tapahtui virhe", "", 2000);
                    }
                } 
            }).catch(error => {
                NotificationManager.error("Tallennuksessa tapahtui virhe", "", 2000);
            })
    }
} 

const changePlanPasswordStatus = status => {
    return {
        type: ActionTypes.HANDLE_PASSWORD_STATUS,
        payload: status
    }
}

export const handlePasswordSettings = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }

    return dispatch => {
        fetch(confs.url + 'plan/modify/password', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if (response.passwordOn !== undefined) {
                        dispatch(changePlanPasswordStatus(response.passwordOn));

                        if (response.passwordOn) {
                            NotificationManager.success("Salasana vaihdettu", "", 3000);
                        } else {
                            NotificationManager.success("Salasana poistettu käytöstä", "", 3000);
                        }
                    } else {
                        NotificationManager.error("Tapahtumaa ei voitu toteuttaa", "", 2000);
                    }
                }
            }).catch(error => {
                NotificationManager.error("Tapahtumassa tapahtui virhe", "", 3000);
            })
    }
}

const removeTaskFromList = params => {
    return {
        type: ActionTypes.REMOVE_DELETED_TASK,
        payload: params
    }
}

export const removeTask = params => {

    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    };

    return dispatch => {
        fetch(confs.url + 'plan/item/removeTask', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   if (response.planItemId !== undefined && response.taskId !== undefined) {
                        dispatch(removeTaskFromList(response));
                        NotificationManager.success("Tarkistuspiste on poistettu", "", 2000);
                    } else {
                        NotificationManager.error("Tarkistuspisteen poisto epäonnistui", "", 2000);
                    }
                }       
            }).catch(error => {
                NotificationManager.error("Tarkistuspisteen poistossa tapahtui virhe", "", 2000);
            })
    }
}

const removeRiskFromList = params => {
    return {
        type: ActionTypes.REMOVE_DELETED_RISK,
        payload: params
    }
}

export const removeRisk = params => {

    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    };

    return dispatch => {
        fetch(confs.url + 'plan/item/removeRisk', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if (response.targetId !== undefined && response.riskId !== undefined) {
                        dispatch(removeRiskFromList(response));
                        NotificationManager.success("Riski on poistettu", "", 2000);
                    } else {
                        NotificationManager.error("Riskin poisto epäonnistui", "", 2000);
                    }
                }              
            }).catch(error => {
                NotificationManager.error("Riskin poistossa tapahtui virhe", "", 2000);
            })
    }
}

export const saveTargetSurveys = data => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...data
    };

    return dispatch => {
        fetch(confs.url + 'plan/save/targetSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   dispatch(updatePlanData(response));
                    NotificationManager.success("Muutokset tallennettu", "", 3000);
                }            
            }).catch(error => {
                NotificationManager.error("Kartoituksien tallennuksessa tapahtui virhe, yritä hetken päästä uudelleen", "", 3000);
            })
    }
}

export const handleClientInformation = clientInfo => {
    return {
        type: ActionTypes.HANDLE_CLIENT_INFORMATION,
        payload: clientInfo
    }
}

const getBuilderItem = item => {
    let createdItem = {}

    if (item.type === "iFrame") {
        createdItem = {
            id: item.id,
            topicContent: item.topicContent,
            hostName: item.hostName,
            size: item.size,
            type: item.type,
            modified: item.modified,
            modifiedByUser: item.modifiedByUser
        }
    } else if (item.type === 'report') {
        createdItem = {
            id: item.id,
            topicContent: item.topicContent,
            textContent: item.textContent,
            type: item.type,
            targetSurvey: {
                ...item.targetSurvey
            },
            modified: item.modified,
            modifiedByUser: item.modifiedByUser
        }
    } else if (item.type === 'list' || item.type === 'Excel' || item.type === 'Trello' || item.type === 'tables' || item.type === 'product' || item.type === 'attachment' || item.type === 'signature' || item.type === 'checklist' || item.type === 'businessExcel' || item.type === 'HubSpot' || item.type === 'gantt' || item.type === 'grid' ) {
        createdItem = {
            ...item
        }

    } else {
        createdItem = {
            id: item.id,
            topicContent: item.topicContent,
            textContent: item.textContent,
            type: item.type,
            modified: item.modified,
            modifiedByUser: item.modifiedByUser
        }
    }

    return createdItem;
}

const setPlanItems = planList => {
    return {
        type: ActionTypes.SET_PLAN_ITEMS,
        payload: planList
    }
}

export const setPlanItem = planItem => {
    return {
        type: ActionTypes.SET_PLAN_ITEM,
        payload: planItem
    }
}

export const setShowTableOfContents = (params) => {
    return {
        type: ActionTypes.SET_SHOW_TABLE_OF_CONTENTS,
        payload: params
    }
}

export const toggleShowTableOfContents = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }
    return dispatch => {
        fetch(confs.url + 'plan/toggleShowTableOfContents', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
        .then(response => {
            if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(response && response.success){
                   
                } else {
                    NotificationManager.error("Ongelma päivittäessä lomaketta", "Virhe", 3000);
                }
            }               
        }).catch(error => {
            // dispatch(fetchRequestFinished("failed"));
            NotificationManager.error("Ongelma päivittäessä lomaketta", "Virhe", 3000);
        })
    }
}

export const reOrderPlanItems = (planList, planId) => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        planList: planList,
        planId: planId
    }
    return dispatch => {
        dispatch(setNewPlanItemOrder(planList));
        fetch(confs.url + 'plan/save/order', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   if (response.status === 'RE_ORDER') {
                        NotificationManager.success("Järjestyksen muutos tallennettu", "", 2000);
                    } else {
                        NotificationManager.error("Tallennus epäonnistui, yritä hetken päästä uudelleen", "", 2000);
                    }
                }              
            })
    }
}

export const setNewPlanItemOrder = planList => {
    return {
        type: ActionTypes.REORDER_PLAN_ITEMS,
        payload: planList
    }
}

export const addItemId = idObject => {
    return {
        type: ActionTypes.ADD_ITEM_ID,
        payload: idObject
    }
}

//Creates new plan item
export const createNewPlanItem = (item, index, destClone, planId) => dispatch => {
    let payload = {}
    if (item.id === 'iFrame') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                type: 'iFrame',
                topicContent: "",
                hostName: "",
                size: {
                    height: 650,
                    width: '100%'
                }
            },
            createdIndex: index,
            planId: planId
        }
    } else if (item.id === 'report') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                type: 'report',
                topicContent: "",
                textContent: "",
                targetSurvey: {
                    ...item.targetSurvey
                }
            },
            createdIndex: index,
            planId: planId
        }
    } else if (item.id === 'embeds') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                targetEmbed: {
                    ...item.selectedEmbed
                },
                businessId: item.businessId,
                topicContent: ""
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.id === 'tables') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type: 'tables',
                topicContent: item.topicContent,
                textContent: item.textContent,
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.type === 'custom-block') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.id === 'attachment') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type: 'attachment'
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.id === 'product') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type:'product'
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.id === 'signature') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type:'signature'
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.id === 'risks') {
            payload = {
                jwtToken: sessionStorage.getItem("token"),
                newItem: {
                    ...item,
                    type:'risks'
                },
                createdIndex: index,
                planId: planId
            }
    } else if(item.id === 'checklist') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type: 'checklist'
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.id === 'businessExcel') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type: 'businessExcel'
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.id === 'HubSpot') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type: 'HubSpot'
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.id === 'gantt') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type: 'gantt'
            },
            createdIndex: index,
            planId: planId
        }
    }
    else if(item.id === 'grid') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type: 'grid'
            },
            createdIndex: index,
            planId: planId
        }
    } else if(item.id === 'TrelloTimeline') {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                ...item,
                type: 'TrelloTimeline'
            },
            createdIndex: index,
            planId: planId
        }
    } else {
        payload = {
            jwtToken: sessionStorage.getItem("token"),
            newItem: {
                type: 'topic-draft',
                topicContent: item.topicContent,
                textContent: item.textContent
            },
            createdIndex: index,
            planId: planId
        }
    }

    return new Promise(async (resolve) => {
        const resultJson = await fetch(confs.url + 'plan/createNewItem', {
            method: 'POST',
            body: JSON.stringify(payload)
        })

        const response = await resultJson.json()
        destClone.splice(index, 0, getBuilderItem(response.item))
        dispatch(addItemId({ tasksById: response.idObject, risksById: response.idObject }))
        dispatch(setPlanItems(destClone))

        resolve()
    })
}

export const resetPlanSelection = () => {
    return {
        type: ActionTypes.RESET_PLAN_SELECTION
    }
}

export const createNewPlanItemFromTemplate = (item, index, destClone, planId) => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        templateId: item._id,
        createdIndex: index,
        planId: planId
    }

    return dispatch => {
        fetch(confs.url + 'plan/reCreatePlanItem', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   destClone.splice(index, 0, getBuilderItem(response.item));
                    dispatch(addItemId({ tasksById: response.tasksById, risksById: response.risksById }));
                    dispatch(setPlanItems(destClone));
                }                
            }).catch(error => {

            })
    }
}

export const handleTitle = title => {
    return {
        type: ActionTypes.SET_PLAN_TITLE,
        payload: title
    }
}

export const savePlanItems = (planId, planItems, planTitle, clientInfo, tags) => dispatch => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        planItems: planItems,
        planTitle: planTitle,
        clientInfo: clientInfo,
        planId: planId,
        tags: tags
    }

    dispatch(fetchRequestStart())

    return new Promise(async (resolve) => {
        try {
            const responseJson = await fetch(confs.url + 'plan/save/plan', {
                method: 'POST',
                body: JSON.stringify(payload)
            })

            const response = await responseJson.json()

            if (Object.keys(response).length > 0 && response.status === 'ok') {
                NotificationManager.success("Lomake tallennettu", "", 3000)
                dispatch(updatePlanData(response.plan.plan))
                dispatch(fetchRequestFinished("finished"))
                if(response.checklistNotUpdated){
                    NotificationManager.error("Tehtävän merkintää ei voida muuttaa, sillä lomakkeessa on jo vähintään yksi allekirjoitus.","Virhe", 7000)
                }
            } else {
                dispatch(fetchRequestFinished("failed"))
                NotificationManager.error("Lomakkeen tallennuksessa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000)
            }

            resolve()
        } catch (ex) {
            dispatch(fetchRequestFinished("failed"))
            NotificationManager.error("Lomakkeen tallennuksessa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
        }

    })
}

const addTaskToItem = response => {
    return {
        type: ActionTypes.ADD_TASK_TO_ITEM,
        payload: response
    }
}

export const fetchRequestFinished = (message) => {
    return {
        type: ActionTypes.FETCH_REQUEST_FINISHED,
        payload: message
    }
}

export const fetchRequestStart = () => {
    return {
        type: ActionTypes.START_FETCH_REQUEST
    }
}

export const addPlanItemTask = (data) => {
    let payload = {
        ...data,
        jwtToken: sessionStorage.getItem("token")
    };

    return dispatch => {
        dispatch(fetchRequestStart());
        fetch(confs.url + 'plan/itemTask', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   if (Object.keys(response).length > 0) {
                        dispatch(addTaskToItem(response));
                        NotificationManager.success("Uusi tarkistuspiste lisätty onnistuneesti", "", 3000);
                        dispatch(fetchRequestFinished("finished"));
                    } else {
                        dispatch(fetchRequestFinished("failed"));
                        NotificationManager.error("Tarkistuspisteen lisäyksessä oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                    }
                }                
            }).catch(error => {
                dispatch(fetchRequestFinished("failed"));
                NotificationManager.error("Tarkistuspisteen lisäyksessä oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
            })
    }
}

const riskHandler = risk => {
    return {
        type: ActionTypes.ADD_RISK_TO_ITEM,
        payload: risk
    }
}

const riskSet = risk => {
    return {
        type: ActionTypes.SET_ITEM_RISK,
        payload: risk
    }
}

export const addModifyPlanRisk = data => {
    let payload = {
        ...data,
        jwtToken: sessionStorage.getItem("token")
    };

    return dispatch => {
        dispatch(fetchRequestStart());
        fetch(confs.url + 'plan/modify/itemRisk', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   if (Object.keys(response).length > 0) {
                        if (payload.riskId !== undefined) {
                            if (data.hide !== null && data.hide) {
                                dispatch(riskSet(response));
                                NotificationManager.success("Riski on muutettu näkyväksi", "", 3000);
                                dispatch(fetchRequestFinished("finished"));
                            } else if (data.hide !== null && !data.hide) {
                                dispatch(riskSet(response));
                                NotificationManager.error("Riski on piilotettu", "", 3000);
                                dispatch(fetchRequestFinished("finished"));
                            } else {
                                dispatch(riskSet(response));
                                NotificationManager.success("Riskiä muokattu onnistuneesti", "", 3000);
                                dispatch(fetchRequestFinished("finished"));
                            }
                        } else {
                            dispatch(riskHandler(response));
                            NotificationManager.success("Uusi riski lisätty onnistuneesti", "", 3000);
                            dispatch(fetchRequestFinished("finished"));
                        }
                    } else {
                        dispatch(fetchRequestFinished("failed"));
                        NotificationManager.error("Riskin lisäyksessä oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                    }
                }              
            }).catch(error => {
                dispatch(fetchRequestFinished("failed"));
                NotificationManager.error("Riskin lisäyksessä oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
            })
    }
}

const setItemTask = taskItem => {
    return {
        type: ActionTypes.SET_ITEM_TASK,
        payload: taskItem
    }
}

export const modifyPlanItemTask = data => async dispatch => {
    let payload = {
        ...data,
        jwtToken: sessionStorage.getItem("token")
    };

    dispatch(fetchRequestStart());

    const responseJson = await fetch(confs.url + 'plan/modify/itemTask', {
        method: 'POST',
        body: JSON.stringify(payload)
    })

    const response = await responseJson.json()

    if (Object.keys(response).length > 0) {
        dispatch(setItemTask(response));

        if (data.hide !== undefined && data.hide) {
            NotificationManager.success("Tarkistuspiste muutettu näkyväksi", "", 3000);
        } else if (data.hide !== undefined && !data.hide) {
            NotificationManager.success("Tarkistuspiste piilotettu", "", 3000);
        } else {
            NotificationManager.success("Tarkistuspistettä muokattu onnistuneesti", "", 3000);
        }

        dispatch(fetchRequestFinished("finished"));
    } else {
        dispatch(fetchRequestFinished("failed"));
        NotificationManager.error("Tarkistuspisteen lisäyksessä oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
    }


}

export const fetchPlan = payload => {
    return dispatch => {
        fetch(confs.url + 'plan/getPlan', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                }
            })
    }
}

const setTemplateList = params => {
    return {
        type: ActionTypes.SET_TEMPLATES,
        payload: params
    }
}

export const getTemplates = params => {
    let payload = {
        ...params,
        jwtToken: sessionStorage.getItem("token")
    };
    return dispatch => {
        fetch(confs.url + 'plan/getTemplatesForProject', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(setTemplateList(response))
                }
            })
    }
}

//Set the plan list
const setListOfPlans = plans => {
    return {
        type: ActionTypes.SET_LIST_OF_PLANS,
        payload: plans
    }
}

// Does not set current plan empty
const setListOfPlansWithoutReset = (plans) => {
    return {
        type: ActionTypes.SET_ONLY_LIST_OF_PLANS,
        payload: plans
    }
}
//Set the list of automatic created plans
const setListOfAutomationResults = plans => {
    return {
        type: ActionTypes.SET_LIST_OF_AUTOMATIONRESULTS,
        payload: plans
    }
}
//Set the list of plan templates
const setListOfTemplatePlans = plans => {
    return {
        type: ActionTypes.SET_LIST_OF_TEMPLATES,
        payload: plans
    }
}

const setListOfAutomations = plans => {
    return {
        type: ActionTypes.SET_LIST_OF_AUTOMATIONS,
        payload: plans
    }
}

const addToAutomationResults = plans => {
    return {
        type: ActionTypes.ADD_TO_AUTOMATIONRESULTS,
        payload: plans
    }
}

export const startEditingPlan = (planId, edit = null) => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token"),
        planId: planId,
        edit:edit
    }

    return dispatch => {
        fetch(confs.url + 'plan/getPlan', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(setPlan(response));
                }               
            }).catch(error => {

            })
    }
}

export const copiedFromTemplate = planId => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token"),
        planId: planId,
        createdFromTemplate:true
    }

    return dispatch => {
        fetch(confs.url + 'plan/getPlan', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(setPlan(response));
                }               
            }).catch(error => {

            })
    }
}

const setPlan = plan => {
    return {
        type: ActionTypes.START_EDITING_PLAN,
        payload: plan
    }
}

export const updatePlanData = plan => {
    return {
        type: ActionTypes.UPDATE_PLAN_DATA,
        payload: plan
    }
}

// To speed up the process only get the plans that have selectedBusiness (filter out the "empty" automation plans)
export const fetchOnlyPlansForFirstList = () => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token")
    }

    return dispatch => {
        fetch(confs.url + 'plan/getFirstPlans', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => dispatch(responseHandler(resp)))
            .then(responseJson => {
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(setListOfPlans(responseJson.reports));
                } 
            }).catch(error => {
                NotificationManager.error("Lomakkeita ei voitu hakea", "Virhe", 3000);
            })
    }
}

//Gets all the plans created by enterprise
export const fetchAllPlans = (reset = true) => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token")
    }

    return dispatch => {
        fetch(confs.url + 'plan/getPlans', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => dispatch(responseHandler(resp)))
            .then(responseJson => {
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if (reset) {
                        dispatch(setListOfPlans(responseJson.reports));
                    } else {
                        dispatch(setListOfPlansWithoutReset(responseJson.reports))
                        
                    }
                    dispatch(setListOfAutomationResults(responseJson.automations))
                } 
            }).catch(error => {
                NotificationManager.error("Lomakkeita ei voitu hakea", "Virhe", 3000);
            })
    }
}

//Gets all the automationplans created by enterprise
export const fetchAllAutomations = () => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token")
    }

    return dispatch => {
        fetch(confs.url + 'plan/getAllAutomations', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => dispatch(responseHandler(resp)))
            .then(responseJson => {
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else { 
                    dispatch(setListOfAutomations(responseJson.reports));            
                    if(responseJson.automations && responseJson.automations.length > 0){
                        dispatch(addToAutomationResults(responseJson.automations))
                    }
                } 
            }).catch(error => {
                NotificationManager.error("Automatisoituja lomakkeita ei voitu hakea", "Virhe", 3000);
            })
    }
}

export const setRefreshInProgress = refresh => {
    return {
        type: ActionTypes.SET_REFRESH_IN_PROGRESS,
        payload: refresh
    }
}


export const refreshPlans = () => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token")
    }
    return dispatch => {
        dispatch(setRefreshInProgress(true))
        fetch(confs.url + 'plan/getPlans', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => dispatch(responseHandler(resp)))
            .then(responseJson => {
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(setListOfPlansWithoutReset(responseJson.reports))
                    dispatch(setListOfAutomationResults(responseJson.automations))
                    dispatch(setRefreshInProgress(false))
                    
                } 
            }).catch(error => {
                NotificationManager.error("Lomakkeita ei voitu hakea", "Virhe", 3000);
            })
    }
}

export const fetchAllSurveyVariablesForPlans = payload => dispatch => {
    payload['jwtToken'] = window.sessionStorage.getItem("token")

    return new Promise(async (resolve) => {
        const resultJson = await fetch(confs.url + 'plan/getSurveyVariables', {
            method: 'POST',
            body: JSON.stringify(payload)
        })
        const result = await resultJson.json()

        if (result) {
            resolve(result)
        } else {
            resolve()
        }
    })
}

export const fetchAllVariablesForPlan = payload => dispatch => {
    payload['jwtToken'] = window.sessionStorage.getItem("token")

    return new Promise(async (resolve) => {
        const resultJson = await fetch(confs.url + 'plan/getPlanVariables', {
            method: 'POST',
            body: JSON.stringify(payload)
        })
        const result = await resultJson.json()

        if (result) {
            resolve(result)
        } else {
            resolve()
        }
    })
}

export const generateAutomaticPlan = (payload) => {
    payload.jwtToken = window.sessionStorage.getItem("token")

    return dispatch => {
        fetch(confs.url + 'plan/generatePlan', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => dispatch(responseHandler(resp)))
            .then(responseJson => {
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(setPlan(responseJson))
                } 
            }).catch(error => {
                NotificationManager.error("Lomaketta ei voitu luoda", "Virhe", 3000);
            })
    }
}

export const savePlanSettings = (payload) => {
    payload.jwtToken = window.sessionStorage.getItem("token")

    return dispatch => {
        fetch(confs.url + 'plan/savePlanSettings', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => dispatch(responseHandler(resp)))
            .then(responseJson => {
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    NotificationManager.success("Asetukset tallennettu", "", 2000);
                    if(!payload.fromList){
                        dispatch(setPlanSettings(responseJson))
                    }else{
                        let provide = {
                            id:payload.planId,
                            ...responseJson
                        }
                        dispatch(setPlanListSettings(provide))
                    }
                }               
            }).catch(error => {
                console.log(error);
                NotificationManager.error("Asetuksia ei voitu tallentaa", "Virhe", 3000);
            })
    }
}

export const savePlanLayout = (payload) => {
    payload.jwtToken = window.sessionStorage.getItem("token")

    return dispatch => {
        fetch(confs.url + 'plan/savePlanLayout', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => dispatch(responseHandler(resp)))
            .then(responseJson => {
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    NotificationManager.success("Lomakkeen rakenne päivitetty", "", 2000);
                    // dispatch(setPlanLayout(responseJson.layoutOptions))
                }               
            }).catch(error => {
                console.log(error);
                NotificationManager.error("Asetuksia ei voitu tallentaa", "Virhe", 3000);
            })
    }
}

export const modifyPlanSentSurvey = (payload) => {
    payload.jwtToken = window.sessionStorage.getItem("token")

    return dispatch => {
        fetch(confs.url + 'plan/modify/sentSurvey', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => dispatch(responseHandler(resp)))
            .then(responseJson => {                
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    responseJson._id = payload.planId
                    NotificationManager.success("Käytettävä kartoitus muutettu", "", 2000);
                    dispatch(setModifiedPlanSentSurvey(responseJson))
                }  
            }).catch(error => {
                
                NotificationManager.error("Kartoitusta ei voitu vaihtaa", "Virhe", 3000);
            })
    }
}

const setModifiedPlanSentSurvey = payload => {
    return {
        type: ActionTypes.MODIFY_PLAN_SENTSURVEY,
        payload: payload
    }
}

const setPlanSettings = settings => {

    return {
        type: ActionTypes.SET_PLAN_SETTINGS,
        payload: settings
    }
}

const setPlanLayout = layoutOptions => {
    return {
        type: ActionTypes.SET_PLAN_LAYOUT,
        payload: layoutOptions
    }
}

const setPlanListSettings = settings => {
    return {
        type: ActionTypes.SET_PLANLIST_SETTINGS,
        payload: settings
    }
}

const addTemplateItem = templateItem => {
    return {
        type: ActionTypes.ADD_TEMPLATE_ITEM,
        payload: templateItem
    }
}

export const createTemplateItem = (params) => {
    let payload = {
        ...params,
        jwtToken: sessionStorage.getItem("token")
    }

    return dispatch => {
        dispatch(fetchRequestStart());
        fetch(confs.url + 'plan/create/templateItem', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if (Object.keys(response).length > 0) {

                        if (params.planItem.type === 'iFrame')
                            NotificationManager.success("Uusi iFrame tallennettu onnistuneesti", "", 3000);
                        else if(params.planItem.type === 'tables')
                            NotificationManager.success("Taulukko tallennettu onnistuneesti", "", 3000);
                        else if(params.type !== undefined && params.type === 'new-custom-block') {
                            NotificationManager.success("Perusmalli on lisätty päivitysmalleihin ja lomake on päivitetty", "", 3000);
                            if(response.newPlan) {
                                dispatch(updatePlanData(response.newPlan))
                            }
                        }
                        else
                            NotificationManager.success("Uusi tekstipohja luotu onnistuneesti", "", 3000);
                            dispatch(addTemplateItem(response));
                            dispatch(fetchRequestFinished("finished"));
                    } else {
                        dispatch(fetchRequestFinished("failed"));
                        if (params.planItem.type === 'iFrame')
                            NotificationManager.error("iFrame tallennuksessa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                        else if(params.planItem.type === 'tables')
                            NotificationManager.success("Taulukon tallennuksessa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                        else
                            NotificationManager.error("Tekstipohjan tallennuksessa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                    }
                }                 
            }).catch(error => {
                dispatch(fetchRequestFinished("failed"));
                if (params.planItem.type === 'iFrame')
                    NotificationManager.error("iFrame tallennuksessa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                else if(params.planItem.type === 'tables')
                    NotificationManager.success("Taulukon tallennuksessa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                else
                    NotificationManager.error("Tekstipohjan tallennuksessa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
            })
    }
}

const addPlanCopy = copiedPlan => {
    return {
        type: ActionTypes.ADD_COPIED_PLAN,
        payload: copiedPlan
    }
}
const addTemplateCopy = copiedPlan => {
    return {
        type: ActionTypes.ADD_COPIED_TEMPLATE,
        payload: copiedPlan
    }
}

export const copyTargetPlan = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }
    let toEnterprise = false
    if(params.copyToEnterprise){
        toEnterprise = params.copyToEnterprise
    }
    return dispatch => {
        fetch(confs.url + 'plan/copy', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   if (response.status === 'copied' && response.type === 'template') {
                        NotificationManager.success("Kopioitu lomakepohjaksi", "", 3000);
                        dispatch(addTemplateCopy(response.planCopy));
                    }
                    else{
                        if(response.status === 'copied') {
                            NotificationManager.success("Lomake kopioitu", "", 3000);
                            if(!toEnterprise){
                                dispatch(addPlanCopy(response.planCopy));
                            }
                        }
                    }
                }      
            }).catch(error => {
                NotificationManager.error("Lomakkeen kopiointi epäonnistui", "", 3000);
            })
    }
}

const planItemDeleted = deletedId => {
    return {
        type: ActionTypes.REMOVE_DELETED_ITEM,
        payload: deletedId
    }
}

export const deletePlanItem = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }

    return dispatch => {
        fetch(confs.url + 'plan/delete/item', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   if (response.status === 'deleted') {
                        NotificationManager.success("Valinta poistettu onnistuneesti", "", 2000);
                        dispatch(planItemDeleted(response.deletedId))
                    } else {
                        NotificationManager.error("Poistoa ei voitu suorittaa", "", 2000);
                    }
                }               
            }).catch(error => {
                NotificationManager.error("Valinnan poisto epäonnistui", "", 2000);
            })
    }
}

const toggleVisibility = items => {
    return {
        type: ActionTypes.TOGGLE_VISIBILITY,
        payload: items
    }
}
export const toggleItem = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }

    return dispatch => {
        fetch(confs.url + 'plan/item/toggle', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if (response.id) {
                    NotificationManager.success("Tilaa vaihdettu onnistuneesti", "", 3000);
                    dispatch(toggleVisibility(response));
                }
            })
    }
}

const deletePlan = deletedId => {
    return {
        type: ActionTypes.DELETE_PLAN,
        payload: deletedId
    }
}

export const deleteTargetPlan = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }

    return dispatch => {
        fetch(confs.url + 'plan/delete', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                   if (response.status === 'deleted') {
                        NotificationManager.success("Lomake poistettu", "", 3000);
                        dispatch(deletePlan(response.deletedId));
                    }
                }             
            }).catch(error => {
                NotificationManager.error("Lomakkeen poisto epäonnistui", "", 3000);
            })
    }
}

const setTemplateItems = templateItems => {
    return {
        type: ActionTypes.SET_TEMPLATE_ITEMS,
        payload: templateItems
    }
}

export const getTemplateItems = () => {
    let payload = {
        jwtToken: sessionStorage.getItem("token")
    }
    return dispatch => {
        dispatch(fetchRequestStart());
        fetch(confs.url + 'plan/templates', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(setTemplateItems(response));
                    dispatch(fetchRequestFinished("finished"));
                }      
            }).catch(error => {
                dispatch(fetchRequestFinished("failed"));
                NotificationManager.error("Pohjien haussa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
            })
    }
}

const setSearchResults = searchResults => {
    return {
        type: ActionTypes.SEARCH_PLAN,
        payload: searchResults
    }
}

export const searchPlan = params => dispatch => {
    return new Promise(async (resolve) => {
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            ...params
        }

        const responseJson = await fetch(confs.url + 'plan/search', {
            method: 'POST',
            body: JSON.stringify(payload)
        })

        const response = await responseJson.json()
        if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated){
            dispatch(ActionCreator.authFailed())
        } else{
            dispatch(setSearchResults(response))
        }
        
        resolve()
    })
}

export const movePlanToBusiness = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }
    
    return dispatch => {
        fetch(confs.url + 'plan/movePlanToBusiness', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(updatePlanData(response))
                    NotificationManager.success("Lomakkeen yritys päivitetty", "", 2000);
                }
            }).catch(error => {
                dispatch(fetchRequestFinished("failed"));
                NotificationManager.error("Pohjien haussa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
            })
    }
}

export const unsubscribePlanEdit = params => {
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }
    
    return dispatch => {
        fetch(confs.url + 'plan/unsubscribePlanEdit', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
        .then(response => {
            if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                // dispatch(updatePlanData(response))
            }
        }).catch(error => {
            // dispatch(fetchRequestFinished("failed"));
            NotificationManager.error("Ongelma poistaesa muokkaajaa lomakkeesta", "Virhe", 3000);
        })
    }
}

export const subscribeToPlan = params =>{
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }
    
    return dispatch => {
        fetch(confs.url + 'plan/subscribeToPlan', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
        .then(response => {
            if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                // dispatch(updatePlanData(response))
            }
        }).catch(error => {
            // dispatch(fetchRequestFinished("failed"));
            NotificationManager.error("Ongelma lisättäessä muokkaajaa lomakkeelle", "Virhe", 3000);
        })
    }
}


export const updateAutomationSummary = params =>{
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }
    
    return dispatch => {
        fetch(confs.url + 'plan/updateAutomationSummary', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
        .then(response => {
            if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(response && response.plan){
                    dispatch(setPlan(response));
                } else {
                    NotificationManager.error("Ongelma päivittäessä lomaketta", "Virhe", 3000);
                }
            }               
        }).catch(error => {
            // dispatch(fetchRequestFinished("failed"));
            NotificationManager.error("Ongelma päivittäessä lomaketta", "Virhe", 3000);
        })
    }
}

export const updateDeals = params =>{
    let payload = {
        jwtToken: sessionStorage.getItem("token"),
        ...params
    }
    
    return dispatch => {
        fetch(confs.url + 'plan/updatePlanDeals', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
        .then(response => {
            if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(response && response.plan){
                    NotificationManager.success("HubSpot-Deal -komponentit päivitetty", "", 2000);
                    startEditingPlan({"planId":response.plan._id})
                } else {
                    NotificationManager.error("Ongelma päivittäessä HubSpot-Deal -komponentteja", "Virhe", 3000);
                }
            }               
        }).catch(error => {
            // dispatch(fetchRequestFinished("failed"));
            NotificationManager.error("Ongelma päivittäessä HubSpot-Deal -komponentteja", "Virhe", 3000);
        })
    }
}
import * as ActionTypes from './ActionTypes';

export const setBreadcrumbs = (payload) => {
    return {
        type: ActionTypes.SET_BREADCRUMBS,
        payload: payload
    }
};

export const addBreadcrumbs = (payload) => {
    if (payload){
        return {
            type: ActionTypes.ADD_BREADCRUMBS,
            payload: payload
        }
    }
    
};

export const addMoreBreadcrumbs = (payload) => dispatch => {
    if (Array.isArray(payload)){
        payload.map((item) => {
            return dispatch(addBreadcrumbs(item));
        })
    }else{
        dispatch(addBreadcrumbs(payload));
    }
}

export const sendBreadcrumbs = (payload) => dispatch => {
    if (Array.isArray(payload)){
        payload.map((item) => {
            return dispatch(setBreadcrumbs(item.payload));
        })
    }
        dispatch(setBreadcrumbs(payload));
};

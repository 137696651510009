import React from 'react';
import {Row, Col} from 'reactstrap';

const calculateWidth = (ranges, index) => {
    if(index > 0) {
        let width = ranges[1] - ranges[0];
        width += 1;
        return width + '%';
    } else {
        return ranges[1] - ranges[0] + '%';
    }
}
const ComparisonBar = props => {
    return (
        <Row>
            <Col xl="12" style={{height: 15, display: 'inherit'}}>
                {typeof props.scores !== 'undefined' ? (
                    <div className="barWrapper-comparison">
                        {props.feedbacks.map((feedback, y) =>
                            <Col style={{flex: "0 " + calculateWidth(feedback.range, y), backgroundColor: feedback.color, height: '15px', paddingLeft: 0, paddingRight: 0}} key={y}></Col>
                        )}
                        <div className="barReportBall" style={{position: 'absolute', margin: '0 auto', left: (props.scores[0].score - 1) + '%', backgroundColor: "#A6C5D5"}}>1
                            <Row className="firstToolTip" style={{backgroundColor: "#A6C5D5"}}>   
                                {props.firstNames.map(x =>
                                    <Col style={{padding: 10, borderBottom: "2px solid #FFF"}} key={x}> 
                                        <h6 style={{color: '#FFF'}}>{x}</h6>
                                    </Col>
                                )}
                            </Row>
                        </div>
                        <div className="barReportBall" style={{position: 'absolute', margin: '0 auto', left: (props.scores[1].score - 1) + '%', backgroundColor: "#4A7993"}}>2
                            <Row className="secondToolTip" style={{backgroundColor: "#4A7993"}}>   
                                {props.secondNames.map(x =>
                                    <Col style={{padding: 10, borderBottom: "2px solid #FFF"}} key={x}> 
                                        <h6 style={{color: '#FFF'}}>{x}</h6>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                ) : (
                    <div className="barWrapper-comparison">
                        {props.feedbacks.map((feedback, y) =>

                            <Col style={{flex: "0 " + calculateWidth(feedback.range, y), backgroundColor: feedback.color, height: '15px', paddingLeft: 0, paddingRight: 0}} key={y}></Col>
                        )}
                        <div className="barReportBall" style={{position: 'absolute', margin: '0 auto', left: (props.avg - 1) + '%', backgroundColor: props.ballColor}}>1</div>
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default ComparisonBar;
import React from 'react';
import {motion} from 'framer-motion';

const Path = props => (
    <motion.path
      fill="transparent"
      strokeWidth="3"
      stroke="hsl(0, 0%, 18%)"
      strokeLinecap="round"
      {...props}
    />
);

const MenuToggle = ({toggle}) => {
    return (
        
        <motion.div 
        className="filter-nibblet-surveylist" 
        onClick={ toggle }
        title={"Filtteri"}
        positionTransition>
        { 
            <i className="fas fa-filter"></i>
        }
        
        </motion.div>
    )
}

export default MenuToggle;
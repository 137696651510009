import * as ActionTypes from '../../../Components/Kartoitus/SenderV2/Actions/ActionTypes';

/*
    REFORMATTED SENDER REDUCER FOR ENTERPRISE VOL.2
*/

const initialState = {
    linkedCompanies: [],
    sentSurveys: [],
    filters: [],
    emailSurveyList: [],
    collaborations: [],
    availableSurveys: [],
    tasksForDashboard: [],
    groupedSurveys: [],
    autoSelectedSurvey: {},
    backgroundList: [],
    sentSurveysForDashboard: [],
    selectedSentSurvey: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.STORE_COMPANIES: 
            return {
                ...state,
                linkedCompanies: [
                    ...action.payload
                ]
            }
        case ActionTypes.GROUPED_SURVEYS:
            return {
                ...state,
                groupedSurveys: [
                    ...action.payload
                ]
            }
        case ActionTypes.STORE_SURVEYS:
            return {
                ...state,
                sentSurveys: [
                    ...action.payload.surveys
                ],
                filters: [
                    ...action.payload.filters
                ]
                
            }
        case ActionTypes.STORE_AVAILABLE_SURVEYS:
            return {
                ...state,
                availableSurveys: [
                    ...action.payload
                ]
            }
        case ActionTypes.ADD_SENT_SURVEY:
            return {
                ...state,
                sentSurveys: [
                    action.payload,
                    ...state.sentSurveys
                ]
            }
        case ActionTypes.AUTO_SELECT_SURVEY:
            return {
                ...state,
                autoSelectedSurvey: [
                    action.payload
                ]
            }
        case ActionTypes.SET_MODIFIED_SENT_SURVEY:
            return {
                ...state,
                sentSurveys: state.sentSurveys.map(survey => {
                    if(survey._id === action.payload._id) {
                        return action.payload
                    }
                    return survey
                })
            }
        case ActionTypes.DELETE_SENT_SURVEY_FROM_LIST:
            return {
                ...state,
                sentSurveys: state.sentSurveys.filter(survey => survey._id !== action.payload)
            }
        case ActionTypes.SET_BACKGROUND_LIST:
            return {
                ...state,
                backgroundList: action.payload
            }
        case ActionTypes.ADD_TO_BACKGROUND_LIST:{
            return {
                ...state,
                backgroundList: [
                    ...state.backgroundList,
                    action.payload
                ]
            }
        }
        case ActionTypes.UPDATE_SENT_SURVEYS_FOR_DASHBOARD:
            return {
                ...state,
                sentSurveysForDashboard: [
                    ...action.payload
                ]
            }
        case ActionTypes.UPDATE_SELECTED_SENT_SURVEY:
            return {
                ...state,
                selectedSentSurvey: {
                    ...action.payload
                }
            }
        default:
            return state;
    }
}

export default reducer;
import * as ActionTypes from './ActionTypes';
import * as ActionCreator from '../../../store/index';
import { confs } from '../../../modules/config';
import { NotificationManager } from 'react-notifications';
import {CHANGE_LOGO, CHANGE_BACKGROUND_IMAGE} from '../../../store/actions/Login/ActionTypes';

const setEnterprise = payload => {
    return {
        type: ActionTypes.SET_ENTERPRISE,
        payload: payload
    }
}

export const fetchEnterprise = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        setTimeout(() => {
            fetch(confs.url + 'get/enterprise', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                        dispatch(ActionCreator.authFailed())
                    } else {
                        dispatch(setEnterprise(responseJson));
                    }
                })
        }, 300);
    }
}

export const editEnterprise = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'modify/enterprise', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    NotificationManager.success('Yrityksen tiedot päivitetty', '', 2000);
                    dispatch(setEnterprise(responseJson));
                }
            })
    }
}

export const handleApiKeys = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'modify/apiKeys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if(responseJson.status !== 401) {
                        NotificationManager.success('Tiedot päivitetty', '', 2000);
                        dispatch(setApiKeysInUse(responseJson));
                    } else {
                        NotificationManager.error(responseJson.message, "Virhe!", 4000);
                    }
                }
            })
    }
}

const setApiKeysInUse = payload => {
    return {
        type: ActionTypes.API_KEYS_IN_USE,
        payload: payload
    }
}

export const saveUserData = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'modify/user', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if(responseJson.ERROR !== undefined) {
                        NotificationManager.error("Käyttäjä on jo lisättynä", "", 2000)
                    } else {
                        NotificationManager.success('Käyttäjän tiedot päivitetty', '', 2000);
                        dispatch(setEnterprise(responseJson));
                    }
                   
                }
            })
    }
}

const saveLogo = params => {
    return {
        type: ActionTypes.SAVE_LOGO,
        payload: params
    }
}

const changeLogo = params => {
    return {
        type: CHANGE_LOGO,
        payload: params
    }
}

export const uploadLogo = (data) => dispatch => {
    fetch(confs.url + 'send/uploadLogo', {
        method: 'POST',
        body: data
    })
        .then(res => res.json())
        .then(response => {
            if (typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (response.success) {
                    NotificationManager.success("Logo lisätty onnistuneesti", "", 2500);
                    dispatch(saveLogo(response.logo))
                    dispatch(changeLogo(response.logo));
                } else {
                    switch (response.error) {
                        case "IMG_SIZE":
                            NotificationManager.error('Tiedoston koko täytyy olla vähemmän kuin 14MB', 'Logon tallennus epäonnistui', 2000);
                            return;
                        case "NOT_IMG":
                            NotificationManager.error('Tiedoston täytyy olla kuvatiedosto', 'Logon tallennus epäonnistui', 2000);
                            return;
                        default:
                            NotificationManager.error('Jokin meni pieleen :(', 'Logon tallennus epäonnistui', 2000);
                            return;
                    }
                }
            }
        })
}

const saveBackground = params => {
    return {
        type: ActionTypes.SAVE_BACKGROUND,
        payload: params
    }
}

// const changeBackground = params => {
//     return {
//         type: CHANGE_BACKGROUND_IMAGE,
//         payload: params
//     }
// }

export const uploadBackgroundImage = (data) => dispatch => {
    fetch(confs.url + 'send/uploadBackgroundImage', {
        method: 'POST',
        body: data
    })
        .then(res => res.json())
        .then(response => {
            if (typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (response.success) {
                    NotificationManager.success("Tausta lisätty onnistuneesti", "", 2500);
                    dispatch(saveBackground(response.backgroundImage))
                    // dispatch(changeBackground(response.background));
                } else {
                    switch (response.error) {
                        case "IMG_SIZE":
                            NotificationManager.error('Tiedoston koko täytyy olla vähemmän kuin 14MB', 'Logon tallennus epäonnistui', 2000);
                            return;
                        case "NOT_IMG":
                            NotificationManager.error('Tiedoston täytyy olla kuvatiedosto', 'Logon tallennus epäonnistui', 2000);
                            return;
                        default:
                            NotificationManager.error('Jokin meni pieleen :(', 'Logon tallennus epäonnistui', 2000);
                            return;
                    }
                }
            }
        })
}

export const uploadExcelToBusiness = (data) => dispatch =>  {
    fetch(confs.url + 'excel/uploadToBusiness', {
        method: 'POST',
        body: data
    })
    .then(res => res.json())
        .then(response => {
            if (typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (response.success) {
                    NotificationManager.success("Tiedosto lisätty onnistuneesti", "", 2500);
                    dispatch(uploadExcel(response.excel))
                    if(response.notSavedHubSpots){
                        dispatch(notAddedHubSpots(response.notSavedHubSpots))
                    }
                } else {
                    NotificationManager.error("Virhe tallentaessa dataa. Tarkistathan, että datan sisältö on oikeanlainen", "Virhe", 5000);
                }
            }
        })
}

const uploadExcel = params => {
    return {
        type: ActionTypes.ADD_EXCEL,
        payload: params
    }
}

const notAddedHubSpots = params => {
    return {
        type: ActionTypes.NOT_ADDED_HUBSPOTS,
        payload: params
    }
}

const removeExcel = params => {
    return {
        type: ActionTypes.REMOVE_EXCEL,
        payload: params
    }
}

export const deleteBusinessExcel = (params) => dispatch => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    fetch(confs.url + 'excel/deleteFile', {
        method: 'POST',
        body: JSON.stringify(payload)
    })
    .then(res => res.json())
        .then(response => {
            if (typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (response.deleted) {
                    NotificationManager.success("Tiedosto poistettu onnistuneesti", "", 2500);
                    dispatch(removeExcel(response.id))
                } 
            }
        })
}

export const inactivateUser = (params) => dispatch => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    fetch(confs.url + 'admin/inactivateUser', {
        method: 'POST',
        body: JSON.stringify(payload)
    })
    .then(res => res.json())
        .then(response => {
            if (typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(response.ERROR !== undefined) {
                    NotificationManager.error("Käyttäjän inaktivoinnissa tapahtui virhe", "Virhe", 3000);
                } else {
                    NotificationManager.success("Käyttäjä inaktivoitu", "", 3000);
                    dispatch(setEnterprise(response))
                } 
            }
        })
}

export const handleEnterpriseColors = (params) => dispatch => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    fetch(confs.url + 'enterprise/updateColors', {
        method: 'POST',
        body: JSON.stringify(payload)
    })
    .then(res => res.json())
        .then(response => {
            if (typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(response.error !== undefined) {
                    NotificationManager.error("Virhe tallentaessa värimuutoksia", "Virhe", 3000);
                } else {
                    NotificationManager.success("Väri muutettu onnistuneesti", "", 3000);
                    dispatch(setEnterprise(response))
                } 
            }
        })
}
import React from 'react'
import {
  Row,
  Col
} from 'reactstrap'
import moment from 'moment'

export default function LatestAnswerRow( props ) {
  const { answerData } = props

  return (
    <div className={ 'selectable-row' } onClick={ () => props.onInspectClick( answerData ) }>
      <Row className="dashboard-survey-row" style={ { paddingBottom: 0 } }>
        <Col xs={ { size: 2 } } style={ { display: 'flex', justifyContent: 'center', marginTop: '.5em' } }>
          <div className="smaller-score-ball" style={ { borderColor: answerData.color } }>{ answerData.avgPoints }</div>
        </Col>

        <Col xs={ { size: 10 } }>
          <p className="lighter-text">{ moment.utc( answerData.created, "MMM D, YYYY, HH:mm:ss A", "en" ).local().format( 'D.M.YYYY  HH.mm' ) } <span style={ { float: 'right' } }>{ answerData.code }</span></p>

          <p className="big-text mb-0" style={ { overflow: 'hidden' } } >{ answerData.surveyName }</p>


        </Col>

      </Row>
      <Row className="dashboard-survey-row" style={ { paddingTop: 0 } }>

        <Col xs={ { size: 9, offset: 2 } } className="content-text">
          <p>{ answerData.businessName }</p>
        </Col>
        <Col xs={ { size: 1 } } style={ { display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' } } className="content-text">
          {/* <button style={ { paddingLeft: '1rem', paddingRight: '1rem' } } className="small-white-button lighter-text">Tarkastele</button> */ }
          <i style={ { float: 'right', color: '#ccc' } } className="fas fa-caret-right"></i>
        </Col>

      </Row>


    </div>
  )
}
import React, { useEffect, useState, useMemo }from "react";
import { Table } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';

const rowSelections = [...Array(10).keys()].map(key => {
    return {
        value: key + 1,
        label: key + 1
    }
})

const colSelections = [...Array(4).keys()].map(key => {
    return {
        value: key + 1,
        label: key + 1
    }
})

export const GridTableConf = ({handleTableConfChanges}) => {
    const [numberOfRows, setNumberOfRows] = useState({value: 1, label: 1});
    const [numberOfColumns, setNumberOfColumns] = useState({value: 1, label: 1})
    const [tableConfiguration, setTableConfiguration] = useState({
        selectedNumberOfCols: 1,
        rows: [
            {
                id: uuidv4(),
                cols: [
                    {
                        id: uuidv4(),
                        text: '-'
                    }
                ]
            }
        ]
    })

    const tableConfigurationHandler = (type, incomingValues) => {
        let configuration = {
            ...tableConfiguration
        }

        switch(type) {
            case 'rows':
                let colValue = numberOfColumns.value;

                configuration = {
                    ...configuration,
                    rows: [...Array(incomingValues.value).keys()].map(key => {
                        
                        return {
                            id: uuidv4(),
                            cols: 
                                [...Array(colValue).keys()].map(col => {
                                    return {
                                        text: '-',
                                        id: uuidv4()
                                    }
                                })
                            
                        }
                    })
                }
                setNumberOfRows(incomingValues)
                break;
            case 'columns':
                let rowValue = numberOfRows.value

                configuration = {
                    ...configuration,
                    selectedNumberOfCols: incomingValues.value,
                    rows: [...Array(rowValue).keys()].map(key => {
                        return {
                            id: uuidv4(),
                            cols:
                                [...Array(incomingValues.value).keys()].map(col => {
                                    return {
                                        text: '-',
                                        id: uuidv4()
                                    }
                                })
                            
                        }
                    })
                }
                setNumberOfColumns(incomingValues)
                break;
            default:
                break;
        }

        setTableConfiguration(configuration);
        handleTableConfChanges({id:'table',value:configuration})
    }

    return(
        <div>
            <div style={{display: 'flex', flexDirection: 'row', marginBottom: '2em'}}>
                <div style={{flex: '1', marginRight: '2em'}}>
                    <label htmlFor="rowSelector">Rivit</label>
                    <Select 
                        id="rowSelector" 
                        options={rowSelections}
                        value={numberOfRows}
                        onChange={value => tableConfigurationHandler('rows', value)}
                    />
                </div>
                <div style={{flex: '1'}}>
                    <label htmlFor="rowColumns">Sarakkeet</label>
                    <Select
                        id="rowColumns" 
                        options={colSelections}
                        value={numberOfColumns}
                        onChange={value => tableConfigurationHandler('columns', value)}
                    />
                </div>
            </div>
            <Table bordered responsive hover>
                <thead>
                    
                </thead>
                <tbody>
                    {tableConfiguration.rows.map((row, index) => 
                        <tr  key={index}>
                            {row.cols.map((col, index) => {
                                return <TableElement count={row.cols.length} col={col} index={index} maximumCount={tableConfiguration.selectedNumberOfCols} key={col.id}/> 
                            })}
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    )
} 

const TableElement = ({count, col, index, maximumCount}) => {

    if(count === 1) {
        return (
            <td key={col.id} colSpan={maximumCount}>{col.text}</td> 
        )
    } else {
        return (
            <td key={col.id} colSpan={(maximumCount % count === 1 && index + 1 === count) ? maximumCount / count + 1 : maximumCount / count}>{col.text}</td> 
        )
    }
}
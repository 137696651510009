
import React, { useEffect, useState, useId }from "react";
import {Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Panel, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import './gridBlock.css'
import GridLayout,{Responsive, WidthProvider} from "react-grid-layout"
import { convertFromRaw } from 'draft-js';
import { ModifiedContainer } from "../DataContainers";
import TextEditor from "../../../../Utilities/TextEditor/texteditor";
import { v4 as uuidv4 } from 'uuid';
import { GridTable, GridText } from "./GridItems";
import { Droppable } from "react-beautiful-dnd";
import { GridTableConf } from "./GridTableConf";

const ResponsiveGridLayout = WidthProvider(Responsive)

const testLayout = [
    { i: uuidv4(), x: 0, y: 0, w: 2, h: 5, topic:'TestiOtsikko', type:'topic-draft' },
    { i: uuidv4(), x: 0, y: 5, w: 3, h: 3 },
    { i: uuidv4(), x: 3, y: 0, w: 4, h: 5, topic: "TestiTaulukko", type: 'tables',
        table: {
            rows: [
                {
                    id: uuidv4(),
                    cols: [
                        {
                            id: uuidv4(),
                            text: '-'
                        },
                        {
                            id: uuidv4(),
                            text: '-'
                        }
                    ]
                },
                {
                    id: uuidv4(),
                    cols: [
                        {
                            id: uuidv4(),
                            text: '-'
                        },
                        {
                            id: uuidv4(),
                            text: '-'
                        }
                    ]
                }
            ],
            selectedNumberOfCols: 2
        }
    },
    { i: uuidv4(), x: 0, y: 8, w: 6, h: 3 }
];

const INITIAL_GRID_ITEM = { 
    x: 0,
    y: 0,
    w: 6,
    h: 5,
    i: uuidv4(),
    topic: 'Otsikko'
}

const gridItemTypes = [
    { name: 'Teksti' , type: 'topic-draft' },
    { name: 'Taulukko' , type: 'tables' }
]

export const GridBlock = ({planItem, plan, editable = false, index, promptSave, toggleDrag, onDelete, toggleItemVisibility, onTemplateAdd }) => {
    const [layout, setLayout] = useState([])
    const [compactType, setcompactType] = useState("vertical");
    const [mounted, setmounted] = useState(false);
    const [title, setTitle] = useState(planItem.topicContent)
    const [locked, setLocked] = useState(true)
    const [openMenu, toggleMenu] = useState(false)
    const [lastRowInLayout, setLastRowInLayout] = useState(0)
    const [newItem, setNewItem] = useState(false)
    const [newItemModalOpen, setNewItemModalOpen] = useState(false)
    const [typeSelectionOpen, setTypeSelectionOpen] = useState(false)

    const handleDataChanges = (data) => {
        setTitle(data)
        let itemPayload = {
            ...planItem,
            topicContent: data,
            layout:layout
        }
        promptSave(itemPayload)
    }
    const onCompactTypeChange = () => {
        const oldCompactType = compactType;
        const compactType =
          oldCompactType === "horizontal"
            ? "vertical"
            : oldCompactType === "vertical"
            ? null
            : "horizontal";
        setcompactType(compactType);
      };

    const getPlainText = rawEditorState => {
        if(typeof rawEditorState !== 'string') {
            let plainTopic = convertFromRaw(rawEditorState).getPlainText();
            plainTopic = plainTopic.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
            return plainTopic;
        } else {
            return rawEditorState;
        }
    }

    useEffect(()=>{
        if(layout.length > 0){
            let array = layout
            let maxValue = array.reduce((acc, value) => {
                return (acc = acc > value.y ? acc : value.y);
            }, 0);
            setLastRowInLayout(maxValue)
        }
        // console.log(layout);
    },[layout])

    useEffect(()=>{
        if(editable === true){
            handleEditable()
        }
    },[editable])

    useEffect(()=>{
        if(editable) {toggleDrag(false)}
    },[])

    useEffect(()=>{
        if(planItem && planItem.layout){
            setLayout(planItem.layout)
        }
    },[planItem])

    const handleEditable = () => {
        let content = layout.map((item) => {
            item = {
                ...item,
                static:!editable
            }
            return item
        })
        setLayout([...layout])
    }


    const onDrop = elemParams => {
        alert(
          `Element parameters:\n${JSON.stringify(
            elemParams,
            ["x", "y", "w", "h"],
            2
          )}`
        )
    }

    const handleDragLock = (event) => {
        if(event==="lock"){
            toggleDrag(false)
        } else {
            toggleDrag(true)
        }
        setLocked(!locked)
    }

    const addNewGridItem = () => {
        let newGridItem = {
            ...newItem,
            i:uuidv4(),
            y: (lastRowInLayout+1)
        }

        if(newItem.type === 'tables'){
            
            let defaultRows =  [
                {
                    id: uuidv4(),
                    cols: [
                        {
                            id: uuidv4(),
                            text: '-'
                        }
                    ]
                }
            ] 
            let height = (newItem.table && newItem.table.rows)? newItem.table.rows.length*1.5 + 1 : 3
            
            newGridItem = {
                ...newGridItem,
                i:uuidv4(),
                y: (lastRowInLayout+1),
                h: height,
                table: {
                    rows: (newItem.table && newItem.table.rows)? newItem.table.rows: defaultRows,
                    selectedNumberOfCols: (newItem.table && newItem.table.selectedNumberOfCols)? newItem.table.selectedNumberOfCols: 1
                }
            }
            // console.log(newGridItem.table.rows.length, (newGridItem.table.rows.length*1.25 + 1) );
        }
       
      
        // console.log(newGridItem);
        setLayout([...layout, newGridItem])
        setNewItemModalOpen(false)
    }

    const onItemDelete = (item) => {
        let array = [...layout]
        array.splice(array.indexOf(item),1)
        array.filter(gridItem => gridItem.i !== item.i)
        setLayout([...array])
        //TODO - save on remove
    }

    const handleItemEdit = ({item, id, value}) => {
        //console.log("onEdit");
        if(item){
            let array = layout.map((gridItem)=>{
                if(gridItem.i === item.i){
                    let editedItem = {
                        ...gridItem,
                        [id]:value
                    }
                    // console.log("edited: ",editedItem);
                    return editedItem
                }
                return gridItem
            })
           
            setLayout([...array])
        }
   
        let payload = {
            ...planItem,
            topicContent: title,
            layout:layout
        }
        promptSave(payload)
    }

    const onItemEdit = (value) => {
        console.log(value);
    }

    const newGridItem = () => {
        setNewItemModalOpen(true)
        setNewItem(INITIAL_GRID_ITEM)
    }

    const editNewItem = ({id,value}) => {
        let updatedItem = {
            ...newItem,
            [id]:value
        }
        setNewItem(updatedItem)
    }

    

    return(
        <>
            {newItem &&
                <Modal isOpen={newItemModalOpen}>
                    <ModalHeader>Valitse solun tyyppi</ModalHeader>
                    <ModalBody>
                        <Col>
                            <Row>
                                <Col>
                                    <div style={{display:'flex'}}>
                                        <Label>Solun tyyppi: </Label>
                                        <Dropdown isOpen={ typeSelectionOpen } style={{ cursor: 'pointer' }} className="" onClick={ (event)=> event.stopPropagation() } toggle={ () => setTypeSelectionOpen( !typeSelectionOpen ) } >
                                            <DropdownToggle tag="div">
                                                <button className="small-white-button wide-button" style={{marginLeft:'1em'}}>
                                                    {newItem.type? 
                                                        gridItemTypes.map((itemType)=>{
                                                            if(itemType.type === newItem.type){
                                                                return(itemType.name)
                                                            }
                                                        })
                                                        :
                                                        "Valitse solun tyyppi"
                                                    }
                                                </button>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {gridItemTypes.map((type, index)=>{
                                                    return(
                                                        <DropdownItem key={index} onClick={() => editNewItem({id:"type", value:type.type})} className="">{type.name}</DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                   {newItem.type === 'tables' &&
                                        <div>
                                            <GridTableConf handleTableConfChanges={editNewItem}/>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => setNewItemModalOpen(false)}>Peruuta</a>  
                        <button className="small-white-button lighter-text wide-button" onClick={() => addNewGridItem()}>Tallenna</button>
                    </ModalFooter>
                </Modal>
            }
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!planItem.visible && "plan-item-private")} id={planItem.id}>
                <Col xl="12">
                    {(editable && (!planItem.visible && planItem.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red' }}>Yksityinen</span>}
                    {(!editable && planItem.modified !== undefined) && 
                        <ModifiedContainer modified={planItem.modified} visible={planItem.visible} modifiedBy={planItem.modifiedByUser}/>
                    }
                </Col>
                <Col xl="12" style={{marginBottom: 8}} className="title-col">
                    <div style={{ display: 'flex' }}>
                        <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}</div>            
                        <TextEditor
                            editorId={`${planItem.i}-subtitle-${editable}`} 
                            content={title} 
                            onTextChange={editorState => handleDataChanges(editorState)}
                            additionalClass="plan-input subTitle plan-subtitle"
                            placeholder="-"
                            readOnly={!editable}
                            plaintextEditor={true}
                        />
                    </div>
                </Col>
                {editable &&
                    <Col xs="12">
                        <div style={{ marginLeft:'auto', marginRight:'0', display:'flex', flexDirection:'row'}}>
                            <div style={{ marginRight: '1em'}} title={"Avaa tai lukitse mahdollisuus liikuttaa tätä kappaletta (vaikuttaa vain kappalejärjestyksen muuttamiseen)"}>
                                {locked?
                                    <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={()=> handleDragLock("unlock")}>
                                        <i className="fas fa-lock"></i>
                                    </button>
                                    :
                                    <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={()=> handleDragLock("lock")}>
                                        <i className="fas fa-lock-open"></i>
                                    </button>
                                }
                            </div>
                            <button className={"small-white-button lighter-text " } title="Lisää uusi muokattava solu" style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={()=> newGridItem()}>
                                <i className="fas fa-plus"></i> 
                            </button>   
                        </div>  
                    </Col> 
                }
                <Col xl="11">
                    <ResponsiveGridLayout
                    // {...this.props}
                        rowHeight={40}
                        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                        layout={layout}
                        onLayoutChange={(update) => setLayout(update)}
                        onDrop={onDrop}
                        // WidthProvider option
                        measureBeforeMount={false}
                        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                        // and set `measureBeforeMount={true}`.
                        useCSSTransforms={mounted}
                        compactType={compactType}
                        preventCollision={!compactType}
                        //isDroppable={true}
                        droppingItem={{ i: uuidv4(), h: 3, w: 3 }}
                    > 
                        {layout.map((item, i) => {
                            let gridItem = (editable? item : {...item, static: true})
                            return(
                                <div key={item.i} data-grid={gridItem} className={"block grid-object " + (editable? "grid-object-edit": "grid-object-preview")}>
                                    {editable && 
                                        <div style={{ marginTop:'-0.25em', marginLeft:'0.25em', float:'right', cursor:'pointer'}}>
                                            <GridItemMenu item={item} onDelete={onItemDelete} toggleTopicVisible={handleItemEdit}/>
                                        </div>
                                    }
                                   {!item.hideTopic &&
                                        <div style={{marginBottom:'1em'}}>
                                            <TextEditor
                                                editorId={`${item.i}-subtitle`} 
                                                content={item.topic} 
                                                onTextChange={editorState => handleItemEdit({item:item,id:'topic', value: editorState})}
                                                additionalClass={"grid-topic " + (editable? "grid-edit":"grid-preview")}
                                                placeholder="-"
                                                plaintextEditor={true}
                                            />
                                        </div>
                                    }
                                    <div>
                                        <GetItemByType 
                                            item={item}
                                            handleEdit={handleItemEdit}
                                            editable={editable}
                                            planId={plan._id}
                                        />
                                    </div>
                                </div>
                            )
                        })} 
                    </ResponsiveGridLayout>
                </Col>
                <Col xs="1">
                <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                        {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                            <DropdownToggle tag="div">
                                <i className="fas fa-bars plusIcon" title="Valikko"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => toggleItemVisibility({planItemId: planItem.id})} className="change-status">{(planItem.visible !== undefined && !planItem.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                                <DropdownItem onClick={() => onTemplateAdd({planItem: planItem})} className="add-template">Lisää pohjaksi</DropdownItem>
                                <DropdownItem onClick={() => onDelete({planItemId: planItem.id, topicContent: planItem.blockName})} className="delete-plan-item">Poista</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>}
                        </div>
                </Col>
            </Row>
        </>
    )
}

const GridItemMenu = ({item, onDelete, toggleTopicVisible}) => {
    const [isOpen, setIsOpen] = useState(false)
    
    return(
        <Dropdown isOpen={ isOpen } style={{ cursor: 'pointer', zIndex:'99' }} className="" onClick={ (event)=> event.stopPropagation() } toggle={ () => setIsOpen( !isOpen) } >
            <DropdownToggle tag="div">
                <i className="fa fa-cog" style={{fontSize:'0.7rem'}}></i>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => toggleTopicVisible({item:item, id:'hideTopic', value: item.hideTopic? !item.hideTopic: true})} className="delete-plan-item">{item.hideTopic? 'Näytä otsikko': 'Piilota otsikko'}</DropdownItem>
                <DropdownItem onClick={() => onDelete(item)} className="delete-plan-item">Poista</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}


const GetItemByType = (props) => {

    switch(props.item.type){
        case 'tables':
            return(
                <GridTable 
                    item={props.item} 
                    handleEdit={props.handleEdit}
                    editable={props.editable}
                    planId={props._id}
                />
            )

        case 'topic-draft':
            return(
                <GridText 
                    item={props.item} 
                    handleEdit={props.handleEdit}
                    editable={props.editable}
                    planId={props._id}
                />
            )
    }
}
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Row, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

const EmbedList = (props) => {
    let i = 0;
    return (
        <div className="company-box">
            <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                <p><b>Upotukset</b></p>
                <div style={{ marginLeft: '0.5em' }}>
                    <form id="search-bar">
                        <input type="search" className="search-bar-input" placeholder="Etsi kartoitusta" value={props.filter} onChange={(event) => props.setfilter(event.target.value)} />
                    </form>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: '1em' }}>
                    <button className="small-white-button lighter-text wide-button" onClick={() => props.handleSelection(null, -1)}>Lisää uusi</button>
                </div>
            </div>
            <Scrollbars
                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                style={{ width: "100%", height: '700px' }} >
                <div>
                    {props.list && props.list.length > 0 ?
                        <Container>
                            {props.list.map((item, y) => {
                                if (item.hostName.toLowerCase().includes(props.filter.toLowerCase())) {


                                    let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row';
                                    if (props.editId === item.id) {
                                        oddEvenClass = oddEvenClass + ' active-embed'
                                    }
                                    i++;
                                    return (
                                        <Row key={y} className={oddEvenClass + ' embed-list'} onClick={() => props.handleSelection(item, item.id)}>
                                            <Col xs="10">
                                                {item.hostName}
                                            </Col>
                                            <Col xs="2" float="center">
                                                <Dropdown isOpen={props.selected === y} style={{ height: '100%', width: '100%' }} onClick={(event) => event.stopPropagation()} toggle={() => props.toggleDropdown(y)}>
                                                    <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1rem' }}>
                                                        <i className="fas fa-bars" title="Valikko"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => props.delete(item.id)}>Poista</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    )
                                }
                            })}

                        </Container>
                        :
                        <Col style={{ marginTop: '1em' }}>
                            <p>Upotuksia ei löytynyt</p>
                        </Col>
                    }
                </div>
            </Scrollbars>

        </div>
    )
}

export default EmbedList;
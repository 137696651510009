import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';

export const ListSelectionModal = ({isOpen, modulesInUse, listSelection, toggle, updateSelection, handleListSelection, selectedBusiness }) => {

    return(
        <Modal isOpen={isOpen} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()}>Valitse yhteenvedossa näytettävät listat</ModalHeader>
            <ModalBody>
                {listSelection && 
                    <Col style={{ paddingLeft: '2m', marginTop:'-0.5em'}}>
                        {modulesInUse.contactManagement &&
                            <Row>
                                <div className="list-selection-item">
                                    <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:"contacts", value:!listSelection.contacts})}>
                                        <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Kontaktit</p>
                                    </div>
                                    <label className="switch switch-in-modal" >
                                        <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:"contacts", value:!listSelection.contacts})} checked={listSelection.contacts}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </Row>
                        }
                        {modulesInUse.planManagement &&
                            <Row>
                                <div className="list-selection-item">
                                    <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:"plans", value:!listSelection.plans})}>
                                        <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Lomakkeet</p>
                                    </div>
                                    <label className="switch switch-in-modal" >
                                        <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:"plans", value:!listSelection.plans})} checked={listSelection.plans}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </Row>
                        }
                        {modulesInUse.projectManagement &&
                            <Row>
                                <div className="list-selection-item">
                                    <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:"projects", value:!listSelection.projects})}>
                                        <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Projektit</p>
                                    </div>
                                    <label className="switch switch-in-modal">
                                        <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:"projects", value:!listSelection.projects})} checked={listSelection.projects}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </Row>
                        }                      
                       {modulesInUse.taskManagement &&
                            <Row>
                                <div className="list-selection-item">
                                    <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:"tasks", value:!listSelection.tasks})}>
                                        <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Tarkistuspisteet</p>
                                    </div>
                                    <label className="switch switch-in-modal">
                                        <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:"tasks", value:!listSelection.tasks})} checked={listSelection.tasks}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </Row>
                        }
                        {modulesInUse.surveyManagement &&
                            <Row>
                                <div className="list-selection-item">
                                    <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:"answers", value:!listSelection.answers})}>
                                        <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Viimeisimmät vastaukset</p>
                                    </div>
                                    <label className="switch switch-in-modal">
                                        <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:"answers", value:!listSelection.answers})} checked={listSelection.answers}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </Row>
                        }
                        <Row>
                            <div className="list-selection-item">
                                <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:"excels", value:!listSelection.excels})}>
                                    <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Yritysexcelit</p>
                                </div>
                                <label className="switch switch-in-modal">
                                    <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:"excels", value:!listSelection.excels})} checked={listSelection.excels}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </Row>
                        {selectedBusiness && selectedBusiness.hubSpot && 
                            <Row>
                                <div className="list-selection-item">
                                    <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:"hubSpot", value:!listSelection.hubSpot})}>
                                        <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>HubSpot</p>
                                    </div>
                                    <label className="switch switch-in-modal">
                                        <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:"hubSpot", value:!listSelection.hubSpot})} checked={listSelection.hubSpot}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </Row>
                        }
                        {modulesInUse.riskManagement && 
                            <Row>
                                <div className="list-selection-item">
                                    <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:"risks", value:!listSelection.risks})}>
                                        <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Riskit</p>
                                    </div>
                                    <label className="switch switch-in-modal">
                                        <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:"risks", value:!listSelection.risks})} checked={listSelection.risks}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </Row>
                        }
                        <Row>
                            <div className="list-selection-item">
                                <div className="list-selection-inner" style={{ width: '90%' }} onClick={() => handleListSelection({id:"trello", value:!listSelection.trello})}>
                                    <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Trello</p>
                                </div>
                                <label className="switch switch-in-modal">
                                    <input id="switch-input" type="checkbox" onChange={() => handleListSelection({id:"trello", value:!listSelection.trello})} checked={listSelection.trello}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </Row>
                    </Col>
                }
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Peruuta</a>
                <button className="small-white-button wide-button active-button" style={{ fontWeight:'bold' }} onClick={() => (updateSelection(), toggle())}>Tallenna</button>
            </ModalFooter>
        </Modal>        
    )
}
import React from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import { connect } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Treehead from './treehead'
import * as ActionCreator from '../../../store/index'
import { Scrollbars } from 'react-custom-scrollbars'
import './treeview.css'

class Treeview extends React.Component {

  constructor() {
    super()

    this.state = {
      selectedSurveys: [],
      surveyTreeReady: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ surveyTreeReady: true })
    }, 500)
  }

  onDragEnd = result => {
    const { destination, source, type } = result
    // const editedSurveyTree = { ...this.props.surveyTree }
    const editedSurveyTree = JSON.parse(JSON.stringify(this.props.surveyTree))

    //no changes if there is no destination, or if the dragged items position hasn't changed
    if (!destination) { return }
    if (destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    let newSurveyTree = [];
    const surveyTreeIndex = type.split('-').pop()
    const droppedType = type.split('-').shift()

    if (droppedType === 'header') {
      const newIds = Array.from(editedSurveyTree[surveyTreeIndex].surveyTree)
      const movedObj = newIds[source.index]
      newIds.splice(source.index, 1)
      newIds.splice(destination.index, 0, movedObj)

      newSurveyTree = [
        ...newIds
      ]
    } else {

      const newData = editedSurveyTree[surveyTreeIndex].surveyTree

      const movedObj = newData[source.droppableId].surveys[source.index]

      newData[source.droppableId].surveys.splice(source.index, 1)
      newData[destination.droppableId].surveys.splice(destination.index, 0, movedObj)

      newSurveyTree = [
        ...newData
      ]
    }
    editedSurveyTree[surveyTreeIndex].surveyTree = newSurveyTree
    this.props.reorderSurveys(editedSurveyTree)
  }

  render() {
    const { onTreeHeadClick, onTreeSurveyClick, selectedSurveyIds, deleteGroup, surveyTree, filter, userInfo } = this.props

    return (
      <Container fluid className="tree-container">
        {this.state.surveyTreeReady ? (
          // eli tässä loopataan kaikki surveytree indexit ja laitetaan otsikoksi liittyvän enterprisen nimi?
          // sama sekä businekselle että enterpriselle?
          <Scrollbars renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            style={{ marginBottom: "1em" }}
            autoHeight autoHeightMin={50} autoHeightMax={600}
          >
            {surveyTree.map((surveyTreeObj, surveyTreeIndex) => {
              return <Row key={surveyTreeIndex} style={{ marginBottom: '1rem' }}>
                <Col md={{ size: 12 }}>
                  {userInfo.userType === 'business' && <h5 style={{ marginLeft: '.8rem' }}>{surveyTreeObj.enterpriseName}</h5>}
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable-container" type={`header-${surveyTreeIndex}`}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className={snapshot.isDraggingOver ? 'dragging-over' : ''}
                          {...provided.droppableProps}
                        >

                          {typeof surveyTreeObj.surveyTree != 'undefined' && surveyTreeObj.surveyTree.length > 0 && surveyTreeObj.surveyTree.map((obj, index) => {
                            return <Treehead
                              key={index}
                              isDragDisabled={false}
                              id={index.toString()}
                              class={index === 0 ? "first-header" : ""}
                              surveyTree={obj}
                              index={index}
                              onTreeHeadClick={onTreeHeadClick}
                              onTreeSurveyClick={onTreeSurveyClick}
                              selectedSurveys={selectedSurveyIds}
                              deleteGroup={deleteGroup}
                              filter={filter}
                              surveyTreeIndex={surveyTreeIndex}
                            />
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Col>
              </Row>
            })}
          </Scrollbars>

        ) : (
            <div style={{ width: '3rem', height: '3rem', display: 'block', margin: 'auto' }} className="spinner-border text-primary"></div>
          )}
      </Container >
    )
  }
}

const mapStateToProps = state => {
  return {
    business: state.reports.selectedBusiness,
    surveyTree: state.reports.surveyTree,
    userInfo: state.authentication
  }
}

const mapDispatchToProps = dispatch => {
  return {
    reorderSurveys: (business, surveyTree) => dispatch(ActionCreator.reorderSurveytree(business, surveyTree))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Treeview)
import * as ActionTypes from './ActionTypes';
import * as ActionCreator from '../../index';
import { confs } from '../../../modules/config';
import { NotificationManager } from 'react-notifications';


export const storeCategories = (payload) => {
    return {
        type: ActionTypes.STORE_CATEGORIES,
        payload: payload
    }
};

export const setCategory = payload => {
    return {
        type: ActionTypes.SET_CATEGORY,
        payload: payload
    }
}

export const setTags = payload => {
    return {
        type: ActionTypes.SET_TAGS,
        payload: payload
    }
}

export const setQuestion = payload => {
    return {
        type: ActionTypes.SET_QUESTION,
        payload: payload
    }
}

export const setQuestionGroup = payload => {
    return {
        type: ActionTypes.SET_QUESTIONGROUP,
        payload: payload
    }
};

export const resetBuilderRedux = payload => {
    return {
        type: ActionTypes.RESET_BUILDER_REDUX
    }
}

export const categoryView = () => {
    const payload = {
        categoryWindow: true,
        questionGroupWindow: false,
        questionWindow: false
    };

    return {
        type: ActionTypes.CATEGORY_VIEW,
        payload: payload
    }
}

export const storeActiveSentSurveys = data => {
    return {
        type: ActionTypes.STORE_ACTIVE_SENT_SURVEYLIST,
        payload: data
    }
}

export const questionGroupView = (group) => {
    const payload = {
        categoryWindow: false,
        questionGroupWindow: true,
        questionWindow: false,
        currentQuestionGroup: {
            ...group
        }
    };
    return {
        type: ActionTypes.QUESTIONGROUP_VIEW,
        payload: payload
    }
}

export const storeGroup = payload => {
    return {
        type: ActionTypes.STORE_GROUP,
        payload: payload
    }
}

export const addPoints = data => {
    return {
        type: ActionTypes.ADD_POINTS,
        payload: data
    }
};

export const decrementPoints = data => {
    return {
        type: ActionTypes.DECREMENT_POINTS,
        payload: data
    }
};

export const changeTab = values => {
    return {
        type: ActionTypes.CHANGE_TAB_FOR_QUESTIONOPTION,
        payload: values
    }
}

export const addGroupPoints = data => {
    return {
        type: ActionTypes.ADD_GROUP_POINTS,
        payload: data
    }
};

export const decrementGroupPoints = data => {
    return {
        type: ActionTypes.DECREMENT_GROUP_POINTS,
        payload: data
    }
};

export const setPoints = data => {
    return {
        type: ActionTypes.SET_POINTS,
        payload: data
    }
}

export const startCategory = payload => {
    return {
        type: ActionTypes.START_CATEGORY,
        start: payload.begin
    };
};

export const startEditingQuestion = payload => {
    const data = {
        categoryWindow: false,
        questionGroupWindow: false,
        questionWindow: true,
        currentQuestion: {
            ...payload.currentQuestion
        },
        groupData: {
            ...payload.groupData
        }
    };
    return {
        type: ActionTypes.EDIT_QUESTION,
        data: Object.assign({}, data)
    }
}

//Set categoryIndex
export const startEditingCategory = payload => {

    let object = {
        ...payload
    };
    return {
        type: ActionTypes.STORE_CATEGORY,
        payload: object
    }
}
export const storeCategory = payload => {
    return {
        type: ActionTypes.STORE_CATEGORY,
        payload: payload
    }
}

export const fetchCategories = (params) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'get/categories', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(storeCategories(responseJson));
                }
            }).catch(error => {
                ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
            })
    }
};

const storeSentSurveyList = data => {
    return {
        type: ActionTypes.STORE_SENT_SURVEYLIST,
        payload: data
    }
};

export const fetchSentSurveysWithId = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'get/sentSurveyList', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    dispatch(storeSentSurveyList(responseJson));
                }
            })
    }
}

const storeSurveyLinkTree = data => {
    return {
        type: ActionTypes.STORE_SURVEY_LINKTREE,
        payload: data
    }
}
export const getSurveyLinks = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'get/linkTree', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if( typeof responseJson.survey !== 'undefined' ) {
                        dispatch( storeSurveyLinkTree( responseJson.survey ) );
                    } else {
                        dispatch( storeSurveyLinkTree( {} ) )
                    }
                }
            })
    }
}
export const getTargetSentSurvey = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        dispatch(ActionCreator.loadingStatus(true))
        fetch(confs.url + 'get/targetsentSurvey', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if(responseJson.survey !== undefined) {
                        dispatch(storeCategory(responseJson.survey));
                        dispatch(setCategory(responseJson.survey));
                    }

                    if(responseJson.activeGroup !== undefined) {
                        dispatch(setQuestionGroup({currentQuestionGroup: responseJson.activeGroup}))
                    }
                    
                    if(responseJson.activeQuestion !== undefined) {
                        dispatch(setQuestion({ currentQuestion: responseJson.activeQuestion }));
                    }
                    dispatch(ActionCreator.loadingStatus(false))
                }
            }).catch(error => {
                dispatch(ActionCreator.loadingStatus(false))
                NotificationManager.error("Tapahtumaa ei voitu toteuttaa", "Virhe", 2000);
            })
    }
}

export const getAllActiveSurveysById = (params) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'get/activeSurveysById', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed());
                } else {
                    dispatch(storeActiveSentSurveys(responseJson))
                }
            })
    }
    
}

export const updateSelectedSentSurveysToPresentOriginal = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'modify/updateSentSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed());
                } else {
                    if(responseJson.updated) {
                        NotificationManager.success("Valitut kartoitukset on nyt päivitetty", "", 3000);
                        dispatch(storeSentSurveyList(responseJson.sentSurveys));
                    } else {
                        NotificationManager.error("Päivitystä ei voitu suorittaa", "", 3000);
                    }
                }
            })
    }
}
export const saveQuestion = (params) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'question/modify', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed());
                } else {
                    if (Object.keys(responseJson).length > 0) {
                        NotificationManager.success("Kysymys tallennettu onnistuneesti", "", 2000);
                        dispatch(storeCategory(responseJson.savedCategory));
                        dispatch(setQuestion({ currentQuestion: responseJson.targetQuestion }));
                    } else {
                        NotificationManager.error("Kysymyksen tallennus epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000);
                    }
                }
            }).catch(error => {
                ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
            })
    }
}

export const saveCategory = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        dispatch(ActionCreator.loadingStatus(true))
        fetch(confs.url + 'add/category', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed());
                    dispatch(ActionCreator.loadingStatus(false))
                } else {
                    if (typeof responseJson.errors !== 'undefined') {
                        let errors = "";

                        responseJson.errors.map((error, x) => {
                            if (x < responseJson.errors.length - 1) {
                                errors += error + ", ";
                            } else {
                                errors += error;
                            }
                            return error;
                        })
                        NotificationManager.error(errors, "Virhe", 4000);
                        dispatch(ActionCreator.loadingStatus(false))
                    } else {
                        NotificationManager.success("Kartoitus on tallennettu onnistuneesti", "", 4000);
                        dispatch(storeCategory(responseJson.createdCategory));
                        dispatch(ActionCreator.loadingStatus(false))
                    }
                }
            }).catch(error => {
                ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
            })
    }
}

export const saveGroup = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'add/group', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed());
                } else {
                    if (typeof responseJson.errors !== 'undefined') {
                        let errors = "";

                        responseJson.errors.map((error, x) => {
                            if (x < responseJson.errors.length - 1) {
                                errors += error + ", ";
                            } else {
                                errors += error;
                            }
                            return error;
                        })
                        NotificationManager.error(errors, "Virhe", 4000);
                    } else {
                        //Server will return the whole stack again, so we can just store the whole category
                        NotificationManager.success("Kysymysryhmä tallennettu", "Kysymysryhmän tallennus")
                        dispatch(storeCategory(responseJson.categoryData));
                        dispatch(storeGroup(responseJson.targetGroup));
                    }
                }
            }).catch(error => {
                ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
            })
    }
}

export const deleteQuestionGroup = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        dispatch(ActionCreator.loadingStatus(true))
        fetch(confs.url + 'modify/deleteGroup', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                    dispatch(ActionCreator.loadingStatus(false));
                } else {
                    if (responseJson.length === 0) {
                        NotificationManager.error("Ryhmän poisto epäonnistui", "Virhe", 4000);
                        dispatch(ActionCreator.loadingStatus(false))
                    } else {
                        //Server will return the whole stack again, so we can just store the whole category
                        NotificationManager.success("Kysymysryhmä poistettu onnistuneesti", "", 4000);
                        dispatch(storeCategory(responseJson.category));
                        dispatch(storeGroup(responseJson.newTargetGroup))
                        dispatch(ActionCreator.loadingStatus(false))
                    }
                }
            }).catch(error => {
                ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
            })
    }
}

export const deleteQuestion = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'modify/deleteQuestion', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if (responseJson.length === 0) {
                        NotificationManager.error("Kysymyksen poisto epäonnistui", "Virhe", 4000);
                    } else {
                        //Server will return the whole stack again, so we can just store the whole category
                        NotificationManager.success("Kysymys poistettu onnistuneesti", "", 4000);
                        dispatch(storeCategory(responseJson.category));
                        dispatch(setQuestion({ currentQuestion: responseJson.nextTarget }));
                    }
                }
            }).catch(error => {
                ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
            })
    }
}

export const getSurveyDocument = payload => {
    let jwt = window.sessionStorage.getItem("token");

    let params = {
        reportId: payload._id,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'get/surveyDocument', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then(function (response) {
            return response.blob();
        }).then(function (res) {
            if (typeof res.isAuthenticated !== 'undefined' && !res.isAuthenticated) {
                dispatch(ActionCreator.authFailed());
            } else {
                var data = new Blob([res], { type: 'application/pdf' });
                var csvURL = window.URL.createObjectURL(data);
                let tempLink = document.createElement('a');
                document.body.appendChild(tempLink);
                tempLink.href = csvURL;
                let filename = 'EverBetter-' + Date.now() + '.pdf';
                tempLink.setAttribute('download', filename.replace(' ', '-').trim());
                tempLink.click();
                URL.revokeObjectURL(tempLink.href)
            }
        }).catch(error => {
            ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
        })
    }
}

export const tagSave = (params) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        tags: params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'tagSave', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed());
                } else {
                    if (Object.keys(responseJson).length > 0) {
                        NotificationManager.success("jooo asd", "", 2000);
                        dispatch(storeCategory(responseJson));
                    } else {
                        NotificationManager.error("sdadsasd", "Virhe", 3000);
                    }
                }
            }).catch(error => {
                ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
            })
    }
}

export const tagSearch = data => dispatch => {
    let jwt = window.sessionStorage.getItem("token");
    let params = {
        data: data,
        jwtToken: jwt
    }
    return fetch(confs.url + 'tagSearch', {
        method: 'POST',
        body: JSON.stringify(params)
    }).then((response) => response.json())
        .then((responseJson) => {
            dispatch(setTags(responseJson));
        });


}

export const reorderSurveyQuestionsToState = payload => {
    return {
        payload: payload,
        type: ActionTypes.REORDER_SURVEY_QUESTIONS
    }
}

export const reorderSurveyQuestions = payload => dispatch => {
    let jwt = window.sessionStorage.getItem("token");

    let params = {
        category: payload,
        jwtToken: jwt
    }

    return fetch(confs.url + 'reorder/surveyQuestions', {
        method: 'POST',
        body: JSON.stringify(params)
    }).then((response) => response.json())
        .then((responseJson) => {
            if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                dispatch(reorderSurveyQuestionsToState(responseJson))
                NotificationManager.success("Tallennettu", "", 2000);
            }
        })

}

export const copyQuestionGroup = payload => dispatch => {
    let jwt = window.sessionStorage.getItem("token");

    let params = {
        surveyId: payload.surveyId,
        questionGroup: payload.group,
        jwtToken: jwt
    }

    return fetch(confs.url + 'add/copiedGroup', {
        method: 'POST',
        body: JSON.stringify(params)
    }).then((response) => response.json())
        .then((responseJson) => {
            if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (typeof responseJson.success !== 'undefined' && !responseJson.success) {
                    NotificationManager.error('Ryhmän kopiointi epäonnistui', 'Virhe', 4000)
                } else {
                    dispatch(storeCategory(responseJson))
                    NotificationManager.success('Ryhmän kopiointi onnistui', 'Ilmoitus', 4000)
                }
            }
        })
}

export const copySurvey = payload => dispatch => {
    let jwt = window.sessionStorage.getItem("token");

    let params = {
        surveyId: payload.id,
        name: payload.name,
        jwtToken: jwt
    }
    let copyToEnterprise = false
    if(payload.copyToEnterprise){
        copyToEnterprise = payload.copyToEnterprise
        params = {
            ...params,
            copyToEnterprise: payload.copyToEnterprise,
            selectedEnterprise: payload.selectedEnterprise
        }
    }
    return fetch(confs.url + 'modify/copySurvey', {
        method: 'POST',
        body: JSON.stringify(params)
    }).then((response) => response.json())
        .then((responseJson) => {
            if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (typeof responseJson.success !== 'undefined' && !responseJson.success) {
                    NotificationManager.error('Kartoituksen kopiointi epäonnistui', 'Virhe', 4000)
                } else {
                    if(!copyToEnterprise){
                        dispatch(fetchCategories())
                    }
                    NotificationManager.success('Kartoituksen kopiointi onnistui', 'Ilmoitus', 4000)
                }
            }
        })
}

export const deleteSurvey = payload => dispatch => {
    let jwt = window.sessionStorage.getItem("token");

    let params = {
        surveyId: payload.id,
        jwtToken: jwt
    }

    return fetch(confs.url + 'modify/deleteSurvey', {
        method: 'POST',
        body: JSON.stringify(params)
    }).then((response) => response.json())
        .then((responseJson) => {
            if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (typeof responseJson.success !== 'undefined' && !responseJson.success) {
                    NotificationManager.error('Kartoituksen poisto epäonnistui', 'Virhe', 4000)
                } else {
                    dispatch(fetchCategories())
                    NotificationManager.success('Kartoituksen poisto onnistui', 'Ilmoitus', 4000)
                }
            }

        })
}

export const storeDashboardSummary = payload => {
    return {
        type: ActionTypes.STORE_SUMMARY,
        payload: payload
    }
}


export const getDashboardSummary = (params) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        setTimeout(() => {
            fetch(confs.url + 'get/dashboardSummary', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                        dispatch(ActionCreator.authFailed())
                    } else {
                        dispatch(storeDashboardSummary(responseJson));
                    }
                })
        }, 400);

    }
};

export const saveDraftjsImage = (data) => dispatch => {
    return new Promise(function(resolve) {
        fetch(confs.url + 'saveDraftjsImage', {
            method: 'POST',
            body: data
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    resolve(responseJson)
                }
            })
    })
}

export const getEnterpriseEmails = () => dispatch => {
    return new Promise( async resolve => {
        let jwt = window.sessionStorage.getItem("token")

        const params = {
            jwtToken: jwt
        }

        const responseJson = await fetch(confs.url + 'get/enterpriseEmails', {
            method: 'POST',
            body: JSON.stringify( params )
        })
        
        const response = await responseJson.json()

        if ( typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated ) {
            dispatch(ActionCreator.authFailed())
            resolve( [] )
        } else {
            resolve(response)
        }
    })
}
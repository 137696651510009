import React, { Component, Fragment } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

export default class FreetextAnswersModal extends Component {
  constructor() {
    super()

    this.state = {
      visibleIndex: null
    }
  }

  onFilterClick = ( answer ) => {
      this.props.filterReportByAnswerSession([answer.answerSessionId])
      this.props.toggleModal()
  }

  displayVariables = ( index ) => {
    if( index === this.state.visibleIndex ) {
      this.setState({ visibleIndex: null })
    } else {
      this.setState({ visibleIndex: index })
    }
    
  }

  render() {
    const { answers } = this.props
    const { visibleIndex } = this.state

    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggleModal()} centered={true} className="freetext-answers-modal" size='lg'>
        <ModalHeader toggle={this.props.toggle}>
          Loppupalautteen tilanneet
        </ModalHeader>
          <p style={{padding: '1rem'}}>Klikkaamalla Tarkastele-painiketta, voit suodattaa raportin näyttämään vain kyseisen vastaajan vastaukset.</p>
        <ModalBody style={{ padding: '1rem 0' }}>

          {/* <Row>
            <Col>
                <h5><b>{answerStack.question}</b></h5>
            </Col>
          </Row> */}

          <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            style={{ width: "100%", height: '500px' }}
            className="custom-scrollbars" >

            {answers && answers.map((answer, index) => {
              let className = index % 2 === 0 ? 'even-row' : 'odd-row'
              let buttonText = visibleIndex === index ? 'Piilota loppupalautteen arvot' : 'Näytä loppupalautteen arvot'

              return (
                <Fragment key={index}>
                  <Row className={className} style={{ padding: '1rem' }}>
                    <Col xs={{size: 4}}>
                      <p>{answer.text}</p>
                      <p className="lighter-text">{moment.utc(answer.time, "MMM D, YYYY, HH:mm:ss A", "en" ).local().format( 'D.M.YYYY  HH.mm' )}</p>
                    </Col>
                    <Col xs={{size: 4}} style={{textAlign: 'center'}}>
                      <button className="small-white-button" style={{padding: '0.25rem 1rem'}} onClick={() => this.displayVariables( index )} >{ buttonText }</button>
                    </Col>
                    {typeof answer.answerSessionId !== 'undefined' ? (
                      <Col xs={{size: 4}} style={{textAlign: 'center'}}>
                        <button className="small-white-button" style={{padding: '0.25rem 1rem'}} onClick={() => this.onFilterClick( answer )} >Tarkastele</button>
                      </Col>
                    ) : (
                      null
                    )}
                  </Row>

                  {(visibleIndex === index && answer.usedVars) && answer.usedVars.map((usedVar, varIndex) => {
                    let value = (usedVar &&usedVar.result)?usedVar.result : 0
                    if(!isNaN(value) && !isNaN(parseFloat(value))){
                      value = parseFloat(value).toFixed(2)
                    }
                    return (
                      <Fragment key={varIndex}>
                        <div className={className + ' used-var-row'}>
                          <Row>
                            <Col xs={{size: 6}}>
                              {usedVar.name}
                            </Col>
                            <Col xs={{size: 6}}>
                              <p>Tulos: {value}</p>
                            </Col>
                          </Row>
                          
                          <Row>
                            <Col xs={{size: 12}}>
                              <p className="lighter-text">{usedVar.description}</p>
                            </Col>
                          </Row>
                        </div>
                      </Fragment>
                    )
                  })}

                </Fragment>
              )
            })}

          </Scrollbars>

          
        </ModalBody>
        <ModalFooter>
            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => this.props.toggleModal()} >Sulje</a>
        </ModalFooter>
      </Modal>
    )
  }
}
import React, {useState, useEffect, useRef} from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import NpsSymbol from '../../Components/Clients/subComponents/NpsSymbol';
import { getTokenSourceMapRange } from 'typescript';
import { FavouriteStar } from '../Favourites/FavouriteStar';


export const ProjectListItem = ({project, goToProject, oddEvenClass = ""}) =>  {
    const {projectName, tags, targetBusiness, created, modified} = project
    const [targetBusinessName, setTargetBusinessName] = useState(targetBusiness&&targetBusiness.label ? targetBusiness.label : "")
    const [progress, setProgress] = useState(null)
    useState(()=>{
        if(project && project.progress){
            let number = Math.round(project.progress)
            setProgress(number)
        }
    },[project])
    return(
        <div className={'dashboard-list-row list-row company-list selectable-row'} onClick={()=> goToProject(project._id)} style={{display:"flex", flexDirection:"column"}}>
            <Row className='task-list-content'>
                <Col xs="5">
                    <p className="list-project-name name-overflow-handler-long" title={project.projectName}>{project.projectName}</p>
                    <p className="list-project-target" title={project.targetBusiness.label}>{project.targetBusiness.label}</p>
                </Col>               
                <Col xs="2">
                    <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start", flexDirection:'column'}}>
                        {project.tags && project.tags.slice(0, 3).map((tag, y) => {                        
                                return(<div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="tag-display tag-text project-lighter-color" title={tag.label}>{tag.label}</p></div>)
                            })  
                        }                                                   
                        {project.tags && project.tags.length > 3 &&  
                                <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                    <i className="material-icons" title={tagsToMore(project.tags.slice(3,project.tags.length))}>more_horiz</i>
                                </div>
                        }  
                    </div> 
                    
                </Col>
                <Col xs="1">
                    <div style={{ display: 'flex',  alignItems: 'center', justifyContent: 'flex-end'}}>
                        {project.plans && project.plans.length > 0 && <i className="fas fa-clipboard-list" title="Lomakkeet" style={{ opacity: '0.7', marginLeft: '0.25em' }}></i>}
                        {project.selectedSurveys && project.selectedSurveys.length > 0 && <i title="Kartoitukset" className="fas fa-file-alt" style={{ opacity: '0.7', marginLeft: '0.25em' }}></i>}
                        {project.files && project.files.length > 0 && <i className="fas fa-paperclip" title="Liitteet" style={{ opacity: '0.7', marginLeft: '0.25em' }}></i>}
                        {project.activities && project.activities.length > 0 && <i style={{ opacity: '0.7', marginLeft: '0.25em' }} title="Tapahtumat" className="far fa-calendar-alt"></i>}
                    </div>    
                    {progress && <p style={{ fontSize: '0.8rem', float: 'right', marginTop:'0.5em' }}>{progress}/100%</p>} 
                </Col>
                <Col xs="1">     
                    {project.status? 
                        <span className="dot" title={project.status.label} style={{backgroundColor: project.status.value, fontSize: '0.8rem', float:'right', marginLeft: '0.25em' }}></span>
                        :
                        <></>
                    }
                </Col>
                <Col xs="2" style={{}}>
                    <p style={{ fontSize: '0.7rem', marginTop: '0em', marginRight: '1em', float: 'right' }}>Muokattu: <span>{moment.utc(modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>           
                </Col>
                <Col xs="1">
                    <div style={{ marginRight: '1em', textAlign: 'right' }}>
                        <FavouriteStar
                            itemId={project._id}
                            itemType={"project"}
                            title={project.projectName}
                            targetBusiness={project.targetBusiness.label? project.targetBusiness.label : "Ei kohdeyritystä"}
                        />
                    </div>
                </Col>
            </Row>      
        </div>   
    )
}

export const ContactListItem = ({contact, oddEvenClass=""}) => {
    const { business } = contact
    let businessName = (business && business.businessName) ? business.businessName : ""
    return(
        <div className={'dashboard-list-row list-row company-list selectable-row'} onClick={()=> {return}} style={{display:"flex", flexDirection:"column"}}>
           {contact &&
                <Row className='task-list-content'>
                    <Col xs="6">
                        <p className="card-title contacts-title">{contact.name}</p>
                        <p className="contact-smaller-text" style={{ fontWeight: 'bold' }}>{contact.role ? contact.role : "-"}</p>
                        {businessName && <p className="contact-smaller-text" >{businessName}</p>}
                    </Col>
                    <Col xs="2" style={{}}>
                        <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                            {contact && contact.tags && contact.tags.slice(0, 7).map((tag, y) => {                        
                                    return(<div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                })  
                            }                                                   
                            {contact && contact.tags && contact.tags.length > 7 &&  
                                    <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                        <i className="material-icons" title={tagsToMore(contact.tags.slice(7, contact.tags.length))}>more_horiz</i>
                                    </div>
                            }  
                        </div>                                
                    </Col>
                    <Col xs="4">
                        <div>
                            {contact && contact.created && <p><span style={{alignSelf:"flex-start", font:"normal normal normal 0.75em/0.80em Raleway", paddingTop:"0.5em" }}>{"Luotu: " + moment.utc(contact.created, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>}
                            {contact.phone && <p><span style={{alignSelf:"flex-start", font:"normal normal normal 0.75em/0.80em Raleway", paddingTop:"0.5em", fontWeight: 'bold'}}>{"puh. " + contact.phone}</span> </p>}
                        </div>
                    </Col>
                </Row>      
            }
        </div>   
    )
}

export const PlanListItem = ({plan, goToPlan, oddEvenClass = ""}) =>  {
    const {planName, tags, selectedBusiness, created, lastEdited, modified} = plan
    const [selectedBusinessName, setTargetBusinessName] = useState(selectedBusiness&&selectedBusiness.label ? selectedBusiness.label : "")   
    const [isFollowed, setIsFollowed] = useState(false)
    const [dateCompare, setDateCompare] = useState(false)
    const [today, setToday] = useState(false)

    useEffect(()=>{
        if(plan.followOptions){
            plan.followOptions.map(option =>{
                if(option.planOpenFollowed && option.planOpenFollowed === true){
                    setIsFollowed(true)
                }
                if(option.commentingFollowed && option.commentingFollowed === true){
                    setIsFollowed(true)
                }
            }) 
        }      
    },[plan])

    useEffect(()=>{
        let today = moment.utc(new Date(),"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
        if(plan.lastDate){
            let lastActive = moment.utc(plan.lastDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(today === lastActive || today > lastActive){
                setDateCompare(true)
            }
            if(today === lastActive){
                setToday(true)
            }
        }
    },[plan])

    return(
        <div className={'dashboard-list-row list-row company-list selectable-row'} onClick={()=> goToPlan(plan._id)} style={{display:"flex", flexDirection:"column"}}>
            <Row className='task-list-content'>
                <Col xs="5">
                    <p className="list-project-name name-overflow-handler-long" title={plan.planName}>{plan.isPrivateDocument && <i className="far fa-eye-slash" style={{color: 'red'}} title='Yksityinen'></i>} {planName}</p>
                    <p className="list-project-target" title={selectedBusinessName}>{selectedBusinessName}</p>
                    {plan.automationSummary && <p style={{ fontWeight: 'bold', fontSize: '0.7rem' }}>Kooste</p>}
                    {!plan.selectedBusiness && <p className="list-project-target">{"Lomakepohja"}</p>}
                </Col>
                <Col xs="2" style={{}}>
                    <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                        {tags && tags.slice(0, 7).map((tag, y) => {                        
                                return(<div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                            })  
                        }                                                   
                        {tags && tags.length > 7 &&  
                                <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                    <i className="material-icons" title={tagsToMore(tags.slice(7, tags.length))}>more_horiz</i>
                                </div>
                        }  
                    </div>                                
                </Col>
                <Col xs="2" style={{ textAlign: 'center' }}>
                    <Row>
                        {plan.status === "published" && <span className="dot" title="Julkaistu" style={{backgroundColor:  'rgb(181, 228, 19)', fontSize:'0.8rem'}}></span>}                                       
                        {(plan.isSigned === true)? 
                            <div style={{ marginLeft: '0.5em' }}>
                                {(plan.allSigned && plan.allSigned === true)? 
                                    <i className="fas fa-signature" aria-hidden="true" title="Allekirjoitettu" style={{ color: '#b5e413', fontSize: '1.5rem' }}></i>
                                    : 
                                    <i className="fas fa-signature" aria-hidden="true" title="Osin allekirjoitettu"  style={{ color: '#ffcc00', fontSize: '1.5rem' }}></i>
                                }
                            </div>
                            :
                            <>
                            </>
                        }
                    </Row>
                    <Row>
                        {plan.annotations && plan.annotations.length > 0 &&
                            <div className="center-in-container" style={{ marginTop: '0.15em', marginRight:'0.5em' }}>
                                <i className="far fa-comment-dots" style={{ fontSize: '1.2rem', color: '#b5e413', float: 'right' }} title="Sisältää kommentteja"></i>
                            </div> 
                        }   
                        {isFollowed && <div title="Lomaketta seurataan" style={{ marginTop: '0.1em', opacity: '0.8' }}><i className="far fa-eye"></i></div>} 
                    </Row>
                </Col>
                <Col xs="2">
                    <div  style={{display: 'flex', flexDirection: 'column', marginRight:'1em' }}>
                        {today &&
                            <span><p className="card-text" style={{ color: 'red' }}>Viimeinen aktiivinen päivä!</p></span>
                        }
                        <p style={{ fontSize: '0.7rem'}}>Muokattu: <span>{moment.utc(lastEdited? lastEdited : modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>
                        {plan.lastDate && 
                            <p style={{ fontSize: '0.7rem'}}>Akt: <span style={dateCompare?{ color:'red' } : {}}>{moment.utc(plan.lastDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>
                        }
                      
                    </div>
                </Col>
                <Col xs="1">
                    <div style={{ marginRight: '1em', textAlign: 'right' }}>
                        <FavouriteStar
                            itemId={plan._id}
                            itemType={"plan"}
                            title={planName}
                            targetBusiness={selectedBusinessName  ? selectedBusinessName : "Ei kohdeyritystä"}
                        />
                    </div>
                </Col>
            </Row>      
        </div>   
    )
}

export const ActiveClientListItem = ({business, select, oddEvenClass=""}) => {
    const { name, businessId, npsScore, lastActive, tags, companyTotalScore} = business
    
    return (
        <div className={'dashboard-list-row list-row '} style={{display:"flex", flexDirection:"column"}} onClick={() => select.selectBusiness(business)} >
            <Row className='task-list-content'>
                <Col xs="4">
                        <p className="bigger-text name-overflow-handler-long" title={name}>{name}</p>
                        <p className="lighter-text">{businessId}</p>  
                </Col>
                <Col xs="2">
                    <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                        {tags&& tags.slice(0, 3).map((tag, i) => {                        
                            return(<div key={i} style={{paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text client-lighter-color" title={tag.label}>{tag.label}</p></div>)
                        })}                                                       
                        {tags && tags.length > 3 &&  
                            <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                <i className="material-icons" title={tagsToMore(tags,3)}>more_horiz</i>
                            </div>
                        }
                    </div>  
                </Col>
                <Col xs="3">
                    <div style={{ display: 'flex', alignSelf :"center", alignItems:"flex-start", flex: "1 1 0px", flexDirection: 'row', justifyContent:'space-between'}}>   
                        {business && companyTotalScore &&
                            <TotalScoreSymbol score={companyTotalScore.totalScore} count={companyTotalScore.followedCount}/>
                        }                
                        {(business && npsScore) ?                                      
                            <NpsSymbol npsData={npsScore}/>                                        
                            :
                            <></>                                      
                        } 
                    </div>
                </Col>
                <Col xs="3" style={{display: 'flex', justifyContent: 'flex-end' }}> 
                    <div style={{ marginRight: '1em'}}>  
                        <p style={{ font:"normal normal normal 0.7em/0.80em Raleway"}}>Viimeisin tapahtuma</p>
                        <p style={{ font:"normal normal normal 0.7em/0.80em Raleway", paddingTop:"0.5em"}}>{moment.utc(lastActive, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</p>                   
                    </div> 
                </Col>
            </Row>
        </div>
    )
}

export const ActivatedSurveyItem = ({survey, goToReport, oddEvenClass=""}) => {
    const { name, businessName, code, dates, surveyAvgColor, surveyAvgScore} = survey

    let startDate = null
    let endDate = null
    if ( dates ) {
        var today = new Date()
        for(var date of dates){
            if(date.startDate && date.endDate && today >= new Date(date.startDate) && today <= new Date(date.endDate)){
                startDate = date.startDate
                endDate = date.endDate
            }
        }
    }
    let answers = 0
    if(survey.jobCalculatedStats && survey.jobCalculatedStats.answers ){
       answers = survey.jobCalculatedStats.answers
    }
    let color = surveyAvgColor || '#c8c8c8'
    let avg = surveyAvgScore || 0

    return(
         <div className={'dashboard-list-row list-row company-list selectable-row'} style={{display:"flex", flexDirection:"column"}} onClick={() => goToReport(survey)} >
            <Row className='task-list-content'>
                <Col xs="5">
                        <p className="bigger-text name-overflow-handler-long" title={name}>{name}</p>
                        <p className="lighter-text">{businessName}</p>  
                </Col>
               
                <Col xs="2" style={{ display: 'flex', alignItems:'center' }}>
                    <div>
                       <p className="bigger-text">{code}</p>
                       <p style={{ font:"normal normal normal 0.8em/0.85em Raleway" }}>{answers} vastausta</p>
                    </div>  
                </Col>
                <Col xs="2">
                    <div style={{ display: 'flex', alignSelf :"center", alignItems:"center", flex: "1 1 0px", flexDirection: 'column'}}>                          
                        <div className="smaller-score-ball" style={ { borderColor: color }}>{ avg }</div>
                    </div>
                </Col>
                <Col xs="2" style={{display: 'flex', flexDirection:'column'}}>  
                    <div style={{ marginRight: '0.5em' ,display: 'flex', flexDirection: 'column', marginRight: '1em'}}>  
                        <p style={{ font:"normal normal normal 0.8em/0.9em Raleway", paddingBottom: '0.25em' }}>Aktiivinen</p>
                        <p style={{ font:"normal normal normal 0.7em/0.85em Raleway" }}>{moment.utc(startDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}-</p>
                        <p style={{ font:"normal normal normal 0.7em/0.85em Raleway" }}>{moment.utc(endDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</p>                     
                    </div> 
                </Col>
                <Col xs="1">
                    <div style={{ marginRight: '1em', textAlign: 'right' }}>
                        <FavouriteStar 
                            itemId={survey._id}
                            itemType={"sentSurvey"}
                            title={name}
                            targetBusiness={businessName? businessName : "Ei kohdeyritystä"}
                            sentSurvey={survey}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
 
export const AnswerListItem = ({result, goToReport, oddEvenClass=""}) => {
    const { surveyName, color, avgPoints, created, code, answered } = result
    return (
        <div className={'dashboard-list-row list-row company-list selectable-row'} style={{display:"flex", flexDirection:"column" }} onClick={() => goToReport(result)} >
            <Row className='task-list-content'>
                <Col xs="5">
                    <p className="bigger-text name-overflow-handler-long" title={surveyName}>{surveyName}</p>
                    <p className="card-text">{result.businessName }</p>
                </Col>
                <Col xs="2" style={{display: 'flex', justifyContent: 'center' }}>
                    <p>{code}</p>
                </Col>
                <Col xs="3">
                    <div className="graphic-circle-container">
                        <div className="smaller-score-ball" style={{ borderColor: color }}>{avgPoints}</div>
                    </div>
                </Col>
                <Col xs="2" style={{display: 'flex', justifyContent: 'flex-end' }}> 
                    <div style={{ marginRight: '1em'}}>  
                        <p style={{ font:"normal normal normal 0.75em/0.80em Raleway"}}>Vastattu</p>
                        <p style={{ font:"normal normal normal 0.6em/0.75em Raleway", paddingTop:"0.5em"}}>{moment.utc(answered, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</p>                   
                    </div> 
                </Col>
            </Row>
        </div>

        
    )
}

export const TaskListItem = ({task, openTask, oddEvenClass=""}) => {
    const { targetCompanyName, status, taskTitle, plainTextTask, deadline, modified } = task
    
    return (
        <div className={'dashboard-list-row list-row company-list selectable-row'} style={{display:"flex", flexDirection:"column" }} onClick={() => openTask(task)}>
            <Row className='task-list-content'>
                <Col xs="5">
                    <span className="tasksText"><p className="bigger-text name-overflow-handler-long" title={taskTitle}>{taskTitle}</p></span>
                    <div>
                        {task && (task.taskTree && Object.keys(task.taskTree).length > 0) ? task.taskTree.surveyTitles.slice(0, 2).map((t, key) => {
                            return(<p className="overflower-short"  key={key} style={{ flexWrap: 'nowrap', fontSize: '0.8rem'}}>{t.title}</p>)                        
                        }):
                            task.planName && 
                            <p className="overflower-short" style={{ flexWrap: 'nowrap', fontSize: '0.8rem'}}>{task.planName}</p>
                        }
                        {task && (task.taskTree && Object.keys(task.taskTree).length > 0 && task.taskTree.surveyTitles.length > 2) ? 
                            <i className="material-icons" style={{ fontSize: '0.8rem' }} title={getSurveyTitles(task, 2)}>more_horiz</i>
                            :
                            <i></i>
                        }
                    </div>
                   
                </Col>
                <Col xs="3">
                    <span className="dot" style={{ backgroundColor: getTaskColor(status), marginBottom: '0.5em' }}></span>
                </Col>
                <Col xs="2">
                    <p className="lighter-text card-text" style={{ float: "right", marginLeft: "auto" }}>Muokattu {moment.utc(modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</p>
                </Col>
                <Col xs="2" style={{display: 'flex', justifyContent: 'flex-end' }}> 
                    <span style={{marginRight: '1em' }} className="lighter-text">{checkStatus(status) ? deadlineChecker(deadline) : getTaskStatus(status)}</span>
                </Col>
            </Row>
        </div>
    )
}

export const HubContactListItem = ({ contact, oddEvenClass="" }) => {
    const { firstName, lastName, company, phone, lifecyclestage, vid, type, value, timestamp, created } = contact
    return (
        <div className={'dashboard-list-row list-row company-list selectable-row'} style={{display:"flex", flexDirection:"column" }}>
            <Row className='task-list-content'>
                <Col xs="5">
                    <span className="overflower-short">
                        {company.name}
                    </span>
                </Col>
                <Col xs="5">
                    <span className="tasksText"><h5 className="card-title overflower-short" title={firstName + " " + lastName}>{firstName + " " + lastName}</h5></span>
                    <p className="card-text tasksText"><span className="overflower-short lighter-text" title={phone}>{phone}</span></p>
                    <p className="card-text tasksText"><span className="overflower-short lighter-text" title={value}>{value}</span></p>
                </Col>
                <Col xs="2" className=" text-muted" style={{display: 'flex', justifyContent: 'flex-end' }}>
                    <span style={{ marginRight: '1em' }} className="lighter-text bold">{lifecyclestage}</span>
                </Col>
            </Row>
        </div>
    )
}

export const HubDealListItem = ({ deal, getStage, oddEvenClass="" }) => {
    const { dealname, amount, dealstage, owner, closedate, isClosed, lastmodified } = deal
   
    return (
        <div className={'dashboard-list-row list-row company-list selectable-row'} style={{display:"flex", flexDirection:"column" }}>
            <Row className='task-list-content'>
                <Col xs="5">
                   
                    <span className="tasksText"><h5 className="card-title overflower-short" title={dealname.value}>{dealname.value}</h5></span>
                     <div style={{ display: 'flex', flexDirection: 'row' }}>
                         <span className="overflower-short bigger-text media-smaller-text">
                            {getStage(deal)}
                        </span>
                        <span className="lighter-text">{lastmodified ? moment.unix(lastmodified.value / 1000).format("DD.MM.YYYY HH:mm") : ""}</span>
                     </div>
                </Col>
                <Col xs="5">
                    <p className="card-text tasksText"><span className="overflower-short lighter-text" title={owner}>{owner}</span></p>
                    <p className="card-text tasksText"><span className="overflower-short lighter-text" title={amount.value} style={isClosed ? { color: 'green' } : {}}>{amount.value} €</span></p>
                </Col>
                <Col xs="2" className=" text-muted" style={{display: 'flex', justifyContent: 'flex-end' }}>
                {!isClosed && <span className="lighter-text bold">Sulkeutumisajankohta {moment.unix(closedate.value / 1000).format("DD.MM.YYYY HH:mm")}</span>}
                </Col>
            </Row>
        </div>
    )
}

export const RiskListItem = ({oddEvenClass, risk, selectRisk}) => {
    const {riskClassification, businessName, color, planName, surveyName, code, status, riskName} = risk  
    return (
        <div className={'dashboard-list-row list-row company-list selectable-row'} style={{display:"flex", flexDirection:"column", paddingRight: '1.25em' }} onClick={()=> selectRisk(risk)}>
            <Row className='task-list-content'>
                <Col xs="5">
                    {riskName &&
                        <p className="name-overflow-handler bigger-text" title={riskName}>{riskName}</p> 
                    }
                    {businessName ? 
                        <p className={"name-overflow-handler " + (riskName? "card-text" : "bigger-text")} title={businessName}>{businessName}</p> 
                        :
                        <p className={"name-overflow-handler " + (riskName? "card-text" : "bigger-text")}>Ei kohdeyritystä</p>
                    }
                    <p className="card-text">{riskClassification.label}</p>    
                </Col>
                <Col xs="4">
                    {planName && <p className="name-overflow-handler" style={{fontSize: '0.8rem', maxWidth: '12em'}} title={planName}>{planName}</p>}
                    {surveyName &&  <p className="name-overflow-handler" style={{fontSize: '0.8rem', maxWidth: '12em'}} title={surveyName}>{surveyName}</p>}
                    {code && <p className="name-overflow-handler" style={{fontSize: '0.8rem', maxWidth: '12em'}} title={code}>{code}</p>}
                </Col>
                <Col xs="1">
                    <div className="smaller-score-ball" style={{ borderColor: color, marginBottom: '0.5em' }}><p style={{ fontWeight: 'bold' }}>{risk.riskScore}</p></div>
                </Col>
                <Col xs="2" className=" text-muted" style={{display: 'flex', justifyContent: 'flex-end' }}>
                    <p style={{ font:"normal normal normal 0.7em/0.85em Raleway" }}>Luotu: {moment.utc(new Date(risk.created), "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</p> 
                </Col>
            </Row>
        </div>
    )
}

const TotalScoreSymbol = ({score, count}) => {

    let color = ""
    let fontColor = "#404040"
    if(count > 0){
        if(score !== null){   
            if (score >= 75){color = "#b5e413"}
            else if(score >= 45 && score < 75){color ='#ffcc00'}
            else if (score < 45){color ='#ED5652'; fontColor = "#fff"}
        }
        else color ='#ED5652'
    } else {
        color ='#c8c8c8'
    }
    return(
        <div style={{display:"flex",alignItems:"center", flexDirection:"row", paddingLeft: "0.5em", }} title={"Pisteet: " + score}>
            <label htmlFor="npsScore" className="lighter-text">Pisteet: </label>               
            <div className="smaller-score-ball" style={{ borderColor: color }}>{count > 0 ? score : "-"}</div> 
        </div>
    );
}

const checkStatus = status => {
    switch (status) {
        case 'Avoin':
            return true;
        case 'Ei huomioitu':
            return true;
        case 'inactive':
            return true;
        default:
            return false
    }
}

const getTaskStatus = status => {
    if (status === "Avoin" || status === "Ei huomioitu" || status === 'inactive') {
        return "Avoin";
    } else if (status === "declined") {
        return "Peruutettu";
    } else {
        return "Suoritettu";
    }
}

const getTaskColor = status => {
    if (status === "Avoin" || status === "Ei huomioitu" || status === 'inactive') {
        return "rgb(255, 204, 0)";
    } else if (status === "declined") {
        return "red";
    } else {
        return "#BDE533";
    }
}

const getSurveyTitles = (task, first) => {
    let taskTitles = "";
    if (task.taskTree !== undefined && Object.keys(task.taskTree).length > 0) {
        task.taskTree.surveyTitles.slice(first, task.taskTree.surveyTitles.length).filter(t => taskTitles = taskTitles + " " + t.title.toString())  
    } else if (task.planName) {
            taskTitles += task.planName;
        }
    return taskTitles;
}

const dateChecker = date => {
    let difference = moment().diff(moment(date).startOf('day'), "days");
    switch (difference) {
        case 0:
            return "tänään";
        case 1:
            return "eilen";
        default:
            return difference + " päivää sitten";

    }
}

const deadlineChecker = deadline => {
    if (deadline !== undefined) {
        let difference = moment(deadline).diff(moment().startOf('day'), "days");
        if (difference === 0) {
            return "Tänään"
        } else if (difference === 1) {
            return "Huomenna"
        } else if (difference > 1) {
            return difference + " päivää jäljellä"
        } else {
            return (
                <span style={{ color: 'red' }}>{moment.utc(deadline, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span>
            );
        }
    }
}
{/*
<div className="card-header title-header task-card-title">
   
</div>
<div className="card-body" style={{ display: 'flex', flexDirection: 'row', paddingTop: 0, paddingBottom: 0 }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        
       
    </div> 
    
</div>
*/}
const tagsToMore = (props) => {
    let tagss = ""
    
    if(props !== undefined){
        props.filter(tags => tagss = tagss + "  " + tags.label.toString())

        return(tagss)
    }
}

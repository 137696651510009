import React, {useState, useEffect} from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {executeRequest} from '../../../Utilities/requestHandler/dataHandler'
import {useDispatch} from 'react-redux'
import {ModifiedContainer} from './DataContainers';
const CustomBlockText = ({id, blockName, editable, index, toggleItemVisibility, onDelete, itemData, layoutPreview = false}) => {
    const [item, setItem] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [openMenu, toggleMenu] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const response = await executeRequest('plan/get/customblock', {customblockId: id}, dispatch)

            if(!!response) {
                if(response.customItem) setItem(response.customItem);
                setIsLoading(false)
            }
        }

        fetchData();
    }, [])

    return (
        isLoading ? 
            <div>
                <LoadingSpinner />
            </div>
        :
        (!isLoading && Object.keys(item).length > 0) ?
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + ((itemData && !itemData.visible) ? "plan-item-private" : "")} id={item.id}>
                <Col xl="12">
                    {editable && (itemData.visible !== undefined && !itemData.visible) && <span style={{fontWeight: 700, color: 'red'}}>Yksityinen</span>}
                    {(!editable && !layoutPreview && item.modified !== undefined) && 
                        <ModifiedContainer modified={item.modified} visible={itemData.visible} modifiedBy={item.modifiedBy}/>
                    }
                </Col>
                <Col xl="12" style={{marginBottom: 8}} className="title-col">
                    <div style={{ display: 'flex' }}>
                        {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}</div>}            
                        <TextEditor 
                            editorId={`${item.id}-subtitle-${editable}`} 
                            content={item.topicContent}
                            onTextChange={() => {return}} 
                            additionalClass="plan-input subTitle plan-subtitle"
                            placeholder="-"
                            readOnly={true}
                            plaintextEditor={true}
                        /> 
                    </div>
                </Col>
                <Col xl="11" style={{paddingTop: '0.8em'}} className="plan-text-col">
                    <TextEditor 
                        editorId={`${item.id}-${editable}`} 
                        content={item.textContent}
                        onTextChange={() => {return}} 
                        additionalClass="plan-input"
                        readOnly={true}
                    />
                </Col>
                <Col xl="1" style={{paddingLeft: 15}} className="plan-dropdown-col">
                    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                    {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                        <DropdownToggle tag="div" >
                            <i className="fas fa-bars plusIcon" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(itemData.visible !== undefined && !itemData.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                            <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                    </div>
                </Col>
            </Row>
            : null
    )
}

const LoadingSpinner = () => {
    return (
        <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}


export default CustomBlockText;

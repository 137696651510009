import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';

const LinkTreeItem = ({linkItem, className, style, setcurrentViewData, data, numberOfLinkedItems}) => {
    const [item, setItem] = useState(linkItem);
    const [numberOfLinks, setNumberOfLinks] = useState(numberOfLinkedItems);
    const [itemClassName, setClassName] = useState(className);

    useEffect(() => {
        setItem(linkItem);
        setClassName(className);
    }, [item, className]);

    return (
        <div className={itemClassName} style={style} onClick={() => setcurrentViewData(data)}>
            <Row>
                <Col xs="10" xl="11">
                    <p style={{paddingLeft: 10}}>{item}</p>
                </Col>
                <Col xs="2" xl="1">
                    {(numberOfLinks !== undefined && numberOfLinks > 0) && 
                    <span style={{float: 'right'}}>
                        <span className="dot infoBlock">{numberOfLinks}</span>
                    </span>}
                </Col> 
            </Row>
        </div>
    )
}

export default LinkTreeItem;
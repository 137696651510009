import React, { Component } from 'react'
import {Row, Col, Container} from 'reactstrap';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected"; 
import '../reportTypes/home.css';
import {confs} from '../../../../modules/config';
/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end
const testData = [{
    name: "Core",
    children: [
      {
        name: "First",
        children: [
          { name: "A1", value: 100 },
          { name: "A2", value: 60 }
        ]
      },
      {
        name: "Second",
        children: [
          { name: "B1", value: 135 },
          { name: "B2", value: 98 }
        ]
      },
      {
        name: "Third",
        children: [
          {
            name: "C1",
            children: [
              { name: "EE1", value: 130 },
              { name: "EE2", value: 87 },
              { name: "EE3", value: 55 }
            ]
          },
          { name: "C2", value: 148 },
          {
            name: "C3", children: [
              { name: "CC1", value: 53 },
              { name: "CC2", value: 30 }
            ]
          },
          { name: "C4", value: 26 }
        ]
      },
      {
        name: "Fourth",
        children: [
          { name: "D1", value: 415 },
          { name: "D2", value: 148 },
          { name: "D3", value: 89 }
        ]
      },
      {
        name: "Fifth",
        children: [
          {
            name: "E1",
            children: [
              { name: "PENAN ULTIMAATTINEN MEGA PALIIKKI", value: 33 },
              { name: "PENAN ULTIMAATTINEN MEGA PALIIKKI PENAN ULTIMAATTINEN MEGA PALIIKKI PENAN ULTIMAATTINEN MEGA PALIIKKI PENAN ULTIMAATTINEN MEGA PALIIKKI", value: 40 },
              { name: "EE3", value: 89 }
            ]
          },
          {
            name: "E2",
            value: 148
          }
        ]
      }

    ]
}];

const mainObject = {
    name: "Kartoitukset",
    value: 500,
    fixed: true,
    x: am4core.percent(50),
    y: am4core.percent(50),
    linkWith: []
}

export default class ReportLinkTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataReady: false,
            fullDataSet: [],
            dataFilterSet: [],
            breadcrumbs: []
        }
    }

    componentDidMount() {
        const {sentSurveyIds} = this.props;

        let jwt = window.sessionStorage.getItem("token")
        let payload = {
            reports: [],
            jwtToken: jwt
        }

        sentSurveyIds.map(report => payload.reports.push({reportId: report}));

        if (payload.reports.length > 0) {
            fetch(confs.url + 'fetch/graphicalData', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
            .then((responseJson) => {
                this.setState({dataReady: true, fullDataSet: responseJson, dataFilterSet: [responseJson], breadcrumbs: [{text: responseJson[0].breadcrumb, data: [...responseJson]}]}, () => {
                    this.createLinkTree(responseJson);
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {sentSurveyIds} = this.props;

        if(sentSurveyIds !== prevProps.sentSurveyIds) {
            let jwt = window.sessionStorage.getItem("token")
            let payload = {
                reports: [],
                jwtToken: jwt
            }

            sentSurveyIds.map(report => payload.reports.push({reportId: report}));

            if (payload.reports.length > 0) {
                fetch(confs.url + 'fetch/graphicalData', {
                    method: 'POST',
                    body: JSON.stringify(payload)
                }).then((response) => response.json())
                .then((responseJson) => {
                    this.setState({dataReady: true, fullDataSet: responseJson, dataFilterSet: [responseJson], breadcrumbs: [{text: responseJson[0].breadcrumb, data: [...responseJson]}]}, () => {
                        if(this.chart) {
                            this.networkSerie.data = [...responseJson];
                        }
                    })
                })
            }
        }
    }

    createLinkTree = (data) => {
        let container = am4core.create("container", am4core.Container);
        container.width = am4core.percent(100);
        container.height = am4core.percent(100);
        let chart = container.createChild(am4plugins_forceDirected.ForceDirectedTree);
        chart.responsive.enabled = true;
        let networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries())
        networkSeries.nodes.template.outerCircle.filters.push(new am4core.DropShadowFilter());

        chart.data = [...data];

        networkSeries.dataFields.id = "id";
        networkSeries.dataFields.linkWith = "linkWith";
        networkSeries.dataFields.value = "value";
        networkSeries.dataFields.name = "name";
        networkSeries.dataFields.itemType = "itemType";
        networkSeries.dataFields.children = "children";
        networkSeries.dataFields.breadcrumb = "breadcrumb";
        
        networkSeries.dataFields.sentSurveyList = "sentSurveyList";
        networkSeries.dataFields.color = "color";
        networkSeries.dataFields.fixed = "fixed";
        networkSeries.dataFields.collapsed = "collapsed";
        networkSeries.nodes.template.togglable = false;
        networkSeries.nodes.template.expandAll = false;
        networkSeries.nodes.template.tooltipText = "{hoverText}";
        networkSeries.nodes.template.fillOpacity = 1;
        networkSeries.manyBodyStrength = -15;
        networkSeries.links.template.strength = 0.2;
        networkSeries.links.template.strokeWidth = 2.5;
        networkSeries.linkWithStrength = 0.2;
        networkSeries.maxLevels = 2;
        networkSeries.minRadius = am4core.percent(2.5);
        networkSeries.maxRadius = am4core.percent(5.5);
        networkSeries.nodes.template.label.text = "{name}";
        networkSeries.nodes.template.label.hideOversized = true;
        networkSeries.nodes.template.label.truncate = true;
        networkSeries.nodes.template.propertyFields.x = "x";
        networkSeries.nodes.template.propertyFields.y = "y";
        this.chart = container;
        this.networkSerie = chart;

        var linkHoverState = networkSeries.links.template.states.create("hover");
        linkHoverState.properties.strokeOpacity = 1;
        linkHoverState.properties.strokeWidth = 5;

        networkSeries.nodes.template.events.on("over", function (event) {
            var dataItem = event.target.dataItem;

            dataItem.childLinks.each(function (link) {
                link.isHover = true;
            })

            if(dataItem.parentLink !== undefined) {
                dataItem.parentLink.isHover = true;
            }
        })
        
        networkSeries.nodes.template.events.on("out", function (event) {
            var dataItem = event.target.dataItem;
            dataItem.childLinks.each(function (link) {
                link.isHover = false;
            })

            if(dataItem.parentLink !== undefined) {
                dataItem.parentLink.isHover = false;
            }
            
        })

        networkSeries.nodes.template.events.on('ready', function(event) {
            // Minimum font size 14:
            // Math.max(14, number here);
            // Maximum font size 45:
            // Math.min(45, number here);
            // combined below...
            var fontSize = Math.max(14, Math.min(18, Math.ceil(event.target.measuredWidth * .5)));
            event.target.fontSize = fontSize;
        });

        networkSeries.nodes.template.events.on('hit', function(event) {

            if(event.target.dataItem.name !== "Kartoitukset" && event.target.dataItem.itemType !== "questionOption") {
                if(event.target.dataItem.parent !== undefined) {
                    
                    let checkIfFound = this.state.breadcrumbs.filter(x => x.parent === event.target.dataItem.parent._dataContext.breadcrumb);

                    if(checkIfFound.length === 0) {
                        let lastCheck = this.state.breadcrumbs.filter(x => x.text === event.target.dataItem.parent._dataContext.breadcrumb);

                        if(lastCheck.length === 0) {
                            let breadcrumbs = [
                                ...this.state.breadcrumbs,
                                {
                                    parent: event.target.dataItem.parent._dataContext.breadcrumb,
                                    parentData: event.target.dataItem.parent._dataContext,
                                    text: event.target.dataItem._dataContext.breadcrumb,
                                    data: event.target.dataItem._dataContext
                                }
                            ];
                            this.setState({breadcrumbs: breadcrumbs});
                        } else {
                            let breadcrumbs = [
                                ...this.state.breadcrumbs,
                                {
                                    text: event.target.dataItem._dataContext.breadcrumb,
                                    data: event.target.dataItem._dataContext
                                }
                            ];
                            this.setState({breadcrumbs: breadcrumbs});
                        }
                            
                    
                    } else {
                        let breadcrumbs = [
                            ...this.state.breadcrumbs,
                            {
                                text: event.target.dataItem._dataContext.breadcrumb,
                                data: event.target.dataItem._dataContext
                            }
                        ];
                            
                        this.setState({breadcrumbs: breadcrumbs});
                    } 
                } else {
                    let breadcrumbs = [
                        ...this.state.breadcrumbs
                    ];
                    let checkPrevious = breadcrumbs.filter(x => x.text === event.target.dataItem._dataContext.breadcrumb);

                    if(checkPrevious.length === 0) {
                        let breadcrumbs = [
                            ...this.state.breadcrumbs,
                            {
                                text: event.target.dataItem._dataContext.breadcrumb,
                                data: event.target.dataItem._dataContext
                            }
                        ];
                        this.setState({breadcrumbs: breadcrumbs});
                    } else {
                        if(breadcrumbs.length > 1) {
                            this.setState({breadcrumbs: breadcrumbs.filter(x => x.text !== event.target.dataItem._dataContext.breadcrumb)});
                        }
                    }
                }
            }
            this.handleGroupToggle(event.target.dataItem._dataContext);
        }, this);
    }

    componentWillUnmount() {
        if(this.chart)
            this.chart.dispose();
    }

    handleGroupToggle = (target) => {

        if(target.itemType !== 'mainSurveyStack' && target.itemType !== 'questionOption') {
            if(this.state.dataFilterSet.length === 1) {
                if(target.sentSurveyList !== undefined && target.sentSurveyList.length > 1) {
                    this.fetchTargetStack(target);
                } else {
                    let dataFilters = [
                        ...this.state.dataFilterSet,
                        [target]
                    ];
                    this.setState({dataFilterSet: dataFilters});
                    this.networkSerie.data = [target];
                }
            } else {
                let existing = [...this.state.dataFilterSet];
                
                if(target === existing[existing.length - 1][0]) {
                    existing.pop();
                } else {
                    existing.push([target]);
                }

                this.setState({dataFilterSet: existing});
                this.networkSerie.data = [...existing[existing.length - 1]];     
            }
        }
    }

    fetchTargetStack = (target) => {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            type: target.itemType,
            target: target.id,
            reports: target.sentSurveyList
        }

        fetch(confs.url + 'fetch/targetStack', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => response.json())
        .then(resp => {
            if(resp.length > 0) {
                let dataFilters = [
                    ...this.state.dataFilterSet,
                    [...resp]
                ];

                this.setState({dataFilterSet: dataFilters, dataReady: true});
                this.networkSerie.data = [...resp];
                this.networkSerie.calculateRelativeSize();
            } else {
                if(Array.isArray(target)) {
                    let dataFilters = [
                        ...this.state.dataFilterSet,
                        [...target]
                    ];
    
                    this.setState({dataFilterSet: dataFilters, dataReady: true});
                    this.networkSerie.data = [...target];
                } else {
                    let dataFilters = [
                        ...this.state.dataFilterSet,
                        [target]
                    ];
    
                    this.setState({dataFilterSet: dataFilters, dataReady: true});
                    this.networkSerie.data = [target];
                }
                
            }
        })
    }

    handleBreadcrumbNavigation = (target, index, isParent) => {
        
        this.fetchTargetStack(target.data);

        let newBreadcrumbs = [...this.state.breadcrumbs.slice(0, index + 1)];
        let dataFilterSet = [...this.state.dataFilterSet.slice(0, index)];
        if(isParent) {
            
            let changebread = {
                ...newBreadcrumbs[index],
                text: newBreadcrumbs[index].parent,
                data: newBreadcrumbs[index].parentData
            };

            delete changebread.parent;
            delete changebread.parentData;

            newBreadcrumbs[index] = {
                ...changebread
            };
        }
        this.setState({breadcrumbs: newBreadcrumbs, dataFilterSet: dataFilterSet});
        this.networkSerie.calculateRelativeSize();
    }

    render() {
        const {sentSurveys} = this.props;
        return (
            <Row style={{height: "100%"}} className="report">
                <Col style={{overflow: 'auto', padding: '0 2rem'}}>
                    <div>
                    <Row className="survey-buttonfilters-row">
                        <div>
                        {this.state.breadcrumbs.map((item, index) =>
                            item.parent !== undefined ? 
                            <React.Fragment key={index}>
                                <span onClick={() => this.handleBreadcrumbNavigation({text: item.parent, data: item.parentData}, index, true)} style={{cursor: 'pointer'}}>{item.parent} / </span>
                                {item.text !== undefined && <span onClick={() => this.handleBreadcrumbNavigation({text: item.text, data: item.data}, index, false)} style={{cursor: 'pointer'}}>{index === this.state.breadcrumbs.length - 1 ? <b>{item.text} / </b> : item.text + " / "}</span>}
                            </React.Fragment>
                            : 
                            <span key={index} onClick={() => this.handleBreadcrumbNavigation({text: item.text, data: item.data}, index, false)} style={{cursor: 'pointer'}}>{index === this.state.breadcrumbs.length - 1 ? <b>{item.text} / </b> : item.text + " / " }</span>
                        )}
                        </div>
                    </Row>
                    <Row className="survey-main-row">
                        <Col xl="12">
                            {this.state.dataReady ?
                                <div style={{height: '100vh', overflow: 'auto'}} id="containerWrapper">
                                    <div id="container" style={{height: '100%'}}></div>
                                </div>
                                : <MegaLoaderSpinner />
                                
                            }
                        </Col>
                    </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

const MegaLoaderSpinner = () => {
    return (
            <div className="loadingSpinnerRowChart">
                <div style={{ display: 'flex', justifyContent: 'center', height: '100%'}}>
                    <div style={{ width: '5rem', height: '5rem', marginTop: '8rem' }} className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
    )
}

const Breadcrumbs = (props) => {
    return (
        <div style={{ marginLeft: props.margin }}>
            <Container fluid>
                <Row>
                    <Col xs="12" style={{ display: 'flex', paddingTop: '1em', alignItems: 'center' }}>
                        <div style={{color: '#404040', fontSize: '1.2em'}}><i className="far fa-list-alt"></i>
                            {props.crumbs && props.crumbs.length > 0 && props.crumbs.map((crumb, index) => {
                                return <div key={index} className="lighter-text" style={{ color: '#000' }}>
                                    <span onClick={() => props.addMoreBreadcrumbs(crumb)}><span style={{ padding: '0em 0.5em' }}>/</span><div style={{ color: '#404040' }}>{crumb.label}</div></span>
                                </div>
                            })
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
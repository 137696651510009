const RESET_BUILDER = "RESET_BUILDER";
const RESET_BUILDER_REDUX = "RESET_BUILDER_REDUX";

export const resetBuilder = () => {
    return {
        type: RESET_BUILDER
    }
}

export const resetBuilderRedux = () => {
    return {
        type: RESET_BUILDER_REDUX
    }
};
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Col, Row, Input } from 'reactstrap'

export default class Treehead extends React.Component {

    render() {
        const { index, id, isDragDisabled, surveyTree, isEditing, newTitle, handleNewTitle, createNew, edit, treeIndex } = this.props

        return (
            <div style={{backgroundColor: isEditing ? '#f5f5f5' : '#fff', padding: '1em'}} >
                <Draggable index={index} draggableId={id} isDragDisabled={isDragDisabled} type={ `header-${index}` }>
                    {provided => (
                        <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            <Row data-id={surveyTree.id} className={ this.props.class } style={{ paddingLeft: 0 }} >
                                <Col xs="6">
                                    {isEditing ?
                                        <Input className="input-bar" placeholder="Anna ryhmälle nimi" value={newTitle} onChange={handleNewTitle}></Input>
                                        :
                                        <><i style={ { marginRight: '1em' } } className="fas fa-grip-vertical"></i> <span className="surveyTree-title"> { surveyTree.title }</span></>
                                    }

                                </Col>
                                <Col xs="4">
                                    {surveyTree.surveys.length} raporttia
                                </Col>
                                <Col xs="2">
                                    {surveyTree.title !== 'Kohdistamattomat' && !createNew &&
                                        <button className="small-white-button wide-button lighter-text" style={{ float: 'right', marginRight: '0.5em' }} onClick={() => edit( treeIndex, index )}>{isEditing ? 'Peruuta' : 'Muokkaa'}</button>
                                    }

                                </Col>
                            </Row>
                            {isEditing &&
                                <Row style={{marginTop: '0.5em'}}>
                                    <Col xs="12">
                                        <button className="small-white-button wide-button lighter-text" onClick={this.props.save}>Tallenna</button>
                                        {!createNew &&
                                            <button className="small-white-button wide-button lighter-text" style={{ marginLeft: '0.5em', backgroundColor: '#ed5652', color: '#fff' }} onClick={() => this.props.delete( treeIndex, index )}>Poista</button>
                                        }
                                    </Col>
                                </Row>
                            }
                        </div>
                    )}
                </Draggable>

            </div>
        )
    }
}

import React, {useState} from "react";
import { useInView } from "react-intersection-observer";
import { Input, Row, Col } from 'reactstrap';
export const DashboardSearch = ({search, setSearch}) => {
    
    return(
        
        <div style={{zIndex: 101, position: 'fixed', width:'100%', top:'-0.5em' }}>
            <Row>
                <Col xs="2">
                </Col>
                <Col xs="7" style={{paddingRight:'1.5em', paddingLeft:'3.5em', paddingTop:'1em',paddingBottom:'0.5em', marginLeft:'-4.5em', marginRight:'1.5em', backgroundColor:'#c8c8c8', borderRadius:'10px'}}>
                    <Row>
                    <Input type="search" id="search-tourId" className="search-bar-open" placeholder={"Hae lomakkeita, projekteja yms.."} value={search} onChange={(event) => setSearch(event.target.value)} />
                    </Row>
                </Col>
                <Col xs="2">
                </Col>
            </Row>
        </div>
           
    )
}
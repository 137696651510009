import React, { useState, useEffect } from 'react';
import { confs } from '../../../modules/config';
import AsyncCreatableSelect from 'react-select/async-creatable';

export const TagHandler = props => {
    const modify = props.modify? props.modify : false
    async function promiseOptions(inputValue) {
        let params = {
            data: inputValue,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const data = fetch(confs.url + 'tagSearch', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => response.json())

        let tags = await data;

        return tags
    };

    function NoOptionsMessage(props) {
        if (props.options.length) return null;
        return <div {...props.innerProps} style={{padding: 5}}>Voit hakea tai lisätä tageja kirjoittamalla</div>;
      }

    const components = {
        NoOptionsMessage
    }

    function formatCreateLabelCreator(value) {
        return 'Lisää tagi: "' + value + '"'
    }

    return (  
        <AsyncCreatableSelect 
            loadOptions={promiseOptions} 
            components={components} 
            isMulti 
            value={props.tags} 
            onChange={selectedTags => props.handleChange({target:{ id: "tags", value: selectedTags}})}
            placeholder=""
            className='react-select-container'
            classNamePrefix="react-select"
            formatCreateLabel={formatCreateLabelCreator} 
            isDisabled={modify}
        />
    )
}
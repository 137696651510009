/* 
!!! NOTE !!!

Adding icons here means they need to be imported to index.js file in Embed app as well!
*/
export const iconList = {"icons": ["fas fa-thumbs-up", "far fa-thumbs-up", "fas fa-thumbs-down", "far fa-thumbs-down", "fas fa-angry","far fa-angry","fas fa-dizzy","far fa-dizzy","fas fa-flushed","far fa-flushed","fas fa-frown","far fa-frown","fas fa-frown-open","far fa-frown-open","fas fa-grimace","far fa-grimace","fas fa-grin","far fa-grin","fas fa-grin-alt","far fa-grin-alt","fas fa-grin-beam","far fa-grin-beam","fas fa-grin-beam-sweat","far fa-grin-beam-sweat","fas fa-grin-hearts","far fa-grin-hearts","fas fa-grin-squint","far fa-grin-squint","fas fa-grin-squint-tears","far fa-grin-squint-tears","fas fa-grin-stars","far fa-grin-stars","fas fa-grin-tears","far fa-grin-tears","fas fa-grin-tongue","far fa-grin-tongue","fas fa-grin-tongue-squint","far fa-grin-tongue-squint","fas fa-grin-tongue-wink","far fa-grin-tongue-wink","fas fa-grin-wink","far fa-grin-wink","fas fa-kiss","far fa-kiss","fas fa-kiss-beam","far fa-kiss-beam","fas fa-kiss-wink-heart","far fa-kiss-wink-heart","fas fa-laugh","far fa-laugh","fas fa-laugh-beam","far fa-laugh-beam","fas fa-laugh-squint","far fa-laugh-squint","fas fa-laugh-wink","far fa-laugh-wink","fas fa-meh","far fa-meh","fas fa-meh-blank","far fa-meh-blank","fas fa-meh-rolling-eyes","far fa-meh-rolling-eyes","fas fa-sad-cry","far fa-sad-cry","fas fa-sad-tear","far fa-sad-tear","fas fa-smile","far fa-smile","fas fa-smile-beam","far fa-smile-beam","fas fa-smile-wink","far fa-smile-wink","fas fa-surprise","far fa-surprise","fas fa-tired","far fa-tired"]}


/*
not in use atm

"Accessibility": ["fab fa-accessible-icon", "fas fa-american-sign-language-interpreting", "fas fa-assistive-listening-systems", "fas fa-audio-description", "fas fa-blind", "fas fa-braille", "fas fa-closed-captioning", "far fa-closed-captioning", "fas fa-deaf", "fas fa-low-vision", "fas fa-phone-volume", "fas fa-question-circle", "far fa-question-circle", "fas fa-sign-language", "fas fa-tty", "fas fa-universal-access", "fas fa-wheelchair"],
    "Alert": ["fas fa-bell","far fa-bell","fas fa-bell-slash","far fa-bell-slash","fas fa-exclamation","fas fa-exclamation-circle","fas fa-exclamation-triangle","fas fa-radiation","fas fa-radiation-alt","fas fa-skull-crossbones"],
    "Animals": ["fas fa-cat","fas fa-crow","fas fa-dog","fas fa-dove","fas fa-dragon","fas fa-feather","fas fa-feather-alt","fas fa-fish","fas fa-frog","fas fa-hippo","fas fa-horse","fas fa-horse-head","fas fa-kiwi-bird","fas fa-otter","fas fa-paw","fas fa-spider"],
*/
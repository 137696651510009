import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  ListGroup, 
  ListGroupItem,
  Form,
  FormGroup,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
  Label
} from 'reactstrap'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { executeRequest } from '../../../../Utilities/requestHandler/dataHandler'
import {checkYTunnus} from '../../../../Utilities/HelperFunctions/helper'
import { motion, AnimatePresence } from 'framer-motion';
import { NotificationManager } from 'react-notifications';
import './ytj.css';


const YTJContainer = ({businessId, selectedBusiness, setYTJInfo}) => {
  const [information, setInformation] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const getInformation = async businessId => {
        const result = await executeRequest('get/YTJBusiness', {businessId: businessId});

        if(!!result) {

            if(result.status !== 404 && result.status !== 502) {
                if(result.error ){
                    NotificationManager.error(result.error,"Virhe",5000)
                } else{
                    setInformation(result);
                    setYTJInfo(result)
                }
            } else {
                setNotFound(true);
                if(result.status === 502){
                    NotificationManager.error("YTJ-palvelu ei vastaa","Virhe",5000)
                }
            }
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    getInformation(businessId);

  }, [])

  useEffect(() => {
    if(notFound) {
        if(checkYTunnus(businessId)) {
            setIsLoading(true)
            const getInformation = async businessId => {
                const result = await executeRequest('get/YTJBusiness', {businessId: businessId});
        
                if(!!result) {
                    if(result.status !== 404 && result.status !== 502) {
                        if(result.error){
                            NotificationManager.error(result.error,"Virhe",5000)
                        } else{
                            setInformation(result);
                            setNotFound(false);
                        }
                    } else {
                        setNotFound(true);
                        if(result.status === 502){
                            NotificationManager.error("YTJ-palvelu ei vastaa","Virhe",5000)
                        }
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }
        
            getInformation(businessId);
        }
    }
  }, [businessId])

  return (
      <>
        <YTunnusModal isOpen={openModal} toggleModal={() => setOpenModal(!openModal)} ID={businessId} selectedBusiness={selectedBusiness}/>
        <Col md={{ size: 12 }} xl={{ size: 6 }}>
        <div className="dashboard-medium-box">
            <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
            <p>YTJ</p>
            </div>
            <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            style={{ width: "100%", height: '500px', zIndex: 90 }}
            className="custom-scrollbars"
            >
            <AnimatePresence>
                {isLoading ? <MegaTinyLoaderSpinner /> : (
                    (Object.keys(information).length > 0 && !notFound) ? <PopulatedBusinessDataList information={information} /> : <NoResultDisplayer businessId={businessId} selectedBusiness={selectedBusiness} setOpenModal={setOpenModal}/>
                )}
            </AnimatePresence>
            </Scrollbars>
        </div>
        </Col>
        </>
  )
}

const MegaTinyLoaderSpinner = () => {
  return (

    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
  )
}

const NoResultDisplayer = ({setOpenModal}) => {
    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column'}}>
                <h5>Tietoja ei voitu hakea asetetulla Y-tunnuksella.</h5>
                <button className="small-white-button lighter-text wide-button riskView" style={{ marginLeft: '3rem' }} onClick={() => setOpenModal(true)}>
                    Vaihda Y-tunnus
                </button>
            </div>
        </>
    )
}

const YTunnusModal = ({ID, isOpen, toggleModal, selectedBusiness}) => {
    const [businessID, setBusinessID] = useState(ID)
    const [invalid, setInvalid] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!checkYTunnus(ID)) {
            setInvalid(true);
        }
    }, [])

    const modifyValue = (value) => {
        setBusinessID(value);

        if(!checkYTunnus(value)) {
            setInvalid(true);
        } else {
            setInvalid(false);
        }
    }

    const onSave = async (e) => {
        if(!invalid) {
            //modify/business
            const { _id } = selectedBusiness

            let payload = {
                _id: _id,
                businessID: businessID
            }

            const response = await executeRequest('modify/business', {targetBusiness: payload}, dispatch)

            if(!!response) {
                //refresh item in redux
                dispatch(updateBusinessValue(response));
                NotificationManager.success("Tiedot tallennettu", "", 3000);
                toggleModal();
            }   
        }
    }

    const updateBusinessValue = response => {
        return {
            type: 'HANDLE_YTJ',
            payload: response
        }
    }
    
    return (
        <Modal isOpen={ isOpen } toggle={ toggleModal } centered>
            <ModalHeader toggle={ toggleModal }>Yrityksen tiedot</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="businessID">Y-tunnus</Label>
                        <Input type="text" name="businessID" id="businessID" placeholder="Y-tunnus" value={businessID} onChange={(e) => modifyValue(e.target.value)} invalid={invalid} />
                        <FormFeedback>Y-tunnus on väärin. Korjaa arvo ja yritä uudestaan.</FormFeedback>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={ toggleModal }>Peruuta</a>
                <Button onClick={ onSave }>Tallenna</Button>
            </ModalFooter>
        </Modal>
    )
}

const MainListItem = ({name, children}) => {
    const [ itemOpen, setItemOpen ] = useState(false);

    return (
        <ListGroupItem action onClick={() => setItemOpen(!itemOpen)} style={{padding: '0.75rem 1rem', cursor: 'pointer'}}>
            <div className="mainListItem">
                <h6 style={{fontWeight: 700, marginBottom: 0}}>{name}</h6>
                <i className={itemOpen ? "fa fa-angle-double-up" : "fa fa-angle-double-down"} aria-hidden="true"></i>
            </div>
            {itemOpen &&
                <div style={{paddingTop: '0.5rem'}}> 
                    {children}
                </div>
            }
        </ListGroupItem>
    )
}

const PopulatedBusinessData = ({information}) => {
    const {businessId, name, names, auxNames, mainAddress, postAddress, registeredOffice, contactDetails, mainLine, languages} = information
    return (
        <div style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
            <Row>
                <MainListItem name="Toiminimi ja Y-tunnus" />
                <Col xl="4">
                    <label htmlFor="businessName">Toiminimi ja Y-tunnus</label>
                    <h5 id="businessName">{`${name} ( ${businessId} )`}</h5>
                </Col>
                <Col xl="4">
                    <label htmlFor="names">Rinnakkaistoiminimet: </label>
                    {names && 
                        names.length > 0 ? 
                            names.map((name, index) => 
                                <p key={name.name + index}>{name.name}</p>
                            )
                            : <p>-</p>
                    }
                </Col>
                <Col xl="4">
                    <label htmlFor="auxNames">Aputoiminimet: </label>
                    {auxNames &&
                        auxNames.length > 0 ?
                            auxNames.map((auxName, index) =>
                                <p key={auxName.name + index}>{auxName.name}</p> 
                            )
                            : <p>-</p> 
                    }
                </Col>
            </Row>
            <Row>
                <Col xl="4">
                    <label htmlFor="mainAddress">Käyntiosoite: </label>
                    {mainAddress && 
                        Object.keys(mainAddress).length > 0 ? 
                            <p>{`${mainAddress.street} , ${mainAddress.postCode}, ${mainAddress.city}`}</p>
                            : <p>-</p>
                    }
                </Col>
                <Col xl="4">
                    <label htmlFor="postAddress">Postiosoite: </label>
                        {postAddress && 
                            Object.keys(postAddress).length > 0 ? 
                                <p>{`${postAddress.street} , ${postAddress.postCode}, ${postAddress.city}`}</p>
                                : <p>-</p>
                        }
                </Col>
                <Col xl="4">
                    <label htmlFor="office">Paikkakunta: </label>
                    {registeredOffice &&
                        Object.keys(registeredOffice).length > 0 ? 
                        <p>{registeredOffice.name}</p>
                        : <p>-</p>
                    }
                </Col>
            </Row>
            <Row>
                <Col xl="4">
                    <label htmlFor="mainAddress">Yhteystiedot: </label>
                    {contactDetails && 
                        contactDetails.length > 0 ? (
                            contactDetails.map((contactInfo, index) => {
                                if(contactInfo.value !== "") 
                                    return (
                                        <div>
                                            <label htmlFor={contactInfo.type}>{contactInfo.type}</label>
                                            <p>{contactInfo.value}</p>
                                        </div>
                                    )
                            }
                            ))
                            : <p>-</p>
                    }
                </Col>
                <Col xl="4">
                    <label htmlFor="postAddress">Päätoimiala: </label>
                        {postAddress && 
                            Object.keys(postAddress).length > 0 ? 
                                <p>{`${postAddress.street} , ${postAddress.postCode}, ${postAddress.city}`}</p>
                                : <p>-</p>
                        }
                </Col>
                <Col xl="4">
                    <label htmlFor="office">Kieli: </label>
                    {registeredOffice &&
                        Object.keys(registeredOffice).length > 0 ? 
                        <p>{registeredOffice.name}</p>
                        : <p>-</p>
                    }
                </Col>
            </Row>
        </div>
    )
}

const PopulatedBusinessDataList = ({information}) => {
    const {businessId, name, names, auxNames, mainAddress, postAddress, registeredOffice, contactDetails, mainLine, languages} = information
    return (
        <div>
            <ListGroup>
                <MainListItem name="Toiminimi ja Y-tunnus">
                    <Row>
                        <Col xl="4">
                            <p>{name}</p>
                        </Col>
                        <Col xl="4"></Col>
                        <Col xl="4">
                            <p>{businessId}</p>
                        </Col>
                    </Row>
                </MainListItem>
                <MainListItem name="Rinnakkais- ja aputoiminimet">
                    <Row>
                        <Col xl="4">
                            <label htmlFor="names"><b>Rinnakkaistoiminimet: </b></label>
                                <div id="names">
                                    {names && 
                                        names.length > 0 ? 
                                            names.map((name, index) => 
                                                <p key={name.name + index}>{name.name}</p>
                                            )
                                            : <p>-</p>
                                    }
                                </div>
                        </Col>
                        <Col xl="4"></Col>
                        <Col xl="4">
                            <label htmlFor="auxNames"><b>Aputoiminimet: </b></label>
                            <div id="auxNames">
                                {auxNames &&
                                    auxNames.length > 0 ?
                                        auxNames.map((auxName, index) =>
                                            <p key={auxName.name + index}>{auxName.name}</p> 
                                        )
                                        : <p>-</p> 
                                }
                            </div>
                        </Col>
                    </Row>
                </MainListItem>
                <MainListItem name="Osoitetiedot">
                    <Row>
                        <Col xl="4"> 
                            <label htmlFor="mainAddress"><b>Käyntiosoite: </b></label>
                            {mainAddress &&
                                Object.keys(mainAddress).length > 0 ?
                                    <> 
                                        <p id="mainAddress">{mainAddress.street}</p>
                                        <p>{mainAddress.postCode}</p>
                                        <p>{mainAddress.city}</p>
                                    </>
                                    : <p id="mainAddress">-</p>
                            }
                        </Col>
                        <Col xl="4">
                            <label htmlFor="postAddress"><b>Postiosoite: </b></label>
                                {postAddress &&
                                    Object.keys(postAddress).length > 0 ?
                                    <> 
                                        <p id="mainAddress">{postAddress.street}</p>
                                        <p>{postAddress.postCode}</p>
                                        <p>{postAddress.city}</p>
                                    </> 
                                        : <p id="postAddress">-</p>
                                }
                        </Col>
                        <Col xl="4">
                            <label htmlFor="office"><b>Paikkakunta: </b></label>
                            {registeredOffice &&
                                Object.keys(registeredOffice).length > 0 ? 
                                <p id="office">{registeredOffice.name}</p>
                                : <p id="office">-</p>
                            }
                        </Col>
                    </Row>
                </MainListItem>
                <MainListItem name="Yhteystiedot">
                    <Row>
                        {contactDetails && 
                            contactDetails.length > 0 ? (
                                contactDetails.map((contactInfo, index) => 
                                        <Col xl="4" key={index}>
                                            <label htmlFor={contactInfo.type}><b>{contactInfo.type}</b></label>
                                            {contactInfo.type === "Kotisivun www-osoite" ? <div><a href={getHTTPSLink(contactInfo.value)} target="_blank">{contactInfo.value}</a></div> : 
                                            <p>{contactInfo.value !== "" ? contactInfo.value : "-"}</p>
                                            }
                                        </Col>
                                    )
                                
                                )
                                : <Col xl="4"><p>-</p></Col>
                        }
                    </Row>
                </MainListItem>
                <MainListItem name="Päätoimiala ja kieli">
                    <Row>
                        {mainLine && 
                            <Col xl="4">
                                <label htmlFor={"Päätoimiala"}><b>Päätoimiala</b></label>
                                <p>{mainLine.name}</p>
                            </Col>
                            
                        }
                        <Col xl="4"></Col>
                        <Col xl="4">
                            <label htmlFor={"languages"}><b>Kieli</b></label> 
                                {languages &&
                                    languages.length > 0 ? 
                                    languages.map((language, index) => 
                                        <p key={index}>{language.name}</p>
                                    )
                                    : <p>-</p>
                            }
                        </Col>
                    </Row>
                </MainListItem>
            </ListGroup>
        </div>
    )
}

const getHTTPSLink = (link) => {
    if(link != undefined && link !== "") {
        let start = link.slice(0, 8);

        if(start === 'https://') {
            return link;
        } else {
            return 'https://' + link
        }
    }
}

export default YTJContainer
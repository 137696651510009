import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars'
import {Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropDownItem, Modal, ModalHeader, ModalBody, ModalFooter, Table, Label, Input} from 'reactstrap'
import {executeRequest} from '../../../Utilities/requestHandler/dataHandler'
import {CopyBlockElement} from './listElements/CopiedBlockElements'
import {useDispatch} from 'react-redux'
import {ListLoadingSpinner} from './ListLoadingSpinner'
import {getPlainTexts} from './listElements/CopiedBlockElements'
import {NotificationManager} from 'react-notifications'
import {DeleteModal} from '../Modals/ActionModals'
import './tabList.css'
import { GanttPhaseModal } from '../Modals/GanttPhaseModal';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import { v4 as uuidv4 } from 'uuid';
import { GanttTemplateEdit } from './listElements/GanttTemplateEdit';

const initialDeleteModalData = {
    modal: false, 
    target: {}
}

const CopiedBlockList = ({filter}) => {
    const [copyBlocks, setCopyBlocks] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [deleteModalData, setDeleteModalData] = useState(initialDeleteModalData);
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [selectedBlock, setSelectedBlock] = useState(false)
    const [removeRowModal, setRemoveRowModal] = useState(false)
    const [lastCol, setLastCol] = useState()
    const dispatch = useDispatch();

    useEffect(() => {
        //plan/templates
        //Get saved blocks
        fetchData();
    }, [])

    const fetchData = async () => {
        const response = await executeRequest('plan/templates', {}, dispatch)

        if(!!response) {
            if(response) setCopyBlocks(response);
            setIsLoading(false)
        }
    }


    const removeCopiedElement = async (payload) => {
        const {target} = deleteModalData
        const {_id} = target

        const response = await executeRequest('plan/removeTemplate', {templateId: _id}, dispatch)

            if(!!response) {
                if(response.status === "deleted") {
                    setCopyBlocks(copyBlocks.filter(copy => copy._id !== response.templateId));
                    NotificationManager.success("Kopiomalli poistettu", "", 3000);
                    setDeleteModalData(initialDeleteModalData)
                }
                setIsLoading(false)
            }
    }

    const toggleEditModal = () => {
        setEditModalOpen(false)
        setSelectedBlock(false)
    }

    const handleDataChanges = (data) => {
        const {id, value} = data
        let block = {
            ...selectedBlock,
            [id] : value
        }
        setSelectedBlock(block)
    }

    const handleSave = async() => {
        let payload = {
            templateId: selectedBlock._id,
            templateItem: {
                ...selectedBlock
            }
        }
        const response = await executeRequest("plan/updateTemplate",payload)
        if(response && response.success){
            NotificationManager.success("Tallennettu onnistuneesti","",3000)
        }
        setEditModalOpen(!editModalOpen)
        fetchData()
    }

    useEffect(()=>{
        if(selectedBlock && selectedBlock.customWidth){
            let lastColWidth = 100
            selectedBlock.customWidth.map((width,i)=>{
                lastColWidth-=width
            })
            setLastCol(lastColWidth)
        }
    },[selectedBlock])

    const checkItemType = () => {
        const {type} = selectedBlock
        switch(type) {
           
            case 'tables':
                return(
                    <>
                        <Modal isOpen={removeRowModal} toggle={() => setRemoveRowModal(!removeRowModal)}>
                            <ModalHeader>Poista taulukon rivi</ModalHeader>
                            <ModalBody>
                                <p>Oletko varma, että haluat poistaa viimeisen rivin? <b>Poisto on pysyvä, eikä sitä voida peruuttaa!</b></p>
                            </ModalBody>
                            <ModalFooter>
                                <a onClick={() => setRemoveRowModal(!removeRowModal)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                                <button onClick={() => removeTableRow()} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                            </ModalFooter>
                        </Modal>
                        <TableItem table={selectedBlock} tableRows={selectedBlock.rows} setTableRows={handleDataChanges} editable={true} blockId={selectedBlock._id}/>
                        <div>
                            <i className="fas fa-plus plusIcon add-table-row-button" title="Lisää rivi" onClick={()=> addTableRow()}></i>
                            <i className="fas fa-minus plusIcon add-table-row-button" title="Poista viimeinen rivi" onClick={()=> setRemoveRowModal(true)}></i>
                        </div>
                        <Label style={{ marginTop:'0em'}}>Anna sarakkeiden koko prosentteina (esim 25)</Label>
                        <div style={{ display:'flex', flexDirection:'row', marginTop: '0.25em'}}>
                            { selectedBlock && selectedBlock.rows &&  selectedBlock.rows.map((row, index) => {
                                if(index === 0){
                                    return(
                                        row.cols.map((col, i) => {
                                            if(i !== row.cols.length-1){
                                                return(
                                                    <div key={i} style={{width:((100/row.cols.length) + "%")}}>
                                                        <Input type="number" value={selectedBlock.customWidth[i]?selectedBlock.customWidth[i]:""} onChange={(e)=> handleWidthChange({index:i,value:e.target.value})} />
                                                    </div>
                                                )
                                            } else {
                                                return(
                                                    <div key={i} style={{width:((100/row.cols.length) + "%")}}>
                                                        <Input readOnly value={lastCol? (lastCol) :""}/>
                                                    </div>
                                                )
                                            }
                                        })
                                    )
                                }
                            })}
                        </div>
                    </>
                )
            case 'gantt':
                return(
                    <div style={{ marginTop: '1em' }}>
                        <GanttTemplateEdit ganttPhases={selectedBlock.phases} setGanttPhases={handleGanttPhases} isTemplate={true}/>
                    </div>
                )    
            default:
                return(
                    <div style={{ padding:'0.5em', borderTop:'1px solid #c1c1c1', borderRadius:'0 0 10px 10px'}}>
                        <TextEditor
                            editorId={`${selectedBlock._id}-content`} 
                            content={selectedBlock.textContent} 
                            onTextChange={editorState => handleDataChanges({id:"textContent", value: editorState})}
                            additionalClass="plan-input"
                            connectionType={"customBlocks"}
                            documentId={selectedBlock._id}
                        /> 
                    </div>
                )
        }
        
    }

    const handleGanttPhases = (phases) => {
        handleDataChanges({id:'phases', value: phases})
    }

    const handleGanttSave = async(phases) => {
        let payload = {
            templateId: selectedBlock._id,
            templateItem: {
                ...selectedBlock,
                phases: [...phases]
            }
        }
        const response = await executeRequest("plan/updateTemplate",payload)
        if(response.success){
            NotificationManager.success("Muutokset tallennettu","",5000)
        } else {
            NotificationManager.error("Virhe tallentaessa dataa","Virhe",5000)
        }
        setEditModalOpen(!editModalOpen)
        fetchData()
    } 

    const cancelEdit = () => {
        setSelectedBlock(null)
        setLastCol(null)
        setEditModalOpen(false)
    }

    const addTableRow = () => {
        if(selectedBlock && selectedBlock.rows){
            let colValue = selectedBlock.selectedNumberOfCols
            let row = {
                id: uuidv4(),
                cols: 
                    [...Array(colValue).keys()].map(col => {
                        return {
                            text: '-',
                            id: uuidv4()
                        }
                    })
            }
            let rows = selectedBlock.rows
            handleDataChanges({id:"rows", value: [...rows, row]})
        }
    }

    const removeTableRow = () => {
        if(selectedBlock && selectedBlock.rows){
            let rows = [...selectedBlock.rows]
            rows.splice((rows.length-1), 1)
           handleDataChanges({id:"rows", value:[...rows]})
        }
        setRemoveRowModal(!removeRowModal)
    }

    const handleWidthChange = (props) => {
        const {index, value} = props
        if(selectedBlock && selectedBlock.customWidth){
            let widths = [...selectedBlock.customWidth]
            let altered = widths.map((width, i)=>{
                if(i===index){
                    return value
                }
                else{
                    return width
                }
            })
            handleDataChanges({id:'customWidth', value:[...altered]}) 
        } 
    }


    return (
        <>
            {deleteModalData.modal && 
                <DeleteModal 
                    isOpen={deleteModalData.modal} 
                    toggleModal={() => setDeleteModalData(initialDeleteModalData)} 
                    handleDelete={removeCopiedElement} title={deleteModalData.target.topicContent ? deleteModalData.target.topicContent : ""}
                    targetText="kopiomallin" 
                />
            }
            {selectedBlock && 
                <Modal size="lg" isOpen={editModalOpen} toggleModal={() => toggleEditModal()}>
                    <ModalHeader>Muokkaa kappalepohjaa</ModalHeader>
                    <ModalBody>
                        <div style={{ margin:'0 2em 2em 2em', border: selectedBlock.type !== "tables"? '2px dashed #c1c1c1': "", borderRadius:'10px'}}>
                            <TextEditor 
                                editorId={`${selectedBlock._id}-subtitle`} 
                                content={selectedBlock.topicContent} 
                                onTextChange={editorState => handleDataChanges({id:"topicContent", value: editorState})}
                                additionalClass="plan-input subTitle"
                                plaintextEditor={true}
                            /> 
                            {checkItemType()}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => cancelEdit()}>Peruuta</a>   
                        <button className="small-white-button wide-button" onClick={() => handleSave()}>Tallenna</button>  
                    </ModalFooter>
                </Modal>
            } 
            <Scrollbars
                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                style={{ width: "100%", height: '100%' }}
                className="custom-scrollbars"
            >
                {isLoading ? 
                <ListLoadingSpinner /> : 
                copyBlocks.map((data, y) => {
                    const {topicContent, textContent, _id, modified, risks, tasks} = data
                    const plainContent = getPlainTexts(topicContent, textContent)
                    if (plainContent.topicContent.toUpperCase().includes(filter.toUpperCase())) {
                        let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row'
                        return <div key={y}>
                                    <CopyBlockElement
                                        item={data}
                                        contentId={_id}
                                        text={textContent}
                                        topic={topicContent}
                                        index={y}
                                        riskList={risks}
                                        taskList={tasks}
                                        editable={false}
                                        onDelete={setDeleteModalData}
                                        oddEvenClass={oddEvenClass}
                                        setSelectedBlock={setSelectedBlock}
                                        setEditModalOpen={setEditModalOpen}
                                    />
                                </div>
                    }
                    return null
                })}                     
            </Scrollbars>
        </>
    )
}
  


const TableItem = ({table, tableRows, setTableRows, promptSave, editable, title, blockId}) => {
    const [tableFields, setTableFields] = useState(table);
    const rows = tableRows;

    const handleTableData = (rowData) => {
        //const {rows} = table
        rows.splice(rowData.rowIndex, 1, {cols: rowData.cols})
        setTableRows({id: "rows", value:[...rows]})
    }

    return (
        <Table bordered hover style={{tableLayout: 'fixed',}}>
            <thead>
            </thead>
            <tbody>
                {rows && rows.map((row, rowIndex) => 
                    <TableRowContainer row={row} rowIndex={rowIndex} handleTableData={ handleTableData } item={table} editable={editable} maximumCount={table.selectedNumberOfCols} key={rowIndex} blockId={blockId}/>
                )}
            </tbody>
        </Table>
    )
}

const TableRowContainer = ({row, rowIndex, handleTableData, editable, maximumCount, item, blockId}) => {
    const { cols } = row
    const {customWidth} = item
    const handleRowChange = colData => {
        const { cols } = row
        let newCols = cols.map(col => {
            if(col.id === colData.id) {
                return colData
            }

            return col
        })

        handleTableData({
            cols: newCols,
            rowIndex: rowIndex
        })
    }

    return (
        <tr key={rowIndex}>         
            {cols.map((col, index) =>
                <ColumnEditor 
                    col={col} 
                    key={col.id} 
                    editorId={col.id} 
                    rowIndex={rowIndex} 
                    handleRowChange={handleRowChange} 
                    editable={editable} 
                    count={cols.length}
                    maximumCount={maximumCount}
                    colIndex={index}
                    customWidth={(customWidth && customWidth[index])? (customWidth[index] + "%"):"" }
                    blockId={blockId}
                />
            )}
        </tr>
    )
}

const ColumnEditor = ({col, editorId, handleRowChange, editable, maximumCount, count, colIndex, customWidth, blockId}) => {
    const getColSpan = () => {
        if(count === 1)
            return maximumCount;
        else
            return (maximumCount % count === 1 && colIndex + 1 === count) ? maximumCount / count + 1 : maximumCount / count
    }

    return (
        <td style={{verticalAlign: 'top', backgroundColor: col.color? col.color : "#ffff" }} width={customWidth} colSpan={getColSpan()}>
            <TextEditor
                editorId={editorId}
                content={col.text}
                onTextChange={editorState => handleRowChange({...col, text: editorState})}
                additionalClass="plan-input"
                readOnly={!editable}
                backgroundColor={col.color? col.color: "#ffff"}
                changeBackgroundColor={color => handleRowChange({...col, color: color})}
                connectionType={"customBlocks"}
                documentId={blockId} 
            />
        </td>
    )
}



export default CopiedBlockList
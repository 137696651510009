import React, { Component } from 'react';
import { Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Input, FormGroup } from 'reactstrap';
import ColorPicker from '../../questiongroup/subComponents/ColorPicker';
import DebounceTextEditor from '../../../../../../../../Utilities/TextEditor/DebounceTextEditor';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { confs } from '../../../../../../../../modules/config';
import QuestionOptionDeletionModal from '../modals/QuestionOptionDeletionModal';
import '../../../kartoitus.css';
import { debounce } from 'debounce';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Select from 'react-select';

const emptyFeedback = () => {
    let contentState = ''
    contentState = ContentState.createFromText("")

    let editorState = EditorState.createWithContent(contentState);
    return convertToRaw(editorState.getCurrentContent())
}
const emptyOptions = {
    text: "",
    feedback: emptyFeedback(),
    points: 15,
    tags: [],
    color: '#FFCC00',
    dialogFeedback: emptyFeedback(),
    dialogPlaintextFeedback: "",
    plaintextFeedback: ""
}

const mentionSuggestions = [
    { name: '@prosentti', title: 'Samoin vastanneiden prosenttimäärä kaikista vastanneista' },
    // { name: '@average', title: 'Samoin vastanneiden keskimääräinen tulos koko kyselyssä' },
    // { name: '@total', title: 'Samoin vastanneiden kokonaismäärä kaikista vastanneista' }
]

export default class QuestionOptions extends Component {
    _isMounted = false;
    constructor() {
        super()

        this.state = {
            activeTab: '1',
            points: {},
            questionOptions: [],
            analytics: [],
            payload: {},
            tags: [],
            targetPayload: {},
            inputIndex: -1,
            isMounting: true,
            optionModal: false,
            pointsInState: "",
            sentSurveyId: "",
            customColor: null
        }
        this.start = 600;
        this.repeatTimer = undefined;
        this.pointTarget = undefined;
        this.placeholderForPoints = undefined;
    }

    componentDidMount() {
        this._isMounted = true;
        let customColor = getComputedStyle(document.documentElement).getPropertyValue('--enterprise-primary-color')
        if(customColor !== null){
            this.setState({customColor : customColor})
        }

        let object = {
            currentQuestion: this.props.question
        };

        if (typeof this.props.question.questionOptions !== 'undefined') {
            object.questionOptions = [
                ...this.props.question.questionOptions
            ]
        } else {
            object.questionOptions = [];
        }

        object.isMounting = false;

        this.setState(object);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.surveyId !== this.props.surveyId) {
            this.setState({sentSurveyId: this.props.surveyId});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    //Adds new question option
    addNewOption = () => {
        //Spread out the new options from the emptyOptions
        let objectToPush = {
            ...emptyOptions
        }

        let payload = {
            groupId: this.props.group.id,
            incomingData: {
                groupId: this.props.group.id,
                targetQuestion: this.props.question.id,
                type: "option",
                target: 'questionOption',
                targetData: {
                    ...objectToPush
                }
            }
        }

        this.props.handleSave(payload);
    }

    onPointsSave = debounce((payload) => {
        this.props.handleSave(payload);
    }, 1000);

    onButtonRelease = (event) => {
        clearTimeout(this.repeatTimer)
        this.pointTarget = undefined;
        if (this.state.targetPayload !== undefined && this.state.targetPayload.incomingData !== undefined) {
            const { targetIndex } = this.state.targetPayload.incomingData;
            if (this.placeholderForPoints !== undefined) {
                if((this.placeholderForPoints.item.points !== -1 && this.placeholderForPoints.item.points !== 101) && this.placeholderForPoints.item.points !== this.props.question.questionOptions[targetIndex].points) {
                    let targetPayload = {
                        ...this.state.targetPayload
                    };

                    if(this.props.question.questionOptions[targetIndex].points !== targetPayload.incomingData.targetData.points) {
                        targetPayload.incomingData.targetData.points = this.props.question.questionOptions[targetIndex].points
                    }
                    
                    this.onPointsSave(targetPayload);
                    this.setState({targetPayload: {}});
                    this.placeholderForPoints = undefined;
                }
            }
        }

        this.start = 600;
        //this.checkPoints();
    }

    checkPoints = debounce(() => {
        if (typeof this.state.targetPayload.incomingData !== 'undefined') {
            const { targetIndex } = this.state.targetPayload.incomingData;
            const { targetData } = this.state.targetPayload.incomingData;

            if (this.props.question.questionOptions !== undefined) {

                if (this.props.question.questionOptions[targetIndex] !== undefined) {
                    const { points } = this.props.question.questionOptions[targetIndex]

                    if (targetData["points"] !== points) {
                        this.props.handleSave(this.state.targetPayload);
                    }
                } else {
                    if (this.state.questionOptions[targetIndex] !== undefined) {
                        this.props.handleSave(this.state.targetPayload);
                    }
                }
            } else if (this.state.questionOptions[targetIndex] !== undefined) {
                this.props.handleSave(this.state.targetPayload);
            }
        }
    }, 1000)

    repeatPositiveButtonEvent = (values) => {
        if (this.pointTarget === undefined) {
            this.pointTarget = values;
            this.placeholderForPoints = {
                ...JSON.parse(JSON.stringify(values))
            }
            this.props.onPointsAdd(values);
            this.updateOptionPoints(values);
        } else {
            if (this.pointTarget.item.points + 1 <= 100) {
                this.props.onPointsAdd(this.pointTarget);
                this.pointTarget.item.points += 1;
                this.updateOptionPoints(this.pointTarget);
            }

        }

        this.repeatTimer = setTimeout(this.repeatPositiveButtonEvent, this.start)
        this.start = this.start / 2
    }

    repeatNegativeButtonEvent = (values) => {
        if (this.pointTarget === undefined) {
            this.pointTarget = values;
            this.placeholderForPoints = {
                ...JSON.parse(JSON.stringify(values))
            }
            this.props.onPointsDecrement(values);
            this.updateOptionPoints(values);
        } else {
            if (this.pointTarget.item.points - 1 >= 0) {
                this.props.onPointsDecrement(this.pointTarget);
                this.pointTarget.item.points -= 1;
                this.updateOptionPoints(this.pointTarget);
            }

        }

        this.repeatTimer = setTimeout(this.repeatNegativeButtonEvent, this.start)
        this.start = this.start / 2
    }

    updateOptionPoints = values => {
        let index = values.targetIndex;
        let type = values.item;

        if (this._isMounted && typeof type["points"] !== 'undefined') {
            if (type["points"] === "" || (!isNaN(type["points"]) && (parseInt(type["points"]) <= 100 && parseInt(type["points"]) >= 0))) {
                let updateObject = {
                    ...this.props.question.questionOptions[index],
                    ...type
                };
                let payload = {
                    groupId: this.props.group.id,
                    incomingData: {
                        targetIndex: index,
                        groupId: this.props.group.id,
                        targetQuestion: this.props.question.id,
                        type: "points",
                        target: 'questionOption',
                        targetData: {
                            ...values.option,
                            points: parseInt( type.points )
                        }
                    }
                }
                //Get all the list items
                let updateList = [
                    ...JSON.parse(JSON.stringify(this.props.question.questionOptions))
                ]
                //Replace the object in the right index with the updateObject
                updateList[index] = updateObject;

                //Set it in state
                let setPoints = {
                    targetIndex: index,
                    points: parseInt( type.points )
                };

                if (type.points !== "" && this.state.inputIndex !== -1) {
                    this.props.onPointsSet(setPoints);
                }
                this.setState({ questionOptions: updateList, targetPayload: payload, pointsInState: type.points });
            } else {
                type["points"] = 100;
                let updateObject = {
                    ...this.props.question.questionOptions[index],
                    ...type
                };
                let payload = {
                    groupId: this.props.group.id,
                    incomingData: {
                        targetIndex: index,
                        groupId: this.props.group.id,
                        targetQuestion: this.props.question.id,
                        type: "points",
                        target: 'questionOption',
                        targetData: {
                            ...values.option,
                            points: parseInt( type.points )
                        }
                    }
                }
                //Get all the list items
                let updateList = [
                    ...JSON.parse(JSON.stringify(this.props.question.questionOptions))
                ]
                //Replace the object in the right index with the updateObject
                updateList[index] = updateObject;
                let setPoints = {
                    targetIndex: index,
                    points: parseInt( type.points )
                };

                if (type.points !== "" && this.state.inputIndex !== -1) {
                    this.props.onPointsSet(setPoints);
                }

                //Set it in state
                this.setState({ questionOptions: updateList, targetPayload: payload, pointsInState: type.points });
            }
        }
    }

    //When user releases mouse this will execute
    onBlurPoints = (index, points) => {

        if (this._isMounted && points === "") {
            let assignValue = {
                points: 0
            }

            let updateObject = {
                ...this.state.questionOptions[index],
                ...assignValue
            };
            //Get all the list items
            let updateList = [
                ...JSON.parse(JSON.stringify(this.state.questionOptions))
            ]
            //Replace the object in the right index with the updateObject
            updateList[index] = updateObject;
            //Set it in state

            this.setState({ questionOptions: updateList });
        }
    }

    //Handles updates to text fields
    updateOptionValues = (values) => {
        //Index of incoming questionOption data
        let index = values.index;
        //Item holds all the data objects that are included
        let item = values.item;
        //Object key that called this method
        let type = values.type;


        let payload = {
            groupId: this.props.group.id,
            incomingData: {
                targetIndex: index,
                groupId: this.props.group.id,
                targetQuestion: this.props.question.id,
                type: type,
                target: 'questionOption',
                targetData: {
                    ...values.option,
                    ...item
                }
            }
        }

        //spread the incoming items to an object before updating 
        let updateFieldObject = {
            ...item
        };
        //Update the item we want from the list
        let updateObject = {
            ...this.state.questionOptions[index],
            ...updateFieldObject
        };
        //Get all the list items
        let updateList = [
            ...JSON.parse(JSON.stringify(this.state.questionOptions))
        ]
        //Replace the object in the right index with the updateObject
        updateList[index] = updateObject;
        //Set it in state
        if (this._isMounted) {
            this.setState({ questionOptions: updateList, targetPayload: payload });
        }


    }

    proceedToDeleteOption = () => {
        if (this._isMounted && Object.keys(this.state.payload).length > 0) {
            let options = [
                ...this.props.question.questionOptions
            ];

            let newOptions = options.filter(x => x !== this.state.payload);
            let payload = {
                groupId: this.props.group.id,
                incomingData: {
                    groupId: this.props.group.id,
                    targetQuestion: this.props.question.id,
                    type: "deleteOption",
                    target: 'questionOption',
                    targetData: {
                        questionOptions: [
                            ...newOptions
                        ]
                    }
                }
            }
            this.props.handleSave(payload);
            this.setState({ optionModal: false, payload: {} });
        }
    }

    openOptionModal = (payload) => {
        if (this._isMounted && this.state.optionModal) {
            this.setState({ optionModal: false, payload: {} });
        } else {
            this.setState({ optionModal: !this.state.optionModal, payload: payload });
        }
    }

    //Handles questionOption color changes
    colorHandler = (colour, index) => {
        let obj = {
            index: index,
            item: {
                ...this.props.question.questionOptions[index],
                color: colour.toUpperCase()
            }
        }

        if (this._isMounted && colour.toUpperCase() !== this.props.question.questionOptions[index].color.toUpperCase()) {
            let payload = {
                groupId: this.props.group.id,
                surveyId: this.props.surveyId,
                incomingData: {
                    targetIndex: index,
                    groupId: this.props.group.id,
                    targetQuestion: this.props.question.id,
                    type: "color",
                    target: 'questionOption',
                    targetData: {
                        ...this.props.question.questionOptions[index],
                        color: colour.toUpperCase()
                    }
                }
            }
            this.props.handleSave(payload);
        }

        this.updateOptionValues(obj);
    }

    changeToInput = (index, points) => {
        if (index === this.state.inputIndex) {
            if (this.state.pointsInState !== "") {
                this.props.handleSave(this.state.targetPayload);
            }

            this.setState({ inputIndex: -1, pointsInState: "" });
        } else {
            this.setState({ inputIndex: index, pointsInState: points });
        }
    }

    resetPayloadData = () => {
        this.setState({ targetPayload: {} });
    }

    handleChange = (value) => {
        const values = {
            index: this.props.index,
            item: { tags: value }
        }
        this.props.onUpdate(values);
        this.props.newTag(value, this.props.index);
    };

    handleTargetSurvey = (value, index, type) => {
        
        if(type === 'add') {
            let payload = {
                groupId: this.props.group.id,
                    surveyId: this.props.surveyId,
                    incomingData: {
                        targetIndex: index,
                        groupId: this.props.group.id,
                        targetQuestion: this.props.question.id,
                        type: "targetSurvey",
                        target: 'questionOption',
                        targetData: {
                            ...this.props.question.questionOptions[index],
                            targetSurvey: value
                        }
                    }
            }
            this.handleLinkSave(payload).then(setTimeout(() => this.fetchNewLinks(), 400));
        } else if (type === 'delete') {
            let payload = {
                groupId: this.props.group.id,
                surveyId: this.props.surveyId,
                incomingData: {
                    targetIndex: index,
                    groupId: this.props.group.id,
                    targetQuestion: this.props.question.id,
                    type: "targetSurvey",
                    target: 'questionOption',
                    targetData: {
                        ...this.props.question.questionOptions[index],
                        targetSurvey: {
                            ...value,
                            delete: true
                        }
                    }
                }
            }
            this.handleLinkSave(payload).then(
                setTimeout(() => this.fetchNewLinks(), 400));
        }
    }

    handleLinkSave = async(payload) => {
        await this.props.handleSave(payload);
    }

    fetchNewLinks = async() => {
        if(this.props.category.original) {
            let linkPayload = {
                surveyId: this.props.category._id,
                original: this.props.category.original
            };
            this.props.getSurveyLinks(linkPayload);
        } else {
            let linkPayload = {
                sentSurveyId: this.props.surveyId,
                original: this.props.category.original
            }
            this.props.getSurveyLinks(linkPayload);
        }
    }

    changeTab = (index, tab) => {
        let options = [
            ...this.state.questionOptions
        ];

        let targetOption = options[index];
        targetOption.activeTab = tab;

        this.setState({ questionOptions: options });
    };

    setTags = (event, id) => {
        let payload = {
            groupId: this.props.group.id,
            surveyId: this.props.surveyId,
            incomingData: {
                groupId: this.props.group.id,
                targetQuestion: this.props.question.id,
                type: "tag",
                target: 'questionOption',
                targetData: {
                    ...id,
                    tags: event? [...event] : []
                }
            }
        }
        this.props.handleSave(payload);
    }

    setDontCountScore = ( questionOption, dontCount ) => {
        let payload = {
            groupId: this.props.group.id,
            surveyId: this.props.surveyId,
            incomingData: {
                groupId: this.props.group.id,
                targetQuestion: this.props.question.id,
                type: "dontCountScore",
                target: 'questionOption',
                targetData: {
                    ...questionOption,
                    dontCountScore: dontCount
                }
            }
        }
        this.props.handleSave( payload )
    }

    render() {
        return (

            <div>
                <QuestionOptionDeletionModal modal={this.state.optionModal} toggler={this.openOptionModal} deleteOption={this.proceedToDeleteOption} length={this.props.question.questionOptions.length} />
                {(this.props.question.questionOptions !== undefined && this.props.question.questionOptions.length > 0) &&
                    this.props.question.questionOptions.map((question, y) =>
                        <QuestionOption {...this.props}
                            repeatPositiveButtonEvent={this.repeatPositiveButtonEvent}
                            repeatNegativeButtonEvent={this.repeatNegativeButtonEvent}
                            onButtonRelease={this.onButtonRelease}
                            changeToInput={this.changeToInput}
                            inputIndex={this.state.inputIndex}
                            color={question.color}
                            text={question.text}
                            feedbacktext={question.feedback}
                            score={question.points}
                            dialogFeedbackText={question.dialogFeedbackText}
                            dialogFeedback={question.dialogFeedback}
                            tags={[]}
                            index={y}
                            key={y + 2}
                            editorId={`${question.id}-${this.state.sentSurveyId}-${y}`}
                            activeTab={this.state.activeTab}
                            handleColor={this.colorHandler}
                            question={question}
                            onOptionDelete={this.openOptionModal}
                            surveyIsDialog={this.props.surveyIsDialog}
                            handleDialogFeedbackTextChange={this.handleDialogFeedbackTextChange}
                            setDialogFeedbackPayload={this.setDialogFeedbackPayload}
                            handleFeedbackTextChange={this.handleFeedbackTextChange}
                            updateOptionPoints={this.updateOptionPoints}
                            setTextEditorPayloadData={this.setTextEditorPayloadData}
                            changeTab={this.changeTab}
                            groupId={this.props.group}
                            questionId={this.props.question.id}
                            pointsInState={this.state.pointsInState}
                            changeOptionTab={this.props.changeOptionTab}
                            setTags={this.setTags} 
                            saveDraftjsImage={this.props.saveDraftjsImage}
                            handleTargetSurvey={this.handleTargetSurvey}
                            surveyFeedback={question.surveyFeedback}
                            setDontCountScore={ this.setDontCountScore }
                            surveyId={this.props.surveyId}
                            />
                    )}
                {this.props.original &&
                <Row>
                    <AddItem add={this.addNewOption} />
                </Row>
                }
            </div>
        )
    }
}

//After user clicks new tag, this will be displayed above the button
const TagList = props => {
    async function promiseOptions(inputValue) {
        let params = {
            data: inputValue,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const data = fetch(confs.url + 'tagSearch', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => response.json())

        let tags = await data;

        return tags
    };

    function NoOptionsMessage(props) {
        if (props.options.length) return null;
        return <div {...props.innerProps} style={{padding: 5}}>Voit hakea tai lisätä tageja kirjoittamalla</div>;
      }

    const components = {
        NoOptionsMessage
    }

    function formatCreateLabelCreator(value) {
        return 'Lisää tagi: "' + value + '"'
      }

    return (
        // <Input placeholder="Uusi tagi" onChange={props.searchTags} ></Input>
        <AsyncCreatableSelect 
            loadOptions={promiseOptions} 
            components={components} 
            isMulti 
            value={props.question.tags} 
            onChange={(event) => props.setTags(event, props.question)}
            placeholder=""
            className='react-select-container'
            classNamePrefix="react-select"
            formatCreateLabel={formatCreateLabelCreator} 
            />
    )
}

const QuestionOption = props => {
    const dontCountScore = props.question.dontCountScore !== undefined && !!props.question.dontCountScore
    
    return (
        <div>
            <Row style={{ paddingBottom: '1rem' }}>
                <Col xl="5" md="5" style={{ paddingTop: 10 }}>
                    <h6>Pisteet</h6>
                    
                    { props.original && <FormGroup check>
                        <h6 check>
                            <Input type="checkbox" onChange={ () => props.setDontCountScore( props.question, !dontCountScore ) } checked={ dontCountScore } />{ ' ' }
                            Älä ota huomioon laskennassa
                        </h6>
                    </FormGroup> }
                    
                    <Row>
                        <Col>
                            {props.original ? <CustomPointsPicker
                                option={props.question}
                                points={props.score}
                                handlePoints={props.updateOptionPoints}
                                index={props.index}
                                pointsInState={props.pointsInState}
                                changeToInput={props.changeToInput}
                                inputIndex={props.inputIndex}
                                repeatPositiveButtonEvent={props.repeatPositiveButtonEvent}
                                repeatNegativeButtonEvent={props.repeatNegativeButtonEvent}
                                onButtonRelease={props.onButtonRelease}
                                dontCountScore={ dontCountScore }
                                />
                                : <h4 style={{textAlign: 'center', fontSize: '3rem'}}>{props.score}</h4>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xl="5" md="5" style={{ paddingTop: 10 }}>
                    <h6>Väri</h6>
                    <Row>
                        <Col xs="12">
                            <ColorPicker color={props.color} handleColor={props.handleColor} index={props.index} surveyId={props.surveyId}/>
                        </Col>
                    </Row>
                </Col>
                <Col xl="2">
                    {props.original && <p style={{ cursor: 'pointer', color: 'red' }} onClick={() => props.onOptionDelete(props.question)}>Poista vaihtoehto</p>}
                </Col>
            </Row>
            <Row>
                <Col xs="6">
                    <h6>Linkitä kartoitus vastausvaihtoehtoon</h6>
                    <Select value={props.question.targetSurvey || null}
                        onChange={item => props.handleTargetSurvey(item, props.index, "add")}
                        options={props.categories.filter(x => (x.value !== props.category._id && x.type === props.category.type) || x.label === "Lopeta kartoitus vastauksesta")}
                        placeholder="Valitse kartoitus johon ohjataan"
                        isDisabled={false}
                        noOptionsMessage={() => {return "Ohjattavia kartoituksia ei löytynyt"}}
                        maxMenuHeight={200}
                        className='react-select-container'
                        classNamePrefix="react-select"
                    />
                </Col>
                {props.question.targetSurvey &&
                    <Col xs="6">
                        <h6 style={{opacity: 0}}>Placeholder</h6>
                        <button className="small-white-button lighter-text wide-button" style={{float: 'left'}} onClick={() => props.handleTargetSurvey(props.question.targetSurvey, props.index, "delete")}>Ota pois käytöstä</button>
                    </Col>
                }
            </Row>
            <Row style={{ alignItems: 'baseline' }}>
                <Col xl="6">
                    <h6 style={{ padding: '0.5rem', paddingBottom: props.surveyIsDialog ? '0.2rem' : 0 }}>Vastausvaihtoehto</h6>
                    <DebounceTextEditor
                        editorId={props.editorId}
                        additionalClass="questionOptionTextArea"
                        content={props.text || ""}
                        readOnly={false}
                        handleSave={props.handleSave}
                        questionId={props.questionId}
                        option={props.question}
                        groupId={props.group.id}
                        type="text"
                        surveyId={props.surveyId}
                        plaintextEditor={true}
                        spellCheck={ props.spellCheckOn }
                        connectionType={"surveys"}
                        documentId={props.surveyId}
                    />
                </Col>
                <Col xl="6">
                    {props.surveyIsDialog ? (
                        <div>
                            <Nav tabs className="">
                                <NavItem className="question-edit-navitem">
                                    <NavLink
                                        className={(props.question.activeTab === undefined || props.question.activeTab === '1' ? ' active' : '')}
                                        onClick={() => props.changeOptionTab({ targetIndex: props.index, tab: '1' })}
                                        style={{ paddingBottom: '0.4rem', paddingTop: '0.4rem' }}
                                    >
                                        Raportin palaute
                                    </NavLink>
                                </NavItem>
                                <NavItem className="question-edit-navitem">
                                    <NavLink
                                        className={(props.question.activeTab !== undefined && props.question.activeTab === '2' ? ' active' : '')}
                                        onClick={() => props.changeOptionTab({ targetIndex: props.index, tab: '2' })}
                                        style={{ paddingBottom: '0.4rem', paddingTop: '0.4rem' }}
                                    >
                                        Dialogivastaus
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            {props.question.activeTab !== '2'  &&
                                <div tabId="1">
                                    <DebounceTextEditor editorId={`${props.editorId}-feedback`}
                                        content={props.feedbacktext || ""}
                                        index={props.index}
                                        onTextChange={props.handleFeedbackTextChange}
                                        readOnly={false}
                                        handleSave={props.handleSave}
                                        questionId={props.questionId}
                                        option={props.question}
                                        groupId={props.group.id}
                                        surveyId={props.surveyId}
                                        type="feedback"
                                        additionalClass="questionOptionTextArea" 
                                        saveDraftjsImage={props.saveDraftjsImage}
                                        spellCheck={ props.spellCheckOn }
                                        connectionType={"surveys"}
                                        documentId={props.surveyId}
                                    />
                                </div>
                            }
                            {props.question.activeTab !== undefined && props.question.activeTab === '2' &&
                                <div tabId="2">
                                    <DebounceTextEditor
                                        editorId={`${props.editorId}-dialog`}
                                        index={props.index}
                                        content={props.dialogFeedback || ""}
                                        onTextChange={props.handleDialogFeedbackTextChange}
                                        handleSave={props.handleSave}
                                        questionId={props.questionId}
                                        option={props.question}
                                        groupId={props.group.id}
                                        surveyId={props.surveyId}
                                        readOnly={false}
                                        type="dialogFeedback"
                                        additionalClass="questionOptionTextArea"
                                        suggestions={mentionSuggestions}
                                        saveDraftjsImage={props.saveDraftjsImage}
                                        spellCheck={ props.spellCheckOn }
                                        connectionType={"surveys"}
                                        documentId={props.surveyId}
                                    />
                                </div>
                            }
                        </div>
                    ) : (
                        <div>
                            <Nav tabs className="">
                                <NavItem className="question-edit-navitem">
                                    <NavLink
                                        className={(props.question.activeTab === undefined || props.question.activeTab === '1' ? ' active' : '')}
                                        onClick={() => props.changeOptionTab({ targetIndex: props.index, tab: '1' })}
                                        style={{ paddingBottom: '0.4rem', paddingTop: '0.4rem' }}
                                    >
                                        Raportin palaute
                                    </NavLink>
                                </NavItem>
                                <NavItem className="question-edit-navitem">
                                    <NavLink
                                        className={(props.question.activeTab !== undefined && props.question.activeTab === '2' ? ' active' : '')}
                                        onClick={() => props.changeOptionTab({ targetIndex: props.index, tab: '2' })}
                                        style={{ paddingBottom: '0.4rem', paddingTop: '0.4rem' }}
                                    >
                                        Kartoitusvastaus
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            {/* <textarea rows="4" className="questionOptionTextArea" value={this.props.feedbacktext} onChange={(e) => this.props.onUpdate({index: this.props.index, item: {feedback: e.target.value}})}></textarea> */}
                            {props.question.activeTab !== '2' &&
                                <div tabId="1">
                                    <DebounceTextEditor
                                        editorId={`${props.editorId}-feedback`}
                                        content={props.question.feedback || ""}
                                        index={props.index}
                                        onTextChange={props.handleFeedbackTextChange}
                                        readOnly={false}
                                        handleSave={props.handleSave}
                                        questionId={props.questionId}
                                        surveyId={props.surveyId}
                                        option={props.question}
                                        groupId={props.group.id}
                                        type="feedback"
                                        additionalClass="questionOptionTextArea"
                                        saveDraftjsImage={props.saveDraftjsImage}
                                        spellCheck={ props.spellCheckOn }
                                        connectionType={"surveys"}
                                        documentId={props.surveyId}
                                    />
                                </div>
                            }
                            {props.question.activeTab !== undefined && props.question.activeTab === '2' &&
                                <div tabId="2">
                                    <DebounceTextEditor
                                        editorId={`${props.editorId}-surveyFeedback`}
                                        index={props.index}
                                        content={props.surveyFeedback || ""}
                                        handleSave={props.handleSave}
                                        questionId={props.questionId}
                                        option={props.question}
                                        groupId={props.group.id}
                                        surveyId={props.surveyId}
                                        readOnly={false}
                                        type="surveyFeedback"
                                        additionalClass="questionOptionTextArea"
                                        suggestions={mentionSuggestions}
                                        saveDraftjsImage={props.saveDraftjsImage}
                                        spellCheck={ props.spellCheckOn }
                                        connectionType={"surveys"}
                                        documentId={props.surveyId}
                                    />
                                </div>
                            }
                        </div>
                    )}
                </Col>
            </Row>

            <Row style={{ paddingBottom: '1rem' }}>
                <Col xl="12">
                    <h6>Tagit</h6>
                    <TagList setTags={props.setTags} questionId={props.question.id} question={props.question} tags={props.question.tags}/>
                </Col>
            </Row>
            <hr></hr>
        </div>
    )
}

const CustomPointsPicker = props => {
    if( !props.dontCountScore ) {
        return (
            <Row className="customPointsPicker">
                <Col style={ { paddingRight: 0 } }>
                    <i className="material-icons" onMouseDown={ () => props.repeatNegativeButtonEvent( { targetIndex: props.index, option: props.option, item: { points: parseInt( props.points ) } } ) } onMouseUp={ props.onButtonRelease } onMouseOut={ props.onButtonRelease }>remove</i>
                </Col>
                <Col style={ { paddingRight: 0, paddingLeft: 0 } }>
                    { props.inputIndex === props.index ? (
                        <Input value={ props.pointsInState } style={ { height: '100%', textAlign: 'center' } } className="customPointsPickerInput" autoFocus
                            onChange={ ( e ) => props.handlePoints( { targetIndex: props.index, option: props.option, item: { points: e.target.value } } ) }
                            onBlur={ () => props.changeToInput( props.index, props.points ) } />
                    ) : (
                            <p onClick={ () => props.changeToInput( props.index, props.points ) }>{ props.points }</p>
                        ) }
                </Col>
                <Col>
                    <i className="material-icons" onMouseDown={ () => props.repeatPositiveButtonEvent( { targetIndex: props.index, option: props.option, item: { points: parseInt( props.points ) } } ) } onMouseUp={ props.onButtonRelease } onMouseOut={ props.onButtonRelease }>add</i>
                </Col>
            </Row>
        )
    } else {
        return (
            <Col xs={{ size: 9 }} style={ { paddingRight: 0, paddingLeft: 0 } }>
                <Input readOnly value="-" style={ { height: '100%', textAlign: 'center', fontSize: '35px' } } />
            </Col>
        )
    }
};

const AddItem = props => {
    return (
        <Col sm="1">
            <i className="material-icons itemAdd" onClick={() => props.add()}>add</i>
        </Col>
    )
}
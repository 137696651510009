import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextEditor from '../../../../../../Utilities/TextEditor/texteditor';
import {stripUnicodeFromString} from '../../../../../../Utilities/HelperFunctions/helper';
import { convertFromRaw } from 'draft-js';
import moment from 'moment';
import * as ActionCreator from '../../../../../../store/index';
import {connect} from 'react-redux';

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center', position: 'absolute' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

class TaskModifier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskTitle: "",
            taskExplanation: "",
            taskTitlePlainContent: "",
            showTree: false
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.task._id !== this.props.task._id) {
            this.setState({
                taskTitle: this.props.task.taskTitle,
                taskExplanation: this.props.task.styledTextTask
            })
        }
    }
    handleTaskTitle = (rawEditorState) => {
        let plaintextEmail = convertFromRaw(rawEditorState).getPlainText()
        plaintextEmail = stripUnicodeFromString( plaintextEmail )
        // plaintextEmail = plaintextEmail.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        this.setState({taskTitle: plaintextEmail});
    }

    handleTaskExplanation = (rawEditorState) => {
        let plaintextEmail = convertFromRaw(rawEditorState).getPlainText()
        plaintextEmail = stripUnicodeFromString( plaintextEmail )
        // plaintextEmail = plaintextEmail.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        this.setState({taskExplanation: rawEditorState, taskExplanationPlainContent: plaintextEmail});
    }

    saveTask = (status = null) => {

        if(status !== null) {
            let payload = {
                taskId: this.props.task._id,
                status: status
            }
            this.props.onTaskUpdated(payload);
            this.props.modifierToggle();
        } else {
            let payload = {
                taskId: this.props.task._id,
                task: {
                    ...this.props.task,
                    taskTitle: this.state.taskTitle !== "" ? this.state.taskTitle : this.props.task.taskTitle,
                    plainTextTask: this.state.taskExplanationPlainContent,
                    styledTextTask: this.state.taskExplanation !== "" ? this.state.taskExplanation : this.props.task.styledTextTask,
                    deadline: moment(new Date(this.props.task.deadline)),
                    notificationDate: moment(new Date(this.props.task.notificationDate))
                }
            }
            this.props.onTaskUpdated(payload);
            this.props.modifierToggle();
        }
    }

    getSurveyTitles = surveyTitles => {
        let titlesAsString = "";

        surveyTitles.map((title, x) => {
            if(x !== surveyTitles.length - 1) {
                titlesAsString += title.title + "   " + `( ${title.code} )` + ", ";
            } else {
                titlesAsString += title.title + "   " + `( ${title.code} )`;
            }
            return null
        })
        return titlesAsString;
    }



    render() {
    const {taskTree} = this.props.task;
    return (
        Object.keys(this.props.task).length > 0 &&
            <Modal isOpen={this.props.modify} toggle={this.props.modifierToggle} size="lg" centered>
            <ModalHeader toggle={this.props.modifierToggle} className="taskModal">
                <div>
                    Tarkistuspisteen muokkaus
                    <button className="small-white-button lighter-text wide-button" onClick={() => this.setState(prevState => ({
                        showTree: !prevState.showTree
                    }))} style={{marginLeft: '3rem'}}>
                        {this.state.showTree ? "Piilota" : "Näytä tarkistuspisteen sijainti"}
                    </button>
                </div> 
            </ModalHeader>
            <ModalBody>
                {this.props.fetchStatus && <MegaLoaderSpinner />}
                <Row className="taskCreatorRow">
                    <div style={{width: '100%'}}>
                        {taskTree !== undefined &&
                            <div>
                                {this.state.showTree && 
                                    <div>
                                        <Col xl="12" style={{paddingBottom: '0.3rem'}}>
                                            <p>{taskTree.surveyTitles.length === 1 ? "Kartoitus: " : "Kartoitukset: "}</p>
                                            <p><b>{this.getSurveyTitles(taskTree.surveyTitles)}</b></p>
                                        </Col>
                                        <Col xl="12" style={{paddingBottom: '1rem'}}>
                                            <p>Rakenne: </p>
                                            <div className="treeStack">
                                                {taskTree.elements.survey !== undefined &&
                                                    <div className="taskTreeRow">
                                                        <i className="fas fa-layer-group taskTreeIcon"></i><span>{taskTree.elements.survey}</span>
                                                    </div>
                                                }
                                                {taskTree.elements.group !== undefined &&
                                                    <div className="taskTreeRow" style={{paddingLeft: '2em'}}>
                                                        <i className="fas fa-layer-group taskTreeIcon"></i><span>{taskTree.elements.group}</span>
                                                    </div>
                                                }
                                                {taskTree.elements.question !== undefined &&
                                                <div className="taskTreeRow" style={{paddingLeft: '4em'}}>
                                                    <i className="fas fa-layer-group taskTreeIcon"></i><span>{taskTree.elements.question}</span>
                                                </div>
                                                }
                                            </div>
                                        </Col>
                                    </div>
                                }
                            </div>
                        }
                        <Col xl="12">
                            <p>Otsikko</p>
                            <TextEditor editorId={`${this.props.task._id}-taskCreatorTitle`}
                                content={this.props.task.taskTitle || ""}
                                onTextChange={this.handleTaskTitle}
                                plaintextEditor={true}
                                additionalClass="taskTitle"
                            />
                        </Col>
                        <Col xl="12">
                            <p>Selite</p>
                            <TextEditor editorId={`${this.props.task._id}-taskCreatorExplanation`}
                                content={this.props.task.styledTextTask || ""}
                                onTextChange={this.handleTaskExplanation}
                                plaintextEditor={true}
                                additionalClass="taskExplanation"
                                styleEditor={true}
                                allowFileUpload={false} 
                            />
                        </Col>
                        <hr style={{clear: 'both'}}/>
                    </div>
                    <Col xl="6">
                        <p>Muistutuspäivä</p>
                        <h5>{moment(new Date(this.props.task.notificationDate)).format('DD.MM.YYYY')}</h5>
                    </Col>
                    <Col xl="6">
                        <p>Deadline</p>
                        <h5>{moment(new Date(this.props.task.deadline)).format('DD.MM.YYYY')}</h5>
                    </Col>
                    <Col xl="12">
                        <p>Vastuuhenkilöt</p>
                        <ul>
                            {this.props.task.notificationList.map((target, x) => {
                                return <li key={`${target.label}-${x}`}>{target.label}</li>
                            })}
                        </ul>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <button className="small-white-button wide-button" onClick={() => this.saveTask("declined")}>Peruuta tarkistuspiste</button>
                <button className="small-white-button wide-button" onClick={() => this.saveTask("done")}>Tarkistuspiste on suoritettu</button>
                <button className="small-white-button wide-button" onClick={() => this.saveTask()}>Tallenna muutokset</button>
            </ModalFooter>
        </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTaskUpdated: (params) => dispatch(ActionCreator.updateTaskCard(params))
    }
}

export default connect(null, mapDispatchToProps)(TaskModifier);
import { useState, useEffect } from "react"
import TextEditor from '../TextEditor/texteditor';
import { convertFromRaw } from 'draft-js';
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import './openAIBlock.css'
import { Scrollbars } from 'react-custom-scrollbars';
import { NotificationManager } from "react-notifications";
import { executeRequest } from "../requestHandler/dataHandler";
import moment from "moment";
const { Configuration, OpenAIApi } = require("openai");

// const testKey = "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"

// We could create model selection for gpt

export const OpenAIBlock = ({apiKey, text, textToPlan, showAIContent = false, toggleAIContent, updatePrompts, enterprisePrompts}) => {
  const configuration = new Configuration({
    apiKey: apiKey,
  });

  const openai = new OpenAIApi(configuration);
  const [prompt, setPrompt] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPrompted, setLoadingPrompted] = useState(false);
  const [loadingProof, setLoadingProof] = useState(false);
  const [loadingPromptResponse, setLoadingPromptResponse] = useState(false)
  const [textInput, setTextInput] = useState("")
  const [promptInput, setPromptInput] = useState("")
  const [infoModal, setInfoModal] = useState(false)
  const [savedPrompts, setSavedPrompts] = useState((enterprisePrompts && enterprisePrompts.length > 0) ? [...enterprisePrompts] : [])
  const [openSaved, setOpenSaved] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(true)
  const [search, setSearch] = useState("")

  useEffect(()=>{
    setTextInput(text)
  },[text])

  useEffect(()=>{
    if(enterprisePrompts && enterprisePrompts.length > 0){
        setSavedPrompts([...enterprisePrompts])
    }
  },[enterprisePrompts])

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{role: "system", content: "Olet asiantuntija."}, {role: "user", content: getPlainText(promptInput)}],
        temperature: 0
      })
      
      let response = completion.data.choices[0].message
      setApiResponse(response.content);
    } catch (e) {
        let message = e.response.data.error.message
        setApiResponse(message)
    }
    setLoading(false);
  };

  const handleProofreading = async (e) => {
    setLoadingProof(true);
    try {
        const completion = await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: [{role: "system", content: "Olet suomen kielen oikeinkirjoituksen asiantuntija. Korjaa teksti selkeälle suomen kielelle ja tiivistä tarvittaessa."}, {role: "user", content: getPlainText(textInput)}],
            temperature: 0
        })
    
        let response = completion.data.choices[0].message
        setApiResponse(response.content)
     
    } catch (e) {
        let message = e.response.data.error.message
        setApiResponse(message)
    }
    setLoadingProof(false);
  }

  const handleWithPrompt = async (e) => {
    setLoadingPrompted(true);
    try {
        const completion = await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: [{role: "system", content: "Olet asiantuntija. Käytä tekstinä '-merkeillä rajattua osiota "}, {role: "user", content: getPlainText(promptInput)+". Teksti:'"+getPlainText(textInput)+"'"}],
            temperature: 0
        })
        
        let response = completion.data.choices[0].message
        setApiResponse(response.content)

    } catch (e) {
        let message = e.response.data.error.message
        setApiResponse(message)
    }
    setLoadingPrompted(false)
  }

  const handleWithResponse = async (e) => {
    setLoadingPromptResponse(true);
    try {
      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{role: "system", content: "Olet asiantuntija. Käytä tekstinä '-merkeillä rajattua osiota "}, {role: "user", content: getPlainText(promptInput)+". Teksti:'"+getPlainText(apiResponse)+"'"}],
        temperature: 0
      })
      
      let response = completion.data.choices[0].message
      setApiResponse(response.content)

    } catch (e) {
        let message = e.response.data.error.message
        setApiResponse(message)
    }
    setLoadingPromptResponse(false)
  }

  const getPlainText = rawEditorState => {
    if(typeof rawEditorState !== 'string') {
        let plainTopic = convertFromRaw(rawEditorState).getPlainText();
        plainTopic = plainTopic.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        return plainTopic;
    } else {
        return rawEditorState;
    }
  }

  const handlePromptChange = (editorState) => {
    setPromptInput(editorState)
    setPrompt(getPlainText(editorState))
  }

  const handleSelectedPrompt = (prompt) => {
    setForceUpdate(true)
    setPromptInput(prompt)
    setPrompt(prompt)
  }

  const handleCopy = () => {
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = apiResponse;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    NotificationManager.success("Vastaus kopioitu leikepöydälle", "", 3000);
  }

  const savePrompt = async() => {
    let payload = {
        toBeSaved: prompt
    }
    const response = await executeRequest('plan/saveAIPrompt',payload)
    if(response._id){
        NotificationManager.success('Hopute tallennettu')
        setSavedPrompts([{...response}, ...savedPrompts])
        updatePrompts()
    }
  }

  const removePrompt = async(id) => {
    let payload = {
        _id: id
    }
    const response = await executeRequest('plan/removeAIPrompt',payload)
    if(response.removed){
        NotificationManager.success('Hopute poistettu')
        updatePrompts()
    }
  }

  const handleNameFilter = (item) => {
    const {prompt} = item
    if(prompt.toLowerCase().includes(search.toLowerCase())) return true
  }

  return (
    <>
        <Modal size="lg" isOpen={infoModal}>
            <ModalHeader toggle={()=> setInfoModal(false)}>Info ja ohjeet</ModalHeader>
            <ModalBody>
                <p><b>Huom!</b></p>
                <p>
                    Muotoilut, emojit  (mikäli tekoäly ei ole tilalle laittanut uusia) ja kuvat poistetaan tekstistä käsiteltäessä. Tällöin ne täytyy asettaa kappaleeseen uudellen, mikäli kappaleen sisältö korvataan AI:n tuottamalla sisällöllä. 
                </p>
                <p style={{marginTop:'0.5em'}}><b>Ohjeet</b></p>
                <p>Voit antaa tekoälylle hopute-kenttään ohjeet toteutukselle. Voit käyttää halutessasi teksti-kenttää.</p>
                <p style={{marginTop:'0.5em'}}><b>1. Konteksti</b></p>
                <p>Konteksti asettaa lähtötilanteen ja orientoi tekoälyä. Se tarjoaa tarvittavat taustatiedot, jotka auttavat mallia ymmärtämään skenaarion.</p>
                <p>Esimerkki: "Olet markkinoinnin asiantuntija, joka on erikoistunut digitaaliseen markkinointiin."</p>
                <p style={{marginTop:'0.5em'}}><b>2. Toimenpide</b></p>
                <p>Toimenpide on tietty asia, jonka haluat tekoälyn suorittavan. Se voi olla esseen kirjoittaminen, kysymykseen vastaaminen, runon kirjoittaminen jne.</p>
                <p>Esimerkki: "Kuvaa 50 sanalla mitä on digitaalinen markkinointi."</p>
                <p style={{marginTop:'0.5em'}}><b>3. Ohje</b></p>
                <p>Ohje opastaa, miten tekoälyn tulisi lähestyä tehtävää. Se voi sisältää vastauksen muodon, yksityiskohtaisuuden tason, kirjoituksen sävyn jne.</p>
                <p>Esimerkki: "Anna lukiolaiselle sopiva vastaus käyttäen mahdollisuuksien mukaan jokapäiväistä kieltä ja metaforia."</p>
                <p style={{marginTop:'0.5em'}}><b>4. Täsmentäminen</b></p>
                <p>Täsmennys ja ohjeet sen varmistamiseksi, että tekoäly ymmärtää, mitä vaaditaan ja voit edelleen täsmentää hoputetta.</p>
                <p>Esimerkki: "Voisitko toistaa minulle, mitä ymmärrät kuvauksesta?"" (teksti)</p>
                <p style={{marginTop:'0.5em'}}><b>5. Vastauksen hyödyntäminen</b></p>
                <p>Mikäli vastaus ei aivan täyttänyt tavoitetta, voit hyödyntää jo saatua vastausta käyttäen hoputteen kanssa "Suorita käyttäen vastausta"-painiketta. Tällöin tekoäly käyttää tekstinä aiemmin antamaansa vastausta.</p>
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => setInfoModal(false)}>Sulje</a>
            </ModalFooter>
        </Modal>
        {showAIContent &&
            <div style={{zIndex:'99', marginTop:'1em', padding:'0.5em', border:'1px solid #ddd', borderRadius:'10px', marginRight:'1em'}}>
                <Row>
                    <Col xs="9">
                        <div style={{display:'flex', marginBottom:'0.5em'}}>
                            <Label><b>Hopute</b></Label>
                            <div onClick={()=> setInfoModal(true)} style={{marginLeft:'1em', cursor:'pointer', marginLeft:'1em'}}><i className="fas fa-info-circle"></i></div>
                            <p style={{ fontSize:'0.8rem', marginLeft:'1.5em', marginTop:'0.2em' }}> (Huom! Tyylit ja kuvat eivät säily, mikäli vastaus siirretään kappaleeksi)</p>
                        </div>
                        <div style={{border:'1px solid #ddd', borderRadius:'10px', marginRight:'0.25em'}}>
                            <Scrollbars
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                style={{ width: "100%", height: '80px', cursor: 'initial' }} >
                                <TextEditor
                                    editorId={'gpt-input2'} 
                                    content={promptInput} 
                                    placeholder="-"
                                    onTextChange={editorState => handlePromptChange(editorState)}
                                    additionalClass="gpt_input "
                                    plaintextEditor={true}
                                    needToForceUpdate={forceUpdate}
                                    setForceUpdate={setForceUpdate}
                                />
                            </Scrollbars>
                        </div>
                    </Col>
                    <Col xs="3">
                        <button className="small-white-button close-ai-block" onClick={()=> toggleAIContent()} title="Piilota OpenAI-lohko">X</button>
                        <Dropdown style={{marginTop:'2em', cursor:'pointer', marginRight:'0.5em'}} autoClose="inside" isOpen={openSaved} toggle={ () => setOpenSaved( !openSaved) }>
                            <DropdownToggle tag="div">
                                <div className={"small-white-button "} style ={{ marginTop:'1em', fontSize:'1rem', textAlign:'center' }} >
                                    {openSaved?
                                        <i className="fas fa-caret-up" style={{marginRight:'0.5em'}}></i> 
                                        :
                                        <i className="fas fa-caret-down" style={{marginRight:'0.5em'}}></i> 
                                    }
                                    Tallennetut hoputteet
                                </div>
                            </DropdownToggle>
                            <DropdownMenu>
                                <div id="searchBarId">
                                    <Input type="search" id="prompt-search" className="search-bar-open" style={{ width:'20.5em', marginLeft:'1.25em'}} placeholder={"Hae hoputetta"} value={search} onChange={(event) => setSearch(event.target.value)} />
                                </div>
                                <div style={{ borderBottom:'2px solid #ebebeb', marginTop:'0.5em'}}></div>
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "25em", height: '400px', cursor: 'initial' }} 
                                >
                                    {savedPrompts && savedPrompts.filter(prompt => handleNameFilter(prompt)).map((item,key)=>{
                                        return(
                                            <DropdownItem key={key} className="saved-prompt-name" toggle={false}>
                                                <div className="remove-prompt" title="Poista" onClick={(e) => (e.preventDefault() , removePrompt(item._id))}>x</div>
                                                <div title={item.prompt} onClick={()=> handleSelectedPrompt(item.prompt)}>
                                                    <p className="saved-prompt-item">{item.prompt}</p>
                                                    <div style={{fontSize:'0.8rem'}}>{moment.utc(item.created,"MMM D, YYYY HH:mm:ss A", "en").local().format('DD.MM.YYYY  HH:mm')}</div>
                                                </div>
                                            </DropdownItem>
                                        )
                                    })}
                                </Scrollbars>
                            </DropdownMenu>
                        </Dropdown>
                        <div  style ={{ marginTop:'0.75em', marginRight:'0.5em' }}>
                            <button 
                                className={"small-white-button wide-button" + (prompt.length === 0 ? "":"ai-button-active")} 
                                style ={{ fontSize:'0.9rem', width:'100%', textAlign:'center'}}
                                onClick={() => savePrompt()}
                            >
                                <div>
                                    Tallenna hopute
                                </div>
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{marginTop:'0.5em'}} xs="9">
                        <div style={{display:'flex', marginLeft:'0.25em'}}>
                            <button
                                disabled={loadingProof || textInput.length === 0}
                                className={"small-white-button " + (textInput.length === 0 ? "":"ai-button-active")}
                                style ={{ marginTop:'1em', marginRight:'1em', fontSize:'0.9rem' }}
                                onClick={() => handleProofreading()}
                                >
                                {loadingProof ? "Oikoluetaan..." : "Oikolue teksti"}
                            </button>
                            <button
                                disabled={loading || prompt.length === 0}
                                className={"small-white-button wide-button " + (prompt.length > 0 ? "ai-button-active":"")}
                                style ={{ marginTop:'1em', marginRight:'1em', fontSize:'0.9rem' }}
                                onClick={() => handleSubmit()}
                            >
                                {loading ? "Suoritetaan..." : "Suorita"}
                            </button> 
                            <button
                                disabled={loadingPrompted || prompt.length === 0}
                                className={"small-white-button " + ((textInput.length !== 0 && prompt.length > 0) ? "ai-button-active":"")}
                                style ={{ marginTop:'1em', marginRight:'1em', fontSize:'0.9rem' }}
                                onClick={() => handleWithPrompt()}
                            >
                                {loadingPrompted ? "Suoritetaan..." : "Suorita käyttäen kappaletta"}
                            </button>
                            <button
                                disabled={loadingPrompted || apiResponse.length === 0}
                                className={"small-white-button " + ((apiResponse.length > 0 && prompt.length > 0) ? "ai-button-active":"")}
                                style ={{ marginTop:'1em', marginRight:'1em', fontSize:'0.9rem' }}
                                onClick={() => handleWithResponse()}
                            >
                                {loadingPromptResponse ? "Suoritetaan..." : "Suorita käyttäen vastausta"}
                            </button>
                            
                        </div>
                    </Col>
                    <Col xs="3">
                        <div style={{display:'flex', flexDirection:'row', marginTop:'0.5em', marginLeft:'-0.25em', marginRight:'0.5em', justifyContent:'space-between'}}>
                            <button
                                disabled={apiResponse.length === 0}
                                className={"small-white-button wide-button " + (apiResponse.length > 0 ? "ai-button-active":"")}
                                style ={{ marginTop:'1em', fontSize:'0.9rem', marginLeft:'0.25em' }}
                                onClick={() => (textToPlan(apiResponse), setTextInput(apiResponse))}
                            >
                                Siirrä kappaleeksi
                            </button>
                            <button
                                disabled={apiResponse.length === 0}
                                className={"small-white-button wide-button " + (apiResponse.length > 0 ? "ai-button-active":"")}
                                style ={{ marginTop:'1em', marginRight:'0em', marginLeft:'0.5em', fontSize:'0.9rem', padding:'0.38em 0.75em' }}
                                onClick={() => handleCopy()}
                                title="Kopioi vastaus"
                            >
                                <i className="far fa-copy" style={{ zIndex:10}}></i>  
                            </button>
                        </div>
                    </Col>
                </Row>
                <div style={{ borderBottom:'2px solid #ebebeb', marginTop:'1em', marginRight:'0.25em' }}></div>
                <div className="response-editor">
                    <Label><b>Vastaus:</b></Label>
                    <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height: '370px' }} >
                    {(loading || loadingPrompted || loadingProof || loadingPromptResponse) ?
                        <MegaLoaderSpinner/>
                        :
                        <>
                            {apiResponse && (
                                <TextEditor
                                editorId={'gpt-output'} 
                                content={apiResponse} 
                                readOnly={true} onTextChange={() => { return }}
                                plaintextEditor={true}
                                />
                            )}
                        </>
                    }
                    </Scrollbars>
                </div>
            </div>
        }
    </>
  )
}


const MegaLoaderSpinner = () => {
  return (
    <Row>
      <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Col>
    </Row>
  )
}
import React, { useState, useEffect } from 'react';
import './project.css';
import { addMoreBreadcrumbs, setBreadcrumbs } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Input, Button, Container, Label, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { ProjectList } from './subComponents/ProjectList';
import { executeRequest } from '../../Utilities/requestHandler/dataHandler';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import { ProjectDataModal } from './ProjectDataModal';

export const ProjectContainer = ({history}) => {   
    const businessName = useSelector(state => state.authentication.businessName)
    const [selectedBusiness, setSelectedBusiness] = useState({})
    const [projects, setProjects] = useState([])
    //const [projectId, setProjectId] = useState("")
    const [selectedProject, setSelectedProject] = useState({})
    const [clientList, setClientList] = useState([])

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch( setBreadcrumbs( { to: 'tiedot', label: businessName } ) )
        dispatch(addMoreBreadcrumbs({ to: 'projektit', label: 'Projektit' }))
        getAllProjects()
    },[])

    const getAllProjects = async() => {
        const response = await executeRequest('project/getAll', {})
        if (!!response){
            setProjects(response)
        }
    }

    useEffect(()=>{
        const getClients = async() => {
            const response = await executeRequest('sender/get/getCompanies', {})
            if(response && response.businessList){
                setClientList(response.businessList.filter(business => business.label !== "Ei kohdeyritystä"))
            }
        }
        getClients()
    },[])
    
    const startNewProject = () => {
        if(selectedBusiness.value){
            history.push({
                pathname: '/projektit/hallitse',
                state:{
                    targetBusiness: selectedBusiness
                }
            })
        } else {
            NotificationManager.error('Valitse luotavalle projektille kohde!', 'Virhe!', 3000)
        }
    }
    const handleProject = (project) => {
        if(project && project._id){
            history.push({
                pathname: '/projektit/hallitse',
                state:{
                    projectId: project._id
                }
            })
        } else {
            NotificationManager.error('Tapahtui virhe!', 'Virhe!', 3000)
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <Container fluid>
            <ProjectDataModal
                isOpen={isModalOpen} 
                closeModal={setIsModalOpen}
                history={history}
            />
            {/* <TargetCompanyModal 
                isOpen={isModalOpen} 
                toggleModal={setIsModalOpen}
                selectedBusiness={selectedBusiness}
                setSelectedBusiness={setSelectedBusiness}
                clientList={clientList}
                startNewProject={startNewProject}
            /> */}
            <div className="project-list" style={{ display: "flex", flexDirection: "row" }}>
                
                <ProjectList 
                    projects={projects}
                    handleProject={handleProject}
                    history={history}
                    setIsModalOpen={setIsModalOpen}
                    update={getAllProjects}
                    clientList={clientList}
                />               
            </div>
        </Container>
    )
}

const TargetCompanyModal = (props) => {
    return(
        <Modal isOpen={props.isOpen} toggle={() => props.toggleModal(false)} >
              <ModalHeader toggle={() => props.toggleModal(false)}><span>Valitse kohdeyritys, jolle projekti luodaan</span></ModalHeader>
              <ModalBody>
                <Label>Kohdeyritys</Label>
                <Select
                    value={props.selectedBusiness}
                    onChange={(selected) => props.setSelectedBusiness(selected)}
                    options={props.clientList}
                    placeholder="Valitse kohdeyritys"
                    isDisabled={false}
                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                    className="companyTargets"
                    classNamePrefix="select"
                />
              </ModalBody>
              <ModalFooter>
                <button className="small-white-button lighter-text wide-button new-project-button" style={{ width: '100%' }} onClick={() => props.startNewProject()}>Jatka</button>
              </ModalFooter>
            </Modal>
    ) 
}
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader, Container, NavLink, NavItem, Nav } from 'reactstrap';
import {ExcelDataModal} from '../Modals/ExcelDataModal'
import NpsSymbol from '../../Components/Clients/subComponents/NpsSymbol';
import { max } from '@amcharts/amcharts4/core';
import { FavouriteStar } from '../Favourites/FavouriteStar';


const dateChecker = date => {
    let difference = moment().diff(moment(date).startOf('day'), "days");
    switch (difference) {
        case 0:
            return "tänään";
        case 1:
            return "eilen";
        default:
            return difference + " päivää sitten";

    }
}

const deadlineChecker = deadline => {
    if (deadline !== undefined) {
        let difference = moment(deadline).diff(moment().startOf('day'), "days");
        if (difference === 0) {
            return "Tänään"
        } else if (difference === 1) {
            return "Huomenna"
        } else if (difference > 1) {
            return difference + " päivää jäljellä"
        } else {
            return (
                <span style={{ color: 'red' }}>{moment.utc(deadline, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span>
            );
        }
    }
}

const capitalizeFirstLetter = string => {
    return string[0].toUpperCase() + string.slice(1);
}

// Old ActiveClientCard
// export const ActiveClientCard = ({ selectBusiness, business }) => {

//     const eventTypes = eventType => {
//         switch (eventType) {
//             case 'task':
//                 return "Tarkistuspiste";
//             case 'risk':
//                 return "Riskiarvio";
//             case 'plan':
//                 return "Lomake"
//             default:
//                 return "Kartoitus"
//         }
//     }

//     return (
//         <div className="card" onClick={() => selectBusiness(business)}>
//             <div className="card-header title-header">{`${business.name} - ${business.businessId}`}</div>
//             <div className="card-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
//                 <h5 className="card-title">Viimeisin tapahtuma</h5>
//                 <p>{eventTypes(business.lastEvent)}</p>
//                 <p className="card-text">{moment.utc(business.lastActive, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</p>
//             </div>
//             <div className="card-footer custom-footer text-muted">
//                 <p>{capitalizeFirstLetter(dateChecker(business.lastActive))}</p>
//             </div>
//         </div>
//     )
// }

const getStatus = status => {
    if (status === "inactive") {
        return "Odottaa julkaisua";
    } else if (status === "published") {
        return "Julkaistu";
    }
}

const tagsToMore = (tags, slicer) => {
    var tagss = ""
    if(tags !== undefined ){
        tags.slice(slicer, tags.length).filter(t => tagss = tagss + "  " + t.label.toString())
        return(tagss)
    }
}



export const ClientPlanCards = ({ plan, goToPlan, businessPage = false}) => {
    const { selectedBusiness, name, planName, creator, created,lastEdited, modified, status, modifiedByUser, tags, lastDate } = plan
    const [dateCompare, setDateCompare] = useState(false)
    const [today, setToday] = useState(false)
    const [isFollowed, setIsFollowed] = useState(false)

    useEffect(()=>{
        let today = moment.utc(new Date(),"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
        if(lastDate){
            let lastActive = moment.utc(lastDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(today === lastActive || today > lastActive){
                setDateCompare(true)
            }
            if(today === lastActive){
                setToday(true)
            }
        }
    },[plan])

    useEffect(()=>{
        if(plan.followOptions){
            plan.followOptions.map(option =>{
                if(option.planOpenFollowed && option.planOpenFollowed === true){
                    setIsFollowed(true)
                }
                if(option.commentingFollowed && option.commentingFollowed === true){
                    setIsFollowed(true)
                }
            }) 
        }       
    },[plan])
    
    return (
        <div className="card client-plan-card" style={{display:"flex", flexDirection:"column"}} onClick={() => goToPlan(plan._id)}>
            <div className="card-new-header">
                <div className="card-extra tag-row">
                    {plan.isPrivateDocument && <i className="far fa-eye-slash" style={{color: 'red'}} title='Yksityinen'></i>}
                    {plan.automationSummary && <span style={{ fontWeight: 'bold', fontSize: '0.7rem', marginRight:'0.25em' }}>Kooste</span>}
                    {tags&& tags.slice(0, 6).map((tag, i) => {                        
                        return(<div key={i} style={{paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                    })}                                                       
                    {tags && tags.length > 6 &&  
                        <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                            <i className="material-icons" title={tagsToMore(tags,6)}>more_horiz</i>
                        </div>
                    }
                </div> 
                <div style={{ margin: '0 0.5em 0 0' }}>
                    <FavouriteStar 
                        itemId={plan._id}
                        itemType={"plan"}
                        title={planName}
                        targetBusiness={(plan.selectedBusiness &&  plan.selectedBusiness.label) ? plan.selectedBusiness.label : "Ei kohdeyritystä"}
                    />
                </div>
            </div>
            <div className="card-body" style={{display:"flex", alignItems:"center", flexDirection:"row", paddingTop: 0, paddingBottom: 0, flex:"3 1" }}>
               <div>
                    {today &&
                        <span><p className="card-text" style={{ color: 'red' }}>Viimeinen aktiivinen päivä!</p></span>
                    }
                   <span className="tasksText"><p className="bigger-text overflower-short card-title" title={planName}>{planName}</p></span>
                    <span className="card-text" style={{display: 'flex', flexDirection: ''}}>
                        <p className="card-text">Muokattu:</p>
                        <p className="card-text">{moment.utc(lastEdited? lastEdited : modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</p>
                    </span>
                    {lastDate && 
                        <p style={{ fontSize: '0.7rem'}}>Akt: <span style={dateCompare?{ color:'red' } : {}}>{moment.utc(plan.lastDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>
                    }
                </div>
                <div style={{ marginRight: '0', marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
                    {plan.status === "published" && <span className="dot" title="Julkaistu" style={{backgroundColor: 'rgb(107,165,63,1)', fontSize: '0.8rem' }}></span>}
                    {(plan.isSigned === true)? 
                        <div style={{ marginTop: '0.5em' }}>
                            {(plan.allSigned && plan.allSigned === true)? 
                                <i className="fas fa-signature" title="Allekirjoitettu" aria-hidden="true" style={{ color: '#b5e413', fontSize: '1.5rem' }}></i>
                                : 
                                <i className="fas fa-signature" title="Osin allekirjoitettu" aria-hidden="true" style={{ color: '#ffcc00', fontSize: '1.5rem' }}></i>
                            }
                        </div>
                        :
                        <>
                        </>
                    }
                    
                </div>
            </div>         
            <div className=" custom-footer form-lighter-color" style={{flex:"1 3", padding:"0.5em 1.25em 0.5em 1.25em"}}>  
                <div style={{display:"flex", flexDirection:"column"}}>  
                    {!plan.selectedBusiness && <p style={{alignSelf:"flex-start", font:"normal normal normal 0.75em/0.80em Raleway" }} >{"Lomakepohja"}</p>}    
                    <span style={{alignSelf:"flex-start", font:"normal normal normal 0.75em/0.80em Raleway" }}>{businessPage ? getStatus(status) : selectedBusiness ? selectedBusiness.label : ""}</span>
                    <span style={{alignSelf:"flex-start", font:"normal normal normal 0.6em/0.75em Raleway", paddingTop:"0.5em"}}>Luotu: {moment.utc(created, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')} </span>                      
                </div> 
                <div className="card-extra">
                    {plan.tasks && <div style={{ marginRight: '0.1em', marginTop: '-0.15em' }}><i className="fas fa-tasks"
                        style={{
                            border: '1px solid #404040',
                            padding: 5,
                            borderRadius: '50%',
                            fontSize: '0.4rem'                         
                        }
                        }></i></div>
                    }
                    {plan.risks && <div style={{ marginRight: '0.5em', marginTop: '-0.15em' }}><i className="fas fa-exclamation"
                        style={{ border: '1px solid #404040', padding: 5, paddingLeft: 7, paddingRight: 7, borderRadius: '50%', fontSize: '0.4rem' }}>
                    </i></div>
                    }
                    {isFollowed && 
                        <div style={{ width: '100%',  opacity: '0.8' }} title="Lomaketta seurataan">
                            <i className="far fa-eye"></i>
                        </div>
                    }
                </div>              
            </div>
        </div>
    )
}

export const ProjectCards = ({project, goToProject}) => {
    const {projectName, tags, targetBusiness, created, modified} = project
    const [targetBusinessName, setTargetBusinessName] = useState(targetBusiness&&targetBusiness.label ? targetBusiness.label : "")
    const [progress, setProgress] = useState(null)
    useState(()=>{
        if(project && project.progress){
            let number = Math.round(project.progress)
            setProgress(number)
        }
    },[project])
    return(
        <div className="card project-card" onClick={()=> goToProject(project._id)} style={{display:"flex", flexDirection:"column"}}>
            <div className="card-new-header">
                <div className="card-extra tag-row">
                    {tags&& tags.slice(0, 6).map((tag, i) => {                        
                        return(<div key={i} style={{paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text project-lighter-color" title={tag.label}>{tag.label}</p></div>)
                    })}                                                       
                    {tags && tags.length > 6 &&  
                        <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                            <i className="material-icons" title={tagsToMore(tags,6)}>more_horiz</i>
                        </div>
                    }
                </div> 
                <div style={{ margin: '0 0.5em 0 0' }}>
                    <FavouriteStar 
                        itemId={project._id}
                        itemType={"project"}
                        title={projectName}
                        targetBusiness={targetBusinessName? targetBusinessName : "Ei kohdeyritystä"}
                    />
                </div>
            </div>
            <div className="card-body" style={{display:"flex", flexDirection:"row", paddingTop:0, marginTop:'0.25em', paddingBottom: 0, flex:"3 1" }}>
                <div>
                    <span className="tasksText"><p className="bigger-text overflower-short card-title" title={projectName}>{projectName}</p></span>
                    <span className="card-text" style={{display: 'flex', flexDirection: ''}}>
                        <p className="card-text">Muokattu:</p>
                    </span>
                    <span>
                        <p className="card-text">{moment.utc(modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</p>
                    </span>
                </div>
                <div style={{ marginRight: '0', marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
                    {project.status? 
                        <span className="dot" title={project.status.label} style={{backgroundColor: project.status.value, fontSize: '0.8rem', marginTop:'0.25em', float:'right', alignSelf:'flex-end' }}></span>
                        :
                        <></>
                    }
                    {progress && <p style={{ fontSize: '0.8rem', marginTop:'0.5em' }}>{progress}/100%</p>} 
                </div>
            </div>         
            <div className=" custom-footer project-lighter-color" style={{flex:"1 3", padding:"0.5em 1.25em 0.5em 1.25em"}}>  
                <div className="card-extra">
                    <p style={{ fontSize: '0.8em' }} className="overflower-short" title={targetBusinessName}>{targetBusinessName}</p>
                </div>              
            </div>
        </div>
    )
}

export const ContactCard = ({contact, onSelect = false, allowRemove, removeSelected}) => {
    const {tags, business } = contact
    let businessName = (business && business.businessName) ? business.businessName : ""
    let bgImageStyles = {}
    
    // if (contact.imageId) {
    //    bgImageStyles = {backgroundImage: `url(${confs.host + '/api/getContactImage/' + "5f290d764c33f4985e595191"})`, backgroundSize: 'contain', backgroundPosition: 'center', pointerEvents: 'none' }
    // }
    return(
        <> 
            {contact && 
                <div className="card contact-card" onClick={() => {onSelect? onSelect(contact): console.log("prompted onClick in contact")}}> 
                    {allowRemove &&
                        <div className="remove-contact-icon" >
                            <i className="fas fa-times" onClick={(e) => (e.stopPropagation(), removeSelected(contact))}></i>
                        </div>
                    }
                    <div className="contacts-divide">                    
                        <div className="contacts-info">
                            <div className="card-new-header contacts-header">
                                <div className="card-extra tag-row">
                                    {tags&& tags.slice(0, 6).map((tag, i) => {                        
                                        return(<div key={i} style={{paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text contact-color" title={tag.label}>{tag.label}</p></div>)
                                    })}                                                       
                                    {tags && tags.length > 6 &&  
                                        <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                            <i className="material-icons" title={tagsToMore(tags,6)}>more_horiz</i>
                                        </div>
                                    }
                                </div>                         
                            </div>               
                            <div className="contact-card-body"> 
                                <p className="card-title contacts-title">{contact.name}</p>
                                <p className="contact-smaller-text" style={{ fontWeight: 'bold' }}>{contact.role ? contact.role : "-"}</p>
                                <p className="contact-smaller-text" >{businessName}</p>
                                {contact.phone && <p><span style={{alignSelf:"flex-start", font:"normal normal normal 0.75em/0.80em Raleway", paddingTop:"0.5em", fontWeight: 'bold'}}>{"puh. " + contact.phone}</span> </p>}
                            </div>  
                        </div>
                        {/* <div className="contacts-image">
                            <div className="logo-container" style={bgImageStyles}></div>
                        </div>  */}
                    </div>
                    <div className="card-footer custom-footer contacts-footer">
                        <div style={{display:"flex", flexDirection:"column"}}>        
                            <span style={{alignSelf:"flex-start", font:"normal normal normal 0.75em/0.80em Raleway" }}>{contact.email || ""}</span>
                            {contact && contact.created && <span style={{alignSelf:"flex-start", font:"normal normal normal 0.75em/0.80em Raleway", paddingTop:"0.5em" }}>{"Luotu: " + moment.utc(contact.created, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span>}
                        </div> 
                    </div>
                </div> 
            }
        </>
    )
}

export const ActiveClientCard = (props) => {
    const { name, businessId, npsScore, lastActive, tags, companyTotalScore} = props.business
    
    return (
        <div className="card active-client-card" style={{display:"flex", flexDirection:"column"}} onClick={() => props.select.selectBusiness(props.business)} >
            <div style={{display:"flex", flexDirection:"row", flex:"1 3", paddingLeft:"1.25em", paddingRight:"1em", paddingTop:"0.5em", maxHeight:"15%",minHeight:"15%"}}>
                <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                    {tags&& tags.slice(0, 3).map((tag, i) => {                        
                        return(<div key={i} style={{paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text client-lighter-color" title={tag.label}>{tag.label}</p></div>)
                    })}                                                       
                    {tags && tags.length > 3 &&  
                        <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.2em"}}>
                            <i className="material-icons" title={tagsToMore(tags,3)}>more_horiz</i>
                        </div>
                    }
                </div> 
            </div>
            <div className="card-body" style={{display:"flex", alignItems:"center", flexDirection:"row", flex:"3 1", paddingTop: '0.5em', paddingBottom: '0.5em' }}>
               <div>                                                                                    
                   <div style={{display: 'flex', flex: '3 1 0px', flexDirection: 'column' }}>
                      <span className="tasksText"><p className="bigger-text overflower-short card-title card-title-client" title={name}>{name}</p></span>
                      <div xs="6" className="lighter-text">{businessId}</div>                                   
                   </div>                                                                                                                                                 
                </div> 
                <div style={{display:"flex", flexDirection:"column", marginLeft:"auto", marginTop:"auto",justifyContent:"center" }}>
                    <span style={{ float: 'right'}}>
                        <div style={{ display: 'flex', alignSelf :"center", alignItems:"flex-start", flex: "1 1 0px", flexDirection: 'column', width:'100%'}}> 
                        {props && companyTotalScore &&
                            <TotalScoreSymbol score={companyTotalScore.totalScore} count={companyTotalScore.followedCount}/>
                        }                  
                        {(props && npsScore) ?                                      
                                <NpsSymbol npsData={npsScore} card={true}/>                                        
                                :
                                <></>                                      
                        }             
                        </div>
                    </span>
                </div>
            </div>         
            <div className=" custom-footer client-lighter-color" style={{flex:"1 3", padding:"0.5em 1.25em 0.5em 1.25em"}}>  
                <div style={{display:"flex", flexDirection:"column"}}>        
                    <span style={{alignSelf:"flex-start", font:"normal normal normal 0.7em/0.80em Raleway"}}>Viimeisin tapahtuma</span>
                    <span style={{alignSelf:"flex-start", font:"normal normal normal 0.7em/0.80em Raleway", paddingTop:"0.5em"}}>{moment.utc(lastActive, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span>                   
                </div>  
            </div>
        </div>
    )
}

export const ActivatedSurveyCard = (props) => {
    const { dates, name, code, surveyAvgColor, surveyAvgScore, businessName } = props.survey

    let startDate = null
    let endDate = null
    if ( dates ) {
        var today = new Date()
        for(var date of dates){
            if(date.startDate && date.endDate && today >= new Date(date.startDate) && today <= new Date(date.endDate)){
                startDate = date.startDate
                endDate = date.endDate
            }
        }
    }
    let answers = 0
    if(props.survey.jobCalculatedStats && props.survey.jobCalculatedStats.answers ){
       answers = props.survey.jobCalculatedStats.answers
    }

    let color = surveyAvgColor || '#c8c8c8'
    let avg = surveyAvgScore || 0

    return (
        <div className="card active-client-card" style={{display:"flex", flexDirection:"column"}} onClick={()=> props.goToReport(props.survey)}>
            <div style={{display:"flex", flexDirection:"row", flex:"1 3", paddingLeft:"1.25em", paddingRight:"1em", paddingTop:"0.5em", maxHeight:"25%",minHeight:"25%", minWidth:'100%'}}>
                <div style={{display:"flex", alignItems:"center", width: '100%'}}>
                    <span className="card-text card-title">
                        <p className="card-title bigger-text" style={{ fontSize: '1.1rem'}}>{code}</p>
                    </span>    
                </div>
                <div style={{ margin: '0 0i 0 0', float: 'right' }}>
                    <FavouriteStar 
                        itemId={props.survey._id}
                        itemType={"sentSurvey"}
                        title={name}
                        targetBusiness={businessName? businessName : "Ei kohdeyritystä"}
                        sentSurvey={props.survey}
                    />
                </div>
            </div>
            <div className="card-body" style={{display:"flex", alignItems:"center", flexDirection:"row", flex:"3 1", paddingTop: '0.5em', paddingBottom: '0.5em' }}>
               <div>                                                                                    
                   <div style={{display: 'flex', flex: '3 1 0px', flexDirection: 'column' }}>
                        <span className="tasksText"><p className="bigger-text overflower-short card-title card-title-client" title={name}>{name}</p></span>  
                        <div xs="6" title={businessName} className="lighter-text card-business-overflower-short">{businessName}</div>
                        <p className="lighter-text">{answers} vastausta</p>
                   </div>                                                                                                                                                 
                </div> 
                <div style={{display:"flex", flexDirection:"column", marginLeft:"auto", marginTop:"auto",justifyContent:"center" }}>
                    <span style={{ float: 'right'}}>
                        <div style={{ display: 'flex', alignSelf :"center", alignItems:"flex-start", flex: "1 1 0px", flexDirection: 'column'}}>                   
                         <div className="smaller-score-ball" style={ { borderColor: color }}>{ avg }</div>
                        </div>
                    </span>
                </div>
            </div>         
            <div className="card-footer custom-footer survey-lighter-color" style={{flex:"1 3", padding:"0.5em 1.25em 0.5em 1.25em"}}>  
                <div style={{display:"flex", flexDirection:"column"}}>  
                    <p style={{ font:"normal normal normal 0.7em/0.8em Raleway", paddingBottom: '0.25em' }}>Aktiivinen</p>      
                    <span style={{alignSelf:"flex-start", font:"normal normal normal 0.70em/0.75em Raleway", marginBottom: '0.5em' }}>{moment.utc(startDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}-{moment.utc(endDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span>                   
                </div>  
            </div>
        </div>
    )
}

const MegaTinyLoaderSpinner = () => {
    return (
        <Row style={{ marginBottom: '2em' }}>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '2rem', height: '2rem', marginTop: '0.5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}


const TotalScoreSymbol = ({score, count}) => {

    let color = ""
    let fontColor = "#404040"
    if(count > 0){
        if(score !== null){   
            if (score >= 75){color = "#b5e413"}
            else if(score >= 45 && score < 75){color ='#ffcc00'}
            else if (score < 45){color ='#ED5652'; fontColor = "#fff"}
        }
        else color ='#ED5652'
    } else {
        color ='#c8c8c8'
    }
    return(
        <div style={{display:"flex",alignItems:"center", flexDirection:"row", paddingLeft: "0.5em", marginBottom:'0.15em'}}>
            <label htmlFor="npsScore" className="lighter-text">Pisteet: </label>               
            <div className="smaller-score-ball" style={{ borderColor: color }}>{count > 0 ? score : "-"}</div> 
        </div>
    );
}

const checkStatus = status => {
    switch (status) {
        case 'Avoin':
            return true;
        case 'Ei huomioitu':
            return true;
        case 'inactive':
            return true;
        default:
            return false
    }
}

const getTaskStatus = status => {
    if (status === "Avoin" || status === "Ei huomioitu" || status === 'inactive') {
        return "Avoin";
    } else if (status === "declined") {
        return "Peruutettu";
    } else {
        return "Suoritettu";
    }
}

const getTaskColor = status => {
    if (status === "Avoin" || status === "Ei huomioitu" || status === 'inactive') {
        return "rgb(255, 204, 0)";
    } else if (status === "declined") {
        return "red";
    } else {
        return "#BDE533";
    }
}

const getSurveyTitles = (task, first) => {
    let taskTitles = "";
    if (task.taskTree !== undefined && Object.keys(task.taskTree).length > 0) {
        task.taskTree.surveyTitles.slice(first, task.taskTree.surveyTitles.length).filter(t => taskTitles = taskTitles + " " + t.title.toString())  
    } else if (task.planName) {
            taskTitles += task.planName;
        }
    return taskTitles;
}

export const ClientTaskCards = ({ task, openTask }) => {
    const { targetCompanyName, status, taskTitle, plainTextTask, deadline, modified } = task

    return (
        <div className="card task-card" onClick={() => openTask(task)}>
            <div className="card-header title-header task-card-title">
                <span className="lighter-text card-text" style={{ float: "right", marginLeft: "auto" }}>{moment.utc(modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span>
            </div>
            <div className="card-body" style={{ display: 'flex', flexDirection: 'row', paddingTop: 0, paddingBottom: 0 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    
                    <span className="tasksText"><p className="bigger-text overflower-short card-title card-title-client" title={taskTitle}>{taskTitle}</p></span>
                    <div>
                        {task && (task.taskTree && Object.keys(task.taskTree).length > 0) ? task.taskTree.surveyTitles.slice(0, 2).map((t, key) => {
                            return(<p className="overflower-short"  key={key} style={{ flexWrap: 'nowrap', fontSize: '0.8rem'}}>{t.title}</p>)                        
                        }):
                            task.planName && 
                            <p className="overflower-short" style={{ flexWrap: 'nowrap', fontSize: '0.8rem'}}>{task.planName}</p>
                        }
                        {task && (task.taskTree && Object.keys(task.taskTree).length > 0 && task.taskTree.surveyTitles.length > 2) ? 
                            <i className="material-icons" style={{ fontSize: '0.8rem' }} title={getSurveyTitles(task, 2)}>more_horiz</i>
                            :
                            <i></i>
                        }
                    </div>
                </div> 
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginRight: '0', marginLeft: 'auto' }}>
                    <span className="dot" style={{ backgroundColor: getTaskColor(status), marginBottom: '0.5em' }}></span>
                </div>
            </div>
            <div className=" custom-footer task-footer task-lighter-color">
                <span className="lighter-text">{checkStatus(status) ? deadlineChecker(deadline) : getTaskStatus(status)}</span>
            </div>
        </div>
    )
}

export const SurveyResultCards = ({ result, goToReport }) => {
    const { surveyName, color, avgPoints, created, code, answered } = result

    return (
        <div className="card survey-card" onClick={() => goToReport(result)}>
            <div className="card-header title-header task-card-title" style={{ borderRadius: '10px 10px 0 0' }}>
                <div className="graphic-circle-container">
                    <div className="smaller-score-ball" style={{ borderColor: color }}>{avgPoints}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className="card-text lighter-text" style={{ marginTop: '0.5rem' }}>{moment.utc(answered, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')}</p>
                    <p>{code}</p>
                </div>
            </div>
            <div className="card-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <p className="card-overflower-short" title={surveyName}>
                    {surveyName}
                </p>
               
            </div>
            <div className="card-footer custom-footer survey-lighter-color">
                <p className="card-text">{result.businessName }</p>
                {/* && result.businessName === "Ei kohdeyritystä") ? result.businessName */}
            </div>
        </div>
    )
}


export const ExcelFieldCard = ({ excel, created, i, click }) => {
    const { name } = excel

    return (
        <div className="card excel-card" key={i} onClick={click}>
            <div className="card-header title-header task-card-title">
            </div>
            <div className="card-body">
                <p>{name}</p>
            </div>
            <div className="card-footer custom-footer">
                <p className="card-text lighter-text" style={{ marginTop: '0.5rem' }}>{moment.utc(created, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY')}</p>
            </div>
        </div>
    )
}


export const HubSpotContactCard = ({ contact, createAsContact, exists }) => {
    const { firstName, lastName, company, phone, lifecyclestage, vid, type, value, timestamp, created, email } = contact

    return (
        <div className="card hub-contact-card">
            <div className="card-header title-header task-card-title">
                <span className="overflower-short" style={{height: '2em'}}>
                    {company}
                </span>
                <div onClick={() => exists? createAsContact(false) : createAsContact(contact)} title={exists? "Kontakteissa" :"Lisää kontaktiksi"}><i className="fas fa-user-plus" style={exists?{ color:'#6ba53f'} : { color:'#ebebeb'}}></i></div>
            </div>
            <div className="card-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <span className="tasksText"><h5 className="card-title overflower-short" title={firstName + " " + lastName}>{firstName + " " + lastName}</h5></span>
                <p className="card-text tasksText"><span className="overflower-short lighter-text" title={phone}>{phone}</span></p>
                <p className="card-text tasksText"><span className="card-overflower-short lighter-text" title={email}>{email}</span></p>
            </div>
            <div className="card-footer custom-footer text-muted">
                <span className="lighter-text bold">{lifecyclestage}</span>
            </div>
        </div>
    )
}

export const HubSpotDealCard = ({ deal, getStage, setSelectedDeal = false, selectedDeal = false }) => {
    const { dealname, amount, dealstage, owner, closedate, isClosed, lastmodified } = deal;

    return (
        <div className="card hub-deal-card" onClick={() => setSelectedDeal? setSelectedDeal(deal) : console.log("Prompted a click")} style={selectedDeal === deal? {boxShadow: '#ebebeb 1px 2px'}:{}}>
            <div className="card-header title-header task-card-title" style={{borderRadius: '10px 10px 0 0'}}>
                <span className="overflower-short bigger-text">
                    {getStage(deal)}
                </span>
                <span className="lighter-text">{lastmodified ? moment.utc(lastmodified).format("DD.MM.YYYY HH:mm") : ""}</span>
            </div>
            <div className="card-body" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <span className="tasksText"><h5 className="card-title overflower-short" title={dealname}>{dealname}</h5></span>
                {/* <p className="card-text tasksText"><span className="overflower-short lighter-text" title={owner}>{owner}</span></p> */}
                <p className="card-text tasksText"><span className="overflower-short lighter-text" title={amount} style={isClosed ? { color: 'green' } : {}}>{amount} €</span></p>
            </div>
            <div className="card-footer custom-footer text-muted">
                {!isClosed && <span className="lighter-text bold">Sulkeutumisajankohta {moment.utc(closedate).format("DD.MM.YYYY HH:mm")}</span>}
            </div>
        </div>
    )
}

export const RiskCard = ({risk, selectRisk }) => {
    const {riskClassification, businessName, color, planName, surveyName, code, status} = risk  
    return (
        <div className="card hub-deal-card" onClick={() => selectRisk(risk)} >
            <div className="card-header title-header task-card-title">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {planName && <p className="name-overflow-handler" style={{fontSize: '0.8rem', maxWidth: '12em'}} title={planName}>{planName}</p>}
                    {surveyName &&  <p className="name-overflow-handler" style={{fontSize: '0.8rem', maxWidth: '12em'}} title={surveyName}>{surveyName}</p>}
                    {code && <p className="name-overflow-handler" style={{fontSize: '0.8rem', maxWidth: '12em'}} title={code}>{code}</p>}
                </div>
            </div>
            <div className="card-body" style={{display:"flex", alignItems:"center", flexDirection:"row", flex:"3 1", paddingTop: '0.5em', paddingBottom: '0.5em' }}>
               <div>                                                                                    
                   <div style={{display: 'flex', flex: '3 1 0px', flexDirection: 'column' }}>
                        {risk.riskName && <p className="bigger-text">{risk.riskName}</p>}
                        <p className={"name-overflow-handler " + (risk.riskName? "card-text" : "bigger-text")} title={businessName? businessName : "Ei kohdeyritystä"}>{businessName? businessName : "Ei kohdeyritystä"}</p>
                        <p className="card-text">{riskClassification.label}</p>      
                        {/* <p className="card-text" style={{fontWeight: 'bold'}}>{status}</p>              */}
                   </div>                                                                                                                                                 
                </div> 
                <div style={{display:"flex", flexDirection:"column", marginLeft:"auto", marginTop:"0",justifyContent:"center", alignItems:"flex-start" }}>
                    <div style={{ display: 'flex', alignSelf :"center", alignItems:"flex-start", flex: "1 1 0px", flexDirection: 'column'}}>                   
                        <span className="smaller-score-ball" style={{ borderColor: color, marginBottom: '0.5em' }}><p style={{}}>{risk.riskScore}</p></span>
                    </div>
                </div>
            </div>         
           
            <div className="card-footer custom-footer text-muted risk-lighter-color">
                <p style={{ font:"normal normal normal 0.7em/0.85em Raleway" }}>Luotu: {moment.utc(new Date(risk.created), "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</p>
            </div>
        </div>
    )
}
import {confs} from '../../../../../../../../modules/config';
import {NotificationManager} from 'react-notifications';
import * as ActionCreator from '../../../../../../../../store/index';
import {GET_AVAILABLE_GROUPS} from '../../../../../../../../store/actions/Kartoitus/QuestionGroup/ActionTypes';
const STORE_GROUP = "STORE_GROUP";
const STORE_CATEGORY = "STORE_CATEGORY";


const storeGroup = data => {
    return {
        type: STORE_GROUP,
        payload: data
    }
}

const storeCategory = data => {
    return {
        type: STORE_CATEGORY,
        payload: data
    }
}

const storeAvailableGroups = payload => {
    return {
        type: GET_AVAILABLE_GROUPS,
        payload: payload
    }
}

export const getAvailableGroups = params => dispatch => {
    let payload = {
        ...params,
        jwtToken:  window.sessionStorage.getItem("token")
    }

    dispatch(ActionCreator.loadingStatus(true))
    fetch(confs.url + 'get/targetGroups', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then(response => response.json())
    .then(responseJson => {
        if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
            dispatch(ActionCreator.authFailed())
        } else {
            if(responseJson.success === true) {
                dispatch(storeAvailableGroups(responseJson));
                dispatch(ActionCreator.loadingStatus(false))
            } else {
                dispatch(storeAvailableGroups(responseJson));
                dispatch(ActionCreator.loadingStatus(false))
            }
        }  
    }).catch(error => {
        NotificationManager.error("Palvelussa tapahtui virhe", "", 3000);
        dispatch(ActionCreator.loadingStatus(false))
    })
}

export const copyGroup = params => dispatch => {
    let payload = {
        ...params,
        jwtToken:  window.sessionStorage.getItem("token")
    }

    dispatch(ActionCreator.loadingStatus(true))
    fetch(confs.url + 'modify/copyGroup', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then(response => response.json())
    .then(responseJson => {
        if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
            dispatch(ActionCreator.authFailed())
        } else {
            if(responseJson.success === true) {
                NotificationManager.success("Muutokset tallennettu", "", 3000);
                dispatch(storeCategory(responseJson.survey));
                dispatch(ActionCreator.loadingStatus(false))
            } else {
                NotificationManager.error("Kopiointi epäonnistui", "", 3000);
                dispatch(ActionCreator.loadingStatus(false))
            }
        }       
    }).catch(error => {
        NotificationManager.error("Palvelussa tapahtui virhe", "", 3000);
        dispatch(ActionCreator.loadingStatus(false))
    })
}
export const modifyGroup = params => dispatch => {

    let payload = {
        ...params,
        jwtToken:  window.sessionStorage.getItem("token")
    }
    dispatch(ActionCreator.loadingStatus(true))

    fetch(confs.url + 'modify/group', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then(response => response.json())
    .then(responseJson => {
        if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
            dispatch(ActionCreator.authFailed())
        } else {
            if(responseJson.status === 'ok') {
                NotificationManager.success("Muutokset tallennettu", "", 3000);
                dispatch(storeGroup(responseJson.targetGroup));
                dispatch(storeCategory(responseJson.category));
                dispatch(ActionCreator.loadingStatus(false))
            } else {
                NotificationManager.error("Tallennus epäonnistui", "", 3000);
                dispatch(ActionCreator.loadingStatus(false))
            }
        }    
    }).catch(error => {
        NotificationManager.error("Tallennus epäonnistui", "", 3000);
        dispatch(ActionCreator.loadingStatus(false))
    })
}

export const deleteFeedbackRange = params => dispatch => {
    let payload = {
        ...params,
        jwtToken:  window.sessionStorage.getItem("token")
    }
    dispatch(ActionCreator.loadingStatus(true))

    fetch(confs.url + 'modify/deleteRange', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then(response => response.json())
    .then(responseJson => {
        if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
            dispatch(ActionCreator.authFailed())
        } else {
            if(responseJson.status === 'ok') {
                NotificationManager.success("Muutokset tallennettu", "", 3000);
                dispatch(storeGroup(responseJson.targetGroup));
                dispatch(storeCategory(responseJson.category));
                dispatch(ActionCreator.loadingStatus(false))
            } else {
                NotificationManager.error("Tallennus epäonnistui", "", 3000);
                dispatch(ActionCreator.loadingStatus(false))
            }
        }  
    }).catch(error => {
        NotificationManager.error("Tallennus epäonnistui", "", 3000);
        dispatch(ActionCreator.loadingStatus(false))
    })
}

export const modifySentSurveyGroup = params => dispatch => {

    let payload = {
        ...params,
        jwtToken:  window.sessionStorage.getItem("token")
    }
    dispatch(ActionCreator.loadingStatus(true))

    fetch(confs.url + 'modify/sentSurveyGroup', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then(response => response.json())
    .then(responseJson => {
        if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
            dispatch(ActionCreator.authFailed())
        } else {
            if(responseJson.status === 'ok') {
                NotificationManager.success("Muutokset tallennettu", "", 3000);
                dispatch(storeGroup(responseJson.targetGroup));
                dispatch(storeCategory(responseJson.category));
                dispatch(ActionCreator.loadingStatus(false))
            } else {
                NotificationManager.error("Tallennus epäonnistui", "", 3000);
                dispatch(ActionCreator.loadingStatus(false))
            }
        }    
    }).catch(error => {
        NotificationManager.error("Tallennus epäonnistui", "", 3000);
        dispatch(ActionCreator.loadingStatus(false))
    })
} 
import React from 'react';
import { Row, Col, DropdownMenu, DropdownItem, Dropdown, DropdownToggle } from 'reactstrap'
import { confs } from '../../../modules/config';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars'

const BusinessSurveyListItem = (props) => {


   let sentSurvey = props.sentSurvey;
   let startDate
   let endDate
   const today = moment.utc().local()
   let titles = ""

   if (!sentSurvey.dates) {
      startDate = moment(new Date(sentSurvey.startDate)).format('DD.MM.YYYY')
      endDate = moment(new Date(sentSurvey.endDate)).format('DD.MM.YYYY')
   } else {
      startDate = moment(new Date(sentSurvey.dates[0].startDate)).format('DD.MM.YYYY')
      endDate = moment(new Date(sentSurvey.dates[0].endDate)).format('DD.MM.YYYY')
   }

   sentSurvey.dates && sentSurvey.dates.map((date) => {
      const dateS = moment.utc(date.startDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
      const dateE = moment.utc(date.endDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
      titles = titles + moment(new Date(date.startDate)).format('DD.MM.YYYY') + " - " + moment(new Date(date.endDate)).format('DD.MM.YYYY') + "\n"

      if (today.isBetween(dateS, dateE)) {
         startDate = moment(new Date(date.startDate)).format('DD.MM.YYYY')
         endDate = moment(new Date(date.endDate)).format('DD.MM.YYYY')
      }
   })

   let color = sentSurvey.surveyAvgColor || '#ED5652'
   let avg = sentSurvey.surveyAvgScore || 0

   return (

      <Row className="dashboard-survey-row sent-survey-row" onClick={() => props.selectReport(sentSurvey._id)}>
         <Col xs={{ size: 2 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="smaller-score-ball" style={avg.length > 3 ? { borderColor: color, fontSize: '0.9rem' } : { borderColor: color }}>{avg}</div>
         </Col>
         <Col xs={{ size: 4 }}>
            <Row>
               <Col xs={{ size: 12 }}>
                  <p className="big-text mb-0 one-line-text" title={sentSurvey.name}>{sentSurvey.name}</p>
               </Col>
            </Row>
            <Row>
               <Col xs={{ size: 12 }}>
                  <p className="lighter-text">{`${startDate} - ${endDate}`} {sentSurvey.dates && sentSurvey.dates.length > 1 ? <i className="far fa-calendar" style={{ paddingLeft: '5px' }} title={titles}></i> : ""}</p>
                  <button style={{ marginTop: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem' }} className="small-white-button lighter-text">Tarkastele</button>
               </Col>
            </Row>
         </Col>
         <Col xs={{ size: 2 }} style={{ textAlign: 'center' }} className="content-text">
            <p>{sentSurvey.enterpriseName}</p>
         </Col>
         <Col xs={{ size: 2 }} style={{ textAlign: 'center' }} className="content-text">
            <Row>
               <Col xs={{ size: 12 }}>
                  <p>{sentSurvey.code}</p>
               </Col>
               <Col xs={{ size: 12 }}>
                  <p><b>{sentSurvey.isActive ? 'Aktiivinen' : 'Päättynyt'}</b></p>
               </Col>
            </Row>
         </Col>
         <Col xs={{ size: 1 }} style={{ textAlign: 'center' }}>
            <Row>
               <Col xs={{ size: 12 }}>
                  <p>{sentSurvey.answerCount}</p>
               </Col>
            </Row>
            <Row>
               <Col xs={{ size: 12 }}>
                  <p className="lighter-text">Vastausta</p>
               </Col>
            </Row>
         </Col>
         <Col xs={{ size: 1 }} style={{ textAlign: 'center' }}>
            <Dropdown isOpen={props.menuIndex === props.index} onClick={(event) => event.stopPropagation()} toggle={() => props.toggleMenu(props.index)} style={{display: 'flex', justifyContent: 'center'}}>
               <DropdownToggle tag="div" style={{  float: 'right', fontSize: '1rem' }}>
                  <i className="fas fa-bars" title="Valikko"></i>
               </DropdownToggle>
               <DropdownMenu>
                  {sentSurvey.isActive && <DropdownItem><a href={`${confs.frontpageUrl}/${sentSurvey.code}`} target="_blank" rel="noopener noreferrer">Avaa kartoitus</a></DropdownItem>}
               </DropdownMenu>
            </Dropdown>
         </Col>
      </Row>


   )

}


export default BusinessSurveyListItem;
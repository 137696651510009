import React from 'react';
import {Row, Col} from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import BarGraph from '../../../../Utilities/DiagramBuilder/BarGraph';
import {confs} from '../../../../modules/config';

const MegaLoaderSpinner = () => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

const ExcelItem = ({itemId, businessId}) => {
    const [excel, setExcel] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [chartLegend, setChartLegend] = React.useState(false);


    React.useEffect(() => {
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            businessId: businessId,
            id: itemId,
            type: 'excel'
        }

        fetchData(payload);
        
    }, [itemId]);

    const fetchData = payload => {
        return new Promise(async (resolve) => {
            setIsLoading(true);
            const resultJson = await fetch(confs.url + 'plan/embedItemData', {
                method : 'POST',
                body: JSON.stringify( payload )
            })

            const response = await resultJson.json()

            setExcel(response);
            setIsLoading(false);

            resolve();
        })
    }
    return (
        isLoading ? 
            <MegaLoaderSpinner /> 
        :
            <div className="dashboard-medium-box" style={{marginTop: 0, height: '100%'}}>
                <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                    <p>{excel.name}</p>
                    <i className="far fa-chart-bar" onClick={() => setChartLegend(!chartLegend)} style={{ marginRight: '1em', marginLeft: 'auto', cursor: 'pointer' }}></i>
                </div>
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height: '400px', zIndex: 90 }}
                    className="custom-scrollbars"
                >
                    <BarGraph data={excel.data} barNames={excel.barNames} title={excel.title} i={itemId} toggledLegend={chartLegend} yTitle={excel.yTitle} xTitle={excel.xTitle} />
                </Scrollbars>
            </div>
    )
}

export default ExcelItem;
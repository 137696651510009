import * as ActionTypes from './ActionTypes';

export const setGroup = groupData => {
    let group = {
        ...groupData
    }
    return {
        type: ActionTypes.SET_GROUP,
        group: group
    };
}

export const updateField = data => {
    return {
        type: ActionTypes.UPDATE_FIELD,
        data: data 
    }
}

export const addNewQuestionOption = () => {
    return {
        type: ActionTypes.ADD_NEW_QUESTION_OPTION
    }
}
export const updateQuestionOption = data => {
    return {
        type: ActionTypes.UPDATE_QUESTION_FIELD,
        payload: data
    }
}

export const newQuestion = data => {
    return {
        type: ActionTypes.NEW_QUESTION
    }
}

//Update color of questiongroup feedback depending on the index
export const updateColor = (color, index) => {
    let payload = {
        color: color,
        index: index
    }
    return {
        type: ActionTypes.SET_COLOR,
        data: payload
    }
}

export const setQuestion = payload => {
    let data = {
        ...payload
    }

    return {
        type: ActionTypes.SET_QUESTION,
        data: data
    }
}
import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import QuestionOptions from './QuestionOptions';
import '../../../kartoitus.css';
import Analyzer from './Analyzer';
const QuestionTabs = props => {
    return (
        <div className="questionTabs">
            <Nav tabs className="tabSelectors">
            <NavItem className= "questionTabItem">
                <NavLink
                className={props.activeTab === '1' ? "questionTab selected"  : "questionTab"}
                onClick={() => { props.toggle('1'); }}
                >
                    Vastausvaihtoehdot
                </NavLink>
            </NavItem>
            <NavItem className="questionTabItem">
                <NavLink
                className={props.activeTab === '2' ? "questionTab selected"  : "questionTab"}
                onClick={() => { props.toggle('2'); }}
                >
                    Analyysi
                </NavLink>
            </NavItem>
            </Nav>
            <TabContent activeTab={props.activeTab}>
            <TabPane tabId="1">
                <Row>
                    <Col sm="12">
                        <QuestionOptions 
                        {...props}
                            />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId="2">
                <Row>
                    <Col sm="12">
                        <Analyzer
                        {...props} 
                        />
                    </Col>
                </Row>
            </TabPane>
            </TabContent>
        </div>
    )
}

export default QuestionTabs;
export const GET_SURVEYS = 'GET_SURVEYS';
export const REORDER_SURVEYS = 'REORDER_SURVEYS';
export const GET_STATISTICS = 'GET_STATISTICS';
export const FETCH_ANON = "FETCH_ANON";
export const CLEAR_STATISTICS = "CLEAR_STATISTICS";
export const ADD_REMOVE_REPORT = "ADD_REMOVE_REPORT";
export const FETCH_NOTES = "FETCH_NOTES";
export const EDIT_NOTE = "EDIT_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const SET_COMPARISON_REPORT = "SET_COMPARISON_REPORT";
export const CLEAR_COMPARISON_REPORT = "CLEAR_COMPARISON_REPORT";
export const SET_COMPARISON_NAMES = "SET_COMPARISON_NAMES";
export const SET_EMPLOYEES_FOR_MEMO = "SET_EMPLOYEES_FOR_MEMO";
export const NO_PERMISSION = "NO_PERMISSION";
export const GET_REPORT = "GET_REPORT";
export const REPLACE_MODIFIED_TASK = "REPLACE_MODIFIED_TASK";
export const REMOVE_FINISHED_TASK = "REMOVE_FINISHED_TASK";
export const FETCH_STATUS = "FETCH_STATUS";
export const SET_COMPANY_TASKS = "SET_COMPANY_TASKS";
export const SET_RISK_EVALUATIONS = "SET_RISK_EVALUATIONS";
export const SET_COMPANY_SCORE = "SET_COMPANY_SCORE";
export const SET_ACTIVE_BUSINESS_LIST = "SET_ACTIVE_BUSINESS_LIST";
import { confs } from '../../../modules/config'
import * as ActionTypes from './ActionTypes'
import { NotificationManager } from 'react-notifications'


export const getExcelHeaders = payload => dispatch => {
   let jwt = window.sessionStorage.getItem("token")
   payload.jwtToken = jwt

   return new Promise(async (resolve) => {
      const resultJson = await fetch(confs.url + 'excel/getHeaders', {
         method: 'POST',
         body: JSON.stringify(payload)
      })

      const result = await resultJson.json()

      if(result.data){
         resolve(result.data)
      }else{
         resolve()
      }
   })
}


export const getExcelRows = payload => dispatch => {
   let jwt = window.sessionStorage.getItem("token")
   payload.jwtToken = jwt

   return new Promise(async (resolve) => {
      const resultJson = await fetch(confs.url + 'excel/getRows', {
         method: 'POST',
         body: JSON.stringify(payload)
      })

      const result = await resultJson.json()

      if(result){
         resolve(result)
      }else{
         resolve()
      }
   })
}


export const getFiles = payload => dispatch => {
   let jwt = window.sessionStorage.getItem("token")
   payload.jwtToken = jwt

   return new Promise(async (resolve) => {
      const resultJson = await fetch(confs.url + 'excel/getFiles', {
         method: 'POST',
         body: JSON.stringify(payload)
      })

      const result = await resultJson.json()

      if(result.data){
         resolve(result.data)
      }else{
         resolve()
      }
   })
}


export const createReport = payload => dispatch => {
   let jwt = window.sessionStorage.getItem("token")
   payload.jwtToken = jwt

   return new Promise(async (resolve) => {
      const resultJson = await fetch(confs.url + 'excel/createChart', {
         method: 'POST',
         body: JSON.stringify(payload)
      })

      const result = await resultJson.json()

      if(result){
         resolve(result)
      }else{
         resolve()
      }
   })
}


export const uploadFile = data => dispatch => {
   return new Promise(async (resolve) => {
      const resultJson = await fetch(confs.url + 'excel/file/getHeaders', {
         method: 'POST',
         body: data
      }) 
      const result = await resultJson.json();
      if(result.data){
         resolve(result.data)
      }else{
         resolve();
      }
   })
}


export const getRowsFromFile = data => dispatch => {
   return new Promise(async (resolve) => {
      const resultJson = await fetch(confs.url + 'excel/file/getRows', {
         method: 'POST',
         body: data
      }) 
      
      const result = await resultJson.json();
      
      if(result){
         resolve(result)
      }else{
         resolve();
      }
   })
}


export const getExcelReport = payload => dispatch => {
   let jwt = window.sessionStorage.getItem( "token" )
   payload.jwtToken = jwt
   
   return new Promise( async ( resolve ) => {
       const resultJson = await fetch( confs.url + 'excel/getExcelRerport', {
           method: 'POST',
           body: JSON.stringify( payload )
       } )
       const result = await resultJson.json()

       if(result.success === true){
           
           dispatch(setBusinessExcels(result.list))
       }
       resolve( result )
   } )
}


export const setBusinessExcels = payload => {
   return {
       type: ActionTypes.SET_BUSINESS_EXCELS,
       payload: payload
   }
}


export const deleteFromList = payload => {
   return {
      type: ActionTypes.DELETE_CHART,
      payload: payload
   }
}


export const deleteExcelReport = payload => dispatch => {
   let jwt = window.sessionStorage.getItem( "token" )
   payload.jwtToken = jwt

   return new Promise( async ( resolve ) => {
      const resultJson = await fetch( confs.url + 'excel/deleteExcelReport', {
          method: 'POST',
          body: JSON.stringify( payload )
      } )
      const result = await resultJson.json()

      if(result.deleted === true){
         NotificationManager.success('Diagrammi poistettu', '', 3000)
         dispatch(deleteFromList(result.id))
      }
      
      resolve( result )
  } )
}


export const deleteFile = payload => dispatch => {
   let jwt = window.sessionStorage.getItem( "token" )
   payload.jwtToken = jwt

   return new Promise( async ( resolve ) => {
      const resultJson = await fetch( confs.url + 'excel/deleteFile', {
          method: 'POST',
          body: JSON.stringify( payload )
      } )
      const result = await resultJson.json()

      if(result.deleted === true){
         NotificationManager.success('Tiedosto poistettu onnistuneesti', '', 2000)
         resolve(result.id)
      }else{
         NotificationManager.error('Tiedoston poistaminen epäonnistui', '', 2000)
      }
      resolve()
  } )
}
import React from 'react';
import {Row, Col} from 'reactstrap';
import ComparisonBar from './ComparisonBar';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import FeedbackReport from './FeedbackReport';
import ViewChanger from './modules/ViewChanger';

const BarReport = props => {
    return (
        <Row className="report">
            <Col style={{height: '100%', overflow: 'auto', padding: '0 2rem'}}>
                {props.sentSurveys.map((survey, ssIndex) => {                
                    if (survey.type === 'feedback') {
                        return <FeedbackReport key={ssIndex} survey={survey} toggleModal={props.toggleIconUrlListModal} />
                    }
                    const surveyColor = survey.surveyColor;
                    

                    return typeof survey.surveyTotalStatistics !== 'undefined' && 
                        <div key={ssIndex}>
                            <Row className="survey-buttonfilters-row">
                                <ViewChanger {...props}/>
                            </Row>
                            <Row className="survey-main-row">
                                <div className="ball-container">
                                <div className="large-score-ball" style={{backgroundColor: surveyColor, boxShadow: "0 0 0 2pt " + surveyColor}}>
                                    {survey.surveyTotalStatistics.avg}
                                </div>
                                </div>
                                <Col md={{size: 11}} className="survey-title-container super-border-left" style={{borderColor: surveyColor}}>
                                    <Row className="survey-title-row">
                                        <Col md={{size: 12}}>
                                            <h2>{survey.title}</h2>
                                            <hr />
                                            {/* <p>{survey.text}</p> */}
                                            <TextEditor editorId={survey._id} content={survey.text} onTextChange={() => {return}} readOnly={true} />
                                        </Col>
                                    </Row>
                                    {survey.questionGroups.map((group, gIndex) => {
                                                    
                                        const groupColor = group.groupColor;
                                        return typeof group.groupTotalStatistics !== 'undefined' && 
                                        <Row className="questiongroup-main-row" key={gIndex}>
                                            <div className="ball-container">
                                                <div className="medium-score-barball" style={{backgroundColor: groupColor, boxShadow: "0 0 0 2pt " + groupColor}}>{group.groupTotalStatistics.avg}</div>
                                            </div>
                                            <Col md={{size: 11}} className={gIndex < (survey.questionGroups.length - 1) ? "questiongroup-title-container-comparison super-border-left" : "questiongroup-title-container-comparison"} style={{borderColor: groupColor}}>
                                                <Row className="questiongroup-title-row" style={{marginBottom: '3.5em'}}>
                                                    <Col md={{size: 12}}>
                                                        <h2>{group.title}</h2>
                                                        <ComparisonBar 
                                                        feedbacks={group.feedbacks} 
                                                        avg={group.groupTotalStatistics.avg} 
                                                        scores={group.groupTotalStatistics.scores} 
                                                        ballColor={groupColor}/>
                                                    </Col>
                                                </Row>
                                            </Col> 
                                        </Row>
                                    })}
                                </Col>
                            </Row>
                        </div>
                })}
            </Col>
        </Row>
    )
}

export default BarReport;
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars'

const IconUrlModal = props => {
    const urls = props.urls
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggleModal} centered={true} size="xl">
            <ModalHeader toggle={props.toggle}>
                Vastauksen url- osoitteet
            </ModalHeader>
            <ModalBody>
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height: '500px', paddingBottom: '1rem' }}
                    className="custom-scrollbars" >

                    {urls.length > 0 && urls.map((url, index) => {
                        let className = index % 2 === 0 ? 'even-row' : 'odd-row'
                        return (
                            <Row key={index} className={className} style={{ padding: '1rem' }}>
                                <Col xs={{ size: 2 }}>
                                    {url.count} kpl
                                </Col>
                                <Col xs={{ size: 8 }}>
                                    {url.name}
                                </Col>
                            </Row>
                        )
                    })}

                </Scrollbars>
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => props.toggleModal()}>Sulje</a>
            </ModalFooter>
        </Modal>
    )
}

export default IconUrlModal;
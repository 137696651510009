import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import TextEditor from '../../../Utilities/TextEditor/texteditor';



class FeedbackReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const survey = this.props.survey
        return (
            <div>
                {typeof survey.surveyTotalStatistics !== 'undefined' &&
                    <div>
                        <Row className="survey-buttonfilters-row">
                            {survey.urlList &&
                                <Col xl={{ size: 4 }} xs={{ size: 12 }}>
                                    <button className="report-button-filter" onClick={() => this.props.toggleModal(survey.urlList)}>Näytä kaikki url- osoitteet</button>
                                </Col>}
                        </Row>
                        {survey &&
                            <Row className="survey-main-row">
                                <div className="ball-container">
                                    <div className="large-score-ball" style={{ backgroundColor: survey.surveyColor, boxShadow: "0 0 0 2pt " + survey.surveyColor }}>
                                        {survey.surveyTotalStatistics.avg}
                                    </div>
                                </div>
                                <Col md={{ size: 11 }} className="survey-title-container" style={{ borderColor: survey.surveyColor }}>
                                    <Row className="survey-title-row">
                                        <Col md={{ size: 12 }}>
                                            <Row>
                                                <Col xs={{ size: 12 }}>
                                                    <h2 style={{ marginBottom: 0 }}>
                                                        {survey.title}
                                                    </h2>
                                                </Col>
                                            </Row>
                                            <hr style={{ clear: 'both' }} />
                                            <TextEditor editorId={survey.sentSurveyId} content={survey.text} onTextChange={() => { return }} readOnly={true} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        {survey.iconListStatistics && survey.iconListStatistics.map((icon, index) => {
                                            return (
                                                <Col key={index}>
                                                    <div className="report-icon-box" onClick={() => icon.urlList && this.props.toggleModal(icon.urlList)}>
                                                        <i style={{ fontSize: '4em', color: icon.color }} className={icon.name}></i>
                                                        <h5 style={{ fontWeight: 600, marginTop: '0.5em' }}>{icon.count || 0} Kpl </h5>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                    <Row style={{ marginTop: '1em' }}>
                                        <Col>
                                            <h5>Palaute</h5>
                                            {survey.iconFeedbacks && survey.iconFeedbacks.length > 0 && survey.iconFeedbacks.map((feedback, index) => {
                                                if (survey.surveyTotalStatistics.avg >= feedback.range[0] && survey.surveyTotalStatistics.avg <= feedback.range[1]) {
                                                    return <TextEditor key={index} editorId={survey.sentSurveyId + '-feedback-' + index} content={feedback.text} onTextChange={() => { return }} readOnly={true} />
                                                }
                                                return null
                                            })}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default FeedbackReport;
import * as ActionTypes from '../../Components/Business/actions/ActionTypes'

const initialState = {
  business: null,
  users: []
}

const reducer = ( state = initialState, action ) => {
  switch(action.type) {
    case ActionTypes.GET_BUSINESS:
      return {
        ...state,
        business: action.payload
      }
    case ActionTypes.GET_BUSINESS_USERS:
      return {
        ...state,
        users: action.payload
      }
      case ActionTypes.SET_HUBSPOT_SETTINGS: {
        return {
          ...state,
          business: {
            ...state.business,
            hubSpot: {
              ...action.payload
            }
          }
        }
      }
    case 'CLEAR_BUSINESS_INFO': {
      return {
        ...state,
        ...initialState
      }
    }

    case ActionTypes.HUBSPOT_CLOSED_VALUE: {
      return {
        ...state,
        business: {
          ...state.business,
          hubSpot: {
            ...state.business.hubSpot,
            closedValue: action.payload
          }
        }
      }
    }
    case 'HANDLE_YTJ': {
      return {
        ...state,
        business: {
          ...state.business,
          ...action.payload
        }
      }
    }
    default:
      return state
  }
}

export default reducer
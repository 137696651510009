import React, { Component } from 'react'

import { Row, Col, Container } from 'reactstrap'
import { connect } from 'react-redux'
import * as ActionCreator from '../../store/index'
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'

import Users from './subComponents/Users'
import BusinessEdit from './subComponents/BusinessEdit'

class BusinessContainer extends Component {
    componentDidMount() {
        this.props.setBreadcrumbs({to: 'tiedot', label: `${this.props.businessName} / tiedot`})
        this.props.getBusiness()
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col xl={{ size: 5 }} xs={{ size: 12 }}>
                        {this.props.users ?
                            <Users users={ this.props.users } save={ this.props.onUserEdit } />
                            :
                            <EmptyBox title="Käyttäjät" />
                        }
                    </Col>
                    <Col xl={{ size: 7 }} xs={{ size: 12 }}>
                        { this.props.business ?
                            <BusinessEdit business={ this.props.business } edit={ this.props.onBusinessEdit } uploadLogo={ this.props.uploadLogo } deleteLogo={ this.props.deleteLogo } />
                            :
                            <EmptyBox title="Yrityksen tiedot" />
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

const EmptyBox = (props) => {
    return (
        <Row>
            <Col md={{ size: 12 }} xl={{ size: 12 }} >
                <div className="company-box">
                    <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                        <p>{ props.title }</p>
                    </div>
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '500px', marginTop: '0.5rem' }} >
                        <MegaLoaderSpinner />
                    </Scrollbars>

                </div>
            </Col>
        </Row>
    )
}

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const mapStateToProps = state => {
    return {
        business: state.business.business,
        businessName: state.authentication.businessName,
        users: state.business.users,
        logo: state.authentication.logo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBusiness: (params) => dispatch(ActionCreator.getBusiness(params)),
        onBusinessEdit: (params) => dispatch(ActionCreator.editBusiness(params)),
        onUserEdit: (params) => dispatch(ActionCreator.editBusinessUser(params)),
        setBreadcrumbs: (params) => dispatch(ActionCreator.setBreadcrumbs(params)),
        addMoreBreadcrumbs: (params) => dispatch(ActionCreator.addMoreBreadcrumbs(params)),
        uploadLogo: (params) => dispatch(ActionCreator.uploadBusinessLogo(params)),
        deleteLogo: () => dispatch(ActionCreator.deleteBusinessLogo())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessContainer))



import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';

function ReportBase({reportBases, handleReportBases, index, sentSurveyId}) {
    const [selectedBase, setSelectedBase] = useState(reportBases.filter(x => x.value === sentSurveyId)[0])
    const [baseSelected, changeStatus] = useState(false);
    useEffect(() => {
        changeStatus(true);
        handleReportBases(selectedBase, index, baseSelected)
    }, [selectedBase])

    return (
        reportBases.filter(x => x.value !== sentSurveyId).length > 0
        ? <div>
            <Select value={selectedBase.value !== null ? selectedBase : null}
                onChange={item => setSelectedBase(item)}
                options={reportBases.filter(x => x.value !== sentSurveyId)}
                placeholder="Valitse raporttipohja"
                isDisabled={false}
                noOptionsMessage={() => {return "Tuloksia ei löytynyt"}}
                className="reportBaseList"
            />
        </div>
        : null
    )
}

export default ReportBase;
import React, {useState, useEffect} from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { SteppedLineTo } from 'react-lineto';
import LinkTreeItem from './LinkTreeItem';
import CategoryModal from './Modals/CategoryModal';
import QuestionGroupModal from './Modals/QuestionGroupModal';
import QuestionModal from './Modals/QuestionModal';
import QuestionOptionModal from './Modals/QuestionOptionModal';

const LinkTreeModalHandler = ({incomingCategory, modalOpen, toggle}) => {
    const [currentViewData, setCurrentViewData] = useState({...incomingCategory, type: 'category'});
    const [isOpen, toggleModal] = useState(modalOpen);
    const [previousViewData, setPreviousViewData] = useState('category');

    useEffect(() => {
        toggleModal(modalOpen)
        setCurrentViewData({...incomingCategory, type: 'category'});
    }, [modalOpen])
    
    const changeViewData = data => {
        setPreviousViewData(currentViewData);
        setCurrentViewData(data);
    }

    const toggleData = () => {
        if(currentViewData.type === 'group') {
            setPreviousViewData(currentViewData);
            setCurrentViewData({...incomingCategory, type: 'category'});
        } else {
            setCurrentViewData(previousViewData)
        }
    }

    if(currentViewData.type === 'category') {
        return <CategoryModal setcurrentViewData={changeViewData} currentViewData={currentViewData} isOpen={isOpen} toggle={() => toggle()}/>
    }
    
    if(currentViewData.type === 'group') {
        return <QuestionGroupModal setcurrentViewData={changeViewData} currentViewData={currentViewData} isOpen={isOpen} toggle={toggleData} close={toggle}/>
    }

    if(currentViewData.type === 'question') {
        return <QuestionModal setcurrentViewData={changeViewData} currentViewData={currentViewData} isOpen={isOpen} toggle={toggleData} close={toggle}/>
    }
    
    return null
}

export default LinkTreeModalHandler;
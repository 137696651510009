import React from 'react';
import { Col } from 'reactstrap'
import moment from 'moment';

const TaskItem = props => {
    return (
        <Col xl="12" className="task-risk-row" onClick={() => props.modifyTask({...props.taskItem, topicContent: props.topicContent})}>
            <div style={{display: 'flex'}}>
                <div>
                    <i className="fas fa-tasks plan-task-icon" style={{border: '1px solid #404040', padding: 10, borderRadius: '50%'}}></i>
                </div>
                <div>
                    <p style={{alignSelf: 'center', paddingLeft: '2em'}}>{props.text}<br /><b>{moment( new Date( props.deadline ) ).format( 'DD.MM.YYYY' )} {props.taskItem.notificationList.length > 0 && `- ${props.taskItem.notificationList[0].label}`}</b></p>
                </div>
            </div>
        </Col>
    )
}

export default TaskItem;
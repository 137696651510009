import React, { Component } from 'react'
import { DateRangePicker } from 'react-dates'
import Select from 'react-select'
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input,
    FormGroup
} from 'reactstrap'

const reportTypeList = [
    {
        value: 'main',
        label: 'Teksti'
    },
    {
        value: 'barQuestions',
        label: 'Kysymys'
    },
    {
        value: 'graphics',
        label: 'Graafinen'
    },
    {
        value: 'bar',
        label: 'Ryhmä'
    }
]
class ReportTypeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'pdf',
            params: {
                email: '',
                title: '',
                simplified: false
            }
        };
    }

    handleInput = (e, field) => {
        let params = this.state.params
        params[field] = e.target.value
        this.setState({ params: params })
    }

    buttonCheck = () => {
        const emailCheck = /[^@]+@[^\.]+\..+/

        if (this.state.type === 'email') {
            let email = this.state.params.email

            if (emailCheck.test(email) && this.state.params.title !== '') {
                return false
            } else {
                return true
            }
        }
        return false
    }

    setSimplifiedButton = () => {
        let { params } = this.state

        params.simplified = !this.state.params.simplified
        this.setState({ params })
    }

    handleSave = () => {
        let { params } = this.state

        if(this.state.type === 'email'){
            let list = params.email.split(',')
            params['emailList'] = list
        }

        this.props.printReport(this.state.type, params)
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggle}>Valitse raportin muoto</ModalHeader>
                    <ModalBody>
                        <Row style={{ marginBottom: '1em' }}>
                            <Col xl="12">
                                <button className={this.state.type === 'pdf' ? "small-white-button lighter-text wide-button selected" : "small-white-button lighter-text wide-button"} style={{ width: '100%' }} onClick={() => this.setState({ type: 'pdf' })}>PDF (.pdf)</button>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1em' }}>
                            <Col xl="12">
                                <button className={this.state.type === 'email' ? "small-white-button lighter-text wide-button selected" : "small-white-button lighter-text wide-button"} style={{ width: '100%' }} onClick={() => this.setState({ type: 'email' })}>Sähköposti</button>
                            </Col>
                            {this.state.type === 'email' &&
                                <>
                                    <Col xl="12" style={{ marginTop: '1em' }}>
                                        <FormGroup check>
                                            <Label check style={{cursor: 'pointer'}}>
                                                <Input type="checkbox" checked={this.state.params.simplified} onClick={this.setSimplifiedButton} /> Vain tekstipalaute
                                        </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" style={{ marginTop: '.5em' }}>
                                        <Label className="lighter-text">Sähköposti (voit erotella sähköpostit pilkulla)</Label>
                                        <Input onChange={(e) => this.handleInput(e, 'email')} value={this.state.params.email}></Input>
                                    </Col>
                                    <Col xl="12" style={{ marginTop: '.5em', marginBottom: '.5em' }}>
                                        <Label className="lighter-text">Otsikko</Label>
                                        <Input onChange={(e) => this.handleInput(e, 'title')} value={this.state.params.title} ></Input>
                                    </Col>
                                </>}
                        </Row>
                        {/* {this.props.multiPrint &&
                            <Row style={{ marginBottom: '1em' }}>
                                <Col xl="12">
                                    <button className={this.state.type === 'word' ? "small-white-button lighter-text wide-button selected" : "small-white-button lighter-text wide-button"} style={{ width: '100%' }} onClick={() => this.setState({ type: 'word' })}>Word (.docx)</button>
                                </Col>
                            </Row>
                        } */}
                    </ModalBody>
                    <ModalFooter>
                        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={this.props.toggle}>Peruuta</a>
                        <button className="small-white-button  wide-button" onClick={this.handleSave} disabled={this.buttonCheck()} >{this.state.type === 'email' ? 'Lähetä' : 'Lataa tiedosto'}</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

class ReportFilterBar extends Component {
    constructor() {
        super()

        this.state = {
            showVariables: false,
            showDates: false,
            focusedDateInput: null,
            startDate: null,
            endDate: null,
            reportModal: false,
            openReportTypeList: false
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            reportModal: !prevState.reportModal
        }));
    }

    printReport = (type, params) => {
        const { printPdfReport, printWordReport, sendEmailReport } = this.props;
        this.setState(prevState => ({
            reportModal: !prevState.reportModal
        }));

        if (type === 'pdf') {
            printPdfReport(type);
        }

        if (type === 'email') {
            printPdfReport(type, params);
        }

        if (type === 'word') {
            printWordReport();
        }
    }

    selectVariable = (variable) => {
        this.setState(prevState => ({ showVariables: !prevState.showVariables }))

        this.props.selectVariable(variable)
    }

    selectDate = (startDate, endDate) => {
        this.setState({ startDate: startDate, endDate: endDate })

        if (startDate !== null && endDate !== null) {
            this.props.addDateFilter(startDate, endDate)
        } else if (startDate === null && endDate === null) {
            this.props.addDateFilter(startDate, endDate)
            this.setState({ showDates: false })
        }
    }

    onDatePickerFocusChange(focusedInput) {
        this.setState({ focusedDateInput: focusedInput })
        if (!focusedInput) {
            this.setState({ showDates: false })
        }
    }

    getReportTypeObject = reportType => {
        switch (reportType) {
            case 'main':
                return {
                    value: 'main',
                    label: 'Teksti'
                }
            case 'barQuestions':
                return {
                    value: 'barQuestions',
                    label: 'Kysymys'
                }
            case 'graphics':
                return {
                    value: 'graphics',
                    label: 'Graafinen'
                }
            case 'bar':
                return {
                    value: 'bar',
                    label: 'Ryhmä'
                }
            default:
                return {
                    value: 'main',
                    label: 'Teksti'
                }
        }
    }

    toggleReportTypeList = () => {

        this.setState(prevState => ({ openReportTypeList: !prevState.openReportTypeList }));
    }



    render() {

        const { reportType, activeFilters, onClick, surveyVariables, setReportType } = this.props
        const { showVariables, showDates } = this.state

        const dateRangeFilter = activeFilters.find(filter => filter.type === 'dateRange') || { label: <i className="far fa-calendar-alt"></i> }

        //   the filters we want to handle manually and don't want to display while looping all activefilters 
        const ignoredFilterTypes = ['dateRange']

        return (
            <Row className="report-filter-box">
                <ReportTypeModal isOpen={this.state.reportModal} toggle={this.toggle} printReport={this.printReport} multiPrint={this.props.multiPrint} />
                <Col xs={{ size: 12 }}>
                    <div className="dashboard-big-box-report">
                        <Row>
                            <Col xl={{ size: 6 }} xs={{ size: 12 }}>
                                {reportType !== 'graphics' &&
                                    <React.Fragment>
                                        <Row style={{ marginBottom: '1rem' }}>
                                            <Col xs={{ size: 12 }}>
                                                <p>Aktiiviset suodattimet</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={{ size: 12 }}>
                                                <div className="filter-nibblet" onClick={() => { this.setState(prevState => ({ showDates: !prevState.showDates })) }}>{dateRangeFilter.label}</div>
                                                {showDates &&
                                                    <div className="daterange-filter">
                                                        <DateRangePicker
                                                            startDate={this.state.startDate}
                                                            startDateId="startID"
                                                            endDate={this.state.endDate}
                                                            endDateId="enddateID"
                                                            onDatesChange={({ startDate, endDate }) => this.selectDate(startDate, endDate)}
                                                            startDatePlaceholderText="Alku"
                                                            endDatePlaceholderText="Loppu"
                                                            displayFormat="DD.MM.YYYY"
                                                            firstDayOfWeek={1}
                                                            appendToBody={true}
                                                            isOutsideRange={() => false}
                                                            focusedInput={this.state.focusedDateInput}
                                                            onFocusChange={focusedInput => this.onDatePickerFocusChange(focusedInput)}
                                                            hideKeyboardShortcutsPanel={true}
                                                            showClearDates={true}
                                                            horizontalMargin={50}
                                                            readOnly
                                                        />
                                                    </div>}

                                                {/* display all but ignored filters as filter-nibblets :)) */}
                                                {activeFilters.map((filter, index) => {
                                                    let cutLabel = filter.label.length > 19 ? filter.label.slice(0, 19) + '...' : filter.label
                                                    return ignoredFilterTypes.indexOf(filter.type) === -1 && <div key={index} title={filter.label} className="filter-nibblet" onClick={() => onClick(filter)}>{cutLabel} <i style={{ marginLeft: '0.5rem' }} className="fas fa-trash-alt"></i></div>
                                                })}

                                                <AddVariableFilterButton
                                                    surveyVariables={surveyVariables}
                                                    toggleShowVariables={() => this.setState(prevState => ({ showVariables: !prevState.showVariables }))}
                                                    showVariables={showVariables}
                                                    selectVariable={this.selectVariable}
                                                    activeFilters={activeFilters}
                                                />
                                            </Col>
                                        </Row>
                                    </React.Fragment>}
                            </Col>
                            <Col xl={{ size: 4 }} xs={{ size: 7 }}>
                                <Row style={{ marginBottom: '1rem' }}>
                                    <Col xs={{ size: 12 }}>
                                        <p>Raporttinäkymä</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ size: 8 }}>
                                        <Select value={this.getReportTypeObject(reportType)}
                                            onChange={item => setReportType(item.value)}
                                            options={reportTypeList.filter(x => x.value !== reportType)}
                                            placeholder="Valitse raporttityyppi"
                                            isDisabled={false}
                                            noOptionsMessage={() => { return "Raporttityyppejä ei löytynyt" }}
                                            maxMenuHeight={200}
                                            className='react-select-container'
                                            classNamePrefix="react-select"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={{ size: 2 }} xs={{ size: 5 }}>
                                <Row style={{ marginBottom: '1rem', textAlign: 'end' }}>
                                    <Col xs={{ size: 12 }}>
                                        <div><p>Tulosta</p></div>
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: 'end' }}>
                                    <Col>
                                        <button className="general-button-report" onClick={this.toggle}>
                                            <i className="fas fa-print"></i>
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

const AddVariableFilterButton = (props) => {
    const { surveyVariables, toggleShowVariables, showVariables, selectVariable, activeFilters } = props

    const getVariables = () => {
        const options = []

        surveyVariables.forEach(variable => {

            let group = {}
            group.label = variable.question
            group.options = []

            variable.answers.forEach(answer => {
                if (!activeFilters.find(filter => filter.label === answer)) {
                    group.options.push({
                        label: answer,
                        value: answer,
                        question: variable.question
                    })
                }
            })
            options.push(group)
        })

        return options
    }

    const onSelectChange = (variable) => {
        selectVariable(variable)
    }

    return (
        surveyVariables ? (
            <div className="survey-variables-container">
                <div onClick={() => toggleShowVariables()} className="add-variable-filter-button">
                    {/* <i className="fas fa-plus"></i> */}
                  Taustamuuttujat
              </div>

                {showVariables && <Select value={""}
                    onChange={onSelectChange}
                    options={getVariables()}
                    placeholder="Etsi taustamuuttujaa"
                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                    className="survey-variables-select"
                    autoFocus
                    menuIsOpen={true}
                />}

            </div>
        ) : (
                null
            )
    )
}

export default ReportFilterBar
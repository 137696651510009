import React, { Component, Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table, CustomInput } from 'reactstrap';
import SidePanel, { getPlainTexts } from './SidePanel';
import '../Kartoitus/KartoitusBuilder/kartoitukset/builder/kartoitus.css';
import './plan.css';
import { confs } from '../../modules/config';
import BuilderItem from './builderItems/BuilderItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import SurveyButton from './builderItems/SurveyButton';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {
    createNewPlanItem,
    createNewPlanItemFromTemplate,
    reOrderPlanItems,
    setPlanItem,
    addModifyPlanRisk,
    savePlanItems,
    handleTitle,
    createTemplateItem,
    getTemplateItems,
    fetchRequestFinished,
    fetchRequestStart,
    saveTargetSurveys,
    changeEditMode,
    publishPlan,
    deletePlanItem,
    toggleItem,
    handlePasswordSettings,
    fetchAllSurveyVariablesForPlans,
    fetchAllVariablesForPlan,
    savePlanSettings,
    resetPlanSelection,
    savePlanLayout,
    savePlanPrivacySettings
 
} from './Actions/ActionCreator';

import {
    getReportForDashboard,
    getBusinessSurveyTree,
    storeBusiness,
    selectBusinessForReport,
    setBreadcrumbs,
    addMoreBreadcrumbs,
   
} from '../../store/index'
import { debounce } from 'debounce';
import TopicAndDraft from './builderItems/TopicAndDraft';
import PreviewIFrame from './builderItems/PreviewIFrame';
import PreviewReport from './builderItems/PreviewReport';
import TrelloBlock from './builderItems/embeds/TrelloBlock';
import ExcelBlock from './builderItems/embeds/ExcelBlock';
import CustomBlockText from './builderItems/CustomBlockText'
import ListBlock from './builderItems/embeds/ListBlock';
import TaskCreatorModal from './Modals/PlanTaskCreatorModal';
import MainRiskModal from '../../Utilities/Modals/riskModals/MainRiskModal';
import TaskCardModal from '../Reports/Dashboard/TaskCards/TaskCardsMainBoard/TaskCardModal'
import PlanActivatorModal from './Modals/PlanActivatorModal';
import SurveyModal from './Modals/SurveyModal';
import ReportSelectionModal from './Modals/ReportSelectionModal';
import TableCreationModal from './Modals/TableCreationModal'
import BusinessEmbedSelectorModal from './Modals/BusinessEmbedSelectorModal';
import PasswordModal from './Modals/PasswordModal';
import Summary from './builderItems/summary/Summary';
import { NotificationManager } from 'react-notifications';
import TextEditor from '../../Utilities/TextEditor/texteditor';
import moment from 'moment';
import AutomaticPlanSettings from './Modals/AutomaticPlanSettings';
import MoveToBusinessModal from './Modals/MoveToBusinessModal'
import {PublishedByContainer, ModifiedContainer} from './builderItems/DataContainers'
import {TableContainer} from './builderItems/TableItems';
import { FollowOptions } from './Modals/FollowOptions';
import { tempVariables } from './templateVariables';
import { CreatePlanFromTemplate } from './Modals/CreatePlanFromTemplate';
import { ProductBlock } from './builderItems/ProductBlock';
import { AttachmentBlock } from './builderItems/AttachmentBlock';
import { AttachmentCreationModal } from './Modals/AttachmentCreationModal';
import * as ActionCreator from './Actions/ActionCreator';
import * as ActionCreator2 from '../../store';
import { SingleDatePicker } from 'react-dates';
import { executeRequest } from '../../Utilities/requestHandler/dataHandler';
import { Scrollbars } from 'react-custom-scrollbars';
import { SignatureCreationModal } from './Modals/SignatureCreationModal';
import { SignatureBlock } from './builderItems/SignatureBlock';
import { useDispatch } from 'react-redux';
import { AnnotationTool } from '../../Utilities/Annotation/AnnotationTool';
import { Annotation } from '../../Utilities/Annotation/Annotation';
import { RiskBlock } from './builderItems/risks/RiskBlock';
import { PersonalEmailSender } from '../../Utilities/PersonalEmailSender/PersonalEmailSender';
import { ChecklistBox } from './builderItems/ChecklistBlock';
import { BusinessExcelModal } from './Modals/BusinessExcelModal';
import { BusinessExcelBlock } from './builderItems/BusinessExcelBlock';
import { PlanBeingEditedModal } from './Modals/PlanBeingEditedModal';
import { HubSpotBlock, HubSpotEditModal } from './builderItems/hubSpot/HubSpotBlock';
import { GanttBlock } from './builderItems/GanttBlock';
import { CreateHubSpotDealModal } from './Modals/CreateHubSpotDealModal';
import { PlanLayoutEditModal } from './Modals/PlanLayoutEdit/PlanLayoutEdit';
import { GridBlock } from './builderItems/Grid/GridBlock';
import { TrelloTimelineBlock } from './builderItems/Trello/TrelloTimelineBlock';
import PrivatePlanModal from './PrivatePlan/PrivatePlanModal';

const templateVariables = tempVariables
const axios = require('axios');

const MegaLoaderSpinner = () => {
    return (
        <Row className="loadingSpinnerRow">
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const selectedItem = sourceClone[droppableSource.index];
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result.selectedItem = selectedItem;
    result.destinationIndex = droppableDestination.index;
    result.destClone = destClone;
    return result;
};


class PlanbuilderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            interfaces: [],
            planItems: [],
            edit: false,
            promptSave: false,
            addTask: false,
            selectedTarget: {},
            targetTask: {},
            existingTask: false,
            riskModal: false,
            planTitle: "",
            saveQueue: {},
            selectedView: "Komponentit",
            clientInfo: {},
            surveyModal: false,
            taskPreviewModal: false,
            selectedTask: {},
            statusModal: false,
            chosenItemId: "",
            deleteModal: false,
            deleteItem: {},
            openDropDown: false,
            publishedModal: false,
            reportItem: {},
            openReportSelection: false,
            openEmbedSelection: false, 
            embedItem: {},
            openAttachmentSelection: false,
            attachmentItem: {},
            openSignatureSelection: false,
            signatureItem: {},
            excelItem: {},
            automation: false,
            variables: [],
            automationSettingsOpen: false,
            template: false,
            movePlanToBusinessModalOpen: false,
            tagOptions: [],
            showTagOptions:false,
            followPlan:false,
            projectId: null,
            openSettingsDropdown: false,
            tagModalOpen: false,
            editingSidepanel:true,
            excelModalOpen: false,
            deleteText: "",
            hubspotItem: {},
            hubspotModalOpen: false,
            dealsUpdated: false,
            enterprisePrompts:[],
            planComponentsUpdated: false,
        }
        this.itemRef = React.createRef();
        this.linkRef = React.createRef();
        this.scrollbarRef = React.createRef();
    }

    startAddingPlanItem = (selectedItem, destinationIndex, destClone, planId) => {
        (this.state.selectedView === 'Komponentit' || this.state.selectedView === 'Päivitysmallit' || this.state.selectedView ==="Liittymät") ?
            this.props.onItemAdd(selectedItem, destinationIndex, destClone, planId) :
            this.props.onItemAdd_Template(selectedItem, destinationIndex, destClone, planId);

    }

    async componentDidMount () {
        const megaData = [
            {
                id: 'textItemitem-0',
                topicContent: 'Tekstialue',
                textContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas egestas ac arcu eget cursus. Etiam maximus tellus quis mattis pulvinar. Quisque iaculis odio sit amet tempor egestas. Nunc feugiat mi at elit malesuada, eget congue urna egestas. Curabitur volutpat dui porta sapien suscipit, eget malesuada est tempus. Pellentesque lacinia porttitor tellus, eget dapibus mi bibendum elementum. Integer facilisis vulputate eros id dictum. Vivamus maximus egestas elementum. In a turpis eleifend, mollis erat quis, ultrices turpis. Ut molestie nulla quis semper ultricies. Aliquam vel vestibulum tellus.'
            },
            {
                id: 'tables',
                topicContent: 'Taulukko',  
                textContent: ''
            },
            {
                id: 'attachment',
                topicContent: 'Liitteet',
            },
            {
                id: 'product',
                topicContent: 'Tuotteet',
            },
            {
                id: 'checklist',
                topicContent: 'Tehtävälista',
            },                  
        ]

        if(this.props.modulesInUse && this.props.modulesInUse.signatureManagement){
            megaData.push({
                id: 'signature',
                topicContent: 'Allekirjoitus'
            })
        } 

        
        if(this.props.modulesInUse && this.props.modulesInUse.ganttEnabled){
            megaData.push({
                id: 'gantt',
                topicContent: 'Gantt-taulukko'
            })
        } 

        if(this.props.modulesInUse && this.props.modulesInUse.trelloEnabled){
            megaData.push({
                id: 'TrelloTimeline',
                topicContent: 'Trello-aikajana'
            })
        }

        // if(this.props.modulesInUse && this.props.modulesInUse.betaTesting){
        //     megaData.push({
        //         id: 'grid',
        //         topicContent: 'Ruudukko'
        //     })
        // } 
        
        if (this.props.plan.tags){
            if(this.props.plan.tags !== undefined){
                this.setState({tagOptions:this.props.plan.tags}) 
            }
        }

        const logoStyle = { backgroundImage: `url(${confs.url + 'getLogo/' + this.props.logo + '?d=' + Date.now()})`, backgroundSize: 'contain', backgroundPosition: 'left center', pointerEvents: 'none', height: 70, width: 140 };

        this.setState({ items: megaData, logoStyle: logoStyle });

        //Check if user came here from project and give a way to return back to it
        if(this.props.history && this.props.history.location && this.props.history.location.state ){
            if(this.props.history.location.state.projectId){
                let state = this.props.history.location.state
                this.props.addMoreBreadcrumbs([{ ...state.crumbs, projectId: state.projectId}])
                this.setState({projectId: state.projectId})
            }
        }
        else{
            //this.props.setBreadcrumbs( { to: 'tiedot', label: `${ this.props.businessName }` } )
            this.props.addMoreBreadcrumbs([{ to: 'lomakkeet', label: 'Lomakkeet' }])
            if (this.props.plan && this.props.plan.planName) {
                this.props.addMoreBreadcrumbs([{ to: 'lomakkeet/luonti', label: `${this.props.plan.planName}` }])
            }
        }

        if (this.props.plan.planName && this.state.planTitle === "") {
            if (this.props.plan && this.props.plan.planName) {
                this.setState({ planTitle: this.props.plan.planName });
            }
        }
        
        window.addEventListener('beforeunload', this.handleTabClose);
        window.addEventListener("unload", this.onCloseWindow);
        this.getEnterprisePrompts()
        
    }

    async componentDidUpdate(prevProps, prevState) {

        // if(document.getElementById("planBox")){
        //     let planElement = document.getElementById("planBox")
        //     if(planElement.getAttribute('listener') !== 'true'){
        //         planElement.addEventListener('beforeunload', async function() {
        //            
        //             await this.props.unsubscribePlanEdit({"planId":this.props.plan._id})
        //         });
        //     }
        // }

        //Check if the plan is a template and limit signatures in case it is
        if(this.props.plan && this.props.plan.template && !this.state.planComponentsUpdated){
            const megaData = [
                {
                    id: 'textItemitem-0',
                    topicContent: 'Tekstialue',
                    textContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas egestas ac arcu eget cursus. Etiam maximus tellus quis mattis pulvinar. Quisque iaculis odio sit amet tempor egestas. Nunc feugiat mi at elit malesuada, eget congue urna egestas. Curabitur volutpat dui porta sapien suscipit, eget malesuada est tempus. Pellentesque lacinia porttitor tellus, eget dapibus mi bibendum elementum. Integer facilisis vulputate eros id dictum. Vivamus maximus egestas elementum. In a turpis eleifend, mollis erat quis, ultrices turpis. Ut molestie nulla quis semper ultricies. Aliquam vel vestibulum tellus.'
                },
                {
                    id: 'tables',
                    topicContent: 'Taulukko',  
                    textContent: ''
                },
                {
                    id: 'attachment',
                    topicContent: 'Liitteet',
                },
                {
                    id: 'product',
                    topicContent: 'Tuotteet',
                },
                {
                    id: 'checklist',
                    topicContent: 'Tehtävälista',
                },                  
            ]

            if(this.props.modulesInUse && this.props.modulesInUse.signatureManagement && !this.props.plan.template){
                megaData.push({
                    id: 'signature',
                    topicContent: 'Allekirjoitus'
                })
            } 

            
            if(this.props.modulesInUse && this.props.modulesInUse.ganttEnabled){
                megaData.push({
                    id: 'gantt',
                    topicContent: 'Gantt-taulukko'
                })
            } 

            if(this.props.modulesInUse && this.props.modulesInUse.trelloEnabled){
                megaData.push({
                    id: 'TrelloTimeline',
                    topicContent: 'Trello-aikajana'
                })
            }
            this.setState({items: megaData, planComponentsUpdated: true})
        }

        if(this.props.plan && this.props.plan.planItems && !this.state.dealsUpdated){
           
            let plan = this.props.plan
            if(plan.planItems.filter(item => item.type === "HubSpot").length > 0){
                let selectedBusiness = plan.selectedBusiness
                this.props.updateDeals({ "planId" : this.props.plan._id, "businessId": selectedBusiness.value })
                this.setState({dealsUpdated: true})
            }

        }

        if (this.props.plan.planName !== prevProps.plan.planName) {
            this.props.addMoreBreadcrumbs([{ to: 'lomakkeet/luonti', label: `${this.props.plan.planName}` }])
        }

        if (this.state.planTitle === "" || typeof this.state.planTitle === 'undefined') {
            if (prevProps.plan !== this.props.plan) {
                this.setState({ planTitle: this.props.plan.planName });
                this.props.addMoreBreadcrumbs([{ to: 'lomakkeet', label: 'Lomakkeet' }, { to: 'lomakkeet/luonti', label: `${this.props.plan.planName}` }])
            }
        }
        if(this.props.plan !== prevProps.plan){
            this.setState({ planTitle: this.props.plan.planName, clientInfo: this.props.plan.clientInfo})
            if(!this.props.plan.template){
                this.setState({template:false})
            }
        }

        if (this.props.plan.clientInfo !== this.state.clientInfo) {
            if (this.props.plan.clientInfo !== undefined && this.state.clientInfo === null) {
                this.setState({ clientInfo: this.props.plan.clientInfo });
            }
        }

        if (this.props.plan.automation && this.props.plan.automation !== this.state.automation) {
            this.setState({ automation: this.props.plan.automation })
            let variables = await this.props.fetchSurveyVariables({ id: this.props.plan.automationSurvey })
            this.setState({ variables: variables })
        }
        if (this.props.plan.template && this.props.plan.template !== this.state.template) {
            this.setState({ template: this.props.plan.template })
            let variables = [...templateVariables.ownInfo, ...templateVariables.project_client, ...templateVariables.targetBusiness]
            this.setState({ variables: variables })
        }

        if (this.props.plan.tags && this.props.plan.tags !== prevProps.plan.tags){
            if(this.props.plan.tags !== undefined){
                this.setState({tagOptions:this.props.plan.tags}) 
            }
        }
        if(this.props.plan && this.props.plan.selectedBusiness && !this.state.risksFetched){

            let businessId = "anon"
            
            if(this.props.plan.selectedBusiness.value){
                businessId = this.props.plan.selectedBusiness.value
            }
            
            await this.props.getRiskEvaluations({ businessId: businessId })
            this.setState({risksFetched:true})
            
        }
    
    }

    componentWillUnmount() {
        const debounceFunction = this.saveWithoutClick
        debounceFunction.flush();
        this.props.unsubscribePlanEdit({"planId":this.props.plan._id});
        this.props.resetPlanSelection();
        window.removeEventListener('beforeunload', this.handleTabClose);
        // let planElement = document.getElementById("#planBox")
        // planElement.removeEventListener('beforeunload', async function() {
        //     await this.props.unsubscribePlanEdit({"planId":this.props.plan._id})
        // });
        window.removeEventListener("unload", this.onCloseWindow);
        this.removeThisFromPlanSubs()
    }

    removeThisFromPlanSubs = () => {
        let subs = []
        if(window.localStorage.getItem('editingPlans') !== null){
            try{
                subs = window.localStorage.getItem('editingPlans')
                if(subs && subs.length > 0){
                    let finalSubsToStore = subs.filter(id => id !== this.props.plan._id)
                    this.updateStorage(finalSubsToStore)
                }
            } catch (exception){
                window.localStorage.removeItem('editingPlans')
            }
        }
    }

    handleTabClose = event => {
        event.preventDefault();
        this.removeSubscriptionOnExit()
        if(this.state.editing){
            return (event.returnValue = 'Are you sure you want to exit?')
        }
    }

    onCloseWindow = () => {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            planId: this.props.plan._id
        }
        fetch(confs.url + 'plan/unsubscribePlanEdit', {
            method: 'POST',
            body: JSON.stringify(payload)
        })
    }

    removeSubscriptionOnExit = () => {
        let editing = []
        if(window.localStorage.getItem('editingPlans') !== null){
            editing = [...window.localStorage.getItem('editingPlans'),this.props.plan._id]
        }else {
            editing = [this.props.plan._id]
        }
       
        // window.localStorage.removeItem('editingPlans')
        this.updateStorage(editing)

        // this.props.unsubscribePlanEdit({"planId":this.props.plan._id})
        
    }

    updateStorage = (selection) => {
        window.localStorage.setItem('editingPlans', JSON.stringify(selection))
    }

    changeView = view => {
        if (view === 'Pohjat') {
            this.props.fetchRequestStart();
            this.setState({ selectedView: view });
            let payload = {
                jwtToken: sessionStorage.getItem("token")
            }
            fetch(confs.url + 'plan/templates', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then(resp => resp.json())
                .then(response => {
                    this.setState({ items: response.sort((a,b) => new Date(b.created).getTime() - new Date(a.created).getTime()) });
                    this.props.fetchRequestFinished("finished");
                }).catch(error => {
                    this.props.fetchRequestFinished("failed");
                    NotificationManager.error("Pohjien haussa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                })
        } 
        else if (view === 'iFrame-mallit') {
            this.props.fetchRequestStart();
            this.setState({ selectedView: view });

            let payload = {
                jwtToken: sessionStorage.getItem("token"),
                type: 'iFrames'
            }
            fetch(confs.url + 'plan/templates', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then(resp => resp.json())
                .then(response => {
                    this.setState({ items: response.sort((a,b) => new Date(b.created).getTime() - new Date(a.created).getTime()) });
                    this.props.fetchRequestFinished("finished");
                }).catch(error => {
                    this.props.fetchRequestFinished("failed");
                    NotificationManager.error("Pohjien haussa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                })
        } 
        else if(view === 'Päivitysmallit') {
            this.props.fetchRequestStart();
            this.setState({ selectedView: view });

            let payload = {
                jwtToken: sessionStorage.getItem("token"),
                type: 'iFrames'
            }
            fetch(confs.url + 'plan/getCustomblocks', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then(resp => resp.json())
                .then(response => {
                    let names = response.map(item => {
                        const {blockName, id, textContent} = item;

                        return {
                            blockName: blockName,
                            id: id,
                            type: 'custom-block',
                            textContent: textContent
                        }
                    })

                    this.setState({ items: names });
                    this.props.fetchRequestFinished("finished");
                }).catch(error => {
                    this.props.fetchRequestFinished("failed");
                    NotificationManager.error("Pohjien haussa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
                })
        } 
        // else if(view === 'Tuotteet') {
        //     this.props.fetchRequestStart();
        //     this.setState({ selectedView: view });

        //     let payload = {
        //         jwtToken: sessionStorage.getItem("token"),
        //         type: 'products'
        //     }
        //     fetch(confs.url + 'product/getProductsForPlan', {
        //         method: 'POST',
        //         body: JSON.stringify(payload)
        //     }).then(resp => resp.json())
        //         .then(response => {
        //             let names = response.map(item => {
        //                 const {id, description, price, productName} = item

        //                 return {
        //                     blockName: productName,
        //                     id:id,
        //                     price: price,
        //                     description: description,
        //                     type: 'product'
        //                 }
        //             })
        //             this.setState({ items: names});
        //             this.props.fetchRequestFinished("finished");
        //         }).catch(error => {
        //             this.props.fetchRequestFinished("failed");
        //             NotificationManager.error("Pohjien haussa oli ongelma, yritä hetken päästä uudelleen", "Virhe", 3000);
        //         })
        // }
        else if( view === "Liittymät") {
            const interfaceList = [
                {
                    id:'businessExcel',
                    topicContent: 'Yritykselle liitetty excel'
                },
                {
                    id: 'embeds',
                    topicContent: 'Trello-API',
                    textContent: ''
                },
                {
                    id: 'iFrame',
                    topicContent: 'iFrame',
                    textContent: ''
                },
                {
                    id: 'report',
                    topicContent: 'Raportti',
                    textContent: ''
                },
            ] 
            
            if(this.props.hasHubSpot){
                interfaceList.push( {
                    id:'HubSpot',
                    topicContent:'HubSpot Deal'
                } )
            } 
    
            if(this.props.modulesInUse && this.props.modulesInUse.riskManagement){
                interfaceList.push({
                    id: 'risks',
                    topicContent: 'Riskianalyysi'
                })
            } 
            this.setState({ items: interfaceList });
        } else {
            const megaData = [
                {
                    id: 'textItemitem-0',
                    topicContent: 'Tekstialue',
                    textContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas egestas ac arcu eget cursus. Etiam maximus tellus quis mattis pulvinar. Quisque iaculis odio sit amet tempor egestas. Nunc feugiat mi at elit malesuada, eget congue urna egestas. Curabitur volutpat dui porta sapien suscipit, eget malesuada est tempus. Pellentesque lacinia porttitor tellus, eget dapibus mi bibendum elementum. Integer facilisis vulputate eros id dictum. Vivamus maximus egestas elementum. In a turpis eleifend, mollis erat quis, ultrices turpis. Ut molestie nulla quis semper ultricies. Aliquam vel vestibulum tellus.'
                },
                {
                    id: 'tables',
                    topicContent: 'Taulukko',  
                    textContent: ''
                },
                {
                    id: 'attachment',
                    topicContent: 'Liitteet',
                },
                {
                    id: 'product',
                    topicContent: 'Tuotteet',
                },
                {
                    id: 'checklist',
                    topicContent: 'Tehtävälista',
                },                  
            ]

            if(this.props.modulesInUse && this.props.modulesInUse.ganttEnabled ){
                megaData.push({
                    id: 'gantt',
                    topicContent: 'Gantt-taulukko'
                })
            } 
            if(this.props.modulesInUse && this.props.modulesInUse.trelloEnabled){
                megaData.push({
                    id: 'TrelloTimeline',
                    topicContent: 'Trello-aikajana'
                })
            }
    
            // if(this.props.modulesInUse && this.props.modulesInUse.betaTesting ){
            //     megaData.push({
            //         id: 'grid',
            //         topicContent: 'Ruudukko'
            //     })
            // } 
    
            if (this.props.plan.tags){
                if(this.props.plan.tags !== undefined){
                    this.setState({tagOptions:this.props.plan.tags}) 
                }
            }
    
            this.setState({ items: megaData });
        }
        this.setState({ selectedView: view });
    }

    id2List = {
        droppable: 'items',
        droppable2: 'planItems'
    };

    getList = id => this.state[this.id2List[id]];

    getPropsList = id => {
        if (this.props.plan !== undefined && this.props.plan.planItems !== undefined) {
            return this.props.plan.planItems;
        }
        return [];
    }

    handlePlanTitle = title => {
        this.setState({ promptSave: true, planTitle: title });
        this.saveWithoutClick();
    }

    onDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            this.setState({ isDragging: false });
            return;
        }

        if (source.droppableId === destination.droppableId && destination.droppableId !== 'droppable') {
            const items = reorder(
                this.getPropsList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.droppableId === 'droppable2') {
                if (source.index !== destination.index) {
                    this.props.onReorder(items, this.props.plan._id);
                }
            }
        } else if (this.state.selectedView === "Sisällysluettelo" && source.droppableId === 'droppable') {
            const items = reorder(
                this.getPropsList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.index !== destination.index) {
                this.props.onReorder(items, this.props.plan._id);
            }
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getPropsList(destination.droppableId),
                source,
                destination
            );
               
            if (result.destClone !== undefined && result.destinationIndex !== undefined && result.selectedItem !== undefined) {
               
                if (result.selectedItem.id === 'report' || result.selectedItem.id === 'embeds' || result.selectedItem.id === 'tables' || result.selectedItem.id === 'attachment' || result.selectedItem.id === 'product' || result.selectedItem.id === 'signature'|| result.selectedItem.id === 'businessExcel' || result.selectedItem.id === 'HubSpot') {
                    let item = {
                        selectedItem: result.selectedItem,
                        destinationIndex: result.destinationIndex,
                        destClone: result.destClone,
                        planId: this.props.planId
                    }

                    if (result.selectedItem.id === 'report')
                        this.setState({ reportItem: item, openReportSelection: true });
                    if (result.selectedItem.id === 'embeds')
                        this.setState({ embedItem: item, openEmbedSelection: true });
                    if(result.selectedItem.id === 'tables')
                        this.setState({ tableItem: item, openTableSelection: true });
                    if(result.selectedItem.id === 'attachment'){
                        this.setState({ attachmentItem: item, openAttachmentSelection: true });
                    }
                    if(result.selectedItem.id === 'product'){
                        let payload = {
                            ...item.selectedItem
                        }
                        this.startAddingPlanItem(payload, result.destinationIndex, result.destClone, this.props.planId);
                    }
                    if(result.selectedItem.id === 'signature'){
                        this.setState({ signatureItem: item, openSignatureSelection: true })
                    }
                    if(result.selectedItem.id === "businessExcel"){
                        this.setState({excelItem: item, excelModalOpen: true})
                    }
                    if(result.selectedItem.id === 'HubSpot'){
                        this.setState({hubspotItem: item, hubspotModalOpen: true})
                    }
                       
                } else {
                    
                    this.startAddingPlanItem(result.selectedItem, result.destinationIndex, result.destClone, this.props.planId);
                }
            } 

            this.setState({
                items: result.droppable
            });
        }
        this.setState({ isDragging: false });
      
    };

    promptSaveButton = (payload = null) => {
        if (payload != null) {
            this.addItemToQueue(payload);
        }
    }

    addItemToQueue = debounce((payload) => {
        let saveQue = {
            ...this.state.saveQueue,
            [payload.id]: {
                ...payload
            }
        };

        if (!this.state.promptSave) {
            this.setState({ promptSave: true });
        }
        this.setState({ saveQueue: saveQue });
        this.saveWithoutClick();
    }, 100);

    saveWithoutClick = debounce(() => {
        if (this.state.promptSave) {
            this.props.onSave(this.props.plan._id, this.state.saveQueue, this.state.planTitle, this.state.clientInfo, this.state.tagOptions);
            this.setState({ promptSave: false, saveQueue: {} });
        }
    }, 30000)

    saveWithClick = () => {
        return new Promise(async (resolve) => {
            if (this.state.promptSave) {
                await this.props.onSave(this.props.plan._id, this.state.saveQueue, this.state.planTitle, this.state.clientInfo, this.state.tagOptions)
            }

            this.setState({ promptSave: false, saveQueue: {} }, () => { resolve() })
        })
    }

    saveIFrameHost = payload => {
        let hostItem = {
            [payload.id]: {
                ...payload
            }
        }

        return new Promise(async (resolve) => {
            await this.props.onSave(this.props.plan._id, hostItem, this.state.planTitle, this.state.clientInfo, this.state.tagOptions)
            resolve()
        })
    }

    saveItemWithoutQueue = payload => {
        let planItem = {
            [payload.id]: {
                ...payload
            }
        }

        return new Promise(async (resolve) => {
            await this.props.onSave(this.props.plan._id, planItem, this.state.planTitle, this.state.clientInfo, this.state.tagOptions)
            resolve()
        })
    }

    startAddingTask = data => {

        let obj = {
            addTask: true,
            existingTask: false,
            selectedTarget: { ...data }
        };

        this.setState(obj);
    }

    modifyTask = data => {
        if (this.props.editPlan) {
            let obj = {
                addTask: true,
                existingTask: true,
                selectedTarget: { ...data }
            };
            this.setState(obj);
        } else {
            let obj = {
                selectedTask: {
                    ...data
                },
                taskPreviewModal: true
            }
            this.setState(obj);
        }
    }

    startRiskAssesment = data => {
        let obj = {
            riskModal: true,
            selectedTarget: {
                ...data,
                risk: data.risk !== undefined ? data.risk : 'new'
            }
        }
        this.setState(obj);
    }

    riskAssesmentClosed = () => {
        this.setState({ riskModal: false });
    }

    handleClientInformation = payload => {
        const {id,value} = payload
        if(id !== "businessName" && id !== "businessID"){
            let clientInfo = {
                ...this.state.clientInfo,
                [id]: value
            };

            this.setState({ clientInfo: clientInfo, promptSave: true });
            
            this.saveWithoutClick();
        }
    }

    toggleSurveyModal = () => {
        this.setState({ surveyModal: !this.state.surveyModal });
    }

    saveSelectedSurveys = selectedItems => {
        if (this.props.plan.relatedSurveys !== undefined && this.props.plan.relatedSurveys.length !== selectedItems.length) {
            this.props.saveTargetSurveys({ targetSurveys: selectedItems, planId: this.props.plan._id });
            this.setState({ surveyModal: false });
        } else if (selectedItems.length > 0) {
            this.props.saveTargetSurveys({ targetSurveys: selectedItems, planId: this.props.plan._id });
            this.setState({ surveyModal: false });
        } else {
            this.setState({ surveyModal: false });
        }
    }

    toggleTaskPreviewModal = () => {
        this.setState({ taskPreviewModal: !this.state.taskPreviewModal });
    }

    onDragStart = () => {
        this.setState({ isDragging: true });
        this.saveWithClick()
    }

    selectReport = async (survey) => {
        const params = {
            reports: [
                {
                    reportId: survey.id
                }
            ]
        }

        if (survey.group !== "Ei kohdeyritystä") {
            this.props.selectBusinessForReport({ businessId: survey.businessID })
            await this.props.getReportTree({ businessId: survey.businessID })
            this.props.getReportForDashboard(params)
            this.props.history.push('/raportit/yritys/raportti')
        } else {
            this.props.storeBusiness({ name: 'Ei kohdeyritystä' })
            this.props.setBreadcrumbs({ to: 'raportit', label: 'raportit' })
            this.props.getReportForDashboard(params)
            this.props.history.push('/raportit/kohdistamaton/raportti')
        }
    }


    startScrolling = chosenTopicId => {
        var element = document.getElementById(chosenTopicId);
        this.scrollToElement(element)
    }

    scrollToElement = (element) => element.scrollIntoView(true);

    openStatusModal = () => {
        this.setState({ statusModal: !this.state.statusModal });
    }

    changePlanStatus = (password, status, passwordStatus, lastDate) => {

        if (passwordStatus && password.length > 0) {
            if (status) {
                let payload = {
                    jwtToken: sessionStorage.getItem("token"),
                    status: status,
                    planId: this.props.plan._id,
                    passwordStatus: passwordStatus,
                    password: password,
                    lastDate: lastDate
                };

                this.props.onPublish(payload);
            }
        } else if (passwordStatus && password.length === 0) {
            NotificationManager.error("Salasana on tyhjä, lisää salasana.", "", 3000);
        } else if (status) {
            let payload = {
                jwtToken: sessionStorage.getItem("token"),
                status: status,
                planId: this.props.plan._id,
                lastDate: lastDate
            };

            this.props.onPublish(payload);
        }

        this.openStatusModal();
    }

    saveAndToggle = async () => {
        if (this.state.promptSave) {
            await this.saveWithClick()
        }
        if(this.state.showTagOptions){
            this.setState({showTagOptions:false})
        }
        if(!this.props.panelOpen){
            this.props.togglePanel()
        }
        this.props.unsubscribePlanEdit({"planId":this.props.plan._id});
        this.props.toggleEdit()
    }

    deleteItem = () => {
        if(this.state.deleteText === "poista"){
            let params = {
                planId: this.props.plan._id,
                ...this.state.deleteItem
            }
            this.toggleitemDeletionModal();
            this.props.onPlanItemDelete(params);
            this.setState({deleteText: ""})
        }
    }

    openItemDeletionModal = (payload) => {
        this.setState({ deleteItem: payload, deleteModal: true });
    }

    toggleitemDeletionModal = () => {
        this.setState({ deleteItem: {}, deleteModal: !this.state.deleteModal });
        this.setState({deleteText: ""})
    }

    toggleItemVisibility = payload => {
        let params = {
            planId: this.props.plan._id,
            ...payload
        }

        this.props.onItemToggle(params);
    }

    toggleDropDown = status => {
        this.setState({ openDropDown: status });
    }

    toggleSettingsDropdown = () => {
        this.setState({openSettingsDropdown : !this.state.openSettingsDropdown})
    }
    
    togglePasswordModal = () => {
        this.setState({ passwordModal: !this.state.passwordModal });
    }

    changePasswordSettings = payload => {
        let params = {
            ...payload,
            planId: this.props.plan._id
        };

        this.props.onSavePasswordSettings(params);
        this.setState({ passwordModal: !this.state.passwordModal });
    }

    toggleUnpublishModal = () => {
        this.setState({ publishedModal: !this.state.publishedModal });
    }

    changePublishedStatus = () => {
        let params = {
            jwtToken: sessionStorage.getItem("token"),
            status: false,
            planId: this.props.plan._id
        }

        this.props.onPublish(params);
        this.toggleUnpublishModal();
    }

    copyPlanLink = (value) => {
        var tempInput = document.createElement("input");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        NotificationManager.success("Lomakkeen linkki kopioitu", "", 3000);
    }

    scrollToSaveButtonAfterResize = id => {
        var element = document.getElementById(`${id}-saveButton`);
        if (!this.isInViewPort(element))
            this.scrollToElement(element);
    }

    isInViewPort = element => {
        var bounding = element.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    savePlanSettings = (settings) => {
        let params = {
            planId: this.props.planId,
            settings: settings
        }
        this.setState({ automationSettingsOpen: false })
        this.props.savePlanSettings(params)
    }

    setTagOptions = (tags) =>{
        this.setState({tagOptions:tags, promptSave: true})
    }

    toggleManageTags = () => {
        this.setState({showTagOptions:!this.state.showTagOptions})
    }

    startEditing(planId) {
        this.props.onEdit(planId);
        this.props.history.push('/lomakkeet/luonti')
    }

    handleLastDaySave = lastDay => {
        this.saveWithClick()
    }

    toggleEditingSidepanel = () => {
        this.setState({editingSidepanel: !this.state.editingSidepanel})
    }

    checkScrollPosition = () => {
        var scrollButton = document.getElementById("scroll-btn");
        if(scrollButton !== undefined && scrollButton !== null && this.scrollbarRef && this.scrollbarRef.current && this.scrollbarRef.current.getScrollTop()){
            if (this.scrollbarRef.current.getScrollTop() > 500) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }
    }

    scrollToTop = () => {
        if(this.scrollbarRef && this.scrollbarRef.current){
          
           // this.scrollbarRef.current.scrollTop()
           this.scrollbarRef.current.view.scroll({
                top: 0,
                behavior: 'smooth',
            });

            var scrollButton = document.getElementById("scroll-btn");
            if (scrollButton){
                scrollButton.style.display = "none";
            }
        }
    }

    toggleExcelModal = () => {
        this.setState({excelModalOpen: !this.state.excelModalOpen})
    }

    toggleHubSpotModal = () => {
        this.setState({hubspotModalOpen: !this.state.hubspotModalOpen})
    }

    getEnterprisePrompts = async() => {
        const response = await executeRequest('plan/getAllEnterprisePrompts',{})
        if(response && response.length > 0){
            this.setState({enterprisePrompts:[...response]})
        }
    }
        
    render() {
        const { selectedTask } = this.state;
        const { planItems, _id } = this.props.plan;
        return (
            <div id="planBox">
                <>
                <TaskCreatorModal
                    modal={this.state.addTask}
                    chosenTarget={this.state.selectedTarget}
                    activeCompany={this.props.plan.selectedBusiness}
                    type="plan-item-task"
                    existingTask={this.state.existingTask}
                    endAdd={() => this.setState({ addTask: false, existingTask: false })}
                />
                {this.props.plan &&
                    <MainRiskModal
                        riskModal={this.state.riskModal}
                        chosenTarget={this.state.selectedTarget}
                        closed={this.riskAssesmentClosed}
                        activeCompany={this.props.plan.selectedBusiness}
                        planId={this.props.plan._id}
                        planStatus={this.props.plan.status}
                        addModifyPlanRisk={this.props.addModifyPlanRisk}
                    />
                }
                {(this.props.plan && this.state.openReportSelection) &&
                    <ReportSelectionModal
                        type="creation"
                        open={this.state.openReportSelection}
                        toggle={() => this.setState({ openReportSelection: !this.state.openReportSelection, reportItem: {} })}
                        targetBusiness={this.props.plan.selectedBusiness}
                        createReportItem={this.startAddingPlanItem}
                        item={this.state.reportItem}
                        clearItems={() => this.setState({ openReportSelection: !this.state.openReportSelection, reportItem: {} })}
                    />
                }

                {(this.props.plan && this.state.openEmbedSelection) &&
                    <BusinessEmbedSelectorModal
                        type="creation"
                        open={this.state.openEmbedSelection}
                        toggle={() => this.setState({ openEmbedSelection: !this.state.openEmbedSelection, embedItem: {} })}
                        targetBusiness={this.props.plan.selectedBusiness}
                        createEmbedItem={this.startAddingPlanItem}
                        item={this.state.embedItem}
                        clearItems={() => this.setState({ openEmbedSelection: !this.state.openEmbedSelection, embedItem: {} })}
                    />
                }

                {(this.props.plan && this.state.openTableSelection) &&
                    <TableCreationModal
                        type="creation"
                        open={this.state.openTableSelection}
                        toggle={() => this.setState({ openTableSelection: !this.state.openTableSelection, tableItem: {} })}
                        targetBusiness={this.props.plan.selectedBusiness}
                        createTableItem={this.startAddingPlanItem}
                        item={this.state.tableItem}
                        clearItems={() => this.setState({ openTableSelection: !this.state.openTableSelection, tableItem: {} })}
                    /> 
                }
                {this.props.plan && this.state.openAttachmentSelection &&
                    <AttachmentCreationModal
                        open={this.state.openAttachmentSelection}
                        toggle={() => this.setState({ openAttachmentSelection: !this.state.openAttachmentSelection, attachmentItem: {} })}
                        targetBusiness={this.props.plan.selectedBusiness}
                        createAttachmentItem={this.startAddingPlanItem}
                        item={this.state.attachmentItem}
                        planId={_id}
                        clearItems={() => this.setState({ openAttachmentSelection: !this.state.openAttachmentSelection, attachmentItem: {} })}
                        creation={true}
                    /> 
                }
                {(this.props.plan && this.state.openSignatureSelection) && 
                    <SignatureCreationModal
                        open={this.state.openSignatureSelection}
                        toggle={() => this.setState({ openSignatureSelection: !this.state.openSignatureSelection, signatureItem: {} })}
                        createSignatureItem={this.startAddingPlanItem}
                        saveItem={this.saveItemWithoutQueue}
                        item={this.state.signatureItem}
                        modulesInUse={this.props.modulesInUse}
                        planId={this.props.plan._id}
                    /> 
                }
                {this.state.taskPreviewModal &&
                    <TaskCardModal
                        isOpen={this.state.taskPreviewModal}
                        toggler={this.toggleTaskPreviewModal}
                        task={selectedTask}
                    />
                }
                {(this.props.plan && this.state.excelModalOpen) && 
                    <BusinessExcelModal
                        isOpen={this.state.excelModalOpen}
                        toggle={this.toggleExcelModal}
                        targetBusiness={this.props.plan.selectedBusiness} 
                        createExcelItem={this.startAddingPlanItem}
                        item={this.state.excelItem}
                    />
                }
                {(this.props.plan && this.state.hubspotModalOpen) &&
                    <HubSpotEditModal 
                        isOpen={this.state.hubspotModalOpen}
                        toggle={this.toggleHubSpotModal}
                        item={this.state.hubspotItem}
                        creation={true}
                        plan={this.props.plan}
                        createHubSpotItem={this.startAddingPlanItem}
                    />
                }
                {this.state.deleteModal &&
                    <Modal isOpen={this.state.deleteModal} toggle={this.toggleitemDeletionModal} className={this.props.className} centered size="lg">
                        <ModalHeader>
                            {this.state.deleteItem &&
                                <TextEditor
                                    editorId={`${this.state.deleteItem.planItemId}-subtitle`}
                                    content={this.state.deleteItem.topicContent}
                                    additionalClass="plan-input subTitle"
                                    readOnly={true}
                                    plaintextEditor={true}
                                />
                            }
                        </ModalHeader>
                        <ModalBody>
                            <p>Oletko varma, että haluat poistaa valitun tekstin? <b>Jos valinta sisältää tarkistuspisteitä tai riskejä, tarkistuspisteet ja riskit poistetaan.</b></p>
                            <h6 style={{ paddingTop: '1.5em' }}><b>Toimintoa ei voi peruuttaa!</b></h6>
                            <div style={{ marginTop: '1em' }}>
                                <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                                <Input id="deleteInput" type="text" value={this.state.deleteText} onChange={(e) => this.setState({deleteText: e.target.value})}/>
                                <div className="email-check-box">
                                    {this.state.deleteText === "poista"?
                                        <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                        :
                                        <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <a onClick={this.toggleitemDeletionModal} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                            <button onClick={this.deleteItem} className="small-white-button wide-button swb-hover delete-plan-item-button" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                        </ModalFooter>
                    </Modal>
                }
                {this.state.publishedModal &&
                    <Modal isOpen={this.state.publishedModal} toggle={this.toggleUnpublishModal} className={this.props.className} size="lg">
                        <ModalHeader>
                            Lomakkeen piilottaminen
                        </ModalHeader>
                        <ModalBody>
                            <p>
                                Oletko varma, että haluat piilottaa lomakkeen?
                            </p>
                            <p style={{ paddingTop: '1.5em' }}>
                                <b>Jos lomake sisältää tarkistuspisteitä tai riskejä, tarkistuspisteet ja riskit piilotetaan asiakkaan näkymästä.</b>
                            </p>
                            <p style={{ paddingTop: '1.5em' }}>
                                <b>Tarkistuspisteet ja riskit palautuvat asiakkaan näkymään uudelleen julkaisun jälkeen.</b>
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <a onClick={this.toggleUnpublishModal}className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                            <button onClick={this.changePublishedStatus} className="small-white-button wide-button swb-hover" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Piilota</button>{' '}
                        </ModalFooter>
                    </Modal>
                }
                {(this.props.plan && this.state.surveyModal) &&
                    <SurveyModal modal={this.state.surveyModal} toggle={this.toggleSurveyModal} selectedBusiness={this.props.plan.selectedBusiness} saveSelectedSurveys={this.saveSelectedSurveys} selectedSurveys={this.props.plan.relatedSurveys || []} />
                }
                {
                    <Modal isOpen={this.state.tagModalOpen} toggle={() => this.setState({tagModalOpen: false})}>
                        <ModalHeader toggle={() => this.setState({tagModalOpen: false})}> Hallitse tageja </ModalHeader>
                        <ModalBody>
                            <TagListEdit tagOptions={this.state.tagOptions} setTagOptions={this.setTagOptions}/>
                        </ModalBody>
                        <ModalFooter>
                            <a onClick={() => this.setState({tagModalOpen: false})} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                            <button onClick={()=> (this.saveWithClick(),this.setState({tagModalOpen: false}))} className="small-white-button wide-button swb-hover delete-plan " >Tallenna</button>
                        </ModalFooter>
                    </Modal>
                }
                </>
                <Container fluid>
                    {Object.keys(this.props.plan).length > 0 &&
                        this.props.editPlan ? (
                            <div className="whitebox-plan" style={{ marginTop: '1rem', padding: '0 1rem', height: '92vh', overflow: 'hidden' }}>
                                <Row style={{ height: 'inherit' }}>
                                    <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                                        <div className="plan-sidePanel-toggler" onClick={()=> this.toggleEditingSidepanel()}><i style={{ fontSize:'1.5rem', color: '#fff' }} className="fas fa-bars"></i></div>
                                        <Col xl="3" className={"dataWindowSidePanel plan-panel " + (this.state.editingSidepanel? "" : "hide-editing-sidePanel")} style={{ overflow: 'hidden', height: 'auto'}}>
                                            <SidePanel data={this.state.items} interfaces={this.state.interfaces} planItems={planItems} startNewPlan={this.props.startNewPlan} changeView={this.changeView} selectedView={this.state.selectedView} fetchStatus={this.props.fetchStatus} annotations={this.props.plan.annotations || false} planId={this.props.plan._id}/>
                                        </Col> 
                                        <Col className="plan-main-body" style={{ height: 'inherit', display: 'flex', flexDirection: 'column' }}>
                                            <Col>
                                                <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '-2em', marginTop: '-2em', fontWeight: 'bold'}}>
                                                    Muokkaus  {this.props.plan.isPrivateDocument && <div style={{ display: 'flex', float: 'right', alignItems:'center', marginLeft:'1em' }}> <i className="far fa-eye-slash" style={{color: 'red'}} title='Yksityinen'></i><p style={{color: 'red', marginLeft:'0.5em', fontSize:'0.9rem', fontWeight:'bold'}}> {' Yksityinen'}</p></div>}
                                                </div> 
                                                <div className="dropdown-plan-settings">
                                                    <Dropdown isOpen={this.state.openSettingsDropdown} style={{ cursor: 'pointer' }} onClick={(event) => event.stopPropagation()} toggle={() => this.toggleSettingsDropdown()} >
                                                        <DropdownToggle tag="div">
                                                            <div className="small-white-button plan-option-toggler" title="Lomakkeen asetukset" onClick={() =>  this.toggleSettingsDropdown()} style={{ backgroundColor: "#6ba53f", color: '#FFF', fontWeight: 700, width: '2em'}}><i className="fa fa-cog"></i></div>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => this.saveAndToggle()}> Tallenna ja palaa esikatseluun</DropdownItem>
                                                            <DropdownItem onClick={() => this.setState({tagModalOpen: true})}>Hallitse tageja</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                            </Col>
                                            <Scrollbars
                                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                                onScroll={this.checkScrollPosition} 
                                                ref={this.scrollbarRef}
                                                style={{ width: "100%", height: '92vh', overflowX: 'hidden'}}
                                            >
                                                <button id="scroll-btn" className="scroll-to-top-button scroll-to-top-edit" title="Takaisin alkuun" onClick={()=> this.scrollToTop()}><i className="fas fa-arrow-up"></i></button>
                                                <Col>
                                                    {(this.props.fetchStatus && !this.state.riskModal && !this.state.addTask) && <MegaLoaderSpinner />}
                                                    <Row>
                                                        <Col style={{ marginTop: '0.25em'}}>
                                                            <TagList tagOptions={this.props.plan.tags}/>
                                                        </Col>
                                                    </Row>
                                                    {this.props.plan && this.props.plan.template &&
                                                        <Row>
                                                            <Col style={{textAlign: 'center', marginTop: '0.5em'}}>
                                                                <h4 style={{ color: "#cfcfcf", fontWeight: 'bolder' }} > {"LOMAKEPOHJA"} </h4>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row style={{ height: 100, marginTop: 20, paddingTop: 5 , paddingBottom: '1em' }} className="plan-main-row">
                                                        <Col xs="2">
                                                            <div className="logo-container-nav-bar" style={this.state.logoStyle}></div>
                                                        </Col>
                                                        <Col xs="8">
                                                            {this.props.editPlan ? <Input className="plan-input title" type="text" placeholder="Otsikko" value={this.state.planTitle} onChange={(e) => this.handlePlanTitle(e.target.value)}></Input> : <h3 className="topicText">Otsikko</h3>}
                                                        </Col>
                                                        
                                                    </Row>
                                                   
                                                    <Row className="plan-main draggableItems">
                                                        {this.props.plan && !this.props.plan.hideClient && this.props.plan.selectedBusiness &&
                                                            <>                                    
                                                                <Col xl="12" style={{ paddingTop: '1.5em'}} className="plan-sub-row">
                                                                    <h4 style={{ fontWeight: 700 }}>{(this.props.modulesInUse && this.props.modulesInUse.customPlanTargetName)?  'Vastaanottaja' : 'Asiakas'}</h4>
                                                                </Col>                                                                                                           
                                                                {!this.state.automation && !this.state.template &&
                                                                    <Col xl="12" className="plan-sub-row">
                                                                        <Input className="small-input-bar plan-input business" value={this.state.clientInfo.businessName} placeholder="Asiakkaan nimi" onChange={(e) => this.handleClientInformation({ id:"businessName", value: e.target.value })}></Input>
                                                                        <Input className="small-input-bar plan-input yTunnus" value={this.state.clientInfo.businessID} placeholder="Y-Tunnus" onChange={(e) => this.handleClientInformation({ id: "businessID", value : e.target.value })}></Input>
                                                                        <div style={{ backgroundColor:'#ebebeb', padding:'0 0.5em', marginLeft:'-0.5em', borderRadius: '5px', marginBottom: '0.15em' }}><Input className="small-input-bar plan-input address" style={{ backgroundColor:'#ebebeb'}} value={this.state.clientInfo.address} placeholder="Osoite" onChange={(e) => this.handleClientInformation({id:  "address", value: e.target.value })}></Input></div>
                                                                        <div style={{ backgroundColor:'#ebebeb', padding:'0 0.5em', marginLeft:'-0.5em', borderRadius: '5px', marginBottom: '0.15em' }}><Input className="small-input-bar plan-input postCode" style={{ backgroundColor:'#ebebeb'}} value={this.state.clientInfo.postCode} placeholder="Postinumero" onChange={(e) => this.handleClientInformation({id: "postCode", value: e.target.value })}></Input></div>
                                                                        <div style={{ backgroundColor:'#ebebeb', padding:'0 0.5em', marginLeft:'-0.5em', borderRadius: '5px', marginBottom: '0.15em' }}><Input className="small-input-bar plan-input postOffice" style={{ backgroundColor:'#ebebeb'}} value={this.state.clientInfo.postOffice} placeholder="Postitoimipaikka" onChange={(e) => this.handleClientInformation({ id :"postOffice", value : e.target.value })}></Input></div>
                                                                    </Col>
                                                                }
                                                            </>
                                                        }
                                                        {(this.props.plan.planItems !== undefined && this.props.plan.planItems.length > 0) && <Summary planItems={planItems} startScrolling={this.startScrolling} />}
                                                    
                                                        <Col xl="12" className="plan-sub-row">
                                                            <div className="dragable-window" style={{ paddingBottom: '1.5em' }}>
                                                                <Droppable droppableId="droppable2" isDropDisabled={this.state.selectedView === 'Sisällysluettelo'}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            style={{ minHeight: 80, height: '100%', width: '100%', border: (this.state.isDragging && this.state.selectedView !== 'Sisällysluettelo') ? '1px dashed #C8C8C8' : 'none' }}
                                                                            {...provided.droppableProps}
                                                                        >
                                                                            {(Object.keys(this.props.plan).length > 0 && planItems !== undefined)
                                                                                && planItems.map((i, index) =>
                                                                                    <div key={i.id + "-" + index}>
                                                                                        <BuilderItem
                                                                                            index={index}
                                                                                            item={i}
                                                                                            textContent={i.textContent}
                                                                                            topicContent={i.topicContent}
                                                                                            edit={this.props.editPlan}
                                                                                            promptSave={this.promptSaveButton}
                                                                                            onItemChange={this.props.onItemChange}
                                                                                            startAddingTask={this.startAddingTask}
                                                                                            modifyTask={this.modifyTask}
                                                                                            startRiskAssesment={this.startRiskAssesment}
                                                                                            riskList={this.props.risksById[i.id]}
                                                                                            taskList={this.props.tasksById[i.id]}
                                                                                            onTemplateAdd={this.props.onTemplateAdd}
                                                                                            chosenTopicIndex={this.state.chosenTopicIndex}
                                                                                            scrollToRef={this.state.chosenTopicIndex === index}
                                                                                            selectedBusiness={this.props.plan.selectedBusiness}
                                                                                            onDelete={this.openItemDeletionModal}
                                                                                            toggleItemVisibility={this.toggleItemVisibility}
                                                                                            modulesInUse={this.props.modulesInUse}
                                                                                            saveIFrameHost={this.saveIFrameHost}
                                                                                            scrollToSaveButtonAfterResize={this.scrollToSaveButtonAfterResize}
                                                                                            saveItemWithoutQueue={this.saveItemWithoutQueue}
                                                                                            variables={this.state.variables}
                                                                                            history={this.props.history}
                                                                                            planId={this.props.plan._id}
                                                                                            plan={this.props.plan}
                                                                                            hasHubSpot={this.props.hasHubSpot}
                                                                                            updatePrompts={this.getEnterprisePrompts}
                                                                                            enterprisePrompts={this.state.enterprisePrompts}
                                                                                        />
                                                                                        {i.id === this.state.chosenItemId && <div className="scroll-to-this" ref={this.itemRef}></div>}
                                                                                    </div>
                                                                                )}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </div>
                                                        </Col>

                                                        {!this.props.plan.template && this.props.modulesInUse.surveyManagement && (
                                                            <Col xl="12" className="plan-sub-row" style={{paddingBottom: '1.5em' }}>
                                                                <div style={{ paddingBottom: '1em', cursor: 'pointer', width: '15em' }}><h4>Kartoitukset</h4></div>
                                                                {this.props.plan.relatedSurveys !== undefined &&
                                                                    <>
                                                                        {this.props.plan.relatedSurveys.map(item =>
                                                                            <Row key={item.id}>
                                                                                <Col xl="4">
                                                                                    <SurveyButton label={item.label} selectReport={this.selectReport} survey={item} />
                                                                                </Col>
                                                                            </Row>
                                                                        )}
                                                                    </>
                                                                }
                                                                <span onClick={this.toggleSurveyModal}><i className="fas fa-plus plusIcon" style={{padding: '10px'}}></i></span>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        {this.state.promptSave && <div className="save-plan-button" onClick={this.saveWithClick}><p style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Tallenna</p></div>}
                                                    </div>
                                                </Col>
                                           </Scrollbars>  
                                        </Col> 
                                    </DragDropContext>
                                </Row>
                            </div>) : (
                            <PlanPreview
                                history={this.props.history}
                                plan={this.props.plan}
                                selectedBusiness={this.props.plan.selectedBusiness}
                                toggleEdit={this.props.toggleEdit}
                                logoStyle={this.state.logoStyle}
                                riskList={this.props.risksById}
                                taskList={this.props.tasksById}
                                modifyTask={this.modifyTask}
                                startRiskAssesment={this.startRiskAssesment}
                                selectReport={this.selectReport}
                                openStatusModal={this.openStatusModal}
                                statusModal={this.state.statusModal}
                                changePlanStatus={this.changePlanStatus}
                                editPlan={this.props.editPlan}
                                chosenTopicId={this.state.chosenItemId}
                                ref={{ itemRef: this.itemRef, linkRef: this.linkRef }}
                                startScrolling={this.startScrolling}
                                openDropDown={this.state.openDropDown}
                                toggleDropDown={this.toggleDropDown}
                                passwordModal={this.state.passwordModal}
                                togglePasswordModal={this.togglePasswordModal}
                                changePasswordSettings={this.changePasswordSettings}
                                copyPlanLink={this.copyPlanLink}
                                toggleUnpublishModal={this.toggleUnpublishModal}
                                modulesInUse={this.props.modulesInUse}
                                openAutomationSettings={() => this.setState({ automationSettingsOpen: !this.state.automationSettingsOpen })}
                                isSettingsOpen={this.state.automationSettingsOpen}
                                savePlanSettings={this.savePlanSettings}
                                movePlanToBusinessModalOpen={this.state.movePlanToBusinessModalOpen}
                                toggleMovePlanModal={() => this.setState({ movePlanToBusinessModalOpen: !this.state.movePlanToBusinessModalOpen })}
                                projectId={this.state.projectId}
                                startEditing={this.startEditing}
                                tagOptions={this.state.tagOptions}
                                setTagOptions={this.setTagOptions}
                                saveWithClick={this.saveWithClick}
                                handleLastDaySave={this.handleLastDaySave}
                                togglePanel={this.props.togglePanel}    
                                panelOpen={this.props.panelOpen} 
                                saveItemWithoutQueue={this.saveItemWithoutQueue}   
                                promptSave={this.promptSaveButton}   
                                hasHubSpot={this.props.hasHubSpot}
                                savePlanLayout={this.props.savePlanLayout} 
                                savePlanPrivacySettings={this.props.savePlanPrivacySettings}                  
                            />
                        
                        )
                    }
                </Container>
            </div>
        )
    }
}

export const checkPreviewItemType = (i, index, props, layoutPreview = false) => {
    switch (i.type) {
        case 'iFrame':
            return (
                <PreviewIFrame
                    item={i}
                    contentId={i.id}
                    text={i.textContent}
                    topic={i.topicContent}
                    index={index}
                    editable={props.editPlan}
                    modulesInUse={props.modulesInUse}
                    layoutPreview={layoutPreview}
                />
            )
        case 'custom-block':
            return (
                <CustomBlockText 
                    id={i.id}
                    blockName={i.blockName}
                    text={i.textContent}
                    topic={i.topicContent}
                    index={index}
                    editable={props.editPlan}
                    itemData={i}
                    layoutPreview={layoutPreview}
                    />
            )
        case 'report':
            return (
                <PreviewReport item={i} planId={props.plan._id} index={index} saveDateRange={props.saveItemWithoutQueue} layoutPreview={layoutPreview}/>
            )
        case 'tables':
            return (
                <TableContainer item={i} index={index} editable={props.editPlan} layoutPreview={layoutPreview}/>
            )
        case 'Trello':
            return (
                <TrelloBlock
                    item={i}
                    itemId={i.targetId}
                    businessId={props.selectedBusiness.value}
                    editable={props.editPlan}
                    index={index}
                    layoutPreview={layoutPreview}
                />
            )
        case 'TrelloTimeline':
            return (
                <TrelloTimelineBlock
                    item={i}
                    itemId={i.targetId}
                    businessId={props.selectedBusiness.value}
                    editable={props.editPlan}
                    index={index}
                    layoutPreview={layoutPreview}
                />
            )
        case 'Excel':
            return (
                <ExcelBlock item={i}
                    itemId={i.targetId}
                    businessId={props.selectedBusiness.value}
                    editable={props.editPlan} 
                    index={index}
                    layoutPreview={layoutPreview}
                />
            )
        case 'list':
            return (
                <ListBlock item={i}
                    itemId={i.targetId}
                    businessId={props.selectedBusiness.value}
                    index={index}
                    editable={props.editPlan} 
                    layoutPreview={layoutPreview}
                />
            )
        case 'product':
            return(
                <ProductBlock
                    item={i}
                    editable={props.editPlan}
                    index={index}
                    layoutPreview={layoutPreview}
                />
            )
        case 'attachment':
            return(
                <AttachmentBlock
                    item={i}
                    editable={props.editPlan}
                    index={index}
                    layoutPreview={layoutPreview}
                />
            )
        case 'signature':
            return(
                <SignatureBlock
                    item={i}
                    editable={props.editPlan}
                    index={index}
                    saveSignatures={props.saveItemWithoutQueue}
                    planId={props.plan._id}
                    layoutPreview={layoutPreview}
                />
            )
        case 'risks':
            return(
                <RiskBlock
                    item={i}
                    editable={props.editPlan}
                    index={index}
                    planId={props.plan._id}
                    layoutPreview={layoutPreview}
                />
            )
        case 'checklist':
            return(
                <ChecklistBox
                    item={i}
                    editable={props.editPlan}
                    index={index}
                    planId={props.plan._id}
                    saveWithoutQueue={props.saveItemWithoutQueue}
                    plan={props.plan}
                    layoutPreview={layoutPreview}
                />
            )  
        case 'businessExcel':
            return(
                <BusinessExcelBlock
                    item={i}
                    editable={props.editPlan}
                    index={index}
                    planId={props.plan._id}
                    saveWithoutQueue={props.saveItemWithoutQueue}
                    plan={props.plan}
                    layoutPreview={layoutPreview}
                />
            )
        case 'HubSpot':
            return (
                <HubSpotBlock
                    item={i}
                    editable={props.editPlan}
                    index={index}
                    saveWithoutQueue={props.saveItemWithoutQueue}
                    planId={props.plan._id}
                    plan={props.plan}
                    selectedBusiness={props.selectedBusiness}
                    layoutPreview={layoutPreview}
                />
            )    
        case 'gantt' :
            return(
                <GanttBlock
                    item={i}
                    editable={props.editPlan}
                    index={index}
                    saveWithoutQueue={props.saveItemWithoutQueue}
                    planId={props.plan._id}
                    plan={props.plan}
                    layoutPreview={layoutPreview}
                />
            ) 
        case 'grid':
            return(
                <GridBlock 
                    planItem={i}
                    editable={props.editPlan}
                    index={index}
                    saveWithoutQueue={props.saveItemWithoutQueue}
                    planId={props.plan._id}
                    plan={props.plan}
                    layoutPreview={layoutPreview}
                />
            )               
        default:
            return (
                <TopicAndDraft
                    item={i}
                    contentId={i.id}
                    text={i.textContent}
                    topic={i.topicContent}
                    index={index}
                    riskList={props.riskList && props.riskList.length > 0 && props.riskList[i.id].filter(risk => risk.visible === undefined || risk.visible)}
                    taskList={props.taskList && props.taskList.length > 0 && props.taskList[i.id].filter(task => task.visible === undefined || task.visible)}
                    modifyTask={props.modifyTask}
                    startRiskAssesment={props.startRiskAssesment}
                    editable={props.editPlan}
                    modulesInUse={props.modulesInUse}
                    promptSave={props.promptSave}
                    layoutPreview={layoutPreview}
                />
            )
    }
}

const ScrollToTopButton = () => {
    window.onscroll = function() {scrollFunction()};
    
    const scrollFunction = () => {
        var scrollButton = document.getElementById("scroll-btn");
        if(scrollButton !== undefined && scrollButton !== null){
            if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }
    }

    const scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return(
        <button id="scroll-btn" className="scroll-to-top-button" title="Takaisin alkuun" onClick={()=> scrollToTop()}><i className="fas fa-arrow-up"></i></button>
    )
}

const PlanPreview = React.forwardRef((props, ref) => {
    const { itemRef, linkRef } = ref;
    const { planItems, clientInfo, status, passwordOn, automation, projectId, isSigned, layoutOptions = false } = props.plan;
    const { history, chosenTopicId, startScrolling, openDropDown, toggleDropDown, passwordModal, togglePasswordModal, changePasswordSettings, toggleUnpublishModal, isSettingsOpen, savePlanSettings, savePlanLayout, toggleMovePlanModal, movePlanToBusinessModalOpen, modulesInUse, panelOpen, togglePanel, selectedBusiness, hasHubSpot} = props;
    const [followModalOpen, setFollowModalOpen] = useState(false)
    const [tagModalOpen, setTagModalOpen] = useState(false)
    const [focusedDateInput, setFocusedDateInput] = useState(false)
    const [lastDate, setLastDate] = useState(null)
    const [lastDayModalOpen, setLastDayModalOpen] = useState(false)
    const [lastActiveDay, setLastActiveDay] = useState(false)
    const [signedPlanModal, setSignedPlanModal] = useState(false)
    const [switchChecked, setSwitchChecked] = useState(false)
    const [commentModalOpen, setCommentModalOpen] = useState(false)
    const [isFollowed, setIsFollowed] = useState(false)
    const [senderModalOpen, setSenderModalOpen] = useState(false)
    const [type, setType] = useState("sendFromEB")
    const [coverPageModal, setCoverPageModal] = useState(false)
    const [showCoverPage, setShowCoverPage] = useState(props.plan && props.plan.coverOptions && props.plan.coverOptions.showCoverPage ? props.plan.coverOptions.showCoverPage : false)
    const [coverText, setCoverText] = useState(props.plan && props.plan.coverOptions && props.plan.coverOptions.textContent ? props.plan.coverOptions.textContent : "")
    const [coverTopic, setCoverTopic] = useState(props.plan && props.plan.coverOptions && props.plan.coverOptions.topicContent ? props.plan.coverOptions.topicContent : "")
    const [forceUpdate, setForceUpdate] = useState(false)
    const [paragraphTemplates, setParagraphTemplates] = useState([])
    const [filter, setFilter] = useState("")
    const [confirmEdit, setConfirmEdit] = useState(false)
    const [versionHistoryOpen, setVersionHistoryOpen] = useState(false)
    const [beingEditedOpen, setBeingEditedOpen] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(props.plan)
    const [createHubDealModal, setCreateHubDealModal] = useState(false)
    const [showTableOfContents, setShowTableOfContents] = useState((props.plan && props.plan.showTableOfContents) ? props.plan.showTableOfContents : true )
    const [planUIModal, setPlanUIModal] = useState(false)
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false)

    const dispatch = useDispatch()

    useEffect(()=>{

        const getParagraphTemplates = async() => {
            let payload = {
                jwtToken: sessionStorage.getItem("token")
            }
            const response = await executeRequest('plan/templates',payload)
            if(response && response.length > 0){
                setParagraphTemplates(response)
            }
        }
           
        getParagraphTemplates()
    },[])

    useEffect(()=>{ 
        if(props.plan){
            if(props.plan.lastDate){
                setLastDate(moment.utc(props.plan.lastDate, "MMM D, YYYY hh:mm:ss A", "en"))
            }
            if(props.plan.allowAnnotation){
                setSwitchChecked(props.plan.allowAnnotation)
            }
            if(props.plan.coverOptions){
                setCoverTopic(props.plan.coverOptions && props.plan.coverOptions.topicContent ? props.plan.coverOptions.topicContent : "")
                setCoverText(props.plan.coverOptions && props.plan.coverOptions.textContent ? props.plan.coverOptions.textContent : "")
                setShowCoverPage(props.plan && props.plan.coverOptions && props.plan.coverOptions.showCoverPage ? props.plan.coverOptions.showCoverPage: false)
                setForceUpdate(true)
            }
        }
    },[props])

    useEffect(()=>{ 
        if(lastDate){
            let today = moment.utc(new Date(),"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            let lastActive = moment.utc(lastDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(today === lastActive || today > lastActive){
                setLastActiveDay(true)

            } else setLastActiveDay(false)
        }
    },[lastDate])
   
    const saveLastDate = async() => {
        let payload = {
            lastDate: lastDate,
            planId: props.plan._id
        }

        const response = await executeRequest('plan/setLastDate', payload)
        if(response.success){
            NotificationManager.success("Viimeinen aktiivinen päivä asetettu!", "", 3000);
            setLastDayModalOpen(!lastDayModalOpen)
        } else {
            NotificationManager.error("Päivämäärän tallennuksessa tapahtui virhe", "Virhe", 3000);
        }
    }
   
    // window.onscroll = function() {scrollFunction()};
    
    // const scrollFunction = () => {
    //     var scrollButton = document.getElementById("scroll-btn");
    //     if(scrollButton !== undefined && scrollButton !== null){
    //         if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
    //             scrollButton.style.display = "block";
    //         } else {
    //             scrollButton.style.display = "none";
    //         }
    //     }
    // }

    // const scrollToTop = () => {
    //     document.body.scrollTop = 0;
    //     document.documentElement.scrollTop = 0;
    // }

    const handleEditing = async() => {
        let payload = {
            planId: props.plan._id,
        }
        const response = await executeRequest('plan/getPlan', payload)
        if((props.plan && props.plan.isSigned) || (response.plan && response.plan.isSigned)){
            setSignedPlanModal(true)

        } else{
            if(panelOpen){
                togglePanel()
            }  
            dispatch(ActionCreator.subscribeToPlan({"planId": props.plan._id}))
            props.toggleEdit()
            
        }
      
    }

    const modifySignedPlan = async() => {

        let payload = {
            planId: props.plan._id
        } 

        const response = await executeRequest('plan/modifySignedPlan', payload).then()

        if(response && response.success){
            setSignedPlanModal(false)
            dispatch(ActionCreator.subscribeToPlan({"planId": props.plan._id}))
            dispatch(ActionCreator.startEditingPlan(response.planId, true))
            NotificationManager.success("Sähköpostihuomautus lähetetty kaikille allekirjoittaneille henkilöille!","", 5000)
        } else {
            NotificationManager.error("Virhe poistaessa allekirjoituksia. Ole hyvä ja ota yhteyttä tuki@qibbie.com","Virhe", 4000)
        }
    }

    const handleAllowAnnotation = async(checked) => {
        setSwitchChecked(checked) 
        let payload = {
            planId: props.plan._id,
            allowAnnotation: checked
        }
        const response = await executeRequest('plan/allowAnnotation', payload)
        if(response.success){
            NotificationManager.success("Muutettu onnistuneesti","", 5000)
        }
    }

    useEffect(()=>{
        if(props.plan.followOptions){
            props.plan.followOptions.map(option =>{
                if(option.planOpenFollowed && option.planOpenFollowed === true){
                    setIsFollowed(true)
                }
                if(option.commentingFollowed && option.commentingFollowed === true){
                    setIsFollowed(true)
                }
                if(option.signingFollowed && option.signingFollowed === true){
                    setIsFollowed(true)
                }
            }) 
        }      
    },[props.plan])
    
    let settings = props.plan.settings || {}

    const toggleSenderModal = () => {
        setSenderModalOpen(!senderModalOpen)
    }

    const closeCoverModal = () => {
        setCoverPageModal(false)
        setCoverTopic(props.plan && props.plan.coverOptions && props.plan.coverOptions.topicContent ? props.plan.coverOptions.topicContent : "")
        setCoverText(props.plan && props.plan.coverOptions && props.plan.coverOptions.textContent ? props.plan.coverOptions.textContent : "")
        setShowCoverPage(props.plan && props.plan.coverOptions && props.plan.coverOptions.showCoverPage ? props.plan.coverOptions.showCoverPage: false)
        setForceUpdate(true)

    }

    const saveCoverOptions = async() => {
        let payload={
            planId: props.plan._id,
            coverOptions: {
                topicContent: coverTopic,
                textContent: coverText,
                showCoverPage: showCoverPage
            }
        }
        const response = await executeRequest('plan/saveCoverOptions', payload)
        if(response.success){
            dispatch(ActionCreator.startEditingPlan(response.id))
            NotificationManager.success("Muutokset tallennettu", "" , 3000)
            closeCoverModal()
        } else {
            NotificationManager.error("Virhe tallentaessa. Ole hyvä ja yritä hetken kuluttua uudelleen","", 5000)
        }
    }

    const handleTemplateSelection = (topic, text) => {
        setCoverTopic(topic)
        setCoverText(text)
        setForceUpdate(true)
    }
    
    const toggleShowClient = async() => {
        let payload = {
            planId: props.plan._id,
            hideClient: props.plan.hideClient? !props.plan.hideClient : true
        }
        const response = await executeRequest('plan/toggleClientVisibility', payload)
        if(response.success){
            dispatch(ActionCreator.startEditingPlan(response.id))
            NotificationManager.success("Muutokset tallennettu", "" , 3000)
           
        } else {
            NotificationManager.error("Virhe tallentaessa. Ole hyvä ja yritä hetken kuluttua uudelleen","", 5000)
        }
    }

    /*

        Plan subscription is checked here just in case of prevention of data loss in plan edit 
    */
    const toggleBeingEdited = async(closeModal) => {
        let payload = {
            planId:  props.plan._id
        }
        const response = await executeRequest("plan/getPlanSubscribers", payload)
        if(!closeModal && response && response.length > 0){
            setSelectedPlan({
                ...selectedPlan,
                beingEditedBy: response
            })
            setBeingEditedOpen(true)
        } else{
            if(!closeModal){
                if((!props.plan.beingEditedBy || (props.plan.beingEditedBy && props.plan.beingEditedBy.length === 0))){
                    handleEditing()
                } else {
                    if(response.length === 0){
                        handleEditing()
                    } else{
                        setBeingEditedOpen(false)
                    }
                }
            }
            else {
                setBeingEditedOpen(false)
            }
        } 
    }

    const updateAutomationSummary = () => {
        dispatch(ActionCreator.updateAutomationSummary({planId: props.plan._id}))
    }

    const toggleTableOfContents = () => {
        dispatch(ActionCreator.toggleShowTableOfContents({planId: props.plan._id, showTableOfContents: !showTableOfContents }))
        setShowTableOfContents(!showTableOfContents)
    }

    const togglePlanUIModal = () => {
        setPlanUIModal(!planUIModal)
    }
    return (
        <div className="whitebox-plan" style={{ marginTop: '1rem', padding:0}}>
            {props.plan && props.plan.planItems && modulesInUse.betaTesting && hasHubSpot && <CreateHubSpotDealModal modalOpen={createHubDealModal} setModal={setCreateHubDealModal} plan={props.plan}/>}
            <Modal size="lg" isOpen={senderModalOpen} toggle={()=> toggleSenderModal()}>
                <ModalHeader toggle={()=> toggleSenderModal()}>Lähetä lomake sähköpostilla</ModalHeader>
                <ModalBody> 
                    <PersonalEmailSender
                        text={"\n\nVoit siirtyä lomakkeeseen alla olevasta linkistä:\n" + `${confs.frontpageUrl}/plan/${props.plan._id}`+ "\n\n" + (props.plan.passwordOn? "Lomakkeen avaamiseen tarvitaan myös salasana!" : "")}
                    />
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => toggleSenderModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={confirmEdit} toggle={()=> setConfirmEdit(!confirmEdit)}>
                <ModalHeader toggle={()=> setConfirmEdit(!confirmEdit)}>Varmista siirtyminen</ModalHeader>
                <ModalBody> 
                    <p>Olet siirtymässä muokkaamaan lomakepohjaa, ole hyvä ja varmista siirtyminen</p>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setConfirmEdit(!confirmEdit)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => handleEditing()} className="small-white-button wide-button" >Jatka muokkaamaan</button>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={coverPageModal}>
                <ModalHeader toggle={() => setCoverPageModal(!coverPageModal)}>Kansilehden asetukset</ModalHeader>
                <ModalBody>
                    <Row style={{ minHeight: '30em' }}>
                        <Col xs="3" className="cover-page-side-panel" style={{ minHeight: '30em', height: 'inherit' }}>
                            <div className="cover-side-panel-header">
                                <p className="cover-side-panel-title">Kappalepohjat</p>
                                <div style={ { marginLeft: '-0.5em', marginBottom: '0.8em', marginTop: '0.5em' } }>
                                    <form id="search-bar">
                                        <input type="search" style={{width: '85%', marginLeft: '0.5em'}} className="search-bar-input" placeholder="Etsi pohjaa" value={ filter } name="surveyFilter" onChange={ ( event ) => setFilter(event.target.value) } />
                                    </form>
                                </div>
                            </div>
                            <Scrollbars
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                style={{ width: "100%", height: '100%' }}
                                className="custom-scrollbars"
                                autoHeight={true}
                                autoHeightMin={'28em'}
                            >   
                            {paragraphTemplates && paragraphTemplates.length > 0 && paragraphTemplates.map((templateItem,key)=> {
                                const {topicContent, textContent} = getPlainTexts(templateItem.topicContent, templateItem.textContent);
                                return(
                                    <Row key={key} style={{paddingBottom: '0.25em', paddingTop: '0.5em'}} className={`paragraph-template-item template-item-${key}`} onClick={() => handleTemplateSelection(topicContent, textContent)}>
                                        <Col xl="12">                      
                                            <h6 style={{fontWeight: 700, marginBottom: 0}} className="block-with-text">{topicContent}</h6>
                                        </Col>
                                        <Col xl="12">
                                            <p className="block-with-text">{textContent}</p>
                                        </Col>
                                    </Row>
                                )
                            })}
                            {!paragraphTemplates.length > 0 && 
                                <p>Ei kappalepohjia</p>
                            }
                            </Scrollbars>    
                        </Col>
                        <Col xs="9">
                            <Row>
                                <Col xs="8">
                                    <div>
                                        {"Näytä kansilehti julkaistussa lomakkeessa"}
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div style={{ display: 'flex', float: 'right'}}>
                                        <label className="switch">
                                            <input id="switch-input" type="checkbox" onChange={() => setShowCoverPage(!showCoverPage)} checked={showCoverPage}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="12" style={{ marginTop: '2em'}} className="title-col">
                                    <Label>Kansilehden otsikko:</Label>
                                    <TextEditor 
                                        editorId={'cover-title'}
                                        content={coverTopic} 
                                        onTextChange={editorState => setCoverTopic(editorState)}
                                        additionalClass="cover-heading subTitle"
                                        plaintextEditor={true}
                                        needToForceUpdate={forceUpdate}
                                        setForceUpdate={setForceUpdate}
                                    /> 
                                </Col>
                                <Col xl="12" style={{paddingTop: '0.8em'}} className="plan-text-col">
                                    <Label>Kansilehden teksti:</Label>
                                    <TextEditor 
                                        editorId={'cover-topic'} 
                                        content={coverText} 
                                        onTextChange={editorState => setCoverText(editorState)}
                                        additionalClass="cover-text"
                                        needToForceUpdate={forceUpdate}
                                        setForceUpdate={setForceUpdate}
                                        connectionType={"plans"}
                                        documentId={props.plan._id}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => closeCoverModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => saveCoverOptions()} className="small-white-button wide-button" >Tallenna</button>
                </ModalFooter>
            </Modal>
                <Modal isOpen={versionHistoryOpen} toggle={() => setVersionHistoryOpen(false)}>
                <ModalHeader toggle={() => setVersionHistoryOpen(false)}>Versiohistoria</ModalHeader>
                <ModalBody>
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '500px'}} 
                    >
                        {props.plan && props.plan.history && props.plan.history.map((historyItem, key) => {
                            return(
                                <div key={key}>
                                    <p style={{marginTop: '0.5em'}}>v{(props.plan.history.length - (key+1)) + ' - '}{moment.utc(historyItem.timestamp,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY  HH:mm')}</p>
                                </div>
                            )
                        })}
                    </Scrollbars>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setVersionHistoryOpen(false)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Sulje</a>
                </ModalFooter>
                </Modal>
                {props.plan && <PlanLayoutEditModal plan={props.plan} modalOpen={planUIModal} toggle={togglePlanUIModal} planPreviewProps={props} savePlanLayout={savePlanLayout}/>}
                {/* {props.plan && <PlanLayoutEditor plan={props.plan} modalOpen={planUIModal} toggle={togglePlanUIModal} />} */}
                <PlanBeingEditedModal plan={props.plan} goToEdit={handleEditing} isOpen={beingEditedOpen} toggle={toggleBeingEdited}/>
            <div className="" style={{ display:'flex', alignItems: 'center', justifyContent: 'center ', height:'2.5em', borderRadius: '10px 10px 0 0', fontWeight: 'bold' }}> 
                Esikatselu 

                <div className="dropdown-plan-settings-preview">
                    <Dropdown isOpen={openDropDown} style={{ cursor: 'pointer' }} onClick={(event) => event.stopPropagation()} toggle={() => toggleDropDown(!openDropDown)} >
                        <DropdownToggle tag="div">
                            <div className="small-white-button plan-option-toggler" title="Lomakkeen asetukset" onClick={() => toggleDropDown(!openDropDown)} style={{ backgroundColor: "#6ba53f", color: '#FFF', fontWeight: 700, width: '2em'}}><i className="fa fa-cog"></i></div>
                        </DropdownToggle>
                        <DropdownMenu>
                            {!props.plan.automationSummary && 
                                (props.plan.template?
                                    <DropdownItem onClick={() => setConfirmEdit(true)} > Muokkaa lomaketta </DropdownItem>
                                    :
                                    <DropdownItem onClick={() => toggleBeingEdited(false)} > Muokkaa lomaketta </DropdownItem>
                                )
                            }
                            {props.plan.automationSummary && <DropdownItem onClick={()=> updateAutomationSummary()}>Päivitä lomake </DropdownItem>}
                            <DropdownItem onClick={()=> window.print()}>Tulosta lomake </DropdownItem>
                            <DropdownItem onClick={() => setTagModalOpen(true)}> Hallitse tageja </DropdownItem>
                            {!props.plan.template &&
                                <>
                                    <DropdownItem onClick={() => setLastDayModalOpen(true)}> Muuta viimeistä aktiivista päivää </DropdownItem>
                                    <DropdownItem onClick={() => setCoverPageModal(true)}>Muokkaa lomakkeen kansilehteä</DropdownItem>
                                    <DropdownItem onClick={()=>setPrivacyModalOpen(true)}>Yksityisyys</DropdownItem>
                                    {modulesInUse.betaTesting && <DropdownItem onClick={()=> setPlanUIModal(true)} >Muokkaa lomakkeen ulkoasua</DropdownItem>}
                                    {/* <DropdownItem onClick={()=> printPage()}>Tulosta pdf</DropdownItem> */}
                                    {status === 'published' ? ( 
                                        <>  
                                            <DropdownItem onClick={() => setFollowModalOpen(true)}>Seuraamisen asetukset</DropdownItem>
                                            <DropdownItem onClick={togglePasswordModal} className="password-change">Vaihda salasana</DropdownItem>
                                            {isSigned !== true && <DropdownItem onClick={toggleUnpublishModal} className="hide-plan">Piilota lomake </DropdownItem>}
                                            <DropdownItem onClick={toggleSenderModal} className="send-plan">Lähetä sähköpostilla</DropdownItem>
                                        </>
                                    ):(
                                        <>
                                            {automation ?
                                                <DropdownItem onClick={props.openAutomationSettings}>Generoinnin asetukset</DropdownItem>
                                                :
                                                <DropdownItem onClick={props.openStatusModal}>Julkaise lomake </DropdownItem>
                                            }
                                        </>
                                    )}
                                    {props.plan && !clientInfo && !automation &&
                                        <DropdownItem onClick={toggleMovePlanModal} className="hide-plan">Siirrä yritykselle</DropdownItem>
                                    }
                                    {props.plan.history && 
                                        <DropdownItem onClick={() => setVersionHistoryOpen(true)}>Versiohistoria</DropdownItem>
                                    }
                                    {hasHubSpot && selectedBusiness && selectedBusiness.value !== 'anon' && <DropdownItem onClick={() => setCreateHubDealModal(true)}>Luo HubSpot diili</DropdownItem>}
                                    <DropdownItem onClick={() => toggleShowClient()}>{(modulesInUse && modulesInUse.customPlanTargetName)?  ((props.plan && props.plan.hideClient)? "Näytä vastaanottaja" : "Piilota vastaanottaja") : ((props.plan && props.plan.hideClient)? "Näytä asiakas" : "Piilota asiakas")}</DropdownItem>
                                    <DropdownItem onClick={()=> toggleTableOfContents()} className="send-plan">{showTableOfContents? "Piilota sisällysluettelo" : "Näytä sisällysluettelo"}</DropdownItem>
                                </>
                            }

                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            <ScrollToTopButton/>
            <Modal isOpen={signedPlanModal} toggle={() => setSignedPlanModal(!signedPlanModal)}>
                <ModalHeader toggle={() => setSignedPlanModal(!signedPlanModal)}> <div style={{ color: 'red' }}>Huom!</div> </ModalHeader>
                <ModalBody>
                    <p>Olet siirtymässä muokkaamaan allekirjoitettua lomaketta!</p>
                    <p style={{ marginTop: '0.5em' }}>Mikäli jatkat muokkaamaan lomaketta kaikki allekirjoitukset kumotaan ja kumoamisesta ilmoitetaan allekirjoittaneille sähköpostitse.</p>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setSignedPlanModal(!signedPlanModal)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={()=> modifySignedPlan()} className="small-white-button wide-button" >Jatka muokkaamaan</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={tagModalOpen} toggle={() => setTagModalOpen(!tagModalOpen)}>
                <ModalHeader toggle={() => setTagModalOpen(!tagModalOpen)}> Hallitse tageja </ModalHeader>
                <ModalBody>
                    <Label>Tagit</Label>
                    <TagListEdit tagOptions={props.tagOptions} setTagOptions={props.setTagOptions}/>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setTagModalOpen(!tagModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={()=> (props.saveWithClick(),setTagModalOpen(!tagModalOpen))} className="small-white-button wide-button">Tallenna</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={lastDayModalOpen} toggle={() => setLastDayModalOpen(!lastDayModalOpen)}>
                <ModalHeader toggle={() => setLastDayModalOpen(!lastDayModalOpen)}> Aseta viimeinen aktiivinen päivä</ModalHeader>
                <ModalBody>
                    <Label>Valittu päivä</Label>
                    <div style={{width: '11.5em' }}>
                        <SingleDatePicker
                            date={lastDate}
                            id="dateId"
                            onDateChange={(date) => setLastDate(date)}
                            placeholder="Viimeinen pvm"
                            appendToBody={false}
                            focused={focusedDateInput}
                            onFocusChange={({focused})  => setFocusedDateInput(focused)}
                            hideKeyboardShortcutsPanel={true}
                            showClearDate 
                            block
                            readOnly
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setLastDayModalOpen(!lastDayModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={()=> saveLastDate()} className="small-white-button wide-button swb-hover delete-plan" >Tallenna</button>
                </ModalFooter>
            </Modal>
                <PlanActivatorModal isOpen={props.statusModal} toggle={props.openStatusModal} changePlanStatus={props.changePlanStatus} status={status} />
                {passwordModal && <PasswordModal isPasswordOn={passwordOn} isOpen={passwordModal} toggle={togglePasswordModal} saveChanges={changePasswordSettings} />}
                {automation && <AutomaticPlanSettings isOpen={isSettingsOpen} toggle={props.openAutomationSettings} settings={settings} save={savePlanSettings} />}
                {movePlanToBusinessModalOpen && <MoveToBusinessModal isOpen={movePlanToBusinessModalOpen} toggle={toggleMovePlanModal} modulesInUse={modulesInUse} planId={props.plan._id}/>}
                {modulesInUse.privateDocuments && <PrivatePlanModal isOpen={privacyModalOpen} toggle={setPrivacyModalOpen} plan={props.plan} savePlanPrivacySettings={props.savePlanPrivacySettings}/>}
                <div className="plan-main-body" style={{ display: 'flex', flexDirection: 'column', padding: " 1em 2em 2em 3em" }}>
                    <Row>
                        <Col xl={{ size: 12 }} style={{ paddingBottom: '1.5em' }}>
                            {props.loading && <MegaLoaderSpinner />}
                            <Row>
                                <Col>
                                    <TagList tagOptions={props.plan.tags}/>
                                </Col>
                            </Row>
                            {props.plan && props.plan.template &&
                                <Row>
                                    <Col style={{textAlign: 'center', marginTop: '0.5em'}}>
                                        <h4 style={{ color: "#cfcfcf", fontWeight: 'bolder' }} > {"LOMAKEPOHJA"} </h4>
                                    </Col>
                                </Row>
                            }
                            {props.plan && props.plan.automationSummary &&
                                <Row>
                                    <Col style={{textAlign: 'center', marginTop: '0.5em'}}>
                                        <h4 style={{ color: "#cfcfcf", fontWeight: 'bolder' }} > {"KOOSTE"} </h4>
                                    </Col>
                                </Row>
                            }
                            <Row className="plan-main-row">
                                <Col xl="12" style={{ paddingTop: 5 }}>
                                    <Row style={{ display: 'flex' }}>
                                        <Col xs="3" style={{ display: 'flex'}} >
                                            <div className="logo-container-nav-bar" style={props.logoStyle}></div>
                                        </Col>
                                        <Col xs="6">
                                            <h3 className="topicText" title={props.plan.planName} style={{ marginTop: '0.5em' }}>{props.plan.planName}</h3>   
                                        </Col>                          
                                        <Col xs="3">
                                            <Row>
                                                <Col xs='12' style={{float: 'right' }}>
                                                    {props.plan.template &&
                                                        <div>
                                                            <div>
                                                                <CreatePlanFromTemplate      
                                                                    planId={props.plan._id}
                                                                    projectId={projectId}
                                                                    editPlan={props.toggleEdit}
                                                                    history={history}
                                                                /> 
                                                            </div>
                                                        </div>
                                                    }
                                                    <div>
                                                        <FollowOptions     
                                                            planId={props.plan._id}
                                                            setFollowModalOpen={setFollowModalOpen}
                                                            followModalOpen={followModalOpen}
                                                            modulesInUse={modulesInUse}
                                                        />
                                                      
                                                        {props.plan.isPrivateDocument &&   <div style={{ display: 'flex', float: 'right', alignItems:'center'}}> <i className="far fa-eye-slash" style={{color: 'red'}} title='Yksityinen'></i><p style={{color: 'red', marginLeft:'0.5em', fontSize:'0.9rem', fontWeight:'bold'}}> {' Yksityinen'}</p></div>}
                                                        <div style={{ display: 'flex', float: 'right'}}>
                                                            {(props.plan && props.plan.annotations&& props.plan.annotations.length > 0) &&
                                                                <>
                                                                    <Modal isOpen={commentModalOpen} toggle={() => setCommentModalOpen(!commentModalOpen)}>
                                                                        <ModalHeader toggle={() => setCommentModalOpen(!commentModalOpen)}>Lomakkeen kommentit</ModalHeader>
                                                                        <ModalBody>
                                                                            <Col>
                                                                                <div className="comment-section" style={{ marginTop: '1em', overflowY: 'auto', marginLeft:'2em'}}>
                                                                                    {props.plan.annotations && props.plan.annotations.length > 0 && props.plan.annotations.map((annotation, key) => {
                                                                                        return(
                                                                                            <Fragment key={key}>
                                                                                                <Annotation 
                                                                                                    item={annotation}
                                                                                                />
                                                                                            </Fragment>
                                                                                        )
                                                                                    })}
                                                                                </div>   
                                                                            </Col>
                                                                        </ModalBody>
                                                                        <ModalFooter>
                                                                            <a onClick={() => setCommentModalOpen(!commentModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Sulje</a>
                                                                        </ModalFooter>
                                                                    </Modal>
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => setCommentModalOpen(true)}>
                                                                        <i className="far fa-comment-dots" style={{ fontSize: '2rem', color: '#b5e413'}} title="Sisältää kommentteja"></i>
                                                                    </div>
                                                                </>
                                                            }
                                                            {isFollowed && <div style={{ float: 'right', fontSize: '1.5rem', marginLeft: '1em', opacity: '0.8' }} title="Lomaketta seurataan"><i className="far fa-eye"></i></div>}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row> 
                                        </Col>
                                    </Row>
                                    <Row className="published-row">
                                        <Col xs="12">
                                            {props.plan && modulesInUse.annotationManagement &&  
                                                <div style={{ float: 'right', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <p style={{ marginRight: '1em', fontWeight: 'bold', opacity: 0.6 }}>Salli kommentointi</p>
                                                    <label className="switch">
                                                        <input id="switch-input" type="checkbox" onChange={() => handleAllowAnnotation(!switchChecked)} checked={switchChecked}/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            }
                                        </Col>
                                        <Col xs='12' style={{float: 'right' }}>
                                            {props.plan.publishedDate !== undefined && 
                                                <PublishedByContainer 
                                                    publishedDate={props.plan.publishedDate} 
                                                    publishedBy={props.plan.publishedBy} 
                                                    copyPlanLink={props.copyPlanLink} 
                                                    linkRef={linkRef} 
                                                    frontpageUrl={confs.frontpageUrl}
                                                    planId={props.plan._id}
                                                />
                                            }
                                        </Col>
                                        <Col  xs='12'>
                                            <div style={{ marginTop: '0.5em', float: 'right', fontWeight: 'bold' }}>
                                                {(props.plan && props.plan.allSigned)?
                                                    <p style={{ color: '#b5e413' }}>Allekirjoitettu</p>
                                                    :
                                                    ((props.plan && props.plan.isSigned === true)? 
                                                        <p style={{ color: '#ffcc00' }}>Allekirjoitettu osin</p>
                                                        :
                                                        ""
                                                    )
                                                
                                                } 
                                            </div>
                                        </Col>
                                        <Col xs='12' style={{float: 'right' }}>
                                            <div style={{ maxHeight:'1.5rem', marginTop: '0.5em' }}>
                                                {lastDate && 
                                                    <p style={{ float: 'right' }}>Viimeinen aktiivinen päivä: <span style={lastActiveDay?{ color:'red' } : {}}>{moment.utc(lastDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>
                                                }
                                            </div>
                                        </Col>
                                    </Row> 
                                    <div style={{ marginTop: '1em' }}>
                                        {(props.plan.lastEdited || props.plan.modified !== undefined) && 
                                            <ModifiedContainer modified={props.plan.lastEdited? props.plan.lastEdited : props.plan.modified} modifiedBy={props.plan.modifiedByUser} />
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {clientInfo !== undefined && props.plan && !props.plan.hideClient &&
                                <Row className="plan-main-row">
                                    <Col xl="12" style={{ paddingTop: '1.5em'}} className="plan-sub-row">
                                        <h4 style={{ fontWeight: 700 }}>{(modulesInUse && modulesInUse.customPlanTargetName)?  'Vastaanottaja' : 'Asiakas'}</h4>
                                    </Col>
                                    <Col xl="12" className="plan-sub-row" style={{}}>
                                        <p className="block-with-text">{clientInfo.businessName}</p>
                                        {clientInfo.businessID !== null && <p className="block-with-text">{clientInfo.businessID}</p>}
                                        {clientInfo.address !== null && <p className="block-with-text">{clientInfo.address}</p>}
                                        <p className="block-with-text">{clientInfo.postCode !== undefined && clientInfo.postCode} {clientInfo.postOffice !== undefined && clientInfo.postOffice}</p>
                                    </Col>
                                </Row>
                            }
                            <Row className="plan-main-row">
                                {showTableOfContents && <Summary planItems={planItems} startScrolling={startScrolling} />}
                                <Col xl="12" className="plan-sub-row">
                                    <div className="dragable-window">
                                        {(Object.keys(props.plan).length > 0 && planItems !== undefined)
                                            && planItems.map((i, index) =>
                                                <div key={i.id+"-"+index}>
                                                    {checkPreviewItemType(i, index, props)}
                                                    {i.id === chosenTopicId && <div className="scroll-to-this" ref={itemRef}></div>}
                                                </div>
                                            )}
                                    </div>
                                </Col>

                                {(props.modulesInUse.surveyManagement && props.plan.relatedSurveys !== undefined) && !props.plan.template &&
                                    <Col xl="12" className="plan-sub-row" style={{paddingBottom: '0.8em' }}>
                                        <div style={{ paddingBottom: '1em', cursor: 'pointer', width: '15em' }}><h4>Kartoitukset</h4></div>
                                        <>
                                            {props.plan.relatedSurveys.map(item =>
                                                <SurveyButton label={item.label} key={item.id} selectReport={props.selectReport} survey={item} />
                                            )}
                                        </>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
        </div>
    )
})

const TagList = props => {

    return (
       <div className="tag-div" style={{display:"flex",flexWrap:"wrap", justifyContent:'center'}}>
          {props.tagOptions&& props.tagOptions.map((tag, i) => {
              return(<div key={i} style={{paddingTop:"0.25em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
          })}
       </div>
    )
}



//After user clicks new tag, this will be displayed above the button
const TagListEdit = props => {
    
    async function promiseOptions(inputValue) {
        let params = {
            data: inputValue,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const data = fetch(confs.url + 'tagSearch', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => response.json())

        let tags = await data;

        return tags
    };

    function NoOptionsMessage(props) {
        if (props.options.length) return null;
        return <div {...props.innerProps} style={{padding: 5}}>Voit hakea tai lisätä tageja kirjoittamalla</div>;
      }

    const components = {
        NoOptionsMessage
    }

    function formatCreateLabelCreator(value) {
        return 'Lisää tagi: "' + value + '"'
      }

    return (
        // <Input placeholder="Uusi tagi" onChange={props.searchTags} ></Input>    
            <AsyncCreatableSelect 
                loadOptions={promiseOptions} 
                components={components} 
                isMulti ={true}
                value={props.tagOptions} 
                onChange={selectedTags => props.setTagOptions(selectedTags)}
                placeholder=""
                className='react-select-container'
                classNamePrefix="react-select"
                formatCreateLabel={formatCreateLabelCreator} 
                />
    )
}

const mapStateToProps = state => {
    return {
        fetchStatus: state.reports.taskModifyStatus,
        logo: state.authentication.logo,
        startNewPlan: state.plan.startBuilding,
        planId: state.plan.plan._id,
        plan: state.plan.plan,
        risksById: state.plan.risksById,
        tasksById: state.plan.tasksById,
        created: state.plan.created,
        editPlan: state.plan.editPlan,
        businessName: state.authentication.businessName,
        modulesInUse: state.authentication.modulesInUse,
        hasHubSpot: state.authentication.hasHubSpot
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onItemAdd: (selectedItem, index, destClone, planId) => dispatch(createNewPlanItem(selectedItem, index, destClone, planId)),
        onItemAdd_Template: (selectedItem, index, destClone, planId) => dispatch(createNewPlanItemFromTemplate(selectedItem, index, destClone, planId)),
        onReorder: (planItems, planId) => dispatch(reOrderPlanItems(planItems, planId)),
        onItemChange: (planItem) => dispatch(setPlanItem(planItem)),
        addModifyPlanRisk: (params) => dispatch(addModifyPlanRisk(params)),
        onSave: (planId, planItems, planTitle, clientInfo, tags) => dispatch(savePlanItems(planId, planItems, planTitle, clientInfo, tags)),
        onTitleChange: (title) => dispatch(handleTitle(title)),
        onTemplateAdd: (planItem) => dispatch(createTemplateItem(planItem)),
        getTemplates: () => dispatch(getTemplateItems()),
        fetchRequestFinished: (status) => dispatch(fetchRequestFinished(status)),
        fetchRequestStart: () => dispatch(fetchRequestStart()),
        saveTargetSurveys: (params) => dispatch(saveTargetSurveys(params)),
        toggleEdit: () => dispatch(changeEditMode()),
        getReportForDashboard: (payload) => dispatch(getReportForDashboard(payload)),
        getReportTree: (payload) => dispatch(getBusinessSurveyTree(payload)),
        storeBusiness: (payload) => dispatch(storeBusiness(payload)),
        setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
        selectBusinessForReport: (payload) => dispatch(selectBusinessForReport(payload)),
        addMoreBreadcrumbs: (crumbs) => dispatch(addMoreBreadcrumbs(crumbs)),
        onPublish: (payload) => dispatch(publishPlan(payload)),
        onPlanItemDelete: (payload) => dispatch(deletePlanItem(payload)),
        onItemToggle: (payload) => dispatch(toggleItem(payload)),
        onSavePasswordSettings: (payload) => dispatch(handlePasswordSettings(payload)),
        fetchSurveyVariables: (payload) => dispatch(fetchAllSurveyVariablesForPlans(payload)),
        fetchPlanVariables: (payload) => dispatch(fetchAllVariablesForPlan(payload)),
        savePlanSettings: (payload) => dispatch(savePlanSettings(payload)),
        resetPlanSelection: (payload) => dispatch(resetPlanSelection(payload)),
        onEdit: (planId) => dispatch(ActionCreator.startEditingPlan(planId)),
        getRiskEvaluations: (params) => dispatch(ActionCreator2.fetchRisks(params)),
        unsubscribePlanEdit: (params) => dispatch(ActionCreator.unsubscribePlanEdit(params)),
        updateDeals: (params) => dispatch(ActionCreator.updateDeals(params)),
        savePlanLayout: (payload) => dispatch(savePlanLayout(payload)),
        savePlanPrivacySettings: (payload) => dispatch(savePlanPrivacySettings(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanbuilderContainer);


import React, { Component } from 'react';
import { TwitterPicker } from 'react-color';

const listOfDefaultColors = [
    '#B5E413',
    '#ED5652', 
    '#FFCC00', 
    '#7BDCB5', 
    '#00D084', 
    '#8ED1FC', 
    '#0693E3', 
    '#ABB8C3', 
    '#EB144C', 
    '#F78DA7', 
    '#9900EF'
];

const Square = props => {
    return (
        <div className="square" onClick={typeof props.toggler !== 'undefined' ? () => props.toggler() : null} style={{backgroundColor: props.colorBackGround ? props.colorBackGround: '#B5E413'}}></div>
    )
}

const setDefaultColors = (defaultColors) => {
    let colorArray = [...defaultColors]
    if(getComputedStyle(document.documentElement).getPropertyValue('--enterprise-secondary-color') && getComputedStyle(document.documentElement).getPropertyValue('--enterprise-primary-color')){
        let color1 = getComputedStyle(document.documentElement).getPropertyValue('--enterprise-secondary-color')
        let color2 = getComputedStyle(document.documentElement).getPropertyValue('--enterprise-primary-color')
        colorArray = [...defaultColors, color1, color2]
    }
    return(colorArray)
}

export default class ColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultColors: setDefaultColors(listOfDefaultColors),
            width: 300,
            isOpen: false,
            color: '#B5E413'
        }
        this.togglePicker = this.togglePicker.bind(this);
        this.handleChangeComplete = this.handleChangeComplete.bind(this);
    }
    togglePicker() {
        if(this.props.readOnly === undefined) {
            this.setState({isOpen: !this.state.isOpen});
        }
    }

    handleChangeComplete = (color, event) => {
        this.props.handleColor(color.hex, this.props.index);
        this.setState({isOpen: false});
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                <Square toggler={this.togglePicker} colorBackGround={this.props.color} style={{marginTop: this.props.noPadding ? this.props.noPadding : null}}/>                
                {this.state.isOpen &&
                    <TwitterPicker className="colorPicker"
                        width={this.state.width}
                        colors={this.state.defaultColors}
                        triangle="hide"
                        color={this.props.color}
                        onChangeComplete={this.handleChangeComplete}
                    />
                }
            </div>
        )
    }
}
import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import NewPlanTarget from '../../planBuilder/NewPlanTarget';
import Select from 'react-select';
import './createNewModal.css'
import { NotificationManager } from 'react-notifications';  
import { useDispatch, useSelector } from 'react-redux';
import CompanyLink from '../../Clients/subComponents/CompanyRegistrationV2';
import moment from 'moment';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { debounce } from 'debounce';
import { DateRangePicker } from 'react-dates';
import MultiSelect from "@kenshooui/react-multi-select";
import * as ActionCreator from '../../Kartoitus/SenderV2/Actions/ActionCreator'
import * as AllActions from '../../../store/index'
import { ProjectDataModal, ProjectDataWithoutModal } from '../../Project/ProjectDataModal';
import BackgroundEditor from '../../Kartoitus/SenderV2/subComponents/BackgroundEditor';
import { confs } from '../../../modules/config';

const INITIAL_STATE_SURVEY = {
    name: "",
    targets: [],
    startDate: null,
    endDate: null,
    surveys: [],
    startSending: true,
    dates: [],
    background: null,
    backgroundSet: false,
    hideMenu: false,
    //resetBackground: true
}

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi kartoitusta...",
        noItemsMessage: "Valitse kartoitus",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
};

export const CreateNewModal = ({history, clientList}) => {
    const modulesInUse = useSelector(state => state.authentication.modulesInUse)
    const [modalOpen, setModalOpen] = useState(false)
    const [selection, setSelection] = useState(null)
    const [targetBusiness, setTargetBusiness] = useState(null)
    const [resendToCompany, setResendToCompany] = useState(null)
    const [focusedDateInput, setFocusedDateInput] = useState()
    const [survey, setSurvey] = useState(INITIAL_STATE_SURVEY)
    const [showDates, setShowDates] = useState(false)
    const [availableSurveys, setAvailableSurveys] = useState()
    const [selectedDate, setselectedDate] = useState({startDate:null,endDate:null})
    const [background, setBackGround] = useState()
    const [hideMenu, setHideMenu] = useState(false)
    const [resetBackground, setResetBackground] = useState(false)
    const [editBackground, setEditBackGround] = useState(false)
    const [backgroundList, setBackgroundList] = useState([])
    const [steps, setSteps] = useState(1)
    const [surveyCode, setSurveyCode] = useState(null)
    const [createdSurvey, setCreatedSurvey] = useState(null)
    const [originalSurvey, setOriginalSurvey] = useState(null)

    const dispatch = useDispatch();

    const toggleModal = () => {
        setModalOpen(!modalOpen)
        clearAll()
    }
    const clearAll = () => {
        setSteps(1)
        setSelection(null)
        setTargetBusiness(null)
        setSurvey(INITIAL_STATE_SURVEY)
    }

    const startNewProject = (projectId) => {
        if(projectId){
            history.push({
                pathname:'projektit/hallitse',
                state: {
                    projectId:projectId
                }
            })
          } else {
            NotificationManager.error("Virhe siirtyessä projektiin", "", 3000);
          }
    }

    const resendPermissionRequest = company => {
        setResendToCompany(company)
    }

    const handleSurveyChange = (event) => {
        const { id, value } = event.target
        setSurvey(prevData => ({
            ...prevData,
            [id]: value
        }))
        if(id=="name"){
            afterSurveySelection()
        }
    }

    const setDates = (date) => {
        setselectedDate(date)
       
        if (date.endDate !== null && date.startDate !== null) {
            setSurvey(prevData => ({
                ...prevData,
                startDate: null,
                endDate: null,
                dates:[...prevData.dates, date]
                
            }))
            setselectedDate({startDate:null, endDate:null})
        }       
    }

    const removeDateFromList = (index) => {
        let obj = [...survey.dates]
        obj.splice(index, 1)
        setSurvey(prevData =>({ 
            ...prevData,
            dates: obj 
        }))
    }

    const  isDayBlocked = (day) => {
        let compare = moment(new Date(day._d))

        for (let date of survey.dates) {
            let start = moment(new Date(date.startDate._d))
            let end = moment(new Date(date.endDate._d))
            if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                 return true;
            }
        }
        return false;

    }

    useEffect(() => {           
        const getSurveys = async () => {
            const result = await executeRequest('sender/get/availableSurveys', {})
            if(!!result) {
                setAvailableSurveys(result)
            }
        }
        getSurveys()
    }, [])

    const activateSurvey = async() => {
        let obj = survey
        if (background) {
            obj = { ...obj, background: background }
        }

        if (hideMenu) {
            obj = { ...obj, hideMenu: hideMenu };
        }

        let jwt = window.sessionStorage.getItem("token");
        let payload = {
            ...obj,
            jwtToken: jwt
        }
        const response = await executeRequest('sender/send/survey', payload)
   
        if( typeof response.success === 'undefined' || response.success ) {
            if ( response.status === "sent" ) {
                NotificationManager.success( "Koodilla " + response.code, "Kartoitus on luotu", 4000 )
            } 
            setSurveyCode(response.code)
            setCreatedSurvey(response.sentSurvey)
            setOriginalSurvey(response.originalSurvey)
            dispatch( ActionCreator.addSurveyToList( response.sentSurvey ) )
            let autoSelect = response.autoSelect
            dispatch( ActionCreator.getGroupedSurveys() )
            dispatch( ActionCreator.autoSelectSurvey( autoSelect ) );
            setSteps(steps+1)
            updateSurveys()
        } else {
            // most likely tried to send survey somehow even when has been restricted by licence
            NotificationManager.error( "Kartoitusta ei voitu lähettää", "Virhe", 2000 );        
        }        
    }

    const closeActivateSurvey = () => {
        toggleModal()
        setSurvey(INITIAL_STATE_SURVEY)
        setResetBackground(false)
    }

    const updateSurveys = () => {
        dispatch(ActionCreator.getSentSurveysForDashboard())
    }

    const handleSurveySelection = selectedItems => {
        setSurvey(prevData => ({
            ...prevData,
            surveys: selectedItems,
        }))
        afterSurveySelection()
    }

    const handleBGChange = (bgSettings) =>{
        setSurvey((prevData)=>({
            ...prevData,
            backgroundSet:bgSettings.backgroundSet,
            background: bgSettings.background 
        }))
        afterSurveySelection()
    }

    useEffect(()=>{
        getBackgrounds()
    },[])

    const getBackgrounds = async() => {
        const result = await executeRequest('sender/get/backgroundList', {})       
        if(result){
            setBackgroundList(result)
        }
    }

    const saveBackground = (obj) => {
        setSurvey((prevData)=>({
            ...prevData,
            background: obj, 
            backgroundSet: true
        }))
        setEditBackGround(false)
    }

    const saveModifiedBackground = async(obj) => {
        let payload = {
            sentSurvey: survey.backgroundEdit,
            background: obj
        }
        const result = await executeRequest('sender/modify/background', payload)
        setSurvey((prevData)=>({
            ...prevData,
            backgroundEdit: null, 
            background: null
        }))
        setEditBackGround(false)
    }

    const uploadBackground = async(payload) => {try {
        const responseJson = await fetch(confs.url + 'sender/uploadBackground', {
            method: 'POST',
            body: payload
        })

        if(responseJson) {
            const response = await responseJson.json()
            getBackgrounds()
            return response;
        } else {
            return false;
        }
    } catch(error) {
        return Promise.reject(error);
    }
    }
    const senderRef = useRef();

    const afterSurveySelection = debounce(() => {
        scrollToMyRef();
    }, 800);

    const scrollToMyRef = () => {   
        if(senderRef && senderRef.current){
            senderRef.current.parentElement.scroll({ top: senderRef.current.offsetTop, left: 0, behavior: 'smooth' })
        } else {
            var elem = document.getElementById("ref")
            elem.scrollIntoView();
        }
    }

    const toggleBackground = () => {
        setEditBackGround(!editBackground)
    }

    const copySurveyLink = (value) => {
        var tempInput = document.createElement("input");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        NotificationManager.success("Lomakkeen linkki kopioitu", "", 3000);
    }

    const goToSurvey = async (obj) => {

        // const params = {
        //     reports: [
        //         {
        //             reportId: createdSurvey._id
        //         }
        //     ]
        // }

        // dispatch(AllActions.getReportForDashboard(params))

        // if (createdSurvey.businessName !== "Ei kohdeyritystä") {
        //     await dispatch(AllActions.getBusinessSurveyTree({ businessId: createdSurvey.businessID }))
        //     history.push('/raportit/yritys/raportti')
        // } else {
        //     dispatch(AllActions.storeBusiness({ name: 'Ei kohdeyritystä' }))
        //     history.push('/raportit/kohdistamaton/raportti')
        // }

        
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            surveyId: originalSurvey.id
        }

        fetch(confs.url + 'fetch/mainSurveyForEdit', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => response.json())
            .then(resp => {

                if (Object.keys(resp).length > 0) {
                    onSurveyEdit(
                        {
                            categoryToEdit: resp,
                            selectedSurvey: {value:createdSurvey._id, label: createdSurvey.name},
                            start: true
                        }
                    );
                    history.push('/kartoitukset/luonti')

                }
            })
    
    }
    const onSurveyEdit = (payload) => {
        dispatch(AllActions.startSurveyEditFromSentSurveys(payload))
    } 

    return(
        <>
            <Modal size="lg" isOpen={modalOpen} toggle={() =>toggleModal()} >
                <ModalHeader className="" toggle={() => toggleModal()}> {(selection && selection.title) ? selection.title : 'Luo uusi'} </ModalHeader>
                <ModalBody className="create-modal">
                    {selection ?
                        <Col>
                            <Row>
                                <Col xs="2"> 
                                    <button className="small-white-button wide-button" onClick={() => clearAll()}><i className="fas fa-arrow-left"></i> Palaa</button>
                                </Col>
                               <Col xs="8" style={{ textAlign: 'center' }}>
                                    <h4 style={{  fontWeight: 'bold'}}>{(selection && selection.title)? selection.title : ""}</h4>
                               </Col>
                               <Col xs="2"></Col>
                            </Row>
                            <div className="bottom-border-for-header"></div>
                            {(selection && selection.class === 'form') &&
                                <Row style={{ marginTop: '-1em'}}>
                                    {
                                        <NewPlanTarget
                                            history={history}
                                        /> 
                                    }
                                </Row>
                            }
                            {(selection && selection.class === 'project') &&
                                <div style={{ paddingTop: '2em' }}>
                                    <ProjectDataWithoutModal
                                        history={history}
                                        startNewProject={startNewProject}
                                    />
                                </div>
                            }
                            {(selection && selection.class === 'client') && 
                                <Row>
                                    <CompanyLink resendToCompany={ resendToCompany } resetResend={ resendPermissionRequest } inModal={true}/>
                                </Row>
                            }
                            {(selection && selection.class === 'survey') &&
                                <div>
                                    <Col xl="12" className={clientList.length > 0 ? "senderContainer magic" : "senderContainer"} style={{ marginTop: '1.5em'}}>
                                       {steps !==7 &&
                                            <Row>
                                                <Col>
                                                    <button className="small-white-button" onClick={()=> setSteps(steps-1)} style={{ padding:'0.25em 0.5em', marginTop:'0.1em', marginBottom:'0.75em'}} disabled={steps === 1}><i className="fas fa-arrow-left"></i> Takaisin</button>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            {steps === 1 &&
                                                <Col xl="12" className={ "dashboard-survey-create" }>
                                                    <div className={ "companySelector magic"}>
                                                        <p className="senderSteps">1. Aktivoitavat kartoitukset</p>
                                                        <Row>
                                                            {/*
                                                    <SurveySelector {...this.state} surveyOptions={this.props.availableSurveys} 
                                                    filterOptions={this.filterSurveyOptions} handleSurveySelection={this.handleSurveySelection}/>*/}
                                                            <Col xl="12">
                                                                <MultiSelect
                                                                    messages={customMessages.messages}
                                                                    items={availableSurveys}
                                                                    selectedItems={survey.surveys}
                                                                    onChange={handleSurveySelection}
                                                                    wrapperClassName="multi-selector"
                                                                />  
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Row style={{ marginTop:'1em'}}>
                                                        <Col xl="12" className="surveyButton">
                                                            <button className={"small-white-button "+ (survey.surveys.length > 0 && "active-button active-bold")} disabled={survey.surveys.length === 0} style={{ marginBottom: '1rem', width: '100%' }} onClick={() => setSteps(steps+1)}>Jatka</button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            {steps === 2 &&
                                                <Col xl="12" className="dashboard-survey-create">
                                                    <p className="senderSteps">2. Valitse kohdeyritys</p>
                                                    <Row className="companySelector magic">
                                                        <Select 
                                                            options={clientList} 
                                                            onChange={(val)=> handleSurveyChange({target:{ id: "targets", value: [val]}})}
                                                            value={survey.targets}
                                                            isDisabled={false}
                                                            noOptionsMessage={() => { return "Yrityksiä ei löytynyt" }}
                                                            className="dashboard-survey-selector"
                                                            classNamePrefix="select"
                                                        />
                                                    </Row>
                                                    <Row style={{ marginTop:'1em'}}>
                                                        <Col xl="12" className="surveyButton">
                                                            <button className={"small-white-button "+ (survey.targets.length > 0 && "active-button active-bold")} disabled={survey.targets.length === 0} style={{ marginBottom: '1rem', width: '100%' }} onClick={() => setSteps(steps+1)}>Jatka</button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            {steps === 3 &&
                                                <Col xl="12" className={"selector-visible dashboard-survey-create"} >
                                                    <div className={"companySelector magic"}>
                                                        <p className="senderSteps">3. Valitse aktiivinen aikaväli</p>
                                                        <Row>
                                                            <Col>
                                                                {survey.dates.length > 0 && survey.dates.map((dateobj, ind) => {
                                                                    let start = moment(new Date(dateobj.startDate)).format('DD.MM.YYYY')
                                                                    let end = moment(new Date(dateobj.endDate)).format('DD.MM.YYYY')
                                                                    let oddEvenClass = ind % 2 === 0 ? 'even-row' : 'odd-row';

                                                                    return <Row key={ind} className={"modify-dates-list " + oddEvenClass} >
                                                                        <Col md="11">{start} - {end}</Col>
                                                                        {survey.dates.length > 0 &&
                                                                            <Col md="1" onClick={() => removeDateFromList(ind)}><i className="far fa-trash-alt"></i></Col>
                                                                        }
                                                                    </Row>
                                                                })

                                                                }
                                                            </Col>
                                                            <Col xs="12" style={{ marginTop: "0.5em" }}>
                                                                <DateRangePicker
                                                                    startDate={selectedDate.startDate} // momentPropTypes.momentObj or null,
                                                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                                                    startDatePlaceholderText="Alku pvm"
                                                                    endDate={selectedDate.endDate} // momentPropTypes.momentObj or null,
                                                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                                                    endDatePlaceholderText="Loppu pvm"
                                                                    onDatesChange={({ startDate, endDate }) => setDates({ startDate, endDate })} // PropTypes.func.isRequired,
                                                                    focusedInput={focusedDateInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                                    onFocusChange={focusedInput => setFocusedDateInput(focusedInput)} // PropTypes.func.isRequired,
                                                                    appendToBody={false}
                                                                    block
                                                                    hideKeyboardShortcutsPanel={true}
                                                                    isDayBlocked={isDayBlocked}
                                                                    readOnly
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Row style={{ marginTop:'1em'}}>
                                                        <Col xl="12" className="surveyButton">
                                                            <button className={"small-white-button "+ (survey.dates.length > 0 && "active-button active-bold")} disabled={survey.dates.length === 0} style={{ marginBottom: '1rem', width: '100%' }} onClick={() => setSteps(steps+1)}>Jatka</button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            {steps === 4 &&
                                                <Col xl="12" className={"selector-visible dashboard-survey-create"}>
                                                    <div className={"companySelector magic" }>
                                                        <p className="senderSteps">4. Anna kartoitukselle nimi</p>
                                                        <Row>
                                                            <Col>
                                                            <Input id="name" className="survey-name" value={survey.name} onChange={(e)=> handleSurveyChange(e)}></Input>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Row style={{ marginTop:'1em'}}>
                                                        <Col xl="12" className="surveyButton">
                                                            <button className={"small-white-button "+ (survey.name.length > 0 && "active-button active-bold")} disabled={survey.name.length === 0} style={{ marginBottom: '1rem', width: '100%' }} onClick={() => setSteps(steps+1)}>Jatka</button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }  
                                            {steps === 5 &&                                  
                                                <Col xl="12" className={ "selector-visible dashboard-survey-create"}>
                                                     <BackgroundEditor
                                                        isOpen={editBackground}
                                                        toggle={toggleBackground}
                                                        background={survey.background}
                                                        list={backgroundList}
                                                        save={survey.backgroundEdit ? saveModifiedBackground : saveBackground}
                                                        uploadBackground={uploadBackground}
                                                    />
                                                    <div className={"companySelector magic"}>
                                                        <p className="senderSteps" >5. Visuaaliset valinnat</p>
                                                        <Row style={{marginTop:'1em'}}>
                                                            <Col xl="12" style={{ marginBottom: '1em' }}>
                                                                <button className={`small-white-button wide-button lighter-text ${!survey.background && survey.backgroundSet && 'active-button active-bold'}`} onClick={() =>  handleBGChange({ backgroundSet: true, background: null })} style={{ marginRight: '1em' }}>Oletus taustakuva</button>
                                                                <button className={`small-white-button wide-button lighter-text ${survey.background && survey.backgroundSet && 'active-button active-bold'}`} onClick={() => setEditBackGround(!editBackground)} style={{ marginRight: '1em' }}>Mukautettu taustakuva</button>
                                                                <button className={`small-white-button wide-button lighter-text ${survey.hideMenu && 'active-button active-bold'}`} onClick={() => handleSurveyChange({target:{id:'hideMenu', value: !survey.hideMenu}})}>Piilota menu</button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Row style={{ marginTop:'1em'}}>
                                                        <Col xl="12" className="surveyButton">
                                                            <button className={"small-white-button "+ "active-button active-bold"} style={{ marginBottom: '1rem', width: '100%' }} onClick={() => setSteps(steps+1)}>Jatka</button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            {steps === 6 &&
                                                <Col xl="12" className={"selector-visible dashboard-survey-create"}>
                                                    <div className={"companySelector magic"}>
                                                        <p className="senderSteps">6. Aktivoi kartoitus</p>
                                                        <Row style={{marginTop:'1em', marginBottom:'1em'}}>
                                                            <Col>
                                                                <div style={{ padding:'0 1em'}}>
                                                                    <Row className="survey-summary-row">
                                                                        <Col xs="6">
                                                                            Nimi aktivoitavalle kartoitukselle: 
                                                                        </Col>
                                                                        <Col xs="6"> 
                                                                            <span style={{ marginLeft:'0.5em'}}>{survey.name}</span>
                                                                        </Col>
                                                                        
                                                                    </Row>
                                                                    <div className="bottom-border"></div>
                                                                    <Row className="survey-summary-row">
                                                                        <Col xs="6">
                                                                            Yritys, jolle kartoitus on suunnattu: 
                                                                        </Col>
                                                                        <Col xs="6">
                                                                            {survey.targets && survey.targets.length > 0 && survey.targets.map((target,index)=>{
                                                                                return(
                                                                                    <span key={index} style={{ marginLeft:'0.5em'}}>{target.label}</span>
                                                                                )
                                                                            })}
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="bottom-border"></div>
                                                                    <Row className="survey-summary-row">
                                                                        <Col xs="6">
                                                                            Valitut kartoitukset: 
                                                                        </Col>
                                                                        <Col xs="6">
                                                                            {survey.surveys && survey.surveys.map((survey, index)=>{
                                                                                return (
                                                                                    <div key={index} style={{ marginLeft:'0.5em'}}>
                                                                                        {survey.label}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="bottom-border"></div>
                                                                    <Row className="survey-summary-row">
                                                                        <Col xs="6">
                                                                            Aktiivinen aika:
                                                                        </Col>
                                                                        <Col xs="6">
                                                                            {survey.dates && survey.dates.length > 0 && survey.dates.map((dates,index)=>{
                                                                                return(
                                                                                    <div key={index} style={{ marginLeft:'0.5em'}}>
                                                                                        {moment.utc(dates.startDate, "MMM D, YYYY hh:mm:ss A", "en").local().format("DD.MM.YYYY") +  "-" +  moment.utc(dates.endDate, "MMM D, YYYY hh:mm:ss A", "en").local().format("DD.MM.YYYY")}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="bottom-border"></div>
                                                                    <Row className="survey-summary-row">
                                                                        <Col xs="6">
                                                                            Visuaaliset valinnat:
                                                                        </Col>
                                                                        <Col xs="6"> 

                                                                            <p><span style={{ marginLeft:'0.5em'}}>Taustakuva: {(survey.background === null || (survey.background !== null && (survey.background.id === false || survey.background.id === null)))? "Oletus" : "Mukautettu"}</span></p>
                                                                            <p><span style={{ marginLeft:'0.5em'}}>Menun näkyvyys: {survey.hideMenu === true? "Piilotettu" : "Näkyvissä"}</span></p>
                                                                        </Col>
                                                                    </Row>
                                                                    {/* <div className="bottom-border"></div> */}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginTop:'1em'}}>
                                                            <Col xl="12" className="surveyButton">
                                                                <button className="small-white-button active-button active-bold" style={{ marginBottom: '1rem', width: '100%' }} onClick={() => activateSurvey()}>Aktivoi kartoitus</button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            }
                                            {steps === 7 &&
                                                <Col xl="12" className="dashboard-survey-create">
                                                    <div className={"companySelector magic"}>
                                                        <p className="senderSteps">7. Aktivoidun kartoituksen kooste</p>
                                                        <Row style={{ marginTop:'1em'}}>
                                                            <Col style={{marginTop:'2em'}}>
                                                                <Row className="survey-summary-row">
                                                                    <Col xs="6">
                                                                        Nimi aktivoitavalle kartoitukselle: 
                                                                    </Col>
                                                                    <Col xs="6"> 
                                                                        <span style={{ marginLeft:'0.5em'}}>{survey.name}</span>
                                                                    </Col>
                                                                </Row>
                                                                <div className="bottom-border"></div>
                                                                <Row className="survey-summary-row">
                                                                    <Col xs="6">
                                                                        Yritys, jolle kartoitus on suunnattu: 
                                                                    </Col>
                                                                    <Col xs="6">
                                                                        {survey.targets && survey.targets.length > 0 && survey.targets.map((target,index)=>{
                                                                            return(
                                                                                <span key={index} style={{ marginLeft:'0.5em'}}>{target.label}</span>
                                                                            )
                                                                        })}
                                                                    </Col>
                                                                </Row>
                                                                <div className="bottom-border"></div>
                                                                <Row className="survey-summary-row">
                                                                    <Col xs="6">
                                                                        Valitut kartoituspohjat: 
                                                                    </Col>
                                                                    <Col xs="6">
                                                                        {survey.surveys && survey.surveys.map((survey, index)=>{
                                                                            return (
                                                                                <div key={index} style={{ marginLeft:'0.5em'}}>
                                                                                    {survey.label}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </Col>
                                                                </Row>
                                                                <div className="bottom-border"></div>
                                                                <Row className="survey-summary-row">
                                                                    <Col xs="6">
                                                                        Aktiivinen aika:
                                                                    </Col>
                                                                    <Col xs="6">
                                                                        {survey.dates && survey.dates.length > 0 && survey.dates.map((dates,index)=>{
                                                                            return(
                                                                                <div key={index} style={{ marginLeft:'0.5em'}}>
                                                                                    {moment.utc(dates.startDate, "MMM D, YYYY hh:mm:ss A", "en").local().format("DD.MM.YYYY") +  "-" +  moment.utc(dates.endDate, "MMM D, YYYY hh:mm:ss A", "en").local().format("DD.MM.YYYY")}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </Col>
                                                                </Row>
                                                                <div className="bottom-border"></div>
                                                                <Row className="survey-summary-row">
                                                                    <Col xs="6">
                                                                        Visuaaliset valinnat:
                                                                    </Col>
                                                                    <Col xs="6"> 
                                                                        <p><span style={{ marginLeft:'0.5em'}}>Taustakuva: {(survey.background === null || (survey.background !== null && (survey.background.id === false || survey.background.id === null)))? "Oletus" : "Mukautettu"}</span></p>
                                                                        <p><span style={{ marginLeft:'0.5em'}}>Menun näkyvyys: {survey.hideMenu === true? "Piilotettu" : "Näkyvissä"}</span></p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginTop:'3em'}}>
                                                            <Col xl="12">
                                                                <Label>Kartoituksen linkki:</Label>
                                                                <InputGroup className="" style={{ marginTop: '0.25em' }}>
                                                                    <Input type="link" value={`${confs.frontpageUrl}/${surveyCode}`} title={`${confs.frontpageUrl}/${surveyCode}`} className="plan-link-container" onClick={() => window.open(`${confs.frontpageUrl}/${surveyCode}`, "_blank")} readOnly/>
                                                                    <InputGroupAddon addonType="append">
                                                                        <button className="lighter-text wide-button copy-plan-link-button" title="Kopioi linkki" onClick={() => document.queryCommandSupported('copy') && copySurveyLink(`${confs.frontpageUrl}/${surveyCode}`)}>
                                                                            <i className="far fa-copy" style={{ zIndex:10}}></i>  
                                                                        </button>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                                <button className="small-white-button wide-button" style={{ marginTop:'2em'}} onClick={()=> goToSurvey()}>Siirry kartoituksen muokkaukseen</button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                        <div id="ref" ref={senderRef}></div>
                                    </Col>
                                </div>
                            }
                        </Col>
                        :
                        <Col style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                            <Row>
                                {/* <div className="dot project-color" style={{fontSize: '1rem', marginRight: '0.5em'}}></div> */}
                                {modulesInUse.planManagement && <Col className="creation-card " onClick={()=> setSelection({class:"form", title: "Luo uusi lomake"})}><div className="creation-card-inner"><div className="dot form-color" style={{fontSize: '1rem', marginRight: '0.5em'}}></div><p>Lomake</p></div></Col>}
                                {modulesInUse.projectManagement && <Col className="creation-card " onClick={()=> setSelection({class:"project", title: "Luo uusi projekti"})}><div className="creation-card-inner"><div className="dot project-color" style={{fontSize: '1rem', marginRight: '0.5em'}}></div><p>Projekti</p></div></Col>} 
                                {modulesInUse.clientManagement && <Col className="creation-card " onClick={()=> setSelection({class:"client", title: "Lisää asiakas"})}><div className="creation-card-inner"><div className="dot client-color" style={{fontSize: '1rem', marginRight: '0.5em'}}></div><p>Asiakas</p></div></Col>}
                                {modulesInUse.surveyManagement && <Col className="creation-card " onClick={()=> setSelection({class:"survey", title: "Aktivoi kartoitus"})}><div className="creation-card-inner"><div className="dot survey-color" style={{fontSize: '1rem', marginRight: '0.5em'}}></div><p>Aktivoi kartoitus</p></div></Col>}
                            </Row>
                        </Col>
                    }
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggleModal()}>Peruuta</a>
                    {steps === 7 &&  <button className="small-white-button wide-button" onClick={() => toggleModal()}>Sulje</button>}
                </ModalFooter>
            </Modal>
            <button id="create-new-tourId" className="create-from-dashboard-button" onClick={()=> setModalOpen(true)}>Luo uusi</button>
        </>
    )
}
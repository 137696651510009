import React, {useState, useRef, useEffect} from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {ModifiedContainer} from './DataContainers';
import { NotificationManager } from 'react-notifications';

export const ChecklistBox = ({item, editable, index, planId, promptSave, saveWithoutQueue, toggleItemVisibility, onDelete, plan, layoutPreview = false}) => {
    const [title, setTitle] = useState(item.topicContent);
    const [summaryItem, setSummaryItem] = useState(item.summaryItem)
    const [openMenu, toggleMenu] = useState(false);
    const [checklist, setChecklist] = useState(item.checklist? item.checklist : []);
    const [newItemTitle, setNewItemTitle] = useState("")
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const [listenerExists, setListenerExists] = useState(false)
    const [pasteListener, setPasteListener] = useState(false)

    function handleDataChanges(type, data) {
        if(type === 'checklist') {
            let itemPayload = {
                id: item.id,
                topicContent: title,
                checklist: data,
                type: item.type
            }
            //onItemChange(itemPayload);
            promptSave(itemPayload);

        } else if(type === 'checked') {
            setChecklist(data)
            let itemPayload = {
                id: item.id,
                topicContent: title,
                checklist: data,
                type: item.type
            }
            //onItemChange(itemPayload);
            saveWithoutQueue(itemPayload);

        } else if(type === 'title') {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                checklist: checklist,
                type: item.type
            }
            //onItemChange(itemPayload);
            promptSave(itemPayload);
        } else {
            setSummaryItem(data);
            let itemPayload = {
                id: item.id,
                topicContent: title,
                checklist: checklist,
                summaryItem: data,
                type: item.type
            }
            //onItemChange(itemPayload);
           if(editable && !type === "checked") promptSave(itemPayload);
        }
    }

    
    const handleCheckItemChange = (props) => {
        const {id, value, index} = props
        if(checklist !== undefined && checklist.length > 0){
            if(plan && !plan.isSigned && !plan.allSigned){
                let list = checklist
                let updatedList = list.map((checkItem, key) => {
                    if(key === index){
                        let newContent = {
                            ...checkItem,
                            [id]: value
                        }
                    return newContent
                    }
                    return checkItem
                })  
                setChecklist([...updatedList])
                
                if(id === "title"){
                    handleDataChanges('checklist', updatedList)
                }
                if(id === "checked"){
                    handleDataChanges('checked', updatedList)
                }
            } else {
                NotificationManager.error("Tehtävän merkintää ei voida muuttaa, sillä lomakkeessa on jo vähintään yksi allekirjoitus.","Virhe", 7000)
            }
        }
    }

    const addNewCheckItem = () => {
        let currentList = checklist
        let newItem = {
            checked: false,
            title: newItemTitle
        }
        setChecklist([...currentList, newItem])
        handleDataChanges('checked', [...currentList, newItem])
        setNewItemTitle("")
    }

    const addOnPaste = (e) => {
        if(e){
            e.stopPropagation();
            e.preventDefault();
            let clipboardData = e.clipboardData || window.clipboardData;
            let items = clipboardData.getData('Text');

            if(items){
                let currentList = checklist
                let itemArray = items.split(/[\r?\n]/)
                let returnList = [...currentList]
                if(itemArray){
                    itemArray.map(item => {
                        let newTitle = item.trim()
                        if(newTitle.length > 0){
                            let newItem = {
                                checked: false,
                                title: newTitle
                            }
                        
                            returnList = [...returnList, newItem]
                            return newItem
                        }
                        
                    })
                }
                setChecklist([...returnList])
                handleDataChanges('checked', [...returnList])
                setNewItemTitle("")
            }
        }
    }

    useEffect(()=>{
        if(document.getElementById(("addNewCheckItem-"+item.id))){
            
            if(!listenerExists){
                document.getElementById(("addNewCheckItem-"+item.id))
                    .addEventListener("keyup", function(event) {
                    event.preventDefault();
                    if (event.keyCode === 13) {
                        document.getElementById(("addItemButton-"+item.id)).click();
                    }
                })
                setListenerExists(true)
            }
        }
    },[item])
   
    const removeSelection = (index) => {

        let array = checklist
        array.splice(index,1)
        setChecklist([...array])

        setRemoveModalOpen(false)
        handleDataChanges('checked', [...array])
        setSelectedItem(null)
        setSelectedIndex(null)
    }

    const toggleCheckingAllowed = () => {
        let itemPayload = {
            ...item,
            topicContent: title,
            allowChecking:(item.allowChecking !== undefined) ? !item.allowChecking : true,
            checklist: [...checklist]
        }
        saveWithoutQueue(itemPayload)
    }

    return (
        <>
            {selectedItem &&
                <Modal isOpen={removeModalOpen} toggle={() => setRemoveModalOpen(!removeModalOpen)}>
                    <ModalHeader toggle={() =>setRemoveModalOpen(!removeModalOpen)}>
                        <p>Olet poistamassa tehtävää {selectedItem.title} listasta!</p>
                    </ModalHeader>
                    <ModalBody>
                        <p>Oletko varma, että haluat poistaa tehtävän {selectedItem.title}? Toimintoa <b>ei voida</b> peruuttaa.</p>
                    </ModalBody>
                    <ModalFooter>
                        <a onClick={() => setRemoveModalOpen(!removeModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                        <button onClick={() => removeSelection(selectedIndex)} className="small-white-button wide-button swb-hover delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>
                    </ModalFooter>
                </Modal>
            }
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                <Col xl="12">
                    {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red' }}>Yksityinen</span>}
                    {(!editable && !layoutPreview && item.modified !== undefined) && 
                        <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                    }
                </Col>
                <Col xl="12" style={{marginBottom: 8}} className="title-col">
                    <div style={{ display: 'flex' }}>
                       {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}</div>}            
                        <TextEditor 
                            editorId={`${item.id}-subtitle-${editable}`} 
                            content={title} 
                            onTextChange={editorState => handleDataChanges("title", editorState)}
                            additionalClass="plan-input subTitle plan-subtitle"
                            placeholder="-"
                            readOnly={!editable}
                            plaintextEditor={true}
                        /> 
                    </div>
                </Col>
                <Col xl="11" style={{paddingTop: '0.8em'}} className="plan-text-col">
                    {editable && 
                        <div>
                            <p style={{ fontSize: '0.8rem' }}>Voit lisätä tehtävän Enter-painikkeella tai valitsemalla lisää. Voit myös liittää tehtävät leikepöydältä (Ctrl + v) HUOM! listan erottimena toimii rivinvaihto</p>
                            <div className="add-new-checkItem-row" >
                                <textarea id={"addNewCheckItem-"+item.id} value={newItemTitle} placeholder="Tehtävän nimi" onPaste={(e) => addOnPaste(e)} onChange={(e) => setNewItemTitle(e.target.value)} /> 
                                <button className="small-white-button " id={"addItemButton-"+item.id} onClick={(e) => (e.stopPropagation(), addNewCheckItem())}>Lisää</button>
                            </div>
                        </div>
                    }
                    <div>
                        {checklist && checklist.length > 0 && checklist.map((checkItem, key)=>{
                            return(
                                <div key={key} style={{ marginTop: '1em' }}>
                                    {/* <Row> */}
                                        <div className="check-item-row">
                                            <Col xs="1" style={{ height: '100%' }}>
                                                <input type="checkbox" className="check-item-box" onChange={() =>  handleCheckItemChange({id:"checked", value: !checkItem.checked, index: key})} checked={checkItem.checked}/>
                                            </Col>
                                            <Col xs="11" style={{ height: '100%' }}>
                                                <Row>
                                                    {editable?
                                                        <Input type="text" className="check-item-title" value={checkItem.title} placeholder="Tehtävä" readOnly={!editable} onChange={(e) => handleCheckItemChange({id:"title", value:e.target.value, index: key})}/>
                                                        :
                                                        <div style={{ display:'flex', alignItems:'center', minHeight:'1.75em' }}>
                                                            <p>{checkItem.title}</p>
                                                        </div>
                                                    }
                                                    {!!editable && <div title="Poista tehtävä" onClick={() => (setRemoveModalOpen(true), setSelectedItem(checkItem), setSelectedIndex(key))} style={{ marginLeft: '0.5em', cursor: 'pointer' }}><i className="fas fa-times"></i></div>}
                                                </Row>
                                            </Col>
                                        </div>
                                    {/* </Row> */}
                                </div>
                            )
                        })}
                    </div>
                </Col>
                <Col xl="1" style={{paddingLeft: 15}} className="plan-dropdown-col">
                    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                    {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                        <DropdownToggle tag="div">
                            <i className="fas fa-bars plusIcon" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            {/* <DropdownItem onClick={() => onTemplateAdd({planItem: item})} className="add-template">Lisää pohjaksi</DropdownItem> */}
                            {/* <DropdownItem onClick={() => onTemplateAdd({planItem: item, type: 'new-custom-block'})} className="add-customblock">Lisää päivitysmalliksi</DropdownItem> */}
                            <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                            <DropdownItem onClick={() => toggleCheckingAllowed()} className="change-status">{(item.allowChecking !== undefined && item.allowChecking) ? "Estä tehtävien merkkaaminen julkaistussa" : "Salli tehtävien merkkaaminen julkaistussa"}</DropdownItem>
                            <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                    </div>
                </Col>
            </Row>
        </>
    )
}
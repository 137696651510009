import React, { Component, useState, useEffect } from 'react';
import { Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import PanelItem from './panelItems/PanelItem';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import TextEditor from '../../Utilities/TextEditor/texteditor';
import {convertFromRaw} from 'draft-js'
import { Scrollbars } from 'react-custom-scrollbars';
import { AnnotationTool } from '../../Utilities/Annotation/AnnotationTool';
import { Annotation } from '../../Utilities/Annotation/Annotation';
import { data } from 'jquery';

export const getPlainTexts = (rawEditorStateTopic, rawEditorStateText) => {
    let plainTopic = "";
    let plainText = "";
    if(typeof rawEditorStateTopic !== 'string' && rawEditorStateTopic !== undefined) {
        plainTopic = convertFromRaw(rawEditorStateTopic).getPlainText();
        plainTopic = plainTopic.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    } else {
        plainTopic = rawEditorStateTopic;
    }

    if(typeof rawEditorStateText !== 'string' && rawEditorStateText !== undefined) {
        plainText = convertFromRaw(rawEditorStateText).getPlainText();
        plainText = plainText.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
    } else {
        plainText = rawEditorStateText;
    }
    
    return {topicContent: plainTopic, textContent: plainText};
}

const selectItems = [
    {
        value: 'Komponentit',
        label: 'Komponentit'
    },
    {
        value: 'Liittymät',
        label: 'Liittymät' 
    },
    {
        value: 'Pohjat',
        label: 'Pohjat'
    }
]

class SidePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: selectItems[0],
            openMenu: false,
            filter: "",
            loading: false,
        }
    }


    handleViewChange = view => {
        this.setState({selectedOption: view});
    }

    toggleMenu = (open) => {
        this.setState({openMenu: open});
    }

    handleFilter = filter => {
        this.setState({filter: filter});
    }
    
    render() {
        
        return (
            <div className="kartoitusSidePanelFull" style={{height: 'auto'}}>
                <Dropdown isOpen={ this.state.openMenu } style={{cursor: 'pointer' }} className="sidepanel-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => this.toggleMenu( !this.state.openMenu) } >
                    <DropdownToggle tag="div">
                        <p className="selectedSurveySubTitle plan-sidePanel" style={{ textAlign: 'center'  }}>{this.props.selectedView}<span style={{marginLeft: 10}}><i className="fas fa-angle-down"></i></span></p>
                    </DropdownToggle>
                    <DropdownMenu style={{width: '100%'}}>
                        <DropdownItem className="tekstipohjat" onClick={() => this.props.changeView("Komponentit")}>Komponentit</DropdownItem>
                        <DropdownItem className="liittymät" onClick={() => this.props.changeView("Liittymät")}>Liittymät</DropdownItem>
                        <DropdownItem className="pohjat" onClick={() => this.props.changeView("Pohjat")}>Pohjat</DropdownItem>
                        <DropdownItem className="customBlocks" onClick={() => this.props.changeView("Päivitysmallit")}>Päivitysmallit</DropdownItem>
                        <DropdownItem className="customBlocks" onClick={() => this.props.changeView("iFrame-mallit")}>iFrame-mallit</DropdownItem>
                        {this.props.annotations && <DropdownItem className="kommentit" onClick={() => this.props.changeView("Kommentit")}>Kommentit</DropdownItem>}
                    </DropdownMenu>
                </Dropdown>
                {(this.props.selectedView === "Pohjat" || this.props.selectedView === "Päivitysmallit" )&&
                <div style={ { marginLeft: '0.5em', marginBottom: '0.8em', marginTop: '0.8em' } }>
                    <form id="search-bar">
                        <input type="search" style={{width: '85%', marginLeft: '0.5em'}} className="search-bar-input" placeholder="Etsi kappaletta" value={ this.state.filter } name="surveyFilter" onChange={ ( event ) => this.handleFilter( event.target.value ) } />
                    </form>
                </div>
                }
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={this.props.selectedView === "Pohjat"? {minHeight:'78vh', width: "100%", height: '100%'} : {minHeight:'85vh', width: "100%", height: '100%'}}
                    className="custom-scrollbars"
                    // autoHeight={true}
                    // autoHeightMin={this.props.selectedView === "Pohjat"? '83vh' : '85vh'}
                >
                    <Droppable droppableId="droppable" isDropDisabled={this.props.selectedView !== "Sisällysluettelo"}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps} 
                                draggable={true}  
                            >  
                            {this.props.selectedView === 'Komponentit' && 
                                this.props.data.map((i, index) => {
                                    return <PanelItem index={index} textContent={i.textContent} id={i.id} key={index} topicContent={i.topicContent}/> 
                                })
                            }    
                            {this.props.selectedView === 'Liittymät' && 
                                this.props.data.map((i, index) => {
                                    return <InterfaceItem index={index} textContent={i.textContent} id={i.id} key={index} topicContent={i.topicContent}/> 
                                })
                            }

                            {this.props.selectedView === "Pohjat" &&
                                this.props.data.map((i, index) => {
                                    const {topicContent, textContent} = getPlainTexts(i.topicContent, i.textContent);
                                    if(i._id !== undefined && (topicContent.toUpperCase().includes(this.state.filter.toUpperCase()) || (textContent && textContent.toUpperCase().includes(this.state.filter.toUpperCase())))) 
                                    return <TemplateItem index={index} textContent={i.textContent} id={i._id} key={index} topicContent={i.topicContent} item={i} /> 
                                })
                            }

                            {this.props.selectedView === "Sisällysluettelo" &&
                                <SummaryItems planItems={this.props.planItems}/> 
                            }

                            {this.props.selectedView === "iFrame-mallit" &&
                                this.props.data.map((i, index) => {
                                    return <IFrameItem index={index} textContent={i.textContent} id={i._id} topicContent={i.topicContent} hostName={i.hostName} key={`${i._id}-${index}`}/> 
                                })
                            }

                            {this.props.selectedView === 'Päivitysmallit' &&
                                <CustomBlockList blocks={this.props.data} filter={this.state.filter}/>
                            }
                            
                            {this.props.selectedView === 'Kommentit' &&
                                <div className="comment-div" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', marginLeft: '1em' }}>
                                    <AnnotationTool 
                                        planId={this.props.planId}
                                        annotationList={this.props.annotations}
                                    />
                                </div>
                            }

                            {provided.placeholder}
                            </div>
                        )} 
                    </Droppable>
                </Scrollbars>
            </div>
        )
    }
}

const MegaLoaderSpinner = () => {
    return (
            <Row className="loadingSpinnerRow">
                <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center'}}>
                    <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </Col>
            </Row>
    )
}

const CustomBlockList = ({blocks, filter}) => {
    return (
        <div> 
            {blocks.map((block, index) => {
                const {topicContent, textContent} = getPlainTexts("",block.textContent);
                if(block.blockName && block.blockName.toUpperCase().includes(filter.toUpperCase()) || (textContent && textContent.toUpperCase().includes(filter.toUpperCase()))){
                    return(
                        <Draggable draggableId={block.id + "-list-" + index} index={index} key={index}>
                            {(provided, snapshot) => (
                                <div className="draggable-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    
                                    style={
                                        provided.draggableProps
                                            .style
                                    }
                                >
                                    <Row style={{paddingBottom: '0.5em', paddingTop: '0.5em'}} className={`draggable-item template-item-${index}`}>
                                        <Col xl="12">                      
                                            <h6 style={{fontWeight: 700, marginBottom: 0}} className="block-with-text">{block.blockName}</h6>
                                        </Col>
                                        {textContent &&
                                            <Col xl="12">
                                                <p className="block-with-text" title={textContent}>{textContent}</p>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            )}
                        </Draggable> 
                    ) 
                }
            })}
        </div>
    )
}

const IFrameItem = props => {
    const {topicContent, textContent} = getPlainTexts(props.topicContent, "");
    const draggableId = props.id ? props.id : props.index + ""
    
    return (
        <Draggable draggableId={draggableId} index={props.index}>
            {(provided, snapshot) => (
                <div 
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    
                    style={
                        provided.draggableProps
                            .style
                    }
                >
                    <Row style={{paddingBottom: '1em'}} className={`draggable-item template-item-${props.index}`}>
                        <Col xl="12">                      
                            <h6 style={{fontWeight: 700, marginBottom: 0}} className="block-with-text">{topicContent}</h6>
                        </Col>
                        <Col className="col-sm-12 col-md-6 offset-md-3" style={{height: 350}}>
                            {props.hostName === "" ? <div style={{ height: '100%', width: '100%', background: '#C8C8C8' }}></div> : <iframe src={props.hostName} frameBorder="0" style={{ height: '100%', width: '100%' }}></iframe>}
                        </Col>
                    </Row>
                </div>
            )}
        </Draggable>   
    )
}

const TemplateItem = props => {
    const {topicContent, textContent} = getPlainTexts(props.topicContent, props.textContent);
    const {item} = props
  
    return (
        <Draggable draggableId={props.id} index={props.index}>
            {(provided, snapshot) => (
                <div className="draggable-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    
                    style={
                        provided.draggableProps
                            .style
                    }
                >
                    <Row style={{paddingBottom: '0.5em', paddingTop: '0.5em'}} className={`draggable-item template-item-${props.index}`}>
                        <Col xl="12">                      
                            <div style={{display:'flex'}}>
                                {(item.type === 'tables'|| item.type === 'gantt') && <p style={{marginRight:'0.5em'}}>{item.type === 'tables'? <i className="fas fa-table"></i>:(item.type === 'gantt' ? <i className="fas fa-chart-bar"></i> :"")}</p>}
                                <h6 style={{fontWeight: 700, marginBottom: 0}} className="block-with-text">{topicContent}</h6> 
                            </div>
                        </Col>
                        <Col xl="12">
                            <p className="block-with-text">{textContent}</p>
                        </Col>
                    </Row>
                </div>
            )}
        </Draggable>   
    )
}

const SummaryItems = props => {
    return (
        props.planItems.map((planItem, index) => 
            <Draggable draggableId={`${planItem.id}-summary`} index={index} key={planItem.id}>
            {(provided, snapshot) => (
                <div className="draggable-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    
                    style={
                        provided.draggableProps
                            .style
                    }
                >
                    <Row style={{paddingBottom: '1em'}}>
                        <Col xl="12">
                            <div className="summary-row">
                            <span className="summary-index">{index + 1}.</span>
                                <TextEditor 
                                    editorId={`${planItem.id}-summary`} 
                                    content={planItem.topicContent} 
                                    additionalClass="block-with-text"
                                    readOnly={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
            </Draggable>   
        )
    )
}

const ProductItems = ({products}) => {
    return (
        products.map((product, index) => 
            <Draggable draggableId={product.id + "-list-" + index} index={index} key={product.id}>
                {(provided, snapshot) => (
                    <div className="draggable-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        
                        style={
                            provided.draggableProps
                                .style
                        }
                    >
                        <Row style={{paddingBottom: '0.5em', paddingTop: '0.5em'}} className={`template-item-${index}`}>
                            <Col xl="12">                      
                                <h6 style={{fontWeight: 700, marginBottom: 0}} className="block-with-text">{product.blockName}</h6>
                                <TextEditor 
                                    editorId={`${product.id}-summary`} 
                                    content={product.description} 
                                    additionalClass="block-with-text plan-input"
                                    readOnly={true}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </Draggable>   
        )
    )
}

const InterfaceItem = (props) => {
    return (
        <Draggable draggableId={props.id} index={props.index} className="draggable-shadow">
            {(provided, snapshot) => (
                <>
                <div className="draggable-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    
                    style={
                        provided.draggableProps
                            .style
                    }
                >
                <Row style={{paddingBottom: '0.5em', paddingTop: '0.5em' }} >
                    <Col xl="12">
                        <h6 style={{fontWeight: 700, marginBottom: 0}}>{props.topicContent}</h6>
                    </Col>
                    {/* <Col xl="12">
                        <p className="block-with-text" title={props.textContent}>{props.textContent}</p>
                    </Col> */}
                </Row>   
                </div>
               </>
            )}
        </Draggable>
        
    )
}

export default SidePanel;
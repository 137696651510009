import React, { Component } from 'react';
import { Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { NotificationManager } from 'react-notifications';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ActionCreator from '../../../store/index';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            editIndex: null,
            userList: [],
            userToEdit: {},
            emailEdited: false,
            inactivateModalOpen: false,
            selectedUser: null,
            deleteText:"",
        }
        this.itemRef = React.createRef();
    }


    componentDidMount() {
        let list = [
            ...this.props.userList
        ]

        this.setState({ userList: list });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userList !== this.props.userList) {
            let list = [
                ...this.props.userList
            ]
            this.setState({ userList: list });
            this.setState({ editIndex: null, userToEdit: null, newUser: null });
        }
        else if (prevProps.userList !== this.props.enterprise.users){
            let list = [
                ...this.props.enterprise.users
            ]
            this.setState({ userList: list });
            this.setState({ editIndex: null, userToEdit: null, newUser: null });
        }
    }

    toggleEdit = (user, y) => {
        if (this.state.editIndex === y) {
            this.setState({ editIndex: null, userToEdit: null, newUser: null, emailEdited: false });
        } else {
            this.setState({ editIndex: y });
            this.setState({ userToEdit: user });
        }
    }

    handleField(field, value) {
        let object = {
            ...this.state.userToEdit
        };
        object[field] = value;

        const emailEdited = field === 'email' ? true : false

        this.setState({ userToEdit: object, emailEdited: emailEdited });
    }

    newUser = () => {
        const newuser = { email: '', phone: '' }

        this.itemRef.current.scrollTop();
        this.setState({ newUser: newuser, userToEdit: newuser, editIndex: 0 })
    }

    handleSave = ( sendNewCode = false, adminToggle = false) => {
        const emailCheck = /[^@]+@[^\.]+\..+/;
        let editeduser = this.state.userToEdit;
        editeduser.sendNewLoginToken = sendNewCode

        if (emailCheck.test(editeduser.email)) {
            if(adminToggle){
                editeduser = {
                    ...editeduser,
                    adminUser: editeduser.adminUser? !editeduser.adminUser: true
                }
            }
            this.props.edit(editeduser);
        } else {
            NotificationManager.error('Tarkista sähköpostiosoite!', 'Virhe', 2000);
        }
    }

    toggleInactiveModal = () => {
        this.setState({ inactivateModalOpen: !this.state.inactivateModalOpen})
        if( !this.state.inactivateModalOpen === false){
            this.setState({ selectedUser: null })
        }
        this.setState({deleteText: ""})
    }

    handleUserInactivation = () => {
        if(this.state.deleteText === 'poista'){
            this.props.inactivateUser(this.state.selectedUser)
            this.toggleInactiveModal()
        }
    }

    render() {
        const { emailEdited } = this.state

        let i = 0;
        let users = this.state.userList;
        if (this.state.newUser && this.state.editIndex) {
            users = [
                this.state.newUser,
                ...users
            ]
        }

        return (
            <div>
                {this.state.selectedUser &&
                    <Modal isOpen={this.state.inactivateModalOpen} toggle={() => this.toggleInactiveModal()}>
                        <ModalHeader><p>Poista käyttäjä: {this.state.selectedUser.email}</p></ModalHeader>
                        <ModalBody>
                            <p>Oletko varma, että tahdot poistaa käyttäjän? Tätä toimintoa <b>ei</b> voi kumota</p>
                            <p>Poiston jälkeen käyttäjän aiemmin tekemät muutokset jäävät voimaan, mutta käyttäjä ei enää pääse kirjautumaan EverBetter-järjestelmään.</p>
                            <div style={{ marginTop: '1em' }}>
                                <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                                <Input id="deleteInput" type="text" value={this.state.deleteText} onChange={(e) => this.setState({deleteText: e.target.value})}/>
                                <div className="email-check-box">
                                    {this.state.deleteText === "poista"?
                                        <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                        :
                                        <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => this.toggleInactiveModal()} >Peruuta</a>
                            <button className="small-white-button wide-button" style={{ backgroundColor: 'red', color:'#fff' }} onClick={() => this.handleUserInactivation()} >Poista</button>
                        </ModalFooter>
                    </Modal>
                }
                <Col xl={{ size: 12 }} className="enterprise-box">
                    <Row>
                        <Col>
                            <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                                <p>Käyttäjät</p>
                                <BoxSearchBar placeholder='Etsi käyttäjää' value={ this.state.filter } onChange={ (val) => this.setState({ filter: val }) } />
                                <div style={{ marginLeft: 'auto', marginRight: '1em' }}>
                                    <button className="small-white-button lighter-text wide-button" onClick={this.newUser}>Lisää uusi</button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <Scrollbars
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                style={{ width: "100%", height: '570px' }}
                                ref={this.itemRef}
                                className="custom-scrollbars">
                                {users && users.map((user, y) => {
                                    if ((user.email.toLowerCase().includes(this.state.filter.toLowerCase())) || (user.phone.toLowerCase().includes(this.state.filter.toLowerCase()))) {
                                        let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row';
                                        i++;
                                        return <Row key={y} className={oddEvenClass + ' enterprise-list '}>
                                            <Col xs="12">
                                                <Row>
                                                    <Col xs="8">
                                                        {this.state.editIndex === y ?
                                                            <div style={{ lineHeight: '0.2em' }}>
                                                                <p style={{ paddingBottom: '1.3em', paddingTop: '0.6em' }}>{this.state.newUser ? 'Luo uusi käyttäjä' : 'Muokkaa käyttäjää'}</p>
                                                                <label className="lighter-text" >Sähköposti</label>
                                                                <Input className="input-bar" type="text" name="email" value={this.state.userToEdit.email} style={{ width: "100%" }} onChange={(e) => this.handleField("email", e.target.value)}></Input>
                                                            </div>
                                                            :
                                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                <span>{user.email}</span>
                                                                <span>{user.lastLogin && moment.utc( user.lastLogin, 'MMM D, YYYY h:m:s A', 'en' ).local().format( 'DD.MM.YYYY HH:mm' )}</span>
                                                            </div>
                                                        }

                                                    </Col>
                                                    <Col xs="4" style={{ textAlign: 'right' }}>
                                                        <div style={{ marginLeft: 'auto'}}>
                                                            <button className="small-white-button lighter-text wide-button" onClick={() => this.toggleEdit(user, y)}>{this.state.editIndex === y ? 'Peruuta' : 'Muokkaa'}</button>
                                                        </div>
                                                        {this.state.editIndex === y && this.props.adminUser &&
                                                            <div style={{ marginLeft: 'auto', marginTop:'1em'}}>
                                                                <Label style={{ marginRight:'0.5em'}}>Admin:</Label>
                                                               <label className="switch">
                                                                    <input id="switch-input" type="checkbox" onChange={() => this.handleSave(false,true)} checked={user.adminUser? user.adminUser: false}/>
                                                                    <span className="slider round"></span>
                                                                </label>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="8">
                                                        {this.state.editIndex === y ?
                                                            <div style={{ lineHeight: '0.2em', marginTop: '1em' }}>
                                                                <label className="lighter-text" >Puhelinnumero</label>
                                                                <Input className="input-bar" name="phone" type="text" value={this.state.userToEdit.phone} style={{ width: "100%" }} onChange={(e) => this.handleField("phone", e.target.value)}></Input>
                                                            </div>
                                                            :
                                                            <p className="lighter-text">{user.phone}</p>
                                                        }
                                                    </Col>
                                                </Row>
                                                {this.state.editIndex === y &&
                                                    <Row>
                                                        <Col xs="6" style={{ marginTop: '1em', marginBottom: '0.2em' }}>
                                                            <button className="small-white-button lighter-text wide-button" onClick={() => this.handleSave(false)} >Tallenna</button>
                                                            { (emailEdited && !this.state.newUser) && <button className="small-white-button lighter-text wide-button" onClick={() => this.handleSave(true)} style={{ marginLeft: '0.5rem' }} >Tallenna ja lähetä kirjautumiskoodi</button> }
                                                        </Col>
                                                        <Col xs="6" style={{ marginTop: '1em', marginBottom: '0.2em', width:'100%', textAlign: 'right' }}>
                                                            {this.props.adminUser && <button className="small-white-button lighter-text wide-button" style={{ backgroundColor: 'red', color: '#fff' }} onClick={() => (this.toggleInactiveModal(), this.setState({ selectedUser: user }))}> Poista käyttäjä </button>}
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    }
                                    return null
                                })}
                                {i === 0 &&
                                    <Col style={{ marginTop: '1em' }}>
                                        <span>Käyttäjiä ei löytynyt</span>
                                    </Col>
                                }
                            </Scrollbars>
                        </Col>
                    </Row>
                </Col>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        enterprise: state.enterprise.enterprise,
        adminUser: state.authentication.adminUser? state.authentication.adminUser: false
    }
}

const mapDispatchToProps = dispatch => {
    return{
        inactivateUser: (params) => dispatch(ActionCreator.inactivateUser(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users))
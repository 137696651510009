import React, { Component } from 'react'
import { Row, Col, Input } from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'

import { confs } from '../../../modules/config'

export default class BusinessEdit extends Component {
  constructor() {
    super()

    this.state = {
      edit: false,
      businessEdit: null
    }
  }

  toggleEdit = () => {
    const businessEdit = this.state.businessEdit ? null : { ...this.props.business }

    this.setState(prevState => ({
      edit: !prevState.edit,
      businessEdit: businessEdit
    }))
  }

  handleField(field, value) {
    let object = {
      ...this.state.businessEdit
    }

    object[field] = value

    this.setState({ businessEdit: object })
  }

  save = () => {
    const edited = { ...this.state.businessEdit, logo: this.props.business.logo }
    this.props.edit( edited )
    // this.setState({ edit: false, businessEdit: null })
    this.toggleEdit()
  }

  uploadLogo = (e) => {
    const formData = new FormData()
    formData.append("logo", e.target.files[0], e.target.files[0].name)
    formData.append("jwtToken", window.sessionStorage.getItem("token"))
    this.props.uploadLogo(formData)
  }

  render() {
    const { edit, businessEdit } = this.state
    const { business, deleteLogo } = this.props

    let logoStyle = {}
    let cursor = edit ? 'pointer' : 'not-allowed'

    if (business.logo) {
      logoStyle = { backgroundImage: `url(${confs.url + 'getLogo/' + business.logo + '?d=' + Date.now()})`, backgroundSize: 'contain', cursor: cursor }
    }

    return (
      <Row>
        <Col xl={{ size: 12 }}>
          <div className="enterprise-box">
            <Row>
              <Col>
                <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                  <p>Yrityksen tiedot</p>

                  <div style={{ marginLeft: 'auto', marginRight: '1em' }}>
                    <button className="small-white-button lighter-text wide-button enterprise-edit-button" onClick={this.toggleEdit}> {!edit ? 'Muokkaa' : 'Peruuta'}</button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Scrollbars
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                  style={{ width: "100%", height: '500px' }}
                >

                  { business.businessName &&
                    <div className="enterprise-edit">
                      <Row>
                        <Col xs="2">
                          <span>Yrityksen nimi:</span>
                        </Col>
                        <Col xs="8">
                          { (edit && businessEdit) ?
                            <Input className="input-bar" name="enterprise-name" type="text" value={ businessEdit.businessName } style={{ width: "100%" }} onChange={(e) => this.handleField("businessName", e.target.value)}></Input>
                            :
                            <span className="light-text">{ business.businessName }</span>
                          }
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="2">
                          <span>Y-tunnus:</span>
                        </Col>
                        <Col xs="8">
                          { (edit && businessEdit) ?
                            <Input className="input-bar" type="text" value={ businessEdit.businessID } style={{ width: "100%" }} onChange={(e) => this.handleField("businessID", e.target.value)}></Input>
                            :
                            <span className="light-text">{ business.businessID }</span>
                          }
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="2">
                          <span>Osoite:</span>
                        </Col>
                        <Col xs="8">
                          { (edit && businessEdit) ?
                            <Input className="input-bar" type="text" value={ businessEdit.address } style={{ width: "100%" }} onChange={(e) => this.handleField( "address", e.target.value )}></Input>
                            :
                            <span className="light-text">{ business.address }</span>
                          }
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="2">
                          <span>Logo:</span>
                        </Col>
                        <Col xs="8">
                          <div className="logo-container" style={ logoStyle }>

                            { !business.logo &&
                              <i className="material-icons" style={{ position: 'relative', fontSize: 60, top: (50 % - 30), color: '#749db4', cursor: 'not-allowed' }}>get_app</i>
                            }
                            { edit &&
                              <Input onChange={ this.uploadLogo } type="file" name="logo" style={{ opacity: 0, height: '100%', cursor: 'pointer', position: 'absolute', top: 0 }} />
                            }

                          </div>

                          { edit &&
                            <span>
                              { business.logo ?
                                <span className="lighter-text" style={{ cursor: 'pointer' }} onClick={ deleteLogo }>Poista logo</span>
                                :
                                <div style={{ display: 'line-block', position: 'relative' }}>
                                  <p className="lighter-text" style={{ cursor: 'pointer' }} type="file" onChange={ this.uploadLogo }>Lataa kuva</p>
                                  <Input onChange={ this.uploadLogo } type="file" name="logo" style={{ opacity: 0, height: '100%', position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} />
                                </div>
                              }
                            </span>
                          }

                        </Col>
                      </Row>
                      <div style={{position:"relative"}}>
                      {edit &&
                        <Row style={{ backgroundColor: '#fff' }}>
                          <Col>
                            <button onClick={ this.save } style={{ width: '100%', marginBottom:"-1rem" }} className="small-white-button">Tallenna</button>
                          </Col>
                        </Row>
                      }
                      </div>
                    </div>
                  }                
                </Scrollbars>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    )
  }
}

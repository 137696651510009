import React, { useState, useEffect } from 'react';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Button, Input } from 'reactstrap'
import { getProjectsForBusiness } from '../../../store';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { flattenDiagnosticMessageText, isTemplateMiddle } from 'typescript';
import { NotificationManager } from 'react-notifications';
import './dashboardGantt.css'
import moment from 'moment'
import Select from 'react-select';

const testTasks = [
    {
        start: new Date(moment("2020-12-22T10:00:00.000Z").format("yyyy.MM.DD")),
        end: new Date(moment("2021-01-22T10:00:00.000Z").format("yyyy.MM.DD")),
        name: 'Test',
        id: 'Project 0',
        type:'project',
        progress: 0,
        isDisabled: true,
        project:'Project 0',
        styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    },
    {
        start: new Date(2020, 1, 2),
        end: new Date(2020, 1, 5),
        name: 'T1',
        id: 'Task 0',
        type:'project',
        progress: 50,
        isDisabled: true,
        project:'Project 0',
        styles: { progressColor: '#d811ac15', progressSelectedColor: '#d811ac15', backgroundSelectedColor: '#d811ac15' },
    }
]

const viewOptions = [
    {label: 'Päivä', value: 'Day'},
    {label: 'Viikko', value: 'Week'},
    {label: 'Kuukausi', value: 'Month'},
    {label:'Vuosi', value:'Year'}
]

const orderOptions = [
    {label:'Muokattu', value: 'modified'},
    {label:'Alku pvm', value: 'startDate'},
    {label:'Loppu pvm', value: 'targetDate'},
    {label:'Nimi', value: 'projectName'},
]

export const DashboardGantt = ({activeType, toggleType, goToProject, goToPlan}) => {
    const [projects, setProjects] = useState([])
    const [selectedView, setSelectedView] = useState(viewOptions[1])
    const [search, setSearch] = useState("")
    const [selectedOrder, setSelectedOrder] = useState({by:'start', ascending:false})
    const [height, setHeight] = useState("350px")
    const [showTags, setShowTags] = useState(false)
    const [selectionModalOpen, setSelectionModalOpen] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null)
    const [projectList, setProjectList] = useState([])

    useEffect(()=>{
        const fetchProjects = async() => {
            let params = {
                orderBy: "startDate"
            }
            const response = await executeRequest('get/projectsForGantt', params)
            if (!!response) {
                if (response.projectList)
                    handleData(response.projectList)
                    setProjectList(response.projectList)
            } else {
                NotificationManager.error("Lomakkeiden haku epäonnistui", "", 3000);
            }         
        }

        fetchProjects();
        orderBy("start")
    },[])

    const handleData = (list) => {
        let array = []
        list.map((item, index)=>{
            if(item.startDate && item.startDate.length > 4 && item.targetDate && item.targetDate.length > 4 && (!item.status || item.status && item.status.label !== "Suljettu" )){ 
                let project = {
                    start: new Date(moment(item.startDate).format("yyyy.MM.DD")),
                    end: new Date(moment(item.targetDate).format('yyyy.MM.DD')),
                    name: item.projectName,
                    id: item._id,
                    type:'project',
                    progress: item.progress || 100,
                    isDisabled: true,
                    project:'Project 0',
                    styles: { backgroundColor:'#fac465', progressColor: item.progress?'#6ba53f': '#fac465', progressSelectedColor: item.progress? '#6ba53f': '#fac465', backgroundSelectedColor: '#fac465'},
                    tags: item.tags?[...item.tags] : false,
                    targetBusiness: (item.targetBusiness && item.targetBusiness.label)? item.targetBusiness.label : "",
                    status: item.status? item.status : false
                }
                array = [...array, project]
            }
        })
      
        setProjects([...array]);
    }

    let date = new Date()
    date.setDate(date.getDate() - 3);

    const handleFilter = (project) => {
        const {tags, name, targetBusiness} = project

        if(name.toUpperCase().includes(search.toUpperCase())) return true

        if(targetBusiness.toUpperCase().includes(search.toUpperCase())) return true

        if(tags !== undefined && tags.length > 0){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }
        
    }

    const customTask = (props) => {
        const {task} = props
        if(task){
            let start = (task.start.getDate() + "." + (task.start.getMonth() +1) + "." + task.start.getFullYear())
            let end =  (task.end.getDate() + "." + (task.end.getMonth() +1  ) + "." + task.end.getFullYear())
            return(
                <div className="_3T42e">
                    <div className='_29NTg' style={{ fontSize:'1em'}} ><b>{task.name}</b></div>
                    <div className='_29NTg'>{task.targetBusiness}</div>
                    <div className='_29NTg' style={{ fontSize:'0.9em'}} ><b>{start +"-" + end}</b></div>
                    <div style={{ display:'flex' }}>
                        {task.tags && task.tags.length > 0 && task.tags.map((tag,key)=>{
                            return(
                                <p key={key} className="tag-display tag-text project-lighter-color">{tag.label}</p>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    const orderBy = (by) => {
        let array = [...projects]        
        if(selectedOrder.by === by){
            if(by === "name"){
                if(selectedOrder.ascending){
                    array.sort((a,b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA > nameB) {
                          return -1;
                        }
                        if (nameA < nameB) {
                          return 1;
                        }
                        return 0;
                    })
                } else {
                    array.sort((a,b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                    })
                }
            } else {
                if(selectedOrder.ascending){
                    array.sort((a,b) => a[by] - b[by])
                } else {
                    array.sort((a,b) => b[by] - a[by])
                }
            }
            setSelectedOrder({by: by, ascending: !selectedOrder.ascending})
        } else {
            if(by === "name"){               
                array.sort((a,b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                })
                setSelectedOrder({by: by, ascending: false})
            } else {
                array.sort((a,b) => b[by] - a[by])
                setSelectedOrder({by: by, ascending: true})
            }
            
        }
        setProjects([...array])
    }

    const customHeader = (props) => {
        const {headerHeight, fontFamily, fontSize, rowWidth} = props
        return(
            <div className='_3_ygE' style={{ height: headerHeight, display:'flex', backgroundColor:'#fff', borderTop:'1px solid #e6e4e4 ', borderRadius:'10px 0 0 0', borderRight:'2px solid #f5f5f5', marginLeft:'-1px'}}>
                <div style={{width:'16em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'1.5em', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=> orderBy("name")}>
                    Nimi 
                    <div style={{ display:'flex', flexDirection:'column', marginLeft:'1em'}}>
                        <i className="fas fa-sort-up" style={{ color: (selectedOrder && selectedOrder.by === "name" && !selectedOrder.ascending) ? "#404040" : "#ebebeb", marginBottom:'-0.75em'}}></i>
                        <i className="fas fa-sort-down" style={{ color:(selectedOrder && selectedOrder.by === "name" && selectedOrder.ascending) ? "#404040" : "#ebebeb"}}></i>             
                    </div>
                </div>
                <div  style={{marginLeft:'-1.5em', borderLeft:'2px solid #e6e4e4 ', marginTop: '0.5em', marginBottom:'0.5em'}}></div>
                <div style={{width:'7em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'0.5em', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=> orderBy("start")}>
                    Alku pvm
                    <div style={{ display:'flex', flexDirection:'column', marginLeft:'1em'}}>
                        <i className="fas fa-sort-up" style={{ color:(selectedOrder && selectedOrder.by === "start" && !selectedOrder.ascending) ? "#404040" : "#ebebeb", marginBottom:'-0.75em'}}></i>
                        <i className="fas fa-sort-down" style={{ color:(selectedOrder && selectedOrder.by === "start" && selectedOrder.ascending) ? "#404040" : "#ebebeb"}}></i>             
                    </div>
                </div>
                <div  style={{marginLeft:'-0.5em', borderLeft:'2px solid #e6e4e4 ', marginTop: '0.5em', marginBottom:'0.5em'}}></div>
                <div style={{width:'7em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'0.5em', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=> orderBy("end")}>
                    Tavoite pvm
                    <div style={{ display:'flex', flexDirection:'column', marginLeft:'0.4em'}}>
                        <i className="fas fa-sort-up" style={{ color:(selectedOrder && selectedOrder.by === "end" && !selectedOrder.ascending) ? "#404040" : "#ebebeb", marginBottom:'-0.75em'}}></i>
                        <i className="fas fa-sort-down" style={{ color:(selectedOrder && selectedOrder.by === "end" && selectedOrder.ascending) ? "#404040" : "#ebebeb"}}></i>             
                    </div>
                </div>
                <div  style={{marginLeft:'-0.5em', borderLeft:'2px solid #e6e4e4 ', marginTop: '0.5em', marginBottom:'0.5em'}}></div>
                <div style={{width:'2em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'0.5em', display:'flex', alignItems:'center'}}>Tila</div>
                {showTags && 
                    <>
                        <div  style={{marginLeft:'0em', borderLeft:'2px solid #e6e4e4 ', marginTop: '0.5em', marginBottom:'0.5em'}}></div>
                        <div style={{width:'7em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'0.5em', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=> orderBy("end")}>
                            Tagit
                        </div>
                    </>
                }
            </div>
        )
    }

    const tagsToMore = (tags, slicer) => {
        var tagList = ""
        if(tags !== undefined ){
            tags.slice(slicer, tags.length).filter(t => tagList = tagList + "  " + t.label.toString())
            return(tagList)
        }
    }

    const getSelectedProject = (id) => {
        projectList.map((project)=>{
            if(project._id === id){
                setSelectedProject(project)
                setSelectionModalOpen(true)
            }
        })
    }

    const customTable = (props) => {
        const {headerHeight, fontFamily, fontSize, rowHeight, tasks} = props
        const weekDay = ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la']
        return(
            <div style={{border:'1px solid #e6e4e4 ', marginRight:'0em', marginLeft:'-2px'}}>
                {tasks && tasks.map((task, index) => {
                    let oddEvenRow = index%2 === 0 ? 'even' : 'odd'
                    let startDate = weekDay[task.start.getDay()] + " " + task.start.getDate() + "." + (task.start.getMonth()+1) + "." + task.start.getFullYear()
                    let endDate = weekDay[task.end.getDay()] + " " + task.end.getDate() + "." + (task.end.getMonth()+1) + "." + task.end.getFullYear()
                    return(
                        <div key={index} className={'gantt-row-styles gantt-row-'+oddEvenRow} style={{ height: rowHeight, display:'flex' , paddingLeft:'1.3em', marginRight:'', cursor:'pointer'}}  onClick={() => getSelectedProject(task.id)}>
                            <div style={{width:'13em', fontSize:'0.95rem', marginLeft:'', display:'flex', flexDirection:'column', textOverflow:'ellipsis', overflow:'hidden', wordBreak:'keep-all', marginTop:'0.3em', whiteSpace:'nowrap'}} title={task.name}>
                                {task.name}
                                <div style={{ fontSize:'0.8em', fontWeight:'bold'}}>{task.targetBusiness}</div>
                            </div>
                            {/* <div  style={{marginLeft:'-1.5em', borderLeft:'1px solid #404040', marginTop: '0.5em', marginBottom:'0.5em'}}></div> */}
                            <div style={{width:'7em', fontSize:fontSize, marginLeft:'1em', display:'flex', marginTop:'0.3em'}}>{startDate}</div>
                            {/* <div  style={{marginLeft:'-0.5em', borderLeft:'1px solid #404040', marginTop: '0.5em', marginBottom:'0.5em'}}></div> */}
                            <div style={{width:'7em', fontSize:fontSize, marginLeft:'', display:'flex', marginTop:'0.3em'}}>{endDate}</div>
                            {/* <div  style={{marginLeft:'-0.5em', borderLeft:'1px solid #404040', marginTop: '0.5em', marginBottom:'0.5em'}}></div> */}
                            <div style={{width:'2em', fontSize:fontSize, marginLeft:'', display:'flex', marginTop:'0.5em'}}>{task.status && <span className="dot" title={task.status.label} style={{backgroundColor: task.status.value, fontSize: '0.8rem', float:'right' }}></span>}</div>
                            {showTags &&
                                <div  style={{marginLeft:'0.5em', marginTop: '0.5em', marginBottom:'0.5em', display:'flex', width:'7em', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    {task.tags && task.tags.slice(0, 3).map((tag, i) => {                        
                                        return(<div key={i} style={{ marginLeft: '0.15em', paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                    })}                                                       
                                    {task.tags && task.tags.length > 3 &&  
                                        <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                            <i className="material-icons" title={tagsToMore(task.tags,3)}>more_horiz</i>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }

    const handleTypeChange = (type) => {
        toggleType("projects",type)
    }
    
    return(
        <Col style={{marginTop:'1.5em', borderLeft: '1px solid #ebebeb'}}>
                <div style={{ display: 'flex', width:'100%',  }}>
                    <div className={'dashboard-dot project-color'} style={{ marginRight: '0.5em'}}></div> <p><b>{"PROJEKTIT (" + projects.filter(project => handleFilter(project)).length +")"}</b></p>
                    {activeType && toggleType &&
                        <div className="view-type-container">
                            <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("cards")}><i className="fas fa-columns"></i></button>
                            <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => handleTypeChange("list")}><i className="fas fa-list-ul"></i></button>
                        </div>
                    }
                    <div style={{marginLeft:'1em'}}>
                        <form id="search-bar" className="gantt-search" style={{width:'12em'}}>
                            <input type="search" className="search-bar-input gantt-search" placeholder="Haku" value={search} onChange={(event)=> setSearch(event.target.value)} />
                        </form>
                    </div>
                    <div style={{ marginLeft:'auto', marginRight:'0', display:'flex'}}>
                        <div style={{ }}>
                            <button className={"small-white-button " } style={{ padding: '0.2em 0.5em' }} onClick={()=>setShowTags(!showTags)} title="Näytä tai piilota tagit"><i className="fas fa-tags"></i></button>
                        </div>
                        <div className="view-type-container" style={{ marginLeft:'auto', marginRight: '0', fontSize:'1rem'}}>
                            {height === "350px" && <button className={"small-white-button " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => setHeight("85vh")}><i className="fas fa-expand"></i></button>}
                            {height === "85vh" && <button className={"small-white-button " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => setHeight("350px")}><i className="fas fa-compress"></i></button>}
                        </div>
                        <div style={{ width:'8em', marginTop:'-0.25em' }}>
                            <Select
                                options={viewOptions}
                                onChange={(val)=> setSelectedView(val)}
                                value={selectedView}
                                isDisabled={false}
                                noOptionsMessage={() => { return "Ei asetuksia" }}
                                className="survey-selector gantt-select"
                                classNamePrefix="select"  
                            />
                        </div>
                    </div>
                </div>
                    {selectedProject &&
                        <Modal isOpen={selectionModalOpen} toggle={()=> setSelectionModalOpen(!selectionModalOpen)}>
                            <ModalHeader toggle={()=> setSelectionModalOpen(!selectionModalOpen)}>Valitse toiminto</ModalHeader>
                            <ModalBody>
                                <Col>
                                    <Row>
                                        <p><b>{selectedProject.projectName}</b></p>
                                    </Row>
                                    <Row>
                                        <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }} onClick={() => goToProject(selectedProject._id)}>Siirry projektiin</button>
                                    </Row>
                                    {selectedProject.ganttPlan &&
                                        <Row>
                                            <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }} onClick={() => goToPlan( selectedProject.projectName, selectedProject._id, selectedProject.ganttPlan.planId)}>Siirry projektisuunnitelmaan</button>
                                        </Row>
                                    }
                                </Col>
                            </ModalBody>
                            <ModalFooter>
                                <a className="go-back-link" onClick={()=> (setSelectionModalOpen(!selectionModalOpen), setSelectedProject(null))} style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                            </ModalFooter>
                        </Modal>
                    }
                <div style={{ backgroundColor:'#fff', borderRadius:'10px', padding: '0 0.25em 0 0', border:'1px solid #e6e4e4 '}}>
                    {projects && projects.length > 0 && projects.filter(project => handleFilter(project)).length > 0 &&
                        <Gantt 
                            tasks={projects.filter(project => handleFilter(project))}
                            viewMode={selectedView.value}
                            viewDate={date}
                            locale={'fin'}
                            ganttHeight={height}
                            preStepsCount={1}
                            TooltipContent={customTask}
                            todayColor={"#D811AC20"} 
                            projectBackgroundSelectedColor={'#D811AC'}  
                            TaskListHeader={customHeader} 
                            TaskListTable={customTable}             
                        />
                    }
                </div>
        </Col>
    )
}
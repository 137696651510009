import React, {useEffect, useState} from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Input, FormGroup, FormFeedback, Form, Label } from "reactstrap"
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';

const INITIAL_SETTINGS = {
    email:"",
    planOpenFollowed: false,
    commentingFollowed: false
}

export const FollowOptions = ({ planId, setFollowModalOpen, followModalOpen}) => {
    const modulesInUse = useSelector(state => state.authentication.modulesInUse);
    const [followOptions, setFollowOptions] = useState([])
    const [followEmailInputHasChanged, setFollowEmailInputHasChanged] = useState(false)
    const [followEmailIsValid, setFollowEmailIsValid] = useState(false)
    const [newEmail, setNewEmail] = useState(INITIAL_SETTINGS)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [selectedEmail, setSelectedEmail] = useState(null)

    let emailInputProps = followEmailInputHasChanged ? { valid: followEmailIsValid, invalid: !followEmailIsValid } : {}

    const handleFollowEmailChange = (value) => {
        const email = value

        setNewEmail({
            planOpenFollowed: newEmail.planOpenFollowed,
            commentingFollowed: newEmail.commentingFollowed,
            email:value
        })
        
        setFollowEmailInputHasChanged(true)
        const emailRegex = /[^@]+@[^\.]+\..+/
        let followEmailIsValid = false

        if (emailRegex.test(email)) {
            followEmailIsValid = true
        } else if (email === '') {
            setFollowEmailInputHasChanged(false)
        }

        setFollowEmailIsValid(followEmailIsValid)
    }

    useEffect(()=>{
        if(planId){
            getEmails()
        }
    },[planId])

    const getEmails = async() => {
        let payload = {
            planId: planId
        }
        const response = await executeRequest('plan/getFollowOptions',payload) 
        if(response && response.followOptions){
            setFollowOptions(response.followOptions)
        }
    }

    const saveFollowEmails = async() =>{     
        let payload = {
            followOptions: followOptions,
            planId: planId
        }
        const response = await executeRequest('plan/saveFollowOptions',payload) 
        if(response){
            setSelectedEmail(newEmail)
            setNewEmail("")
            getEmails()
            NotificationManager.success('Muutokset tallennettu onnistuneesti!', '', 5000)          
        } else {
            NotificationManager.error('Virhe tallentaessa muutoksia!', 'Virhe', 5000) 
        }
        
        
    }
    const removeEmail = () => {
        saveFollowEmails()
        setDeleteModalOpen(false)
    }
    const addEmail = () => {
        saveFollowEmails()
        setFollowEmailIsValid(false)
    }

    const handleEmailSave = () => {
        // mikäli tulee jotain ongelmia sähköpostin lisäyksen kanssa 
        // => setFollowOptions(array)
        let array = followOptions
        array.push(newEmail)
        setFollowOptions([...followOptions, newEmail])
        addEmail()
    }

    const handleEmailDelete = () => {
        let array = followOptions
        array.splice(selectedIndex-1,1)
        setFollowOptions(array)
        removeEmail()
    }

    const handleOptionsChanged = (props) => {
        const {id, value} = props
        
        let array = followOptions

        array.map(obj => {
            if(obj.email === selectedEmail.email){
                obj[id] = value
                setSelectedEmail(prevData => ({
                    ...prevData,
                    [id]:value
                }))
            } 
        })
        setFollowOptions(array)
    }


    return(
        <>
            <Modal isOpen={deleteModalOpen} toggle={() => setDeleteModalOpen(!deleteModalOpen)}>
                <ModalHeader>{}</ModalHeader>
                <ModalBody>
                    <p>Oletko varma, että haluat poistaa sähköpostiosoitteen listalta?</p>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setDeleteModalOpen(!deleteModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => handleEmailDelete()} className="small-white-button wide-button swb-hover delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={followModalOpen} toggle={() => setFollowModalOpen(false)}>
                <ModalHeader toggle={() => setFollowModalOpen(false)}>Seuraamisen asetukset</ModalHeader>
                <ModalBody>
                    <div>
                        <Label>Anna sähköpostiosoite</Label>
                        <FormGroup>
                            <Input {...emailInputProps} value={newEmail.email} onBlur={() => setFollowEmailInputHasChanged(false)} onChange={(e) => handleFollowEmailChange(e.target.value)} type="text" placeholder="Sähköpostiosoite" />
                            <FormFeedback>Syötä sähköpostiosoite oikeassa muodossa</FormFeedback>
                        </FormGroup>
                        {followEmailIsValid &&
                            <button className="small-white-button lighter-text wide-button" onClick={() => handleEmailSave()}>Lisää sähköposti</button>
                        }
                    </div>
                    <div style={{ marginTop: '2em' }}>
                        {followOptions && followOptions.length > 0 &&
                            <>
                                <Label>Lisätyt sähköpostiosoitteet</Label>
                                {followOptions.map((option,y) =>{
                                    let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row';
                                    y++;
                                    return(
                                        <div key={y} style={{ display: 'flex', alignContent: 'center' }} className={oddEvenClass + ' company-list selectable-row ' + ((selectedEmail && selectedEmail.email === option.email)? 'selected-email' : '')} onClick={()=> (setSelectedEmail(option), setSelectedIndex(y))}>
                                            <p>{option.email}</p><i style={{ marginRight:'0.5em', marginLeft: 'auto', marginTop: '0.25em' }} onClick={(e) => (e.stopPropagation(), setDeleteModalOpen(true), setSelectedIndex(y))} className="far fa-trash-alt"></i>
                                        </div>
                                    )
                                })} 
                            </>
                        } 
                    </div>
                    {selectedEmail &&  
                        <div style={{ padding: '1em', marginTop: '1em' }}>
                            <Row style={{ borderBottom: '1px solid #B5E413'}}>
                                <Col> 
                                    <Row>
                                        <p style={{fontWeight: 'bold'}}>Valittu sähköpostiosoite</p>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <p style={{fontWeight: 'bold'}}>Ilmoitukset</p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <p style={{ marginTop: '0.5em' }}>{selectedEmail.email}</p>
                                    </Row>
                                </Col>
                                <Col>
                                    <div style={{ marginLeft:'-1em', marginTop: '0.5em', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent:'center' }}>
                                        <p style={{fontWeight: 'bold', opacity: 0.6 }}>Lomakkeen avaus</p>
                                        <div style={{ float: 'right', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <label className="switch">
                                                <input id="switch-input" type="checkbox" name="planOpenFollowed" onChange={(e) => (handleOptionsChanged({id:e.target.name,value:!selectedEmail.planOpenFollowed}))} checked={selectedEmail.planOpenFollowed}/>
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {modulesInUse && modulesInUse.annotationManagement &&
                                        <div style={{ marginLeft:'-1em', marginTop: '0.5em', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <p style={{fontWeight: 'bold', opacity: 0.6 }}>Kommentointi</p>
                                            <div style={{ float: 'right', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <label className="switch">
                                                    <input id="switch-input" type="checkbox" name="commentingFollowed" onChange={(e) => (handleOptionsChanged({id:e.target.name,value:!selectedEmail.commentingFollowed}))} checked={selectedEmail.commentingFollowed}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    }
                                    {modulesInUse && modulesInUse.signatureManagement &&
                                        <div style={{ marginLeft:'-1em', marginTop: '0.5em', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <p style={{fontWeight: 'bold', opacity: 0.6 }}>Allekirjoitus</p>
                                            <div style={{ float: 'right', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <label className="switch">
                                                    <input id="switch-input" type="checkbox" name="signingFollowed" onChange={(e) => (handleOptionsChanged({id:e.target.name,value:!selectedEmail.signingFollowed}))} checked={selectedEmail.signingFollowed}/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => (setFollowModalOpen(!followModalOpen), setSelectedEmail(null))}>Peruuta</a>
                    {followOptions && followOptions.length > 0 &&
                        <button className="small-white-button wide-button" onClick={() => (saveFollowEmails(),setFollowModalOpen(false))}>Tallenna</button>
                    }
                </ModalFooter>
            </Modal>
            {/* <div className="follow-plan">
                {emailList&&emailList.length>0? 
                    <button style={{backgroundColor: 'rgb(189, 229, 51)', color: 'rgb(255, 255, 255)', fontWeight: '700' }} className="small-white-button lighter-text wide-button" onClick={() => setFollowModal(true)}>Lomaketta seurataan</button>
                    :
                    <button className="small-white-button lighter-text wide-button" onClick={() => setFollowModal(true)}>Seuraa lomaketta</button> 
                }
            </div> */}
        </>
    )
}
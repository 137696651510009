import * as ActionTypes from '../actions/Kartoitus/QuestionGroup/ActionTypes';

const initialState = {
    groupToEdit: {},
    questionToEdit: {},
    availableGroups: [],
    defaultValue: {}
};

const newQuestion = {
    title: "",
    questionOptions: [
        {
            text: "",
            feedback: "",
            points: "",
            tags: "",
            color: '#FFCC00'
        }
    ]
}

const updateQuestionOptionsArray = (array, action) => {
    return array.map((item, index) => {
        if(index !== action.payload.index) {
            return item;
        }

        return {
            ...item,
            ...action.payload.item
        }
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.UPDATE_FIELD:
            return {
                ...state,
                groupToEdit: {
                    ...state.groupToEdit,
                    ...action.data
                }
            }
        case ActionTypes.SET_GROUP:
            return {
                ...state,
                groupToEdit: action.group
            }
        case ActionTypes.SET_QUESTION:
            return {
                ...state,
                questionToEdit: {
                    ...action.data
                }
            }
        case ActionTypes.GET_AVAILABLE_GROUPS:
            return {
                ...state,
                availableGroups: action.payload.groups,
                defaultValue: action.payload.defaultValue
            }
        case ActionTypes.NEW_QUESTION: 
            return {
                ...state,
                questionToEdit: {
                    ...newQuestion
                }
            }
        case ActionTypes.UPDATE_QUESTION_FIELD:
            let ItemArray = updateQuestionOptionsArray(state.questionToEdit.questionOptions, action);
            return {
                ...state,
                questionToEdit: {
                    ...state.questionToEdit,
                    questionOptions: ItemArray
                },
                
            }
        case ActionTypes.ADD_NEW_QUESTION_OPTION: {
            return {
                ...state,
                questionToEdit: {
                    ...state.questionToEdit,
                    questionOptions: [...state.questionToEdit.questionOptions, newQuestion.questionOptions]
                }
            }
        }
        default:
            return state;
    }
}

export default reducer;
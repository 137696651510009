import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap'
import { UploadExcel } from './UploadExcel';
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

export const ExcelHandler = ({ saveBusinessExcel, notAddedHubSpots = null, excels, deleteExcel, hasHubSpot }) => {
    const [isListOpen, setIsListOpen] = useState(false)
    const [addNew, setAddNew] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [selectedExcel, setselectedExcel] = useState({})
    const [notAddedModal, setNotAddedModal] = useState(true)

    const toggleDelete = (excel) => {
        setselectedExcel(excel)
        setIsDeleteOpen(true)
    }

    useEffect(() => {
        setAddNew(false)
        setIsDeleteOpen(false)
        setselectedExcel({})
    }, [excels])

    return <>
        {notAddedHubSpots &&
            <Modal isOpen={notAddedModal} size="lg">
                <ModalHeader toggle={()=> setNotAddedModal(!notAddedModal)}> Seuraaville yrityksille ei voitu lisätä HubSpot yhteyttä: </ModalHeader>
                <ModalBody>
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '500px' }}
                        className="custom-scrollbars"
                    >
                        <Col>
                            {notAddedHubSpots.map((hubSpot, index)=>{
                                return(
                                    <Row key={index} style={{ marginTop:'1em' }}>
                                        <p>{hubSpot.companyName} - {hubSpot.companyId} - {hubSpot.reason}</p>
                                    </Row>
                                )
                            })}
                        </Col>
                    </Scrollbars>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={()=> setNotAddedModal(!notAddedModal)}>Sulje</a>
                </ModalFooter>
            </Modal>
        }
        <div>
            <UploadExcel isOpen={addNew} toggle={() => setAddNew(!addNew)} saveBusinessExcel={saveBusinessExcel} hasHubSpot={hasHubSpot} />
            <DeleteExcelModal isOpen={isDeleteOpen} toggle={() => setIsDeleteOpen(!isDeleteOpen)} excel={selectedExcel} confirm={deleteExcel} />
            <span style={{cursor:"pointer"}}
            title="Näytä ladatut Excel-tiedostot."
            onClick={() => setIsListOpen(!isListOpen)}>{excels.length} {excels.length === 1 ? 'tiedosto' : 'tiedostoa'} 
            <i style={{ marginLeft: '.2em' }} className={!isListOpen ? 'fas fa-angle-down' : 'fas fa-angle-up'}></i>
            </span>
            
            <button className="small-white-button lighter-text wide-button" style={{ position: 'absolute', right: 0, marginRight: '1.5em' }} onClick={() => setAddNew(true)}>Lisää uusi</button>
        </div>
        {isListOpen &&
            <div>
                {excels.length > 0 && excels.map((excel, i) => {
                    return <Row key={i} >
                        <Col xs="5">{excel.name} {excel.filename && <p className="lighter-text">{excel.filename}</p>}</Col>
                        <Col xs="1"><span className="lighter-text">{(excel.businessList && excel.businessList.length) || 0} yritystä</span></Col>
                        <Col xs="2"><span className="lighter-text">{(excel.headers && excel.headers.length) || 0} kenttää</span></Col>
                        <Col xs="2">{moment(new Date(excel.created)).format('DD.MM.YYYY')}</Col>
                        <Col xs="2" style={{cursor: 'pointer'}}><i className="far fa-trash-alt" onClick={() => toggleDelete(excel)}></i></Col>
                    </Row>
                })}
            </div>
        }
    </>

}


const DeleteExcelModal = ({ isOpen, toggle, excel, confirm }) => {
    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Poista tiedosto</ModalHeader>
        <ModalBody>
            <p>Haluatko varmasti poistaa tiedoston {excel.name}? Toimintoa ei voi peruuttaa</p>
        </ModalBody>
        <ModalFooter>
            <a onClick={toggle} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
            <Button onClick={() => confirm(excel.id)} color="danger">Poista</Button>
        </ModalFooter>
    </Modal>
}
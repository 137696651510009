import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import HelperBox from '../../../../Utilities/HelperBox/HelperBox';
import FilterContainer from './taskFilter/FilterContainer';
import FilterButton from './taskFilter/FilterButton';
import { HorizontalContainer, HorizontalList, HorizontalItem } from '../../../../Utilities/horizontal-scroller/HorizontalElements';
import { ClientTaskCards, RiskCard, SurveyResultCards } from '../../../../Utilities/horizontal-scroller/HorizontalCards';
import { executeRequest } from '../../../../Utilities/requestHandler/dataHandler';
import { useDispatch, useSelector } from 'react-redux'
import { storeTasks, storeTask, updateTaskStatus, storeLatestAnswers } from '../../../../store/index'
import {addTask} from '../../../../Utilities/HelperFunctions/fetchPhaser';
import { VerticalContainer, VerticalList, VerticalItem} from '../../../../Utilities/ListView/ListElements';
import {  AnswerListItem, TaskListItem, RiskListItem } from '../../../../Utilities/ListView/ListItems';
import MainRiskModal from '../../../../Utilities/Modals/riskModals/MainRiskModal';

const getSurveyTitles = task => {
  let taskTitles = "";
  if (task.taskTree !== undefined && Object.keys(task.taskTree).length > 0) {
    task.taskTree.surveyTitles.forEach((taskTitle, x) => {
      if (x === task.taskTree.surveyTitles.length - 1) {
        taskTitles += taskTitle.title;
      } else {
        taskTitles += taskTitle.title + ", ";
      }
    })
  }

  return taskTitles !== "" ? taskTitles : task.planName;
}

const getTaskStatus = status => {
  if (status === "Avoin" || status === "Ei huomioitu" || status === "inactive") {
    return "Avoin";
  } else if (status === "declined") {
    return "Peruutettu";
  } else {
    return "Valmis";
  }
}

const statusFilterHandler = (task, filters) => {
  if (filters.length === 0) {
    return true;
  } else if (filters.filter(x => {
    if(x.filter === task.status)
      return true
    else if(x.filter === "Avoin" && task.status === 'inactive')
      return true
    else
      return false
  }).length > 0) {
    return true;
  }

  return false;
}

export const Tasks = (props) => {
  const { tasks, sentSurveys, isBusiness, activeType, toggleType, search } = props
  const [statusFilters, setFilters] = useState([{
      value: 'Avoin',
      filter: 'Avoin'
  }]);
  const [isLoading, setIsLoading] = useState(true);
  const [dTasks, setDTasks] = useState([]);

  const isMounted = useRef(true);

  const dispatch = useDispatch();

  useEffect(() => {
      let businessId = null

      const fetchTasks = async params => {
          const response = await addTask('get/tasksForDashboard', { businessId: businessId }, dispatch)
          setTimeout(() => {
            if (!!response) {
              if (isMounted.current) {
                  setDTasks(response);
                  dispatch(storeTasks(response));
              }
            }
            if (isMounted.current) setIsLoading(false) 
          }, 200)     
      }

      fetchTasks();

      return () => {
          isMounted.current = false;
      }
  }, [])

  const statusFilterHandler = (task, filters) => {
      if (filters.length === 0) {
          return true;
      } else if (filters.filter(x => {
          if (x.filter === task.status)
              return true
          else if (x.filter === "Avoin" && task.status === 'inactive')
              return true
          else
              return false
      }).length > 0) {
          return true;
      }

      return false;
  }

  const isSurveyIncluded = task => {

    const { taskTree } = task;

    let isIncluded = false;
    if (taskTree !== undefined && Object.keys(taskTree).length > 0) {
        const { surveyTitles } = taskTree;

        surveyTitles.forEach((surveyTitle, x) => {
            const { title } = surveyTitle
            if (title.toUpperCase().includes(search.toUpperCase())) {
                isIncluded = true;
            }
        })
    } else if (task.planName) {
        isIncluded = task.planName.toUpperCase().includes(search.toUpperCase());
    }

    return isIncluded;
  }

  const dateSearch = (task) => {
    const {modified, deadline} = task
    if(modified !== undefined){
      let edited = moment.utc(modified,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
      let lastDay = moment.utc(deadline,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
      if(edited === search) return true
      if(lastDay === search) return true
      if(search.includes("-")){
          let dates = search.split("-")
          const [d01 ,m01, y01] = lastDay.split(".")
          const [d0, m0, y0] = edited.split(".")
          const [d1, m1, y1] = dates[0].split(".")
          const [d2, m2, y2] = dates[1].split(".")
          let firstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y0+"."+m0+"."+d0))
          let secondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y0+"."+m0+"."+d0))

          let deadlineFirstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y01+"."+m01+"."+d01))
          let deadlineSecondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y01+"."+m01+"."+d01))

          if( firstOk && secondOk )return true
          if( deadlineFirstOk && deadlineSecondOk )return true
      }
    }
    return false
  }

  return (
    <>
      {activeType ==='cards'?
        <HorizontalContainer label={"TARKISTUSPISTEET (" + dTasks.filter(t => statusFilterHandler(t, statusFilters)).filter(task => (task.taskTitle && task.taskTitle.toLowerCase().includes(search.toLowerCase()))|| dateSearch(task) || isSurveyIncluded(task)).length +")"} filter filters={statusFilters} setStatusFilters={setFilters} activeType={activeType} toggleType={toggleType} listName={"tasks"}>
          <HorizontalList>
            {dTasks && dTasks.filter(t => statusFilterHandler(t, statusFilters)).filter(task => (task.taskTitle && task.taskTitle.toLowerCase().includes(search.toLowerCase()))|| dateSearch(task) || isSurveyIncluded(task)).length > 0 ? 
            (dTasks.filter(t => statusFilterHandler(t, statusFilters)).map((task) => {
              if ((task.taskTitle && task.taskTitle.toLowerCase().includes(search.toLowerCase())) || dateSearch(task) || isSurveyIncluded(task)) {
                  return (
                    <HorizontalItem key={task._id}>
                      <ClientTaskCards task={task} openTask={props.onTaskSelect}/>
                    </HorizontalItem>
                  )
              }
            }))
            : 
              <EmptyList isLoading={isLoading} />
            }
          </HorizontalList>
        </HorizontalContainer>
        :
        <VerticalContainer label={"TARKISTUSPISTEET (" + dTasks.filter(t => statusFilterHandler(t, statusFilters)).filter(task => (task.taskTitle && task.taskTitle.toLowerCase().includes(search.toLowerCase()))|| dateSearch(task) || isSurveyIncluded(task)).length +")"} filter filters={statusFilters} setStatusFilters={setFilters} activeType={activeType} toggleType={toggleType} listName={"tasks"}>
            {(dTasks && dTasks.filter(t => statusFilterHandler(t, statusFilters)).filter(task => (task.taskTitle && task.taskTitle.toLowerCase().includes(search.toLowerCase()))|| dateSearch(task) || isSurveyIncluded(task)).length > 0) ?
              <VerticalList>
                  {dTasks.filter(t => statusFilterHandler(t, statusFilters)).map((task, key) => { 
                    if((task.taskTitle && task.taskTitle.toLowerCase().includes(search.toLowerCase())) || dateSearch(task) || isSurveyIncluded(task)){
                      let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                      return( 
                          <VerticalItem key={task._id}>
                            <TaskListItem key={key} task={task} openTask={props.onTaskSelect} oddEvenClass={oddEvenClass}/>
                          </VerticalItem>
                      )
                  }}) 
                }
              </VerticalList>
              :
              <EmptyList isLoading={isLoading} />
            }
        </VerticalContainer>
      }
    </>
  )
}

export const Answers = (props) => {
  const {selectAnswer, activeType, toggleType, search} = props
  const [isLoading, setIsLoading] = useState(true);
  const [answers, setAnswers] = useState([])

  const isMounted = useRef(true);

  const dispatch = useDispatch();

  useEffect(() => {
      let businessId = null

      const fetchAnswers = async params => {
          const response = await addTask('get/latestAnswers', { businessId: businessId }, dispatch)
          setTimeout(() => {
            if (!!response) {
              if (isMounted.current) {
                  setAnswers(response);
                  dispatch(storeLatestAnswers(response));
              }
            }
            if (isMounted.current) setIsLoading(false) 
          }, 200)
      }

      fetchAnswers();

      return () => {
          isMounted.current = false;
      }
  }, [])

  const dateSearch = (answer) => {
    const {answered} = answer

    if(answered !== undefined){
      let modified = moment.utc(answered,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
      if(modified === search) return true
      if(search.includes("-")){
          let dates = search.split("-")
          const [d0, m0, y0] = modified.split(".")
          const [d1, m1, y1] = dates[0].split(".")
          const [d2, m2, y2] = dates[1].split(".")
          let firstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y0+"."+m0+"."+d0))
          let secondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y0+"."+m0+"."+d0))
          // console.log(edited,moment(new Date(y1+"."+m1+"."+d1)), moment(new Date(y2+"."+m2+"."+d2)), firstOk, secondOk);
          if( firstOk && secondOk )return true
          else return false
      }
    } 
    return false
  }

  return (
    <>
      { activeType ==='cards'?
        <HorizontalContainer label={"VIIMEISIMMÄT VASTAUKSET (" + answers.filter(x => x.code && x.code.toUpperCase().includes(search.toUpperCase()) || dateSearch(x) || x.businessName.toUpperCase().includes(search.toUpperCase()) || x.surveyName.toUpperCase().includes(search.toUpperCase())).length +")"} activeType={activeType} toggleType={toggleType} listName={"answers"}>
            <HorizontalList>
                {(answers && answers.filter(x => x.code && x.code.toUpperCase().includes(search.toUpperCase()) || dateSearch(x) || x.businessName.toUpperCase().includes(search.toUpperCase()) || x.surveyName.toUpperCase().includes(search.toUpperCase())).length > 0) ?
                    answers.filter(x => x.code && x.code.toUpperCase().includes(search.toUpperCase()) || dateSearch(x) || x.businessName.toUpperCase().includes(search.toUpperCase()) || x.surveyName.toUpperCase().includes(search.toUpperCase())).map((result, i)=>
                        <HorizontalItem key={result.answerSessionId + i}>
                            <SurveyResultCards result={result} goToReport={selectAnswer} />
                        </HorizontalItem>
                    )
                    :
                    <EmptyList isLoading={isLoading} />
                }
            </HorizontalList>
        </HorizontalContainer>
        :
        <VerticalContainer label={"VIIMEISIMMÄT VASTAUKSET (" + answers.filter(x => x.code && x.code.toUpperCase().includes(search.toUpperCase()) || dateSearch(x) || x.businessName.toUpperCase().includes(search.toUpperCase()) || x.surveyName.toUpperCase().includes(search.toUpperCase())).length +")"} activeType={activeType} toggleType={toggleType} listName={"answers"}>
          { (answers && answers.filter(x => x.code && x.code.toUpperCase().includes(search.toUpperCase()) || dateSearch(x) || x.businessName.toUpperCase().includes(search.toUpperCase()) || x.surveyName.toUpperCase().includes(search.toUpperCase())).length > 0) ? 
            <VerticalList>
                {answers.filter(x => x.code && x.code.toUpperCase().includes(search.toUpperCase()) || dateSearch(x) || x.businessName.toUpperCase().includes(search.toUpperCase()) || x.surveyName.toUpperCase().includes(search.toUpperCase())).map((result, key) => { 
                    let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                    return( 
                        <VerticalItem key={key}>
                          <AnswerListItem key={key} result={result} goToReport={selectAnswer} oddEvenClass={oddEvenClass}/>
                        </VerticalItem>
                    )
                })}
            </VerticalList>
            :
            <EmptyList isLoading={isLoading} />
          }
        </VerticalContainer>
      }
    </>
  )
}

export const Risks = ({activeType, toggleType, goToReport, goToPlan, search}) => {
  const [risks, setRisks] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [riskSelectionModal, setRiskSelectionModal] = useState(false)
  const [selectedRisk, setSelectedRisk] = useState(null)
  const [modifyRisk, setModifyRisk] = useState(false)
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.authentication)
  const getRiskList = async() => {
    const response = await addTask("get/risksForDashboard", {}, dispatch)
    if(response && response.length > 0){
      setRisks(response)
    }
  }
  
  useEffect(()=>{
    getRiskList()
  },[])

  const toggleModal = () => {
    setRiskSelectionModal(!riskSelectionModal)
  }

  const startEditing = () => {
    setModifyRisk(true)
  }
  const selectRisk = (risk) => {
    setSelectedRisk(risk)
    toggleModal()
  }

  const dateSearch = (risk) => {
    const {created} = risk
    if(created !== undefined){
      let createdDate = moment.utc(created,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
      if(createdDate  === search) return true
      if(search.includes("-")){
          let dates = search.split("-")
          const [d0, m0, y0] = createdDate .split(".")
          const [d1, m1, y1] = dates[0].split(".")
          const [d2, m2, y2] = dates[1].split(".")
          let firstOk = moment(new Date(y1+"."+m1+"."+d1)).isSameOrBefore(new Date(y0+"."+m0+"."+d0))
          let secondOk = moment(new Date(y2+"."+m2+"."+d2)).isSameOrAfter(new Date(y0+"."+m0+"."+d0))

          if( firstOk && secondOk )return true
      }
    }
    return false
  }


  return(
    <>
      {selectedRisk &&
        <MainRiskModal 
          riskModal={modifyRisk}
          chosenTarget={{risk:selectedRisk}}
          closed={() => setModifyRisk(false)}
          modifyFromDashboard={true} 
          activeCompany={selectedRisk.targetCompany || "anon"}
          userInfo={userInfo}
          selectReport={goToReport}
        />
      }
    <Modal isOpen={riskSelectionModal} toggle={() => toggleModal()}>
      <ModalHeader toggle={() => toggleModal()}>Riskin valinnat</ModalHeader>
      <ModalBody>
        {selectedRisk && 
          <>
            {selectedRisk.sentSurveys &&
              <Row>
                <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }} onClick={() => goToReport(selectedRisk.sentSurveyId, selectedRisk.targetCompany)}>Siirry raporttiin</button>
              </Row>
            }
            {selectedRisk.planId &&
              <Row>
                  <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }} onClick={() => goToPlan(selectedRisk.planId)}>Siirry lomakkeeseen</button>
              </Row>
            }
            <Row>
                <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }} onClick={() => startEditing()}>Muokkaa riskiä</button>
            </Row>
          </>
        }
      </ModalBody>
      <ModalFooter>
        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggleModal()}>Peruuta</a>
      </ModalFooter>
    </Modal>
    { activeType ==='cards'?
      <HorizontalContainer label={"RISKIT (" + risks.filter( risk => risk.businessName && risk.businessName.toLowerCase().includes(search.toLowerCase()) || dateSearch(risk) || risk.riskClassification.label.toLowerCase().includes(search.toLowerCase()) || risk.code && risk.code.toLowerCase().includes(search.toLowerCase()) || risk.surveyName && risk.surveyName.toLowerCase().includes(search.toLowerCase()) || risk.planName && risk.planName.toLowerCase().includes(search.toLowerCase())).length +")"} activeType={activeType} toggleType={toggleType} listName={"risks"}>
          <HorizontalList>
            {(risks && risks.filter( risk => risk.businessName && risk.businessName.toLowerCase().includes(search.toLowerCase()) || dateSearch(risk) || risk.riskClassification.label.toLowerCase().includes(search.toLowerCase()) || risk.code && risk.code.toLowerCase().includes(search.toLowerCase()) || risk.surveyName && risk.surveyName.toLowerCase().includes(search.toLowerCase()) || risk.planName && risk.planName.toLowerCase().includes(search.toLowerCase())).length > 0 ) ?
                risks.filter( risk => risk.businessName && risk.businessName.toLowerCase().includes(search.toLowerCase()) || dateSearch(risk) || risk.riskClassification.label.toLowerCase().includes(search.toLowerCase()) || risk.code && risk.code.toLowerCase().includes(search.toLowerCase()) || risk.surveyName && risk.surveyName.toLowerCase().includes(search.toLowerCase()) || risk.planName && risk.planName.toLowerCase().includes(search.toLowerCase())).map((result, i)=>
                    <HorizontalItem key={1+ i}>
                      <RiskCard risk={result} selectRisk={selectRisk}/>
                    </HorizontalItem>
                )
                :
                <EmptyList isLoading={isLoading} />
            }
          </HorizontalList>
      </HorizontalContainer>
      :
      <VerticalContainer label={"RISKIT (" + risks.filter( risk => risk.businessName && risk.businessName.toLowerCase().includes(search.toLowerCase()) || dateSearch(risk) || risk.riskClassification.label.toLowerCase().includes(search.toLowerCase()) || risk.code && risk.code.toLowerCase().includes(search.toLowerCase()) || risk.surveyName && risk.surveyName.toLowerCase().includes(search.toLowerCase()) || risk.planName && risk.planName.toLowerCase().includes(search.toLowerCase())).length +")"} activeType={activeType} toggleType={toggleType} listName={"risks"}>
        {(risks && risks.filter( risk => risk.businessName && risk.businessName.toLowerCase().includes(search.toLowerCase()) || dateSearch(risk) || risk.riskClassification.label.toLowerCase().includes(search.toLowerCase()) || risk.code && risk.code.toLowerCase().includes(search.toLowerCase()) || risk.surveyName && risk.surveyName.toLowerCase().includes(search.toLowerCase()) || risk.planName && risk.planName.toLowerCase().includes(search.toLowerCase())).length > 0) ? 
          <VerticalList>
              {risks.filter( risk => risk.businessName && risk.businessName.toLowerCase().includes(search.toLowerCase()) || dateSearch(risk) || risk.riskClassification.label.toLowerCase().includes(search.toLowerCase()) || risk.code && risk.code.toLowerCase().includes(search.toLowerCase()) || risk.surveyName && risk.surveyName.toLowerCase().includes(search.toLowerCase()) || risk.planName && risk.planName.toLowerCase().includes(search.toLowerCase())).map((result, key) => { 
                  let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                  return( 
                      <VerticalItem key={key}>
                        <RiskListItem key={key} risk={result} selectRisk={selectRisk} oddEvenClass={oddEvenClass}/>
                      </VerticalItem>
                  )
              })}
          </VerticalList>
          :
          <EmptyList isLoading={isLoading} />
        }
      </VerticalContainer>
    }
  </>
  )
}

const EmptyList = ({ isLoading, error = "" }) => {
  return (
      <div className="list-placeholder-container">
          {isLoading ?
              <ListLoadingSpinner />
              :
              <h3>{error === "" ? "Ei tuloksia" : error}</h3>
          }
      </div>
  )
}

const ListLoadingSpinner = () => {
  return (
      <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
      </div>
  )
}

export default Tasks
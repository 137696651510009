import { NotificationManager } from 'react-notifications'
import * as ActionTypes from './ActionTypes'
import * as ActionCreator from '../../../store/index'
import { confs } from '../../../modules/config'

export const displayLoading = isLoading => dispatch => {
    dispatch({ type: ActionTypes.LOADING, payload: isLoading })
}

export const clearSelected = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED
    }
}

export const storeBusinesses = (payload) => {
    return {
        type: ActionTypes.GET_BUSINESSES,
        payload: payload
    }
}

const setRiskMatrice = data => {
    return {
        type: ActionTypes.SET_RISK_MATRICE,
        payload: data
    }
}

export const getRiskMatrice = (params) => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'get/riskMatrice', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {

                    if (Object.keys(responseJson).length > 0) {
                        dispatch(setRiskMatrice(responseJson.matrice))
                    } else {
                        NotificationManager.error("Raporttitietojen hakeminen epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000)
                    }
                }
            }).catch(error => {

            })
    }
}

export const getBusinesses = (params) => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        setTimeout(() => {
            fetch(confs.url + 'get/business', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                        dispatch(ActionCreator.authFailed())
                    } else {
                        if (Object.keys(responseJson).length > 0) {
                            dispatch(storeBusinesses(responseJson))
                        } else {
                            NotificationManager.error("Yrityksen tietojen hakeminen epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000)
                            dispatch(storeBusinesses([]))
                        }
                    }
                })
        }, 300);
    }
}

export const storeLatestAnswers = (payload) => {
    return {
        type: ActionTypes.GET_LATEST_ANSWERS,
        payload: payload
    }
}

export const getLatestAnswers = (params) => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        setTimeout(() => {
            fetch(confs.url + 'get/latestAnswers', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                        dispatch(ActionCreator.authFailed())
                    } else {
                        if (responseJson.length > 0) {
                            dispatch(storeLatestAnswers(responseJson))
                        } else {
                            dispatch(storeLatestAnswers([]))
                        }
                    }
                })
        }, 300)
    }
}

export const updateLatestAnswers = (params) => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        setTimeout(() => {
            fetch(confs.url + 'updateAnswerResults', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                        dispatch(ActionCreator.authFailed())
                    } else {
                        if (responseJson.length > 0) {
                            dispatch(storeLatestAnswers(responseJson))
                        } else {
                            dispatch(storeLatestAnswers([]))
                        }
                    }
                })
        }, 300)
    }
}


export const storeSelectedBusiness = (payload) => {
    return {
        type: ActionTypes.SELECT_BUSINESS,
        payload: payload
    }
}

export const selectBusinessForReport = (params) => dispatch => {
    return new Promise(function (resolve) {
        dispatch(storeSelectedBusiness(params.businessId))
        resolve()
    })

}

export const storeReportDashboard = (payload) => {
    return {
        type: ActionTypes.GET_REPORT_DASHBOARD,
        payload: payload
    }
}

export const handleYTJ = (enabled = false) => {
    return {
        type: 'HANDLE_YTJ',
        payload: {enableYTJ : enabled}
    }
}

export const getReportDashboardForBusiness = (params) => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'get/reportDashboard', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    
                    if (Object.keys(responseJson).length > 0) {
                        dispatch(handleYTJ(responseJson.business.enableYTJ));
                        dispatch(storeReportDashboard(responseJson))
                    } else {
                        NotificationManager.error("Raporttitietojen hakeminen epäonnistui, yritä hetken päästä uudelleen", "Virhe", 3000)
                    }
                }
            })
    }
}

export const storeBusinessSentSurveys = (payload) => {
    return {
        type: ActionTypes.SET_BUSINESS_SENTSURVEYS,
        payload: payload
    }
}

export const getSentSurveysForBusiness = (params) => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'get/sentSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if(typeof responseJson.surveys !== 'undefined' && responseJson.surveys.length > 0 ){
                        dispatch(storeBusinessSentSurveys(responseJson.surveys))
                    }else{
                        dispatch(storeBusinessSentSurveys([]))
                    }
                }
            })
    }
}

export const storeBusinessProjects = (payload) => {
    return {
        type: ActionTypes.SET_BUSINESS_PROJECTS,
        payload: payload
    }
}

export const getProjectsForBusiness = (params) => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'get/projectsForDashboard', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if(typeof responseJson.projectList !== 'undefined' && responseJson.projectList.length > 0 ){
                        dispatch(storeBusinessProjects(responseJson.projectList))
                    }else{
                        dispatch(storeBusinessProjects([]))
                    }
                }
            })
    }
}

export const fetchRequestStart = () => {
    return {
        type: ActionTypes.START_FETCH_REQUEST
    }
}

export const fetchRequestFinished = (message) => {
    return {
        type: ActionTypes.FETCH_REQUEST_FINISHED,
        payload: message
    }
}

export const storeReport = (payload, sentSurveyIds) => {
    return {
        type: ActionTypes.GET_REPORT,
        payload: payload,
        sentSurveyIds: sentSurveyIds
    }
}

export const storeSurveyVariables = (payload) => {
    return {
        type: ActionTypes.GET_SURVEY_VARIABLES,
        payload: payload
    }
}

export const getReportForDashboard = (params) => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    const sentSurveyIds = []

    params.reports.map(report => sentSurveyIds.push(report.reportId))

    if (params.reports.length > 0) {
        return dispatch => {
            dispatch(displayLoading(true));
            fetch(confs.url + 'get/reportFromTreeV2', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                        dispatch(ActionCreator.authFailed())
                    } else {
                        if (Object.keys(responseJson).length > 0) {

                            const surveyVariables = []
                            responseJson.forEach(survey => {
                                if (survey.surveyVariables) {
                                    surveyVariables.push(...survey.surveyVariables)
                                }
                            })

                            dispatch(storeReport(responseJson, sentSurveyIds))
                            dispatch(storeSurveyVariables(surveyVariables))

                            if (params.baseChangeTo !== undefined && params.baseChangeTo !== null) {
                                NotificationManager.success(`Raportin näkymä vaihdettu pohjaan - ${params.baseChangeTo.label}`, "Ilmoitus", 3000)
                            } else {
                                if (params.answerSessionIds) {
                                    NotificationManager.success(`Raportti suodatettu vastausvaihtoehdon mukaan`, "Ilmoitus", 3000)
                                } else {
                                    if (params.reports.length > 1) {
                                        NotificationManager.success(`Raportin haku onnistui, mukana ${params.reports.length} kartoitusta`, "Ilmoitus", 3000)
                                    } else {
                                        NotificationManager.success(`Raportin haku onnistui, mukana ${params.reports.length} kartoitus`, "Ilmoitus", 3000)
                                    }
                                }
                            }
                            dispatch(displayLoading(false))
                        } else {
                            dispatch(storeReport([], sentSurveyIds))
                            dispatch(storeSurveyVariables([]))
                            NotificationManager.error("Hakutermeillä ei löytynyt vastauksia", "Virhe", 3000)
                            dispatch(displayLoading(false))
                        }
                    }
                }).catch(error => {
                    dispatch(displayLoading(false))
                    NotificationManager.error("Hakutermeillä tuloksien haku epäonnistui", "Virhe", 3000)
                })
        }
    } else {
        return dispatch => {
            dispatch(storeReport([], []))
            dispatch(displayLoading(false))
        }
    }
}

export const storeBusinessSurveyTree = (payload) => {
    return {
        type: ActionTypes.GET_SURVEY_TREE,
        payload: payload
    }
}

export const storeBusiness = (payload) => {
    return {
        type: ActionTypes.GET_BUSINESS,
        payload: payload
    }
}

export const getBusinessSurveyTree = (params) => dispatch => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return fetch(confs.url + 'get/newSurveyTree', {
        method: 'POST',
        body: JSON.stringify(payload)
    })
        .then(res => res.json())
        .then((data) => {
            if (typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
                dispatch(ActionCreator.authFailed());
            } else {
                if( typeof data.business !== 'undefined' ) {
                    const surveyTree = data.surveyTree || []
                    dispatch( storeBusinessSurveyTree( surveyTree ) )
                    dispatch( storeBusiness( data.business ) )
                    dispatch( storeSelectedBusiness( data.business._id ) )
                } else {
                    dispatch( storeBusiness( { businessName: 'Ei kohdeyritystä' } ) )
                    dispatch( ActionCreator.selectBusinessForReport( { businessId: 'anon', name: 'Ei kohdeyritystä' } ))
                }
            }
        })
}

export const fetchSummaryCards = (params) => dispatch => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    fetch(confs.url + 'fetch/surveyCards', {
        method: 'POST',
        body: JSON.stringify(payload)
    })
        .then(res => res.json())
        .then((data) => {
            if (typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
                dispatch(ActionCreator.authFailed());
            } else {
                if ( typeof data.success !== 'undefined' && !data.success ) {
                    dispatch( setSummaryCards( [] ) );
                } else {
                    dispatch( setSummaryCards( data ) )
                }
            }
        })
}

export const setSummaryCards = (data) => {
    return {
        type: ActionTypes.SET_SUMMARY_CARDS,
        payload: data
    }
}

export const reorderSurveytree = (surveyTreeData) => dispatch => {
    dispatch(storeBusinessSurveyTree(surveyTreeData))
    let postData = {
        surveyTreeData: surveyTreeData,
        jwtToken: window.sessionStorage.getItem("token")
    }
    fetch(confs.url + 'business/reorderSurveysNew', {
        method: 'POST',
        body: JSON.stringify(postData)
    })
        .then(res => res.json())
        .then(data => {
            if (typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (!data.success) {
                    NotificationManager.error('Tallennus epäonnistui', 'Virhe', 2000);
                }
            }
        })
}

export const getReportByAnswerSession = (payload) => dispatch => {
    let jwt = window.sessionStorage.getItem("token");

    let newReportPayload = {
        reports: [],
        jwtToken: jwt,
        answerSessionIds: payload.answerSessionIds
    }

    payload.reports.map((report) => {
        return newReportPayload.reports.push({ reportId: report.sentSurveyId })
    })

    const sentSurveyIds = []

    payload.reports.map(report => sentSurveyIds.push(report.reportId))

    if (payload.reports.length > 0) {
        fetch(confs.url + 'get/reportFromTreeV2', {
            method: 'POST',
            body: JSON.stringify(newReportPayload)
        })
            .then(res => res.json())
            .then(data => {
                if (typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if (data.length > 0) {

                        dispatch(storeReport(data, sentSurveyIds))

                        if (payload.answerSessionIds) {
                            NotificationManager.success("Raportti suodatettu valitun vastauksen mukaan.", "Tiedot päivitetty", 3000)
                        } else {
                            if (newReportPayload.reports.length > 1) {
                                NotificationManager.success("Raportissa mukana " + newReportPayload.reports.length + " kartoitusta", "Tiedot päivitetty", 3000);
                            } else {
                                NotificationManager.success("Raportissa mukana " + newReportPayload.reports.length + " kartoitus", "Tiedot päivitetty", 3000);
                            }
                        }
                    }
                }
            })
    }
}

export const addFilter = (newFilter, activeFilters) => dispatch => {
    if (typeof activeFilters === 'undefined') { activeFilters = [] }
    const sameFilter = activeFilters.find(oldFilter => oldFilter.label === newFilter.label)

    const filterIndex = activeFilters.indexOf(sameFilter)
    if (filterIndex === -1) {
        activeFilters.push(newFilter)

        dispatch({
            type: ActionTypes.ADD_FILTER,
            payload: activeFilters
        })
    } else if (newFilter.type === 'answerSession') {
        activeFilters[filterIndex] = newFilter

        dispatch({
            type: ActionTypes.ADD_FILTER,
            payload: activeFilters
        })
    }
}

export const removeFilter = (index) => dispatch => {
    dispatch({
        type: ActionTypes.REMOVE_FILTER,
        payload: index
    })
}

export const setVariables = (payload) => dispatch => {
    dispatch({
        type: ActionTypes.SET_VARIABLES,
        payload: payload
    })
}

export const setSentSurveyIds = ( payload ) => dispatch => {
    return new Promise(function( resolve ) {
    resolve(dispatch({
        type: ActionTypes.SET_SENTSURVEY_IDS,
        payload: payload 
    }))
    })
}

export const saveDemoblock = payload => async dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    const resultJson = await fetch( confs.url + 'save/demoblock', {
        method: 'POST',
        body: JSON.stringify( payload )
    })

    const result = await resultJson.json()

    if( typeof result.success !== 'undefined' && result.success ) {
        dispatch(getReportDashboardForBusiness({ businessId: payload.businessId }))
        NotificationManager.success( 'Esittelylaatikko lisätty', '', 4000 )
    } else {
        NotificationManager.error( 'Esittelylaatikon lisäys epäonnistui', '', 4000 )
    }
}

export const deleteDemoblock = payload => async dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    const resultJson = await fetch( confs.url + 'delete/demoblock', {
        method: 'POST',
        body: JSON.stringify( payload )
    } )

    const result = await resultJson.json()

    if ( typeof result.success !== 'undefined' && result.success ) {
        dispatch( getReportDashboardForBusiness( { businessId: payload.businessId } ) )
        NotificationManager.success( 'Esittelylaatikko poistettu', '', 4000 )
    } else {
        NotificationManager.error( 'Esittelylaatikon poistaminen epäonnistui', '', 4000 )
    }
}


export const saveTrelloList = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'save/trelloList', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        if( typeof result._id !== 'undefined' ) {
            dispatch( getReportDashboardForBusiness( { businessId: payload.businessId } ) )
            NotificationManager.success( 'Trello-taulu luotu', '', 4000 )
        } else {
            NotificationManager.error( 'Trello-taulun luonti epäonnistui', '', 4000 )
        }

        resolve( result )
    })
}

export const deleteTrelloList = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'delete/trelloList', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        if ( typeof result._id !== 'undefined' ) {
            dispatch( getReportDashboardForBusiness( { businessId: payload.businessId } ) )
            NotificationManager.success( 'Trello-taulu poistettu', '', 4000 )
        }

        resolve( result )
    } )
}

export const archiveTrelloCard = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'archive/trelloCard', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        if ( typeof result.id !== 'undefined' ) {
            dispatch( getReportDashboardForBusiness( { businessId: payload.businessId } ) )
            NotificationManager.success( 'Kortti arkistoitu', '', 4000 )
        }

        resolve( result )
    } )
}

const updateTrelloCheckList = (payload) => {
    return {
        type: ActionTypes.UPDATE_TRELLO_CARD_CHECKLIST,
        payload: payload
    }
}

export const updateChecklistTrelloCard = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'modify/trellochecklist', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        if ( typeof result.id !== 'undefined' ) {
            dispatch( updateTrelloCheckList ( { cardId: payload.cardId, checkItem: result } ) )
            NotificationManager.success( 'Tila vaihdettu', '', 4000 );
        }

        resolve( result )
    } )
}

export const getPlansForBusiness = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt
    
    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'get/plansForBusiness', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )
        const result = await resultJson.json()

        if(result.success === true){
            dispatch(setBusinessPlans(result.planList))
        }
    } )
}

export const setBusinessPlans = payload=> {
    return {
        type: ActionTypes.SET_BUSINESS_PLANS,
        payload: payload
    }
}

export const getTrelloBoardLists = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'get/trelloListsForModal', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        resolve( result )
    } )
}

export const getTrelloDataForTimeline = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'get/trelloDataForTimeline', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        resolve( result )
    } )
}

export const getTrelloUserBoards = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'get/trelloUserBoards', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        if( result.length === 0 ) {
            NotificationManager.error( 'Antamillasi tunnuksilla ei löytynyt käyttäjää tai aktiivisia tauluja.', '', 4000 )
        }
        resolve( result )
    } )
}


export const updateBusinessGoalSum = payload => dispatch => {
    let jwt = window.sessionStorage.getItem( "token" )
    payload.jwtToken = jwt

    return new Promise( async ( resolve ) => {
        const resultJson = await fetch( confs.url + 'update/businessGoal', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        if( result.sum ) {
            NotificationManager.success( 'Tavoite päivitetty.', '', 2000 )
            dispatch(updateBusinessGoal(result))
        }
        resolve()
    } )
}

export const updateBusinessValues = payload => {
    return {
        type: ActionTypes.UPDATE_BUSINESS_GOALS,
        payload: payload
    }
}

export const clearReportDashboard = () => {
    return {
        type: ActionTypes.CLEAR_REPORT_DASHBOARD
    }
}

export const updateBusinessGoal = payload=> {
    return {
        type: ActionTypes.UPDATE_BUSINESS_GOAL,
        payload: payload
    }
}
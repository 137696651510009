import React, { useState, useEffect } from 'react';
import { Input, Button, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Form } from 'reactstrap';
import Select from 'react-select';
import { executeRequest } from '../../requestHandler/dataHandler';
import { Scrollbars } from 'react-custom-scrollbars';

export const EnterpriseSelection = ({modalOpen, toggle, enterpriseList}) => {
    const [activeEnterprise, setActiveEnterprise] = useState({})
    const [search, setSearch] = useState("")
    const [sortedEnterprises, setSortedEnterprises] = useState([])
    const [currentEnterprise, setCurrentEnterprise] = useState({})

    useEffect(()=> {
        if(enterpriseList){
            enterpriseList.map( enterprise => {
                if(enterprise.current){
                    setActiveEnterprise(enterprise)
                    setCurrentEnterprise(enterprise)
                }
            })
        }
    },[])

    useEffect(()=>{
        if(enterpriseList){
            let sorted = enterpriseList.sort((a,b) => a.label.localeCompare(b.label))
            setSortedEnterprises([...sorted])
        }
    },[enterpriseList])

    const checkActive = () => {
        enterpriseList.map( enterprise => {
            if(enterprise.current){
                setActiveEnterprise(enterprise)
            }
        })
    }

    const handleEnterpriseChange = async() => {
        let payload = {
            activeEnterprise: activeEnterprise
        }

        const response = await executeRequest('update/activeEnterprise', payload)

        if(response.success){
            window.location.reload();
        }
    }

    const closeAndClean = () => {
        toggle(false)
        checkActive()
    }

    let i = 0;

    return(
        <Modal isOpen={modalOpen} toggle={()=> closeAndClean()}>
            <ModalHeader toggle={()=> closeAndClean()}>Valitse aktiivinen yritys</ModalHeader>
            <ModalBody>
                <Col>
                    <Row>
                        <div>
                            <div style={{ display:'flex'}}>
                                <Label>Aktiivisena: </Label>
                                <p style={{marginLeft:'0.5em', fontWeight:'bold'}}>{currentEnterprise.label}</p>
                            </div>
                            <div style={{borderBottom:'3px solid #6ba53f', height:'0.25em', marginBottom:'1em'}}></div>
                        </div>
                    </Row>
                    <Row>
                       
                    </Row>
                    <Row>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2em', marginBottom:'0.5em' }}>
                            <Input type="search" className="search-bar-open" placeholder={"Hae"} value={search} onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        {/* <Select
                            value={activeEnterprise}
                            onChange={(selected) => setActiveEnterprise(selected)}
                            options={enterpriseList}
                        /> */}
                        <Scrollbars
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            style={{ width: "100%" }}
                            className="custom-scrollbars"
                            autoHeight={true}
                            autoHeightMin={'300px'}
                            autoHeightMax={'500px'}
                        >
                            {sortedEnterprises && sortedEnterprises.length > 0 && sortedEnterprises.filter(entry => entry.label.toUpperCase().includes(search.toUpperCase())).map((enterprise, y)=>{
                                //{sentSurveys.length > 0 && sentSurveys.filter(survey => statusFilter(survey)).map((sentSurvey, index) => {                        
                                let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                i++;
                                return (
                                    <div className={oddEvenClass + ' list-row ' + (activeEnterprise && activeEnterprise === enterprise && 'enterprise-select-active')} style={{ padding: '0.75em 1em', alignItems: 'center', marginBottom:'1px'  }} key={y} onClick={() => setActiveEnterprise(enterprise)}>
                                        <Row className='plan-list-content' style={{ alignItems: 'center' }}>
                                            <Col xs="11">
                                                <p title={enterprise.label} className="content-text name-overflow-handler-long">{enterprise.label}</p> 
                                            </Col> 
                                            <Col xs="1">
                                                {currentEnterprise && currentEnterprise === enterprise && <i className="fas fa-check" style={{ color: 'green'}}></i>}
                                            </Col> 
                                        </Row>
                                    </div>                      
                                )                       
                            })}
                        </Scrollbars> 
                    </Row>
                </Col>
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => closeAndClean()}>Peruuta</a>
                <button className="small-white-button wide-button"onClick={() => handleEnterpriseChange()}>Aktivoi</button>
            </ModalFooter>
        </Modal>
    )
}
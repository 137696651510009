import * as ActionTypes from '../actions/Contacts/ActionTypes'

const initialState = {
    contacts: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_CONTACTS:
            return {
                ...state,
                contacts: action.payload
            }
        default:
            return state
    }
}

import React from 'react';
import {Row, Col} from 'reactstrap';
import ColorPicker from '../../questiongroup/subComponents/ColorPicker';
import DebounceTextEditor from '../../../../../../../../Utilities/TextEditor/DebounceTextEditor';
import '../../../kartoitus.css';

const Analyzer = props => {
    return (
        <div>
            {props.analytics !== undefined && props.analytics.map((analytic, x) =>
                <div key={x}>
                    <Row>
                        <Col xl="12" style={{paddingBottom: '1rem'}}>
                            <Row>
                                <Col xl="6" md="6" style={{ paddingTop: 10 }}>
                                    <h6>Arvot</h6>

                                    <p className="analyzePoints">{analytic.range[0]} - {analytic.range[1]}</p>
                                </Col>
                                <Col xl="6" md="6" className="analyzer-color-row" style={{ paddingTop: 10}}>
                                    <h6>Väri</h6>
                                    <ColorPicker color={analytic.color} handleColor={() => {return}} readOnly={true} index={x} />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl="12" style={{ paddingTop: 10 }}>
                                    <h6>Teksti</h6>
                                    <div>
                                        <DebounceTextEditor 
                                            editorId={`${props.question.id}-${analytic.range[1]}`} 
                                            index={x} 
                                            content={analytic.text} 
                                            onTextChange={props.handleAnalyticChange}
                                            handleSave={props.handleSave} 
                                            readOnly={false}
                                            type="analytics"
                                            plaintextEditor={true}
                                            questionId={props.question.id}
                                            groupId={props.group.id}
                                            surveyId={props.surveyId}
                                            analytics={props.analytics}
                                            analyticPayload={analytic}
                                            additionalClass="analyzerTextArea"
                                            spellCheck={ props.spellCheckOn }
                                            connectionType={"surveys"}
                                            documentId={props.surveyId}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {x !== (props.group.feedbacks.length - 1) && <hr></hr>}
                </div>
            )}
        </div>
    )
}

export default Analyzer;
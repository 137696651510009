import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { confs } from '../../../../modules/config';
import { Scrollbars } from 'react-custom-scrollbars';
import { NotificationManager } from 'react-notifications';
import QlikIframe from './QlikIframe'

const MegaLoaderSpinner = () => {
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

const DemoBlock = ({ dashboardData, itemId, businessId, type, dashboard, onDeleteClick }) => {
    const [data, setData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            businessId: businessId,
            id: itemId,
            type: type
        }
        if (!dashboard) {
            fetchData(payload);
        } else {
            setData(dashboardData)
        }

    }, [itemId]);

    const fetchData = async payload => {
        setIsLoading(true);

        try {
            const response = await fetch(confs.url + 'plan/embedItemData', {
                method: 'POST',
                body: JSON.stringify(payload)
            })

            if (response.success === undefined) {
                const responseJson = await response.json();

                setData(responseJson);
            }
        } catch (err) {
            NotificationManager.error("Valitettavasti lohkon tietojen haussa tapahtui virhe", "", 3000);
        }
        setIsLoading(false);
    }

    return (
        isLoading ?
            <MegaLoaderSpinner />
            :
            <div className="dashboard-medium-box">
                <div className="box-header bigger-text">
                    <p>
                        {data.title}
                        {dashboard &&
                            <i onClick={() => onDeleteClick(data)} className="fas fa-trash-alt" style={{ color: '#ed5652', float: 'right', marginRight: '1em', marginTop: '.3em', cursor: 'pointer' }}></i>
                        }
                    </p>
                </div>
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height: '500px' }}
                >

                    {type === 'list' ? (
                        [...Array(10).keys()].map(number => {

                            let oddEvenClass = number % 2 === 0 ? 'even-row' : 'odd-row'

                            return (
                                Object.keys(data).length > 0 &&
                                <div key={number} className={oddEvenClass} style={{ padding: '1em' }}>
                                    <Row>
                                        {Object.keys(data.data).map((key, index) => {
                                            if (data.data[key] !== '') {
                                                return <Col key={index}>{data.data[key]}</Col>
                                            }
                                        })}

                                    </Row>
                                </div>
                            )
                        })
                    ) : (
                            Object.keys(data).length > 0 &&
                            <>
                                {data.qlik ? <QlikIframe data={data} hostName={data.data.url}/> : <iframe src={data.data.url} frameBorder="0" style={{ height: '100%', width: '100%' }} ></iframe>}
                            </>
                        )}
                </Scrollbars>
            </div>
    )
}

export default DemoBlock;
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer'
import {motion, useAnimation} from 'framer-motion';
import {Row, Col, DropdownMenu, DropdownItem, Dropdown, DropdownToggle, Modal, ModalHeader, ModalFooter, ModalBody} from 'reactstrap';
import {confs} from '../../../modules/config';
import moment from 'moment';
import { AttachmentsModal } from './AttachmentsModal';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { updateSelectedSentSurveysToPresentOriginal } from '../../../store';

export const ProjectSurveyListItem = ({sentSurvey, selectReport, changeDate, toggleChangeSurveyTarget, toggleKartoitusEdit, toggleNameChange, toggleMenu, endSurvey, index, menuIndex, modifyBackground, oddEven, projectId, updateSurveys}) => {
    const [modalOpen, setModalOpen] = useState()
    const [attachmentAnswers, setAttachmentAnswers] = useState()
    const [removeModalOpen, setRemoveModalOpen] = useState(false)

    const [ref, inView, entry] = useInView({
        threshold: 0,
    })

    let startDate
    let endDate
    const today = moment.utc().local()
    let titles = ""

    if (!sentSurvey.dates){
        startDate = moment(new Date(sentSurvey.startDate)).format('DD.MM.YYYY')
        endDate = moment(new Date(sentSurvey.endDate)).format('DD.MM.YYYY')
    }else{
        startDate = moment(new Date(sentSurvey.dates[0].startDate)).format('DD.MM.YYYY')
        endDate = moment(new Date(sentSurvey.dates[0].endDate)).format('DD.MM.YYYY')
    

        sentSurvey.dates && sentSurvey.dates.map((date) => {
            const dateS = moment.utc(date.startDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
            const dateE = moment.utc(date.endDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
            titles = titles + moment(new Date(date.startDate)).format('DD.MM.YYYY') + " - " +moment(new Date(date.endDate)).format('DD.MM.YYYY') + "\n"

            if(today.isBetween(dateS, dateE)){
                startDate = moment(new Date(date.startDate)).format('DD.MM.YYYY')
                endDate = moment(new Date(date.endDate)).format('DD.MM.YYYY')
                return
            } else {
                if(today.isAfter(dateE) && dateE.isAfter(endDate)){
                    startDate = moment(new Date(date.startDate)).format('DD.MM.YYYY')
                    endDate = moment(new Date(date.endDate)).format('DD.MM.YYYY')
                }
            }
        })
    }

    let color = sentSurvey.surveyAvgColor || '#ED5652'
    let avg = sentSurvey.surveyAvgScore || 0

    const variants = {
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {delay: i * 0.3, ease: 'easeIn', duration: 0.2}
        }),
        hidden: {
            opacity: 0
        }
    }
    
    const getSelectedSurveyData = async(params) => {
        if(params && params._id){
            let payload = {
                sentSurveyId: params._id
            }
            const response = await executeRequest('project/getSurveyAttachmentAnswers', payload)
            if(response){
                setAttachmentAnswers(response)
            } 
        }
    }
    const removeSelection = async() => {
        let payload = {
            sentSurveyId: sentSurvey._id,
            projectId:projectId
        }
        const response = await executeRequest('project/removeSurveyFromProject', payload)
        if(response.success){
            setRemoveModalOpen(!removeModalOpen)
            updateSurveys(projectId)
        }
    }

    const toggleAttachmensModal = (sentSurvey) => {
        setModalOpen(true)    
        getSelectedSurveyData(sentSurvey)

    }    

    const toggleModal = () => {
        setModalOpen(!modalOpen)  
    }

    return (
        
        <div className={oddEven + " list-row"}>
        <>
            <Modal isOpen={removeModalOpen} toggle={() => setRemoveModalOpen(!removeModalOpen)}>
                <ModalHeader toggle={() =>setRemoveModalOpen(!removeModalOpen)}>
                        <p>{sentSurvey.name}</p>
                </ModalHeader>
                <ModalBody>
                    <p> Oletko varma, että haluat poistaa kartoituksen projektista?</p>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setRemoveModalOpen(!removeModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => removeSelection()} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>
                </ModalFooter>
            </Modal>
                <AttachmentsModal
                isOpen={modalOpen}
                toggleModal={toggleModal}
                attachments={attachmentAnswers}
                projectId={projectId}
            />
            <Row className="" style={{ padding: '0.25em 0.5em', alignItems: 'center' }} onClick={() => selectReport(sentSurvey)}>
                {/* <Col xs={{ size: 2 }} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="smaller-score-ball" style={avg.length > 3 ? { borderColor: color, fontSize: '0.9rem' } : { borderColor: color }}>{avg}</div>
                </Col> */}
                <Col sm={{ size: 3 }}>
                    <Row>
                        <Col xs={{ size: 12 }}>
                            <p className="content-text name-overflow-handler-long" title={sentSurvey.name}>{sentSurvey.name}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ size: 12 }} className="content-text lighter-text">
                        <p title={sentSurvey.businessName}>{sentSurvey.businessName}</p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={{ size: 2 }} style={{ textAlign: 'center' }} >
                    <p className="lighter-text">{`${startDate} - ${endDate}`} {sentSurvey.dates && sentSurvey.dates.length > 1 ? <i className="far fa-calendar" style={{paddingLeft: '5px'}} title={titles}></i> : ""}</p>
                </Col>
                <Col sm={{ size: 2 }} style={{ textAlign: 'center' }} className="content-text lighter-text">
                    <Row>
                        <Col xs={{ size: 12 }}>
                            <p>{sentSurvey.answerCount}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ size: 12 }}>
                            <p className="lighter-text">Vastausta</p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={{ size: 2 }} style={{ textAlign: 'center' }} className="content-text lighter-text">
                    <Row>
                        <Col xs={{size: 12}}>
                            <p>{sentSurvey.code}</p>
                        </Col>
                        <Col xs={{size: 12}}>
                            <p style={{ fontWeight: 'bold' }}>{sentSurvey.isActive ? 'Aktiivinen' : 'Päättynyt'}</p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={{ size: 2 }} style={{ display: 'flex', justifyContent: 'center' }}>
                    { (sentSurvey && sentSurvey.hasAttachmentQuestions) && 
                        <button onClick={(event) =>  (event.stopPropagation(), toggleAttachmensModal(sentSurvey))} style={{ marginTop: '0.5rem', padding: '0.5em'}} className="small-white-button lighter-text">Tarkastele liitteitä</button>
                    }
                </Col>
                <Col sm={{ size: 1 }} style={{ textAlign: 'center' }}>
                    <Dropdown isOpen={menuIndex === index} onClick={(event) => event.stopPropagation()} toggle={() => toggleMenu(index)} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent:'center' }}>
                        <DropdownToggle tag="div" style={{  float: 'right', fontSize: '1rem' }}>
                            <i className="fas fa-bars" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            { sentSurvey.isActive && <DropdownItem onClick={ () => window.open( `${ confs.frontpageUrl }/${ sentSurvey.code }`, "_blank", "noopener" ) }>Avaa kartoitus</DropdownItem>}
                            {sentSurvey.isActive && <DropdownItem onClick={() => toggleChangeSurveyTarget(sentSurvey)}>Vaihda kohdeyritys</DropdownItem>}
                            <DropdownItem onClick={() => toggleKartoitusEdit( sentSurvey )}>Muokkaa kartoitusta</DropdownItem>
                            <DropdownItem onClick={() => toggleNameChange( sentSurvey )}>Muokkaa nimeä</DropdownItem>
                            <DropdownItem onClick={() => changeDate(sentSurvey)}>Muokkaa aikaväliä</DropdownItem>
                            <DropdownItem onClick={() => modifyBackground(sentSurvey)}>Muokkaa taustakuvaa</DropdownItem>
                            {sentSurvey.isActive && <DropdownItem onClick={(e) => endSurvey(sentSurvey, "cancel")}>Lopeta kartoitus</DropdownItem> }
                            <DropdownItem onClick={() => setRemoveModalOpen(true)}>Poista projektista</DropdownItem>
                            {/* <DropdownItem onClick={() => endSurvey(sentSurvey, "delete")}>Poista kartoitus</DropdownItem> */}
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
        </>
        </div>
    )
}

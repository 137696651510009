import React, { useState } from 'react';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';
import { Button, Row, Col, DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import TextEditor from '../../../Utilities/TextEditor/texteditor';

export const ProductList = ({setCreateModal, products, setSelectedProduct, setModifyModal, updateList}) => {
    const [search, setSearch] = useState("")
    const [delModal, setDelModal] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [selectedData, setSelectedData] = useState()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteText, setDeleteText] = useState("")

    const toggleDelModal = (data) => {
        setSelectedData(data) 
        setDelModal(!delModal)
        setDeleteModalOpen(!deleteModalOpen)
    }
    const toggleDropdown = (y) => {
        if (menuOpen === y) {
            setMenuOpen(null)
        } else {
            setMenuOpen(y)
        }
    }
    const handleProduct = (product) => {
        setSelectedProduct(product)
        setModifyModal(true)

    }

    const handleDelete = async() => {
        if(deleteText === "poista"){
            let params = {
                productId: selectedData._id
            }

            const response = await executeRequest('product/deleteProduct',params)
            setDeleteModalOpen(false)
            setSelectedData({})
            setDeleteText("")
            updateList()
        }
    }

    const handleListNameFilter = product => {
        const { productName, tags} = product
        
        if(productName && productName.toUpperCase().includes(search.toUpperCase())) return true
        
        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
        setDeleteText("")
    }

    return(
        <>
            <Modal isOpen={deleteModalOpen} toggle={() => toggleDeleteModal()}>
                <ModalHeader>{selectedData && selectedData.productName}</ModalHeader>
                <ModalBody>
                    <p>Oletko varma, että haluat poistaa tuotteen? <b>Toimintoa ei voi peruuttaa!</b></p>
                    <div style={{ marginTop: '1em' }}>
                        <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                        <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                        <div className="email-check-box">
                            {deleteText === "poista"?
                                <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                :
                                <i className="fas fa-times" style={{ color: 'red' }}></i> 
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setDeleteModalOpen(!deleteModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => handleDelete()} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                </ModalFooter>
            </Modal>
            <div className="product-box product-container">
                <Col>
                    <Row className="tab-header bigger-text product-color" style={{ width: 'auto', display: 'flex', alignItems: 'center', paddingLeft: 0, borderRadius: '5px 5px 0 0' }}>    
                        <Col>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <p>Tuotteet</p>
                                <BoxSearchBar placeholder='Haku' value={ search } onChange={(val) => setSearch(val)} />
                            </div>
                        </Col>
                        <Col>
                            <button className="new-product-button wide-button small-white-button" style={{ float: 'right' }} onClick={() => setCreateModal(true)}>Uusi Tuote</button>
                        </Col>
                    </Row>
                    <Row className="product-box-body">
                        {products && products.length > 0 ? products.filter(p => handleListNameFilter(p)).map((product, y)=>{
                            let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row';
                            y++;
                            return(
                                
                                <div key={y} onClick={() => handleProduct(product)} style={{ paddingLeft: '1rem'  }} className={oddEvenClass + ' selectable-row'}>
                                    <Row className='task-list-content'>
                                        <Col xs="4" style={{ alignSelf: 'center' }}>
                                            <p className="product-name overflower-short" title={product.productName}>{product.productName}</p>
                                        </Col>
                                        <Col xs="2">
                                            <p style={{ fontWeight: 'bold' }}>Yksikkö</p>
                                            <p>{product.unit}</p>
                                        </Col>
                                        <Col xs="2">
                                            <p style={{ fontWeight: 'bold' }}>Hinta (€)</p>
                                            <p>{product.price}</p>
                                        </Col>
                                        <Col xs="3" style={{ }}>
                                            <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                                                {product.tags && product.tags.slice(0, 8).map((tag, y) => {                        
                                                        return(<div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="tag-display tag-text product-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                    })  
                                                }                                                   
                                                {product.tags && product.tags.length > 8 &&  
                                                        <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                                            <i className="material-icons" title={tagsToMore(product.tags.slice(8,product.tags.length))}>more_horiz</i>
                                                        </div>
                                                }  
                                            </div>                                            
                                        </Col> 
                                        <Col xs="1">
                                            <Dropdown isOpen={menuOpen === y} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent:'center'}} onClick={(event) => event.stopPropagation()} toggle={() => toggleDropdown(y)}>
                                                <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                    <i className="fas fa-bars" title="Valikko"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => toggleDelModal(product)}>Poista</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown> 
                                        </Col>
                                    </Row>
                                </div>  
                            )
                        })
                        :
                        <div style={{ alignSelf:'center' }}>
                            <h3 style={{ marginTop: '2em' }}>Tuotteita ei löytynyt</h3>
                        </div>
                        }                
                    </Row>
                </Col>
            </div>
        </>
    )
}

const tagsToMore = (props) => {
    let tagss = ""
    
    if(props !== undefined){
        props.filter(tags => tagss = tagss + "  " + tags.label.toString())

        return(tagss)
    }
}
import React, { useState } from 'react';
import { Button, Row, Col, DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import moment from 'moment';
import { confs } from '../../../modules/config';
import { NotificationManager } from 'react-notifications';  

export const FileList = (props) => {
    const {fileList, search, update, isLoading} = props
    const [menuOpen, setMenuOpen] = useState(false)
    const [previewModal, setPreviewModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteText, setDeleteText] = useState("")

    const toggleDropdown = (y) => {
        if (menuOpen === y) {
            setMenuOpen(null)
        } else {
            setMenuOpen(y)
        }
    }

    const handleListNameFilter = file => {
        const { name, fileEnd, type } = file
        
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        
        if(fileEnd && fileEnd.toUpperCase().includes(search.toUpperCase())) return true
    
        if(type && type.toUpperCase().includes(search.toUpperCase())) return true

        return false
    }

    const togglePreview = () => {
        setPreviewModal(false)
        setSelectedImage(null)
    }

    const openPreview = (file) => {
        setSelectedImage(file)
        setPreviewModal(true)
    }

    const handleDownload = async(fileId, view=false) => {
        let payload = {
            fileId: fileId,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const response = await executeRequest('project/downloadFile', payload)
        if(response.data){
            try{
                var blob = new Blob([new Uint8Array(response.data.data)], { type: response.mimeType });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.name + "." + response.fileEnd;
                
                document.body.appendChild(link);
                
                if(view){
                    window.open(link)
                } else {
                    link.click();
                }
                document.body.removeChild(link);

            } catch {}
        }       
    }

    const openDeleteModal = (file) => {
        setSelectedFile(file)
        setDeleteModal(true)
    }

    const handleDelete = async(fileId) => {
        if(deleteText === "poista"){
            let payload = {
                fileId:fileId
            }
            const response = await executeRequest("deleteFile",payload)
            if(response.success){
                NotificationManager.success('Tiedosto poistettu', 'Ilmoitus', 4000)
                setSelectedFile(null)
                setDeleteModal(false)
                
                update()
            } else {
                NotificationManager.error('Poisto epäonnistui', 'Virhe', 3000)
            }
            setDeleteText("")
        }
    }

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal)
        setDeleteText("")
    }

    return(
        <>
            <Modal isOpen={deleteModal} toggle={() => toggleDeleteModal()}>
                <ModalHeader>Poista tiedosto <b>{selectedFile && selectedFile.name ? selectedFile.name + "."+selectedFile.fileEnd : ""}</b></ModalHeader>
                <ModalBody>
                    <p>Oletko varma, että haluat poistaa tämän tiedoston? <b>Poisto on pysyvä, eikä sitä voida peruuttaa!</b></p>
                    {selectedFile && !selectedFile.name &&
                         <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '100%', width: '100%', backgroundRepeat: 'no-repeat', marginTop: '1em' }}>
                            <img style={{ height: '100%', width: '100%'}}  src={`${confs.host}/api/get/imagePreview/${selectedFile.id}`}></img>
                        </div>
                    }
                    <div style={{ marginTop: '1em' }}>
                        <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                        <Input id ="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                        <div className="email-check-box">
                            {deleteText === "poista"?
                                <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                :
                                <i className="fas fa-times" style={{ color: 'red' }}></i> 
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => toggleDeleteModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => handleDelete(selectedFile.id)} className="small-white-button wide-button delete-plan" style={{backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={previewModal} toggle={() => togglePreview()}>
                <ModalHeader toggle={() => togglePreview()}>{"Esikatselu"}</ModalHeader>
                <ModalBody>
                    {selectedImage &&
                        <div>
                            {selectedImage.name &&
                                <p style={{ fontWeight:'bold', paddingBottom: '1em' }}>{selectedImage.name + "." + selectedImage.fileEnd}</p>
                            }
                            <div id='plan-title-image' style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '100%', width: '100%', backgroundRepeat: 'no-repeat' }}>
                                <img style={{ height: '100%', width: '100%'}}  src={`${confs.host}/api/get/imagePreview/${selectedImage.id}`}></img>
                            </div>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => togglePreview()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                </ModalFooter>
            </Modal>
            <Col> 
                <Row className="product-box-body">
                    {fileList && fileList.length > 0 ? fileList.filter(file => handleListNameFilter(file)).map((file, y)=>{
                        let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row';
                        
                        let size = file.size || "Ei määritelty"
                        if(!isNaN(file.size) && !isNaN(parseFloat(file.size))){
                            if(file.size > 0.01){
                                size = parseFloat(file.size).toFixed(2) + "MB"
                            } else {
                                let kiloBytes = file.size * 1024
                                size = parseFloat(kiloBytes).toFixed(2) + "KB"
                            }
                            
                        }
                        let isImage = false
                        let preview = {}
                        if(file.type === "draftjs-image" || file.type === "draft-js-image" || (file.mimeType && file.mimeType.toUpperCase().includes('IMAGE'))){
                            isImage = true
                        }
                        return(
                            <div key={y} onClick={() => {return}} style={{ paddingLeft: '1rem'}} className={oddEvenClass + ' list-row'}>
                                <Row className='task-list-content'>
                                    <Col xs={isImage?"5":"7"} style={{ alignSelf: 'center' }}>
                                        {file.name?
                                            <p className="product-name tools-filename-overflower" title={file.name+"."+file.fileEnd}>{file.name+"."+file.fileEnd}</p> 
                                            : 
                                            (file.fileName? 
                                                <p className="product-name tools-filename-overflower" title={file.fileName}>{file.fileName}</p>
                                                :
                                                <p>Ei nimeä</p>
                                            ) 
                                        }
                                    </Col>
                                    {isImage &&
                                        <Col xs={"2"}>
                                            <div id='plan-title-image' onClick={() => openPreview(file)} style={{ backgroundSize: 'contain', backgroundPosition: 'left center', height: '70px', maxWidth: '140px', backgroundRepeat: 'no-repeat' }}>
                                                <img style={{ maxHeight: '70px'}}  src={`${confs.host}/api/get/imagePreview/${file.id}`}></img>
                                            </div>
                                        </Col>
                                    }
                                    <Col xs="2">
                                        <p style={{ fontWeight: 'bold' }}>Luotu</p>
                                        <p>{moment.utc(file.created, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY HH:mm ')}</p>
                                    </Col>
                                    <Col xs="2">
                                        <p style={{ fontWeight: 'bold' }}>{"Koko"}</p>
                                        {file.size ? <p>{size}</p> : <p>Ei tiedossa</p>}
                                    </Col>
                                    
                                    <Col xs="1">
                                        <Dropdown isOpen={menuOpen === y} style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent:'center'}} onClick={(event) => event.stopPropagation()} toggle={() => toggleDropdown(y)}>
                                            <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                <i className="fas fa-bars" title="Valikko"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {file && file.fileEnd && (file.fileEnd.toUpperCase() === "PDF") && <DropdownItem onClick={() => handleDownload(file.id, true)}>Esikatsele</DropdownItem>}
                                                {file.mimeType && <DropdownItem onClick={() => handleDownload(file.id)}>Lataa</DropdownItem>}
                                                <DropdownItem onClick={() => openDeleteModal(file)}>Poista</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown> 
                                    </Col>
                                </Row>
                            </div>  
                        )
                    })
                    :
                    <div style={{ alignSelf:'center' }}>
                        <h3 style={{ marginTop: '2em' }}>Tiedostoja ei löytynyt</h3>
                    </div>
                    }                
                </Row>
            </Col>
        </>
    )
}
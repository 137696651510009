import React, {useState, useRef} from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Input} from 'reactstrap';
import moment from 'moment';
import QlikIframe from '../Modals/previewItems/QlikIframe';
import {ModifiedContainer} from './DataContainers'

const PreviewIFrame = ({item, editable, index, layoutPreview = false}) => {

    return (
        <Row style={{paddingBottom: '1.75em'}} className="plan-object" id={item.id}>
            <Col xl="12">
                {(!editable && !layoutPreview && item.modified !== undefined) && 
                   <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser} />
                }
            </Col>
            <Col xl="12" style={{marginBottom: 8}} className="title-col">
                <div style={{ display: 'flex' }}>
                    {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}<i className="far fa-file-code plan-input subTitle" style={{ marginLeft: '0.5em', alignSelf: 'center'}}></i></div>}            
                    <TextEditor 
                        editorId={`${item.id}-subtitle-${editable}`} 
                        content={item.topicContent} 
                        additionalClass="plan-input subTitle plan-subtitle"
                        readOnly={true}
                        placeholder="-"
                        plaintextEditor={true}
                    />
                </div>
            </Col>
            <Col className="col-sm-12 col-md-10 offset-md-1">
                <div style={item.size}>
                {item.hostName === "" ? <div style={{ height: '100%', width: '100%', background: '#C8C8C8' }}></div> : item.qlik ? <QlikIframe data={item} hostName={item.hostName} style={item.size} />: <iframe src={item.hostName} frameBorder="0" style={item.size}></iframe>}
                </div>
            </Col>
        </Row>
    )
}

export default PreviewIFrame;
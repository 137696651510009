import React, { Component } from 'react';
import { Row, Col, Button, Label, Input, FormGroup, FormFeedback, NavItem, NavLink, Nav, TabPane, TabContent } from 'reactstrap';
import QuestionDeletionModal from './modals/QuestionDeletionModal';
import '../../kartoitus.css';
import { connect } from 'react-redux';
import * as ActionCreator from '../../../../../../../store/index';
import QuestionTabs from './subComponents/QuestionTabs';
import { debounce } from "debounce";
import Select from 'react-select';

import DebounceTextEditor from '../../../../../../../Utilities/TextEditor/DebounceTextEditor';
const questionTypes = { isFreetextQuestion: false, isDateQuestion: false, isVariableQuestion: false, isComment: false, isNPS: false, isBusinessId: false, isAttachmentQuestion: false }

const selectList = [
    {
        value: {
        },
        label: "Normaali"
    },
    {
        value: {
            isFreetextQuestion: true
        },
        label: "Vapaateksti"
    },
    {
        value: {
            isDateQuestion: true
        },
        label: "Päivämäärä"
    },
    {
        value: {
            isVariableQuestion: true
        },
        label: "Taustamuuttuja"
    },
    {
        value: {
            isComment: true
        },
        label: "Välikommentti"
    },
    {
        value: {
            isNPS: true
        },
        label: "NPS"
    },
    {
        value: {
            isBusinessId: true
        },
        label: "Y-tunnus"
    },
    {
        value: {
            isAttachmentQuestion: true
        },
        label: "Liite"
    }
]


class QuestionEdit extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            questionOptions: [],
            analytics: [],
            payload: {},
            titleChanged: false,
            analyticsChanged: false,
            optionsChanged: false,
            currentQuestion: {},
            showInReport: true,
            isFreetextQuestion: false,
            isMounting: true,
            inputIndex: -1,
            targetPayload: {},
            activeTab: '1',
            informationTab: '1',
            questionType: 'normal',
            allowWeekends: false,
            questionId: null,
            followQuestionIsChecked: props.question.followQuestion || false,
            followQuestionEmail: props.question.followQuestionEmail || "",
            followEmailIsValid: null,
            _reMount: false
        }

        this.openQuestionModal = this.openQuestionModal.bind(this);
        this.proceedToDeleteQuestion = this.proceedToDeleteQuestion.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        let object = {
            currentQuestion: this.props.question
        };

        if (typeof this.props.question.text !== 'undefined') {
            object.title = this.props.question.text;
        } else {
            object.title = "";
        }

        if (typeof this.props.question.showInReport !== 'undefined') {
            object.showInReport = this.props.question.showInReport;
        }

        if (typeof this.props.question.isFreetextQuestion !== 'undefined') {
            object.isFreetextQuestion = this.props.question.isFreetextQuestion;
        }
        object.questionType = this.checkQuestionType(object.currentQuestion);


        object.isMounting = false;
        this.setState(object);
    }

    componentDidUpdate(prevProps, prevState) {

        /*if(nextProps.question.analytics.length !== nextProps.groupData.feedbacks.length && this.state.analytics.length !== nextProps.groupData.feedbacks.length) {
            let object = {
                analytics: [...nextProps.question.analytics]
            };
        }*/
        if (prevProps.question !== this.props.question) {
            let object = {
                currentQuestion: this.props.question
            };
            if (typeof this.props.question.text !== 'undefined') {
                object.title = this.props.question.text;
            } else {
                object.title = "";
            }

            if (typeof this.props.question.showInReport !== 'undefined') {
                object.showInReport = this.props.question.showInReport;
            } else {
                object.showInReport = true;
            }
            if (typeof this.props.question.isFreetextQuestion !== 'undefined') {
                object.isFreetextQuestion = this.props.question.isFreetextQuestion;
            } else {
                object.isFreetextQuestion = false;
            }
            if (typeof this.props.question.followQuestion !== 'undefined') {
                object.followQuestionIsChecked = this.props.question.followQuestion
            } else {
                object.followQuestionIsChecked = false
            }
            if (typeof this.props.question.followQuestionEmail !== 'undefined') {
                object.followQuestionEmail = this.props.question.followQuestionEmail
            } else {
                object.followQuestionEmail = ''
            }
            object.questionType = this.checkQuestionType(object.currentQuestion);
            this.setState(object);
        }

        if (prevProps.surveyId !== this.props.surveyId) {
            this.setState({ _reMount: !prevState._reMount });

            setTimeout(() => {
                this.setState(prevState => ({
                    _reMount: !prevState._reMount
                }))
            }, 400);
        }
    }



    componentWillUnmount() {
        this._isMounted = false;
    }

    openQuestionModal(payload) {
        if (this.state.questionModal) {
            this.setState({ questionModal: false, payload: {} });
        } else {
            this.setState({ questionModal: !this.state.questionModal, payload: payload });
        }
    }

    //Handle question deletion
    proceedToDeleteQuestion() {
        if (Object.keys(this.state.payload).length > 0) {
            this.deleteThisQuestion(this.state.payload);
            this.openQuestionModal();
        }
    }

    handleShowInReportChange = () => {
        this.setState(prevState => ({
            showInReport: !prevState.showInReport,
            optionsChanged: true
        }))
    }

    handleFreetextQuestionChange = () => {
        this.setState(prevState => ({
            isFreetextQuestion: !prevState.isFreetextQuestion,
            optionsChanged: true
        }))
    }

    togglePanel = tab => {
        this.setState({ activeTab: tab });
    }

    toggleInformationTab = tab => {
        this.setState({ informationTab: tab });
    }

    scrollToLastOption = () => {
        this.lastOptionRef.current.parentElement.scroll({ top: this.lastOptionRef.current.offsetTop, left: 0, behavior: 'smooth' })
    }

    checkQuestionType = (question) => {

        if (question.isDateQuestion !== 'undefined' && question.isDateQuestion) {
            if (question.allowWeekends !== 'undefined') {

                this.setState({ allowWeekends: question.allowWeekends });
            } else {
                this.setState({ allowWeekends: false });
            }
            return "dateQuestion"
        } else if (question.isFreetextQuestion !== 'undefined' && question.isFreetextQuestion) {
            return "freetextQuestion"
        } else if (question.isVariableQuestion !== 'undefined' && question.isVariableQuestion) {
            return "variableQuestion"
        } else if (question.isComment !== 'undefined' && question.isComment) {
            return "comment"
        } else if (question.isNPS !== 'undefined' && question.isNPS) {
            return "NPS"
        }  else if (question.isBusinessId !== 'undefined' && question.isBusinessId) {
            return "businessId"
        } else if (question.isAttachmentQuestion !== 'undefined' && question.isAttachmentQuestion){
            return "attachment"
        } else {
            return "normal"
        }
    }

    changeQuestionType = (type = null) => {
        let types = {}
        if (type !== null) {
            types = {
                ...questionTypes,
                ...type
            }
        } else {
            types = {
                ...questionTypes,
            }
        }

        let payload = {
            groupId: this.props.group.id,
            incomingData: {
                targetQuestion: this.props.question.id,
                type: "text",
                target: 'question',
                targetData: {
                    ...types
                }
            }
        }

        this.props.onOptionModified(payload);
    }

    handleCheckBox = () => {
        const ans = !this.state.allowWeekends;
        this.setState({ allowWeekends: !this.state.allowWeekends });

        let payload = {
            groupId: this.props.group.id,
            incomingData: {
                targetQuestion: this.props.question.id,
                type: "text",
                target: 'question',
                targetData: {
                    allowWeekends: ans
                }
            }
        }
        this.props.onOptionModified(payload);
    }

    handleFollowQuestionCheck = () => {
        const { group, question } = this.props

        this.setState(prevState => ({
            followQuestionIsChecked: !prevState.followQuestionIsChecked
        }), () => {

            const payload = {
                groupId: group.id,
                surveyId: this.props.surveyId,
                incomingData: {
                    targetQuestion: question.id,
                    type: "text",
                    target: 'question',
                    targetData: {
                        followQuestion: this.state.followQuestionIsChecked
                    }
                }
            }

            this.props.surveyId !== undefined && this.props.surveyId !== "" ? this.props.onSentSurveyOptionModified(payload) : this.props.onOptionModified(payload)
        })
    }

    handleFollowEmailChange = (e) => {
        const email = e.target.value

        this.setState({ followEmailInputHasChanged: true, followQuestionEmail: email })
        const emailRegex = /[^@]+@[^\.]+\..+/
        let followEmailIsValid = false

        if (emailRegex.test(email)) {
            followEmailIsValid = true
        } else if (email === '') {
            this.setState({ followEmailInputHasChanged: false })
        }

        this.setState({ followEmailIsValid: followEmailIsValid }, () => {
            this.saveFollowEmail(email)
        })
    }

    saveFollowEmail = debounce((email) => {
        const { group, question } = this.props
        const { followEmailIsValid } = this.state

        if (followEmailIsValid || email === '') {
            let payload = {
                groupId: group.id,
                surveyId: this.props.surveyId,
                incomingData: {
                    targetQuestion: question.id,
                    type: "text",
                    target: 'question',
                    targetData: {
                        followQuestionEmail: email
                    }
                }
            }
            this.props.surveyId !== undefined && this.props.surveyId !== "" ? this.props.onSentSurveyOptionModified(payload) : this.props.onOptionModified(payload)
        }

    }, 1000)

    handleFollowEmailBlur = () => {
        this.setState({ followEmailInputHasChanged: false })
    }

    deleteThisQuestion = (payload) => {
        let thisGroup = this.props.category.questionGroups.filter(group => group.id === payload.groupId);
        if (thisGroup.length > 0) {
            if (thisGroup[0].questions.length === 1) {
                this.props.changeViewToGroup();
            }
        }

        this.props.onQuestionDelete({
            ...payload
        });
    }

    getTypeObject = type => {
        switch (type) {
            case 'normal':
                return {
                    value: {
                    },
                    label: "Normaali"
                }
            case 'freetextQuestion':
                return {
                    value: {
                        isFreetextQuestion: true
                    },
                    label: "Vapaateksti"
                }
            case 'dateQuestion':
                return {
                    value: {
                        isDateQuestion: true
                    },
                    label: "Päivämäärä"
                }
            case 'variableQuestion':
                return {
                    value: {
                        isVariableQuestion: true
                    },
                    label: "Taustamuuttuja"
                }
            case 'comment':
                return {
                    value: {
                        isComment: true
                    },
                    label: "Välikommentti"
                }
            case 'NPS':
                return {
                    value: {
                        isNPS: true
                    },
                    label: 'NPS'
                }
            case 'businessId':
                return {
                    value: {
                        isBusinessId: true
                    },
                    label: 'Y-tunnus'
                }
            case 'attachment':
                return {
                    value: {
                        isAttachmentQuestion: true
                    },
                    label: 'Liite'
                }
            default:
                return {
                    value: {
                    },
                    label: "Normaali"
                }
        }
    }

    render() {
        const typeStyles = {
            option: (provided, state) => ({
                ...provided,
                fontSize: '1rem'
            }),
            container: (provided, state) => ({
                ...provided,
                width: 400
            }),
            menu: (provided, state) => ({
                ...provided,
                width: 400
            })
        }

        let emailInputProps = this.state.followEmailInputHasChanged ? { valid: this.state.followEmailIsValid, invalid: !this.state.followEmailIsValid } : {}

        return (
            <div className={this.props.question.id}>
                <QuestionDeletionModal modal={this.state.questionModal} deleteQuestion={this.proceedToDeleteQuestion} toggle={this.openQuestionModal} />
                <Row style={{ marginTop: '2rem', marginBottom: '2em' }}>
                    <Col>
                        {(this.props.category.original && (this.props.question.text !== undefined || this.props.question.comment !== undefined)) && <Button className="deleteButton" onClick={() => this.openQuestionModal({ targetQuestion: this.props.question.id, groupId: this.props.group.id })}>Poista kysymys</Button>}
                        <h4>{this.props.question.text !== undefined ? "Muokkaa kysymystä" : "Lisää kysymys"}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col><Label>Tyyppi</Label></Col>
                </Row>
                {this.props.category.original ?
                    <Row style={{ padding: '0 1rem' }}>
                        <Select
                            value={this.getTypeObject(this.state.questionType)}
                            onChange={item => this.changeQuestionType(item.value)}
                            options={selectList.filter(x => x.label !== this.getTypeObject(this.state.questionType).label)}
                            placeholder="Valitse kysymyksen tyyppi"
                            noOptionsMessage={() => { return "Valintoja ei löytynyt" }}
                            styles={typeStyles}
                            className="question-type-select"
                            classNamePrefix="select"
                        />
                    </Row>
                    :
                    <Row style={{ padding: '0 1rem' }}>
                        <Col className={this.state.questionType === 'normal' ? 'question-type-selector-active' : 'question-type-selector-disabled'} >Normaali</Col>
                        <Col className={this.state.questionType === 'freetextQuestion' ? 'question-type-selector-active' : 'question-type-selector-disabled'} >Vapaateksti</Col>
                        <Col className={this.state.questionType === 'dateQuestion' ? 'question-type-selector-active' : 'question-type-selector-disabled'} >Päivämäärä</Col>
                        <Col className={this.state.questionType === 'variableQuestion' ? 'question-type-selector-active' : 'question-type-selector-disabled'} >Taustamuuttuja</Col>
                        <Col className={this.state.questionType === 'comment' ? 'question-type-selector-active' : 'question-type-selector-disabled'}>Välikommentti</Col>
                        <Col className={this.state.questionType === 'businessId' ? 'question-type-selector-active' : 'question-type-selector-disabled'}>Y-tunnus</Col>
                        <Col className={this.state.questionType === 'attachment' ? 'question-type-selector-active' : 'question-type-selector-disabled'}>Liite</Col>
                    </Row>
                }
                {this.state.questionType !== 'comment' ? (
                    !this.state._reMount &&
                    <div>
                        <Row style={{ marginTop: '2rem' }}>
                            <Col xl="12">
                                <div className="questionTabs">
                                    <Nav tabs className="tabSelectors">
                                        <NavItem className="questionTabItem">
                                            <NavLink className={this.state.informationTab === '1' ? 'questionTab selected' : 'questionTab'} onClick={() => { this.toggleInformationTab('1'); }}>
                                                Kysymys
                                            </NavLink>
                                        </NavItem>

                                        <NavItem className="questionTabItem">
                                            <NavLink className={this.state.informationTab === '2' ? 'questionTab selected' : 'questionTab'} onClick={() => { this.toggleInformationTab('2'); }}>
                                                Lisätieto
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                {this.state.informationTab === '1' &&
                                    <div tabId="1">
                                        <DebounceTextEditor
                                            editorId={`${this.props.question.id}-${this.props.selectedSurveyIndex}-questionTitle`}
                                            content={this.props.question.text || ""}
                                            questionId={this.props.question.id}
                                            groupId={this.props.group.id}
                                            autoFocus={this.props.question.autoFocus}
                                            additionalClass="categoryTextArea"
                                            readOnly={false}
                                            handleSave={this.props.category.original ? this.props.onOptionModified : this.props.onSentSurveyOptionModified}
                                            type="questionTitle"
                                            surveyId={this.props.surveyId}
                                            plaintextEditor={true}
                                            spellCheck={this.props.spellCheckOn}
                                            connectionType={"surveys"}
                                            documentId={this.props.surveyId}
                                        />
                                    </div>
                                }
                                {this.state.informationTab === '2' &&
                                    <div tabId="2">
                                        <DebounceTextEditor
                                            editorId={`${this.props.question.id}-${this.props.selectedSurveyIndex}`}
                                            content={this.props.question.information || ""}
                                            questionId={this.props.question.id}
                                            groupId={this.props.group.id}
                                            additionalClass="categoryTextArea"
                                            readOnly={false}
                                            handleSave={this.props.category.original ? this.props.onOptionModified : this.props.onSentSurveyOptionModified}
                                            type="questionInformation"
                                            surveyId={this.props.surveyId}
                                            plaintextEditor={false}
                                            spellCheck={this.props.spellCheckOn}
                                            saveDraftjsImage={this.props.saveDraftjsImage}
                                            connectionType={"surveys"}
                                            documentId={this.props.surveyId}
                                        />
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '2rem' }}>
                            <Col xl="12">
                                <FormGroup check>
                                    <Label check>
                                        <Input className="checkBox" type="checkbox" onChange={this.handleFollowQuestionCheck} checked={this.state.followQuestionIsChecked} /> {' '}
                                        Seuraa kysymystä
                                    </Label>
                                </FormGroup>
                                {this.state.followQuestionIsChecked &&
                                    <span>
                                        <Input {...emailInputProps} value={this.state.followQuestionEmail} onBlur={this.handleFollowEmailBlur} onChange={this.handleFollowEmailChange} type="text" placeholder="Sähköpostiosoite" />
                                        <FormFeedback>Syötä sähköpostiosoite oikeassa muodossa</FormFeedback>
                                    </span>
                                }
                            </Col>
                        </Row>
                    </div>
                ) : (
                        !this.state._reMount && <div>
                            <Row style={{ marginTop: '2rem' }}>
                                <Col xl="12">
                                    <h5>Teksti</h5>
                                    <DebounceTextEditor
                                        editorId={`${this.props.question.id}-${this.props.selectedSurveyIndex}`}
                                        content={this.props.question.comment || ""}
                                        questionId={this.props.question.id}
                                        groupId={this.props.group.id}
                                        additionalClass="categoryTextArea"
                                        readOnly={false}
                                        type="comment"
                                        handleSave={this.props.category.original ? this.props.onOptionModified : this.props.onSentSurveyOptionModified}
                                        surveyId={this.props.surveyId}
                                        plaintextEditor={false}
                                        spellCheck={this.props.spellCheckOn}
                                        connectionType={"surveys"}
                                        documentId={this.props.surveyId}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                {/* If question is a freetext question, it doesn't have feedbacks or questionoptions */}
                {(this.state.questionType === 'normal' || this.state.questionType === 'variableQuestion') && (!this.state.isMounting && !this.state._reMount) && this.props.question.questionOptions !== undefined &&
                    <Row className="questionTabsRow" style={{ marginTop: '2rem' }}>
                        <Col>
                            <QuestionTabs
                                getSurveyLinks={this.props.getSurveyLinks}
                                surveyIsDialog={this.props.surveyIsDialog}
                                activeTab={this.state.activeTab}
                                question={this.props.question}
                                onButtonRelease={this.onButtonRelease}
                                optionsHaveMounted={this.optionsHaveMounted}
                                group={this.props.group}
                                handleSave={this.props.category.original ? this.props.onOptionModified : this.props.onSentSurveyOptionModified}
                                activeGroupIndex={this.props.activeGroupIndex}
                                toggle={this.togglePanel}
                                executeLoader={this.props.executeLoader}
                                handleAnalyticChange={this.handleAnalyticChange}
                                analytics={this.props.question.analytics}
                                onPointsAdd={this.props.onPointsAdd}
                                original={this.props.category.original}
                                onPointsDecrement={this.props.onPointsDecrement}
                                onPointsSet={this.props.onPointsSet}
                                changeOptionTab={this.props.onTabChange}
                                tagSearch={this.props.tagSearch}
                                surveyId={this.props.surveyId}
                                searchedTags={this.props.searchedTags}
                                saveDraftjsImage={this.props.saveDraftjsImage}
                                selectedSurveyIndex={this.props.selectedSurveyIndex}
                                categories={this.props.categories}
                                category={this.props.category}
                                spellCheckOn={this.props.spellCheckOn}
                            />
                        </Col>
                    </Row>
                }
                {this.state.questionType === 'dateQuestion' && !this.state.isMounting && this.props.question.text !== undefined &&
                    <Row>
                        <Col xs={{ size: 12 }} style={{ marginTop: '1em' }}>
                            <FormGroup check>
                                <h5 check>
                                    <Input type="checkbox" onChange={this.handleCheckBox} defaultChecked={this.state.allowWeekends} />{' '}
                                    Salli viikonloput
                                </h5>
                            </FormGroup>
                        </Col>
                    </Row>
                }
                {this.state.questionType === 'freetextQuestion' && !this.state.isMounting && this.props.question.text !== undefined && !this.state._reMount &&
                    <Row>
                        <Col xs="12" style={{marginTop: '1em'}}>
                            <h5>Oletusvastaus</h5>
                            <DebounceTextEditor
                                editorId={`freetext-${this.props.question.id}-${this.props.selectedSurveyIndex}`}
                                content={this.props.question.placeholder || ""}
                                questionId={this.props.question.id}
                                groupId={this.props.group.id}
                                additionalClass="categoryTextArea"
                                readOnly={false}
                                type="placeholder"
                                handleSave={this.props.category.original ? this.props.onOptionModified : this.props.onSentSurveyOptionModified}
                                surveyId={this.props.surveyId}
                                plaintextEditor={true}
                                spellCheck={this.props.spellCheckOn}
                                connectionType={"surveys"}
                                documentId={this.props.surveyId}
                            />
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        searchedTags: state.kartoitus.tagSearch
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onOptionModified: (params) => dispatch(ActionCreator.modifyQuestion(params)),
        onSentSurveyOptionModified: (params) => dispatch(ActionCreator.modifySentSurveyQuestion(params)),
        onPointsAdd: (params) => dispatch(ActionCreator.addPoints(params)),
        onPointsDecrement: (params) => dispatch(ActionCreator.decrementPoints(params)),
        onPointsSet: (params) => dispatch(ActionCreator.setPoints(params)),
        onTabChange: (params) => dispatch(ActionCreator.changeTab(params)),
        onQuestionDelete: (params) => dispatch(ActionCreator.deleteQuestionV2(params)),
        tagSearch: (params) => dispatch(ActionCreator.tagSearch(params)),
        getSurveyLinks: (params) => dispatch(ActionCreator.getSurveyLinks(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionEdit);
import React, { Component} from 'react';
import {
    Row,
    Col
  } from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

export default class RiskContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    getTitleBasedOnProps = () => {
        if(this.props.headerSize === 2) {
            return (
                "Riskiarviot"
            )
        }
    }

    render() {
        const {risks, riskTargetData} = this.props;
        return (
            <Row className={this.props.additionalClass} style={{paddingTop: '1rem'}}>
                <Col md={{size: 12}}>

                    <h5 style={{marginBottom: 0}}>
                        <button className="small-white-button lighter-text wide-button" style={{margin: '.25rem 0 .5rem .5rem', float: 'right'}}
                            onClick={() => this.setState({isOpen: !this.state.isOpen})}>{this.state.isOpen ? "Piilota riskiarviot" : "Näytä riskiarviot"}
                        </button>
                        {this.getTitleBasedOnProps()}
                    </h5>

                    <hr style={{clear: 'both'}}/>
                    {this.state.isOpen &&
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%" }}
                        className="custom-scrollbars"
                        autoHeight
                        autoHeightMin={100}
                        autoHeightMax={330}
                        >
                            {risks.length >  0 && 
                                risks.map((risk, index) => {
                
                                let creationDate = risk.created ? moment(new Date(risk.created)).format('DD.MM.YYYY') : ''
                                let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'

                                return <div className={oddEvenClass + ' selectable-row task-list-row'} style={{ paddingLeft: '1rem' }} key={index} 
                                        onClick={() => this.props.modifyRisk(
                                            {
                                             ...riskTargetData,
                                            risk: risk})
                                        }>
                                            <Row>
                                                <Col className="riskIconCol">
                                                    <div style={{display: 'flex', height: '100%'}}>
                                                    <i className="fas fa-exclamation-triangle" style={{paddingRight: '0.5em', color: risk.color, fontSize: '1.5em', alignSelf: 'center'}}></i>
                                                    </div>
                                                </Col>
                                                <Col xs="10">
                                                    <Row className='task-list-content'>
                                                    <Col xs={{ size: 6 }}>
                                                        <div className="tasksText">
                                                            <span className="overflower-short">{risk.explanationPlainContent}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ size: 3 }}>
                                                        <p>{risk.riskGroup.label}</p>
                                                    </Col>
                                                    <Col xs={{ size: 3 }}>
                                                        <p>{risk.riskClassification.label}</p>
                                                    </Col>
                                                    </Row>
                                                    <Row>
                                                    <Col xs={{ size: 6 }}>
                                                        <p className="lighter-text">{risk.riskOdds.label} - {risk.riskLevel.label}</p>
                                                    </Col>
                                                    <Col xs={{ size: 3 }}>
                                                        <p className="lighter-text">{risk.targetCompanyName}</p>
                                                    </Col>
                                                    <Col xs={{ size: 3 }}>
                                                        <p className="lighter-text">{creationDate}</p>
                                                    </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                        })}       
                    </Scrollbars>
                    }
                </Col>
            </Row>
        )
    }
}
import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Treenode from './treenode'

export default class Treehead extends React.Component {

  render() {
    const { index, id, isDragDisabled, surveyTree, onTreeHeadClick, onTreeSurveyClick, selectedSurveys, surveyTreeIndex } = this.props

    return (
      <div style={{ marginBottom: '1em' }}>
        <Draggable index={ index } draggableId={ id } isDragDisabled={ isDragDisabled } type={ `header-${ surveyTreeIndex }` }>
          {provided => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {/* {this.props.surveyTree.title !== 'Kohdistamattomat' &&  */}
              <span>
                <div data-id={ surveyTree.id } className={ "tree-header " + this.props.class }>
                  <span className="tree-head" onClick={() => { onTreeHeadClick && onTreeHeadClick(this.props.surveyTree) }}>{ surveyTree.title }</span>
                  {/* <i className="material-icons" style={{float:"right"}}>open_with</i> 
                  <i className="material-icons cursor-pointer" onClick={() => this.props.deleteGroup(this.props.surveyTree)} style={{float:"right"}}>delete</i> */}
                </div>
              </span>
              {/* } */}

              <Droppable droppableId={ id } type={ `node-${ surveyTreeIndex }` } style={{ minHeight: '20px' }}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={{ minHeight: '1em' }}
                    className={snapshot.isDraggingOver ? 'dragging-over tree-child' : 'tree-child'}
                    {...provided.droppableProps}
                  >
                    {/* <Scrollbars renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
                  style={{width: "100%", marginBottom: "1em"}}
                  autoHeight autoHeightMin={10} autoHeightMax={500} 
                > */}
                    { surveyTree.surveys && surveyTree.surveys.map((obj, index) => {
                      if ((obj.title.toLowerCase().includes(this.props.filter.toLowerCase()))) {
                        return <Treenode
                          onTreeSurveyClick={ onTreeSurveyClick }
                          key={index}
                          index={index}
                          id={obj.id}
                          title={obj.title}
                          selectedSurveys={ selectedSurveys }
                          surveyObj={obj}
                          surveyTreeIndex={surveyTreeIndex}
                        />
                      }
                      return null
                    }
                    )}
                    {provided.placeholder}

                    {/* </Scrollbars> */}
                  </div>
                )}
              </Droppable>

            </div>
          )}
        </Draggable>

      </div>
    )
  }
}

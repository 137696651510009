/* This stateless component will display a square icon as feedback color, display the point range and feedback text editor*/

import React from 'react';
import {Row, Col} from 'reactstrap';
import ColorPicker from './ColorPicker';
import DebounceTextEditor from '../../../../../../../../Utilities/TextEditor/DebounceTextEditor';
import { convertFromRaw } from 'draft-js'
import '../../../kartoitus.css';

const CustomNumberPicker = props => {
    const {higherRange, lowerRange, index, repeatPositive, repeatNegative, onButtonRelease} = props;

    return (
        <div>
            <div className="customNumberValues">
                {lowerRange} - {higherRange}
            </div>
            <div className="customPlusMinus">
                <div>
                    <i className="material-icons" onMouseDown={() => repeatPositive({index: index, lowerRange: lowerRange, higherRange: higherRange})} onMouseUp={onButtonRelease} onMouseOut={onButtonRelease}>add</i>
                </div>
                <div>
                    <i className="material-icons" onMouseDown={() => repeatNegative({index: index, lowerRange: lowerRange, higherRange: higherRange})} onMouseUp={onButtonRelease} onMouseOut={onButtonRelease}>remove</i>
                </div>
            </div>
        </div>
    )
}
const GroupFeedbackItem = props => {
    const handleFeedbackChange = (rawEditorState) => {
      let plaintextFeedback = convertFromRaw(rawEditorState).getPlainText()
      plaintextFeedback = plaintextFeedback.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')

      props.changeFeedBack(props.index, rawEditorState, plaintextFeedback)
    }

    return (
        
        <Row>
            <Col xl="12">
                <Row>
                    <Col xl="8" md="12" style={{ paddingTop: 10 }}>
                        <h6>Raja-arvot</h6>
                        <Row>
                            <Col>
                            {props.original ? 
                                <CustomNumberPicker 
                                    higherRange={props.rangeHigher} 
                                    lowerRange={props.rangeLower} 
                                    textHandler={props.textHandler} 
                                    handleChange={props.handleNumberValue} 
                                    index={props.index} 
                                    isValid={props.feedbackData.isValid}
                                    repeatPositive={props.repeatPositiveButtonEvent}
                                    repeatNegative={props.repeatNegativeButtonEvent}
                                    onButtonRelease={props.onButtonRelease} 
                                />
                                : <h2 style={{fontSize: '3rem'}}>{props.rangeLower} - {props.rangeHigher}</h2>
                            }
                            </Col>
                        </Row>
                    </Col>
                    <Col xl="2" md="12" style={{ paddingTop: 10}}>
                        <h6>Väri</h6>
                        <Row>
                            <Col xs="1">
                                <ColorPicker color={props.color} handleColor={props.handleColorChange} index={props.index} surveyId={props.surveyId}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl="2">
                        {props.original && <p style={{ cursor: 'pointer', color: 'red' }} onClick={() => props.openRangeModal(props.feedbackData)}>Poista raja-arvo</p>}
                    </Col>
                </Row>
            </Col>
            <Col xl="12">
                <Row>
                    <Col xl="12" style={{ paddingTop: 10 }}>
                        <h6>Palaute</h6>
                        <Row>
                            <Col xl="12"> 
                                <DebounceTextEditor 
                                editorId={`${props.groupId}-${props.index}`} 
                                content={props.feedback}
                                index={props.index} 
                                onTextChange={handleFeedbackChange}
                                handleSave={props.handleSave}
                                feedbacks={props.feedbacks}
                                categoryId={props.categoryId}
                                groupId={props.groupId}
                                type={props.iconFeedback ? "iconFeedback" : "groupFeedbacks"} 
                                surveyId={props.surveyId}
                                readOnly={false} 
                                additionalClass="group-text-area"
                                saveDraftjsImage={props.saveDraftjsImage}
                                spellCheck={ props.spellCheckOn }   
                                connectionType={"surveys"}
                                documentId={props.surveyId}                          
                            />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}



export default GroupFeedbackItem;
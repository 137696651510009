import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Label, Input } from 'reactstrap';
import { TagHandler } from './TagHandler';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';


export const ModifyProductModal = ({isOpen, toggleModal, setSelectedProduct, product, updateList }) => {

    const handleChange = (data) => {
        const { id, value } = data
        setSelectedProduct(prevData => ({
            ...prevData,
            [id]:value
        }))
    }

    const handleSave = async() => {
        let payload = {
            product: product
        }
        const response = await executeRequest('product/updateProduct', payload)
        if(response && response.success == true){
            toggleModal(false)
        }
        updateList()
    }
    // const handleTest = (val) =>{
    //     setTest(val)
    //     if(test && test.length > 0){
    //         let regexTag = /\#[a-zA-ZåÅäÄöÖ]+/g
    //         let matches = val.match(regexTag)
    //         let replaced = val.replaceAll()
    //         console.log(val, '""', replaced);

    //     }
    // }

    // const [test, setTest] = useState("")
    return(
        <Modal size="lg" isOpen={isOpen} toggle={() => toggleModal(false)}>
             <ModalHeader toggle={() => toggleModal(false)}>Muokkaa tuotetta</ModalHeader>
                <ModalBody>
                    <div style={{ marginBottom: '1em' }}>
                        <Label>Tuotteen nimi</Label>
                        <Input id="productName" value={product.productName} onChange={(e)=> handleChange(e.target)}></Input>
                    </div>  
                    <div style={{ marginBottom: '1em' }}>  
                        <Label>Tuotteen hinta</Label>
                        <Input id="price" type='number' value={product.price} onChange={(e)=> handleChange(e.target)}></Input>
                    </div>
                    <div style={{ marginBottom: '1em' }}>  
                        <Label>Yksikkö</Label>
                        <Input id="unit" type='text' value={product.unit} placeholder="esim. tunti, päivä, kerta" onChange={(e)=> handleChange(e.target)}></Input>
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Label>Lisää tuotteelle tageja</Label>
                        <TagHandler
                            tags={product.tags}
                            handleChange={handleChange}
                        />
                    </div>
                     <div style={{ marginBottom: '1em' }}>
                        <Label>Kuvaus</Label>
                        <TextEditor
                            placeholder=" -" 
                            // value={description}                        
                            content={product.description} 
                            onTextChange={(val) => handleChange({ id: "description", value: val})} 
                            readOnly={false}
                            allowFileUpload={false}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => toggleModal(false)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    {product.productName && product.productName.length > 0 &&
                        <button onClick={() => handleSave()} className="small-white-button wide-button" >Tallenna</button>
                    }
                </ModalFooter>
        </Modal>
    )
}
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';


export const ExcelDataModal = ({ data, isOpen, toggle }) => {
    const [rowData, setRowData] = useState([])

    useEffect(() => {
        if (data && data.headers) {
            let rows = [];
            for (let i = 1; i <= data.rowCount; i++) {
                let row = []
                for(let j = 0; j < data.headers.length; j++){
                    row.push(data.data[j].values[i-1])
                }
                rows.push(row)
            }

            setRowData(rows)
        }
    }, [data])

    return <Modal isOpen={isOpen} toggle={toggle} size="xl" className="excel-modal">
        <ModalHeader>{data.name}</ModalHeader>
        <ModalBody style={{overflowX:"auto", overflowY:"auto", maxHeight:"80vh", paddingTop: 0}}>
            {data.data &&
                <Table striped hover reflow>
                    <thead striped style={{position:"sticky", top:0, backgroundColor:"white"}}>
                        <tr>
                            {data.headers.map((head, i) => {
                                return <th key={i}>{head || ''}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody >
                            {rowData.map((row, ind) => {
                                return <tr key={ind}>
                                    {row.map((r, rowInd) => {
                                        return <td key={rowInd} >
                                            {r}
                                        </td>
                                    })}
                                </tr>
                            })}
                    </tbody>
                </Table>
            }
        </ModalBody>
        <ModalFooter>
            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Sulje</a>
        </ModalFooter>
    </Modal>
}
import React from 'react';
import MultiSelect from "@kenshooui/react-multi-select";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback, Input } from 'reactstrap';
import Select from 'react-select';

const riskLevels = [
    {
        label: "Olematon",
        value: 0
    },
    {
        label: "Matala",
        value: 33
    },
    {
        label: "Kohonnut",
        value: 66
    },
    {
        label: "Korkea",
        value: 100
    }
]


const InnerSecondModal = props => {
    return (
        <Modal isOpen={props.innerSecond} toggle={props.toggleSecond} onClosed={props.closeAll ? props.toggle : undefined} size="lg" centered>
            <ModalHeader toggle={props.toggleAll} className="taskModal">
                <div>
                    Vastuuhenkilöt
                    <span className="modalPageNumber">2 / 3</span>
                </div> 
            </ModalHeader>
            <ModalBody>
                <Row className="taskCreatorRow">
                    <Col>
                        <button className="small-white-button lighter-text wide-button" onClick={props.startAdding}>Lisää sähköposti</button>
                        {props.startAddingEmail &&
                            <div style={{paddingTop: "0.8rem"}}>
                                <span>
                                    <Input valid={props.emailReady} autoFocus invalid={!props.emailReady} value={props.emailToAdd} onChange={props.handleEmailChange} type="text" placeholder="Sähköpostiosoite" onKeyPress={props.handleEmailAdd}/>
                                    <FormFeedback valid={props.emailReady}>{props.status.feedback}</FormFeedback>
                                </span>
                            </div>
                        }
                    </Col>
                    <Col xl="12">
                        <p>Vastuuhenkilöt</p>
                        <MultiSelect
                            items={props.emailList}
                            selectedItems={props.selectedEmails}
                            onChange={props.handleEmailSelections}
                            showSearch={props.emailList.length > 0 ? true : false}
                            showSelectAll={false}
                            messages={props.messages}
                            showSelectedItems={false}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="small-white-button lighter-text wide-button" onClick={() => props.toggleSecond()}>Takaisin</a>
                {props.modalReady && <button className="small-white-button lighter-text wide-button" onClick={() => props.toggleFinal()}>Jatka</button>}
            </ModalFooter>
        </Modal>
    )
}

export default InnerSecondModal;
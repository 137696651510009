import React from 'react'
import { motion } from 'framer-motion'

const FilterButton = ( { toggle } ) => {
  return (

    <motion.div
      className="animated-filter-button"
      onClick={ toggle }
      positionTransition
    >
      {
        <i className="fas fa-filter"></i>
      }

    </motion.div>
  )
}

export default FilterButton
import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Scrollbars } from 'react-custom-scrollbars'
import Treehead from './Treehead'
import { connect } from 'react-redux'
import * as ActionCreator from '../../../store/index'
import { NotificationManager } from 'react-notifications'

class ModifySurveyTree extends Component {
    constructor() {
        super()
        this.state = {
            createNew: false,
            edit: false,
            editId: null,
            newTitle: ''
        }
    }

    componentDidMount() {
        this.setState({ surveyTree: this.props.surveyTree });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.surveyTree !== this.props.surveyTree) {
            this.setState({ surveyTree: this.props.surveyTree });
        }
    }

    onDragEnd = result => {
        const { destination, source, type } = result
        const surveyTree = JSON.parse(JSON.stringify(this.state.surveyTree))

        //no changes if there is no destination, or if the dragged items position hasn't changed
        if (!destination) { return }
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        const treeIndex = type
        const editedSurveyTree = surveyTree[ treeIndex ]

        let newSurveyTree = []

        const newIds = Array.from( editedSurveyTree.surveyTree )
        const movedObj = newIds[source.index]
        newIds.splice(source.index, 1)
        newIds.splice(destination.index, 0, movedObj)

        newSurveyTree = [
            ...newIds
        ]
        
        editedSurveyTree.surveyTree = newSurveyTree
        surveyTree[ treeIndex ] = editedSurveyTree

        this.props.reorderSurveys( surveyTree )
    }

    handleNew = ( treeIndex ) => {
        if ( this.state.createNew ) {
            let editedTree = [ ...this.state.surveyTree ]
            
            let newTree = editedTree[ treeIndex ]
            newTree.surveyTree.splice(0, 1)

            editedTree[ treeIndex ] = newTree

            this.setState({ createNew: false, surveyTree: editedTree, edit: false, editId: null, newTitle: '' })
        } else {
            const newObj = { title: '', surveys: [] }
            let editedTree = [ ...this.state.surveyTree ]
            
            const newTree = [
                newObj,
                ...editedTree[ treeIndex ].surveyTree
            ]

            editedTree[ treeIndex ].surveyTree = newTree

            this.setState({ createNew: true, surveyTree: editedTree, edit: true, editId: treeIndex, newTitle: '' })
        }
    }

    handleNewTitle = (event) => {
        this.setState({ newTitle: event.target.value })
    }

    handleSave = () => {
        if (this.state.newTitle !== '' && this.state.newTitle !== 'undefined' && this.state.newTitle !== 'Kohdistamattomat') {
            let treeStack = [ ...this.state.surveyTree ]
            let tree = treeStack[0].surveyTree

            tree[this.state.editId].title = this.state.newTitle
            treeStack[0].surveyTree = tree

            this.setState({ surveyTree: treeStack, edit: false, createNew: false, editId: null, newTitle: '' })

            let editedSurveyTree = {}

            if (this.props.surveyTree && this.props.surveyTree[0].businessId) {
                editedSurveyTree = [ ...this.props.surveyTree ]
            } else {
                editedSurveyTree = [{ businessId: this.props.business._id }]
            }

            editedSurveyTree[0].surveyTree = tree

            
            this.props.reorderSurveys(editedSurveyTree)

        } else if (this.state.newTitle === 'Kohdistamattomat') {
            NotificationManager.error('Ryhmän nimi ei voi olla "Kohdistamattomat"', 'Virhe', 2000);
        } else {
            NotificationManager.error('Ryhmällä täytyy olla nimi', 'Virhe', 2000);
        }
    }

    handleEdit = ( treeIndex, groupIndex ) => {
        
        let treeStack = { ...this.state.surveyTree[treeIndex] }
        let newTitle = treeStack.surveyTree[groupIndex].title

        this.setState({ edit: !this.state.edit, editId: this.state.edit ? null : groupIndex, newTitle: newTitle, createNew: false })
    }

    handleDelete = ( treeIndex, groupIndex ) => {
        let treeStack = [ ...this.state.surveyTree ]
        let selectedTreeBranch = treeStack[treeIndex]
        let tree = selectedTreeBranch.surveyTree
        let removed = treeStack[treeIndex].surveyTree[groupIndex].surveys

        let noCategory = tree.find((element) => {
            return element.title === 'Kohdistamattomat'
        })

        let newSurveys = [
            ...noCategory.surveys,
            ...removed
        ]

        noCategory.surveys = newSurveys
        tree.splice(groupIndex, 1)

        selectedTreeBranch.surveyTree = tree
        treeStack[treeIndex] = selectedTreeBranch

        this.setState({ surveyTree: treeStack, edit: false, editId: null, newTitle: '' })

        let editedSurveyTree = JSON.parse(JSON.stringify(this.props.surveyTree))
        let editedTreeBranch = editedSurveyTree[treeIndex]
        editedTreeBranch.surveyTree = tree
        editedSurveyTree[treeIndex] = editedTreeBranch

        this.props.reorderSurveys(editedSurveyTree)
    }

    render() {
        const { surveyTree } = this.state
        const { userInfo } = this.props

        return (
            <Col md="12" xl="12" >
                <div className="dashboard-medium-box" style={{ boxShadow: 'none' }}>
                    <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                        <p>Raporttien lajittelu</p>
                        <div style={{ marginLeft: 'auto', marginRight: '2em' }}>
                            { userInfo.userType === 'enterprise' && <button className="small-white-button lighter-text wide-button" onClick={ () => this.handleNew(0) }>{!this.state.createNew ? 'Lisää uusi' : 'Peruuta'}</button>}
                        </div>
                    </div>
                    <Scrollbars renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '500px', zIndex: 90 }}
                    >
                        <DragDropContext onDragEnd={this.onDragEnd}>
                        { surveyTree && surveyTree.map(( surveyTreeObj, index ) => {
                            return <Row key={ index }>
                                <Col md={{ size: 12 }}>
                                    { userInfo.userType === 'business' && (
                                        <Row style={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
                                            <Col xs={{ size: 9 }}>
                                                <h5 style={{ fontWeight: 'bold' }}>{ surveyTreeObj.enterpriseName }</h5>
                                            </Col>
                                            <Col xs={{ size: 3 }}>
                                                <button className="small-white-button wide-button lighter-text" onClick={ () => this.handleNew(index) }>{ !this.state.createNew ? 'Lisää ryhmä' : 'Peruuta' }</button>
                                            </Col>
                                        </Row>
                                    )}
                                    
                                        <Droppable droppableId={ surveyTreeObj.enterpriseId } type={ index }>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    className={snapshot.isDraggingOver ? 'dragging-over' : ''}
                                                    {...provided.droppableProps}
                                                >
                                                    {typeof surveyTreeObj.surveyTree != 'undefined' && surveyTreeObj.surveyTree.length > 0 && surveyTreeObj.surveyTree.map((obj, ind) => {
                                                        return <Treehead
                                                            key={ind}
                                                            isDragDisabled={this.state.createNew || this.state.edit ? true : false}
                                                            id={ `${surveyTreeObj.enterpriseId}-${ind}` }
                                                            surveyTree={obj}
                                                            index={ind}
                                                            edit={this.handleEdit}
                                                            isEditing={this.state.editId === ind ? true : false}
                                                            save={this.handleSave}
                                                            createNew={this.state.createNew}
                                                            newTitle={this.state.newTitle}
                                                            handleNewTitle={this.handleNewTitle}
                                                            delete={this.handleDelete}
                                                            treeIndex={index}
                                                        />
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    
                                </Col>
                            </Row>
                        })}
                        </DragDropContext>
                    </Scrollbars>
                </div>
            </Col>
        )
    }
}
const mapStateToProps = state => {
    return {
        business: state.reports.selectedBusiness,
        surveyTree: state.reports.surveyTree
    }
}

const mapDispatchToProps = dispatch => {
    return {
        reorderSurveys: (surveyTree) => dispatch(ActionCreator.reorderSurveytree(surveyTree))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifySurveyTree);
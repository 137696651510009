import * as React from "react";
import { motion, useCycle } from "framer-motion";
import { MenuItem } from "./MenuItem";
import './filter.css';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.02 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const filters = [
    {
        value: 'Tilanne'
    },
    {
        value: 'Asiakkaat'
    }
];

export const Menu = (props) => {
  return (
  <motion.ul variants={variants} className={props.className}>
    {props.surveyFilters.map(i => (
      <MenuItem i={i} key={i.value} handleSelection={ props.handleSelection } mainFilters={ props.mainFilters } />
    ))}
  </motion.ul>
  )
};
import React, {useState, useEffect} from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalFooter, ModalBody, Label, Input, FormGroup, FormFeedback} from 'reactstrap';
import {ModifiedContainer} from './DataContainers';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import moment from 'moment';
import CodeInput from '../../../Utilities/EmailCode/CodeInput';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import {startEditingPlan} from '../Actions/ActionCreator';
import { HorizontalContainer, HorizontalList, HorizontalItem, HorizontalContainerWithSearch } from '../../../Utilities/horizontal-scroller/HorizontalElements';
import { ContactCard } from '../../../Utilities/horizontal-scroller/HorizontalCards';

export const SignatureBlock = ({item,editable,onDelete,toggleItemVisibility, index, saveSignatures, promptSave, planId, plan, layoutPreview = false}) => {

    const [openMenu, toggleMenu] = useState(false)
    const [signatures, setSignatures] = useState(item.signatures? item.signatures: [])
    const [title, setTitle] = useState(item.topicContent)
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [signer, setSigner] = useState({})
    const [signModalOpen, setSignModalOpen] = useState(false)
    const [codeVerificationInProgress, setCodeVerificationInProgress] = useState(false)
    const [verifiedUserData, setVerifiedUserData] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState()
    const [modifiyModal, setModifyModal] = useState(false)

    const dispatch = useDispatch()
    
    const handleDataChanges = (data) => {
        setTitle(data)
        let itemPayload = {
            ...item,
            topicContent: data
        }
        promptSave(itemPayload)
    }
    useEffect(()=> {
        if(item && item.signatures){
            setSignatures(item.signatures)
        }
    },[item])

    const cancel = () => {
        setSignatures(item.signatures)
        setAddModalOpen(!addModalOpen)
    }

    const handleSave = () => {
        let itemPayload = {
            ...item,
            signatures: signatures,
            topicContent: title
        }
        saveSignatures(itemPayload)
        setAddModalOpen(false)
    }


    const startToSignPlan = (signer, index) => {
        setSigner(signer)
        setSelectedIndex(index)
        setSignModalOpen(true)
    }

    const signDocument = async(code) => {
        setCodeVerificationInProgress(true)
        let obj = {
            code: code,
            signer:signer,
            signatureId: item.signatureId,
            planId: planId
        }
        
        const response = await executeRequest('homepage/verifyCode',obj)
        
        if(response.verified){
            setCodeVerificationInProgress(false)
            setSignModalOpen(false)
            setVerifiedUserData(false) 
            dispatch(startEditingPlan(planId))
        } else if(response.codeExpired) {
            NotificationManager.error('Koodi on vanhentunut, ole hyvä ja tilaa uusi koodi painamalla "Lähetä minulle uusi koodi!"-linkkiä',"Virhe!",5000)
            setCodeVerificationInProgress(false)
        } else {
            NotificationManager.error("Virhe varmistaessa koodia! Ole hyvä ja yritä uudelleen. Mikäli tämä virhe tapahtuu uudelleen ole hyvä ja varmista, että sähköpostiosoitteesi on oikein ja yritä uudelleen. Tarvittaessa ota yhteyttä tuki@qibbie.com","Virhe",10000)
            setCodeVerificationInProgress(false)
        }
    }

    const validateSigner = async() => {
        if(signer.name && signer.signerId){
         
            let payload = {
                signer: signer,
                planId: planId,
                signatureId: item.signatureId
            }
            const response = await executeRequest('homepage/sendCode', payload)
            if (response.success){
                setVerifiedUserData(true)
            }
                
        }
    }

    const toggleModifyModal = () => {
        setModifyModal(!modifiyModal)
    }
 

    return (
        Object.keys(item).length > 0 ?
            <>
                <Modal size="lg" isOpen={signModalOpen} >
                    <ModalHeader toggle={() => (setSignModalOpen(!signModalOpen), setVerifiedUserData(false))}>
                        Digitaalinen allekirjoitus
                    </ModalHeader>
                    <ModalBody>
                        {verifiedUserData ?
                            <div>
                                <p style={{ textAlign: 'center', paddingBottom: '1em' }}>Saat kohta sähköpostiisi viisinumeroisen koodin. Saatuasi koodin, ole hyvä ja syötä saamasi koodi alla oleviin kenttiin.</p>
                                <CodeInput onSubmit={signDocument} codeVerificationInProgress={codeVerificationInProgress}/>
                                {!codeVerificationInProgress &&
                                    <>
                                        <p style={{ textAlign: 'center', paddingTop: '2em', fontSize: '0.8rem' }}>Huomioithan, että sähköpostin saapumisessa voi mennä hetki aikaa. Tarkistathan myös varmuuden vuoksi roskapostisi! Mikäli et ole saanut koodia ole hyvä ja paina alla olevaa linkkiä.</p>
                                        <p style={{ textAlign: 'center', paddingTop: '1em', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => validateSigner()}>Lähetä minulle uusi koodi!</p>
                                    </>
                                } 
                           </div>
                            :
                            <Row>
                                <Col>
                                    <p>Mikäli tietosi eivät ole oikein, otathan yhteyytä lomakkeen luoneeseen tahoon. </p>
                                    <div style={{ marginTop: '1em' }}>
                                        <Label>Nimi</Label>
                                        <p>{signer.name}</p>
                                    </div>
                                    <div style={{ marginTop: '1em' }}>
                                        <Label>Yritys</Label>
                                        <p>{signer.business}</p>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}onClick={() => (setSignModalOpen(false), setVerifiedUserData(false), setCodeVerificationInProgress(false))}>Peruuta</a>
                            {!verifiedUserData &&
                                <button className="small-white-button lighter-text wide-button sign-plan-button" onClick={() => validateSigner()}>Varmenna</button>
                            }
                    </ModalFooter>
                </Modal>
                <SignatureModificationModal open={modifiyModal} item={item} toggle={toggleModifyModal} signatures={signatures} setSignatures={setSignatures} saveItem={saveSignatures}/>
                <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                    <Col xl="12">
                        {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red'}}>Yksityinen</span>}
                        {(!editable && !layoutPreview && item.modified !== undefined) && 
                            <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                        }
                    </Col>
                    <Col xl="12" style={{marginBottom: 8}} className="title-col">
                        <div style={{ display: 'flex' }}>
                            {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}<i className="fas fa-signature" style={{ marginLeft:'0.5em' }}></i></div>}            
                            <TextEditor 
                                editorId={`${item.id}-${editable}`} 
                                content={title}
                                onTextChange={(data) => {handleDataChanges(data)}} 
                                additionalClass="plan-input subTitle"
                                placeholder="Otsikko"
                                readOnly={!editable}
                                plaintextEditor={true}
                            />
                        </div>
                    </Col>
                    <Col xl="11" style={{paddingTop: '0.8em'}} className="plan-text-col"> 
                        <Row>
                            {signatures.map((signer,key) => {
                                return(
                                    <Col key={key}>
                                        <Row>
                                            <Col>
                                                <p>
                                                    Päiväys: {signer.signedDate ? moment.utc(signer.signedDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY'): "-"}
                                                </p>
                                                <p style={{ marginTop:'0.25em' }}>{signer.business || ""}</p>
                                            </Col>
                                        </Row>
                                        <Row  className="sign-row">
                                            <Col>
                                                {signer.verified? 
                                                    <div className="signature-container underLine">
                                                        <p className="signed-name">{signer.name}</p>
                                                    </div>
                                                    :
                                                    <div className="signature-container">
                                                        <button className="small-white-button lighter-text wide-button sign-plan-button" style={{ width: '100%', fontSize: '1.05rem' }} onClick={() => startToSignPlan(signer, key)}>Allekirjoita</button>
                                                    </div> 
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: '0.5em' }}>
                                            <Col>
                                                <p>{signer.name}</p>
                                                <p>{signer.email}</p>
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: '0.5em' }}>
                                            <Col>
                                                {signer.verified? 
                                                    <div style={{justifySelf:'flex-end' }}>
                                                        <p>Digitaalinen allekirjoitus:</p>
                                                        {signer.signerCode}
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                    <Col xl="1" style={{paddingLeft: 15}} className="plan-dropdown-col">
                        <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                        {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                            <DropdownToggle tag="div">
                                <i className="fas fa-bars plusIcon" title="Valikko"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                                {plan && !plan.signed && <DropdownItem onClick={() => toggleModifyModal()} className="delete-plan-item">Muokkaa</DropdownItem>}
                                <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.blockName})} className="delete-plan-item">Poista</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>}
                        </div>
                    </Col>
                </Row>
                {/* {editable && 
                    <Row style={{ marginLeft: '0.25em', paddingBottom: "1em" }}>
                        <p style={{ cursor:'pointer' }} onClick={() => setAddModalOpen(true)}> <i className="fas fa-cog dash-plusIcon" style={{ backgroundColor: '#B5E413', color: '#fff' }}></i><span>Hallitse tuotteita</span></p> 
                    </Row>
                } */}
            </>
        :
        null
    )

}

const INITIAL_STATE = {
    name: "",
    email: "",
    business: ""
}

export const SignatureModificationModal = ({open, toggle, item, saveItem, signatures, setSignatures}) => {
    const [signature, setSignature] = useState(INITIAL_STATE)
    const [contacts, setContacts] = useState([])
    const [search, setSearch] = useState("")
    const [emailInputHasChanged, setEmailInputHasChanged] = useState(false)
    const [emailIsValid, setEmailIsValid] = useState(false)
    const modulesInUse = useSelector(state => state.authentication.modulesInUse)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [infoVisible, setInfoVisible] = useState(false)
    const [edit, setEdit] = useState(false)

    const handleSave = () => {
        let payload = {
            ...item,
            signatures:signatures
        }
        saveItem(payload);
        toggle();
    }

    const handleChanges = (props) => {
        const{id, value} = props
        setSignature({
            ...signature,
            [id]:value
        })
    }

    const handleEmailChange = (value) => {
        const email = value
        setSignature({
            ...signature,
            email:value
        })
        setEmailInputHasChanged(true)
        const emailRegex = /[^@]+@[^\.]+\..+/
        let emailIsValid = false

        if (emailRegex.test(email)) {
            emailIsValid = true
        } else if (email === '') {
            setEmailInputHasChanged(false)
        }

        setEmailIsValid(emailIsValid)
    }


    const handleListNameFilter = contact => {
        const {business, tags, name, jobTitle} = contact
        let businessName = business.businessName

        if(businessName && businessName.toUpperCase().includes(search.toUpperCase())) return true
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        if(jobTitle && jobTitle.toUpperCase().includes(search.toUpperCase())) return true
        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    const getContacts = async() => {

        const response = await executeRequest('business/getContactInfo', {})
        if(response.contacts){
            setContacts(response.contacts)
        } 
    }   

    useEffect(()=>{
        getContacts()
    },[])

    const addThisSigner = () => {
        if(signature.name && signature.email){
            let array = signatures
            setSignatures([...array, signature])
        } else {
            NotificationManager.error("Ole hyvä ja tarkista syötetyt tiedot!","Virhe!", 4000)
        }
        clearFields()
    }

    const clearFields = () => {
        setSignature(INITIAL_STATE)
    }

    const removeSigner = (index) => {
        let array = signatures
        array.splice(index,1)
        setSignatures([...array])
    }

    const handleCardSelection = (selected) => {
        if(selected.name && selected.email){
            let signer = {
                name: selected.name, 
                email:selected.email,
                business:(selected.business && selected.business.businessName)? selected.business.businessName : ""
            }
            let array = signatures
            setSignatures([...array, signer])
        }
    }

    const handleSelection = (signer, key) => {
        setEdit(true)
        setSignature(signer)
        setSelectedIndex(key)
    }
    const handleUpdate = () => {
        let array = signatures
        array.splice(selectedIndex, 1, signature)
        setSignatures([...array])
        setSelectedIndex(null)
        clearFields()
    }

    let emailInputProps = emailInputHasChanged ? { valid: emailIsValid, invalid: !emailIsValid } : {}

    return(
        <Modal size="lg" isOpen={open} toggle={() => (setSignature(INITIAL_STATE), toggle())}>
            <ModalHeader toggle={() => (setSignature(INITIAL_STATE), toggle())}>
                Muokkaa allekirjoittajia
            </ModalHeader>
            <ModalBody>
                <div style={{ marginBottom: '1em'}}>
                    <i className="fas fa-question-circle" style={{ cursor: 'pointer' }} onClick={()=> setInfoVisible(!infoVisible)}></i>
                    {infoVisible && <p>Voit valita modaalin alaosasta muokattavan allekirjoittajan, jolloin tiedot täytetään ensin valinnan mukaan. Tämän jälkeen voit suorittaa ja tallentaa muutokset. Voit myös lisätä tai poistaa allekirjoittajia.</p>}
                </div>
                <div>
                    <Label>Nimi *</Label>
                    <Input type="text" value={signature.name} placeholder="Nimi" onChange={(e)=> handleChanges({ id:"name", value: e.target.value })}/>
                </div>
                <div style={{ marginTop: '1em' }}>
                    <Label>Yritys</Label>
                    <Input type="text" value={signature.business} placeholder="Yritys" onChange={(e)=> handleChanges({ id: "business", value: e.target.value })}/>
                </div>
                <div style={{ marginTop: '1em' }}>
                    <FormGroup>
                        <Label>Sähköpostiosoite *</Label>
                        <Input {...emailInputProps} value={signature.email} onBlur={() => setEmailInputHasChanged(false)} onChange={(e) => handleEmailChange(e.target.value)} type="email" placeholder="Sähköpostiosoite" />
                        <FormFeedback>Syötä sähköpostiosoite oikeassa muodossa</FormFeedback>
                    </FormGroup>
                </div>
                <div style={{ marginTop: '1.5em' }}>
                    {edit?
                        <button className="small-white-button lighter-text wide-button sign-plan-button" style={{ width: '100%' }} onClick={() => handleUpdate()}>Tallenna muutokset</button>
                        :
                        <button className="small-white-button lighter-text wide-button sign-plan-button" style={{ width: '100%' }} onClick={() => addThisSigner()}>Lisää allekirjoittaja</button>
                    }
                </div> 
                {modulesInUse.contactManagement && contacts && contacts.length > 0 && 
                    <div style={{ marginTop: '1em', borderBottom: '2px double #6ba53f', borderTop: '2px double #6ba53f', paddingBottom: '1em', paddingTop: '0.5em' }}>
                        <Label>Voit valita lisättävän allekirjoittajan myös kontakteista</Label>
                        <div className="contact-card-overview" style={{marginTop:0}}>
                            <HorizontalContainerWithSearch style={{}} label={"Kontaktit"} setFilter={setSearch} listName={"Kontaktit"}>
                                <HorizontalList>
                                    {contacts.filter(ctct => handleListNameFilter(ctct)).map((contact,key) =>
                                        <HorizontalItem key={key}>
                                            <ContactCard contact={contact} onSelect={handleCardSelection} allowRemove={false}/>
                                        </HorizontalItem>
                                    )}
                                    </HorizontalList>
                                </HorizontalContainerWithSearch>
                        </div>
                    </div>
                }
                
                <Row style={{ marginTop: '1em' }}>
                   {signatures.length > 0 && signatures.map((signer,key) => {
                       return(
                           <Col style={{ cursor: 'pointer' }} key={key} onClick={() => handleSelection(signer, key)}>
                                <div className='delete-hover' style={{float:'right', cursor:'pointer'}} onClick={()=> removeSigner(key)}>
                                    <i className="fas fa-times"></i>
                                </div>
                                <div>
                                    <p>Päiväys: -</p>
                                    <p>{signer.business}</p>
                                </div>
                                <div className="signature-container underLine">
                                    <p className="signed-name">{signer.name}</p>
                                </div>
                                <div>
                                    <p>{signer.name}</p>
                                </div>
                           </Col>
                       )
                   })

                   }
                </Row>
            </ModalBody>
            <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => (setSignature(INITIAL_STATE), toggle())}>Peruuta</a>
                    <button className="small-white-button wide-button" onClick={() => handleSave()}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

const EmptyList = ({ isLoading, error = "" }) => {
    return (
        <div className="list-placeholder-container">
            {isLoading ?
                <ListLoadingSpinner />
                :
                <h3>{error === "" ? "Ei tuloksia" : error}</h3>
            }
        </div>
    )
}

const ListLoadingSpinner = () => {
    return (
        <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}
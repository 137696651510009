export const CHECK_LOGIN = "CHECK_LOGIN";
export const UPDATE_FAVOURITES = "UPDATE_FAVOURITES";
export const AUTH_FAILED = "AUTH_FAILED";
export const AGREEMENT = "AGREEMENT";
export const NEWS_OK = "NEWS_OK";
export const CHANGE_LOGO = "CHANGE_LOGO";
export const CHANGE_BACKGROUND_IMAGE = "CHANGE_BACKGROUND_IMAGE";
export const CHANGE_DOWNLOAD_STATUS = "CHANGE_DOWNLOAD_STATUS";
export const SET_HELPLINKS = "SET_HELPLINKS";
export const SET_FIRST_LOGIN_DONE = "SET_FIRST_LOGIN_DONE";
export const SET_TRELLO_KEYS = "SET_TRELLO_KEYS";
import React, {useState, useRef} from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Input} from 'reactstrap';
import moment from 'moment';
import ReportItem from '../reports/ReportItem';
import {ModifiedContainer} from './DataContainers';

const PreviewReport = ({item, planId, editable, saveDateRange, index, layoutPreview = false}) => {
    const {targetSurvey} = item
    return (
        <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
            <Col xl="12">
                {(editable && !item.visible) && <span style={{fontWeight: 700, color: 'red' }}>Yksityinen</span>}
                {(!editable &&!layoutPreview && item.modified !== undefined) && 
                    <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                }
            </Col>
            <Col xl="12" style={{marginBottom: 8}} className="title-col">
                <div style={{ display: 'flex' }}>
                    <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', display: 'flex', alignSelf: 'center'}}>{index + 1}<i className="fas fa-chart-pie plan-input subTitle" style={{marginLeft: '0.5em', alignSelf: 'center'}}></i></div>            
                    <TextEditor 
                        editorId={`${item.id}-subtitle-${editable}`} 
                        content={item.topicContent} 
                        additionalClass="plan-input subTitle plan-subtitle"
                        readOnly={true}
                        placeholder="-"
                        plaintextEditor={true}
                    />
                </div>
            </Col>
            <Col className="col-sm-12 col-md-10 offset-md-1">
                <ReportItem id={targetSurvey.id} planId={planId} saveDateRange={saveDateRange} item={item}/>
            </Col>
        </Row>
    )
}

export default PreviewReport;
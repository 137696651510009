import React from 'react'
import {
  convertFromRaw
} from 'draft-js'
import { debounce } from 'debounce'

import TextEditor from './texteditor'
import { stripUnicodeFromString } from '../../Utilities/HelperFunctions/helper'

const DebounceTextEditor = (props) => {

  const buildPayload = (rawEditorState, type) => {

    let plaintext = convertFromRaw(rawEditorState).getPlainText()
    // plaintext = plaintext.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, (match) => { return match !== '€' ? '' : match })
    plaintext = stripUnicodeFromString(plaintext)

    let items = {

    }

    let payload = {

    }

    //Feedback
    if (type === 'feedback' && props.surveyId === "") {
      items = {
        feedback: rawEditorState,
        plaintextFeedback: plaintext
      }

      payload = {
        groupId: props.groupId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: type,
          target: 'questionOption',
          targetData: {
            ...props.option,
            ...items
          }
        }
      }
    } else if (type === 'feedback' && props.surveyId !== "") {
      items = {
        feedback: rawEditorState,
        plaintextFeedback: plaintext
      }

      payload = {
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: type,
          target: 'questionOption',
          targetData: {
            ...props.option,
            ...items
          }
        }
      }
    }

    if (type === 'iconFeedback' && props.categoryId !== "") {
      items = [
        ...props.feedbacks
      ];

      let item = {
        ...items[props.index],
        text: rawEditorState,
        plaintextFeedback: plaintext
      };

      items[props.index] = item;

      payload = {
        categoryId: props.categoryId,
        incomingData: {
          iconFeedbacks: [
            ...items
          ]
        }
      }
    }

    //Dialog response
    if (type === 'dialogFeedback' && props.surveyId === "") {
      items = {
        dialogFeedback: rawEditorState,
        dialogPlaintextFeedback: plaintext
      }

      payload = {
        groupId: props.groupId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: type,
          target: 'questionOption',
          targetData: {
            ...props.option,
            ...items
          }
        }
      }
    } else if (type === 'dialogFeedback' && props.surveyId !== "") {
      items = {
        dialogFeedback: rawEditorState,
        dialogPlaintextFeedback: plaintext
      }

      payload = {
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: type,
          target: 'questionOption',
          targetData: {
            ...props.option,
            ...items
          }
        }
      }
    }

    //Survey response
    if (type === 'surveyFeedback' && props.surveyId === "") {
      items = {
        surveyFeedback: rawEditorState,
        surveyPlaintextFeedback: plaintext
      }

      payload = {
        groupId: props.groupId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: type,
          target: 'questionOption',
          targetData: {
            ...props.option,
            ...items
          }
        }
      }
    } else if (type === 'surveyFeedback' && props.surveyId !== "") {
      items = {
        surveyFeedback: rawEditorState,
        surveyPlaintextFeedback: plaintext
      }

      payload = {
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: type,
          target: 'questionOption',
          targetData: {
            ...props.option,
            ...items
          }
        }
      }
    }

    if (type === "comment" && props.surveyId === "") {
      items = {
        comment: rawEditorState,
        plaintextComment: plaintext
      }

      payload = {
        groupId: props.groupId,
        incomingData: {
          targetQuestion: props.questionId,
          type: "comment",
          target: 'question',
          targetData: {
            ...items
          }
        }
      }
    }
    //Question option text
    if (type === "text" && props.surveyId === "") {
      items = {
        text: plaintext
      }

      payload = {
        groupId: props.groupId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: type,
          target: 'questionOption',
          targetData: {
            ...props.option,
            ...items
          }
        }
      }
    } else if (type === "text" && props.surveyId !== "") {
      items = {
        text: plaintext
      }

      payload = {
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: type,
          target: 'questionOption',
          targetData: {
            ...props.option,
            ...items
          }
        }
      }
    }

    //Group title
    if (type === "groupTitle" && props.surveyId === "") {
      payload = {
        categoryId: props.categoryId,
        groupId: props.groupId,
        incomingData: {
          title: plaintext
        }
      }
    } else if (type === "groupTitle" && props.surveyId !== "") {
      payload = {
        categoryId: props.categoryId,
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          title: plaintext
        }
      }
    }

    //Question title
    if (type === "questionTitle" && props.surveyId === "") {
      items = {
        text: plaintext
      }

      payload = {
        groupId: props.groupId,
        incomingData: {
          targetQuestion: props.questionId,
          type: "text",
          target: 'question',
          targetData: {
            ...items
          }
        }
      }
    } else if (type === "questionTitle" && props.surveyId !== "") {
      items = {
        text: plaintext
      }

      payload = {
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          targetQuestion: props.questionId,
          type: "text",
          target: 'question',
          targetData: {
            ...items
          }
        }
      }
    }

    //Question information
    if (type === "questionInformation" && props.surveyId === "") {
      items = {
        information: rawEditorState
      }

      payload = {
        groupId: props.groupId,
        incomingData: {
          targetQuestion: props.questionId,
          type: "text",
          target: 'question',
          targetData: {
            ...items
          }
        }
      }
    } else if (type === "questionInformation" && props.surveyId !== "") {
      items = {
        information: rawEditorState
      }

      payload = {
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          targetQuestion: props.questionId,
          type: "text",
          target: 'question',
          targetData: {
            ...items
          }
        }
      }
    }


    //Question analytics
    if (type === "analytics" && props.surveyId === "") {
      items = {
        ...props.analyticPayload,
        text: rawEditorState,
        plaintextValue: plaintext
      }

      let analytics = [
        ...props.analytics
      ]

      analytics[props.index] = {
        ...items
      }

      payload = {
        groupId: props.groupId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: 'analytics',
          target: 'questionOption',
          targetData: {
            analytics: analytics
          }
        }
      }
    } else if (type === "analytics" && props.surveyId !== "") {
      items = {
        ...props.analyticPayload,
        text: rawEditorState,
        plaintextValue: plaintext
      }

      let analytics = [
        ...props.analytics
      ]

      analytics[props.index] = {
        ...items
      }

      payload = {
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          targetIndex: props.index,
          groupId: props.groupId,
          targetQuestion: props.questionId,
          type: 'analytics',
          target: 'questionOption',
          targetData: {
            analytics: analytics
          }
        }
      }
    }

    //Group description
    if (type === "groupDescription" && props.surveyId === "") {
      payload = {
        categoryId: props.categoryId,
        groupId: props.groupId,
        incomingData: {
          text: rawEditorState,
          plaintextGroupDescription: plaintext
        }
      }
    } else if (type === "groupDescription" && props.surveyId !== "") {
      payload = {
        categoryId: props.categoryId,
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          text: rawEditorState,
          plaintextGroupDescription: plaintext
        }
      }
    }

    //Group feedbacks
    if (type === "groupFeedbacks" && props.surveyId === "") {
      items = [
        ...props.feedbacks
      ]

      let item = {
        ...items[props.index],
        text: rawEditorState,
        plaintextFeedback: plaintext
      }

      items[props.index] = item

      payload = {
        categoryId: props.categoryId,
        groupId: props.groupId,
        incomingData: {
          feedbacks: [
            ...items
          ]
        }
      }
    } else if (type === "groupFeedbacks" && props.surveyId !== "") {
      items = [
        ...props.feedbacks
      ]

      let item = {
        ...items[props.index],
        text: rawEditorState,
        plaintextFeedback: plaintext
      }

      items[props.index] = item

      payload = {
        categoryId: props.categoryId,
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          feedbacks: [
            ...items
          ]
        }
      }
    }

    //Category title
    if (type === "categoryTitle" && props.surveyId === "") {
      payload = {
        categoryId: props.categoryId,
        incomingData: {
          title: plaintext
        }
      }
    } else if (type === "categoryTitle" && props.surveyId !== "") {
      payload = {
        categoryId: props.categoryId,
        surveyId: props.surveyId,
        incomingData: {
          title: plaintext
        }
      }
    }

    if (type === "categoryExplanation" && props.surveyId === "") {
      payload = {
        categoryId: props.categoryId,
        incomingData: {
          text: rawEditorState,
          plaintextCategoryExplanation: plaintext
        }
      }
    } else if (type === "categoryExplanation" && props.surveyId !== "") {
      payload = {
        categoryId: props.categoryId,
        surveyId: props.surveyId,
        incomingData: {
          text: rawEditorState,
          plaintextCategoryExplanation: plaintext
        }
      }
    }

    if (type === "categoryTomHanks" && props.surveyId === "") {
      payload = {
        categoryId: props.categoryId,
        incomingData: {
          plaintextSurveyEndedText: plaintext,
          surveyEndedText: rawEditorState
        }
      }
    } else if (type === "categoryTomHanks" && props.surveyId !== "") {
      payload = {
        categoryId: props.categoryId,
        surveyId: props.surveyId,
        incomingData: {
          plaintextSurveyEndedText: plaintext,
          surveyEndedText: rawEditorState
        }
      }
    }

    if (type === "surveyName") {
      payload = {
        text: plaintext
      }
    }

    if (type === 'categoryUltimateFeedback' && props.surveyId === "") {
      payload = {
        categoryId: props.categoryId,
        incomingData: {
          plaintextFinalFeedback: plaintext,
          finalFeedback: rawEditorState
        }
      }
    } else if (type === 'categoryUltimateFeedback' && props.surveyId !== "") {
      payload = {
        categoryId: props.categoryId,
        surveyId: props.surveyId,
        incomingData: {
          plaintextFinalFeedback: plaintext,
          finalFeedback: rawEditorState
        }
      }
    }

    if (type === "emailTitle") {
      payload = {
        emailTitle: rawEditorState,
        emailTitlePlain: plaintext
      }
    }

    if (type === "emailExplanation") {
      payload = {
        emailExplanation: rawEditorState,
        emailPlainExplanation: plaintext
      }
    }

    if (type === "embed") {
      payload = {
        id: props.id,
        type: props.embedType,
        data: {
          [props.embedType]: {
            text: rawEditorState,
            plaintext: plaintext
          }
        }
      }
    }

    if (type === "embed-mobile") {
      payload = {
        id: props.id,
        type: props.embedType,
        data: {
          [props.embedType]: {
            mobileText: rawEditorState,
            plainMobileText: plaintext
          }
        }
      }
    }

    if (type === "placeholder") {
      items = {
        // placeholder: rawEditorState,
        placeholder: plaintext
      }

      payload = {
        groupId: props.groupId,
        surveyId: props.surveyId,
        incomingData: {
          targetQuestion: props.questionId,
          type: type,
          target: 'question',
          targetData: {
            ...items
          }
        }
      }
    }

    onSave(payload)
  }

  const onSave = debounce((payload) => {
    if (props.handleSave !== undefined) {
      props.handleSave(payload)
    }
  }, 1000)

  const onTextChange = (rawEditorState) => {
    buildPayload(rawEditorState, props.type)
  }

  return (
    <TextEditor {...props} onTextChange={onTextChange} />
  )
}

export default DebounceTextEditor
import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import TextEditor from '../../TextEditor/texteditor';
import { convertFromRaw } from 'draft-js';
import {stripUnicodeFromString} from '../../../Utilities/HelperFunctions/helper';
import Select from 'react-select';
import {connect} from 'react-redux';
import * as ActionCreator from '../../../store/index';
import RiskSummary from './RiskSummary';
import {riskLevelValues, riskOddsValues} from '../../Riskvalues';
import {removeRisk} from '../../../Components/planBuilder/Actions/ActionCreator';
class MainRiskModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            riskName: "",
            classification: null,
            riskControlGroup: null,
            explanation: "",
            odds: null,
            riskLevel: null,
            openMainModal: false,
            openSummaryModal: false,
            modal: false,
            showTree: false,
            riskTree: null,
            previousRisk: null,
            previousOdds: null,
            hideModal: false,
            deleteModal: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.riskModal !== this.props.riskModal) {
            this.setState({modal: this.props.riskModal});

            if(this.props.riskModal) {
                this.props.onOpenGetControlGroups();
                this.props.onOpenGetRiskClassifications();

                if(this.props.riskMatrice === null) {
                    this.props.onOpenGetRiskMatrice();
                }
            }

            if(Object.keys(this.props.chosenTarget.risk).length > 0 && this.props.chosenTarget.risk !== 'new') {
                this.setState({
                    riskId: this.props.chosenTarget.risk._id,
                    riskName: this.props.chosenTarget.risk.riskName ? this.props.chosenTarget.risk.riskName : "",
                    classification: this.props.chosenTarget.risk.riskClassification,
                    riskControlGroup: this.props.chosenTarget.risk.riskGroup,
                    odds: this.props.chosenTarget.risk.riskOdds,
                    riskLevel: this.props.chosenTarget.risk.riskLevel,
                    riskTree: this.props.chosenTarget.risk.riskTree,
                    previousRisk: this.props.chosenTarget.risk.riskLevel,
                    previousOdds: this.props.chosenTarget.risk.riskOdds
                })
                this.handleRiskExplanation(this.props.chosenTarget.risk.explanation);
            } else if(this.props.chosenTarget.risk === 'new') {
                this.setState({
                    riskName: "",
                    classification: null,
                    riskControlGroup: null,
                    explanation: "",
                    odds: null,
                    riskLevel: null,
                    riskTree: null,
                    previousRisk: null,
                    previousOdds: null
                });
            }
            //Fetch riskcontrolgroups, classifications
            /*if(this.props.modal) {
                this.props.onModalOpen({yTunnus: this.props.activeCompany.businessID, type: 'taskModal'});
            }*/
        }
    }

    checkState = () => {
        let status = true;
        if(this.state.riskName === ""){
            status = false;
        }
        if(this.state.classification === null) {
            status = false;
        }

        if(this.state.riskControlGroup === null) {
            status = false;
        }

        if(this.state.explanation === "") {
            status = false;
        }

        if(this.state.odds === null) {
            status = false;
        }

        if(this.state.riskLevel === null) {
            status = false;
        }

        return status;
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleRiskLevel = (value) => {
        this.setState({riskLevel: value});
    }

    handleOdds = (value) => {
        this.setState({odds: value});
    }

    handleClassification = value => {
        this.setState({classification: value});
    }

    handleRiskControlGroup = value => {
        this.setState({riskControlGroup: value});
    }

    toggleSummary = () => {
        this.setState({
            modal: !this.state.modal,
            summary: !this.state.summary
        });
    }

    handleRiskExplanation = (rawEditorState) => {
        let plaintextExplanation = convertFromRaw(rawEditorState).getPlainText()
        plaintextExplanation = stripUnicodeFromString( plaintextExplanation )
        // plaintextEmail = plaintextEmail.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        this.setState({explanation: rawEditorState, explanationPlainContent: plaintextExplanation});
    }

    onRiskSave = (visibility = null) => {
        
        if(this.props.planId !== undefined && this.props.addModifyPlanRisk !== undefined) {
            let payload = {
                riskId: this.state.riskId,
                planId: this.props.planId,
                riskName: this.state.riskName,
                explanation: this.state.explanation,
                explanationPlainContent: this.state.explanationPlainContent,
                riskScore:  this.state.riskLevel.value * this.state.odds.value,
                riskLevel: this.state.riskLevel,
                riskOdds: this.state.odds,
                target: {...this.props.chosenTarget},
                riskControl: this.state.riskControlGroup,
                riskClassification: this.state.classification,
                companyTarget: this.props.activeCompany.value,
                status: this.props.planStatus,
                hide: visibility
            }
            
            this.props.addModifyPlanRisk(payload);
            this.setState({modal: false, summary: false});
            this.props.closed();

        } else if(this.props.modifyFromDashboard === undefined) {
            let payload = {
                riskId: this.state.riskId,
                riskName: this.state.riskName,
                explanation: this.state.explanation,
                explanationPlainContent: this.state.explanationPlainContent,
                riskScore:  this.state.riskLevel.value * this.state.odds.value,
                riskLevel: this.state.riskLevel,
                riskOdds: this.state.odds,
                target: {...this.props.chosenTarget},
                activeReports: this.props.activeIds,
                riskControl: this.state.riskControlGroup,
                riskClassification: this.state.classification,
                companyTarget: this.props.activeCompany._id
            }

            this.props.onSave(payload);
            this.setState({modal: false, summary: false});
            this.props.closed();
        } else {
            let payload = {
                riskId: this.state.riskId,
                riskName: this.state.riskName,
                explanation: this.state.explanation,
                explanationPlainContent: this.state.explanationPlainContent,
                riskScore:  this.state.riskLevel.value * this.state.odds.value,
                riskLevel: this.state.riskLevel,
                riskOdds: this.state.odds,
                target: {...this.props.chosenTarget},
                riskControl: this.state.riskControlGroup,
                riskClassification: this.state.classification,
                modifiedFromDashboard: true,
                companyTarget: this.props.activeCompany || this.props.userInfo.businessId
            }

            this.props.onSave(payload);
            this.setState({modal: false, summary: false});
            this.props.closed();
        }
        
    }

    getSurveyTitles = surveyTitles => {
        let titlesAsString = "";

        surveyTitles.map((title, x) => {
            if(x !== surveyTitles.length - 1) {
                titlesAsString += title.title + "   " + `( ${title.code} )` + ", ";
            } else {
                titlesAsString += title.title + "   " + `( ${title.code} )`;
            }
            return null
        })
        return titlesAsString;
    }

    toggleDeletionModal = () => {
        this.setState({deleteModal: !this.state.deleteModal});
    }

    toggleHideModal = () => {
        this.setState({hideModal: !this.state.hideModal});
    }

    toggleVisibility = visible => {
        let payload = {
            planTaskId: this.props.chosenTarget._id,
            hide: visible
        }
        this.props.onTaskModify(payload);
        this.toggleAll();
    }

    render() {
        const firstModalReady = this.checkState();
        const {riskTree} = this.state;
        const {selectReport} = this.props;

        if(this.state.modal) {
            return (
                <>
                {this.state.deleteModal && 
                        <Modal isOpen={this.state.deleteModal} toggle={this.toggleDeletionModal} className={this.props.className} centered>
                            <ModalHeader>
                                Tarkistuspisteen poisto
                            </ModalHeader>
                            <ModalBody>
                                <p>Oletko varma, että haluat poistaa valitun tarkistuspisteen?</p>
                                <h6 style={{paddingTop: '1.5em'}}><b>Toimintoa ei voi peruuttaa!</b></h6>
                            </ModalBody>
                            <ModalFooter>
                                <a onClick={this.toggleDeletionModal} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                                <button onClick={() => {this.props.onRiskRemove({targetId: this.props.chosenTarget.risk.targetId, riskId: this.state.riskId}); this.props.closed()}} className="small-white-button swb-hover wide-button delete-risk-button" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                            </ModalFooter>
                        </Modal>
                }
                {this.state.hideModal &&
                    <Modal isOpen={this.state.hideModal} toggle={this.toggleHideModal} className={this.props.className} centered>
                        <ModalHeader>
                            Riskin piilotus
                        </ModalHeader>
                        <ModalBody>
                            <p>Oletko varma, että haluat piilottaa valitun riskin?</p>
                            <h6 style={{paddingTop: '1.5em'}}><b>Riski on valinnan jälkeen vielä voimassa, mutta sitä ei näytetä lomakkeen katselussa.</b></h6>
                        </ModalBody>
                        <ModalFooter>
                            <a onClick={this.toggleHideModal} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                            <button onClick={() => this.onRiskSave((this.props.chosenTarget.risk !== undefined && this.props.chosenTarget.risk.visible !== undefined) ? !this.props.chosenTarget.risk.visible : false)} className="small-white-button wide-button swb-hover" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Piilota</button>{' '}
                        </ModalFooter>
                    </Modal>
                }
                <Modal isOpen={this.state.modal} toggle={this.props.closed} centered size="lg">
                        <ModalHeader toggle={this.props.closed} className="taskModal">
                            <div>
                                {this.props.chosenTarget.risk !== 'new' ? "Muokkaa riskiarviota" : "Luo uusi riskiarvio"}
                                { (riskTree !== null && riskTree !== undefined && riskTree.surveyTitles && riskTree.surveyTitles.length > 0) && <button className="small-white-button lighter-text wide-button" onClick={() => this.setState(prevState => ({
                                    showTree: !prevState.showTree
                                }))} style={{marginLeft: '3rem'}}>
                                    {this.state.showTree ? "Piilota" : "Näytä riskiarvion sijainti"}
                                </button>
                                }
                                <span className="modalPageNumber">1 / 2</span>
                            </div> 
                        </ModalHeader>
                        <ModalBody>
                            {this.props.planId !== undefined &&
                            <div style={{paddingTop: '0.5em', paddingBottom: '0.5em', display: 'flex'}}>
                                {this.props.chosenTarget.risk !== 'new' && 
                                <div>
                                    <button className="small-white-button lighter-text wide-button delete" onClick={
                                        ((this.props.chosenTarget.risk.visible !== undefined && this.props.chosenTarget.risk.visible) || this.props.chosenTarget.risk.visible === undefined ) ? () => this.toggleHideModal() 
                                        : () => this.onRiskSave(!this.props.chosenTarget.risk.visible)}>{(this.props.chosenTarget.risk.visible !== undefined && !this.props.chosenTarget.risk.visible) ? "Näytä riski" : "Piilota riski"}
                                    </button>
                                </div>}
                                {this.props.chosenTarget.risk !== 'new' && <div><button className="small-white-button lighter-text wide-button delete" onClick={() => this.toggleDeletionModal()} style={{marginLeft: '1.8em'}}>Poista riski</button></div> }
                            </div>
                            }
                            {riskTree !== null &&
                                <div>
                                    {this.state.showTree && 
                                        <div>
                                            <Col xl="12" style={{paddingBottom: '0.3rem'}}>
                                                <p>{riskTree.surveyTitles.length === 1 ? "Kartoitus: " : "Kartoitukset: "}</p>
                                                <p><b>{this.getSurveyTitles(riskTree.surveyTitles)}</b></p>
                                            </Col>
                                            <Col xl="12" style={{paddingBottom: '1rem'}}>
                                                <p>Rakenne: </p>
                                                <div className="treeStack">
                                                    {riskTree.elements.survey !== undefined &&
                                                        <div className="taskTreeRow">
                                                            <i className="fas fa-layer-group taskTreeIcon"></i><span>{riskTree.elements.survey}</span>
                                                        </div>
                                                    }
                                                    {riskTree.elements.group !== undefined &&
                                                        <div className="taskTreeRow" style={{paddingLeft: '2em'}}>
                                                            <i className="fas fa-layer-group taskTreeIcon"></i><span>{riskTree.elements.group}</span>
                                                        </div>
                                                    }
                                                    {riskTree.elements.question !== undefined &&
                                                    <div className="taskTreeRow" style={{paddingLeft: '4em'}}>
                                                        <i className="fas fa-layer-group taskTreeIcon"></i><span>{riskTree.elements.question}</span>
                                                    </div>
                                                    }
                                                    { riskTree.surveyTitles.some( sTitle => sTitle.id !== undefined ) &&
                                                    <div style={{ marginTop: '1rem' }}>
                                                        <button className="small-white-button lighter-text" onClick={ () => selectReport( this.props.chosenTarget ) }>Avaa raportti</button>
                                                    </div>
                                                    }
                                                </div>
                                            </Col>
                                        </div>
                                    }
                                </div>
                            }
                            <h4>{this.props.chosenTarget.targetText}</h4>
                            <Row className="taskCreatorRow">
                                <Col xl="12" style={{ paddingTop: 0}} >
                                    <p>Riskin otsikko</p>
                                    <Input id="riskName" className={this.state.riskName? "" : "border-red"} placeholder="Riskin otsikko" value={this.state.riskName} onChange={(e)=> this.setState({riskName: e.target.value})}></Input>               
                                </Col>
                                <Col xl="12" className="riskExplanationEditor">
                                    <p>Selite</p>
                                    <TextEditor editorId={`${this.props.chosenTarget.targetText}-riskExplanation`} content={this.state.explanation} onTextChange={this.handleRiskExplanation} />
                                </Col>
                                <Col xl="12">
                                    <p>Luokittelu</p>
                                    <Select
                                        value={this.state.classification}
                                        onChange={ this.handleClassification }
                                        options={ this.props.riskClassification }
                                        placeholder="Valitse riskiarvion luokittelu"
                                        noOptionsMessage={() => {return "Tuloksia ei löytynyt"}}
                                        className="formula-question-select classification"
                                        classNamePrefix="select"
                                    />
                                </Col>
                                <Col xl="12">
                                    <p>Todennäköisyys</p>
                                    <Select
                                        value={this.state.odds}
                                        onChange={ this.handleOdds }
                                        options={ riskOddsValues }
                                        placeholder="Valitse todennäköisyys"
                                        noOptionsMessage={() => {return "Tuloksia ei löytynyt"}}
                                        className="formula-question-select odds"
                                        classNamePrefix="select"
                                    />
                                </Col>
                                <Col xl="12">
                                    <p>Vaikuttavuus</p>
                                    <Select
                                        value={this.state.riskLevel}
                                        onChange={ this.handleRiskLevel }
                                        options={ riskLevelValues }
                                        placeholder="Valitse vakavuus"
                                        noOptionsMessage={() => {return "Tuloksia ei löytynyt"}}
                                        className="formula-question-select riskLevel"
                                        classNamePrefix="select"
                                    />
                                </Col> 
                                <Col xl="12">
                                    <p>Riskienhallintatoimi</p>
                                    <Select
                                        value={this.state.riskControlGroup}
                                        onChange={ this.handleRiskControlGroup }
                                        options={ this.props.riskControlGroups }
                                        placeholder="Valitse riskienhallintatoimi"
                                        noOptionsMessage={() => {return "Tuloksia ei löytynyt"}}
                                        className="formula-question-select action"
                                        classNamePrefix="select"
                                    />
                                </Col> 
                            </Row>
                        </ModalBody>
                        <ModalFooter className="taskCreatorFooter">
                            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => this.props.closed()}>Peruuta</a>
                            {firstModalReady && <button className="small-white-button wide-button" onClick={() => this.toggleSummary()}>Jatka</button>}
                        </ModalFooter>
                    </Modal>
                    </>
            )
        }

        if(this.state.summary) {
            return (
                <RiskSummary riskMatrice={this.props.riskMatrice} saveRisk={this.onRiskSave} previousRiskLevel={this.state.previousRisk} previousOdds={this.state.previousOdds} classification={this.state.classification} toggleSummary={this.toggleSummary} riskControl={this.state.riskControlGroup} riskName={this.state.riskName} riskExplanation={this.state.explanation} selectedRiskLevel={this.state.riskLevel} odds={this.state.odds} summary={this.state.summary} />
            )
        }

        return (
            null
        )
    }
}

const mapStateToProps = state => {
    return {
        riskControlGroups: state.reports.riskControlGroups,
        riskClassification: state.reports.riskClassifications,
        riskMatrice: state.reports.riskMatrice
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onOpenGetControlGroups: (params) => dispatch(ActionCreator.fetchControlGroups(params)),
        onOpenGetRiskClassifications: (params) => dispatch(ActionCreator.fetchRiskClassifications(params)),
        onSave: (params) => dispatch(ActionCreator.saveTargetRisk(params)),
        onOpenGetRiskMatrice: (params) => dispatch(ActionCreator.getRiskMatrice(params)),
        onRiskRemove: (params) => dispatch(removeRisk(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainRiskModal);
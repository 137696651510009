import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap';

import * as ActionCreator from '../../../store'
import TreeView from '../modules/treeview'

class ReportContent extends Component {
    constructor() {
        super()

        this.state = {
            filter: ''
        }

        this.toggleSelectedGroup = this.toggleSelectedGroup.bind(this)
        this.onSurveyClick = this.onSurveyClick.bind(this)
    }

    async toggleSelectedGroup(group) {
        const { sentSurveyIds, setSentSurveyIds } = this.props

        let selections = [...sentSurveyIds]

        if (group.surveys) {
            if (selections.length === 0) {
                selections = group.surveys.map(survey => {
                    return survey.id
                })
            } else {
                // check if any of the surveys in the selected group are not selected before
                let hasSurveysNotSelected = false

                group.surveys.forEach(survey => {
                    // if a survey of the group has not been previously selected, let's add it to the selecteds
                    if (selections.indexOf(survey.id) === -1) {
                        hasSurveysNotSelected = true
                        selections.push(survey.id)
                    }
                })

                if (!hasSurveysNotSelected) {
                    // all surveys of group have been previously selected, so we want to remove all their selections
                    group.surveys.map(survey => selections.splice(selections.indexOf(survey.id), 1))
                }
            }
        }

        await setSentSurveyIds(selections)
        this.getReportWithChosenFilters()
        
        if(this.props.navigateTo) {
            this.props.navigateTo('/raportit/yritys/raportti')
        }
    }

    deleteSurveyGroup = (groupToDelete) => {
        let surveyTree = this.props.selectedBusiness.surveyTree
        let groupSurveys
        const index = surveyTree.indexOf(groupToDelete)

        if (surveyTree[index].surveys.length > 0) {
            groupSurveys = surveyTree[index].surveys
            let kohdistamattomatGroup = surveyTree.filter(branch => branch.title === 'Kohdistamattomat')

            if (kohdistamattomatGroup.length > 0) {
                const kohdistamattomatIndex = surveyTree.indexOf(kohdistamattomatGroup[0])

                if (typeof kohdistamattomatGroup[0].surveys !== 'undefined') {
                    kohdistamattomatGroup[0].surveys = [
                        ...kohdistamattomatGroup[0].surveys,
                        ...groupSurveys
                    ]
                } else {
                    kohdistamattomatGroup[0].surveys = groupSurveys
                }
                surveyTree[kohdistamattomatIndex] = kohdistamattomatGroup[0]
            } else {
                surveyTree.push({ title: 'Kohdistamattomat', surveys: groupSurveys })
            }
        }
        surveyTree.splice(index, 1)

        this.props.saveSurveyTree(this.props.selectedBusiness._id, surveyTree)

        // if(groupSurveys && groupSurveys.length > 0) {
        //     NotificationManager.success('Ryhmä poistettu. Ryhmän sisältämät kartoitukset on siirretty kohdistamattomiin.', 'Ilmoitus', 4000)
        // }
    }

    async onSurveyClick(obj) {
        const { sentSurveyIds, setSentSurveyIds } = this.props

        if (sentSurveyIds.indexOf(obj.id) === -1) {
            sentSurveyIds.push(obj.id)
            await setSentSurveyIds(sentSurveyIds)

        } else {
            const surveyIndex = sentSurveyIds.indexOf(obj.id)
            sentSurveyIds.splice(surveyIndex, 1)
            await setSentSurveyIds(sentSurveyIds)
        }

        this.getReportWithChosenFilters()

        if(this.props.navigateTo) {
            this.props.navigateTo('/raportit/yritys/raportti')
        }
    }

    getReportWithChosenFilters() {
        const { sentSurveyIds, activeFilters, selectedVariables, getReport } = this.props

        let params = {
            reports: []
        }

        // ADD REPORT ID:S TO PARAMS
        const newSentSurveyIds = []

        sentSurveyIds.map(surveyId => {
            if (newSentSurveyIds.indexOf(surveyId) === -1) {
                return newSentSurveyIds.push(surveyId)
            }
            return false
        })

        newSentSurveyIds.map(id => params.reports.push({ reportId: id }))

        // ADD DATERANGE TO PARAMS
        const dateRangeFilter = activeFilters.find(filter => filter.type === 'dateRange') || null

        if (dateRangeFilter) {
            const dateRange = {
                startDate: dateRangeFilter.startDate,
                endDate: dateRangeFilter.endDate
            }

            params.dateRange = dateRange
        }

        // ADD ANSWERSESSION ID:S TO PARAMS
        const answerSessionFilter = activeFilters.find(filter => filter.type === 'answerSession') || null

        if (answerSessionFilter) {
            const answerSessionIds = answerSessionFilter.answerSessionIds

            params.answerSessionIds = answerSessionIds
        }

        // ADD SURVEYVARIABLES TO PARAMS
        if (Object.keys(selectedVariables).length > 0) {
            params.surveyVariables = selectedVariables
        }

        getReport(params)
    }

    render() {
        const { business, sentSurveyIds, surveyTree } = this.props

        return (
            <Row>
                <Col>
                    <Row style={{ paddingLeft: '0.8em' }} className="mainContentRow">
                        <Col>
                            <h3>Raportit</h3>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: '1em'}}>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginLeft: '0.8em' }}>
                                <form id="search-bar">
                                    <input type="search" className="search-bar-input" style={{backgroundColor: '#FAFAFA'}} placeholder="Etsi raporttia" value={this.state.filter} onChange={(event) => this.setState({ filter: event.target.value })} />
                                </form>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mainContentRow">
                        {business && (surveyTree && surveyTree.length > 0) && <TreeView onTreeHeadClick={this.toggleSelectedGroup} onTreeSurveyClick={this.onSurveyClick} selectedSurveyIds={sentSurveyIds} filter={this.state.filter} />}
                    </Row>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        business: state.reports.selectedBusiness,
        sentSurveys: state.reports.sentSurveys,
        sentSurveyIds: state.reports.sentSurveyIds,
        activeFilters: state.reports.activeFilters,
        selectedVariables: state.reports.selectedVariables,
        surveyTree: state.reports.surveyTree
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(ActionCreator.getReportForDashboard(params)),
        setSentSurveyIds: (params) => dispatch(ActionCreator.setSentSurveyIds(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportContent)
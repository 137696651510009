import React, { useState, Fragment, useEffect } from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col} from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications';
import BoxSearchBar from '../../../Utilities/BoxSearchBar/BoxSearchBar';

export const AttachmentsModal = ({isOpen, toggleModal, answerSessionIds, attachments, projectId}) => {
    const [selected, setSelected] = useState()
    const [selectedFiles, setSelectedFiles] = useState([])
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState([])
    const [showInfo, setShowInfo] = useState(false) 
    const [ansIds, setAnsIds] = useState([])
    const [comparable, setComparable] = useState(false)
    const [selectedFile, setSelectedFile] = useState({})
    const [fileOptionsModal, setFileOptionsModal] = useState(false)
    const [fileEnd, setFileEnd] = useState()
    const [search, setSearch] = useState("")

    const closeModal = () => {
        setSelected(answerSessionIds)
        toggleModal()
        setSelectedFiles([])
    }

    useEffect(()=>{
        if(attachments && attachments.questions && attachments.answers){
            setQuestions([...attachments.questions])
            setAnswers([...attachments.answers])
        }
    },[attachments])

    const onOptionButtonClick = (answer) => {
        if(answer && answer.answer){ 
            
            setSelectedFile(answer.answer)
            let array = answer.answer.fileName.split('.')
            let end = array[(array.length -1 )]
            setFileEnd(end) 
            setFileOptionsModal(true)
        }
        if(answer && answer.files){
            
        }
    }

    const downloadFile = async(file, preview=false) => {
        let payload = {
            fileId: file.fileId,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const response = await executeRequest('plan/downloadFile', payload)
        if(response.data){
            try{
                var blob = new Blob([new Uint8Array(response.data.data)], { type: response.mimeType });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.name + "." + response.fileEnd;
        
                document.body.appendChild(link);
                if(preview){
                    window.open(link)
                }else{
                    link.click();
                }
        
                document.body.removeChild(link);

            } catch {}
        }       
    }
   
    const handleSelection = (answer) => {
        let ans = answer
           
  
        let selection = selectedFiles
        if(selectedFiles.includes(ans)){
            selection.splice(selectedFiles.indexOf(ans),1)
            setSelectedFiles([...selection])
        } else {
            setSelectedFiles([...selection, ans])
        }
    }

    const handleSelectionV2 = (data) => {
        let ans = data
           
        let selection = selectedFiles
        if(selectedFiles.filter(object => object.answer.fileId === data.answer.fileId).length > 0){
            // selection.splice(selectedFiles.indexOf(selectedFiles.filter(object => object.answer.fileId === data.answer.fileId))-1,1)
            selection.map((object, key)=>{
                if(object.answer.fileId === data.answer.fileId){
                   selection.splice(key,1)

                }
            })
            setSelectedFiles([...selection])
        } else {
            setSelectedFiles([...selection, ans])
        }
    }

    const addToProject = async() => {
        let payload = {
            projectId: projectId,
            files: selectedFiles,
            connectionType: "projects"
        }
        const response = await executeRequest('project/addFilesToProject', payload)
        if(response.success){
            NotificationManager.success('Liitteiden lisääminen onnistui!', 'Ilmoitus', 4000)
            closeModal()
        } else {
            NotificationManager.error('Liitte on jo projektissa!', 'Virhe', 8000)
        }
    }

    const handleAdding = () => {
        if(selectedFiles.length > 0){
            addToProject()
        } else {
            NotificationManager.error('Yhtään tiedostoa ei ole valittu!', 'Virhe', 4000)
        }
    }

    const toggleInfo = () => {
        setShowInfo(!showInfo)
    }

    // in case compare will be wanted in this modal..
    // const checkComparable = (file) => {
    //     let checkExcel = new RegExp(/([a-zA-Z0-9ÄäÖöÅå\s_\\.\-\(\):])+(.xls|.xlsx)$/)
    //     if(checkExcel.exec(file) !== null){
    //         setComparable(true)
    //     } else {
    //         setComparable(false)
    //     } 
    // }

    // useEffect(()=>{
    //     if(selectedFiles && selectedFiles.length > 0){
    //         for(var file of selectedFiles){
    //             if(file.answer && file.answer.fileName){
    //                 checkComparable(file.answer.fileNme)
    //             }
    //         }
    //     }
    // },[selectedFiles])

    const handleSearch = (answer) => {
        
        if(answer.files && answer.files.filter(file => file.name.toLowerCase().includes(search.toLowerCase())).length > 0)return true
        if(answer.answer && answer.answer.fileName && answer.answer.fileName.toLowerCase().includes(search.toLowerCase())) return true
        if(answer.business && answer.business.label.toLowerCase().includes(search.toLowerCase())) return true

        return false
    }

    const handleFileSearch = (file, answer) => {
        if(file.name.toLowerCase().includes(search.toLowerCase()) || answer.business.label.toLowerCase().includes(search.toLowerCase())) return true
        else return false
    }

    return(
        <>
            <Modal isOpen={fileOptionsModal} toggle={()=> setFileOptionsModal(false)}>
                <ModalHeader toggle={()=> setFileOptionsModal(false)}> <p className="modal-name-overflow-handler" style={{ color: '#9a0707' }}>{selectedFile.fileName}</p></ModalHeader>
                <ModalBody>
                    {fileEnd && (fileEnd.toLowerCase() === "jpg" || fileEnd.toLowerCase() === "jpeg" || fileEnd.toLowerCase() === "png" || fileEnd.toLowerCase() === "svg" || fileEnd.toLowerCase() === "bmp" || fileEnd.toLowerCase() === "pdf") &&
                        <button onClick={() => downloadFile(selectedFile, true)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>Esikatsele</button>
                    }   
                    <button onClick={() => downloadFile(selectedFile)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>Lataa</button>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setFileOptionsModal(false)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                </ModalFooter>
            </Modal>
            <Modal isOpen={isOpen} toggle={() => closeModal()} className="freetext-answers-modal">
                <ModalHeader toggle={() => closeModal() }>
                    {"Hallitse kartoituksen liitteitä"}
                </ModalHeader>
                <ModalBody>
                    <Col style={{ paddingBottom: '1em' }}>
                        <i className="fas fa-info attachment-info" onClick={() => toggleInfo()}></i>
                        {showInfo &&
                            <p style={{padding: '1rem'}}>
                                Voit ladata tiedoston painamalla "Lataa"-painiketta. Voit siirtää haluamasi tiedostot projektin liitteisiin painamalla "Valitse"-painiketta ja painamalla "Lisää projektiin" -painiketta. 
                                Mikäli haluat vertailla exceltiedostoja, lisää ensin halutut tiedostot projektin liitteisiin, jonka jälkeen ne löytyvät myös vertailuiden exceleistä.
                            </p>
                        } 
                    </Col>           
                    <Scrollbars
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                        style={{ width: "100%", height: '500px', paddingBottom: '1rem' }}
                        className="custom-scrollbars" >
                        <Col>
                            <Col xs="12" className="attachment-modal-search">
                                <Row>
                                    <p style={{ fontWeight: 'bold' }}>Hae yrityksen nimellä</p><BoxSearchBar placeholder='Haku' value={ search } onChange={(e) => setSearch(e)} />
                                </Row>
                            </Col>
                            {questions && questions.map((question, key) => {
                                return(
                                    <Col key={key} style={{ marginBottom: '1em' }}> 
                                        <Row className="attachment-group-header">
                                            <h5>{question.questionText}</h5>
                                        </Row>
                                            {answers && answers.filter(answer =>  ((answer.answer || (answer.files && answer.files.length > 0)) && handleSearch(answer))).map((answer, index) => {
                                                let className = index % 2 === 0 ? 'even-row' : 'odd-row'
                                                if(answer.questionId === question.id && (answer.answer || answer.files)){
                                                    if(answer.files ){
                                                        return(
                                                            <div key={index} className={className} style={{padding: '1rem'}}>
                                                                <Row>
                                                                    <Col xs="12">
                                                                        <p className="content-text name-overflow-handler-long" style={{ fontWeight: 'bold'}} title={answer.business.label}>{answer.business.label}</p>
                                                                    </Col> 
                                                                    <Col>
                                                                        {answer.files.length > 0 && answer.files.filter(file => handleFileSearch(file, answer)).map((file,key)=>{
                                                                           
                                                                            let selectionObject = {
                                                                                answer:{fileName:file.name, fileId:file.fileId},
                                                                                questionId: answer.questionId,
                                                                                business:answer.business
                                                                            } 
                                                                            return(
                                                                                <Row key={key} style={{ marginTop: '0.5em' }}>
                                                                                    <Col xs="8">
                                                                                    <p className="content-text name-overflow-handler-long" title={file.name}>{file.name}</p>
                                                                                    </Col>
                                                                                    <Col xs="4">
                                                                                        <button onClick={(e) => (e.stopPropagation(),onOptionButtonClick(selectionObject))} className="small-white-button" style={{padding: '0.25rem 1rem'}}>Liitteen valinnat</button>
                                                                                        <button onClick={() => handleSelectionV2(selectionObject)} className={"small-white-button " + (selectedFiles.filter(object => object.answer.fileId === file.fileId).length > 0? "selected-to-files":"")} style={{padding: '0.25rem 1rem', float:'right '}}>Valitse</button>
                                                                                    </Col>
                                                                                </Row>
                                                                            )
                                                                        })}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    } else{
                                                        return(
                                                            <div key={index} className={className} style={{padding: '1rem'}}>
                                                                <Row>
                                                                    <Col xs="12">
                                                                        <Row>
                                                                            <Col xs="8">
                                                                                <p className="content-text name-overflow-handler-long" style={{ fontWeight: 'bold'}} title={answer.business.label}>{answer.business.label}</p>
                                                                            </Col>
                                                                       </Row>
                                                                        <Row>
                                                                            <Col xs="8">
                                                                                <p className="content-text name-overflow-handler-long" title={answer.answer.fileName}>{answer.answer.fileName}</p>
                                                                            </Col>
                                                                            <Col xs="4">
                                                                                <button onClick={() => onOptionButtonClick(answer)} className="small-white-button" style={{padding: '0.25rem 1rem'}}>Liitteen valinnat</button>
                                                                                <button onClick={() => handleSelection(answer, answer.business)} className={"small-white-button " + (selectedFiles.includes(answer)? "selected-to-files":"")} style={{padding: '0.25rem 1rem', float:'right '}}>Valitse</button>
                                                                            </Col> 
                                                                        </Row>
                                                                    </Col> 
                                                                </Row>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            })} 
                                    </Col>
                                )
                            })}
                        </Col>
                    </Scrollbars>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => closeModal()}>Peruuta</a>
                    {comparable && <button className="small-white-button  wide-button" style={{ }} onClick={() => handleAdding()}>Lisää vertailtaviin exceleihin</button>}
                    <button className="small-white-button  wide-button" style={{ }} onClick={() => handleAdding()}>Lisää projektin liitteisiin</button>
                </ModalFooter>
            </Modal>
        </>
    )
}
import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Container, Label, FormGroup, Nav, NavItem, NavLink } from 'reactstrap';
import Select from 'react-select';
import './plan.css';
import { confs } from '../../modules/config';
import { useDispatch, useSelector } from 'react-redux'
import * as ActionCreator from './Actions/ActionCreator';
import { addMoreBreadcrumbs, setBreadcrumbs, setVariables } from '../../store'
import AsyncCreatableSelect from 'react-select/async-creatable';
import { executeRequest } from '../../Utilities/requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications';  
import { Dropzone } from '../../Utilities/Dropzone/Dropzone';

const baseURL = confs.url

/*
    This is the starting component for plan building, it contains a Select Component for selecting target client
    Plan can be named aswell with the input field
*/

const startNewPlan = (dispatch, selectedBusiness, planName, type, selectedSurvey, settings, history, tags=[], projectId = null, selectedVariables = null) => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token"),
        selectedBusiness: selectedBusiness,
        planName: planName,
        tags: tags,
        projectId: projectId
    };

    if (type === 'automation') {
        payload['automation'] = true
        payload['automationSurvey'] = selectedSurvey._id
        payload.automationSurveyName = selectedSurvey.name
        payload['selectedBusiness'] = null
        payload['settings'] = settings
    }

    if(type === 'template'){
        payload['template'] = true
        payload['selectedBusiness'] = null
        payload['selectedVariables'] = selectedVariables
    }
    dispatch(ActionCreator.createNewPlan(payload));
}

const TagList = props => {
    
    async function promiseOptions(inputValue) {
        let params = {
            data: inputValue,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const data = fetch(confs.url + 'tagSearch', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => response.json())

        let tags = await data;

        return tags
    };

    function NoOptionsMessage(props) {
        if (props.options.length) return null;
        return <div {...props.innerProps} style={{padding: 5}}>Voit hakea tai lisätä tageja kirjoittamalla</div>;
      }

    const components = {
        NoOptionsMessage
    }

    function formatCreateLabelCreator(value) {
        return 'Lisää tagi: "' + value + '"'
      }

    return (
        // <Input placeholder="Uusi tagi" onChange={props.searchTags} ></Input>    
            <AsyncCreatableSelect 
                loadOptions={promiseOptions} 
                components={components} 
                isMulti 
                value={props.tagOptions} 
                onChange={selectedTags => props.setTagOptions(selectedTags)}
                placeholder=""
                className='react-select-container'
                classNamePrefix="react-select"
                formatCreateLabel={formatCreateLabelCreator} 
                />
    )
}

const NewPlanTarget = ({ history, copy, selectedData, onCopy, projectId, projectName, planType, updatePlans = false}) => {
    const created = useSelector(state => state.plan.startBuilding);
    const businessName = useSelector(state => state.authentication.businessName)
    const modulesInUse = useSelector(state => state.authentication.modulesInUse)
    const trial = useSelector(state => state.authentication.trial)
    const allowTools = useSelector(state => state.authentication.allowTools)
    const EBAdmin = useSelector(state => state.authentication.EBAdmin)
    const enterpriseList = useSelector(state => state.authentication.enterpriseList)
    const [selectedBusiness, setSelectedBusiness] = useState(selectedData !== undefined ? selectedData.selectedBusiness : {})
    const [clientList, setClientList] = useState([]);
    const [planName, setPlanName] = useState(selectedData !== undefined ? selectedData.planName : "");
    const [surveyList, setSurveyList] = useState([])
    const [selectedSurvey, setSurvey] = useState({})
    const [type, setType] = useState(planType ? planType : 'plan')
    const [automaticCreate, setAutomaticCreate] = useState(false);
    const [showReportOnAnswer, setShowReportOnAnswer] = useState(false);
    const [sendReportOnAnswer, setSendReportOnAnswer] = useState(false);
    const [raportText, setRaportText] = useState('');
    const [tagOptions, setTagOptions] = useState([])
    const [selectedVariables, setSelectedVariables] = useState(null)
    const [clientSelected, setClientSelected] = useState(false)
    const [targetSelected, setTargetSelected] = useState(false)
    const [ownInfoSelected, setOwnInfoSelected] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [templateList, setTemplateList] = useState([])
    const [selectedFile, setSelectedFile] = useState(null)
    const [isConverting, setIsConverting] = useState(false)
    const [planItems, setPlanItems] = useState([])
    const [accepted, setAccepted] = useState(false)
    const [tags, setTags] = useState([])
    const [converted, setConverted] = useState(false)
    const [createAsTemplate, setCreateAsTemplate] = useState(false)
    const [copyToEnterprise, setCopyToEnterprise] = useState(false)
    const [copyAsTemplate, setCopyAsTemplate] = useState(false)
    const [selectedEnterprise, setSelectedEnterprise] = useState(false)



    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBreadcrumbs({ to: 'tiedot', label: `${businessName}` }))

        dispatch(addMoreBreadcrumbs([{ to: 'lomakkeet', label: 'Lomakkeet' }, { to: 'lomakkeet/uusi', label: 'Uusi' }]))

        if (modulesInUse.clientManagement) {
            //Fetch client list
            let payload = {
                jwtToken: window.sessionStorage.getItem("token")
            }

            fetch(confs.url + 'sender/get/getCompanies', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then(resp => resp.json())
                .then(response => {
                    setClientList(response.businessList)
                    if(history.location!== undefined && history.location.state !== undefined){
                        for(var i in response.businessList){
                            if(response.businessList[i].value === history.location.state.businessId.toString()){
                                setSelectedBusiness({
                                    value: response.businessList[i].value,
                                    label:response.businessList[i].label
                                })
                            }
                        }
                    }
                }).catch(error => {

                })
        } else {
            const businessList = [
                { value: 'anon', label: 'Ei kohdeyritystä' }
            ]
            setClientList(businessList)
        }

        getAllTemplates()

    }, [])

    useEffect(() => {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token")
        }
        fetch(confs.url + 'sender/get/allSentSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(resp => resp.json())
            .then(response => {
                // filter out surveys without a code 
                const list = response.surveys.filter(w => w.code !== undefined);
                setSurveyList(list)
            }).catch(error => {

            })

    }, [])

    useEffect(() => {
        if (copy === undefined && created){
            if(projectId){
                history.push({
                    pathname:'/lomakkeet/luonti',
                    state:{
                        crumbs:{
                            to: 'projektit/hallitse', label: projectName
                        }, 
                        projectId:projectId
                    }
                })
            } else 
            history.push('/lomakkeet/luonti')
        }  
    }, [created])

    useEffect(() => {
        if (showReportOnAnswer || sendReportOnAnswer) {
            setAutomaticCreate(true);
        }
    }, [sendReportOnAnswer, showReportOnAnswer]);

    useEffect(() => {
        if (!automaticCreate) {
            setShowReportOnAnswer(false);
            setSendReportOnAnswer(false);
        }
    }, [automaticCreate]);


    const customStyles = {
        control: (base, state) => ({
            ...base,
            height: '58px',
            minHeight: '58px',
        }),
    }

    const handleVariableChange = (variable, bool) => {
        setSelectedVariables(prevSelected =>({
            ...prevSelected,
            [variable]:bool
        }))
        
    }

    const getAllTemplates = async() => {
        const response = await executeRequest('plan/getTemplatesForSelect', {})
        if(!!response){
            setTemplateList(response)
        }
    }
    const startEditing = (formId) => {
        dispatch(ActionCreator.startEditingPlan(formId))
        // this.props.history.push('/lomakkeet/luonti')
    }

    const handleCopySave = async() => {
        if(planName.length>0){
            let payload = {
                planId: selectedTemplate.value,
                planName: planName,
                // projectId: selectedProject? selectedProject.value : projectId,
                selectedBusiness: selectedBusiness? selectedBusiness : clientList[0],
                tags: tagOptions.length > 0 ? tagOptions : null
            }
            const response = await executeRequest('plan/create/copyFromTemplate', payload)
            if(!!response && !response.trialEnded && !response.reason){
                NotificationManager.success('Kopion luominen onnistui', 'Ilmoitus', 4000)
                startEditing(response.created._id)
            } else {
                if(response.trialEnded){
                    NotificationManager.error("Kokeilujakso on päättynyt","",4000)
                } else {
                    if(response.reason){
                        if(response.reason === "limit"){
                            NotificationManager.error("Lomakkeiden enimmäismäärä on täyttynyt","",4000)
                        }
                        if(response.reason === "module"){
                            NotificationManager.error("Ominaisuus (lisenssi) ei ole käytössä","",4000)
                        }
                    } else{
                        NotificationManager.error('Kopion luominen epäonnistui', 'Ilmoitus', 4000)
                    }
                }
            }
        } else {
            NotificationManager.error('Anna kopiolle nimi!','Virhe', 3000)
        }
    }    

    
    const handleConversion = async() => {
        let data = new FormData()
        let blob = selectedFile.slice(0, selectedFile.size, selectedFile.type); 

        let newFile = new File([blob],handleUmlauts(selectedFile.name), {type:selectedFile.type})
        //let business = targetBusiness? JSON.stringify({...targetBusiness}) : null
        //data.set("business", business)
        data.set("jwtToken", window.sessionStorage.getItem("token"))
        data.set("file", newFile, newFile.name)
        data.set("type", "conversion")
        let tempName = selectedFile.name.split(".")
        data.set("fileEnd", tempName[tempName.length-1])
        tempName.pop()
        let finalName = tempName.join(".")
        data.set("name",finalName)
        setPlanName(finalName)
        upload(data)
    }

    const handleUmlauts = (name) => {
        
        if(name){
            let newName = name
            newName = newName.replace(/ä/g, 'a');
            newName = newName.replace(/ö/g, 'o');
            newName = newName.replace(/å/g, 'o');
            newName = newName.replace(/Ä/g, 'A');
            newName = newName.replace(/Ö/g, 'O');
            newName = newName.replace(/Å/g, 'O');

            
            return newName
        }
    }

    const upload = async(props) => {
        setIsConverting(true)
        try {
            const responseJson = await fetch(baseURL + 'plan/convertWordToPlan', {
                method: 'POST',
                body: props
            })
            if(responseJson) {
                const response = await responseJson.json()
                if(response && response.success){
                    if(response.document){
                        setPlanItems(response.document)
                        NotificationManager.success("Dokumentin muuntaminen lomakkeeksi onnistuu", "", 4000)
                        setAccepted(true)
                    } else{
                        NotificationManager.error("Virhe muuntaessa dokumenttia lomakkeeksi", "Virhe", 4000)
                        setAccepted(false)
                    }
                    setIsConverting(false)
                } else {
                    if(response.trialEnded){
                        NotificationManager.error("Kokeilujakso on päättynyt","",4000)
                    } else {
                        if(response.reason){
                            if(response.reason === "limit"){
                                NotificationManager.error("Lomakkeiden enimmäismäärä on täyttynyt","",4000)
                            }
                            if(response.reason === "module"){
                                NotificationManager.error("Ominaisuus (lisenssi) ei ole käytössä","",4000)
                            }
                        }
                    }
                    setAccepted(false)
                    setIsConverting(false)
                }
               
                return response;
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }

    const createPlan = async() => {
        let payload = {
            planName: planName,
            planItems: planItems,
            tags: tagOptions,
            selectedBusiness: selectedBusiness,
            createAsTemplate: createAsTemplate
        }
        const response = await executeRequest("plan/createFromWordDoc", payload)
        if(response.success && response.created){
            startEditing(response.created)
        } else {
            if(response.trialEnded){
                NotificationManager.error("Kokeilujakso on päättynyt","",4000)
            } else {
                if(response.reason){
                    if(response.reason === "limit"){
                        NotificationManager.error("Lomakkeiden enimmäismäärä on täyttynyt","",4000)
                    }
                    if(response.reason === "module"){
                        NotificationManager.error("Ominaisuus (lisenssi) ei ole käytössä","",4000)
                    }
                }
            }
        }
    }

    const toggleCheckBox = () => {
        setCreateAsTemplate(!createAsTemplate)
    }

    const handleCheck = () => {
        setCopyAsTemplate(!copyAsTemplate)
    }
    const handleEnterpriseCheck = () => {
        setCopyToEnterprise(!copyToEnterprise)
    }
    return (
        <Container fluid>
            <div className={projectId ? "project-plan-cont" : (copy !== undefined ? "copy-plan-whitebox" : "whitebox")} style={{ marginTop: '1em' }}>
                <div className="newPlanTarget-div">
                    <Row style={projectId? {} :{ width: '60%' }}>
                        <Col xl="12" style={{ margingBottom: '1em' }}>
                            {!copy &&      
                                <>
                                    {planType? 
                                        (<>
                                            <Nav pills className="project-nav-tabs plan-tabs">
                                                <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                    <NavLink 
                                                        className={type === 'plan'  ? 'active' : ''}
                                                        onClick={() => setType('plan')}
                                                    >
                                                        Lomake
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                    <NavLink 
                                                        className={type === 'automation'   ? 'active' : ''}
                                                        onClick={() => setType('automation')}
                                                    >
                                                        Generoitu
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                    <NavLink 
                                                        className={type === 'templateCopy'   ? 'active' : ''}
                                                        onClick={() => setType('templateCopy')}
                                                    >
                                                        Uusi lomake pohjasta
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </>)
                                        :
                                        (<>
                                            <Nav pills className="project-nav-tabs plan-tabs">
                                                <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                    <NavLink 
                                                        className={type === 'plan'  ? 'active' : ''}
                                                        onClick={() => setType('plan')}
                                                    >
                                                        Lomake
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                    <NavLink 
                                                        className={type === 'automation'   ? 'active' : ''}
                                                        onClick={() => setType('automation')}
                                                    >
                                                        Automaatio
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                    <NavLink 
                                                        className={type === 'template'   ? 'active' : ''}
                                                        onClick={() => setType('template')}
                                                    >
                                                        Uusi pohja
                                                    </NavLink>
                                                </NavItem> 
                                                <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                    <NavLink 
                                                        className={type === 'templateCopy'   ? 'active' : ''}
                                                        onClick={() => setType('templateCopy')}
                                                    >
                                                        Uusi lomake pohjasta
                                                    </NavLink>
                                                </NavItem>
                                                {modulesInUse.wordConversion && 
                                                    <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                        <NavLink 
                                                            className={type === 'createFromDocument'   ? 'active' : ''}
                                                            onClick={() => setType('createFromDocument')}
                                                        >
                                                            Uusi lomake dokumentista
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                            </Nav>
                                        </>)
                                    }
                                </>
                            }
                        </Col>
                        {type === "createFromDocument" &&
                            <>
                                {accepted? 
                                    <Col xl="12">
                                        <label>
                                            <h4 style={{ fontWeight: 700 }}>{"Valitse asiakas, jolle lomake luodaan"}</h4>
                                        </label>
                                        <Select
                                            value={selectedBusiness}
                                            onChange={setSelectedBusiness}
                                            options={clientList}
                                            placeholder="Etsi yritys"
                                            isDisabled={false}
                                            noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                            styles={customStyles}
                                            className="companyTargets"
                                            classNamePrefix="select"
        
                                        />
                                    </Col>
                                    :
                                    (isConverting ?
                                        <MegaLoaderSpinner />
                                        :
                                        <Col xs="12" style={{ paddingTop: '1em'}}>
                                            <label>
                                                <h4 style={{ fontWeight: 700 }}>{"Valitse tiedosto"}</h4>
                                            </label>
                                            <p style={{ paddingBottom: '0.5em' }}>
                                                Varmista että ladattavassa tiedostossa on käytetty vähintään alkukappaleessa Heading1- tai Heading2 -tyyliä. Tiedoston on oltava .docx -tyyppinen.
                                            </p>
                                            <Dropzone 
                                                setFile={setSelectedFile}
                                                acceptTypes={".docx"}
                                            />
                                        </Col>
                                    )
                                }
                            </>
                        }
                        {type === 'plan' &&
                            <Col xl="12">
                               {copyToEnterprise?
                                    <>
                                        <label>
                                            <h4 style={{ fontWeight: 700 }}>{"Valitse enterprise, jolle kopio luodaan"}</h4>
                                        </label>
                                        <Select
                                            value={selectedEnterprise}
                                            onChange={setSelectedEnterprise}
                                            options={enterpriseList}
                                            placeholder="Valitse enterprise"
                                            isDisabled={false}
                                            noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                            styles={customStyles}
                                            className="companyTargets"
                                            classNamePrefix="select"
                                        />
                                    </>
                                    :
                                    <>   
                                        <label>
                                            <h4 style={{ fontWeight: 700 }}>{copy !== undefined ? "Valitse asiakas, jolle kopio luodaan" : "Valitse asiakas, jolle lomake luodaan"}</h4>
                                        </label>
                                        <Select
                                            value={selectedBusiness}
                                            onChange={setSelectedBusiness}
                                            options={clientList}
                                            placeholder="Etsi yritys"
                                            isDisabled={false}
                                            noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                            styles={customStyles}
                                            className="companyTargets"
                                            classNamePrefix="select"

                                        />
                                    </> 
                                }
                            </Col>
                        }
                        {type === 'automation' &&
                            <Col xl="12">
                                <label>
                                    <h4 style={{ fontWeight: 700 }}>Valitse lähetetty kartoitus, jolle automaattinen lomake luodaan</h4>
                                </label>
                                <Select
                                    value={selectedSurvey}
                                    onChange={setSurvey}
                                    options={surveyList}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option._id}
                                    styles={customStyles}
                                    className="companyTargets"
                                    classNamePrefix="select"
                                />
                                <FormGroup check>
                                    <div style={{ marginTop: '1em' }}>
                                        <Label check style={{ cursor: 'pointer', marginBottom: '.5em' }}>
                                            <Input type="checkbox" checked={automaticCreate} onChange={() => setAutomaticCreate(!automaticCreate)} /> Generoi lomake automaattisesti vastauksesta
                                    </Label>
                                    </div>
                                    <div>
                                        <Label check style={{ cursor: 'pointer', marginBottom: '.5em' }}>
                                            <Input type="checkbox" checked={showReportOnAnswer} onChange={() => setShowReportOnAnswer(!showReportOnAnswer)} /> Näytä lomake vastaajalle
                                     </Label>
                                    </div>
                                    {showReportOnAnswer &&
                                        <div style={{ marginBottom: '.5em' }}>
                                            <Label className="lighter-text">Linkin teksti</Label>
                                            <Input onChange={(e) => setRaportText(e.target.value)} value={raportText} ></Input>
                                        </div>
                                    }
                                    {/* 
                                not in use yet.
                                <Label check style={{ cursor: 'pointer', marginBottom: '.5em' }}>
                                    <Input type="checkbox" checked={sendReportOnAnswer} onChange={() => setSendReportOnAnswer(!sendReportOnAnswer)} /> Lähetä lomake vastaajalle
                              </Label> */}
                                </FormGroup>
                            </Col>
                        }
                        { (type === "templateCopy") &&
                            <>
                                <Col xl="12">
                                    <label>
                                        <h4 style={{ fontWeight: 700 }}>Valitse asiakas, jolle lomake luodaan</h4>
                                    </label>
                                    <Select
                                        value={selectedBusiness}
                                        onChange={setSelectedBusiness}
                                        options={clientList}
                                        placeholder="Etsi yritys"
                                        isDisabled={false}
                                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                        styles={customStyles}
                                        className="companyTargets"
                                        classNamePrefix="select"
                                    />
                                </Col>
                                <Col xl="12">
                                    <label>
                                        <h4 style={{ fontWeight: 700 }}>Valitse lomakepohja, josta lomake luodaan</h4>
                                    </label>
                                    <Select
                                        value={selectedTemplate}
                                        onChange={setSelectedTemplate}
                                        options={templateList}
                                        styles={customStyles}
                                        placeholder="Valitse pohja"
                                        className="companyTargets"
                                        classNamePrefix="select"
                                    />
                                </Col>
                            </>
                        }
                        {(type !== "createFromDocument" || accepted) &&
                            <>
                                <Col xl="12">
                                    <label>
                                    <h4 style={{ fontWeight: 700 }}>{(type === 'template')? "Pohjan nimi" :  "Lomakkeen nimi"}</h4>
                                    </label>
                                    <Input type="text" className="plan-name-input" name="copy-plan-name" style={{ height: 58, minHeight: 58 }} value={planName} onChange={(e) => setPlanName(e.target.value)}></Input>
                                </Col>
                                <Col xl="12">
                                    <label><h4 style={{ fontWeight: 700 }}>Tagit</h4></label>
                                    <TagList tagOptions={tagOptions} setTagOptions={setTagOptions}/>
                                </Col>
                                {(type === "createFromDocument" && accepted) &&
                                    <Col xl="12" style={{ fontSize: '1.5rem', marginLeft: '1em', paddingBottom: '1em'}}>
                                        <Label check>
                                            <Input style={{ height: '17px', width: '17px' }} className="checkBox" type="checkbox" onChange={()=> toggleCheckBox()} checked={createAsTemplate} /> {' '}
                                            Luo pohjana
                                        </Label>
                                    </Col>
                                }
                            </>
                        }
                        {copy &&
                            <Col style={{ marginTop: '0.5em', marginBottom: '0.5em', marginLeft: '1em' }}>
                                <div className="check-item-row">
                                    <input type="checkbox" className="check-item-box" onChange={() =>  handleCheck()} checked={copyAsTemplate}/>
                                    <p style={{ fontWeight: 'bold', marginLeft: '0.5em' }}>Kopioi lomakepohjaksi</p>
                                </div>
                                {EBAdmin &&
                                    <div className="check-item-row"  style={{ marginTop:'0.25em'}}>
                                        <input type="checkbox" className="check-item-box" onChange={() =>  handleEnterpriseCheck()} checked={copyToEnterprise}/>
                                        <p style={{ fontWeight: 'bold', marginLeft: '0.5em' }}>Kopioi toiselle enterpriselle</p>
                                    </div>
                                }
                            </Col>
                        }
                        {(type === "createFromDocument")?
                            (   
                                <Col xl="12" style={{ paddingTop: 5 }}>

                                    {selectedFile &&  !accepted && <button className="small-white-button wide-button createPlan active-button" style={{ width: '100%' }} onClick={()=> handleConversion()}> <h4 style={{ fontWeight: 700, paddingTop: '0.5rem' }}>Jatka</h4> </button>}
                                    {accepted && !converted && <button className={"small-white-button wide-button createPlan " + ((selectedBusiness && Object.keys(selectedBusiness).length > 0 && planName !== "") ? "active-button": "")} disabled={planName.length === 0 || (selectedBusiness && Object.keys(selectedBusiness).length === 0) } style={{ width: '100%' }} onClick={()=> (createPlan(),setConverted(true))}> <h4 style={{ fontWeight: 700, paddingTop: '0.5rem' }}>Luo lomake </h4></button>}
                                    {accepted && converted &&
                                        <Col> 
                                            <p>Pieni hetki, lomaketta valmistellaan. Valmistelussa voi mennä hetki</p>
                                            <MegaLoaderSpinner/>
                                        </Col>
                                    }
                                </Col>
                            )
                            :
                            ((type === 'template') ?
                                <Col xl="12" style={{ paddingTop: 5 }}>
                                    <Button className="small-white-button wide-button createPlan" onClick={() => startNewPlan(dispatch, selectedBusiness, planName, type, selectedSurvey, { createOnAnswer: automaticCreate, showOnAnswer: showReportOnAnswer, sendOnAnswer: sendReportOnAnswer, linkText: raportText }, history, tagOptions, projectId, selectedVariables)} style={(planName !== "") ? { width: '100%', backgroundColor: '#B5E413', color: '#FFF' } : { width: '100%', opacity: 0.5, pointerEvents: 'none' }}>
                                        <h4 style={{ fontWeight: 700, paddingTop: '0.5rem' }}>Luo pohja</h4>
                                    </Button>
                                </Col>
                                :
                                (type === "templateCopy" ?
                                    <Button className="small-white-button wide-button createPlan" onClick={() => handleCopySave()} style={((Object.keys(selectedBusiness).length > 0 || Object.keys(selectedSurvey).length > 0) && planName !== "" && selectedTemplate !== null) ? { width: '100%', backgroundColor: '#B5E413', color: '#FFF' } : { width: '100%', opacity: 0.5, pointerEvents: 'none' }}>
                                        <h4 style={{ fontWeight: 700, paddingTop: '0.5rem' }}>Luo lomake</h4>
                                    </Button>
                                    :
                                    <Col xl="12" style={{ paddingTop: 5 }}>
                                        {copy !== undefined ?
                                            <button className={"small-white-button wide-button " + ((selectedBusiness && Object.keys(selectedBusiness).length > 0 && planName !== "") ? "active-button": "")} onClick={() => onCopy({ copyToEnterprise: copyToEnterprise, selectedEnterprise: selectedEnterprise, copyAsTemplate: copyAsTemplate, planName: planName, selectedBusiness: selectedBusiness, planId: selectedData._id, type, selectedSurvey, settings: { createOnAnswer: automaticCreate, showOnAnswer: showReportOnAnswer, sendOnAnswer: sendReportOnAnswer, linkText: raportText } })} disabled={(selectedBusiness && Object.keys(selectedBusiness).length > 0 && planName !== "") ? false: true} style={{ width: '100%' }} >
                                                <h4 style={{ fontWeight: 700, paddingTop: '0.5rem' }}>Kopioi lomake</h4>
                                            </button>
                                            :
                                            <button className="small-white-button wide-button createPlan" onClick={() => startNewPlan(dispatch, selectedBusiness, planName, type, selectedSurvey, { createOnAnswer: automaticCreate, showOnAnswer: showReportOnAnswer, sendOnAnswer: sendReportOnAnswer, linkText: raportText }, history, tagOptions, projectId, selectedVariables, selectedTemplate)} style={((Object.keys(selectedBusiness).length > 0 || Object.keys(selectedSurvey).length > 0) && planName !== "") ? { width: '100%', backgroundColor: '#B5E413', color: '#FFF' } : { width: '100%', opacity: 0.5, pointerEvents: 'none' }}>
                                                <h4 style={{ fontWeight: 700, paddingTop: '0.5rem' }}>Luo lomake</h4>
                                            </button>
                                        }
                                    </Col>
                                )
                            )
                        }
                       
                    </Row>
                </div>
            </div>
        </Container>
    )
}

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

export default NewPlanTarget;
import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';

const DeleteModal = props => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size="xs">
            <ModalHeader toggle={props.toggleNoteModal}>{props.title}</ModalHeader>
            <ModalBody>
               {props.text}
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => props.toggle()}>Peruuta</a>
                <Button onClick={() => props.confirm()} style={{backgroundColor: '#ed5652', borderColor: '#ed5652'}}>Poista</Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteModal;
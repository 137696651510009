import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Container, Label, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import MultiSelect from "@kenshooui/react-multi-select";
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { confs } from '../../../modules/config';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useDispatch, useSelector } from 'react-redux';
import { startEditingPlan } from '../Actions/ActionCreator';
import Select from 'react-select';


const customMessages = {
    messages: {
        searchPlaceholder: "Etsi lomaketta",
        noItemsMessage: "Valitse lomake",
        noneSelectedMessage: "Ei valittuja",
        selectedMessage: "Valittu",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
};


export const CombinePlans = ({history}) => {
    const [planList, setPlanList] = useState([])
    const [selectedPlans, setSelectedPlans] = useState([])
    const [visibleContent, setVisibleContent] = useState("selectPlans")
    const [name, setName] = useState('')
    const [tags,setTags] = useState([])
    const [businessList, setBusinessList] = useState([])
    const [selectedBusiness,setSelectedBusiness] = useState({})

    const dispatch = useDispatch()

    useEffect(()=>{
        getPlans()
        getBusinessList()
    },[])

    const getPlans = async() => {
        const response = await executeRequest('plan/getPlansForMerge',{})
        if(response){
            setPlanList(response)
        }
    }

    const getBusinessList = async()=> {
        const response = await executeRequest('sender/get/getCompanies',{})
        if(response && response.businessList){
            setBusinessList(response.businessList)
            setSelectedBusiness(response.businessList[0])
        }
    }

    const handleSelection = (selectedItems) => {
        setSelectedPlans(selectedItems)
    }

    const createMerge = async() => {
        let payload = {
            selectedPlans: selectedPlans,
            tags: tags,
            planName: name,
            selectedBusiness: selectedBusiness
        }

        const response = await executeRequest('plan/createMergedPlan',payload)
        if(response && response.createdId){
            dispatch( startEditingPlan(response.createdId) )

            history.push( '/lomakkeet/luonti' )
        }
    }

    return(
        <Col style={{ marginTop: '1.5em'}}>
            <h3 style={{marginBottom:'1em'}}>Yhdistä lomakkeita</h3>
           {visibleContent === 'selectPlans' &&
                <> 
                    <Label>Valitse lomakkeet</Label>
                    <MultiSelect 
                        messages={customMessages.messages}
                        items={planList}
                        selectedItems={selectedPlans}
                        onChange={handleSelection}
                        wrapperClassName="multi-selector"
                    />
                    <button onClick={() => setVisibleContent('givePlanData')} className={"small-white-button wide-button " + (selectedPlans && selectedPlans.length > 1? " active-button" : "")} disabled={(selectedPlans && selectedPlans.length > 1)? false : true} style={{ width: '100%', marginTop:'2em'}}>Jatka</button>
                </>
            }
            {visibleContent === 'givePlanData' &&
                <>
                    <Label>Anna uudelle lomakkeelle nimi</Label>
                    <Input value={name} placeholder={"Lomakkeen nimi"} onChange={(e) => setName(e.target.value)}/>
                    <Label style={{ marginTop: '1em' }}>Kenelle lomake luodaan</Label>
                    <Select 
                        value={selectedBusiness}
                        onChange={(selected) => setSelectedBusiness(selected)}
                        options={businessList}
                        placeholder="Valitse kohdeyritys"
                        isDisabled={false}
                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                        className="companyTargets"
                        classNamePrefix="select"
                    />
                    <Label style={{ marginTop: '1em' }}>Lisää lomakkeelle tageja</Label>
                    <TagHandler 
                        setTags={setTags}
                        tags={tags}
                    />
                    <div style={{ display: 'flex', justifyContent:'space-between' }}>
                        <button onClick={() => setVisibleContent('selectPlans')} className="small-white-button wide-button " style={{ width: '48%', marginTop:'2em'}}>Takaisin</button>
                        <button onClick={() => createMerge()} className={"small-white-button wide-button " + (name && name.length > 1? " active-button" : "")} disabled={(name && name.length > 1)? false : true} style={{ width: '48%', marginTop:'2em'}}>Yhdistä lomakkeet</button>
                    </div>
                </>
            }
        </Col>
    )
}

const TagHandler = props => {
    async function promiseOptions(inputValue) {
        let params = {
            data: inputValue,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const data = fetch(confs.url + 'tagSearch', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => response.json())

        let tags = await data;

        return tags
    };

    function NoOptionsMessage(props) {
        if (props.options.length) return null;
        return <div {...props.innerProps} style={{padding: 5}}>Voit hakea tai lisätä tageja kirjoittamalla</div>;
      }

    const components = {
        NoOptionsMessage
    }

    function formatCreateLabelCreator(value) {
        return 'Lisää tagi: "' + value + '"'
    }

    return (  
        <AsyncCreatableSelect 
            loadOptions={promiseOptions} 
            components={components} 
            isMulti 
            value={props.tags} 
            onChange={selectedTags => props.setTags(selectedTags)}
            placeholder=""
            className='react-select-container'
            classNamePrefix="react-select"
            formatCreateLabel={formatCreateLabelCreator} 
        />
    )
}
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

export default class Treenode extends React.Component {

  render() {
    const { selectedSurveys, id, surveyObj, index, surveyTreeIndex } = this.props

    const selected = selectedSurveys.indexOf(id) !== -1

    return (
      <Draggable index={index} draggableId={id} type={ `node-${surveyTreeIndex}` }>
        {(provided, snapshot) => {
          const style = {
            // color: snapshot.isDragging ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,1)',
            ...provided.draggableProps.style
          }
          return (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
              ref={provided.innerRef}
              data-id={id}
              onClick={() => this.props.onTreeSurveyClick(surveyObj)}
            >
              <div className={"tree-node-wrap" + (selected ? " tree-node-active" : "")} >
                <i className="fas fa-grip-vertical"></i>
                {' '}
                <span className="tree-node">{this.props.title}</span>
              </div>
              {/* {selected && <i className='material-icons survey-selected-mark'>check</i>} */}
            </div>
          )
        }}
      </Draggable>
    )
  }
}

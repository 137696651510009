import React from 'react'
import {
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button
} from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { debounce } from 'debounce'
import DebounceTextEditor from '../../../../../../../Utilities/TextEditor/DebounceTextEditor'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { connect } from 'react-redux'

import { confs } from '../../../../../../../modules/config'
import * as ActionCreator from '../../../../../../../store/index'

class FinalFeedback extends React.Component {
  constructor( props ) {
    super( props )

    this.state = {
      formulaTestNumber: '',
      formulaTestResult: '',
      newVariableVisible: false,
      newVariable: { name: '', description: '', formula: [] },
      formulaModalIsOpen: false,
      oldVariables: props.category.finalFeedbackVariables || [],
      formulaFreeNumber: '',
      variableBeingEdited: {},
      finalFeedbackIsChecked: props.category.askFinalFeedbackEmail || false,
      enterpriseEmails: [],
      feedbackSuggestions: [],
      confirmDeleteModal: false,
      variableBeingDeleted: null
    }
  }

  componentDidMount() {
    this.getAndFormatEnterpriseEmails()
    this.getFeedbackMentionSuggestions()
  }

  saveNewVariable = () => {
    const newVariable = this.state.newVariable
    newVariable.formula = newVariable.formula || []
    const oldVariables = this.props.category.finalFeedbackVariables || []
    const saveVariables = [ ...oldVariables, newVariable ]

    let payload = {
      categoryId: this.props.category._id,
      incomingData: {
        finalFeedbackVariables: saveVariables
      }
    }

    this.props.onCategoryModified( payload )
    this.setState( { newVariableVisible: false, newVariable: {}, oldVariables: saveVariables } )
  }

  cancelVariableAdd = () => {
    this.setState( { newVariableVisible: false, newVariable: {} } )
  }

  onNewVariableChange = ( variable ) => {
    this.setState( { newVariable: variable } )
  }

  onOldVariableChange = ( variable ) => {
    const { oldVariables } = this.state

    let edit = JSON.parse( JSON.stringify( oldVariables ) )
    const editedVariable = oldVariables.find( obj => obj.id === variable.id )

    if ( editedVariable ) {
      const index = oldVariables.indexOf( editedVariable )
      edit[ index ] = variable

      this.setState( { oldVariables: edit }, () => this.saveFinalFeedbackVariables() )
    }
  }

  saveFinalFeedbackVariables = debounce( () => {
    const { oldVariables } = this.state
    const { category, onCategoryModified } = this.props

    let payload = {
      categoryId: category._id,
      incomingData: {
        finalFeedbackVariables: oldVariables
      }
    }

    onCategoryModified( payload )
  }, 1000 )

  removeVariable = ( variable ) => {
    const { category, onCategoryModified } = this.props
    const { oldVariables } = this.state
    const fbVariables = JSON.parse( JSON.stringify( oldVariables ) )

    fbVariables.forEach( ( fbVar, index ) => {
      if ( fbVar.id ) {
        if ( fbVar.id === variable.id ) {
          fbVariables.splice( index, 1 )
        }
      } else {
        if ( fbVar.name === variable.name ) {
          fbVariables.splice( index, 1 )
        }
      }
    } )

    let payload = {
      categoryId: category._id,
      incomingData: {
        finalFeedbackVariables: fbVariables
      }
    }

    onCategoryModified( payload )
    this.setState( { oldVariables: fbVariables, confirmDeleteModal: false } )
  }

  toggleFormulaModal = () => {
    if ( this.state.formulaModalIsOpen ) {
      this.setState( { formulaFreeNumber: '', variableBeingEdited: {}, formulaTestNumber: '', formulaTestResult: '' } )
    }

    this.setState( prevState => ( {
      formulaModalIsOpen: !prevState.formulaModalIsOpen
    } ) )
  }

  setEditedVariable = ( variable = {} ) => {
    this.setState( { variableBeingEdited: variable } )
  }

  getSurveyQuestions = ( returnAllQuestions = false ) => {
    const { category } = this.props
    const questionList = []

    if ( typeof category.questionGroups !== 'undefined' ) {
      category.questionGroups.forEach( group => {
        let groupObj = {}
        groupObj.label = group.title
        groupObj.options = []

        if ( typeof group.questions !== 'undefined' ) {
          group.questions.forEach( question => {
            // won't be adding any freetext or date questions or comments to the list since they kinda have no scores to count in formula... u know
            if ( ( question.isFreetextQuestion === undefined || !question.isFreetextQuestion ) && ( question.isDateQuestion === undefined || !question.isDateQuestion ) && ( question.isComment === undefined || !question.isComment ) ) {
              let questionObj = {
                label: question.text,
                value: question.text,
                id: question.id,
                type: 'question'
              }
              groupObj.options.push( questionObj )
            } else if ( returnAllQuestions && ( question.isComment === undefined || !question.isComment ) ) {
              let questionObj = {
                label: question.text,
                value: question.text,
                id: question.id,
                type: 'question'
              }
              groupObj.options.push( questionObj )
            }
          } )
        }
        questionList.push( groupObj )
      } )
    }

    return questionList
  }

  addPieceToFormula = ( piece ) => {
    let variableBeingEdited = { ...this.state.variableBeingEdited }
    const formula = [ ...variableBeingEdited.formula ]

    if ( formula.length > 0 ) {
      const lastIndex = formula.length - 1
      let bracketOpenCount = 0
      let bracketClosedCount = 0

      formula.forEach( part => {
        if ( part.type === 'bracket-open' ) { bracketOpenCount++ }
        if ( part.type === 'bracket-close' ) { bracketClosedCount++ }
      } )

      if ( piece.type === 'number' || piece.type === 'question' ) {
        if ( formula[ lastIndex ].type === 'operator' || formula[ lastIndex ].type === 'bracket-open' ) {
          formula.push( piece )
        } else {
          NotificationManager.error( 'Numeroita ja kysymyksiä voi lisätä vain laskuoperaattorin tai sulun auki jälkeen.', 'Virhe', 3000 )
        }
      } else if ( piece.type === 'operator' ) {
        if ( formula[ lastIndex ].type === 'number' || formula[ lastIndex ].type === 'question' || formula[ lastIndex ].type === 'bracket-close' ) {
          formula.push( piece )
        } else {
          NotificationManager.error( 'Laskuoperaattoreita voi lisätä vain numeron tai kysymyksen jälkeen.', 'Virhe', 3000 )
        }
      } else if ( piece.type === 'bracket-close' ) {
        if ( ( formula[ lastIndex ].type === 'number' || formula[ lastIndex ].type === 'question' || formula[ lastIndex ].type === 'bracket-close' ) && bracketClosedCount < bracketOpenCount ) {
          formula.push( piece )
        } else {
          NotificationManager.error( 'Sulut voi sulkea vain numeron tai kysymyksen jälkeen. Tarkista myös sulkujen määrä.', 'Virhe', 3000 )
        }
      } else {
        // type is bracket-open
        if ( formula[ lastIndex ].type === 'operator' || formula[ lastIndex ].type === 'bracket-open' ) {
          formula.push( piece )
        } else {
          NotificationManager.error( 'Sulun voi avata vain laskuoperaattorin jälkeen.', 'Virhe', 3000 )
        }
      }

    } else {
      if ( piece.type !== 'operator' ) {
        formula.push( piece )
      } else {
        NotificationManager.error( 'Kaavan ensimmäinen pala ei voi olla laskuoperaattori.', 'Virhe', 3000 )
      }
    }

    variableBeingEdited.formula = formula
    this.setState( { variableBeingEdited: variableBeingEdited } )
  }

  removePieceFromFormula = ( index ) => {
    let variableBeingEdited = { ...this.state.variableBeingEdited }
    const formula = [ ...variableBeingEdited.formula ]

    formula.splice( index, 1 )

    variableBeingEdited.formula = formula
    this.setState( { variableBeingEdited: variableBeingEdited } )
  }

  saveFormula = () => {
    const { category, onCategoryModified } = this.props
    const { variableBeingEdited } = this.state
    const lastFormulaIndex = variableBeingEdited.formula.length - 1

    if ( lastFormulaIndex < 0 || variableBeingEdited.formula[ lastFormulaIndex ].type !== 'operator' ) {

      const fbVariables = [ ...category.finalFeedbackVariables ]

      fbVariables.forEach( ( fbVar, index ) => {
        if ( fbVar.id === variableBeingEdited.id ) {
          fbVariables.splice( index, 1, variableBeingEdited )
        }
      } )

      let payload = {
        categoryId: category._id,
        incomingData: {
          finalFeedbackVariables: fbVariables
        }
      }

      this.toggleFormulaModal()
      onCategoryModified( payload )

    } else {
      NotificationManager.error( 'Tallennus epäonnistui. Kaavan viimeinen pala ei voi olla operaattori.', 'Virhe', 3000 )
    }
  }

  getFormulaOperatorLabel = operator => {
    const style = {
      fontSize: '.8em'
    }

    const bracketStyle = {
      fontWeight: 'bold'
    }

    switch ( operator ) {
      case 'plus':
        return <i style={ style } className="fas fa-plus"></i>
      case 'miinus':
        return <i style={ style } className="fas fa-minus"></i>
      case 'jaettuna':
        return <i style={ style } className="fas fa-divide"></i>
      case 'kertaa':
        return <i style={ style } className="fas fa-times"></i>
      case '(':
        return <span style={ bracketStyle }>(</span>
      case ')':
        return <span style={ bracketStyle }>)</span>
      default:
        return operator
    }
  }

  testFormula = async () => {
    let payload = {
      formula: this.state.variableBeingEdited.formula,
      testNumber: this.state.formulaTestNumber,
      jwtToken: window.sessionStorage.getItem( "token" )
    }

    const jsonRes = await fetch( confs.url + 'testFormula', {
      method: 'POST',
      body: JSON.stringify( payload )
    } )
    const res = await jsonRes.json()

    if ( res.result ) {
      this.setState( { formulaTestResult: res.result } )
    } else {
      this.setState( { formulaTestResult: 'Virhe kaavassa, ei tulosta' } )
    }
  }

  freeNumberHandler = ( value, testNumber = false ) => {
    if ( testNumber ) {
      let regex = /^-?\d*\.?\d*$/;
      if ( regex.test( value ) ) {
        this.setState( { formulaTestNumber: value } );
      }
    } else {
      let regex = /^-?\d*\.?\d*$/;
      if ( regex.test( value ) ) {
        this.setState( { formulaFreeNumber: value } );
      }
    }
  }

  handleFinalFeedbackCheck = () => {
    const { category, onCategoryModified } = this.props

    this.setState( prevState => ( {
      finalFeedbackIsChecked: !prevState.finalFeedbackIsChecked
    } ), () => {

      const payload = {
        categoryId: category._id,
        incomingData: {
          askFinalFeedbackEmail: this.state.finalFeedbackIsChecked
        }
      }

      onCategoryModified( payload )
    } )
  }

  handleFinalFeedbackEmailsChange = selectedEmails => {
    const { category, onCategoryModified } = this.props

    const payload = {
      categoryId: category._id,
      incomingData: {
        finalFeedbackEnterpriseEmails: selectedEmails
      }
    }

    onCategoryModified( payload )
  }

  getAndFormatEnterpriseEmails = async () => {
    let formattedEmails = []
    const emailResult = await this.props.getEnterpriseEmails()

    if ( emailResult.length > 0 ) {
      emailResult.forEach( email => formattedEmails.push( { label: email, value: email } ) )
    }

    this.setState( { enterpriseEmails: formattedEmails } )
  }

  getFeedbackMentionSuggestions = () => {
    const surveyQuestions = this.getSurveyQuestions( true )
    let suggestions = []

    let i = 1
    surveyQuestions.forEach( group => {
      if ( group.options ) {
        group.options.forEach( question => {
          let suggestion = {
            name: `@kysymys${ i }`,
            title: question.label || '',
            id: question.id
          }
          suggestions.push( suggestion )
          i++
        } )
      }
    } )

    this.setState( { feedbackSuggestions: suggestions } )
  }

  toggleDeleteModal = ( variable = null ) => {
    this.setState( prevState => ( {
      confirmDeleteModal: !prevState.confirmDeleteModal,
      variableBeingDeleted: variable
    } ) )
  }

  render() {
    const { oldVariables } = this.state

    const variableSuggestions = oldVariables.map( variable => { return { name: '@' + variable.name, title: variable.description } } )

    return (
      <div className="categoryEditWindow">
        <FormulaModal
          formulaModalIsOpen={ this.state.formulaModalIsOpen }
          toggleFormulaModal={ this.toggleFormulaModal }
          addPieceToFormula={ this.addPieceToFormula }
          getSurveyQuestions={ this.getSurveyQuestions }
          freeNumberHandler={ this.freeNumberHandler }
          formulaFreeNumber={ this.state.formulaFreeNumber }
          variableBeingEdited={ this.state.variableBeingEdited }
          removePieceFromFormula={ this.removePieceFromFormula }
          saveFormula={ this.saveFormula }
          getFormulaOperatorLabel={ this.getFormulaOperatorLabel }
          testFormula={ this.testFormula }
          formulaTestNumber={ this.state.formulaTestNumber }
          formulaTestResult={ this.state.formulaTestResult }
        />

        <ConfirmDeleteModal isOpen={ this.state.confirmDeleteModal } toggleModal={ this.toggleDeleteModal } confirmDelete={ this.removeVariable } variable={ this.state.variableBeingDeleted } />

        <Row style={ { marginTop: '1em' } }>
          <Col xs={ { size: 12 } }>
            <h3>Loppupalaute</h3>
          </Col>
        </Row>

        <Row style={ { marginTop: '2em' } }>
          <Col className="tomHanksEditor">
            <h5>Palautteiden muuttujat</h5>
            <p>Voit lisätä muuttujia lähetettävään palautteeseen kirjoittamalla palautteen tekstikenttään @-merkin ja muuttujan nimen.</p>
            { this.props.category.finalFeedbackVariables && this.props.category.finalFeedbackVariables.map( ( vari, index ) => {
              return (
                <div key={ index } >
                  <VariableGroup variable={ vari } varIndex={ index } newVariable={ false } toggleFormulaModal={ this.toggleFormulaModal } onVariableChange={ this.onOldVariableChange } setEditedVariable={ this.setEditedVariable } getFormulaOperatorLabel={ this.getFormulaOperatorLabel } />
                  <p style={ { color: 'red', cursor: 'pointer' } } onClick={ () => this.toggleDeleteModal( vari ) }>Poista muuttuja</p>
                </div>
              )
            } ) }

            { this.state.newVariableVisible &&
              <VariableGroup
                newVariable={ true }
                variable={ this.state.newVariable }
                saveVariable={ this.saveNewVariable }
                cancelEdit={ this.cancelVariableAdd }
                onVariableChange={ this.onNewVariableChange }
                toggleFormulaModal={ this.toggleFormulaModal }
                setEditedVariable={ this.setEditedVariable }
                getFormulaOperatorLabel={ this.getFormulaOperatorLabel }
              />
            }

            { !this.state.newVariableVisible && <button style={ { marginTop: '0.5rem' } } onClick={ () => this.setState( { newVariableVisible: true } ) } className="small-white-button">Lisää uusi muuttuja</button> }

          </Col>
        </Row>

        <Row style={ { marginTop: '2em' } }>
          <Col className="tomHanksEditor">
            <h5>Lähetettävä palaute</h5>
            <p>Lähetettävä palaute voidaan lähettää kartoituksen täyttäjälle sähköpostitse kartoituksen täyttämisen jälkeen. </p>

            <FormGroup check>
              <h6 style={ { margin: '.5em 0' } }>
                <Input type="checkbox" id="final-feedback-check" onChange={ this.handleFinalFeedbackCheck } checked={ this.state.finalFeedbackIsChecked } /> { ' ' }
                Kerää sähköpostiosoite ja lähetä automaattisesti asiakkaalle palaute.
              </h6>
            </FormGroup>

            { this.state.finalFeedbackIsChecked &&
              <>
                <p>Lähetä asiakkaan saama palaute myös seuraaviin osoitteisiin: </p>
                <CreatableSelect
                  isMulti
                  name="final-feedback-emails"
                  options={ this.state.enterpriseEmails }
                  placeholder="Valitse tai kirjoita..."
                  onChange={ this.handleFinalFeedbackEmailsChange }
                  value={ this.props.category.finalFeedbackEnterpriseEmails || [] }
                />

                <DebounceTextEditor
                  editorId={ `${ this.state.editorId }-ultimateFeedback` }
                  content={ this.props.category.finalFeedback || "" }
                  handleSave={ this.props.category.original ? this.props.onCategoryModified : this.props.onSentSurveyCategoryModified }
                  categoryId={ this.props.category._id }
                  surveyId={ this.props.surveyId }
                  type="categoryUltimateFeedback"
                  plaintextEditor={ true }
                  // placeholder="Syötä kartoituksen kokonaispalaute tähän. Kokonaispalaute lähetetään niin halutessanne kartoituksen täyttäneen käyttäjän antamaan sähköpostiosoitteeseen."
                  suggestions={ [ ...variableSuggestions, ...this.state.feedbackSuggestions] }
                  spellCheck={ this.props.spellCheckOn }
                  additionalClass='mt-2'
                  connectionType={"surveys"}
                  documentId={this.props.surveyId}
                />
              </>
            }
          </Col>
        </Row>

        <Row style={{ marginTop: '2em' }}>
          <Col className="tomHanksEditor">
            <h5>Kiitosteksti</h5>
            <p>Kiitosteksti näytetään kartoituksen yhteydessä viimeisen kysymyksen jälkeen.</p>
            {/* <textarea rows="5" className="categoryTextArea" value={this.state.categoryExplanation} placeholder="Anna Kartoituksen selite" onChange={(e) => this.setState({categoryExplanation: e.target.value})} /> */ }
            <DebounceTextEditor
              editorId={ `${ this.state.editorId }-surveyEndedText` }
              content={ this.props.category.surveyEndedText || "" }
              // onTextChange={ this.onSurveyEndedTextChange }
              handleSave={ this.props.category.original ? this.props.onCategoryModified : this.props.onSentSurveyCategoryModified }
              categoryId={ this.props.category._id }
              surveyId={ this.props.surveyId }
              type="categoryTomHanks"
              readOnly={ false }
              saveDraftjsImage={ this.props.saveDraftjsImage }
              spellCheck={ this.props.spellCheckOn }
              suggestions={ [ ...variableSuggestions, ...this.state.feedbackSuggestions ] }
              connectionType={"surveys"}
              documentId={this.props.surveyId}
            // placeholder="Syötä haluamasi kartoituksen kiitosteksti tähän. Kiitosteksti näytetään kartoituksen täyttäneelle henkilölle kartoituksen jälkeen."
            />
          </Col>
        </Row>

      </div>
    )
  }
}

const VariableGroup = ( props ) => {
  const { newVariable, saveVariable, cancelEdit, variable, onVariableChange, toggleFormulaModal, setEditedVariable } = props

  const onChange = ( field, value ) => {
    if ( field === 'name' ) {
      value = value.split( ' ' ).join( '' )
    }

    variable[ field ] = value
    onVariableChange( variable )
  }

  const onFormulaClick = ( variable ) => {
    setEditedVariable( variable )
    toggleFormulaModal()
  }

  return (
    <div className="variable-group-container">
      <Label style={ { marginTop: '0.5rem', marginBottom: 0 } }>Nimi <small>(Nimeen ei voi laittaa välilyöntejä)</small></Label>
      <Input type="text" value={ variable.name } onChange={ ( e ) => onChange( 'name', e.target.value ) } />

      <Label style={ { marginTop: '0.5rem', marginBottom: 0 } }>Selite</Label>
      <Input type="text" value={ variable.description } onChange={ ( e ) => onChange( 'description', e.target.value ) } />

      { !newVariable ? (
        <div>
          <Label style={ { marginTop: '0.5rem', marginBottom: 0 } }>Kaava</Label>

          <div style={ { marginTop: '0.5rem', cursor: 'pointer' } } onClick={ () => onFormulaClick( variable ) }>

            <FormulaContainer variableBeingEdited={ variable } getFormulaOperatorLabel={ props.getFormulaOperatorLabel } />

          </div>

        </div>
      ) : (
          <p className="light-text mt-3">Voit lisätä muuttujalle kaavan muuttujan lisäämisen jälkeen</p>
        ) }

      {/* <Input type="text" readOnly onClick={ () => onFormulaClick( variable ) } style={{ cursor: 'pointer' }} /> */ }

      { newVariable &&
        <div style={ { marginTop: '1rem' } }>
          <button className="small-white-button" onClick={ () => saveVariable() }>Lisää</button>
          <button className="small-white-button" onClick={ () => cancelEdit() } style={ { marginLeft: '0.5rem' } }>Peruuta</button>
        </div>
      }
    </div>
  )
}

const FormulaModal = props => {
  const formulaOperators = [
    { label: 'plus', value: '+', type: 'operator' },
    { label: 'miinus', value: '-', type: 'operator' },
    { label: 'jaettuna', value: '/', type: 'operator' },
    { label: 'kertaa', value: '*', type: 'operator' },
    { label: '(', value: '(', type: 'bracket-open' },
    { label: ')', value: ')', type: 'bracket-close' }
  ]

  const customStyles = {
    option: ( provided, state ) => ( {
      ...provided,
      fontSize: '1rem',
      width: '100%'
    } )
  }

  const formatOperatorLabels = ( { value, label } ) => {
    return (
      <div>{ props.getFormulaOperatorLabel( label ) }</div>
    )
  }

  return (
    <Modal isOpen={ props.formulaModalIsOpen } toggle={ props.toggleFormulaModal } size='xl'>
      <ModalHeader>Kaavaeditori</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={ { size: 12 } }>
            <FormulaContainer variableBeingEdited={ props.variableBeingEdited } removePieceFromFormula={ props.removePieceFromFormula } getFormulaOperatorLabel={ props.getFormulaOperatorLabel } />
          </Col>
        </Row>

        <Row style={ { marginTop: '1em' } }>
          <Col xs={ { size: 6 } }>
            <Row>
              <Col>
                <InputGroup style={ { marginRight: '1rem' } }>
                  <Input type='text' placeholder="Lisää haluamasi numero" onChange={ ( e ) => props.freeNumberHandler( e.target.value ) } value={ props.formulaFreeNumber } />
                  <InputGroupAddon addonType="append">
                    <button className="input-addon-button" onClick={ () => props.addPieceToFormula( { label: props.formulaFreeNumber, value: props.formulaFreeNumber, type: 'number' } ) }>Lisää</button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>

            <Row style={ { marginTop: '1em' } }>
              <Col>
                <Select
                  value={ "" }
                  onChange={ props.addPieceToFormula }
                  options={ formulaOperators }
                  placeholder="Valitse operaattori"
                  className="formula-operator-select"
                  styles={ customStyles }
                  formatOptionLabel={ formatOperatorLabels }
                  classNamePrefix="select"
                />
              </Col>
            </Row>
          </Col>

          <Col xs={ { size: 6 } }>
            <Select
              value={ "" }
              onChange={ props.addPieceToFormula }
              options={ props.getSurveyQuestions() }
              placeholder="Valitse kysymyksen tulos"
              noOptionsMessage={ () => { return "Ei sopivia kysymyksiä" } }
              className="formula-question-select"
              classNamePrefix="select"
            />
          </Col>
        </Row>

        <hr />

        <Row style={ { marginTop: '1em' } }>
          <Col>
            <h5>Testaa kaavan toimintaa</h5>
            <p>Voit testata kaavan toimintaa antamalla alle numeron, jolla kaikkien kaavassa olevien kysymysten arvo korvataan testissä, ja painamalla Testaa-nappia. Ohjelma ilmoittaa tuloksen, jonka kaava antaa.</p>
          </Col>
        </Row>
        <Row style={ { marginTop: '1em' } }>
          <Col xs={ { size: 6 } }>
            <InputGroup>
              <Input type='text' placeholder="Numero, jolla kysymysten arvo korvataan" onChange={ ( e ) => props.freeNumberHandler( e.target.value, true ) } value={ props.formulaTestNumber } />
              <InputGroupAddon addonType="append">
                <button className="input-addon-button" onClick={ props.testFormula }>Testaa</button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col xs={ { size: 6 } }>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <div className="formula-test-result-label">Tulos</div>
              </InputGroupAddon>
              <Input type='text' readOnly value={ props.formulaTestResult } />
            </InputGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter> 
        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={ props.toggleFormulaModal }>Peruuta</a>
        <button className="small-white-button wide-button" onClick={ props.saveFormula }>Tallenna</button>
      </ModalFooter>
    </Modal>
  )
}

const FormulaContainer = props => {
  const { variableBeingEdited, removePieceFromFormula, getFormulaOperatorLabel } = props
  return (
    <>
      <div className="formula-container">
        { ( variableBeingEdited && variableBeingEdited.formula ) && variableBeingEdited.formula.map( ( piece, index ) => {
          let pieceStyle = {}
          let onPieceClick = () => { return }

          if ( index === variableBeingEdited.formula.length - 1 && typeof removePieceFromFormula === 'function' ) {
            pieceStyle.cursor = 'pointer'
            onPieceClick = () => removePieceFromFormula( index )
          }

          if ( piece.type === 'bracket-open' || piece.type === 'bracket-close' ) {
            pieceStyle.backgroundColor = 'rgb(181, 228, 19)'
          }

          return <div key={ index } className="formula-block" onClick={ onPieceClick } style={ pieceStyle }>{ getFormulaOperatorLabel( piece.label ) }</div>
        } ) }
      </div>
      <p className="lighter-text">Voit poistaa kaavan viimeisen osan klikkaamalla sitä.</p>
    </>
  )
}

const ConfirmDeleteModal = props => {
  return (
    <Modal isOpen={ props.isOpen } toggle={ props.toggleModal }>
      <ModalHeader>Oletko varma?</ModalHeader>
      <ModalBody>
        <p>Haluatko varmasti poistaa muuttujan?</p>
      </ModalBody>
      <ModalFooter>  
        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={ props.toggleModal }>Peruuta</a>
        <Button color='danger' onClick={ () => props.confirmDelete( props.variable ) }>Poista</Button>
      </ModalFooter>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onSave: ( params ) => dispatch( ActionCreator.saveCategory( params ) ),
    onCategoryModified: ( params ) => dispatch( ActionCreator.modifyCategory( params ) ),
    onSentSurveyCategoryModified: ( params ) => dispatch( ActionCreator.modifySentSurveyCategory( params ) ),
    uploadImg: ( params ) => dispatch( ActionCreator.uploadImg( params ) ),
    getEnterpriseEmails: () => dispatch( ActionCreator.getEnterpriseEmails() )
  }
}

export default connect( null, mapDispatchToProps )( FinalFeedback )
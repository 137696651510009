import * as React from "react";
import { motion, useCycle } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.02 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const filters = [
    {
      value: 'Avoin',
      filter: 'Avoin'
    },
    {
      value: 'Peruutettu',
      filter: 'declined'
    },
    {
      value: 'Valmis',
      filter: 'done'
    }
];

export const Menu = (props) => {
  const { customFilters } = props
  return (
  <motion.ul variants={variants} className={props.className} style={{zIndex: 999}}>
    {customFilters ? (
      customFilters.map(i => 
        <MenuItem i={i} key={i.value} handleSelection={ props.handleSelection } filters={ props.statusFilters } />
      )
    ) : (
      filters.map(i => 
      <MenuItem i={i} key={i.value} handleSelection={ props.handleSelection } filters={ props.statusFilters } />
    ))}
  </motion.ul>
  )
};
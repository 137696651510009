import React, { Component, useState } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';
import { confs } from '../../../modules/config';
import { connect } from 'react-redux';
import * as ActionCreator from '../../../store/index';
import { Scrollbars } from 'react-custom-scrollbars';
import { APIHandler } from './APIHandler';
import { ExcelHandler } from './ExcelHandler';

class EnterpriseEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            enterprise: {
                businessName: '',
                contactEmail: '',
                address: '',
                postcode: '',
                businessID: ''
            },
            edit: false
        }
        this.handleField = this.handleField.bind(this);
        this.uploadLogo = this.uploadLogo.bind(this);
        this.uploadBackgroundImage = this.uploadBackgroundImage.bind(this)
    }

    componentDidMount() {
        let enterprise = {
            ...this.props.enterprise
        }

        this.setState({ enterprise: enterprise });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.enterprise !== this.props.enterprise) {
            let enterprise = {
                ...this.props.enterprise
            }

            this.setState({ enterprise: enterprise });

        }
    }

    handleField(field, value) {
        let object = {
            ...this.state.enterprise
        };

        object[field] = value;

        this.setState({ enterprise: object });
    }

    deleteLogo = () => {
        let enterprise = this.state.enterprise;

        enterprise = {
            ...enterprise,
            logo: false
        }

        this.setState({ enterprise: enterprise });
    }

    deleteBackground = () => {
        let enterprise = this.state.enterprise;

        enterprise = {
            ...enterprise,
            styles: {
                ...enterprise.styles,
                backgroundImage:false
            }
        }

        this.setState({ enterprise: enterprise });
    }

    save = () => {
        this.props.edit(this.state.enterprise);
        this.setState({ edit: false })
    }

    uploadLogo(e) {
        const formData = new FormData()
        formData.append("logo", e.target.files[0], e.target.files[0].name)
        formData.append("businessId", this.props.enterprise.enterpriseId)
        formData.append("jwtToken", window.sessionStorage.getItem("token"))
        this.props.uploadLogo(formData)
    };

    uploadBackgroundImage(e) {
        const formData = new FormData()
        formData.append("background", e.target.files[0], e.target.files[0].name)
        formData.append("businessId", this.props.enterprise.enterpriseId)
        formData.append("jwtToken", window.sessionStorage.getItem("token"))
        this.props.uploadBackgroundImage(formData)
    };

    saveBusinessExcel = (payload) => {
        let formData = new FormData()
        formData.append("file", payload.file)
        formData.append("title", payload.title)
        formData.append("businessId", payload.selectedBusinessId.name)
        formData.append("jwtToken", window.sessionStorage.getItem("token"))
        if(payload.hubExcelChecked){
            formData.append("hubExcel", payload.hubExcelChecked)
        }
        if(payload.selectedBusinessName){
            formData.append("businessName", payload.selectedBusinessName.name)
        }
        if(payload.portalId){
            formData.append("portalId", payload.portalId)
        }
        this.props.uploadExcelToBusiness(formData)
    }

    deleteExcel = (id) => {
        let params = {fileId: id}
        this.props.deleteExcel(params)
    }

    handleColorChange = (params) => {
        const {type, color} = params
        let payload = {}
        if(this.state.enterprise && this.state.enterprise.styles && this.state.enterprise.styles.css){
            payload = this.state.enterprise.styles.css
        }
        if(type === "primary"){
            payload = {
                ...payload,
               
                "--enterprise-primary-color":color
            }
        }
        if(type === "secondary"){
            payload = {
                ...payload,
                "--enterprise-highlight-color":color,
                "--enterprise-secondary-color":color
            }
        }
        if(type === "title"){
            payload = {
                ...payload,
                "--custom-heading-color":color
            }
        }
        this.props.handleEnterpriseColors({css:payload})
    }

    render() {
        const style = { fontWeight: '300', fontSize: '0.9em' }
        let logoStyle = {}
        if (this.state.enterprise.logo) {
            logoStyle = { backgroundImage: `url(${confs.host + '/api/getLogo/' + this.state.enterprise.logo + '?d=' + Date.now()})`, backgroundSize: 'cover' }
        }
        let backgroundStyle = {}
        if (this.state.enterprise.styles && this.state.enterprise.styles.backgroundImage) {
            backgroundStyle = { backgroundImage: `url(${confs.host}/api/get/imagePreview/${this.state.enterprise.styles.backgroundImage})`, backgroundSize: 'cover' }
        }
        return (
            <Row>
                <Col xl={{ size: 12 }}>
                    <div className="enterprise-box">
                        <Row>
                            <Col>
                                <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                                    <p>Yrityksen tiedot</p>

                                    <div style={{ marginLeft: 'auto', marginRight: '1em' }}>
                                        <button className="small-white-button lighter-text wide-button enterprise-edit-button" onClick={() => this.setState({ edit: !this.state.edit })}> {!this.state.edit ? 'Muokkaa' : 'Peruuta'}</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "100%", height: '570px' }} >
                                    {this.state.enterprise.businessName &&
                                        <div className="enterprise-edit">
                                            <Row>
                                                <Col xs="2">
                                                    <span>Yrityksen nimi:</span>
                                                </Col>
                                                <Col xs="8">
                                                    {this.state.edit ?
                                                        <Input className="input-bar" name="enterprise-name" type="text" value={this.state.enterprise.businessName} style={{ width: "100%" }} onChange={(e) => this.handleField("businessName", e.target.value)}></Input>
                                                        :
                                                        <span style={style}>{this.state.enterprise.businessName}</span>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="2">
                                                    <span>Y-tunnus:</span>
                                                </Col>
                                                <Col xs="8">
                                                    {this.state.edit ?
                                                        <Input className="input-bar" type="text" value={this.state.enterprise.businessID} style={{ width: "100%" }} onChange={(e) => this.handleField("businessID", e.target.value)}></Input>
                                                        :
                                                        <span style={style}>{this.state.enterprise.businessID}</span>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="2">
                                                    <span>Osoite:</span>
                                                </Col>
                                                <Col xs="8">
                                                    {this.state.edit ?
                                                        <Input className="input-bar" type="text" value={this.state.enterprise.address} style={{ width: "100%" }} onChange={(e) => this.handleField("address", e.target.value)}></Input>
                                                        :
                                                        <span style={style}>{this.state.enterprise.address}</span>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <APIHandler edit={this.state.edit} saveApiKey={this.props.handleAPIKeys} hubSpot={this.state.enterprise.HubSpot} qlikList={this.state.enterprise.qlikList} openAI={this.state.enterprise.OpenAI} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="2">
                                                    <span>Excel tiedostot:</span>
                                                </Col>
                                                <Col xs="10">
                                                    <ExcelHandler saveBusinessExcel={this.saveBusinessExcel} excels={this.state.enterprise.excels || []} deleteExcel={this.deleteExcel} notAddedHubSpots={this.props.notAddedHubSpots ? this.props.notAddedHubSpots : null}  hasHubSpot={this.props.hasHubSpot} />
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col xs="2">
                                                    <span>Logo:</span>
                                                </Col>
                                                <Col xs="8">
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div className="logo-container" style={logoStyle}>
                                                            {!this.state.enterprise.logo &&
                                                                <i className="material-icons" style={{ position: 'relative', fontSize: 60, top: (50 % - 30), left: (50 % - 30), color: '#749db4' }}>get_app</i>
                                                            }
                                                            <Input onChange={this.uploadLogo} title="Tästä painamalla voit vaihtaa logon" type="file" name="logo" style={{ opacity: 0, height: '100%', cursor: 'pointer', position: 'absolute', top: 0 }} />                                                     
                                                        </div>                                                   
                                                        <span style={{ marginLeft: '1em' }}>
                                                            {this.state.enterprise.logo ?
                                                                <button className="small-white-button lighter-text wide-button" style={{ cursor: 'pointer', backgroundColor:"red",color:"#fff",fontFamily: "Font Awesome 5 Brands !impotant"}} onClick={this.deleteLogo}>Poista logo</button>
                                                                :
                                                                <div style={{ display: 'line-block', position: 'relative' }}>
                                                                    <p className="lighter-text" style={{ cursor: 'pointer' }} type="file" onChange={this.uploadLogo}>Lataa kuva</p>
                                                                    <Input onChange={this.uploadLogo} type="file" name="logo" style={{ opacity: 0, height: '100%', position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} />
                                                                </div>
                                                            }
                                                        </span>  
                                                    </div> 
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="2">
                                                    <span>Taustakuva:</span>
                                                </Col>
                                                <Col xs="8">
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div className="logo-container" style={backgroundStyle}>
                                                            {!this.state.enterprise.styles || (this.state.enterprise.styles && !this.state.enterprise.styles.backgroundImage) &&
                                                                <i className="material-icons" style={{ position: 'relative', fontSize: 60, top: (50 % - 30), left: (50 % - 30), color: '#749db4' }}>get_app</i>
                                                            } 
                                                            {this.props.adminUser && <Input onChange={this.uploadBackgroundImage} title="Tästä painamalla voit vaihtaa taustakuvan" type="file" name="logo" style={{ opacity: 0, height: '100%', cursor: 'pointer', position: 'absolute', top: 0 }} /> }                                                                                                      
                                                        </div>                                                   
                                                        {this.props.adminUser &&
                                                            <span style={{ marginLeft: '1em' }}>
                                                                {(this.state.enterprise.styles && this.state.enterprise.styles.backgroundImage) ?
                                                                    <button className="small-white-button lighter-text wide-button" style={{ cursor: 'pointer', backgroundColor:"red",color:"#fff",fontFamily: "Font Awesome 5 Brands !impotant"}} onClick={this.deleteBackground}>Poista taustakuva</button>
                                                                    :
                                                                    <div style={{ display: 'line-block', position: 'relative' }}>
                                                                        <p className="lighter-text" style={{ cursor: 'pointer' }} type="file" onChange={this.uploadBackgroundImage}>Lataa kuva</p>
                                                                        <Input onChange={this.uploadBackgroundImage} type="file" name="logo" style={{ opacity: 0, height: '100%', position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} />
                                                                    </div>
                                                                }
                                                            </span> 
                                                        }  
                                                    </div> 
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="2">
                                                    <span>Yrityksen värit:</span>
                                                </Col>
                                                <Col xs="8">
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>                                          
                                                        {this.props.adminUser &&
                                                            <EnterpriseColors 
                                                                handleColorChange={this.handleColorChange}
                                                                enterpriseColors={(this.state.enterprise && this.state.enterprise.styles && this.state.enterprise.styles.css)?this.state.enterprise.styles.css: {"--enterprise-primary-color": "#6ba53f", "--enterprise-secondary-color": "#404040", "--custom-heading-color": "#404040"}}
                                                            />                                  
                                                        }  
                                                    </div> 
                                                </Col>
                                            </Row>
                                            <div style={{position:"relative", marginTop:"-0.5rem"}}>
                                            {this.state.edit &&
                                            <>
                                            <Row style={{position:"relative", backgroundColor: '#fff' }}>
                                                    <Col>
                                                        <button onClick={this.save} style={{ width: '100%'}} className="small-white-button">Tallenna</button>
                                                    </Col>
                                                </Row>
                                                </>
                                            }
                                            </div>
                                        </div>
                                       
                                    }                                    
                                </Scrollbars>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        hasHubSpot: state.authentication.hasHubSpot,
        notAddedHubSpots: state.enterprise.enterprise.notAddedHubSpots,
        adminUser: state.authentication.adminUser
    }
};


const mapDispatchToProps = dispatch => {
    return {
        uploadLogo: (params) => dispatch(ActionCreator.uploadLogo(params)),
        uploadBackgroundImage: (params) => dispatch(ActionCreator.uploadBackgroundImage(params)),
        handleAPIKeys: (params) => dispatch(ActionCreator.handleApiKeys(params)),
        uploadExcelToBusiness: (params) => dispatch(ActionCreator.uploadExcelToBusiness(params)),
        deleteExcel: (params) => dispatch(ActionCreator.deleteBusinessExcel(params)),
        handleEnterpriseColors: (params) => dispatch(ActionCreator.handleEnterpriseColors(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseEdit);

const listOfDefaultColors = [
    // '#fac465',
    // '#FAF371',
    '#6ba53f', 
    '#00D084', 
    '#7BDCB5', 
    '#7DDBFA', 
    '#4BA0FA', 
    '#D38DFA',
    '#F78DA7', 
    '#FA766C',  
    '#FA9946',
    '#ABB8C3',
    '#4957A9',
    '#A63744',
    '#404040',
    
];

const listOfTitleColors = [
    '#6ba53f', 
    '#831111',
    '#060560', 
    '#A63744',
    '#4957A9',
    '#404040',
]

const EnterpriseColors = ({handleColorChange, enterpriseColors}) => {
    const [primaryColor, setPrimaryColor] = useState(enterpriseColors["--enterprise-primary-color"] ||"#6ba53f")
    const [secondaryColor, setSecondaryColor] = useState(enterpriseColors["--enterprise-secondary-color"] ||"#404040")
    const [titleColor, setTitleColor] = useState(enterpriseColors["--custom-heading-color"] || "#404040")
    return(
        <Col>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{  marginLeft: '0em', marginBottom:'-0.5em'}}>
                    <div style={{ display:'flex', marginBottom:'0.5em',}}><Label>Väri 1 <span style={{fontSize:'0.8rem'}}>(Lomakkeen sisällysluettelon hoveraus ja tulosta-painike)</span></Label></div>
                    <div style={{display:'flex', marginLeft:'-0.25em'}}>
                        {listOfDefaultColors && listOfDefaultColors.map((color, index)=>{
                            return(
                                <div key={index} className="color-square" onClick={() => setPrimaryColor(color)} style={{backgroundColor: color, border: color.toLowerCase() === primaryColor.toLowerCase()?(color !== '#404040'?'2px solid #404040': '2px solid #ABB8C3'):'none'}}></div>
                            )
                        })}
                    </div>
                    <div class="input-group mb-3" style={{ borderRadius:' 5px' , width:'15em'}}>
                        <div class="input-group-append">
                            <span class="mySpanClass input-group-text" style={{ borderRadius:'5px 0 0 5px', backgroundColor: primaryColor, color:'#fff'}}>#</span>
                        </div>
                        <input  type="text" class="form-control" placeholder="FFFF" value={primaryColor.substring(1)} onChange={(e) => setPrimaryColor(('#' +e.target.value))}/>
                        <div class="input-group-append">
                            <button className="small-white-button" onClick={() => handleColorChange({ type: 'primary', color:primaryColor})} style={{ borderRadius:'0 5px 5px 0'}}>Tallenna</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop:'1em', }}>
                <div style={{  marginLeft: '0em', marginBottom:'-0.5em'}}>
                    <div style={{ display:'flex', marginBottom:'0.5em'}}><Label>Väri 2 <span style={{fontSize:'0.8rem'}}>(Lomakkeen painikkeet ja otsikko, Lakanakartoituksen navigointi ja lähetä-painike)</span></Label></div>
                    <div style={{display:'flex', marginLeft:'-0.25em'}}>
                        {listOfDefaultColors && listOfDefaultColors.map((color, index)=>{
                            return(
                                <div key={index} className="color-square" onClick={() => setSecondaryColor(color)} style={{backgroundColor: color, border: color.toLowerCase() === secondaryColor.toLowerCase()?(color !== '#404040'?'2px solid #404040': '2px solid #ABB8C3'):'none'}}></div>
                            )
                        })}
                    </div>
                    <div class="input-group mb-3" style={{ borderRadius:' 5px', width:'15em'}}>
                        <div class="input-group-append">
                            <span class="mySpanClass input-group-text" style={{ borderRadius:'5px 0 0 5px', backgroundColor: secondaryColor, color:'#fff'}}>#</span>
                        </div>
                        <input  type="text" class="form-control" placeholder="FFFF" value={secondaryColor.substring(1)} onChange={(e) => setSecondaryColor(('#'+e.target.value))}/>
                        <div class="input-group-append">
                            <button className="small-white-button" onClick={() => handleColorChange({ type: 'secondary', color:secondaryColor})} style={{ borderRadius:'0 5px 5px 0'}}>Tallenna</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop:'1em' }}>
                <div style={{  marginLeft: '0em', marginBottom:'-0.5em'}}>
                    <div style={{ display:'flex', marginBottom:'0.5em'}}><Label>Lomakkeen alaotsikoiden väri</Label></div>
                    <div style={{display:'flex', marginLeft:'-0.25em'}}>
                        {listOfTitleColors && listOfTitleColors.map((color, index)=>{
                            return(
                                <div key={index} className="color-square" onClick={() => setTitleColor(color)} style={{backgroundColor: color, border: color.toLowerCase() === titleColor.toLowerCase()?(color !== '#404040'?'2px solid #404040': '2px solid #ABB8C3'):'none'}}></div>
                            )
                        })}
                    </div>
                    <div class="input-group mb-3" style={{ borderRadius:' 5px', width:'15em'}}>
                        <div class="input-group-append">
                            <span class="mySpanClass input-group-text" style={{ borderRadius:'5px 0 0 5px', backgroundColor: titleColor, color:'#fff'}}>#</span>
                        </div>
                        <input  type="text" class="form-control" placeholder="FFFF" value={titleColor.substring(1)} onChange={(e) => setTitleColor( ('#' +e.target.value))}/>
                        <div class="input-group-append">
                            <button className="small-white-button" onClick={() => handleColorChange({ type: 'title', color:titleColor})} style={{ borderRadius:'0 5px 5px 0'}}>Tallenna</button>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    )
}
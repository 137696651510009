import React, { useState, useEffect } from 'react';
import { confs } from '../../../modules/config';
import Select from 'react-select';
import { useDispatch } from 'react-redux'
import * as ActionCreator from '../Actions/ActionCreator';


import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';

const MoveToBusinessModal = ({ modulesInUse, isOpen, toggle, planId, updatePlanList=null }) => {
    const [clientList, setClientList] = useState([]);
    const [selectedBusiness, setSelectedBusiness] = useState({})
    const dispatch = useDispatch();

    useEffect(() => {
        if (modulesInUse.clientManagement) {
            let payload = {
                jwtToken: window.sessionStorage.getItem("token")
            }

            fetch(confs.url + 'sender/get/getCompanies', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then(resp => resp.json())
                .then(response => {
                    setClientList(response.businessList)
                }).catch(error => {

                })
        } else {
            const businessList = [
                { value: 'anon', label: 'Ei kohdeyritystä' }
            ]
            setClientList(businessList)
        }
    }, [])

    const save = (dispatch) => {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            selectedBusiness: selectedBusiness,
            planId: planId
        }
        dispatch(ActionCreator.movePlanToBusiness(payload));
        if(updatePlanList !== null){
            updatePlanList()
        }
        toggle();
    }


    return (
        <Modal isOpen={isOpen} toggle={toggle} centered size="md">
            <ModalHeader toggle={toggle} className="taskModal">
                <div>
                    Siirrä lomake yritykselle
                </div>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xl="12">
                        <FormGroup check>
                            <Label check style={{ cursor: 'pointer', marginBottom: '.5em' }}>
                                Valitse asiakas
                            </Label>
                            <Select
                                value={selectedBusiness}
                                onChange={setSelectedBusiness}
                                options={clientList}
                                placeholder="Etsi yritys"
                                isDisabled={false}
                                noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                className="companyTargets"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                <button className="small-white-button lighter-text wide-button" onClick={() => save(dispatch)}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

export default MoveToBusinessModal;
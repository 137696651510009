import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader, Container, Nav, NavItem, NavLink, Input } from 'reactstrap';
import * as ActionCreator from '../../../../../store/index';
import { NotificationManager } from 'react-notifications';
import { Scrollbars } from 'react-custom-scrollbars'
import { storeCategory } from '../builder/TypeComponents/category/actions/ActionCreator'
import SenderContainer, { ChangeNameModal, ChangeSurveyTargetModal, ConfirmationModal, EditKartoitusModal, SentSurveys } from '../../../SenderV2/SenderContainer';
import SurveyListItem from '../../../SenderV2/SurveyListItem';
import BackgroundEditor from '../../../SenderV2/subComponents/BackgroundEditor';
import ModifyDateModal from '../../../../../Utilities/Modals/ModifyDateModal';
import moment from 'moment';
import { confs } from '../../../../../modules/config';
import FilterButton from '../../../SenderV2/Filter/FilterButton';
import MenuContainer from '../../../SenderV2/Filter/MenuContainer';
import { updateHelpLinks } from '../../../../../store/actions/Login/ActionCreator';
import { executeRequest } from '../../../../../Utilities/requestHandler/dataHandler';
import Select from 'react-select';
import * as UtilActions from '../../../../../store/actions/UtilActions/ActionCreator'

class SurveyList extends Component {
    constructor(props) {
        super(props);
        this.startEditing = this.startEditing.bind(this);

        this.state = {
            selected: false,
            modal: false,
            newName: null,
            selectedData: null,
            filter: '',
            activeTab: 'sentSurveys',
            filterPlaceholder: 'Etsi kartoitusta',
            surveysReady: false, 
            menuIndex: -1,
            confirmationModal: false,
            itemToModify: {},
            modalAction: '',
            selectedTarget: null,
            datesList: [],
            modifyDates: [],
            dateToChangeInd: null,
            filter: '',
            nameModalActive: false,
            editedName: '',
            editedSurvey: null,
            chosenKartoitus: null,
            kartoitusModalActive: false,
            mainFilters: [],
            backgroundSet: false,
            editBackground: false,
            background: null,
            resetBackground: false,
            hideMenu: false,
            dateModal: false,
            helpLinkSetup: false,
            newHelpLink: (this.props.helpLinks && this.props.helpLinks.survey) ? this.props.helpLinks.survey : "",
            deleteText: "",
            mainFilters: [],
            copyToEnterprise: false,
            selectedEnterprise: null
        };
    }

    componentDidMount() {
        this.props.resetBuilder();
        this.props.onMountFetchCategories();
        this.props.getAllSurveys();
        this.props.getAvailableSurveys();

        setTimeout(() => {
            this.setState({ surveysReady: true });
        }, 600);
    }

    startEditingSentSurvey = (surveyId = null, selectedSurvey = null) => {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            surveyId: surveyId === null ? this.state.chosenKartoitus.value : surveyId
        }

        fetch(confs.url + 'fetch/mainSurveyForEdit', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => response.json())
        .then(resp => {

            if (Object.keys(resp).length > 0) {
                this.props.onSurveyEdit(
                    {
                        categoryToEdit: resp,
                        selectedSurvey: selectedSurvey === null ? {
                            value: this.state.surveyData._id,
                            label: this.state.surveyData.name
                        } : selectedSurvey,
                        start: true
                    }
                );
                this.props.history.push('/kartoitukset/luonti')

            }
        })
    } 

    startEditing(category) {
        this.props.onEdit(category);
        this.props.history.push('/kartoitukset/luonti')
    }

    toggleDropdown = (y) => {
        if (this.state.selected === y) {
            this.setState({ selected: false });
        } else {
            this.setState({ selected: y })
        }
    }

    handleCopy = () => {
        if (this.state.newName !== null && this.state.newName !== '') {
            let params = {
                id: this.state.selectedData.surveyId,
                name: this.state.newName
            }

            if(this.state.copyToEnterprise){
                params = {
                    ...params,
                    copyToEnterprise: this.state.copyToEnterprise,
                    selectedEnterprise: this.state.selectedEnterprise
                }
            }

            this.props.copySurvey(params);
            this.setState({ modal: false });
        } else {
            NotificationManager.error("Anna uudelle kartoitukselle nimi", "Virhe", 3000);
        }
    }

    handleDelete = () => {
        if(this.state.deleteText === "poista"){
            let params = {
                id: this.state.selectedData.surveyId
            }

            this.props.deleteSurvey(params);
            this.setState({ modal: false });
            this.setState({deleteText: ""})
        }
    }

    toggleModal = (data, x) => {
        this.setState({ selectedData: data, delete: x })
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
        this.setState({deleteText: ""})
    }

    handleInputChange = (event) => {
        this.setState({ newName: event.target.value });
    }

    shortTitle = (title) => {
        let newtitle = title;
        newtitle = newtitle.slice(0, 70) + "...";
        return newtitle
    }


    selectReport = async (obj) => {
        const params = {
            reports: [
                {
                    reportId: obj._id
                }
            ]
        }

        this.props.getReportForDashboard(params)

        if (obj.businessName !== "Ei kohdeyritystä") {
            await this.props.getReportTree({ businessId: obj.businessID })
            this.props.history.push('/raportit/yritys/raportti')
        } else {
            this.props.storeBusiness({ name: 'Ei kohdeyritystä' })
            this.props.history.push('/raportit/kohdistamaton/raportti')
        }

    }

    toggleMenu = (cardIndex) => {
        if (this.state.menuIndex === cardIndex) {
            let obj = {
                menuIndex: -1
            };
            this.setState(obj);
        } else {
            let obj = {
                menuIndex: cardIndex
            };

            this.setState(obj);
        }
    }

    
    toggleConfirmationModal = (survey, action) => {
        if (this.state.confirmationModal) {
            this.setState({ confirmationModal: false, itemToModify: {}, modalAction: '' })
        } else {
            this.setState({ confirmationModal: true, itemToModify: survey, modalAction: action })
        }
        this.setState({deleteText: ""})
    }

    modalAction = (modalAction = false) => {
       

        if(this.state.modalAction && (this.state.modalAction !=="delete" || (this.state.modalAction === 'delete' && this.state.deleteText === 'poista'))){
            this.props.onSurveyEnd(this.state.itemToModify, this.state.modalAction)
            this.setState({ confirmationModal: false, itemToModify: {}, modalAction: '' })
            this.setState({deleteText: ""})
        } else if(modalAction){ 
            this.props.onSurveyEnd(this.state.itemToModify, modalAction)
            this.setState({ confirmationModal: false, itemToModify: {}, modalAction: '' })
            this.setState({deleteText: ""})
        }
    }

    toggleChangeSurveyTargetModal = (sentSurvey = null) => {
        this.setState(prevState => ({
            changeSurveyTargetModalIsOpen: !prevState.changeSurveyTargetModalIsOpen,
            surveyHavingTargetChanged: sentSurvey,
            selectedTarget: null
        }))
    }

    onSurveyTargetSelectChange = (val) => {
        this.setState({ selectedTarget: val })
    }

    changeSurveyTarget = (sentSurvey, newTarget) => {
        const { changeSurveyTarget } = this.props

        const params = {
            sentSurvey: sentSurvey,
            targetObj: newTarget
        }

        changeSurveyTarget(params)

        this.toggleChangeSurveyTargetModal()
    }

    setDates = (date) => {
        this.setState(date);

        if (date.endDate !== null && date.startDate !== null) {
            this.setState({ 'datesList': [...this.state.datesList, date] });
            this.setState({ endDate: null, startDate: null });
        }
    }

    isDayHighlighted = (day) => {
        let compare = moment(new Date(day))

        for (let date of this.state.datesList) {
            let start = moment(new Date(date.startDate._d))
            let end = moment(new Date(date.endDate._d))

            if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                return true;
            }
        }
        return false;
    }

    isDayBlocked = (day) => {
        let compare = moment(new Date(day))

        for (let date of this.state.modifyDates) {
            let start = moment(new Date(date.startDate))
            let end = moment(new Date(date.endDate))

            if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                return true;
            }
        }
        return false;

    }

    changeModifiedDateIndex = (dateobj, ind) => {
        if (ind !== -1) {
            this.setState({ s_date: moment(new Date(dateobj.startDate)), e_date: moment(new Date(dateobj.endDate)) })
        } else {
            this.setState({ s_date: null, e_date: null })
        }
        this.setState({ dateToChangeInd: ind })
    }

    removeDateFromList = (ind) => {
        if (this.state.modal) {
            let obj = [...this.state.modifyDates]
            obj.splice(ind, 1)
            this.setState({ modifyDates: obj })
        } else {
            let obj = [...this.state.datesList]
            obj.splice(ind, 1)
            this.setState({ datesList: obj })
        }
    }


    toggleBackgroundEditor = () => {
        if (this.state.editBackground && this.state.backgroundEdit) {
            this.setState({ background: null, backgroundEdit: null })
        }

        this.setState({ editBackground: !this.state.editBackground })
    }

    saveBackground = (obj) => {
        this.setState({ background: obj, backgroundSet: true, editBackground: false })
        this.afterSurveySelection()
    }

    modifyBackground = (sentSurvey) => {
        this.setState({ backgroundEdit: sentSurvey, editBackground: true, background: sentSurvey.background ? sentSurvey.background : {} })
    }

    saveModifiedBackground = (obj) => {
        let payload = {
            sentSurvey: this.state.backgroundEdit,
            background: obj
        }

        this.setState({
            backgroundEdit: null, editBackground: false, background: null
        })

        this.props.modifyBackground(payload)
    }

    saveEditedName = () => {
        const { editedName, editedSurvey } = this.state

        if (editedName.trim() !== '') {

            const params = {
                surveyId: editedSurvey._id,
                name: editedName
            }

            this.props.onSurveyDateModified(params)
        }
        this.toggleNameChange()
    }

    toggleNameChange = (survey = null) => {
        if (!survey) {
            this.setState({ editedName: '', nameModalActive: false, editedSurvey: null })
        } else {
            const usdd = JSON.parse(JSON.stringify(survey))
            this.setState({ editedName: usdd.name, nameModalActive: true, editedSurvey: survey })
        }
    }

    editName = (name) => {
        this.setState({ editedName: name })
    }

    modalToggler = () => {
        if (this.state.dateModal === true) {
            this.setState({ e_date: null, s_date: null, modifyDates: [], dateToChangeInd: null });
        }
        this.setState({ dateModal: !this.state.dateModal });
    }

    setReportData = (survey) => {
        this.setState({ s_date: moment(new Date(survey.startDate)), e_date: moment(new Date(survey.endDate)), dateModal: true, chosenSurvey: survey, modifyDates: survey.dates });
    }

    proceedToModifyDate = () => {
        let dates = [...this.state.modifyDates];

        for (let i = 0; i < dates.length; i++) {
            dates[i].startDate = moment(new Date(dates[i].startDate));
            dates[i].endDate = moment(new Date(dates[i].endDate));
        }
        let params = {
            ...this.state.chosenSurvey,
            surveyId: this.state.chosenSurvey._id,
            startDate: null,
            endDate: null,
            dates: dates
        };

        this.props.onSurveyDateModified(params);
        this.setState({ dateModal: false, dateToChangeInd: null, modifyDates: [] });
    }

    handleChosenKartoitusForEdit = (selectedOption) => {
        this.setState({ chosenKartoitus: selectedOption });
    }

    toggleKartoitusEdit = (survey = null) => {
        if (!survey) {
            this.setState({ kartoitusModalActive: false, modalData: null, chosenKartoitus: null, surveyData: null })
        } else if (survey && survey.mainSurveys && survey.mainSurveys.length > 1) {
            this.setState({ kartoitusModalActive: true, modalData: survey.mainSurveys, chosenKartoitus: survey.mainSurveys[0], surveyData: survey })
        } else {
            this.startEditingSentSurvey(survey.mainSurveys[0].value, { value: survey._id, label: survey.name });
        }
    }

    modifierFocus = (focus) => {
        this.setState(focus);
    }

    changeModifierDate = (dates) => {
        this.setState({ s_date: dates.startDate, e_date: dates.endDate });

        if (dates.endDate !== null && dates.startDate !== null) {
            let obj = []
            if (this.state.dateToChangeInd !== null && this.state.modifyDates.length > 0) {
                if (this.state.dateToChangeInd === -1) {
                    obj = [...this.state.modifyDates, dates]
                } else {
                    obj = [...this.state.modifyDates];
                    obj[this.state.dateToChangeInd] = dates;
                }
            } else {
                obj = [dates];
            }

            this.setState({ modifyDates: obj });
            if (this.state.dateToChangeInd === -1) {
                this.setState({ dateToChangeInd: obj.length - 1 })
            }
            //this.setState({ dateToChangeInd: null });
        }
    }

    handleMainFilters = (filter) => {
        if (this.state.mainFilters.includes(filter)) {
            let removeFilter = this.state.mainFilters.filter(x => x !== filter);
            this.props.getAllSurveys({ companyTargets: removeFilter.filter(x => x.businessName !== undefined) });
            this.setState({ mainFilters: removeFilter });
        } else {
            let filterObject = {
                target: filter.target
            }

            if (filter.value === "Tilanne") {
                //These will be used to directly filter results in the current list
                if (filter.target === "Aktiivinen") {
                    filterObject = {
                        ...filterObject,
                        isActive: true
                    }
                } else if (filter.target === "Päättynyt") {
                    filterObject = {
                        ...filterObject,
                        isActive: false
                    }
                } else if (filter.target === "Vastauksia") {
                    filterObject = {
                        ...filterObject,
                        hasAnswers: true
                    }
                } else if (filter.target === "Ei vastauksia") {
                    filterObject = {
                        ...filterObject,
                        hasAnswers: false
                    }
                }
            } else if (filter.value === "Asiakkaat") {
                //Fetch new list with the companies
                filterObject = {
                    ...filterObject,
                    businessName: filter.target
                }
            }

            let filters = [
                ...this.state.mainFilters,
                filterObject
            ]

            this.props.getAllSurveys({ companyTargets: filters.filter(x => x.businessName !== undefined) });

            this.setState({ mainFilters: filters });
        }
    }

    statusFilter = (sentSurvey) => {
        let mainFiltersActive = this.state.mainFilters.filter(x => x.isActive !== undefined);
        let mainFiltersAnswers = this.state.mainFilters.filter(x => x.hasAnswers !== undefined);

        let activeMatch = false;
        let answerMatch = false;

        if (mainFiltersActive.length > 0) {
            mainFiltersActive.map(filter => {
                if (filter.isActive === sentSurvey.isActive) {
                    activeMatch = true;
                }
            })
        } else {
            activeMatch = true;
        }

        if (mainFiltersAnswers.length > 0) {
            mainFiltersAnswers.map(filter => {
                if (filter.hasAnswers && sentSurvey.answerCount > 0) {
                    answerMatch = true;
                } else if (!filter.hasAnswers && sentSurvey.answerCount === 0) {
                    answerMatch = true;
                }
            })
        } else {
            answerMatch = true;
        }

        return activeMatch === answerMatch;
    }
    
    saveLink = async() => {
        let payload = {
            type: "survey",
            link: this.state.newHelpLink
        }
        const response = await executeRequest('save/helpLinks', payload)
        if(response.helpLinks){
            this.props.updateHelpLinks(response.helpLinks)
            NotificationManager.success("Linkki tallennettu", "", 3000)
        }
        this.setState({helpLinkSetup: false})
    }

    handleDeleteText = (value) => {
        this.setState({deleteText: value})
    }

    handleEnterpriseChange = (value) => {
        this.setState({selectedEnterprise: value})
    }

    handleEnterpriseCheck = () => {
        this.setState({copyToEnterprise: !this.state.copyToEnterprise})
    }

    addOrRemoveEnterpriseDownload = (data,action) => {
        let payload = {}
        if(action === "add"){
            payload = {
                id: data.surveyId,
                name: data.title,
                group: "Kartoitus",
                action: action
            }
           
        }
        if(action === "remove"){
            payload = {
                id: data.surveyId,
                action: action
            }
        }
        
        this.props.editEnterpriseDownloads(payload)
    }

    render() {
        let i = 0;
        const {sentSurveys} = this.props
        return (
            <Container fluid>
                <ModifyDateModal
                    changeModifierDate={this.changeModifierDate}
                    modifierFocus={this.modifierFocus}
                    startDate={this.state.s_date}
                    endDate={this.state.e_date}
                    focusedInput={this.state.focusedInputModal}
                    save={this.proceedToModifyDate}
                    isOpen={this.state.dateModal}
                    toggle={this.modalToggler}
                    dates={this.state.modifyDates}
                    setIndex={this.changeModifiedDateIndex}
                    modifyIndex={this.state.dateToChangeInd}
                    removeDate={this.removeDateFromList}
                    isDayBlocked={this.isDayBlocked}
                />
                <ConfirmationModal
                    isOpen={this.state.confirmationModal}
                    toggle={this.toggleConfirmationModal}
                    confirm={this.modalAction}
                    data={this.state.itemToModify}
                    actionType={this.state.modalAction}
                    deleteText={this.state.deleteText}
                    setDeleteText={this.handleDeleteText} 
                />
                <ChangeSurveyTargetModal
                    isOpen={this.state.changeSurveyTargetModalIsOpen}
                    toggle={this.toggleChangeSurveyTargetModal}
                    confirm={this.changeSurveyTarget}
                    businesses={this.props.linkedCompanies}
                    sentSurvey={this.state.surveyHavingTargetChanged}
                    onTargetChange={this.onSurveyTargetSelectChange}
                    selected={this.state.selectedTarget}
                />
                <ChangeNameModal
                    isOpen={this.state.nameModalActive}
                    toggle={this.toggleNameChange}
                    name={this.state.editedName}
                    onNameChange={this.editName}
                    confirm={this.saveEditedName}
                />
                <EditKartoitusModal
                    isOpen={this.state.kartoitusModalActive}
                    toggle={this.toggleKartoitusEdit}
                    data={this.state.modalData}
                    chosenItem={this.state.chosenKartoitus}
                    handleChosenKartoitusForEdit={this.handleChosenKartoitusForEdit}
                    confirm={this.startEditing}
                />

                {!this.state.resetBackground && <BackgroundEditor
                    isOpen={this.state.editBackground}
                    toggle={this.toggleBackgroundEditor}
                    background={this.state.background}
                    list={this.props.backgrounds}
                    save={this.state.backgroundEdit ? this.saveModifiedBackground : this.saveBackground}
                    uploadBackground={this.props.uploadBackground}
                />}
                <Row>
                    <Col className="" xl="12">
                        <div className="survey-color" style={{ borderRadius: '10px'}}>
                            <div className="whitebox" style={{ marginTop: '1rem',  padding: '0 1em' , borderRadius: '30px', height:'100%'}}>
                                {this.state.delete ?
                                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className}>
                                        <ModalHeader>{this.state.selectedData && this.state.selectedData.title}</ModalHeader>
                                        <ModalBody>
                                            <p>Oletko varma, että haluat poistaa kartoituksen? <b>Toimintoa ei voi peruuttaa!</b></p>
                                            <div style={{ marginTop: '1em' }}>
                                                <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                                                <Input id="deleteInput" type="text" value={this.state.deleteText} onChange={(e) => this.setState({deleteText: e.target.value})}/>
                                                <div className="email-check-box">
                                                    {this.state.deleteText === "poista"?
                                                        <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                                    }
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <a onClick={this.toggleModal} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                                            <button onClick={() => this.handleDelete()} className="small-white-button wide-button swb-hover" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                                        </ModalFooter>
                                    </Modal>
                                    :
                                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className}>
                                        <ModalHeader>{this.state.selectedData && this.state.selectedData.title}</ModalHeader>
                                        <ModalBody>
                                            <p>Anna kartoitukselle nimi:</p>
                                            <input type="text" name="copy-survey" value={this.state.newName} onChange={this.handleInputChange} style={{ width: '100%' }}></input>
                                            { this.props.EBAdmin && this.props.enterpriseList &&
                                                <div className="check-item-row"  style={{marginLeft:'0.15em', marginTop:'1em', marginBottom:'1em'}}>
                                                    <input type="checkbox" className="check-item-box" onChange={() =>  this.handleEnterpriseCheck()} checked={this.state.copyToEnterprise}/>
                                                    <p style={{ fontWeight: 'bold', marginLeft: '0.5em' }}>Kopioi toiselle enterpriselle</p>
                                                </div>
                                            }
                                            {this.state.copyToEnterprise &&
                                                <>
                                                    <label>
                                                        <p style={{ fontWeight: 700 }}>{"Valitse enterprise, jolle kopio luodaan"}</p>
                                                    </label>
                                                    <Select
                                                        value={this.state.selectedEnterprise}
                                                        onChange={this.handleEnterpriseChange}
                                                        options={this.props.enterpriseList}
                                                        placeholder="Valitse enterprise"
                                                        isDisabled={false}
                                                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                                        className="companyTargets"
                                                        classNamePrefix="select"
                                                    />
                                                </>
                                            }
                                        </ModalBody>
                                        <ModalFooter>
                                            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={this.toggleModal}>Peruuta</a>
                                            <button className="small-white-button wide-button swb-hover" color="primary" onClick={this.handleCopy} >Kopioi</button>{' '}
                                        </ModalFooter>
                                    </Modal>
                                }
                                <div style={{ paddingLeft: '1rem', paddingTop:'1em', borderRadius: '10px 10px 0 0', paddingBottom: '0', marginBottom: '-2em'}}>
                                    <Row>
                                        <Col xs="7">
                                            <h2>Kartoitustyökalu</h2>
                                        </Col>
                                        <Col xs="5">
                                            <div style={{ display: 'flex', float: 'right', marginRight: '2em' }}>
                                            
                                                {this.props.EBAdmin && 
                                                    <>
                                                        {!this.state.helpLinkSetup &&
                                                            <>
                                                                {this.props.helpLinks && this.props.helpLinks.survey?
                                                                    <button className="small-white-button swb-hover" onClick={()=> this.setState({helpLinkSetup: true})} style={{}}>Muokkaa linkkiä</button>
                                                                    :
                                                                    <button className="small-white-button swb-hover" onClick={()=> this.setState({helpLinkSetup: true})} style={{}}>Aseta linkki</button>
                                                                }
                                                            </>
                                                        }
                                                        {this.state.helpLinkSetup &&
                                                            <div style={{ display: 'flex', width:'20em' }}>
                                                                <Input type="text" style={{ width: '70%' }} value={this.state.newHelpLink} onChange={(e)=> this.setState({newHelpLink: e.target.value})}/>
                                                                <button className="small-white-button swb-hover" onClick={()=> this.saveLink()} style={{width: '30%'}}>Tallenna</button>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {this.props.helpLinks && this.props.helpLinks.survey && this.props.helpLinks.survey.length > 0 &&
                                                    <div title="Ohjeet" className="news-info" style={{ display: 'flex' , marginLeft: '1em', alignSelf: 'center', fontSize: '1.75rem' }} onClick={()=> window.open(this.props.helpLinks.plan)}>
                                                        <i className="fas fa-info-circle"></i>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="kartoitusExplanation" style={{ paddingBottom: '1em'}}>
                                        <Col>
                                            <p>
                                                Kartoitustyökalulla voit luoda kartoituksia, jotka voit myöhemmin lähettää asiakkaille. Kartoitukset voivat olla tyypiltään älykkäitä dialogeja tai klassisia kartoituksia.
                                                Palvelu luo kartoituksesta automaattisesti raportin siihen annettujen vastausten perusteella.
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col xs={{ size: 12 }}>
                                        <div className="box-header bigger-text" style={{ display: 'flex', alignItems:'center', padding: '0 0 0 0', borderBottom: 'none'}}>
                                            <Nav pills className="project-nav-tabs plan-tabs">
                                                <NavItem style={{ width: '15em', textAlign: 'center' }}>
                                                    <NavLink 
                                                        className={this.state.activeTab === 'sentSurveys' ? 'active' : ''}
                                                        onClick={() => { this.setState({ activeTab: 'sentSurveys', filter: "", filterPlaceholder: 'Etsi kartoitusta' }) }}
                                                    >
                                                        Aktivoidut kartoitukset
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem style={{ width: '10em', textAlign: 'center' }} >
                                                    <NavLink
                                                        className={this.state.activeTab === 'survey' ? 'active' : ''}
                                                        onClick={() => { this.setState({ activeTab: 'survey', filter: "", filterPlaceholder: 'Etsi kartoituspohjaa' }) }}
                                                    >
                                                    Kartoituspohjat
                                                    </NavLink>
                                                </NavItem>
                                
                                            
                                            </Nav>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div style={{ marginLeft: '3em', marginRight: '1.8em', alignItems: 'center', display: 'flex', marginBottom: '0.5em', width: '100%'}}>
                                        <input type="search" className="search-bar-open form-control" style={{ width: '50%'}} placeholder={this.state.filterPlaceholder} value={this.state.filter} onChange={(event) => this.setState({ filter: event.target.value })} />
                                        {this.state.activeTab === "sentSurveys" && this.props.filters && 
                                            <div style={{width: 'auto', padding: '1em 0 1em 0', display: 'flex' }}>
                                                <div style={{ width: 'auto', position: 'relative', marginRight: '1em' }} className="filter-nav">
                                                    <MenuContainer surveyFilters={this.props.filters} handleMainFilters={this.handleMainFilters} mainFilters={this.state.mainFilters} />
                                                </div>
                                                <div style={{display: 'flex'}}>
                                                    {this.state.mainFilters.map((filterItem) =>
                                                        <FilterButton filterItem={filterItem} handleMainFilters={this.handleMainFilters} key={filterItem.target} />
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Row>

                                <Row className="categoryList-survey" style={{ overflow: 'hidden', paddingBottom: '0.5em' }}>
                                    {this.state.activeTab === "survey" && 
                                        <Col xs="12">
                                            <Scrollbars
                                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                                style={{ width: "100%", height: '100%' }}
                                                className="custom-scrollbars"
                                            >
                                                {this.props.categories.map((data, y) => {
                                                    if ((data.title.toLowerCase().includes(this.state.filter.toLowerCase())) || (data.modifiedBy !== undefined && data.modifiedBy.toLowerCase().includes(this.state.filter.toLowerCase()))) {
                                                        let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                                        i++;
                                                        return <div className={oddEvenClass + ' list-row'} style={{ paddingLeft: '1rem' }} key={y} onClick={() => this.startEditing(data)}>
                                                            <Row className='survey-list-content'>
                                                                <Col xs={{ size: 6 }}>
                                                                    <p title={data.title}>{data.title.length > 70 ? this.shortTitle(data.title) : data.title}</p>
                                                                </Col>
                                                                <Col xs={{ size: 3 }}>
                                                                    <p style={{ fontSize: '0.8rem' }}>Muokattu: <span>{moment.utc(data.modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY HH:mm ')}</span></p>
                                                                </Col>
                                                                <Col xs={{ size: 2 }}>
                                                                    <button className="small-white-button lighter-text wide-button">Muokkaa</button>
                                                                </Col>
                                                                <Col xs={{ size: 1 }}>
                                                                    <Dropdown isOpen={this.state.selected === y} style={{ height: '100%', width: '100%' }} onClick={(event)=> event.stopPropagation() } toggle={() => this.toggleDropdown(y)}>
                                                                        <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                                            <i className="fas fa-bars" title="Valikko"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => this.toggleModal(data, false)}>Kopioi</DropdownItem>
                                                                            {this.props.EBAdmin && this.props.enterpriseDownloads &&
                                                                                <DropdownItem onClick={() => this.addOrRemoveEnterpriseDownload(data, this.props.enterpriseDownloads.filter(download => download.id === data.surveyId).length === 0 ? "add" : "remove")}>{this.props.enterpriseDownloads.filter(download => download.id === data.surveyId).length === 0 ?"Lisää lomake ladattavaksi" : "Poista lomake latauksista"}</DropdownItem>
                                                                            }
                                                                            <DropdownItem onClick={() => this.toggleModal(data, true)} >Poista</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                    return null
                                                })}
                                            </Scrollbars>
                                        </Col>
                                    }
                                    {this.state.activeTab === 'sentSurveys' &&
                                        <Col xs="12" className="sentSurvey-list-height-calculator">
                                            <SentSurveysForList
                                                surveysReady={this.state.surveysReady}
                                                sentSurveys={sentSurveys.filter(survey => this.statusFilter(survey))}
                                                changeDate={this.setReportData}
                                                menuIndex={this.state.menuIndex}
                                                endSurvey={this.toggleConfirmationModal}
                                                toggleMenu={this.toggleMenu}
                                                selectReport={this.selectReport}
                                                toggleChangeSurveyTarget={this.toggleChangeSurveyTargetModal}
                                                filter={this.state.filter}
                                                toggleKartoitusEdit={this.toggleKartoitusEdit}
                                                toggleNameChange={this.toggleNameChange}
                                                surveyFilters={this.props.filters}
                                                handleMainFilters={this.handleMainFilters}
                                                mainFilters={this.state.mainFilters}
                                                modifyBackground={this.modifyBackground}  
                                                activeTab={this.state.activeTab}  
                                            />
                                        </Col>
                                    }
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories: state.kartoitus.categories,
        sentSurveys: state.senderV2.sentSurveys,
        backgrounds: state.senderV2.backgroundList,
        linkedCompanies: state.senderV2.linkedCompanies,
        filters: state.senderV2.filters,
        EBAdmin: state.authentication.EBAdmin,
        enterpriseList: state.authentication.enterpriseList,
        helpLinks: state.authentication.helpLinks,
        adminUser: state.authentication.adminUser? state.authentication.adminUser: false,
        enterpriseDownloads: state.utils.enterpriseDownloads
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllSurveys: (payload) => dispatch(ActionCreator.getAllSurveys(payload)),
        onStart: (payload) => dispatch(ActionCreator.startCategory(payload)),
        onMountFetchCategories: () => dispatch(ActionCreator.fetchCategories()),
        onEdit: (payload) => dispatch(ActionCreator.fetchSurveyStack(payload)),
        copySurvey: (payload) => dispatch(ActionCreator.copySurvey(payload)),
        deleteSurvey: (payload) => dispatch(ActionCreator.deleteSurvey(payload)),
        resetBuilder: () => dispatch(ActionCreator.resetBuilder()),
        getReportForDashboard: (payload) => dispatch(ActionCreator.getReportForDashboard(payload)),
        storeBusiness: (params) => dispatch(ActionCreator.storeBusiness(params)),
        onSurveyEdit: (payload) => dispatch(ActionCreator.startSurveyEditFromSentSurveys(payload)),
        getAllBackgrounds: () => dispatch(ActionCreator.getAllBackgrounds()),
        modifyBackground: (payload) => dispatch(ActionCreator.modifyBackground(payload)),
        uploadBackground: (payload) => dispatch(ActionCreator.uploadBackground(payload)),
        getCompanies: (payload) => dispatch(ActionCreator.fetchLinkedCompanies(payload)),
        changeSurveyTarget: (params) => dispatch(ActionCreator.changeSurveyTarget(params)),
        onSurveyDateModified: (payload) => dispatch(ActionCreator.modifyActiveDate(payload)),
        onSurveyEnd: (params, type) => dispatch(ActionCreator.endSurvey(params, type)),
        getAvailableSurveys: (payload) => dispatch(ActionCreator.getAvailableSurveys(payload)),
        updateHelpLinks: (payload) =>dispatch(updateHelpLinks(payload)),
        getReportTree: (payload) => dispatch(ActionCreator.getBusinessSurveyTree(payload)),
        editEnterpriseDownloads : (payload) => dispatch(UtilActions.editEnterpriseDownloads(payload)),
        getEnterpriseDownloads: (payload) => dispatch(UtilActions.getEnterpriseDownloads(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);


const SentSurveysForList = (props) => {
    const {sentSurveys, surveysReady, toggleMenu, activeTab, handleMainFilters, mainFilters, surveyFilters} = props
    const [projects, setProjects] = useState([])

    return(
        <>  
           
            {surveysReady ?
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height: '100%' }}
                    className="custom-scrollbars"
                >
                    {sentSurveys.length > 0 && sentSurveys.map((sentSurvey, index) => {
                            let oddEven = index % 2 === 0 ? 'even-row' : 'odd-row'
                            index++;
                        if (sentSurvey.code) {
                            if (sentSurvey.name.toLowerCase().includes(props.filter.toLowerCase()) || (sentSurvey.code && sentSurvey.code.toLowerCase().includes(props.filter.toLowerCase())) || sentSurvey.businessName.toLowerCase().includes(props.filter.toLowerCase())) {
                                return (
                                    <div key={index}>
                                        <SurveyListItem
                                            sentSurvey={sentSurvey}
                                            selectReport={props.selectReport}
                                            toggleMenu={toggleMenu}
                                            toggleChangeSurveyTarget={props.toggleChangeSurveyTarget}
                                            toggleKartoitusEdit={props.toggleKartoitusEdit}
                                            toggleNameChange={props.toggleNameChange}
                                            changeDate={props.changeDate}
                                            endSurvey={props.endSurvey}
                                            index={index}
                                            key={sentSurvey._id}
                                            menuIndex={props.menuIndex}
                                            modifyBackground={props.modifyBackground}
                                            oddEven={oddEven}
                                            projects={projects}
                                        />
                                    </div>
                                )
                            }
                        }
                    })}

                </Scrollbars>
                : 
                <div style={{ height: 500, width: '100%' }} className="custom-scrollbars"><MegaLoaderSpinner /></div>
            
            }
        </>
    )
}

const MegaLoaderSpinner = () => {
    return (
        <Row className="loadingSpinnerRow">
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, useLocation} from 'react-router-dom'

//Redux
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

//Dev tools
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

//reducers

import Login from './store/reducers/Login';
import KartoitusHandler from './store/reducers/kartoitusHandler';
import QuestionGroupHandler from './store/reducers/QuestionGroupHandler';
import SenderHandler from './store/reducers/SenderHandler';
import EnterpriseHandler from './store/reducers/enterpriseHandler';
//Responsible for changing tabs on the sidepanel and delivering data to dashboard for reports
import reportReducer from './store/reducers/reportReducer';
//Used for survey statistics
import surveyReducer from './store/reducers/surveyReducer';
// import sidePanelReducer from './store/reducers/sidePanelHandler';
import newReportReducer from './store/reducers/newReportsReducer'
import breadcrumHandler from './store/reducers/breadcrumbHandler'

/*V2*/
import SenderV2Handler from './store/reducers/reducersV2/SenderHandler';
import CollaborationHandler from './store/reducers/reducersV2/CollaborationHandler';
import ComparisonHandler from './store/reducers/reducersV2/ComparisonHandler';

import BusinessReducer from './store/reducers/businessReducer'
import embedHandler from './store/reducers/embedHandler';
import PlanHandler from './store/reducers/reducersV2/PlanHandler';
import ExcelHandler from './store/reducers/reducersV2/ExcelHandler';
import ProjectHandler from './store/reducers/reducersV2/ProjectHandler';
import contactHandler from './store/reducers/contactHandler';
import utilHandler from './store/reducers/utilHandler';

const rootReducer = combineReducers({
    authentication: Login,
    kartoitus: KartoitusHandler,
    group: QuestionGroupHandler,
    sender: SenderHandler,
    senderV2: SenderV2Handler,
    surveys: surveyReducer,
    reportAndTab: reportReducer,
    enterprise: EnterpriseHandler,
    // sidePanel: sidePanelReducer,
    reports: newReportReducer,
    crumbs: breadcrumHandler,
    collaborations: CollaborationHandler,
    comparison: ComparisonHandler,
    business: BusinessReducer,
    embed: embedHandler,
    plan: PlanHandler,
    excel: ExcelHandler,
    project: ProjectHandler,
    contacts: contactHandler,
    utils: utilHandler
});

const ScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect( () => {
        window.scrollTo( 0, 0 )
    }, [ pathname ] )

    return null
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
ReactDOM.render(
<Provider store={store}>
    <Router>
        <ScrollToTop />
        <App/>
    </Router>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

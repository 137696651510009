import React, { useEffect, useState, useId }from "react";
import {Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Table } from "reactstrap";
import TextEditor from "../../../../Utilities/TextEditor/texteditor";

export const GridText = ({item, handleEdit, editable, planId}) => {
    return(
        <div>
            <TextEditor 
              editorId={`${item.i}`} 
              content={item.text} 
              onTextChange={editorState => handleEdit({item:item,id:'text', value: editorState})}
              additionalClass={"grid-text " + (editable? "grid-edit":"grid-preview")}
              placeholder="-"
              connectionType={"plans"}
              documentId={planId}
              readOnly={!editable}
            />
        </div>
    )
}


export const GridTable = ({item, handleEdit, editable, planId}) => {
    const rows = item.table.rows
    const borderless = item.table.borderless? item.table.borderless: false
    const handleTableData = (rowData) => {
        rows.splice(rowData.rowIndex, 1, {cols: rowData.cols})
        handleEdit({item:item, id:'table', value:{...item.table, rows: rows}})
    }

    return(
        <Table bordered hover style={{tableLayout: 'fixed'}}>
            <thead>
            </thead>
            <tbody>
                {rows && rows.map((row, rowIndex) => {
                    return(
                        <TableRowContainer row={row} rowIndex={rowIndex} handleTableData={ handleTableData } editable={editable} maximumCount={item.table.selectedNumberOfCols} key={rowIndex} planId={planId} borderless={borderless}/>
                )})}
            </tbody>
        </Table>
    )

}

const TableRowContainer = ({row, rowIndex, handleTableData, editable, maximumCount, planId }) => {
    const { cols } = row
    const handleRowChange = colData => {
        const { cols } = row
        let newCols = cols.map(col => {
            if(col.id === colData.id) {
                return colData
            }

            return col
        })

        handleTableData({
            cols: newCols,
            rowIndex: rowIndex
        })
    }

    return (
        <tr key={rowIndex}>   
            {cols.map((col, index) =>
                <ColumnEditor 
                    col={col} 
                    key={col.id} 
                    editorId={col.id} 
                    rowIndex={rowIndex} 
                    handleRowChange={handleRowChange} 
                    editable={editable} 
                    count={cols.length}
                    maximumCount={maximumCount}
                    colIndex={index}
                    planId={planId}
                />
            )}
        </tr>
    )
}

const ColumnEditor = ({col, editorId, handleRowChange, editable, maximumCount, count, colIndex, customWidth, planId}) => {
    const getColSpan = () => {
        if(count === 1)
            return maximumCount;
        else
            return (maximumCount % count === 1 && colIndex + 1 === count) ? maximumCount / count + 1 : maximumCount / count
    }

   

    return (
        <td style={{verticalAlign: 'top', backgroundColor: col.color? col.color : "#ffff" }} colSpan={getColSpan()}>
            <TextEditor
                editorId={editorId}
                content={col.text}
                onTextChange={editorState => handleRowChange({...col, text: editorState})}
                additionalClass="plan-input"
                readOnly={!editable}
                backgroundColor={col.color? col.color: "#ffff"}
                changeBackgroundColor={color => handleRowChange({...col, color: color})}
                connectionType={"plans"}
                documentId={planId}
            />
        </td>
    )
}



export const ListHeaderSorter = ({label, name, orderBy, sortOrder, handleSortOrder}) => {
    return (
        <div className="sorting-hover" style={{display:'flex'}} onClick={()=> handleSortOrder(name)}>
            {label}
            <div style={{ display:'flex', flexDirection:'column', marginLeft:'0.4em'}}>
                <i className="fas fa-sort-up" style={{ color:(orderBy === name && sortOrder === 1) ? "#404040" : "#ebebeb", marginBottom:'-0.75em'}}></i>
                <i className="fas fa-sort-down" style={{ color:(orderBy === name && sortOrder !== 1) ? "#404040" : "#ebebeb"}}></i>             
            </div>
        </div>
    )
}
import React, {useState, useEffect} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import {confs} from '../../../../modules/config';
import {NotificationManager} from 'react-notifications';
import Select from 'react-select';
import TextEditor from '../../../../Utilities/TextEditor/texteditor';
import {convertFromRaw, stripUnicodeFromString} from 'draft-js';
import GoalCreator from './GoalCreator';

import './summary.css';


const getValues = () => {
    let values = []

    for(let i = 0; i <= 100; i++) {
        values.push(i);
    }

    return values;
}
const handleSubSurveys = (selectedSubSurveys, survey) => {
    let items = selectedSubSurveys.filter(x => x === survey);

    if(items.length > 0) {
        return selectedSubSurveys.filter(x => x !== survey);
    } else {
        items = selectedSubSurveys.filter(x => x !== survey);
        items = [
            ...items,
            survey
        ];

        return items;
    }
}

const SummaryCreationModal = ({startCard, company, className, toggle, setSummaryCards}) => {
  const [isOpen, setModalOpen] = useState(startCard);
  const [targetCompany, setTargetCompany] = useState(company);
  const [selectedMainSurvey, setselectedMainSurvey] = useState(null);
  const [selectedSubSurvey, setSelectedSubSurvey] = useState(null);
  const [mainSurveys, setMainSurveys] = useState([]);
  const [availableSurveys, setAvailableSurveys] = useState([]);
  const [selectedSubSurveys, setSelectedSubSurveys] = useState([]);
  const [startSave, setStartSave] = useState(false);
  const [goalValue, setGoalValue] = useState([0]);

  const [summaryName, setSummaryName] = useState("");

    useEffect(() => {
        setTargetCompany(company);
        let payload = {
            jwtToken: window.sessionStorage.getItem("token")
        }

        if(isOpen) {
            //Fetch main surveys that have been sent to this company
            fetch(confs.url + 'fetch/mainSurveys', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then(response => response.json())
            .then(res => {
                setMainSurveys(res);
            }).catch(error => {
                NotificationManager.error("Valitettavasti kartoituksien haku epäonnistui", "", 2000);
            })
        } else {
            setSummaryName("");
            setselectedMainSurvey(null);
            setSelectedSubSurveys([])
        }
    }, [isOpen])

    useEffect(() => {
        if(selectedMainSurvey !== null) {
            let payload = {
                mainId: selectedMainSurvey.value,
                targetCompany: targetCompany,
                jwtToken: window.sessionStorage.getItem("token")
            }

            if(isOpen) {
                fetch(confs.url + 'fetch/surveysWithMainId', {
                    method: 'POST',
                    body: JSON.stringify(payload)
                }).then(response => response.json())
                .then(res => {
                    setAvailableSurveys(res);
                }).catch(error => {
                    NotificationManager.error("Valitettavasti lähetettyjä kartoituksia ei voitu hakea", "", 2000);
                })
            }
        }
    }, [selectedMainSurvey])

    useEffect(() => {
        if(startSave) {
            let selectedReports = [];
            selectedSubSurveys.map((item, x) => {
                selectedReports.push({reportId: item.value});
            })
            
            let surveyCard = {
                mainId: selectedMainSurvey.value,
                summaryName: summaryName,
                chartGoalValue: goalValue,
                businessId: targetCompany,
                reports: selectedReports
            }

            let payload = {
                jwtToken: window.sessionStorage.getItem("token"),
                surveyCard: surveyCard
            }

            if(isOpen) {
                fetch(confs.url + 'save/surveyCard', {
                    method: 'POST',
                    body: JSON.stringify(payload)
                }).then(response => response.json())
                .then(res => {
                    setSummaryCards(res);
                    setStartSave(false);
                    toggle();
                }).catch(error => {
                    NotificationManager.error("Valitettavasti seurannan luonti epäonnistui", "", 2000);
                })
            }
        } 
    }, [startSave])

    useEffect(() => {
        setModalOpen(startCard);
    }, [startCard])

    return (
      <div>
        <Modal isOpen={isOpen} toggle={toggle} className={className} size="lg">
          <ModalHeader toggle={toggle} style={{paddingLeft: '2.5em'}}>Seurantakortin luonti</ModalHeader>
          <ModalBody>
            <Row style={{paddingLeft: '1.5em', paddingRight: '1.5em'}}>
                <Col xl="12" style={{paddingBottom: '1em'}}>
                    <p>Anna seurantakortille nimi. Kun olet valinnut pääkartoituksen, esitetään seurattavat kartoitukset. Mukaan on otettava vähintään kaksi eri kartoitusta.</p>
                </Col>
            </Row>
            <Row style={{paddingLeft: '1.5em', paddingRight: '1.5em'}}>
                <Col xl="12" style={{paddingBottom: '1em'}}>
                    <label>Seurantakortin nimi</label>
                    <input type="text" onChange={(e) => setSummaryName(e.target.value)} style={{width: '100%'}}/> 
                </Col>
                <Col xl="12" style={{paddingBottom: '1em'}}>
                    <label>Valitse pääkartoitus</label>
                    <Select
                        value={selectedMainSurvey}
                        onChange={item => setselectedMainSurvey(item) }
                        options={ mainSurveys.filter(x => x !== selectedMainSurvey) }
                        placeholder="Valitse pääkartoitus"
                        noOptionsMessage={() => {return "Kartoituksia ei löytynyt"}}
                        className="formula-question-select"
                    />
                </Col>
                {selectedMainSurvey !== null &&
                    <Col xl="12" style={{paddingBottom: '1em'}}>
                        <label>Valitse vähintään kaksi seurattavaa kartoitusta</label>
                        <Select
                            value={selectedSubSurvey}
                            onChange={item =>  setSelectedSubSurveys(handleSubSurveys(selectedSubSurveys, item)) }
                            options={ availableSurveys.filter(x => !selectedSubSurveys.includes(x)) }
                            placeholder="Valitse aktivoitu kartoitus"
                            noOptionsMessage={() => {return "Kartoituksia ei löytynyt"}}
                            className="formula-question-select"
                        />
                    </Col>
                }
                {selectedSubSurveys.length > 0 &&
                    <Col xl="12">
                        <label>Valitut aktivoidut kartoitukset</label>
                        {selectedSubSurveys.map((item, x) => 
                            <div key={item.value} onClick={() => setSelectedSubSurveys(selectedSubSurveys.filter(x => x !== item))}>
                                <Button className="button-subSurvey">{item.label}<i className="far fa-list-alt" style={{fontSize: '1.3em'}}></i></Button>
                            </div>
                        )}
                    </Col>
                }
                {selectedSubSurveys.length >= 2 &&
                    <Col xl="12">
                        <label>Tavoitearvo</label>
                        <GoalCreator setGoalValue={setGoalValue} goalValue={goalValue} finalChange={() => {return true}}/>
                    </Col>
                }
            </Row>
          </ModalBody>
          <ModalFooter style={{display: 'flex', justifyContent: 'space-between'}}>
            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
            {selectedSubSurveys.length >= 2 && <button className="small-white-button wide-button" onClick={() => setStartSave(true)}>Aloita seuranta</button>}
          </ModalFooter>
        </Modal>
      </div>
    );
}

export default SummaryCreationModal;
import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars'
import {v4 as uuidv4 } from 'uuid'
import { fetchData } from '../../Clients/actions/ActionCreator';
import { Container, Row, Col, Input, Button, Table, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import {executeRequest} from '../../../Utilities/requestHandler/dataHandler'
import TextEditor from '../../../Utilities/TextEditor/texteditor'
import Select from 'react-select';
import {useDispatch} from 'react-redux'
import { NotificationManager } from 'react-notifications'
import moment from 'moment';
import {DeleteModal} from '../Modals/ActionModals'
import {ListLoadingSpinner} from '../listTabs/ListLoadingSpinner'

const initialDeleteModalData = {
    modal: false, 
    target: {}
}

export const CustomBlockList = ({filter, toggleModal}) => {
    const [blocks, setBlocks] = useState([]);
    const [menuIndex, setMenuIndex] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCustomblockId, setSelectedCustomblockId] = useState(null)
    const [deleteModalData, setDeleteModalData] = useState(initialDeleteModalData);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const response = await executeRequest('plan/getCustomblocks', {}, dispatch)

            if(!!response) {
                if(response) setBlocks(response);
                setIsLoading(false)
            }
        }

        fetchData();
    }, [])

    useEffect(() => {
        if(!modalOpen && selectedCustomblockId !== null)
            setSelectedCustomblockId(null);
    }, [modalOpen, selectedCustomblockId])

    const editBlock = (selectedBlockId) => {
        setSelectedCustomblockId(selectedBlockId);
        setModalOpen(true);
    }

    const finalizeBlockEdit =  async (target) => {
        const response = await executeRequest('plan/edit/customblock', {customBlock: target}, dispatch)

        if(!!response) {
            if(response.status && response.customBlock) {
                setBlocks(blocks => blocks.map(block => {
                    if(block.id === target.id) {
                        return response.customBlock
                    }
                    return block
                }))
                setModalOpen(modalOpen => !modalOpen)
                NotificationManager.success("Tiedot päivitetty", "", 3000);
            }
        } else {
            NotificationManager.error("Tietoja ei voitu päivittää, yritä hetken päästä uudelleen.", "", 3000);
        }
    }

    const removeBlock = async () => {
        const {target} = deleteModalData

        if(target.id !== undefined) {
            const response = await executeRequest('plan/modify/customblock', {customblockId: deleteModalData.target.id}, dispatch)

            if(!!response) {
                if(response.status === 'deleted' && response.customblockId) {
                    setBlocks(blocks.filter(block => block.id !== response.customblockId))
                    setDeleteModalData(initialDeleteModalData)
                    NotificationManager.success("Päivitysmalli poistettu", "", 3000);
                }
            } else {
                NotificationManager.error("Päivitysmallit ei voitu poistaa, yritä hetken päästä uudelleen.", "", 3000);
            }
        }
    }

    return (
        <>
        {modalOpen && <CustomBlockEditorModal isOpen={modalOpen} toggleModal={() => setModalOpen(modalOpen => !modalOpen)} customblockId={selectedCustomblockId} onSave={finalizeBlockEdit} />}
        {deleteModalData.modal && 
            <DeleteModal 
                isOpen={deleteModalData.modal} 
                toggleModal={() => setDeleteModalData(initialDeleteModalData)} 
                handleDelete={removeBlock} title={deleteModalData.target.blockName ? deleteModalData.target.blockName : ""}
                targetText="päivitysmallin" 
            />
        }
        <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            style={{ width: "100%", height: '100%' }}
            className="custom-scrollbars"
        >
            {isLoading ? <ListLoadingSpinner /> : 
            blocks.map((data, y) => {
                const {blockName, createdBy, modifiedBy, modified} = data
                if ((data.blockName.toLowerCase().includes(filter.toLowerCase())) || (data.createdBy !== undefined && data.createdBy.toLowerCase().includes(filter.toLowerCase()))) {
                    let oddEvenClass = y % 2 === 0 ? 'even-row' : 'odd-row'
                    return <React.Fragment key={y}>
                                <div className={oddEvenClass + ' selectable-row list-row'} style={{ paddingLeft: '1rem' }} key={y} onClick={() => editBlock(data.id)}>
                                    <Row className=''>
                                        <Col xs={{ size: 6 }}>
                                            <p title={blockName}>{blockName}</p>
                                        </Col>
                                        <Col xs={{ size: 3 }}>
                                            <p>{createdBy !== undefined && createdBy}</p>
                                        </Col>
                                        <Col xs={{ size: 2 }}>
                                            <button className="small-white-button lighter-text wide-button">Muokkaa</button>
                                        </Col>
                                        <Col xs={{ size: 1 }}>
                                            <Dropdown isOpen={menuIndex === y} style={{ height: '100%', width: '100%' }} onClick={(event) => event.stopPropagation()} toggle={() => setMenuIndex(prevMenu => prevMenu !== null ? null : y)}>
                                                <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                    <i className="fas fa-bars" title="Valikko"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => setDeleteModalData({modal: true, target: data})} >Poista</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        <Col xs={{ size: 12 }}>
                                            <p>{modifiedBy}</p>
                                            <p>{moment.utc(modified, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </React.Fragment>
                }
                return null
            })}                     
        </Scrollbars>
        </>
    )
}

const CustomBlockEditorModal = ({history, isOpen, customblockId, toggleModal, onSave}) => {
    const [customItem, setCustomItem] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [nestedModal, setNestedModal] = useState(false);
    const [action, setAction] = useState('');
    const [confirmed, setConfirmed] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const response = await executeRequest('plan/get/customblock', {customblockId: customblockId}, dispatch)

            if(!!response) {
                if(response.customItem) setCustomItem(response.customItem);
                setIsLoading(false)
            }
        }

        fetchData();
    }, [])

    const confirmAction = (action = 'close') => {
        setNestedModal(true);
    }

    const toggleNestedModal = () => {
        setNestedModal(nestedModal => !nestedModal);
        setAction('');
    }

    return (
        <Modal isOpen={isOpen} toggle={() => confirmAction('close')} centered size="lg">
            <ModalHeader>Päivitysmallin sisältö</ModalHeader>
            <ModalBody>
                <div>
                    <h4>HUOM!</h4>
                    <p>Tehdyt muutokset astuvat välittömästi voimaan kaikkiin lomakkeisiin, joihin tämä päivitysmalli on liitettynä.</p>
                    <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '1em'}}>
                        <div>
                            <h5>Luotu</h5>
                            <p>{customItem.createdBy}</p>
                            <p>{moment.utc(customItem.created, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')}</p>
                        </div>
                        <div>
                            <h5>Muokattu</h5>
                            <p>{customItem.modifiedBy}</p>
                            <p>{moment.utc(customItem.modified, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')}</p>
                        </div>
                    </div>
                </div>
                {isLoading ? <ListLoadingSpinner /> : 
                <Row style={{paddingTop: '2em'}}>
                    <CustomTextBlock item={customItem} setItem={setCustomItem} blockId={customblockId} />
                    <Col xl="12" style={{paddingTop: '1em'}}>
                            <label>
                                <h5 style={{ fontWeight: 700 }}>Päivitysmallin nimi</h5>
                            </label>
                            <Input 
                                type="text" 
                                className="plan-name-input" 
                                name="copy-plan-name" 
                                value={customItem.blockName} 
                                onChange={(e) => setCustomItem({...customItem, blockName: e.target.value})}></Input>
                    </Col>
                </Row>}
                <Modal isOpen={nestedModal} toggle={toggleNestedModal} centered>
                    <ModalHeader>Valmista?</ModalHeader>
                    <ModalBody>Varmista että haluat sulkea ikkunan</ModalBody>
                    <ModalFooter style={{display: 'flex', justifyContent: 'space-between'}}>
                        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggleNestedModal}>Peruuta</a>
                        <button className="small-white-button wide-button" onClick={toggleModal}>Valmis</button>
                    </ModalFooter>
                </Modal>
            </ModalBody>
            <ModalFooter style={{display: 'flex', justifyContent: 'space-between'}}>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggleModal}>Peruuta</a>
                <button className="small-white-button  wide-button" onClick={() => onSave(customItem)}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}
export const CustomBlockEditor = ({history, newBlock = true}) => {
    const [type, setType] = useState("text-block")
    const [blockName, setBlockName] = useState("");
    const dispatch = useDispatch();

    const [item, setItem] = useState({
        id: uuidv4(),
        topicContent: "",
        textContent: ""
    })
    
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            const response = await executeRequest('get/customblock', {}, dispatch)

            if(!!response) {
                if(response.customItem) setItem(response.customItem);
                setIsLoading(false)
            }
        }

        if(!newBlock) {
            fetchData();
        } else {
            setIsLoading(false);
        }
    }, [])

    const createCustomblock = async () => {
        const response = await executeRequest('plan/createCustomblock', {customBlock: {...item, type: type, blockName: blockName}}, dispatch);

        if(!!response) {
            if(response.status === 'created')
                history.push(
                    {
                        pathname: '/lomakkeet',
                        state: {activeTab: 'blocks'}
                    }
                )
            else
                NotificationManager.error("Päivitysmallia ei saatu luotua, yritä hetken päästä uudelleen", 3000);
        }
    }

    return (
        <Container fluid>
            <div className="whitebox" style={{ marginTop: '1em', height: 'auto' }}>
                <div className="newPlanTarget-div">
                    <Row style={{ width: '60%' }}>
                        <Col xl="12" style={{ marginBottom: '1em'}}>
                            <div>
                                <h4>Uusi päivitysmalli</h4>
                                <p>Kun päivitysmalli on luotu ja liitetty lomakkeeseen, sitä muokattaessa se päivittyy automaattisesti kaikkiin lomakkeisiin joihin se on liitettynä.</p>
                            </div>
                        </Col>
                        <Col xl="12" style={{ margingBottom: '1em' }}>
                            {/* <button className={type === 'text-block' ? "plan-type-button plan-type-selected" : "plan-type-button"} onClick={() => setType('text-block')}>Tekstilohko</button> */}
                            {/*<button className={type === 'tables' ? "plan-type-button plan-type-selected" : "plan-type-button"} onClick={() => setType('tables')}>Taulukko</button>*/}
                        </Col>
                        {
                        isLoading ? <ListLoadingSpinner /> :
                            type === 'text-block' ?
                                <CustomTextBlock dispatch={dispatch} item={ item } setItem={ setItem } blockId={"anon"}/>
                                :
                                <CustomTableBlock dispatch={dispatch} />
                            
                        }
                        <Col xl="12">
                            <label>
                                <h4 style={{ fontWeight: 700 }}>Anna päivitysmallille nimi</h4>
                            </label>
                            <Input type="text" className="plan-name-input" name="copy-plan-name" value={blockName} onChange={(e) => setBlockName(e.target.value)}></Input>
                        </Col>
                        <Col xl="12" style={{ paddingTop: 5 }}>
                            <Button className="small-white-button wide-button createPlan" style={{width: '100%', backgroundColor: '#B5E413', color: '#FFF' }}>
                                <h4 style={{ fontWeight: 700, paddingTop: '0.5rem' }} onClick={createCustomblock}>Luo päivitysmalli</h4>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export const CustomTextBlock = ({item, setItem, blockId}) => {

    return (
        <Col xl="12">
            <div><p>Määrittele otsikko ja sisältö</p></div>
            <div style={{marginBottom: 8}} className="title-col">
                    <TextEditor 
                        editorId={`${item.id}-subtitle`} 
                        content={item.topicContent} 
                        onTextChange={editorState => setItem({...item, topicContent: editorState})}
                        plaintextEditor={true}
                        additionalClass="custom-block-editor"
                    /> 
            </div>
            <div style={{paddingTop: '0.8em'}} className="plan-text-col">
                    <TextEditor 
                        editorId={`${item.id}-text-content`} 
                        content={item.textContent} 
                        onTextChange={editorState => setItem({...item, textContent: editorState})}
                        additionalClass="custom-block-editor"
                        connectionType={"customBlocks"}
                        documentId={blockId}
                    />
            </div>
        </Col>
    )
}


const rowSelections = [...Array(10).keys()].map(key => {
    return {
        value: key + 1,
        label: key + 1
    }
})

const colSelections = [...Array(4).keys()].map(key => {
    return {
        value: key + 1,
        label: key + 1
    }
})

const MegaLoaderSpinner = () => {
    return (
        <Row className="loadingSpinnerRow">
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center'}}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const CustomTableBlock = ({customTextblockId, newBlock = true, dispatch}) => {
    const [isLoading, setLoading] = React.useState(false);
    const [closeAll, setCloseAll] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [numberOfRows, setNumberOfRows] = React.useState({value: 1, label: 1});
    const [numberOfColumns, setNumberOfColumns] = React.useState({value: 1, label: 1})
    const [editRow, setEditRow] = React.useState({});

    const [tableConfiguration, setTableConfiguration] = React.useState({
        selectedNumberOfCols: 1,
        rows: [
            {
                id: uuidv4(),
                cols: [
                    {
                        id: uuidv4(),
                        text: '-'
                    }
                ]
            }
        ]
    })

    useEffect(() => {
        if(!newBlock && customTextblockId !== undefined) {

        }
    }, [])

    const tableConfigurationHandler = (type, incomingValues) => {
        let configuration = {
            ...tableConfiguration
        }

        switch(type) {
            case 'rows':
                let colValue = numberOfColumns.value;

                configuration = {
                    ...configuration,
                    rows: [...Array(incomingValues.value).keys()].map(key => {
                        
                        return {
                            id: uuidv4(),
                            cols: 
                                [...Array(colValue).keys()].map(col => {
                                    return {
                                        text: '-',
                                        id: uuidv4()
                                    }
                                })
                            
                        }
                    })
                }
                setNumberOfRows(incomingValues)
                break;
            case 'columns':
                let rowValue = numberOfRows.value

                configuration = {
                    ...configuration,
                    selectedNumberOfCols: incomingValues.value,
                    rows: [...Array(rowValue).keys()].map(key => {
                        return {
                            id: uuidv4(),
                            cols:
                                [...Array(incomingValues.value).keys()].map(col => {
                                    return {
                                        text: '-',
                                        id: uuidv4()
                                    }
                                })
                            
                        }
                    })
                }
                setNumberOfColumns(incomingValues)
                break;
            default:
                break;
        }

        setTableConfiguration(configuration);
    }

    const toggleNested = (index = null) => {
        if(index !== null) setEditRow({rowData: tableConfiguration.rows[index], editIndex: index});
        else setEditRow({});

        setNestedModal(!nestedModal);
        setCloseAll(false);
    }

    const toggleAll = () => {
        setNestedModal(!nestedModal);
        setCloseAll(true);
    }

    const finalizeCreateTableItem = () => {
        //onItemChange(itemPayload);
        let payload = {
            ...tableConfiguration,
        }

        //createTableItem(payload, item.destinationIndex, item.destClone, item.planId);
        
        //clearItems();
    
        toggleAll(true);
    }

    const handleRowEdit = (row, index) => {
        
        let configuration = {
            ...tableConfiguration,
        }

        if(row.cols.length > configuration.selectedNumberOfCols) {
            configuration = {
                ...configuration,
                selectedNumberOfCols: row.cols.length
            }
        }

        configuration.rows.splice(index, 1, row)
        setTableConfiguration(configuration);
        toggleNested();
    }

    return (
        <Col xl="12">
            {Object.keys(editRow).length > 0 && <RowEditModal nestedModal={nestedModal} toggleNested={toggleNested} closeAll={closeAll} editRow={editRow} saveRowSelection={handleRowEdit}/>}
            <div style={{display: 'flex', flexDirection: 'row', marginBottom: '2em'}}>
                <div style={{flex: '1', marginRight: '2em'}}>
                    <label htmlFor="rowSelector">Rivit</label>
                    <Select 
                        id="rowSelector" 
                        options={rowSelections}
                        value={numberOfRows}
                        onChange={value => tableConfigurationHandler('rows', value)}
                    />
                </div>
                <div style={{flex: '1'}}>
                    <label htmlFor="rowColumns">Sarakkeet</label>
                    <Select 
                        id="rowColumns" 
                        options={colSelections}
                        value={numberOfColumns}
                        onChange={value => tableConfigurationHandler('columns', value)}
                    />
                </div>
            </div>
            <Table bordered responsive hover>
                <thead>
                    
                </thead>
                <tbody>
                    {tableConfiguration.rows.map((row, index) => 
                        <tr onClick={() => toggleNested(index)} key={index}>
                            {row.cols.map((col, index) => {
                                return <TableElement count={row.cols.length} col={col} index={index} maximumCount={tableConfiguration.selectedNumberOfCols} key={col.id}/> 
                            })}
                        </tr>
                    )}
                </tbody>
            </Table>
        </Col>
    )
}

const TableElement = ({count, col, index, maximumCount}) => {

    if(count === 1) {
        return (
            <td key={col.id} colSpan={maximumCount}>{col.text}</td> 
        )
    } else {
        return (
            <td key={col.id} colSpan={(maximumCount % count === 1 && index + 1 === count) ? maximumCount / count + 1 : maximumCount / count}>{col.text}</td> 
        )
    }
}

{/*const TableModifierModal = ({open, toggle, item}) => {
    const {rows} = item
    const [closeAll, setCloseAll] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [numberOfRows, setNumberOfRows] = React.useState(rows.length);
    const [numberOfColumns, setNumberOfColumns] = React.useState(rows.length);
    const [editRow, setEditRow] = React.useState({});

    return (
        <Modal isOpen={open} toggle={toggle} centered size="xl">
            <ModalHeader toggle={toggle} className="taskModal">
                Taulukon määrittelyt
            </ModalHeader>
            <ModalBody>
                {editRow && <RowEditModal nestedModal={nestedModal} toggleNested={toggleNested} closeAll={closeAll} toggle={toggle} editRow={editRow} />}
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: '2em'}}>
                    <div style={{flex: '1', marginRight: '2em'}}>
                        <label htmlFor="rowSelector">Rivit</label>
                        <Select 
                            id="rowSelector" 
                            options={rowSelections}
                            value={numberOfRows}
                            onChange={value => tableConfigurationHandler('rows', value)}
                        />
                    </div>
                    <div style={{flex: '1'}}>
                        <label htmlFor="rowColumns">Kolumnit</label>
                        <Select 
                            id="rowColumns" 
                            options={colSelections}
                            value={numberOfColumns}
                            onChange={value => tableConfigurationHandler('columns', value)}
                        />
                    </div>
                </div>
                <Table bordered responsive hover>
                    <thead>
                        
                    </thead>
                    <tbody>
                        {tableConfiguration.rows.map((row, index) => 
                            <tr onClick={() => toggleNested(index)} key={index}>
                                {row.cols.map((col, index) =>
                                    <th key={index * Math.random(10)}>{col.text}</th> 
                                )}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <button className="small-white-button lighter-text wide-button" onClick={toggle}>Takaisin</button>
                <button className="small-white-button lighter-text wide-button" onClick={finalizeCreateTableItem}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}*/}

const RowEditModal = ({nestedModal, toggleNested, closeAll, toggle, editRow, saveRowSelection}) => {
    const { rowData, editIndex } = editRow

    const [columnCount, setColumnCount] = useState(rowData !== undefined && rowData.cols.length);

    return (
        <Modal isOpen={nestedModal} toggle={toggleNested} onClosed={closeAll ? toggle : undefined} centered>
            <ModalHeader>Rivin asetukset</ModalHeader>
            <ModalBody>
                <div style={{paddingBottom: '2em'}}>
                    <label htmlFor="columnCount">Sarakkeiden määrä</label>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <i className="fas fa-minus" onClick={() => {if(columnCount > 1) setColumnCount(columnCount - 1)}} style={{
                            alignSelf: 'center',
                            paddingRight: '1.5em',
                            fontSize: '1.8em',
                            cursor: 'pointer'
                        }}></i>
                        <h5>{columnCount}</h5>
                        <i className="fas fa-plus" onClick={() => {if(columnCount < 4) setColumnCount(columnCount + 1)}} style={{
                            alignSelf: 'center',
                            paddingLeft: '1.5em',
                            fontSize: '1.8em',
                            cursor: 'pointer'
                        }}></i>
                    </div>
                </div>
                <div>
                    <Table bordered responsive>
                        <thead>
                            
                        </thead>
                        <tbody>
                            <tr>
                                {[...Array(columnCount).keys()].map((col, index) =>
                                    <td key={index * Math.random(10)}>-</td> 
                                )}
                            </tr>
                        </tbody>
                    </Table>
                </div>
                </ModalBody>
            <ModalFooter>
              <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggleNested}>Peruuta</a>{' '}
              <Button color="secondary" onClick={() => saveRowSelection(
                  {
                    id: uuidv4(), 
                    cols:[...Array(columnCount).keys()].map(col => {
                      return {
                        text: '-',
                        id: uuidv4()
                      }
                  })},
                  editIndex
              )}>Tallenna</Button>
            </ModalFooter>
        </Modal>
    )
}
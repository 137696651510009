import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Col, Row, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import ColorPicker from '../../Kartoitus/KartoitusBuilder/kartoitukset/builder/TypeComponents/questiongroup/subComponents/ColorPicker'
import { debounce } from 'debounce'
import Select from 'react-select'
import Popup from "reactjs-popup";
import { NotificationManager } from 'react-notifications'
import DebounceTextEditor from '../../../Utilities/TextEditor/DebounceTextEditor'

const displayOptions = [
    { value: null, label: 'Näytä kaikissa' },
    { value: 'hideTablet', label: 'Piilota tableteilla' },
    { value: 'hideMobile', label: 'Piilota puhelimilla' }
]
export default class ModifyEmbed extends Component {
    constructor(props) {
        super(props)

        this.state = {
            embed: null,
            activeTab: "survey",
            copied: false,
            activeEditor: 'desktop'
        }
    }

    componentDidMount() {
        if (this.props.embed) {
            this.setState({ embed: this.props.embed })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.embed !== this.props.embed) {
            this.setState({ embed: this.props.embed, copied: false })
        }
    }

    send = debounce((payload) => {
        this.props.save(payload)
    }, 1000);

    handleField = (field, value) => {
        let obj = {
            ...this.state.embed
        };
        obj[field] = value;
        this.setState({ embed: obj });

        let item = { [field]: value };

        let payload = {
            id: this.state.embed.id,
            data: item
        }
        this.send(payload);
    }

    handleColorChange = (color, type) => {
        let item = { [type]: color }
        let payload = {
            type: this.state.activeTab,
            id: this.state.embed.id,
            data: {
                [this.state.activeTab]: item
            }
        }
        this.props.save(payload);
    }

    handleSurveyChange = (data) => {

        let type = "";
        if (data) {
            if (data.type.toLowerCase() === 'kartoitus') {
                type = 'survey'
                data.type = 'survey'
            } else {
                type = data.type
            }
        } else {
            type = this.state.activeTab
        }

        let payload = {
            id: this.state.embed.id,
            type: type,
            data: {
                [type]: data
            }
        }
        this.props.save(payload);
    }

    handlePositionChange = (type, pos) => {
        if (typeof this.state.embed[type] === 'undefined' || this.state.embed[type].position !== pos) {
            let payload = {
                id: this.state.embed.id,
                type: type,
                data: {
                    [type]: {
                        position: pos
                    }
                }
            }

            this.props.save(payload);
        }
    }

    getSelectedSurvey = () => {
        let surveys = this.props.sentSurveys.surveys;
        let cur = this.state.embed.survey;

        for (let i = 0; i < surveys.length; i++) {
            const element = surveys[i];
            if (element.name === cur.name) {

                return element;
            }
        }
        return null;
    }

    copyLink = () => {
        document.querySelector('.token').select();
        document.execCommand('copy');

        NotificationManager.success('Upote on kopioitu leikepöydällesi', null, 3000)
    }

    render() {
        let sentSurveys = this.props.sentSurveys;
        let embed = this.state.embed;
        return (
            <Row>
                <Col xs="12">
                    <div className="company-box">
                        <Row>
                            <Col>
                                <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                                    <p><b>Upotuksen tiedot</b></p>
                                    {embed && embed.count &&
                                        <div style={{ marginLeft: 'auto', marginRight: '2em' }}>
                                            <small>Upotus on ladattu {embed.count} kertaa.</small>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Scrollbars
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                    style={{ width: "100%", height: '700px', marginTop: '0.5rem' }} >
                                    {this.state.embed &&
                                        <div className="embed-edit-box">
                                            <Row style={{ marginBottom: '60px' }}>
                                                <Col xl="7" xs={{ size: 12, order: 2 }}>
                                                    <div className="embed-hostname-textbox">
                                                        <Label className="lighter-text" style={{ marginBottom: 0 }}>Käytettävä www-sivu</Label>
                                                        <Input className="input-bar" name="domain" type="text" style={{ width: "100%" }} value={(embed && embed.hostName) ? embed.hostName : ''} onChange={(e) => this.handleField("hostName", e.target.value)}></Input>
                                                    </div>
                                                    {this.props.embed.hostName &&
                                                        <div>
                                                            <Popup trigger={<button className="small-white-button lighter-text wide-button" style={{ marginTop: '1em' }}> Näytä HTML-koodi</button>} className="link" position="bottom left">
                                                                <div>
                                                                    <Label className="lighter-text" style={{ marginBottom: 0, marginTop: '1em' }}>Klikkaa kopioidaksesi upotteen</Label>
                                                                    <Input className="token"
                                                                        name="link"
                                                                        style={{ width: "100%", border: 'none', backgroundColor: 'transparent' }}
                                                                        value={embed ? '<script type="text/javascript" src="https://app.everbetter.fi/upotus/embed.js" token="' + embed.token + '" ></script>' : ''} readOnly
                                                                        onClick={() => document.queryCommandSupported('copy') && this.copyLink()}
                                                                        type="textarea"
                                                                    />
                                                                </div>
                                                            </Popup>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col xl="5" xs={{ size: 12, order: 1 }}>
                                                    <h3>Upotteen käyttäminen</h3>
                                                    <p>1. Klikkaa "Näytä HTML-koodi".</p>
                                                    <p>2. Kopio avautuvassa ruudussa näkyvä HTML-koodi. </p>
                                                    <p>3. Liitä koodi blogisi tai sivustosi HTML-koodiin.</p>
                                                </Col>
                                            </Row>
                                            {this.props.embed.hostName &&
                                                <div>
                                                    <Row>
                                                        <Col md="12">
                                                            <Row>
                                                                <Col xs="12">
                                                                    <Row>
                                                                        <Col xs="12">
                                                                            <Row>
                                                                                <Nav tabs style={{ width: '100%' }}>
                                                                                    <Col style={{ padding: 0 }}>
                                                                                        <NavItem style={{ width: '100%' }}>
                                                                                            <NavLink
                                                                                                className={this.state.activeTab === 'survey' ? 'active' : ''}
                                                                                                onClick={() => { this.setState({ activeTab: 'survey' }) }}
                                                                                            >
                                                                                                Kartoitus
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                    </Col>
                                                                                    <Col style={{ padding: 0 }}>
                                                                                        <NavItem style={{ width: '100%' }} >
                                                                                            <NavLink
                                                                                                className={this.state.activeTab === 'dialog' ? 'active' : ''}
                                                                                                onClick={() => { this.setState({ activeTab: 'dialog' }) }}
                                                                                            >
                                                                                                Dialog
                                                                                    </NavLink>
                                                                                        </NavItem>
                                                                                    </Col>
                                                                                    <Col style={{ padding: 0 }}>
                                                                                        <NavItem style={{ width: '100%' }}>
                                                                                            <NavLink
                                                                                                className={this.state.activeTab === 'feedback' ? 'active' : ''}
                                                                                                onClick={() => { this.setState({ activeTab: 'feedback' }) }}
                                                                                            >
                                                                                                Feedback
                                                                                    </NavLink>
                                                                                        </NavItem>
                                                                                    </Col>
                                                                                </Nav>
                                                                            </Row>
                                                                            <TabContent activeTab={this.state.activeTab}>
                                                                                <TabPane tabId="survey">
                                                                                    <Row>
                                                                                        <Col xs="12" xl="7">
                                                                                            <Row>
                                                                                                <Col xs="2">
                                                                                                    <Label className="lighter-text embed-label" >Taustaväri</Label>
                                                                                                    <ColorPicker handleColor={this.handleColorChange} index="backgroundColor" color={(embed.survey && embed.survey.backgroundColor) || '#FFF'} />
                                                                                                </Col>
                                                                                                <Col xs="2">
                                                                                                    <Label className="lighter-text embed-label" >Tekstin väri</Label>
                                                                                                    <ColorPicker handleColor={this.handleColorChange} index="fontColor" color={(embed.survey && embed.survey.fontColor) || '#000'} />
                                                                                                </Col>
                                                                                                <Col xs="4">
                                                                                                    <Label className="lighter-text embed-label">Näkyvyys</Label>
                                                                                                    <Select
                                                                                                        options={displayOptions}
                                                                                                        value={displayOptions.find(option => option.value === (embed.survey && embed.survey.display || null))}
                                                                                                        isClearable={false}
                                                                                                        onChange={(e) => this.handleSurveyChange({ display: e.value, type: 'survey' })}
                                                                                                        classNamePrefix="select"
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Label className="lighter-text">Aktivoitu kartoitus</Label>
                                                                                            <Select
                                                                                                options={sentSurveys ? sentSurveys.surveys : null}
                                                                                                getOptionLabel={option => `${option.name}`}
                                                                                                onChange={this.handleSurveyChange}
                                                                                                placeholder="Valitse lähetetty kartoitus"
                                                                                                isClearable={true}
                                                                                                value={sentSurveys && sentSurveys.surveys.find(option => option.name === (embed.survey && embed.survey.name))}
                                                                                                getOptionValue={(option) => option.name}
                                                                                                noOptionsMessage={() => 'Lähetettyjä kartoituksia ei löydy'}
                                                                                                className="survey-selector"
                                                                                                classNamePrefix="select"
                                                                                            />
                                                                                            <Nav tabs style={{ width: '100%', marginTop: '1em' }}>
                                                                                                <Col style={{ padding: 0 }}>
                                                                                                    <NavItem style={{ width: '100%' }}>
                                                                                                        <NavLink
                                                                                                            className={this.state.activeEditor === 'desktop' ? 'active' : ''}
                                                                                                            onClick={() => { this.setState({ activeEditor: 'desktop' }) }}
                                                                                                        >
                                                                                                            Teksti isolle näytölle
                                                                                                        </NavLink>
                                                                                                    </NavItem>
                                                                                                </Col>
                                                                                                <Col style={{ padding: 0 }}>
                                                                                                    <NavItem style={{ width: '100%' }} >
                                                                                                        <NavLink
                                                                                                            className={this.state.activeEditor === 'mobile' ? 'active' : ''}
                                                                                                            onClick={() => { this.setState({ activeEditor: 'mobile' }) }}
                                                                                                        >
                                                                                                            Teksti pienelle näytölle
                                                                                                        </NavLink>
                                                                                                    </NavItem>
                                                                                                </Col>

                                                                                            </Nav>
                                                                                            {this.state.activeEditor === 'desktop' ?
                                                                                                <DebounceTextEditor
                                                                                                    editorId={'survey-' + embed.id}
                                                                                                    content={(embed.survey && embed.survey.text) || ""}
                                                                                                    additionalClass="embed-text-area"
                                                                                                    handleSave={this.props.save}
                                                                                                    type="embed"
                                                                                                    embedType="survey"
                                                                                                    id={embed.id}
                                                                                                    plaintextEditor={false}
                                                                                                />
                                                                                                :
                                                                                                <DebounceTextEditor
                                                                                                    editorId={'survey-mobile-' + embed.id}
                                                                                                    content={(embed.survey && embed.survey.mobileText) || ""}
                                                                                                    additionalClass="embed-text-area"
                                                                                                    handleSave={this.props.save}
                                                                                                    type="embed-mobile"
                                                                                                    embedType="survey"
                                                                                                    id={embed.id}
                                                                                                    plaintextEditor={false}
                                                                                                />}
                                                                                        </Col>

                                                                                        <Col xs="7" xl="5">
                                                                                            <Label className="lighter-text">Upotteen asemointi</Label>
                                                                                            <div className="browser-box" style={{ position: 'relative', marginBottom: '1em' }}>
                                                                                                <i className={embed.survey && embed.survey.position === 'left' ? 'far fa-check-square left-square' : 'far fa-square left-square'}
                                                                                                    onClick={() => this.handlePositionChange('survey', 'left')}></i>
                                                                                                <i className={embed.survey && embed.survey.position === 'center' ? 'far fa-check-square center-square' : 'far fa-square center-square'}
                                                                                                    onClick={() => this.handlePositionChange('survey', 'center')}>
                                                                                                </i>
                                                                                                <i className={embed.survey && embed.survey.position === 'right' ? 'far fa-check-square right-square' : 'far fa-square right-square'}
                                                                                                    onClick={() => this.handlePositionChange('survey', 'right')}></i>
                                                                                            </div>
                                                                                            {/* <Label>Koko leveä</Label>
                                                                                            <div>
                                                                                                {embed && embed.survey && embed.survey.wideEmbed ?
                                                                                                    <button className="small-white-button" onClick={() => this.handleSurveyChange({ wideEmbed: false, type: 'survey' })}>Kyllä</button>
                                                                                                    :
                                                                                                    <button className="small-white-button" onClick={() => this.handleSurveyChange({ wideEmbed: true, type: 'survey' })}>Ei</button>
                                                                                                }
                                                                                            </div> */}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </TabPane>
                                                                                <TabPane tabId="dialog">
                                                                                    <Row>
                                                                                        <Col xs="12" xl="7">
                                                                                            <Row>
                                                                                                <Col xs="2">
                                                                                                    <Label className="lighter-text embed-label" >Taustaväri</Label>
                                                                                                    <ColorPicker handleColor={this.handleColorChange} index="backgroundColor" color={(embed.dialog && embed.dialog.backgroundColor) || '#FFF'} />
                                                                                                </Col>
                                                                                                <Col xs="2">
                                                                                                    <Label className="lighter-text embed-label" >Tekstin väri</Label>
                                                                                                    <ColorPicker handleColor={this.handleColorChange} index="fontColor" color={(embed.dialog && embed.dialog.fontColor) || '#000'} />
                                                                                                    {/* <button onClick={this.save}>xD</button> */}
                                                                                                </Col>
                                                                                                <Col xs="4">
                                                                                                    <Label className="lighter-text embed-label">Näkyvyys</Label>
                                                                                                    <Select
                                                                                                        options={displayOptions}
                                                                                                        value={displayOptions.find(option => option.value === (embed.dialog && embed.dialog.display || null))}
                                                                                                        isClearable={false}
                                                                                                        onChange={(e) => this.handleSurveyChange({ display: e.value, type: 'dialog' })}
                                                                                                        classNamePrefix="select"
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Label className="lighter-text">Aktivoitu dialogi</Label>
                                                                                            <Select
                                                                                                options={sentSurveys ? sentSurveys.dialogs : null}
                                                                                                getOptionLabel={option => `${option.name}`}
                                                                                                onChange={this.handleSurveyChange}
                                                                                                placeholder="Valitse lähetetty dialogi"
                                                                                                isClearable={true}
                                                                                                value={sentSurveys && sentSurveys.dialogs.find(option => option.name === (embed.dialog && embed.dialog.name))}
                                                                                                getOptionValue={(option) => option.name}
                                                                                                noOptionsMessage={() => 'Lähetettyjä kartoituksia ei löydy'}
                                                                                                classNamePrefix="select"
                                                                                            />
                                                                                            <Nav tabs style={{ width: '100%', marginTop: '1em' }}>
                                                                                                <Col style={{ padding: 0 }}>
                                                                                                    <NavItem style={{ width: '100%' }}>
                                                                                                        <NavLink
                                                                                                            className={this.state.activeEditor === 'desktop' ? 'active' : ''}
                                                                                                            onClick={() => { this.setState({ activeEditor: 'desktop' }) }}
                                                                                                        >
                                                                                                            Teksti isolle näytölle
                                                                                                        </NavLink>
                                                                                                    </NavItem>
                                                                                                </Col>
                                                                                                <Col style={{ padding: 0 }}>
                                                                                                    <NavItem style={{ width: '100%' }} >
                                                                                                        <NavLink
                                                                                                            className={this.state.activeEditor === 'mobile' ? 'active' : ''}
                                                                                                            onClick={() => { this.setState({ activeEditor: 'mobile' }) }}
                                                                                                        >
                                                                                                            Teksti pienelle näytölle
                                                                                                        </NavLink>
                                                                                                    </NavItem>
                                                                                                </Col>

                                                                                            </Nav>
                                                                                            {this.state.activeEditor === 'desktop' ?
                                                                                                <DebounceTextEditor
                                                                                                    editorId={'dialog-' + embed.id}
                                                                                                    content={(embed.dialog && embed.dialog.text) || ""}
                                                                                                    additionalClass="embed-text-area"
                                                                                                    handleSave={this.props.save}
                                                                                                    type="embed"
                                                                                                    embedType="dialog"
                                                                                                    id={embed.id}
                                                                                                    plaintextEditor={false}
                                                                                                />
                                                                                                :
                                                                                                <DebounceTextEditor
                                                                                                    editorId={'dialog-mobile-' + embed.id}
                                                                                                    content={(embed.dialog && embed.dialog.mobileText) || ""}
                                                                                                    additionalClass="embed-text-area"
                                                                                                    handleSave={this.props.save}
                                                                                                    type="embed-mobile"
                                                                                                    embedType="dialog"
                                                                                                    id={embed.id}
                                                                                                    plaintextEditor={false}
                                                                                                />
                                                                                            }
                                                                                        </Col>
                                                                                        <Col xl="5" xs="7" >
                                                                                            <Label className="lighter-text">Upotteen asemointi</Label>
                                                                                            <div className="browser-box" style={{ position: 'relative', marginBottom: '1em' }}>
                                                                                                <i className={embed.dialog && embed.dialog.position === 'left' ? 'far fa-check-square left-square' : 'far fa-square left-square'}
                                                                                                    onClick={() => this.handlePositionChange('dialog', 'left')}>
                                                                                                </i>
                                                                                                <i className={embed.dialog && embed.dialog.position === 'center' ? 'far fa-check-square center-square' : 'far fa-square center-square'}
                                                                                                    onClick={() => this.handlePositionChange('dialog', 'center')}>
                                                                                                </i>

                                                                                                <i className={embed.dialog && embed.dialog.position === 'right' ? 'far fa-check-square right-square' : 'far fa-square right-square'}
                                                                                                    onClick={() => this.handlePositionChange('dialog', 'right')}></i>
                                                                                                <i className={embed.dialog && embed.dialog.position === 'inpage' ? 'far fa-check-square inpage-square' : 'far fa-square inpage-square'}
                                                                                                    onClick={() => this.handlePositionChange('dialog', 'inpage')}></i>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </TabPane>
                                                                                <TabPane tabId="feedback">
                                                                                    <Row>
                                                                                        <Col xl="7" xs="12">
                                                                                            <Row>
                                                                                                <Col xs="2">
                                                                                                    <Label className="lighter-text embed-label" >Taustaväri</Label>
                                                                                                    <ColorPicker handleColor={this.handleColorChange} index="backgroundColor" color={(embed.feedback && embed.feedback.backgroundColor) || '#FFF'} />
                                                                                                </Col>
                                                                                                <Col xs="2">
                                                                                                    <Label className="lighter-text embed-label" >Tekstin väri</Label>
                                                                                                    <ColorPicker handleColor={this.handleColorChange} index="fontColor" color={(embed.feedback && embed.feedback.fontColor) || '#000'} />
                                                                                                    {/* <button onClick={this.save}>xD</button> */}
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Label className="lighter-text">Aktivoitu feedback</Label>
                                                                                            <Select
                                                                                                options={sentSurveys ? sentSurveys.feedbacks : null}
                                                                                                getOptionLabel={option => `${option.name}`}
                                                                                                onChange={this.handleSurveyChange}
                                                                                                placeholder="Valitse lähetetty feedback"
                                                                                                isClearable={true}
                                                                                                value={sentSurveys && sentSurveys.feedbacks.find(option => option.name === (embed.feedback && embed.feedback.name))}
                                                                                                getOptionValue={(option) => option.name}
                                                                                                noOptionsMessage={() => 'Lähetettyjä kartoituksia ei löydy'}
                                                                                                className="embed-fb-selector"
                                                                                                classNamePrefix="select"
                                                                                            />
                                                                                            {/* <Label className="lighter-text">Teksti</Label>
                                                                                            <DebounceTextEditor
                                                                                                editorId={'feedback-' + embed.id}
                                                                                                content={(embed.feedback && embed.feedback.text) || ""}
                                                                                                additionalClass="embed-text-area"
                                                                                                handleSave={this.props.save}
                                                                                                type="embed"
                                                                                                embedType="feedback"
                                                                                                id={embed.id}
                                                                                                plaintextEditor={true}
                                                                                            /> */}
                                                                                        </Col>
                                                                                        <Col xl="5" xs="7">
                                                                                            <Label className="lighter-text">Upotteen asemointi</Label>
                                                                                            <div className="browser-box" style={{ position: 'relative' }}>
                                                                                                <i className={embed.feedback && embed.feedback.position === 'left' ? 'far fa-check-square left-square sq-feedback' : 'far fa-square left-square sq-feedback'}
                                                                                                    onClick={() => this.handlePositionChange('feedback', 'left')}
                                                                                                ></i>
                                                                                                <i className={embed.feedback && embed.feedback.position === 'right' ? 'far fa-check-square right-square sq-feedback' : 'far fa-square right-square sq-feedback'}
                                                                                                    onClick={() => this.handlePositionChange('feedback', 'right')}></i>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>

                                                                                </TabPane>
                                                                            </TabContent>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </div>
                                    }
                                </Scrollbars>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row >
        )
    }
}


import React from 'react';
import {Button} from 'reactstrap';

const AddButton = props => {
    return (
        <Button className="newQuestion" block onClick={() => props.setQuestionData(props.group, {})}>
            {props.text}
        </Button>
    )
}

export default AddButton;
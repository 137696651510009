import * as ActionTypes from '../../Kartoitus/Sender/Actions/ActionTypes';
import * as ActionCreator from '../../../store/index';
import {COLLABORATION_COMPANY, FETCHING_DATA, REQUEST_STATUS, STRIPPED_LIST} from './ActionTypes';
import { confs } from '../../../modules/config';
import {NotificationManager} from 'react-notifications';

const permissionRequested = payload => {
    return {
        type: ActionTypes.REQUEST_PERMISSION,
        payload: payload
    }
};

const setCollaborations = collabs => {
    return {
        type: ActionTypes.SET_COLLABORATIONS,
        payload: collabs
    }
};

export const requestStatus = status => {
    return {
        type: REQUEST_STATUS,
        payload: status
    }
}

export const resetCollaborations = payload => {
    return {
        type: "RESET_COLLABORATIONS"
    }
}

export const modifyBusiness = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'modify/businessHandler', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(responseJson.success) {
                    NotificationManager.success("Yritys lisätty onnistuneesti", "Tallennettu", 2000);
                    dispatch(permissionRequested(responseJson.businessList));
                } else {
                    NotificationManager.error("Yrityksen luonnissa tapahtui virhe", "Virhe", 4000);
                }
            }
        }).catch(error => {
            ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
        })
    }
}

export const addBusinessUser = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'modify/addUser', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(responseJson.success) {
                    NotificationManager.success("Käyttäjä lisätty onnistuneesti", "Tallennettu", 2000);
                    dispatch(permissionRequested(responseJson.businessList));
                } else {
                    NotificationManager.error(responseJson.error, "Virhe", 4000);
                }
            }
        }).catch(error => {
            ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
        })
    }
}

const strippedList = data => {
    return {
        type: STRIPPED_LIST,
        payload: data
    }
}

export const requestPermission = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        dispatch(requestStatus("fetching"))
        fetch(confs.url + 'send/collaboration/permissionRequest', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(responseJson.status !== undefined) {

                    if(responseJson.status === "finished") {
                        dispatch(requestStatus(responseJson.status));
                        dispatch(setCollaborations( responseJson.collaborations ))
                        NotificationManager.success("Asiakas lisätty", "Tallennettu", 2000);
                    } else if(responseJson.status === "EXISTING_EMAILS") {
                        dispatch(requestStatus(responseJson.status));
                        dispatch(strippedList(responseJson.strippedList));
                        NotificationManager.error("Yhteistyöpyyntöä ei voida lähettää, koska henkilö on jo järjestelmässä. Listasta on automaattisesti poistettu kaikki ylimääräiset sähköpostiosoitteet, lähetä pyyntö uudelleen.", "Virhe", 7000);
                    }
                } else {
                    if(responseJson.errors !== undefined) {
                        if(responseJson.errors.status === "EXISTS") {
                            dispatch(requestStatus(responseJson.errors.status));
                            NotificationManager.error("Asiakkaan lisäys epäonnistui, asiakas on jo luotu", "Virhe", 4000);
                        } else if(responseJson.errors.status === "PENDING") {
                            dispatch(requestStatus(responseJson.errors.status));
                            NotificationManager.error("Yhteistyöpyyntö on jo lähetetty, odota yrityksen vastausta", "Virhe", 4000);
                        }
                    } else if(responseJson.collaborations !== undefined) {
                        dispatch(requestStatus("finished"));
                        dispatch(setCollaborations( responseJson.collaborations ))
                        NotificationManager.success("Asiakas luotu", "Tallennettu", 2000);
                    }
                }
            }
        }).catch(error => {
            ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
        })
    }
}

export const fetchCollaborations = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        setTimeout(() => {
            fetch(confs.url + 'get/collaboration/list', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
            .then((responseJson) => {
                if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if(responseJson.length > 0) {
                        dispatch(setCollaborations(responseJson));
                    }
                }
            })
        }, 30);
    }
}

export const endCollaboration = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    
    return dispatch => {
        fetch(confs.url + 'send/endCollaboration', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                dispatch(setCollaborations(responseJson));
            }
        }).catch(error => {
            ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
        })
    }
}

export const fetchCompanyInformation = (params, type = null) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    
    return dispatch => {
        dispatch(fetchData());
        fetch(confs.url + 'get/check', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if(responseJson.status === "no results") {
                    if(type !== null) {
                        NotificationManager.success("Tuloksia kyseisellä Y-tunnuksella ei löytynyt, syötä tiedot", "", 3000);
                    }
                    dispatch(fetchData());
                    dispatch(setCompanyInformation({
                        businessName: "",
                        postcode: "",
                        address: "",
                        emailList: [],
                        status: responseJson.status
                    }));
                } else {
                    if(type !== null) {
                        NotificationManager.success("Yrityksen tiedot haettu", "", 3000);
                    }
                    dispatch(fetchData());
                    dispatch(setCompanyInformation(responseJson));
                }
                
            }
        }).catch(error => {
            ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
        })
    }
}

export const cancelCollaborationRequest = payload => async dispatch => {
    let jwt = window.sessionStorage.getItem('token')

    let params = {
        jwtToken: jwt,
        ...payload
    }

    const responseJson = await fetch(confs.url + 'cancel/collaborationRequest', {
        method: 'POST',
        body: JSON.stringify( params )
    })

    const response = await responseJson.json()

    if ( response.success && response.collaborations ) {
        dispatch(setCollaborations( response.collaborations ))
        NotificationManager.success( 'Yhteistyöpyyntö peruutettu.', 'Ilmoitus', 4000 )
    } else {
        NotificationManager.error( 'Yhteistyöpyynnön peruuttaminen epäonnistui.', 'Ilmoitus', 4000 )
    }
}

export const fetchData = () => {
    return {
        type: FETCHING_DATA
    }
}

export const setCompanyInformation = params => {
    return {
        type: COLLABORATION_COMPANY,
        payload: params
    }
}

  
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Tour from 'reactour';

export const DashboardGuide = ({toggleTour, adminUser}) => {
    const [tourOpen, setTourOpen] = useState(false)
    const [steps, setSteps] = useState(null)
    const modulesInUse = useSelector(state => state.authentication.modulesInUse)

    useEffect(()=>{
        buildSteps()
    },[modulesInUse])

    useEffect(()=>{

        if(toggleTour && toggleTour !== tourOpen){
            setTourOpen(toggleTour)
        }
    },[toggleTour])

    const buildSteps = () => {
        
        let steps = [
            {
                id: adminUser?"Ohjeet ja lataukset" : "Ohjeet",
                selector: '#yhteenveto-info',
                content: 'Täältä voit katsoa videon uudelleen, sekä löydät ohjeita EverBetter-palvelun käyttöön.'+ (adminUser? 'Voit myös lisätä haluamasi lomakepohjat ja kartoituspohjat omaan käyttöympäristöösi': ''),
            }
        ]
       
        // steps.push({
        //     id: "Luo uusi -painike",
        //     selector: '#create-new-tourId',
        //     content: 'Täällä voit luoda uusia lomakkeita, luoda uuden projektin, lisätä asiakkaan tai aktivoida kartoituksia.'
        // })

        // steps.push({
        //     id: "Haku",
        //     selector: '#search-tourId',
        //     content: 'Voit hakea listoista muun muassa nimen, yrityksen nimen tai tagien perusteella.'
        // })

        // steps.push({
        //     id: "Suosikit",
        //     selector: '#favourites-tourId',
        //     content: 'Tästä näet suosikeiksi merkityt lomakkeet ja projektit.'
        // })

        // steps.push({
        //     id: "Yhteenvedon asetukset",
        //     selector: '#dashboard-options-tourId',
        //     content: 'Täällä voit määrittää mitkä listat näytetään yhteenvedossa.'
        // })

        // if (modulesInUse.planManagement) {
        //     steps.push({
        //         id: "Listanäkymä",
        //         selector: '#list-view-tourId',
        //         content: 'Voit vaihtaa listan näkymää kortti-listauksen ja perinteisen listauksen välillä.'
        //     })
        // }  
         
        // if ((modulesInUse.projectManagement || modulesInUse.taskManagement) && document.getElementById("filter-button-tourId")) {
        //     steps.push({
        //         id: "Suodatuspainike",
        //         selector: '#filter-button-tourId',
        //         content: 'Voit suodattaa listalla näytettäviä osumia valinnan mukaisesti'
        //     })
        // }     
       
        setSteps(steps)
    }
    return (
        <>
            {/* <div className="tour-button dashboard-button" style={{ width: '2em' }} onClick={() => setTourOpen(true)}><i className="fas fa-info-circle"></i></div> */}
            <Tour
                steps={steps}
                isOpen={tourOpen}
                rounded={4}
                onRequestClose={() => (setTourOpen(false))}
                badgeContent={(curr, total) => (steps && !Number.isNaN(parseInt(curr)) && steps[(parseInt(curr)-1)].id)? `${steps[(parseInt(curr)-1)].id}` : (parseInt(curr) + "/" + total)}   
                // startAt={0}
                closeButtonAriaLabel={"Sulje"}
                showCloseButton={false}
                // showButtons={false}
                showNavigation={false}
                lastStepNextButton={<div style={{ color:'#fff', fontWeight:'bold', backgroundColor:'#007aff', marginLeft:'-4em' }} className="small-white-button wide-button">Sulje</div>}
                prevButton={<div></div>}
            />
        </>
    )
}
import React, { useState } from 'react';
import {HubSpotContacts, HubSpotDeals, EmptyHubSpotContainer} from '../../../Utilities/horizontal-scroller/card-lists/Lists'
import {EditHubSpotModal} from '../../../Utilities/Modals/CreateHubSpotModal.js'
import {AnimatePresence} from 'framer-motion';

export const HubSpotContainer = ({hubSpot, updateBusinessValues, activeType, toggleType, business}) => {
    const { hubSpotId, modules, hubSpotCompanyId } = hubSpot
    const [openModal, setOpenModal] = useState(false);
    
    return (
        <>
        {openModal && <EditHubSpotModal hubSpot={hubSpot} isOpen={openModal} toggle={() => setOpenModal(!openModal)} />}       
            {hubSpotCompanyId && (modules.contacts || modules.deals) ? (
                <AnimatePresence initial={false}>
                    {(modules && !!modules.contacts) && <HubSpotContacts activeType={activeType.hubContacts} toggleType={toggleType} hubSpot={hubSpot} key="Contacts" openSettings={() => setOpenModal(!openModal)} business={business}/>}
                    {(modules && !!modules.deals) && <HubSpotDeals activeType={activeType.hubDeals} toggleType={toggleType} hubSpot={hubSpot} key="Deals" openSettings={() => setOpenModal(!openModal)} updateBusinessValues={ updateBusinessValues }/>}
                </AnimatePresence>
            ) : (
                <EmptyHubSpotContainer openSettings={() => setOpenModal(!openModal)}/>
            )}
        </>
    )
}
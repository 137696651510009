import React from 'react';

export const ListLoadingSpinner = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <div style={{ width: '5rem', height: '5rem', alignSelf: 'center'}} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
    )
}
import React, { useState, useRef, forwardRef } from 'react'
import {
  Row,
  Col,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap'


import TextEditor from '../../../../Utilities/TextEditor/texteditor';

import {confs} from '../../../../modules/config';
import {NotificationManager} from 'react-notifications';

import ViewCardModal from '../../../Reports/subComponents/Trello/ViewCardModal';
import moment from 'moment';

import { Rnd } from "react-rnd";
import {ResizedScrollBar} from './hocs/ResizedScrollBar';
import {ModifiedContainer} from '../DataContainers'

const MegaLoaderSpinner = () => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '5rem', height: '5rem', alignSelf: 'center'}} className="spinner-border text-primary" role="status" >
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

const emptyCard = {
    name: '',
    desc: '',
    labels: [],
    memberList: []
}


const TrelloBlock = ({item, itemId, businessId, preview = false, editable, promptSave, toggleDrag, toggleItemVisibility, onDelete, saveItemWithoutQueue, layoutPreview = false}) => {
    const isCancelled = useRef(false);
    const [ title , setTitle] = useState(item.topicContent);
    const [ data, setData] = useState({});
    const [ isLoading, setIsLoading ] = useState( false )
    const [ cardModalIsOpen, setCardModalIsOpen ] = useState( false )
    const [ selectedCard, setSelectedCard ] = useState( emptyCard )
    const [enableResize, setEnableResize] = useState(false);
    const [openMenu, toggleMenu] = useState(false);
    const [size, setSize] = useState(item.size);

    React.useEffect(() => {
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            businessId: businessId,
            id: itemId,
            type: 'trello'
        }
        setIsLoading(true);
        fetchData(payload);

        return () => isCancelled.current = true
    
    }, []);

    function handleDataChanges(type, data) {
        
        if(type === "title") {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                type: item.type
            }
            promptSave(itemPayload);
        }
        //onItemChange(itemPayload);
    }

    const fetchData = async payload => {
        

        try {
            const response = await fetch( confs.url + 'plan/embedItemData', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )

            if(response.success === undefined) {
                const responseJson = await response.json();
                
                if(!isCancelled.current)
                    setData(responseJson);
            }
        } catch(err) {
            NotificationManager.error("Valitettavasti lohkon tietojen haussa tapahtui virhe", "", 3000);
        }

        if(!isCancelled.current)
            setIsLoading(false);
    }

    const toggleCardmodal = () => {
        setCardModalIsOpen( !cardModalIsOpen )
    }

    const openCardModal = card => {
        if(!preview) {
            setSelectedCard( card )
            toggleCardmodal()
        }

        return;
    }

    const archiveCard = async payload => {
        let jwt = window.sessionStorage.getItem( "token" )
        payload.jwtToken = jwt

        const resultJson = await fetch( confs.url + 'archive/trelloCard', {
            method: 'POST',
            body: JSON.stringify( payload )
        } )

        const result = await resultJson.json()

        if ( typeof result.id !== 'undefined' ) {
            let filterOutCard = {
                ...data,
                cards: data.cards.filter(x => x.id !== result.id)
            }

            setData(filterOutCard);
            NotificationManager.success( 'Kortti arkistoitu', '', 4000 )
        }
    }

    const updateChecklistTrelloCard = payload => {
        let jwt = window.sessionStorage.getItem( "token" )
        payload.jwtToken = jwt
    
        return new Promise( async ( resolve ) => {
            const resultJson = await fetch( confs.url + 'modify/trellochecklist', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )
    
            const result = await resultJson.json()
    
            if ( typeof result.id !== 'undefined' ) {
                
                updateCardCheckList( { cardId: payload.cardId, checkItem: result } ) 
                NotificationManager.success( 'Tila vaihdettu', '', 4000 );
            }
    
            resolve( result )
        } )
    }

    const updateCardCheckList = ({cardId, checkItem}) => {
        
        const {cards} = data
        
        const newCardData = cards.map(card => {
            if(card.id === cardId) {
                const {checklists} = card
                
                const checkLists = checklists.map(checklist => {
                if(checklist.id === checkItem.idChecklist) {
                    const {checkItems} = checklist
                    
                    const newCheckItems = checkItems.map(item => {
                    if(item.id === checkItem.id) {
                        return checkItem
                    }
                    return item;
                    })

                    return {
                    ...checklist,
                    checkItems: newCheckItems
                    }
                }
                return checklist 
                })

                return {
                ...card,
                checklists: checkLists
                }
            }
            return card
        })

        let newData = {
            ...data,
            cards: [...newCardData]
        }

        setData(newData);
    }

    const saveTrelloBoard = () => {
        let itemPayload = {
            id: item.id,
            topicContent: title,
            size: size,
            type: item.type
        }

        saveItemWithoutQueue(itemPayload);
        setEnableResize(false);
        toggleDrag(true);
    }

    function toggleResize() {
        if(enableResize) {
            toggleDrag(true);
        } else {
            toggleDrag(false);
        }
        setEnableResize(!enableResize);
    }

    function handleResize(height, width) {
        setSize({height: height, width: width});
    }

    function handleDivSize(height, direction) {
        if(size.height !== height) {
            setSize({...size, height: height});
        }
    }

    const checkListNumbers = (checklists) => {
        let numberOfItems = 0;
        let completed = 0;
    
        if(checklists !== undefined) {
          const lists = checklists.map(checklist => {
            const {checkItems} = checklist
    
            checkItems.map(checkItem => {
              if(checkItem.state !== 'incomplete') {
                completed++;
              }
              return checkItem
            })
    
            numberOfItems += checkItems.length
            return checklist;
          })
    
          return {
            numberOfItems: numberOfItems,
            completed: completed
          }
        }
    
        return;
    }

    const style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "#f0f0f0"
    };

    return (
        <Row style={{paddingBottom: '1.75em', marginBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
            <Col xl="12">
                {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red'}}>Yksityinen</span>}
                {(!editable && !layoutPreview && item.modified !== undefined) && 
                    <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                }
            </Col>
            {!layoutPreview &&
                <Col xl="1" className="topic-col" style={{display: 'flex'}}>
                    <i className="fab fa-trello plan-input subTitle" style={{alignSelf: 'center'}}></i>
                </Col>
            }
            <Col xl="10" style={{marginBottom: 8}} className="title-col">
            {editable ? 
            <input className="plan-input subTitle" name="title" type="text" style={{ width: "100%" }} placeholder="Otsikko" value={(item && title) ? title : ''} onChange={(e) => handleDataChanges("title", e.target.value)}></input>
            :
                <TextEditor 
                    editorId={`${item.id}-subtitle-${editable}`} 
                    content={item.topicContent}
                    additionalClass="plan-input subTitle plan-subtitle"
                    readOnly={!editable}
                    placeholder="-"
                    plaintextEditor={true}
                />
            }
            </Col>
            <Col xl="1" style={{paddingLeft: 15, marginBottom: 8}} className="plan-dropdown-col">
                <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                    <DropdownToggle tag="div">
                        <i className="fas fa-bars plusIcon" title="Valikko"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                        <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                    </DropdownMenu>
                </Dropdown>}
                </div>
            </Col>
            {editable && 
                <>
                    <Col className="col-sm-12 col-md-5 offset-md-1 plan-dropdown-col">
                        <button className={enableResize ? "small-white-button lighter-text wide-button active" : "small-white-button lighter-text wide-button"} onClick={() => toggleResize()} style={{marginBottom: '0.8em'}}>Muuta kokoa</button>
                    </Col>
                    <Col className="col-sm-12 col-md-5 offset-md-1 plan-dropdown-col">
                        <button className="small-white-button lighter-text wide-button" onClick={() => saveTrelloBoard()} id={`${item.id}-saveButton`}>Tallenna</button>
                    </Col>
                </>
            }
            <Col className="col-sm-12 col-md-10 offset-md-1" style={{marginTop: '1.5em'}}>
            {!preview && <ViewCardModal isOpen={ cardModalIsOpen } toggle={ toggleCardmodal } card={ selectedCard } businessId={ businessId } archiveCard={ archiveCard } updateChecklistCard={updateChecklistTrelloCard} />}
            {enableResize ?
                <div style={{...size, maxWidth: '100%'}}>
                    <Rnd
                    size={size}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        handleResize(ref.style.height, ref.style.width)
                    }}
                    onResize={(e, direction, ref, delta, position) => {
                        handleDivSize(ref.style.height, direction)
                    }}
                    disableDragging
                    style={style}
                    maxWidth={'100%'}
                    enableResizing={ {top:false, right:true, bottom:true, left:true, topRight:false, bottomRight:true, bottomLeft:true, topLeft:false } }
                    >
                        <div className="dashboard-medium-box" style={{marginTop: 0, height: size.height, width: '100%', border: '1px solid black', paddingBottom: 2}}>
                            <ResizedScrollBar size={size} title={data.name}>
                                { data.cards && data.cards.filter( card => !card.closed ).map( ( card, index ) => {

                                let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'
                                let cardNumbers = checkListNumbers(card.checklists);
                                return (
                                <div 
                                key={ index } 
                                className={ "selectable-row " + oddEvenClass } 
                                style={!preview ? { padding: '1em' } :  { padding: '1em', cursor: 'default' } } 
                                onClick={ () => openCardModal( card ) }>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    { card.labels && card.labels.map( ( label, index ) => {
                                                    return (
                                                        <span key={ index } title={ label.name } className={ `trello-label trello-label-${ label.color }` }>
                                                            <span className='trello-label-text'>{ label.name }</span>
                                                        </span>
                                                    )
                                                    } ) }
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    { card.name }
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <div className="member-row">                   
                                                        <div>
                                                            {card.checklists && 
                                                            <>
                                                                <i className="far fa-check-square trello"></i>
                                                                <span className="checklist-numbers">{`${cardNumbers.completed}/${cardNumbers.numberOfItems}`}</span>
                                                            </>
                                                            }
                                                        </div>
                                                        <div>
                                                        { card.memberList.map( ( member, index ) => {
                                                            return <span key={ index } title={ member.fullName } className='trello-member'>{ member.initials }</span>
                                                        } ) }
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                )
                                } ) }
                            </ResizedScrollBar>
                        </div>
                    </Rnd>
                </div> 
            : <div className="dashboard-medium-box" style={{marginTop: 0, ...size}}>
                {isLoading ? 
                    <div style={{height: '100%', width: '100%'}}>
                        <MegaLoaderSpinner />
                    </div>
                : <ResizedScrollBar size={size} title={data.name}>
                        { data.cards && data.cards.filter( card => !card.closed ).map( ( card, index ) => {

                        let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'
                        let cardNumbers = checkListNumbers(card.checklists);
                        return (
                        <div key={ index } className={ "selectable-row " + oddEvenClass } style={!preview ? { padding: '1em' } :  { padding: '1em', cursor: 'default' } } onClick={ () => openCardModal( card ) }>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            { card.labels && card.labels.map( ( label, index ) => {
                                            return (
                                                <span key={ index } title={ label.name } className={ `trello-label trello-label-${ label.color }` }>
                                                    <span className='trello-label-text'>{ label.name }</span>
                                                </span>
                                            )
                                            } ) }
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            { card.name }
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="member-row">                   
                                                <div>
                                                    {card.checklists && 
                                                    <>
                                                        <i className="far fa-check-square trello"></i>
                                                        <span className="checklist-numbers">{`${cardNumbers.completed}/${cardNumbers.numberOfItems}`}</span>
                                                    </>
                                                    }
                                                </div>
                                                <div>
                                                { card.memberList.map( ( member, index ) => {
                                                    return <span key={ index } title={ member.fullName } className='trello-member'>{ member.initials }</span>
                                                } ) }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        )
                        } ) }
                    </ResizedScrollBar>
                }
                </div>
            }
            </Col>
        </Row>
    )
}

export default TrelloBlock


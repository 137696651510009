import React, {useState, useEffect} from 'react';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalFooter, ModalBody} from 'reactstrap';
import {executeRequest} from '../../../Utilities/requestHandler/dataHandler';
import {ModifiedContainer} from './DataContainers';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import { AttachmentCreationModal } from '../Modals/AttachmentCreationModal';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications'

export const AttachmentBlock = ({item,editable,onDelete,toggleItemVisibility, index, promptSave, saveFiles, onTemplateAdd, planId, layoutPreview = false}) => {
    const [openMenu, toggleMenu] = useState(false)
    const [title, setTitle] = useState(item.topicContent)
    const [files, setFiles] = useState(item.files? item.files : [])  
    const [selectedIndex, setSelectedIndex] = useState()
    const [selectedFile, setSelectedFile] = useState({})
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const [fileOptionsModal, setFileOptionsModal] = useState(false)

    const dispatch = useDispatch;

    const handleDownload = (file) => {
        setSelectedFile(file)
        setFileOptionsModal(true)
    }

    const downloadFile = async(file, preview=false) => {
        let payload = {
            fileId: file._id,
            jwtToken: window.sessionStorage.getItem("token")
        }

        const response = await executeRequest('plan/downloadFile', payload)
        if(response.data){
            try{
                var blob = new Blob([new Uint8Array(response.data.data)], { type: response.mimeType });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.name + "." + response.fileEnd;
        
                document.body.appendChild(link);
                if(preview){
                    window.open(link)
                }else{
                    link.click();
                }
        
                document.body.removeChild(link);

            } catch {}
        }       
    }

    const handleDataChanges = (data) => {
        setTitle(data)
        let itemPayload = {
            ...item,
            topicContent: data
        }
        promptSave(itemPayload)
    }

    const addFiles = (file) => {
        
        let tempFiles = files ?[...item.files, file] : [file]
        setFiles(tempFiles)

        let itemPayload = {
            ...item,
            files: tempFiles
        }
        saveFiles(itemPayload)
        setAddModalOpen(false)
    }

    const handleRemove = (index, file) => {
       setSelectedIndex(index)
       setSelectedFile(file)
       setRemoveModalOpen(true)
    }

    const removeSelection = (index, fileId) => {
        let tempFiles = files
        tempFiles.splice(index,1)
        setFiles(tempFiles)

        let itemPayload = {
            ...item,
            files: tempFiles
        }
        saveFiles(itemPayload)
        setRemoveModalOpen(false)
        setSelectedIndex(null)
        setSelectedFile({})
        removePlanIdFromFile(fileId)
    }

    const removePlanIdFromFile = async(fileId) => {
        let payload = {
            fileId: fileId,
            planId: planId,
            connectionType: "plans"
        }
        const response = executeRequest('file/removeIdFromFile', payload)
        if(response.success){
          NotificationManager.success('Tedosto poistettu suunnitelmasta', 'Ilmoitus', 3000)
        }
    }

    return (
        Object.keys(item).length > 0 ?
        <>
            <AttachmentCreationModal
                open={addModalOpen}
                toggle={setAddModalOpen}
                item={item}
                addFiles={addFiles}
                planId={planId}
            />
            <Modal isOpen={fileOptionsModal} toggle={()=> setFileOptionsModal(false)}>
                <ModalHeader toggle={()=> setFileOptionsModal(false)}> <p className="modal-name-overflow-handler" style={{ color: '#9a0707' }}>{selectedFile.fileName +"."+selectedFile.fileEnd}</p></ModalHeader>
                <ModalBody>
                    {selectedFile && (selectedFile.fileEnd === "jpg" || selectedFile.fileEnd === "jpeg" || selectedFile.fileEnd === "png" || selectedFile.fileEnd === "svg" || selectedFile.fileEnd === "bmp" || selectedFile.fileEnd === "pdf") &&
                        <button onClick={() => downloadFile(selectedFile, true)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>Esikatsele</button>
                    }   
                    <button onClick={() => downloadFile(selectedFile)} className="small-white-button swb-hover enterprise-styled-button" style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}>Lataa</button>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setFileOptionsModal(false)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                </ModalFooter>
            </Modal>
            {selectedFile &&
                <Modal isOpen={removeModalOpen} toggle={() => setRemoveModalOpen(!removeModalOpen)}>
                    <ModalHeader toggle={() =>setRemoveModalOpen(!removeModalOpen)}>
                        <p>Olet poistamassa tiedostoa {selectedFile.fileName} lomakkeesta!</p>
                    </ModalHeader>
                    <ModalBody>
                        <p>Oletko varma, että haluat poistaa tiedoston {selectedFile.fileName +"."+selectedFile.fileEnd}?</p>
                    </ModalBody>
                    <ModalFooter>
                        <a onClick={() => setRemoveModalOpen(!removeModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                        <button onClick={() => removeSelection(selectedIndex, selectedFile._id)} className="small-white-button wide-button swb-hover delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>
                    </ModalFooter>
                </Modal>
            }
            <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                <Col xl="12">
                    {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red'}}>Yksityinen</span>}
                    {(!editable && !layoutPreview && item.modified !== undefined) && 
                        <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                    }
                </Col>
                <Col xl="12" className="plan-text-col">
                    <div style={{ display: 'flex' }}>
                        {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}<i className="far fa-file-alt" style={{marginLeft: '0.5em' }}></i></div>}            
                        <TextEditor 
                                editorId={`${item.id}-${editable}`} 
                                content={title}
                                onTextChange={(data) => handleDataChanges(data)} 
                                additionalClass="plan-input subTitle"
                                placeholder="Otsikko"
                                readOnly={!editable}
                                plaintextEditor={true}
                        />
                    </div>
                </Col>
              
                 <Col xl="11" style={{paddingTop: '0.8em'}} className="plan-text-col">        
                    {files && files.map((file,key) => {
                        return(
                            <Row key={key} className="plan-attachment-row" >
                                <Col className="plan-attachment plan-attachment-row " onClick={() => handleDownload(file)}>
                                    <p className="planAttachment-name-overflow-handler" title={file.fileName +"."+file.fileEnd}>{file.fileName +"."+file.fileEnd}</p>
                                    {editable&&
                                        <div className="remove-planAttachment" onClick={(e) => (e.stopPropagation(), handleRemove(key, file))}>
                                            <i className="fas fa-times"></i>
                                        </div>
                                    }
                                </Col>
                                
                            </Row>
                        )
                    })}
                </Col>
                <Col xl="1" style={{paddingLeft: 15}} className="plan-dropdown-col">
                    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                    {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                        <DropdownToggle tag="div">
                            <i className="fas fa-bars plusIcon" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                            <DropdownItem onClick={() => setAddModalOpen(true)} className="delete-plan-item">Lisää liite</DropdownItem>
                            <DropdownItem onClick={() => onTemplateAdd({planItem: item})} className="add-template">Lisää pohjaksi</DropdownItem>
                            <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.blockName})} className="delete-plan-item">Poista</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                    </div>
                </Col>
            </Row>
            {editable && 
                <Row style={{ marginLeft: '0.25em', paddingBottom: "1em" }}>
                    <p style={{ cursor:'pointer' }} onClick={() => setAddModalOpen(true)}> <i className="fas fa-plus dash-plusIcon" style={{ backgroundColor: '#B5E413', color: '#fff' }}></i><span> Lisää liite</span></p> 
                </Row>
            }
       </> 
       :null
    )

}


import React from 'react';
import {Row, Col} from 'reactstrap';
import { Draggable } from 'react-beautiful-dnd';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import './item.css';

const PanelItem = (props) => {
    return (
        <Draggable draggableId={props.id} index={props.index} className="draggable-shadow">
            {(provided, snapshot) => (
                <>
                <div className="draggable-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    
                    style={
                        provided.draggableProps
                            .style
                    }
                >
                <Row style={{paddingBottom: '0.5em', paddingTop: '0.5em' }} >
                    <Col xl="12">
                        <h6 style={{fontWeight: 700, marginBottom: 0}}>{props.topicContent}</h6>
                    </Col>
                    {/* <Col xl="12">
                        <p className="block-with-text" title={props.textContent}>{props.textContent}</p>
                    </Col> */}
                </Row>   
                </div>
               </>
            )}
        </Draggable>
        
    )
}

export default PanelItem;
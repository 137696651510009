import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import StagingArea from '../Components/StagingArea/StagingArea';
import SideNav from '../Utilities/SideNav/SideNav'
import BusinessSideNav from '../Utilities/SideNav/BusinessSideNav'
import KartoitusBuilder from '../Components/Kartoitus/KartoitusBuilder/kartoitukset/builder/KartoitusBuilder';
import Dashboard from '../Components/Dashboard/Dashboard';
import Clients from '../Components/Clients/Clients';
import EnterpriseContainer from '../Components/Enterprise/EnterpriseContainer';
import BusinessContainer from '../Components/Business/BusinessContainer'
import Reports from '../Components/Reports/Reports'
import BusinessSelect from '../Components/Reports/subComponents/BusinessSelect'
import ReportDashboard from '../Components/Reports/subComponents/ReportDashboard'
import SenderContainer from '../Components/Kartoitus/SenderV2/SenderContainer';
import SurveyList from '../Components/Kartoitus/KartoitusBuilder/kartoitukset/builder/SurveyList';
import Breadcrumbs from '../Utilities/Breadcrumbs/Breadcrumbs'
import ComparisonReport from '../Components/Reports/subComponents/reportTypes/ComparisonReport';
import Embed from '../Components/Embed/Embed'
import PlanList from '../Components/planBuilder/PlanList';
import PlanbuilderContainer from '../Components/planBuilder/PlanbuiderContainer';
import NewPlanTarget from '../Components/planBuilder/NewPlanTarget';
import PlanSearch from '../Components/planBuilder/PlanSearch';
import * as ActionCreator from '../store/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { motion } from 'framer-motion'
import { confs } from '../modules/config';
import { startNewPlan } from '../Components/planBuilder/Actions/ActionCreator';
import TestingComponent from '../Utilities/HelperFunctions/TestingComponent';
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import GuideTour from '../Utilities/GuideTour/GuideTour'
import { CustomBlockEditor } from '../Components/planBuilder/customBlocks/CustomBlockElements';
import { ProjectContainer } from '../Components/Project/ProjectContainer';
import { HandleProject } from '../Components/Project/HandleProject';
import { ProductContainer } from '../Components/Product/ProductContainer';

import CustomStyleWrapper from '../hoc/CustomStyleWrapper';
import { Tools } from '../Components/OtherTools/Tools';
import { OpenAI } from '../Utilities/OpenAI/AIChat/OpenAI';

const StyleWrapper = CustomStyleWrapper(Dashboard);

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
}

const listOfSideTabs = [
    "/raportit/yritys",
    "/raportit/yritys/raportti",
    "/kartoitukset",
    "/kartoitukset/lahetys",
    "/kartoitukset/luonti",
    "/kartoitukset/aktivoi",
    "/kartoitukset/muokkaus",
    "/lomakkeet",
    "/lomakkeet/luonti",
    "/lomakkeet/uusi",
    "/lomakkeet/uusi/lohko",
    "/lomakkeet/hae"
]

const pathDependentModules = {
    "/raportit/yritys": 'surveyManagement',
    "/raportit/yritys/raportti": 'surveyManagement',
    "/kartoitukset": 'surveyManagement',
    "/kartoitukset/lahetys": 'surveyManagement',
    "/kartoitukset/luonti": 'surveyManagement',
    "/kartoitukset/aktivoi": 'surveyManagement',
    "/kartoitukset/muokkaus": 'surveyManagement',
    "/lomakkeet": 'planManagement',
    "/lomakkeet/luonti": 'planManagement',
    "/lomakkeet/uusi": 'planManagement',
    "/lomakkeet/uusi/lohko": 'planManagement',
    "/lomakkeet/hae": 'planManagement'
}

const PrivateRoute = ({ component: Component, isAuthenticated, panelOpen, togglePanel, ...rest }) => (
    <Route {...rest}
        render={props => (
            isAuthenticated === true
                ? <Component {...props} panelOpen={panelOpen} togglePanel={togglePanel}/>
                : <Redirect to='/login' />
        )}
    />
)

class Navigator extends Component {
    constructor() {
        super()

        this.state = {
            activeTab: 'yhteenveto',
            panelOpen: true,
            logoStyle: {},
            mobileMenuOpen: false
        }
    }

    componentDidMount() {
        if (this.props.logo) {
            this.setState({ logoStyle: { backgroundImage: `url(${confs.url + 'getLogo/' + this.props.logo + '?d=' + Date.now()})`, backgroundSize: 'contain', backgroundPosition: 'center', pointerEvents: 'none' }, logoId: this.props.logo });
        }

        if(this.props.history) {
            const {location} = this.props.history

            if(location.pathname)
              if(location.pathname.includes('/raportit/yritys') && this.state.panelOpen  ) {this.toggleSidePanels()}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.logo !== this.props.logo) {
            this.setState({ logoStyle: { backgroundImage: `url(${confs.url + 'getLogo/' + this.props.logo + '?d=' + Date.now()})`, backgroundSize: 'contain', backgroundPosition: 'center', pointerEvents: 'none' }, logoId: this.props.logo + '?d=' + Date.now() });
        }

        if (this.props.downloadNew) {
            if (this.props.logo) {
                this.setState({ logoStyle: { backgroundImage: `url(${confs.url + 'getLogo/' + this.props.logo + '?d=' + Date.now()})`, backgroundSize: 'contain', backgroundPosition: 'center', pointerEvents: 'none' }, logoId: this.props.logo + '?d=' + Date.now() });
            } else {
                this.setState({ logoStyle: {}, logoId: null })
            }
            this.props.doneLoading();
        }
    }

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab })
    }

    sidePanelOpen = (path) => {
        const { modulesInUse } = this.props

        if (modulesInUse && typeof pathDependentModules[path] !== 'undefined') {
            const dependentModule = pathDependentModules[path]

            return (modulesInUse[dependentModule] && listOfSideTabs.includes(path) && this.state.panelOpen)
        }
        return (listOfSideTabs.includes(path) && this.state.panelOpen)
    }

    toggleSidePanels = () => {
        this.setState({ panelOpen: !this.state.panelOpen });
    }

    navigateTo = (path) => {
        this.props.history.push(path)
    }

    toggleMobileMenu = () => {
        this.setState(state => ({ mobileMenuOpen: !state.mobileMenuOpen }))
    }

    render() {
        let pathname = this.props.location.pathname
        const { isAuthenticated, userType, modulesInUse } = this.props
        let routes = null

        const sidenavVariants = {
            active: {
                marginLeft: '22.5rem'
            },
            notActive: {
                marginLeft: '7.5rem'
            },
            surveyActive: {
                marginLeft: '22.5rem'
            },
            isMobile: {
                marginLeft: 0,
                paddingTop: '2em'
            }
        }

        const mobileMenuVariants = {
            active: {
                opacity: 1,
                x: 0,
                display: 'block'
            },
            notActive: {
                opacity: 0,
                x: -100,
                transitionEnd: {
                    display: 'none'
                }
            }
        }

        if (userType === 'enterprise') {
            routes = <div>
                <Switch location={this.props.location}>
                    <Route path="/login" exact component={StagingArea} />
                    <Route path="/login/:token" exact component={StagingArea} />
                    <PrivateRoute path="/lomakkeet" exact component={PlanList} isAuthenticated={isAuthenticated}  />
                    <PrivateRoute path="/lomakkeet/luonti" exact component={PlanbuilderContainer} panelOpen={this.state.panelOpen} togglePanel={this.toggleSidePanels} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/lomakkeet/uusi" exact component={NewPlanTarget} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/lomakkeet/hae" exact component={PlanSearch} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/lomakkeet/uusi/lohko" exact component={CustomBlockEditor} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/kartoitukset" exact component={SurveyList} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/kartoitukset/aktivoi" exact component={SenderContainer} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/kartoitukset/luonti" exact component={KartoitusBuilder} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/yhteenveto" exact component={StyleWrapper} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/yhteistyo" exact component={Clients} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/tiedot" exact component={EnterpriseContainer} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/raportit" exact component={BusinessSelect} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/raportit/yritys" exact component={ReportDashboard} togglePanel={this.toggleSidePanels} panelOpen={this.state.panelOpen} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/raportit/kohdistamaton" exact component={ReportDashboard} togglePanel={this.toggleSidePanels} panelOpen={false} isAuthenticated={this.props.isAuthenticated} />
                    <PrivateRoute path="/raportit/yritys/raportti" exact component={Reports} isAuthenticated={isAuthenticated} history={this.props.history} />
                    <PrivateRoute path="/raportit/kohdistamaton/raportti" exact component={Reports} isAuthenticated={isAuthenticated}  history={this.props.history}/>
                    <PrivateRoute path="/raportit/vertailu/raportti" exact component={ComparisonReport} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/upotus" exact component={Embed} isAuthenticated={isAuthenticated} />
                    <PrivateRoute path="/projektit" exact component={ProjectContainer} isAuthenticated={isAuthenticated} history={this.props.history}/>
                    <PrivateRoute path="/projektit/hallitse" exact component={HandleProject} isAuthenticated={isAuthenticated} history={this.props.history}/>
                    <PrivateRoute path="/tuotteet" exact component={ProductContainer} isAuthenticated={isAuthenticated} history={this.props.history}/>
                    <PrivateRoute path="/tyokalut" exact component={Tools} isAuthenticated={isAuthenticated} history={this.props.history}/>
                    {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                        <PrivateRoute path="/testi" exact component={TestingComponent} isAuthenticated={true} />
                    }
                    <Redirect path="*" to="/login" />
                </Switch>
            </div>
        } else {
            routes = <div>
                <Switch location={this.props.location}>
                    <Route path="/login" exact component={StagingArea} />
                    <Route path="/login/:token" exact component={StagingArea} />
                    <PrivateRoute path="/tiedot" exact component={BusinessContainer} isAuthenticated={this.props.isAuthenticated} />
                    <PrivateRoute path="/raportit/yritys" exact component={ReportDashboard} panelOpen={this.state.panelOpen} isAuthenticated={this.props.isAuthenticated} />
                    <PrivateRoute path="/raportit/yritys/raportti" exact component={Reports} isAuthenticated={this.props.isAuthenticated} />
                    <PrivateRoute path="/raportit/vertailu/raportti" exact component={ComparisonReport} isAuthenticated={this.props.isAuthenticated} />
                    {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                        <PrivateRoute path="/testi" exact component={TestingComponent} isAuthenticated={true} />
                    }
                    <Redirect path="*" to="/login" />
                </Switch>
            </div>
        }

        const navProps = {
            onNavClick: this.setActiveTab,
            logoStyle: this.state.logoStyle,
            location: this.props.location,
            activeTab: pathname,
            onLogOut: this.props.onLogOut,
            togglePanel: this.toggleSidePanels,
            panelOpen: this.state.panelOpen,
            navigateTo: this.navigateTo,
            onNewPlan: this.props.onNewPlan,
            modulesInUse: this.props.modulesInUse,
            enterpriseList: this.props.enterpriseList
        }

        let togglePosition = this.state.mobileMenuOpen ? 'fixed' : 'absolute'

        return (
            <div style={{ width: '100%' }}>
                {isAuthenticated &&
                    <>
                        {this.props.openAIKey && <OpenAI apiKey={this.props.openAIKey}/>}
                        <BrowserView>
                            {userType === 'enterprise' ? <SideNav {...navProps} /> : <BusinessSideNav {...navProps} />}

                            <Breadcrumbs
                                crumbs={this.props.breadcrumbs.crumbs}
                                addMoreBreadcrumbs={this.props.addMoreBreadcrumbs}
                                margin={this.sidePanelOpen(pathname) ? '22.5rem' : '7.5rem'} />
                        </BrowserView>

                        <MobileView>
                            <div onClick={this.toggleMobileMenu}>
                                <i className={this.state.mobileMenuOpen ? "fas fa-times" : "fas fa-bars"} style={{ position: togglePosition, top: 0, left: 0, zIndex: 101, fontSize: '1.5em', padding: '.5em' }}></i>
                            </div>

                            <motion.div style={{ height: '100vh', position: 'absolute', zIndex: 100 }} initial='notActive' animate={this.state.mobileMenuOpen ? 'active' : 'notActive'} variants={mobileMenuVariants}>
                                {userType === 'enterprise' ? <SideNav {...navProps} mobileNav={true} /> : <BusinessSideNav {...navProps} mobileNav={true} />}
                            </motion.div>
                        </MobileView>
                    </>
                }
                <motion.div className="base-container" initial={isMobile ? 'isMobile' : 'active'} animate={isMobile ? 'isMobile' : this.sidePanelOpen(pathname) ? 'active' : 'notActive'} variants={sidenavVariants}>
                    {/* <GuideTour modulesInUse={modulesInUse} /> */}
                    {routes}
                </motion.div>

            </div>
        )
    }
}

const mapPropsToState = state => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        businessName: state.authentication.businessName,
        breadcrumbs: state.crumbs,
        selectedBusiness: state.reports.selectedBusiness,
        logo: state.authentication.logo,
        downloadNew: state.authentication.downloadNew,
        userType: state.authentication.userType,
        modulesInUse: state.authentication.modulesInUse,
        enterpriseList: state.authentication.enterpriseList,
        openAIKey : state.authentication.openAIKey
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogOut: (params) => dispatch(ActionCreator.authFailed()),
        addMoreBreadcrumbs: (params) => dispatch(ActionCreator.addMoreBreadcrumbs(params)),
        doneLoading: (params) => dispatch(ActionCreator.logoDownloaded(params)),
        onNewPlan: (params) => dispatch(startNewPlan(params))
    }
}

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(Navigator));
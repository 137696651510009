import * as ActionTypes from '../../Components/Kartoitus/Sender/Actions/ActionTypes';
import {SET_RISK_EVALUATIONS, SET_COMPANY_SCORE} from '../../Components/Reports/Dashboard/Actions/actionTypes'

/*
*   This reducer will be responsible for the Sender Tab
*   store companies for the Select
*   store sent surveys
*
*
*
*
*
*
*/

const initialState = {
    companies: [],
    sentSurveys: null,
    collaborations: null,
    sentAnonSurveys: [],
    surveyTree: [],
    tasksForDashboard: null,
    risksForDashboard: null,
    companyScore: 0,
    riskMatrice: null,
    riskMatriceResults: null
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_COMPANIES: 
            return {
                ...state,
                companies: [
                    ...action.payload
                ]
            }
        case SET_RISK_EVALUATIONS:
            return {
                ...state,
                risksForDashboard: [
                    ...action.payload.riskList
                ],
                riskMatrice: action.payload.matrice,
                riskMatriceResults: action.payload.matriceResults,
                newRiskMatrice: action.payload.newMatrice
            }
        case SET_COMPANY_SCORE:
            return {
                ...state,
                companyScore: action.payload
            }
        case ActionTypes.SENT_SURVEYS:
            return {
                ...state,
                sentSurveys: [
                    ...action.payload
                ]
            }
        case ActionTypes.REQUEST_PERMISSION:
            return {
                ...state,
                collaborations: [
                    ...action.payload
                ]
            }
        case ActionTypes.FETCH_ANON: 
            return {
                ...state,
                sentAnonSurveys: [
                    ...action.payload
                ]
            }
        case ActionTypes.FETCH_TASKS:
            return {
                ...state,
                tasksForDashboard: [
                    ...action.payload
                ]
            }
        case ActionTypes.CLEAR_TASK_LIST:
            return {
                ...state,
                tasksForDashboard: []
            }
        case ActionTypes.REMOVE_TASK_FROM_LIST:
            return {
                ...state,
                tasksForDashboard: state.tasksForDashboard.filter( task => task._id !== action.payload._id )
            }
        case ActionTypes.SET_TASK_IN_LIST:
            return {
                ...state,
                tasksForDashboard: state.tasksForDashboard.map(task => {
                    if(task._id === action.payload._id)
                        return action.payload
                    return task
                })
            }
        case "UPDATE_TASK_STATUS":
            return {
                ...state,
                tasksForDashboard: state.tasksForDashboard.map(task => {
                    if(task._id === action.payload._id)
                        return {
                            ...task,
                            status: action.payload.status
                        }
                        
                    return task
                })
            }
        default:
            return state;
        
    }
}

export default reducer;
import * as ActionTypes from './ActionTypes';
import { confs } from '../../../../modules/config';
import { NotificationManager } from 'react-notifications';
import { authFailed } from '../../../../store/index';

export const storeCompanies = companies => {
    return {
        type: ActionTypes.FETCH_COMPANIES,
        payload: companies
    }
}

export const sentSurveys = surveys => {
    return {
        type: ActionTypes.SENT_SURVEYS,
        payload: surveys
    }
}

const storeAnons = result => {
    return {
        type: ActionTypes.FETCH_ANON,
        payload: result
    }
}

export const storeTasks = tasks => {
    return {
        type: ActionTypes.FETCH_TASKS,
        payload: tasks
    }
}

export const updateTaskStatus = (taskId, status) => {
    return {
        type: "UPDATE_TASK_STATUS",
        payload: {
            _id: taskId,
            status: status
        }
    }
}

export const storeTask = task => {
    return {
        type: ActionTypes.SET_TASK_IN_LIST,
        payload: task
    }
}

export const endSurvey = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'send/surveyEnd', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    dispatch(sentSurveys(responseJson));
                }
            })
    }
}

export const fetchCompanies = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        setTimeout(() => {
            fetch(confs.url + 'get/collaboration/list', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                        dispatch(authFailed());
                    } else {
                        dispatch(storeCompanies(responseJson));
                    }
                })
        }, 300);
    }
}

export const sendSurveys = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'send/survey', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    NotificationManager.success("Uusi kartoitus aktivoitu", "Kartoituksen aktivointi");
                    dispatch(sentSurveys(responseJson));
                }
            })
    }
}

export const getSurveys = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'get/sentSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    dispatch(sentSurveys(responseJson));
                }
            })
    }
}

export const sendAnonSurvey = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'send/anonSendSurvey', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    dispatch(storeAnons(responseJson));
                }
            })
    }
}

export const getAnonSurveys = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'get/anonSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    dispatch(storeAnons(responseJson));
                }
            })
    }
}

export const deleteSentSurvey = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'send/deleteSentSurvey', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    let title = params["name"];

                    if (typeof title === 'undefined') {
                        title = "";
                    }
                    NotificationManager.error("On poistettu onnistuneesti.", title, 5000);
                    dispatch(sentSurveys(responseJson));
                }
            }).catch(error => NotificationManager.error("", "Valitettavasti poistossa tapahtui virhe", 7000))
    }
}

export const deleteAnonSentSurvey = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'send/deleteAnonSentSurvey', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    let title = params["name"];

                    if (typeof title === 'undefined') {
                        title = "";
                    }
                    NotificationManager.error("On poistettu onnistuneesti.", title, 5000);
                    dispatch(storeAnons(responseJson));
                }
            }).catch(error => NotificationManager.error("", "Valitettavasti poistossa tapahtui virhe", 7000))
    }
}

export const getSentSurveysForDashboard = params => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'get/sentSurveysForDashboard', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed())
                } else {
                    if ( typeof responseJson.success !== 'undefined' && !responseJson.success ) {
                        dispatch( sentSurveys( [] ) )
                    } else {
                        dispatch( sentSurveys( responseJson ) )
                    }
                    
                }
            })
    }
}

export const getTasksForDashboard = params => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        setTimeout(() => {
            fetch(confs.url + 'get/tasksForDashboard', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                        dispatch(authFailed())
                    } else {
                        if( typeof responseJson.success !== 'undefined' && !responseJson.success ) {
                            dispatch( storeTasks( [] ) )
                        } else {
                            dispatch( storeTasks( responseJson ) )
                        }
                    }
                })
        }, 300);
    }


}

export const removeTaskFromList = ( task ) => {
    return {
        type: ActionTypes.REMOVE_TASK_FROM_LIST,
        payload: task
    }
}

export const clearTaskList = () => {
    return {
        type: ActionTypes.CLEAR_TASK_LIST
    }
}

export const saveTaskFromDashboard = payload => dispatch => {
    let jwt = window.sessionStorage.getItem("token")

    let cardPayload = {
        jwtToken: jwt,
        ...payload
    }

    fetch(confs.url + 'modify/taskCardV2', {
        method: 'POST',
        body: JSON.stringify(cardPayload)
    })
        .then(res => res.json())
        .then(data => {
            if (typeof data.isAuthenticated !== 'undefined' && !data.isAuthenticated) {
                dispatch(authFailed())
            } else {
                if ((data.status === "updated" || data.status === 'declined')) {
                    NotificationManager.success("Tarkistuspiste päivitetty", "Tarkistuspisteen päivitys", 4000)

                    // dispatch( removeTaskFromList( data.modifiedTask ) )
                    if(data.modifiedTask !== undefined && 
                        data.modifiedTask.status !== undefined &&
                        (data.modifiedTask.status === "declined" || data.modifiedTask.status === "done")) {
                            dispatch( updateTaskStatus ( data.modifiedTask._id, data.modifiedTask.status ) )
                        } else {
                            dispatch ( storeTask(data.modifiedTask) )
                        }
                } else {
                    NotificationManager.error("Tarkistuspisteen päivitys epäonnistui.", "Tarkistuspisteen päivitys", 4000)
                }
            }
        })
}
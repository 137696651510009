import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"

import './riskmatrice.css';

// tämmönen valmiiks sitä ajatellen, että joskus pitää näyttää tyhjä riskikartta. esim uutta riskiarvioo lisätessä on vielä vanha riskikartta
const blanko = [
    { x: "Vähäinen", y: "Epätodennäköinen", color: "#B5E413", value: 0 },
    { x: "Vähäinen", y: "Mahdollinen", color: "#FFCC00", value: 0 },
    { x: "Vähäinen", y: "Todennäköinen", color: "#ff9900", value: 0 },
    { x: "Vähäinen", y: "Lähes varma", color: "#ED5652", value: 0 },
    { x: "Kohtalainen", y: "Epätodennäköinen", color: "#FFCC00", value: 0 },
    { x: "Kohtalainen", y: "Mahdollinen", color: "#FFCC00", value: 0 },
    { x: "Kohtalainen", y: "Todennäköinen", color: "#ff9900", value: 0 },
    { x: "Kohtalainen", y: "Lähes varma", color: "#ED5652", value: 0 },
    { x: "Merkittävä", y: "Epätodennäköinen", color: "#ff9900", value: 0 },
    { x: "Merkittävä", y: "Mahdollinen", color: "#ff9900", value: 0 },
    { x: "Merkittävä", y: "Todennäköinen", color: "#ED5652", value: 0 },
    { x: "Merkittävä", y: "Lähes varma", color: "#ED5652", value: 0 },
    { x: "Kriittinen", y: "Epätodennäköinen", color: "#ED5652", value: 0 },
    { x: "Kriittinen", y: "Mahdollinen", color: "#ED5652", value: 0 },
    { x: "Kriittinen", y: "Todennäköinen", color: "#ED5652", value: 0 },
    { x: "Kriittinen", y: "Lähes varma", color: "#ED5652", value: 0 }
]

am4core.options.onlyShowOnViewport = true

class RiskMatrice extends Component {
    componentDidMount() {
        
        this.makeChart()
    }

    componentDidUpdate( prevProps ) {
        if( prevProps.newRiskMatrice !== this.props.newRiskMatrice ) {
            this.chart.dispose()
            this.makeChart()
        }
    }

    componentWillUnmount() {
        if ( this.chart ) {
            this.chart.dispose()
        }
    }

    makeChart = () => {
        const { newRiskMatrice, openRiskListModal, riskMatriceResults } = this.props

        if ( riskMatriceResults !== undefined ) {
            // Themes begin
            am4core.useTheme( am4themes_animated )
            // Themes end

            let chart = am4core.create( "chartdiv", am4charts.XYChart )
            chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

            chart.maskBullets = false

            let xAxis = chart.xAxes.push( new am4charts.CategoryAxis() )
            let yAxis = chart.yAxes.push( new am4charts.CategoryAxis() )

            xAxis.dataFields.category = "x"
            yAxis.dataFields.category = "y"

            xAxis.renderer.grid.template.disabled = true
            xAxis.renderer.minGridDistance = 40

            yAxis.renderer.grid.template.disabled = true
            // yAxis.renderer.inversed = true
            yAxis.renderer.minGridDistance = 30

            let series = chart.series.push( new am4charts.ColumnSeries() )
            series.dataFields.categoryX = "x"
            series.dataFields.categoryY = "y"
            series.dataFields.value = "value"
            series.dataFields.valueX = "xPosition"
            series.dataFields.valueY = "yPosition"
            series.dataFields.color = "color"
            series.sequencedInterpolation = true
            series.defaultState.transitionDuration = 300

            // Set up column appearance
            let column = series.columns.template
            column.strokeWidth = 2
            column.strokeOpacity = 1
            column.stroke = am4core.color( "#ffffff" )
            column.tooltipText = "{x}, {y}: {value.workingValue.formatNumber('#.')} kpl"
            column.width = am4core.percent( 100 )
            column.height = am4core.percent( 100 )
            column.column.cornerRadius( 6, 6, 6, 6 )
            column.propertyFields.fill = "color"

            // Set up bullet appearance, this is the bullet that creates the scaling background for the label 
            let bullet1 = series.bullets.push( new am4charts.CircleBullet() )
            bullet1.circle.propertyFields.radius = "value"
            bullet1.circle.fill = am4core.color( "#000" )
            bullet1.circle.strokeWidth = 0
            bullet1.circle.fillOpacity = 0.7
            bullet1.interactionsEnabled = false

            bullet1.circle.adapter.add( "scale", ( scale, target, key ) => {
                const newScale = parseFloat( scale + target.properties.radius / 10 )
                return newScale
            } )

            bullet1.circle.adapter.add( "radius", ( radius, target, key ) => {
                if ( radius > 0 ) {
                    return 15
                }
                return radius
            } )

            // label which contains the number of risks
            let bullet2 = series.bullets.push( new am4charts.LabelBullet() )
            bullet2.label.text = "{value}"
            bullet2.label.fill = am4core.color( "#fff" )
            bullet2.zIndex = 1
            bullet2.fontSize = 11
            bullet2.interactionsEnabled = false
            bullet2.scale = 1.3

            bullet2.label.adapter.add( "text", ( text, target, key ) => {
                const value = target.dataItem.values.value.value
                if ( value !== 0 ) {
                    return text
                }
                return ''
            } )

            chart.data = newRiskMatrice

            // the on click event where we open the modal that contains list of risks 
            series.columns.template.events.on( 'hit', ( e ) => {
                const { color, valueX, valueY, value } = e.target.dataItem

                if ( value > 0 ) {
                    openRiskListModal( valueY, valueX, color )
                }
            } )

            this.chart = chart
        }
    }

    render() {
        const { riskMatrice, riskMatriceResults, selectedRiskLevel, odds, previousRiskLevel, previousOdds } = this.props

        if ( riskMatriceResults !== undefined ) {
            return (
                <div>
                    <div id="chartdiv" style={ { height: '500px' } }></div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="oddsTextContainer oddsText">Todennäköisyys</div>
                    <div className="riskMatriceContainer">
                        { riskMatrice !== null &&
                            riskMatrice.map( ( innerList, index ) => {
                                return innerList.map( ( value, innerIndex ) => {
                                    return <div className="riskMatriceCell" style={ { backgroundColor: riskMatrice[ index ][ innerIndex ] } } key={ index + " / " + innerIndex }>

                                        {
                                            ( selectedRiskLevel !== undefined && odds !== undefined ) &&
                                                ( previousRiskLevel !== null && previousOdds !== null ) ?
                                                ( previousRiskLevel.value - 1 === innerIndex && previousOdds.value - 1 === index ) ?
                                                    <div className="riskMatriceDotContainer"><i className="fas fa-circle"></i></div>
                                                    :
                                                    ( selectedRiskLevel.value - 1 === innerIndex && odds.value - 1 === index ) &&
                                                    <div className="riskMatriceDotContainer animate-flicker-dot">
                                                        <i className="far fa-circle" style={ { color: '#FFF' } }></i>
                                                    </div>
                                                :
                                                ( selectedRiskLevel.value - 1 === innerIndex && odds.value - 1 === index ) &&
                                                <div className="riskMatriceDotContainer animate-flicker-dot">
                                                    <i className="far fa-circle" style={ { color: '#FFF' } }></i>
                                                </div>

                                        }
                                    </div>
                                } )
                            } )
                        }
                    </div>
                    <div className="vaikuttavuusText">Vaikuttavuus</div>
                </div>
            )
        }
    }
}

export default RiskMatrice;
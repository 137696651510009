import React from 'react';
import {Button, Col} from 'reactstrap';

const SurveyButton = props => {
    return (
        <div style={{paddingBottom: '0.8em', cursor: 'pointer', display: 'flex'}} onClick={() => props.selectReport(props.survey)}>
            <div style={{display: 'flex'}}>
                <i className="fas fa-angle-right" style={{alignSelf: 'center', paddingRight: '1.5em'}}></i>
                <div className="ball-container-plan">
                    <div className="smaller-score-ball" style={{ borderColor: props.survey.color }}><span>{props.survey.avg}</span></div>
                </div>
                <span style={{alignSelf: 'center', paddingLeft: '1.5em', paddingRight: '1.5em'}}>{props.label}</span>
            </div>
        </div>
    )
}

export default SurveyButton;
import React, { useState, useEffect } from 'react';
import { Collapse, Col, Row, Label, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import Select from 'react-select';
import MultiSelect from "@kenshooui/react-multi-select";
import { NotificationManager } from 'react-notifications';
import { TagHandler } from './TagHandler';
import { Scrollbars } from 'react-custom-scrollbars';
import TextEditor from '../../../Utilities/TextEditor/texteditor';

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi aihetta...",
        noItemsMessage: "Valitse aihe",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
};

export const BaseView = ({ viewOptions = {}, projectId = null, setView, selectedViewId = null , setActiveTab}) => {
    const [results, setResults] = useState([])
    const [groups, setGroups] = useState([])
    const [headers, setHeaders] = useState([])
    const [groupZero, setGroupZero] = useState(null)
    const [visibleIndexes, setVisibleIndexes] = useState([])
    const [selectedExcel, setSelectedExcel] = useState({})
    const [chosenExcels, setChosenExcels] = useState(viewOptions.chosenExcels)
    const [excels, setExcels] = useState([])
    const [selectableGroups, setSelectableGroups] = useState(null)
    const [selectedGroups, setSelectedGroups] = useState(viewOptions.selectedGroups)
    const [modalOpen, setModalOpen] = useState(false)
    const [nameModalOpen, setNameModalOpen] = useState(false)
    const [viewName, setViewName] = useState(viewOptions.viewName ? viewOptions.viewName : "")
    const [viewTags, setViewTags] = useState(viewOptions.viewTags)
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [selectedData, setSelectedData] = useState(null)
    const [madeChanges, setMadeChanges] = useState(false)
    const [extras, setExtras] = useState(viewOptions.extras ? viewOptions.extras : [])
    const [calcStyle, setCalcStyle] = useState(viewOptions.calcStyle? viewOptions.calcStyle: "sum")
    const [calculated, setCalculated] = useState("")

    const getExcelComparison = async() => {
        let payload = {
            chosenExcels: chosenExcels,
            calcStyle: calcStyle
        }
        const response = await executeRequest('project/view/compareExcels', payload)
        if(!!response && response.result && response.groups && response.headers){
            setResults(response.result)
            setGroups(response.groups)
            // for(var group of response.groups){
            //     setVisibleIndexes(prevData => ([
            //         ...prevData,
            //         response.groups.indexOf(group)
            //     ]))
            // }
            setHeaders(response.headers)
            setGroupZero(response.result[0])
            setSelectableGroups(response.groups)
            setCalculated(response.calcStyle)
        }
        else {
            setResults([])
            setGroups([])
            setVisibleIndexes([])
            setHeaders([])
            setGroupZero([])
        }
    }

    useEffect(()=>{
        getExcelsForView()
    },[])

    useEffect(()=>{
        getExcelComparison()
    },[viewOptions, chosenExcels])

    const toggleVisible = (index) => {
        let indexes = visibleIndexes
        if(visibleIndexes.includes(index)){
            indexes.splice(visibleIndexes.indexOf(index),1)
            setVisibleIndexes([...indexes])
        } else {
            setVisibleIndexes([...indexes,index])
        }
    }

    const handleChange = (val) => {
        setSelectedExcel(val)
        setChosenExcels(prevData =>([
            ...prevData,
            val
        ]))
        saveView()
        setSelectedExcel(null)   
    }

    const selectedExcelFilter = (excel) => {
        const { value, groups } = excel
        
        if(groups.length === 0) return false
        if(chosenExcels.filter(exl => exl.value.toUpperCase().includes(value.toUpperCase())).length > 0) return false
        else if(groups && chosenExcels[0]){
            if(JSON.stringify(groups) == JSON.stringify(chosenExcels[0].groups)){
                return true
            }
            else return false
        }

        return false
    }

    const handleGroupFilter = (group) => {
        const {label, id} = group

        if(selectedGroups.length === 0) return true
        if(selectedGroups.filter(selected => selected.label.toUpperCase().includes(label.toUpperCase())).length > 0) return true
       
        return false
        
    }

   const handleRemove = (index, excel) => {
        setSelectedIndex(index)
        setSelectedData(excel)
        setRemoveModalOpen(true)
    }

    const removeSelection = (index, excel) => {
        if((chosenExcels.length -1) > 0){
            let array = chosenExcels
            array.map((chosen, i) => {
                if(chosen.value === index){
                    array.splice(i,1)
                }
            })
            setChosenExcels([...array])
        }
        setSelectedIndex(null)
        setSelectedData(null)
        setRemoveModalOpen(!removeModalOpen) 
        saveView()
    }

    const getExcelsForView = async() => {
        let payload = {
            projectId: projectId
        }
        const response = await executeRequest('project/newView/getExcels', payload)
        if(!!response){
            setExcels([...response])
        }
    }
  
    const handleGroupSelection = (selectedItems) => {
        setSelectedGroups([...selectedItems])
    }

    const handleSave = () => {
        if(viewName.length === 0){
            setNameModalOpen(!nameModalOpen)
        }
        else {
            saveView()
            setModalOpen(false)
        }
    }

    const handleTags= (e) => {
        setViewTags(e.target.value)
    }

    const saveView = async() => {
        let payload = {
            projectId: projectId,
            chosenExcels: chosenExcels,
            selectedGroups: selectedGroups,
            selectedViewId: viewOptions.selectedViewId ? viewOptions.selectedViewId : selectedViewId ,
            viewName: viewName,
            viewTags: viewTags,
            extras: extras,
            calcStyle: calcStyle
        }

        const response = await executeRequest('project/saveView', payload)

        if(response.success === true){
            NotificationManager.success('Muutokset tallennettu', 'Ilmoitus', 4000)
        } else {
            NotificationManager.error('Tallentamisessa tapahtui virhe', 'Virhe', 4000)
        }
        getExcelComparison()
        setMadeChanges(false)
    }

    const customOption = (option) => {
        if(option && option.label){
            let businessName = option.business.label ? option.business.label : ""
            return(
                <div className="option-label">
                    {businessName + " - " + option.label}
                </div>
            )
        }
    }
    
    const handleDataChanges = (id, data) => {

        if(extras.filter(extra => {return extra.excelId === id}).length > 0){
            setExtras(prevData => ([
                ...prevData.map((extra,ind) => {
                   if(extra.excelId === id){
                       return{excelId: id, data:data}
                   }
                   return extra
                })
            ]))
            setMadeChanges(true)
        } else {
            setExtras(prevData => ([
                ...prevData,
                {excelId: id, data:data}
            ]))
            setMadeChanges(true)
        }
    }

   
    return(
        <div>
             <Modal isOpen={removeModalOpen} toggle={() => setRemoveModalOpen(!removeModalOpen)}>
                <ModalHeader toggle={() =>setRemoveModalOpen(!removeModalOpen)}>
                    {chosenExcels.length > 1 ?
                        <p>{selectedData && selectedData.label}</p>
                        :
                        <p>Huom!</p>
                    }
                </ModalHeader>
                <ModalBody>
                    {chosenExcels.length > 1 ?
                        <p>Oletko varma, että haluat poistaa excelin vertailusta?</p>
                        :
                        <p>Et voi poistaa viimeistä exceltiedostoa vertailusta</p>
                    }
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setRemoveModalOpen(!removeModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    {chosenExcels.length > 1 &&
                        <button onClick={() => removeSelection(selectedIndex, selectedData.value)} className="small-white-button wide-button delete-plan" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>
                    }
                </ModalFooter>
            </Modal>
            <Modal isOpen={nameModalOpen} toggle={() => setNameModalOpen(!nameModalOpen)}>
                <ModalHeader toggle={() => setNameModalOpen(!nameModalOpen)}>Anna vielä vertailulle nimi</ModalHeader>
                <ModalBody>
                    <div>
                        <Label>Nimi</Label>
                        <Input id="viewName" type="text" value={viewName} onChange={(e) => setViewName(e.target.value)}></Input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setNameModalOpen(!nameModalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                   { viewName.length > 0 && 
                        <button className="small-white-button wide-button save-view-button"  onClick={() => (saveView(), setNameModalOpen(!nameModalOpen))}>
                            <p>Tallenna</p>
                        </button>
                    } 
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Vertailun asetukset</ModalHeader>
                <ModalBody>
                    <Label>Valitut excelit  <p style={{ fontSize: '0.7rem', marginBottom: '0.5em' }}>(Voit poistaa valinnan painamalla)</p></Label>
                    
                    <div style={{ marginBottom: '1em', display: 'flex', flexDirection:'row', flexWrap: 'wrap' }}>
                        {chosenExcels && chosenExcels.map((excel, index) => {
                            return(
                                <div key={index} className="selected-excel-block">
                                    {/* <span><i style={{ float: 'right', cursor: 'pointer', marginTop: '0.5em', fontSize: '0.8em' }} className="far fa-trash-alt" onClick={() => removeSelection(index)}></i></span> */}
                                    <button onClick={() => handleRemove(excel.value, excel)} className="small-white-button swb-hover">
                                        <div>
                                            {excel.business && <p className="name-overflow-handler" style={{ fontSize: '0.9rem'}}>{excel.business.label}</p>}
                                            <p style={{ fontSize: '0.7rem'}}>{excel.label}</p>
                                        </div>
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                    
                    <div style={{ marginBottom: '1em' }}>
                        <Label>Valittavat excelit</Label>
                        <Select
                            value={selectedExcel}
                            onChange={(val)=> handleChange(val)}
                            options={excels.filter(excel => selectedExcelFilter(excel))}
                            getOptionLabel={option => customOption(option)}
                            placeholder="Valitse excel.."
                            noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                            className="projectTypes"
                            classNamePrefix="select"
                        />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Label>Nimi</Label>
                        <Input id="viewName" placeholder="Anna vertailulle nimi tallentaaksesi " type="text" value={viewName} onChange={(e) => setViewName(e.target.value)}></Input>
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Label>Tagit</Label>
                        <TagHandler
                            tags={viewTags}
                            handleChange={handleTags}
                        />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Label>Laskettava arvo</Label>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <button onClick={() => setCalcStyle("sum")}  className={"small-white-button " + (calcStyle === "sum"? "selected-calc-active": "")} style={{ width: '100%' }}>Summa</button> 
                            <button onClick={() => setCalcStyle("avg")}  className={"small-white-button " + (calcStyle === "avg"? "selected-calc-active": "")} style={{ width: '100%' }}>Keskiarvo</button> 
                        </div>
                    </div>
                    {selectableGroups &&
                        <div style={{ marginBottom: '1em' }}> 
                            <Label>Valitse näytettävät aihealueet</Label>                                                               
                            <MultiSelect
                                messages={customMessages.messages}
                                items={selectableGroups}
                                selectedItems={selectedGroups}
                                onChange={handleGroupSelection}
                                wrapperClassName="multi-selector round-corners"
                            />
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => setModalOpen(!modalOpen)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    <button onClick={() => handleSave()}  className="small-white-button wide-button" >Tallenna</button> 
                </ModalFooter>
            </Modal>
            <div>
                <div style={{ display:'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '2em', paddingRight: '1em'}}>
                    <Col>
                        <button className="small-white-button " style={{ marginLeft: '1em' }} onClick={() => (setActiveTab("views"),setView("project"))}>
                            <p>Takaisin</p>
                        </button>
                    </Col>
                    <Col className="tag-container">
                        <div style={{display:"flex", flexWrap:"wrap", alignItems:"flex-start"}}>
                            {viewTags && viewTags.slice(0, 7).map((tag, y) => {                        
                                    return(<div key={y} style={{paddingTop:"0.15em"}}><p key={y} className="tag-display tag-text project-tag" title={tag.label}>{tag.label}</p></div>)
                                })  
                            }                                                   
                            {viewTags && viewTags.length > 7 &&  
                                    <div style={{display:"flex", paddingTop:"0.15em", marginBottom:"-0.6em", marginTop:"auto"}}>
                                        <i className="material-icons" title={tagsToMore(viewTags.slice(7,viewTags.length))}>more_horiz</i>
                                    </div>
                            }  
                        </div>                                            
                    </Col>   
                    <Col>
                        <button className="small-white-button view-options" onClick={() => setModalOpen(!modalOpen)}>
                            <i className="fa fa-cog"></i>
                        </button>
                    </Col>
                </div>
                <div className="view-header-row">
                    <Col>
                        <h2 style={{ marginLeft: '0.5em', fontWeight: 'bold', marginBottom: '0.5em' }}>{viewName}</h2>
                    </Col>
                </div> 
            </div>
            <div className="compare-box">
                <div style={{ display:'flex', flexDirection: 'row', width: 'max-content' }}>
                    <div className="view-excel-block">
                    {/* empty col to fill up space (you know, like the outer space but smaller :P) */}
                    </div>
                    {results&& results.map((result,i) => {
                        return(
                            <div key={i} className={"view-excel-block business-buttons dark-compare-"+ (i===0? "first" : (i===(results.length-1)? "last": "middle"))}>
                                <div className="selected-excel remove-row">
                                    <div className="remove-from-view-button" onClick={() => handleRemove(result.id, {label:result.name})}>x</div>
                                </div>
                                <div className="selected-excel center-in-compare">
                                    <p style={{ fontSize: '0.7rem' }} title={result.name} className="business-name-handler">{result.name}</p>
                                    <p title={result.business.label} className="business-name-handler">{result.business.label}</p>
                                    {/* <p style={{ paddingTop: '0.25rem' , fontSize: '0.7rem' }} >{result.name}</p> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {groups && groups.filter( group => handleGroupFilter(group)).map((groupHeader, index) => {                  
                    return( 
                            <div key={index} > 
                                <div style={{ display:'flex', flexDirection: 'row', width: 'max-content' }} className={"group-header-row" + (index === 0 ? " first-group" : "")} onClick={() => toggleVisible(index)}>    
                                    <div  className="view-excel-block">
                                        <p className="group-header">{groupHeader.label}</p> 
                                    </div>
                                    {results && results.map((result,i) => {
                                        return(
                                            <div key={i} className={"view-excel-block darker-compare-"+ (i===0? "first" : (i===(results.length-1)? "last": "middle"))}>
                                                {result.groups && result.groups.map((group,j) => {
                                                    if(group.groupName && group.groupName === groupHeader.label){
                                                        return(  
                                                            <Row key={j} className="compare-header-row">
                                                                <Col  className="view-centered">
                                                                    {group.avg &&
                                                                        <p className="group-header">{"avg: " + group.avg.toFixed(2)}</p>
                                                                    }    
                                                                    {group.sum > 0 &&
                                                                        <p className="group-header">{"sum: " + group.sum.toFixed(2)}</p>
                                                                    }                                                 
                                                                </Col>
                
                                                            </Row>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                                <Collapse isOpen={visibleIndexes.includes(index)} className="collapse-section">
                                    <div style={{ display:'flex', flexDirection: 'row', width: 'max-content'  }}>   
                                        <div className="view-excel-block business-data-column" >
                                        {/* Empty space for header row in a group, can be given really nice and descriptive value if needed to */}
                                        </div>
                                        {results && results.map((result,i) => {
                                            return(
                                                <div key={i} className={"view-excel-block business-data-column compare-"+ (i===0? "first" : (i===(results.length-1)? "last": "middle"))}>
                                                    {results && results[0].groups && results[0].groups.map((group,j) => {
                                                        if(group.groupName && group.groupName === groupHeader.label){
                                                            return(
                                                                <div style={{ paddingBottom: '0.25em', display:'flex', flexDirection: 'row' }} key={j} >
                                                                    {group.rows&& group.rows[0] && Object.keys(group.rows[0]).map((key,k) => {
                                                                        if(key!== 'topic'){
                                                                            return(
                                                                                <Col key={k} className="view-centered">
                                                                                    <p className="value-header" >{headers[key-1]}</p>
                                                                                </Col>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div style={{ display:'flex', flexDirection: 'row', width: 'max-content'  }} className="group-topic-row">
                                        <div className="view-excel-block topic-header-column">
                                            {results && results[0].groups && results[0].groups.map((group,j) => {
                                                if(group.groupName && group.groupName === groupHeader.label){
                                                    return(
                                                        <Col style={{ paddingBottom: '1em' }} key={j} >
                                                            {group.rows&& group.rows.map((row,k) => {
                                                                return(
                                                                    <Row key={k} id={k} className={"topic-header-row center-topic" + (k!==0? " compare-topic-divider": "")}>
                                                                        <span className="view-text-ellipsis" title={row.topic}>{row.topic}</span>
                                                                    </Row>    
                                                                )
                                                            })}
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </div>
                                        {results && results.map((result,i) => {
                                            return(
                                                <div key={i} className={"view-excel-block business-data-column  compare-"+ (i===0? "first" : (i===(results.length-1)? "last": "middle"))}>

                                                    {groupZero && groupZero.groups && groupZero.groups.map((group,j) => {
                                                        if(group.groupName && group.groupName === groupHeader.label){
                                                            return(
                                                                <div key={j}>
                                                                    {group.rows && group.rows.map((r,k) => {
                                                                        return(
                                                                            <div key={k} className="view-group-col compare-row-divider" style={{ padding: 0}}>
                                                                                {result && result.groups && results[i].groups[j].rows && results[i].groups[j].rows.map((row,l) => {
                                                                                    if(r.topic === row.topic){
                                                                                        return(
                                                                                            <Row key={l} className="view-excel-block  topic-header-row">
                                                                                                {Object.keys(row).map((key, m) => {
                                                                                                    if(key !== "topic"){
                                                                                                        let value = Object.values(row)[m]
                                                                                                        if(!isNaN(value) && !isNaN(parseFloat(value))){
                                                                                                            value = parseFloat(value).toFixed(2)
                                                                                                        }
                                                                                                        return(
                                                                                                            <Col key={m} className={"view-centered limit-text-length"}>
                                                                                                                <p title={value}>{value}</p>
                                                                                                            </Col>
                                                                                                        )
                                                                                                    } 
                                                                                                })}
                                                                                            </Row>
                                                                                        ) 
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Collapse>
                            </div>
                    )
                })}  
                <div style={{ display:'flex', flexDirection: 'row', width: 'max-content'}}  className="summary-row group-row last-group">
                    <div style={{ paddingLeft: '1em' }} className="view-excel-block">
                        <p>Yhteenveto</p>
                    </div>
                    {results && results.map((result,i) => {
                        return(
                            <div key={i} className={"view-excel-block view-centered summary-header dark-bottom-"+ (i===0? "first" : (i===(results.length-1)? "last": "middle"))}>
                                { result.total && 
                                    <p>{calculated + ": "} {result.total.toFixed(2)}</p>
                                } 
                            </div>
                        )
                    })}
                </div>
                <div style={{ display:'flex', flexDirection: 'row', width: 'max-content' }}  className=" bottom-row group-row last-row">
                    <div className="view-excel-block">
                    
                    </div>
                    {results && results.map((result,i) => {
                        return(
                            <div key={i} className={"view-excel-block bottom-elements dark-bottom-"+ (i===0? "first" : (i===(results.length-1)? "last": "middle"))}>
                            
                            </div>
                        )
                    })}
                </div>
                <div style={{ display:'flex', flexDirection: 'row', width: 'max-content', paddingTop: '2em', paddingBottom: '1em' }} className="">
                    <div style={{ paddingLeft: '1em'}} className="view-excel-block " >

                    </div>
                    {results && results.map((result,i) => {
                        let wantedExtra = {}
                        extras.map((extra, key) => { if(extra.excelId === result.id){
                            wantedExtra = extra
                        } })
                        return(
                            <div key={i} style={{ marginRight: '1em' }} className="view-excel-block">
                                {result.id && 
                                    <TextEditor
                                        editorId={i} 
                                        content={wantedExtra.data}
                                        onTextChange={(data) => handleDataChanges(result.id, data)} 
                                        additionalClass="group-row freetext-area"
                                        placeholder=" "
                                        // plaintextEditor={true}
                                        allowFileUpload={false}
                                    />
                                }
                            </div>
                        )
                    })}
                </div>
                {madeChanges && 
                    <button onClick={() => handleSave()}  className="small-white-button" style={{ width: '100%' }}>Tallenna</button> 
                }
            </div>
        </div>
    )
}

const tagsToMore = (props) => {
    let tagss = ""
    
    if(props !== undefined){
        props.filter(tags => tagss = tagss + "  " + tags.label.toString())

        return(tagss)
    }
}
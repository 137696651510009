import React, {useState, useEffect} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Row, Col, FormGroup, FormFeedback} from 'reactstrap';
import {executeRequest} from '../../../Utilities/requestHandler/dataHandler';
import { confs } from '../../../modules/config';
import { NotificationManager } from 'react-notifications';
import { HorizontalContainer, HorizontalList, HorizontalItem, HorizontalContainerWithSearch } from '../../../Utilities/horizontal-scroller/HorizontalElements';
import { ContactCard } from '../../../Utilities/horizontal-scroller/HorizontalCards';

const baseURL = confs.url

export const SignatureCreationModal = ({open, toggle, targetBusiness, item, createSignatureItem, modulesInUse, planId}) => {
    
    const [signatures, setSignatures] = useState([])
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [business, setBusiness] = useState("")
    const [contacts, setContacts] = useState([])
    const [search, setSearch] = useState("")
    const [emailInputHasChanged, setEmailInputHasChanged] = useState(false)
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [followSigning, setFollowSigning] = useState(false)

    const newSignatureItem = () => {
        let payload = {
            ...item.selectedItem,
            signatures:signatures
        }
        createSignatureItem(payload, item.destinationIndex, item.destClone, item.planId);
        toggle();
    }

    const handleEmailChange = (value) => {
        const email = value
        setEmail(email)
        setEmailInputHasChanged(true)
        const emailRegex = /[^@]+@[^\.]+\..+/
        let emailIsValid = false

        if (emailRegex.test(email)) {
            emailIsValid = true
        } else if (email === '') {
            setEmailInputHasChanged(false)
        }

        setEmailIsValid(emailIsValid)
    }


    const handleListNameFilter = contact => {
        const {business, tags, name, jobTitle} = contact
        let businessName = business.businessName

        if(businessName && businessName.toUpperCase().includes(search.toUpperCase())) return true
        if(name && name.toUpperCase().includes(search.toUpperCase())) return true
        if(jobTitle && jobTitle.toUpperCase().includes(search.toUpperCase())) return true
        if(tags !== undefined){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }

        return false
    }

    const getContacts = async() => {

        const response = await executeRequest('business/getContactInfo', {})
        if(response.contacts){
            setContacts(response.contacts)
        } 
    }   

    useEffect(()=>{
        getContacts()
    },[])

    const addThisSigner = () => {
        if(name && email){
            let signer = {
                name: name, 
                email:email,
                business:business
            }
            let array = signatures
            setSignatures([...array, signer])
        } else {
            NotificationManager.error("Ole hyvä ja tarkista syötetyt tiedot!","Virhe!", 4000)
        }
        setName("")
        setEmail("")
        setBusiness("")
    }
    const handleSave = () => {
        if(followSigning){
            saveFollowOptions()
        }
        newSignatureItem()
    }

    const removeSigner = (index) => {
        let array = signatures
        array.splice(index,1)
        setSignatures([...array])
    }

    const handleCardSelection = (selected) => {
        if(selected.name && selected.email){
            let signer = {
                name: selected.name, 
                email:selected.email,
                business:(selected.business && selected.business.businessName)? selected.business.businessName : ""
            }
            let array = signatures
            setSignatures([...array, signer])
        }
    }

    let emailInputProps = emailInputHasChanged ? { valid: emailIsValid, invalid: !emailIsValid } : {}

    const saveFollowOptions = async() => {
        let payload = {
            planId: planId,
            signingFollowed:followSigning
        }
        const response = await executeRequest('plan/saveSignatureFollow',payload)
        if(!response.success){
            NotificationManager.error("Ei voitu lisätä allekirjoituksen seurantaa. Voit muokata seuraamisen asetuksia lomakkeen asetuksista (esikatselutilassa)","Virhe",5000)
        } else {
            NotificationManager.success("Allekirjoituksen seuraus lisätty","",3000)
        }
    }

    return(
        <Modal size="lg" isOpen={open} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()}>
                Allekirjoittajat
            </ModalHeader>
            <ModalBody>
                <div>
                    <Label>Nimi *</Label>
                    <Input type="text" value={name} placeholder="Nimi" onChange={(e)=> setName(e.target.value)}/>
                </div>
                <div style={{ marginTop: '1em' }}>
                    <Label>Yritys</Label>
                    <Input type="text" value={business} placeholder="Yritys" onChange={(e)=> setBusiness(e.target.value)}/>
                </div>
                <div style={{ marginTop: '1em' }}>
                    <FormGroup>
                        <Label>Sähköpostiosoite *</Label>
                        <Input {...emailInputProps} value={email} onBlur={() => setEmailInputHasChanged(false)} onChange={(e) => handleEmailChange(e.target.value)} type="email" placeholder="Sähköpostiosoite" />
                        <FormFeedback>Syötä sähköpostiosoite oikeassa muodossa</FormFeedback>
                    </FormGroup>
                </div>
                <div style={{ marginTop: '1.5em' }}>
                    <button className="small-white-button lighter-text wide-button sign-plan-button" style={{ width: '100%' }} onClick={() => addThisSigner()}>Lisää allekirjoittaja</button>
                </div> 
                {modulesInUse.contactManagement && contacts && contacts.length > 0 && 
                    <div style={{ marginTop: '2em' }}>
                        <Label>Tai valitse kontakteista</Label>
                        <div className="contact-card-overview" style={{marginTop:0}}>
                            <HorizontalContainerWithSearch style={{}} label={"Kontaktit"} listName={"Kontaktit"} setFilter={setSearch}>
                                <HorizontalList>
                                    {contacts.filter(ctct => handleListNameFilter(ctct)).map((contact,key) =>
                                        <HorizontalItem key={key}>
                                            <ContactCard contact={contact} onSelect={handleCardSelection} allowRemove={false}/>
                                        </HorizontalItem>
                                    )}
                                    </HorizontalList>
                                </HorizontalContainerWithSearch>
                        </div>
                    </div>
                }
                
                <Row style={{ marginTop: '2em' }}>
                   {signatures.length > 0 && signatures.map((signer,key) => {
                       return(
                           <Col key={key}>
                                <div style={{float:'right', cursor:'pointer'}} onClick={()=> removeSigner(key)}>
                                    <i className="fas fa-times"></i>
                                </div>
                                <div>
                                    <p>Päiväys: -</p>
                                    <p>{signer.business}</p>
                                </div>
                                <div className="signature-container underLine">
                                    <p className="signed-name">{signer.name}</p>
                                </div>
                                <div>
                                    <p>{signer.name}</p>
                                </div>
                           </Col>
                       )
                   })

                   }
                </Row>
                <Row>
                    <Col>
                        <div style={{ marginLeft:'0em', marginTop: '1.5em', display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '2em' }}>
                            <p style={{fontWeight: 'bold', opacity: 0.7 }}>Seuraa allekirjoitusta</p>
                            <div style={{ marginLeft:'1em', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <label className="switch">
                                    <input id="switch-input" type="checkbox" name="signingFollowed" onChange={(e) => setFollowSigning(!followSigning)} checked={followSigning}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Peruuta</a>
                    <button className="small-white-button wide-button" onClick={() => handleSave()}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

const EmptyList = ({ isLoading, error = "" }) => {
    return (
        <div className="list-placeholder-container">
            {isLoading ?
                <ListLoadingSpinner />
                :
                <h3>{error === "" ? "Ei tuloksia" : error}</h3>
            }
        </div>
    )
}

const ListLoadingSpinner = () => {
    return (
        <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}
import * as ActionTypes from './ActionTypes';
import { confs } from '../../../modules/config';
import * as ActionCreator from '../../index';
export const store_Login = (payload) => {
    return {
        type: ActionTypes.CHECK_LOGIN,
        payload: payload
    }
}

export const updateFavourites = (payload) => {
    return {
        type: ActionTypes.UPDATE_FAVOURITES,
        payload: payload
    }
}

export const updateHelpLinks = (payload) => {
    return {
        type: ActionTypes.SET_HELPLINKS,
        payload:payload
    }
}

export const logoDownloaded = payload => {
    return {
        type: ActionTypes.CHANGE_DOWNLOAD_STATUS
    }
}

export const checkLogin = (params) => {
    return dispatch => {
        fetch(confs.url + 'login/checkLogin', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.isAuthenticated) {
                window.sessionStorage.setItem("token", responseJson.jwtToken);
                window.sessionStorage.setItem("userType", responseJson.userType);
                
                const userType = responseJson.userType || ''
                confs.setUrls( userType )

                dispatch(store_Login(responseJson));
            } else {
                dispatch(authFailed())
            }
        }).catch(error => {
            dispatch(ActionCreator.authFailed())
        })
    }
}

const agreement = payload => {
    return {
        type: ActionTypes.AGREEMENT,
        payload: payload.agreement
    }
}

export const checkExisting = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'login/checkAuthentication', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.isAuthenticated) {
                window.sessionStorage.setItem("token", responseJson.jwtToken);
                window.sessionStorage.setItem("userType", responseJson.userType);
                dispatch(store_Login(responseJson));
            } else {
                dispatch(authFailed())
            }
        }).catch(error => {
            dispatch(ActionCreator.authFailed())
        })
    }
}

const setFirstLoginDone = (payload) => {
    return {
        type: ActionTypes.SET_FIRST_LOGIN_DONE,
        payload: payload
    }
}

export const handleFirstLoginDone = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'user/firstLoginDone', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
        .then((responseJson) => {
            if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                dispatch(setFirstLoginDone(responseJson.firstTimeLoginDone));
            }
        }).catch(error => {
            dispatch(ActionCreator.authFailed())
        })
    }
}

export const acceptAgreement = params => {

    if(params.agreement) {
        let jwt = window.sessionStorage.getItem("token");

        let payload = {
            jwtToken: jwt
        };

        return dispatch => {
            fetch(confs.url + 'send/agreement', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
            .then((response) => {
                if(typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                    dispatch(ActionCreator.authFailed());
                } else {
                    dispatch(agreement({agreement: response}))
                }
            })
            .catch(error => {
            })
        }
    } else if(!params.agreement) {
        return dispatch => {
            dispatch(ActionCreator.authFailed())
        }
    }
}

export const authFailed = payload => {
    window.sessionStorage.removeItem("token");
    return {
        type: ActionTypes.AUTH_FAILED,
        payload: {
            isAuthenticated: false,
            authenticationFailed: true
        }
    }
}
import * as ActionTypes from './ActionTypes';
import { NotificationManager } from 'react-notifications';
import {authFailed, storeReport} from '../../../../store/index';
import {confs} from '../../../../modules/config';

const setControlGroups = data => {
    return {
        type: ActionTypes.SET_CONTROLGROUPS,
        payload: data
    }
}

const setRiskClassifications = data => {
    return {
        type: ActionTypes.SET_RISKCLASSIFICATIONS,
        payload: data
    }
}

export const fetchControlGroups = params => dispatch => {
    let payload = {
        ...params,
        jwtToken: window.sessionStorage.getItem("token")
    };

    fetch(confs.url + 'get/controlGroups', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then((response) => response.json())
        .then((responseJson) => {
            if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(authFailed());
            } else {
                dispatch(setControlGroups(responseJson));
            }
        }).catch(error => {
            NotificationManager.error("Valitettavasti pyyntö epäonnistui", "", 3000);
        })
}

export const fetchRiskClassifications = params => dispatch => {
    let payload = {
        ...params,
        jwtToken: window.sessionStorage.getItem("token")
    };

    fetch(confs.url + 'get/riskClassifications', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then((response) => response.json())
        .then((responseJson) => {
            if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(authFailed());
            } else {
                dispatch(setRiskClassifications(responseJson));
            }
        }).catch(error => {
            NotificationManager.error("Valitettavasti pyyntö epäonnistui", "", 3000);
        })
}

export const saveTargetRisk = params => dispatch => {
    
    let payload = {
        ...params,
        jwtToken: window.sessionStorage.getItem("token")
    };
    let storedSurveyIds = null;
    if(params.activeReports !== undefined) {
        storedSurveyIds = [ ...params.activeReports ]
        const sentSurveyIds = []

        if(params.modifiedFromDashboard === undefined) {
        
            params.activeReports.map(report => sentSurveyIds.push({ sentSurveyId : report }))
            payload.activeReports = [
                ...sentSurveyIds
            ]
        }
    }

    fetch(confs.url + 'save/risk', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then((response) => response.json())
        .then((responseJson) => {
            if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(authFailed());
            } else {
                //dispatch(setRiskClassifications(responseJson));
                if(params.modifiedFromDashboard) {
                    dispatch(setRiskEvaluations(responseJson));
                } else {
                    dispatch(storeReport(responseJson.reports, storedSurveyIds));
                }
                NotificationManager.success("Riskiarvio tallennettu", "", 2000);
            }
        }).catch(error => {
            
            NotificationManager.error("Valitettavasti pyyntö epäonnistui", "", 3000);
        })
}
const SET_RISK_EVALUATIONS = "SET_RISK_EVALUATIONS";

const setRiskEvaluations = data => {
    return {
      type: SET_RISK_EVALUATIONS,
      payload: data
    }
  }
import * as ActionTypes from '../actions/UtilActions/ActionTypes';

const initialState = {
    enterpriseDownloads: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_ENTERPRISE_DOWNLOADS:
            return {
                ...state,
                enterpriseDownloads: [...action.payload]
            }
        default:
            return state
    }
}

export default reducer;
import React, {useState, useEffect} from 'react';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalFooter, ModalBody, Label, Input} from 'reactstrap';
import {ModifiedContainer} from './DataContainers';
import MultiSelect from "@kenshooui/react-multi-select";
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import { TagHandler } from '../../Product/subComponents/TagHandler';

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi tuotetta...",
        noItemsMessage: "Valitse tuote",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
}

const INITIAL_STATE = {
    productName: "",
    price: null,
    tags:[],
    description: null
}

export const ProductBlock = ({item,editable,onDelete,toggleItemVisibility, index, saveProducts, promptSave, planId, layoutPreview = false}) => {
    const [openMenu, toggleMenu] = useState(false)
    const [products, setProducts] = useState(item.products ? item.products : [])
    const [title, setTitle] = useState(item.topicContent)
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)
    const [productOptions, setProductOptions] = useState([])
    const [showSummary, setShowSummary] = useState(item && item.showSummary !== undefined ? item.showSummary : true )
    const [createModal, setCreateModal] = useState(false)
    const [newProduct, setNewProduct] = useState(INITIAL_STATE)

    const handleSelectedProducts = (selectedProducts) => {
        setProducts(selectedProducts)
    }

    const handleDataChanges = (data) => {
        setTitle(data)
        let itemPayload = {
            ...item,
            topicContent: data
        }
        promptSave(itemPayload)
    }

    const handleChange = (target, product) => {
        const {value, id} = target
        let array = products
        array.map(object => {
            if(object.id === product.id){
                object[id] = value
            }
        })
        setProducts([...array])
       let itemPayload = {
            ...item,
            showSummary: showSummary,
            products:products,
            topicContent:title
        }
        promptSave(itemPayload)
    }

    const cancel = () => {
        setProducts(item.products)
        setAddModalOpen(!addModalOpen)
    }

    useEffect(()=> {
        if(products && products.length > 0){
            let total = 0
            products.map(product => 
                total += (product.price * product.amount)
            )
            setTotalAmount(total.toFixed(2))
        }
    },[products])

    const handleSave = (data) => {
        let itemPayload = {
            ...item,
            showSummary: showSummary,
            products: data,
            topicContent: title
        }
        saveProducts(itemPayload)
        setAddModalOpen(false)
    }

    const getProductOptions = async() => {

        const response = await executeRequest('product/getProductOptions',{})

        if(response.products){
            setProductOptions(response.products)
        }   
    }

    useEffect(()=>{
        getProductOptions()
    },[])

    const toggleSummary = (param) => {
        setShowSummary(param)
        let itemPayload = {
            ...item,
            showSummary: param,
            products:products,
            topicContent:title
        }
        promptSave(itemPayload)
    }

    const addNewProduct = (id) => {
        let list = products
        let nProduct = newProduct
        let payload = {
            ...nProduct,
            id: id,
            amount: 1,
            label: nProduct.productName
        }
        if(list.length > 0){
            list = [...list, payload]
        } else {
            list = [payload]
        }
        handleSelectedProducts([...list])
        handleSave([...list])
        setNewProduct(INITIAL_STATE)
        getProductOptions()
    }

    return (
        Object.keys(item).length > 0 ?
            <>
                <NewProductModal
                    isOpen={createModal} 
                    toggleModal={setCreateModal}
                    setProduct={setNewProduct}
                    product={newProduct}
                    addToList={addNewProduct}
                    planId={planId}
                />
                <Modal size="lg" isOpen={addModalOpen} toggle={() => setAddModalOpen(!addModalOpen)}>
                    <ModalHeader toggle={() => setAddModalOpen(!addModalOpen)}>
                        Valitse tuotteita
                    </ModalHeader>
                    <ModalBody>
                        <Label>Valitse tuotteet</Label>
                        <MultiSelect
                            messages={customMessages.messages}
                            items={productOptions}
                            selectedItems={products}
                            onChange={handleSelectedProducts}
                            wrapperClassName="multi-selector round-corners"
                        />
                        {products && 
                            <Col className="product-summary-container" style={{ marginTop: '1em'}}>
                                <Row className="product-headers-row">
                                    <Col xs="4">
                                        <Label>Nimi</Label>
                                    </Col>
                                    <Col xs="2">
                                        <Label>Yksikkö</Label>
                                    </Col>
                                    <Col xs="2">
                                        <Label>Hinta (€)</Label>
                                    </Col>
                                    <Col xs="2">
                                        <Label>Määrä (á)</Label>
                                    </Col>
                                    <Col xs="2">
                                        <Label>Yhteensä</Label>
                                    </Col>
                                </Row>
                                {products.map((product, key) => {
                                    return(
                                        <Row key={key} className="product-content-block">
                                            <Col xs="4">
                                                <p>{product.label}</p>
                                            </Col>
                                            <Col xs="2">
                                                <p>{product.unit}</p>
                                            </Col>
                                            <Col xs="2">
                                                <Input id="price" type="number" value={product.price} placeholder="-" onChange={(e)=> handleChange(e.target, product)}/>
                                            </Col>
                                            <Col xs="2">
                                                <Input id="amount" type="number" value={product.amount} placeholder="-" onChange={(e)=> handleChange(e.target, product)}/>
                                            </Col>
                                            <Col xs="2">
                                                <p>{(product.price * product.amount).toFixed(2)}</p>
                                            </Col>
                                        </Row> 
                                    )
                                })}
                                <Row className="product-summary-row">
                                    <Col xs="10">
                                        <p>Yhteensä:</p>
                                    </Col>
                                    <Col xs="2">
                                        <p><b>{totalAmount}€</b></p>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </ModalBody>
                    <ModalFooter>
                            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}onClick={() => cancel()}>Peruuta</a>
                            <button className="small-white-button wide-button"  onClick={() => handleSave(products)}>Tallenna</button>
                    </ModalFooter>
                </Modal>
                <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                    <Col xl="12">
                        {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red'}}>Yksityinen</span>}
                        {(!editable && !layoutPreview && item.modified !== undefined) && 
                            <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                        }
                    </Col>
                    <Col xl="12" style={{marginBottom: 8}} className="title-col">
                        <div style={{ display: 'flex' }}>
                            {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}<i className="fas fa-tags" style={{ marginLeft: '0.5em' }}></i> </div>}      
                            <TextEditor 
                                editorId={`${item.id}-${editable}`} 
                                content={title}
                                onTextChange={(data) => handleDataChanges(data)} 
                                additionalClass="plan-input plan-subtitle subTitle"
                                placeholder="-"
                                plaintextEditor={true}
                                readOnly={!editable}
                            />
                        </div>
                    </Col>
                    <Col xl="11" style={{paddingTop: '0.8em'}} className="plan-text-col"> 
                        {products &&     
                            <div className="plan-product-row" style={{display:'flex', flexDirection:'row'}}>
                                <Col className="product-summary-container">
                                    <Row className="product-headers-row">
                                        <Col xs="4">
                                            <Label>Nimi</Label>
                                        </Col>
                                        <Col xs="2">
                                            <Label>Yksikkö</Label>
                                        </Col>
                                        <Col xs="2">
                                            <Label>Hinta (€)</Label>
                                        </Col>
                                        <Col xs="2">
                                            <Label>Määrä (á)</Label>
                                        </Col>
                                        <Col xs="2">
                                            <Label>Yhteensä</Label>
                                        </Col>
                                    </Row>
                                    {editable ?
                                        products.map((product, key) => {
                                            return(
                                                <Row key={key} className="product-content-block">
                                                    <Col xs="4">
                                                        <p>{product.label}</p>
                                                    </Col>
                                                    <Col xs="2">
                                                        <p>{product.unit}</p>
                                                    </Col>
                                                    <Col xs="2">
                                                        <Input id="price" type="number" value={product.price} placeholder="-" onChange={(e)=> handleChange(e.target, product)}/>
                                                    </Col>
                                                    <Col xs="2">
                                                        <Input id="amount" type="number" value={product.amount} placeholder="-" onChange={(e)=> handleChange(e.target, product)}/>
                                                    </Col>
                                                    <Col xs="2">
                                                        <p>{(product.price * product.amount).toFixed(2)}</p>
                                                    </Col>
                                                </Row> 
                                            )
                                        }) 
                                        :
                                        products.map((product, key) => {
                                            return(
                                                <Row key={key} className="product-content-block">
                                                    <Col xs="4">
                                                        <p>{product.label}</p>
                                                    </Col>
                                                    <Col xs="2">
                                                        <p>{product.unit}</p>
                                                    </Col>
                                                    <Col xs="2">
                                                        <p>{product.price}</p>
                                                    </Col>
                                                    <Col xs="2">
                                                        <p>{product.amount}</p>
                                                    </Col>
                                                    <Col xs="2">
                                                        <p>{(product.price * product.amount).toFixed(2)}</p>
                                                    </Col>
                                                </Row> 
                                            )
                                        })
                                    }
                                    <div className="product-summary-row">
                                        {showSummary &&
                                            <Row>
                                                <Col xs="10">
                                                    <p>Yhteensä: </p>
                                                </Col>
                                                <Col xs="2">
                                                    <p><b>{totalAmount}€</b></p>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </Col>
                            </div>
                        }  
                    </Col>
                    <Col xl="1" style={{paddingLeft: 15}} className="plan-dropdown-col">
                        <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                        {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                            <DropdownToggle tag="div">
                                <i className="fas fa-bars plusIcon" title="Valikko"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                                <DropdownItem onClick={() => setAddModalOpen(true)} className="delete-plan-item">Valitse tuotteita</DropdownItem>
                                <DropdownItem onClick={() => setCreateModal(true)} className="delete-plan-item">Luo uusi tuote</DropdownItem>
                                {showSummary ?
                                    <DropdownItem onClick={() => toggleSummary(false)} className="delete-plan-item">Piilota yhteensä-rivi</DropdownItem>
                                    :
                                    <DropdownItem onClick={() => toggleSummary(true)} className="delete-plan-item">Näytä yhteensä-rivi</DropdownItem>
                                }
                                <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.blockName})} className="delete-plan-item">Poista</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>}
                        </div>
                    </Col>
                </Row>
            </>
        :
        null
    )

}

const NewProductModal = ({isOpen, toggleModal, setProduct, product, addToList, planId}) => {
    
    const handleChange = (data) => {
        const { id, value } = data
        setProduct(prevData => ({
            ...prevData,
            [id]:value
        }))
    }

    const handleCreate = async() => {
        let payload = {
            product: product
        }
        const response = await executeRequest('product/createNewProduct', payload)
        if(response.success && response.id){
            toggleModal(false)
            addToList(response.id) 
        }
    }

    return(
        <Modal size="lg" isOpen={isOpen} toggle={() => toggleModal(false)}>
             <ModalHeader toggle={() => toggleModal(false)}>Uusi tuote</ModalHeader>
                <ModalBody>
                    <div style={{ marginBottom: '1em' }}>
                        <Label>Tuotteen nimi</Label>
                        <Input id="productName" value={product.productName} onChange={(e)=> handleChange(e.target)}></Input>
                    </div>
                    <div style={{ marginBottom: '1em' }}>   
                        <Label>Tuotteen hinta</Label>
                        <Input id="price" type='number' value={product.price} onChange={(e)=> handleChange(e.target)}></Input>
                    </div>   
                    <div style={{ marginBottom: '1em' }}>   
                        <Label>Yksikkö</Label>
                        <Input id="unit" type='text' value={product.unit} placeholder="esim. tunti, päivä, kerta" onChange={(e)=> handleChange(e.target)}></Input>
                    </div>   
                    <div style={{ marginBottom: '1em' }}>  
                        <Label>Lisää tuotteelle tageja</Label>
                        <TagHandler
                            tags={product.tags}
                            handleChange={handleChange}
                        />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Label>Kuvaus</Label>
                        <TextEditor
                            placeholder=" -" 
                            // value={description}                        
                            content={product.description} 
                            onTextChange={(val) => handleChange({ id: "description", value: val})} 
                            readOnly={false}  
                            connectionType={"plans"}
                            documentId={planId} 
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => toggleModal(false)} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                    {product.productName && product.productName.length > 0 && 
                        <button onClick={() => handleCreate()} className="small-white-button wide-button" >Luo uusi</button>
                    }
                </ModalFooter>
        </Modal>
    )
}
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup } from 'reactstrap'
import Select from 'react-select'
import { NotificationManager } from 'react-notifications'

import { confs } from '../../../modules/config'


export const UploadExcel = ({ isOpen, toggle, saveBusinessExcel, hasHubSpot }) => {
    const [title, setTitle] = useState('')
    const [headers, setHeaders] = useState([])
    const [file, setFile] = useState(null)
    const [selectedBusinessId, setSelectedBusinessId] = useState(null)
    const [selectedBusinessName, setSelectedBusinessName] = useState(null)
    const [hubExcelChecked, setHubExcelChecked] = useState(false)
    const [portalId, setPortalId] = useState("")

    const handleFile = async (e) => {
        let formData = new FormData()
        formData.append("file", e.target.files[0], e.target.files[0].name)
        formData.append("jwtToken", window.sessionStorage.getItem("token"))
        formData.append("name", title)
        setFile(e.target.files[0])

        const resultJson = await fetch(confs.url + 'excel/file/getHeaders', {
            method: 'POST',
            body: formData
        })
        const result = await resultJson.json();
        if (result.data && result.data.length > 0) {
            setHeaders(result.data)
        } else {
            NotificationManager.error('Tiedostoa ei voitu ladata', '', 2000)
        }
    }

    useEffect(() => {
        if(!isOpen){
            setTitle('')
            setHeaders([])
            setFile(null)
            setSelectedBusinessId(null)
        }
    }, [isOpen])

    return <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader>
            <p>Lataa excel tiedosto</p>
        </ModalHeader>
        <ModalBody>
            <Row>
                {!headers.length > 0 ?
                    <>
                        <Col xs="12" style={{ marginTop: '1em', marginBottom: '1em' }}>
                            <Label>Nimi</Label>
                            <Input name="excelname" value={title} onChange={(e) => setTitle(e.target.value)}></Input>
                        </Col>
                        {title && title.length > 0 &&
                            <>
                                {hasHubSpot &&
                                    <Col xs="12">
                                        <FormGroup check>
                                            <Label check>
                                                <Input className="checkBox" type="checkbox" onChange={() => setHubExcelChecked(!hubExcelChecked)} checked={hubExcelChecked} /> {' '}
                                                (<b>Huom!</b> HubSpot lataus) Tuo ja liitä asiakkaiden id:t
                                            </Label>
                                        </FormGroup>   
                                    </Col>
                                }
                                <Col xs="12" style={{ marginBottom: '1em', marginTop:'1em' }}>
                                    <Label>Valitse tiedosto</Label>
                                    {/* <Input name="url" onChange={(e) => props.modifyValue(e)} ></Input> */}
                                    <Input style={{ marginTop: '.5em' }} type="file" name="excel" onChange={handleFile} />
                                </Col>
                                
                            </> 
                        }
                    </>
                    :
                    <Col>
                        <Label>Valitse y-tunnus sarake</Label>
                        <Select options={headers}
                            onChange={(event) => setSelectedBusinessId(event)}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            classNamePrefix="select"
                        />
                        {hubExcelChecked && 
                            <>
                                <Label style={{marginTop:'1em'}}>Valitse yritysten nimi sarake</Label>
                                <Select options={headers}
                                    onChange={(event) => setSelectedBusinessName(event)}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.name}
                                    classNamePrefix="select"
                                />
                                <Label style={{marginTop:'1em'}}>HubSpot portaalin tunniste (vapaaehtoinen, mutta tarvitaan Deal-ominaisuuteen linkittämiseen)</Label>
                                <Input value={portalId} onChange={(e)=> setPortalId(e.target.value)}/>
                            </>
                        }
                    </Col>
                }
            </Row>

        </ModalBody>
        <ModalFooter>
            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
            {selectedBusinessId &&
                <button className="small-white-button wide-button" onClick={() => saveBusinessExcel({file, title, selectedBusinessId, hubExcelChecked, selectedBusinessName, portalId})}>Tallenna</button>
            }
        </ModalFooter>


    </Modal>

}
import React, { useState, useEffect } from 'react';
import { Collapse, Col, Row, Label, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import * as ActionCreator from '../../../store/index';
import { useDispatch, useStore } from 'react-redux';
import Select from 'react-select';
import moment from 'moment'
import { DateRangePicker } from 'react-dates';
import { NotificationManager } from 'react-notifications';  
import BackgroundEditor from '../../Kartoitus/SenderV2/subComponents/BackgroundEditor';
import { confs } from '../../../modules/config';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';

export const SentSurveyOptions = ({isOpen, closeModal, survey, history}) => {
    const { code, name } = survey
    const [surveyToUpdate, setSurveyToUpdate] = useState(survey)
    const [data, setData] = useState()
    const [datesModal, setDatesModal] = useState(false)
    const [sDate, setSDate] = useState({})
    const [dateToChangeInd, setDateToChangeInd] = useState(null)
    const [modifyDates, setModifyDates] = useState({})
    const [modifierFocus, setModifierFocus] = useState()
    const [editBackground, setEditBackGround] = useState(false)
    const [backgroundList, setBackgroundList] = useState([])

    const dispatch = useDispatch()

    const goToReport = () => {
        const params = {
            reports: [
                {
                    reportId: survey._id
                }
            ]
        }

        dispatch(ActionCreator.getReportForDashboard(params))

        if (survey.businessName !== "Ei kohdeyritystä") {
            dispatch(ActionCreator.getBusinessSurveyTree({ businessId: survey.businessID }))
            history.push({
                pathname:'/raportit/yritys/raportti',
            })
        } else {
            dispatch(ActionCreator.storeBusiness({ name: 'Ei kohdeyritystä' }))
            history.push({
                pathname:'/raportit/kohdistamaton/raportti',    
            })
            
        }
    }

    const startEditing = () => {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            surveyId: survey.surveys[0]._id
        }

        fetch(confs.url + 'fetch/mainSurveyForEdit', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => response.json())
            .then(resp => {

                if (Object.keys(resp).length > 0) {
                    onSurveyEdit(
                        {
                            categoryToEdit: resp,
                            selectedSurvey: {value:survey._id, label: survey.name},
                            start: true
                        }
                    );
                    history.push('/kartoitukset/luonti')

                }
            })
    }

    const onSurveyEdit = (payload) => {
        dispatch(ActionCreator.startSurveyEditFromSentSurveys(payload))
    } 

    const toggleDateModal = () => {
        setDatesModal(!datesModal)
        setSurveyToUpdate(survey)
    }

    const  isDayBlocked = (day) => {
        let compare = moment(new Date(day))
        if(surveyToUpdate && surveyToUpdate.dates){
            for (let date of surveyToUpdate.dates) {
                let start = moment(new Date(date.startDate))
                let end = moment(new Date(date.endDate))
                if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                    return true;
                }
            } 
            return false;
        }
    }
    
    const isDayHighlighted = (day) => {
        let compare = moment(new Date(day))
        if(surveyToUpdate && surveyToUpdate.dates){
            for (let date of this.state.datesList) {
                let start = moment(new Date(date.startDate._d))
                let end = moment(new Date(date.endDate._d))

                if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                    return true;
                }
            }
            return false;
        }
    }

    const onDatePickerFocusChange = (focusedInput) => {
        setModifierFocus(focusedInput)
    }
    const changeModifierDate = (dates) => {
        let formatedDates = {
            startDate: new Date(moment.utc(dates.startDate, "MMM D, YYYY, HH:mm:ss A", "en").local()),
            endDate: new Date(dates.endDate),
        }
        setSDate({ startDate: dates.startDate, endDate: dates.endDate });

        if (dates.endDate !== null && dates.startDate !== null) {
            let obj = []
            if (dateToChangeInd !== null && modifyDates.length > 0) {
                if (dateToChangeInd === -1) {
                    obj = [...modifyDates, formatedDates]
                } else {
                    obj = [...modifyDates];
                    obj[dateToChangeInd] = formatedDates;
                }
            } else {
                obj = [dates];
            }

            setModifyDates(obj);
            
            if (dateToChangeInd === -1) {
                setDateToChangeInd(obj.length - 1)
            }
            //this.setState({ dateToChangeInd: null });
        }
    }

    const changeModifiedDateIndex = (dateobj, ind) => {
        if (ind !== -1) {
            setSDate({ startDate: moment(new Date(dateobj.startDate)), endDate: moment(new Date(dateobj.endDate)) })
        } else {
            setSDate({ startDate: null, endDate: null })
        }
        setDateToChangeInd(ind)
    }

    useEffect(() => {
        if(!datesModal){
            setDateToChangeInd(null)
        }
    },[datesModal])

    const removeDateFromList = (ind) => {
        if (datesModal) {
            let obj = [...modifyDates]
            obj.splice(ind, 1)
            setModifyDates( obj) 
        }
    }
    useEffect(()=>{
        if(surveyToUpdate && surveyToUpdate.dates){
            setModifyDates(surveyToUpdate.dates)
        }
    },[surveyToUpdate])

    const saveTarget = async(survey, {target, value}) => {

        let params = {
            sentSurveyId:survey._id,
            change: target,
            data: value
        }
        const response = await executeRequest('project/updateSentSurvey', params)
        if(response.success && response.updated){
            //update changes to redux (this when redux is used for surveys in dashboard)
            dispatch(ActionCreator.updateSelectedSentSurvey(response.updated))
        }
        // setModalIsOpen(false)
        // setNameModalActive(false)
        setDatesModal(false)
        setSDate({startDate:null, endDate:null})

    }

    const toggleBackground =() => {
        setEditBackGround(false)
    }
    
    
    const getBackgrounds = async() => {
        const result = await executeRequest('sender/get/backgroundList', {})       
        if(result){
            setBackgroundList(result)
        }
    }

    useEffect(()=>{
        getBackgrounds()
    },[])

    const modifyBackground = (props) => {
        setEditBackGround(true)
        setSurveyToUpdate(props)
    }

    const uploadBackground = async(payload) => {
        try {
            const responseJson = await fetch(confs.url + 'sender/uploadBackground', {
                method: 'POST',
                body: payload
            })
    
            if(responseJson) {
                const response = await responseJson.json()
                getBackgrounds()
                return response;
            } else {
                return false;
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }
    const saveModifiedBackground = async(obj) => {
        let payload = {
            sentSurvey: surveyToUpdate.backgroundEdit,
            background: obj
        }
        const result = await executeRequest('sender/modify/background', payload)
        setSurveyToUpdate((prevData)=>({
            ...prevData,
            backgroundEdit: null, 
            background: null
        }))
        setEditBackGround(false)
    }
    const saveBackground = async(obj) => {
        setSurveyToUpdate((prevData)=>({
            ...prevData,
            background: obj, 
            backgroundSet: true
        }))
        let payload = {
            sentSurvey:surveyToUpdate,
            background:obj
        }
        const result = await executeRequest('sender/modify/background', payload)
        setEditBackGround(false)
    }

    return(
        <>
            <ModifyDateModal
                changeModifierDate={changeModifierDate}
                modifierFocus={onDatePickerFocusChange}
                focusedInput={modifierFocus}
                save={saveTarget}
                isOpen={datesModal}
                toggle={setDatesModal}
                dates={modifyDates}
                sDate={sDate}
                setIndex={changeModifiedDateIndex}
                modifyIndex={dateToChangeInd}
                removeDate={removeDateFromList}
                isDayBlocked={isDayBlocked}
                sentSurvey={surveyToUpdate}
                setSurveyToUpdate={setSurveyToUpdate}
            />  
            <BackgroundEditor
                isOpen={editBackground}
                toggle={toggleBackground}
                background={survey.background}
                list={backgroundList}
                save={survey.backgroundEdit ? saveModifiedBackground : saveBackground}
                uploadBackground={uploadBackground}
            />
            <Modal isOpen={isOpen} toggle={() => closeModal(false)}>
                <ModalHeader className="" toggle={() => closeModal(false)}>
                    <p style={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth:'18em', whiteSpace: 'nowrap'}} title={name}> {name} </p>
                </ModalHeader>
                <ModalBody>
                    <Col>
                        {/* <Row>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <p>Nimi: <span style={{ fontWeight: 'bold' }}>{name}</span></p> 
                            </div>
                        </Row> */}
                        <Row>
                            <p style={{ }}>Koodi: <span style={{ fontWeight: 'bold' }}>{code}</span></p>
                        </Row>
                        <Row>
                            <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }}  onClick={ () => window.open( `${ confs.frontpageUrl }/${ survey.code }`, "_blank", "noopener" ) }>Avaa kartoitus</button>
                        </Row>
                        <Row>
                            <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }} onClick={() => startEditing()}>Muokkaa kartoitusta</button>
                        </Row>
                        <Row>
                            <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }} onClick={() => setEditBackGround(true)}>Muokkaa taustakuvaa</button>
                        </Row>
                        <Row>
                            <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }} onClick={() => toggleDateModal()}>Muokkaa aikaväliä</button>
                        </Row>
                        <Row>
                            <button className="small-white-button wide-button" style={{ width: '100%', marginTop: '1em' }} onClick={() => goToReport()}>Siirry raporttiin</button>
                        </Row>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => closeModal(false)}><p>Peruuta</p></a>
                </ModalFooter>
            </Modal>
        </>
    )
}

const ModifyDateModal = props => {
    const {save, setIndex, focusedInput} = props
    const closeBtn = <button className="close" onClick={() => props.toggle(false)}>&times;</button>;
    const promptSave = () => {
        if(props.sentSurvey && props.sentSurvey.dates !== props.dates){
            for(var date of props.dates){
                date.startDate = moment.utc(date.startDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
                date.endDate = moment.utc(date.endDate, "MMM D, YYYY, HH:mm:ss A", "en").local()
            }
            save(props.sentSurvey, {target:"dates",value:props.dates})
            props.setSurveyToUpdate(prevProps => ({
                ...prevProps,
                dates: [...props.dates]
            }))
            NotificationManager.success('Päivämäärät muutettu onnistuneesti', 'Ilmoitus', 4000)
        } else {
            NotificationManager.error('Päivämäärille ei ole tehty muutoksia', 'Virhe', 4000)
        }
    }
    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className} >
                <ModalHeader toggle={props.toggle} close={closeBtn}>Aktiivisen aikavälin muutos</ModalHeader>
                <ModalBody style={{ paddingBottom: 0, paddingTop: 0 }}>
                    {props.dates ?
                        <div style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                            {props.modifyIndex !== null &&
                                <div style={{ margin: "1em 0" }}>
                                    <Row style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                                        <Col xl="6">
                                            <div className="lighter-text">Alku</div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="lighter-text">Loppu</div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                                        <Col xl="12">
                                            <DateRangePicker
                                                readOnly
                                                startDate={props.sDate.startDate} // momentPropTypes.momentObj or null,
                                                startDateId="unique_start_" // PropTypes.string.isRequired,
                                                endDate={props.sDate.endDate} // momentPropTypes.momentObj or null,
                                                endDateId="unique_enddate_" // PropTypes.string.isRequired,
                                                onDatesChange={({ startDate, endDate }) => props.changeModifierDate({ startDate, endDate })} // PropTypes.func.isRequired,
                                                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                onFocusChange={focusedInput => props.modifierFocus(focusedInput)}
                                                block
                                                hideKeyboardShortcutsPanel={true}
                                                isDayHighlighted={props.isDayBlocked}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row style={{ marginBottom: '0.5em', marginTop: '1em' }}>
                                <Col>
                                    <button className="small-white-button" onClick={() => setIndex(null, -1)}><i className="far fa-calendar-plus lighter-text"></i><span style={{paddingLeft: '0.5em'}}>Lisää aika</span></button>
                                </Col>
                            </Row >
                            {props.dates.length > 0 && props.dates.map((date, index) => {
                                let start = moment(new Date(date.startDate)).local().format('DD.MM.YYYY')
                                let end = moment(new Date(date.endDate)).local().format('DD.MM.YYYY')
                                let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row';
                                let highlight = props.modifyIndex === index ? " highlight-row" : "";

                                return <Row key={index} style={{ marginBottom: '0.5em', marginTop: '1em' }} className={"modify-dates-list " + oddEvenClass + highlight} >
                                    <Col md="10">{start} - {end}</Col>
                                    <Col md="1" onClick={() => props.setIndex(date, index)}><i style={{ cursor: 'pointer' }} title="Edit dates" className="far fa-edit"></i></Col>
                                    {props.dates.length > 1 &&
                                        <Col md="1" onClick={() => props.removeDate(index)}><i style={{ cursor: 'pointer' }} className="far fa-trash-alt"></i></Col>
                                    }
                                </Row>
                            })}
                        </div>
                        :
                        <div style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                            <Row style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                                <Col xl="6">
                                    <h5>Alku</h5>
                                </Col>
                                <Col xl="6">
                                    <h5>Loppu</h5>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>
                                <Col xl="12">
                                    <DateRangePicker
                                        readOnly
                                        startDate={props.sDate.startDate} // momentPropTypes.momentObj or null,
                                        startDateId="unique_startdate_" // PropTypes.string.isRequired,
                                        endDate={props.sDate.endDate} // momentPropTypes.momentObj or null,
                                        endDateId="unique_enddate" // PropTypes.string.isRequired,
                                        onDatesChange={({ startDate, endDate }) => props.changeModifierDate({ startDate, endDate })} // PropTypes.func.isRequired,
                                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={focusedInput => props.modifierFocus(focusedInput)}
                                        block
                                        hideKeyboardShortcutsPanel={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <a onClick={() => props.toggle()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a> {' '}
                    <button onClick={() => promptSave()} className="small-white-button wide-button" style={{ }}>Tallenna</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
import React, {Component} from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';

class ActiveSurveysModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSurveys: []
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.activeSurveys !== this.props.activeSurveys) {
            let updateList = [
                
            ]

            this.props.activeSurveys.map((survey, index) => {
                let newItem = {
                    ...survey,
                    selected: false
                };
                updateList.push(newItem);
            })

            this.setState({activeSurveys: updateList});
        }
    }

    toggleSelection = (survey, index) => {
        let list = [
            ...this.state.activeSurveys
        ];

        let listObject = {
            ...list[index],
            selected: !list[index].selected
        };

        list[index] = {
            ...listObject
        };

        this.setState({activeSurveys: list});
    }

    render() {
        const {activeSurveys} = this.state;
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg" centered>
                <ModalHeader toggle={this.props.toggle} className="taskModal">
                    <div>
                        Valitse päivitettävät kartoitukset
                    </div> 
                </ModalHeader>
                <ModalBody>
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
                    style={{width: "100%", height: '500px'}}
                    className="custom-scrollbars"
                    >
                    
                    {activeSurveys.length > 0 ? activeSurveys.map((sentSurvey, index) => {

                    const activatedDate = moment(new Date(sentSurvey.activatedDate)).format('DD.MM.YYYY')
                    
                    return <Row className="dashboard-survey-row sent-survey-row" key={index} onClick={() => this.toggleSelection(sentSurvey, index)}>
                                <Col xs={{size: 3}}>
                                    <button  style={{marginTop: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem'}} className={this.state.activeSurveys[index].selected ? "small-white-button lighter-text selected" : "small-white-button lighter-text"} onClick={() => this.toggleSelection(sentSurvey, index)}>
                                    {this.state.activeSurveys[index].selected ? "Päivitetään": "Valitse"}</button>
                                </Col>
                                <Col xs={{size: 3}}>
                                    <Row>
                                        <Col xs={{size: 12}}>
                                            <p className="big-text mb-0">{ sentSurvey.surveyName }</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{size: 12}}>
                                            <p className="lighter-text">{ `Aktivoitu: ${activatedDate}` }</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{size: 3}} style={{textAlign: 'center'}} className="content-text">
                                    <p>{ sentSurvey.businessName }</p>
                                </Col>
                                <Col xs={{size: 3}} style={{textAlign: 'center'}} className="content-text">
                                    <p>{ sentSurvey.code }</p>
                                </Col>
                            </Row>
            
            
                    }) : 
                    <div>
                        <p className="empty-dashboard-row light-text">Ei aktiivisia kartoituksia</p>
                    </div>
                    }
                    </Scrollbars> 
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => this.props.toggle()}>Sulje</a>
                    {this.state.activeSurveys.filter(x => x.selected === true).length > 0 && <button className="small-white-button wide-button" onClick={() => this.props.startUpdatingSelectedSurveys(this.state.activeSurveys)}>Päivitä</button>}
                </ModalFooter>
            </Modal>
        )
    }
}

export default ActiveSurveysModal;
import React, { useState, useEffect } from 'react';
import { executeRequest } from '../Utilities/requestHandler/dataHandler';

const CustomStyleWrapper = (WrappedComponent) => {
    return class HightlightColors extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                isLoading: true
            }
            this.getStyles = this.getStyles.bind(this);
        }

        getStyles = async () => {

            const styles = await executeRequest('login/getEnterpriseStyles', {})
            if(styles && styles.css){
                Object.keys(styles.css).map(key => {
                    document.documentElement.style.setProperty(key, styles.css[key])
                })
                this.setState({isLoading: false})
            } else {
                this.setState({isLoading: false})
            }
        }

        componentDidMount() {
            this.getStyles();
        }

        render() {
            if(this.state.isLoading){
                return(
                    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <ListLoadingSpinner/>
                    </div>
                )
            } 
            return (
                <WrappedComponent />
            )
        }
    }
}

const ListLoadingSpinner = () => {
        return (
            <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        )
    }

export default CustomStyleWrapper;
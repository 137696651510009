import React, {useState, useEffect, useRef} from 'react';
import {Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalFooter, ModalBody, Label, Input} from 'reactstrap';
import {executeRequest} from '../../../../Utilities/requestHandler/dataHandler';
import {ModifiedContainer} from '../DataContainers';
import TextEditor from '../../../../Utilities/TextEditor/texteditor';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications'
import moment from 'moment';
import { HorizontalContainer, HorizontalContainerWithSearch, HorizontalItem, HorizontalList } from '../../../../Utilities/horizontal-scroller/HorizontalElements';
import { HubSpotDealCard } from '../../../../Utilities/horizontal-scroller/HorizontalCards';
import { VerticalContainer, VerticalContainerWithSearch, VerticalItem, VerticalList } from '../../../../Utilities/ListView/ListElements';
import { HubDealListItem } from '../../../../Utilities/ListView/ListItems';
import '../../plan.css';
import { updatePlanData } from '../../Actions/ActionCreator';

export const HubSpotBlock = ({item,editable,onDelete,toggleItemVisibility, index, plan, saveWithoutQueue, selectedBusiness, promptSave = false, layoutPreview = false}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [title, setTitle] = useState(item.topicContent)
    const [openMenu,setOpenMenu] = useState(false)
    const [selectedDeal, setSelectedDeal] = useState((item && item.selectedDeal) ? item.selectedDeal : false)
    const [stageLabel, setStageLabel ] = useState((item && item.stageLabel) ? item.stageLabel : "")
    const [summaryItem, setSummaryItem] = useState(item.summaryItem)
    const [selectedContact, setSelectedContact] = useState((item && item.selectedContact) ? item.selectedContact : false)
    const dispatch = useDispatch()

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    useEffect(()=>{
        if(item && item.selectedDeal){
            setSelectedDeal(item.selectedDeal)
        }

        if(item && item.stageLabel) {
            setStageLabel(item.stageLabel)
        }

    },[item, plan])

    const toggleMenu = (param) => {
        setOpenMenu(param)
    } 

    function handleDataChanges(type, data) {
        if(type === 'title') {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                selectedDeal: selectedDeal,
                stageLabel: stageLabel,
                type: item.type,
            }
            //onItemChange(itemPayload);
            promptSave(itemPayload);
        } else {
            setSummaryItem(data);
            let itemPayload = {
                id: item.id,
                topicContent: title,
                selectedDeal: selectedDeal,
                stageLabel: stageLabel,
                type: item.type,
                summaryItem: data
            }
            //onItemChange(itemPayload);
            promptSave(itemPayload);
        }
    }

    const updateDealToHubSpot = async() => {
        let payload = {
            planId: plan._id,
            businessId: selectedBusiness.value,
            planItemId:item.id
        }
        const response = await executeRequest('plan/updateToHubSpot', payload)
        
        if(response && response.updated && response.updated.length > 0 && !response.error && (!response.updated[0].status || response.updated[0].status < 400)){
            NotificationManager.success("Linkki päivitetty HubSpot diilille","",3000)
            let itemPayload = {
                ...item,
                id: item.id,
                topicContent: title,
                selectedDeal: selectedDeal,
                stageLabel: stageLabel,
                type: item.type,
                linkUpdatedToHubSpot: true
            }
            saveWithoutQueue(itemPayload)
        }else{
            if(response && response.updated && response.updated.length > 0 && !response.error && (response.updated[0].status >= 400)){
                NotificationManager.error("Diiliä ei löytynyt","Virhe",3000)
            }
            NotificationManager.error("Linkin päivitys epäonnistui","Virhe",3000)
        }

    }

    return(
        <>
            <HubSpotEditModal
                isOpen={isOpen}
                toggle={toggle}
                item={item}
                creation={false}
                plan={plan}
                save={saveWithoutQueue}
            />
            
                <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                    <Col xl="12">
                        {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red' }}>Yksityinen</span>}
                        {(!editable && !layoutPreview && item.modified !== undefined) && 
                            <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                        }
                    </Col>
                    <Col xl="12" style={{marginBottom: 8}} className="title-col">
                        <div style={{ display: 'flex' }}>
                            {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}</div>}            
                            <TextEditor 
                                editorId={`${item.id}-subtitle-${editable}`} 
                                content={title} 
                                onTextChange={editorState => handleDataChanges("title", editorState)}
                                additionalClass="plan-input subTitle plan-subtitle"
                                placeholder="-"
                                readOnly={!editable}
                                plaintextEditor={true}
                            /> 
                        </div>
                    </Col>
                    {item &&
                        <Col xl="11" style={{marginBottom: 8}} className="title-col">
                            {!editable && item && !item.linkUpdatedToHubSpot && (plan && plan.status && plan.status === "published") &&
                                <div style={{ float:'right', position:'inherit', zIndex:'50' }}>
                                    <button className="small-white-button" onClick={()=>updateDealToHubSpot()}>Liitä julkaistu lomake dealiin</button>
                                </div>
                            }
                            {item&&item.linkUpdatedToHubSpot &&
                                <div style={{ float:'right', position:'inherit', zIndex:'50' }}>
                                    <p style={{ fontSize:'0.9rem', fontWeight:'bold', color:"#949494" }}>Lomake on liitetty dealiin</p>
                                </div>
                            }
                            {selectedDeal && 
                                <Col>
                                    <div style={{ display: 'flex'}}>
                                        <Col xs="4">
                                            <Row className="deal-info-row"><p>Nimi</p></Row>
                                            <Row className="deal-info-row"><p>Vaihe</p></Row>
                                            <Row className="deal-info-row"><p>Summa</p></Row>
                                            {!selectedDeal.isClosed && <Row className="deal-info-row">Sulkeutumisajankohta</Row>}
                                            {selectedContact && 
                                                <> 
                                                    <Row className="deal-info-row">Kontaktin nimi</Row>
                                                    <Row className="deal-info-row">Kontaktin email</Row>
                                                </>
                                            }
                                        </Col>
                                        <Col xs="8">
                                            <Row className="deal-info-row"><p>{selectedDeal.dealname}</p></Row> 
                                            <Row className="deal-info-row"><p>{stageLabel? stageLabel : ""}</p></Row>
                                            <Row className="deal-info-row">{selectedDeal.amount + " €"}</Row>
                                            {!selectedDeal.isClosed && <Row className="deal-info-row" style={{color: 'red'}}> {moment.utc(selectedDeal.closedate).format("DD.MM.YYYY HH:mm")}</Row>}
                                            {selectedContact && 
                                                <> 
                                                    <Row className="deal-info-row">{(selectedContact.firstName + " " + selectedContact.lastName)}</Row>
                                                    <Row className="deal-info-row">{selectedContact.email}</Row>
                                                </>
                                            }
                                        </Col>
                                    </div>
                                    {item && item.hubSpotLink && <Col><Row className="deal-info-row" style={{paddingLeft:'0.25em'}}><a href={item.hubSpotLink} target="_blank">{item.hubSpotLink}</a></Row></Col>}
                                </Col>
                            }
                        </Col>
                    }
                    <Col xl="1" style={{paddingLeft: 15}} className="plan-dropdown-col">
                        <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                        {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                            <DropdownToggle tag="div">
                                <i className="fas fa-bars plusIcon" title="Valikko"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => setIsOpen(true)} className="delete-plan-item">Muokkaa</DropdownItem>
                                {(plan && plan.status && plan.status === "published") && selectedBusiness && <DropdownItem onClick={() => updateDealToHubSpot()} className="send-plan-to-hubspot">Liitä julkaistu lomake Dealiin</DropdownItem>}
                                <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>}
                        </div>
                    </Col>
                </Row>
           
        </>
    )
}

export const HubSpotEditModal = ({isOpen, toggle, item, creation = false, plan, createHubSpotItem, save}) => {
    const [hubSpotOptions, setHubSpotOptions] = useState({})
    const [selectedOptions, setSelectedOptions] = useState({})
    const [selectedDeal, setSelectedDeal] = useState((item && item.selectedDeal) ? item.selectedDeal : false)
    const [hubDeals, setHubDeals] = useState([])
    const [hubPipelines, setHubPipelines] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPipeline, setSelectedPipeline] = useState({})
    const [selectedStageLabel, setSelectedStageLabel] = useState((item && item.stageLabel) ? item.stageLabel :"")
    const [hubPortalId, setHubPortalId] = useState("")
    const [hubLink, setHubLink] = useState(false)

    const newHubSpotItem = () => {
        let payload = {
            ...item.selectedItem,
            selectedDeal: selectedDeal,
            stageLabel: selectedStageLabel,
            hubSpotLink: hubLink? hubLink : null
        }
        createHubSpotItem(payload, item.destinationIndex, item.destClone, item.planId);
        toggle();
    }

    const saveHubSpotItem = () => {
        let payload = {
            ...item,
            selectedDeal: selectedDeal,
            stageLabel: selectedStageLabel,
            hubSpotLink: hubLink? hubLink : null
        }
        save(payload);
        toggle();
    }

    useEffect(()=>{
        getDeals()
        getPortalId()
    },[])

    const getPortalId = async() => {
        let payload = {
            businessId: plan.selectedBusiness.value
        }
        const response = await executeRequest("plan/checkHubData", payload)
        if(response && response.received){
            setHubPortalId(response.received)
        }
    }

    const getDeals = async() => {
        let payload = {
            businessId: plan.selectedBusiness.value
        }
        const response = await executeRequest("plan/getHubSpotDeals", payload)
        if(response && response.deals){
            setHubDeals(response.deals)
            if(response.pipelines){
                setHubPipelines(response.pipelines)
            }
        } else {
            if(response && response.noKey){
                NotificationManager.error(response.noKey,"Virhe",3000)
            }
            if(response && response.keyStatus){
                NotificationManager.error("API-avain ei ole enää voimassa","Virhe",3000)
            }
        }
        setIsLoading(false)
    }

    const getStageLabel = () => {
        const { pipeline, dealstage } = selectedDeal;
        let stageLabel = "Tuntematon";

        if (pipeline !== undefined && pipeline === selectedPipeline.id) {
            if (selectedPipeline.stages) {
                const { stages } = selectedPipeline;

                stages.map(stage => {
                    if (dealstage === stage.id) {
                        stageLabel = stage.label;
                    }
                })
            }
        }

        setSelectedStageLabel(stageLabel)
    }

    useEffect(()=>{
        if(selectedDeal && selectedDeal.dealstage){
            getStageLabel()
        }
    },[selectedDeal, item])

    useEffect(()=>{
        createHubLink()
    },[selectedDeal, hubPortalId])

    const saveHubPortalId = async() => {
        let payload = {
            businessId: plan.selectedBusiness.value,
            hubSpotPortalId: hubPortalId
        }
        const response = await executeRequest('plan/saveHubSpotData', payload)
        if(response && response.saved){
            createHubLink(response.saved)
            NotificationManager.success("Tunniste lisätty onnistuneesti", "", 3000)
        } else {
            NotificationManager.error("Tunnistetta ei voitu lisätä", "Virhe", 3000)
        }
    }

    const createHubLink = () => {
        if(selectedDeal && selectedDeal.dealId && hubPortalId){
            let url = `https://app.hubspot.com/contacts/${hubPortalId}/deal/${selectedDeal.dealId}/`
            setHubLink(url)
        }
    }

    return(
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}> HubSpot-komponentin valinnat</ModalHeader>
            <ModalBody>
                <HubSpotDeals
                    deals={hubDeals}
                    pipelines={hubPipelines} 
                    isLoading={isLoading}
                    setSelectedDeal={setSelectedDeal}
                    setSelectedPipeLine={setSelectedPipeline}
                    selectedPipeline={selectedPipeline}
                />
                <div style={{borderBottom: '1px solid #dee2e6', marginBottom: '1em'}}></div>
                {selectedDeal && 
                    <Col>
                        <Label><b>Valittu diili:</b></Label>
                        <div style={{ display: 'flex'}}>
                            <Col xs="4">
                                <Row className="deal-info-row"><p>Nimi</p></Row>
                                <Row className="deal-info-row"><p>Vaihe</p></Row>
                                <Row className="deal-info-row"><p>Summa</p></Row>
                                {!selectedDeal.isClosed && <Row className="deal-info-row">Sulkeutumisajankohta</Row>}
                            </Col>
                            <Col xs="8">
                                <Row className="deal-info-row"><p>{selectedDeal.dealname}</p></Row>
                                <Row className="deal-info-row"><p>{item.stageLabel? item.stageLabel : selectedStageLabel}</p></Row>
                                <Row className="deal-info-row">{ selectedDeal.amount + " €"}</Row>
                                {!selectedDeal.isClosed && <Row className="deal-info-row" style={{color: 'red'}}> {moment.utc(selectedDeal.closedate).format("DD.MM.YYYY HH:mm")}</Row>}
                            </Col>
                        </div>
                    </Col>
                }
                <div style={{borderBottom: '1px solid #dee2e6', marginBottom: '1em', marginTop: '1em'}}></div>
                <Col>
                    <Col>
                        <Row className="deal-info-row"><p><b>HubSpot portaalin tunniste</b> (käytetään linkin luomiseen)</p></Row>
                        <Row className="deal-info-row"><Input value={hubPortalId} placeholder="Anna HubSpot-portaalin tunniste tähän" onChange={(e)=> setHubPortalId(e.target.value)}></Input></Row>
                        <Row className="deal-info-row"><button onClick={() => saveHubPortalId()} className="small-white-button wide-button ">Tallenna tunniste</button></Row>
                    </Col> 
               </Col>
            </ModalBody>
            <ModalFooter>
                <a onClick={toggle} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                {creation?
                    <button onClick={() => newHubSpotItem()} className="small-white-button wide-button ">Jatka</button>
                    :
                    <button onClick={() => saveHubSpotItem()} className="small-white-button wide-button ">Tallenna</button>
                }
            </ModalFooter>
        </Modal>
    )
}

const HubSpotDeals = ({ deals, pipelines, openSettings, isLoading, setSelectedDeal, selectedPipeline, setSelectedPipeLine}) => {
    const [search, setSearch] = useState("");
    const [statusFilters, setFilters] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [stageLabels, setStageLabels] = useState([]);
    const [activeType, setActiveType] = useState("cards")
    const dispatch = useDispatch();

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const getStageLabel = (deal) => {
        const { pipeline, dealstage } = deal;
        let stageLabel = "Tuntematon";

        if (pipeline !== undefined && pipeline === selectedPipeline.id) {
            if (selectedPipeline.stages) {
                const { stages } = selectedPipeline;

                stages.map(stage => {
                    if (dealstage === stage.id) {
                        stageLabel = stage.label;
                    }
                })
            }
        }

        return stageLabel;
    }

    const changePipe = (event) => {
        const { name, value } = event.target;

        pipelines.map(pipe => {
            if (pipe.id === value) {
                const { stages } = pipe;
                let customFilters = stages.filter(stage => stage.label !== undefined).map(s => {
                    return {
                        value: s.label,
                        filter: s.label
                    }
                });
                setFilters([]);
                setStageLabels(customFilters);

                setSelectedPipeLine(pipe);
            }
        })
    }

    const calculateClosedValue = async (deals) => {
        let value = 0;

        const closedDeals = deals && deals.filter(d => d.isClosed);

        closedDeals && closedDeals.map(cd => {
            const { closed_amount } = cd
            value += parseInt(closed_amount)
        })

        return value;
    }

    // useEffect(async()=>{
    //     let values = {
    //         closedValues: await calculateClosedValue(deals),
    //         inProgressValues: await calculateInprogressValues(deals)
    //     }
    //     updateBusinessValues(values);
    // },[deals])

    const calculateInprogressValues = async deals => {
        let value = 0;

        const inProgressDeals = deals && deals.filter(d => !d.isClosed);
        inProgressDeals && inProgressDeals.map(cd => {
            const { amount } = cd
            value += parseInt(amount.value)
        })
        return value;
    }
    const dealNameFilter = deal => {
        const { dealname, dealstage } = deal;

        if (dealname.toUpperCase().includes(search.toUpperCase())) return true
        if (dealstage.toUpperCase().includes(search.toUpperCase())) return true

        return false
    }

    const stageFilter = deal => {
        return statusFilters.length > 0 ? statusFilters.filter(status => status.filter === getStageLabel(deal)).length > 0 : true;
    }

    const toggleType = (listName, type) => {
        setActiveType(type)
    }

    return (
        <>
            {activeType === 'cards'?
                <HorizontalContainerWithSearch
                    label={"HubSpot Deals (" + deals.length +")"}
                    setFilter={setSearch}
                    searchFilter={search}
                    pipeline={selectedPipeline}
                    availablePipelines={pipelines}
                    filter={stageLabels.length > 0}
                    customFilters={stageLabels}
                    filters={statusFilters}
                    setStatusFilters={setFilters}
                    changePipeline={changePipe}
                    activeType={activeType} 
                    toggleType={toggleType} 
                    listName={"hubDeals"}
                >
                    <HorizontalList>
                        {(isLoading || deals.length === 0) ?
                            <EmptyList isLoading={isLoading} error={errorMessage} />
                            :
                            deals.filter(deal => (deal.pipeline === selectedPipeline.id && dealNameFilter(deal) && stageFilter(deal))).map(result =>
                                <HorizontalItem key={result.dealId}>
                                    <HubSpotDealCard deal={result} getStage={getStageLabel} setSelectedDeal={setSelectedDeal}/>
                                </HorizontalItem>
                            )
                        }
                    </HorizontalList>
                </HorizontalContainerWithSearch>
                :
                <VerticalContainerWithSearch 
                label={"HubSpot Deals (" + deals.length +")"}
                setFilter={setSearch}
                searchFilter={search}
                pipeline={selectedPipeline}
                availablePipelines={pipelines}
                filter={stageLabels.length > 0}
                customFilters={stageLabels}
                filters={statusFilters}
                setStatusFilters={setFilters}
                changePipeline={changePipe}
                activeType={activeType} 
                toggleType={toggleType} 
                listName={"hubDeals"}>
                    <VerticalList>
                        {deals.length > 0 ? deals.filter(deal => (deal.pipeline === selectedPipeline.id && dealNameFilter(deal) && stageFilter(deal))).map((result, key) => {
                            let oddEvenClass = key % 2 === 0 ? 'even-row' : 'odd-row';
                            return(
                                <VerticalItem key={result.dealId}>
                                    <HubDealListItem key={key} deal={result} getStage={getStageLabel} oddEvenClass={oddEvenClass}/>
                                </VerticalItem>
                            )
                        })
                        : 
                        (
                            <EmptyList isLoading={isLoading} />
                        )}
                    </VerticalList>
                </VerticalContainerWithSearch>
            }
        </>
    )
}

const EmptyList = ({ isLoading, error = "" }) => {
    return (
        <div className="list-placeholder-container">
            {isLoading ?
                <ListLoadingSpinner />
                :
                <h3>{error === "" ? "Ei tuloksia" : error}</h3>
            }
        </div>
    )
}
const ListLoadingSpinner = () => {
    return (
        <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Tour from 'reactour';

export const ClientDashboardGuide = ({openSettings, hasHubSpot = false}) => {
    const [tourOpen, setTourOpen] = useState(false)
    const [steps, setSteps] = useState(null)
    const [stepNum, setStepNum] = useState()
    const [buttonPressed, setButtonPressed] = useState(false)
    const modulesInUse = useSelector(state => state.authentication.modulesInUse)

    useEffect(()=>{
        buildSteps()
    },[modulesInUse])

    const handleSettingsOpen = () => {
        openSettings()
        // setButtonPressed(true)
    }

    const buildSteps = () => {
        
        let steps = [
            {
                id: "Asiakkaan yhteenveto",
                selector: '#client-dashboard-tourId',
                content: 'Asiakkaan yhteenvetoon kootaan valitulle asiakkaalle tuotettu sisältö.',
            }
        ]
        

        steps.push({
            id: "YTJ-info",
            selector: '#ytj-info-tour',
            content: 'Tänne kootaan asiakkaan tietoja YTJ-rajapinnasta (YTJ-API). Jotta tiedot näkyvät, asiakas tulee yhdistää YTJ-rajapintaan. Voit yhdistää asiakkaan YTJ-rajapintaan (YTJ-API) asiakkaan asetuksista.'
        })

        steps.push({
            id: "Asetukset",
            selector: '#reportDashboard-options-tourId',
            content:({ goTo }) => ( 
                <div>
                  Täällä voit muun muassa muokata näkymän asetuksia. Ole hyvä ja avaa asetukset ennen seuraavaan opasteeseen siirtymistä.
                  <br/>
                  <button className='small-white-button' style={{ marginTop: '0.5em '}} onClick={() => handleSettingsOpen()}>Avaa asetukset</button>
                </div>
            )
        })

        steps.push({
            id: "Aikajana",
            selector: '#timeline-toggle',
            content: 'Aikajanalle kootaan kaikki asiakkaalle toteutetut lomakkeet, kartoitukset, projektit, riskit ja tarkistuspisteet.'
        })

        steps.push({
            id: "Tagit",
            selector: '#edit-business-tags-id',
            content: 'Voit lisätä, poistaa ja muokata asiakkaan tageja.'
        })

        if(modulesInUse.contactManagement){
            steps.push({
                id: "Kontaktit",
                selector: '#contact-management-tour',
                content: 'Voit lisätä, poistaa ja muokata asiakkaan tageja.'
            })
        }

        steps.push({
            id: "Näytettävät listat",
            selector: '#select-lists-tour',
            content: 'Täältä voit valita, mitkä listat tässä yhteenvedossa näytetään.'
        })

        steps.push({
            id: "Trello",
            selector: '#trello-box-tour',
            content: 'Täältä voit lisätä asiakkaalle Trello-taulun.'
        })

        steps.push({
            id: "HubSpot",
            selector: '#hubSpot-tour',
            content: 'Täältä voit yhdistää asiakkaan HubSpot-rajapintaan.'
        })

        
        steps.push({
            id: "YTJ-rajapinta",
            selector: '#YTJ-tour',
            content: 'Täältä voit yhdistää asiakkaan YTJ-rajapintaan tai poistaa olemassa olevan yhteyden.'
        })
            
        setSteps(steps)
    }
    return (
        <>
            <div className={"tour-button"} onClick={() => setTourOpen(true)}><i className="fas fa-info-circle"></i></div>
            <Tour
                steps={steps}
                isOpen={tourOpen}
                rounded={4}
                onRequestClose={() => (setTourOpen(false))}
                badgeContent={(curr, total) => (steps && !Number.isNaN(parseInt(curr)) && steps[(parseInt(curr)-1)].id)? `${steps[(parseInt(curr)-1)].id}` : (parseInt(curr) + "/" + total)}   
                goToStep={stepNum}
                // startAt={0}
            />
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars'
import {Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropDownItem} from 'reactstrap'
import {executeRequest} from '../../../Utilities/requestHandler/dataHandler'
import {CopiedIFrameElement} from './listElements/CopiedBlockElements'
import {useDispatch} from 'react-redux'
import {ListLoadingSpinner} from './ListLoadingSpinner'
import {getPlainTexts} from './listElements/CopiedBlockElements'
import {NotificationManager} from 'react-notifications'
import {DeleteModal} from '../Modals/ActionModals'
import './tabList.css'

const initialDeleteModalData = {
    modal: false, 
    target: {}
}

const CopiedIFrameElements = ({filter}) => {
    const [copyBlocks, setCopyBlocks] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [deleteModalData, setDeleteModalData] = useState(initialDeleteModalData);
    const dispatch = useDispatch();

    useEffect(() => {
        //plan/templates
        //Get saved blocks
        const fetchData = async () => {
            const response = await executeRequest('plan/templates', {type: 'iFrames'}, dispatch)

            if(!!response) {
                if(response) setCopyBlocks(response.sort((a,b) => new Date(b.created).getTime() - new Date(a.created).getTime()));
                setIsLoading(false)
            }
        }

        fetchData();
    }, [])

    const removeCopiedElement = async (payload) => {
        const {target} = deleteModalData
        const {_id} = target

        const response = await executeRequest('plan/removeTemplate', {templateId: _id}, dispatch)

            if(!!response) {
                if(response.status === "deleted") {
                    setCopyBlocks(copyBlocks.filter(copy => copy._id !== response.templateId));
                    NotificationManager.success("iFrame-malli poistettu", "", 3000);
                    setDeleteModalData(initialDeleteModalData)
                }
                setIsLoading(false)
            }
    }

    return (
        <>
            {deleteModalData.modal && 
                <DeleteModal 
                    isOpen={deleteModalData.modal} 
                    toggleModal={() => setDeleteModalData(initialDeleteModalData)} 
                    handleDelete={removeCopiedElement} title={deleteModalData.target.topicContent ? deleteModalData.target.topicContent : ""}
                    targetText="iFrame-mallin" 
                />
            }
            <Scrollbars
                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                style={{ width: "100%", height: '100%' }}
                className="custom-scrollbars"
            >
                <div className="iFrame-list-container">
                    {isLoading ? <ListLoadingSpinner /> :
                    copyBlocks.map((data, y) => {
                        const {topicContent, textContent} = getPlainTexts(data.topicContent, "")
                        if (topicContent.toUpperCase().includes(filter.toUpperCase())) {
                            return  <CopiedIFrameElement
                                            iFrame={data}
                                            onDelete={setDeleteModalData}
                                            key={data._id}
                                        />
                        }
                        return null
                    })}
                </div>                   
            </Scrollbars>
        </>
    )
}

export default CopiedIFrameElements
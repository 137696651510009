import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';

const AutomaticPlanSettings = ({ isOpen, toggle, save, settings }) => {
    const [automaticCreate, setAutomaticCreate] = useState(settings.createOnAnswer || false);
    const [showReportOnAnswer, setShowReportOnAnswer] = useState(settings.showOnAnswer || false);
    const [sendReportOnAnswer, setSendReportOnAnswer] = useState(settings.sendOnAnswer || false);
    const [raportText, setRaportText] = useState(settings.linkText || '');

    useEffect(() => {
        setAutomaticCreate(settings.createOnAnswer || false)
        setShowReportOnAnswer(settings.showOnAnswer || false)
        setSendReportOnAnswer(settings.sendOnAnswer || false)
        setRaportText(settings.linkText || '')
    }, [settings])

    useEffect(() => {
        if (showReportOnAnswer || sendReportOnAnswer) {
            setAutomaticCreate(true);
        }
    }, [sendReportOnAnswer, showReportOnAnswer]);

    useEffect(() => {
        if (!automaticCreate) {
            setShowReportOnAnswer(false);
            setSendReportOnAnswer(false);
        }
    }, [automaticCreate]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered size="md">
            <ModalHeader toggle={toggle} className="taskModal">
                <div>
                    Generoidun lomakkeen asetukset
                </div>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xl="12">
                        <FormGroup check>
                            <Label check style={{ cursor: 'pointer', marginBottom: '.5em' }}>
                                <Input type="checkbox" checked={automaticCreate} onChange={() => setAutomaticCreate(!automaticCreate)} /> Generoi lomake automaattisesti vastauksesta
                            </Label>
                            <Label check style={{ cursor: 'pointer', marginBottom: '.5em' }}>
                                <Input type="checkbox" checked={showReportOnAnswer} onChange={() => setShowReportOnAnswer(!showReportOnAnswer)} /> Näytä lomake vastaajalle
                            </Label>
                            {showReportOnAnswer &&
                                <div style={{ marginBottom: '.5em' }}>
                                    <Label className="lighter-text">Linkin teksti</Label>
                                    <Input onChange={(e) => setRaportText(e.target.value)} value={raportText} ></Input>
                                </div>
                            }
                            {/* 
                                not in use yet.
                            <Label check style={{ cursor: 'pointer', marginBottom: '.5em' }}>
                                <Input type="checkbox" checked={sendReportOnAnswer} onChange={() => setSendReportOnAnswer(!sendReportOnAnswer)} /> Lähetä lomake vastaajalle
                            </Label> */}
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="taskCreatorFooter">
                <a className="go-back-link" onClick={toggle}>Peruuta</a>
                <button className="small-white-button lighter-text wide-button" onClick={() => save({ createOnAnswer: automaticCreate, showOnAnswer: showReportOnAnswer, sendOnAnswer: sendReportOnAnswer, linkText: raportText })}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

export default AutomaticPlanSettings;
import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import RiskMatrice from '../../Matrice/RiskMatrice';
const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center', position: 'absolute' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const RiskSummary = (props) => {
    return (
        <Modal isOpen={props.summary} toggle={props.toggleSummary} onClosed={props.closeAll ? props.toggle : undefined} size="lg" centered>
            <ModalHeader toggle={props.toggleSummary} className="taskModal">
                <div>
                    Riskiarvion yhteenveto
                    <span className="modalPageNumber">2 / 2</span>
                </div> 
            </ModalHeader>
            <ModalBody>
                {props.fetchStatus && <MegaLoaderSpinner />}
                <Row>
                    <div style={{width: '100%'}}>
                        <Col xl="12">
                            <h5 style={{ marginBottom: 0 }}>Luokittelu:</h5>
                            <h5>{props.classification.label}</h5>
                        </Col>
                        <Col xl="12">
                            <h5 style={{ marginBottom: 0 }}>Riskienhallintatoimi:</h5>
                            <h5>{props.riskControl.label}</h5>
                        </Col>
                        <hr style={{clear: 'both'}}/>
                    </div>
                </Row>
                <Row>
                    <Col xl="7">
                        <Row>
                            <Col xl="12">
                                <h5 style={{ marginBottom: 0 }}>Otsikko:</h5>
                                <h5>{props.riskName}</h5>
                            </Col>
                            <Col xl="12">
                                <h5 style={{marginBottom: 0, marginTop: '1rem'}}>Selitys:</h5>
                                <TextEditor editorId="riskExplanationSummary"  additionalClass="texteditor-no-minHeight" content={props.riskExplanation} readOnly={true} onTextChange={() => false} />
                            </Col>
                            <Col xl="12">
                                <h5 style={{marginBottom: 0, marginTop: '2rem'}}>Prioriteetti:</h5>
                                <p>{props.odds.label} / {props.selectedRiskLevel.label}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl="5">
                        <RiskMatrice {...props} />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => props.toggleSummary()}>Peruuta</a>
                <button className="small-white-button wide-button save-risk" onClick={() => props.saveRisk()}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

export default RiskSummary;
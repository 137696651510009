import React, {Component} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import TopicAndDraft from './TopicAndDraft';
import IFrame from './IFrame';
import ReportItem from './ReportItem';
import TrelloBlock from './embeds/TrelloBlock';
import ListBlock from './embeds/ListBlock';
import CustomBlockText from './CustomBlockText';
import ExcelBlock from './embeds/ExcelBlock';
import { TableContainer} from './TableItems';
import { ProductBlock } from './ProductBlock';
import { AttachmentBlock } from './AttachmentBlock';
import { SignatureBlock } from './SignatureBlock';
import { RiskBlock } from './risks/RiskBlock';
import { ChecklistBox } from './ChecklistBlock';
import { BusinessExcelBlock } from './BusinessExcelBlock';
import { HubSpotBlock } from './hubSpot/HubSpotBlock';
import { GanttBlock } from './GanttBlock';
import { GridBlock } from './Grid/GridBlock';
import { TrelloTimelineBlock } from './Trello/TrelloTimelineBlock';

class BuilderItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allowDrag: true
        }
    }

    toggleDrag = flag => {
        if(this.state.allowDrag !== flag)
            this.setState({allowDrag: flag});
    }

    checkItem = type => {
        switch(type) {
            case 'iFrame':
                return (
                    <IFrame
                        item={this.props.item} 
                        startAddingTask={this.props.startAddingTask} 
                        contentId={this.props.id} text={this.props.textContent} 
                        topic={this.props.topicContent} editable={this.props.edit} 
                        promptSave={this.props.promptSave} 
                        onItemChange={this.props.onItemChange}
                        modifyTask={this.props.modifyTask}
                        startRiskAssesment={this.props.startRiskAssesment}
                        riskList={this.props.riskList} 
                        taskList={this.props.taskList}
                        onTemplateAdd={this.props.onTemplateAdd}
                        index={this.props.index}
                        onDelete={this.props.onDelete}
                        toggleItemVisibility={this.props.toggleItemVisibility}
                        modulesInUse={ this.props.modulesInUse }
                        saveIFrameHost={ this.props.saveIFrameHost }
                        toggleDrag={ this.toggleDrag }
                        scrollToSaveButtonAfterResize={ this.props.scrollToSaveButtonAfterResize } 
                    />
                )
            case 'report':
                return (
                    <ReportItem 
                        item={this.props.item} 
                        startAddingTask={this.props.startAddingTask}
                        targetBusiness={this.props.selectedBusiness} 
                        contentId={this.props.id} text={this.props.textContent} 
                        topic={this.props.topicContent} editable={this.props.edit} 
                        promptSave={this.props.promptSave} 
                        onItemChange={this.props.onItemChange}
                        modifyTask={this.props.modifyTask}
                        startRiskAssesment={this.props.startRiskAssesment}
                        riskList={this.props.riskList} 
                        taskList={this.props.taskList}
                        onTemplateAdd={this.props.onTemplateAdd}
                        index={this.props.index}
                        onDelete={this.props.onDelete}
                        toggleItemVisibility={this.props.toggleItemVisibility}
                        modulesInUse={ this.props.modulesInUse }
                    />
                )
            case 'tables':
                return (
                    <TableContainer 
                        item={this.props.item}
                        promptSave={this.props.promptSave}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility}
                        onDelete={this.props.onDelete}
                        onTemplateAdd={this.props.onTemplateAdd}
                        saveWithoutPrompt={this.props.saveItemWithoutQueue}
                        index={this.props.index}
                        planId={this.props.planId}
                     />
                )
            case 'Trello':
                return (
                    <TrelloBlock 
                        item={this.props.item} 
                        itemId={this.props.item.targetId} 
                        businessId={this.props.selectedBusiness.value} 
                        preview={true}
                        promptSave={this.props.promptSave}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility}
                        toggleDrag={ this.toggleDrag }
                        onDelete={this.props.onDelete}
                        saveItemWithoutQueue={ this.props.saveItemWithoutQueue}   
                        index={this.props.index}
                    />
                   
                )
            case 'TrelloTimeline':
                return (
                    <TrelloTimelineBlock
                        item={this.props.item} 
                        itemId={this.props.item.targetId} 
                        businessId={this.props.selectedBusiness.value} 
                        preview={true}
                        promptSave={this.props.promptSave}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility}
                        toggleDrag={ this.toggleDrag }
                        onDelete={this.props.onDelete}
                        saveItemWithoutQueue={ this.props.saveItemWithoutQueue}   
                        index={this.props.index}
                    />
                   
                )
            case 'list':
                return (
                    <ListBlock
                        item={this.props.item} 
                        itemId={this.props.item.targetId} 
                        businessId={this.props.selectedBusiness.value} 
                        preview={true}
                        promptSave={this.props.promptSave}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility}
                        toggleDrag={ this.toggleDrag }
                        onDelete={this.props.onDelete}
                        saveItemWithoutQueue={ this.props.saveItemWithoutQueue} 
                        index={this.props.index}
                    />
                )
            case 'Excel':
                return (
                    <ExcelBlock
                        item={this.props.item} 
                        itemId={this.props.item.targetId} 
                        businessId={this.props.selectedBusiness.value} 
                        promptSave={this.props.promptSave}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility}
                        toggleDrag={ this.toggleDrag }
                        index={this.props.index}
                        onDelete={this.props.onDelete}
                        saveItemWithoutQueue={ this.props.saveItemWithoutQueue}
                     />
                )
            case 'custom-block':
                return (
                    <CustomBlockText 
                        id={this.props.item.id} 
                        blockName={this.props.item.blockName} 
                        editable={this.props.edit} 
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        itemData={this.props.item}
                    />
                )
            case 'product':
                return (
                    <ProductBlock
                        item={this.props.item}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        promptSave={this.props.promptSave} 
                        saveProducts={this.props.saveItemWithoutQueue}
                        planId={this.props.planId}
                    />
                )
            case 'attachment':
                return (
                    <AttachmentBlock
                        item={this.props.item}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        promptSave={this.props.promptSave}
                        saveFiles={this.props.saveItemWithoutQueue}
                        onTemplateAdd={this.props.onTemplateAdd}
                        planId={this.props.planId}
                    />
                )  
            case 'signature':
                return (
                    <SignatureBlock
                        item={this.props.item}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        promptSave={this.props.promptSave}
                        saveSignatures={this.props.saveItemWithoutQueue}
                        planId={this.props.planId}
                        plan={this.props.plan}
                    />
                )        
            case 'risks':
                return (
                    <RiskBlock
                        item={this.props.item}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        //startRiskAssesment={this.props.startRiskAssesment}
                        //startAddingTask={this.props.startAddingTask} 
                        //promptSave={this.props.promptSave}
                        //saveSignatures={this.props.saveItemWithoutQueue}
                        plan={this.props.plan}
                    />
                )
            case 'checklist':
                return (
                    <ChecklistBox
                        item={this.props.item}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        promptSave={this.props.promptSave}
                        saveWithoutQueue={this.props.saveItemWithoutQueue}
                        planId={this.props.planId}
                        plan={this.props.plan}
                    />
                )     
            case 'businessExcel':
                return (
                    <BusinessExcelBlock
                        item={this.props.item}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        promptSave={this.props.promptSave}
                        saveWithoutQueue={this.props.saveItemWithoutQueue}
                        planId={this.props.planId}
                        plan={this.props.plan}
                    />
                )
            case 'HubSpot':
                return (
                    <HubSpotBlock
                        item={this.props.item}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        promptSave={this.props.promptSave}
                        saveWithoutQueue={this.props.saveItemWithoutQueue}
                        selectedBusiness={this.props.selectedBusiness}
                        planId={this.props.planId}
                        plan={this.props.plan}
                        
                    />
                )      
            case 'gantt':
                return (
                    <GanttBlock
                        item={this.props.item}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        promptSave={this.props.promptSave}
                        saveWithoutQueue={this.props.saveItemWithoutQueue}
                        selectedBusiness={this.props.selectedBusiness}
                        onTemplateAdd={this.props.onTemplateAdd}
                        planId={this.props.planId}
                        plan={this.props.plan}
                        toggleDrag={ this.toggleDrag }
                    />
                )
            case 'grid':
                return (
                    <GridBlock
                        planItem={this.props.item}
                        editable={this.props.edit}
                        toggleItemVisibility={this.props.toggleItemVisibility} 
                        onDelete={this.props.onDelete}
                        index={this.props.index}
                        promptSave={this.props.promptSave}
                        saveWithoutQueue={this.props.saveItemWithoutQueue}
                        onTemplateAdd={this.props.onTemplateAdd}
                        planId={this.props.planId}
                        plan={this.props.plan}
                        toggleDrag={ this.toggleDrag }
                    />
                )                                                 
            default:
                return (
                    <TopicAndDraft 
                        item={this.props.item} 
                        startAddingTask={this.props.startAddingTask} 
                        contentId={this.props.id} 
                        text={this.props.textContent} 
                        topic={this.props.topicContent} 
                        editable={this.props.edit} 
                        promptSave={this.props.promptSave} 
                        onItemChange={this.props.onItemChange}
                        modifyTask={this.props.modifyTask}
                        startRiskAssesment={this.props.startRiskAssesment}
                        riskList={this.props.riskList} 
                        taskList={this.props.taskList}
                        onTemplateAdd={this.props.onTemplateAdd}
                        index={this.props.index}
                        onDelete={this.props.onDelete}
                        toggleItemVisibility={this.props.toggleItemVisibility}
                        modulesInUse={ this.props.modulesInUse }
                        variables={this.props.variables}
                        planId={this.props.planId}
                        toggleDrag={ this.toggleDrag }
                        updatePrompts={this.props.updatePrompts}
                        enterprisePrompts={this.props.enterprisePrompts}
                    />
                )
        }
    }

    render() {
        return (
            <Draggable draggableId={this.props.item.id} index={this.props.index} isDragDisabled={!this.state.allowDrag}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                        >
                        {this.checkItem(this.props.item.type)}
                        </div>
                    )}
                    
            </Draggable>
        )
    }
}



export default BuilderItem;
import React, { useState, useEffect } from 'react';
import './project.css';
import { Row, Col, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { executeRequest } from '../../Utilities/requestHandler/dataHandler';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import { TagHandler } from './subComponents/TagHandler';
import { DateRangePicker } from 'react-dates';
import moment from 'moment'
import TextEditor from '../../Utilities/TextEditor/texteditor';
import { convertFromRaw } from 'draft-js'

const INITIAL_STATE_PROJECT = {
    projectName: "",
    description: "",
    tags: null,
    selectedType: "",
    selectedProviders: [],
    startDate: null,
    targetDate: null,
    targetBusiness: {label:"Ei kohdeyritystä", value:"anon"},
    selectedSurveys: [],
    plans: [],
    files: [],
    activities:[],
    status: {value: "#c3c3c3", label: "Avoin"},
    ganttPlan: null
}

const statusList = [
    {value: "#c3c3c3", label: "Avoin"},
    {value: "#ffcc00", label: "Käynnissä"},
    {value: "#b5e413", label: "Valmis"},
    {value: '#ed5652', label:'Suljettu'}
 
]

export const ProjectDataModal = ({isOpen, closeModal, projectToModify = null, history, updateProject = null, setUpdatedProject = null, targetBusiness = null, planList = []}) => {
    const [project, setProject] = useState(INITIAL_STATE_PROJECT)
    const [providers, setProviders] = useState()
    const [clientList, setClientList] = useState([])
    const [focusedDateInput, setFocusedDateInput] = useState()
    const [projectList, setProjectList] = useState([])
    const [selectedProject, setSelectedProject] = useState(null)
    const [forceUpdate, setForceUpdate] = useState(false)
    const [plansWithGantt, setPlansWithGantt] = useState([])

    useEffect(()=>{
        if(planList && planList.length > 0){
            let hasGanttList = []
            planList.map((plan) => {
                if(plan.planItems){ 
                    plan.planItems.map((item)=> {
                        if(item.type === 'gantt'){
                            let plainTopic =  ""
                            if(typeof item.topicContent !== "string"){ 
                                plainTopic = convertFromRaw(item.topicContent).getPlainText()
                            } else {
                                plainTopic = item.topicContent
                            }
                            let ganttItem = {
                                planId: plan._id,
                                value: item.id,
                                label: plan.planName + " - " + plainTopic
                            }
                            hasGanttList = [...hasGanttList, ganttItem]
                        }
                    })
                   
                }
            })
            setPlansWithGantt([...hasGanttList])
        }
    },[planList])



    const createProject = async() => {
        if(project.projectName.length > 0){
            let payload = ({
                project:{...project}
            })
            const response = await executeRequest('project/create', payload)
                
            if(response.success === true){
                handleProject(response.project)
                NotificationManager.success('Projekti luotu onnistuneesti', 'Ilmoitus', 4000)
            } else{
                if(response.trialEnded){
                    NotificationManager.error("Kokeilujakso on päättynyt","",4000)
                } else {
                    if(response.reason){
                        if(response.reason === "limit"){
                            NotificationManager.error("Projektien enimmäismäärä on täyttynyt","",4000)
                        }
                        if(response.reason === "module"){
                            NotificationManager.error("Ominaisuus (lisenssi) ei ole käytössä","",4000)
                        }
                    } else {
                        NotificationManager.error('Projektin luonnissa tapahtui virhe', 'Virhe', 4000)
                    }
                }
            }
        } else {
            NotificationManager.error('Anna projektille vielä nimi', 'Virhe', 4000)
        }
    }

    const saveProject = async() => {
        if(project.projectName.length > 0){
            let payload = ({
                project: {...project},
                _id: project._id
            })
            const response = await executeRequest('project/save', payload)

            if(response.success === true){
                if(updateProject){
                    updateProject(response.project._id)
                }
                if(setUpdatedProject){
                    setUpdatedProject(response.project)
                }
                closeModal()
                NotificationManager.success('Tietojen tallentaminen onnistui', 'Ilmoitus', 4000)
            } else {
                NotificationManager.error('Tietojen tallentamisessa tapahtui virhe', 'Virhe', 4000)
            }
        } else {
            NotificationManager.error('Projektin nimi ei voi olla tyhjä', 'Virhe', 4000)
        }
    }
    

    useEffect(()=>{
        if(projectToModify){
            setProject(projectToModify)
        }
    },[projectToModify, isOpen])

    useEffect(()=>{
        const getClients = async() => {
            const response = await executeRequest('sender/get/getCompanies',{})
            if(response && response.businessList){
                if(targetBusiness){
                    var clients = response.businessList
                    var target = clients.filter( business => business.value === targetBusiness)
                    var business = target[0]
                    handleChange({target:{id:"targetBusiness" , value: business }})
                    setClientList(target)
                } else {
                    setClientList(response.businessList)
                }
            }
        }
        getClients()

        const getProviders = async() =>{
            const response = await executeRequest('project/getProviders',{})
            if(response){
                setProviders(response)
            }
        }
        getProviders()

        const getAllProjects = async() => {
            const response = await executeRequest('project/getProjectsForSelect', {})
            if (!!response){
                setProjectList(response)
            }
        }
        getAllProjects()
    },[])

    const toggleModal = () => {
        closeModal(!isOpen)
        setProject(INITIAL_STATE_PROJECT)
        setSelectedProject(null)
    }
    
    const handleChange = event => {
        const { id, value } = event.target
        setProject(prevData => ({
            ...prevData,
            [id]: value
        }))      
    }

    const updateSelectedProject = (project) => {
        if(project !== selectedProject){
            setForceUpdate(true)
        }

        setSelectedProject(project)
    }

    const setDates = (date) => {
        setProject(prevData => ({
            ...prevData,
            startDate: date.startDate,
            targetDate: date.endDate
        }))
    }

    const customFilter = (option, searchText) => {
        if (
            option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.value.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.tags.filter(tag => tag.toLowerCase().includes(searchText.toLowerCase())).length > 0
        ) {
          return true;
        } else {
          return false;
        }
    }

    const onDatePickerFocusChange = (focusedInput) => {
        setFocusedDateInput(focusedInput)
    }

    const customOption = (option) => {
        return(
            <div style={{ display:'flex', flexDirection:"row" }}>
                <div className="option-label">{`${option.label}`}</div>
                <div className="option-tag-container">
                    {option && option.tags && option.tags.slice(0,4).map(tag => {
                        return(
                            <div key={tag} className="option-tag client-lighter-color">{tag}</div>
                        )                  
                    })}
                    {option && option.tags && option.tags.length > 4 &&  
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="material-icons" style={{ fontSize: '0.9rem' }} title={tagsToMore(option.tags,4)}>more</i>
                        </div>
                    }
                    
                </div>
            </div>
        )
    }

    const tagsToMore = (tags, slicer) => {
        var tagss = ""
        if(tags !== undefined ){
            tags.slice(slicer, tags.length).filter(t => tagss = tagss + "  " + t)
            return(tagss)
        }
    }

    const handleProject = (project) => {
        if(project && project._id){
            history.push({
                pathname: '/projektit/hallitse',
                state:{
                    projectId: project._id
                }
            })
        } else {
            NotificationManager.error('Tapahtui virhe!', 'Virhe!', 3000)
        }
    }

    useEffect(()=>{
        if(project && document.getElementById("projectNameLabel")){   
            var projectNameInput = document.getElementById("projectNameLabel")
            if(project.projectName.length > 0){
                projectNameInput.style.color = "#404040"
            } else {
                projectNameInput.style.color = "red"
            }
        }
    },[project, isOpen, projectToModify])

    return(
        <Modal size="lg" isOpen={isOpen} toggle={() => toggleModal()} >
            <ModalHeader toggle={() => toggleModal(false)}>Projektin tiedot</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Kohdeyritys</Label>
                            <Select
                                value={project.targetBusiness}
                                onChange={(selected) => handleChange({target:{id: "targetBusiness", value: selected}})}
                                options={clientList}
                                placeholder="Valitse kohdeyritys"
                                isDisabled={false}
                                noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                className="companyTargets"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label id="projectNameLabel">Projektin nimi</Label>
                                </Col>
                                {/* <Col>
                                    <div style={{ cursor: 'pointer', marginLeft: 'auto'}}> {!modify?<i onClick={() => setModify(!modify)} className="fas fa-lock"></i> : <i onClick={() => handleSave()} className="fas fa-lock-open"></i>}</div>
                                </Col> */}
                            </Row>
                            <Input id="projectName" className="project-name" placeholder="Projektin nimi" value={project.projectName} onChange={(e)=> handleChange(e)}></Input>               
                        </FormGroup>
                        {plansWithGantt.length > 0 &&
                            <FormGroup>
                                <Label className="project-status-label">Projektisuunnitelma</Label>
                                <Select
                                    value={project.ganttPlan? project.ganttPlan : null}
                                    onChange={(selected) => handleChange({target:{id: "ganttPlan", value: selected}})}
                                    options={plansWithGantt}
                                    placeholder="Valitse lomake"
                                    isDisabled={false}
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    className="companyTargets"
                                    classNamePrefix="select"
                                />
                            </FormGroup>
                        }
                        <FormGroup>
                            <Label className="project-status-label">Projektin status</Label>
                            <Select
                                value={project.status}
                                onChange={(selected) => handleChange({target:{id: "status", value: selected}})}
                                options={statusList}
                                placeholder="Valitse status"
                                isDisabled={false}
                                noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                className="companyTargets"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="project-tags-label">Lisää projektille tageja</Label>
                            <TagHandler
                                tags={project.tags}
                                handleChange={handleChange}
                            />
                        </FormGroup>
                        <FormGroup>   
                            <Label className="project-providers-label">Projektiin liitetyt yritykset</Label>
                            {providers ? 
                                <Select
                                    isMulti
                                    value={project.selectedProviders}
                                    onChange={(selected) => handleChange({target:{ id: "selectedProviders", value: selected}})}
                                    options={providers}
                                    getOptionLabel={option => customOption(option)}
                                    placeholder="Ei valittuja yrityksiä"
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    classNamePrefix="select"
                                    filterOption={customFilter}
                                    closeMenuOnSelect={false}
                                />
                                :
                                <div>
                                    <span>No providers found</span>
                                </div>
                            } 
                        </FormGroup>
                        <FormGroup>   
                                <div style={{ display: "flex", flexDirection: "column"}}>
                                    <Label>Projektin aloitus ja tavoite pvm</Label>
                                    <DateRangePicker
                                        startDate={project.startDate ? moment(project.startDate) : null}
                                        startDateId="startID"
                                        endDate={project.targetDate ? moment(project.targetDate) : null}
                                        endDateId="enddateID"
                                        onDatesChange={({startDate, endDate}) => setDates({startDate, endDate})}
                                        startDatePlaceholderText="Aloitus pvm"
                                        endDatePlaceholderText="Tavoite pvm"
                                        displayFormat="DD.MM.YYYY"
                                        firstDayOfWeek={1}
                                        appendToBody={false}
                                        isOutsideRange={() => false}
                                        focusedInput={focusedDateInput}
                                        onFocusChange={focusedInput => onDatePickerFocusChange(focusedInput)}
                                        hideKeyboardShortcutsPanel={true}
                                        showClearDates={true}
                                        horizontalMargin={50}
                                        openDirection="up"
                                        block
                                    />
                                </div>
                        </FormGroup>                 
                    </Form>
                    <Label className="project-description-label">Kuvaus / lisätiedot</Label>  
                        {projectList && projectList.length > 0 &&
                            <div style={{ marginTop: '0.1em', marginBottom: '1.5em'}}>
                                <p style={{ fontSize: '0.8rem' }} className="project-description-label">Voit myös kopioida kuvauksen olemassa olevasta projektista</p>  
                                <Select
                                    value={selectedProject}
                                    onChange={(selected) => (updateSelectedProject(selected), handleChange({target:{ id: "description", value: selected.description}}))}
                                    options={projectList}
                                    placeholder="Valitse projekti, jonka kuvaus kopioidaan"
                                    isDisabled={false}
                                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                    className="companyTargets"
                                    classNamePrefix="select"
                                />
                            </div>
                        }                   
                        {project &&
                            <TextEditor
                                placeholder=" - "                        
                                content={project.description} 
                                onTextChange={(val) => handleChange({target:{ id: "description", value: val}})} 
                                additionalClass="round-corners"
                                allowFileUpload={true}
                                needToForceUpdate={forceUpdate}
                                setForceUpdate={setForceUpdate}
                                connectionType={"project"}
                                documentId={project._id}
                            /> 
                        }
                </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggleModal()}>Peruuta</a>
                {project._id? 
                    <button className="small-white-button wide-button new-project-button continue-highlighted" style={{ }} onClick={() => saveProject()}>Tallenna</button>
                    :
                    <button className={"small-white-button wide-button new-project-button " + (project && project.projectName && project.projectName.length > 0 ? "continue-highlighted" : "")} style={{ }} onClick={() => createProject()}>Luo projekti</button>
                }              
            </ModalFooter>
        </Modal>
    ) 
}

export const ProjectDataWithoutModal = ({history, updateProject = null, projectToModify = null, setUpdatedProject = null, startNewProject = null}) => {
    const [project, setProject] = useState(INITIAL_STATE_PROJECT)
    const [providers, setProviders] = useState()
    const [clientList, setClientList] = useState([])
    const [focusedDateInput, setFocusedDateInput] = useState()
    const [projectList, setProjectList] = useState([])
    const [selectedProject, setSelectedProject] = useState({})
    const [forceUpdate, setForceUpdate] = useState(false)

    const createProject = async() => {
        if(project.projectName.length > 0){
            let payload = ({
                project:{...project}
            })
            const response = await executeRequest('project/create', payload)
                
            if(response.success && response.project){
                if(startNewProject){
                    startNewProject(response.project._id)
                } else {
                    handleProject(response.project)
                }
                NotificationManager.success('Projekti luotu onnistuneesti', 'Ilmoitus', 4000)
            } else{
                if(response.trialEnded){
                    NotificationManager.error("Kokeilujakso on päättynyt","",4000)
                } else {
                    if(response.reason){
                        if(response.reason === "limit"){
                            NotificationManager.error("Projektien enimmäismäärä on täyttynyt","",4000)
                        }
                        if(response.reason === "module"){
                            NotificationManager.error("Ominaisuus (lisenssi) ei ole käytössä","",4000)
                        } 
                    } else {
                        NotificationManager.error('Projektin luonnissa tapahtui virhe', 'Virhe', 4000)
                    }
                }
            }
        } else {
            NotificationManager.error('Anna projektille vielä nimi', 'Virhe', 4000)
        }
    }

    const saveProject = async() => {
        if(project.projectName.length > 0){
            let payload = ({
                project: {...project},
                _id: project._id
            })
            const response = await executeRequest('project/save', payload)

            if(response.success === true){
                if(updateProject){
                    updateProject(response.project._id)
                }
                if(setUpdatedProject){
                    setUpdatedProject(response.project)
                }
                NotificationManager.success('Tietojen tallentaminen onnistui', 'Ilmoitus', 4000)
            } else {
                NotificationManager.error('Tietojen tallentamisessa tapahtui virhe', 'Virhe', 4000)
            }
        } else {
            NotificationManager.error('Anna projektille vielä nimi', 'Virhe', 4000)
        }
    }
    

    useEffect(()=>{
        if(projectToModify && projectToModify !== null){
            setProject(projectToModify)
        }
    },[projectToModify])

    useEffect(()=>{
        const getClients = async() => {
            const response = await executeRequest('sender/get/getCompanies',{})
            if(response && response.businessList){
                setClientList(response.businessList)
            }
        }
        getClients()

        const getProviders = async() =>{
            const response = await executeRequest('project/getProviders',{})
            if(response){
                setProviders(response)
            }
        }
        getProviders()

        const getAllProjects = async() => {
            const response = await executeRequest('project/getProjectsForSelect', {})
            if (!!response){
                setProjectList(response)
            }
        }
        getAllProjects()
    },[])

    const handleChange = event => {
        const { id, value } = event.target
        setProject(prevData => ({
            ...prevData,
            [id]: value
        }))
    }

    const updateSelectedProject = (project) => {
        if(project !== selectedProject){
            setForceUpdate(true)
        }

        setSelectedProject(project)
    }

    const setDates = (date) => {
        setProject(prevData => ({
            ...prevData,
            startDate: date.startDate,
            targetDate: date.endDate
        }))
    }

    const customFilter = (option, searchText) => {
        if (
            option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.value.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.tags.filter(tag => tag.toLowerCase().includes(searchText.toLowerCase())).length > 0
        ) {
          return true;
        } else {
          return false;
        }
    }

    const onDatePickerFocusChange = (focusedInput) => {
        setFocusedDateInput(focusedInput)
    }

    const customOption = (option) => {
        return(
            <div style={{ display:'flex', flexDirection:"row" }}>
                <div className="option-label">{`${option.label}`}</div>
                <div className="option-tag-container">
                    {option && option.tags && option.tags.slice(0,4).map(tag => {
                        return(
                            <div key={tag} className="option-tag client-lighter-color">{tag}</div>
                        )                  
                    })}
                    {option && option.tags && option.tags.length > 4 &&  
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="material-icons" style={{ fontSize: '0.9rem' }} title={tagsToMore(option.tags,4)}>more</i>
                        </div>
                    }
                    
                </div>
            </div>
        )
    }

    const tagsToMore = (tags, slicer) => {
        var tagss = ""
        if(tags !== undefined ){
            tags.slice(slicer, tags.length).filter(t => tagss = tagss + "  " + t)
            return(tagss)
        }
    }

    const handleProject = (project) => {
        if(project && project._id){
            history.push({
                pathname: '/projektit/hallitse',
                state:{
                    projectId: project._id
                }
            })
        } else {
            NotificationManager.error('Tapahtui virhe!', 'Virhe!', 3000)
        }
    }

    // useEffect(()=>{
    //     if(project && document.getElementById("projectNameLabel") ){   
    //         var projectNameInput = document.getElementById("projectNameLabel")
    //         if(project.projectName.length > 0){
    //             projectNameInput.style.color = "#404040"
    //         } else {
    //             projectNameInput.style.color = "red"
    //         }
    //     }
    // },[project])

    return(
        <div>
            <Form>
                <FormGroup>
                    <Label>Kohdeyritys</Label>
                    <Select
                        value={project.targetBusiness}
                        onChange={(selected) => handleChange({target:{id: "targetBusiness", value: selected}})}
                        options={clientList}
                        placeholder="Valitse kohdeyritys"
                        isDisabled={false}
                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                        className="companyTargets"
                        classNamePrefix="select"
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col>
                            <Label id="projectNameLabel">Projektin nimi</Label>
                        </Col>
                        {/* <Col>
                            <div style={{ cursor: 'pointer', marginLeft: 'auto'}}> {!modify?<i onClick={() => setModify(!modify)} className="fas fa-lock"></i> : <i onClick={() => handleSave()} className="fas fa-lock-open"></i>}</div>
                        </Col> */}
                    </Row>
                    <Input id="projectName" className="project-name" placeholder="Projektin nimi" value={project.projectName} onChange={(e)=> handleChange(e)}></Input>               
                </FormGroup>
                <FormGroup>
                    <Label className="project-status-label">Projektin status</Label>
                    <Select
                        value={project.status}
                        onChange={(selected) => handleChange({target:{id: "status", value: selected}})}
                        options={statusList}
                        placeholder="Valitse status"
                        isDisabled={false}
                        noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                        className="companyTargets"
                        classNamePrefix="select"
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="project-tags-label">Lisää projektille tageja</Label>
                    <TagHandler
                        tags={project.tags}
                        handleChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>   
                    <Label className="project-providers-label">Projektiin liitetyt yritykset</Label>
                    {providers ? 
                        <Select
                            isMulti
                            value={project.selectedProviders}
                            onChange={(selected) => handleChange({target:{ id: "selectedProviders", value: selected}})}
                            options={providers}
                            getOptionLabel={option => customOption(option)}
                            placeholder="Ei valittuja yrityksiä"
                            noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                            classNamePrefix="select"
                            filterOption={customFilter}
                            closeMenuOnSelect={false}
                        />
                        :
                        <div>
                            <span>No providers found</span>
                        </div>
                    } 
                </FormGroup>
                <FormGroup>   
                        <div style={{ display: "flex", flexDirection: "column"}}>
                            <Label>Projektin aloitus ja tavoite pvm</Label>
                            <DateRangePicker
                                startDate={project.startDate ? moment(project.startDate) : null}
                                startDateId="startID"
                                endDate={project.targetDate ? moment(project.targetDate) : null}
                                endDateId="enddateID"
                                onDatesChange={({startDate, endDate}) => setDates({startDate, endDate})}
                                startDatePlaceholderText="Aloitus pvm"
                                endDatePlaceholderText="Tavoite pvm"
                                displayFormat="DD.MM.YYYY"
                                firstDayOfWeek={1}
                                appendToBody={false}
                                isOutsideRange={() => false}
                                focusedInput={focusedDateInput}
                                onFocusChange={focusedInput => onDatePickerFocusChange(focusedInput)}
                                hideKeyboardShortcutsPanel={true}
                                showClearDates={true}
                                horizontalMargin={50}
                                openDirection="up"
                                block
                            />
                        </div>
                </FormGroup>
                <FormGroup>                     
                    <Label className="project-description-label">Kuvaus / lisätiedot</Label> 
                    {projectList && projectList.length > 0 &&
                        <div style={{ marginTop: '0.1em', marginBottom: '1.5em'}}>
                            <p style={{ fontSize: '0.8rem' }} className="project-description-label">Voit myös kopioida kuvauksen olemassa olevasta projektista</p>  
                            <Select
                                value={selectedProject}
                                onChange={(selected) => (updateSelectedProject(selected), handleChange({target:{ id: "description", value: selected.description}}))}
                                options={projectList}
                                placeholder="Valitse projekti"
                                isDisabled={false}
                                noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                                className="companyTargets"
                                classNamePrefix="select"
                            />
                        </div>
                    }                                       
                    {project &&
                        <TextEditor
                            placeholder=" - "                        
                            content={project.description} 
                            onTextChange={(val) => handleChange({target:{ id: "description", value: val}})} 
                            additionalClass="round-corners"
                            allowFileUpload={true}
                            needToForceUpdate={forceUpdate}
                            setForceUpdate={setForceUpdate}
                            connectionType={"project"}
                            documentId={project._id}
                        /> 
                    }
                </FormGroup>
                
            </Form>
            <div style={{ marginTop: '2em' }}>
                <button className={"small-white-button wide-button " + (project.projectName.length > 0 ? "continue-highlighted" : "")} disabled={project.projectName.length > 0 ? false : true } style={{ width: '100%' }} onClick={() => createProject()}>Luo projekti</button>
            </div>
        </div>
    )

}
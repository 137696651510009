import React, { Component, Fragment } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap'
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'

export default class BusinessIdAnswersModal extends Component {
  constructor() {
    super()

    this.state = {
      displayAnswersIndex: null,
    }
  }

  onFilterClick = ( answer ) => {
      const {answers, answerSessionId} = answer

      if(answers !== undefined) {
        this.props.filterReportByAnswerSession([...answers.map(answerDoc => {
            return answerDoc.answerSessionId
        })]);
      } else if(answerSessionId !== undefined) {
        this.props.filterReportByAnswerSession([answerSessionId]);
      }
      this.props.toggleModal()
  }

  displayAnswers = ( index ) => {
    if( index === this.state.displayAnswersIndex ) {
      this.setState({ displayAnswersIndex: null })
    } else {
      this.setState({ displayAnswersIndex: index })
    }
    
  }

  render() {
    const { answers } = this.props

    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggleModal()} centered={true} className="freetext-answers-modal" size='lg'>
        <ModalHeader toggle={this.props.toggle}>
          Yritysvastaukset
        </ModalHeader>
          <p style={{padding: '1rem'}}>
              Klikkaamalla Tarkastele-painiketta, voit suodattaa raportin näyttämään vain kyseiseen kohdeyritykseen liittyvät vastaukset.
          </p>
        <ModalBody style={{ padding: '1rem 0' }}>

          {/* <Row>
            <Col>
                <h5><b>{answerStack.question}</b></h5>
            </Col>
          </Row> */}

          <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            style={{ width: "100%", height: '500px' }}
            className="custom-scrollbars" >

            {answers && answers.map((answer, index) => {
              let className = index % 2 === 0 ? 'even-row' : 'odd-row'
              const {answers} = answer
              return (
                <Fragment key={index}>
                  <Row className={className} style={answers.length > 1 ? { padding: '1rem', cursor: 'n-resize' } : {padding: '1rem'}} onClick={() => this.displayAnswers(index)}>
                    <Col xs={{size: 4}}>
                      <p>{answer.businessName}</p>
                      <p>{moment.utc(answers[answers.length - 1].time, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')}</p>
                    </Col>
                    <Col xs={{size: 4}} style={{textAlign: 'center'}}>
                      <p>{answer.value}</p>
                      <p className="lighter-text">{`${answers.length} kpl`}</p>
                    </Col>
                    {answers.length > 0 ? (
                      <Col xs={{size: 4}} style={{textAlign: 'center'}}>
                        <button className="small-white-button" style={{padding: '0.25rem 1rem'}} onClick={() => this.onFilterClick( answer )}>Tarkastele</button>
                      </Col>
                    ) : (
                      null
                    )}
                  </Row>
                  {this.state.displayAnswersIndex === index && 
                    answers.map(singleAnswer => 
                        <Row className={className} style={{ padding: '1rem' }} key={singleAnswer.answerSessionId}>
                            <Col xs={{size: 4}}>
                                <p>{moment.utc(singleAnswer.time, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')}</p>
                            </Col>
                            <Col xs={{size: 4}} style={{textAlign: 'center'}}>
                                
                            </Col>
                            <Col xs={{size: 4}} style={{textAlign: 'center'}}>
                                <button className="small-white-button" style={{padding: '0.25rem 1rem'}} onClick={() => this.onFilterClick( singleAnswer )}>Tarkastele</button>
                            </Col>
                        </Row>
                    )}
                </Fragment>
              )
            })}
          </Scrollbars>
        </ModalBody>
        <ModalFooter>
            <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => this.props.toggleModal()}>Sulje</a>
        </ModalFooter>
      </Modal>
    )
  }
}
import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import {Redirect} from 'react-router-dom';
import * as ActionTypes from '../../store/index';

import { connect } from 'react-redux';
import { confs } from '../../modules/config'

class StagingArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlParam: "",
            verified: false
        }

    }

    componentDidMount() {
        window.sessionStorage.removeItem('userType')
        if(typeof this.props.match.params.token !== 'undefined') {
            let token = this.props.match.params.token || "";
            if(token !== "") {
                let params = {
                    jwtToken: token
                };
                this.props.onPageLoad(params);
            }
        } else {
            if(typeof window.sessionStorage.getItem("token") !== 'undefined') {
                this.props.onExisting();
            } else {
                this.props.onNoTokenFound("Hablandoo");
            }
            
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.isAuthenticated !== this.props.isAuthenticated) {       
            this.setState({isAuthenticated: true});
        }
    }


    render() {
        const { userType, isAuthenticated, authFailed } = this.props
       
        if( isAuthenticated && ( userType && userType === 'enterprise' ) ) {
            return <Redirect to="/yhteenveto" />
        } else if( isAuthenticated && ( userType && userType === 'business' ) ) {
            return <Redirect to="/raportit/yritys" />
        }

        if(authFailed) {
           window.location.href = confs.frontpageUrl + "/login"
        }

        return (
            <Container>
                <Row>
                    <h1 hidden>Logging in...</h1>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        authFailed: state.authentication.authenticationFailed,
        userType: state.authentication.userType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPageLoad: (params) => dispatch(ActionTypes.checkLogin(params)),
        onNoTokenFound: (params) => dispatch(ActionTypes.authFailed(params)),
        onExisting: (params) => dispatch(ActionTypes.checkExisting(params))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StagingArea);
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {executeRequest} from '../requestHandler/dataHandler'
import { useDispatch } from 'react-redux'

const NewsModal = ({showNews}) => {
    const dispatch = useDispatch();

    const acceptNews = async () => {
        const response = await executeRequest('archive/news', {}, dispatch);

        if(response) {
            dispatch(changeStatus());
        }
    }

    const changeStatus = () => {
        return {
            type: 'NEWS_OK'
        }
    }

    return (
        <Modal isOpen={showNews} toggle={acceptNews} centered={true}>
            <ModalHeader toggle={acceptNews}>Tiedoksi</ModalHeader>
            <ModalBody>
                <h1>
                    API-Avaimet
                </h1>
                <p>
                    API-Avaimissa käytettävää salausta on päivitetty ja suojausmenetelmää on muutettu.
                </p>
                <p style={{paddingTop: '1em'}}><b>Muutoksesta johtuen API-avaimet täytyy syöttää uudelleen omiin tietoihin.</b></p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={acceptNews}>Ok</Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export default NewsModal;

import * as ActionTypes from './ActionTypes'
import * as ActionCreator from '../../../store/index'
import { NotificationManager } from 'react-notifications'
import { confs } from '../../../modules/config'

export const store_list = (payload) => ({
    type: ActionTypes.SET_EMBED,
    payload: payload
})


export const getEmbedList = () => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token")
    }

    return dispatch => {
        setTimeout(() => {
            fetch(confs.url + 'embed/getEmbedList', {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => response.json())
                .then((responseJson) => {
                    if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated){
                        dispatch(ActionCreator.authFailed())
                    }else{
                        dispatch(store_list(responseJson))
                    }
                })
        }, 300);
    }
}

export const store_edit = (payload) => ({
    type: ActionTypes.SET_EDIT,
    payload: payload
})

export const setEditEmbed = (payload) => dispatch => {
    dispatch(store_edit(payload));
}

export const saveEmbed = (payload) => dispatch => {
    payload = {
        ...payload,
        jwtToken: window.sessionStorage.getItem("token")
    }

    fetch(confs.url + 'embed/modifyEmbed', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (responseJson.success === true) {
                    NotificationManager.success('Tietojen tallentaminen onnistui', 'Ilmoitus', 3000)
                    dispatch(store_list(responseJson.list));
                    dispatch(setEditEmbed(responseJson.item));
                } else {
                    NotificationManager.error('Tietojen tallentaminen epäonnistui', 'Ilmoitus', 3000)
                }
            }        
        })
}

export const set_sendSurveys = (payload) => ({
    type: ActionTypes.SET_SENTSURVEYS,
    payload: payload
})

export const getSentSurveysForEmbed = () => dispatch => {
    const payload = { jwtToken: window.sessionStorage.getItem("token") }

    fetch(confs.url + 'embed/getSentSurveys', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (responseJson.success) {
                    dispatch(set_sendSurveys(responseJson.items))
                }
            }    
        })
}

export const deleteEmbed = (payload) => dispatch => {
    payload = {
        id: payload,
        jwtToken: window.sessionStorage.getItem("token")
    }

    fetch(confs.url + 'embed/deleteEmbed', {
        method: 'POST',
        body: JSON.stringify(payload)
    }).then((response) => response.json())
        .then((responseJson) => {
            if(typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                dispatch(ActionCreator.authFailed())
            } else {
                if (responseJson.success === true) {
                    NotificationManager.success('Upotuksen poistaminen onnistui', 'Ilmoitus', 3000)
                    dispatch(store_edit({}));
                    dispatch(getEmbedList());
                } else {
                    NotificationManager.error('Upotuksen tietojen poistaminen epäonnistui', 'Ilmoitus', 3000)
                }
            }       
        })

}

export const set_initialState = () => ({
    type: ActionTypes.SET_INITIALSTATE
})

export const setDefault = () => dispatch => {
    dispatch(set_initialState);
}
import React, {useState, useEffect} from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { SteppedLineTo } from 'react-lineto';
import LineTo from 'react-lineto';
import LinkTreeItem from '../LinkTreeItem';

const CategoryModal = ({currentViewData, toggle, isOpen, setcurrentViewData}) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" centered>
                <ModalHeader toggle={toggle} className="taskModal">
                    <div>
                        Kartoituksen linkit
                    </div> 
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <Row style={{marginBottom: 40}} className="justify-content-center">
                            <Col xs="12" xl="12" style={{marginBottom: 30}}>
                                <Row>
                                    <Col xs="8" xl="8"><b>Kartoitus</b></Col>
                                </Row>
                                <Row>
                                    {/*<Col xs="1" xl="1" className={"lineHolder-title"}></Col>*/}
                                    <Col xs="8" xl="8">
                                        <div style={{border: '1px solid #CACACA', padding: 8}} className="title">
                                            <Row>
                                                <Col xs="10" xl="11">
                                                    <p style={{paddingLeft: 10}}>{currentViewData.title}</p>
                                                </Col>
                                                <Col xs="2" xl="1">
                                                    {(currentViewData.numberOfLinks !== undefined && currentViewData.numberOfLinks > 0) && 
                                                    <span style={{float: 'right'}}>
                                                        <span className="dot infoBlock">{currentViewData.numberOfLinks}</span>
                                                    </span>}
                                                </Col> 
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" xl="12">
                                <Row>
                                    <Col xs="1" xl="1"></Col>
                                    <Col xs="6" xl="6"><b>Kysymysryhmät</b></Col>
                                </Row>
                            </Col>
                            {currentViewData.questionGroups !== undefined && currentViewData.questionGroups.map((group, index) =>
                            <Col xs="12" xl="12" key={ index } style={{marginBottom: 30}}>
                                <Row>
                                    <Col xs="1" xl="1" className={`lineHolder-${index}`}></Col>
                                    {group.title !== "" &&
                                    <Col xs="6" xl="6">
                                        <LinkTreeItem className={index} numberOfLinkedItems={group.numberOfLinks} linkItem={group.title} style={{border: '1px solid #CACACA', padding: 8, cursor: 'pointer', height: '100%'}} data={{...group, type: 'group'}} setcurrentViewData={setcurrentViewData}/>
                                    </Col>}
                                </Row>
                            </Col>
                            )}
                            {/*<LineTo from={"lineHolder-title"} to={"title"} fromAnchor={"20% 0"} toAnchor={"left"} borderColor="#404040" borderStyle="solid" borderWidth={3} zIndex={9999} within="modal-body"/>*/}
                            {currentViewData.questionGroups !== undefined && <LineTo from={"title"} to={`lineHolder-${currentViewData.questionGroups.length - 1}`} fromAnchor={"4.5% 100%"} toAnchor={"50% 55%"} borderColor="#CACACA" borderStyle="solid" borderWidth={3} zIndex={9999} delay={1000} within="modal-body"/>}
                            {currentViewData.questionGroups !== undefined && currentViewData.questionGroups.map((item, index) =>
                                <div key={index + 1}> 
                                    <SteppedLineTo from={`lineHolder-${index}`} to={index + ""} fromAnchor={"51% 50%"} toAnchor={"left"} borderColor="#CACACA" borderStyle="solid" borderWidth={3} zIndex={9999} within="modal-body"/>
                                </div>
                            )}
                        </Row>
                    </div>
                    {/*<div className="container">
                        <Row className="justify-content-center">
                            <Col xs="10" xl="10">
                                <div style={{border: '1px solid #CACACA', padding: 8, marginBottom: 40, textAlign: 'center'}} className="title">{currentViewData.title} <span className="badge badge-success">{currentViewData.numberOfLinks}</span></div>
                            </Col>
                            {currentViewData.questionGroups.map((group, index) =>
                            <Col xs="8" xl="8" key={group.id}>
                                <LinkTreeItem  className={index + ""} numberOfLinkedItems={group.numberOfLinks} linkItem={group.title} style={{border: '1px solid #CACACA', padding: 8, marginBottom: 40, textAlign: 'center', cursor: 'pointer'}} data={{...group, type: 'group'}} setcurrentViewData={setcurrentViewData}/>
                            </Col>
                            )}
                        </Row>
                            
                        
                        <SteppedLineTo from={"title"} to={"0"} fromAnchor={"bottom center"} toAnchor={"top center"} borderColor="#404040" borderStyle="solid" borderWidth={3} zIndex={9999} delay={1000} within="modal-body"/>
                        {currentViewData.questionGroups.map((item, index) => 
                            <SteppedLineTo key={index + 1} from={index + ""} to={index + 1 + ""} fromAnchor={"bottom center"} toAnchor={"top center"} borderColor="#404040" borderStyle="solid" borderWidth={3} zIndex={9999} within="modal-body"/>
                        )}
                        </div>*/}
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Sulje</a>
                </ModalFooter>
            </Modal>
    )
}

export default CategoryModal;
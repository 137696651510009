import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import * as ActionCreator from '../../store/index';
import { withRouter } from 'react-router-dom';
import { getLatestAnswers } from '../Reports/actions/ActionCreator'
import { startEditingPlan, fetchAllPlans } from '../planBuilder/Actions/ActionCreator'
import AgreementModal from '../../Utilities/Modals/AgreementModal';
import DashboardMain from './DashboardMain'
import Tour from 'reactour'
import * as ActionCreatorPlan from '../planBuilder/Actions/ActionCreator';
import { handleFirstLoginDone } from '../../store/actions/Login/ActionCreator';
import { createTextChangeRange } from 'typescript';


class Dashboard extends Component {
    constructor() {
        super()
        this.state = {
            showAgreement: false
        }
    }

    componentDidMount() {
        // this.props.getSentSurveysForDashboard()
        // this.props.getTasksForDashboard()
        // this.props.getDashboardSummary()
        // this.props.getActiveBusinessList();
        this.props.setBreadcrumbs({ to: 'tiedot', label: `${this.props.businessName}` })
        this.props.addMoreBreadcrumbs([{ to: 'yhteenveto', label: "yhteenveto" }]);
        if(this.props.adminUser){
            this.props.getEnterpriseDownloads({})
        }
        this.props.updateLatestAnswers()
        this.checkPlanSubscriptions()
    }

    componentWillUnmount() {
        this.props.clearTaskList();
    }

    // buildReportPayload = (surveyCode, targetId, surveyId, start, end) => {
    //     let payload = {
    //         activeTab: '1',
    //         surveyId: surveyId,
    //         recievedCode: surveyCode,
    //         targetId: targetId,
    //         startDate: start,
    //         endDate: end
    //     }

    //     this.props.onReportSwitch(payload)
    // }

    selectReport = async (obj) => {
        const params = {
            reports: [
                {
                    reportId: obj._id
                }
            ]
        }

        this.props.getReportForDashboard(params)

        if (obj.businessID !== 'anon') {
            await this.props.getReportTree({ businessId: obj.businessID })
            this.props.history.push('/raportit/yritys/raportti')
        } else {
            this.props.storeBusiness({ businessId: 'anon', name: 'Ei kohdeyritystä' })
            this.props.history.push('/raportit/kohdistamaton/raportti')
        }
    }

    goToReport = async (id, businessId = 'anon') => {
        const params = {
            reports: [
                {
                    reportId: id
                }
            ]
        }

        this.props.getReportForDashboard(params)

        if (businessId !== 'anon') {
            await this.props.getReportTree({ businessId: businessId })
            this.props.history.push('/raportit/yritys/raportti')
        } else {
            this.props.storeBusiness({ businessId: 'anon', name: 'Ei kohdeyritystä' })
            this.props.history.push('/raportit/kohdistamaton/raportti')
        }
    }

    selectBusiness = (params) => {
        this.props.addBreadcrumbs({ to: 'raportit/yritys', label: params.name, businessId: params.id })
        this.props.onBusinessSelect({ businessId: params.id, name: params.name })
        this.props.getReportTree({ businessId: params.id })
        this.props.history.push("/raportit/yritys")
    }

    // :D
    selectReportFromTask = async (obj) => {
        const { getReportForDashboard, getReportTree, storeBusiness } = this.props

        const params = {
            reports: []
        }

        if (obj && obj.taskTree) {
            obj.taskTree.surveyTitles.forEach(taskSurveyObj => {
                params.reports.push({ reportId: taskSurveyObj.id })
            })

            getReportForDashboard(params)

            if (obj.targetCompany && obj.targetCompany !== 'anon') {
                await getReportTree({ businessId: obj.targetCompany })
                this.props.history.push('/raportit/yritys/raportti')
            } else {
                storeBusiness({ name: 'Ei kohdeyritystä' })
                this.props.history.push('/raportit/kohdistamaton/raportti')
            }
        }
    }

    togglePlan = (planId) => {
        this.props.fetchAllPlans();
        this.props.onPlanEdit(planId);
        this.props.history.push('/lomakkeet/luonti')
    }

    goToNewPlan = () => {
        this.props.history.push('/lomakkeet/uusi')
    }

    checkPlanSubscriptions = () => {
        if(this.props.planSubscriptions && this.props.planSubscriptions.length > 0){
            
            if(window.localStorage.getItem('editingPlans') !== null){
                    
                let editing = window.localStorage.getItem('editingPlans')
                try{
                    let editingList = []
                    editingList = JSON.parse(editing)                
                    editingList.filter(id => id.length > 0).map((id, index) =>{
                        this.props.unsubscribePlanEdit({"planId": id})
                    }) 
                    window.localStorage.removeItem('editingPlans')
                } catch (exception) {
                    window.localStorage.removeItem('editingPlans')
                }
            }
           
        }
    }

    render() {
        const { sentSurveys, tasks, modulesInUse, latestAnswers } = this.props

        return (
            this.state.showAgreement ?
                (
                    <Row>
                        <AgreementModal onAction={this.props.onAgreement} />
                    </Row>
                ) : (
                    <div>
                        <div className="dashboard-container">
                            <DashboardMain
                                sentSurveys={sentSurveys}
                                tasks={tasks}
                                updateTask={this.props.updateTask}
                                selectReport={this.selectReport}
                                goToReport={this.goToReport}
                                dashboardSummary={this.props.dashboardSummary}
                                selectReportFromTask={this.selectReportFromTask}
                                activeBusinessList={this.props.activeBusinessList}
                                selectBusiness={this.selectBusiness}
                                onPlanEdit={ this.togglePlan }
                                modulesInUse={ modulesInUse }
                                latestAnswers={latestAnswers}
                                filterTypes={this.props.filterTypes}
                                activeFilters={this.props.activeFilters}
                                addReportFilter={this.props.addReportFilter}
                                getReport={this.props.getReport}
                                getReportTree={this.props.getReportTree}
                                history={this.props.history}
                                storeBusiness={this.props.storeBusiness}
                                news={this.props.news}
                                showNews={this.props.showNews}
                                goToNewPlan={this.goToNewPlan}
                                firstLoginDone={this.props.firstLoginDone}
                                adminUser={this.props.adminUser}
                                enterpriseDownloads={this.props.enterpriseDownloads}
                                handleFirstLoginDone={this.props.handleFirstLoginDone}
                            />
                        </div>
                    </div>
                )
        )
    }
}



const mapStateToProps = state => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        categories: state.kartoitus.categories,
        surveyTree: state.reports.surveyTree,
        sentSurveys: state.sender.sentSurveys,
        // currentlyActiveTab: state.reportAndTab.activeTab,
        sentAnonSurveys: state.sender.sentAnonSurveys,
        // openComparison: state.reportAndTab.openComparison,
        agreement: state.authentication.agreement,
        news: state.authentication.news,
        showNews: state.authentication.showNews,
        tasks: state.sender.tasksForDashboard,
        dashboardSummary: state.kartoitus.dashboardSummary,
        businessName: state.authentication.businessName,
        businessPath: state.authentication.businessURL,
        activeBusinessList: state.kartoitus.activeBusinessList,
        modulesInUse: state.authentication.modulesInUse,
        latestAnswers: state.reports.latestAnswers,
        filterTypes: state.reports.filterTypes,
        activeFilters: state.reports.activeFilters,
        planSubscriptions: state.authentication.planSubscriptions,
        listOrder: state.authentication.listOrder,
        firstLoginDone: state.authentication.firstLoginDone,
        adminUser: state.authentication.adminUser? state.authentication.adminUser: false,
        enterpriseDownloads: state.utils.enterpriseDownloads
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onAgreement: (payload) => dispatch(ActionCreator.acceptAgreement(payload)),
        getSentSurveysForDashboard: (payload) => dispatch(ActionCreator.getSentSurveysForDashboard(payload)),
        getTasksForDashboard: (payload) => dispatch(ActionCreator.getTasksForDashboard(payload)),
        updateTask: (payload) => dispatch(ActionCreator.saveTaskFromDashboard(payload)),
        getReportForDashboard: (payload) => dispatch(ActionCreator.getReportForDashboard(payload)),
        getReportTree: (payload) => dispatch(ActionCreator.getBusinessSurveyTree(payload)),
        setBreadcrumbs: (params) => dispatch(ActionCreator.setBreadcrumbs(params)),
        getDashboardSummary: (params) => dispatch(ActionCreator.getDashboardSummary(params)),
        addMoreBreadcrumbs: (params) => dispatch(ActionCreator.addMoreBreadcrumbs(params)),
        addBreadcrumbs: (params) => dispatch(ActionCreator.addBreadcrumbs(params)),
        storeBusiness: (params) => dispatch(ActionCreator.storeBusiness(params)),
        getActiveBusinessList: (payload) => dispatch(ActionCreator.getActiveBusinessList(payload)),
        onBusinessSelect: (params) => dispatch(ActionCreator.selectBusinessForReport(params)),
        onPlanEdit: (planId) => dispatch(startEditingPlan(planId)),
        fetchAllPlans: () => dispatch(fetchAllPlans()),
        clearTaskList: () => dispatch(ActionCreator.clearTaskList()),
        updateLatestAnswers: () => dispatch(ActionCreator.updateLatestAnswers()),
        addReportFilter: (params) => dispatch(ActionCreator.addFilter(params)),
        getReport: (params) => dispatch(ActionCreator.getReportForDashboard(params)),
        getReportTree: (params) => dispatch(ActionCreator.getBusinessSurveyTree(params)),
        unsubscribePlanEdit: (params) => dispatch(ActionCreatorPlan.unsubscribePlanEdit(params)),
        getEnterpriseDownloads: (params) => dispatch(ActionCreator.getEnterpriseDownloads(params)),
        handleFirstLoginDone: (params) => dispatch(ActionCreator.handleFirstLoginDone(params))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
import React, {useEffect, useState} from 'react';
import { Row, Col, Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';


export const Breadcrumbs = (props) => {
    const user = useSelector(state => state.authentication.user)
    const trial = useSelector(state => state.authentication.trial?state.authentication.trial:false)
    const trialEnd = useSelector(state => state.authentication.trialEnd?state.authentication.trialEnd:false)
    const [trialLeft, setTrialLeft] = useState("")

    useEffect(()=>{
        calculateTrial()
    },[trialEnd, props])

    const calculateTrial = () => {
        if(trialEnd){
            let today =  moment.utc(new Date(),"MMM D, YYYY hh:mm:ss A", "en").local()
            let endDate = moment.utc(trialEnd,"MMM D, YYYY hh:mm:ss A", "en").local()
            if(today.isBefore(endDate)){
                setTrialLeft(endDate.diff(today,"days"))
            } else if(today.isSame(endDate)) {
                setTrialLeft(0)
            } else {
                setTrialLeft(-1)
            }
        }
    }
    return (
        <div style={{ marginLeft: props.margin }} className="breadcrumbs">
            <Container fluid style={{ paddingBottom: 0 }}>
                {trial && <div className="trial-left-div">{trialLeft >= 0? ("Kokeilujaksoa jäljellä " + trialLeft + " päivää"): "Kokeilujakso on päättynyt"}</div>}
                <Row>
                    <Col xs="8" style={{ display: 'flex', paddingTop: '1em', alignItems: 'center' }}>
                        <NavLink to="/yhteenveto" style={{color: '#404040'}}><i className="fas fa-home"> </i></NavLink>
                        {props.crumbs && props.crumbs.length > 0 && props.crumbs.map((crumb, index) => {
                            return <p key={index} className="lighter-text crumb-overflow-handler" style={{ color: '#000' }}>
                                <span onClick={() => props.addMoreBreadcrumbs(crumb)}><span style={{ padding: '0em 0.5em' }}>/</span><NavLink to={{ pathname: "/" + crumb.to, ...crumb }}  style={{ color: '#404040' }}>{crumb.label}</NavLink></span>
                            </p>
                        })
                        }
                    </Col>
                    <Col xs="4" style={{ display: 'flex', paddingTop: '1em', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <p className="lighter-text crumb-overflow-handler">{user}</p>
                    </Col>
                </Row>
                {/* <Row style={{ position:'absolute', width: '100%', top: '1em'}}>
                    <Col xs="12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width:'100%'  }}> 
                        <p style={{backgroundColor: '#f7bbad9c', padding: '0.2em 2em', borderRadius:'10px' }}>Huoltokatko sovelluksessa 7.10.2022 klo 15:00 - 9.10.2022 klo 01:00</p>
                    </Col>
                </Row> */}
            </Container>
        </div>
    )
}

export default Breadcrumbs;
import React, { useState, useEffect } from 'react';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap'
import '../../Dashboard/subComponents/dashboardGantt.css'
import moment from 'moment'
import Select from 'react-select';
import TextEditor from '../../../Utilities/TextEditor/texteditor';
import { ModifiedContainer } from './DataContainers';
import { DateRangePicker } from 'react-dates';
import { TagHandler } from '../../Project/subComponents/TagHandler';
import { GanttPhaseModal } from '../Modals/GanttPhaseModal';

const testTasks = [
    {
        start: new Date(moment("2022-11-22T10:00:00.000Z").format("yyyy.MM.DD")),
        end: new Date(moment("2022-12-22T10:00:00.000Z").format("yyyy.MM.DD")),
        name: 'Test',
        id: 'Project 0',
        type:'project',
        progress: 0,
        isDisabled: true,
        project:'Project 0',
        styles: { progressColor: '#acd811', progressSelectedColor: '#fac465', backgroundColor:'#fac465', backgroundSelectedColor: '#fac465' },
        description:'Testi',
        tasks:[
            {
                start: new Date(moment("2022-11-22T10:00:00.000Z").format("yyyy.MM.DD")),
                end: new Date(moment("2022-12-01T10:00:00.000Z").format("yyyy.MM.DD")),
                name: 'Testii',
                id: 'Task 1',
                type:'task',
                progress: 0,
                isDisabled: false,
                project:'Project 0',
                styles: { progressColor: '#acd811', progressSelectedColor: '#aeb8c2', backgroundSelectedColor: '#aeb8c2' },
                checked:false,
                description:'Testi'
            },
            {
                start: new Date(moment("2022-11-29T10:00:00.000Z").format("yyyy.MM.DD")),
                end: new Date(moment("2022-12-23T10:00:00.000Z").format("yyyy.MM.DD")),
                name: 'T1',
                id: 'Task 0',
                type:'task',
                progress: 0,
                isDisabled: false,
                project:'Project 0',
                styles: { progressColor: '#acd811', progressSelectedColor: '#aeb8c2', backgroundSelectedColor: '#aeb8c2' },
                checked: false,
                description:''
            }
        ]
    }
    
]

const viewOptions = [
    {label: 'Päivä', value: 'Day'},
    {label: 'Viikko', value: 'Week'},
    {label: 'Kuukausi', value: 'Month'},
    {label:'Vuosi', value:'Year'}
]

const orderOptions = [
    {label:'Muokattu', value: 'modified'},
    {label:'Alku pvm', value: 'startDate'},
    {label:'Loppu pvm', value: 'endDate'},
    {label:'Nimi', value: 'name'},
]

// TODO - sort order projects by start or name

export const GanttBlock = ({editable, item, index , promptSave, toggleItemVisibility, onDelete, saveWithoutQueue, toggleDrag, onTemplateAdd, layoutPreview = false}) => {
    const [title, setTitle] = useState((item && item.topicContent)? item.topicContent :"Vaiheet")
    const [phaseList, setPhaseList] = useState([])
    const [phases, setPhases] = useState([])
    const [selectedView, setSelectedView] = useState(viewOptions[1])
    const [search, setSearch] = useState("")
    const [selectedOrder, setSelectedOrder] = useState({by:'start', ascending:false})
    const [editGantt, setEditGantt] = useState(false)
    const [selectedPhase, setSelectedPhase] = useState(null)
    const [editPhase, setEditPhase] = useState(false)
    const [openMenu, toggleMenu] = useState(false)
    const [calculatedHeight, setCalculatedHeight] = useState("350px")
    const [height, setHeight] = useState(calculatedHeight)
    const [filteredPhases, setFilteredPhases] = useState([])
    const [visibility, setVisibility] = useState({})
    const [locked, setLocked] = useState(true)
    const [showTags, setShowTags] = useState(false)
    
    const handleDataChanges = (data) => {
        setTitle(data)
        let itemPayload = {
            ...item,
            topicContent: data
        }
        promptSave(itemPayload)
    }

    useEffect(()=>{
        if(item.phases){
            setPhases([...item.phases])
        }
    },[item])

    useEffect(()=>{
       calculateHeight() 
    },[phases, filteredPhases])

    useEffect(()=>{
        if(phases.length > 0){
            handleData(phases)
        }
    },[phases, setPhases])

    const handleData = (list) => {
        let array = []
        list.map((phase) => {
            let object = {
                ...phase,
                start: moment(phase.start).set({hour:0,minute:0,second:0,millisecond:0}).local().toDate(),
                end: moment(phase.end).set({hour:23,minute:0,second:0,millisecond:0}).local().toDate()
            }
            // delete object.tasks
            array = [...array, object]

            if(phase && phase.tasks && phase.tasks.length > 0){
                let tasks = phase.tasks
                tasks.map((task)=>{
                    task = {
                        ...task,
                        start: moment(task.start).set({hour:0,minute:0,second:0,millisecond:0}).local().toDate(),
                        end: moment(task.end).set({hour:23,minute:0,second:0,millisecond:0}).local().toDate()
                    }
                    array = [...array, task]
                })
            }
        })
        setPhaseList([...array]);
    }

    let date = new Date()
    date.setDate(date.getDate() - 3);

    const handleFilter = (phase) => {
        const {tags, name} = phase

        if(name.toUpperCase().includes(search.toUpperCase())) return true

        if(tags !== undefined && tags.length > 0){     
            if(tags.filter(tag => tag.label.toUpperCase().includes(search.toUpperCase())).length > 0) return true
        }
        
    }

    useEffect(()=>{
        if(phases){
            let visibilitySettings = visibility
            let array = [...phases]
            array.map((phase,key)=>{
                if(phase.type === 'project'){
                    visibilitySettings = {
                        ...visibilitySettings,
                        [phase.id]: false
                    }
                }
            })
            setVisibility(visibilitySettings)
        }
        if(editable) {toggleDrag(false)}
    },[])

    const filterVisibility = () => {       
        if(phaseList){
            if(search && search.length > 0){
                setFilteredPhases([...phaseList])
            }
            else{
                let array = phaseList.filter((phase, index) =>{
                    if(phase.type === "project" || (visibility[phase.project])){
                        return true
                    } else {
                        return false
                    }
                })
                setFilteredPhases([...array])
            }
        }
    }

    useEffect(()=>{
        filterVisibility()
    },[phases, phaseList, visibility, search])

    const customTaskTooltip = (props) => {
        const {task} = props
        if(task){
            let start = moment(task.start).toDate()
            let end = moment(task.end).toDate()
            let startDate = start.getDate() + "." + (start.getMonth()+1) + "." + start.getFullYear()
            let endDate = end.getDate() + "." + (end.getMonth()+1) + "." + end.getFullYear()
            return(
                <div className="_3T42e">
                    <div className='_29NTg' style={{ fontSize:'1em'}} ><b>{task.name}</b></div>
                    <div className='_29NTg'>{task.targetBusiness}</div>
                    <div className='_29NTg'>{task.description}</div>
                    <div className='_29NTg' style={{ fontSize:'0.9em'}} ><b>{startDate +"-" + endDate}</b></div>
                    <div style={{ display:'flex' }}>
                        {task.tags && task.tags.length > 0 && task.tags.map((tag,key)=>{
                            return(
                                <p key={key} className="tag-display tag-text project-lighter-color">{tag.label}</p>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    const orderBy = (by) => {
        let array = [...phaseList]        
        if(selectedOrder.by === by){
            if(by === "name"){
                if(selectedOrder.ascending){
                    array.sort((a,b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA > nameB) {
                          return -1;
                        }
                        if (nameA < nameB) {
                          return 1;
                        }
                        return 0;
                    })
                } else {
                    array.sort((a,b) => {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0;
                    })
                }
            } else {
                if(selectedOrder.ascending){
                    array.sort((a,b) => a[by] - b[by])
                } else {
                    array.sort((a,b) => b[by] - a[by])
                }
            }
             setSelectedOrder({by: by, ascending: !selectedOrder.ascending})
        } else {
            if(by === "name"){               
                array.sort((a,b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                })
                setSelectedOrder({by: by, ascending: false})
            } else {
                array.sort((a,b) => b[by] - a[by])
                setSelectedOrder({by: by, ascending: true})
            }
            
        }
        // setPhaseList([...array])
    }

    const handleCheckItemChange = (task,index) => {
        let list = [...phases]
        let array = list.map((phase, index)=>{
            if(phase.id === task.project){
                let tasks = phase.tasks
                let taskList = tasks.map((obj)=>{
                    if(obj.id === task.id){
                        obj = {
                            ...obj,
                            checked:!obj.checked,
                            progress:!obj.checked === true? 100:0,
                            styles: { progressColor: !obj.checked === true?'#acd811' : '#aeb8c2', progressSelectedColor: !obj.checked === true?'#acd811' : '#aeb8c2', backgroundSelectedColor: !obj.checked === true?'#acd811' : '#aeb8c2' },
                        }
                    }
                    return obj
                })
                phase = {
                    ...phase,
                    tasks:[...taskList]
                }
            }
            return phase 
        })
        let updated = calculateProgress(task, [...array])
        saveModifications(updated)
    }

    const updatePhases = (list) => {
        setPhases([...list])
    }

    const handleSave = () => {
       let array = []
        let itemPayload = {
            id: item.id,
            topicContent: title,
            phases:[...array],
            type: item.type
        }
        saveWithoutQueue(itemPayload)
    }

    const toggleChildrenVisibility = (task, index) => {
        if(task && task.type){
            if(task.type==="project"){
                let obj = {
                    ...visibility,
                    [task.id]: !visibility[task.id]
                }
                setVisibility(obj)
            }
        }
    }

    const calculateProgress = (task, list) => {

        let array = [...list.map((phase)=>{
            if(phase.id === task.project){
                let progress = 0
                let count = 0
                let tasks = phase.tasks
                tasks.map((obj)=>{
                    progress += obj.progress
                    count++
                })
           
                phase = {
                    ...phase,
                    progress: (progress/count)
                } 
            }
            return phase
        })]
        setPhases([...array])
        return array
    }

    useEffect(()=>{

    },[])

    const tagsToMore = (tags, slicer) => {
        var tagList = ""
        if(tags !== undefined ){
            tags.slice(slicer, tags.length).filter(t => tagList = tagList + "  " + t.label.toString())
            return(tagList)
        }
    }
 
    const customTable = (props) => {
        const {headerHeight, fontFamily, fontSize, rowHeight, tasks} = props
        const weekDay = ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la']
        return(
            <div style={{border:'1px solid #e6e4e4 ', marginRight:'0em', marginLeft:'-2px'}}>
                {tasks && tasks.map((task, index) => {
                    let oddEvenRow = index%2 === 0 ? 'even' : 'odd'
                    let start = moment(task.start).toDate()
                    let end = moment(task.end).toDate()
                    let startDate = weekDay[start.getDay()] + " " + start.getDate() + "." + (start.getMonth()+1) + "." + start.getFullYear()
                    let endDate = weekDay[end.getDay()] + " " + end.getDate() + "." + (end.getMonth()+1) + "." + end.getFullYear()
                    return(
                        <div key={index} className={'gantt-row-styles gantt-row-'+oddEvenRow} style={{ height: (rowHeight), display:'flex' , paddingLeft:'1.3em', marginTop:'-0.8px', cursor:'pointer'}} onClick={() => (task.type==="project"? toggleChildrenVisibility(task, index) : "")}>
                            {task.type !== 'project' && 
                                <div style={{width:'3em', fontSize:'0.95rem', marginLeft:'', display:'flex', flexDirection:'column', textOverflow:'ellipsis', overflow:'hidden', wordBreak:'keep-all', marginTop:'0.5em', whiteSpace:'nowrap'}} title={task.name}>
                                    <input type="checkbox" className="check-item-box" onChange={() => handleCheckItemChange(task, index)} checked={task.checked}/>
                                </div>
                            }
                            {/* <div  style={{marginLeft:'-1.5em', borderLeft:'1px solid #404040', marginTop: '0.5em', marginBottom:'0.5em'}}></div> */}
                            <div style={task.type !== 'project'?  {width: '12em', fontSize: fontSize , marginLeft:'1em', display:'flex', marginTop:'0.3em', fontWeight: 'normal'} : {width: '15em', fontSize: '1rem' , marginLeft:'0em', display:'flex', marginTop:'0.4em', fontWeight: 'bold', marginRight:'-0.75em'}} title={task.name}>
                                <div style={{width: (task.type !== 'project'? '10em': '13em'), fontSize:'0.85rem', lineHeight:'20px', overflow:'hidden' ,marginTop:(task.type !== 'project'? "0.25em":"") }}>{task.name}</div>
                                {task.type === 'project' && <div style={{marginRight:'2em', fontSize:'0.8rem', marginLeft:'auto', marginTop:'0.15em'}}>{"(" + task.tasks.length+ ")"}</div>}
                            </div>
                            {/* <div  style={{marginLeft:'-0.5em', borderLeft:'1px solid #404040', marginTop: '0.5em', marginBottom:'0.5em'}}></div> */}
                            <div style={{width:'7em', fontSize:fontSize, marginLeft:'', display:'flex', marginTop:'0.3em'}}>
                            {startDate} - {endDate}
                            </div>
                            {showTags &&
                                <div  style={{marginLeft:'0.5em', marginTop: '0.5em', marginBottom:'0.5em', display:'flex', width:'7em', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    {task.tags && task.tags.slice(0, 3).map((tag, i) => {                        
                                        return(<div key={i} style={{ marginLeft: '0.15em', paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                    })}                                                       
                                    {task.tags && task.tags.length > 3 &&  
                                        <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                            <i className="material-icons" title={tagsToMore(task.tags,3)}>more_horiz</i>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }

    const customHeader = (props) => {
        const {headerHeight, fontFamily, fontSize, rowWidth} = props
        return(
            <div className='_3_ygE' style={{ height: (headerHeight-0.2), display:'flex', backgroundColor:'#fff', borderRadius:'10px 0 0 0', borderRight:'2px solid #f5f5f5', marginLeft:'-1px', marginTop:''}}>
                <div style={{width:'17em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'1.25em', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=> orderBy("start")}>
                    Nimi
                    {/* <div style={{ display:'flex', flexDirection:'column', marginLeft:'1em'}}>
                        <i className="fas fa-sort-up" style={{ color: (selectedOrder && selectedOrder.by === "name" && !selectedOrder.ascending) ? "#404040" : "#ebebeb", marginBottom:'-0.75em'}}></i>
                        <i className="fas fa-sort-down" style={{ color:(selectedOrder && selectedOrder.by === "name" && selectedOrder.ascending) ? "#404040" : "#ebebeb"}}></i>             
                    </div> */}
                </div>
                <div  style={{marginLeft:'-1em', borderLeft:'2px solid #e6e4e4 ', marginTop: '0.5em', marginBottom:'0.5em'}}></div>
                <div style={{width:'7em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'0.5em', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=> orderBy("end")}>
                    Alku pvm - Tavoite pvm
                    {/* <div style={{ display:'flex', flexDirection:'column', marginLeft:'1em'}}>
                        <i className="fas fa-sort-up" style={{ color:(selectedOrder && selectedOrder.by === "start" && !selectedOrder.ascending) ? "#404040" : "#ebebeb", marginBottom:'-0.75em'}}></i>
                        <i className="fas fa-sort-down" style={{ color:(selectedOrder && selectedOrder.by === "start" && selectedOrder.ascending) ? "#404040" : "#ebebeb"}}></i>             
                    </div> */}
                </div>
                {showTags && 
                    <>
                        <div  style={{marginLeft:'0em', borderLeft:'2px solid #e6e4e4 ', marginTop: '0.5em', marginBottom:'0.5em'}}></div>
                        <div style={{width:'7em', fontFamily: fontFamily, fontSize:fontSize, marginLeft:'0.5em', display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=> orderBy("end")}>
                            Tagit
                        </div>
                    </>
                }
            </div>
        )
    }

    const calculateHeight = () => {
        let value = "350px"
        if(phases){ 
            if(filteredPhases && filteredPhases.length > 0 && ((filteredPhases.length * 50) <= 350)){ 
                value = (filteredPhases.length * 50) + "px"
            } 
        }
        setCalculatedHeight(value)
    }

    //Gets changed task and updates it
    const handleDateChange = (changed) => {
        let list = [...phases]
        let earliest = ""
        let latest = ""
        let array = list.map((phase)=>{
            if(phase.id === changed.project){
                let tasks = [...phase.tasks]
                let taskList = tasks.map((task, index)=>{
                    if(index === 0){
                        let start = ""
                        let end = ""
                        if(task.id === changed.id){
                            start = moment(changed.start).set({hour:0,minute:0,second:0,millisecond:0}).local().toDate()
                            end = moment(changed.end).set({hour:23,minute:0,second:0,millisecond:0}).local().toDate()
                        } else {
                            start = moment(task.start).set({hour:0,minute:0,second:0,millisecond:0}).local().toDate()
                            end = moment(task.end).set({hour:23,minute:0,second:0,millisecond:0}).local().toDate()
                        }  
                        earliest = start
                        latest = end
                    }

                    if(task.id === changed.id){
                        let start = moment(changed.start).set({hour:0,minute:0,second:0,millisecond:0}).local().toDate()
                        let end = moment(changed.end).set({hour:23,minute:0,second:0,millisecond:0}).local().toDate()
                        if(start < earliest){
                            earliest = start
                        }
                        if(end > latest){
                            latest = end
                        }
                        return {...changed}
                    } else {
                        let start = moment(task.start).set({hour:0,minute:0,second:0,millisecond:0}).local().toDate()
                        let end = moment(task.end).set({hour:23,minute:0,second:0,millisecond:0}).local().toDate()
                        if(start < earliest){
                            earliest = start
                        }
                        if(end > latest){
                            latest = end
                        }
                        return task
                    }
                })

                let start = moment(changed.start).set({hour:0,minute:0,second:0,millisecond:0}).local().toDate()
                let end = moment(changed.end).set({hour:23,minute:0,second:0,millisecond:0}).local().toDate()
                if(start < earliest){
                    earliest = start
                }
                if(end > latest){
                    latest = end
                }
                phase = {
                    ...phase,
                    start:earliest,
                    end:latest,
                    tasks: [...taskList]
                }
                return {
                    ...phase,
                    start:earliest,
                    end:latest,
                    tasks: [...taskList]
                }
            }
            
            return phase
        })
        setPhases([...array])
        saveModifications(array)
    }

    const handleDragLock = (event) => {
        if(event==="lock"){
            toggleDrag(false)
        } else {
            toggleDrag(true)
        }
        setLocked(!locked)
    }

    const toggleCheckingAllowed = () => {
        let itemPayload = {
            ...item,
            topicContent: title,
            allowChecking:(item.allowChecking !== undefined) ? !item.allowChecking : true
        }
        saveWithoutQueue(itemPayload)
    }

    const saveModifications = (phaseList) => {
        
        let itemPayload = {
            ...item,
            topicContent: title,
            phases: phaseList
        }
        saveWithoutQueue(itemPayload)
    }

    return(
        <Row style={{paddingBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
            <GanttPhaseModal isOpen={editGantt} toggle={()=> setEditGantt(!editGantt)} save={saveModifications} ganttPhases={phases} setGanttPhases={setPhases}/>
            <Col xl="12">
                {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red' }}>Yksityinen</span>}
                {(!editable && !layoutPreview && item.modified !== undefined) && 
                    <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                }
            </Col>
            <Col xl="12" style={{marginBottom: 8}} className="title-col">
                <div style={{ display: 'flex' }}>
                    {!layoutPreview && <div className="plan-input subTitle" style={{marginRight: '0.5em', cursor: 'default', alignSelf: 'center'}}>{index + 1}</div>}            
                    <TextEditor 
                        editorId={`${item.id}-subtitle-${editable}`} 
                        content={title} 
                        onTextChange={editorState => handleDataChanges(editorState)}
                        additionalClass="plan-input subTitle plan-subtitle"
                        placeholder="-"
                        readOnly={!editable}
                        plaintextEditor={true}
                    />
                </div>
                
            </Col>
            <Col xs="11" style={{}} draggable={false}>
                <div style={{ display: 'flex', width:'100%', marginBottom:'0.25em'}}>                    
                    <div style={{marginLeft:'0em', marginTop:'0.5em'}}>
                        <form id="search-bar" className="gantt-search" style={{width:'12em'}}>
                            <input type="search" className="search-bar-input gantt-search" placeholder="Haku" value={search} onChange={(event)=> setSearch(event.target.value)} />
                        </form>
                    </div>    
                    <div style={{ marginLeft:'auto', marginRight:'0', display:'flex'}}>
                        {editable &&
                            <div style={{ marginRight: '1em'}} title={"Avaa tai lukitse mahdollisuus liikuttaa tätä kappaletta (vaikuttaa vain kappalejärjestyksen muuttamiseen)"}>
                                {locked?
                                    <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={()=> handleDragLock("unlock")}>
                                        <i className="fas fa-lock"></i>
                                    </button>
                                    :
                                    <button className={"small-white-button lighter-text " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={()=> handleDragLock("lock")}>
                                        <i className="fas fa-lock-open"></i>
                                    </button>
                                }
                            </div>
                        }
                        <div style={{ marginLeft:'auto', marginRight:'0', display:'flex'}}>
                            <div style={{ marginLeft:'auto'}}>
                                <button className={"small-white-button " } style={{ padding: '0.2em 0.5em' }} onClick={()=>setShowTags(!showTags)} title="Näytä tai piilota tagit"><i className="fas fa-tags"></i></button>
                            </div>
                            <div style={{marginRight:'0.5em', marginLeft:'auto'}}>
                                {height !== "85vh" && <button className={"small-white-button " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => setHeight("85vh")}><i className="fas fa-expand"></i></button>}
                                {height === "85vh" && <button className={"small-white-button " } style={{ padding: '0.2em 0.5em', marginLeft: '0.5em' }} onClick={ () => setHeight(calculatedHeight)}><i className="fas fa-compress"></i></button>}
                            </div>
                            <div style={{ width:'8em', marginTop:'-0.25em' }}>
                                <Select
                                    options={viewOptions}
                                    onChange={(val)=> setSelectedView(val)}
                                    value={selectedView}
                                    isDisabled={false}
                                    noOptionsMessage={() => { return "Ei asetuksia" }}
                                    className="survey-selector gantt-select"
                                    classNamePrefix="select"  
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor:'#fff', borderRadius:'10px', padding: '0 0.25em 0 0', border:'1px solid #e6e4e4 '}} >
                    {phases && filteredPhases.filter( task => handleFilter(task)).length > 0 &&
                        <Gantt 
                            tasks={filteredPhases.filter(task => handleFilter(task))}
                            viewMode={selectedView.value}
                            viewDate={date}
                            locale={'fin'}
                            ganttHeight={height === "85vh" ? height : calculatedHeight}
                            preStepsCount={2}
                            TooltipContent={customTaskTooltip}
                            todayColor={"#D811AC20"} 
                            projectBackgroundSelectedColor={'#D811AC'}  
                            TaskListHeader={customHeader} 
                            TaskListTable={customTable}    
                            onDateChange={(editable && locked)? handleDateChange : false}  
                            
                        />
                    }
                </div>
            </Col>
            <Col xs="1">
            <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                    {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                        <DropdownToggle tag="div">
                            <i className="fas fa-bars plusIcon" title="Valikko"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                            <DropdownItem onClick={() => setEditGantt(true)} className="change-status">Muokkaa</DropdownItem>
                            <DropdownItem onClick={() => onTemplateAdd({planItem: item})} className="add-template">Lisää pohjaksi</DropdownItem>
                            <DropdownItem onClick={() => toggleCheckingAllowed()} className="change-status">{(item.allowChecking !== undefined && item.allowChecking) ? "Estä tehtävien merkkaaminen julkaistussa" : "Salli tehtävien merkkaaminen julkaistussa"}</DropdownItem>
                            <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.blockName})} className="delete-plan-item">Poista</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                    </div>
            </Col>
        </Row>
    )
}
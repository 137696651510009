import * as ActionTypes from '../../../Components/Project/Actions/ActionTypes';

const initialState = {
    selectedProject: {},
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_SELECTED_PROJECT:
            return {
                ...state,
               selectedProject: {...action.payload}
            }
        default:
            return state;
    }
}

export default reducer;
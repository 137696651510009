import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {executeRequest} from '../requestHandler/dataHandler';
import { NotificationManager } from 'react-notifications';
export const CreateHubSpotModal = ({isOpen, toggle, business}) => {
    const {hubSpot} = business
    const [hubSpotId, setHubSpotId] = useState(hubSpot ? hubSpot.hubSpotCompanyId : "");
    const [hubSpotPortalId, setHubSpotPortalId] = useState("")
    
    const [modules, setModules] = useState((hubSpot && hubSpot.modules) ? {
        ...hubSpot.modules
    } : 
        {
            contacts: false,
            deals: false
        }
    );

    useEffect(()=>{
        if(business && business.hubSpot){
            if(hubSpot.hubSpotPortalId){
                setHubSpotPortalId(hubSpot.hubSpotPortalId)
            }
        }
    },[business])

    const dispatch = useDispatch();

    const usingEnterprise = useSelector(state => state.authentication.businessName)

    const addHubSpot = async () => {
        
        if(hubSpot !== undefined) {
            let payload = {
                hubSpotId: hubSpot.hubSpotId,
                hubSpotCompanyId: hubSpotId,
                modules: modules,
                hubSpotPortalId: hubSpotPortalId
            }
    
            const response = await executeRequest('hubspot/edit', payload, dispatch);

            if(!!response) {
                if(response.hubSpotId !== undefined) {
                    dispatch({
                        type: "SET_HUBSPOT_SETTINGS",
                        payload: response
                    });
                    NotificationManager.success("Asetuksia muokattu onnistuneesti", "",);
                    toggle();
                } else {
                    NotificationManager.error("Asetuksien tallennus epäonnistui", "", 2000);
                }
            }
        } else {
            let payload = {
                hubSpotCompanyId: hubSpotId,
                businessId: business._id,
                modules: modules,
                hubSpotPortalId: hubSpotPortalId
            }
    
            const response = await executeRequest('hubspot/add', payload, dispatch);
    
            if(!!response) {
                if(response.status === 200) {
                    dispatch({
                        type: "SET_HUBSPOT_SETTINGS",
                        payload: response
                    });
                    NotificationManager.success("HubSpot integraatio luotu yritykselle", "", 2000);
                    toggle();
                } else if(response.status === "EXISTING_HUB_SPOT") {
                    NotificationManager.error("Yritykselle on jo lisätty Hubspot", "Virhe", 2000);
                }
            }
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>HubSpot Integraatio</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label htmlFor="hubspotCompanyId">{`${business ? business.businessName : "Yrityksen"} HubSpot tunniste`}</Label>
                        <Input 
                            type="text" 
                            name="hubspotCompanyId" 
                            id="hubspotCompanyId" 
                            value={hubSpotId} 
                            onChange={(e) => setHubSpotId(e.target.value)} placeholder="Yrityksen HubSpot tunniste" />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="hubspotEnterpriseId">{`${usingEnterprise ? usingEnterprise : "Yrityksen"} HubSpot portal tunniste` }</Label>
                        <Input 
                            type="text" 
                            name="hubspotEnterpriseId" 
                            id="hubspotEnterpriseId" 
                            value={hubSpotPortalId} 
                            onChange={(e) => setHubSpotPortalId(e.target.value)} placeholder="Käyttävän yrityksen HubSpot portal tunniste" />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                        <Input type="checkbox" 
                        name="contacts" 
                        checked={ modules.contacts } 
                        onChange={(e) => setModules({...modules, [e.target.name]: e.target.checked})} />{' '}
                            Tuo kontaktit
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                        <Input type="checkbox" 
                        name="deals" 
                        checked={ modules.deals } 
                        onChange={(e) => setModules({...modules, [e.target.name]: e.target.checked})}/>{' '}
                            Tuo diilit
                        </Label>
                    </FormGroup>
                </Form>        
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                <Button onClick={addHubSpot}>Valmis</Button>
            </ModalFooter>
        </Modal>
    )
}

export const EditHubSpotModal = ({isOpen, toggle, hubSpot}) => {
    const [modules, setModules] = useState(hubSpot.modules ? {
        ...hubSpot.modules
    } : {
        
    });

    const [hubSpotCompanyId, setHubSpotCompanyId] = useState(hubSpot.hubSpotCompanyId);
    const dispatch = useDispatch();


    const editHubSpot = async () => {
        let payload = {
            hubSpotId: hubSpot.hubSpotId,
            hubSpotCompanyId: hubSpotCompanyId,
            modules: modules
        }

        const response = await executeRequest('hubspot/edit', payload, dispatch);

        if(!!response) {
            if(response.hubSpotId !== undefined) {
                dispatch({
                    type: "SET_HUBSPOT_SETTINGS",
                    payload: response
                });
                toggle();
            } else {
                NotificationManager.error("Asetuksien tallennus epäonnistui", "", 2000);
            }
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>HubSpot asetukset</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label htmlFor="hubspotCompanyId">HubSpot tunniste</Label>
                        <Input 
                            type="text" 
                            name="hubspotCompanyId" 
                            id="hubspotCompanyId" 
                            value={hubSpotCompanyId} 
                            onChange={(e) => setHubSpotCompanyId(e.target.value)}
                            placeholder="Yrityksen HubSpot tunniste" />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                        <Input type="checkbox" 
                        name="contacts" 
                        checked={ modules.contacts } 
                        onChange={(e) => setModules({...modules, [e.target.name]: e.target.checked})} />{' '}
                            Tuo kontaktit
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                        <Input type="checkbox" 
                        name="deals" 
                        checked={ modules.deals } 
                        onChange={(e) => setModules({...modules, [e.target.name]: e.target.checked})}/>{' '}
                            Tuo diilit
                        </Label>
                    </FormGroup>
                </Form>        
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Peruuta</a>
                <Button onClick={editHubSpot}>Valmis</Button>
            </ModalFooter>
        </Modal>
    )
}

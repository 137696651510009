import {NotificationManager} from 'react-notifications';
import {authFailed} from '../store/index';

export const responseHandler = response => dispatch => {
    if(!response.ok) {
        switch(response.status) {
            case 401:
                dispatch(authFailed())
                break;
            case 403:
                NotificationManager.error("Oikeutesi eivät riitä", "Virhe", 3000);
                break;
            default:
                NotificationManager.error("Pyyntö epäonnistui", "", 3000);
                break;
        }
        return;
    }

    return response.json();
}
import { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader, Label, Container, NavLink, NavItem, Nav } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { ProductList } from './subComponents/ProductList';
import { executeRequest } from '../../Utilities/requestHandler/dataHandler';
import { useDispatch, useSelector } from 'react-redux';
import FilterContainer from '../Kartoitus/Sender/modules/taskFilter/FilterContainer';
import FilterButton from '../Kartoitus/Sender/modules/taskFilter/FilterButton';
import { FileList } from './subComponents/FileList';

const customFilters = [
    {
        value: 'Kuvat',
        filter: 'image',
        target: 'image'
    },
    {
        value: 'PDF',
        filter: 'pdf',
        target: 'PDF'
    },
    {
        value: 'Excel',
        filter: 'xls',
        target: 'XLS'
    },
    {
        value: 'PowerPoint',
        filter: 'ppt',
        target: 'PPT'
    }
];

export const Tools = (props) => {
    const [activeTab, setActiveTab] = useState("files")
    const [filter, setFilter] = useState("")
    const [filterPlaceholder, setFilterPlaceholder] = useState("Etsi tiedostoa")
    const [newProductModal, setNewProductModal] = useState(false)
    const [productList, setProductList] = useState([])
    const [statusFilters, setStatusFilters] = useState([])
    const [fileList, setFileList] = useState([])
    const [fileStatistics, setFileStatistics] = useState(null)
    const [filesLoading, setFilesLoading] = useState(true)

    const modulesInUse = useSelector(state => state.authentication.modulesInUse)

    const handleChanges = (changes) => {
        setActiveTab(changes.activeTab)
        setFilter(changes.filter)
        setFilterPlaceholder(changes.filterPlaceholder)
    }

    useEffect(()=>{
        getProducts()
        getFiles()
        getFileStatistics()
    },[])

    const getProducts = async() => {
        const response = await executeRequest('product/getProductsForEnterprise', {})
        if(!!response){ 
            setProductList(response)
        }
    }

    const updateProducts = () => {
        getProducts()
    }

    const updateFiles = () => {
        getFiles()
        getFileStatistics()
    }

    const getFiles = async() => {
        const response = await executeRequest('get/filesForEnterprise', {})
        if(!!response){ 
            setFileList(response)
            setFilesLoading(false)
        }
    }

    const getFileStatistics = async() => {
        const response = await executeRequest('get/fileStatistics', {})
        if(!!response){ 
            setFileStatistics(response)
        }
    }

    const [loading, setLoading] = useState(false)
    const testConversion = async() => {
        
        const response = await executeRequest('convertAllFiles', {})
       
        if(response.success){
            setLoading(false)
            // console.log(response.files);
        }
        setLoading(false)
    }

    const filterFileList = (file) => {
        let isMatch = false

        if(statusFilters.length > 0){           
            statusFilters.map( filter => {
                if(filter.filter && file.mimeType && file.mimeType.toUpperCase().includes(filter.filter.toUpperCase())){isMatch = true}
                if((file.mimeType && !file.mimeType.toUpperCase().includes("IMAGE")) && file.fileEnd && file.fileEnd.toUpperCase().includes(filter.filter.toUpperCase())){ isMatch = true}
            })
        
        } else isMatch = true
        
        return isMatch
    }

    return(
        <Container fluid>
            <Row>
                <Col className="" xl="12">
                    <div className='tools-color' style={{ borderRadius: '10px' }}>
                        <div className="whitebox" style={{ marginTop: '1rem', paddingTop:0, paddingBottom: '1em', height: '100%', borderRadius: '30px' }}>
                            <div className="" style={{ paddingLeft: '2rem', paddingTop: '1em', paddingBottom:'0', marginBottom: '-2em', borderRadius: '10px 10px 0 0' }}>
                                <Row>
                                    <Col xs="10">
                                        <h2>Työkalut</h2>
                                    </Col>
                                    <Col xs="2">
                                        
                                    </Col>
                                </Row>
                                <Row className="kartoitusExplanation" style={{ paddingBottom: '0.5em' }}>
                                    <Col>
                                        <p>
                                            {"Työkaluissa voit hallinnoida tiedostoja" + (modulesInUse.planManagement? ", sekä tuotteita." : ".")}
                                        </p>
                                    
                                        {fileStatistics &&
                                            <div style={{justifySelf: 'center',  margin: '0.5em 0', marginLeft: '0em', display:'flex', flexDirection: 'row', fontWeight: 'bold' }}>
                                                <div><p>Tiedostot: {fileStatistics.totalAmount}kpl </p></div>     
                                                <div style={{ marginLeft: '1em' }}><p>{parseFloat(fileStatistics.totalSize) >= 0.5 ? (parseFloat(fileStatistics.totalSize).toFixed(2) > 1000 ? (parseFloat(fileStatistics.totalSize/1024).toFixed(2) + "GB") : parseFloat(fileStatistics.totalSize).toFixed(2) + "MB") : (parseFloat(fileStatistics.totalSize*1024).toFixed(2) + "KB")}</p></div>            
                                            </div>
                                        }
                                    </Col>
                                    {/* <Col>
                                        <button onClick={() => (testConversion(), setLoading(true))} className="small-white-button wide-button " style={{  }}>TestConversion</button> {loading && <MegaTinyLoaderSpinner/> }
                                    </Col> */}
                                </Row>
                            </div>
                            <Row>
                                <Col>
                                    <div className="bigger-text" style={{ display: 'flex', padding: '0 1em' }}>
                                        <Nav pills className="project-nav-tabs plan-tabs">
                                            <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                <NavLink 
                                                    className={activeTab === 'files' ? 'active' : ''}
                                                    onClick={() => handleChanges({activeTab: 'files', filter: "", filterPlaceholder: 'Etsi tiedostoa'})}
                                                >
                                                    Tiedostot
                                                </NavLink>
                                            </NavItem>
                                            {modulesInUse.planManagement &&
                                                <NavItem style={{ width: '10em', textAlign: 'center' }} >
                                                    <NavLink
                                                        className={activeTab === 'products' ? 'active' : ''}
                                                        onClick={() => handleChanges({activeTab: 'products', filter: "", filterPlaceholder: 'Etsi tuotetta'})}
                                                    >
                                                    Tuotteet
                                                    </NavLink>
                                                </NavItem>
                                            }
                                        </Nav>
                                    </div>
                                </Col>
                            </Row>
                            <div  style={{ paddingBottom: '0.5em', width: '100%', display:'flex', marginLeft: '3em', marginRight:'2.5em'}}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '40%' }}>
                                        <input type="search" className="search-bar-open form-control" placeholder={filterPlaceholder} value={filter} onChange={(event) => setFilter(event.target.value)} />
                                    </div>
                                    {activeTab === "files" && 
                                            <div style={{  margin: '0.5em', marginLeft: '-0em', display:'flex', flexDirection: 'row' }}>
                                                <div style={{position: 'relative' }} className="filter-nav">
                                                    <FilterContainer handleStatusFilters={setStatusFilters} statusFilters={statusFilters} customFilters={customFilters} />
                                                </div>
                                                {statusFilters.map((filterItem) =>
                                                    <FilterButton filterItem={filterItem} removeItem={setStatusFilters} key={filterItem.value} filters={statusFilters} />
                                                )}
                                            </div>
                                    }
                            
                                    <div style={{ display:'flex', flexDirection: 'row', marginLeft:'auto', marginRight: '4.25em'}}>
                                        {activeTab === "files" && <button onClick={() => {return}} style={{  margin: '0.5em' }} className="small-white-button wide-button ">Lisää uusi tiedosto</button>}
                                        {activeTab === "products" && <button onClick={() => setNewProductModal(true)} style={{ margin: '0.5em' }} className="small-white-button wide-button ">Lisää uusi tuote</button>}
                                    </div> 
                            </div>
                            <Row className="categoryList" style={{ minHeight: 'max-content'}}>
                                <Col xs="12" style={{ padding: '0 2em'}}>
                                    <Scrollbars
                                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                        style={{ width: "100%", height: '100%', borderRadius: '10px'}}
                                        className="custom-scrollbars"
                                    >
                                        {activeTab === "files" && 
                                            <div>
                                                {filesLoading?
                                                    <Row className="product-box-body">
                                                        <MegaTinyLoaderSpinner />
                                                    </Row>
                                                    :
                                                    <FileList 
                                                        fileList={fileList.filter(file => filterFileList(file))}
                                                        search={filter}
                                                        update={updateFiles}
                                                    />
                                                }
                                            </div>
                                        }
                                        {activeTab === "products" &&
                                            <div>
                                                <ProductList
                                                    createModal={newProductModal}
                                                    setCreateModal={setNewProductModal}
                                                    products={productList}
                                                    search={filter}
                                                    updateList={updateProducts}
                                                />
                                            </div>
                                        }   
                                    </Scrollbars>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

const MegaTinyLoaderSpinner = () => {
    return (
      <Row>
        <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '4rem', height: '4rem', marginTop: '0.5rem' }}>
            <div style={{ width: '2rem', height: '2rem', marginTop: '0.5rem' }} className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
import React, {useState, useEffect} from 'react';
import MegaUltraChart from './SummaryCard';
import {confs} from '../../../../modules/config';
import {Row, Col} from 'reactstrap';
import moment from 'moment';
moment.locale();
const emptyStyleBox = {
    backgroundColor: '#c8c8c8', 
    boxShadow: '0 3px 6px #CCC', 
    marginTop: '1.8em', 
    minHeight: 400,
    opacity: 0.7
}

const resultStyleBox = {
    backgroundColor: '#FFF',
    boxShadow: '0 3px 6px #CCC',
    marginTop: '1em',
    minHeight: 402,
    cursor: 'pointer'
}

const SummaryCardContainer = ({surveyCardInfo, payload, index, getReport, openInfo, panelOpen}) => {
    const [surveyCard, setSurveyCard] = useState(surveyCardInfo);
    const [chartData, setChartData] = useState({});
    const [dataReady, setDataReady] = useState(false);
    const [boxStyle, setBoxStyle] = useState(emptyStyleBox);
    const [panelToggled, setPanelToggled] = useState(panelOpen);

    useEffect(() => {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            surveyCard: {
                ...surveyCard
            }
        }
        fetch(confs.url + 'fetch/surveyCardData', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => response.json())
        .then(res => {
            setChartData(res.chartData);
            setTimeout( () => {
                setDataReady( true );
            }, 100 * index )
        })

        return () => {
            setDataReady(false);
        }
    }, [surveyCard])

    useEffect(() => {
        setTimeout(() => {
            setPanelToggled(panelOpen)
        }, 500)
    }, [panelOpen])

    useEffect(() => {
        if(surveyCard.chartGoalValue !== undefined) {
            if(surveyCardInfo.chartGoalValue[0] !== surveyCard.chartGoalValue[0]) {
                setSurveyCard(surveyCardInfo);
                setDataReady(false);
            }
        }

        if(surveyCard.chartGoalValue === undefined && surveyCardInfo.chartGoalValue !== undefined) {
            setSurveyCard(surveyCardInfo);
            setDataReady(false);
        }
    }, [surveyCardInfo])

    useEffect(() => {
        if( Object.keys( chartData ).length > 0 ) {
            setBoxStyle( resultStyleBox )
        }
    }, [chartData])


    return (
        surveyCard._id !== undefined &&
        <Col xl="3" xs="12" md="4" lg="6" className="summary-container">
            <div className="summary-inner-container" style={ boxStyle } onClick={ () => getReport( surveyCard ) }>
            {dataReady ?
                <>
                {((chartData.data.length - 1) > 0 && panelOpen === panelToggled) ? (
                    <Row className="mx-auto">
                        <MegaUltraChart chartData={ chartData } chartId={ surveyCard._id } avg={ chartData.totalAvg } chartName={ surveyCard.summaryName } chartGoal={ surveyCard.chartGoalValue } />
                    </Row>
                )
                : (
                    <Row className="mx-auto">
                        <NoResultsBox panelOpen={panelOpen} panelToggled={panelToggled} />
                    </Row>
                )}
                    <Row className="mx-auto">
                        <Col className="px-0" xl="12" style={{paddingTop: '0.5em', display: 'flex', justifyContent: 'space-between'}}>
                            <h5 style={{paddingLeft: '1rem' }}>{surveyCard.summaryName !== "" ? surveyCard.summaryName : "Nimetön"}</h5>
                                <i className="fas fa-cog edit-summary-card-cog" style={ { alignSelf: 'center', paddingRight: '1em', fontSize: '1.4em', cursor: 'pointer', color: '#a0a0a0'}} onClick={(e) => { 
                                e.stopPropagation()
                                openInfo(surveyCard, chartData.data)
                            }} >
                            </i>
                        </Col>
                    </Row>
                    <Row className="mx-auto">
                        <Col className="px-0" xs={{ size: 5 }} style={{ display: 'flex', paddingTop: '0.5em', paddingBottom: '1em' }}>

                            <div className="medium-score-ball big-text-ball" style={chartData.chartColor ? 
                                { backgroundColor: chartData.chartColor, boxShadow: `0 0 0 2pt ${chartData.chartColor}`, marginLeft: '1rem' }
                                :{backgroundColor: '#C8C8C8', boxShadow: `0 0 0 2pt #C8C8C8`, marginLeft: '1rem'}}>{chartData.totalAvg ? chartData.totalAvg : 0}
                            </div>
                            
                        </Col>
                        <Col className="px-0" xs={{size: 7}}>

                            <h6>Kierroksia: {chartData.data.length - 1}</h6>
                            <h6>Vastauksia: {chartData.totalAnswers}</h6>
                            <h6>Tarkistuspisteet: {chartData.taskCount > 0 ? `${chartData.tasksDone} / ${chartData.taskCount}` : " - "}</h6>
                            <h6>Riskit: {chartData.risks > 0 ? chartData.risks : " - "}</h6>
                                { chartData.totalAnswers > 0 ? (
                                <>
                                    <h6 style={{ marginBottom: 0 }}>Viimeisin vastaus:</h6>
                                    <h6> { moment( chartData.latestAnswer ).format( "DD.MM.YYYY HH:mm" ) }</h6>
                                </>
                                ) : <h6>Odottaa vastauksia</h6>
                                }
                                
                        </Col>
                    </Row>
            </>
            : <MegaLoaderSpinner />
            }
            </div>
        </Col>
    )
}

const NoResultsBox = (props) => {
    return (
        <Col className="px-0">
            <div style={{ height: "200px", backgroundColor: '#C8C8C8', display: 'flex', justifyContent: 'center' }}>
                <h4 style={{alignSelf: 'center', color: '#FFF', textAlign: 'center'}}>{props.panelOpen !== props.panelToggled ? 'Piirretään uudelleen' : 'Odottaa vastauksia'}</h4>
            </div>
        </Col>
    )
}


const MegaLoaderSpinner = () => {
    return (
            <div className="loadingSpinnerRowChart">
                <div style={{ display: 'flex', justifyContent: 'center', height: '100%'}}>
                    <div style={{ width: '5rem', height: '5rem', marginTop: '8rem' }} className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
    )
}




export default SummaryCardContainer;
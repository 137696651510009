import React, { Component, useState, useEffect } from 'react';

import { Row, Col, Container, Button, Input, FormFeedback, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from 'react-select';
import './sender.css';
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux';
import * as ActionCreator from '../../../store/index';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import DebounceTextEditor from '../../../Utilities/TextEditor/DebounceTextEditor';
import ModifyDateModal from '../../../Utilities/Modals/ModifyDateModal';
import MultiSelect from "@kenshooui/react-multi-select";
import { convertFromRaw, ContentState, EditorState, convertToRaw } from 'draft-js';
import MenuContainer from './Filter/MenuContainer';
import FilterButton from './Filter/FilterButton'
import { motion, AnimatePresence } from 'framer-motion'
import { debounce } from 'debounce';
import SurveyListItem from './SurveyListItem';
import 'moment/locale/fi';
import { confs } from '../../../modules/config';
import BackgroundEditor from './subComponents/BackgroundEditor';
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';

moment.locale('fi');

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi kartoitusta...",
        noItemsMessage: "Valitse kartoitus",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
};

const customEmailMessages = {
    messages: {
        searchPlaceholder: "Etsi sähköpostiosoitetta...",
        noItemsMessage: "Valitse sähköpostiosoite",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
};

const emptyBlock = () => {
    let contentState = ''
    contentState = ContentState.createFromText("")

    let editorState = EditorState.createWithContent(contentState);
    return convertToRaw(editorState.getCurrentContent())
}

const MegaLoaderSpinner = () => {
    return (
        <Row className="loadingSpinnerRow">
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}


class SenderContainer extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            startSending: false,
            surveyName: "",
            companySelection: {},
            type: {},
            startDate: null,
            endDate: null,
            selectedOption: {},
            selectedSurveys: [],
            selectedOptions: [],
            selectedEmailSurveys: [],
            focusedInput: null,
            modal: false,
            selectedEmails: [],
            menuIndex: -1,
            surveysReady: false,
            companiesReady: false,
            emailTitle: emptyBlock(),
            emailTitlePlain: "",
            emailExplanation: emptyBlock(),
            emailPlainExplanation: "",
            emailPlainContent: "",
            emailToAdd: "",
            emailList: [],
            emailEditorId: `${0}-EmailEditor`,
            emailReady: false,
            sentEmails: 0,
            status: {},
            emailScrolled: false,
            numberOfSentSurveys: 0,
            confirmationModal: false,
            itemToModify: {},
            modalAction: '',
            selectedTarget: null,
            datesList: [],
            modifyDates: [],
            dateToChangeInd: null,
            filter: '',
            nameModalActive: false,
            editedName: '',
            editedSurvey: null,
            chosenKartoitus: null,
            kartoitusModalActive: false,
            mainFilters: [],
            backgroundSet: false,
            editBackground: false,
            background: null,
            resetBackground: false,
            hideMenu: false
        }

        this.senderRef = React.createRef();
        this.senderContainerRef = React.createRef();
        this.emailSenderRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.getAllSurveys();
        this.props.getAvailableSurveys();
        this.props.getGroupedSurveys();
        this.props.getAllBackgrounds();
        this.props.setBreadcrumbs({ to: 'tiedot', label: `${this.props.businessName}` })
        this.props.addMoreBreadcrumbs([{ to: 'kartoitukset', label: "kartoitukset" }]);

        if (this.props.modulesInUse.clientManagement) {
            this.props.getCompanies();
        }

        setTimeout(() => {
            this.setState({ surveysReady: true });
        }, 400);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.autoSelectedSurvey !== this.props.autoSelectedSurvey && this._isMounted) {
            this.setState({ selectedEmailSurveys: this.props.autoSelectedSurvey });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = (selectedOption) => {
        let selectedOptions = [
            {
                ...selectedOption
            }
        ];

        this.setState({ companySelection: selectedOption, selectedOptions: selectedOptions });
    };

    handleChosenKartoitusForEdit = (selectedOption) => {
        this.setState({ chosenKartoitus: selectedOption });
    }

    handleTypes = selectedOption => {
        this.setState({ type: selectedOption });
    }

    startBuilding = type => {
        let processStatus = {
            ...this.state.processStatus,
            typeSelected: type
        };
        this.setState({ processStatus: processStatus });
    }

    handleSurveySelection = selectedItems => {
        let isempty = !this.state.selectedSurveys.length > 0;
        this.setState({ selectedSurveys: selectedItems });
        if (isempty) {
            this.afterSurveySelection();
        }
    }

    handleEmailSelections = selectedItems => {
        this.setState({ selectedEmails: selectedItems });
        if (!this.state.emailScrolled) {
            this.afterEmailSelections();
            this.setState({ emailScrolled: true });
        }
    }

    handleEmailSurveySelections = selectedItems => {
        var unique = selectedItems.filter(item => {
            return !this.state.selectedEmailSurveys.some(chosenItem => {
                return item.id === chosenItem.id;
            });
        });

        this.setState({ selectedEmailSurveys: unique });
        this.afterEmailSelections();
    }

    afterEmailSelections = debounce(() => {
        this.scrollEmailContainer();
    }, 1000)

    afterSurveySelection = debounce(() => {
        this.scrollToMyRef();
    }, 800);


    handleSurveyRemoval = removeItem => {
        let surveys = [
            ...this.state.selectedSurveys.filter(x => x.value !== removeItem.value)
        ]

        this.setState({ selectedSurveys: surveys });
    }

    handleSurveyName = value => {
        let isempty = !this.state.surveyName.length > 0;
        this.setState({ surveyName: value.text });
        if (isempty) {
            this.afterSurveySelection();
        }
    }

    sendEmailReminders = () => {
        let payload = {
            surveyList: [
                ...this.state.selectedEmailSurveys
            ],
            emails: [
                ...this.state.selectedEmails
            ],
            emailTitle: this.state.emailTitlePlain,
            emailExplanation: this.state.emailExplanation
        };

        this.setState({
            selectedEmailSurveys: [],
            selectedEmails: [],
            emailList: [],
            emailTitlePlain: "",
            emailTitle: emptyBlock(),
            emailExplanation: "",
            emailPlainExplanation: "",
            emailScrolled: false,
            sentEmails: this.state.sentEmails + 1
        });
        this.props.sendSurveyEmail(payload);
    }


    changeModifierDate = (dates) => {
        this.setState({ s_date: dates.startDate, e_date: dates.endDate });

        if (dates.endDate !== null && dates.startDate !== null) {
            let obj = []
            if (this.state.dateToChangeInd !== null && this.state.modifyDates.length > 0) {
                if (this.state.dateToChangeInd === -1) {
                    obj = [...this.state.modifyDates, dates]
                } else {
                    obj = [...this.state.modifyDates];
                    obj[this.state.dateToChangeInd] = dates;
                }
            } else {
                obj = [dates];
            }

            this.setState({ modifyDates: obj });
            if (this.state.dateToChangeInd === -1) {
                this.setState({ dateToChangeInd: obj.length - 1 })
            }
            //this.setState({ dateToChangeInd: null });
        }
    }

    modifierFocus = (focus) => {
        this.setState(focus);
    }

    toggleMenu = (cardIndex) => {
        if (this.state.menuIndex === cardIndex) {
            let obj = {
                menuIndex: -1
            };
            this.setState(obj);
        } else {
            let obj = {
                menuIndex: cardIndex
            };

            this.setState(obj);
        }
    }


    filterSurveyOptions = option => {
        if (this.state.selectedSurveys.includes(option)) {
            return false;
        }
        return true;
    }

    handleDates = (dates) => {
        this.setState(dates);
    }

    handleFocus = focus => {
        this.setState(focus);
    }

    sendSurvey = () => {
        let obj = {
            //startDate: new Date(moment(this.state.startDate)),
            //endDate: new Date(moment(this.state.endDate)),
            dates: [...this.state.datesList],
            surveys: [...this.state.selectedSurveys],
            name: this.state.surveyName,
            targets: [
                ...this.state.selectedOptions
            ]
        }
        if (this.state.background) {
            obj = { ...obj, background: this.state.background }
        }

        if (this.state.hideMenu) {
            obj = { ...obj, hideMenu: this.state.hideMenu };
        }

        this.props.onSurveySend(obj);

        this.setState({
            surveyName: "",
            companySelection: {},
            startDate: null,
            endDate: null,
            selectedSurveys: [],
            startSending: true,
            datesList: [],
            background: null,
            backgroundSet: false,
            hideMenu: false,
            numberOfSentSurveys: this.state.numberOfSentSurveys + 1,
            resetBackground: true
        }, () => this.setState({ resetBackground: false }));
        this.scrollContainerToTop();
        this.props.getGroupedSurveys();
    }

    handleSenderEmailTitle = (payload) => {
        let isempty = !this.state.emailTitlePlain.length > 0;
        this.setState(payload);
        if (isempty) {
            this.scrollEmailContainer();
        }
    }

    handleSenderEmailExplanation = (payload) => {
        let isempty = !this.state.emailPlainExplanation.length > 0;
        this.setState(payload);
        if (isempty) {
            this.scrollEmailContainer();
        }
    }

    handleSenderEmailField = (rawEditorState) => {
        let plaintextEmail = convertFromRaw(rawEditorState).getPlainText()
        plaintextEmail = plaintextEmail.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        this.setState({ emailContent: rawEditorState, emailPlainContent: plaintextEmail });
    }

    proceedToModifyDate = () => {

        let dates = [...this.state.modifyDates];

        for (let i = 0; i < dates.length; i++) {
            dates[i].startDate = moment(new Date(dates[i].startDate));
            dates[i].endDate = moment(new Date(dates[i].endDate));
        }
        let params = {
            ...this.state.chosenSurvey,
            surveyId: this.state.chosenSurvey._id,
            startDate: null,
            endDate: null,
            dates: dates
        };

        this.props.onSurveyDateModified(params);
        this.setState({ modal: false, dateToChangeInd: null, modifyDates: [] });

    }

    setReportData = (survey) => {
        this.setState({ s_date: moment(new Date(survey.startDate)), e_date: moment(new Date(survey.endDate)), modal: true, chosenSurvey: survey, modifyDates: survey.dates });
    }

    toggleNameChange = (survey = null) => {
        if (!survey) {
            this.setState({ editedName: '', nameModalActive: false, editedSurvey: null })
        } else {
            const usdd = JSON.parse(JSON.stringify(survey))
            this.setState({ editedName: usdd.name, nameModalActive: true, editedSurvey: survey })
        }
    }

    toggleKartoitusEdit = (survey = null) => {
        if (!survey) {
            this.setState({ kartoitusModalActive: false, modalData: null, chosenKartoitus: null, surveyData: null })
        } else if (survey && survey.mainSurveys && survey.mainSurveys.length > 1) {
            this.setState({ kartoitusModalActive: true, modalData: survey.mainSurveys, chosenKartoitus: survey.mainSurveys[0], surveyData: survey })
        } else {
            this.startEditing(survey.mainSurveys[0].value, { value: survey._id, label: survey.name });
        }
    }

    startEditing = (surveyId = null, selectedSurvey = null) => {
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            surveyId: surveyId === null ? this.state.chosenKartoitus.value : surveyId
        }



        fetch(confs.url + 'fetch/mainSurveyForEdit', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => response.json())
            .then(resp => {

                if (Object.keys(resp).length > 0) {
                    this.props.onSurveyEdit(
                        {
                            categoryToEdit: resp,
                            selectedSurvey: selectedSurvey === null ? {
                                value: this.state.surveyData._id,
                                label: this.state.surveyData.name
                            } : selectedSurvey,
                            start: true
                        }
                    );
                    this.props.history.push('/kartoitukset/luonti')

                }
            })

    }

    saveEditedName = () => {
        const { editedName, editedSurvey } = this.state

        if (editedName.trim() !== '') {

            const params = {
                surveyId: editedSurvey._id,
                name: editedName
            }

            this.props.onSurveyDateModified(params)
        }
        this.toggleNameChange()
    }

    editName = (name) => {
        this.setState({ editedName: name })
    }

    modalToggler = () => {
        if (this.state.modal === true) {
            this.setState({ e_date: null, s_date: null, modifyDates: [], dateToChangeInd: null });
        }
        this.setState({ modal: !this.state.modal });
    }

    scrollToMyRef = () => {
        this.senderRef.current.parentElement.scroll({ top: this.senderRef.current.offsetTop, left: 0, behavior: 'smooth' })
    }

    scrollEmailContainer = () => {
        this.emailSenderRef.current.parentElement.scroll({ top: this.emailSenderRef.current.offsetTop, left: 0, behavior: 'smooth' })
    }

    scrollContainerToTop = () => {
        this.senderContainerRef.current.parentElement.scroll({ top: this.senderContainerRef.current.offsetTop, left: 0, behavior: 'smooth' })
    }

    addEmailToList = (email) => {
        let emailObject = {
            id: email,
            label: email
        };

        if (this.state.emailList.filter(email => email.label === emailObject.label).length === 0) {
            let emailList = [
                ...this.state.emailList,
                emailObject
            ]

            this.setState({ emailList: emailList, emailToAdd: "" });
        }
    }

    handleEmailChange = email => {
        let check = this.validateEmail(email.target.value)
        if (check.status) {
            this.setState({ emailReady: true, emailToAdd: email.target.value, status: check });
        } else {
            this.setState({ emailReady: false, emailToAdd: email.target.value, status: check });
        }
    }

    handleEmailAdd = event => {
        if (event.key === 'Enter') {
            if (this.state.emailReady) {
                let emailObject = {
                    id: this.state.emailToAdd,
                    label: this.state.emailToAdd
                };

                let emails = [
                    ...this.state.emailList,
                    emailObject
                ];

                let selectedEmails = [
                    ...this.state.selectedEmails,
                    emailObject
                ];

                this.handleEmailSelections(selectedEmails);
                this.setState({ emailList: emails, emailToAdd: "", emailReady: false, status: {} });
            }
        }
    }

    validateEmail = (email) => {
        let emailObject = {
            id: email,
            label: email
        };

        var regex = /[^@]+@[^\.]+\..+/; /*/^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;*/
        /* 	    
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/*/
        if (regex.test(email)) {
            if (this.state.selectedEmails.length < 5 && this.state.emailList.filter(email => email.label === emailObject.label).length === 0) {
                return {
                    status: true,
                    feedback: "Lisää sähköposti painamalla 'Enter'-näppäintä"
                }
            } else if (this.state.selectedEmails.length === 5) {
                return {
                    status: false,
                    feedback: "Voit lisätä ainoastaan viisi sähköpostia toistaiseksi postituslistalle"
                };
            } else {
                return {
                    status: false,
                    feedback: "Sähköposti on jo listalla"
                };
            }
        } else {
            return {
                status: false,
                feedback: "Syötä sähköpostiosoite oikeassa muodossa"
            }
        }
    }

    selectReport = async (obj) => {
        const params = {
            reports: [
                {
                    reportId: obj._id
                }
            ]
        }

        this.props.getReportForDashboard(params)

        if (obj.businessName !== "Ei kohdeyritystä") {
            await this.props.getReportTree({ businessId: obj.businessID })
            this.props.history.push('/raportit/yritys/raportti')
        } else {
            this.props.storeBusiness({ name: 'Ei kohdeyritystä' })
            this.props.history.push('/raportit/kohdistamaton/raportti')
        }

    }

    toggleConfirmationModal = (survey, action) => {
        if (this.state.confirmationModal) {
            this.setState({ confirmationModal: false, itemToModify: {}, modalAction: '' })
        } else {
            this.setState({ confirmationModal: true, itemToModify: survey, modalAction: action })
        }
    }

    modalAction = () => {
        this.props.onSurveyEnd(this.state.itemToModify, this.state.modalAction)
        this.setState({ confirmationModal: false, itemToModify: {}, modalAction: '' })
    }

    toggleChangeSurveyTargetModal = (sentSurvey = null) => {
        this.setState(prevState => ({
            changeSurveyTargetModalIsOpen: !prevState.changeSurveyTargetModalIsOpen,
            surveyHavingTargetChanged: sentSurvey,
            selectedTarget: null
        }))
    }

    onSurveyTargetSelectChange = (val) => {
        this.setState({ selectedTarget: val })
    }

    changeSurveyTarget = (sentSurvey, newTarget) => {
        const { changeSurveyTarget } = this.props

        const params = {
            sentSurvey: sentSurvey,
            targetObj: newTarget
        }

        changeSurveyTarget(params)

        this.toggleChangeSurveyTargetModal()
    }

    setDates = (date) => {
        this.setState(date);

        if (date.endDate !== null && date.startDate !== null) {
            this.setState({ 'datesList': [...this.state.datesList, date] });
            this.setState({ endDate: null, startDate: null });
        }
    }

    isDayHighlighted = (day) => {
        let compare = moment(new Date(day))

        for (let date of this.state.datesList) {
            let start = moment(new Date(date.startDate._d))
            let end = moment(new Date(date.endDate._d))

            if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                return true;
            }
        }
        return false;
    }

    isDayBlocked = (day) => {
        let compare = moment(new Date(day))

        for (let date of this.state.modifyDates) {
            let start = moment(new Date(date.startDate))
            let end = moment(new Date(date.endDate))

            if (compare.isSameOrAfter(start, "day") && compare.isSameOrBefore(end, "day")) {
                return true;
            }
        }
        return false;

    }

    changeModifiedDateIndex = (dateobj, ind) => {
        if (ind !== -1) {
            this.setState({ s_date: moment(new Date(dateobj.startDate)), e_date: moment(new Date(dateobj.endDate)) })
        } else {
            this.setState({ s_date: null, e_date: null })
        }
        this.setState({ dateToChangeInd: ind })
    }

    removeDateFromList = (ind) => {
        if (this.state.modal) {
            let obj = [...this.state.modifyDates]
            obj.splice(ind, 1)
            this.setState({ modifyDates: obj })
        } else {
            let obj = [...this.state.datesList]
            obj.splice(ind, 1)
            this.setState({ datesList: obj })
        }
    }

    setFilter = (filter) => {
        this.setState({ filter: filter })
    }

    //This function sets the filters from filter list
    handleMainFilters = (filter) => {
        if (this.state.mainFilters.includes(filter)) {
            let removeFilter = this.state.mainFilters.filter(x => x !== filter);
            this.props.getAllSurveys({ companyTargets: removeFilter.filter(x => x.businessName !== undefined) });
            this.setState({ mainFilters: removeFilter });
        } else {
            let filterObject = {
                target: filter.target
            }

            if (filter.value === "Tilanne") {
                //These will be used to directly filter results in the current list
                if (filter.target === "Aktiivinen") {
                    filterObject = {
                        ...filterObject,
                        isActive: true
                    }
                } else if (filter.target === "Päättynyt") {
                    filterObject = {
                        ...filterObject,
                        isActive: false
                    }
                } else if (filter.target === "Vastauksia") {
                    filterObject = {
                        ...filterObject,
                        hasAnswers: true
                    }
                } else if (filter.target === "Ei vastauksia") {
                    filterObject = {
                        ...filterObject,
                        hasAnswers: false
                    }
                }
            } else if (filter.value === "Asiakkaat") {
                //Fetch new list with the companies
                filterObject = {
                    ...filterObject,
                    businessName: filter.target
                }
            }

            let filters = [
                ...this.state.mainFilters,
                filterObject
            ]

            this.props.getAllSurveys({ companyTargets: filters.filter(x => x.businessName !== undefined) });

            this.setState({ mainFilters: filters });
        }
    }

    statusFilter = (sentSurvey) => {
        let mainFiltersActive = this.state.mainFilters.filter(x => x.isActive !== undefined);
        let mainFiltersAnswers = this.state.mainFilters.filter(x => x.hasAnswers !== undefined);

        let activeMatch = false;
        let answerMatch = false;

        if (mainFiltersActive.length > 0) {
            mainFiltersActive.map(filter => {
                if (filter.isActive === sentSurvey.isActive) {
                    activeMatch = true;
                }
            })
        } else {
            activeMatch = true;
        }

        if (mainFiltersAnswers.length > 0) {
            mainFiltersAnswers.map(filter => {
                if (filter.hasAnswers && sentSurvey.answerCount > 0) {
                    answerMatch = true;
                } else if (!filter.hasAnswers && sentSurvey.answerCount === 0) {
                    answerMatch = true;
                }
            })
        } else {
            answerMatch = true;
        }

        return activeMatch === answerMatch;
    }

    toggleBackgroundEditor = () => {
        if (this.state.editBackground && this.state.backgroundEdit) {
            this.setState({ background: null, backgroundEdit: null })
        }

        this.setState({ editBackground: !this.state.editBackground })
    }

    saveBackground = (obj) => {
        this.setState({ background: obj, backgroundSet: true, editBackground: false })
        this.afterSurveySelection()
    }

    modifyBackground = (sentSurvey) => {
        this.setState({ backgroundEdit: sentSurvey, editBackground: true, background: sentSurvey.background ? sentSurvey.background : {} })
    }

    saveModifiedBackground = (obj) => {
        let payload = {
            sentSurvey: this.state.backgroundEdit,
            background: obj
        }

        this.setState({
            backgroundEdit: null, editBackground: false, background: null
        })

        this.props.modifyBackground(payload)
    }

    render() {
        const { linkedCompanies, modulesInUse, sentSurveys } = this.props
        const linkedCompaniesList = (linkedCompanies !== undefined && linkedCompanies.length > 0) ? linkedCompanies : [{ value: 'anon', label: 'Ei kohdeyritystä' }]

        return (
            <Container fluid className="dashboard-main">
                <ModifyDateModal
                    changeModifierDate={this.changeModifierDate}
                    modifierFocus={this.modifierFocus}
                    startDate={this.state.s_date}
                    endDate={this.state.e_date}
                    focusedInput={this.state.focusedInputModal}
                    save={this.proceedToModifyDate}
                    isOpen={this.state.modal}
                    toggle={this.modalToggler}
                    dates={this.state.modifyDates}
                    setIndex={this.changeModifiedDateIndex}
                    modifyIndex={this.state.dateToChangeInd}
                    removeDate={this.removeDateFromList}
                    isDayBlocked={this.isDayBlocked}
                />
                <ConfirmationModal
                    isOpen={this.state.confirmationModal}
                    toggle={this.toggleConfirmationModal}
                    confirm={this.modalAction}
                    data={this.state.itemToModify}
                    actionType={this.state.modalAction} 
                />
                <ChangeSurveyTargetModal
                    isOpen={this.state.changeSurveyTargetModalIsOpen}
                    toggle={this.toggleChangeSurveyTargetModal}
                    confirm={this.changeSurveyTarget}
                    businesses={this.props.linkedCompanies}
                    sentSurvey={this.state.surveyHavingTargetChanged}
                    onTargetChange={this.onSurveyTargetSelectChange}
                    selected={this.state.selectedTarget}
                />
                <ChangeNameModal
                    isOpen={this.state.nameModalActive}
                    toggle={this.toggleNameChange}
                    name={this.state.editedName}
                    onNameChange={this.editName}
                    confirm={this.saveEditedName}
                />
                <EditKartoitusModal
                    isOpen={this.state.kartoitusModalActive}
                    toggle={this.toggleKartoitusEdit}
                    data={this.state.modalData}
                    chosenItem={this.state.chosenKartoitus}
                    handleChosenKartoitusForEdit={this.handleChosenKartoitusForEdit}
                    confirm={this.startEditing}
                />

                {!this.state.resetBackground && <BackgroundEditor
                    isOpen={this.state.editBackground}
                    toggle={this.toggleBackgroundEditor}
                    background={this.state.background}
                    list={this.props.backgrounds}
                    save={this.state.backgroundEdit ? this.saveModifiedBackground : this.saveBackground}
                    uploadBackground={this.props.uploadBackground}
                />}
               
                <AnimatePresence> 
                    <div className="survey-color" style={{ borderRadius: '10px'}}>
                        <div style={{ padding: '0 1em', borderRadius: '25px', backgroundColor:'#fff' }}>
                            <Row key={"surveyActivator"}>
                                <Col xs={{ size: 12 }} lg={{ size: 7 }}>
                                    <motion.div
                                        initial={{ opacity: 0, x: 100 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ ease: 'easeIn', duration: 0.3 }}
                                        exit={{ opacity: 0, x: -50 }}
                                    >
                                        <div className=" survey-sender">
                                            <div className="box-header bigger-text " style={{ borderBottom: 'none' }}>
                                                <h4>Aktivoi kartoitus</h4>
                                            </div>
                                            <Col xl="12" className={linkedCompaniesList.length > 0 ? "senderContainer magic" : "senderContainer"}>
                                                <div ref={this.senderContainerRef}></div>
                                                <Row>
                                                    <Col xl="12">
                                                        <p className="senderSteps">1. Valitse kohdeyritys</p>
                                                        <Row className="companySelector magic">
                                                            <CompanySelector options={linkedCompaniesList} handleCompanySelection={this.handleChange} selectedOption={this.state.companySelection} />
                                                        </Row>
                                                    </Col>
                                                    <Col xl="12" className={Object.keys(this.state.companySelection).length !== 0 ? "selector-visible" : "selector"} >
                                                        <div className={Object.keys(this.state.companySelection).length !== 0 ? "companySelector magic" : "companySelector"}>
                                                            <p className="senderSteps">2. Valitse aktiivinen aikaväli</p>
                                                            <Row>
                                                                <Col>
                                                                    {this.state.datesList.length > 0 && this.state.datesList.map((dateobj, ind) => {
                                                                        let start = moment(new Date(dateobj.startDate._d)).format('DD.MM.YYYY')
                                                                        let end = moment(new Date(dateobj.endDate._d)).format('DD.MM.YYYY')
                                                                        let oddEvenClass = ind % 2 === 0 ? 'even-row' : 'odd-row';

                                                                        return <Row key={ind} className={"modify-dates-list " + oddEvenClass} >
                                                                            <Col md="11">{start} - {end}</Col>
                                                                            {this.state.datesList.length > 1 &&
                                                                                <Col md="1" onClick={() => this.removeDateFromList(ind)}><i className="far fa-trash-alt"></i></Col>
                                                                            }
                                                                        </Row>
                                                                    })

                                                                    }
                                                                </Col>
                                                                <Col xs="12" style={{ marginTop: "0.5em" }}>
                                                                    <DateRangePicker
                                                                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                                                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                                                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                                                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                                                        onDatesChange={({ startDate, endDate }) => this.setDates({ startDate, endDate })} // PropTypes.func.isRequired,
                                                                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                                                        appendToBody={true}
                                                                        block
                                                                        hideKeyboardShortcutsPanel={true}
                                                                        isDayBlocked={this.isDayHighlighted}
                                                                        readOnly
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                    <Col xl="12" className={this.state.datesList.length > 0 ? "selector-visible" : "selector"}>
                                                        <div className={this.state.datesList.length > 0 !== null ? "companySelector magic" : "companySelector"}>
                                                            <p className="senderSteps">3. Anna kartoitukselle nimi</p>
                                                            <Row>
                                                                <Col>
                                                                    <DebounceTextEditor
                                                                        editorId={`${this.state.numberOfSentSurveys}-sendKartoitus`}
                                                                        content={this.state.surveyName}
                                                                        handleSave={this.handleSurveyName}
                                                                        type="surveyName"
                                                                        plaintextEditor={true}
                                                                        additionalClass="sender-text-area"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    <Col xl="12" className={this.state.surveyName !== "" ? "selector-visible" : "selector"}>
                                                        <div className={this.state.surveyName !== "" ? "companySelector magic" : "companySelector"}>
                                                            <p className="senderSteps">4. Aktivoitavat kartoitukset</p>
                                                            <Row>
                                                                {/*
                                                        <SurveySelector {...this.state} surveyOptions={this.props.availableSurveys} 
                                                        filterOptions={this.filterSurveyOptions} handleSurveySelection={this.handleSurveySelection}/>*/}
                                                                <Col xl="12">
                                                                    {this.state.surveyName !== "" &&
                                                                        <MultiSelect
                                                                            messages={customMessages.messages}
                                                                            items={this.props.availableSurveys}
                                                                            selectedItems={this.state.selectedSurveys}
                                                                            onChange={this.handleSurveySelection}
                                                                            wrapperClassName="multi-selector"
                                                                        />}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    <Col xl="12" className={this.state.selectedSurveys.length > 0 ? "selector-visible" : "selector"}>
                                                        <div className={this.state.selectedSurveys.length > 0 ? "companySelector magic" : "companySelector"}>
                                                            <p className="senderSteps">5. Valitse taustakuva</p>
                                                            <Row>
                                                                <Col xl="12" style={{ marginBottom: '1em' }}>
                                                                    <button className={`small-white-button wide-button lighter-text ${!this.state.background && this.state.backgroundSet && 'active-button'}`} onClick={() => this.setState({ backgroundSet: true, background: null }, this.afterSurveySelection())} style={{ marginRight: '1em' }}>Oletus taustakuva</button>
                                                                    <button className={`small-white-button wide-button lighter-text ${this.state.background && this.state.backgroundSet && 'active-button'}`} onClick={() => this.setState({ editBackground: true })} style={{ marginRight: '1em' }}>Mukautettu</button>
                                                                    <button className={`small-white-button wide-button lighter-text ${this.state.hideMenu && 'active-button'}`} onClick={() => this.setState({ hideMenu: !this.state.hideMenu })}>Piilota menu</button>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                    </Col>

                                                    <Col xl="12" className={this.state.backgroundSet ? "selector-visible" : "selector"}>
                                                        <div className={this.state.backgroundSet > 0 ? "companySelector magic" : "companySelector"}>
                                                            <p className="senderSteps">6. Aktivoi kartoitus</p>
                                                            <Row>
                                                                <Col xl="12" className="surveyButton">
                                                                    {(!modulesInUse.limitedSurveyManagement || sentSurveys.length < modulesInUse.limitedSurveyCount) ? (
                                                                        <button className="small-white-button" style={{ marginBottom: '1rem', width: '100%' }} onClick={() => this.sendSurvey()}>Aktivoi kartoitus</button>
                                                                    ) : (
                                                                            <button disabled className="small-white-button" style={{ marginBottom: '1rem', width: '100%' }} >Aktivoitujen kartoitusten maksimimäärä saavutettu</button>
                                                                        )}

                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div ref={this.senderRef}></div>
                                            </Col>
                                        </div>
                                    </motion.div>
                                </Col>
                                <EmailActivator
                                    {...this.state}
                                    companiesReady={this.props.linkedCompanies.length > 0}
                                    handleEmailChange={this.handleEmailChange}
                                    ref={this.emailSenderRef}
                                    validateEmail={this.validateEmail}
                                    handleEmailAdd={this.handleEmailAdd}
                                    handleEmailSelections={this.handleEmailSelections}
                                    handleSenderEmailExplanation={this.handleSenderEmailExplanation}
                                    handleSenderEmailTitle={this.handleSenderEmailTitle}
                                    addEmailToList={this.addEmailToList}
                                    messages={customEmailMessages.messages}
                                    groupedSurveys={this.props.groupedSurveys}
                                    handleEmailSurveySelections={this.handleEmailSurveySelections}
                                    send={this.sendEmailReminders}
                                />
                            </Row>
                            {/* <Row>
                                <SentSurveys
                                    surveysReady={this.state.surveysReady}
                                    sentSurveys={this.props.sentSurveys.filter(survey => this.statusFilter(survey))}
                                    changeDate={this.setReportData}
                                    menuIndex={this.state.menuIndex}
                                    endSurvey={this.toggleConfirmationModal}
                                    toggleMenu={this.toggleMenu}
                                    selectReport={this.selectReport}
                                    toggleChangeSurveyTarget={this.toggleChangeSurveyTargetModal}
                                    filter={this.state.filter}
                                    setFilter={this.setFilter}
                                    toggleKartoitusEdit={this.toggleKartoitusEdit}
                                    toggleNameChange={this.toggleNameChange}
                                    surveyFilters={this.props.filters}
                                    handleMainFilters={this.handleMainFilters}
                                    mainFilters={this.state.mainFilters}
                                    modifyBackground={this.modifyBackground}
                                />
                            </Row> */}
                        </div>
                    </div>
                </AnimatePresence>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        sentSurveys: state.senderV2.sentSurveys,
        linkedCompanies: state.senderV2.linkedCompanies,
        availableSurveys: state.senderV2.availableSurveys,
        groupedSurveys: state.senderV2.groupedSurveys,
        businessPath: state.authentication.businessURL,
        businessName: state.authentication.businessName,
        autoSelectedSurvey: state.senderV2.autoSelectedSurvey,
        filters: state.senderV2.filters,
        backgrounds: state.senderV2.backgroundList,
        modulesInUse: state.authentication.modulesInUse
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllSurveys: (payload) => dispatch(ActionCreator.getAllSurveys(payload)),
        onSurveyEdit: (payload) => dispatch(ActionCreator.startSurveyEditFromSentSurveys(payload)),
        getCompanies: (payload) => dispatch(ActionCreator.fetchLinkedCompanies(payload)),
        getAvailableSurveys: (payload) => dispatch(ActionCreator.getAvailableSurveys(payload)),
        onSurveySend: (payload) => dispatch(ActionCreator.onSurveySend(payload)),
        setBreadcrumbs: (params) => dispatch(ActionCreator.setBreadcrumbs(params)),
        onSurveyEnd: (params, type) => dispatch(ActionCreator.endSurvey(params, type)),
        getGroupedSurveys: (params) => dispatch(ActionCreator.getGroupedSurveys(params)),
        sendSurveyEmail: (params) => dispatch(ActionCreator.sendSurveyEmail(params)),
        getReportForDashboard: (payload) => dispatch(ActionCreator.getReportForDashboard(payload)),
        getReportTree: (payload) => dispatch(ActionCreator.getBusinessSurveyTree(payload)),
        onSurveyDateModified: (payload) => dispatch(ActionCreator.modifyActiveDate(payload)),
        addMoreBreadcrumbs: (params) => dispatch(ActionCreator.addMoreBreadcrumbs(params)),
        clearReportReducer: () => dispatch(ActionCreator.clearSelected()),
        storeBusiness: (params) => dispatch(ActionCreator.storeBusiness(params)),
        changeSurveyTarget: (params) => dispatch(ActionCreator.changeSurveyTarget(params)),
        getAllBackgrounds: () => dispatch(ActionCreator.getAllBackgrounds()),
        modifyBackground: (payload) => dispatch(ActionCreator.modifyBackground(payload)),
        uploadBackground: (payload) => dispatch(ActionCreator.uploadBackground(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SenderContainer)

const CompanySelector = props => {
    const { selectedOption } = props;
    const customStyles = {
        control: (base, state) => ({
            ...base,
            height: '58px',
            minHeight: '58px',
        }),
    }
    return (
        <Col>
            <Select value={selectedOption}
                onChange={props.handleCompanySelection}
                options={props.options}
                placeholder="Etsi yritys"
                isDisabled={false}
                noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                styles={customStyles}
                classNamePrefix="select"
            />
        </Col>
    )
}

const EmailActivator = React.forwardRef((props, ref) => {
    return (
        <Col xs={{ size: 12 }} lg={{ size: 5 }}>
            <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ ease: 'easeIn', duration: 0.3 }}
                exit={{ opacity: 0, x: -50 }}
                key={"emailActivator"}
            >
                <div >
                    <div className="box-header bigger-text " style={{ borderBottom: 'none' }}>
                        <p>Lähetä tieto kartoituksesta sähköpostilla</p>
                    </div>
                    <Col xl="12" className={props.companiesReady ? "senderContainer magic" : "senderContainer" }>
                        <Row>
                            <Col xl="12">
                                <p className="senderSteps">1. Anna viestin otsikko</p>
                                <Row className="companySelector magic">
                                    <Col>
                                        <DebounceTextEditor editorId={`${props.sentEmails}-emailTitle`}
                                            content={props.emailTitle}
                                            handleSave={props.handleSenderEmailTitle}
                                            type="emailTitle"
                                            plaintextEditor={true}
                                            additionalClass="sender-text-area" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="12" className={props.emailTitlePlain !== "" ? "selector-visible" : "selector"}>
                                <div className={props.emailTitlePlain !== "" ? "companySelector magic" : "companySelector"}>
                                    <p className="senderSteps">2. Anna viestille selite</p>
                                    <Row>
                                        <Col>
                                            <DebounceTextEditor
                                                editorId={`${props.sentEmails}-emailExplanation`}
                                                content={props.emailExplanation}
                                                handleSave={props.handleSenderEmailExplanation}
                                                type="emailExplanation"
                                                additionalClass="reminderExplanation"
                                                plaintextEditor={true}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xl="12" className={props.emailPlainExplanation !== "" ? "selector-visible" : "selector"}>
                                <div className={props.emailPlainExplanation !== "" ? "companySelector magic" : "companySelector"}>
                                    <p className="senderSteps">3. Valitse sähköpostit</p>
                                    <Row>
                                        <Col xl="12">
                                            <p className="senderSteps">Lisää sähköposti</p>
                                            <div style={{ paddingTop: "0.8rem" }}>
                                                <span>
                                                    <Input valid={props.emailReady} autoFocus invalid={!props.emailReady} value={props.emailToAdd} onChange={props.handleEmailChange} type="text" placeholder="Sähköpostiosoite" onKeyPress={props.handleEmailAdd} />
                                                    <FormFeedback valid={props.emailReady}>{props.status.feedback}</FormFeedback>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xl="12">
                                            <p className="senderSteps">Postituslista</p>
                                            <MultiSelect
                                                items={props.emailList}
                                                selectedItems={props.selectedEmails}
                                                onChange={props.handleEmailSelections}
                                                showSearch={props.emailList.length > 0 ? true : false}
                                                showSelectedItems={false}
                                                showSelectAll={false}
                                                messages={props.messages}
                                                wrapperClassName="multi-selector"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            {props.selectedEmails.length > 0 &&
                                <Col xl="12" className={props.selectedEmails.length > 0 ? "selector-visible" : "selector"}>
                                    <div className={props.selectedEmails.length > 0 ? "companySelector magic" : "companySelector"}>
                                        <p className="senderSteps">4. Valitse aktivoitu kartoitus</p>
                                        <Row>
                                            {/*
                                    <SurveySelector {...this.state} surveyOptions={this.props.availableSurveys} 
                                    filterOptions={this.filterSurveyOptions} handleSurveySelection={this.handleSurveySelection}/>*/}
                                            <Col xl="12">
                                                <MultiSelect
                                                    items={props.groupedSurveys}
                                                    withGrouping
                                                    selectedItems={props.selectedEmailSurveys}
                                                    onChange={props.handleEmailSurveySelections}
                                                    showSelectedItems={false}
                                                    showSelectAll={false}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            }
                            <Col xl="12" className={(props.selectedEmails.length > 0 && props.selectedEmailSurveys.length > 0) ? "selector-visible" : "selector"}>
                                <div className={props.selectedEmailSurveys.length > 0 ? "companySelector magic" : "companySelector"}>
                                    <p className="senderSteps">5. Lähetä viesti</p>
                                    <Row>
                                        <Col xl="12" className="surveyButton">
                                            <Button onClick={() => props.send()}>Lähetä</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <div ref={ref}></div>
                    </Col>
                </div>
            </motion.div>
        </Col>
    )
})

export const SentSurveys = (props) => {
    const { sentSurveys, surveysReady, filter, setFilter, surveyFilters, handleMainFilters, mainFilters } = props

    const [projects, setProjects] = useState([])
    useEffect(() => {
        getUserProjects()
    },[])
    const getUserProjects = async() => {
        const response = await executeRequest('project/getProjectsForSelect',{})
        if(!!response){
            setProjects(response)
        }
    }

    return (

        <Col md={{ size: 12 }} xl={{ size: 12 }}>
            <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ ease: 'easeIn', duration: 0.3 }}
                exit={{ opacity: 0, x: -50 }}
                key={"sentSurveys"}
            >
                <div className="" style={{ marginBottom: '1rem' }}>
                    <div className="box-header bigger-text " style={{ display: 'flex', borderBottom: 'none' }}>
                        <p>Aktivoidut kartoitukset</p>
                        <div style={{ marginLeft: '0.5em' }}>
                            <form id="search-bar">
                                <input type="search" className="search-bar-input" placeholder="Etsi kartoitusta" value={filter} onChange={(event) => setFilter(event.target.value)} />
                            </form>
                        </div>
                        <div style={{ display: 'flex' }}>

                            {mainFilters.map((filterItem) =>
                                <FilterButton filterItem={filterItem} handleMainFilters={handleMainFilters} key={filterItem.target} />
                            )}

                        </div>
                        <div style={{ width: 600, position: 'relative' }} className="filter-nav">
                            <MenuContainer surveyFilters={surveyFilters} handleMainFilters={handleMainFilters} mainFilters={mainFilters} />
                        </div>
                    </div>
                    {surveysReady ?
                        <Scrollbars
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            style={{ width: "100%", height: '500px' }}
                            className="custom-scrollbars"
                        >

                            {sentSurveys.length > 0 && sentSurveys.map((sentSurvey, index) => {
                                let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'
                                if (sentSurvey.code) {
                                    if (sentSurvey.name.toLowerCase().includes(filter.toLowerCase()) || (sentSurvey.code && sentSurvey.code.toLowerCase().includes(filter.toLowerCase())) || sentSurvey.businessName.toLowerCase().includes(filter.toLowerCase())) {
                                        return <SurveyListItem
                                            sentSurvey={sentSurvey}
                                            selectReport={props.selectReport}
                                            toggleMenu={props.toggleMenu}
                                            toggleChangeSurveyTarget={props.toggleChangeSurveyTarget}
                                            toggleKartoitusEdit={props.toggleKartoitusEdit}
                                            toggleNameChange={props.toggleNameChange}
                                            changeDate={props.changeDate}
                                            endSurvey={props.endSurvey}
                                            index={index}
                                            key={sentSurvey._id}
                                            menuIndex={props.menuIndex}
                                            modifyBackground={props.modifyBackground}
                                            projects={projects}
                                            oddEven={oddEvenClass}
                                        />
                                    }
                                }

                            })}

                        </Scrollbars>
                        : <div style={{ height: 500, width: '100%' }} className="custom-scrollbars"><MegaLoaderSpinner /></div>}
                </div>
            </motion.div>
        </Col>
    )
}

export const ConfirmationModal = (props) => {
    const [deleteText, setDeleteText] = useState("")
    
    const toggleModal = () => {
        props.toggle()
        setDeleteText("")
    }

    const confirmDelete = () => {
        if(props.actionType === 'delete' && deleteText === "poista"){
            props.confirm(props.actionType)
        }
        else if(props.actionType === "cancel"){
            props.confirm(props.actionType)
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={()=> toggleModal()}>
            <ModalHeader>{props.data.name} </ModalHeader>
            <ModalBody>
                {props.actionType === 'delete' ?
                    <>
                        <p>Oletko varma, että haluat poistaa aktivoidun kartoituksen? <b>Toimintoa ei voi peruuttaa!</b></p>
                        <div style={{ marginTop: '1em' }}>
                            <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                            <Input id="deleteInput" type="text" value={deleteText} onChange={(e) => setDeleteText(e.target.value)}/>
                            <div className="email-check-box">
                                {deleteText === "poista"?
                                    <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                    :
                                    <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                }
                            </div>
                        </div>
                    </>
                    :
                    <p>Oletko varma, että haluat lopettaa aktivoidun kartoituksen?</p>
                }
            </ModalBody>
            <ModalFooter>
                <a onClick={() => toggleModal()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                <button onClick={() => confirmDelete()} className="small-white-button wide-button swb-hover" style={{ backgroundColor: '#ed5652', color: '#fff' }}>{props.actionType === 'delete' ? 'Poista' : 'Lopeta'}</button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export const ChangeSurveyTargetModal = (props) => {
    const { businesses, sentSurvey, isOpen, toggle, confirm, onTargetChange, selected } = props
    const customStyles = {
        control: (base, state) => ({
            ...base,
            marginBottom: '.5rem'
        }),
    }
    const businessList = (businesses && sentSurvey) ? businesses.filter(business => business.label !== sentSurvey.businessName) : {}

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Vaihda kartoituksen kohdeyritys</ModalHeader>
            <ModalBody>
                <div className="big-text" style={{ marginBottom: '1rem' }} >Tämänhetkinen kohdeyritys: <b>{sentSurvey && sentSurvey.businessName}</b> </div>

                <div className="big-text" style={{ marginBottom: '.5rem' }} >Valitse uusi kohdeyritys:</div>

                <Select
                    value={selected}
                    onChange={onTargetChange}
                    options={businessList}
                    placeholder="Valitse yritys"
                    isDisabled={false}
                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                    styles={customStyles}
                    classNamePrefix="select"
                />
            </ModalBody>
            <ModalFooter>
                <a onClick={toggle} cclassName="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                <button onClick={() => confirm(sentSurvey, selected)} className="small-white-button wide-button swb-hover">Vaihda</button>
            </ModalFooter>
        </Modal>
    )
}

export const ChangeNameModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={() => props.toggle()}>
            <ModalHeader>Muuta nimeä</ModalHeader>
            <ModalBody>
                <Input type="text" value={props.name || ''} onChange={(e) => props.onNameChange(e.target.value)} />
            </ModalBody>
            <ModalFooter>
                <a onClick={() => props.toggle()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a> {' '}
                <button onClick={props.confirm} className="small-white-button wide-button swb-hover" >Tallenna</button>
            </ModalFooter>
        </Modal>
    )
}

export const EditKartoitusModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={() => props.toggle()}>
            <ModalHeader>Valitse muokattava kartoitus</ModalHeader>
            <ModalBody>
                <Select value={props.chosenItem}
                    onChange={props.handleChosenKartoitusForEdit}
                    options={props.data}
                    placeholder="Valitse muokattava kartoitus"
                    isDisabled={false}
                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                    classNamePrefix="select"
                />
            </ModalBody>
            <ModalFooter>
                <a onClick={() => props.toggle()} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a> {' '}
                <button onClick={() => props.confirm()} className="small-white-button wide-button swb-hover">Jatka</button>
            </ModalFooter>
        </Modal>
    )
}
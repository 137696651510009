import { executeRequest } from '../requestHandler/dataHandler';

const fetch = (url, params, dispatch) => executeRequest(url, params, dispatch)

export const addTask = (() => {
    let pending = Promise.resolve();
    
    const run = async (url, options, dispatch) => {
      try {
        await pending;
      } finally {
        return fetch(url, options, dispatch);
      }
    }
  
    // update pending promise so that next task could await for it
    return (url, params, dispatch) => (pending = run(url, params, dispatch))
})();


import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const InfoModal = (props) => {
    return (
        <Modal isOpen={props.info} toggle={props.toggleInfo} size="lg" centered>
            <ModalHeader toggle={props.toggleInfo} className="taskModal">
                <div>
                    Ohjeet toiminnoista
                    <i className="fas fa-info-circle infoIcon" style={{pointerEvents: 'none'}}></i> 
                </div> 
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <h3>Muokkaa aktivoitua kartoitusta</h3>
                        <p>Listauksessa esitetään kaikki aktivoidut kartoitukset, jotka sisältävät valitun kartoituksen. 
                            Valitsemalla listauksesta aktivoidun kartoituksen, voit muokata kartoitusta. 
                        </p>
                        <p><b>Huomaa, että kartoituksen ei tarvitse olla tällä hetkellä aktiivinen.</b></p>
                        <p style={{paddingTop: 10}}>
                            Sallitut operaatiot ovat seuraavat:  
                        </p>
                        <ul>
                            <li>Tekstien muutokset.</li>
                            <li>Värien muutokset.</li>
                            <li>Seuraa / Älä seuraa kysymyksiä.</li>
                            <li>Dialogin kuvan muutos.</li>
                            <li>Linkittää kartoituksia vastausvaihtoehtoihin.</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Päivitä aktiiviset kartoitukset</h3>
                        <p>
                            Painiketta painamalla teille esitetään listaus tällä hetkellä aktiivisina olevista kartoituksista, jotka sisältävät kartoituksen jota olet muokkaamassa.
                        </p> 
                        <p style={{paddingTop: 10}}>Toiminto etenee seuraavasti: </p>
                        <ol className="info-order-list">
                            <li>Valitse listasta "Alkuperäinen"-kartoitus.</li>
                            <li>Suorita muutokset.</li>
                            <li>Paina "Päivitä aktiiviset kartoitukset"-painiketta.</li>
                            <li>Valitse esitetystä ikkunasta haluamasi aktiiviset kartoitukset, jotka haluat päivittää. </li>
                            <li>Painikkeella "Päivitä", kaikki valitsemat aktiiviset kartoitukset päivittyvät "Alkuperäisen"-kartoituksen tiedoilla.</li>
                        </ol>
                        <p style={{paddingTop: 10}}><b>Huom! Jos aktiivinen kartoitus sisältää muita kartoituksia, toiminto ei vaikuta kyseisten kartoitusten tietoihin.</b></p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => props.toggleInfo()}>Sulje</a>
            </ModalFooter>
        </Modal>
    )
}

export default InfoModal;
import MultiSelect from "@kenshooui/react-multi-select";
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { Component, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useInView } from 'react-intersection-observer';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { executeRequest } from '../../Utilities/requestHandler/dataHandler';
import { confs } from '../../modules/config';
import { addBreadcrumbs, addMoreBreadcrumbs, setBreadcrumbs } from '../../store';
import { updateHelpLinks } from '../../store/actions/Login/ActionCreator';
import * as UtilActions from '../../store/actions/UtilActions/ActionCreator';
import * as ActionCreatorIndex from '../../store/index';
import * as ActionCreator from './Actions/ActionCreator';
import AutomaticPlanSettings from './Modals/AutomaticPlanSettings';
import { CreatePlanFromTemplate } from './Modals/CreatePlanFromTemplate';
import { ModifySentSurveyModal } from './Modals/ModifySentSurveyModal';
import MoveToBusinessModal from './Modals/MoveToBusinessModal';
import { PlanBeingEditedModal } from './Modals/PlanBeingEditedModal';
import NewPlanTarget from './NewPlanTarget';
import { CustomBlockList } from './customBlocks/CustomBlockElements';
import { CombinePlans } from './listTabs/CombinePlans';
import CopiedBlockList from './listTabs/CopiedBlockList';
import CopiedIFrameElements from './listTabs/CopiedIFrameElements';

class PlanList extends Component {
    constructor(props) {
        super(props);
        this.startEditing = this.startEditing.bind(this);

        this.state = {
            selected: false,
            modal: false,
            newName: "",
            selectedData: {},
            filter: '',
            filterPlaceholder: 'Etsi lomaketta',
            activeTab: 'plans',
            answersId: '',
            planSettings: {},
            editSettingsModal: false,
            editSentSurvey: false,
            modifySentSurveyId: '',
            toolMenuOpen: false,
            projectModalOpen:false,
            movePlanToBusinessModalOpen:false,
            selectedPlanId: null,
            clientList: [],
            projectList: [],
            helpLinkSetup: false,
            newHelpLink: (this.props.helpLinks && this.props.helpLinks.plan) ? this.props.helpLinks.plan : "",
            deleteText: "",
            beingEditedOpen: false,
            selectedPlanData: {},
            automationSummary: "kooste",
            summaryModalOpen: false
        };
    }

    componentDidMount() {
        this.props.getTemplates()
        this.props.onMountFetchPlans();
        this.props.getEnterpriseDownloads();
        this.props.onMountFetchAutomations();
        this.props.setBreadcrumbs({ to: 'tiedot', label: `${this.props.businessName}` })
        
        this.props.addMoreBreadcrumbs([{ to: 'lomakkeet', label: 'Lomakkeet' }])
        if(this.props.location.state !== undefined && this.props.location.state !== null && this.props.location.state.activeTab !== undefined) {
            this.setState({activeTab: this.props.location.state.activeTab});
        }
        this.getClients()
        this.getProjects()  
        //uncomment below if all plans need to be fetched in the mount 
        // this.props.getAllPlans()     
    }

    getClients = async() => {
        const response = await executeRequest('sender/get/getCompanies',{})
        if(response && response.businessList){
            this.setState({clientList: response.businessList})
        }
    }
    getProjects = async() =>{
        const response = await executeRequest('project/getProjectsForSelect',{})
        if(response){
            this.setState({projectList: response})
        }
    }

    startEditing(planId, edit = null) {
        if(edit){
            this.props.onModify(planId,edit)
        } else {
            this.props.onEdit(planId);
        }
        this.props.history.push('/lomakkeet/luonti')
    }

    toggleDropdown = (y) => {
        if (this.state.selected === y) {
            this.setState({ selected: false });
        } else {
            this.setState({ selected: y })
        }
    }

    handleDelete = () => {
        if(this.state.deleteText === "poista"){
            let params = {
                planId: this.state.selectedData._id
            }
            this.props.onDelete(params);
            this.setState({ modal: false, selectedData: {} });
            this.setState({deleteText: ""})
        }
    }

    handleCopy = payload => {
        this.props.onCopy(payload);
        this.props.getTemplates();
        this.props.refreshPlans();
        this.setState({ modal: false, selectedData: {} });
    }

    cleanUp = () => {
        this.setState({ modal: false, selectedData: {} });
    }

    toggleModal = (data, x) => {
        this.setState({ selectedData: data, delete: x })
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
        this.setState({deleteText:""})
    }

    handleInputChange = (event) => {
        this.setState({ newName: event.target.value });
    }

    shortTitle = (title) => {
        let newtitle = title;
        //newtitle = newtitle.slice(0, 70) + "...";

        return newtitle
    }

    openAnswers = (id) => {
        if (id === this.state.answersId) {
            this.setState({ answersId: '' })

        } else {
            this.setState({ answersId: id })
        }
    }

    generatePlan = (plan, ansId) => {
        let payload = {
            answerSessionId: ansId,
            planId: plan
        }

        this.props.generatePlan(payload)
        this.props.history.push('/lomakkeet/luonti')
    }

    editPlanSettings = (data) => {
        if(this.state.editSettingsModal){
            this.setState({planSettings: {}, editSettingsModal: false})
        }else{
            this.setState({planSettings: data, editSettingsModal: true})
        }
    }

    savePlanSettings = (settings) => {
        let params = {
            planId: this.state.planSettings._id,
            settings: settings,
            fromList: true
        }
        this.setState({planSettings: {}, editSettingsModal: false})
        this.props.savePlanSettings(params)
    }

    toggleSentSurveyChange = (data) => {
        if(this.state.editSentSurvey){
            this.setState({editSentSurvey: !this.state.editSentSurvey, planSettings: {}})
        }else{
            this.setState({editSentSurvey: !this.state.editSentSurvey, planSettings: data})
        }
    }

    saveSentSurvey = (payload) => {
        this.props.modifyPlanSentSurvey(payload)
        this.setState({editSentSurvey: !this.state.editSentSurvey, planSettings: {}})
    }

    toggleTools = () => {
        this.setState({toolMenuOpen:!this.state.toolMenuOpen})
    }

    toggleProjectModal = (data) => {
        if(data){
            this.setState({ selectedData: data})
        }
        this.setState({projectModalOpen: !this.state.projectModalOpen})
    } 

    lastActiveDay = (lastDate) => {
        if(lastDate){
            let today = moment.utc(new Date(),"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            let lastActive = moment.utc(lastDate,"MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')
            if(today === lastActive || today > lastActive){
                return(true)

            } else return(false)
        }
    }
    handleRefresh = () => {
        this.props.refreshPlans()
    }

    toggleMoveToBusinessModal = (planId) => {
        
        if(this.state.movePlanToBusinessModalOpen){
            this.setState({movePlanToBusinessModalOpen: false})
            this.setState({ selectedPlanId: null})
        } else {
            this.setState({movePlanToBusinessModalOpen: true})
            this.setState({ selectedPlanId: planId})
        }
        
        
    }

    saveLink = async() => {
        let payload = {
            type: "plan",
            link: this.state.newHelpLink
        }
        const response = await executeRequest('save/helpLinks', payload)
        if(response.helpLinks){
            this.props.updateHelpLinks(response.helpLinks)
            NotificationManager.success("Linkki tallennettu", "", 3000)
        }
        this.setState({helpLinkSetup: false})
    }

    goToSurvey = (data) => {
    
        let payload = {
            jwtToken: window.sessionStorage.getItem("token"),
            surveyId: data.automationSurvey,
            isSentSurvey: true
        }

        fetch(confs.url + 'fetch/mainSurveyForEdit', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => response.json())
        .then(resp => {

            if (Object.keys(resp).length > 0) {
                this.props.onSurveyEdit(
                    {
                        categoryToEdit: resp,
                        selectedSurvey: {
                            value: data.automationSurvey,
                            label: data.automationSurveyName
                        },
                        start: true
                    }
                );
                this.props.history.push('/kartoitukset/luonti')

            }
        })
    }

    toggleBeingEdited = async(closeModal = false, data = false) => {   
        if(!closeModal && ((this.state.selectedPlanData && this.state.selectedPlanData._id) || (data && data._id))){
            let payload = {
                planId: (this.state.selectedPlanData && this.state.selectedPlanData._id)? this.state.selectedPlanData._id : data._id
            }
            const response = await executeRequest("plan/getPlanSubscribers", payload)
    
            if( response && response.length > 0 ){
                this.setState({
                    selectedPlanData: {
                        ...this.state.selectedPlanData,
                        beingEditedBy: response
                    }
                })
             
                this.setState({beingEditedOpen: true})    
            } else {
                this.startEditing(this.state.selectedPlanData._id, true)
            }
        }
        else {
            this.setState({beingEditedOpen: false})
        }
    }

    goToEdit = () => {
        this.props.subscribeToPlan({"planId": this.state.selectedPlanData._id})
        this.startEditing(this.state.selectedPlanData._id, true)
    }

    createAutomationSummary = async(payload) => {
        const response = await executeRequest('plan/createAutomationSummary', payload)
        if(response){
            if(response.createdId){
                NotificationManager.success("Kooste luotu onnistuneesti. Löydät koosteen Lomakkeet-listalta listan päivittämisen jälkeen","",5000)
            } else {

                NotificationManager.error("Mahdollinen virhe luotaessa koostetta.","Virhe", 4000)
            }
           
        } else {
            NotificationManager.error("Mahdollinen virhe luotaessa koostetta.","Virhe", 4000)
        }
        this.toggleSummaryModal()
    }

    addOrRemoveEnterpriseDownload = (plan, action, type) => {
        let payload = {}
        if(action === "add"){
            payload = {
                id: plan._id,
                name: plan.planName,
                group: type,
                action: action
            }
           
        }
        if(action === "remove"){
            payload = {
                id: plan._id,
                action: action
            }
        }
        
        this.props.editEnterpriseDownloads(payload)
    }

    toggleSummaryModal = (data = false) => {
        this.setState({selectedData: data, summaryModalOpen: !this.state.summaryModalOpen})
    }

    handlePlanFiltering = (data) => {
        if(data.planName.toLowerCase().includes(this.state.filter.toLowerCase())) return true 
        if(data.selectedBusiness != undefined && data.selectedBusiness.label.toLowerCase().includes(this.state.filter.toLowerCase())) return true 
        if(data.tags != undefined && data.tags.filter(tag => tag.label.toUpperCase().includes(this.state.filter.toUpperCase())).length > 0) return true
        if(data.automationSummary && this.state.automationSummary.includes(this.state.filter.toLowerCase())) return true

        return false
    }
    render() {
        const { toggleSidePanel, refreshInProgress } = this.props
        let i = 0;
        return (
            <Container fluid>
                {this.state.activeTab === 'automations' && <AutomationSummaryModal isOpen={this.state.summaryModalOpen} toggle={this.toggleSummaryModal} createAutomationSummary={this.createAutomationSummary} plan={this.state.selectedData}/>}
                <AutomaticPlanSettings isOpen={this.state.editSettingsModal} toggle={this.editPlanSettings} settings={this.state.planSettings.settings || {}} save={this.savePlanSettings} />
                <ModifySentSurveyModal isOpen={this.state.editSentSurvey} toggle={this.toggleSentSurveyChange} plan={this.state.planSettings} save={this.saveSentSurvey}/>
                <AddToProjectModal isOpen={this.state.projectModalOpen} toggle={this.toggleProjectModal} plan={this.state.selectedData}/>
                <Row>
                    <Col className="" xl="12" >
                        <div className="form-color" style={{borderRadius: '10px'}}>
                            <div className="whitebox " style={{ marginTop: '1rem', paddingTop:0, paddingBottom: '1em', paddingRight: '0.5em', paddingLeft:'1em', height: '100%', borderRadius:'30px' }}>
                                {this.state.delete ?
                                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className}>
                                        <ModalHeader>{this.state.selectedData && this.state.selectedData.planName}</ModalHeader>
                                        <ModalBody>
                                            <p>Oletko varma, että haluat poistaa lomakkeen? <b>Toimintoa ei voi peruuttaa!</b></p>
                                            <div style={{ marginTop: '1em'}}>
                                                <p>Hyväksyäksesi poistamisen, kirjoita vielä alla olevaan kentään pienillä kirjaimilla <b>poista</b>.</p>
                                                <Input id="deleteInput" type="text" value={this.state.deleteText} onChange={(e) => this.setState({deleteText: e.target.value})}/>
                                                <div className="email-check-box">
                                                    {this.state.deleteText === "poista"?
                                                        <i className="fas fa-check" style={{ color: '#B5E413' }}></i>
                                                        :
                                                        <i className="fas fa-times" style={{ color: 'red' }}></i> 
                                                    }
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <a onClick={this.toggleModal} className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                                            <button onClick={this.handleDelete} className="small-white-button swb-hover delete-plan wide-button" style={{ backgroundColor: '#ed5652', color: '#fff' }}>Poista</button>{' '}
                                        </ModalFooter>
                                    </Modal>
                                    :
                                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className} size="lg">
                                        <ModalHeader>Kopioi lomake</ModalHeader>
                                        <ModalBody>
                                            <NewPlanTarget copy={true} onCopy={this.handleCopy} selectedData={this.state.selectedData} cleanUp={this.cleanUp} />
                                        </ModalBody>
                                        <ModalFooter>
                                            <a className="go-back-link" onClick={this.toggleModal} style={{marginLeft: 0, marginRight: 'auto'}}>Peruuta</a>
                                        </ModalFooter>
                                    </Modal>
                                }
                                {this.state.selectedPlanData && <PlanBeingEditedModal plan={this.state.selectedPlanData} goToEdit={this.goToEdit} isOpen={this.state.beingEditedOpen} toggle={this.toggleBeingEdited}/>}
                                {this.state.movePlanToBusinessModalOpen && <MoveToBusinessModal isOpen={this.state.movePlanToBusinessModalOpen} toggle={this.toggleMoveToBusinessModal} modulesInUse={this.props.modulesInUse} planId={this.state.selectedPlanId} updatePlanList={this.handleRefresh}/>}
                                <div className="" style={{ paddingLeft: '1rem', paddingTop: '1em', paddingBottom:'0', marginBottom: '-2em', borderRadius: '10px 10px 0 0' }}>
                                    <Row>
                                        <Col xs="7">
                                            <h2>Lomakkeet</h2>
                                        </Col>
                                        <Col xs="5">
                                            <div style={{ display: 'flex', float: 'right', marginRight: '2em' }}>
                                            
                                                {this.props.EBAdmin && 
                                                    <>
                                                        {!this.state.helpLinkSetup &&
                                                            <>
                                                                {this.props.helpLinks && this.props.helpLinks.plan?
                                                                    <button className="small-white-button swb-hover" onClick={()=> this.setState({helpLinkSetup: true})} style={{}}>Muokkaa linkkiä</button>
                                                                    :
                                                                    <button className="small-white-button swb-hover" onClick={()=> this.setState({helpLinkSetup: true})} style={{}}>Aseta linkki</button>
                                                                }
                                                            </>
                                                        }
                                                        {this.state.helpLinkSetup &&
                                                            <div style={{ display: 'flex', width:'20em' }}>
                                                                <Input type="text" style={{ width: '70%' }} value={this.state.newHelpLink} onChange={(e)=> this.setState({newHelpLink: e.target.value})}/>
                                                                <button className="small-white-button swb-hover" onClick={()=> this.saveLink()} style={{width: '30%'}}>Tallenna</button>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {this.props.helpLinks && this.props.helpLinks.plan && this.props.helpLinks.plan.length > 0 &&
                                                    <div title="Ohjeet" className="news-info" style={{ display: 'flex', marginLeft: '1em', alignSelf: 'center', fontSize: '1.75rem' }} onClick={()=> window.open(this.props.helpLinks.plan)}>
                                                        <i className="fas fa-info-circle"></i>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="kartoitusExplanation" style={{ paddingBottom: '1em' }}>
                                        <Col xs="10">
                                            <p>
                                                Voit luoda erilaisia lomakkeita tai asiakirjoja sekä tekstikappalemalleja.
                                            </p>
                                            {this.state.activeTab === 'plans' && <p style={{ fontSize:'0.7em'}}>Lomakkeet-listaan haetaan aluksi vain osa uusimmista lomakkeista. Päivitä-painikkeella (oikealla) voi hakea loput lomakkeet ja päivittää listan</p>}
                                            {this.props.trial && <div style={{ marginTop:'1em'}}>{"Lomakkeita (lomakkeet + lomakepohjat): "+ this.props.planList.length + "/15"}</div>}
                                            {!this.props.trial && this.props.modulesInUse.limitedPlanManagement && this.props.modulesInUse.limitedPlanCount && <div style={{ marginTop:'1em'}}>{"Lomakkeita (lomakkeet + lomakepohjat): "+ this.props.planList.length + "/"+this.props.modulesInUse.limitedPlanCount}</div>}
                                        </Col>
                                        <Col xs="2">
                                           
                                            <div style={{ float: 'right', marginRight: '2em' }}>
                                                {refreshInProgress ?
                                                    <div style={{ width: '2.5em', height: '2.5em' }} className="spinner-border text-primary" role="status">
                                                    </div>
                                                    :
                                                    <button className="small-white-button refresh-button" title="Päivitä lomakkeet" onClick={() => this.handleRefresh()}>
                                                        <i className="fas fa-redo"></i>
                                                    </button>
                                                
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col>
                                        <div className="bigger-text" style={{ display: 'flex', padding: 0 }}>
                                            <Nav pills className="project-nav-tabs plan-tabs">
                                                <NavItem style={{ width: '10em', textAlign: 'center' }}>
                                                    <NavLink 
                                                        className={this.state.activeTab === 'plans' ? 'active' : ''}
                                                        onClick={() => { this.setState({ activeTab: 'plans', filter: "", filterPlaceholder: 'Etsi lomaketta' }) }}
                                                    >
                                                        Lomakkeet
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem style={{ width: '10em', textAlign: 'center' }} >
                                                    <NavLink
                                                        className={this.state.activeTab === 'templates' ? 'active' : ''}
                                                        onClick={() => { this.setState({ activeTab: 'templates', filter: "", filterPlaceholder: 'Etsi lomakepohjaa' }) }}
                                                    >
                                                    Lomakepohjat
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem style={{ width: '10em', textAlign: 'center' }} >
                                                    <NavLink
                                                        className={this.state.activeTab === 'automations' ? 'active' : ''}
                                                        onClick={() => { this.setState({ activeTab: 'automations', filter: "", filterPlaceholder: 'Etsi automaattista lomaketta' }) }}
                                                    >
                                                    Automaatiopohjat
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem style={{ width: '10em', textAlign: 'center' }} >
                                                    <Dropdown isOpen={this.state.toolMenuOpen} toggle={() => this.toggleTools()}>
                                                        <DropdownToggle tag="div" data-toggle="dropdown">
                                                            <NavLink
                                                                className={(this.state.activeTab === 'blocks' || this.state.activeTab === 'copied-models' || this.state.activeTab ===  'automationResults') ? 'active' : ''}
                                                                onClick={() =>  (this.setState({filter: "", filterPlaceholder: 'Etsi mallia'}), this.toggleTools())}
                                                            >
                                                            Työkalut <i className="fa fa-cog"></i>
                                                            </NavLink> 
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => { this.setState({ activeTab: 'blocks', filter: "", filterPlaceholder: 'Etsi päivitysmallia' }) }}>Päivitysmallit</DropdownItem>
                                                            <DropdownItem onClick={() => { this.setState({ activeTab: 'copied-models', filter: "", filterPlaceholder: 'Etsi pohjaa' }) }}>Pohjat</DropdownItem>
                                                            <DropdownItem onClick={() => { this.setState({ activeTab: 'iframes', filter: "", filterPlaceholder: 'Etsi pohjaa' }) }}>iFrame-pohjat</DropdownItem>
                                                            <DropdownItem onClick={() => { this.setState({ activeTab: 'automationResults', filter: "", filterPlaceholder: 'Etsi tulosta' }) }}>Tulokset</DropdownItem>
                                                            <DropdownItem onClick={() => { this.setState({ activeTab: 'combinePlans', filter: "", filterPlaceholder: '-' }) }}>Yhdistä lomakkeita</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </NavItem> 
                                            </Nav>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                        <Col>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ marginLeft: '2em', width: '100%', marginRight:'0.8em', marginBottom: '0.5em' }}>
                                                    <input type="search" className="search-bar-open form-control" placeholder={this.state.filterPlaceholder} value={this.state.filter} onChange={(event) => this.setState({ filter: event.target.value })} />
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </Row>

                                <Row className="categoryList" style={{ minHeight: 'max-content'}}>
                                    <Col xs="12">
                                        <Scrollbars
                                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                            style={{ width: "100%", height: '100%' }}
                                            className="custom-scrollbars"
                                        >
                                            {this.state.activeTab === 'blocks' &&
                                                <CustomBlockList filter={this.state.filter} toggleModal={this.toggleModal}/> 
                                            }
                                            {this.state.activeTab === 'combinePlans' && 
                                                <CombinePlans 
                                                    history={this.props.history}
                                                />
                                            }
                                            {this.state.activeTab === 'copied-models' && 
                                                <CopiedBlockList filter={this.state.filter}/>
                                            }

                                            {this.state.activeTab === 'iframes' && 
                                                <CopiedIFrameElements filter={this.state.filter} />
                                            }
                                            {this.state.activeTab === 'plans' && (this.props.hasLoaded? 
                                                this.props.planList.filter(plan => this.handlePlanFiltering(plan)).map((data, y) => {
                                                    const {planName} = data;
                                                    let isFollowed = false;
                                                    if(data.followOptions){
                                                        data.followOptions.map(option =>{
                                                            if(option.planOpenFollowed && option.planOpenFollowed === true){
                                                                isFollowed = true
                                                            }
                                                            if(option.commentingFollowed && option.commentingFollowed === true){
                                                                isFollowed = true
                                                            }
                                                        }) 
                                                    }      
                                                   
                                                        let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                                        let signedInfo = "Tähän lomakkeeseen on jo tehty allekirjoitus, joten muokkaamaan ei pääse suoraan. Voit siirtyä lomakkeen esikatseluun klikkaamalla lomaketta."
                                                        i++;
                                                        return(                                                             
                                                        <PlanItemWrapper key={y}>
                                                            <div className={oddEvenClass + " list-row"} style={{ paddingLeft: '1rem' }} key={y} onClick={() => data.automation ? this.openAnswers(data._id) : this.startEditing(data._id)}>
                                                                <Row>
                                                                    <Col xs={{ size: 5}}>
                                                                        <p title={planName} className="plan-list-name">{data.isPrivateDocument && <i className="far fa-eye-slash" style={{color: 'red'}} title='Yksityinen'></i>} {planName}</p>
                                                                        {data.template && <p>{"Lomakepohja"}</p>} 
                                                                        {data.selectedBusiness &&                  
                                                                            <p>{data.selectedBusiness.label}</p>
                                                                        }
                                                                        {!data.automationSummary && data.automationSurveyName &&
                                                                            <p><i className="fas fa-cog" title="Generoitu lomake"></i> Vastauksia: {data.answers.length} kpl</p>
                                                                        }
                                                                    </Col>
                                                                    <Col xs={{ size: 4 }}>
                                                                        {/* <p>{data.creator}</p> */}
                                                                        <Row >
                                                                            <Col>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                                    {data.tags && data.tags.slice(0, 5).map((tag, i) => {                        
                                                                                        return(<div key={i} style={{ marginLeft: '0.15em', paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                                                    })}                                                       
                                                                                    {data.tags && data.tags.length > 5 &&  
                                                                                        <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                                                                            <i className="material-icons" title={tagsToMore(data.tags,5)}>more_horiz</i>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                {data.lastDate && 
                                                                                    <p style={{ fontSize: '0.8rem' }}>Viim. akt.: <span style={this.lastActiveDay(data.lastDate)?{ color:'red' } : {}}>{moment.utc(data.lastDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>
                                                                                }
                                                                                {(data.lastEdited ||data.modified) && 
                                                                                    <p style={{ fontSize: '0.8rem' }}>
                                                                                        Muokattu: <span>{moment.utc(data.lastEdited ? data.lastEdited : data.modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY HH:mm ')}</span> 
                                                                                    </p>
                                                                                }
                                                                                {data.automationSummary && <p style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Kooste</p>}
                                                                            </Col>
                                                                            {((data.annotations && data.annotations.length > 0) || data.status === "published") &&
                                                                                <Col xs="3" > 
                                                                                    {data.status === "published" &&  
                                                                                        <div className="center-in-container" >
                                                                                            <span className="dot" title="Julkaistu" style={{backgroundColor:  'rgb(181, 228, 19)', fontSize:'0.8rem', float: 'center', marginBottom: '0.5em' }}></span>
                                                                                        </div>
                                                                                    }  
                                                                                    {data.annotations && data.annotations.length > 0 &&
                                                                                        <div className="center-in-container" >
                                                                                            <i className="far fa-comment-dots" style={{ fontSize: '1.2rem', color: '#b5e413', float: 'center' }} title="Sisältää kommentteja"></i>
                                                                                        </div>
                                                                                    }
                                                                                </Col>
                                                                            }
                                                                            {isFollowed && <div style={{marginLeft : '-1em', zIndex:'12',  opacity: '0.8'}}><i className="far fa-eye" title="Lomaketta seurataan" ></i></div>}
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xs={{ size: 2 }} className="center-in-container" style={{ alignItems: 'flex-start' }}>
                                                                        {(data.isSigned === true)? 
                                                                            ((data.allSigned && data.allSigned === true)? 
                                                                                <i className="fas fa-signature" aria-hidden="true" title={signedInfo} style={{ color: '#b5e413', cursor: 'pointer', fontSize: '1.5rem' }}></i>
                                                                                : 
                                                                                <i className="fas fa-signature" aria-hidden="true" title={signedInfo} style={{ color: '#ffcc00', cursor: 'pointer', fontSize: '1.5rem' }}></i>
                                                                            )
                                                                            :
                                                                            (!data.automationSummary && <button className="small-white-button lighter-text wide-button" onClick={(e) => (e.stopPropagation(),this.setState({selectedPlanData: data}, ()=> this.toggleBeingEdited(false, data)))}>Muokkaa</button>)
                                                                        }   
                                                                        {data.answerCount && <p style={{ fontSize: '0.8rem'}}>{"Vastauksia: "+ data.answerCount }</p>}
                                                                    </Col>
                                                                    <Col xs={{ size: 1 }}>
                                                                        <Dropdown isOpen={this.state.selected === y} style={{ height: '100%', width: '100%' }} onClick={(event) => event.stopPropagation()} toggle={() => this.toggleDropdown(y)}>
                                                                            <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                                                <i className="fas fa-bars" title="Valikko"></i>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={()=> this.startEditing(data._id)}>Avaa</DropdownItem>
                                                                                {!data.automation ?
                                                                                    <DropdownItem onClick={() => this.toggleModal(data, false)}>Kopioi</DropdownItem>
                                                                                    :
                                                                                    <>
                                                                                        <DropdownItem onClick={() => this.toggleSentSurveyChange(data)}>Vaihda kartoitusta</DropdownItem>
                                                                                        <DropdownItem onClick={() => this.editPlanSettings(data) } >Muokkaa asetuksia</DropdownItem>
                                                                                    </>
                                                                                }
                                                                                <DropdownItem onClick={()=> this.toggleProjectModal(data)}>Lisää projektiin</DropdownItem>
                                                                                {this.props.EBAdmin && this.props.enterpriseDownloads &&
                                                                                    <DropdownItem onClick={() => this.addOrRemoveEnterpriseDownload(data, this.props.enterpriseDownloads.filter(download => download.id === data._id).length === 0 ? "add" : "remove", "Lomake")}>{this.props.enterpriseDownloads.filter(download => download.id === data._id).length === 0 ?"Lisää lomake ladattavaksi" : "Poista lomake latauksista"}</DropdownItem>
                                                                                }
                                                                                {(data.isSigned !== true && data.allSigned !== true) && 
                                                                                    <>
                                                                                        <DropdownItem onClick={()=> this.toggleMoveToBusinessModal(data._id)}>Siirrä toiselle yritykselle</DropdownItem>
                                                                                        <DropdownItem onClick={() => this.toggleModal(data, true)} >Poista</DropdownItem>
                                                                                    </>
                                                                                }
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    </Col>  
                                                            
                                                                    {
                                                                        this.state.answersId === data._id && data.answers.map((answer, yy) => {
                                                                            let generated = this.props.automationList.filter(e => e.usedAnswerSession === answer.answerSessionId).length > 0 ? true : false
                                                                            let moved = this.props.planList.filter(e => e.usedAnswerSession === answer.answerSessionId)
                                                                            let busName = ''
                                                                            
                                                                            if (moved.length > 0) {
                                                                                busName = moved[0].selectedBusiness.label
                                                                            }

                                                                            return <div key={yy} style={{ borderTop: yy !== 0 ? '1px solid #cecece' : 'none', marginLeft: '1em' }}>
                                                                                <Row key={yy} className={'selectable-row list-row'} style={{ paddingTop: '.5em', paddingBottom: '.5em' }} onClick={() => this.generatePlan(data._id, answer.answerSessionId)}>
                                                                                    <Col xs="5">
                                                                                        <p>{moment.utc(answer.date, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')} {generated && '(Luotu)'} {busName && ` (Siirretty: ${busName})`} </p>
                                                                                    </Col>
                                                                                    <Col xs="4">
                                                                                        {answer.businessId && <p>Y-tunnus: {answer.businessId}</p>}
                                                                                    </Col>
                                                                                    <Col xs={{ size: 3}}>
                                                                                        <button className="small-white-button lighter-text wide-button" onClick={() => this.generatePlan(data._id, answer.answerSessionId)}>Generoi</button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </Row>
                                                            </div>
                                                        </PlanItemWrapper>
                                                )
                                                    return null
                                                })
                                                :
                                                <MegaLoaderSpinner/>  
                                            )}
                                            {this.state.activeTab === 'automations' && (this.props.automationList? this.props.automationList.map((data, y) => {
                                                const {planName} = data;
                                                let isFollowed = false;
                                                if(data.followOptions){
                                                    data.followOptions.map(option =>{
                                                        if(option.planOpenFollowed && option.planOpenFollowed === true){
                                                            isFollowed = true
                                                        }
                                                        if(option.commentingFollowed && option.commentingFollowed === true){
                                                            isFollowed = true
                                                        }
                                                    }) 
                                                }      
                                                if (!data.template && ((data.planName.toLowerCase().includes(this.state.filter.toLowerCase())) || (data.selectedBusiness != undefined && data.selectedBusiness.label.toLowerCase().includes(this.state.filter.toLowerCase())) || (data.tags != undefined && data.tags.filter(tag => tag.label.toUpperCase().includes(this.state.filter.toUpperCase())).length > 0) || (data.creator !== undefined && data.creator.toLowerCase().includes(this.state.filter.toLowerCase())))) {
                                                    let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                                    let signedInfo = "Tähän lomakkeeseen on jo tehty allekirjoitus, joten muokkaamaan ei pääse suoraan. Voit siirtyä lomakkeen esikatseluun klikkaamalla lomaketta."
                                                    i++;
                                                    return <div className={oddEvenClass + ' list-row'} style={{ paddingLeft: '1rem' }} key={y} onClick={() => data.automation ? this.openAnswers(data._id) : this.startEditing(data._id)}>
                                                        <Row className=''>
                                                            <Col xs={{ size: 5}}>
                                                                <p title={planName} className="plan-list-name">{planName}</p>
                                                                {data.template && <p>{"Lomakepohja"}</p>} 
                                                                {data.selectedBusiness &&                  
                                                                    <p>{data.selectedBusiness.label}</p>
                                                                }
                                                            
                                                                {data.automationSurveyName &&
                                                                    <>
                                                                        <p title={data.automationSurveyName} style={{ fontSize: '0.9rem' }}>Kartoitus: <a style={{ color: 'rgb(0, 0, 167)', textDecoration: 'underline' }} title={"Siirry kartoitukseen"} onClick={() => this.goToSurvey(data)}>{data.automationSurveyName}</a></p>
                                                                        <p><i className="fas fa-cog" title="Generoitu lomake"></i> Vastauksia: {data.answers.length} kpl</p>
                                                                    </>
                                                                }
                                                            </Col>
                                                            <Col xs={{ size: 4 }}>
                                                                {/* <p>{data.creator}</p> */}
                                                                <Row >
                                                                    <Col>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                            {data.tags && data.tags.slice(0, 5).map((tag, i) => {                        
                                                                                return(<div key={i} style={{ marginLeft: '0.15em', paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                                            })}                                                       
                                                                            {data.tags && data.tags.length > 5 &&  
                                                                                <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                                                                    <i className="material-icons" title={tagsToMore(data.tags,5)}>more_horiz</i>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {data.lastDate && 
                                                                            <p style={{ fontSize: '0.8rem' }}>Viim. akt.: <span style={this.lastActiveDay(data.lastDate)?{ color:'red' } : {}}>{moment.utc(data.lastDate, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY')}</span></p>
                                                                        }
                                                                        {(data.lastEdited || data.modified) && 
                                                                            <p style={{ fontSize: '0.8rem' }}>
                                                                                Muokattu: <span>{moment.utc(data.lastEdited? data.lastEdited : data.modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY HH:mm ')}</span> 
                                                                            </p>
                                                                        }
                                                                    </Col>
                                                                    {((data.annotations && data.annotations.length > 0) || data.status === "published") &&
                                                                        <Col > 
                                                                            {data.status === "published" &&  
                                                                                <div className="center-in-container" >
                                                                                    <span className="dot" title="Julkaistu" style={{backgroundColor:  'rgb(181, 228, 19)', fontSize:'0.8rem', float: 'center', marginBottom: '0.5em' }}></span>
                                                                                </div>
                                                                            }  
                                                                            {data.annotations && data.annotations.length > 0 &&
                                                                                <div className="center-in-container" >
                                                                                    <i className="far fa-comment-dots" style={{ fontSize: '1.2rem', color: '#b5e413', float: 'center' }} title="Sisältää kommentteja"></i>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                    }
                                                                    {isFollowed && <div style={{marginLeft : '-1em', zIndex:'12',  opacity: '0.8'}}><i className="far fa-eye" title="Lomaketta seurataan" ></i></div>}
                                                                </Row>
                                                            </Col>
                                                            <Col xs={{ size: 2 }} className="center-in-container" style={{ alignItems: 'flex-start' }}>
                                                                {(data.isSigned === true)? 
                                                                    ((data.allSigned && data.allSigned === true)? 
                                                                        <i className="fas fa-signature" aria-hidden="true" title={signedInfo} style={{ color: '#b5e413', cursor: 'pointer', fontSize: '1.5rem' }}></i>
                                                                        : 
                                                                        <i className="fas fa-signature" aria-hidden="true" title={signedInfo} style={{ color: '#ffcc00', cursor: 'pointer', fontSize: '1.5rem' }}></i>
                                                                    )
                                                                    :
                                                                    <button className="small-white-button lighter-text wide-button" onClick={(e) => (e.stopPropagation(), this.startEditing(data._id, true))}>Muokkaa</button>
                                                                }
                                                            </Col>
                                                            <Col xs={{ size: 1 }}>
                                                                <Dropdown isOpen={this.state.selected === y} style={{ height: '100%', width: '100%' }} onClick={(event) => event.stopPropagation()} toggle={() => this.toggleDropdown(y)}>
                                                                    <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                                        <i className="fas fa-bars" title="Valikko"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={()=> this.startEditing(data._id)}>Avaa</DropdownItem>
                                                                        {!data.automation ?
                                                                            ((data.isSigned !== true && data.allSigned !== true)&& <DropdownItem onClick={() => this.toggleModal(data, false)}>Kopioi</DropdownItem>)
                                                                            :
                                                                            <>
                                                                                <DropdownItem onClick={() => this.toggleSentSurveyChange(data)}>Vaihda kartoitusta</DropdownItem>
                                                                                <DropdownItem onClick={() => this.editPlanSettings(data) } >Muokkaa asetuksia</DropdownItem>
                                                                            </>
                                                                        }
                                                                        <DropdownItem onClick={()=> this.toggleProjectModal(data)}>Lisää projektiin</DropdownItem>
                                                                        
                                                                        {(data.isSigned !== true && data.allSigned !== true) && 
                                                                            <>
                                                                                <DropdownItem onClick={()=> this.toggleMoveToBusinessModal(data._id)}>Siirrä toiselle yritykselle</DropdownItem>
                                                                                <DropdownItem onClick={() => this.toggleSummaryModal(data)}>Luo kooste</DropdownItem>
                                                                                <DropdownItem onClick={() => this.toggleModal(data, true)} >Poista</DropdownItem>
                                                                            </>
                                                                        }
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </Col>  
                                                        </Row>
                                                        {
                                                            this.state.answersId === data._id && data.answers.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime() ).map((answer, yy) => {
                                                                let generated = this.props.automationList.filter(e => e.usedAnswerSession === answer.answerSessionId).length > 0 ? true : false
                                                                let moved = this.props.planList.filter(e => e.usedAnswerSession === answer.answerSessionId)
                                                                let busName = ''
                                                                
                                                                if (moved.length > 0) {
                                                                    busName = moved[0].selectedBusiness.label
                                                                }

                                                                return <div key={yy} style={{ borderTop: yy !== 0 ? '1px solid #cecece' : 'none', marginLeft: '1em' }}>
                                                                    <Row key={yy} className={'list-row '} style={{ paddingTop: '.5em', paddingBottom: '.5em' }} onClick={() => this.generatePlan(data._id, answer.answerSessionId)}>
                                                                        <Col xs="5">
                                                                            <p>{moment.utc(answer.date, "MMM D, YYYY, HH:mm:ss A", "en").local().format('D.M.YYYY  HH.mm')} {generated && '(Luotu)'} {busName && ` (Siirretty: ${busName})`} </p>
                                                                        </Col>
                                                                        <Col xs="4">
                                                                            {answer.businessId && <p>Y-tunnus: {answer.businessId}</p>}
                                                                        </Col>
                                                                        <Col xs={{ size: 3}}>
                                                                            <button className="small-white-button lighter-text wide-button" onClick={() => this.generatePlan(data._id, answer.answerSessionId)}>Generoi</button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                }
                                                return null
                                                })
                                                :
                                                <MegaLoaderSpinner/>
                                            )}
                                            {this.state.activeTab === 'automationResults' && this.props.automationResults.map((data, y) => {
                                                if ((data.planName.toLowerCase().includes(this.state.filter.toLowerCase())) || (data.created !== undefined && data.created.toLowerCase().includes(this.state.filter.toLowerCase())) || (data.creator !== undefined && data.creator.toLowerCase().includes(this.state.filter.toLowerCase())) ||  (data.created !== undefined && data.created.toLowerCase().includes(this.state.filter.toLowerCase())) || (data.business !== undefined && ((data.business.label != undefined && data.business.label.toLowerCase().includes(this.state.filter.toLowerCase()))|| (data.business.businessID != undefined && data.business.businessID.toLowerCase().includes(this.state.filter.toLowerCase())))) || (data.planTitle !== undefined && data.planTitle.toLowerCase().includes(this.state.filter.toLowerCase()))) {
                                                    let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                                    i++;
                                                    return <React.Fragment key={y}>
                                                        <div className={oddEvenClass + ' list-row'} style={{ paddingLeft: '1rem' }} key={y} onClick={() => this.startEditing(data._id)}>
                                                            <Row className=''>
                                                                <Col xs={{ size: 4 }}>
                                                                    {data.planTitle?
                                                                        <p title={data.planTitle}>{data.planTitle.length > 70 ? this.shortTitle(data.planTitle) : data.planTitle}</p>
                                                                        :
                                                                        <p title={data.planName}>{data.planName.length > 70 ? this.shortTitle(data.planName) : data.planName}</p>
                                                                    }
                                                                    {data.selectedBusiness &&
                                                                        <p>{data.selectedBusiness.label}</p>
                                                                    }
                                                                    {data.automationSurveyName &&
                                                                        <p>Kartoitus: {data.automationSurveyName}</p>
                                                                    }
                                                                </Col>
                                                                <Col xs={{ size: 5 }}>
                                                                    {/* <p>{data.creator}</p> */}
                                                                    {data.business &&
                                                                        <p> {data.business.label? data.business.label : ""} / {data.business.businessID? data.business.businessID: ""} </p>
                                                                    }
                                                                    <p>{moment.utc(data.created, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY HH:mm ')}</p>
                                                                </Col>
                                                                <Col xs={{ size: 2 }}>
                                                                    <button className="small-white-button lighter-text wide-button" onClick={() => this.startEditing(data._id)}>Muokkaa</button>
                                                                </Col>
                                                                <Col xs={{ size: 1 }}>
                                                                    <Dropdown isOpen={this.state.selected === y} style={{ height: '100%', width: '100%' }} onClick={(event) => event.stopPropagation()} toggle={() => this.toggleDropdown(y)}>
                                                                        <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                                            <i className="fas fa-bars" title="Valikko"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => this.toggleModal(data, false)}>Kopioi</DropdownItem>
                                                                            <DropdownItem onClick={() => this.toggleModal(data, true)} >Poista</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                return null
                                            })}
                                            {this.state.activeTab === 'templates' && this.props.templateList.map((data, y) => {
                                                if ((data.planName.toLowerCase().includes(this.state.filter.toLowerCase())) || (data.creator !== undefined && data.creator.toLowerCase().includes(this.state.filter.toLowerCase()))) {
                                                    let oddEvenClass = i % 2 === 0 ? 'even-row' : 'odd-row'
                                                    i++;
                                                    return <React.Fragment key={y}>
                                                        <div className={oddEvenClass + ' list-row'} style={{ paddingLeft: '1rem', paddingBottom: '1em', cursor: 'auto' }} key={y} >
                                                            <Row className=''>
                                                                <Col xs={{ size: 6 }}>
                                                                    <p className="plan-list-name" title={data.planTitle}>{data.planTitle ? (data.planTitle.length > 70 ? this.shortTitle(data.planTitle) : data.planTitle) : data.planName}</p>
                                                                </Col>
                                                                <Col xs={{ size: 3 }}>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                        {data.tags && data.tags.slice(0, 5).map((tag, i) => {                        
                                                                            return(<div key={i} style={{ marginLeft: '0.15em', paddingTop:"0.15em"}}><p key={i} className="tag-display tag-text form-lighter-color" title={tag.label}>{tag.label}</p></div>)
                                                                        })}                                                       
                                                                        {data.tags && data.tags.length > 5 &&  
                                                                            <div style={{ paddingTop: "0.15em", marginBottom: "auto", marginTop: "" }}>
                                                                                <i className="material-icons" title={tagsToMore(data.tags,5)}>more_horiz</i>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <p style={{ fontSize: '0.8rem' }}>Muokattu: <span>{moment.utc(data.lastEdited? data.lastEdited : data.modified, "MMM D, YYYY hh:mm:ss A", "en").local().format('DD.MM.YYYY HH:mm ')}</span></p>
                                                                </Col>
                                                                <Col xs={{ size: 2 }}>
                                                                        <CreatePlanFromTemplate 
                                                                            clients={this.state.clientList}
                                                                            projects={this.state.projectList}
                                                                            planId={data._id}
                                                                        />
                                                                </Col>
                                                                <Col xs={{ size: 1 }}>
                                                                    <Dropdown isOpen={this.state.selected === y} style={{ height: '100%', width: '100%', cursor: 'pointer' }} onClick={(event) => event.stopPropagation()} toggle={() => this.toggleDropdown(y)}>
                                                                        <DropdownToggle tag="div" style={{  float: 'right', marginRight:'0.5em', fontSize: '1.25rem' }}>
                                                                            <i className="fas fa-bars" title="Valikko"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={()=> this.startEditing(data._id)}>Avaa</DropdownItem>
                                                                            <DropdownItem onClick={() => this.toggleModal(data, false)}>Kopioi</DropdownItem>
                                                                            {this.props.EBAdmin && this.props.enterpriseDownloads &&
                                                                                <DropdownItem onClick={() => this.addOrRemoveEnterpriseDownload(data, this.props.enterpriseDownloads.filter(download => download.id === data._id).length === 0 ? "add" : "remove", "Lomakepohja")}>{this.props.enterpriseDownloads.filter(download => download.id === data._id).length === 0 ?"Lisää lomake ladattavaksi" : "Poista lomake latauksista"}</DropdownItem>
                                                                            }
                                                                            <DropdownItem onClick={() => this.toggleModal(data, true)} >Poista</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                return null
                                            })}

                                        </Scrollbars>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container >
        )
    }
}

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const AddToProjectModal = ({isOpen, toggle, plan}) => {
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState(null)
    useEffect(() => {
        getUserProjects()
    },[])
    const getUserProjects = async() => {
        const response = await executeRequest('project/getProjectsForSelect',{})
        if(!!response){
            setProjects(response)
        }
    }

    const handleAddToProject = async() => {
        if(selectedProject !== null){
            let payload = {
                planId: plan._id,
                projectId: selectedProject.value
            }
            const response = await executeRequest('project/addPlanToProject', payload)
            if(response.success){
                NotificationManager.success('Lomake lisätty projektiin onnistuneesti!', '', 3000)
                toggle({})
                
            }
            else if(response.exists){
                NotificationManager.error('Lomake on jo lisätty projektiin!', 'Virhe', 3000)
            }
            else {
                NotificationManager.error('Virhe lisätessälomaketta!', 'Virhe', 3000)
            }
        } else {
            NotificationManager.error('Ei valittua projektia!', 'Virhe', 3000)
        }
        setSelectedProject(null)
    }

    return(
        <Modal size="lg" isOpen={isOpen} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()}> Lisää lomake projektiin</ModalHeader>
            <ModalBody>
                <Label>Valitse projekti, johon lomake liitetään</Label>
                <Select
                    value={selectedProject}
                    onChange={(selected) => setSelectedProject(selected)}
                    options={projects}
                    placeholder="Valitse projekti.."
                    isDisabled={false}
                    noOptionsMessage={() => { return "Tuloksia ei löytynyt" }}
                    className="companyTargets"
                    classNamePrefix="select"
                />
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => (toggle(),setSelectedProject(null))}>Peruuta</a>
                <button className="small-white-button lighter-text wide-button" onClick={() => handleAddToProject()}>Tallenna</button>
            </ModalFooter>
        </Modal>
    )

}

const customMessages = {
    messages: {
        searchPlaceholder: "Etsi vastausta...",
        noItemsMessage: "Valitse vastaus",
        noneSelectedMessage: "Valintoja ei ole",
        selectedMessage: "valittuna",
        selectAllMessage: "Valitse kaikki",
        clearAllMessage: "Tyhjennä valinnat",
        disabledItemsTooltip: "You can only select 1 file"
    }
}

//hides data of items not in view to ease the workload
export const PlanItemWrapper = ({children}) => {
    const [ref, inView, entry] = useInView({threshold:0})
    const variants = {
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {delay: 0.1, ease: 'easeIn', duration: 0.1}
        }),
        hidden: i => ({
            opacity: 1, 
            x: 0,
            transition: {delay: 0.1, ease: 'easeOut', duration: 0.1}
        })
    }
    return ( 
        <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ ease: 'easeIn', duration: 0.1 }}
            exit={{ opacity: 0, x: -50 }}
            key={"forms"}
            ref={ref}
        >   
            <div className={"list--content"}>
                {inView && children}
            </div>
        </motion.div>
    )
}

const AutomationSummaryModal = ({isOpen, toggle, createAutomationSummary, plan}) => {
    const [selectedAnswers, setSelectedAnswers] = useState([])
    const [answerList, setAnswerList] = useState([])
    const [title, setTitle] = useState("")
    const [headerText, setHeaderText] = useState("")
    const [selectedVariable, setSelectedVariable] = useState(false)
    const [variables, setVariables] = useState([])

    useEffect(()=>{
        if(plan && plan._id){
            getAnswerSessionData()
            //Commented getting variables due to the fact that variables can be set to the paragraph title in the automation plan
            //getVariables()
            setTitle(plan.planName)
        }
    },[plan])

    const getAnswerSessionData = async() => {
        let payload = {
            planId: plan._id
        }
        const response = await executeRequest('plan/getAnswersForSummaryCreation',payload)
        if(response){
            let array = []
            response.map((answer) => {
                if(array.filter((ans) => ans.id === answer.id).length === 0){
                    answer = {
                        ...answer,
                        label: moment.utc(answer.label, "MMM D, YYYY hh:mm:ss A", "en").local().format("DD.MM.YYYY HH:mm")
                    }
                    array.push(answer)
                }
            })
            
            setAnswerList(array)
        }
    }

    const getVariables = async() => {
        let payload = {
            id: plan.automationSurvey
        }
        const response = await executeRequest('plan/getSurveyVariables',payload)
        if(response){
            let array = []
            response.map((variable) => {
                if(variable.name !== "@liite"){
                    variable = {
                        ...variable,
                        label: variable.name + " - " + variable.title
                    }
                    array.push(variable)
                }
            })
            setVariables(array)
        }
    }

    const handleSelectedAnswers = (selected) => {
        setSelectedAnswers(selected)
    }

    const handleCreation = () => {
        let payload = {
            planId:plan._id,
            selectedAnswers: selectedAnswers,
            title: title,
            headerText: headerText.length > 0 ? headerText: false,
            selectedVariable: selectedVariable
        }
        createAutomationSummary(payload)
    }
    return (
        <>
            {plan && answerList.length > 0 &&
                <Modal size="lg" isOpen={isOpen} toggle={() => toggle()}>
                    <ModalHeader toggle={() => toggle()}>Koosteen asetukset</ModalHeader>
                    <ModalBody>
                        <Col>
                            <Row>
                                <Col>
                                    <Label>{'Koosteen (Lomake) nimi:'}</Label>
                                    <Input value={title} onChange={(e) => setTitle(e.target.value)}/>
                                </Col>
                            </Row>
                            {/* <Row style={{ marginTop: '1em'}}>
                                <Col>
                                    <Label>Kappaleiden otsikon sisältö:</Label>
                                    <Input value={headerText} placeholder="Mikäli tyhjä, käytetään vastauksen pvm" onChange={(e) => setHeaderText(e.target.value)}/>
                                    {variables.length > 0 && 
                                        <div style={{marginTop:'1em'}}>
                                            <Label>{'Voit sisällyttää kappaleen otsikkoon myös kysymyksen vastauksen (mikäli et halua, jätä tyhjäksi)'}</Label>
                                            <Select 
                                                options={variables} 
                                                onChange={(val)=> setSelectedVariable(val)}
                                                value={selectedVariable}
                                                isDisabled={false}
                                                noOptionsMessage={() => { return "Kysymyksiä ei löytynyt" }}
                                                className=""
                                                placeholder={"Valitse kysymys"}
                                                classNamePrefix="select"
                                            />
                                        </div>
                                    }
                                </Col>
                            </Row> */}
                            <Row style={{ marginTop: '1em'}}>
                                <Col>
                                    <Label>Valitut vastaukset:</Label>
                                    <MultiSelect
                                        messages={customMessages.messages}
                                        items={answerList}
                                        selectedItems={selectedAnswers}
                                        onChange={handleSelectedAnswers}
                                        wrapperClassName="multi-selector round-corners"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={() => toggle()}>Peruuta</a>
                        <button className="small-white-button lighter-text wide-button" onClick={() => handleCreation()}>Luo kooste</button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

const tagsToMore = (tags, slicer) => {
    var tagss = ""
    if(tags !== undefined ){
        tags.slice(slicer, tags.length).filter(t => tagss = tagss + "  " + t.label.toString())
        return(tagss)
    }
}

const mapStateToProps = state => {
    return {
        planList: state.plan.planList,
        enterpriseDownloads: state.plan.enterpriseDownloads || [],
        automationResults: state.plan.automationResults,
        automationList: state.plan.automationList,
        businessName: state.authentication.businessName,
        templateList: state.plan.templateList,
        hasLoaded: state.plan.hasLoaded || false,
        refreshInProgress: state.plan.refreshInProgress,
        modulesInUse: state.authentication.modulesInUse,
        EBAdmin: state.authentication.EBAdmin || false,
        helpLinks: state.authentication.helpLinks,
        trial: state.authentication.trial,
        enterpriseDownloads: state.utils.enterpriseDownloads
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMountFetchPlans: () => dispatch(ActionCreator.fetchOnlyPlansForFirstList()),
        getAllPlans: () => dispatch(ActionCreator.fetchAllPlans()),
        onMountFetchAutomations: () => dispatch(ActionCreator.fetchAllAutomations()),
        refreshPlans: () => dispatch(ActionCreator.refreshPlans()),
        onEdit: (planId) => dispatch(ActionCreator.startEditingPlan(planId)),
        onModify: (planId, edit) => dispatch(ActionCreator.startEditingPlan(planId, edit)),
        onCopy: (payload) => dispatch(ActionCreator.copyTargetPlan(payload)),
        onDelete: (payload) => dispatch(ActionCreator.deleteTargetPlan(payload)),
        addBreadcrumbs: (crumb) => dispatch(addBreadcrumbs(crumb)),
        setBreadcrumbs: (crumb) => dispatch(setBreadcrumbs(crumb)),
        addMoreBreadcrumbs: (crumbs) => dispatch(addMoreBreadcrumbs(crumbs)),
        generatePlan: (payload) => dispatch(ActionCreator.generateAutomaticPlan(payload)),
        savePlanSettings: (payload) => dispatch(ActionCreator.savePlanSettings(payload)),
        modifyPlanSentSurvey: (payload) => dispatch(ActionCreator.modifyPlanSentSurvey(payload)),
        getTemplates: (payload) => dispatch(ActionCreator.getTemplates(payload)),
        updateHelpLinks: (payload) =>dispatch(updateHelpLinks(payload)),
        onSurveyEdit: (payload) => dispatch(ActionCreatorIndex.startSurveyEditFromSentSurveys(payload)),
        subscribeToPlan : (payload) => dispatch(ActionCreator.subscribeToPlan(payload)),
        editEnterpriseDownloads : (payload) => dispatch(UtilActions.editEnterpriseDownloads(payload)),
        getEnterpriseDownloads: (payload) => dispatch(UtilActions.getEnterpriseDownloads(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanList);

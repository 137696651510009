import React, { useState, useRef } from 'react'
import {
  Row,
  Col
} from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'

import {confs} from '../../../../modules/config';
import {NotificationManager} from 'react-notifications';

import ViewCardModal from '../../../Reports/subComponents/Trello/ViewCardModal';

const MegaLoaderSpinner = () => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '5rem', height: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

const emptyCard = {
    name: '',
    desc: '',
    labels: [],
    memberList: []
}


const TrelloItem = ({itemId, businessId, preview = false}) => {
    const isCancelled = useRef(false);
    const [ data, setData] = useState({});
    const [ isLoading, setIsLoading ] = useState( false )
    const [ cardModalIsOpen, setCardModalIsOpen ] = useState( false )
    const [ selectedCard, setSelectedCard ] = useState( emptyCard )

    React.useEffect(() => {
        isCancelled.current = false;
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            businessId: businessId,
            id: itemId,
            type: 'trello'
        }
        setIsLoading(true);
        fetchData(payload);

        return () => {
            isCancelled.current = true;
        }
    
    }, [itemId]);

    

    const fetchData = async payload => {
        
        try {
            const response = await fetch( confs.url + 'plan/embedItemData', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )

            if(response.success === undefined) {
                const responseJson = await response.json();
                //Extra checks so if this gets unmounted mid request, won't try setting data that can cause memory leaks
                if(!isCancelled.current)
                    setData(responseJson);
            }
        } catch(err) {
            NotificationManager.error("Valitettavasti lohkon tietojen haussa tapahtui virhe", "", 3000);
        }
        //Extra checks so if this gets unmounted mid request, won't try setting data that can cause memory leaks
        if(!isCancelled.current)
            setIsLoading(false);
    }

    const toggleCardmodal = () => {
        setCardModalIsOpen( !cardModalIsOpen )
    }

    const openCardModal = card => {
        if(!preview) {
            setSelectedCard( card )
            toggleCardmodal()
        }

        return;
    }

    return (
        isLoading ? <MegaLoaderSpinner />
        :
        <>
        {!preview && <ViewCardModal isOpen={ cardModalIsOpen } toggle={ toggleCardmodal } card={ selectedCard } businessId={ businessId } />}
        <div className="dashboard-medium-box" style={{marginTop: 0, height: '100%'}}>
            <div className="box-header bigger-text">
                <p>
                    { data.name }
                </p>
            </div>
            <Scrollbars
            renderTrackHorizontal={ props => <div { ...props } className="track-horizontal" style={ { display: "none" } } /> }
            renderThumbHorizontal={ props => <div { ...props } className="thumb-horizontal" style={ { display: "none" } } /> }
            style={ { width: "100%", height: '500px' } }
            >
            { data.cards && data.cards.filter( card => !card.closed ).map( ( card, index ) => {

                let oddEvenClass = index % 2 === 0 ? 'even-row' : 'odd-row'

                return (
                <div key={ index } className={ "selectable-row " + oddEvenClass } style={ { padding: '1em' } } onClick={ () => openCardModal( card ) }>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    { card.labels && card.labels.map( ( label, index ) => {
                                    return (
                                        <span key={ index } title={ label.name } className={ `trello-label trello-label-${ label.color }` }>
                                            <span className='trello-label-text'>{ label.name }</span>
                                        </span>
                                    )
                                    } ) }
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    { card.name }
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    { card.memberList.map( ( member, index ) => {
                                        return <span key={ index } title={ member.fullName } className='trello-member'>{ member.initials }</span>
                                    } ) }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                )
            } ) }

            </Scrollbars>
        </div>
        </>
    )
}

export default TrelloItem
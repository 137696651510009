import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import '../../question/modals/modals.css';
const GroupDeletionModal = props => {
    return (
        <div>
            <Modal isOpen={props.modal} toggle={props.toggle} centered={true}>
                <ModalHeader toggle={props.toggle}>Kysymysryhmän poisto</ModalHeader>
                <ModalBody>
                    Oletko aivan varma ryhmän poistosta? <b>Ryhmän poisto on pysyvä.</b>
                </ModalBody>
                <ModalFooter>
                    <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={props.toggle} >Peruuta</a>{' '}
                    <button className="small-white-button wide-button swb-hover" style={{backgroundColor: '#ed5652', color: '#fff'}} onClick={props.deleteGroup}>Poista</button>
                </ModalFooter>
            </Modal>
      </div>
    )
}

export default GroupDeletionModal;
import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Container, Row, Col, Modal, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap'
import { Scrollbars } from 'react-custom-scrollbars'
import * as ActionCreator from '../../../store'
import ModifySurveyTree from './ModifySurveyTree'
import BusinessSummary from './BusinessSummary'
import RiskList from '../../Clients/subComponents/RiskList'
import RiskAssesmentModal from '../../../Utilities/Modals/riskModals/MainRiskModal'
import RiskListModal from '../../../Utilities/Modals/riskModals/RiskListModal'
import { CreateHubSpotModal } from '../../../Utilities/Modals/CreateHubSpotModal'
import { riskLevelValues, riskOddsValues } from '../../../Utilities/Riskvalues'
import { startEditingPlan, fetchAllPlans } from '../../planBuilder/Actions/ActionCreator'
import { getExcelReport, deleteExcelReport } from '../../../Utilities/DiagramBuilder/Actions/ActionCreator'
import ComparisonContainer from '../../comparisonTool/ComparisonContainer'
import SummaryCreationModal from './SummaryCard/SummaryCreationModal';
import SummaryCardContainer from './SummaryCard/SummaryCardContainer';
import SummaryCardInformationModal from './SummaryCard/SummaryCardInformationModal';
import { motion } from 'framer-motion'
import Timeline from './Timeline'
import AnimatedFilterToggler from '../../../Utilities/AnimatedFilterToggler/AnimatedFilterToggler'
import HelperBox from '../../../Utilities/HelperBox/HelperBox'
import BusinessSurveyListItem from './BusinessSurveyList'
import TrelloList from './Trello/TrelloList'
import TrelloCreationModal from './Trello/TrelloCreationModal'
import BarGraph from '../../../Utilities/DiagramBuilder/BarGraph'
import CreateChartModal from '../../../Utilities/DiagramBuilder/createChartModal'
import DeleteModal from '../../../Utilities/Modals/DeleteModal'
import { HubSpotContainer } from '../subComponents/HubSpotContainer'
import YTJContainer from '../subComponents/YTJ/YTJContainer';
import { Tasks, Plans, Answers, ExcelList, Projects, Contacts } from '../../../Utilities/horizontal-scroller/card-lists/Lists';
import { NotificationManager } from 'react-notifications'
import { executeRequest } from '../../../Utilities/requestHandler/dataHandler';
import DemoBlock from '../../planBuilder/Modals/previewItems/DemoBlock'
import AsyncCreatableSelect from 'react-select/async-creatable';
import { confs } from '../../../modules/config';
import { rgbToHsl } from '@amcharts/amcharts4/.internal/core/utils/Colors'
import { ContactModal } from '../../../Utilities/Modals/ContactModal'
import { ProjectDataModal } from '../../Project/ProjectDataModal'
import { ListSelectionModal } from './ListSelectionModal'
import { ClientDashboardGuide } from '../../../Utilities/GuideTour/ClientDashboardGuide'

const businessListTypes = {
    contacts: 'cards',
    forms: 'cards',
    projects: 'cards',
    clients: 'cards',
    tasks: 'cards',
    answers: 'cards',
    excels: 'cards',
    hubDeals:'cards',
    hubContacts: 'cards'
}

const INNITIAL_showList = {
    contacts: true,
    plans: true,
    projects: true,
    tasks: true,
    answers: true,
    excels: true,
    hubSpot: true,
    risks: true,
    trello: true
    
}

class ReportDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            taskFilter: '',
            riskFilter: '',
            surveyFilter: '',
            planFilter: '',
            taskModalOpen: false,
            selectedTask: null,
            riskModal: false,
            mounted: false,
            showRiskMatrice: true,
            filterRiskLevel: null,
            filterRiskOdds: null,
            squareColor: '',
            openSummaryCreation: false,
            openTagCreation: false,
            showSummaries: false,
            openSummaryInfo: false,
            sortModalIsOpen: false,
            hubSpotModalOpen: false,
            newRiskMatrice: [],
            showTimeline: false,
            activeTimelineFilters: this.getTimelineFilters(),
            panelToggled: props.panelOpen,
            demoCreationToggled: false,
            openCreateDiagram: false,
            chartLegend: null,
            demoBlockData: {
                type: 'iframe',
                title: '',
                data: {
                    input1: '',
                    input2: '',
                    input3: '',
                    input4: '',
                    url: ''
                },
                businessId: props.selectedBusinessId
            },
            trelloCreationToggled: false,
            isChartDeleteModalOpen: false,
            chartDeleteTitle: '',
            chartDeleteId: null,
            editBusinessGoal: false,
            businessGoal: 0,
            updateHub: false,
            tagOptions:[],
            modalIsOpen: false,
            projects:[],
            contactModalOpen: false,
            businessListTypes: businessListTypes,
            newProjectModalOpen: false,
            showList: INNITIAL_showList,
            listSelectionOpen: false,
            YTJInfo: null,
            settingsOpen: false,
            search: "",
        }
    }

    async componentDidMount() {

        if(this.props.panelOpen) this.props.togglePanel()
        const { selectedBusinessId, getReportDashboardForBusiness, getProjectsForBusiness, getLatestAnswers, getTasks, getRiskEvaluations, getCompanyScore, userInfo, getReportTree, selectBusiness, getSummaryCards, getSentSurveysForBusiness, getPlansForBusiness, getExcelReports } = this.props
        
        if (selectedBusinessId) {
            getReportDashboardForBusiness({ businessId: selectedBusinessId })
            //getLatestAnswers({ businessId: selectedBusinessId })
            //getTasks({ businessId: selectedBusinessId })
            getRiskEvaluations({ businessId: selectedBusinessId })
            getCompanyScore({ businessId: selectedBusinessId })
            getSummaryCards({ businessId: selectedBusinessId })
            // getPlansForBusiness({ businessId: selectedBusinessId })
            getExcelReports({ businessId: selectedBusinessId })
            getProjectsForBusiness({ businessId: selectedBusinessId })

        } else if (this.props.location.selectedBusinessId) {
            getReportDashboardForBusiness({ businessId: this.props.location.selectedBusinessId })
            //getLatestAnswers({ businessId: this.props.location.selectedBusinessId })
            //getTasks({ businessId: this.props.location.selectedBusinessId })
            getRiskEvaluations({ businessId: selectedBusinessId })
            getCompanyScore({ businessId: selectedBusinessId })
            getReportTree({ businessId: this.props.location.selectedBusinessId })
            getSummaryCards({ businessId: selectedBusinessId })
            // getPlansForBusiness({ businessId: selectedBusinessId })
            getExcelReports({ businessId: selectedBusinessId })
            getProjectsForBusiness({ businessId: selectedBusinessId })
        } else if (userInfo && userInfo.userType === 'business' && userInfo.businessId) {
            getReportDashboardForBusiness({ businessId: userInfo.businessId })
            getLatestAnswers({ businessId: userInfo.businessId })
            //getTasks({ businessId: userInfo.businessId })
            getRiskEvaluations({ businessId: userInfo.businessId })
            getCompanyScore({ businessId: userInfo.businessId })
            getReportTree({ businessId: userInfo.businessId })
            getSummaryCards({ businessId: userInfo.businessId })
            getSentSurveysForBusiness({ businessId: userInfo.businessId })
            // getPlansForBusiness({ businessId: userInfo.businessId })
            getProjectsForBusiness({ businessId: userInfo.businessId })
            //getExcelReports({ businessId: userInfo.businessId })
            // selectBusiness({ businessId: userInfo.businessId, businessName: userInfo.businessName })
        }

        setTimeout(() => { this.setState({ mounted: true, showSummaries: true }) }, 1200)

        const showTimeline = window.localStorage.getItem("showTimeline")

        if (window.localStorage.getItem('showTimeline') !== null && showTimeline === 'mickey') {
            this.setState({ showTimeline: true })
        } else {
            window.localStorage.setItem('showTimeline', false)
        }
        this.getStoredTypes()
        this.getStoredListSettings() 
    }

    componentWillUnmount() {
        //Clear company
        this.props.clearBusiness();
        this.props.clearDashboard();
        this.setState({ showSummaries: false });
    }

    componentDidUpdate(prevProps) {
       
        if (prevProps.reportDashboardData !== this.props.reportDashboardData && this.props.reportDashboardData !== null && this.props.reportDashboardData.business.businessName !== 'Ei kohdeyritystä') {
            if(this.props.history && this.props.history.location && this.props.history.location.state ){
                if(this.props.history.location.state.projectId){
    
                    let state = this.props.history.location.state
                    this.props.addBreadcrumbs({ ...state.crumbs, projectId: state.projectId})
                    this.setState({projectId: state.projectId})
                }
            } 
            this.props.addBreadcrumbs({ to: 'raportit/yritys', label: this.props.reportDashboardData.business.businessName, businessId: this.props.reportDashboardData.business.businessId });
        }
        

        if (this.props.newRiskMatrice !== undefined && prevProps.newRiskMatrice !== this.props.newRiskMatrice) {
            // reorder the matrice to match the order amchart wants it to be
            const reOrderedRiskMatrice = []

            for (let j = 0; j < this.props.newRiskMatrice.length; j++) {
                for (let i = 0; i < this.props.newRiskMatrice.length; i++) {
                    reOrderedRiskMatrice.push(this.props.newRiskMatrice[i][j])
                }
            }

            this.setState({ newRiskMatrice: reOrderedRiskMatrice })
        }

        if (this.props.panelOpen !== this.state.panelToggled) {
            // setTimeout(() => {
                this.setState({ panelToggled: this.props.panelOpen })
            // }, 400)
        }

        
        if(this.props.selectedBusiness){
            const { businessTags } = this.props.selectedBusiness
            
            if(businessTags !== undefined) {
                if(this.state.tagOptions !== businessTags && this.state.tagOptions.length == 0){
                    this.setTagOptions(businessTags);
                }
            }
        }
    }

    updateStorage = () => {
        const types = {...this.state.businessListTypes}
        window.localStorage.setItem('businessListTypes', JSON.stringify(types)) 
    }
    handleActiveTypeChange = (id, type) => {
        this.setState({businessListTypes:{...this.state.businessListTypes, [id]: type}}, () => this.updateStorage())
      }
    getStoredTypes = () => {
        if(window.localStorage.getItem('businessListTypes') !== null){
          let types = window.localStorage.getItem('businessListTypes')
          this.setState({businessListTypes: JSON.parse(types)})
        }
      }

    setTagOptions = (tags) => {
        this.setState({tagOptions:tags})
    }
    
    goToNewPlan = (id) => {
        this.props.history.push({
            pathname:'/lomakkeet/uusi',
            state:{businessId: id}
        })
    }

    selectLatestAnswerReport = async (obj) => {
        const { getReport, addReportFilter, filterTypes, activeFilters, selectedBusinessId } = this.props
        const answerSessionId = typeof obj.answerSessionId !== 'undefined' ? obj.answerSessionId : ''

        const params = {
            reports: [
                {
                    reportId: obj.sentSurveyId
                }
            ],
            answerSessionIds: [
                answerSessionId
            ]
        }

        await getReport(params)

        if (answerSessionId !== '') {
            let filterType = JSON.parse(JSON.stringify(filterTypes.answerSession))
            filterType.answerSessionIds = [obj.answerSessionId]

            addReportFilter(filterType, activeFilters)
        }
        if (selectedBusinessId !== 'anon') {
            this.props.history.push('/raportit/yritys/raportti')
        } else {
            this.props.history.push('/raportit/kohdistamaton/raportti')
        }
    }

    setFilter = (event) => {
        const name = event.target.name;
        this.setState({ [name]: event.target.value });
    }

    toggleTaskModal = () => {
        this.setState(prevState => ({
            taskModalOpen: !prevState.taskModalOpen
        }))
    }

    onTaskSelect = (task) => {
        this.setState({ selectedTask: task, taskModalOpen: true })
    }

    updateTaskStatus = (params) => {
        this.toggleTaskModal()
        this.props.updateTask(params)
    }

    // tää sama funkkari on kopioituna dashboardilla. jos jotain muutoksia tulee nii ois hyvä muuttaa myös sinne...
    // joo samaa mieltä, ei oo paras ratkasu
    selectReportFromTask = async (obj) => {
        const { getReport, getReportTree, storeBusiness } = this.props

        const params = {
            reports: []
        }

        if (obj && obj.taskTree) {
            obj.taskTree.surveyTitles.forEach(taskSurveyObj => {
                params.reports.push({ reportId: taskSurveyObj.id })
            })

            getReport(params)

            if (obj.targetCompany && obj.targetCompany !== 'anon') {
                await getReportTree({ businessId: obj.targetCompany })
                this.props.history.push('/raportit/yritys/raportti')
            } else {
                storeBusiness({ name: 'Ei kohdeyritystä' })
                this.props.history.push('/raportit/kohdistamaton/raportti')
            }
        }
    }

    selectReportFromSummaryCard = async (surveyCard) => {
        const { getReport, getReportTree, storeBusiness } = this.props

        const params = {
            reports: [...surveyCard.reports]
        }

        if (surveyCard && surveyCard.reports) {
            getReport(params)

            if (surveyCard.businessId) {
                await getReportTree({ businessId: surveyCard.businessId })
                this.props.history.push('/raportit/yritys/raportti')
            } else {
                storeBusiness({ name: 'Ei kohdeyritystä' })
                this.props.history.push('/raportit/kohdistamaton/raportti')
            }
        }
    }

    selectReport = (sentSurveyId) => {
        const params = {
            reports: [
                {
                    reportId: sentSurveyId
                }
            ]
        }

        this.props.getReport(params)
        this.props.history.push('/raportit/yritys/raportti')
    }

    toggleMenu = (i) => {
        this.setState({ menuIndex: this.state.menuIndex === i ? -1 : i })
    }

    riskAssesmentClosed = () => {
        this.setState({ riskModal: false });
    }

    startRiskAssesment = data => {
        let obj = {
            riskModal: true,
            selectedTarget: {
                risk: data
            }
        }
        this.setState(obj);
    }

    selectReportFromRisk = async (obj) => {
        const { getReportForDashboard, getReportTree, storeBusiness } = this.props

        const params = {
            reports: []
        }

        if (obj.risk && obj.risk.riskTree) {
            obj.risk.riskTree.surveyTitles.forEach(taskSurveyObj => {
                params.reports.push({ reportId: taskSurveyObj.id })
            })

            getReportForDashboard(params)

            if (obj.risk.targetCompany && obj.risk.targetCompany !== 'anon') {
                await getReportTree({ businessId: obj.risk.targetCompany })
                this.props.history.push('/raportit/yritys/raportti')
            } else {
                storeBusiness({ name: 'Ei kohdeyritystä' })
                this.props.history.push('/raportit/kohdistamaton/raportti')
            }
        }
    }

    changeRiskView = () => {
        this.setState(prevState => ({
            showRiskMatrice: !prevState.showRiskMatrice
        }))
    }

    openRiskListModal = (riskOdds, riskLevel, color) => {
        this.setState({
            riskListOpen: true,
            filterRiskLevel: riskLevelValues[riskLevel],
            filterRiskOdds: riskOddsValues[riskOdds],
            squareColor: color
        });
    }

    closeRiskListModal = () => {
        this.setState({
            riskListOpen: false,
            filterRiskLevel: null,
            filterRiskOdds: null,
            squareColor: ''
        })
    }

    toggleCreation = () => {
        this.setState(prevState => ({
            openSummaryCreation: !prevState.openSummaryCreation
        }))
    }

    toggleCreateTags = () =>{
        this.setState(prevState =>({
            openTagCreation: !prevState.openTagCreation
        }))
    }

    toggleSummaries = () => {
        this.setState(prevState => ({
            showSummaries: !prevState.showSummaries
        }))
    }


    toggleSummaryInfo = (card = null, chartData) => {
        if (card !== null) {
            this.setState(prevState => ({
                openSummaryInfo: !prevState.openSummaryInfo,
                targetSummary: {
                    ...card,
                    chartData: chartData
                }
            }))
        } else {
            this.setState(prevState => ({
                openSummaryInfo: !prevState.openSummaryInfo,
                targetSummary: {}
            }))
        }
    }

    toggleSortModal = () => {
        this.setState(prevState => ({ sortModalIsOpen: !prevState.sortModalIsOpen }))
    }

    toggleTimeline = () => {
        const showTimeline = !this.state.showTimeline
        let storageValue = 'goofy'

        if (showTimeline) {
            storageValue = 'mickey'
        }

        window.localStorage.setItem('showTimeline', storageValue)
        this.setState({ showTimeline: showTimeline })
    }

    openReportFromTimeline = sentSurvey => {
        const { getReportForDashboard, getReportTree, selectedBusinessId } = this.props
        const params = {
            reports: []
        }

        if (sentSurvey) {
            params.reports.push({ reportId: sentSurvey._id })
            getReportForDashboard(params)

            if (selectedBusinessId !== 'anon') {
                getReportTree({ businessId: sentSurvey.businessID })
                this.props.history.push('/raportit/yritys/raportti')
            } else {
                this.props.history.push('/raportit/kohdistamaton/raportti')
            }
        }
    }

    toggleTimelineFilter = filter => {
        const activeFilters = [...this.state.activeTimelineFilters]
        const filterIndex = activeFilters.indexOf(filter)

        if (filterIndex !== -1) {
            activeFilters.splice(filterIndex, 1)
            this.setState({ activeTimelineFilters: activeFilters })
        } else {
            activeFilters.push(filter)
            this.setState({ activeTimelineFilters: activeFilters })
        }
    }

    toggleDemoblockCreation = () => {
        const { demoCreationToggled } = this.state

        let newState = {
            demoCreationToggled: !demoCreationToggled
        }

        if (demoCreationToggled) {
            newState.demoBlockData = {
                type: 'iframe',
                title: '',
                data: {
                    input1: '',
                    input2: '',
                    input3: '',
                    input4: '',
                    url: ''
                },
                businessId: this.props.selectedBusinessId
            }
        }

        this.setState(newState)
    }

    setDemoblockData = (slot, value) => {
        const demoBlockData = { ...this.state.demoBlockData }

        if (slot === 'type' || slot === 'title') {
            demoBlockData[slot] = value
        } else {
            demoBlockData.data[slot] = value
        }

        this.setState({ demoBlockData: demoBlockData })
    }

    saveDemoblock = async () => {
        this.props.saveDemoblock(this.state.demoBlockData)
        this.toggleDemoblockCreation()
    }

    deleteDemoblock = data => {
        this.props.deleteDemoblock(data)
    }

    saveTags = async () =>{
        const {tagOptions} = this.state
        let data = {
            businessId:this.props.selectedBusinessId,
            tags: tagOptions.length > 0 ? [...tagOptions] : []
        }  

        this.props.saveTags(data)
        this.toggleCreateTags()
    }

    toggleTrelloBoardCreation = () => {
        this.setState(state => ({
            trelloCreationToggled: !state.trelloCreationToggled
        }))
    }

    deleteTrelloList = list => {
        const params = {
            listId: list.id,
            businessId: this.props.selectedBusinessId
        }

        this.props.deleteTrelloList(params)
    }

    togglePlan = (planId) => {
        this.props.fetchAllPlans();
        this.props.onPlanEdit(planId);
        this.props.history.push('/lomakkeet/luonti')
    }

    getTimelineFilters = () => {
        const { modulesInUse } = this.props
        const timelineFilters = []

        if (modulesInUse.surveyManagement) { timelineFilters.push('Kartoitukset') }
        if (modulesInUse.taskManagement) { timelineFilters.push('Tarkistuspisteet') }
        if (modulesInUse.riskManagement) { timelineFilters.push('Riskit') }
        if (modulesInUse.planManagement) { timelineFilters.push('Lomakkeet') }
        if (modulesInUse.projectManagement ){ timelineFilters.push('Projektit') } 

        return timelineFilters
    }

    toggleCreateDiagram = () => {
        this.setState({ openCreateDiagram: !this.state.openCreateDiagram })
    }

    deleteChart = () => {
        this.props.deleteExcelReport({ reportId: this.state.chartDeleteId })
        this.toggleDeleteChart();
    }

    toggleDeleteChart = (obj) => {
        if (this.state.isChartDeleteModalOpen) {
            this.setState({ isChartDeleteModalOpen: false, chartDeleteTitle: '', chartDeleteId: null })
        } else {
            this.setState({ isChartDeleteModalOpen: true, chartDeleteTitle: obj.name, chartDeleteId: obj.id })
        }
    }

    toggleBusinessGoal = () => {
        if (this.state.editBusinessGoal) {
            this.setState({ editBusinessGoal: false, businessGoal: 0 })
        } else {
            let rdd = this.props.reportDashboardData
            if (rdd && rdd.business && rdd.business.goal) {
                this.setState({ businessGoal: rdd.business.goal, editBusinessGoal: true })
            } else {
                this.setState({ editBusinessGoal: true })
            }
        }
    }

    handleGoalInput = (e) => {
        this.setState({ businessGoal: e.target.value })
    }

    handleGoalInputSave = async () => {
        let payload = {
            businessId: this.props.selectedBusinessId,
            goalSum: this.state.businessGoal
        }

        await this.props.updateBusinessGoal(payload);
        this.toggleBusinessGoal();
    }

    newProject = () => {
        this.setState({ newProjectModalOpen: true })
    }

    closeNewProjectModal = () => {
        this.setState({ newProjectModalOpen: false })
    }

    goToProject = (projectId) => {
        this.props.history.push({
            pathname:'/projektit/hallitse',
            state: {
                projectId:projectId
            }
        })
    }

    toggleContactModal = () => {
        this.setState({contactModalOpen : !this.state.contactModalOpen})
    }

    handleListSelection = (params) => {
        const {id, value} = params
        this.setState({
          showList:{
            ...this.state.showList, 
            [id]:value
          }
        })
    }

    updateListSelectionStorage = () => {
        const selection = {...this.state.showList}
        window.localStorage.setItem('clientListSelection', JSON.stringify(selection)) 
    }
    
    getStoredListSettings = () => {
        if(window.localStorage.getItem('clientListSelection') !== null){
            let selection = window.localStorage.getItem('clientListSelection')
            this.setState({showList: JSON.parse(selection)})
        }
    }

    toggleListSelection = () => {
        this.setState({listSelectionOpen : !this.state.listSelectionOpen})
    }

    setYTJInfo = (props) => {
        this.setState({YTJInfo: props})
    }

    toggleSettings = () => {
        this.setState({ settingsOpen: !this.state.settingsOpen})
    }

    render() {
        const { reportDashboardData, latestAnswers, tasks, risks, userInfo, panelOpen, sentSurveys, plans, modulesInUse, excels, location, selectedBusinessId, selectedBusiness, projects } = this.props
        const { taskModalOpen, selectedTask, modalIsOpen, showList } = this.state
        const timelineFilterButtonStyle = { position: 'absolute', right: 40, top: 25 }
        const isBusiness = userInfo.userType === 'business' ? true : false
        const surveysForTimeline = reportDashboardData ? reportDashboardData.sentSurveys : []
        const tasksForTimeline = tasks ? tasks : []
        const risksForTimeline = risks ? risks : []
        return (
            <Container fluid style={{ paddingBottom: 15, position: 'relative' }}>
                
                {modulesInUse.riskManagement && (
                    <>
                        <RiskListModal isOpen={this.state.riskListOpen} riskModal={this.state.riskModal} modifyRisk={this.startRiskAssesment} riskOdds={this.state.filterRiskOdds} riskLevel={this.state.filterRiskLevel} riskList={risks} closed={this.closeRiskListModal} squareColor={this.state.squareColor} />
                        <RiskAssesmentModal riskModal={this.state.riskModal} selectReport={this.selectReportFromRisk} chosenTarget={this.state.selectedTarget} closed={this.riskAssesmentClosed} userInfo={this.props.userInfo} activeCompany={this.props.selectedBusinessId} modifyFromDashboard={true} />
                    </>
                )}

                {modulesInUse.surveyManagement && (
                    <>
                        <SummaryCreationModal company={this.props.selectedBusinessId} startCard={this.state.openSummaryCreation} toggle={this.toggleCreation} setSummaryCards={this.props.setSummaryCards} />
                        <SummaryCardInformationModal startCard={this.state.openSummaryInfo} targetSurveyCard={this.state.targetSummary} toggle={this.toggleSummaryInfo} getSummaryCards={this.props.getSummaryCards} companyPayload={{ businessId: this.props.selectedBusinessId }} />
                    </>
                )}
                <ContactModal isOpen={this.state.contactModalOpen} toggleModal={this.toggleContactModal} business={this.props.selectedBusiness} allowRemove={true}/>
                <DemoCreationModal isOpen={this.state.demoCreationToggled} toggle={this.toggleDemoblockCreation} setDemoblockData={this.setDemoblockData} demoBlockData={this.state.demoBlockData} saveDemoblock={this.saveDemoblock} />
                <TrelloCreationModal isOpen={this.state.trelloCreationToggled} toggle={this.toggleTrelloBoardCreation} businessId={this.props.selectedBusinessId} />
                <CreateChartModal toggle={this.toggleCreateDiagram} isOpen={this.state.openCreateDiagram} businessId={this.props.selectedBusinessId} getExcelReports={this.props.getExcelReports} />
                <CreateTagModal toggle={this.toggleCreateTags} isOpen={this.state.openTagCreation} businessId={this.props.selectedBusinessId} tagOptions={this.state.tagOptions} setTagOptions={this.setTagOptions} saveTags={this.saveTags}/>
                {userInfo.userType === 'enterprise' && <ReportSortModal toggle={this.toggleSortModal} isOpen={this.state.sortModalIsOpen} reportDashboardData={reportDashboardData} userInfo={userInfo} />}
                {(userInfo.userType === 'enterprise' && this.state.hubSpotModalOpen) && <CreateHubSpotModal
                    isOpen={this.state.hubSpotModalOpen}
                    toggle={() => this.setState(prevState => ({ hubSpotModalOpen: !prevState.hubSpotModalOpen }))}
                    business={this.props.selectedBusiness} />
                }
                <ListSelectionModal 
                    isOpen={this.state.listSelectionOpen} 
                    toggle={this.toggleListSelection} 
                    modulesInUse={modulesInUse} 
                    listSelection={this.state.showList}
                    handleListSelection={this.handleListSelection} 
                    updateSelection={this.updateListSelectionStorage}
                    selectedBusiness={selectedBusiness}
                />
                {(userInfo.userType === 'enterprise' || this.props.summaryCards.length > 0) && (
                    <BusinessMenu
                        summaryCards={this.props.summaryCards}
                        selectedBusiness={selectedBusiness}
                        toggleSummaries={this.toggleSummaries}
                        toggleCreation={this.toggleCreation}
                        showSummaries={this.state.showSummaries}
                        toggleSort={this.toggleSortModal}
                        userType={userInfo.userType}
                        toggleTimeline={this.toggleTimeline}
                        showTimeline={this.state.showTimeline}
                        toggleDemoblockCreation={this.toggleDemoblockCreation}
                        toggleTrelloBoardCreation={this.toggleTrelloBoardCreation}
                        modulesInUse={modulesInUse}
                        createDiagram={this.toggleCreateDiagram}
                        createHubSpot={() => this.setState(prevState => ({ hubSpotModalOpen: !prevState.hubSpotModalOpen }))}
                        toggleCreateTags={this.toggleCreateTags}
                        toggleContactModal={this.toggleContactModal}
                        toggleListSelectionModal={this.toggleListSelection}
                        toggleSettings={this.state.settingsOpen}
                    />
                )}
                
                <DeleteModal isOpen={this.state.isChartDeleteModalOpen} title={this.state.chartDeleteTitle} text="Oletko varma että haluat poistaa diagrammin? Toimintoa ei voida peruuttaa!" confirm={this.deleteChart} toggle={this.toggleDeleteChart} />

                <Row>
                    <BusinessSummary
                        mounted={this.state.mounted}
                        risks={risks}
                        selectedBusiness={selectedBusiness}
                        companyScore={this.props.companyScore}
                        business={this.props.reportDashboardData && this.props.reportDashboardData.business ? this.props.reportDashboardData.business : null}
                        modulesInUse={modulesInUse}
                        editBusinessGoal={this.state.editBusinessGoal}
                        businessGoal={this.state.businessGoal}
                        toggleInput={this.toggleBusinessGoal}
                        handleInput={this.handleGoalInput}
                        handleInputSave={this.handleGoalInputSave}
                        businessGoals={this.props.businessGoals}
                        tags={this.state.tagOptions}
                        YTJInfo={this.state.YTJInfo}
                    />
                </Row>

                {this.state.showTimeline &&
                    <Row>
                        <Col>
                            <div className="dashboard-medium-box timeline-box" style={{ height: '400px' }}>
                                <div className="box-header bigger-text">
                                    <p>
                                        Aikajana
                                    </p>
                                    <AnimatedFilterToggler filters={this.getTimelineFilters()} selectedFilters={this.state.activeTimelineFilters} selectFilter={this.toggleTimelineFilter} buttonStyle={timelineFilterButtonStyle} />
                                </div>
                                {(this.state.mounted && this.state.panelToggled === panelOpen) ?
                                    <Timeline risks={risksForTimeline} tasks={tasksForTimeline} surveys={surveysForTimeline} plans={plans} projects={projects} onProjectClick={this.goToProject} onRiskClick={this.startRiskAssesment} onTaskClick={this.onTaskSelect} onPlanClick={this.togglePlan} onSurveyClick={this.openReportFromTimeline} mounted={this.state.mounted} filters={this.state.activeTimelineFilters} panelOpen={panelOpen} />
                                    :
                                    <MegaLoaderSpinner />
                                }

                            </div>
                        </Col>
                    </Row>
                }
                <Row style={{ paddingLeft: '2em', marginTop: '1em'}}>
                    <Col xs="12">
                        <Input type="search" className="search-bar-open" placeholder={"Hae lomakkeita, projekteja yms.."} value={this.state.search} onChange={(event) => this.setState({ search: event.target.value })} />
                    </Col>
                </Row>
                <Row>
                    {(modulesInUse.surveyManagement && this.state.showSummaries) &&
                        this.props.summaryCards.map((item, index) =>
                            <SummaryCardContainer key={item._id} panelOpen={panelOpen} index={index} getReport={this.selectReportFromSummaryCard} surveyCardInfo={item} payload={{ jwtToken: window.sessionStorage.getItem("token"), businessId: this.props.selectedBusinessId }} openInfo={this.toggleSummaryInfo} />
                        )}
                </Row>
                <Row>
                    {modulesInUse.contactManagement && this.props.selectedBusinessId && showList.contacts &&
                        <Contacts businessId={this.props.selectedBusinessId} showAddNewOnList={true} newContact={this.toggleContactModal} activeType={this.state.businessListTypes.contacts} toggleType={this.handleActiveTypeChange} search={this.state.search}/>
                    }
                    {(modulesInUse.planManagement && userInfo.userType !== 'business') && showList.plans &&
                        <Plans goToPlan={this.togglePlan} businessPage userInfo={userInfo} location={location} selectedBusinessId={selectedBusinessId} newPlan={this.goToNewPlan} activeType={this.state.businessListTypes.forms} toggleType={this.handleActiveTypeChange} showAddNewOnList={true} search={this.state.search} />
                    }

                    {modulesInUse.projectManagement && showList.projects &&
                        <>
                            <ProjectDataModal
                                isOpen={this.state.newProjectModalOpen}
                                closeModal={this.closeNewProjectModal}
                                history={this.props.history}
                                targetBusiness={selectedBusinessId}
                            />
                            <Projects newProject={this.newProject} businessId={selectedBusinessId} history={this.props.history} activeType={this.state.businessListTypes.projects} toggleType={this.handleActiveTypeChange} showAddNewOnList={true} search={this.state.search} modulesInUse={modulesInUse}/>
                        </>
                    }

                    {modulesInUse.taskManagement && showList.tasks && (
                        <Tasks userInfo={userInfo} location={location} selectedBusinessId={selectedBusinessId} openTaskModal={this.onTaskSelect} selectReport={this.selectReportFromTask}  activeType={this.state.businessListTypes.tasks} toggleType={this.handleActiveTypeChange} search={this.state.search}/>
                    )}


                    {modulesInUse.surveyManagement && showList.answers && (
                        <Answers userInfo={userInfo} location={location} selectedBusinessId={selectedBusinessId} goToReport={this.selectLatestAnswerReport}  activeType={this.state.businessListTypes.answers} toggleType={this.handleActiveTypeChange} search={this.state.search}/>
                    )}
                    
                    {showList.excels && <ExcelList selectedBusinessId={selectedBusinessId} location={location} activeType={this.state.businessListTypes.excels} toggleType={this.handleActiveTypeChange} />}

                    {selectedBusiness && (
                        selectedBusiness.hubSpot && showList.hubSpot && 
                        <HubSpotContainer hubSpot={selectedBusiness.hubSpot} updateBusinessValues={this.props.updateBusinessValues} activeType={this.state.businessListTypes} toggleType={this.handleActiveTypeChange} business={{businessName: selectedBusiness.businessName, id: selectedBusinessId}} updateContacts={this.updateContacts}/>
                    )}
                    {modulesInUse.riskManagement && showList.risks && (
                        reportDashboardData && risks ?
                            <RiskList mounted={this.state.mounted} risks={risks} onRiskSelect={this.onRiskSelect} filter={this.state.riskFilter} setFilter={this.setFilter} modifyRisk={this.startRiskAssesment}
                                showRiskMatrice={this.state.showRiskMatrice} changeView={this.changeRiskView} riskMatriceResults={this.props.riskMatriceResults} riskMatrice={this.props.riskMatrice}
                                openRiskListModal={this.openRiskListModal} newRiskMatrice={this.state.newRiskMatrice} search={this.state.search}/>
                            :
                            <EmptyBox title="Riskiarviot" size="6" />
                    )}

                    {userInfo.userType === 'business' &&
                        <>
                            {sentSurveys ?
                                <Col md={{ size: 12 }} xl={{ size: 6 }}>
                                    <div className="dashboard-medium-box">
                                        <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                                            <p>Aktivoidut kartoitukset</p>
                                            <div style={{ marginLeft: '0.5em' }}>
                                                <form id="search-bar">
                                                    <input type="search" className="search-bar-input" placeholder="Etsi kartoitusta" name="surveyFilter" onChange={(event) => this.setFilter(event)} />
                                                </form>
                                            </div>
                                        </div>
                                        <Scrollbars
                                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                            style={{ width: "100%", height: '500px', zIndex: 90 }}
                                            className="custom-scrollbars"
                                        >
                                            {sentSurveys.length > 0 ? sentSurveys.map((survey, i) => {
                                                if (survey.name && survey.name.toLowerCase().includes(this.state.surveyFilter.toLowerCase())) {
                                                    return <BusinessSurveyListItem key={i} index={i} menuIndex={this.state.menuIndex} toggleMenu={this.toggleMenu} sentSurvey={survey} selectReport={this.selectReport} />
                                                }
                                            })
                                                :
                                                <HelperBox text="Ei aktivoituja kartoituksia" onlyText={true} />
                                            }
                                        </Scrollbars>
                                    </div>
                                </Col>
                                :
                                <EmptyBox title="Lähetetyt kartoitukset" size="6" />
                            }
                        </>
                    }

                    {userInfo.userType === 'business' &&
                        <Col md={{ size: 12 }} xl={{ size: 6 }}>
                            <ComparisonContainer fromBusiness={true} />
                        </Col>
                    }

                    {(reportDashboardData && reportDashboardData.demoBlocks) ? (
                        reportDashboardData.demoBlocks.map((block, index) => {
                            return <Col md={{ size: 12 }} xl={{ size: 6 }} key={index}><DemoBlock dashboardData={block} onDeleteClick={this.deleteDemoblock} dashboard={true} /></Col>
                        })
                    ) : (
                            null
                        )}

                    {(reportDashboardData && reportDashboardData.trelloLists && showList.trello) ? (
                        reportDashboardData.trelloLists.map((list, index) => {
                            return <TrelloList key={index} list={list.listData} cards={list.cardData} businessId={this.props.selectedBusinessId} onDeleteClick={this.deleteTrelloList} />
                        })
                    ) : (
                            null
                        )}

                    {(reportDashboardData && excels) && (
                        excels.map((excel, i) => {
                            return (<Col key={i} md={{ size: 12 }} xl={{ size: 6 }}>
                                <div className="dashboard-medium-box">
                                    <div className="box-header bigger-text" style={{ display: 'flex', alignItems: 'center' }}>
                                        <p>{excel.name}</p>
                                        <i className="far fa-chart-bar" onClick={() => this.setState({ chartLegend: this.state.chartLegend === i ? null : i })} style={{ marginRight: '1em', marginLeft: 'auto', cursor: 'pointer' }}></i>
                                        <i className="fas fa-trash-alt" onClick={() => this.toggleDeleteChart(excel)} style={{ color: '#ed5652', marginRight: '1em', cursor: 'pointer' }}></i>
                                    </div>
                                    <Scrollbars
                                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                                        style={{ width: "100%", height: '500px', zIndex: 90 }}
                                        className="custom-scrollbars"
                                    >
                                        <BarGraph data={excel.data} barNames={excel.barNames} title={excel.title} i={i} toggledLegend={this.state.chartLegend === i ? true : false} yTitle={excel.yTitle} xTitle={excel.xTitle} />
                                    </Scrollbars>
                                </div>
                            </Col>)
                        })
                    )}

                    {selectedBusiness && (
                        selectedBusiness.enableYTJ &&
                        <YTJContainer businessId={selectedBusiness.businessID} selectedBusiness={selectedBusiness} setYTJInfo={this.setYTJInfo} />
                    )}
            
                </Row>
            </Container>
        )
    }
}

const EmptyBox = (props) => {
    return (
        <Col md={{ size: 12 }} xl={props.size} >
            <div className="dashboard-medium-box">
                <div className="box-header bigger-text">
                    <p>{props.title}</p>
                </div>
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height: '500px', marginTop: '0.5rem' }}
                >
                    <MegaLoaderSpinner />
                </Scrollbars>
            </div>
        </Col>
    )
}

const MegaLoaderSpinner = () => {
    return (
        <Row>
            <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Col>
        </Row>
    )
}

const BusinessMenu = props => {
    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch();
    const { selectedBusiness } = props

    const content = {
        open: {
            height: 'auto',
            opacity: 1,
            transition: {
                when: 'beforeChildren',
                duration: .2
            }
        },
        closed: {
            height: '0',
            opacity: 0,
            transitionEnd: {
                display: 'none'
            },
            transition: {
                delay: .2,
                duration: .2
            }
        }
    }

    const item = {
        open: {
            opacity: 1,
            // y: 0
        },
        closed: {
            opacity: 0,
            // y: '-50%'
        }
    }

    useEffect(()=>{
        if(props && props.toggleSettings && props.toggleSettings !== isOpen){
            setIsOpen(props.toggleSettings)
        }
    },[props])

    const toggleClick = () => {
        setIsOpen(!isOpen)
        if (typeof props.toggleSummaries === 'function') { props.toggleSummaries() }
    }

    const toggleTimelineClick = () => {
        setIsOpen(!isOpen)
        if (typeof props.toggleTimeline === 'function') { props.toggleTimeline() }
    }

    const creationClick = () => {
        setIsOpen(!isOpen)
        if (typeof props.toggleCreation === 'function') { props.toggleCreation() }
    }
    const contactClick = () => {
        setIsOpen(!isOpen)
        if (typeof props.toggleContactModal === 'function') { props.toggleContactModal() }
    }

    const createTagsClick = () =>{
        setIsOpen(!isOpen)
        if (typeof props.toggleCreateTags === 'function') {props.toggleCreateTags()}
    }

    const sortClick = () => {
        setIsOpen(!isOpen)
        if (typeof props.toggleSort === 'function') { props.toggleSort() }
    }

    const demoblockClick = () => {
        setIsOpen(!isOpen)
        if (typeof props.toggleDemoblockCreation === 'function') { props.toggleDemoblockCreation() }
    }

    const trelloClick = () => {
        setIsOpen(!isOpen)
        if (typeof props.toggleDemoblockCreation === 'function') { props.toggleTrelloBoardCreation() }
    }

    const createDiagram = () => {
        setIsOpen(!isOpen)
        if (typeof props.createDiagram === 'function') { props.createDiagram() }
    }

    const createHubSpot = () => {
        setIsOpen(!isOpen)
        if (typeof props.createHubSpot === 'function') { props.createHubSpot() }
    }

    const listSelection = () => {
        setIsOpen(!isOpen)
        if (typeof props.toggleListSelectionModal === 'function') { props.toggleListSelectionModal() }
    }

    const toggleYTJ = async (e) => {

        const { selectedBusiness } = props

        let toggledStatus = selectedBusiness.enableYTJ !== undefined ? !selectedBusiness.enableYTJ : true

        let payload = {
            ...selectedBusiness,
            enableYTJ: selectedBusiness.enableYTJ !== undefined ? !selectedBusiness.enableYTJ : true,
            responsible: typeof selectedBusiness.responsible === 'object' ? selectedBusiness.responsible[0] : null
        }

        const response = await executeRequest('modify/business', { targetBusiness: payload }, dispatch)

        if (!!response) {
            //refresh item in redux
            dispatch(updateBusinessValue(response));

            if (toggledStatus)
                NotificationManager.success("Lisätty YTJ Integraatio yritykselle", "", 3000);
            else
                NotificationManager.success("YTJ Integraatio poistettu käytöstä", "", 3000);
        }

        setIsOpen(!isOpen)
    }

    const updateBusinessValue = response => {
        return {
            type: 'HANDLE_YTJ',
            payload: response
        }
    }

    const openSettingsMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="business-menu" style={{ height: 'auto' }}>
            <div className='tourGuide-div' title="Opastus">
                {selectedBusiness && <ClientDashboardGuide openSettings={openSettingsMenu} hasHubSpot={selectedBusiness.hubSpot || false}/>}
            </div>
            <div  title="Asetukset">
                <i className="fas fa-cog business-menu-cog" id="reportDashboard-options-tourId" onClick={() => setIsOpen(!isOpen)}></i>
                <motion.nav className="business-menu-content" initial={'closed'} variants={content} animate={isOpen ? 'open' : 'closed'}>
                    {props.summaryCards.length > 0 && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" onClick={toggleClick}><p>{props.showSummaries ? 'Piilota seurantakortit' : 'Näytä seurantakortit'}</p></motion.div>}

                    <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" id="timeline-toggle" onClick={toggleTimelineClick}><p>{props.showTimeline ? 'Piilota aikajana' : 'Näytä aikajana'}</p></motion.div>

                    {(props.userType === 'enterprise' && props.modulesInUse.surveyManagement) && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" id="" onClick={creationClick}><p>Lisää seurantakortti</p></motion.div>}
                    {(props.userType === 'enterprise' && props.modulesInUse.surveyManagement) && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" id="edit-business-tags-id" onClick={createTagsClick}><p>Lisää tai muokkaa tageja</p></motion.div>}
                    {(props.userType === 'enterprise' && props.modulesInUse.surveyManagement) && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" onClick={sortClick} ><p>Järjestele kartoituksia</p></motion.div>}
                    {(props.userType === 'enterprise' && props.modulesInUse.contactManagement) && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" id="contact-management-tour" onClick={contactClick} ><p>Hallitse kontaktikortteja</p></motion.div>}
                    {(props.userType === 'enterprise') && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" id="select-lists-tour" onClick={listSelection} ><p>Valitse näytettävät listat</p></motion.div>}
                    {(props.userType === 'enterprise' && props.modulesInUse.surveyManagement) && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" onClick={demoblockClick} ><p>Luo esittelylaatikko</p></motion.div>}

                    {props.userType === 'enterprise' && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" id="trello-box-tour" onClick={trelloClick} ><p>Luo Trello-taulu</p></motion.div>}

                    {props.userType === 'enterprise' && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" onClick={createDiagram} ><p>Lataa excel</p></motion.div>}
                    {props.userType === 'enterprise' && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" id="hubSpot-tour" onClick={createHubSpot} ><p>HubSpot-API</p></motion.div>}
                    {props.userType === 'enterprise' && <motion.div variants={item} animate={isOpen ? 'open' : 'closed'} className="business-menu-item" id='YTJ-tour' onClick={toggleYTJ} >
                        <p>{selectedBusiness && !selectedBusiness.enableYTJ ? "YTJ-API Käyttöön" : "YTJ-API Pois"}</p>
                    </motion.div>}

                </motion.nav>
                
            </div>
        </div>
    )
}

const ReportSortModal = props => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size='xl'>
            <ModalBody>
                {props.reportDashboardData && props.reportDashboardData.business ?
                    <ModifySurveyTree userInfo={props.userInfo} />
                    :
                    <EmptyBox title="Raporttien lajittelu" size="12" />
                }
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={props.toggle}>Sulje</a>
            </ModalFooter>
        </Modal>
    )
}

const DemoCreationModal = props => {
    const { demoBlockData } = props

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size='xl'>
            <ModalBody>

                <Row style={{ marginBottom: '2em' }}>
                    <Col style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {/*<FormGroup check>
                            <Label check>
                                <Input defaultChecked={true} type="radio" name="radio1" value="list" onChange={(e) => props.setDemoblockData('type', e.target.value)} />{' '}
                                Lista
                            </Label>  
                        </FormGroup>*/}
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" defaultChecked={true} name="radio1" value="iframe" onChange={(e) => props.setDemoblockData('type', e.target.value)} />{' '}
                                Iframe
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '1em' }}>
                    <Col>
                        <Label>Laatikon otsikko</Label>
                        <Input type="text" name="title" onChange={(e) => props.setDemoblockData(e.target.name, e.target.value)}></Input>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Iframen urli</Label>
                        <Input key="5" value={demoBlockData.data.url} placeholder={demoBlockData.data.url} name="url" type="text" onChange={(e) => props.setDemoblockData(e.target.name, e.target.value)}></Input>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={props.toggle}>Sulje</a>
                <Button onClick={props.saveDemoblock}>Tallenna</Button>
            </ModalFooter>
        </Modal>
    )
}

const CreateTagModal = props => {
    const {toggle,isOpen, businessId, tagOptions, setTagOptions} = props

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='xl'>
            <ModalBody>
                <Row style={{ marginBottom: '2em' }}>
                    <Col style={{ display: 'flex', justifyContent: 'space-around' }}>                       
                        <FormGroup check>
                            <Label check>Tagien hallinta</Label>
                        </FormGroup>
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection:"column"}}>
                    <label style={{fontSize:"0.85em"}}>Lisää tai poista tageja</label>
                    <TagList tagOptions={tagOptions} setTagOptions={setTagOptions}/>
                </div>
            </ModalBody>
            <ModalFooter>
                <a className="go-back-link" style={{marginLeft: 0, marginRight: 'auto'}} onClick={toggle}>Sulje</a>
                <Button onClick={props.saveTags}>Tallenna</Button>
            </ModalFooter>
        </Modal>
    )
}

const TagList = props => {
    async function promiseOptions(inputValue) {
        let params = {
            data: inputValue,
            jwtToken: window.sessionStorage.getItem("token")
        }
        const data = fetch(confs.url + 'tagSearch', {
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => response.json())

        let tags = await data;
        return tags
    };

    function NoOptionsMessage(props) {
        if (props.options.length) return null;
        return <div {...props.innerProps} style={{padding: 5}}>Voit hakea tai lisätä tageja kirjoittamalla</div>;
      }

    const components = {
        NoOptionsMessage
    }

    function formatCreateLabelCreator(value) {
        return 'Lisää tagi: "' + value + '"'
    }
    
    return (
        <div>
            <AsyncCreatableSelect 
                loadOptions={promiseOptions} 
                components={components} 
                isMulti 
                value={props.tagOptions} 
                onChange={selectedTags => props.setTagOptions(selectedTags)}
                placeholder=""
                className='react-select-container'
                classNamePrefix="react-select"
                formatCreateLabel={formatCreateLabelCreator} 
            />
        </div>
    )
}

const DemoBlockOld = props => {
    const { data, onDeleteClick } = props

    return (
        <Col md={{ size: 12 }} xl={{ size: 6 }} >
            <div className="dashboard-medium-box">
                <div className="box-header bigger-text">
                    <p>
                        {data.title}
                        <i onClick={() => onDeleteClick(data)} className="fas fa-trash-alt" style={{ color: '#ed5652', float: 'right', marginRight: '1em', marginTop: '.3em', cursor: 'pointer' }}></i>
                    </p>
                </div>
                <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                    style={{ width: "100%", height: '500px' }}
                >

                    {data.type === 'list' ? (
                        [...Array(10).keys()].map(number => {

                            let oddEvenClass = number % 2 === 0 ? 'even-row' : 'odd-row'

                            return (
                                <div key={number} className={oddEvenClass} style={{ padding: '1em' }}>
                                    <Row>
                                        {Object.keys(data.data).map((key, index) => {
                                            if (data.data[key] !== '') {
                                                return <Col key={index}>{data.data[key]}</Col>
                                            }
                                        })}

                                    </Row>
                                </div>
                            )
                        })
                    ) : (
                            <iframe src={data.data.url} frameBorder="0" style={{ height: '100%', width: '100%' }} ></iframe>
                        )}
                </Scrollbars>
            </div>
        </Col>
    )
}

const mapStateToProps = state => {
    return {
        selectedBusinessId: state.reports.selectedBusinessId,
        businessGoals: state.reports.businessGoals,
        selectedBusiness: state.business.business,
        reportDashboardData: state.reports.reportDashboardData,
        tasks: state.sender.tasksForDashboard,
        latestAnswers: state.reports.latestAnswers,
        filterTypes: state.reports.filterTypes,
        activeFilters: state.reports.activeFilters,
        risks: state.sender.risksForDashboard,
        companyScore: state.sender.companyScore,
        riskMatrice: state.sender.riskMatrice,
        riskMatriceResults: state.sender.riskMatriceResults,
        userInfo: state.authentication,
        summaryCards: state.reports.surveySummaryCards,
        newRiskMatrice: state.sender.newRiskMatrice,
        sentSurveys: state.reports.businessSentSurveys,
        plans: state.reports.businessPlans,
        modulesInUse: state.authentication.modulesInUse,
        excels: state.excel.businessExcels,
        projects: state.reports.projects

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReportDashboardForBusiness: (params) => dispatch(ActionCreator.getReportDashboardForBusiness(params)),
        getLatestAnswers: (params) => dispatch(ActionCreator.getLatestAnswers(params)),
        getReport: (params) => dispatch(ActionCreator.getReportForDashboard(params)),
        addReportFilter: (params) => dispatch(ActionCreator.addFilter(params)),
        getReportForDashboard: (payload) => dispatch(ActionCreator.getReportForDashboard(payload)),
        addBreadcrumbs: (params) => dispatch(ActionCreator.addBreadcrumbs(params)),
        getReportTree: (params) => dispatch(ActionCreator.getBusinessSurveyTree(params)),
        updateTask: (payload) => dispatch(ActionCreator.saveTaskFromDashboard(payload)),
        getTasks: (payload) => dispatch(ActionCreator.getTasksForDashboard(payload)),
        storeBusiness: (params) => dispatch(ActionCreator.storeBusiness(params)),
        getRiskEvaluations: (params) => dispatch(ActionCreator.fetchRisks(params)),
        getCompanyScore: (params) => dispatch(ActionCreator.getCompanyScore(params)),
        selectBusiness: (params) => dispatch(ActionCreator.selectBusinessForReport(params)),
        setSummaryCards: (params) => dispatch(ActionCreator.setSummaryCards(params)),
        getSummaryCards: (params) => dispatch(ActionCreator.fetchSummaryCards(params)),
        getSentSurveysForBusiness: (params) => dispatch(ActionCreator.getSentSurveysForBusiness(params)),
        getProjectsForBusiness: (params) => dispatch(ActionCreator.getProjectsForBusiness(params)),
        saveDemoblock: (params) => dispatch(ActionCreator.saveDemoblock(params)),
        deleteDemoblock: (params) => dispatch(ActionCreator.deleteDemoblock(params)),
        deleteTrelloList: (params) => dispatch(ActionCreator.deleteTrelloList(params)),
        getPlansForBusiness: (params) => dispatch(ActionCreator.getPlansForBusiness(params)),
        onPlanEdit: (planId) => dispatch(startEditingPlan(planId)),
        fetchAllPlans: () => dispatch(fetchAllPlans(false)),
        getExcelReports: (payload) => dispatch(getExcelReport(payload)),
        deleteExcelReport: (payload) => dispatch(deleteExcelReport(payload)),
        updateBusinessGoal: (payload) => dispatch(ActionCreator.updateBusinessGoalSum(payload)),
        updateBusinessValues: (payload) => dispatch(ActionCreator.updateBusinessValues(payload)),
        clearBusiness: () => dispatch(ActionCreator.clearBusiness()),
        clearDashboard: () => dispatch(ActionCreator.clearReportDashboard()),
        setHubspotClosedValue: (value) => dispatch(ActionCreator.setHubSpotClosedValue(value)),
        saveTags:(payload) => dispatch(ActionCreator.saveTags(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDashboard)
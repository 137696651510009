import { confs } from '../../../../modules/config';
import * as ActionTypes from './ActionTypes';

import { authFailed } from '../../../../store/index';
import { NotificationManager } from 'react-notifications';

import moment from 'moment'

const sentSurveys = data => {
    return {
        type: ActionTypes.STORE_SURVEYS,
        payload: data
    }
};

const setCompanies = data => {
    return {
        type: ActionTypes.STORE_COMPANIES,
        payload: data
    }
};

const storeAvailableSurveys = data => {
    return {
        type: ActionTypes.STORE_AVAILABLE_SURVEYS,
        payload: data
    }
}

export const addSurveyToList = params => {
    return {
        type: ActionTypes.ADD_SENT_SURVEY,
        payload: params
    }
};

export const autoSelectSurvey = params => {
    return {
        type: ActionTypes.AUTO_SELECT_SURVEY,
        payload: params
    };
}

export const updateSentSurveysForDashboard = (params) => {
    return {
        type: ActionTypes.UPDATE_SENT_SURVEYS_FOR_DASHBOARD,
        payload: params
    }
}

export const updateSelectedSentSurvey = (params) => {
    return {
        type: ActionTypes.UPDATE_SELECTED_SENT_SURVEY,
        payload: params
    }
}

export const getSentSurveysForDashboard = () => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'get/sentSurveysForDashboard', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    dispatch(updateSentSurveysForDashboard(responseJson));
                }
            }).catch(error => {
                NotificationManager.error("Hakiessa tapahtui virhe", "Virhe", 2000);
            })
    }
}

export const onSurveySend = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'sender/send/survey', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    if( typeof responseJson.success === 'undefined' || responseJson.success ) {
                        if ( responseJson.status === "sent" ) {
                            NotificationManager.success( "Koodilla " + responseJson.code, "Kartoitus on luotu", 4000 )
                        }
                        dispatch( addSurveyToList( responseJson.sentSurvey ) )
                        let autoSelect = responseJson.autoSelect
                        dispatch( getGroupedSurveys() )
                        dispatch( autoSelectSurvey( autoSelect ) );
                    } else {
                        // most likely tried to send survey somehow even when has been restricted by licence
                        NotificationManager.error( "Kartoitusta ei voitu lähettää", "Virhe", 2000 );        
                    }
                }
            }).catch(error => {
                NotificationManager.error("Kartoitusta ei voitu lähettää", "Virhe", 2000);
            })
    }
}

export const fetchLinkedCompanies = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'sender/get/getCompanies', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    dispatch(setCompanies(responseJson.businessList));
                }
            }).catch(error => {
                NotificationManager.error("Yrityshaussa tapahtui virhe", "Virhe", 2000);
            })
    }
};

const deletedSurvey = params => {
    return {
        type: ActionTypes.DELETE_SENT_SURVEY_FROM_LIST,
        payload: params
    };
}

const modifiedSurvey = params => {
    return {
        type: ActionTypes.SET_MODIFIED_SENT_SURVEY,
        payload: params
    };
}

const setBackgroundList = params => {
    return {
        type: ActionTypes.SET_BACKGROUND_LIST,
        payload: params
    }
}

export const endSurvey = (params, type) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        surveyData: params,
        type: type,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'sender/modify/survey/cancel', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    if (responseJson.status !== undefined && responseJson.status === "deleted") {
                        NotificationManager.success("Kartoitus on poistettu onnistuneesti", "", 3000);
                        dispatch(deletedSurvey(responseJson.deletedSurvey));
                    } else if (responseJson.status !== undefined && responseJson.status === "cancelled") {
                        NotificationManager.success("Kartoitus on päätetty onnistuneesti", "", 3000);
                        dispatch(modifiedSurvey(responseJson.modifiedSurvey));
                    } else if (responseJson.status !== undefined && responseJson.status === "activeCancelled") {
                        NotificationManager.success("Aktiivinen kartoitus päätetty onnistuneesti. Kartoitus aktivoituu uudestaan asetettujen päivämäärien mukaisesti.", "", 5000);
                        dispatch(modifiedSurvey(responseJson.modifiedSurvey));
                    }

                }
            }).catch((error) => {
                NotificationManager.error("Valitettavasti pyyntöä ei voitu toteuttaa", "Virhe", 3000);
            })
    }
}
export const getAllSurveys = params => {
    let jwt = window.sessionStorage.getItem("token");
    let companyTargets = []
    if (params !== undefined && params.companyTargets !== undefined && params.companyTargets.length > 0) {
        params.companyTargets.map(x => companyTargets.push(x.businessName));

        params = {
            ...params,
            companyTargets: companyTargets
        }
    }

    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'sender/get/allSentSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    dispatch(sentSurveys(responseJson));
                }
            }).catch(error => {
                NotificationManager.error("Lähetettyjen kartoituksien haussa tapahtui virhe", "Virhe", 3000);
            })
    }
}

export const getAvailableSurveys = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'sender/get/availableSurveys', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    dispatch(storeAvailableSurveys(responseJson));
                }
            }).catch(error => {
                NotificationManager.error("Lähetettävien kartoituksien haussa tapahtui virhe", "Virhe", 3000);
            })
    }
}

const groupedSurveys = data => {
    return {
        type: ActionTypes.GROUPED_SURVEYS,
        payload: data
    }
}

export const getGroupedSurveys = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'sender/get/sentSurveysForEmail', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    dispatch(groupedSurveys(responseJson));
                }
            }).catch(error => {
                NotificationManager.error("Valitettavasti pyyntöä ei voitu toteuttaa", "Virhe", 3000);
            })
    }
}

export const sendSurveyEmail = params => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'sender/send/surveyEmail', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    NotificationManager.success("Tieto kartoituksesta on lähetetty sähköpostilla", "", 3000);
                }
            }).catch(error => {
                NotificationManager.error("Valitettavasti pyyntöä ei voitu toteuttaa", "Virhe", 3000);
            })
    }
}


export const modifyActiveDate = params => {
    // tell moment not to adjust dates to utc when serializing to json, otherwise dates would get cuck'd :)) complaints can be directed to eetu if not working, ty
    moment.fn.toJSON = function () { return this.format(); }

    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'send/modifySurvey', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed());
                } else {
                    if (Object.keys(responseJson).length > 0) {
                        NotificationManager.success("Muokkaus onnistui!", "Ilmoitus", 3000);

                        dispatch(sentSurveys(responseJson));
                    } else {
                        NotificationManager.error("Tapahtumassa oli virhe, yritä hetken päästä uudelleen", "Virhe!", 3000);
                    }
                }

            })
    }
}

export const changeSurveyTarget = params => {
    let jwt = window.sessionStorage.getItem("token")
    let payload = {
        ...params,
        jwtToken: jwt
    }

    return dispatch => {
        fetch(confs.url + 'sender/modify/surveyTarget', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed())
                } else {
                    if (typeof responseJson.success !== undefined && responseJson.success === false) {
                        NotificationManager.error("Tapahtumassa oli virhe, yritä hetken päästä uudelleen", "Virhe!", 3000)
                    } else {
                        NotificationManager.success("Kartoituksen kohdeyritys vaihdettu", "Tallennettu", 3000)
                        dispatch(modifiedSurvey(responseJson))
                    }
                }

            })
    }
}

export const getAllBackgrounds = () => {
    let payload = {
        jwtToken: window.sessionStorage.getItem("token")
    }

    return dispatch => {
        fetch(confs.url + 'sender/get/backgroundList', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson !== undefined && responseJson.length > 0) {
                    dispatch(setBackgroundList(responseJson))
                }
            })
    }
}

export const modifyBackground = (params) => {
    let payload = {
        ...params,
        jwtToken: window.sessionStorage.getItem("token")
    }

    return dispatch => {
        fetch(confs.url + 'sender/modify/background', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(authFailed())
                } else {
                    if (typeof responseJson.success !== undefined && responseJson.success === false) {
                        NotificationManager.error("Tapahtumassa oli virhe, yritä hetken päästä uudelleen", "Virhe!", 3000)
                    } else {
                        NotificationManager.success("Kartoituksen taustakuva vaihdettu", "Tallennettu", 3000)
                        dispatch(modifiedSurvey(responseJson))
                    }
                }

            })
    }
}

export const uploadBackground = (data) => dispatch => {
    fetch(confs.url + 'sender/uploadBackground ', {
        method: 'POST',
        body: data
    })
        .then(res => res.json())
        .then(response => {
            if (typeof response.isAuthenticated !== 'undefined' && !response.isAuthenticated) {
                dispatch(authFailed())
            } else {
                if (response.success) {
                    NotificationManager.success("Taustakuva lisätty onnistuneesti", "", 2500);
                    dispatch(addToBackgroundList({url: response.url}))
                } else {
                    switch (response.error) {
                        case "IMG_SIZE":
                            NotificationManager.error('Tiedoston koko täytyy olla vähemmän kuin 14MB', 'Taustakuvan tallennus epäonnistui', 2000);
                            return;
                        case "NOT_IMG":
                            NotificationManager.error('Tiedoston täytyy olla kuvatiedosto', 'Taustakuvan tallennus epäonnistui', 2000);
                            return;
                        default:
                            NotificationManager.error('Jokin meni pieleen :(', 'Taustakuvan tallennus epäonnistui', 2000);
                            return;
                    }
                }
            }
        })
}


const addToBackgroundList = params => {
    return {
        type: ActionTypes.ADD_TO_BACKGROUND_LIST,
        payload: params
    }
}
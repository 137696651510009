import * as ActionTypes from './ActionTypes';
import * as ActionCreator from '../../index';
import { confs } from '../../../modules/config';

export const storeContacts = payload => {
    return {
        type: ActionTypes.SET_CONTACTS,
        payload: payload
    }
}

export const fetchContacts = (params) => {
    let jwt = window.sessionStorage.getItem("token");
    let payload = {
        ...params,
        jwtToken: jwt
    }
    return dispatch => {
        fetch(confs.url + 'business/getContactInfo', {
            method: 'POST',
            body: JSON.stringify(payload)
        }).then((response) => response.json())
            .then((responseJson) => {
                if (typeof responseJson.isAuthenticated !== 'undefined' && !responseJson.isAuthenticated) {
                    dispatch(ActionCreator.authFailed())
                } else {
                    if(responseJson.contacts){
                        dispatch(storeContacts(responseJson.contacts));
                    } else {
                        ActionCreator.errorHandler("Valitettavasti kontaktien päivittäminen epäonnistui", "");
                    }
                }
            }).catch(error => {
                ActionCreator.errorHandler("Valitettavasti pyyntö epäonnistui", "");
            })
    }
};
import React from 'react';
import {
    Row,
    Col
 } from 'reactstrap';

const NpsSymbol = ({npsData, card = false}) => {

    let color = ""
    let fontColor = "#404040"
    let count = npsData? (npsData.positive + npsData.passive + npsData.negative) : 0
    if(npsData !== null && npsData.score !== null && count > 0){  
        if (npsData.score >= 30){color = "rgb(181, 228, 19)"}
        else if(npsData.score >= 0 && npsData.score < 30){color ='rgb(255, 204, 0)'}
        else if (npsData.score < 0){color ='#ED5652'; fontColor = "#fff"}
    
    }
    else color ='#c8c8c8'

    return(
        <div style={card ? {display:"flex",alignItems:"center", flexDirection:"row", justifyContent:'right', paddingLeft: "0.5em", width:'100%'} :{display:"flex",alignItems:"center", flexDirection:"row", justifyContent:'right', paddingLeft: "0.5em", width:'max-content'}} title={"NPS: "+npsData.score}>
            <label htmlFor="npsScore" className="lighter-text">NPS: </label>               
            <div className="smaller-score-ball" title={"NPS: "+npsData.score} style={(npsData.score && npsData.score === -100) ? { borderColor: color, fontSize: '0.7rem', paddingTop: '0.3rem' } : { borderColor: color }}>{count > 0 ? npsData.score : "-"}</div> 
        </div>
    );
}

export default NpsSymbol;
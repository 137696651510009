import React, { useState, useRef, forwardRef } from 'react'
import {
  Row,
  Col,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap'

import TextEditor from '../../../../Utilities/TextEditor/texteditor';

import {confs} from '../../../../modules/config';
import {NotificationManager} from 'react-notifications';

import { Rnd } from "react-rnd";
import {ResizedScrollBar} from './hocs/ResizedScrollBar';
import BarGraph from '../../../../Utilities/DiagramBuilder/BarGraph';
import { useInView } from 'react-intersection-observer'
import {ModifiedContainer} from '../DataContainers'

const MegaLoaderSpinner = () => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '5rem', height: '5rem', alignSelf: 'center' }} className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}


const ExcelBlock = ({item, itemId, businessId, preview = false, editable, promptSave, toggleDrag, toggleItemVisibility, onDelete, saveItemWithoutQueue, index, layoutPreview = false}) => {
    const [ref, inView, entry] = useInView({
        threshold: 0,
    })
    const isCancelled = useRef(false);
    const [ title , setTitle] = useState(item.topicContent);
    const [ data, setData] = useState({});
    const [ showChart, setShowChart ] = useState(false);
    const [ isLoading, setIsLoading ] = useState( false )
    const [enableResize, setEnableResize] = useState(false);
    const [openMenu, toggleMenu] = useState(false);
    const [showLegend, toggleShowLegend] = useState(false);
    const [size, setSize] = useState(item.size || {height: 500, width: '100%'});

    React.useEffect(() => {
        let payload = {
            jwtToken: sessionStorage.getItem("token"),
            businessId: businessId,
            id: itemId,
            type: 'excel'
        }
        setIsLoading(true);
        fetchData(payload);

        return () => isCancelled.current = true
    }, []);

    function handleDataChanges(type, data) {
        
        if(type === "title") {
            setTitle(data);
            let itemPayload = {
                id: item.id,
                topicContent: data,
                type: item.type
            }
            promptSave(itemPayload);
        }
        //onItemChange(itemPayload);
    }

    const fetchData = async payload => {
        try {
            const response = await fetch( confs.url + 'plan/embedItemData', {
                method: 'POST',
                body: JSON.stringify( payload )
            } )

            if(response.success === undefined) {
                const responseJson = await response.json();
                if(!isCancelled.current) {
                    setData(responseJson);
                }
            }
        } catch(err) {
            NotificationManager.error("Valitettavasti lohkon tietojen haussa tapahtui virhe", "", 3000);
        }

        if(!isCancelled.current) {
            setIsLoading(false);
        }
    }

    const onSave = () => {
        let itemPayload = {
            id: item.id,
            topicContent: title,
            size: size,
            type: item.type
        }

        saveItemWithoutQueue(itemPayload);
        setEnableResize(false);
        toggleDrag(true);
    }

    function toggleResize() {

        if(!showChart)
            setShowChart(true);

        if(enableResize) {
            toggleDrag(true);
        } else {
            toggleDrag(false);
        }

        setEnableResize(!enableResize);
    }

    function handleResize(height, width) {
        setSize({height: height, width: width});
    }

    function handleDivSize(height, direction) {
        if(size.height !== height) {
            setSize({...size, height: height});
        }
    }

    const legendToggler = () => {
        toggleShowLegend(!showLegend);
    }

    const style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "#f0f0f0"
    };

    return (
        <div ref={ref}>
            <Row style={{paddingBottom: '1.75em', marginBottom: '1.75em'}} className={"plan-object " + (!item.visible && "plan-item-private")} id={item.id}>
                <Col xl="12">
                    {(editable && (!item.visible && item.visible !== undefined)) && <span style={{fontWeight: 700, color: 'red'}}>Yksityinen</span>}
                    {(!editable && !layoutPreview && item.modified !== undefined) && 
                        <ModifiedContainer modified={item.modified} visible={item.visible} modifiedBy={item.modifiedByUser}/>
                    }
                </Col>
                { !layoutPreview && 
                    <Col xl="1" className="topic-col" style={{display: 'flex'}}>
                        <i className="far fa-chart-bar plan-input subTitle" style={{alignSelf: 'center'}}></i>
                    </Col>
                }
                <Col xl="11" style={{marginBottom: 8}} className="title-col">
                {editable ? 
                <input className="plan-input subTitle" name="title" type="text" style={{ width: "100%" }} placeholder="Otsikko" value={(item && title) ? title : ''} onChange={(e) => handleDataChanges("title", e.target.value)}></input>
                :
                    <TextEditor 
                        editorId={`${item.id}-subtitle-${editable}`} 
                        content={item.topicContent}
                        additionalClass="plan-input subTitle plan-subtitle"
                        readOnly={!editable}
                        placeholder="-"
                        plaintextEditor={true}
                    />
                }
                </Col>
                {editable ? 
                    <>
                        <Col className="col-sm-12 col-md-3 offset-md-1 plan-dropdown-col">
                            <button className={enableResize ? "small-white-button lighter-text wide-button active" : "small-white-button lighter-text wide-button"} onClick={() => toggleResize()} style={{marginBottom: '0.8em'}}>Muuta kokoa</button>
                        </Col>
                        <Col className="col-sm-12 col-md-3 offset-md-1 plan-dropdown-col">
                            <button 
                            className={showChart ? "small-white-button lighter-text wide-button active" : "small-white-button lighter-text wide-button"} 
                            onClick={() => setShowChart(!showChart)} id={`${item.id}-saveButton`}>{showChart ? "Piilota" : "Näytä"}</button>
                        </Col>
                        <Col className="col-sm-12 col-md-2 offset-md-1 plan-dropdown-col">
                            <button className="small-white-button lighter-text wide-button" onClick={() => onSave()} id={`${item.id}-saveButton`}>Tallenna</button>
                        </Col>
                        <Col xl="1" style={{paddingLeft: 15, marginBottom: 8}} className="plan-dropdown-col">
                            <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                            {editable && <Dropdown isOpen={ openMenu } style={{ height: '100%', cursor: 'pointer' }} className="plan-item-dropdown" onClick={ (event)=> event.stopPropagation() } toggle={ () => toggleMenu( !openMenu) } >
                                <DropdownToggle tag="div">
                                    <i className="fas fa-bars plusIcon" title="Valikko"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => toggleItemVisibility({planItemId: item.id})} className="change-status">{(item.visible !== undefined && !item.visible) ? "Julkinen" : "Yksityinen"}</DropdownItem>
                                    <DropdownItem onClick={() => onDelete({planItemId: item.id, topicContent: item.topicContent})} className="delete-plan-item">Poista</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>}
                            </div>
                        </Col>
                    </>
                    :
                    <>
                        <Col className="col-sm-12 col-md-3 offset-md-1 plan-dropdown-col">
                            <button 
                            className={showChart ? "small-white-button lighter-text wide-button active" : "small-white-button lighter-text wide-button"} 
                            onClick={() => setShowChart(!showChart)} id={`${item.id}-saveButton`}>{showChart ? "Piilota" : "Näytä"}</button>
                        </Col>
                    </>
                }
                <Col className="col-sm-12 col-md-10 offset-md-1" style={{marginTop: '1.5em'}}>
                    {showChart &&
                        <> 
                        {enableResize ?
                            <div style={{...size, maxWidth: '100%'}}>
                                <Rnd
                                size={size}
                                onResizeStop={(e, direction, ref, delta, position) => {
                                    handleResize(ref.style.height, ref.style.width)
                                }}
                                onResize={(e, direction, ref, delta, position) => {
                                    handleDivSize(ref.style.height, direction)
                                }}
                                disableDragging
                                style={style}
                                maxWidth={'100%'}
                                enableResizing={ {top:false, right:true, bottom:true, left:true, topRight:false, bottomRight:true, bottomLeft:true, topLeft:false } }
                                >
                                    <div className="dashboard-medium-box" style={{marginTop: 0, height: size.height, width: '100%', border: '1px solid black', paddingBottom: 2}}>
                                        <ResizedScrollBar size={size} title={data.name} toggleLegend={legendToggler}>
                                            <BarGraph data={data.data} barNames={data.barNames} title={data.title} i={item.id} toggledLegend={showLegend} yTitle={data.yTitle} xTitle={data.xTitle} />
                                        </ResizedScrollBar>
                                    </div>
                                </Rnd>
                            </div> 
                        : 
                        isLoading ? 
                            <div style={{height: '100%', width: '100%'}}>
                                <MegaLoaderSpinner />
                            </div>
                        :   
                            <div className="dashboard-medium-box" style={{marginTop: 0, ...size}} >
                                <ResizedScrollBar size={size} title={data.name} toggleLegend={legendToggler}>
                                    <BarGraph 
                                        data={data.data} 
                                        barNames={data.barNames} 
                                        title={data.title} 
                                        i={item.id} 
                                        toggledLegend={showLegend} 
                                        yTitle={data.yTitle} 
                                        xTitle={data.xTitle} />
                                </ResizedScrollBar>
                            </div>
                        }
                        </>
                    }    
                </Col>
            </Row>
        </div>
    )
}

export default ExcelBlock


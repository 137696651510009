import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import Select from 'react-select';
import {Row, Col} from 'reactstrap';

const reportTypeList = [
    {
        value: 'barQuestions',
        label: 'Kysymys'
    },
    {
        value: 'bar',
        label: 'Ryhmä'
    }
]

const ViewChanger = ({setReportType, reportType, handleDateSelection, item }) => {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [focusedInput, setModifierFocus] = useState()

    const changeModifierDate = (dates) => {
        setStartDate(dates.startDate)
        setEndDate(dates.endDate)
        if(dates.startDate && dates.endDate && (startDate !== dates.startDate || endDate !== dates.endDate)){
            handleDateSelection({startDate: dates.startDate, endDate: dates.endDate})
        }
    }

    useEffect(()=>{
        if(item.dateRange){
            if(item.dateRange.startDate && item.dateRange.endDate){
                setStartDate(moment(item.dateRange.startDate))
                setEndDate(moment(item.dateRange.endDate))
            }
        }
    },[])

    const resetDateRange = () => {
        setStartDate(null)
        setEndDate(null)
        handleDateSelection({startDate:null, endDate: null})
    }

    return (
        <>
            <Col xl={{size: 5}} xs={{size: 5}}>
                <Row>
                    <Col xs={{size: 12}}>
                        <p>Raporttinäkymä</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{size: 8}}>
                        <Select value={getReportTypeObject(reportType)}
                            onChange={item => setReportType(item.value)}
                            options={reportTypeList.filter(x => x.value !== reportType)}
                            placeholder="Valitse raporttityyppi"
                            isDisabled={false}
                            noOptionsMessage={() => {return "Raporttityyppejä ei löytynyt"}}
                            maxMenuHeight={200}
                            className='react-select-container'
                            classNamePrefix="react-select"
                        />
                    </Col>
                </Row>
            </Col>
            <Col xl={{size: 7}} xs={{size: 7}}>
                <Row>
                    <Col xs={{size: 12}}>
                        <p>Aikaväli</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{size: 8}}>
                        <DateRangePicker
                            readOnly
                            startDate={startDate} // momentPropTypes.momentObj or null,
                            startDateId="unique_start_" // PropTypes.string.isRequired,
                            endDate={endDate} // momentPropTypes.momentObj or null,
                            endDateId="unique_enddate_" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => changeModifierDate({ startDate, endDate })} // PropTypes.func.isRequired,
                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => setModifierFocus(focusedInput)}
                            isOutsideRange={() => false}
                            startDatePlaceholderText="Aloitus pvm"
                            endDatePlaceholderText="Lopetus pvm"
                            // block
                            hideKeyboardShortcutsPanel={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <button className="small-white-button" style={{ marginLeft: '0.25em', marginTop: '0.25em'}} onClick={() => resetDateRange()}>Poista aikaväli</button>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default ViewChanger;

const getReportTypeObject = reportType => {
    switch(reportType) {
        case 'barQuestions':
            return {
                value: 'barQuestions', 
                label: 'Kysymys'
            }
        case 'bar':
            return {
                value: 'bar', 
                label: 'Ryhmä'
            }
        default:
            return {
                value: 'bar', 
                label: 'Ryhmä'
            }
      }
}
import React, { Component } from 'react';
import CategoryEdit from '../TypeComponents/category/CategoryEdit';
import QuestionGroupEdit from '../TypeComponents/questiongroup/QuestionGroupEdit';
import QuestionEdit from '../TypeComponents/question/QuestionEdit';
import { connect } from 'react-redux';
import * as ActionCreator from '../../../../../../store/index';
import { Row, Col } from 'reactstrap';
import GroupList from './GroupList';
import '../kartoitus.css';
import SidePanel from '../../KartoitusV2/SidePanel';
import FinalFeedback from '../TypeComponents/finalFeedback/FinalFeedback'

const MegaLoaderSpinner = () => {
    return (
            <Row className="loadingSpinnerRow">
                <Col xs={{ size: 12 }} style={{ display: 'flex', justifyContent: 'center'}}>
                    <div style={{ width: '5rem', height: '5rem', marginTop: '5rem' }} className="spinner-border text-primary megaLoaderSpinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </Col>
            </Row>
    )
}

class DataWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryWindow: true,
            questionGroupWindow: false,
            questionWindow: false,
            sentSurveyId: "",
            selectedSurveyIndex: 0,
            spellCheckOn: false
        }

        this.lastOptionRef = React.createRef();
        this.topOfTheWorld = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.question !== this.props.question) {
            if(this.props.question.questionOptions !== undefined && prevProps.question.questionOptions !== undefined) {
                if((this.props.question.questionOptions.length > prevProps.question.questionOptions.length) && (this.props.question.id === prevProps.question.id)) {
                    this.scrollToLastOption();
                }
                if(this.props.question.id !== prevProps.question.id) {
                    this.scrollToTheTop()
                }
            }
        }
    }

    scrollToLastOption = () => {
        this.lastOptionRef.current.parentElement.scroll({ top: this.lastOptionRef.current.offsetTop, left: 0, behavior: 'smooth' })
    }

    scrollToTheTop = () => {
        this.topOfTheWorld.current.parentElement.scroll({top: this.topOfTheWorld.current.offsetTop, left: 0, behavior: 'auto'})
    }

    updateQuestionOrder = (qgroup) => {
        let finalobj = { ...this.props.currentCategory, questionGroups: qgroup }
        this.props.reorderSurveyQuestions(finalobj);
    }

    setModifiableSentSurveyId = (id, index) => {
        this.setState({sentSurveyId: id, selectedSurveyIndex: index});
    }

    toggleSpellCheck = () => {
        this.setState( state => ({
            spellCheckOn: !state.spellCheckOn
        }))
    }

    render() {
        const isDialog = this.props.category.type === 'dialog' ? true : false
        return (
            <Row style={{ height: 'inherit' }}>
                
                <Col xl="3" className="dataWindowSidePanel">
                    {/*<GroupList setGroupData={this.props.setGroupData}
                    setQuestionData={this.props.setQuestionData} 
                    categoryEdit={this.props.changeToCategoryView} 
                    deleteGroup={this.props.prepareGroupDeletionData} 
                    getDocument={this.props.onSurveyCheck} onQuestionReorder={this.props.reorderQuestions}
                     activeGroupIndex={this.props.groupIndex} activeQuestion={this.props.question.id}/>*/}
                <SidePanel 
                currentSurveyQuestionGroups={this.props.currentCategory.questionGroups}  
                title={this.props.currentCategory.title} 
                setQuestionData={this.props.setQuestionData} 
                categoryEdit={this.props.changeToCategoryView}  
                setGroupData={this.props.setGroupData}
                currentCategory={this.props.currentCategory}  
                currentQuestion={this.props.question}
                currentQuestionGroup={this.props.group}
                changeToCategory={this.props.categoryEdit}
                view={this.props.view}
                addGroup={this.props.addGroup} 
                addQuestion={this.props.addQuestion}
                updateQuestionOrder={this.updateQuestionOrder}
                surveySelected={this.setModifiableSentSurveyId}
                type={this.props.currentCategory.type}
                changeToFeedbacksView={ this.props.changeToFeedbacksView }
                />
            </Col>
            <Col xl={{size: 9, order: 3}} style={{height: 'inherit', overflowY: 'auto', overflowX: 'hidden'}}>
                {this.props.loading && <MegaLoaderSpinner />}
                
                <div ref={this.topOfTheWorld}></div>
                
                {this.props.view === 'category' &&
                    <CategoryEdit category={this.props.category} executeLoader={this.props.executeLoader} surveyId={this.state.sentSurveyId} saveDraftjsImage={this.props.saveDraftjsImage} selectedSurveyIndex={this.state.selectedSurveyIndex} toggleSpellCheck={ this.toggleSpellCheck } spellCheckOn={ this.state.spellCheckOn }/> 
                }

                {this.props.view == 'group' &&
                    <QuestionGroupEdit group={this.props.group}
                    category={this.props.category} 
                    executeLoader={this.props.executeLoader} 
                    categoryId={this.props.category._id} 
                    deleteGroup={this.props.deleteGroup}  
                    surveyId={this.state.sentSurveyId}
                    saveDraftjsImage={this.props.saveDraftjsImage}
                    selectedSurveyIndex={this.state.selectedSurveyIndex}
                    spellCheckOn={ this.state.spellCheckOn }
                    />
                }

                {this.props.view === 'question' &&
                    <QuestionEdit
                        question={this.props.currentQuestion}
                        originalQuestion={this.props.originalQuestion}
                        group={this.props.group}
                        surveyIsDialog={isDialog}
                        activeGroupIndex={this.props.activeGroupIndex}
                        category={this.props.category}
                        executeLoader={this.props.executeLoader}
                        changeViewToGroup={this.props.changeViewToGroup}
                        surveyId={this.state.sentSurveyId}
                        saveDraftjsImage={this.props.saveDraftjsImage}
                        selectedSurveyIndex={this.state.selectedSurveyIndex}
                        categories={this.props.categories}
                        spellCheckOn={ this.state.spellCheckOn }
                    />
                }

                {this.props.view === 'feedbacks' &&
                    <FinalFeedback category={this.props.category} executeLoader={this.props.executeLoader} surveyId={this.state.sentSurveyId} saveDraftjsImage={this.props.saveDraftjsImage} selectedSurveyIndex={this.state.selectedSurveyIndex} toggleSpellCheck={ this.toggleSpellCheck } spellCheckOn={ this.state.spellCheckOn }/> 
                }

                <div ref={this.lastOptionRef}></div>
            </Col>
        </Row>
        )

    }
}

//Toggler for different data views with redux
const mapStateToProps = state => {
    return {
        categoryWindow: state.kartoitus.categoryWindow,
        questionGroupWindow: state.kartoitus.questionGroupWindow,
        questionWindow: state.kartoitus.questionWindow,
        currentGroup: state.kartoitus.currentQuestionGroup,
        groupData: state.kartoitus.groupData,
        currentCategory: state.kartoitus.currentCategory,
        currentQuestion: state.kartoitus.currentQuestion,
        loading: state.kartoitus.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onQuestionSave: (params) => dispatch(ActionCreator.saveQuestion(params)),
        executeLoader: (params) => dispatch(ActionCreator.loadingStatus(params)),
        reorderSurveyQuestions: (params) => dispatch(ActionCreator.reorderSurveyQuestions(params)),
        saveDraftjsImage: (params) => dispatch(ActionCreator.saveDraftjsImage(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataWindow);

